import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Typography } from '@material-ui/core';
import moment from 'moment';

const DataPagamentoField = ({ ...props }) => {
  const record = useRecordContext(props);
  const value = record
    ? record.record.categoria_problema_despesa_id
      ? record.record.categoria_problema_despesa_id === 1
        ? null
        : new Date(record.record.data_pagamento)
      : new Date(record.record.data_pagamento)
    : null;

  return (
    <Typography>{value ? moment(value).format('DD/MM/YYYY') : '-'}</Typography>
  );
};



export default DataPagamentoField;
