import React from 'react';

import { PDFViewer } from '@react-pdf/renderer';

import Document from './Document';

const RelatorioFc = ({ open, dados }) => (
  <>
    {open
        && (
        <PDFViewer
          style={{ margin: 0, padding: 0, border: 0 }}
          width="100%"
          height="100%"
        >
          <Document {...dados} />
        </PDFViewer>
        )}
  </>
);

export default RelatorioFc;
