import React, { useRef, useState, useEffect, useContext } from 'react';

import { makeStyles, Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AlarmIcon from '@material-ui/icons/Alarm';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'notistack';

import {
  DialogoConfirmacao,
  AutoCompleteRemoto,
  DialogoCliente2,
  SwitchInput,
  DateTimeInputBonito,
} from '../../../..';
import { getListAllAPI } from '../../../../../services';
import {
  formatCPF,
  formatCNPJ,
  STATUS_ATIVO,
  PRIMARY_COLOR,
} from '../../../../../utils';
import { tipoStatusAndamentoCriarPedido } from '../../utils/constants';
import { PedidoContext } from '../contextPedido';
import AppBar from './AppBar';
import DialogoIntervalo from './DialogoIntervalo';

const FECHAR_PEDIDO_ACTION = 'F8';
const CANCELAR_PEDIDO_ACTION = 'F4';
const EDITAR_CLIENTE_ACTION = 'F7';
const NOVO_CLIENTE_ACTION = 'F9';

const useStyles = makeStyles(() => ({
  btn: {
    marginTop: '10px',
    opacity: '0.75',
  },
  footer: {
    height: '15vh',
    padding: '10px',
    paddingBottom: '20px',
  },
}));

function PreInicio({ voltar, prosseguir }) {
  const classes = useStyles();

  const refDialogoConfirmacao = useRef(null);
  const refDialogoCliente = useRef(null);

  const { pedido, dispatch } = useContext(PedidoContext);
  const {
    cliente,
    itens,
    idEdit,
    isMargemCartao,
    dataMinima,
    dataMaxima,
    tipoStatusAndamento,
  } = pedido;

  const [clienteLocal, setClienteLocal] = useState(cliente);
  const [isMargemCartaoLocal, setIsMargemCartaoLocal] = useState(
    isMargemCartao,
  );

  const [dataMinimaLocal, setDataMinimaLocal] = useState(dataMinima);
  const [dataMaximaLocal, setDataMaximaLocal] = useState(dataMaxima);

  const refDialogoIntervalo = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  function handleActions(action) {
    switch (action) {
      case FECHAR_PEDIDO_ACTION: {
        dispatch({
          type: 'UPDATE_DADOS_TELA_INICIAL',
          cliente: clienteLocal,
          isMargemCartao: isMargemCartaoLocal,
          dataMinima: dataMinimaLocal,
          dataMaxima: dataMaximaLocal,
        });
        prosseguir();

        break;
      }
      case CANCELAR_PEDIDO_ACTION:
        if (refDialogoConfirmacao.current) {
          refDialogoConfirmacao.current.handleOpen(
            'Cancelamento da pedido de liberação',
            'Tem certeza que deseja cancelar esse pedido de liberação!',
            CANCELAR_PEDIDO_ACTION,
          );
        }
        break;
      case EDITAR_CLIENTE_ACTION:
        if (refDialogoCliente.current) {
          const idCliente = clienteLocal ? clienteLocal.id : 0;
          if (idCliente > 0) {
            refDialogoCliente.current.handleEdit(idCliente, 0);
          }
        }
        break;
      case NOVO_CLIENTE_ACTION:
        if (refDialogoCliente.current) refDialogoCliente.current.handleCreate();
        break;
      default:
        break;
    }
  }

  function handleConfirma(codigo) {
    switch (codigo) {
      case CANCELAR_PEDIDO_ACTION:
        voltar();
        break;
      default:
        break;
    }
  }

  function validateCliente() {
    let error = '';
    if (!clienteLocal) error = 'Este campo é obrigatório';
    return error;
  }

  function getErros() {
    const errosOld = ['', ''];
    errosOld[0] = validateCliente();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  async function updateCliente(id) {
    const data = await getListAllAPI('clientes', ['id', 'asc'], { id }, [
      'cidade.estado',
    ]);
    if (data.data.length <= 0) {
      throw 'Cliente não encontrado!';
    }
    setClienteLocal(data.data[0]);
  }

  function valorMenorMultiplo15(valor) {
    const resto = valor % 15;

    return valor - resto;
  }

  function addTempo() {
    const timeInicio = dataMinimaLocal.getTime();
    const timeFim = dataMaximaLocal.getTime();

    const minutosDiferenca = parseInt((timeFim - timeInicio) / (60 * 1000), 10);

    let novosMinutos = minutosDiferenca + 15;

    novosMinutos = novosMinutos > 120 ? 15 : valorMenorMultiplo15(novosMinutos);

    const timeNovo = dataMinimaLocal.getTime() + novosMinutos * 60 * 1000;
    const novoData = new Date();
    novoData.setTime(timeNovo);
    setDataMaximaLocal(novoData);
  }

  function getIntervaloAtual() {
    const timeInicio = dataMinimaLocal.getTime();
    const timeFim = dataMaximaLocal.getTime();

    const minutos = parseInt((timeFim - timeInicio) / (60 * 1000), 10);

    return minutos;
  }

  function editarTempo() {
    if (refDialogoIntervalo.current) {
      refDialogoIntervalo.current.handleOpen(getIntervaloAtual());
    }
  }

  return (
    <>
      <AppBar
        disabledBack={false}
        disabledForward={erroExistente}
        onClick={handleActions}
        FECHAR_PEDIDO_ACTION={FECHAR_PEDIDO_ACTION}
        CANCELAR_PEDIDO_ACTION={CANCELAR_PEDIDO_ACTION}
        NOVO_CLIENTE_ACTION={NOVO_CLIENTE_ACTION}
        idEdit={idEdit}
        numeroPedido={idEdit}
        disabledCliente={tipoStatusAndamento !== tipoStatusAndamentoCriarPedido}
        hideCliente={tipoStatusAndamento !== tipoStatusAndamentoCriarPedido}
        tipoStatusAndamento={tipoStatusAndamento}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        className={classes.body}
      >
        <Box
          flex={4}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <Box
            display="flex"
            css={{
              backgroundColor: 'transparent',
              padding: '10px 10px',
              width: '50%',
            }}
          >
            <Box
              display="flex"
              css={{
                backgroundColor: 'white',
                opacity: '0.75',
                padding: '0px 10px',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <h2
                  style={{
                    textAlign: 'center',
                    margin: '10px',
                  }}
                >
                  Cliente
                </h2>
                {itens.length === 0 &&
                tipoStatusAndamento === tipoStatusAndamentoCriarPedido ? (
                  <Tooltip title="Editar Cliente">
                    <IconButton
                      edge="start"
                      className={classes.menuButton}
                      color="inherit"
                      aria-label="open drawer"
                      size="small"
                      disabled={
                        clienteLocal === null ? true : clienteLocal.id === 1
                      }
                      onClick={() => handleActions(EDITAR_CLIENTE_ACTION)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </div>
              <Box
                display="flex"
                alignItems="center"
                flex={1}
                style={{
                  gap: '10px',
                }}
              >
                <AutoCompleteRemoto
                  id="cliente"
                  name="cliente"
                  label="Responsável"
                  value={clienteLocal}
                  onChange={(value) => {
                    if (itens.length <= 0) {
                      setClienteLocal(value);
                    } else {
                      enqueueSnackbar(
                        'Pra alterar o cliente, exclua todos os itens do pedido!!!',
                        {
                          variant: 'error',
                        },
                      );
                    }
                  }}
                  handleKey={(keyCode, keyName) => {
                    handleActions(keyName);
                  }}
                  handleEnter={() => {}}
                  resource="clientes"
                  nested={['cidade.estado', 'categoria']}
                  toString={false}
                  fullwidth={false}
                  autoFocus
                  filters={{ status: STATUS_ATIVO, ids_excluso: [1] }}
                  disabled={itens.length > 0}
                />
                <AutoCompleteRemoto
                  id="cliente"
                  name="cliente"
                  label="Nome Fantasia"
                  value={clienteLocal}
                  onChange={(value) => {
                    if (itens.length <= 0) {
                      setClienteLocal(value);
                    } else {
                      enqueueSnackbar(
                        'Pra alterar o cliente, exclua todos os itens do pedido!!!',
                        {
                          variant: 'error',
                        },
                      );
                    }
                  }}
                  handleKey={(keyCode, keyName) => {
                    handleActions(keyName);
                  }}
                  handleEnter={() => {}}
                  resource="clientes"
                  campo="nome_fantasia"
                  nested={['cidade.estado', 'categoria']}
                  toString={false}
                  fullwidth={false}
                  filters={{ status: STATUS_ATIVO, ids_excluso: [1] }}
                  disabled={itens.length > 0}
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                flex={1}
                style={{
                  gap: '10px',
                }}
              >
                <AutoCompleteRemoto
                  id="cliente"
                  name="cliente"
                  label="CPF"
                  value={clienteLocal}
                  onChange={(value) => {
                    if (itens.length <= 0) {
                      setClienteLocal(value);
                    } else {
                      enqueueSnackbar(
                        'Pra alterar o cliente, exclua todos os itens do pedido!!!',
                        {
                          variant: 'error',
                        },
                      );
                    }
                  }}
                  handleKey={(keyCode, keyName) => {
                    handleActions(keyName);
                  }}
                  handleEnter={() => {}}
                  resource="clientes"
                  campo="cpf"
                  campoPesquisa="cpf"
                  nested={['cidade.estado', 'categoria']}
                  toString={false}
                  style={{}}
                  fullwidth={false}
                  filters={{ status: STATUS_ATIVO, ids_excluso: [1] }}
                  disabled={itens.length > 0}
                />
                <AutoCompleteRemoto
                  id="cliente"
                  name="cliente"
                  label="CNPJ"
                  value={clienteLocal}
                  onChange={(value) => {
                    if (itens.length <= 0) {
                      setClienteLocal(value);
                    } else {
                      enqueueSnackbar(
                        'Pra alterar o cliente, exclua todos os itens do pedido!!!',
                        {
                          variant: 'error',
                        },
                      );
                    }
                  }}
                  handleKey={(keyCode, keyName) => {
                    handleActions(keyName);
                  }}
                  handleEnter={() => {}}
                  resource="clientes"
                  campo="cnpj"
                  campoPesquisa="cnpj"
                  nested={['cidade.estado', 'categoria']}
                  toString={false}
                  style={{}}
                  fullwidth={false}
                  filters={{ status: STATUS_ATIVO, ids_excluso: [1] }}
                  disabled={itens.length > 0}
                />
              </Box>
              {clienteLocal && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flex={1}
                  style={{
                    marginLeft: '0px',
                    padding: '0px',
                    borderRadius: '5px',
                    marginBottom: '10px',
                    marginTop: '10px',
                  }}
                >
                  <Box
                    marginLeft="10px"
                    display="flex"
                    flexDirection="column"
                    flex={1}
                    style={{
                      gap: '5px',
                    }}
                  >
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: PRIMARY_COLOR,
                          marginRight: '10px',
                        }}
                      >
                        Responsável:
                      </p>
                      {clienteLocal.nome}
                    </Box>
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: PRIMARY_COLOR,
                          marginRight: '10px',
                        }}
                      >
                        Razão Social:
                      </p>
                      {clienteLocal.razao_social}
                    </Box>
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: PRIMARY_COLOR,
                          marginRight: '10px',
                        }}
                      >
                        CPF:
                      </p>
                      {formatCPF(clienteLocal.cpf)}
                    </Box>
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: PRIMARY_COLOR,
                          marginRight: '10px',
                        }}
                      >
                        CNPJ:
                      </p>
                      {formatCNPJ(clienteLocal.cnpj)}
                    </Box>
                  </Box>
                  <Box
                    marginLeft="10px"
                    display="flex"
                    flexDirection="column"
                    flex={1}
                  >
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                      justifyContent="flex-start"
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: PRIMARY_COLOR,
                          marginRight: '10px',
                        }}
                      >
                        Nome Fantasia:
                      </p>
                      {clienteLocal.nome_fantasia}
                    </Box>

                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                      justifyContent="flex-start"
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: PRIMARY_COLOR,
                          marginRight: '10px',
                        }}
                      >
                        Bairro:
                      </p>
                      {clienteLocal.bairro}
                    </Box>
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                      justifyContent="flex-start"
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: PRIMARY_COLOR,
                          marginRight: '10px',
                        }}
                      >
                        Cidade:
                      </p>
                      {clienteLocal.cidade
                        ? `${clienteLocal.cidade.nome} - ${clienteLocal.cidade.estado.sigla}`
                        : ''}
                    </Box>
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                      justifyContent="flex-start"
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: PRIMARY_COLOR,
                          marginRight: '10px',
                        }}
                      >
                        Logradouro:
                      </p>
                      {clienteLocal.logradouro}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <Box
            display="flex"
            css={{
              backgroundColor: 'transparent',
              padding: '10px 10px',
              width: '50%',
            }}
          >
            <Box
              display="flex"
              css={{
                backgroundColor: 'white',
                opacity: '0.75',
                padding: '0px 10px',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                }}
              >
                <div style={{ marginTop: '15px', flex: 1 }}>
                  <DateTimeInputBonito
                    showTodayButton
                    todayLabel="Hoje"
                    name="dataMinima"
                    handleEnter={() => {}}
                    label="Horário de Início"
                    handleKey={() => {}}
                    value={dataMinimaLocal}
                    onChange={(value) => setDataMinimaLocal(value)}
                    format="dd MMMM yyyy - HH:mm"
                    fullWidth
                    variant="standard"
                    maxDate={dataMaximaLocal}
                    disabled
                    inputVariant="outlined"
                  />
                </div>
                <div
                  style={{
                    marginTop: '15px',
                    display: 'flex',
                    flex: 1,
                  }}
                >
                  <DateTimeInputBonito
                    showTodayButton
                    todayLabel="Hoje"
                    name="dataLimite"
                    handleEnter={() => {}}
                    label="Horário Limite"
                    handleKey={() => {}}
                    value={dataMaximaLocal}
                    onChange={(value) => {
                      if (value.getTime() > dataMinimaLocal.getTime()) {
                        setDataMaximaLocal(value);
                      }
                    }}
                    format="dd MMMM yyyy - HH:mm"
                    fullWidth
                    variant="standard"
                    minDate={
                      new Date().getTime() > dataMinimaLocal.getTime()
                        ? new Date()
                        : dataMinimaLocal
                    }
                    disabled
                    inputVariant="outlined"
                  />
                  {tipoStatusAndamento === tipoStatusAndamentoCriarPedido ? (
                    <Tooltip
                      title="Mudar para próximos 15 minutos"
                      placement="bottom"
                      aria-label="add2"
                    >
                      <IconButton
                        aria-label="editar"
                        size="small"
                        onClick={() => addTempo()}
                      >
                        <AlarmIcon />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                  {tipoStatusAndamento === tipoStatusAndamentoCriarPedido ? (
                    <Tooltip
                      title="Editar período"
                      placement="bottom"
                      aria-label="add2"
                    >
                      <IconButton
                        aria-label="editar"
                        size="small"
                        onClick={() => editarTempo()}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </div>
              </div>
              <div
                style={{
                  marginTop: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1,
                }}
              >
                {/* {tipoStatusAndamento === tipoStatusAndamentoCriarPedido ? (
                  <SwitchInput
                    name="is_margem_cartao"
                    handleEnter={() => {}}
                    label="Mudar pra Liberar Venda em Dinheiro / PIX à Distância"
                    handleKey={() => {}}
                    checked={isMargemCartaoLocal}
                    onChange={(value) => {
                      if (itens.length > 0) {
                        enqueueSnackbar(
                          'Pra alterar a forma de pagamento, exclua todos os itens do pedido!!!',
                          {
                            variant: 'error',
                          },
                        );
                      } else {
                        setIsMargemCartaoLocal(value.target.checked);
                      }
                    }}
                  />
                ) : (
                  <span
                    style={{
                      color: 'red',
                      fontSize: '20px',
                      fontWeight: 'bold',
                      border: '2px solid red',
                      borderRadius: '20px',
                      padding: '10px',
                    }}
                  >
                    {isMargemCartao
                      ? 'Venda em Dinheiro / PIX à Distância'
                      : 'Venda em Cartão'}
                  </span>
                )} */}
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                  }}
                >
                  <span
                    style={{
                      color: isMargemCartaoLocal ? 'green' : 'red',
                      fontSize: '20px',
                      fontWeight: 'bold',
                      border: isMargemCartaoLocal
                        ? '2px solid green'
                        : '2px solid red',
                      borderRadius: '20px',
                      padding: '10px',
                      cursor: 'pointer',
                    }}
                  >
                    {isMargemCartaoLocal
                      ? 'Venda em Dinheiro / PIX à Distância'
                      : 'Venda em Cartão'}
                  </span>
                  {tipoStatusAndamento === tipoStatusAndamentoCriarPedido ? (
                    <Tooltip
                      title="Mudar forma de pagamento"
                      placement="bottom"
                      aria-label="add2"
                    >
                      <IconButton
                        aria-label="editar"
                        size="medium"
                        onClick={() => {
                          if (itens.length > 0) {
                            enqueueSnackbar(
                              'Pra alterar a forma de pagamento, exclua todos os itens do pedido!!!',
                              {
                                variant: 'error',
                              },
                            );
                          } else {
                            setIsMargemCartaoLocal(!isMargemCartaoLocal);
                          }
                        }}
                      >
                        <AutorenewIcon />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </div>
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={handleConfirma}
      />
      <DialogoCliente2
        ref={refDialogoCliente}
        handleClose={(idUpdate) => {
          if (idUpdate > 0) {
            updateCliente(idUpdate);
          }
        }}
      />
      <DialogoIntervalo
        ref={refDialogoIntervalo}
        handleSalvar={(value) => {
          const timeNovo = dataMinimaLocal.getTime() + value * 60 * 1000;
          const novoData = new Date();
          novoData.setTime(timeNovo);
          setDataMaximaLocal(novoData);
        }}
      />
    </>
  );
}

export default PreInicio;
