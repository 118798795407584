import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from 'react';

import { Box } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import NoteIcon from '@material-ui/icons/Note';
import ReceiptIcon from '@material-ui/icons/Receipt';
import RefreshIcon from '@material-ui/icons/Refresh';
import StayCurrentPortraitIcon from '@material-ui/icons/StayCurrentPortrait';
import TodayIcon from '@material-ui/icons/Today';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import MaterialIcon from 'material-icons-react';
import { useSnackbar } from 'notistack';

import {
  getListAllAPI,
  createAPI,
  updateAPI,
  getUserId,
} from '../../../services';
import { STATUS_ATIVO } from '../../../utils/constants';
import {
  PrecoInput,
  SwitchInput,
  DateInputBonito,
  TextInput,
  AutoCompleteLocalCustomizado,
  AutoCompleteRemoto,
} from '../../Inputs';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const useStyles3 = makeStyles({
  chip: {
    borderColor: (props) => props.color,
    backgroundColor: 'white',
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  deleteIcon: {
    color: (props) => props.color,
    cursor: 'pointer',
    '&:hover': {
      color: (props) => props.color,
    },
  },
});

const ChipCategoria = ({ option, injetado, campo }) => {
  const classes2 = useStyles3({ color: option.color });
  const { onDelete, ...rest } = injetado;
  return (
    <Chip
      classes={{ outlined: classes2.chip, deleteIcon: classes2.deleteIcon }}
      variant="outlined"
      label={option[campo]}
      {...rest}
      avatar={
        <Avatar style={{ backgroundColor: 'white', marginRight: '3px' }}>
          <MaterialIcon icon={option.icon} size={20} color={option.color} />
        </Avatar>
      }
    />
  );
};

const ChipCategoriaProblema = ({ option, injetado, campo }) => {
  const classes2 = useStyles3({ color: 'black' });
  const { onDelete, ...rest } = injetado;
  return (
    <Chip
      classes={{ outlined: classes2.chip, deleteIcon: classes2.deleteIcon }}
      variant="outlined"
      label={option[campo]}
      {...rest}
      avatar={
        <Avatar
          style={{
            backgroundColor: 'black',
            marginRight: '3px',
            color: 'white',
          }}
        >
          {option.nome.charAt(0)}
        </Avatar>
      }
    />
  );
};

const ChipConta = ({ option, injetado, campo }) => {
  const classes2 = useStyles3({ color: option.color });
  const { onDelete, ...rest } = injetado;
  return (
    <Chip
      classes={{ outlined: classes2.chip, deleteIcon: classes2.deleteIcon }}
      variant="outlined"
      label={option[campo]}
      {...rest}
      avatar={
        <Avatar
          style={{
            backgroundColor: option.color,
            marginRight: '3px',
            color: 'white',
          }}
        >
          {option.nome.charAt(0)}
        </Avatar>
      }
    />
  );
};

const ChipFatura = ({ option, injetado, campo }) => {
  const classes2 = useStyles3({ color: '#8e8e8e' });
  const { onDelete, ...rest } = injetado;
  return (
    <Chip
      classes={{ outlined: classes2.chip, deleteIcon: classes2.deleteIcon }}
      variant="outlined"
      label={option[campo]}
      {...rest}
    />
  );
};

const ESPACO_INPUTS = '24px';
const TAMANHO_PEQUENO = 'sm';
const TAMANHO_GRANDE = 'lg';

const DEFAULT_DESCRICAO = '';
const DEFAULT_CATEGORIA = [];
const DEFAULT_CARTAO_CREDITO = [];
const DEFAULT_VALOR = 0;
const DEFAULT_DATA_PAGAMENTO = new Date();
const DEFAULT_ID = -1;
const DEFAULT_TAMANHO = TAMANHO_PEQUENO;
const DEFAULT_TIPO_EDICAO = 0;
const DEFAULT_GRUPO_ID = -1;
const DEFAULT_REPETIR = false;
const DEFAULT_VEZES_REPETIR = 2;

const Vazio = [
  {
    nome: 'Despesa Não problemática',
    id: -1,
    cor: 'red',
    icone: '',
  },
];

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose, campo = 'nome' }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [descricao, setDescricao] = useState(DEFAULT_DESCRICAO);
    const [categoria, setCategoria] = useState(DEFAULT_CATEGORIA);
    const [categoriaProblemas, setCategoriaProblema] = useState(
      DEFAULT_CATEGORIA,
    );
    const [cartao, setCartao] = useState(DEFAULT_CARTAO_CREDITO);
    const [cartoesOptions, setCartoesOptions] = useState([]);
    const [valor, setValor] = useState(DEFAULT_VALOR);
    const [dataPagamento, setDataPagamento] = useState(DEFAULT_DATA_PAGAMENTO);
    const [categoriasOptions, setCategoriasOptions] = useState([]);
    const [
      categoriasProblemasOptions,
      setCategoriasProblemasOptions,
    ] = useState([]);
    const [id, setId] = useState(DEFAULT_ID);
    const classes = useStyles();
    const [tamanho, setTamanho] = useState(DEFAULT_TAMANHO);
    const [tipoEdicao, setTipoEdicao] = React.useState(DEFAULT_TIPO_EDICAO);
    const [grupoId, setGrupoId] = useState(DEFAULT_GRUPO_ID);
    const [repetir, setRepetir] = useState(DEFAULT_REPETIR);
    const [vezesRepetir, setVezesRepetir] = useState(DEFAULT_VEZES_REPETIR);
    const [carregando, setCarregando] = useState(false);
    const [fatura, setFatura] = useState([]);
    const [faturasOptions, setFaturasOptions] = useState([]);
    const [sufixo, setSufixo] = useState('');
    const refValorField = useRef(null);
    const [descricaoProblema, setDescricaoProblema] = useState('');
    const [fornecedor, setFornecedor] = useState(null);
    const [isParcelaCompra, setIsParcelaCompra] = useState(false);
    const [idFornecedorOriginal, setIdFornecedorOriginal] = useState(0);

    const handleChange = (event) => {
      setTipoEdicao(parseInt(event.target.value, 10));
    };

    /* useEffect(() => {
    async function getCategorias() {
      const dataCartoes = await getListAllAPI(
        'cartoes_credito',
        ['id', 'asc'],
        { },
        [],
      );

      const dataCategorias = await getListAllAPI(
        'categorias_transacao',
        ['id', 'asc'],
        { tipo: 'Despesa', gerenciado: false },
        [],
      );

      setCartoesOptions(dataCartoes.data);
      setCategoriasOptions(dataCategorias.data);
    }
    getCategorias();
  }, [open]); */

    async function getDados(
      categoriaInicial,
      cartaoInicial,
      categoriaProblemaInicial,
    ) {
      const dataCartoes = await getListAllAPI(
        'cartoes_credito',
        ['id', 'asc'],
        { ativo: true },
        [],
      );

      const dataCategorias = await getListAllAPI(
        'categorias_transacao',
        ['id', 'asc'],
        categoriaInicial
          ? {
              tipo: 'Despesa',
              gerenciado: false,
              status: STATUS_ATIVO,
              ids_incluso: [categoriaInicial.id],
            }
          : { tipo: 'Despesa', gerenciado: false, status: STATUS_ATIVO },
        [],
      );

      const dataCategoriasProblemas = await getListAllAPI(
        'categorias-problemas-despesas',
        ['id', 'asc'],
        categoriaProblemaInicial
          ? {
              status: STATUS_ATIVO,
              ids_incluso: [categoriaProblemaInicial.id],
              ids_excluso: [1],
            }
          : { status: STATUS_ATIVO, ids_excluso: [1] },
        [],
      );

      // if (categoriaInicial) {
      //   if (!categoriaInicial.ativo) {
      //     dataCategorias.data.push(categoriaInicial);
      //   }
      // }

      if (cartaoInicial) {
        if (!cartaoInicial.ativo) {
          dataCartoes.data.push(cartaoInicial);
        }
      }

      setCartoesOptions(dataCartoes.data);
      setCategoriasOptions(dataCategorias.data);
      //setCategoriasProblemasOptions(dataCategoriasProblemas.data);
      setCategoriasProblemasOptions([
        Vazio[0],
        ...dataCategoriasProblemas.data,
      ]);
    }

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    function getMesAnterior(mes, ano) {
      if (mes === 0) {
        return { mes: 11, ano: ano - 1 };
      }
      return { mes: mes - 1, ano };
    }

    function getMesPosterior(mes, ano) {
      if (mes === 11) {
        return { mes: 0, ano: ano + 1 };
      }
      return { mes: mes + 1, ano };
    }

    function getFaturaAtual(cartao, dataBase = new Date()) {
      const diaFechamento = cartao.dia_fechamento;
      const diaPagamento = cartao.dia_pagamento;

      const diaAtual = dataBase.getDate();
      const mesAtual = dataBase.getMonth();
      const anoAtual = dataBase.getFullYear();

      let infosFatura = null;

      if (diaFechamento < diaPagamento) {
        if (diaAtual > diaFechamento) {
          infosFatura = getMesPosterior(mesAtual, anoAtual);
        } else {
          infosFatura = { mes: mesAtual, ano: anoAtual };
        }
      } else if (diaAtual < diaFechamento) {
        infosFatura = getMesPosterior(mesAtual, anoAtual);
      } else {
        infosFatura = getMesPosterior(mesAtual, anoAtual);
        infosFatura = getMesPosterior(infosFatura.mes, infosFatura.ano);
      }
      infosFatura.nome = createDescricaoFatura(
        diaPagamento,
        infosFatura.mes,
        infosFatura.ano,
      );
      return infosFatura;
    }

    function createDescricaoFatura(dia_pagamento, mes, ano) {
      const dataPagamentoFatura = new Date();
      dataPagamentoFatura.setDate(dia_pagamento);
      dataPagamentoFatura.setMonth(mes);
      dataPagamentoFatura.setFullYear(ano);
      const descricaoFatura = `${format(
        dataPagamentoFatura,
        "dd 'de' MMM 'de' yyyy",
        {
          locale: ptBR,
        },
      )}`;
      return descricaoFatura;
    }

    async function reiniciar() {
      try {
        await getDados(null, null, null);
        setDescricao(DEFAULT_DESCRICAO);
        setCategoria(DEFAULT_CATEGORIA);
        setValor(DEFAULT_VALOR);
        setDataPagamento(new Date());
        setId(DEFAULT_ID);
        setTamanho(DEFAULT_TAMANHO);
        setTipoEdicao(DEFAULT_TIPO_EDICAO);
        setGrupoId(DEFAULT_GRUPO_ID);
        setRepetir(DEFAULT_REPETIR);
        setVezesRepetir(DEFAULT_VEZES_REPETIR);
        setCarregando(false);
        setCartao([]);
        setFatura([]);
        setFaturasOptions([]);
        setSufixo('');
        setCategoriaProblema([]);
        setDescricaoProblema('');
        setFornecedor(null);
        setIsParcelaCompra(false);
        setIdFornecedorOriginal(0);
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    async function reiniciarApartirCartao(idCartao) {
      try {
        await getDados(null, null, null);
        const dataCartoes = await getListAllAPI(
          'cartoes_credito',
          ['id', 'asc'],
          { id: [idCartao] },
          [],
        );
        if (dataCartoes.data.length <= 0)
          throw 'Este cartão de crédito não existe!';
        setDescricao(DEFAULT_DESCRICAO);
        setCategoria(DEFAULT_CATEGORIA);
        setValor(DEFAULT_VALOR);
        setDataPagamento(new Date());
        setId(DEFAULT_ID);
        setTamanho(DEFAULT_TAMANHO);
        setTipoEdicao(DEFAULT_TIPO_EDICAO);
        setGrupoId(DEFAULT_GRUPO_ID);
        setRepetir(DEFAULT_REPETIR);
        setVezesRepetir(DEFAULT_VEZES_REPETIR);
        setCarregando(false);
        setCartao([dataCartoes.data[0]]);
        setFatura([getFaturaAtual(dataCartoes.data[0])]);
        setFaturasOptions(montaFaturasOptions(dataCartoes.data[0]));
        setSufixo('');
        setCategoriaProblema([]);
        setDescricaoProblema('');
        setFornecedor(null);
        setIsParcelaCompra(false);
        setIdFornecedorOriginal(0);
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    async function reiniciarApartirFatura(idCartao, mes, ano) {
      try {
        await getDados(null, null, null);
        const dataCartoes = await getListAllAPI(
          'cartoes_credito',
          ['id', 'asc'],
          { id: [idCartao] },
          [],
        );
        if (dataCartoes.data.length <= 0)
          throw 'Este cartão de crédito não existe!';
        setDescricao(DEFAULT_DESCRICAO);
        setCategoria(DEFAULT_CATEGORIA);
        setValor(DEFAULT_VALOR);
        setDataPagamento(new Date());
        setId(DEFAULT_ID);
        setTamanho(DEFAULT_TAMANHO);
        setTipoEdicao(DEFAULT_TIPO_EDICAO);
        setGrupoId(DEFAULT_GRUPO_ID);
        setRepetir(DEFAULT_REPETIR);
        setVezesRepetir(DEFAULT_VEZES_REPETIR);
        setCarregando(false);
        setCartao([dataCartoes.data[0]]);
        setFatura([
          {
            mes,
            ano,
            nome: createDescricaoFatura(
              dataCartoes.data[0].dia_pagamento,
              mes,
              ano,
            ),
          },
        ]);
        setFaturasOptions(montaFaturasOptions(dataCartoes.data[0]));
        setSufixo('');
        setCategoriaProblema([]);
        setDescricaoProblema('');
        setFornecedor(null);
        setIsParcelaCompra(false);
        setIdFornecedorOriginal(0);
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    async function getDataResource(id) {
      try {
        const data = await getListAllAPI(
          'despesas_cartao_credito',
          ['id', 'asc'],
          { id: [id] },
          ['fatura.cartao', 'categoria', 'problema', 'fornecedor'],
        );
        if (data.data.length > 0) {
          await getDados(
            data.data[0].categoria,
            data.data[0].fatura.cartao,
            data.data[0].problema,
          );
          setDescricao(data.data[0].nome);
          setCategoria([data.data[0].categoria]);
          setValor(data.data[0].valor_original);
          setDataPagamento(new Date(data.data[0].data_real));
          setTamanho(data.data[0].grupo_id ? TAMANHO_GRANDE : TAMANHO_PEQUENO);
          setTipoEdicao(DEFAULT_TIPO_EDICAO);
          setGrupoId(
            data.data[0].grupo_id ? data.data[0].grupo_id : DEFAULT_GRUPO_ID,
          );
          setRepetir(DEFAULT_REPETIR);
          setVezesRepetir(DEFAULT_VEZES_REPETIR);
          setCarregando(false);
          setSufixo(
            data.data[0].grupo_id
              ? `(${data.data[0].id_repeticao}/${data.data[0].total_repeticoes})`
              : '',
          );
          setCartao([data.data[0].fatura.cartao]);
          setFatura([
            {
              nome: createDescricaoFatura(
                data.data[0].fatura.cartao.dia_pagamento,
                data.data[0].fatura.mes,
                data.data[0].fatura.ano,
              ),
              mes: data.data[0].fatura.mes,
              ano: data.data[0].fatura.ano,
            },
          ]);
          setFaturasOptions(
            montaFaturasOptions(data.data[0].fatura.cartao, {
              mes: data.data[0].fatura.mes,
              ano: data.data[0].fatura.ano,
            }),
          );
          setCategoriaProblema(
            data.data[0].problema ? [data.data[0].problema] : [],
          );
          setDescricaoProblema('');
          // setDescricaoProblema(
          //   data.data[0].problema
          //     ? data.data[0].descricao_problema
          //       ? data.data[0].descricao_problema
          //       : ''
          //     : '',
          // );
          setFornecedor(data.data[0].fornecedor);
          setIsParcelaCompra(!!data.data[0].parcela_compra_id);
          setIdFornecedorOriginal(
            data.data[0].fornecedor ? data.data[0].fornecedor.id : 0,
          );
        } else {
          throw 'Esta transação não existe!';
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    async function getFatura(cartao_credito_id, mes, ano) {
      try {
        const data = await getListAllAPI(
          'faturas',
          ['id', 'asc'],
          { cartao_credito_id, mes, ano },
          [],
        );
        if (data.data.length > 0) {
          return data.data[0].status;
        }
        throw 'Esta fatura não existe!';
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    async function handleSalvar() {
      setCarregando(true);
      const faturaStatus = await getFatura(
        cartao[0].id,
        fatura[0].mes,
        fatura[0].ano,
      );
      if (!faturaStatus) {
        try {
          if (id <= 0) {
            await createAPI('despesas_cartao_credito', {
              nome: descricao,
              valor_original: valor,
              data_real: dataPagamento,
              categoria_transacao_2_id:
                categoria.length > 0 ? categoria[0].id : null,
              repetir,
              vezesRepetir,
              cartao_credito_id: cartao[0].id,
              fatura: { mes: fatura[0].mes, ano: fatura[0].ano },
              categoria_problema_despesa_id:
                categoriaProblemas.length > 0 ? categoriaProblemas[0].id : null,
              descricao_problema:
                categoriaProblemas.length > 0 ? descricaoProblema : '',
              user_id: getUserId(),
              fornecedor_id: fornecedor ? fornecedor.id : 1,
            });
            setCarregando(false);
            enqueueSnackbar('Despesa de cartão criada com sucesso!', {
              variant: 'success',
            });
          } else {
            await updateAPI('despesas_cartao_credito', id, {
              nome: descricao,
              valor_original: valor,
              data_real: dataPagamento,
              categoria_transacao_2_id:
                categoria.length > 0 ? categoria[0].id : null,
              tipoEdicao,
              cartao_credito_id: cartao[0].id,
              fatura: { mes: fatura[0].mes, ano: fatura[0].ano },
              categoria_problema_despesa_id:
                categoriaProblemas.length > 0 ? categoriaProblemas[0].id : null,
              descricao_problema:
                categoriaProblemas.length > 0 ? descricaoProblema : '',
              user_id: getUserId(),
              fornecedor_id: fornecedor ? fornecedor.id : 1,
            });
            setCarregando(false);
            enqueueSnackbar('Despesa de cartão atualizada com sucesso!', {
              variant: 'success',
            });
          }
          handleCloseDialog();
        } catch (erros) {
          enqueueSnackbar(`${erros}`, {
            variant: 'error',
          });
          handleCloseDialog();
        }
      } else {
        enqueueSnackbar('Essa fatura já está paga!', {
          variant: 'error',
        });
      }
    }

    function focusValorField() {
      if (refValorField.current) {
        refValorField.current.focus();
        refValorField.current.select();
      }
    }

    const tempo = 1000;

    useEffect(() => {
      const timer = setTimeout(() => {
        if (open) {
          focusValorField();
        }
      }, tempo);
      return () => clearTimeout(timer);
    }, [open]);

    useImperativeHandle(ref, () => ({
      handleCreate() {
        setCarregando(true);
        reiniciar();
        setOpen(true);
      },
      handleCreateApartirCartao(idCartao) {
        setCarregando(true);
        reiniciarApartirCartao(idCartao);
        setOpen(true);
      },
      handleCreateApartirFatura(idCartao, mes, ano) {
        setCarregando(true);
        reiniciarApartirFatura(idCartao, mes, ano);
        setOpen(true);
      },
      handleEdit(id) {
        setCarregando(true);
        getDataResource(id);
        setOpen(true);
        setId(id);
      },
    }));

    const renderTagCartao = (option, injetado) => (
      <ChipConta option={option} injetado={injetado} campo={campo} />
    );

    const renderOptionCartao = (option) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          style={{
            backgroundColor: option.color,
            marginRight: '10px',
            width: '30px',
            height: '30px',
          }}
        >
          {option.nome.charAt(0)}
        </Avatar>
        {option[campo]}
      </div>
    );

    const renderTagCategoria = (option, injetado) => (
      <ChipCategoria option={option} injetado={injetado} campo={campo} />
    );

    const renderTagFatura = (option, injetado) => (
      <ChipFatura option={option} injetado={injetado} campo={campo} />
    );

    const renderOptionCategoria = (option) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          style={{
            backgroundColor: option.color,
            marginRight: '10px',
            width: '30px',
            height: '30px',
          }}
        >
          <MaterialIcon icon={option.icon} size={20} color="#000000" />
        </Avatar>
        {option[campo]}
      </div>
    );

    const renderOptionFatura = (option) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {option[campo]}
      </div>
    );

    const renderTagCategoriaProblema = (option, injetado) => (
      <ChipCategoriaProblema
        option={option}
        injetado={injetado}
        campo={campo}
        color="black"
      />
    );

    const renderOptionCategoriaProblema = (option) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {option[campo]}
      </div>
    );

    const handleOnChangeCartao = (value) => {
      if (value.length > 0) {
        if (value.length === 1) {
          setCartao([value[0]]);
          const faturaLocal = getFaturaAtual(value[0], dataPagamento);
          setFatura([faturaLocal]);
          setFaturasOptions(
            montaFaturasOptions(value[0], {
              mes: faturaLocal.mes,
              ano: faturaLocal.ano,
            }),
          );
        } else if (value.length === 2) {
          setCartao([value[1]]);
          const faturaLocal = getFaturaAtual(value[1], dataPagamento);
          setFatura([faturaLocal]);
          setFaturasOptions(
            montaFaturasOptions(value[1], {
              mes: faturaLocal.mes,
              ano: faturaLocal.ano,
            }),
          );
        }
      } else {
        setCartao([]);
        setFatura([]);
        setFaturasOptions([]);
      }
    };

    const handleOnChangeCategoria = (value) => {
      if (value.length > 0) {
        if (value.length === 1) {
          setCategoria([value[0]]);
        } else if (value.length === 2) {
          setCategoria([value[1]]);
        }
      } else {
        setCategoria([]);
      }
    };

    const handleOnChangeFatura = (value) => {
      if (value.length > 0) {
        if (value.length === 1) {
          setFatura([value[0]]);
        } else if (value.length === 2) {
          setFatura([value[1]]);
        }
      } else {
        setFatura([]);
      }
    };

    const handleOnChangeCategoriaProblema = (value) => {
      if (value.length > 0) {
        if (value[0].id < 0) {
          if (value.length === 1) {
            setCategoriaProblema([]);
          } else {
            const valorCategoria = value[value.length - 1];
            if (valorCategoria.id < 0) {
              setCategoriaProblema([]);
            } else {
              setCategoriaProblema([valorCategoria]);
            }
          }
        } else {
          const valorCategoria = value[value.length - 1];
          if (valorCategoria.id < 0) {
            setCategoriaProblema([]);
          } else {
            setCategoriaProblema([valorCategoria]);
          }
        }
      } else {
        setCategoriaProblema(value);
      }
    };

    function validateValor() {
      let error = '';
      if (valor <= 0 || Number.isNaN(valor)) {
        error = 'Deve ter um valor maior que 0';
      }
      return error;
    }

    function validateCategoria() {
      let error = '';
      if (categoria.length <= 0) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function validateCartaoCredito() {
      let error = '';
      if (cartao.length <= 0) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function validateVezesRepetir() {
      let error = '';
      if (vezesRepetir < 2) {
        error = 'Deve ser um valor maior que 2';
      }
      return error;
    }

    function validateDescricao() {
      let error = '';
      if (descricao === '' || descricao === null) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function validateCategoriaProblema() {
      let error = '';
      if (categoriaProblemas.length <= 0) {
        error = '';
      }
      return error;
    }

    function validateDescricaoProblema() {
      let error = '';
      if (descricaoProblema.length > 200) {
        error = 'A descrição deve ter no máximo 200 caracteres';
      }
      return error;
    }

    function validateFornecedor() {
      let error = '';
      if (!fornecedor) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function getErros() {
      const errosOld = ['', '', '', '', '', '', ''];
      errosOld[0] = validateValor();
      errosOld[1] = validateCategoria();
      errosOld[2] = validateVezesRepetir();
      errosOld[3] = validateDescricao();
      errosOld[4] = validateCartaoCredito();
      errosOld[5] = validateCategoriaProblema();
      errosOld[6] = validateDescricaoProblema();
      errosOld[7] = validateFornecedor();
      return errosOld;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    function montaFaturasOptions(cartao, fatura = null) {
      const options = [];

      const diaPagamento = cartao ? cartao.dia_pagamento : 1;

      let mesBackup = 0;
      let anoBackup = 0;

      if (fatura) {
        mesBackup = fatura.mes;
        anoBackup = fatura.ano;
      } else {
        const { mes, ano } = getFaturaAtual(cartao);
        mesBackup = mes;
        anoBackup = ano;
      }

      let mesIteracao = mesBackup;
      let anoIteracao = anoBackup;

      const faturasAntes = 3;
      const faturasDepois = 3;

      for (let i = 0; i < faturasAntes; i += 1) {
        const dataPagamentoFatura = new Date();
        const { mes, ano } = getMesAnterior(mesIteracao, anoIteracao);
        mesIteracao = mes;
        anoIteracao = ano;
        dataPagamentoFatura.setDate(diaPagamento);
        dataPagamentoFatura.setMonth(mesIteracao);
        dataPagamentoFatura.setFullYear(anoIteracao);
        const descricaoFatura = `${format(
          dataPagamentoFatura,
          "dd 'de' MMM 'de' yyyy",
          {
            locale: ptBR,
          },
        )}`;
        options.push({
          nome: descricaoFatura,
          ano: anoIteracao,
          mes: mesIteracao,
        });
      }

      options.reverse();

      mesIteracao = mesBackup;
      anoIteracao = anoBackup;

      const dataPagamentoFaturaSelecionada = new Date();
      dataPagamentoFaturaSelecionada.setDate(diaPagamento);
      dataPagamentoFaturaSelecionada.setMonth(mesIteracao);
      dataPagamentoFaturaSelecionada.setFullYear(anoIteracao);
      const descricaoFatura = `${format(
        dataPagamentoFaturaSelecionada,
        "dd 'de' MMM 'de' yyyy",
        {
          locale: ptBR,
        },
      )}`;
      options.push({
        nome: descricaoFatura,
        ano: anoIteracao,
        mes: mesIteracao,
      });

      for (let i = 0; i < faturasDepois; i += 1) {
        const dataPagamentoFatura = new Date();
        const { mes, ano } = getMesPosterior(mesIteracao, anoIteracao);
        mesIteracao = mes;
        anoIteracao = ano;
        dataPagamentoFatura.setDate(diaPagamento);
        dataPagamentoFatura.setMonth(mesIteracao);
        dataPagamentoFatura.setFullYear(anoIteracao);
        const descricaoFatura = `${format(
          dataPagamentoFatura,
          "dd 'de' MMM 'de' yyyy",
          {
            locale: ptBR,
          },
        )}`;
        options.push({
          nome: descricaoFatura,
          ano: anoIteracao,
          mes: mesIteracao,
        });
      }

      return options;
    }

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullWidth
          maxWidth={tamanho}
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: '#00796b', color: 'white' }}
          >
            {id <= 0
              ? 'Nova despesa de cartão de crédito'
              : 'Editar despesa de cartão de crédito'}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                  <PrecoInput
                    name="valor"
                    ref={refValorField}
                    handleEnter={() => {}}
                    label="Valor"
                    handleKey={() => {}}
                    value={Number.isNaN(valor) ? '' : String(valor)}
                    onChange={(value) => {
                      const newValue = parseFloat(value.target.value);
                      if (newValue <= 0 || Number.isNaN(newValue)) {
                        setValor(0);
                      } else {
                        setValor(newValue);
                      }
                    }}
                    error={erros[0] !== ''}
                    helperText={erros[0]}
                    style={{ marginBottom: ESPACO_INPUTS }}
                    fullWidth
                    variant="standard"
                    disabled={tipoEdicao === 2}
                    autoFocus
                    InputProps={{
                      startAdornment: (
                        <>
                          <StayCurrentPortraitIcon
                            style={{ marginRight: '24px' }}
                          />
                        </>
                      ),
                    }}
                  />

                  <DateInputBonito
                    showTodayButton
                    todayLabel="Hoje"
                    name="data_pagamento"
                    handleEnter={() => {}}
                    style={{ marginBottom: ESPACO_INPUTS, marginTop: '0px' }}
                    label="Data Pagamento"
                    handleKey={() => {}}
                    value={dataPagamento}
                    onChange={(value) => {
                      setDataPagamento(value);
                      if (cartao.length > 0) {
                        const faturaLocal = getFaturaAtual(cartao[0], value);
                        setFatura([faturaLocal]);
                        setFaturasOptions(
                          montaFaturasOptions(cartao[0], {
                            mes: faturaLocal.mes,
                            ano: faturaLocal.ano,
                          }),
                        );
                      } else {
                        setFatura([]);
                        setFaturasOptions([]);
                      }
                    }}
                    format="dd MMMM yyyy"
                    fullWidth
                    variant="standard"
                    disabled={tipoEdicao !== 0}
                    InputProps={{
                      startAdornment: (
                        <>
                          <TodayIcon style={{ marginRight: '24px' }} />
                        </>
                      ),
                    }}
                  />

                  <TextInput
                    name="descricao"
                    handleEnter={() => {}}
                    label="Descrição"
                    handleKey={() => {}}
                    value={descricao}
                    onChange={(value) => setDescricao(value.target.value)}
                    error={erros[3] !== ''}
                    helperText={erros[3]}
                    style={{ marginBottom: ESPACO_INPUTS, marginTop: '0px' }}
                    fullWidth
                    variant="standard"
                    InputProps={{
                      startAdornment: (
                        <>
                          <NoteIcon style={{ marginRight: '24px' }} />
                        </>
                      ),
                    }}
                  />

                  <AutoCompleteLocalCustomizado
                    name="categoria"
                    handleEnter={() => {}}
                    label="Categoria"
                    handleKey={() => {}}
                    value={categoria}
                    disableClearable
                    onChangeLocal={(value) => handleOnChangeCategoria(value)}
                    campo="nome"
                    options={categoriasOptions}
                    variant="standard"
                    renderTag={renderTagCategoria}
                    renderOption={renderOptionCategoria}
                    filterSelectedOptions={false}
                    style={{ marginBottom: ESPACO_INPUTS, marginTop: '0px' }}
                    error={erros[1] !== ''}
                    helperText={erros[1]}
                    startAdornment={
                      <InputAdornment position="start">
                        <BookmarkBorderIcon style={{ marginRight: '16px' }} />
                      </InputAdornment>
                    }
                  />

                  <AutoCompleteLocalCustomizado
                    name="cartao"
                    handleEnter={() => {}}
                    label="Cartão"
                    handleKey={() => {}}
                    value={cartao}
                    disableClearable
                    onChangeLocal={(value) => handleOnChangeCartao(value)}
                    campo="nome"
                    options={cartoesOptions}
                    variant="standard"
                    renderTag={renderTagCartao}
                    renderOption={renderOptionCartao}
                    filterSelectedOptions={false}
                    style={{ marginBottom: ESPACO_INPUTS, marginTop: '0px' }}
                    error={erros[4] !== ''}
                    helperText={erros[4]}
                    disabled={tipoEdicao === 2}
                    startAdornment={
                      <InputAdornment position="start">
                        <CreditCardIcon style={{ marginRight: '16px' }} />
                      </InputAdornment>
                    }
                  />

                  <AutoCompleteLocalCustomizado
                    name="fatura"
                    handleEnter={() => {}}
                    label="Fatura"
                    handleKey={() => {}}
                    value={fatura}
                    disableClearable
                    onChangeLocal={(value) => handleOnChangeFatura(value)}
                    campo="nome"
                    options={faturasOptions}
                    variant="standard"
                    renderTag={renderTagFatura}
                    renderOption={renderOptionFatura}
                    style={{ marginBottom: ESPACO_INPUTS, marginTop: '0px' }}
                    // disabled={false}
                    error={erros[2] !== ''}
                    helperText={erros[2]}
                    filterSelectedOptions={false}
                    disabled={tipoEdicao === 2}
                    startAdornment={
                      <InputAdornment position="start">
                        <ReceiptIcon
                          style={{
                            marginRight: '16px',
                            color: 'rgba(0, 0, 0, 0.7)',
                          }}
                        />
                      </InputAdornment>
                    }
                  />

                  {!repetir && (
                    <AutoCompleteLocalCustomizado
                      name="categoria_problema"
                      handleEnter={() => {}}
                      label="Categoria Problema"
                      handleKey={() => {}}
                      value={
                        categoriaProblemas.length <= 0
                          ? Vazio
                          : categoriaProblemas
                      }
                      disableClearable
                      onChangeLocal={(value) =>
                        handleOnChangeCategoriaProblema(value)
                      }
                      campo="nome"
                      options={categoriasProblemasOptions}
                      variant="standard"
                      renderTag={renderTagCategoriaProblema}
                      renderOption={renderOptionCategoriaProblema}
                      style={{ marginBottom: ESPACO_INPUTS, marginTop: '0px' }}
                      // defaultValue={[]}
                      disabled={tipoEdicao !== 0}
                      error={erros[5] !== ''}
                      helperText={erros[5] !== ''}
                      filterSelectedOptions={false}
                      startAdornment={
                        <InputAdornment position="start">
                          <BookmarkBorderIcon style={{ marginRight: '16px' }} />
                        </InputAdornment>
                      }
                    />
                  )}

                  {categoriaProblemas.length > 0 && !repetir && (
                    <TextInput
                      name="descricao_problema"
                      multiline
                      maxRows={4}
                      // ref={refs.current[1]}
                      handleEnter={() => {}}
                      label="Descrição Problema"
                      handleKey={() => {}}
                      value={descricaoProblema}
                      onChange={(value) =>
                        setDescricaoProblema(value.target.value)
                      }
                      error={erros[6] !== ''}
                      helperText={erros[6] !== ''}
                      style={{ marginBottom: ESPACO_INPUTS, marginTop: '0px' }}
                      fullWidth
                      variant="standard"
                      disabled={tipoEdicao !== 0}
                    />
                  )}

                  <AutoCompleteRemoto
                    id="fornecedor"
                    name="fornecedor"
                    label="Fornecedor"
                    value={fornecedor}
                    onChange={(value) => setFornecedor(value)}
                    handleKey={(keyCode, keyName) => {}}
                    handleEnter={() => {}}
                    resource="fornecedores_todos_tipos"
                    nested={[]}
                    toString={false}
                    fullwidth
                    variant="standard"
                    filters={
                      id > 0
                        ? {
                            status: STATUS_ATIVO,
                            tipo: [2, 3],
                            ids_incluso: [idFornecedorOriginal],
                          }
                        : {
                            status: STATUS_ATIVO,
                            tipo: [2, 3],
                          }
                    }
                    getOptionSelected={(option, value) =>
                      option.nome === value.nome
                    }
                    getOptionLabel={(option) =>
                      `${option.nome}${
                        option.nome_fantasia
                          ? ` - (${option.nome_fantasia})`
                          : ''
                      }`
                    }
                    error={erros[7] !== ''}
                    helperText={erros[7]}
                    disabled={isParcelaCompra || (id > 0 && tipoEdicao !== 2)}
                  />

                  {(id <= 0 || (id > 0 && grupoId > 0)) && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        margin: '24px 0px',
                      }}
                    >
                      <Button
                        onClick={() =>
                          tamanho === TAMANHO_PEQUENO
                            ? setTamanho(TAMANHO_GRANDE)
                            : setTamanho(TAMANHO_PEQUENO)
                        }
                        style={{
                          borderRadius: '25px',
                          fontWeight: 'bold',
                        }}
                      >
                        {tamanho === TAMANHO_PEQUENO ? (
                          <>
                            <div>Mais Detalhes</div>
                            <ChevronRightIcon />
                          </>
                        ) : (
                          <>
                            <div>Menos Detalhes</div>
                            <ChevronLeftIcon />
                          </>
                        )}
                      </Button>
                    </div>
                  )}
                </div>
                {tamanho === 'lg' && (
                  <div style={{ flex: 1, marginLeft: '50px' }}>
                    {id <= 0 && (
                      <>
                        <div
                          style={{
                            marginTop: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <RefreshIcon style={{ marginRight: '24px' }} />
                            <div style={{ fontWeight: 'bold' }}>Parcelado</div>
                          </div>
                          <SwitchInput
                            name="caixa"
                            handleEnter={() => {}}
                            label=""
                            handleKey={() => {}}
                            checked={repetir}
                            onChange={(value) =>
                              setRepetir(value.target.checked)
                            }
                          />
                        </div>

                        <div
                          style={{ display: 'flex', alignItems: 'baseline' }}
                        >
                          <TextInput
                            name="vezes"
                            handleEnter={() => {}}
                            label=""
                            handleKey={() => {}}
                            type="number"
                            error={erros[2] !== ''}
                            helperText={erros[2]}
                            disabled={!repetir}
                            fullWidth
                            variant="standard"
                            value={vezesRepetir}
                            onChange={(e) =>
                              setVezesRepetir(parseInt(e.target.value, 10))
                            }
                            InputProps={{
                              endAdornment: (
                                <div style={{ marginLeft: '35px' }}>vezes</div>
                              ),
                            }}
                          />
                        </div>
                      </>
                    )}
                    {id > 0 && grupoId > 0 && (
                      <>
                        <div
                          style={{
                            margin: '8px 0px',
                            color: 'rgba(0, 0, 0, 0.87)',
                            fontSize: '20px',
                            fontWeight: 'bold',
                          }}
                        >
                          <div
                            style={{ marginBottom: '5px' }}
                          >{`Atenção esta é uma despesa repetida ${sufixo}.`}</div>
                          <div>Você deseja...</div>
                        </div>
                        <FormControl
                          component="fieldset"
                          style={{ marginBottom: '9px' }}
                        >
                          <RadioGroup
                            aria-label="gender"
                            name="gender1"
                            value={tipoEdicao}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              value={0}
                              control={<Radio />}
                              label="Editar somente esta"
                            />
                            <FormControlLabel
                              value={1}
                              control={<Radio />}
                              label="Editar esta, e as futuras"
                            />
                            <FormControlLabel
                              value={2}
                              control={<Radio />}
                              label="Editar todas (incluindo efetivadas)"
                            />
                          </RadioGroup>
                        </FormControl>
                        {tipoEdicao === 1 && (
                          <div>
                            *Não é possível alterar a Data ou Efetivar a
                            despesa.
                          </div>
                        )}
                        {tipoEdicao === 2 && (
                          <div>
                            *Não é possível alterar o Valor, Data, Conta ou
                            Efetivar a despesa.
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
          </DialogContent>
          <DialogActions
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            <Box>
              <div className={classes.wrapper}>
                <Button
                  onClick={handleSalvar}
                  classes={{
                    root: classes.btnAtions,
                    disabled: classes.btnAtionsDisabled,
                  }}
                  disabled={erroExistente || carregando}
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: '#00796b', color: 'white' }}
                >
                  Salvar
                </Button>
                {carregando && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Box>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
