import React from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import EditIcon from '@material-ui/icons/Edit';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import PrintIcon from '@material-ui/icons/Print';
import SaveIcon from '@material-ui/icons/Save';
import ShareIcon from '@material-ui/icons/Share';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    /* height: 380,
    transform: 'translateZ(0px)',
    flexGrow: 1, */
  },
  speedDial: {
    // position: 'absolute',
    // bottom: theme.spacing(2),
    // right: theme.spacing(2),
    // zIndex: 1000,
  },
  fab: {
    backgroundColor: '#009FD4',
    color: 'white',
    '&:hover': {
      backgroundColor: '#009FD4',
      color: 'white',
    },
  },
}));

const actionsConta = [
  { icon: <AutorenewIcon />, name: 'Transferência' },
  { icon: <TrendingDownIcon />, name: 'Despesa' },
  { icon: <TrendingUpIcon />, name: 'Receita' },
];

const actionsCaixa = [{ icon: <AutorenewIcon />, name: 'Transferência' }];

export default function OpenIconSpeedDial({ handleNewTransacao, isCaixa }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);

  const handleVisibility = () => {
    setHidden((prevHidden) => !prevHidden);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (tipo) => {
    handleClose();
    handleNewTransacao(tipo);
  };

  const actions = isCaixa ? actionsCaixa : actionsConta;

  return (
    <SpeedDial
      ariaLabel="SpeedDial openIcon example"
      className={classes.speedDial}
      hidden={hidden}
      // icon={<SpeedDialIcon openIcon={<EditIcon />} />}
      icon={<SpeedDialIcon />}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      // style={{ backgroundColor: '#009FD4', color: 'white' }}
      classes={{ fab: classes.fab }}
      FabProps={{ size: 'small' }}
      direction="left"
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={() => handleClick(action.name)}
          tooltipPlacement="top"
        />
      ))}
    </SpeedDial>
  );
}
