import * as React from 'react';
import { useRecordContext } from 'react-admin';

import Chip from '@material-ui/core/Chip';

const StatusField = ({ handleVendasParciais, ...rest }) => {
  const record = useRecordContext(rest);
  const isEmPartes = record ? record.record.is_em_partes : false;
  return (
    <div style={{ display: 'flex' }}>
      {isEmPartes ? (
        <Chip
          size="small"
          label="Venda em Partes"
          style={{ backgroundColor: '#c4c930', cursor: 'pointer' }}
          onClick={() => handleVendasParciais(record.record)}
        />
      ) : (
        <Chip
          size="small"
          label="Venda Normal"
          style={{ backgroundColor: '#4caf50' }}
        />
      )}
    </div>
  );
};

export default StatusField;
