import React, { useState, forwardRef, useImperativeHandle } from 'react';

import { Box } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CloseIcon from '@material-ui/icons/Close';
import StayCurrentPortraitIcon from '@material-ui/icons/StayCurrentPortrait';
import TodayIcon from '@material-ui/icons/Today';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { api } from '../../services';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState(-1);
  const [conferidoMaquineta, setConferidoMaquineta] = useState(false);
  const [descricao, setDescricao] = useState('');
  const [data, setData] = useState('');
  const [valor, setValor] = useState('');
  const [modalidade, setModalidade] = useState('');
  const classes = useStyles();

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function handleSalvar() {
    try {
      if (id <= 0) {
        enqueueSnackbar('Nenhuma transação aqui!!!', {
          variant: 'error',
        });
      } else {
        await api.put(`/change_status_verificacao_planilha_cartoes/${id}`, {});
        enqueueSnackbar(`Mudança de status feita com sucesso!`, {
          variant: 'success',
        });
      }
      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id, descricao, data, valor, modalidade, conferidoMaquineta) {
      setDescricao(descricao);
      setValor(valor);
      setData(data);
      setModalidade(modalidade);
      setConferidoMaquineta(conferidoMaquineta);
      setOpen(true);
      setId(id);
    },
  }));

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{
            backgroundColor: conferidoMaquineta ? '#f44336' : '#4caf50',
            color: 'white',
          }}
        >
          {conferidoMaquineta ? 'Desconferir' : 'Conferir'}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '30px',
            margin: '0',
          }}
        >
          <div>
            <Box marginBottom="24px">
              <span
                style={{
                  fontWeight: 'bold',
                }}
              >
                Descrição:{' '}
              </span>
              <span>{descricao}</span>
            </Box>
            <Box marginBottom="24px">
              <span
                style={{
                  fontWeight: 'bold',
                }}
              >
                Data:{' '}
              </span>
              <span>{data}</span>
            </Box>
            <Box marginBottom="24px">
              <span
                style={{
                  fontWeight: 'bold',
                }}
              >
                Valor:{' '}
              </span>
              <span>{valor}</span>
            </Box>
            <Box marginBottom="24px">
              <span
                style={{
                  fontWeight: 'bold',
                }}
              >
                Modalidade:{' '}
              </span>
              <span>{modalidade}</span>
            </Box>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            padding: '32px 36px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={handleCloseDialog}
            variant="outlined"
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
            color="primary"
          >
            Cancelar
          </Button>
          <Box>
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                variant="contained"
                color="primary"
              >
                Confirmar
              </Button>
            </div>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
