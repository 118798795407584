import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

const useStyles = makeStyles((theme) => ({
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
  },
  cartao: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#00796b',
    marginLeft: '10px',
  },
}));

const StatusField = ({ handleStatus = () => {}, ...props }) => {
  const classes = useStyles();
  const record = useRecordContext(props);
  const status = record ? record.record.status : false;
  return (
    <div style={{ display: 'flex' }}>
      {status ? (
        <Avatar
          className={classes.confirmado}
          onClick={() => handleStatus(record)}
        >
          <CheckIcon style={{ fontSize: 15 }} />
        </Avatar>
      ) : (
        <Avatar className={classes.pendente}>
          <PriorityHighIcon
            style={{ fontSize: 15 }}
            onClick={() => handleStatus(record)}
          />
        </Avatar>
      )}
    </div>
  );
};

export default StatusField;
