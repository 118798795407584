import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CloseIcon from '@material-ui/icons/Close';
import Rating from '@material-ui/lab/Rating';
import { set } from 'date-fns';
import { useSnackbar } from 'notistack';

import {
  TextInput,
  AutoCompleteRemoto,
  SelectInput,
  SwitchInput,
} from '../../../Components';
import { createAPI, updateAPI, getListAllAPI } from '../../../services';
import {
  formatCNPJ,
  formatCPF,
  formatCEP,
  formatIE,
  STATUS_ATIVO,
  TURNO_MANHA,
  TURNO_NOITE,
  TURNO_12_HORAS,
} from '../../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_ID = -1;

const optionsTurno = [
  {
    label: 'Manhã',
    value: TURNO_MANHA,
  },
  {
    label: 'Noite',
    value: TURNO_NOITE,
  },
  {
    label: '12H',
    value: TURNO_12_HORAS,
  },
];

function getLabelFolga(grupo) {
  if (grupo) {
    if (!grupo.equipe_colaborador_id)
      return (
        <>
          <span
            style={{
              fontWeight: 'bold',
            }}
          >
            SS
          </span>
          <span>(folga no domingo)</span>
        </>
      );
    switch (grupo.dia_da_folga) {
      case 0:
        return (
          <>
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              Escalado
            </span>
            <span>(folga no domingo)</span>
          </>
        );
      case 1:
        return (
          <>
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              Escalado
            </span>
            <span>(folga na segunda-feira)</span>
          </>
        );
      case 2:
        return (
          <>
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              Escalado
            </span>
            <span>(folga na terça-feira)</span>
          </>
        );
      case 3:
        return (
          <>
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              Escalado
            </span>
            <span>(folga na quarta-feira)</span>
          </>
        );
      case 4:
        return (
          <>
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              Escalado
            </span>
            <span>(folga na quinta-feira)</span>
          </>
        );
      case 5:
        return (
          <>
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              Escalado
            </span>
            <span>(folga na sexta-feira)</span>
          </>
        );
      case 6:
        return (
          <>
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              Escalado
            </span>
            <span>(folga no sábado)</span>
          </>
        );
      default:
        return '';
    }
  }
  return '';
}

const GL1 = 'GL1';
const GL2 = 'GL2';
const GL3 = 'GL3';
const C1 = 'C1';

function getPodeEditar(codinome) {
  switch (codinome) {
    case GL1:
      return false;
    case GL2:
      return false;
    case GL3:
      return false;
    case C1:
      return false;
    default:
      return true;
  }
}

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [nome, setNome] = useState('');
  const [id, setId] = useState(DEFAULT_ID);
  const [funcao, setFuncao] = useState(null);
  const [turno, setTurno] = useState(TURNO_MANHA);
  const [grupo, setGrupo] = useState(null);
  //const [empresa, setEmpresa] = useState(null);
  //const [isFuncionarioInterno, setIsFuncionarioInterno] = useState(true);
  //const [empresaOriginalId, setEmpresaOriginalId] = useState(null);
  const [funcaoOriginalId, setFuncaoOriginalId] = useState(null);
  const [nomeOriginal, setNomeOriginal] = useState('');

  const [carregando, setCarregando] = useState(false);

  const classes = useStyles({ cor: '#009FD4' });

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function reiniciar() {
    setId(DEFAULT_ID);
    setNome('');
    setCarregando(false);
    setGrupo(null);
    setTurno(TURNO_MANHA);
    setFuncao(null);
    setFuncaoOriginalId(null);
    //setIsFuncionarioInterno(true);
    //setEmpresa(null);
    //setEmpresaOriginalId(null);
    setNomeOriginal('');
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'codinomes_colaborador',
        ['id', 'asc'],
        { id: [id] },
        ['funcao', 'grupoColaborador' /*'empresa'*/],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        setNome(data.data[0].nome);
        setCarregando(false);
        setFuncao(data.data[0].funcao);
        setFuncaoOriginalId(
          data.data[0].funcao ? data.data[0].funcao.id : null,
        );
        setGrupo(data.data[0].grupoColaborador);
        setFuncao(data.data[0].funcao);
        setTurno(data.data[0].turno);
        //setIsFuncionarioInterno(data.data[0].is_funcionario_interno);
        //setEmpresa(data.data[0].empresa);
        /*setEmpresaOriginalId(
          data.data[0].empresa ? data.data[0].empresa.id : null,
        );*/
        setNomeOriginal(data.data[0].nome);
      } else {
        throw 'Este Codinome não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    try {
      if (id <= 0) {
        await createAPI('codinomes_colaborador', {
          nome,
          turno,
          grupo_colaborador_id: grupo ? grupo.id : null,
          //is_funcionario_interno: isFuncionarioInterno,
          funcao_colaborador_id: funcao ? funcao.id : null,
          //empresa_id: empresa ? empresa.id : null,
        });
        setCarregando(false);
        enqueueSnackbar('Este codinome foi criado com sucesso!', {
          variant: 'success',
        });
      } else {
        await updateAPI('codinomes_colaborador', id, {
          nome,
          turno,
          grupo_colaborador_id: grupo ? grupo.id : null,
          //is_funcionario_interno: isFuncionarioInterno,
          funcao_colaborador_id: funcao ? funcao.id : null,
          //empresa_id: empresa ? empresa.id : null,
        });
        setCarregando(false);
        enqueueSnackbar('Este Codinome foi atualizado com sucesso!', {
          variant: 'success',
        });
      }

      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      reiniciar();
      setOpen(true);
    },
    handleEdit(id) {
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
    },
  }));

  function validateNome() {
    let error = '';
    if (!nome) error = 'Este campo é obrigatório';
    else if (nome.length > 80) {
      error = 'Número de caracteres maior que o permitido (máximo 80)';
    }
    return error;
  }

  function validateFuncao() {
    let error = '';
    if (!funcao) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateGrupo() {
    let error = '';
    if (!grupo) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  /*function validateEmpresa() {
    let error = '';
    if (!empresa) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }*/

  function getErros() {
    const errosOld = [''];
    errosOld[0] = validateNome();
    errosOld[1] = validateFuncao();
    errosOld[2] = validateGrupo();
    //errosOld[3] = validateEmpresa();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          {id < 0 ? `Novo Codinome` : `Editar Codinome`}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <Box display="flex">
                <Box flex={1} mr="0.5em">
                  <TextInput
                    name="nome"
                    handleEnter={() => {}}
                    label="Descrição"
                    handleKey={() => {}}
                    value={nome}
                    onChange={(value) => setNome(value.target.value)}
                    error={erros[0] !== ''}
                    helperText={erros[0]}
                    fullWidth
                    autoFocus
                    variant="outlined"
                    disabled={!getPodeEditar(nomeOriginal)}
                  />
                </Box>
                <Box flex={1} ml="0.5em">
                  <AutoCompleteRemoto
                    handleEnter={() => {}}
                    label="Função"
                    handleKey={() => {}}
                    value={funcao}
                    onChange={(value) => setFuncao(value)}
                    fullWidth
                    toString={false}
                    getOptionSelected={(option, value) =>
                      option.nome === value.nome
                    }
                    getOptionLabel={(option) => `${option.nome}`}
                    nested={[]}
                    filters={
                      funcaoOriginalId
                        ? {
                            ids_incluso: [funcaoOriginalId],
                            status: STATUS_ATIVO,
                          }
                        : { status: STATUS_ATIVO }
                    }
                    resource="funcoes_colaboradores"
                    disabled={false}
                    error={erros[1] !== ''}
                    helperText={erros[1]}
                  />
                </Box>
                {/*<Box
                  flex={1}
                  ml="0.5em"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <SwitchInput
                    name="is_funcionario_interno"
                    handleEnter={() => {}}
                    label="Funcionário Interno"
                    handleKey={() => {}}
                    checked={isFuncionarioInterno}
                    onChange={(value) => {
                      setIsFuncionarioInterno(value.target.checked);
                    }}
                  />
                  </Box>*/}
              </Box>
              <Box display="flex">
                {/*<Box flex={1} ml="0.5em">
                  <AutoCompleteRemoto
                    handleEnter={() => {}}
                    label="Loja"
                    handleKey={() => {}}
                    value={empresa}
                    onChange={(value) => setEmpresa(value)}
                    fullWidth
                    toString={false}
                    getOptionSelected={(option, value) =>
                      option.nome_fantasia === value.nome_fantasia
                    }
                    getOptionLabel={(option) => `${option.nome_fantasia}`}
                    nested={[]}
                    filters={
                      empresaOriginalId
                        ? {
                            ids_incluso: [empresaOriginalId],
                            status: STATUS_ATIVO,
                          }
                        : { status: STATUS_ATIVO }
                    }
                    resource="empresa-loja"
                    error={erros[3] !== ''}
                    helperText={erros[3]}
                  />
                  </Box>*/}
              </Box>
              <Box display="flex">
                <Box flex={1} mr="0.5em">
                  <AutoCompleteRemoto
                    name="grupo"
                    label="Grupo"
                    value={grupo}
                    onChange={(value) => setGrupo(value)}
                    handleKey={() => {}}
                    handleEnter={() => {}}
                    resource="grupos_colaborador"
                    nested={[]}
                    toString={false}
                    fullWidth
                    error={erros[2] !== ''}
                    helperText={erros[2]}
                  />
                </Box>
                <Box flex={1} ml="0.5em">
                  <FormControl
                    variant="outlined"
                    style={{
                      marginTop: '16px',
                    }}
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel shrink id="turno-colaborador">
                      Turno
                    </InputLabel>
                    <SelectInput
                      labelId="turno-colaborador"
                      name="turno"
                      handleEnter={() => {}}
                      label="Turno"
                      handleKey={() => {}}
                      value={turno}
                      onChange={(value) => setTurno(value.target.value)}
                      options={optionsTurno}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box display="flex" mt="10px">
                <Box
                  flex={1}
                  mr="0.5em"
                  style={{
                    display: 'flex',
                    //justifyContent: 'center',
                    alignItems: 'center',
                    gap: '5px',
                  }}
                >
                  {getLabelFolga(grupo)}
                </Box>
                <Box flex={1} ml="0.5em" />
              </Box>
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          <div className={classes.wrapper}>
            <Button
              onClick={handleSalvar}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              disabled={erroExistente || carregando}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
            {carregando && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
