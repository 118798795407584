import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import { useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import {
  IntegerInput,
  AutoCompleteRemoto,
  PesoInput,
  DateInputBonito,
  PrecoInput,
} from '../../Components/Inputs';
import SearchProduto from '../../Components/Inputs/SearchProduto';
import { PriceInput as PrecoInput2 } from '../../libNova/inputs/PriceInput';
import { WeightInput } from '../../libNova/inputs/WeightInput';
import { createAPI, getListAllAPI, updateAPI } from '../../services';
import { getUserId } from '../../services/alth';
import {
  SECONDARY_COLOR,
  formatMoeda,
  STATUS_ATIVO,
  POR_UNIDADE,
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  TIPO_UNIDADE_OFERTA_UN,
  TIPO_UNIDADE_OFERTA_CAIXA,
  TIPO_UNIDADE_OFERTA_CAIXETA,
  TIPO_UNIDADE_OFERTA_CAIXA_MAE,
  TIPO_UNIDADE_OFERTA_KG,
  POR_PESO_VARIAVEL,
  formatPeso,
  round10,
  ZERO_APROXIMADO,
} from '../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  rootDialogoSmall: {
    borderRadius: '0px',
  },
  btnAtions: {
    borderRadius: '25px',
  },
  btnAtionsSmall: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_ID = -1;

function getQtdeEfetivaCalculado(produto, tipoUnidadeOferta, qtde) {
  if (produto) {
    const tipo = produto.unidade.tipo;
    const tipoOferta = tipoUnidadeOferta;
    switch (tipo) {
      case POR_UNIDADE: {
        switch (tipoOferta) {
          case TIPO_UNIDADE_OFERTA_UN:
            return qtde;
          case TIPO_UNIDADE_OFERTA_CAIXA:
            return qtde * produto.unidades_caixa_nivel_1;

          case TIPO_UNIDADE_OFERTA_CAIXETA:
            return (
              qtde *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2
            );

          case TIPO_UNIDADE_OFERTA_CAIXA_MAE:
            return (
              qtde *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3
            );

          default:
            return 0;
        }
      }
      case POR_PESO_FIXO: {
        switch (tipoOferta) {
          case TIPO_UNIDADE_OFERTA_UN:
            return qtde;
          case TIPO_UNIDADE_OFERTA_CAIXA:
            return qtde * produto.unidades_caixa_nivel_1;

          case TIPO_UNIDADE_OFERTA_CAIXETA:
            return (
              qtde *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2
            );

          case TIPO_UNIDADE_OFERTA_CAIXA_MAE:
            return (
              qtde *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3
            );

          default:
            return 0;
        }
      }
      case POR_PESO_VARIAVEL: {
        return 0;
      }
      case POR_PESO_VARIAVEL_SEM_UNIDADE: {
        switch (tipoOferta) {
          case TIPO_UNIDADE_OFERTA_KG:
            return qtde;
          case TIPO_UNIDADE_OFERTA_CAIXA:
            return qtde * produto.peso_caixa_nivel_1;

          case TIPO_UNIDADE_OFERTA_CAIXETA:
            return (
              qtde * produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2
            );

          case TIPO_UNIDADE_OFERTA_CAIXA_MAE:
            return (
              qtde *
              produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3
            );

          default:
            return 0;
        }
      }
      default:
        return 0;
    }
  }
  return 0;
}

function getTotalUsado(produto, liberacoes) {
  let total = 0;

  for (let i = 0; i < liberacoes.length; i++) {
    const liberacao = liberacoes[i];
    if (
      liberacao.status ||
      (!liberacao.status && liberacao.transacao_especial_id)
    ) {
      total += getQtdeEfetivaCalculado(
        produto,
        liberacao.tipo_unidade,
        liberacao.escalonadas[0].quantidade,
      );
    }
  }

  return total;
}

function getNivelCaixaQtde(produto) {
  if (produto.unidades_caixa_nivel_3 > 0) {
    return 3;
  }

  if (produto.unidades_caixa_nivel_2 > 0) {
    return 2;
  }

  if (
    produto.unidades_caixa_nivel_1 > 0 ||
    produto.peso_caixa_nivel_1 > ZERO_APROXIMADO
  ) {
    return 1;
  }

  return 0;
}

function getOptionsCaixaria(produto) {
  if (produto) {
    const tipo = produto.unidade.tipo;
    switch (tipo) {
      case POR_UNIDADE: {
        const nivel = getNivelCaixaQtde(produto);
        switch (nivel) {
          case 0:
            return [
              {
                label: 'Un',
                value: 0,
              },
            ];
          case 1:
            return [
              {
                label: 'Un',
                value: 0,
              },
              {
                label: 'Caixa Nível 1',
                value: 1,
              },
            ];
          case 2:
            return [
              {
                label: 'Un',
                value: 0,
              },
              {
                label: 'Caixa Nível 1',
                value: 1,
              },
              {
                label: 'Caixa Nível 2',
                value: 2,
              },
            ];
          case 3:
            return [
              {
                label: 'Un',
                value: 0,
              },
              {
                label: 'Caixa Nível 1',
                value: 1,
              },
              {
                label: 'Caixa Nível 2',
                value: 2,
              },
              {
                label: 'Caixa Nível 3',
                value: 3,
              },
            ];
          default:
            return [];
        }
      }
      case POR_PESO_FIXO: {
        const nivel = getNivelCaixaQtde(produto);
        switch (nivel) {
          case 0:
            return [
              {
                label: 'Un',
                value: 0,
              },
            ];
          case 1:
            return [
              {
                label: 'Un',
                value: 0,
              },
              {
                label: 'Caixa Nível 1',
                value: 1,
              },
            ];
          case 2:
            return [
              {
                label: 'Un',
                value: 0,
              },
              {
                label: 'Caixa Nível 1',
                value: 1,
              },
              {
                label: 'Caixa Nível 2',
                value: 2,
              },
            ];
          case 3:
            return [
              {
                label: 'Un',
                value: 0,
              },
              {
                label: 'Caixa Nível 1',
                value: 1,
              },
              {
                label: 'Caixa Nível 2',
                value: 2,
              },
              {
                label: 'Caixa Nível 3',
                value: 3,
              },
            ];
          default:
            return [];
        }
      }
      case POR_PESO_VARIAVEL:
        return [
          {
            label: 'Kg',
            value: 0,
          },
        ];
      case POR_PESO_VARIAVEL_SEM_UNIDADE: {
        const nivel = getNivelCaixaQtde(produto);
        switch (nivel) {
          case 0:
            return [
              {
                label: 'Kg',
                value: 0,
              },
            ];
          case 1:
            return [
              {
                label: 'Kg',
                value: 0,
              },
              {
                label: 'Caixa Nível 1',
                value: 1,
              },
            ];
          case 2:
            return [
              {
                label: 'Kg',
                value: 0,
              },
              {
                label: 'Caixa Nível 1',
                value: 1,
              },
              {
                label: 'Caixa Nível 2',
                value: 2,
              },
            ];
          case 3:
            return [
              {
                label: 'Kg',
                value: 0,
              },
              {
                label: 'Caixa Nível 1',
                value: 1,
              },
              {
                label: 'Caixa Nível 2',
                value: 2,
              },
              {
                label: 'Caixa Nível 3',
                value: 3,
              },
            ];
          default:
            return [];
        }
      }
      default:
        return [];
    }
  }
  return [];
}

function getQtdeEfeitva(produto, nivel, qtde) {
  if (produto) {
    const tipo = produto.unidade.tipo;
    switch (tipo) {
      case POR_UNIDADE: {
        switch (nivel) {
          case 0:
            return qtde;
          case 1:
            return qtde * produto.unidades_caixa_nivel_1;

          case 2:
            return (
              qtde *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2
            );

          case 3:
            return (
              qtde *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3
            );

          default:
            return 0;
        }
      }
      case POR_PESO_FIXO: {
        switch (nivel) {
          case 0:
            return qtde;
          case 1:
            return qtde * produto.unidades_caixa_nivel_1;

          case 2:
            return (
              qtde *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2
            );

          case 3:
            return (
              qtde *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3
            );

          default:
            return 0;
        }
      }
      case POR_PESO_VARIAVEL: {
        return 0;
      }
      case POR_PESO_VARIAVEL_SEM_UNIDADE: {
        switch (nivel) {
          case 0:
            return qtde;
          case 1:
            return qtde * produto.peso_caixa_nivel_1;

          case 2:
            return (
              qtde * produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2
            );

          case 3:
            return (
              qtde *
              produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3
            );

          default:
            return 0;
        }
      }
      default:
        return 0;
    }
  }
  return 0;
}

function getDadosInput(produto, nivel) {
  if (produto) {
    const tipo = produto.unidade.tipo;
    switch (tipo) {
      case POR_UNIDADE: {
        switch (nivel) {
          case 0:
            return {
              isTipoInteger: true,
              label: 'Qtde (Un)',
              totalCaixas: 0,
            };
          case 1:
            return {
              isTipoInteger: true,
              label: `Qtde (Caixas Nível 1 (${produto.unidades_caixa_nivel_1} Un))`,
              totalCaixas: 0,
            };

          case 2:
            return {
              isTipoInteger: true,
              label: `Qtde (Caixas Nível 2 (${
                produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2
              } Un))`,
              totalCaixas: 0,
            };

          case 3:
            return {
              isTipoInteger: true,
              label: `Qtde (Caixas Nível 3 (${
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              } Un))`,
              totalCaixas: 0,
            };

          default:
            return {
              isTipoInteger: true,
              label: '',
              totalCaixas: 0,
            };
        }
      }
      case POR_PESO_FIXO: {
        switch (nivel) {
          case 0:
            return {
              isTipoInteger: true,
              label: 'Qtde (Un)',
              totalCaixas: 0,
            };
          case 1:
            return {
              isTipoInteger: true,
              label: `Qtde (Caixas Nível 1 (${produto.unidades_caixa_nivel_1} Un))`,
              totalCaixas: 0,
            };

          case 2:
            return {
              isTipoInteger: true,
              label: `Qtde (Caixas Nível 2 (${
                produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2
              } Un))`,
              totalCaixas: 0,
            };

          case 3:
            return {
              isTipoInteger: true,
              label: `Qtde (Caixas Nível 3 (${
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              } Un))`,
              totalCaixas: 0,
            };

          default:
            return {
              isTipoInteger: true,
              label: '',
              totalCaixas: 0,
            };
        }
      }
      case POR_PESO_VARIAVEL: {
        return {
          isTipoInteger: true,
          label: '',
          totalCaixas: 0,
        };
      }
      case POR_PESO_VARIAVEL_SEM_UNIDADE: {
        switch (nivel) {
          case 0:
            return {
              isTipoInteger: false,
              label: `Qtde (Kg)`,
              totalCaixas: 0,
            };
          case 1:
            return {
              isTipoInteger: true,
              label: `Qtde (Caixas Nível 1 (${formatPeso(
                produto.peso_caixa_nivel_1,
              )}))`,
              totalCaixas: 0,
            };

          case 2:
            return {
              isTipoInteger: true,
              label: `Qtde (Caixas Nível 2 (${formatPeso(
                produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2,
              )}))`,
              totalCaixas: 0,
            };

          case 3:
            return {
              isTipoInteger: true,
              label: `Qtde (Caixas Nível 3 (${formatPeso(
                produto.peso_caixa_nivel_1 *
                  produto.unidades_caixa_nivel_2 *
                  produto.unidades_caixa_nivel_3,
              )}))`,
              totalCaixas: 0,
            };

          default:
            return {
              isTipoInteger: true,
              label: '',
              totalCaixas: 0,
            };
        }
      }
      default:
        return {
          isTipoInteger: true,
          label: '',
          totalCaixas: 0,
        };
    }
  }
  return {
    isTipoInteger: true,
    label: '',
    totalCaixas: 0,
  };
}

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState(DEFAULT_ID);

  const [carregando, setCarregando] = useState(false);

  const classes = useStyles({ cor: 'black' });
  const [produto, setProduto] = useState(null);
  const [qtde, setQtde] = useState(0);
  const [data, setData] = useState(new Date());
  const [verbaUnitaria, setVerbaUnitaria] = useState(0);
  const [qtdeUsada, setQtdeUsada] = useState(0);

  ///
  const [nivelCaixaria, setNivelCaixaria] = useState(0);
  ///

  const isTipoUnidade = produto
    ? produto.unidade
      ? produto.unidade.tipo === POR_UNIDADE ||
        produto.unidade.tipo === POR_PESO_FIXO
      : true
    : true;

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function reiniciar() {
    setCarregando(false);
    setId(DEFAULT_ID);
    setProduto(null);
    setQtde(0);
    setData(new Date());
    setVerbaUnitaria(0);
    setQtdeUsada(0);
    setNivelCaixaria(0);
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'verbas_produtos',
        ['id', 'asc'],
        { id: [id] },
        [],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        setProduto(data.data[0].produto);
        setData(data.data[0].data);
        setQtdeUsada(
          getTotalUsado(data.data[0].produto, data.data[0].liberacoes),
        );
        //setQtde(data.data[0].qtde);
        setQtde(
          data.data[0].nivel_caixaria
            ? data.data[0].unidades_virtuais
            : data.data[0].qtde,
        );
        setVerbaUnitaria(data.data[0].verba_unitaria);
        //setQtdeUsada(0);
        setNivelCaixaria(data.data[0].nivel_caixaria);
        setCarregando(false);
      } else {
        throw 'Esta verba não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    try {
      if (id <= 0) {
        await createAPI('verbas_produtos', {
          produto_id: produto ? produto.id : null,
          qtde: getQtdeEfeitva(produto, nivelCaixaria, qtde),
          criador_id: getUserId(),
          data,
          verba_unitaria: verbaUnitaria,
          verba_total: verbaTotal,
          nivel_caixaria: nivelCaixaria,
          unidades_caixa_nivel_1: produto ? produto.unidades_caixa_nivel_1 : 0,
          unidades_caixa_nivel_2: produto ? produto.unidades_caixa_nivel_2 : 0,
          unidades_caixa_nivel_3: produto ? produto.unidades_caixa_nivel_3 : 0,
          peso_caixa_nivel_1: produto ? produto.peso_caixa_nivel_1 : 0,
          unidades_virtuais: nivelCaixaria ? qtde : 0,
        });
        setCarregando(false);
        enqueueSnackbar('Verba criada com sucesso!', {
          variant: 'success',
        });
      } else {
        await updateAPI('verbas_produtos', id, {
          produto_id: produto ? produto.id : null,
          qtde: getQtdeEfeitva(produto, nivelCaixaria, qtde),
          atualizador_id: getUserId(),
          data,
          verba_unitaria: verbaUnitaria,
          verba_total: verbaTotal,
          nivel_caixaria: nivelCaixaria,
          unidades_caixa_nivel_1: produto ? produto.unidades_caixa_nivel_1 : 0,
          unidades_caixa_nivel_2: produto ? produto.unidades_caixa_nivel_2 : 0,
          unidades_caixa_nivel_3: produto ? produto.unidades_caixa_nivel_3 : 0,
          peso_caixa_nivel_1: produto ? produto.peso_caixa_nivel_1 : 0,
          unidades_virtuais: nivelCaixaria ? qtde : 0,
        });
        setCarregando(false);
        enqueueSnackbar('Verba atualizada com sucesso!', {
          variant: 'success',
        });
      }
      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      reiniciar();
      setOpen(true);
    },
    handleEdit(id) {
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
    },
  }));

  const qtdeEfetiva2 = getQtdeEfeitva(produto, nivelCaixaria, qtde);

  function validateProduto() {
    let error = '';
    if (!produto) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateQtde() {
    let error = '';
    if (qtde <= 0 || Number.isNaN(qtde)) {
      error = 'Deve ter um valor maior que 0!';
    } else if (id > 0) {
      if (qtdeEfetiva2 < qtdeUsada) {
        error = `O valor da qtde atual ${
          isTipoUnidade
            ? round10(qtdeEfetiva2)
            : formatPeso(round10(qtdeEfetiva2, -3))
        } deve ser maior ou igual a ${
          isTipoUnidade
            ? round10(qtdeUsada)
            : formatPeso(round10(qtdeUsada, -3))
        }, pois essa quantidade já foi usada nas liberações!`;
      }
    }
    return error;
  }

  function validateVerbaUnitaria() {
    let error = '';
    if (verbaUnitaria <= 0 || Number.isNaN(verbaUnitaria)) {
      error = 'Deve ter um valor maior que 0!';
    }
    return error;
  }

  function getErros() {
    const errosOld = ['', ''];
    errosOld[0] = validateProduto();
    errosOld[1] = validateQtde();
    errosOld[2] = validateVerbaUnitaria();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  function getDadosProduto() {
    if (produto) {
      return {
        nome: produto.nome,
        precoVenda: produto.preco_venda,
      };
    }
    return {
      nome: '',
      precoVenda: 0,
    };
  }

  const { nome, precoVenda } = getDadosProduto();

  function getTotalVerba(tipoUnidade) {
    switch (tipoUnidade) {
      case POR_UNIDADE:
        return verbaUnitaria * qtdeEfetiva2;
      case POR_PESO_FIXO:
        return (
          verbaUnitaria * qtdeEfetiva2 * (produto ? produto.peso_medio : 1)
        );
      case POR_PESO_VARIAVEL_SEM_UNIDADE:
        return verbaUnitaria * qtdeEfetiva2;
      default:
        return 0;
    }
  }

  const verbaTotal = getTotalVerba(produto ? produto.unidade.tipo : '');

  async function verificaVerbaExistente(value) {
    if (value) {
      const data = await getListAllAPI(
        'verbas_produtos',
        ['id', 'asc'],
        { status: STATUS_ATIVO, produto_id: value.id },
        [],
      );
      if (data.data.length > 0) {
        setProduto(null);
        enqueueSnackbar('Este produto já contém uma verba em atividade!', {
          variant: 'error',
        });
      } else {
        setProduto(value);
      }
    } else {
      setProduto(null);
      enqueueSnackbar('Erro ao selecionar o produto!', {
        variant: 'error',
      });
    }
  }

  const optionsTipoUnidadeOferta = getOptionsCaixaria(produto);

  const { isTipoInteger, label, totalCaixas } = getDadosInput(
    produto,
    nivelCaixaria,
  );

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{
          paper: classes.rootDialogoSmall,
        }}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: SECONDARY_COLOR, color: 'white' }}
        >
          {id < 0 ? `Nova Verba de Produto` : `Editar Verba de Produto`}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '20px 15px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              {id <= 0 ? (
                <AutoCompleteRemoto
                  name="produto"
                  handleEnter={() => {}}
                  label="Produto"
                  handleKey={() => {}}
                  value={produto}
                  onChange={(value) => verificaVerbaExistente(value)}
                  fullWidth
                  toString={false}
                  getOptionSelected={(option, value) =>
                    option.nome === value.nome
                  }
                  getOptionLabel={(option) => `${option.nome}`}
                  filters={{ status: STATUS_ATIVO }}
                  resource="produtos/search/autocomplete"
                  nested={['unidade']}
                  error={erros[0] !== ''}
                  helperText={erros[0]}
                />
              ) : null}
              {produto && (
                <div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                      padding: '10px',
                      border: '1px solid blue',
                      borderRadius: '10px',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: '5px', fontWeight: 'bold' }}>
                        Produto:
                      </div>
                      <div>{nome}</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: '5px', fontWeight: 'bold' }}>
                        Preço venda:
                      </div>
                      <div>{formatMoeda(precoVenda)}</div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginTop: '20px',
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{
                        fontSize: '18px',
                        marginRight: '10px',
                      }}
                    >
                      Tipo Unidade:
                    </Typography>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={nivelCaixaria}
                      onChange={(event) => setNivelCaixaria(event.target.value)}
                    >
                      {optionsTipoUnidadeOferta.map(({ label, value }) => (
                        <MenuItem value={value}>{label}</MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                    }}
                  >
                    {/*<PrecoInput
                      name="verba_unitaria"
                      handleEnter={() => {}}
                      label="Verba Unitária"
                      handleKey={() => {}}
                      value={
                        Number.isNaN(verbaUnitaria) ? '' : String(verbaUnitaria)
                      }
                      onChange={(value) => {
                        setVerbaUnitaria(parseFloat(value.target.value));
                      }}
                      fullWidth
                      error={erros[2] !== ''}
                      helperText={erros[2]}
                      disabled={id > 0}
                    />*/}
                    <PrecoInput2
                      name="verba_unitaria"
                      handleEnter={() => {}}
                      label="Verba Unitária"
                      handleKey={() => {}}
                      value={verbaUnitaria}
                      onChange={(value) => {
                        setVerbaUnitaria(value.target.value);
                      }}
                      fullWidth
                      error={erros[2] !== ''}
                      helperText={erros[2]}
                      disabled={id > 0}
                    />
                    <PrecoInput
                      name="total"
                      handleEnter={() => {}}
                      label="Verba Total"
                      handleKey={() => {}}
                      value={Number.isNaN(verbaTotal) ? '' : String(verbaTotal)}
                      onChange={(value) => {}}
                      fullWidth
                      //variant="standard"
                      disabled
                    />
                  </div>
                  <div>
                    {isTipoInteger ? (
                      <IntegerInput
                        handleEnter={() => {}}
                        label={label}
                        handleKey={() => {}}
                        value={Number.isNaN(qtde) ? '' : String(qtde)}
                        onChange={(value) =>
                          setQtde(parseInt(value.target.value, 10))
                        }
                        fullWidth
                        error={erros[1] !== ''}
                        helperText={erros[1]}
                      />
                    ) : (
                      <WeightInput
                        handleEnter={() => {}}
                        label={label}
                        handleKey={() => {}}
                        /*value={Number.isNaN(qtde) ? '' : String(qtde)}
                        onChange={(value) =>
                          setQtde(parseFloat(value.target.value))
                        }*/
                        value={qtde}
                        onChange={(value) => setQtde(value.target.value)}
                        fullWidth
                        error={erros[1] !== ''}
                        helperText={erros[1]}
                      />
                    )}
                  </div>
                  <div
                    style={{
                      marginTop: '10px',
                    }}
                  >
                    <DateInputBonito
                      showTodayButton
                      disablePast
                      todayLabel="Hoje"
                      name="data"
                      handleEnter={() => {}}
                      label="Validade"
                      handleKey={() => {}}
                      value={data}
                      onChange={(value) => {
                        setData(value);
                      }}
                      format="dd MMMM yyyy"
                      fullWidth
                      variant="outlined"
                      inputVariant="outlined"
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            //padding: '0px 15px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className={classes.wrapper}>
            <Button
              onClick={handleSalvar}
              classes={{
                root: classes.btnAtions,
              }}
              //style={{ marginLeft: '30px' }}
              disabled={erroExistente}
              variant="contained"
              color="primary"
              fullWidth
            >
              Salvar
            </Button>
            {carregando && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
