const Validation = (values) => {
  const errors = {};
  if (!values.nome) errors.nome = 'Este campo é obrigatório';
  else if (values.nome.length > 80)
    errors.nome = 'Número de caracteres maior que o permitido (máximo 80)';
  if (values.preco_venda < 0 || Number.isNaN(values.preco_venda))
    errors.preco_venda = 'Este campo é obrigatório';
  if (values.preco_revenda < 0 || Number.isNaN(values.preco_revenda))
    errors.preco_revenda = 'Este campo é obrigatório';
  return errors;
};

export default Validation;
