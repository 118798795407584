import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import { getListAllAPI, api } from '../../../services';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, textAlign = 'left', ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5" style={{ fontWeight: 'bold', textAlign }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: 'black' }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#00796b',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#00796b',
      },
    },
  },
})(TextField);

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [cartao, setCartao] = useState(null);
  const [nomeCartaoLocal, setNomeCartaoLocal] = useState('');
  const classes = useStyles();

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'cartoes_credito',
        ['id', 'asc'],
        { id },
        [],
      );
      if (data.data.length > 0) {
        setCartao(data.data[0]);
      } else {
        throw 'Este cartão de crédito não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function handleSalvar() {
    try {
      await api.put(`/desativar_cartao_credito_permanentemente/${cartao.id}`);
      enqueueSnackbar(
        'Cartão de crédito excluido permanentemente com sucesso!',
        {
          variant: 'success',
        },
      );
      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      getDataResource(id);
      setOpen(true);
    },
  }));

  function getDadosCartao() {
    if (cartao) {
      return {
        nomeCartao: cartao.nome,
      };
    }
    return {
      nomeCartao: '',
    };
  }

  const { nomeCartao } = getDadosCartao();

  const podeDeletar = nomeCartao === nomeCartaoLocal;

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ color: 'black' }}
          textAlign="center"
        >
          Deletar cartão de crédito
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span
                style={{
                  marginBottom: '8px',
                  color: 'rgba(0, 0, 0, 0.54)',
                  fontWeight: 400,
                }}
              >
                Você tem certeza que deseja deletar o cartão
              </span>
              <span
                style={{
                  marginBottom: '8px',
                  color: 'rgba(0, 0, 0, 0.54)',
                  fontWeight: 'bold',
                  marginLeft: '5px',
                  marginRight: '5px',
                }}
              >
                {nomeCartao}
              </span>
              <span
                style={{
                  marginBottom: '8px',
                  color: 'rgba(0, 0, 0, 0.54)',
                  fontWeight: 400,
                }}
              >
                ?
              </span>
            </div>
            <p
              style={{
                marginBottom: '8px',
                color: 'rgba(0, 0, 0, 0.54)',
                fontWeight: 400,
              }}
            >
              Confirme o nome do cartão:
            </p>
            <CssTextField
              id="standard-basic"
              label=""
              placeholder="ex: Meu Cartão"
              fullWidth
              value={nomeCartaoLocal}
              onChange={(e) => setNomeCartaoLocal(e.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={handleSalvar}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
            variant="contained"
            style={{ backgroundColor: 'white', color: '#00796b' }}
            color="primary"
          >
            CANCELAR
          </Button>
          <div className={classes.wrapper}>
            <Button
              onClick={handleSalvar}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="contained"
              // style={{ backgroundColor: '#00796b', color: 'white' }}
              color="primary"
              disabled={!podeDeletar}
            >
              DELETAR
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
