import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

const useStyles = makeStyles((theme) => ({
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
  },
  cartao: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#00796b',
    marginLeft: '10px',
  },
}));

const StatusField = (props) => {
  const classes = useStyles();
  const record = useRecordContext();
  const value = record.record ? record.record.despesa.status : false;
  const isCartao = record ? record.record.despesa.fatura_id : false;
  return (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
      <div
        style={{ fontSize: '17px', fontWeight: 'bold', marginRight: '10px' }}
      >
        Status:
      </div>
      <div style={{ display: 'flex' }}>
        {value ? (
          <Avatar className={classes.confirmado}>
            <CheckIcon style={{ fontSize: 15 }} />
          </Avatar>
        ) : (
          <Avatar className={classes.pendente}>
            <PriorityHighIcon style={{ fontSize: 15 }} />
          </Avatar>
        )}
        {isCartao && (
          <Avatar className={classes.cartao}>
            <CreditCardIcon style={{ fontSize: 15 }} />
          </Avatar>
        )}
      </div>
    </div>
  );
};



export default StatusField;
