import React, { useState, useRef, useEffect } from 'react';
import { Title } from 'react-admin';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import AssignmentIcon from '@material-ui/icons/Assignment';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { AutoCompleteRemoto } from '../../../Components';
import { api, getListAllAPI } from '../../../services';
import {
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  POR_UNIDADE,
  STATUS_ATIVO,
} from '../../../utils';
import DialogoPDF from './DialogoPDF';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '20px',
  },
  rowHead: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  rowHead2: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  rowResumo: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  negativo: {
    backgroundColor: '#ffc7cf',
  },
  positivo: {
    backgroundColor: '#cbf5d0',
  },
}));

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(true);
  const classes = useRowStyles();
  const classes2 = useStyles();

  return (
    <>
      <Box
        className={classes.root}
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#dce3de',
        }}
        boxShadow={2}
      >
        <Box>
          <IconButton
            aria-label="expand row"
            // size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Box>
        <Box style={{ fontSize: '20px' }}>{`${row.nome} - ${row.funcao}`}</Box>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box margin={1}>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow className={classes2.rowHead2}>
                <TableCell className={classes2.rowHead2}>Fardamento</TableCell>
                <TableCell className={classes2.rowHead2} align="right">
                  Tamanho
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row.fardamentos.map((historyRow) => (
                <TableRow key={historyRow.nome}>
                  <TableCell component="th" scope="row">
                    {`${historyRow.nome} (${historyRow.tamanho})`}
                  </TableCell>
                  <TableCell align="right">{historyRow.tamanho}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Collapse>
    </>
  );
}

export default function CollapsibleTable() {
  const [data, setData] = useState([]);
  const classes = useStyles();
  const refDialogoInventario = useRef(null);
  const [colaborador, setColaborador] = useState(null);

  function handleClick() {
    if (refDialogoInventario.current) {
      refDialogoInventario.current.handleOpen();
    }
  }

  function formatDados(dados) {
    return dados.map((item) => ({
      nome: item.nome,
      funcao: item.funcao.nome,
      fardamentos: item.fardamentos.map((fard) => ({
        nome: fard.nome,
        tamanho: fard.tamanho ? fard.tamanho.nome : '',
      })),
    }));
  }

  useEffect(() => {
    async function getData(colaborador) {
      const data = await getListAllAPI(
        'colaboradores',
        ['id', 'asc'],
        colaborador ? { id: [colaborador.id] } : { status: STATUS_ATIVO },
        [
          'funcao',
          // 'locais',
          'fardamentos.tamanho',
          // 'qualidades',
          // 'defeitos',
          // 'cidade.estado',
          /// 'filhos',
        ],
      );
      setData(formatDados(data.data));
    }

    getData(colaborador);
  }, [colaborador]);

  return (
    <>
      <Title title="Relatório Fardamentos Colaborador" />
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ flex: 1 }}>
            <AutoCompleteRemoto
              handleEnter={() => {}}
              resource="colaboradores"
              nested={[]}
              filters={{
                status: STATUS_ATIVO,
              }}
              label="Colaborador"
              handleKey={() => {}}
              value={colaborador}
              onChange={(value) => setColaborador(value)}
              /* error={!colaborador}
              helperText={
                colaborador ? '' : 'É necessário especificar o colaborador'
              } */
              toString={false}
              fullWidth
              getOptionSelected={(option, value) => option.nome === value.nome}
              getOptionLabel={(option) => `${option.nome}`}
            />
          </div>
          <div style={{ margin: '0 0 0 10px' }}>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={handleClick}
              disabled={data.length <= 0}
              size="medium"
            >
              <AssignmentIcon />
            </IconButton>
          </div>
        </div>

        {/* {data.map((item, index) => (
          <Box
            style={{
              padding: '20px',
              background: 'rgb(179, 179, 179)',
              margin: '20px 0',
            }}
            boxShadow={4}
          >
            <Box
              style={{
                textAlign: 'center',
                marginBottom: '20px',
                fontSize: '30px',
              }}
            >
              {`${item.nome.toUpperCase()}`}
            </Box>
            <Box>
              <Row key={item.nome} row={item} />
            </Box>
          </Box>
        ))} */}

        {data.map((item, index) => (
          <Row key={item.nome} row={item} />
        ))}
      </div>
      <DialogoPDF
        dados={data}
        ref={refDialogoInventario}
        colaborador={colaborador}
      />
    </>
  );
}
