import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CloseIcon from '@material-ui/icons/Close';
import TodayIcon from '@material-ui/icons/Today';
import Rating from '@material-ui/lab/Rating';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  TextInput,
  IntegerInput,
  AutoCompleteRemoto,
  DateInput,
  CpfInput,
  TelefoneInput,
  CnpjInput,
  CepInput,
  IEInput,
  PrecoInput,
  DateInputBonito,
} from '../../../Components';
import { createAPI, updateAPI, getListAllAPI } from '../../../services';
import {
  formatCNPJ,
  formatCPF,
  formatCEP,
  formatIE,
  formatMoeda,
  formatTelefone,
  STATUS_ATIVO,
  formatPorcentagem,
  formatPreco,
} from '../../../utils';

const optionsCfop = {
  5102: '5102 - Venda de mercadoria de terceiros',
  5405: '5405 - Venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita ao regime de substituição tributária, na condição de contribuinte substituído',
};

const optionsCstIcms = {
  '00': '00 - Tributada integralmente',
  20: '20 - Tributação com redução de base de cálculo',
  40: '40 - Tributação Isenta',
  41: '41 - Tributação Não tributada',
  50: '50 - Tributação Suspensão',
  60: '60 - Tributação ICMS cobrado anteriormente por substituição tributária',
};

const optionsCstPis = {
  '01':
    '01 - Operação Tributável (base de cálculo = valor da operação alíquota normal (cumulativo/não cumulativo))',
  '02':
    '02 - Operação Tributável (base de cálculo = valor da operação (alíquota diferenciada))',
  '04': '04 - Operação Tributável (tributação monofásica (alíquota zero))',
  '05': '05 - Operação Tributável (Substituição Tributária)',
  '06': '06 - Operação Tributável (alíquota zero)',
  '07': '07 - Operação Isenta da Contribuição',
  '08': '08 - Operação Sem Incidência da Contribuição',
  '09': '09 - Operação com Suspensão da Contribuição',
};

const optionsCstCofins = {
  '01':
    '01 - Operação Tributável (base de cálculo = valor da operação alíquota normal (cumulativo/não cumulativo))',
  '02':
    '02 - Operação Tributável (base de cálculo = valor da operação (alíquota diferenciada))',
  '04': '04 - Operação Tributável (tributação monofásica (alíquota zero))',
  '05': '05 - Operação Tributável (Substituição Tributária)',
  '06': '06 - Operação Tributável (alíquota zero)',
  '07': '07 - Operação Isenta da Contribuição',
  '08': '08 - Operação Sem Incidência da Contribuição',
  '09': '09 - Operação com Suspensão da Contribuição',
};

const optionsOrigIcms = {
  0: '0 - Nacional, exceto as indicadas nos códigos 3, 4, 5 e 8;',
  1: '1 - Estrangeira - Importação direta, exceto a indicada no código 6',
  2: '2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7',
  3: '3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%',
  4: '4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes',
  5: '5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%',
  6: '6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX e gás natura',
  7: '7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante lista CAMEX e gás natural',
  8: '8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%',
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DEFAULT_ID = -1;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function getDadosImpostosComputados(dados) {
  const {
    cstIcmsEntrada,
    cstIcmsSaida,
    cstPisEntrada,
    cstPisSaida,
    cstCofinsEntrada,
    cstCofinsSaida,
    cest,
  } = dados;

  return {
    isCasoEspecialPisCredito: cstPisEntrada ? cstPisEntrada !== '01' : false,
    isCasoEspecialPisDebito: cstPisSaida ? cstPisSaida !== '01' : false,
    isCasoEspecialCofinsCredito: cstCofinsEntrada
      ? cstCofinsEntrada !== '01'
      : false,
    isCasoEspecialCofinsDebito: cstCofinsSaida
      ? cstCofinsSaida !== '01'
      : false,
    isCasoEspecialIpiCredito: false,
    isCasoEspecialIpiDebito: false,
    isCasoEspecialIcmsNormalCredito: cstIcmsEntrada
      ? cstIcmsEntrada !== '00'
      : false,
    isCasoEspecialIcmsNormalDebito: cstIcmsSaida
      ? cstIcmsSaida !== '00'
      : false,
  };
}

function FieldIMposto({ label, value }) {
  return (
    <div
      style={{
        display: 'flex',
        gap: '10px',
        fontSize: '20px',
      }}
    >
      <span
        style={{
          fontWeight: 'bold',
        }}
      >{`${label}:`}</span>
      <span>{`${value}`}</span>
    </div>
  );
}

function getLabelOption(value, options) {
  if (!value) return '-';
  const v1 = options[value];
  return v1 ? v1 : '-';
}

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [id, setId] = useState(DEFAULT_ID);

  const [carregando, setCarregando] = useState(false);

  const [value, setValue] = React.useState(0);

  ///////////////////////////////////////

  /*   const [cstIcmsEntrada2, setCstIcmsEntrada] = useState('');
  const [cstIcmsSaida2, setCstIcmsSaida] = useState('');
  const [cstPisEntrada, setCstPisEntrada] = useState('');
  const [cstPisSaida, setCstPisSaida] = useState('');
  const [cstCofinsEntrada, setCstCofinsEntrada] = useState('');
  const [cstCofinsSaida, setCstCofinsSaida] = useState('');
  const [origIcms, setOrigIcms] = useState('');

  const [fcp, setFcp] = useState(0);

  const [pautaFiscalIcms, setPautaFiscalIcms] = useState(0);
  const [rbc, setRbc] = useState(0);

  const [pisCredito, setPisCredito] = useState(0);
  const [pisDebito, setPisDebito] = useState(0);
  const [cofinsCredito, setCofinsCredito] = useState(0);
  const [cofinsDebito, setCofinsDebito] = useState(0);
  const [icmsNormalCredito, setIcmsNormalCredito] = useState(0);
  const [icmsNormalDebito, setIcmsNormalDebito] = useState(0);
  const [icmsFronteira, setIcmsFonteira] = useState(0);
  const [icmsAntecipacao, setIcmsAntecipacao] = useState(0);
  const [
    isCasoEspecialIcmsFronteira,
    setIsCasoEspecialIcmsFronteira,
  ] = useState(false);
  const [
    isCasoEspecialIcmsAntecipacao,
    setIsCasoEspecialIcmsAntecipacao,
  ] = useState(false);
  const [mva, setMva] = useState(0); */
  const [configs, setConfigs] = useState(null);

  const [nome, setNome] = useState('');
  const [setor, setSetor] = useState(null);

  /*   const cstIcmsEntrada = cstIcmsEntrada2;
  const cstIcmsSaida = cstIcmsSaida2;

  const {
    isCasoEspecialCofinsCredito,
    isCasoEspecialCofinsDebito,
    isCasoEspecialIcmsNormalCredito,
    isCasoEspecialIcmsNormalDebito,
    isCasoEspecialPisCredito,
    isCasoEspecialPisDebito,
  } = getDadosImpostosComputados({
    cstIcmsEntrada,
    cstIcmsSaida,
    cstPisEntrada,
    cstPisSaida,
    cstCofinsEntrada,
    cstCofinsSaida,
  }); */

  ///////////////////////////////////////

  const [impostos, setImpostos] = useState({
    cfop: '',
    rbc: 0,
    ncm: '',
    cst_pis_entrada: '',
    cst_pis_saida: '',
    cst_cofins_entrada: '',
    cst_cofins_saida: '',
    cest: '',
    fcp: 0,
    orig: '',
    pauta_fiscal: 0,
    cst_icms_entrada: '',
    cst_icms_saida: '',
    mva: 0,
    icms_fronteira: 0,
    is_caso_especial_icms_fronteira: false,
    icms_antecipacao: 0,
    is_caso_especial_icms_antecipacao: false,
  });

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'categorias',
        ['id', 'asc'],
        { id: [id] },
        ['setor'],
      );

      const dataConfig = await getListAllAPI(
        'config',
        ['id', 'asc'],
        { id: 1 },
        ['cidade.estado'],
      );

      if (data.data.length > 0) {
        setConfigs(data.data[0]);
        setId(data.data[0].id);
        setNome(data.data[0].nome);
        setValue(0);
        setCarregando(false);
        //
        setConfigs(dataConfig.data[0]);
        setId(data.data[0].id);
        setSetor(data.data[0].setor);
        /*         setRbc(data.data[0].rbc);
        setCstPisEntrada(data.data[0].cst_pis_entrada);
        setCstPisSaida(data.data[0].cst_pis_saida);
        setCstCofinsEntrada(data.data[0].cst_cofins_entrada);
        setCstCofinsSaida(data.data[0].cst_cofins_saida);
        setMva(data.data[0].mva); */
        setCarregando(false);
        setImpostos(formatImpostos(data.data[0], dataConfig.data[0]));
        /*         setPisCredito(data.data[0].pis_credito);
        setPisDebito(data.data[0].pis_debito);
        setCofinsCredito(data.data[0].cofins_credito);
        setCofinsDebito(data.data[0].cofins_debito);
        setIcmsNormalCredito(data.data[0].icms_normal_credito);
        setIcmsNormalDebito(data.data[0].icms_normal_debito);
        setIcmsFonteira(data.data[0].icms_fronteira);
        setIsCasoEspecialIcmsFronteira(
          data.data[0].is_caso_especial_icms_fronteira,
        );
        setIcmsAntecipacao(data.data[0].icms_antecipacao);
        setIsCasoEspecialIcmsAntecipacao(
          data.data[0].is_caso_especial_icms_antecipacao,
        );
        setOrigIcms(data.data[0].orig);
        setFcp(data.data[0].fcp ? data.data[0].fcp : 0);
        setPautaFiscalIcms(
          data.data[0].pauta_fiscal ? data.data[0].pauta_fiscal : 0,
        );

        setCstIcmsEntrada(data.data[0].cst_icms_entrada);
        setCstIcmsSaida(data.data[0].cst_icms_saida); */
      } else {
        throw 'Esta Categoria não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  function formatImpostos(dados, config) {
    let dadosFormatados = {};

    if (dados) {
      dadosFormatados = {
        cfop: getLabelOption(dados.cfop, optionsCfop),
        orig: getLabelOption(dados.orig, optionsOrigIcms),
        rbc: `${formatPorcentagem(dados.rbc)}`,
        mva: `${formatPorcentagem(dados.mva)}`,
        fcp: `${formatPorcentagem(dados.fcp)}`,
        pauta_fiscal: `${formatPreco(dados.pauta_fiscal)}`,
        ncm: dados.itemNcm
          ? `${dados.ncm || '-'} (${dados.itemNcm.descricao})`
          : dados.ncm || '-',
        cst_pis_entrada: getLabelOption(dados.cst_pis_entrada, optionsCstPis),
        cst_pis_saida: getLabelOption(dados.cst_pis_saida, optionsCstPis),
        cst_cofins_entrada: getLabelOption(
          dados.cst_cofins_entrada,
          optionsCstCofins,
        ),
        cst_cofins_saida: getLabelOption(
          dados.cst_cofins_saida,
          optionsCstCofins,
        ),
        cst_icms_entrada: getLabelOption(
          dados.cst_icms_entrada,
          optionsCstIcms,
        ),
        cst_icms_saida: getLabelOption(dados.cst_icms_saida, optionsCstIcms),
        cest: dados.cest || '-',
        //
        pis_credito: dados.is_caso_especial_pis_credito
          ? `${formatPorcentagem(dados.pis_credito)}`
          : `${formatPorcentagem(config.pis)}`,
        //is_caso_especial_pis_credito: dados.is_caso_especial_pis_credito,
        pis_debito: dados.is_caso_especial_pis_debito
          ? `${formatPorcentagem(dados.pis_debito)}`
          : `${formatPorcentagem(config.pis)}`,
        //is_caso_especial_pis_debito: dados.is_caso_especial_pis_debito,
        cofins_credito: dados.is_caso_especial_cofins_credito
          ? `${formatPorcentagem(dados.cofins_credito)}`
          : `${formatPorcentagem(config.cofins)}`,
        //is_caso_especial_cofins_credito: dados.is_caso_especial_cofins_credito,
        cofins_debito: dados.is_caso_especial_cofins_debito
          ? `${formatPorcentagem(dados.cofins_debito)}`
          : `${formatPorcentagem(config.cofins)}`,
        //is_caso_especial_cofins_debito: dados.is_caso_especial_cofins_debito,
        ipi_credito: dados.is_caso_especial_ipi_credito
          ? `${formatPorcentagem(dados.ipi_credito)}`
          : `${formatPorcentagem(config.ipi)}`,
        //is_caso_especial_ipi_credito: dados.is_caso_especial_ipi_credito,
        ipi_debito: dados.is_caso_especial_ipi_debito
          ? `${formatPorcentagem(dados.ipi_debito)}`
          : `${formatPorcentagem(config.ipi)}`,
        //is_caso_especial_ipi_debito: dados.is_caso_especial_ipi_debito,
        icms_normal_credito: dados.is_caso_especial_icms_normal_credito
          ? `${formatPorcentagem(dados.icms_normal_credito)}`
          : `${formatPorcentagem(config.cidade.estado.icms_ai_compra)}`,
        //is_caso_especial_icms_normal_credito: dados.is_caso_especial_icms_normal_credito,
        icms_normal_debito: dados.is_caso_especial_icms_normal_debito
          ? `${formatPorcentagem(dados.icms_normal_debito)}`
          : `${formatPorcentagem(config.cidade.estado.icms_ai_compra)}`,
        //is_caso_especial_icms_normal_debito: dados.is_caso_especial_icms_normal_debito,
        icms_fronteira: dados.is_caso_especial_icms_fronteira
          ? `${formatPorcentagem(dados.icms_fronteira)} % (Caso Especial)`
          : ` - `,
        //is_caso_especial_icms_fronteira: dados.is_caso_especial_icms_fronteira,
        icms_antecipacao: dados.is_caso_especial_icms_antecipacao
          ? `${formatPorcentagem(dados.icms_antecipacao)} % (Caso Especial)`
          : `${formatPorcentagem(config.icms_antecipacao)} % (Padrão)`,
        //is_caso_especial_icms_antecipacao: dados.is_caso_especial_icms_antecipacao,
        cst_icms_entrada_original: dados.cst_icms_entrada,
      };
    } else {
      dadosFormatados = {};
    }

    return dadosFormatados;
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
    },
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        fullScreen
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          {`Detalhes Categoria de Produto "${nome}"`}
        </DialogTitle>
        <DialogContent dividers>
          <div style={{ height: '100%' }}>
            <Box
              display={{ xs: 'block', sm: 'flex' }}
              flexDirection="column"
              marginBottom="30px"
              height="100%"
            >
              {carregando ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '300px',
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <Box p="1em">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                  >
                    <Tab label="Geral" {...a11yProps(0)} />
                    <Tab label="Impostos" {...a11yProps(1)} />
                  </Tabs>
                  <Box display="flex">
                    <Box flex={2} mr="1em">
                      <TabPanel value={value} index={0}>
                        <Box
                          flex={1}
                          style={{
                            padding: '10px',
                            border: '3px solid red',
                            borderRadius: '10px',
                            marginBottom: '20px',
                          }}
                        >
                          <Typography
                            variant="h6"
                            gutterBottom
                            style={{
                              color: 'red',
                              fontWeight: 'bold',
                              textAlign: 'center',
                              textDecoration: 'underline',
                            }}
                          >
                            Geral
                          </Typography>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '10px',
                            }}
                          >
                            <FieldIMposto label="Descrição" value={nome} />
                            <FieldIMposto
                              label="Setor"
                              value={setor ? setor.nome : '-'}
                            />
                          </div>
                        </Box>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <Box display="flex">
                          <Box
                            flex={2}
                            mr="1em"
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '20px',
                            }}
                          >
                            {/* <Box
                              flex={1}
                              style={{
                                padding: '10px',
                                border: '3px solid red',
                                borderRadius: '10px',
                              }}
                            >
                              <Typography
                                variant="h6"
                                gutterBottom
                                style={{
                                  color: 'red',
                                  fontWeight: 'bold',
                                  textAlign: 'center',
                                  textDecoration: 'underline',
                                }}
                              >
                                Geral
                              </Typography>

                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: '10px',
                                }}
                              >
                                <FieldIMposto
                                  label="NCM"
                                  value={impostos.ncm}
                                />
                                <FieldIMposto
                                  label="Origem da Mercadoria"
                                  value={impostos.orig}
                                />
                                <FieldIMposto
                                  label="CEST"
                                  value={impostos.cest}
                                />
                                <FieldIMposto
                                  label="CFOP"
                                  value={impostos.cfop}
                                />
                              </div>
                            </Box> */}
                            <Box
                              flex={1}
                              style={{
                                padding: '10px',
                                border: '3px solid red',
                                borderRadius: '10px',
                              }}
                            >
                              <Typography
                                variant="h6"
                                gutterBottom
                                style={{
                                  color: 'red',
                                  fontWeight: 'bold',
                                  textAlign: 'center',
                                  textDecoration: 'underline',
                                }}
                              >
                                ICMS
                              </Typography>

                              <Box
                                style={{
                                  padding: '10px',
                                  border: '3px solid blue',
                                  borderRadius: '10px',
                                  marginBottom: '10px',
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  style={{
                                    color: 'blue',
                                    fontWeight: 'bold',
                                    //textAlign: 'center',
                                    textDecoration: 'underline',
                                  }}
                                >
                                  ENTRADA
                                </Typography>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px',
                                  }}
                                >
                                  <FieldIMposto
                                    label="CST"
                                    value={impostos.cst_icms_entrada}
                                  />
                                  <FieldIMposto
                                    label="Aliquota"
                                    value={impostos.icms_normal_credito}
                                  />
                                </div>
                              </Box>
                              <Box
                                style={{
                                  padding: '10px',
                                  border: '3px solid blue',
                                  borderRadius: '10px',
                                  marginBottom: '10px',
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  style={{
                                    color: 'blue',
                                    fontWeight: 'bold',
                                    //textAlign: 'center',
                                    textDecoration: 'underline',
                                  }}
                                >
                                  SAÍDA
                                </Typography>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px',
                                  }}
                                >
                                  <FieldIMposto
                                    label="CST"
                                    value={impostos.cst_icms_saida}
                                  />
                                  <FieldIMposto
                                    label="Aliquota"
                                    value={impostos.icms_normal_debito}
                                  />
                                  {impostos.cst_icms_entrada_original ===
                                  '60' ? (
                                    <FieldIMposto
                                      label="RBC (Redução da Base de Cálculo)"
                                      value={impostos.rbc}
                                    />
                                  ) : null}
                                  {impostos.cst_icms_entrada_original ===
                                  '60' ? (
                                    <FieldIMposto
                                      label="MVA (Margem de Valor Agregado)"
                                      value={impostos.mva}
                                    />
                                  ) : null}
                                  {impostos.cst_icms_entrada_original ===
                                  '60' ? (
                                    <FieldIMposto
                                      label="FCP (Fundo de Combate a Pobreza)"
                                      value={impostos.fcp}
                                    />
                                  ) : null}
                                  {impostos.cst_icms_entrada_original ===
                                  '60' ? (
                                    <FieldIMposto
                                      label="Pauta Fiscal"
                                      value={impostos.pauta_fiscal}
                                    />
                                  ) : null}
                                </div>
                              </Box>
                            </Box>

                            <Box
                              flex={1}
                              style={{
                                padding: '10px',
                                border: '3px solid red',
                                borderRadius: '10px',
                              }}
                            >
                              <Typography
                                variant="h6"
                                gutterBottom
                                style={{
                                  color: 'red',
                                  fontWeight: 'bold',
                                  textAlign: 'center',
                                  textDecoration: 'underline',
                                }}
                              >
                                PIS
                              </Typography>

                              <Box
                                style={{
                                  padding: '10px',
                                  border: '3px solid blue',
                                  borderRadius: '10px',
                                  marginBottom: '10px',
                                }}
                                //gutterBottom
                              >
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  style={{
                                    color: 'blue',
                                    fontWeight: 'bold',
                                    //textAlign: 'center',
                                    textDecoration: 'underline',
                                  }}
                                >
                                  ENTRADA
                                </Typography>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px',
                                  }}
                                >
                                  <FieldIMposto
                                    label="CST"
                                    value={impostos.cst_pis_entrada}
                                  />
                                  <FieldIMposto
                                    label="Aliquota"
                                    value={impostos.pis_credito}
                                  />
                                </div>
                              </Box>
                              <Box
                                style={{
                                  padding: '10px',
                                  border: '3px solid blue',
                                  borderRadius: '10px',
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  style={{
                                    color: 'blue',
                                    fontWeight: 'bold',
                                    //textAlign: 'center',
                                    textDecoration: 'underline',
                                  }}
                                >
                                  SAÍDA
                                </Typography>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px',
                                  }}
                                >
                                  <FieldIMposto
                                    label="CST"
                                    value={impostos.cst_pis_saida}
                                  />
                                  <FieldIMposto
                                    label="Aliquota"
                                    value={impostos.pis_debito}
                                  />
                                </div>
                              </Box>
                            </Box>

                            <Box
                              flex={1}
                              style={{
                                padding: '10px',
                                border: '3px solid red',
                                borderRadius: '10px',
                              }}
                            >
                              <Typography
                                variant="h6"
                                gutterBottom
                                style={{
                                  color: 'red',
                                  fontWeight: 'bold',
                                  textAlign: 'center',
                                  textDecoration: 'underline',
                                }}
                              >
                                COFINS
                              </Typography>

                              <Box
                                style={{
                                  padding: '10px',
                                  border: '3px solid blue',
                                  borderRadius: '10px',
                                  marginBottom: '10px',
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  style={{
                                    color: 'blue',
                                    fontWeight: 'bold',
                                    //textAlign: 'center',
                                    textDecoration: 'underline',
                                  }}
                                >
                                  ENTRADA
                                </Typography>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px',
                                  }}
                                >
                                  <FieldIMposto
                                    label="CST"
                                    value={impostos.cst_cofins_entrada}
                                  />
                                  <FieldIMposto
                                    label="Aliquota"
                                    value={impostos.cofins_credito}
                                  />
                                </div>
                              </Box>

                              <Box
                                style={{
                                  padding: '10px',
                                  border: '3px solid blue',
                                  borderRadius: '10px',
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  style={{
                                    color: 'blue',
                                    fontWeight: 'bold',
                                    //textAlign: 'center',
                                    textDecoration: 'underline',
                                  }}
                                >
                                  SAÍDA
                                </Typography>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px',
                                  }}
                                >
                                  <FieldIMposto
                                    label="CST"
                                    value={impostos.cst_cofins_saida}
                                  />
                                  <FieldIMposto
                                    label="Aliquota"
                                    value={impostos.cofins_debito}
                                  />
                                </div>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </TabPanel>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
