import React, { cloneElement } from 'react';
import { TopToolbar, sanitizeListRestProps, Button } from 'react-admin';

import AddIcon from '@material-ui/icons/Add';
import LoopIcon from '@material-ui/icons/Loop';

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  enabledCreate,
  handleCriar = () => {},
  ...rest
}) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {filters &&
      cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <Button onClick={handleCriar} label="Novo">
      <AddIcon />
    </Button>
  </TopToolbar>
);

export default ListActions;
