import moment from 'moment';

function formatDateDDMMYYYY(date, separator = '/') {
  return date
    ? moment(new Date(date)).format(`DD${separator}MM${separator}YYYY`)
    : '-';
}

function formatDateDDMMYYYYHHmm(
  date,
  separator1 = '/',
  separator2 = '-',
  separator3 = ':',
) {
  return date
    ? moment(new Date(date)).format(
        `DD${separator1}MM${separator1}YYYY ${separator2} HH${separator3}mm`,
      )
    : '-';
}

function formatDateHHmm(date, separator = ':') {
  return date ? moment(new Date(date)).format(`HH${separator}mm`) : '-';
}

export { formatDateDDMMYYYY, formatDateHHmm, formatDateDDMMYYYYHHmm };
