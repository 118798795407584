import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';

import { Box, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import CheckIcon from '@material-ui/icons/Check';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { SwitchInput } from '../../../Components';
import {
  getLabelTipo,
  getValorFormatado,
} from '../../../Components/Dialogos/DialogoFornecedor/utils';
import { getListAllAPI } from '../../../services';
import {
  formatTelefone,
  formatCNPJ,
  formatCPF,
  formatCEP,
  formatIE,
  SECONDARY_COLOR,
  formatMoeda,
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  formatPeso,
  getLabelNivelCaixaria,
  disablePeso,
  getTotal2,
  POR_PESO_FIXO,
} from '../../../utils';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: SECONDARY_COLOR,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
    cursor: 'pointer',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
    cursor: 'pointer',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const DEFAULT_ID = -1;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DialogoShowVenda = forwardRef(({ handleClose = () => {} }, ref) => {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [id, setId] = useState(DEFAULT_ID);
  const [data, setData] = useState('-');
  const [numeroVenda, setNumeroVenda] = useState('');
  const [cliente, setCliente] = useState({
    nome: '-',
    nomeFantasia: '-',
    razaoSocial: '-',
    cpf: '-',
    cnpj: '-',
    bairro: '-',
    cidade: '-',
    logradouro: '-',
    categoria: '-',
  });
  const [valorTotal, setValorTotal] = useState(0);
  const [itens, setItens] = useState([]);
  const [itensExcluidos, setItensExcluidos] = useState([]);
  const [parcelas, setParcelas] = useState([]);
  const [frentista, setFrentista] = useState('');
  const [caixa, setCaixa] = useState('');

  const [itensOriginal, setItensOriginal] = useState([]);

  const [valorTotalOriginal, setValorTotalOriginal] = useState(0);

  const [carregando, setCarregando] = useState(false);
  const [value, setValue] = React.useState(0);

  const [isVendaOriginal, setIsVendaOriginal] = useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  function formatData(data) {
    return data ? moment(new Date(data)).format('DD/MM/YYYY - HH:mm') : '-';
  }

  function formatNumeroVenda(data) {
    return data || '-';
  }

  function formatCliente(data) {
    let nome = '-';
    let razaoSocial = '-';
    let nomeFantasia = '-';
    let cpf = '-';
    let cnpj = '-';
    let bairro = '-';
    let cidade = '-';
    let logradouro = '-';
    let categoria = '-';

    if (data) {
      nome = data.nome ? data.nome : '-';
      razaoSocial = data.razao_social ? data.razao_social : '-';
      nomeFantasia = data.nome_fantasia ? data.nome_fantasia : '-';
      cpf = data.cpf ? formatCPF(data.cpf) : '-';
      cnpj = data.cnpj ? formatCNPJ(data.cnpj) : '-';
      bairro = data.bairro ? data.bairro : '-';
      cidade = data.cidade
        ? `${data.cidade.nome} - ${data.cidade.estado.sigla}`
        : '-';
      logradouro = data.logradouro ? data.logradouro : '-';
      categoria = data.categoria ? data.categoria.nome : '-';
    }

    return {
      nome,
      razaoSocial,
      nomeFantasia,
      cpf,
      cnpj,
      bairro,
      cidade,
      logradouro,
      categoria,
    };
  }

  function formatValorTotal(data) {
    return formatMoeda(data);
  }

  function formatItens(data) {
    const vetor = [];
    for (let i = 0; i < data.length; i++) {
      const unidades = data[i].unidades_caixa_aparente
        ? data[i].unidades_caixa_aparente
        : data[i].unidades_caixa
        ? data[i].unidades / data[i].unidades_caixa
        : data[i].unidades;
      const unitario = data[i].unidades_caixa_aparente
        ? data[i].preco_venda_caixa
        : data[i].unidades_caixa
        ? data[i].preco_venda_caixa
        : data[i].preco_venda;

      const sufixoUnidade = data[i].unidades_caixa_aparente
        ? data[i].peso_caixa > 0
          ? `${getLabelNivelCaixaria(data[i].nivel_caixaria)}(${formatPeso(
              data[i].peso_caixa,
            )} kg)`
          : `${getLabelNivelCaixaria(data[i].nivel_caixaria)}(${
              data[i].unidades_caixa
            } un)`
        : data[i].unidades_caixa
        ? `cx(${data[i].unidades_caixa} un)`
        : '';

      const isNivel = data[i].unidades_caixa_aparente
        ? true
        : !!data[i].unidades_caixa;

      const total = getTotal2(data[i].peso, unidades, unitario, {
        ...data[i].produto,
        nivelCaixa: isNivel,
      });

      vetor.push({
        id: data[i].id,
        produto: `${data[i].produto.nome}${
          sufixoUnidade !== '' ? ` - ${sufixoUnidade}` : ''
        }`,
        unidades: unidades || '-',
        peso: disablePeso(data[i].produto) ? formatPeso(data[i].peso) : '-',
        unitario: `${formatMoeda(unitario)}`,
        total: formatMoeda(total),
        totalValue: total,
      });
    }
    return vetor;
  }

  function formatParcelas(data) {
    const vetor = [];
    for (let i = 0; i < data.length; i++) {
      const parcela = data[i];
      const tipoPagamento = data[i].tipo_pagamento;
      const conta = data[i]
        ? data[i].tipo_pagamento
          ? data[i].receita
            ? data[i].receita.conta
              ? data[i].receita.conta.nome
              : ''
            : ''
          : ''
        : '';
      const responsavel = data[i]
        ? data[i].autorizador
          ? data[i].autorizador.username
          : '-'
        : '-';

      vetor.push({
        id: parcela.id,
        meioPagamento: tipoPagamento.nome,
        valor: formatMoeda(
          tipoPagamento.id === 1 ? parcela.valor_real : parcela.valor,
        ),
        dataPagamento: moment(new Date(parcela.data_pagamento)).format(
          'DD/MM/YYYY',
        ),
        valorRecebido:
          tipoPagamento.id === 1
            ? formatMoeda(parcela.troco + parcela.valor_real)
            : '-',
        troco: tipoPagamento.id === 1 ? formatMoeda(parcela.troco) : '-',
        conta,
        responsavel,
      });
    }
    return vetor;
  }

  function dadosRestantesVenda(itensVenda, itensDevolucao) {
    let newItensVenda = [];

    for (let i = 0; i < itensVenda.length; i++) {
      const item = itensVenda[i];
      const itensDevolucoesLocal = itensDevolucao.filter(
        (item2) => item2.item_venda_id === item.id,
      );

      const newItem = { ...item };

      for (let j = 0; j < itensDevolucoesLocal.length; j++) {
        const itemDevolucao = itensDevolucoesLocal[j];
        newItem.unidades -= itemDevolucao.unidades;
        newItem.peso -= itemDevolucao.peso;
        newItem.unidades_caixa_aparente -=
          itemDevolucao.unidades_caixa_aparente;
      }

      newItensVenda.push(newItem);
    }

    newItensVenda = newItensVenda.filter((item3) => {
      switch (item3.produto.unidade.tipo) {
        case POR_UNIDADE: {
          if (item3.nivel_caixaria) {
            return item3.unidades_caixa_aparente > 0.0099;
          }

          return item3.unidades > 0.0099;
        }
        case POR_PESO_FIXO: {
          if (item3.nivel_caixaria) {
            return item3.unidades_caixa_aparente > 0.0099;
          }

          return item3.unidades > 0.0099;
        }
        case POR_PESO_VARIAVEL_SEM_UNIDADE: {
          if (item3.nivel_caixaria) {
            return item3.unidades_caixa_aparente > 0.0099;
          }

          return item3.peso > 0.0099;
        }
        default:
          return false;
      }
    });

    return newItensVenda;
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI('vendas', ['id', 'asc'], { id: [id] }, [
        'itens.produto.unidade',
        'itens.produto.dadosImposto',
        'itensExcluidos.produto.unidade',
        'parcelas.tipo_pagamento',
        'parcelas.autorizador',
        'parcelas.receita.conta',
        'cliente.cidade.estado',
        'cliente.categoria',
        'sessao.user',
        'sessao.caixa',
        'itensDevolucao',
      ]);
      if (data.data.length > 0) {
        const newItens = formatItens(
          dadosRestantesVenda(data.data[0].itens, data.data[0].itensDevolucao),
        );
        //
        let totalNew = 0;
        for (let i = 0; i < newItens.length; i++) {
          totalNew += newItens[i].totalValue;
        }
        setId(data.data[0].id);
        setData(formatData(data.data[0].data));
        setNumeroVenda(formatNumeroVenda(data.data[0].numero));
        setCliente(formatCliente(data.data[0].cliente));
        setItensExcluidos(formatItens(data.data[0].itensExcluidos));
        setParcelas(formatParcelas(data.data[0].parcelas));
        setFrentista(data.data[0].sessao.user.username);
        setCaixa(data.data[0].sessao.caixa.nome);
        setValue(0);
        setCarregando(false);
        //
        setItens(newItens);
        setItensOriginal(formatItens(data.data[0].itens));
        setValorTotal(formatValorTotal(totalNew));
        setValorTotalOriginal(formatValorTotal(data.data[0].valor));
        setIsVendaOriginal(false);
      } else {
        throw 'Esta venda não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(-1);
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setOpen(true);
      setCarregando(true);
      getDataResource(id);
    },
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen
    >
      <DialogTitle id="form-dialog-title">{`Venda nº ${numeroVenda} - ${data}`}</DialogTitle>
      <DialogContent dividers>
        <div style={{ height: '100%' }}>
          <Box
            display={{ xs: 'block', sm: 'flex' }}
            flexDirection="column"
            marginBottom="30px"
            height="100%"
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '300px',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Box p="1em">
                <SwitchInput
                  name="is_venda_original"
                  handleEnter={() => {}}
                  label="Venda Original"
                  handleKey={() => {}}
                  checked={isVendaOriginal}
                  onChange={(value) => setIsVendaOriginal(value.target.checked)}
                />
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab label="Geral" {...a11yProps(0)} />
                  <Tab label="Cliente" {...a11yProps(1)} />
                  <Tab label="Itens" {...a11yProps(2)} />
                  <Tab label="Itens Removidos" {...a11yProps(3)} />
                  <Tab label="Parcelas" {...a11yProps(4)} />
                </Tabs>
                <Box display="flex">
                  <Box flex={2} mr="1em">
                    <TabPanel value={value} index={0}>
                      <Box
                        display="flex"
                        alignItems="center"
                        mt="20px"
                        fontSize={20}
                      >
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Data:
                          </span>
                          {data}
                        </Box>
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Número Venda:
                          </span>
                          {numeroVenda}
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        mt="20px"
                        fontSize={20}
                      >
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Valor:
                          </span>
                          {isVendaOriginal ? valorTotalOriginal : valorTotal}
                        </Box>
                        <Box flex={1} />
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        mt="20px"
                        fontSize={20}
                      >
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Frentista:
                          </span>
                          {frentista}
                        </Box>
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Caixa:
                          </span>
                          {caixa}
                        </Box>
                      </Box>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Box
                        display="flex"
                        alignItems="center"
                        mt="20px"
                        fontSize={20}
                      >
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Responsável:
                          </span>
                          {cliente.nome}
                        </Box>
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Nome Fantasia:
                          </span>
                          {cliente.nomeFantasia}
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        mt="20px"
                        fontSize={20}
                      >
                        <Box flex={1}>
                          <span
                            style={{
                              fontWeight: 'bold',
                              marginRight: '10px',
                            }}
                          >
                            Razão Social:
                          </span>
                          {cliente.razaoSocial}
                        </Box>
                        <Box flex={1}>
                          <span
                            style={{
                              fontWeight: 'bold',
                              marginRight: '10px',
                            }}
                          >
                            Bairro:
                          </span>
                          {cliente.bairro}
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        mt="20px"
                        fontSize={20}
                      >
                        <Box flex={1}>
                          <span
                            style={{
                              fontWeight: 'bold',
                              marginRight: '10px',
                            }}
                          >
                            CPF:
                          </span>
                          {cliente.cpf}
                        </Box>
                        <Box flex={1}>
                          <span
                            style={{
                              fontWeight: 'bold',
                              marginRight: '10px',
                            }}
                          >
                            Cidade:
                          </span>
                          {cliente.cidade}
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        mt="20px"
                        fontSize={20}
                      >
                        <Box flex={1}>
                          <span
                            style={{
                              fontWeight: 'bold',
                              marginRight: '10px',
                            }}
                          >
                            CNPJ:
                          </span>
                          {cliente.cnpj}
                        </Box>
                        <Box flex={1}>
                          <span
                            style={{
                              fontWeight: 'bold',
                              marginRight: '10px',
                            }}
                          >
                            Logradouro:
                          </span>
                          {cliente.logradouro}
                        </Box>
                      </Box>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <Box mt="1em" />
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Produto</StyledTableCell>
                              <StyledTableCell>Unidades</StyledTableCell>
                              <StyledTableCell>Peso (Kg)</StyledTableCell>
                              <StyledTableCell>Unitário</StyledTableCell>
                              <StyledTableCell>Subtotal</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {isVendaOriginal
                              ? itensOriginal.map((row) => (
                                  <StyledTableRow key={row.id}>
                                    <StyledTableCell>
                                      {row.produto}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.unidades}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.peso}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.unitario}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.total}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))
                              : itens.map((row) => (
                                  <StyledTableRow key={row.id}>
                                    <StyledTableCell>
                                      {row.produto}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.unidades}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.peso}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.unitario}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.total}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <Box mt="1em" />
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Produto</StyledTableCell>
                              <StyledTableCell>Unidades</StyledTableCell>
                              <StyledTableCell>Peso (Kg)</StyledTableCell>
                              <StyledTableCell>Unitário</StyledTableCell>
                              <StyledTableCell>Subtotal</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {itensExcluidos.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>{row.produto}</StyledTableCell>
                                <StyledTableCell>
                                  {row.unidades}
                                </StyledTableCell>
                                <StyledTableCell>{row.peso}</StyledTableCell>
                                <StyledTableCell>
                                  {row.unitario}
                                </StyledTableCell>
                                <StyledTableCell>{row.total}</StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                      <Box mt="1em" />
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>
                                Meio de Pagamento
                              </StyledTableCell>
                              <StyledTableCell>Valor</StyledTableCell>
                              <StyledTableCell>
                                Data de Pagamento
                              </StyledTableCell>
                              <StyledTableCell>Valor Recebido</StyledTableCell>
                              <StyledTableCell>Troco</StyledTableCell>
                              <StyledTableCell>Conta</StyledTableCell>
                              <StyledTableCell>Responsável</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {parcelas.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>
                                  {row.meioPagamento}
                                </StyledTableCell>
                                <StyledTableCell>{row.valor}</StyledTableCell>
                                <StyledTableCell>
                                  {row.dataPagamento}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.valorRecebido}
                                </StyledTableCell>
                                <StyledTableCell>{row.troco}</StyledTableCell>
                                <StyledTableCell>{row.conta}</StyledTableCell>
                                <StyledTableCell>
                                  {row.responsavel}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.wrapper}>
          <Button
            color="primary"
            onClick={() => {
              handleCloseDialog();
            }}
            disabled={carregando}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
          >
            Fechar
          </Button>
          {carregando && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
});

export default DialogoShowVenda;
