import React, { forwardRef } from 'react';
import { MenuItemLink } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { getUsername } from '../../services';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.main,
    height: '50px',
  },
  icon2: {
    color: theme.palette.primary.main,
  },
}));

const UsernameMenuItem = forwardRef((props, ref) => {
  const classes = useStyles();
  return (
    <MenuItemLink
      ref={ref}
      to="/"
      leftIcon={<AccountCircleIcon className={classes.icon2} />}
      primaryText={getUsername()}
      className={classes.icon}
    />
  );
});

export default UsernameMenuItem;
