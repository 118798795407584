import React from 'react';

import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    // flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    marginBottom: '20px',
    // width: 'min-content',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function ChipsArray({ colors }) {
  const classes = useStyles();
  return (
    <Paper component="ul" className={classes.root}>
      {colors.map((data) => (
        <li key={data.limit}>
          <Chip
            label={data.label}
            className={classes.chip}
            style={{ ...data.style }}
          />
        </li>
      ))}
    </Paper>
  );
}
