import React, { useRef, forwardRef } from 'react';

import DialogoCreateImpressao from './DialogoCreateImpressao';
import DialogoProduto from './index3';

const Container = forwardRef(({ handleClose, isImprimir = true }, ref) => {
  const refDialogoImpressao = useRef(null);
  function handleCloseDialogo(id) {
    handleClose(id);
    if (id > 0 && isImprimir) {
      if (refDialogoImpressao.current) {
        refDialogoImpressao.current.handleCreate(id);
      }
    }
  }
  return (
    <>
      <DialogoProduto ref={ref} handleClose={handleCloseDialogo} />
      <DialogoCreateImpressao
        ref={refDialogoImpressao}
        handleClose={() => {}}
      />
    </>
  );
});

export default Container;
