import * as React from 'react';
import { useRecordContext } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DetailsIcon from '@material-ui/icons/Details';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MinimizeIcon from '@material-ui/icons/Minimize';
import PrintIcon from '@material-ui/icons/Print';
import { useSnackbar } from 'notistack';

import { imprimirPacoteMalote } from '../../../services/impressora';

const ActionsField = ({
  handleEdit,
  handleDesmembrar,
  handleShow,
  handleLixeira = () => {},
  ...rest
}) => {
  const record = useRecordContext(rest);
  const { enqueueSnackbar } = useSnackbar();

  async function imprimir(id) {
    if (id > 0) {
      const codigo = await imprimirPacoteMalote(id);
      if (codigo < 0) {
        enqueueSnackbar('Erro ao imprimir a nota!', {
          variant: 'error',
        });
      }
    }
  }
  return (
    <div
      style={{
        marginRight: '0px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Tooltip title="Criar Retirada 100" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleDesmembrar(record)}
        >
          <MinimizeIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Detalhes" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="detalhes"
          size="small"
          onClick={() => handleShow(record)}
        >
          <DetailsIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Editar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="edit"
          size="small"
          onClick={() => handleEdit(record)}
        >
          <EditOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Imprimir" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="imprimir"
          size="small"
          onClick={() => imprimir(record ? record.record.id : -1)}
        >
          <PrintIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title="Mandar para a lixeira"
        placement="bottom"
        aria-label="excluir2"
      >
        <IconButton
          aria-label="excluir"
          size="small"
          onClick={() => handleLixeira(record)}
        >
          <DeleteForeverIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};



export default ActionsField;
