import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DetailsIcon from '@material-ui/icons/Details';
import GroupIcon from '@material-ui/icons/Group';

import { formatMoeda } from '../../../../../utils';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'block',
  },
  sectionDesktop: {
    display: 'flex',
    gap: '10px',
  },
}));

export default function PrimarySearchAppBar({
  disabledBack = false,
  disabledForward = false,
  onClick,
  CANCELAR_COMPRA_ACTION,
  FECHAR_COMPRA_ACTION,
  NOVO_FORNECEDOR_ACTION,
  DETALHES_ACTION,
  numeroCompra,
  idEdit,
  subtotal,
  resto,
  totalParcelas,
  disabledFornecedor = false,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleMenuClose();
          onClick(NOVO_FORNECEDOR_ACTION);
        }}
      >
        Novo
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar>
          <Tooltip title="Cancelar">
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              size="small"
              disabled={disabledBack}
              onClick={() => onClick(CANCELAR_COMPRA_ACTION)}
            >
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Prosseguir">
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              size="small"
              disabled={disabledForward}
              onClick={() => onClick(FECHAR_COMPRA_ACTION)}
            >
              <ArrowForwardIcon />
            </IconButton>
          </Tooltip>
          <Typography className={classes.title} variant="h6" noWrap />
          <div className={classes.grow}>
            {idEdit <= 0 ? (
              <div>
                <span
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  NOVA COMPRA
                </span>
                <span
                  style={{
                    margin: '0 10px',
                  }}
                >
                  |
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    marginRight: '7px',
                    color: 'red',
                  }}
                >
                  SUBTOTAL:
                </span>
                <span>{formatMoeda(subtotal)}</span>
                <span
                  style={{
                    margin: '0 10px',
                  }}
                >
                  |
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    marginRight: '7px',
                  }}
                >
                  TOTAL DE PARCELAS:
                </span>
                <span>{formatMoeda(totalParcelas)}</span>
                <span
                  style={{
                    margin: '0 10px',
                  }}
                >
                  |
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    marginRight: '7px',
                    color: 'red',
                  }}
                >
                  RESTANTE:
                </span>
                <span>{formatMoeda(resto)}</span>
              </div>
            ) : (
              <div>
                <span
                  style={{
                    fontWeight: 'bold',
                    marginRight: '7px',
                    color: 'red',
                  }}
                >
                  COMPRA Nº:
                </span>
                <span>{numeroCompra}</span>
                <span
                  style={{
                    margin: '0 10px',
                  }}
                >
                  |
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    marginRight: '7px',
                    color: 'red',
                  }}
                >
                  SUBTOTAL:
                </span>
                <span>{formatMoeda(subtotal)}</span>
                <span
                  style={{
                    margin: '0 10px',
                  }}
                >
                  |
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    marginRight: '7px',
                  }}
                >
                  TOTAL DE PARCELAS:
                </span>
                <span>{formatMoeda(totalParcelas)}</span>
                <span
                  style={{
                    margin: '0 10px',
                  }}
                >
                  |
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    marginRight: '7px',
                    color: 'red',
                  }}
                >
                  RESTANTE:
                </span>
                <span>{formatMoeda(resto)}</span>
              </div>
            )}
          </div>
          <div className={classes.sectionDesktop}>
            <Tooltip title="Detalhes">
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                color="inherit"
                onClick={() => onClick(DETALHES_ACTION)}
                size="small"
              >
                <DetailsIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Fornecedores">
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                color="inherit"
                onClick={handleProfileMenuOpen}
                size="small"
                disabled={disabledFornecedor}
              >
                <GroupIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </div>
  );
}
