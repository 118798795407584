import React, { useImperativeHandle, forwardRef, useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useSnackbar } from 'notistack';

import { api, getUserId } from '../../../services';
import { STATUS_ATIVO } from '../../../utils/constants';
import { AutoCompleteRemoto } from '../../Inputs';

const FormDialog = forwardRef(({ handlePermitido }, ref) => {
  const [open, setOpen] = React.useState(false);
  const [responsavel, setResponsavel] = useState(null);
  const [senhaResponsavel, setSenhaResponsavel] = useState('');
  const [showPasswordResponsavel, setShowPasswordResponsavel] = useState(false);
  const [conferente, setConferente] = useState(null);
  const [senhaConferente, setSenhaConferente] = useState('');
  const [showPasswordConferente, setShowPasswordConferente] = useState(false);
  const [cliente, setCliente] = useState(null);
  const [senhaCliente, setSenhaCliente] = useState('');
  const [showPasswordCliente, setShowPasswordCliente] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };

  async function handleSubmit() {
    try {
      const response = await api.post('permissao_venda_parcial', {
        responsavel_id: responsavel.id,
        senhaResponsavel,
        conferente_id: conferente.id,
        senhaConferente,
        cliente_id: cliente.id,
        senhaCliente,
      });
      const data = response.data;
      if (!data.is_authorized) throw data.message;
      enqueueSnackbar('Retirada autorizada e registrada com sucesso!!!', {
        variant: 'success',
      });
      handleClose();
      handlePermitido(responsavel.id, conferente.id);
    } catch (error) {
      enqueueSnackbar(`${error}`, {
        variant: 'error',
      });
    }
  }

  async function getData(cliente_id) {
    try {
      const responseCliente = await api.get(`/clientes/${cliente_id}`);
      const responseResponsavel = await api.get(`/users/${getUserId()}`);

      setCliente(responseCliente.data);
      setResponsavel(responseResponsavel.data);
      setConferente(null);

      setSenhaCliente('');
      setSenhaResponsavel('');
      setSenhaConferente('');

      setShowPasswordCliente(false);
      setShowPasswordResponsavel(false);
      setShowPasswordConferente(false);
    } catch (error) {
      handleClose();
      enqueueSnackbar(`${error}`, {
        variant: 'error',
      });
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(cliente_id) {
      setOpen(true);
      getData(cliente_id);
    },
  }));

  function validateUser(user) {
    if (!user) return 'Usuário obrigatório!';
    return '';
  }

  function validateSenha(senha) {
    if (senha.length <= 0 || senha.length > 30) return 'Usuário obrigatório!';
    return '';
  }

  function getErros() {
    const errosOld = ['', '', '', '', '', ''];
    errosOld[0] = validateUser(responsavel);
    errosOld[1] = validateSenha(senhaResponsavel);
    errosOld[2] = validateUser(conferente);
    errosOld[3] = validateSenha(senhaConferente);
    errosOld[4] = validateUser(cliente);
    errosOld[5] = validateSenha(senhaCliente);
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          Autorização Retirada Mercadoria
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="h6"
            style={{
              textAlign: 'center',
              marginTop: '20px',
            }}
          >
            Responsável
          </Typography>
          <Box display="flex" alignItems="center">
            <Box flex={1} marginRight="20px">
              <AutoCompleteRemoto
                name=""
                handleEnter={() => {}}
                resource="users"
                nested={[]}
                filters={{ status: STATUS_ATIVO }}
                label=""
                handleKey={() => {}}
                value={responsavel}
                onChange={(value) => setResponsavel(value)}
                error={erros[0] !== ''}
                helperText={erros[0]}
                toString={false}
                fullWidth
                campo="username"
                getOptionSelected={(option, value) =>
                  option.username === value.username
                }
                getOptionLabel={(option) => `${option.username}`}
                disabled
                /* inputProps={{
                  style: {
                    margin: 0,
                  },
                }} */
              />
            </Box>
            <Box flex={1} marginTop="16px" marginBottom="8px">
              <OutlinedInput
                id="outlined-adornment-password2"
                type={showPasswordResponsavel ? 'text' : 'password'}
                value={senhaResponsavel}
                onChange={(event) => setSenhaResponsavel(event.target.value)}
                fullWidth
                error={erros[1] !== ''}
                helperText={erros[1]}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {}}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPasswordResponsavel ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
          </Box>
          <Typography
            variant="h6"
            style={{
              textAlign: 'center',
              marginTop: '20px',
            }}
          >
            Conferente
          </Typography>
          <Box display="flex" alignItems="center">
            <Box flex={1} marginRight="20px">
              <AutoCompleteRemoto
                name=""
                handleEnter={() => {}}
                resource="users"
                nested={[]}
                filters={{ status: STATUS_ATIVO }}
                label=""
                handleKey={() => {}}
                value={conferente}
                onChange={(value) => setConferente(value)}
                error={erros[2] !== ''}
                helperText={erros[2]}
                toString={false}
                fullWidth
                campo="username"
                getOptionSelected={(option, value) =>
                  option.username === value.username
                }
                getOptionLabel={(option) => `${option.username}`}
                /* disabled */
              />
            </Box>
            <Box flex={1} marginTop="16px" marginBottom="8px">
              <OutlinedInput
                id="outlined-adornment-password2"
                type={showPasswordConferente ? 'text' : 'password'}
                value={senhaConferente}
                onChange={(event) => setSenhaConferente(event.target.value)}
                fullWidth
                error={erros[3] !== ''}
                helperText={erros[3]}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {}}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPasswordConferente ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
          </Box>
          <Typography
            variant="h6"
            style={{
              textAlign: 'center',
              marginTop: '20px',
            }}
          >
            Cliente
          </Typography>
          <Box display="flex" alignItems="center">
            <Box flex={1} marginRight="20px">
              <AutoCompleteRemoto
                name=""
                handleEnter={() => {}}
                resource="clientes"
                nested={[]}
                filters={{}}
                label=""
                handleKey={() => {}}
                value={cliente}
                onChange={(value) => setCliente(value)}
                error={erros[4] !== ''}
                helperText={erros[4]}
                toString={false}
                fullWidth
                getOptionSelected={(option, value) =>
                  option.nome === value.nome
                }
                getOptionLabel={(option) => `${option.nome}`}
                disabled
              />
            </Box>
            <Box flex={1} marginTop="16px" marginBottom="8px">
              <OutlinedInput
                id="outlined-adornment-password2"
                type={showPasswordCliente ? 'text' : 'password'}
                value={senhaCliente}
                onChange={(event) => setSenhaCliente(event.target.value)}
                fullWidth
                label=""
                error={erros[5] !== ''}
                helperText={erros[5]}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {}}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPasswordCliente ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={erroExistente}
          >
            Imprimir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default FormDialog;
