import React, { useImperativeHandle, forwardRef, useState } from 'react';

import { getListAllAPI } from '../../../services';
import { adapterVendaToNota2, adapterCompraToNota2 } from '../../../utils';
import DialogoNotaThermalPrinter from './index';

const NOTA_FEITA = 0;
const NOTA_EM_ANDAMENTO = 1;

const DialogoNota = forwardRef((props, ref) => {
  const [transacao, setTransacao] = useState(null);
  const [boletos, setBoletos] = useState([]);
  const [empresa, setEmpresa] = useState(null);
  const [open, setOpen] = useState(false);
  const [tipoNota, setTipoNota] = useState(NOTA_FEITA);
  const [tipoTransacao, setTipoTransacao] = useState('compra');

  const isDados = () => transacao && empresa;

  async function getVenda(id) {
    const dataVenda = await getListAllAPI('vendas', ['id', 'asc'], { id }, [
      'itens.produto.unidade',
      'cliente.cidade.estado',
      'parcelas.tipoPagamento',
      'parcelas.boleto',
    ]);
    return dataVenda.data[0];
  }

  async function getCompra(id) {
    const dataCompra = await getListAllAPI(
      'compras',
      ['id', 'asc'],
      { id },
      [
        'lotes.produto.unidade',
        'fornecedor.cidade.estado',
        'parcelas.despesa.conta',
        'parcelas.despesa.fatura.cartao',
      ],
      // [],
    );
    return dataCompra.data[0];
  }

  async function getDados(id, dados, type) {
    const config = await getListAllAPI('config', ['id', 'asc'], { id: 1 }, [
      'cidade.estado',
    ]);
    let resourceFormated = null;
    switch (type) {
      case 'venda': {
        let venda = {};
        if (id > 0) {
          venda = await getVenda(id);
        } else {
          venda = dados;
        }
        resourceFormated = adapterVendaToNota2(
          venda,
          config.data[0],
          props.logo,
        );
        break;
      }
      case 'compra': {
        let compra = {};
        if (id > 0) {
          compra = await getCompra(id);
        } else {
          compra = {
            numero: 0,
            data: new Date(),
            fornecedor: dados.cliente,
            lotes: dados.itens,
            parcelas: dados.parcelas,
          };
        }
        resourceFormated = adapterCompraToNota2(
          compra,
          config.data[0],
          props.logo,
        );
        break;
      }
      default:
        break;
    }
    return resourceFormated;
  }

  async function setDados(id, dados, typeResource) {
    const {
      boletos: boletosNew,
      transacao: transacaoNew,
      empresa: empresaNew,
    } = await getDados(id, dados, typeResource);
    setTransacao(transacaoNew);
    setBoletos(boletosNew);
    setEmpresa(empresaNew);
    setTipoTransacao(typeResource);
  }

  async function reiniciar(id, dados, typeResource) {
    setDados(id, dados, typeResource);
    setOpen(true);
    if (id > 0) {
      setTipoNota(NOTA_FEITA);
    } else {
      setTipoNota(NOTA_EM_ANDAMENTO);
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id, typeResource) {
      reiniciar(id, {}, typeResource);
    },
    handleOpenLocal(itens, parcelas, cliente, typeResource) {
      reiniciar(
        -1,
        {
          numero: 0,
          data: new Date(),
          cliente,
          itens,
          parcelas,
        },
        typeResource,
      );
    },
  }));

  const handleClose = () => {
    setOpen(false);
    props.handleClose(tipoNota);
  };

  return (
    <>
      {isDados() && (
        <DialogoNotaThermalPrinter
          boletos={boletos}
          empresa={empresa}
          transacao={transacao}
          open={open}
          handleCloseDialog={handleClose}
          tipoNota={tipoNota}
          tipoTransacao={tipoTransacao}
        />
      )}
    </>
  );
});

export default DialogoNota;
