import { createContext } from 'react';

export const CompraContext = createContext({
  compra: {
    fornecedor: null,
    lotes: [],
    parcelas: [],
    subTotal: 0,
    idEdit: -1,
    numeroNotaFiscal: '',
    data_faturamento: '',
    data_criacao: new Date(),
    loja: {
      nome: 'CARUARU FRIOS',
      value: 'CF',
    },
    repasse_ipi: 0,
    repasse_frete: 0,
    repasse_st: 0,
    repasse_seguros: 0,
    repasse_outras_despesas: 0,
    carregando: true,
    config: null,
    numeroCompra: '-',
    total_repasse_ipi: 0,
    total_repasse_frete: 0,
    total_repasse_st: 0,
    total_repasse_seguros: 0,
    total_repasse_outras_despesas: 0,
    subTotalProdutos: 0,
    isProdutosSomenteFornecedor: true,
    isIncluiInativos: false,
    serieNotaFiscal: '',
    chaveAcessoNotaFiscal: '',
    isImportacaoXml: false,
  },
  dispatch: () => {},
});
