import React from 'react';
import {
  Filter as FilterAdmin,
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

import {
  choicesTipo,
  choicesUniverso,
  choicesStatusLoja,
  choicesTipoHorario,
} from './choices';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <TextInput label="Descrição" source="nome" />
    <SelectInput
      source="tipo"
      choices={choicesTipo}
      label="Tipo"
      allowEmpty={false}
    />
    <SelectInput
      source="universo"
      choices={choicesUniverso}
      label="Universo"
      allowEmpty={false}
    />
    <SelectInput
      source="status_loja"
      label="Status da Loja"
      choices={choicesStatusLoja}
      allowEmpty={false}
    />
    <SelectInput
      source="tipo_horario"
      label="Tipo de Horário"
      choices={choicesTipoHorario}
      allowEmpty={false}
    />
    <ReferenceInput
      fullWidth
      label="Equipe"
      source="equipe_colaborador_id"
      reference="equipes_colaborador"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput>
  </FilterAdmin>
);

export default Filter;
