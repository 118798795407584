import * as React from 'react';
import { useRecordContext } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

function getDataMaximaStatus(data_maxima) {
  const timeDataLimite = new Date(data_maxima).getTime();
  const timeDataAtual = new Date().getTime();

  if (timeDataLimite > timeDataAtual) {
    return true;
  }

  return false;
}

const ActionsField = ({
  handleEdit = () => {},
  handleShow = () => {},
  handleDeletar = () => {},
  handleCriarApartirJornalAnterior = () => {},
  ...rest
}) => {
  const record = useRecordContext(rest);

  const dataMaximaStatus = record
    ? record.record
      ? getDataMaximaStatus(record.record.data_maxima)
      : false
    : false;

  const status = record
    ? record.record
      ? record.record.status
      : false
    : false;

  const statusFinal = dataMaximaStatus && status;

  return (
    <div
      style={{
        marginRight: '0px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Tooltip
        title="Criar novo Jornal de Ofertas com base neste"
        placement="bottom"
        aria-label="editar"
      >
        <IconButton
          aria-label="new"
          size="small"
          onClick={() => handleCriarApartirJornalAnterior(record.record)}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>

      {statusFinal ? (
        <Tooltip title="Editar" placement="bottom" aria-label="editar">
          <IconButton
            aria-label="editar"
            size="small"
            onClick={() => handleEdit(record.record)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      ) : null}

      {statusFinal ? (
        <Tooltip title="Deletar" placement="bottom" aria-label="deletar">
          <IconButton
            aria-label="deletar"
            size="small"
            onClick={() => handleDeletar(record.record)}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      ) : null}

      <Tooltip title="Detalhes" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleShow(record.record)}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ActionsField;
