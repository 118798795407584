const Validation = (values) => {
  const errors = {};
  if (values.unidades < 0 || Number.isNaN(values.unidades))
    errors.unidades = 'Este campo é obrigatório';
  if (values.peso < 0 || Number.isNaN(values.peso))
    errors.peso = 'Este campo é obrigatório';

  return errors;
};

export default Validation;
