import * as React from 'react';
import { useRecordContext } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AlarmAddIcon from '@material-ui/icons/AlarmAdd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DetailsIcon from '@material-ui/icons/Details';
import EditIcon from '@material-ui/icons/Edit';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import PrintIcon from '@material-ui/icons/Print';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useSnackbar } from 'notistack';

import { STATUS_ATIVO, STATUS_DELETADO } from '../../utils';

const ActionsField = ({
  handleAprovacao = () => {},
  handleDetalhes = () => {},
  handleEstenderPrazoPedido = () => {},
  handleDeletar = () => {},
  disabledDeletar = false,
  ...rest
}) => {
  const record = useRecordContext(rest);

  const is_analisado = record
    ? record.record
      ? record.record.is_analisado
      : false
    : false;

  const is_analisado_volta = record
    ? record.record
      ? record.record.is_analisado_volta
      : false
    : false;

  const status = record
    ? record.record
      ? record.record.status === STATUS_ATIVO
      : false
    : false;

  const is_utilizado = record
    ? record.record
      ? record.record.is_utilizado
      : false
    : false;

  return (
    <div
      style={{
        marginRight: '0px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {status && !is_utilizado ? (
        <Tooltip
          title="Estender Prazo por 1h"
          placement="bottom"
          aria-label="estender-oferta"
        >
          <IconButton
            aria-label="estender-oferta"
            size="small"
            onClick={() => handleEstenderPrazoPedido(record.record)}
          >
            <AlarmAddIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      {is_analisado && !is_analisado_volta && !disabledDeletar ? (
        <Tooltip title="Aprovação" placement="bottom" aria-label="add2">
          <IconButton
            aria-label="check"
            size="small"
            onClick={() => handleAprovacao(record.record)}
          >
            <CheckCircleOutlineIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Detalhes" placement="bottom" aria-label="add2">
          <IconButton
            aria-label="check"
            size="small"
            onClick={() => handleDetalhes(record.record)}
          >
            <DetailsIcon />
          </IconButton>
        </Tooltip>
      )}
      {status && !disabledDeletar ? (
        <Tooltip
          title="Excluir Pedido"
          placement="bottom"
          aria-label="excluir2"
        >
          <IconButton
            aria-label="excluir"
            size="small"
            onClick={() => handleDeletar(record.record)}
          >
            <DeleteForeverIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </div>
  );
};

export default ActionsField;
