import * as React from 'react';
import { useRecordContext } from 'react-admin';

function getMeioPagamento(nome) {
  if (nome.includes('Cartão de Crédito')) return 'Crédito';
  if (nome.includes('Cartão de Débito')) return 'Débito';
  if (nome.includes('Voucher')) return 'Voucher';
  return '';
}

const ActionsField = ({ handleEfetivar, ...rest }) => {
  const record = useRecordContext(rest);
  const value = record ? record.record.nome : '';

  return (
    <div
      style={{
        marginRight: '0px',
        width: '120px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {getMeioPagamento(value)}
    </div>
  );
};

export default ActionsField;
