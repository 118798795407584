import { createContext } from 'react';

export const VendaContext = createContext({
  venda: {
    cliente: null,
    itens: [],
    subTotal: 0,
  },
  dispatch: () => {},
});
