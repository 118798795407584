import React, { useRef } from 'react';
import {
  TextField,
  NumberField,
  ListBase,
  ListToolbar,
  Pagination,
  FunctionField,
} from 'react-admin';

import { Card } from '@material-ui/core';

import Datagrid from '../../Components/Datagrid';
import DeleteButton from '../../Components/DeleteButton';
import DialogoDeleteResource from '../../Components/DialogoDeleteResource';
import EditButton from '../../Components/EditButton2';
import Actions from './ActionsList';
import Dialogo from './ContainerDialogoProduto';
import Filter from './Filter';

const MyList = ({ ...props }) => {
  const refDialogoCompra = useRef(null);
  const refDialogoDeleteResource = useRef(null);

  function handleCriar() {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleCreate();
    }
  }
  function handleEdit({ id }) {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleEdit(id);
    }
  }
  function deleteHundle(record) {
    if (refDialogoDeleteResource.current)
      refDialogoDeleteResource.current.handleOpen(record);
  }

  return (
    <>
      <ListToolbar
        filters={props.filters}
        actions={<Actions handleCriar={handleCriar} {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid {...props}>
          <TextField source="ncm" label="NCM" />
          <TextField source="cst" label="CST" />
          <TextField source="cfop" label="CFOP" />
          <EditButton editHundle={handleEdit} />
          <DeleteButton
            label="Excluir"
            deleteHundle={deleteHundle}
            disabled={() => false}
          />
        </Datagrid>
        <TransacoesPagination />
      </Card>
      <Dialogo ref={refDialogoCompra} />
      <DialogoDeleteResource ref={refDialogoDeleteResource} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

function formatPorcentagem(valor) {
  return valor !== null && valor !== undefined
    ? `${valor.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}%`
    : '';
}

const PostPanel = ({ record }) => (
  <div>
    <div style={{ marginTop: '5px' }}>
      <span style={{ fontWeight: 'bold', marginRight: '10px' }}>IPI:</span>
      <span>{`${formatPorcentagem(record.ipi)}`}</span>
    </div>
    <div style={{ marginTop: '5px' }}>
      <span style={{ fontWeight: 'bold', marginRight: '10px' }}>PIS:</span>
      <span>{`${formatPorcentagem(record.pis)}`}</span>
    </div>
    <div style={{ marginTop: '5px' }}>
      <span style={{ fontWeight: 'bold', marginRight: '10px' }}>COFINS:</span>
      <span>{`${formatPorcentagem(record.cofins)}`}</span>
    </div>
    <div style={{ marginTop: '5px' }}>
      <span style={{ fontWeight: 'bold', marginRight: '10px' }}>ICMS AI:</span>
      <span>{`${formatPorcentagem(record.icms_ai)}`}</span>
    </div>
    <div style={{ marginTop: '5px' }}>
      <span style={{ fontWeight: 'bold', marginRight: '10px' }}>ICMS INT:</span>
      <span>{`${formatPorcentagem(record.icms_int)}`}</span>
    </div>
    <div style={{ marginTop: '5px' }}>
      <span style={{ fontWeight: 'bold', marginRight: '10px' }}>DF:</span>
      <span>{`${formatPorcentagem(record.df)}`}</span>
    </div>
    <div style={{ marginTop: '5px' }}>
      <span style={{ fontWeight: 'bold', marginRight: '10px' }}>RBC:</span>
      <span>{`${formatPorcentagem(record.rbc)}`}</span>
    </div>
  </div>
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      expand={<PostPanel />}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Impostos Produtos"
    />
  </>
);

export default List2;
