import React, { Children, isValidElement } from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  defaultToolbar: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
  rightToolbar: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const BodyToolbar = ({ children, alignContent = 'default', ...rest }) => {
  const classes = useStyles();
  function getAlign(align) {
    switch (align) {
      case 'right':
        return classes.rightToolbar;
      default:
        return classes.defaultToolbar;
    }
  }
  return (
    <div className={getAlign(alignContent)}>
      {Children.map(children, (button) =>
        button && isValidElement(button)
          ? React.cloneElement(button, rest)
          : null,
      )}
    </div>
  );
};

export default BodyToolbar;
