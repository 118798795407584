import {
  STATUS_ANDAMENTO_ATIVIDADE_EM_ANDAMENTO,
  STATUS_ANDAMENTO_ATIVIDADE_FINALIZADA,
  AVALIACAO_FEITO_COM_EXCELENCIA,
  AVALIACAO_MALFEITO,
  AVALIACAO_NAO_REALIZADO,
} from '../../utils/constants';

const choicesStatusAndamento = [
  { id: STATUS_ANDAMENTO_ATIVIDADE_EM_ANDAMENTO, name: 'Em Andamento' },
  { id: STATUS_ANDAMENTO_ATIVIDADE_FINALIZADA, name: 'Finalizada' },
];

const choicesAvaliacao = [
  { id: AVALIACAO_FEITO_COM_EXCELENCIA, name: 'Feito com Excelência' },
  { id: AVALIACAO_MALFEITO, name: 'Malfeito' },
  { id: AVALIACAO_NAO_REALIZADO, name: 'Não Realizado' },
  { id: null, name: 'Sem Avaliação' },
];

export { choicesStatusAndamento, choicesAvaliacao };
