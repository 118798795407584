import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

const useStyles = makeStyles((theme) => ({
  root: {
    /* width: theme.spacing(3),
    height: theme.spacing(3), */
  },
}));

const ActionsField = ({
  tipo,
  handleEdit,
  handleDelete,
  handleChangeStatus,
  filtroStatus,
  ...rest
}) => {
  const classes = useStyles();
  const record = useRecordContext(rest);
  // const status = record ? record.record.status : false;
  const status = filtroStatus;

  return (
    <div
      style={{
        marginRight: '0px',
        // width: '120px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {!status ? (
        <Tooltip title="Efetivar" placement="bottom" aria-label="add2">
          <IconButton
            aria-label="check"
            classes={{ root: classes.root }}
            size="small"
            onClick={() => handleChangeStatus(record.record)}
            style={{ color: '#00897B' }}
          >
            <CheckCircleOutlineIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Efetivar" placement="bottom" aria-label="add2">
          <IconButton
            aria-label="check"
            classes={{ root: classes.root }}
            size="small"
            onClick={() => handleChangeStatus(record.record)}
            style={{ color: '#f44336' }}
          >
            <PriorityHighIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Editar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="edit"
          classes={{ root: classes.root }}
          size="small"
          onClick={() => handleEdit(record.record)}
          style={{ color: '#00897B' }}
        >
          <EditOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Deletar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="delete"
          classes={{ root: classes.root }}
          size="small"
          onClick={() => handleDelete(record.record)}
          style={{ color: '#00897B' }}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};



export default ActionsField;
