import React, { useState, useRef, useEffect } from 'react';
import { Title } from 'react-admin';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import AssignmentIcon from '@material-ui/icons/Assignment';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { AutoCompleteRemoto } from '../../../Components';
import { api } from '../../../services';
import {
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  POR_UNIDADE,
  getPrecoUnitarioVerdadeiroCaixa2,
  getEstoqueLabel,
  STATUS_ATIVO,
  formatMoeda,
} from '../../../utils';
import DialogoPDF from './DialogoPDF';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '20px',
  },
  rowHead: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  rowHead2: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  rowResumo: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  negativo: {
    backgroundColor: '#ffc7cf',
  },
  positivo: {
    backgroundColor: '#cbf5d0',
  },
}));

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(true);
  const classes = useRowStyles();
  const classes2 = useStyles();

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.nome}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow className={classes2.rowHead2}>
                    <TableCell className={classes2.rowHead2}>Produto</TableCell>
                    <TableCell className={classes2.rowHead2} align="right">
                      Unidades
                    </TableCell>
                    <TableCell className={classes2.rowHead2} align="right">
                      Peso (Kg)
                    </TableCell>
                    <TableCell className={classes2.rowHead2} align="right">
                      Preço Venda
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.produtos.map((historyRow) => (
                    <TableRow key={historyRow.nome}>
                      <TableCell component="th" scope="row">
                        {historyRow.nome}
                      </TableCell>
                      <TableCell align="right">
                        {historyRow.unidade.tipo ===
                        POR_PESO_VARIAVEL_SEM_UNIDADE
                          ? '-'
                          : getEstoqueLabel(historyRow)}
                      </TableCell>
                      <TableCell align="right">
                        {historyRow.unidade.tipo === POR_UNIDADE
                          ? '-'
                          : getEstoqueLabel(historyRow)}
                      </TableCell>
                      <TableCell align="right">
                        {historyRow.preco_venda > historyRow.preco_oferta ? (
                          <div>
                            <div
                              style={{
                                color: 'red',
                                textDecoration: 'line-through',
                                fontSize: '12px',
                              }}
                            >
                              {formatMoeda(historyRow.preco_venda)}
                            </div>
                            <div
                              style={{
                                backgroundColor: 'yellow',
                                fontWeight: 'bold',
                                fontSize: '15px',
                              }}
                            >
                              {formatMoeda(historyRow.preco_oferta)}
                            </div>
                          </div>
                        ) : (
                          <div>{formatMoeda(historyRow.preco_venda)}</div>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function CollapsibleTable() {
  const [data, setData] = useState([]);
  const classes = useStyles();
  const refDialogoInventario = useRef(null);
  const [local, setLocal] = useState(null);

  function handleClick() {
    if (refDialogoInventario.current) {
      refDialogoInventario.current.handleOpen();
    }
  }

  useEffect(() => {
    async function getData() {
      const data = await api.get(`/relatorio_produtos_locais/${local.id}`);
      const dados = data.data;
      for (let i = 0; i < dados.length; i++) {
        const fornecedor = dados[i];
        for (let j = 0; j < fornecedor.produtos.length; j++) {
          const produto = fornecedor.produtos[j];
          produto.preco_oferta = getPrecoUnitarioVerdadeiroCaixa2(
            { ...produto, nivelCaixa: 0 },
            0,
            0,
          );
        }
      }
      setData(data.data);
    }

    if (local) {
      getData();
    }
  }, [local]);

  return (
    <>
      <Title title="Relatório produtos de um local" />
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ flex: 1 }}>filtro locais</div>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={handleClick}
            disabled={data.length <= 0 || !local}
          >
            <AssignmentIcon />
          </IconButton>
        </div>
        <div>
          <AutoCompleteRemoto
            handleEnter={() => {}}
            resource="locais_setores"
            nested={[]}
            filters={{ status: STATUS_ATIVO }}
            label="Local"
            handleKey={() => {}}
            value={local}
            onChange={(value) => setLocal(value)}
            error={!local}
            helperText={local ? '' : 'É necessário especificar o local'}
            toString={false}
            fullWidth
            getOptionSelected={(option, value) => option.nome === value.nome}
            getOptionLabel={(option) => `${option.nome}`}
          />
        </div>
        <TableContainer component={Paper} className={classes.root}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow className={classes.rowHead}>
                <TableCell />
                <TableCell className={classes.rowHead}>Fornecedor</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <Row key={row.nome} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <DialogoPDF dados={data} ref={refDialogoInventario} local={local} />
    </>
  );
}
