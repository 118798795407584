import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';

import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  DateInput,
  TextInput,
  CpfInput,
  TelefoneInput,
  CnpjInput,
  CepInput,
  IEInput,
  AutoCompleteRemoto,
} from '../../../Components';
import {
  createAPI,
  updateAPI,
  getListAllAPI,
  getUserId,
} from '../../../services';

const FECHAR_VENDA_ACTION = 'F8';
const CANCELAR_VENDA_ACTION = 'F4';
const MUDAR_FIDELIDADE_ACTION = 'F10';

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const RAZAO_SOCIAL_POSITION = 7;
const NOME_FANTASIA_POSITION = 8;
const CNPJ_POSITION = 9;
const IE_POSITION = 10;
const CIDADE_POSITION = 11;
const BAIRRO_POSITION = 12;
const LOGRADOURO_POSITION = 13;
const NUMERO_POSITION = 14;
const CEP_POSITION = 15;

const DEFAULT_ID = -1;

const SidebarInputs = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [id, setId] = useState(DEFAULT_ID);
  const [bairro, setBairro] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [razaoSocial, setRazaoSocial] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [ie, setIe] = useState('');
  const [cep, setCep] = useState('');
  const [cidade, setCidade] = useState(null);

  const refs = useRef([]);
  const [carregando, setCarregando] = useState(false);

  const inputs = [
    {
      nome: 'razao_social',
      defaultValue: '',
      label: 'Razão Social',
    },
    {
      nome: 'nome_fantasia',
      defaultValue: '',
      label: 'Nome Fantasia',
    },
    {
      nome: 'cnpj',
      defaultValue: '',
      label: 'CNPJ',
    },
    {
      nome: 'ie',
      defaultValue: '',
      label: 'Inscrição Estadual',
    },
    {
      nome: 'cidade',
      defaultValue: null,
      label: 'Cidade',
      resource: 'cidades',
      nested: ['estado'],
      filters: { ativo: true },
    },
    {
      nome: 'bairro',
      defaultValue: '',
      label: 'Bairro',
    },
    {
      nome: 'logradouro',
      defaultValue: '',
      label: 'Logradouro',
    },
    {
      nome: 'numero',
      defaultValue: '',
      label: 'Número',
    },
    {
      nome: 'cep',
      defaultValue: '',
      label: 'CEP',
    },
  ];

  refs.current = inputs.map(
    (ref, index) => (refs.current[index] = React.createRef()),
  );

  function validateRazaoSocial() {
    let error = '';
    if (razaoSocial) {
      if (razaoSocial.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
    }
    return error;
  }

  function validateNomeFantasia() {
    let error = '';
    if (nomeFantasia) {
      if (nomeFantasia.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
    }
    return error;
  }

  function validateCnpj() {
    let error = '';
    if (cnpj) {
      if (cnpj.length < 14) error = 'O CNPJ deve ter 14 dígitos';
    }
    return error;
  }

  function validateIE() {
    let error = '';
    if (ie) {
      if (ie.length >= 20)
        error = 'A Inscrição Estadual deve ser menor que 20 dígitos';
    }
    return error;
  }

  function validateCidade() {
    let error = '';
    if (!cidade) error = 'Este campo é obrigatório';
    return error;
  }

  function validateBairro() {
    let error = '';
    if (bairro) {
      if (bairro.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
    }
    return error;
  }

  function validateLogradouro() {
    let error = '';
    if (logradouro) {
      if (logradouro.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
    }
    return error;
  }

  function validateNumero() {
    let error = '';
    if (numero) {
      if (numero.length > 6) {
        error = 'Máximo de 6 letras';
      }
    }
    return error;
  }

  function validateCep() {
    let error = '';
    if (cep) {
      if (cep.length < 8) error = 'O CEP deve ter 8 dígitos';
    }
    return error;
  }

  function getErros() {
    const errosOld = ['', '', '', '', '', '', '', '', ''];
    errosOld[0] = validateRazaoSocial();
    errosOld[1] = validateNomeFantasia();
    errosOld[2] = validateCnpj();
    errosOld[3] = validateIE();
    errosOld[4] = validateCidade();
    errosOld[5] = validateBairro();
    errosOld[6] = validateLogradouro();
    errosOld[7] = validateNumero();
    errosOld[8] = validateCep();

    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  const handleCloseDialog = (idUpdate) => {
    setOpen(false);
    handleClose(idUpdate);
  };

  async function reiniciar() {
    setId(DEFAULT_ID);
    setRazaoSocial(inputs[0].defaultValue);
    setNomeFantasia(inputs[1].defaultValue);
    setCnpj(inputs[2].defaultValue);
    setIe(inputs[3].defaultValue);
    setCidade(inputs[4].defaultValue);
    setBairro(inputs[5].defaultValue);
    setLogradouro(inputs[6].defaultValue);
    setNumero(inputs[7].defaultValue);
    setCep(inputs[8].defaultValue);
    setCarregando(false);
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'empresa-loja',
        ['id', 'asc'],
        { id: [id] },
        ['cidade.estado'],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        setRazaoSocial(data.data[0].razao_social);
        setNomeFantasia(data.data[0].nome_fantasia);
        setCnpj(data.data[0].cnpj);
        setIe(data.data[0].inscricao_estadual);
        setCidade(data.data[0].cidade);
        setBairro(data.data[0].bairro);
        setLogradouro(data.data[0].logradouro);
        setNumero(data.data[0].numero);
        setCep(data.data[0].cep);
        setCarregando(false);
      } else {
        throw 'Este cliente não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(-1);
    }
  }

  async function handleSalvar() {
    if (!erroExistente) {
      setCarregando(true);
      try {
        let idLocal = -1;
        if (id <= 0) {
          const clienteCriado = await createAPI('empresa-loja', {
            razao_social: razaoSocial,
            nome_fantasia: nomeFantasia,
            cnpj,
            inscricao_estadual: ie,
            cidade_id: cidade ? cidade.id : null,
            bairro,
            logradouro,
            numero,
            cep,
            //user_id: getUserId(),
          });
          setCarregando(false);
          enqueueSnackbar('Loja criada com sucesso!', {
            variant: 'success',
          });
          idLocal = clienteCriado.data ? clienteCriado.data.id : -1;
        } else {
          await updateAPI('empresa-loja', id, {
            razao_social: razaoSocial,
            nome_fantasia: nomeFantasia,
            cnpj,
            inscricao_estadual: ie,
            cidade_id: cidade ? cidade.id : null,
            bairro,
            logradouro,
            numero,
            cep,
            //user_id: getUserId(),
          });
          setCarregando(false);
          enqueueSnackbar('Loja atualizada com sucesso!', {
            variant: 'success',
          });
          idLocal = id;
        }
        handleCloseDialog(idLocal);
      } catch (erros) {
        setCarregando(false);
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog(-1);
      }
    } else {
      enqueueSnackbar('Existem erros no formulário!', {
        variant: 'error',
      });
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      setOpen(true);
      setCarregando(true);
      reiniciar();
    },
    handleEdit(id) {
      setOpen(true);
      setCarregando(true);
      setId(id);
      getDataResource(id);
    },
  }));

  function handleActions(action) {
    switch (action) {
      case FECHAR_VENDA_ACTION:
        handleSalvar();
        break;
      case CANCELAR_VENDA_ACTION:
        handleCloseDialog(-1);
        break;
      default:
        break;
    }
  }

  function getRefNextInput(index) {
    let position = -1;
    switch (index) {
      case 0:
        position = 1;
        break;
      case 1:
        position = 2;
        break;
      case 2:
        position = 3;
        break;
      case 3:
        position = 4;
        break;
      case 4:
        position = 5;
        break;
      case 5:
        position = 6;
        break;
      case 6:
        position = 7;
        break;
      case 7:
        position = 8;
        break;
      default:
        break;
    }
    return position;
  }

  function handleNextInput(index) {
    const position = getRefNextInput(index);
    if (position === -1) {
      refs.current[0].current.focus();
      refs.current[0].current.select();
    } else if (refs.current[position].current) {
      refs.current[position].current.focus();
      refs.current[position].current.select();
    }
  }

  function handleKey(keyCode, keyName) {
    handleActions(keyName);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen
    >
      <DialogTitle id="form-dialog-title">
        {id > 0 ? 'Editar Loja' : 'Nova Loja'}
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ height: '100%' }}>
          <Box
            display={{ xs: 'block', sm: 'flex' }}
            flexDirection="column"
            marginBottom="30px"
            height="100%"
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '300px',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Box p="1em">
                <Box display="flex">
                  <Box flex={2} mr="1em">
                    <Typography variant="h6" gutterBottom>
                      Dados Empresariais
                    </Typography>

                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <TextInput
                          name={inputs[0].nome}
                          ref={refs.current[RAZAO_SOCIAL_POSITION]}
                          handleEnter={() =>
                            handleNextInput(RAZAO_SOCIAL_POSITION)
                          }
                          label={inputs[0].label}
                          handleKey={handleKey}
                          value={razaoSocial}
                          onChange={(value) =>
                            setRazaoSocial(value.target.value)
                          }
                          error={erros[0] !== ''}
                          helperText={erros[0]}
                          fullWidth
                          autoFocus
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <TextInput
                          name={inputs[1].nome}
                          ref={refs.current[NOME_FANTASIA_POSITION]}
                          handleEnter={() =>
                            handleNextInput(NOME_FANTASIA_POSITION)
                          }
                          label={inputs[1].label}
                          handleKey={handleKey}
                          value={nomeFantasia}
                          onChange={(value) =>
                            setNomeFantasia(value.target.value)
                          }
                          error={erros[1] !== ''}
                          helperText={erros[1]}
                          fullWidth
                        />
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <CnpjInput
                          name={inputs[2].nome}
                          ref={refs.current[CNPJ_POSITION]}
                          handleEnter={() => handleNextInput(CNPJ_POSITION)}
                          label={inputs[2].label}
                          handleKey={handleKey}
                          value={cnpj}
                          onChange={(value) => setCnpj(value.target.value)}
                          error={erros[2] !== ''}
                          helperText={erros[2]}
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <IEInput
                          name={inputs[3].nome}
                          ref={refs.current[IE_POSITION]}
                          handleEnter={() => handleNextInput(IE_POSITION)}
                          label={inputs[3].label}
                          handleKey={handleKey}
                          value={ie}
                          onChange={(value) => setIe(value.target.value)}
                          error={erros[3] !== ''}
                          helperText={erros[3]}
                          fullWidth
                        />
                      </Box>
                    </Box>

                    <Typography variant="h6" gutterBottom>
                      Endereco
                    </Typography>

                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <AutoCompleteRemoto
                          name={inputs[4].nome}
                          ref={refs.current[CIDADE_POSITION]}
                          handleEnter={() => handleNextInput(CIDADE_POSITION)}
                          resource={inputs[4].resource}
                          nested={inputs[4].nested}
                          filters={inputs[4].filters}
                          label={inputs[4].label}
                          handleKey={handleKey}
                          value={cidade}
                          onChange={(value) => setCidade(value)}
                          error={erros[4] !== ''}
                          helperText={erros[4]}
                          toString={false}
                          fullWidth
                          getOptionSelected={(option, value) =>
                            option.nome === value.nome
                          }
                          getOptionLabel={(option) =>
                            `${option.nome} - ${option.estado.sigla}`
                          }
                          autoFocus={false}
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <TextInput
                          name={inputs[5].nome}
                          ref={refs.current[BAIRRO_POSITION]}
                          handleEnter={() => handleNextInput(BAIRRO_POSITION)}
                          label={inputs[5].label}
                          handleKey={handleKey}
                          value={bairro}
                          onChange={(value) => setBairro(value.target.value)}
                          error={erros[5] !== ''}
                          helperText={erros[5]}
                          fullWidth
                        />
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <TextInput
                          name={inputs[6].nome}
                          ref={refs.current[LOGRADOURO_POSITION]}
                          handleEnter={() =>
                            handleNextInput(LOGRADOURO_POSITION)
                          }
                          label={inputs[6].label}
                          handleKey={handleKey}
                          value={logradouro}
                          onChange={(value) =>
                            setLogradouro(value.target.value)
                          }
                          error={erros[6] !== ''}
                          helperText={erros[6]}
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <TextInput
                          name={inputs[7].nome}
                          ref={refs.current[NUMERO_POSITION]}
                          handleEnter={() => handleNextInput(NUMERO_POSITION)}
                          label={inputs[7].label}
                          handleKey={handleKey}
                          value={numero}
                          onChange={(value) => setNumero(value.target.value)}
                          error={erros[7] !== ''}
                          helperText={erros[7]}
                          fullWidth
                        />
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <CepInput
                          name={inputs[8].nome}
                          ref={refs.current[CEP_POSITION]}
                          handleEnter={() => handleNextInput(CEP_POSITION)}
                          label={inputs[8].label}
                          handleKey={handleKey}
                          value={cep}
                          onChange={(value) => setCep(value.target.value)}
                          error={erros[8] !== ''}
                          helperText={erros[8]}
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} ml="0.5em" />
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCloseDialog(-1)} color="primary">
          Cancelar (F4)
        </Button>
        <div className={classes.wrapper}>
          <Button
            color="primary"
            onClick={() => {
              handleSalvar();
            }}
            disabled={erroExistente || carregando}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
          >
            Cadastrar (F8)
          </Button>
          {carregando && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
});

export default SidebarInputs;
