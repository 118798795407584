import React from 'react';
import { Filter as FilterAdmin, TextInput } from 'react-admin';

import { PeriodoInput, PrecoInput } from '../../Components/InputsReactAdmin';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <TextInput label="Descrição" source="nome" />
    <PeriodoInput
      source="periodo"
      label="Período"
      defaultValue={{
        start: new Date(),
        end: new Date(),
      }}
    />
    <PrecoInput source="valor" label="Valor" fixedDecimalScale={false} />
  </FilterAdmin>
);

export default Filter;
