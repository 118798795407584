import React, { useRef } from 'react';
import {
  ListBase,
  ListToolbar,
  Pagination,
  useRefresh,
  Title,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
} from 'react-admin';

import { Card, makeStyles } from '@material-ui/core';

import ActionsField from './ActionsField';
import Actions from './ActionsList';
import Dialogo from './DialogoCreateEdit';
import DialogoDeleteResource from './DialogoDeletar';
import Filter from './Filter';

const useStyles = makeStyles((theme) => ({
  dataHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
}));

const MyList = ({ ...props }) => {
  const refDialogoCompra = useRef(null);
  const refDialogoDeleteResource = useRef(null);
  const refresh = useRefresh();
  const classes = useStyles();

  function handleCriar() {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleCreate();
    }
  }
  function handleEdit({ id }) {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleEdit(id);
    }
  }
  function deleteHundle({ id }) {
    if (refDialogoDeleteResource.current)
      refDialogoDeleteResource.current.handleOpen(id);
  }

  const handleClose = () => {
    refresh();
  };

  return (
    <>
      <Title title="Checagens Fardamentos" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions handleCriar={handleCriar} {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid {...props}>
          <ReferenceField
            label="Colaborador"
            source="colaborador_id"
            reference="colaboradores"
            sortable={false}
          >
            <TextField
              source="nome"
              headerClassName={classes.dataHeader}
              cellClassName={classes.dataCell}
            />
          </ReferenceField>
          <ReferenceField
            label="Fardamentos"
            source="fardamento_id"
            reference="fardamentos"
            sortable={false}
          >
            <TextField
              source="nome"
              headerClassName={classes.dataHeader}
              cellClassName={classes.dataCell}
            />
          </ReferenceField>
          <DateField source="data" sortable={false} label="Data" />
          <ActionsField
            source="valor"
            textAlign="right"
            label="Ações"
            sortable={false}
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            handleDelete={deleteHundle}
            handleEdit={handleEdit}
            ativo
          />
        </Datagrid>
        <TransacoesPagination />
      </Card>
      <Dialogo ref={refDialogoCompra} handleClose={handleClose} />
      <DialogoDeleteResource
        ref={refDialogoDeleteResource}
        handleClose={handleClose}
      />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Checagens Camisas"
      component="div"
    />
  </>
);

export default List2;
