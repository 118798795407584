import React from 'react';
import {
  Filter as FilterAdmin,
  ReferenceInput,
  SelectInput,
} from 'react-admin';

import { PeriodoInput, PrecoInput } from '../../Components/InputsReactAdmin';
import { STATUS_ATIVO } from '../../utils/constants';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <PeriodoInput
      source="periodo"
      label="Período"
      defaultValue={{
        start: new Date(),
        end: new Date(),
      }}
    />
    <PrecoInput source="search" label="Valor" fixedDecimalScale={false} />
    <ReferenceInput
      source="conta_origem"
      reference="contas_normal"
      label="Conta Origem"
      filter={{ is_caixa: false, status: STATUS_ATIVO }}
      allowEmpty={false}
    >
      <SelectInput optionText="nome" allowEmpty={false} />
    </ReferenceInput>
    <ReferenceInput
      source="conta_destino"
      reference="contas_normal"
      label="Conta Destino"
      filter={{ is_caixa: false, status: STATUS_ATIVO }}
      allowEmpty={false}
    >
      <SelectInput optionText="nome" allowEmpty={false} />
    </ReferenceInput>
  </FilterAdmin>
);

export default Filter;
