import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from 'react';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AlarmIcon from '@material-ui/icons/Alarm';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'notistack';

import {
  PrecoInput,
  DateTimeInputBonito,
  IntegerInput,
  SwitchInput,
  PesoInput,
  SelectInput,
} from '../../../../..';
import { WeightInput } from '../../../../../../libNova/inputs/WeightInput';
import {
  getListAllAPI,
  createAPI,
  updateAPI,
  getUserId,
  getManyAPI,
  api,
} from '../../../../../../services';
import {
  POR_UNIDADE,
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  TIPO_UNIDADE_OFERTA_KG,
  TIPO_UNIDADE_OFERTA_UN,
  TIPO_UNIDADE_OFERTA_CAIXA,
  TIPO_UNIDADE_OFERTA_CAIXETA,
  TIPO_UNIDADE_OFERTA_CAIXA_MAE,
  getNivelCaixaQtde,
  getLabelTipoUnidade,
  getQuantidadeCaixariaLabel,
  getPrecoUnitarioVerdadeiroCaixa2,
  getOptionsTipoUnidadeOferta,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  formatCPF,
  formatCNPJ,
  ZERO_APROXIMADO,
  formatMoeda,
  STATUS_ATIVO,
  formatPeso,
  TIPO_OFERTA_PRODUTO_JORNAL,
  TIPO_OFERTA_PRODUTO_LIBERACAO,
  TIPO_OFERTA_PRODUTO_ZERAR_ESTOQUE,
  formatPreco,
  round10,
  trunc10,
} from '../../../../../../utils';
import {
  tipoStatusAndamentoAprovar,
  tipoStatusAndamentoAprovarVolta,
  tipoStatusAndamentoCriarPedido,
  tipoStatusAndamentoDetalhes,
} from '../../../utils/constants';
import DialogoPrecificacao from '../DialogoPrecificacao';
import TabelaPrecos from './TabelaPrecos';

const optionscomparaValoresCorretoDefault = { precisao: 2, operador: '===' };

function comparaValoresCorreto(
  valor1,
  valor2,
  options = optionscomparaValoresCorretoDefault,
) {
  const optionsLocal = {
    ...optionscomparaValoresCorretoDefault,
    ...options,
  };

  switch (optionsLocal.operador) {
    case '===':
      return (
        round10(valor1, -1 * optionsLocal.precisao) ===
        round10(valor2, -1 * optionsLocal.precisao)
      );
    case '>=':
      return (
        round10(valor1, -1 * optionsLocal.precisao) >=
        round10(valor2, -1 * optionsLocal.precisao)
      );
    case '<=':
      return (
        round10(valor1, -1 * optionsLocal.precisao) <=
        round10(valor2, -1 * optionsLocal.precisao)
      );
    case '>':
      return (
        round10(valor1, -1 * optionsLocal.precisao) >
        round10(valor2, -1 * optionsLocal.precisao)
      );
    case '<':
      return (
        round10(valor1, -1 * optionsLocal.precisao) <
        round10(valor2, -1 * optionsLocal.precisao)
      );
    case '!==':
      return (
        round10(valor1, -1 * optionsLocal.precisao) !==
        round10(valor2, -1 * optionsLocal.precisao)
      );
    default:
      throw 'Nenhum operador passado para a função comparaPrecos';
  }
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    //borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const optionsManifestacaoCliente1 = [
  {
    label: 'Aceito',
    value: 'aceito',
  },
  {
    label: 'Rejeitado',
    value: 'rejeitado',
  },
  {
    label: 'Nova Solicitação',
    value: 'nova-solicitacao',
  },
];

const optionsManifestacaoCliente2 = [
  {
    label: 'Aceito',
    value: 'aceito',
  },
  {
    label: 'Rejeitado',
    value: 'rejeitado',
  },
];

const optionsNegociacao = [
  {
    label: 'Continuar Negociação',
    value: false,
  },
  {
    label: 'Encerrar Negociação',
    value: true,
  },
];

function formatPreco2(valor, casasDecimais = 2) {
  return valor !== null && valor !== undefined
    ? valor % 1 === 0
      ? valor
      : `${valor.toLocaleString('de-DE', {
          minimumFractionDigits: casasDecimais,
          maximumFractionDigits: casasDecimais,
        })}`
    : '';
}

function isKg(produto) {
  if (produto) {
    //if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) return true;
    return false;
  }

  return false;
}

function InputTipoUnidade({
  tipoUnidadeOferta,
  setTipoUnidadeUnidade,
  produto,
  disabled = false,
}) {
  const optionsTipoUnidadeOferta = getOptionsTipoUnidadeOferta(produto);
  const handleChange = (event) => {
    setTipoUnidadeUnidade(event.target.value);
  };
  return (
    <Select
      labelId="demo-simple-select-outlined-label"
      id="demo-simple-select-outlined"
      value={tipoUnidadeOferta}
      onChange={handleChange}
      disabled={disabled}
    >
      {optionsTipoUnidadeOferta.map(({ label, value }) => (
        <MenuItem value={value}>{label}</MenuItem>
      ))}
    </Select>
  );
}

function getMaiorPrecoSugerido(
  dados,
  tipoUnidadeOferta,
  desconto_maximo_preco_venda,
) {
  const {
    precoUnidade,
    precoCaixariaNivel1,
    precoCaixariaNivel2,
    precoCaixariaNivel3,
    precoOfertaJornalUnidade,
    precoOfertaJornalCaixariaNivel1,
    precoOfertaJornalCaixariaNivel2,
    precoOfertaJornalCaixariaNivel3,
    precoOfertaZerarEstoqueUnidade,
    precoOfertaZerarEstoqueCaixariaNivel1,
    precoOfertaZerarEstoqueCaixariaNivel2,
    precoOfertaZerarEstoqueCaixariaNivel3,
    precoVendaComMargemDeLbZeroUnidade,
    precoVendaComMargemDeLbZeroCaixariaNivel1,
    precoVendaComMargemDeLbZeroCaixariaNivel2,
    precoVendaComMargemDeLbZeroCaixariaNivel3,
  } = dados;

  switch (tipoUnidadeOferta) {
    case TIPO_UNIDADE_OFERTA_UN: {
      return {
        minimoPreco: Math.min(
          ...[
            precoUnidade,
            precoOfertaJornalUnidade,
            precoOfertaZerarEstoqueUnidade,
            precoVendaComMargemDeLbZeroUnidade,
          ].filter((item) => item > 0),
        ),
        minimoDescontoAplicado:
          (precoUnidade * desconto_maximo_preco_venda) / 100,
        precoAtual: precoUnidade,
      };
    }
    case TIPO_UNIDADE_OFERTA_KG: {
      return {
        minimoPreco: Math.min(
          ...[
            precoUnidade,
            precoOfertaJornalUnidade,
            precoOfertaZerarEstoqueUnidade,
            precoVendaComMargemDeLbZeroUnidade,
          ].filter((item) => item > 0),
        ),
        minimoDescontoAplicado:
          (precoUnidade * desconto_maximo_preco_venda) / 100,
        precoAtual: precoUnidade,
      };
    }
    case TIPO_UNIDADE_OFERTA_CAIXA: {
      return {
        minimoPreco: Math.min(
          ...[
            precoCaixariaNivel1,
            precoOfertaJornalCaixariaNivel1,
            precoOfertaZerarEstoqueCaixariaNivel1,
            precoVendaComMargemDeLbZeroCaixariaNivel1,
          ].filter((item) => item > 0),
        ),
        minimoDescontoAplicado:
          (precoCaixariaNivel1 * desconto_maximo_preco_venda) / 100,
        precoAtual: precoCaixariaNivel1,
      };
    }
    case TIPO_UNIDADE_OFERTA_CAIXETA: {
      return {
        minimoPreco: Math.min(
          ...[
            precoCaixariaNivel2,
            precoOfertaJornalCaixariaNivel2,
            precoOfertaZerarEstoqueCaixariaNivel2,
            precoVendaComMargemDeLbZeroCaixariaNivel2,
          ].filter((item) => item > 0),
        ),
        minimoDescontoAplicado:
          (precoCaixariaNivel2 * desconto_maximo_preco_venda) / 100,
        precoAtual: precoCaixariaNivel2,
      };
    }
    case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
      return {
        minimoPreco: Math.min(
          ...[
            precoCaixariaNivel3,
            precoOfertaJornalCaixariaNivel3,
            precoOfertaZerarEstoqueCaixariaNivel3,
            precoVendaComMargemDeLbZeroCaixariaNivel3,
          ].filter((item) => item > 0),
        ),
        minimoDescontoAplicado:
          (precoCaixariaNivel3 * desconto_maximo_preco_venda) / 100,
        precoAtual: precoCaixariaNivel3,
      };
    }
    default: {
      return {
        minimoPreco: 0,
        minimoDescontoAplicado: 0,
        precoAtual: 0,
      };
    }
  }
}

function getTotais(dados) {
  const {
    qtdeUnidade,
    qtdeCaixariaNivel1,
    qtdeCaixariaNivel2,
    qtdeCaixariaNivel3,
    ///////////////////
    precoCaixariaNivel1,
    precoCaixariaNivel2,
    precoCaixariaNivel3,
    precoUnidade,
    ///////////////////
    precoVendaComMargemDeLbZeroCaixariaNivel1,
    precoVendaComMargemDeLbZeroCaixariaNivel2,
    precoVendaComMargemDeLbZeroCaixariaNivel3,
    precoVendaComMargemDeLbZeroUnidade,
    ///////////////////////
    verbaCaixariaNivel1,
    verbaCaixariaNivel2,
    verbaCaixariaNivel3,
    verbaUnidade,
    ///////////////////////////
    precoVendaLiberadoMaisVerbaIncluidaUnidade,
    precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel1,
    precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel2,
    precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel3,
    //////////////////////////
    produto,
    tipoUnidadeOferta,
    ///////////////////////////
    precoOfertaJornalUnidade,
    precoOfertaJornalCaixariaNivel1,
    precoOfertaJornalCaixariaNivel2,
    precoOfertaJornalCaixariaNivel3,
    /////////////////////////////
    precoOfertaZerarEstoqueUnidade,
    precoOfertaZerarEstoqueCaixariaNivel1,
    precoOfertaZerarEstoqueCaixariaNivel2,
    precoOfertaZerarEstoqueCaixariaNivel3,
    /////////////////////////////
    precoCompraUnidade,
    precoCompraCaixariaNivel1,
    precoCompraCaixariaNivel2,
    precoCompraCaixariaNivel3,
    /////////////////////////////
    precoMinimoUnidade,
    precoMinimoCaixariaNivel1,
    precoMinimoCaixariaNivel2,
    precoMinimoCaixariaNivel3,
    /////////////////////////////
    precoVendaComMargemDeLbZero2Unidade,
    precoVendaComMargemDeLbZero2CaixariaNivel1,
    precoVendaComMargemDeLbZero2CaixariaNivel2,
    precoVendaComMargemDeLbZero2CaixariaNivel3,
    /////////////////////////////
    fator,
  } = dados;

  switch (tipoUnidadeOferta) {
    case TIPO_UNIDADE_OFERTA_KG: {
      const totalSemVerba = qtdeUnidade * fator * precoUnidade;
      const totalJornalOferta = qtdeUnidade * fator * precoOfertaJornalUnidade;
      const totalZerarEstoque =
        qtdeUnidade * fator * precoOfertaZerarEstoqueUnidade;
      const totalComMargemDeLbZero =
        qtdeUnidade * fator * precoVendaComMargemDeLbZeroUnidade;
      const totalVerba = qtdeUnidade * fator * verbaUnidade;
      const totalVerbaIncluida =
        qtdeUnidade * fator * precoVendaLiberadoMaisVerbaIncluidaUnidade;
      const totalCompra = qtdeUnidade * fator * precoCompraUnidade;
      const totalMinimo = qtdeUnidade * fator * precoMinimoUnidade;
      const totalComMargemDeLbZero2 =
        qtdeUnidade * fator * precoVendaComMargemDeLbZero2Unidade;

      return {
        totalSemVerba: totalSemVerba > 0 ? formatMoeda(totalSemVerba) : '-',
        totalJornalOferta:
          totalJornalOferta > 0 ? formatMoeda(totalJornalOferta) : '-',
        totalZerarEstoque:
          totalZerarEstoque > 0 ? formatMoeda(totalZerarEstoque) : '-',
        totalComMargemDeLbZero:
          totalComMargemDeLbZero > 0
            ? formatMoeda(totalComMargemDeLbZero)
            : '-',
        totalVerba: totalVerba > 0 ? formatMoeda(totalVerba) : '-',
        totalVerbaIncluida:
          totalVerbaIncluida > 0 ? formatMoeda(totalVerbaIncluida) : '-',
        totalCompra: totalCompra > 0 ? formatMoeda(totalCompra) : '-',
        totalMinimo: totalMinimo > 0 ? formatMoeda(totalMinimo) : '-',
        totalComMargemDeLbZero2:
          totalComMargemDeLbZero2 > 0
            ? formatMoeda(totalComMargemDeLbZero2)
            : '-',
      };
    }
    case TIPO_UNIDADE_OFERTA_UN: {
      const totalSemVerba = qtdeUnidade * fator * precoUnidade;
      const totalJornalOferta = qtdeUnidade * fator * precoOfertaJornalUnidade;
      const totalZerarEstoque =
        qtdeUnidade * fator * precoOfertaZerarEstoqueUnidade;
      const totalComMargemDeLbZero =
        qtdeUnidade * fator * precoVendaComMargemDeLbZeroUnidade;
      const totalVerba = qtdeUnidade * fator * verbaUnidade;
      const totalVerbaIncluida =
        qtdeUnidade * fator * precoVendaLiberadoMaisVerbaIncluidaUnidade;
      const totalCompra = qtdeUnidade * fator * precoCompraUnidade;
      const totalMinimo = qtdeUnidade * fator * precoMinimoUnidade;
      const totalComMargemDeLbZero2 =
        qtdeUnidade * fator * precoVendaComMargemDeLbZero2Unidade;

      return {
        totalSemVerba: totalSemVerba > 0 ? formatMoeda(totalSemVerba) : '-',
        totalJornalOferta:
          totalJornalOferta > 0 ? formatMoeda(totalJornalOferta) : '-',
        totalZerarEstoque:
          totalZerarEstoque > 0 ? formatMoeda(totalZerarEstoque) : '-',
        totalComMargemDeLbZero:
          totalComMargemDeLbZero > 0
            ? formatMoeda(totalComMargemDeLbZero)
            : '-',
        totalVerba: totalVerba > 0 ? formatMoeda(totalVerba) : '-',
        totalVerbaIncluida:
          totalVerbaIncluida > 0 ? formatMoeda(totalVerbaIncluida) : '-',
        totalCompra: totalCompra > 0 ? formatMoeda(totalCompra) : '-',
        totalMinimo: totalMinimo > 0 ? formatMoeda(totalMinimo) : '-',
        totalComMargemDeLbZero2:
          totalComMargemDeLbZero2 > 0
            ? formatMoeda(totalComMargemDeLbZero2)
            : '-',
      };
    }
    case TIPO_UNIDADE_OFERTA_CAIXA: {
      const totalSemVerba = qtdeCaixariaNivel1 * precoCaixariaNivel1;
      const totalJornalOferta =
        qtdeCaixariaNivel1 * precoOfertaJornalCaixariaNivel1;
      const totalZerarEstoque =
        qtdeCaixariaNivel1 * precoOfertaZerarEstoqueCaixariaNivel1;
      const totalComMargemDeLbZero =
        qtdeCaixariaNivel1 * precoVendaComMargemDeLbZeroCaixariaNivel1;
      const totalVerba = qtdeCaixariaNivel1 * verbaCaixariaNivel1;
      const totalVerbaIncluida =
        qtdeCaixariaNivel1 * precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel1;
      const totalCompra = qtdeCaixariaNivel1 * precoCompraCaixariaNivel1;
      const totalMinimo = qtdeCaixariaNivel1 * precoMinimoCaixariaNivel1;
      const totalComMargemDeLbZero2 =
        qtdeCaixariaNivel1 * precoVendaComMargemDeLbZero2CaixariaNivel1;

      return {
        totalSemVerba: totalSemVerba > 0 ? formatMoeda(totalSemVerba) : '-',
        totalJornalOferta:
          totalJornalOferta > 0 ? formatMoeda(totalJornalOferta) : '-',
        totalZerarEstoque:
          totalZerarEstoque > 0 ? formatMoeda(totalZerarEstoque) : '-',
        totalComMargemDeLbZero:
          totalComMargemDeLbZero > 0
            ? formatMoeda(totalComMargemDeLbZero)
            : '-',
        totalVerba: totalVerba > 0 ? formatMoeda(totalVerba) : '-',
        totalVerbaIncluida:
          totalVerbaIncluida > 0 ? formatMoeda(totalVerbaIncluida) : '-',
        totalCompra: totalCompra > 0 ? formatMoeda(totalCompra) : '-',
        totalMinimo: totalMinimo > 0 ? formatMoeda(totalMinimo) : '-',
        totalComMargemDeLbZero2:
          totalComMargemDeLbZero2 > 0
            ? formatMoeda(totalComMargemDeLbZero2)
            : '-',
      };
    }
    case TIPO_UNIDADE_OFERTA_CAIXETA: {
      const totalSemVerba = qtdeCaixariaNivel2 * precoCaixariaNivel2;
      const totalJornalOferta =
        qtdeCaixariaNivel2 * precoOfertaJornalCaixariaNivel2;
      const totalZerarEstoque =
        qtdeCaixariaNivel2 * precoOfertaZerarEstoqueCaixariaNivel2;
      const totalComMargemDeLbZero =
        qtdeCaixariaNivel2 * precoVendaComMargemDeLbZeroCaixariaNivel2;
      const totalVerba = qtdeCaixariaNivel2 * verbaCaixariaNivel2;
      const totalVerbaIncluida =
        qtdeCaixariaNivel2 * precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel2;
      const totalCompra = qtdeCaixariaNivel2 * precoCompraCaixariaNivel2;
      const totalMinimo = qtdeCaixariaNivel2 * precoMinimoCaixariaNivel2;
      const totalComMargemDeLbZero2 =
        qtdeCaixariaNivel2 * precoVendaComMargemDeLbZero2CaixariaNivel2;

      return {
        totalSemVerba: totalSemVerba > 0 ? formatMoeda(totalSemVerba) : '-',
        totalJornalOferta:
          totalJornalOferta > 0 ? formatMoeda(totalJornalOferta) : '-',
        totalZerarEstoque:
          totalZerarEstoque > 0 ? formatMoeda(totalZerarEstoque) : '-',
        totalComMargemDeLbZero:
          totalComMargemDeLbZero > 0
            ? formatMoeda(totalComMargemDeLbZero)
            : '-',
        totalVerba: totalVerba > 0 ? formatMoeda(totalVerba) : '-',
        totalVerbaIncluida:
          totalVerbaIncluida > 0 ? formatMoeda(totalVerbaIncluida) : '-',
        totalCompra: totalCompra > 0 ? formatMoeda(totalCompra) : '-',
        totalMinimo: totalMinimo > 0 ? formatMoeda(totalMinimo) : '-',
        totalComMargemDeLbZero2:
          totalComMargemDeLbZero2 > 0
            ? formatMoeda(totalComMargemDeLbZero2)
            : '-',
      };
    }
    case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
      const totalSemVerba = qtdeCaixariaNivel3 * precoCaixariaNivel3;
      const totalJornalOferta =
        qtdeCaixariaNivel3 * precoOfertaJornalCaixariaNivel3;
      const totalZerarEstoque =
        qtdeCaixariaNivel3 * precoOfertaZerarEstoqueCaixariaNivel3;
      const totalComMargemDeLbZero =
        qtdeCaixariaNivel3 * precoVendaComMargemDeLbZeroCaixariaNivel3;
      const totalVerba = qtdeCaixariaNivel3 * verbaCaixariaNivel3;
      const totalVerbaIncluida =
        qtdeCaixariaNivel3 * precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel3;
      const totalCompra = qtdeCaixariaNivel3 * precoCompraCaixariaNivel3;
      const totalMinimo = qtdeCaixariaNivel3 * precoMinimoCaixariaNivel3;
      const totalComMargemDeLbZero2 =
        qtdeCaixariaNivel3 * precoVendaComMargemDeLbZero2CaixariaNivel3;

      return {
        totalSemVerba: totalSemVerba > 0 ? formatMoeda(totalSemVerba) : '-',
        totalJornalOferta:
          totalJornalOferta > 0 ? formatMoeda(totalJornalOferta) : '-',
        totalZerarEstoque:
          totalZerarEstoque > 0 ? formatMoeda(totalZerarEstoque) : '-',
        totalComMargemDeLbZero:
          totalComMargemDeLbZero > 0
            ? formatMoeda(totalComMargemDeLbZero)
            : '-',
        totalVerba: totalVerba > 0 ? formatMoeda(totalVerba) : '-',
        totalVerbaIncluida:
          totalVerbaIncluida > 0 ? formatMoeda(totalVerbaIncluida) : '-',
        totalCompra: totalCompra > 0 ? formatMoeda(totalCompra) : '-',
        totalMinimo: totalMinimo > 0 ? formatMoeda(totalMinimo) : '-',
        totalComMargemDeLbZero2:
          totalComMargemDeLbZero2 > 0
            ? formatMoeda(totalComMargemDeLbZero2)
            : '-',
      };
    }
    default: {
      const totalSemVerba = 0;
      const totalJornalOferta = 0;
      const totalZerarEstoque = 0;
      const totalComMargemDeLbZero = 0;
      const totalVerba = 0;
      const totalVerbaIncluida = 0;
      const totalCompra = 0;
      const totalMinimo = 0;
      const totalComMargemDeLbZero2 = 0;

      return {
        totalSemVerba: totalSemVerba > 0 ? formatMoeda(totalSemVerba) : '-',
        totalJornalOferta:
          totalJornalOferta > 0 ? formatMoeda(totalJornalOferta) : '-',
        totalZerarEstoque:
          totalZerarEstoque > 0 ? formatMoeda(totalZerarEstoque) : '-',
        totalComMargemDeLbZero:
          totalComMargemDeLbZero > 0
            ? formatMoeda(totalComMargemDeLbZero)
            : '-',
        totalVerba: totalVerba > 0 ? formatMoeda(totalVerba) : '-',
        totalVerbaIncluida:
          totalVerbaIncluida > 0 ? formatMoeda(totalVerbaIncluida) : '-',
        totalCompra: totalCompra > 0 ? formatMoeda(totalCompra) : '-',
        totalMinimo: totalMinimo > 0 ? formatMoeda(totalMinimo) : '-',
        totalComMargemDeLbZero2:
          totalComMargemDeLbZero2 > 0
            ? formatMoeda(totalComMargemDeLbZero2)
            : '-',
      };
    }
  }
}

function getPrecosOfertas(produto) {
  let precoOfertaJornal = 0;
  let precoOfertaZerarEstoque = 0;

  if (produto.ofertasProdutos.length > 0) {
    for (let i = 0; i < produto.ofertasProdutos.length; i++) {
      const oferta = produto.ofertasProdutos[i];
      if (oferta.tipo === TIPO_OFERTA_PRODUTO_JORNAL) {
        precoOfertaJornal = oferta.valor;
      } else if (oferta.tipo === TIPO_OFERTA_PRODUTO_ZERAR_ESTOQUE) {
        precoOfertaZerarEstoque = oferta.valor;
      }
    }
  }

  return {
    precoOfertaJornal,
    precoOfertaZerarEstoque,
  };
}

function getUnidadesUnitaria(produto, qtde, tipoUnidadeOferta, verbaUnitaria) {
  if (produto) {
    const { precoOfertaJornal, precoOfertaZerarEstoque } = getPrecosOfertas(
      produto,
    );
    switch (produto.unidade.tipo) {
      case POR_UNIDADE: {
        switch (tipoUnidadeOferta) {
          case TIPO_UNIDADE_OFERTA_UN: {
            return {
              unidades: qtde,
              peso: 0,
              verba: verbaUnitaria,
              precoOfertaJornal,
              precoOfertaZerarEstoque,
            };
          }
          case TIPO_UNIDADE_OFERTA_CAIXA: {
            return {
              unidades: qtde * produto.unidades_caixa_nivel_1,
              peso: 0,
              verba: verbaUnitaria / produto.unidades_caixa_nivel_1,
              precoOfertaJornal,
              precoOfertaZerarEstoque,
            };
          }
          case TIPO_UNIDADE_OFERTA_CAIXETA: {
            return {
              unidades:
                qtde *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2,
              peso: 0,
              verba:
                verbaUnitaria /
                (produto.unidades_caixa_nivel_1 *
                  produto.unidades_caixa_nivel_2),
              precoOfertaJornal,
              precoOfertaZerarEstoque,
            };
          }
          case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
            return {
              unidades:
                qtde *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3,
              peso: 0,
              verba:
                verbaUnitaria /
                (produto.unidades_caixa_nivel_1 *
                  produto.unidades_caixa_nivel_2 *
                  produto.unidades_caixa_nivel_3),
              precoOfertaJornal,
              precoOfertaZerarEstoque,
            };
          }
          default: {
            return {
              unidades: qtde,
              peso: 0,
              verba: 0,
              precoOfertaJornal: 0,
              precoOfertaZerarEstoque: 0,
            };
          }
        }
      }
      case POR_PESO_VARIAVEL_SEM_UNIDADE: {
        switch (tipoUnidadeOferta) {
          case TIPO_UNIDADE_OFERTA_KG: {
            return {
              unidades: 0,
              peso: qtde,
              verba: verbaUnitaria,
              precoOfertaJornal,
              precoOfertaZerarEstoque,
            };
          }
          case TIPO_UNIDADE_OFERTA_CAIXA: {
            return {
              unidades: 0,
              peso: qtde * produto.peso_caixa_nivel_1,
              verba: verbaUnitaria / produto.peso_caixa_nivel_1,
              precoOfertaJornal,
              precoOfertaZerarEstoque,
            };
          }
          case TIPO_UNIDADE_OFERTA_CAIXETA: {
            return {
              unidades: 0,
              peso:
                qtde *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2,
              verba:
                verbaUnitaria /
                (produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2),
              precoOfertaJornal,
              precoOfertaZerarEstoque,
            };
          }
          case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
            return {
              unidades: 0,
              peso:
                qtde *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3,
              verba:
                verbaUnitaria /
                (produto.peso_caixa_nivel_1 *
                  produto.unidades_caixa_nivel_2 *
                  produto.unidades_caixa_nivel_3),
              precoOfertaJornal,
              precoOfertaZerarEstoque,
            };
          }
          default: {
            return {
              unidades: qtde,
              peso: 0,
              verba: 0,
              precoOfertaJornal: 0,
              precoOfertaZerarEstoque: 0,
            };
          }
        }
      }
      case POR_PESO_VARIAVEL: {
        return {
          unidades: 0,
          peso: 0,
          verba: 0,
          precoOfertaJornal: 0,
          precoOfertaZerarEstoque: 0,
        };
      }
      case POR_PESO_FIXO: {
        switch (tipoUnidadeOferta) {
          case TIPO_UNIDADE_OFERTA_UN: {
            return {
              unidades: qtde,
              peso: qtde * produto.peso_medio,
              verba: verbaUnitaria,
              precoOfertaJornal,
              precoOfertaZerarEstoque,
            };
          }
          case TIPO_UNIDADE_OFERTA_CAIXA: {
            return {
              unidades: qtde * produto.unidades_caixa_nivel_1,
              peso: qtde * produto.unidades_caixa_nivel_1 * produto.peso_medio,
              verba:
                verbaUnitaria /
                (produto.peso_medio * produto.unidades_caixa_nivel_1),
              precoOfertaJornal,
              precoOfertaZerarEstoque,
            };
          }
          case TIPO_UNIDADE_OFERTA_CAIXETA: {
            return {
              unidades:
                qtde *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2,
              peso:
                qtde *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.peso_medio,
              verba:
                verbaUnitaria /
                (produto.peso_medio *
                  produto.unidades_caixa_nivel_1 *
                  produto.unidades_caixa_nivel_2),
              precoOfertaJornal,
              precoOfertaZerarEstoque,
            };
          }
          case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
            return {
              unidades:
                qtde *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3,
              peso:
                qtde *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3 *
                produto.peso_medio,
              verba:
                verbaUnitaria /
                (produto.peso_medio *
                  produto.unidades_caixa_nivel_1 *
                  produto.unidades_caixa_nivel_2 *
                  produto.unidades_caixa_nivel_3),
              precoOfertaJornal,
              precoOfertaZerarEstoque,
            };
          }
          default: {
            return {
              unidades: qtde,
              peso: 0,
              verba: 0,
              precoOfertaJornal: 0,
              precoOfertaZerarEstoque: 0,
            };
          }
        }
      }
      default:
        return {
          unidades: 0,
          peso: 0,
          verba: 0,
          precoOfertaJornal: 0,
          precoOfertaZerarEstoque: 0,
        };
    }
  }
  return {
    unidades: 0,
    peso: 0,
    verba: 0,
    precoOfertaJornal: 0,
    precoOfertaZerarEstoque: 0,
  };
}

function getQuantidadesTipos(
  unidades,
  peso,
  produto,
  valor,
  verba,
  precoOfertaJornal,
  precoOfertaZerarEstoque,
  precoCompra,
  precoMinimo,
  precoPrecificacaoZero,
) {
  if (produto) {
    switch (produto.unidade.tipo) {
      case POR_UNIDADE: {
        return {
          qtdeUnidade: formatPreco2(unidades),
          qtdeUnidadeValue: unidades,
          qtdeCaixariaNivel1:
            produto.unidades_caixa_nivel_1 > 0
              ? formatPreco2(unidades / produto.unidades_caixa_nivel_1)
              : '-',
          qtdeCaixariaNivel1Value:
            produto.unidades_caixa_nivel_1 > 0
              ? unidades / produto.unidades_caixa_nivel_1
              : 0,
          qtdeCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0
              ? formatPreco2(
                  unidades /
                    (produto.unidades_caixa_nivel_1 *
                      produto.unidades_caixa_nivel_2),
                )
              : '-',
          qtdeCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? unidades /
                (produto.unidades_caixa_nivel_1 *
                  produto.unidades_caixa_nivel_2)
              : 0,
          qtdeCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0
              ? formatPreco2(
                  unidades /
                    (produto.unidades_caixa_nivel_1 *
                      produto.unidades_caixa_nivel_2 *
                      produto.unidades_caixa_nivel_3),
                )
              : '-',
          qtdeCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? unidades /
                (produto.unidades_caixa_nivel_1 *
                  produto.unidades_caixa_nivel_2 *
                  produto.unidades_caixa_nivel_3)
              : 0,
          //////////////////////////////////////
          precoUnidade: formatMoeda(produto.preco_venda),
          precoUnidadeValue: produto.preco_venda,
          precoCaixariaNivel1:
            produto.unidades_caixa_nivel_1 > 0 && produto.preco_venda > 0
              ? formatMoeda(
                  produto.preco_venda * produto.unidades_caixa_nivel_1,
                )
              : '-',
          precoCaixariaNivel1Value:
            produto.unidades_caixa_nivel_1 > 0
              ? produto.preco_venda * produto.unidades_caixa_nivel_1
              : 0,
          precoCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0 && produto.preco_venda > 0
              ? formatMoeda(
                  produto.preco_venda *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          precoCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? produto.preco_venda *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          precoCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0 && produto.preco_venda > 0
              ? formatMoeda(
                  produto.preco_venda *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          precoCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? produto.preco_venda *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
          /////////////////////////////////////
          precoVendaComMargemDeLbZeroUnidade: formatMoeda(valor),
          precoVendaComMargemDeLbZeroUnidadeValue: valor,
          precoVendaComMargemDeLbZeroCaixariaNivel1:
            produto.unidades_caixa_nivel_1 > 0 && valor > 0
              ? formatMoeda(valor * produto.unidades_caixa_nivel_1)
              : '-',
          precoVendaComMargemDeLbZeroCaixariaNivel1Value:
            produto.unidades_caixa_nivel_1 > 0
              ? valor * produto.unidades_caixa_nivel_1
              : 0,
          precoVendaComMargemDeLbZeroCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0 && valor > 0
              ? formatMoeda(
                  valor *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          precoVendaComMargemDeLbZeroCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? valor *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          precoVendaComMargemDeLbZeroCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0 && valor > 0
              ? formatMoeda(
                  valor *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          precoVendaComMargemDeLbZeroCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? valor *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
          /////////////////////////////////////
          verbaUnidade: formatMoeda(verba),
          verbaUnidadeValue: verba,
          verbaCaixariaNivel1:
            produto.unidades_caixa_nivel_1 > 0
              ? formatMoeda(verba * produto.unidades_caixa_nivel_1)
              : '-',
          verbaCaixariaNivel1Value:
            produto.unidades_caixa_nivel_1 > 0
              ? verba * produto.unidades_caixa_nivel_1
              : 0,
          verbaCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0
              ? formatMoeda(
                  verba *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          verbaCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? verba *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          verbaCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0
              ? formatMoeda(
                  verba *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          verbaCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? verba *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
          /////////////////////////////////////
          precoVendaLiberadoMaisVerbaIncluidaUnidade: formatMoeda(
            valor - verba,
          ),
          precoVendaLiberadoMaisVerbaIncluidaUnidadeValue: valor - verba,
          precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel1:
            produto.unidades_caixa_nivel_1 > 0
              ? formatMoeda((valor - verba) * produto.unidades_caixa_nivel_1)
              : '-',
          precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel1Value:
            produto.unidades_caixa_nivel_1 > 0
              ? (valor - verba) * produto.unidades_caixa_nivel_1
              : 0,
          precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0
              ? formatMoeda(
                  (valor - verba) *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? (valor - verba) *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0
              ? formatMoeda(
                  (valor - verba) *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? (valor - verba) *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
          /////////////////////////////////////
          totaisUnidade: formatMoeda((valor - verba) * unidades),
          totaisUnidadeValue: (valor - verba) * unidades,
          totaisCaixariaNivel1:
            produto.unidades_caixa_nivel_1 > 0
              ? formatMoeda(
                  (valor - verba) * unidades * produto.unidades_caixa_nivel_1,
                )
              : '-',
          totaisCaixariaNivel1Value:
            produto.unidades_caixa_nivel_1 > 0
              ? (valor - verba) * unidades * produto.unidades_caixa_nivel_1
              : 0,
          totaisCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0
              ? formatMoeda(
                  (valor - verba) *
                    unidades *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          totaisCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? (valor - verba) *
                unidades *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          totaisCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0
              ? formatMoeda(
                  (valor - verba) *
                    unidades *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          totaisCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? (valor - verba) *
                unidades *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
          //////////////////////////////////////
          precoOfertaJornalUnidade:
            precoOfertaJornal > 0 ? formatMoeda(precoOfertaJornal) : '-',
          precoOfertaJornalUnidadeValue: precoOfertaJornal,
          precoOfertaJornalCaixariaNivel1:
            produto.unidades_caixa_nivel_1 > 0 && precoOfertaJornal > 0
              ? formatMoeda(precoOfertaJornal * produto.unidades_caixa_nivel_1)
              : '-',
          precoOfertaJornalCaixariaNivel1Value:
            produto.unidades_caixa_nivel_1 > 0
              ? precoOfertaJornal * produto.unidades_caixa_nivel_1
              : 0,
          precoOfertaJornalCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0 && precoOfertaJornal > 0
              ? formatMoeda(
                  precoOfertaJornal *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          precoOfertaJornalCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? precoOfertaJornal *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          precoOfertaJornalCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0 && precoOfertaJornal > 0
              ? formatMoeda(
                  precoOfertaJornal *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          precoOfertaJornalCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? precoOfertaJornal *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
          //////////////////////////////////////
          precoOfertaZerarEstoqueUnidade:
            precoOfertaZerarEstoque > 0
              ? formatMoeda(precoOfertaZerarEstoque)
              : '-',
          precoOfertaZerarEstoqueUnidadeValue: precoOfertaZerarEstoque,
          precoOfertaZerarEstoqueCaixariaNivel1:
            produto.unidades_caixa_nivel_1 > 0 && precoOfertaZerarEstoque > 0
              ? formatMoeda(
                  precoOfertaZerarEstoque * produto.unidades_caixa_nivel_1,
                )
              : '-',
          precoOfertaZerarEstoqueCaixariaNivel1Value:
            produto.unidades_caixa_nivel_1 > 0
              ? precoOfertaZerarEstoque * produto.unidades_caixa_nivel_1
              : 0,
          precoOfertaZerarEstoqueCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0 && precoOfertaZerarEstoque > 0
              ? formatMoeda(
                  precoOfertaZerarEstoque *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          precoOfertaZerarEstoqueCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? precoOfertaZerarEstoque *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          precoOfertaZerarEstoqueCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0 && precoOfertaZerarEstoque > 0
              ? formatMoeda(
                  precoOfertaZerarEstoque *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          precoOfertaZerarEstoqueCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? precoOfertaZerarEstoque *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
          //////////////////////////////////////
          precoCompraUnidade: precoCompra > 0 ? formatMoeda(precoCompra) : '-',
          precoCompraUnidadeValue: precoCompra,
          precoCompraCaixariaNivel1:
            produto.unidades_caixa_nivel_1 > 0 && precoCompra > 0
              ? formatMoeda(precoCompra * produto.unidades_caixa_nivel_1)
              : '-',
          precoCompraCaixariaNivel1Value:
            produto.unidades_caixa_nivel_1 > 0
              ? precoCompra * produto.unidades_caixa_nivel_1
              : 0,
          precoCompraCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0 && precoCompra > 0
              ? formatMoeda(
                  precoCompra *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          precoCompraCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? precoCompra *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          precoCompraCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0 && precoCompra > 0
              ? formatMoeda(
                  precoCompra *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          precoCompraCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? precoCompra *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
          //////////////////////////////////////
          precoMinimoUnidade: precoMinimo > 0 ? formatMoeda(precoMinimo) : '-',
          precoMinimoUnidadeValue: precoMinimo,
          precoMinimoCaixariaNivel1:
            produto.unidades_caixa_nivel_1 > 0 && precoMinimo > 0
              ? formatMoeda(precoMinimo * produto.unidades_caixa_nivel_1)
              : '-',
          precoMinimoCaixariaNivel1Value:
            produto.unidades_caixa_nivel_1 > 0
              ? precoMinimo * produto.unidades_caixa_nivel_1
              : 0,
          precoMinimoCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0 && precoMinimo > 0
              ? formatMoeda(
                  precoMinimo *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          precoMinimoCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? precoMinimo *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          precoMinimoCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0 && precoMinimo > 0
              ? formatMoeda(
                  precoMinimo *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          precoMinimoCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? precoMinimo *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
          /////////////////////////////////////
          precoVendaComMargemDeLbZero2Unidade: formatMoeda(
            precoPrecificacaoZero,
          ),
          precoVendaComMargemDeLbZero2UnidadeValue: precoPrecificacaoZero,
          precoVendaComMargemDeLbZero2CaixariaNivel1:
            produto.unidades_caixa_nivel_1 > 0
              ? formatMoeda(
                  precoPrecificacaoZero * produto.unidades_caixa_nivel_1,
                )
              : 0,
          precoVendaComMargemDeLbZero2CaixariaNivel1Value:
            produto.unidades_caixa_nivel_1 > 0
              ? precoPrecificacaoZero * produto.unidades_caixa_nivel_1
              : 0,
          precoVendaComMargemDeLbZero2CaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0
              ? formatMoeda(
                  precoPrecificacaoZero *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          precoVendaComMargemDeLbZero2CaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? precoPrecificacaoZero *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          precoVendaComMargemDeLbZero2CaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0
              ? formatMoeda(
                  precoPrecificacaoZero *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          precoVendaComMargemDeLbZero2CaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? precoPrecificacaoZero *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
        };
      }
      case POR_PESO_VARIAVEL_SEM_UNIDADE: {
        return {
          qtdeUnidade: formatPeso(peso),
          qtdeUnidadeValue: peso,
          qtdeCaixariaNivel1:
            produto.peso_caixa_nivel_1 > 0
              ? formatPreco2(peso / produto.peso_caixa_nivel_1)
              : '-',
          qtdeCaixariaNivel1Value:
            produto.peso_caixa_nivel_1 > 0
              ? peso / produto.peso_caixa_nivel_1
              : 0,
          qtdeCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0
              ? formatPreco2(
                  peso /
                    (produto.peso_caixa_nivel_1 *
                      produto.unidades_caixa_nivel_2),
                )
              : '-',
          qtdeCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? peso /
                (produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2)
              : 0,
          qtdeCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0
              ? formatPreco2(
                  peso /
                    (produto.peso_caixa_nivel_1 *
                      produto.unidades_caixa_nivel_2 *
                      produto.unidades_caixa_nivel_3),
                )
              : '-',
          qtdeCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? peso /
                (produto.peso_caixa_nivel_1 *
                  produto.unidades_caixa_nivel_2 *
                  produto.unidades_caixa_nivel_3)
              : 0,
          //////////////////////////////////////
          precoUnidade: `${formatMoeda(produto.preco_venda)} / Kg`,
          precoUnidadeValue: produto.preco_venda,
          precoCaixariaNivel1:
            produto.peso_caixa_nivel_1 > 0 && produto.preco_venda > 0
              ? formatMoeda(produto.preco_venda * produto.peso_caixa_nivel_1)
              : '-',
          precoCaixariaNivel1Value:
            produto.peso_caixa_nivel_1 > 0
              ? produto.preco_venda * produto.peso_caixa_nivel_1
              : 0,
          precoCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0 && produto.preco_venda > 0
              ? formatMoeda(
                  produto.preco_venda *
                    produto.peso_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          precoCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? produto.preco_venda *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          precoCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0 && produto.preco_venda > 0
              ? formatMoeda(
                  produto.preco_venda *
                    produto.peso_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          precoCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? produto.preco_venda *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
          //////////////////////////////////////
          precoVendaComMargemDeLbZeroUnidade: `${formatMoeda(valor)} / Kg`,
          precoVendaComMargemDeLbZeroUnidadeValue: valor,
          precoVendaComMargemDeLbZeroCaixariaNivel1:
            produto.peso_caixa_nivel_1 > 0
              ? formatMoeda(valor * produto.peso_caixa_nivel_1)
              : '-',
          precoVendaComMargemDeLbZeroCaixariaNivel1Value:
            produto.peso_caixa_nivel_1 > 0
              ? valor * produto.peso_caixa_nivel_1
              : 0,
          precoVendaComMargemDeLbZeroCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0
              ? formatMoeda(
                  valor *
                    produto.peso_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          precoVendaComMargemDeLbZeroCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? valor *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          precoVendaComMargemDeLbZeroCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0
              ? formatMoeda(
                  valor *
                    produto.peso_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          precoVendaComMargemDeLbZeroCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? valor *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
          //////////////////////////////////////
          verbaUnidade: `${formatMoeda(verba)} / Kg`,
          verbaUnidadeValue: verba,
          verbaCaixariaNivel1:
            produto.peso_caixa_nivel_1 > 0
              ? formatMoeda(verba * produto.peso_caixa_nivel_1)
              : '-',
          verbaCaixariaNivel1Value:
            produto.peso_caixa_nivel_1 > 0
              ? verba * produto.peso_caixa_nivel_1
              : 0,
          verbaCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0
              ? formatMoeda(
                  verba *
                    produto.peso_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          verbaCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? verba *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          verbaCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0
              ? formatMoeda(
                  verba *
                    produto.peso_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          verbaCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? verba *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
          //////////////////////////////////////
          precoVendaLiberadoMaisVerbaIncluidaUnidade: `${formatMoeda(
            valor - verba,
          )} / Kg`,
          precoVendaLiberadoMaisVerbaIncluidaUnidadeValue: valor - verba,
          precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel1:
            produto.peso_caixa_nivel_1 > 0
              ? formatMoeda((valor - verba) * produto.peso_caixa_nivel_1)
              : '-',
          precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel1Value:
            produto.peso_caixa_nivel_1 > 0
              ? (valor - verba) * produto.peso_caixa_nivel_1
              : 0,
          precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0
              ? formatMoeda(
                  (valor - verba) *
                    produto.peso_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? (valor - verba) *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,

          precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0
              ? formatMoeda(
                  (valor - verba) *
                    produto.peso_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? (valor - verba) *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
          //////////////////////////////////////
          totaisUnidade: formatMoeda((valor - verba) * peso),
          totaisUnidadeValue: (valor - verba) * peso,
          totaisCaixariaNivel1:
            produto.peso_caixa_nivel_1 > 0
              ? formatMoeda(
                  ((valor - verba) * peso) / produto.peso_caixa_nivel_1,
                )
              : '-',
          totaisCaixariaNivel1Value:
            produto.peso_caixa_nivel_1 > 0
              ? ((valor - verba) * peso) / produto.peso_caixa_nivel_1
              : 0,
          totaisCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0
              ? formatMoeda(
                  ((valor - verba) * peso) /
                    (produto.peso_caixa_nivel_1 *
                      produto.unidades_caixa_nivel_2),
                )
              : '-',
          totaisCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? ((valor - verba) * peso) /
                (produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2)
              : 0,
          totaisCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0
              ? formatMoeda(
                  ((valor - verba) * peso) /
                    (produto.peso_caixa_nivel_1 *
                      produto.unidades_caixa_nivel_2 *
                      produto.unidades_caixa_nivel_3),
                )
              : '-',
          totaisCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? ((valor - verba) * peso) /
                (produto.peso_caixa_nivel_1 *
                  produto.unidades_caixa_nivel_2 *
                  produto.unidades_caixa_nivel_3)
              : 0,
          //////////////////////////////////////
          precoOfertaJornalUnidade:
            precoOfertaJornal > 0
              ? `${formatMoeda(precoOfertaJornal)} / Kg`
              : '-',
          precoOfertaJornalUnidadeValue: precoOfertaJornal,
          precoOfertaJornalCaixariaNivel1:
            produto.peso_caixa_nivel_1 > 0 && precoOfertaJornal > 0
              ? formatMoeda(precoOfertaJornal * produto.peso_caixa_nivel_1)
              : '-',
          precoOfertaJornalCaixariaNivel1Value:
            produto.peso_caixa_nivel_1 > 0
              ? precoOfertaJornal * produto.peso_caixa_nivel_1
              : 0,
          precoOfertaJornalCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0 && precoOfertaJornal > 0
              ? formatMoeda(
                  precoOfertaJornal *
                    produto.peso_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          precoOfertaJornalCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? precoOfertaJornal *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          precoOfertaJornalCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0 && precoOfertaJornal > 0
              ? formatMoeda(
                  precoOfertaJornal *
                    produto.peso_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          precoOfertaJornalCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? precoOfertaJornal *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
          //////////////////////////////////////
          precoOfertaZerarEstoqueUnidade:
            precoOfertaZerarEstoque > 0
              ? `${formatMoeda(precoOfertaZerarEstoque)} / Kg`
              : '-',
          precoOfertaZerarEstoqueUnidadeValue: precoOfertaZerarEstoque,
          precoOfertaZerarEstoqueCaixariaNivel1:
            produto.peso_caixa_nivel_1 > 0 && precoOfertaZerarEstoque > 0
              ? formatMoeda(
                  precoOfertaZerarEstoque * produto.peso_caixa_nivel_1,
                )
              : '-',
          precoOfertaZerarEstoqueCaixariaNivel1Value:
            produto.peso_caixa_nivel_1 > 0
              ? precoOfertaZerarEstoque * produto.peso_caixa_nivel_1
              : 0,
          precoOfertaZerarEstoqueCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0 && precoOfertaZerarEstoque > 0
              ? formatMoeda(
                  precoOfertaZerarEstoque *
                    produto.peso_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          precoOfertaZerarEstoqueCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? precoOfertaZerarEstoque *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          precoOfertaZerarEstoqueCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0 && precoOfertaZerarEstoque > 0
              ? formatMoeda(
                  precoOfertaZerarEstoque *
                    produto.peso_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          precoOfertaZerarEstoqueCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? precoOfertaZerarEstoque *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
          //////////////////////////////////////
          precoCompraUnidade:
            precoCompra > 0 ? `${formatMoeda(precoCompra)} / Kg` : '-',
          precoCompraUnidadeValue: precoCompra,
          precoCompraCaixariaNivel1:
            produto.peso_caixa_nivel_1 > 0 && precoCompra > 0
              ? formatMoeda(precoCompra * produto.peso_caixa_nivel_1)
              : '-',
          precoCompraCaixariaNivel1Value:
            produto.peso_caixa_nivel_1 > 0
              ? precoCompra * produto.peso_caixa_nivel_1
              : 0,
          precoCompraCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0 && precoCompra > 0
              ? formatMoeda(
                  precoCompra *
                    produto.peso_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          precoCompraCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? precoCompra *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          precoCompraCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0 && precoCompra > 0
              ? formatMoeda(
                  precoCompra *
                    produto.peso_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          precoCompraCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? precoCompra *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
          //////////////////////////////////////
          precoMinimoUnidade:
            precoMinimo > 0 ? `${formatMoeda(precoMinimo)} / Kg` : '-',
          precoMinimoUnidadeValue: precoMinimo,
          precoMinimoCaixariaNivel1:
            produto.peso_caixa_nivel_1 > 0 && precoMinimo > 0
              ? formatMoeda(precoMinimo * produto.peso_caixa_nivel_1)
              : '-',
          precoMinimoCaixariaNivel1Value:
            produto.peso_caixa_nivel_1 > 0
              ? precoMinimo * produto.peso_caixa_nivel_1
              : 0,
          precoMinimoCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0 && precoMinimo > 0
              ? formatMoeda(
                  precoMinimo *
                    produto.peso_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          precoMinimoCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? precoMinimo *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          precoMinimoCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0 && precoMinimo > 0
              ? formatMoeda(
                  precoMinimo *
                    produto.peso_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          precoMinimoCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? precoMinimo *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
          //////////////////////////////////////
          precoVendaComMargemDeLbZero2Unidade: `${formatMoeda(
            precoPrecificacaoZero,
          )} / Kg`,
          precoVendaComMargemDeLbZero2UnidadeValue: precoPrecificacaoZero,
          precoVendaComMargemDeLbZero2CaixariaNivel1:
            produto.peso_caixa_nivel_1 > 0
              ? formatMoeda(precoPrecificacaoZero * produto.peso_caixa_nivel_1)
              : '-',
          precoVendaComMargemDeLbZero2CaixariaNivel1Value:
            produto.peso_caixa_nivel_1 > 0
              ? precoPrecificacaoZero * produto.peso_caixa_nivel_1
              : 0,
          precoVendaComMargemDeLbZero2CaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0
              ? formatMoeda(
                  precoPrecificacaoZero *
                    produto.peso_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          precoVendaComMargemDeLbZero2CaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? precoPrecificacaoZero *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          precoVendaComMargemDeLbZero2CaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0
              ? formatMoeda(
                  precoPrecificacaoZero *
                    produto.peso_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          precoVendaComMargemDeLbZero2CaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? precoPrecificacaoZero *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
        };
      }
      case POR_PESO_VARIAVEL: {
        return {
          qtdeUnidade: 0,
          qtdeUnidadeValue: 0,
          qtdeCaixariaNivel1: 0,
          qtdeCaixariaNivel1Value: 0,
          qtdeCaixariaNivel2: 0,
          qtdeCaixariaNivel2Value: 0,
          qtdeCaixariaNivel3: 0,
          qtdeCaixariaNivel3Value: 0,
          //////////////////////////////////////
          precoUnidade: 0,
          precoUnidadeValue: 0,
          precoCaixariaNivel1: 0,
          precoCaixariaNivel1Value: 0,
          precoCaixariaNivel2: 0,
          precoCaixariaNivel2Value: 0,
          precoCaixariaNivel3: 0,
          precoCaixariaNivel3Value: 0,
          ///////////////////////////////////////
          precoVendaComMargemDeLbZeroUnidade: 0,
          precoVendaComMargemDeLbZeroUnidadeValue: 0,
          precoVendaComMargemDeLbZeroCaixariaNivel1: 0,
          precoVendaComMargemDeLbZeroCaixariaNivel1Value: 0,
          precoVendaComMargemDeLbZeroCaixariaNivel2: 0,
          precoVendaComMargemDeLbZeroCaixariaNivel2Value: 0,
          precoVendaComMargemDeLbZeroCaixariaNivel3: 0,
          precoVendaComMargemDeLbZeroCaixariaNivel3Value: 0,
          ///////////////////////////////
          verbaUnidade: 0,
          verbaUnidadeValue: 0,
          verbaCaixariaNivel1: 0,
          verbaCaixariaNivel1Value: 0,
          verbaCaixariaNivel2: 0,
          verbaCaixariaNivel2Value: 0,
          verbaCaixariaNivel3: 0,
          verbaCaixariaNivel3Value: 0,
          ///////////////////////////////
          precoVendaLiberadoMaisVerbaIncluidaUnidade: 0,
          precoVendaLiberadoMaisVerbaIncluidaUnidadeValue: 0,
          precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel1: 0,
          precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel1Value: 0,
          precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel2: 0,
          precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel2Value: 0,
          precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel3: 0,
          precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel3Value: 0,
          ///////////////////////////////
          totaisUnidade: 0,
          totaisUnidadeValue: 0,
          totaisCaixariaNivel1: 0,
          totaisCaixariaNivel1Value: 0,
          totaisCaixariaNivel2: 0,
          totaisCaixariaNivel2Value: 0,
          totaisCaixariaNivel3: 0,
          totaisCaixariaNivel3Value: 0,
          //////////////////////////////////////
          precoOfertaJornalUnidade: 0,
          precoOfertaJornalUnidadeValue: 0,
          precoOfertaJornalCaixariaNivel1: 0,
          precoOfertaJornalCaixariaNivel1Value: 0,
          precoOfertaJornalCaixariaNivel2: 0,
          precoOfertaJornalCaixariaNivel2Value: 0,
          precoOfertaJornalCaixariaNivel3: 0,
          precoOfertaJornalCaixariaNivel3Value: 0,
          //////////////////////////////////////
          precoOfertaZerarEstoqueUnidade: 0,
          precoOfertaZerarEstoqueUnidadeValue: 0,
          precoOfertaZerarEstoqueCaixariaNivel1: 0,
          precoOfertaZerarEstoqueCaixariaNivel1Value: 0,
          precoOfertaZerarEstoqueCaixariaNivel2: 0,
          precoOfertaZerarEstoqueCaixariaNivel2Value: 0,
          precoOfertaZerarEstoqueCaixariaNivel3: 0,
          precoOfertaZerarEstoqueCaixariaNivel3Value: 0,
          //////////////////////////////////////
          precoCompraUnidade: 0,
          precoCompraUnidadeValue: 0,
          precoCompraCaixariaNivel1: 0,
          precoCompraCaixariaNivel1Value: 0,
          precoCompraCaixariaNivel2: 0,
          precoCompraCaixariaNivel2Value: 0,
          precoCompraCaixariaNivel3: 0,
          precoCompraCaixariaNivel3Value: 0,
          //////////////////////////////////////
          precoMinimoUnidade: 0,
          precoMinimoUnidadeValue: 0,
          precoMinimoCaixariaNivel1: 0,
          precoMinimoCaixariaNivel1Value: 0,
          precoMinimoCaixariaNivel2: 0,
          precoMinimoCaixariaNivel2Value: 0,
          precoMinimoCaixariaNivel3: 0,
          precoMinimoCaixariaNivel3Value: 0,
          ///////////////////////////////////////
          precoVendaComMargemDeLbZero2Unidade: 0,
          precoVendaComMargemDeLbZero2UnidadeValue: 0,
          precoVendaComMargemDeLbZero2CaixariaNivel1: 0,
          precoVendaComMargemDeLbZero2CaixariaNivel1Value: 0,
          precoVendaComMargemDeLbZero2CaixariaNivel2: 0,
          precoVendaComMargemDeLbZero2CaixariaNivel2Value: 0,
          precoVendaComMargemDeLbZero2CaixariaNivel3: 0,
          precoVendaComMargemDeLbZero2CaixariaNivel3Value: 0,
        };
      }
      case POR_PESO_FIXO: {
        return {
          qtdeUnidade: `${unidades} Un (${formatPeso(
            unidades * produto.peso_medio,
          )})`,
          qtdeUnidadeValue: unidades,
          qtdeCaixariaNivel1:
            produto.unidades_caixa_nivel_1 > 0
              ? formatPreco2(unidades / produto.unidades_caixa_nivel_1)
              : '-',
          qtdeCaixariaNivel1Value:
            produto.unidades_caixa_nivel_1 > 0
              ? unidades / produto.unidades_caixa_nivel_1
              : 0,
          qtdeCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0
              ? formatPreco2(
                  unidades /
                    (produto.unidades_caixa_nivel_1 *
                      produto.unidades_caixa_nivel_2),
                )
              : '-',
          qtdeCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? unidades /
                (produto.unidades_caixa_nivel_1 *
                  produto.unidades_caixa_nivel_2)
              : 0,
          qtdeCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0
              ? formatPreco2(
                  unidades /
                    (produto.unidades_caixa_nivel_1 *
                      produto.unidades_caixa_nivel_2 *
                      produto.unidades_caixa_nivel_3),
                )
              : '-',
          qtdeCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? unidades /
                (produto.unidades_caixa_nivel_1 *
                  produto.unidades_caixa_nivel_2 *
                  produto.unidades_caixa_nivel_3)
              : 0,
          //////////////////////////////////////
          precoUnidade: `${formatMoeda(produto.preco_venda)} / Kg`,
          precoUnidadeValue: produto.preco_venda,
          precoCaixariaNivel1:
            produto.unidades_caixa_nivel_1 > 0 && produto.preco_venda > 0
              ? formatMoeda(
                  produto.preco_venda *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1,
                )
              : '-',
          precoCaixariaNivel1Value:
            produto.unidades_caixa_nivel_1 > 0
              ? produto.preco_venda *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1
              : 0,
          precoCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0 && produto.preco_venda > 0
              ? formatMoeda(
                  produto.preco_venda *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          precoCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? produto.preco_venda *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          precoCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0 && produto.preco_venda > 0
              ? formatMoeda(
                  produto.preco_venda *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          precoCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? produto.preco_venda *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
          //////////////////////////////////////
          precoVendaComMargemDeLbZeroUnidade: `${formatMoeda(valor)} / Kg`,
          precoVendaComMargemDeLbZeroUnidadeValue: valor,
          precoVendaComMargemDeLbZeroCaixariaNivel1:
            produto.unidades_caixa_nivel_1 > 0
              ? formatMoeda(
                  valor * produto.peso_medio * produto.unidades_caixa_nivel_1,
                )
              : '-',
          precoVendaComMargemDeLbZeroCaixariaNivel1Value:
            produto.unidades_caixa_nivel_1 > 0
              ? valor * produto.peso_medio * produto.unidades_caixa_nivel_1
              : 0,
          precoVendaComMargemDeLbZeroCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0
              ? formatMoeda(
                  valor *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          precoVendaComMargemDeLbZeroCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? valor *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          precoVendaComMargemDeLbZeroCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0
              ? formatMoeda(
                  valor *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          precoVendaComMargemDeLbZeroCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? valor *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
          //////////////////////////////////////
          verbaUnidade: `${formatMoeda(verba)} / Kg`,
          verbaUnidadeValue: verba,
          verbaCaixariaNivel1:
            produto.unidades_caixa_nivel_1 > 0
              ? formatMoeda(
                  verba * produto.peso_medio * produto.unidades_caixa_nivel_1,
                )
              : '-',
          verbaCaixariaNivel1Value:
            produto.unidades_caixa_nivel_1 > 0
              ? verba * produto.peso_medio * produto.unidades_caixa_nivel_1
              : 0,
          verbaCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0
              ? formatMoeda(
                  verba *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          verbaCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? verba *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          verbaCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0
              ? formatMoeda(
                  verba *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          verbaCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? verba *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
          //////////////////////////////////////
          precoVendaLiberadoMaisVerbaIncluidaUnidade: `${formatMoeda(
            valor - verba,
          )} / Kg`,
          precoVendaLiberadoMaisVerbaIncluidaUnidadeValue: valor - verba,

          precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel1:
            produto.unidades_caixa_nivel_1 > 0
              ? formatMoeda(
                  (valor - verba) *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1,
                )
              : '-',
          precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel1Value:
            produto.unidades_caixa_nivel_1 > 0
              ? (valor - verba) *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1
              : 0,
          precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0
              ? formatMoeda(
                  (valor - verba) *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? (valor - verba) *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0
              ? formatMoeda(
                  (valor - verba) *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? (valor - verba) *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
          /////////////////////////////////////
          totaisUnidade: formatMoeda((valor - verba) * unidades),
          totaisUnidadeValue: (valor - verba) * unidades,
          totaisCaixariaNivel1:
            produto.unidades_caixa_nivel_1 > 0
              ? formatMoeda(
                  (valor - verba) *
                    unidades *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1,
                )
              : '-',
          totaisCaixariaNivel1Value:
            produto.unidades_caixa_nivel_1 > 0
              ? (valor - verba) *
                unidades *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1
              : 0,
          totaisCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0
              ? formatMoeda(
                  (valor - verba) *
                    unidades *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          totaisCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? (valor - verba) *
                unidades *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          totaisCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0
              ? formatMoeda(
                  (valor - verba) *
                    unidades *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          totaisCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? (valor - verba) *
                unidades *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
          //////////////////////////////////////
          precoOfertaJornalUnidade:
            precoOfertaJornal > 0
              ? `${formatMoeda(precoOfertaJornal)} / Kg`
              : '-',
          precoOfertaJornalUnidadeValue: precoOfertaJornal,
          precoOfertaJornalCaixariaNivel1:
            produto.unidades_caixa_nivel_1 > 0 && precoOfertaJornal > 0
              ? formatMoeda(
                  precoOfertaJornal *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1,
                )
              : '-',
          precoOfertaJornalCaixariaNivel1Value:
            produto.unidades_caixa_nivel_1 > 0
              ? precoOfertaJornal *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1
              : 0,
          precoOfertaJornalCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0 && precoOfertaJornal > 0
              ? formatMoeda(
                  precoOfertaJornal *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          precoOfertaJornalCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? precoOfertaJornal *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          precoOfertaJornalCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0 && precoOfertaJornal > 0
              ? formatMoeda(
                  precoOfertaJornal *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          precoOfertaJornalCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? precoOfertaJornal *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
          //////////////////////////////////////
          precoOfertaZerarEstoqueUnidade:
            precoOfertaZerarEstoque > 0
              ? `${formatMoeda(precoOfertaZerarEstoque)} / Kg`
              : '-',
          precoOfertaZerarEstoqueUnidadeValue: precoOfertaZerarEstoque,
          precoOfertaZerarEstoqueCaixariaNivel1:
            produto.unidades_caixa_nivel_1 > 0 && precoOfertaZerarEstoque > 0
              ? formatMoeda(
                  precoOfertaZerarEstoque *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1,
                )
              : '-',
          precoOfertaZerarEstoqueCaixariaNivel1Value:
            produto.unidades_caixa_nivel_1 > 0
              ? precoOfertaZerarEstoque *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1
              : 0,
          precoOfertaZerarEstoqueCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0 && precoOfertaZerarEstoque > 0
              ? formatMoeda(
                  precoOfertaZerarEstoque *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          precoOfertaZerarEstoqueCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? precoOfertaZerarEstoque *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          precoOfertaZerarEstoqueCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0 && precoOfertaZerarEstoque > 0
              ? formatMoeda(
                  precoOfertaZerarEstoque *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          precoOfertaZerarEstoqueCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? precoOfertaZerarEstoque *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
          //////////////////////////////////////
          precoCompraUnidade:
            precoCompra > 0 ? `${formatMoeda(precoCompra)} / Kg` : '-',
          precoCompraUnidadeValue: precoCompra,
          precoCompraCaixariaNivel1:
            produto.unidades_caixa_nivel_1 > 0 && precoCompra > 0
              ? formatMoeda(
                  precoCompra *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1,
                )
              : '-',
          precoCompraCaixariaNivel1Value:
            produto.unidades_caixa_nivel_1 > 0
              ? precoCompra *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1
              : 0,
          precoCompraCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0 && precoCompra > 0
              ? formatMoeda(
                  precoCompra *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          precoCompraCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? precoCompra *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          precoCompraCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0 && precoCompra > 0
              ? formatMoeda(
                  precoCompra *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          precoCompraCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? precoCompra *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
          //////////////////////////////////////
          precoMinimoUnidade:
            precoMinimo > 0 ? `${formatMoeda(precoMinimo)} / Kg` : '-',
          precoMinimoUnidadeValue: precoMinimo,
          precoMinimoCaixariaNivel1:
            produto.unidades_caixa_nivel_1 > 0 && precoMinimo > 0
              ? formatMoeda(
                  precoMinimo *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1,
                )
              : '-',
          precoMinimoCaixariaNivel1Value:
            produto.unidades_caixa_nivel_1 > 0
              ? precoMinimo *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1
              : 0,
          precoMinimoCaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0 && precoMinimo > 0
              ? formatMoeda(
                  precoMinimo *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          precoMinimoCaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? precoMinimo *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          precoMinimoCaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0 && precoMinimo > 0
              ? formatMoeda(
                  precoMinimo *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          precoMinimoCaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? precoMinimo *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
          //////////////////////////////////////
          precoVendaComMargemDeLbZero2Unidade: `${formatMoeda(
            precoPrecificacaoZero,
          )} / Kg`,
          precoVendaComMargemDeLbZero2UnidadeValue: precoPrecificacaoZero,
          precoVendaComMargemDeLbZero2CaixariaNivel1:
            produto.unidades_caixa_nivel_1 > 0
              ? formatMoeda(
                  precoPrecificacaoZero *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1,
                )
              : '-',
          precoVendaComMargemDeLbZero2CaixariaNivel1Value:
            produto.unidades_caixa_nivel_1 > 0
              ? precoPrecificacaoZero *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1
              : 0,
          precoVendaComMargemDeLbZero2CaixariaNivel2:
            produto.unidades_caixa_nivel_2 > 0
              ? formatMoeda(
                  precoPrecificacaoZero *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2,
                )
              : '-',
          precoVendaComMargemDeLbZero2CaixariaNivel2Value:
            produto.unidades_caixa_nivel_2 > 0
              ? precoPrecificacaoZero *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              : 0,
          precoVendaComMargemDeLbZero2CaixariaNivel3:
            produto.unidades_caixa_nivel_3 > 0
              ? formatMoeda(
                  precoPrecificacaoZero *
                    produto.peso_medio *
                    produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3,
                )
              : '-',
          precoVendaComMargemDeLbZero2CaixariaNivel3Value:
            produto.unidades_caixa_nivel_3 > 0
              ? precoPrecificacaoZero *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              : 0,
        };
      }
    }
  }

  return {
    qtdeUnidade: 0,
    qtdeUnidadeValue: 0,
    qtdeCaixariaNivel1: 0,
    qtdeCaixariaNivel1Value: 0,
    qtdeCaixariaNivel2: 0,
    qtdeCaixariaNivel2Value: 0,
    qtdeCaixariaNivel3: 0,
    qtdeCaixariaNivel3Value: 0,
    //////////////////////////////////////
    precoUnidade: 0,
    precoUnidadeValue: 0,
    precoCaixariaNivel1: 0,
    precoCaixariaNivel1Value: 0,
    precoCaixariaNivel2: 0,
    precoCaixariaNivel2Value: 0,
    precoCaixariaNivel3: 0,
    precoCaixariaNivel3Value: 0,
    ///////////////////////////////////////
    precoVendaComMargemDeLbZeroUnidade: 0,
    precoVendaComMargemDeLbZeroUnidadeValue: 0,
    precoVendaComMargemDeLbZeroCaixariaNivel1: 0,
    precoVendaComMargemDeLbZeroCaixariaNivel1Value: 0,
    precoVendaComMargemDeLbZeroCaixariaNivel2: 0,
    precoVendaComMargemDeLbZeroCaixariaNivel2Value: 0,
    precoVendaComMargemDeLbZeroCaixariaNivel3: 0,
    precoVendaComMargemDeLbZeroCaixariaNivel3Value: 0,
    ///////////////////////////////
    verbaUnidade: 0,
    verbaUnidadeValue: 0,
    verbaCaixariaNivel1: 0,
    verbaCaixariaNivel1Value: 0,
    verbaCaixariaNivel2: 0,
    verbaCaixariaNivel2Value: 0,
    verbaCaixariaNivel3: 0,
    verbaCaixariaNivel3Value: 0,
    ///////////////////////////////
    precoVendaLiberadoMaisVerbaIncluidaUnidade: 0,
    precoVendaLiberadoMaisVerbaIncluidaUnidadeValue: 0,
    precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel1: 0,
    precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel1Value: 0,
    precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel2: 0,
    precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel2Value: 0,
    precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel3: 0,
    precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel3Value: 0,
    ///////////////////////////////
    totaisUnidade: 0,
    totaisUnidadeValue: 0,
    totaisCaixariaNivel1: 0,
    totaisCaixariaNivel1Value: 0,
    totaisCaixariaNivel2: 0,
    totaisCaixariaNivel2Value: 0,
    totaisCaixariaNivel3: 0,
    totaisCaixariaNivel3Value: 0,
    //////////////////////////////////////
    precoOfertaJornalUnidade: 0,
    precoOfertaJornalUnidadeValue: 0,
    precoOfertaJornalCaixariaNivel1: 0,
    precoOfertaJornalCaixariaNivel1Value: 0,
    precoOfertaJornalCaixariaNivel2: 0,
    precoOfertaJornalCaixariaNivel2Value: 0,
    precoOfertaJornalCaixariaNivel3: 0,
    precoOfertaJornalCaixariaNivel3Value: 0,
    //////////////////////////////////////
    precoOfertaZerarEstoqueUnidade: 0,
    precoOfertaZerarEstoqueUnidadeValue: 0,
    precoOfertaZerarEstoqueCaixariaNivel1: 0,
    precoOfertaZerarEstoqueCaixariaNivel1Value: 0,
    precoOfertaZerarEstoqueCaixariaNivel2: 0,
    precoOfertaZerarEstoqueCaixariaNivel2Value: 0,
    precoOfertaZerarEstoqueCaixariaNivel3: 0,
    precoOfertaZerarEstoqueCaixariaNivel3Value: 0,
    //////////////////////////////////////
    precoCompraUnidade: 0,
    precoCompraUnidadeValue: 0,
    precoCompraCaixariaNivel1: 0,
    precoCompraCaixariaNivel1Value: 0,
    precoCompraCaixariaNivel2: 0,
    precoCompraCaixariaNivel2Value: 0,
    precoCompraCaixariaNivel3: 0,
    precoCompraCaixariaNivel3Value: 0,
    //////////////////////////////////////
    precoMinimoUnidade: 0,
    precoMinimoUnidadeValue: 0,
    precoMinimoCaixariaNivel1: 0,
    precoMinimoCaixariaNivel1Value: 0,
    precoMinimoCaixariaNivel2: 0,
    precoMinimoCaixariaNivel2Value: 0,
    precoMinimoCaixariaNivel3: 0,
    precoMinimoCaixariaNivel3Value: 0,
    ///////////////////////////////////////
    precoVendaComMargemDeLbZero2Unidade: 0,
    precoVendaComMargemDeLbZero2UnidadeValue: 0,
    precoVendaComMargemDeLbZero2CaixariaNivel1: 0,
    precoVendaComMargemDeLbZero2CaixariaNivel1Value: 0,
    precoVendaComMargemDeLbZero2CaixariaNivel2: 0,
    precoVendaComMargemDeLbZero2CaixariaNivel2Value: 0,
    precoVendaComMargemDeLbZero2CaixariaNivel3: 0,
    precoVendaComMargemDeLbZero2CaixariaNivel3Value: 0,
  };
}

const TAMANHO_PEQUENO = 'sm';
const TAMANHO_GRANDE = 'lg';

function convertTipoUnidadeOfertaQuantidade(
  unidadeAtual,
  unidadeAlvo,
  valor,
  produto,
) {
  const fatorCaixa1 = produto
    ? produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
      ? produto.peso_caixa_nivel_1
      : produto.unidades_caixa_nivel_1
    : 1;

  const fatorPesoMedio = produto
    ? produto.unidade.tipo === POR_PESO_FIXO
      ? 1
      : 1
    : 1;
  switch (unidadeAtual) {
    case TIPO_UNIDADE_OFERTA_KG: {
      switch (unidadeAlvo) {
        case TIPO_UNIDADE_OFERTA_KG: {
          return valor;
        }
        case TIPO_UNIDADE_OFERTA_UN: {
          return valor;
        }
        case TIPO_UNIDADE_OFERTA_CAIXA: {
          return valor / (fatorPesoMedio * fatorCaixa1);
        }
        case TIPO_UNIDADE_OFERTA_CAIXETA: {
          return (
            valor /
            (fatorPesoMedio * fatorCaixa1 * produto.unidades_caixa_nivel_2)
          );
        }
        case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
          return (
            valor /
            (fatorPesoMedio *
              fatorCaixa1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3)
          );
        }
        default: {
          return 0;
        }
      }
    }
    case TIPO_UNIDADE_OFERTA_UN: {
      switch (unidadeAlvo) {
        case TIPO_UNIDADE_OFERTA_KG: {
          return valor;
        }
        case TIPO_UNIDADE_OFERTA_UN: {
          return valor;
        }
        case TIPO_UNIDADE_OFERTA_CAIXA: {
          return valor / (fatorPesoMedio * fatorCaixa1);
        }
        case TIPO_UNIDADE_OFERTA_CAIXETA: {
          return (
            valor /
            (fatorPesoMedio * fatorCaixa1 * produto.unidades_caixa_nivel_2)
          );
        }
        case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
          return (
            valor /
            (fatorPesoMedio *
              fatorCaixa1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3)
          );
        }
        default: {
          return 0;
        }
      }
    }
    case TIPO_UNIDADE_OFERTA_CAIXA: {
      switch (unidadeAlvo) {
        case TIPO_UNIDADE_OFERTA_KG: {
          return valor * (fatorPesoMedio * fatorCaixa1);
        }
        case TIPO_UNIDADE_OFERTA_UN: {
          return valor * (fatorPesoMedio * fatorCaixa1);
        }
        case TIPO_UNIDADE_OFERTA_CAIXA: {
          return valor;
        }
        case TIPO_UNIDADE_OFERTA_CAIXETA: {
          return valor / produto.unidades_caixa_nivel_2;
        }
        case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
          return (
            valor /
            (produto.unidades_caixa_nivel_2 * produto.unidades_caixa_nivel_3)
          );
        }
        default: {
          return 0;
        }
      }
    }
    case TIPO_UNIDADE_OFERTA_CAIXETA: {
      switch (unidadeAlvo) {
        case TIPO_UNIDADE_OFERTA_KG: {
          return (
            valor *
            (fatorPesoMedio * fatorCaixa1 * produto.unidades_caixa_nivel_2)
          );
        }
        case TIPO_UNIDADE_OFERTA_UN: {
          return (
            valor *
            (fatorPesoMedio * fatorCaixa1 * produto.unidades_caixa_nivel_2)
          );
        }
        case TIPO_UNIDADE_OFERTA_CAIXA: {
          return valor * fatorCaixa1;
        }
        case TIPO_UNIDADE_OFERTA_CAIXETA: {
          return valor;
        }
        case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
          return valor / produto.unidades_caixa_nivel_3;
        }
        default: {
          return 0;
        }
      }
    }
    case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
      switch (unidadeAlvo) {
        case TIPO_UNIDADE_OFERTA_KG: {
          return (
            valor *
            (fatorPesoMedio *
              fatorCaixa1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3)
          );
        }
        case TIPO_UNIDADE_OFERTA_UN: {
          return (
            valor *
            (fatorPesoMedio *
              fatorCaixa1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3)
          );
        }
        case TIPO_UNIDADE_OFERTA_CAIXA: {
          return (
            valor *
            (produto.unidades_caixa_nivel_2 * produto.unidades_caixa_nivel_3)
          );
        }
        case TIPO_UNIDADE_OFERTA_CAIXETA: {
          return valor * (fatorPesoMedio * produto.unidades_caixa_nivel_3);
        }
        case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
          return valor;
        }
        default: {
          return 0;
        }
      }
    }
    default: {
      return 0;
    }
  }
}

function convertTipoUnidadeOferta(unidadeAtual, unidadeAlvo, valor, produto) {
  const fatorCaixa1 = produto
    ? produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
      ? produto.peso_caixa_nivel_1
      : produto.unidades_caixa_nivel_1
    : 1;

  const fatorPesoMedio = produto
    ? produto.unidade.tipo === POR_PESO_FIXO
      ? produto.peso_medio
      : 1
    : 1;
  switch (unidadeAtual) {
    case TIPO_UNIDADE_OFERTA_KG: {
      switch (unidadeAlvo) {
        case TIPO_UNIDADE_OFERTA_KG: {
          return valor;
        }
        case TIPO_UNIDADE_OFERTA_UN: {
          return valor;
        }
        case TIPO_UNIDADE_OFERTA_CAIXA: {
          return valor * fatorPesoMedio * fatorCaixa1;
        }
        case TIPO_UNIDADE_OFERTA_CAIXETA: {
          return (
            valor *
            fatorPesoMedio *
            fatorCaixa1 *
            produto.unidades_caixa_nivel_2
          );
        }
        case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
          return (
            valor *
            fatorPesoMedio *
            fatorCaixa1 *
            produto.unidades_caixa_nivel_2 *
            produto.unidades_caixa_nivel_3
          );
        }
        default: {
          return 0;
        }
      }
    }
    case TIPO_UNIDADE_OFERTA_UN: {
      switch (unidadeAlvo) {
        case TIPO_UNIDADE_OFERTA_KG: {
          return valor;
        }
        case TIPO_UNIDADE_OFERTA_UN: {
          return valor;
        }
        case TIPO_UNIDADE_OFERTA_CAIXA: {
          return valor * fatorPesoMedio * fatorCaixa1;
        }
        case TIPO_UNIDADE_OFERTA_CAIXETA: {
          return (
            valor *
            fatorPesoMedio *
            fatorCaixa1 *
            produto.unidades_caixa_nivel_2
          );
        }
        case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
          return (
            valor *
            fatorPesoMedio *
            fatorCaixa1 *
            produto.unidades_caixa_nivel_2 *
            produto.unidades_caixa_nivel_3
          );
        }
        default: {
          return 0;
        }
      }
    }
    case TIPO_UNIDADE_OFERTA_CAIXA: {
      switch (unidadeAlvo) {
        case TIPO_UNIDADE_OFERTA_KG: {
          return valor / (fatorPesoMedio * fatorCaixa1);
        }
        case TIPO_UNIDADE_OFERTA_UN: {
          return valor / (fatorPesoMedio * fatorCaixa1);
        }
        case TIPO_UNIDADE_OFERTA_CAIXA: {
          return valor;
        }
        case TIPO_UNIDADE_OFERTA_CAIXETA: {
          return valor * produto.unidades_caixa_nivel_2;
        }
        case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
          return (
            valor *
            produto.unidades_caixa_nivel_2 *
            produto.unidades_caixa_nivel_3
          );
        }
        default: {
          return 0;
        }
      }
    }
    case TIPO_UNIDADE_OFERTA_CAIXETA: {
      switch (unidadeAlvo) {
        case TIPO_UNIDADE_OFERTA_KG: {
          return (
            valor /
            (fatorPesoMedio * fatorCaixa1 * produto.unidades_caixa_nivel_2)
          );
        }
        case TIPO_UNIDADE_OFERTA_UN: {
          return (
            valor /
            (fatorPesoMedio * fatorCaixa1 * produto.unidades_caixa_nivel_2)
          );
        }
        case TIPO_UNIDADE_OFERTA_CAIXA: {
          return valor / produto.unidades_caixa_nivel_2;
        }
        case TIPO_UNIDADE_OFERTA_CAIXETA: {
          return valor;
        }
        case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
          return valor * produto.unidades_caixa_nivel_3;
        }
        default: {
          return 0;
        }
      }
    }
    case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
      switch (unidadeAlvo) {
        case TIPO_UNIDADE_OFERTA_KG: {
          return (
            valor /
            (fatorPesoMedio *
              fatorCaixa1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3)
          );
        }
        case TIPO_UNIDADE_OFERTA_UN: {
          return (
            valor /
            (fatorPesoMedio *
              fatorCaixa1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3)
          );
        }
        case TIPO_UNIDADE_OFERTA_CAIXA: {
          return (
            valor /
            (produto.unidades_caixa_nivel_2 * produto.unidades_caixa_nivel_3)
          );
        }
        case TIPO_UNIDADE_OFERTA_CAIXETA: {
          return valor / produto.unidades_caixa_nivel_3;
        }
        case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
          return valor;
        }
        default: {
          return 0;
        }
      }
    }
    default: {
      return 0;
    }
  }
}

function getValorFormatadoEvento(produto, tipoUnidadeOferta, valor) {
  if (produto) {
    switch (produto.unidade.tipo) {
      case POR_UNIDADE: {
        switch (tipoUnidadeOferta) {
          case TIPO_UNIDADE_OFERTA_UN: {
            return valor;
          }
          case TIPO_UNIDADE_OFERTA_CAIXA: {
            return valor * produto.unidades_caixa_nivel_1;
          }
          case TIPO_UNIDADE_OFERTA_CAIXETA: {
            return (
              valor *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2
            );
          }
          case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
            return (
              valor *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3
            );
          }
          default: {
            return 0;
          }
        }
      }
      case POR_PESO_VARIAVEL_SEM_UNIDADE: {
        switch (tipoUnidadeOferta) {
          case TIPO_UNIDADE_OFERTA_KG: {
            return valor;
          }
          case TIPO_UNIDADE_OFERTA_CAIXA: {
            return valor * produto.peso_caixa_nivel_1;
          }
          case TIPO_UNIDADE_OFERTA_CAIXETA: {
            return (
              valor *
              produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2
            );
          }
          case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
            return (
              valor *
              produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3
            );
          }
          default: {
            return 0;
          }
        }
      }
      case POR_PESO_VARIAVEL: {
        return 0;
      }
      case POR_PESO_FIXO: {
        switch (tipoUnidadeOferta) {
          case TIPO_UNIDADE_OFERTA_UN: {
            return valor;
          }
          case TIPO_UNIDADE_OFERTA_CAIXA: {
            return valor * produto.unidades_caixa_nivel_1 * produto.peso_medio;
          }
          case TIPO_UNIDADE_OFERTA_CAIXETA: {
            return (
              valor *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.peso_medio
            );
          }
          case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
            return (
              valor *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3 *
              produto.peso_medio
            );
          }
          default: {
            return 0;
          }
        }
      }
      default: {
        return 0;
      }
    }
  }

  return 0;
}

const DialogoCategoriaTransacao = forwardRef(
  (
    {
      handleSalvar2 = () => {},
      handleClose,
      cliente = null,
      tipoStatusAndamento,
    },
    ref,
  ) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [id, setId] = useState(-1);
    //const [produto, setProduto] = useState(null);
    //const [valor, setValor] = useState(0);
    //const [tipoUnidadeOferta, setTipoUnidadeUnidade] = useState(
    //TIPO_UNIDADE_OFERTA_UN,
    //);
    //const [qtde2, setQtde] = useState(0);

    //const [config, setConfig] = useState(null);
    //const [dadosPrecificacaoAnterior, setDadosPrecificacaoAnterior] = useState(
    // null,
    //);
    //const [quantidadeComprada, setQuantidadeComprada] = useState(0);
    //const [
    //valorNegociadoComAIndustria,
    //setValorNegociadoComAIndustria,
    //] = useState(null);
    //const [fornecedor, setFornecedor] = useState(null);
    const classes = useStyles();
    const [tamanho, setTamanho] = useState(TAMANHO_GRANDE);
    const [carregando, setCarregando] = useState(false);
    const refDialogoPrecificacaoLiberacao = useRef(null);
    //const [dadosPrecificacao, setDadosPrecificacao] = useState(null);
    //const [isMargemCartao, setIsMargemCartao] = useState(true);
    //const [verbaUnitaria2, setVerbaUnitaria2] = useState(0);
    //const [manifestacaoCliente, setManifestacaoCliente] = useState('aceito');
    //const [precoSugerido, setPrecoSugerido] = useState(0);
    //const [loteId, setLoteId] = useState(0);
    const refVerbaUnitaria = useRef(0);
    const [cont, setCont] = useState(0);
    //const [precoSugeridoOriginal, setPrecoSugeridoOriginal] = useState(0);
    //const [isNegociacaoEncerrada, setIsNegociacaoEncerrada] = useState(false);
    //const [verbaUnitariaOriginal, setVerbaUnitariaOriginal] = useState(0);
    //const [
    //tipoUnidadeOfertaQuantidade,
    //setTipoUnidadeUnidadeQuantidade,
    //] = useState(TIPO_UNIDADE_OFERTA_UN);

    //const [
    //tipoUnidadeOfertaPrecoSugerido,
    //setTipoUnidadeUnidadePrecoSugerido,
    //] = useState(TIPO_UNIDADE_OFERTA_UN);

    const [dados, setDados] = useState({
      produto: null,
      valor: 0,
      tipoUnidadeOferta: TIPO_UNIDADE_OFERTA_UN,
      qtde2: 0,
      config: null,
      dadosPrecificacaoAnterior: null,
      quantidadeComprada: 0,
      valorNegociadoComAIndustria: 0,
      fornecedor: null,
      dadosPrecificacao: null,
      isMargemCartao: true,
      verbaUnitaria2: 0,
      manifestacaoCliente: 'aceito',
      precoSugerido: 0,
      loteId: 0,
      precoSugeridoOriginal: 0,
      isNegociacaoEncerrada: false,
      verbaUnitariaOriginal: 0,
      tipoUnidadeOfertaQuantidade: TIPO_UNIDADE_OFERTA_UN,
      tipoUnidadeOfertaPrecoSugerido: TIPO_UNIDADE_OFERTA_UN,
    });

    /* changeState({
      valor: value.target.value,
    }); */

    const {
      config,
      dadosPrecificacao,
      dadosPrecificacaoAnterior,
      fornecedor,
      isMargemCartao,
      isNegociacaoEncerrada,
      loteId,
      manifestacaoCliente,
      precoSugerido,
      precoSugeridoOriginal,
      produto,

      qtde2,
      quantidadeComprada,
      tipoUnidadeOferta,
      tipoUnidadeOfertaPrecoSugerido,
      tipoUnidadeOfertaQuantidade,
      valor,
      valorNegociadoComAIndustria,
      verbaUnitaria2,
      verbaUnitariaOriginal,
    } = dados;

    const optionsManifestacaoCliente = isNegociacaoEncerrada
      ? optionsManifestacaoCliente2
      : optionsManifestacaoCliente1;

    const qtde = convertTipoUnidadeOfertaQuantidade(
      tipoUnidadeOfertaQuantidade,
      tipoUnidadeOferta,
      qtde2,
      produto,
    );

    /* const verbaUnitaria =
      tipoStatusAndamento === tipoStatusAndamentoCriarPedido
        ? refVerbaUnitaria.current
        : verbaUnitaria2;

    useEffect(() => {
      if (tipoStatusAndamento === tipoStatusAndamentoCriarPedido) {
        const verbaUnitaria =
          getValorFormatadoEvento(produto, tipoUnidadeOferta, valor) -
          precoSugerido;
        refVerbaUnitaria.current = verbaUnitaria;
        setCont(cont + 1);
      }
    }, [precoSugerido, tipoUnidadeOferta, valor]); */

    const verbaUnitaria = refVerbaUnitaria.current;

    useEffect(() => {
      const temp = getValorFormatadoEvento(
        produto,
        tipoUnidadeOfertaPrecoSugerido,
        valor,
      );
      let verbaUnitaria = temp - precoSugerido;

      verbaUnitaria = convertTipoUnidadeOferta(
        tipoUnidadeOfertaPrecoSugerido,
        tipoUnidadeOferta,
        verbaUnitaria,
        produto,
      );

      refVerbaUnitaria.current = verbaUnitaria;
      setCont(cont + 1);
    }, [precoSugerido, tipoUnidadeOferta, valor]);

    function changeState(valueUpdate) {
      setDados({
        ...dados,
        ...valueUpdate,
      });
    }

    const handleChange = (event) => {
      changeState({
        tipoUnidadeOferta: event.target.value,
      });
    };

    const handleCloseDialog = (status) => {
      setOpen(false);
      handleClose(status, -1);
    };

    async function getProduto(id) {
      const data = await getManyAPI(
        'produtos/search/autocomplete',
        { id: [id], ofertasProdutos: true },
        ['dadosImposto', 'unidade'],
      );
      if (data.data.length > 0) {
        const produto = data.data[0];
        return {
          ...produto,
          preco_oferta: getPrecoUnitarioVerdadeiroCaixa2(
            { ...produto, nivelCaixa: 0 },
            0,
            0,
          ),
        };
      }
      throw 'Este produto não existe!';
    }

    function getUnidadeInicial(produto) {
      if (produto) {
        const tipo = produto.unidade.tipo;
        switch (tipo) {
          case POR_UNIDADE:
            return TIPO_UNIDADE_OFERTA_UN;
          case POR_PESO_FIXO:
            return TIPO_UNIDADE_OFERTA_UN;
          case POR_PESO_VARIAVEL:
            return TIPO_UNIDADE_OFERTA_KG;
          case POR_PESO_VARIAVEL_SEM_UNIDADE:
            return TIPO_UNIDADE_OFERTA_KG;
          default:
            return TIPO_UNIDADE_OFERTA_UN;
        }
      }
      return TIPO_UNIDADE_OFERTA_UN;
    }

    async function getConfig() {
      const config = await getListAllAPI('config', ['id', 'asc'], { id: 1 }, [
        'cidade.estado',
      ]);
      if (config.data.length <= 0) {
        throw 'Configurações inacessíveis!';
      }
      return config.data[0];
    }

    async function getDadosPrecificacaoAnterior(produto_id) {
      const dados = await api.get(`/get_ultimo_lote_precificado/${produto_id}`);
      return dados.data;
    }

    async function getDadosPrecificacaoAnterior2(dados_precificacao_id) {
      const dados = await api.get(
        `/get_dados_precificacao_pedido_liberacao/${dados_precificacao_id}`,
      );
      return dados.data;
    }

    async function verificaDuplicidade(cliente_id, produto_id) {
      //try {
      /* const dados = await api.get(
        `/verificar_duplicidade_pedido_liberacao/${produto_id}/${cliente_id}`,
      );
      return dados.data.totalTotal; */
      /* } catch (e) {
        return -1;
      } */
      return 0;
    }

    async function reiniciar(produto, isMargemCartao) {
      try {
        setId(-1);
        const qtdeDuplicidade = await verificaDuplicidade(
          cliente.id,
          produto.id,
        );
        if (qtdeDuplicidade > 0)
          throw 'Já existe este item em um pedido ativo para este mesmo cliente!';
        const produto2 = await getProduto(produto.id);
        //setProduto(produto2);
        const config = await getConfig();
        //setConfig(await getConfig());
        const {
          fornecedor,
          dadosPrecificacaoAnterior,
          quantidadeComprada,
          valorNegociadoComAIndustria,
          lote_id,
        } = await getDadosPrecificacaoAnterior(produto.id);
        //setLoteId(lote_id);
        //setFornecedor(fornecedor);
        //setDadosPrecificacaoAnterior(dadosPrecificacaoAnterior);
        //setQuantidadeComprada(quantidadeComprada);
        //setValorNegociadoComAIndustria(valorNegociadoComAIndustria);
        /* setValor(
          dadosPrecificacaoAnterior
            ? dadosPrecificacaoAnterior.preco_de_venda
            : 0,
        ); */
        //setValor(0);
        setTamanho(TAMANHO_GRANDE);
        //setQtde(0);
        const unidadeInicial = getUnidadeInicial(produto);
        //setTipoUnidadeUnidade(unidadeInicial);
        //setIsMargemCartao(isMargemCartao);
        //setVerbaUnitaria2(0);
        refVerbaUnitaria.current = 0;
        setCarregando(false);
        //setManifestacaoCliente('aceito');
        //setPrecoSugerido(0);
        //setPrecoSugeridoOriginal(0);
        //setIsNegociacaoEncerrada(false);
        //setTipoUnidadeUnidadeQuantidade(unidadeInicial);
        //setTipoUnidadeUnidadePrecoSugerido(unidadeInicial);
        //setVerbaUnitariaOriginal(0);

        setDados({
          produto: produto2,
          valor: 0,
          tipoUnidadeOferta: unidadeInicial,
          qtde2: 0,
          config,
          dadosPrecificacaoAnterior,
          quantidadeComprada,
          valorNegociadoComAIndustria,
          fornecedor,
          dadosPrecificacao: null,
          isMargemCartao,
          verbaUnitaria2: 0,
          manifestacaoCliente: 'aceito',
          precoSugerido: 0,
          loteId: lote_id,
          precoSugeridoOriginal: 0,
          isNegociacaoEncerrada: false,
          verbaUnitariaOriginal: 0,
          tipoUnidadeOfertaQuantidade: unidadeInicial,
          tipoUnidadeOfertaPrecoSugerido: unidadeInicial,
        });
        if (refDialogoPrecificacaoLiberacao.current) {
          refDialogoPrecificacaoLiberacao.current.handleOpen(isMargemCartao);
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog(0);
      }
    }

    function getVerbaFormatadaInit(produto, tipoUnidadeOferta, verba_unitaria) {
      switch (produto.unidade.tipo) {
        case POR_UNIDADE: {
          switch (tipoUnidadeOferta) {
            case TIPO_UNIDADE_OFERTA_UN: {
              return verba_unitaria;
            }
            case TIPO_UNIDADE_OFERTA_CAIXA: {
              return verba_unitaria * produto.unidades_caixa_nivel_1;
            }
            case TIPO_UNIDADE_OFERTA_CAIXETA: {
              return (
                verba_unitaria *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              );
            }
            case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
              return (
                verba_unitaria *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              );
            }
            default: {
              return 0;
            }
          }
        }
        case POR_PESO_VARIAVEL_SEM_UNIDADE: {
          switch (tipoUnidadeOferta) {
            case TIPO_UNIDADE_OFERTA_KG: {
              return verba_unitaria;
            }
            case TIPO_UNIDADE_OFERTA_CAIXA: {
              return verba_unitaria * produto.peso_caixa_nivel_1;
            }
            case TIPO_UNIDADE_OFERTA_CAIXETA: {
              return (
                verba_unitaria *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              );
            }
            case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
              return (
                verba_unitaria *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              );
            }
            default: {
              return 0;
            }
          }
        }
        case POR_PESO_VARIAVEL: {
          return 0;
        }
        case POR_PESO_FIXO: {
          switch (tipoUnidadeOferta) {
            case TIPO_UNIDADE_OFERTA_UN: {
              return verba_unitaria;
            }
            case TIPO_UNIDADE_OFERTA_CAIXA: {
              return (
                verba_unitaria *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1
              );
            }
            case TIPO_UNIDADE_OFERTA_CAIXETA: {
              return (
                verba_unitaria *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              );
            }
            case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
              return (
                verba_unitaria *
                produto.peso_medio *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              );
            }
            default: {
              return 0;
            }
          }
        }
      }

      return 0;
    }

    async function getDataResource(
      id,
      valor,
      tipoUnidadeOferta,
      produto,
      qtde,
      dadosPrecificacao,
      verba_unitaria,
      isMargemCartao,
      verba_unitaria_original,
      isPrecificacaoLocal,
      isNegociacaoEncerrada,
      tipoUnidadeOfertaQuantidade,
      tipoUnidadeOfertaPrecoSugerido,
    ) {
      try {
        setId(id);
        const produto2 = await getProduto(produto.id);
        //setProduto(produto2);

        const config = await getConfig();
        //setConfig(config);

        let fornecedor = 0;
        let dadosPrecificacaoAnterior = 0;
        let quantidadeComprada = 0;
        let valorNegociadoComAIndustria = 0;

        if (isPrecificacaoLocal) {
          const dados = await getDadosPrecificacaoAnterior(produto.id);
          fornecedor = dados.fornecedor;
          dadosPrecificacaoAnterior = dados.dadosPrecificacaoAnterior;
          quantidadeComprada = dados.quantidadeComprada;
          valorNegociadoComAIndustria = dados.valorNegociadoComAIndustria;
        } else {
          const dados = await getDadosPrecificacaoAnterior2(
            dadosPrecificacao.id,
          );
          fornecedor = dados.fornecedor;
          dadosPrecificacaoAnterior = dados.dadosPrecificacaoAnterior;
          quantidadeComprada = dados.quantidadeComprada;
          valorNegociadoComAIndustria = dados.valorNegociadoComAIndustria;
        }

        //setFornecedor(fornecedor);
        //setDadosPrecificacaoAnterior(dadosPrecificacaoAnterior);
        //setQuantidadeComprada(quantidadeComprada);
        //setValorNegociadoComAIndustria(valorNegociadoComAIndustria);
        //setValor(valor);
        setTamanho(TAMANHO_GRANDE);

        //setTipoUnidadeUnidade(tipoUnidadeOferta);
        //setIsMargemCartao(isMargemCartao);
        const verba = getVerbaFormatadaInit(
          produto,
          tipoUnidadeOferta,
          verba_unitaria,
        );
        //setVerbaUnitaria2(verba);
        refVerbaUnitaria.current = verba;
        setCarregando(false);
        //setDadosPrecificacao(dadosPrecificacao);
        //setManifestacaoCliente('aceito');
        const valor2 = getValorFormatadoEvento(
          produto2,
          tipoUnidadeOferta ? tipoUnidadeOferta : tipoUnidadeOferta,
          valor,
        );

        //setPrecoSugerido(valor2 - verba);

        const verbaOriginal = getVerbaFormatadaInit(
          produto,
          tipoUnidadeOferta,
          verba_unitaria_original,
        );

        const precoSugeridoOriginal = convertTipoUnidadeOferta(
          tipoUnidadeOferta,
          tipoUnidadeOfertaPrecoSugerido,
          valor2 - verbaOriginal,
          produto,
        );

        /* setPrecoSugeridoOriginal(
          precoSugeridoOriginal
        ); */
        //setIsNegociacaoEncerrada(isNegociacaoEncerrada);

        const tipoUnidadeOfertaQuantidade2 = tipoUnidadeOfertaQuantidade
          ? tipoUnidadeOfertaQuantidade
          : tipoUnidadeOferta;

        /* setTipoUnidadeUnidadeQuantidade(
          tipoUnidadeOfertaQuantidade2
        ); */

        const tipoUnidadeOfertaPrecoSugerido2 = tipoUnidadeOfertaPrecoSugerido
          ? tipoUnidadeOfertaPrecoSugerido
          : tipoUnidadeOferta;

        /* setTipoUnidadeUnidadePrecoSugerido(tipoUnidadeOfertaPrecoSugerido2);
        setVerbaUnitariaOriginal(verbaOriginal); */

        const precoSugerido = convertTipoUnidadeOferta(
          tipoUnidadeOferta,
          tipoUnidadeOfertaPrecoSugerido,
          valor2 - verba,
          produto,
        );

        //setPrecoSugerido(precoSugerido);

        const qtde2 = convertTipoUnidadeOfertaQuantidade(
          tipoUnidadeOferta,
          tipoUnidadeOfertaQuantidade,
          qtde,
          produto,
        );

        //setQtde(qtde2);

        setDados({
          produto: produto2,
          valor,
          tipoUnidadeOferta,
          qtde2,
          config,
          dadosPrecificacaoAnterior,
          quantidadeComprada,
          valorNegociadoComAIndustria,
          fornecedor,
          dadosPrecificacao,
          isMargemCartao,
          verbaUnitaria2: verba,
          manifestacaoCliente: 'aceito',
          precoSugerido,
          loteId: -1,
          precoSugeridoOriginal,
          isNegociacaoEncerrada,
          verbaUnitariaOriginal: verbaOriginal,
          tipoUnidadeOfertaQuantidade: tipoUnidadeOfertaQuantidade2,
          tipoUnidadeOfertaPrecoSugerido: tipoUnidadeOfertaPrecoSugerido2,
        });
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog(0);
      }
    }

    async function isOfertaExistente() {
      /* const data = await getListAllAPI(
        'ofertas_liberacao_produtos',
        ['id', 'asc'],
        {
          produto_id: produto.id,
          cliente_id: cliente.id,
          status: STATUS_ATIVO,
        },
        [],
      );

      if (data.data.length > 0) {
        return true;
      } */
      return false;
    }

    async function handleSalvar() {
      setCarregando(true);
      try {
        if (id <= 0) {
          if (!(await isOfertaExistente())) {
            handleSalvar2(
              {
                produto,
                user_id: getUserId(),
                valor: valor - verbaUnidadeValue,
                tipo_unidade: tipoUnidadeOferta,
                dadosPrecificacao,
                verba_unitaria: verbaUnidadeValue,
                //quantidade: qtde,
                quantidade: round10(
                  convertTipoUnidadeOfertaQuantidade(
                    tipoUnidadeOfertaQuantidade,
                    tipoUnidadeOferta,
                    qtde2,
                    produto,
                  ),
                  -2,
                ),
                status_aprovado_volta: manifestacaoCliente !== 'rejeitado',
                is_fim_negociacao: isNegociacaoEncerrada,
                tipo_unidade_quantidade: tipoUnidadeOfertaQuantidade,
                tipo_unidade_preco_sugerido: tipoUnidadeOfertaPrecoSugerido,
              },
              id,
            );
            setCarregando(false);
            enqueueSnackbar(`Liberação de Preço criada com sucesso!`, {
              variant: 'success',
            });
          } else {
            enqueueSnackbar(
              `Já existe uma liberação de preço ativa para este produto e este cliente!`,
              {
                variant: 'error',
              },
            );
          }
        } else {
          handleSalvar2(
            {
              produto,
              user_id: getUserId(),
              valor: valor - verbaUnidadeValue,
              tipo_unidade: tipoUnidadeOferta,
              dadosPrecificacao,
              verba_unitaria: verbaUnidadeValue,
              //quantidade: qtde,
              quantidade: round10(
                convertTipoUnidadeOfertaQuantidade(
                  tipoUnidadeOfertaQuantidade,
                  tipoUnidadeOferta,
                  qtde2,
                  produto,
                ),
                -2,
              ),
              status_aprovado_volta: manifestacaoCliente !== 'rejeitado',
              is_fim_negociacao: isNegociacaoEncerrada,
              tipo_unidade_quantidade: tipoUnidadeOfertaQuantidade,
              tipo_unidade_preco_sugerido: tipoUnidadeOfertaPrecoSugerido,
            },
            id,
          );
          setCarregando(false);
          enqueueSnackbar(`Liberação de Preço atualizada com sucesso!`, {
            variant: 'success',
          });
        }
        handleCloseDialog(0, produto.id);
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog(0);
      }
    }

    useImperativeHandle(ref, () => ({
      handleCreate(produto = null, isMargemCartao) {
        setCarregando(true);
        reiniciar(produto, isMargemCartao);
        setOpen(true);
      },
      handleEdit(
        id,
        valor,
        tipoUnidadeOferta,
        produto,
        qtde,
        dadosPrecificacao,
        verba_unitaria,
        isMargemCartao,
        verba_unitaria_original,
        isPrecificacaoLocal = false,
        is_fim_negociacao,
        tipoUnidadeOfertaQuantidade,
        tipoUnidadeOfertaPrecoSugerido,
      ) {
        setCarregando(true);
        getDataResource(
          id,
          valor,
          tipoUnidadeOferta,
          produto,
          qtde,
          dadosPrecificacao,
          verba_unitaria,
          isMargemCartao,
          verba_unitaria_original,
          isPrecificacaoLocal,
          is_fim_negociacao,
          tipoUnidadeOfertaQuantidade,
          tipoUnidadeOfertaPrecoSugerido,
        );
        setOpen(true);
        setId(id);
      },
    }));

    ///////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////
    const {
      peso,
      unidades,
      verba,
      precoOfertaJornal,
      precoOfertaZerarEstoque,
    } = getUnidadesUnitaria(produto, qtde, tipoUnidadeOferta, verbaUnitaria);

    const tipoCaixarias = {
      descicao_do_kpi: 'Tipo de Caixaria utilizada na negociação',
      unidade:
        tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_UN ||
        tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_KG
          ? 'X'
          : '',
      caixaria_nivel_1:
        tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXA ? 'X' : '',
      caixaria_nivel_2:
        tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXETA ? 'X' : '',
      caixaria_nivel_3:
        tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXA_MAE ? 'X' : '',
      totais: '',
    };

    const {
      qtdeUnidade,
      qtdeUnidadeValue,
      qtdeCaixariaNivel1,
      qtdeCaixariaNivel1Value,
      qtdeCaixariaNivel2,
      qtdeCaixariaNivel2Value,
      qtdeCaixariaNivel3,
      qtdeCaixariaNivel3Value,
      ///////////////////
      precoCaixariaNivel1,
      precoCaixariaNivel1Value,
      precoCaixariaNivel2,
      precoCaixariaNivel2Value,
      precoCaixariaNivel3,
      precoCaixariaNivel3Value,
      precoUnidade,
      precoUnidadeValue,
      ///////////////////
      precoVendaComMargemDeLbZeroCaixariaNivel1,
      precoVendaComMargemDeLbZeroCaixariaNivel1Value,
      precoVendaComMargemDeLbZeroCaixariaNivel2,
      precoVendaComMargemDeLbZeroCaixariaNivel2Value,
      precoVendaComMargemDeLbZeroCaixariaNivel3,
      precoVendaComMargemDeLbZeroCaixariaNivel3Value,
      precoVendaComMargemDeLbZeroUnidade,
      precoVendaComMargemDeLbZeroUnidadeValue,
      ///////////////////////
      verbaCaixariaNivel1,
      verbaCaixariaNivel1Value,
      verbaCaixariaNivel2,
      verbaCaixariaNivel2Value,
      verbaCaixariaNivel3,
      verbaCaixariaNivel3Value,
      verbaUnidade,
      verbaUnidadeValue,
      ///////////////////////////
      precoVendaLiberadoMaisVerbaIncluidaUnidade,
      precoVendaLiberadoMaisVerbaIncluidaUnidadeValue,
      precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel1,
      precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel1Value,
      precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel2,
      precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel2Value,
      precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel3,
      precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel3Value,
      //////////////////////////
      precoOfertaJornalUnidade,
      precoOfertaJornalUnidadeValue,
      precoOfertaJornalCaixariaNivel1,
      precoOfertaJornalCaixariaNivel1Value,
      precoOfertaJornalCaixariaNivel2,
      precoOfertaJornalCaixariaNivel2Value,
      precoOfertaJornalCaixariaNivel3,
      precoOfertaJornalCaixariaNivel3Value,
      //////////////////////////////////////
      precoOfertaZerarEstoqueUnidade,
      precoOfertaZerarEstoqueUnidadeValue,
      precoOfertaZerarEstoqueCaixariaNivel1,
      precoOfertaZerarEstoqueCaixariaNivel1Value,
      precoOfertaZerarEstoqueCaixariaNivel2,
      precoOfertaZerarEstoqueCaixariaNivel2Value,
      precoOfertaZerarEstoqueCaixariaNivel3,
      precoOfertaZerarEstoqueCaixariaNivel3Value,
      //////////////////////////////////////
      precoCompraUnidade,
      precoCompraUnidadeValue,
      precoCompraCaixariaNivel1,
      precoCompraCaixariaNivel1Value,
      precoCompraCaixariaNivel2,
      precoCompraCaixariaNivel2Value,
      precoCompraCaixariaNivel3,
      precoCompraCaixariaNivel3Value,
      //////////////////////////////////////
      precoMinimoUnidade,
      precoMinimoUnidadeValue,
      precoMinimoCaixariaNivel1,
      precoMinimoCaixariaNivel1Value,
      precoMinimoCaixariaNivel2,
      precoMinimoCaixariaNivel2Value,
      precoMinimoCaixariaNivel3,
      precoMinimoCaixariaNivel3Value,
      ///////////////////////////////////////
      precoVendaComMargemDeLbZero2Unidade,
      precoVendaComMargemDeLbZero2UnidadeValue,
      precoVendaComMargemDeLbZero2CaixariaNivel1,
      precoVendaComMargemDeLbZero2CaixariaNivel1Value,
      precoVendaComMargemDeLbZero2CaixariaNivel2,
      precoVendaComMargemDeLbZero2CaixariaNivel2Value,
      precoVendaComMargemDeLbZero2CaixariaNivel3,
      precoVendaComMargemDeLbZero2CaixariaNivel3Value,
    } = getQuantidadesTipos(
      unidades,
      peso,
      produto,
      valor,
      verba,
      precoOfertaJornal,
      precoOfertaZerarEstoque,
      dadosPrecificacao ? dadosPrecificacao.valor_negociado_com_a_industria : 0, //precoCompra
      dadosPrecificacao
        ? dadosPrecificacao.preco_de_venda - dadosPrecificacao.custo_operacional
        : 0, //precoMinimo
      dadosPrecificacao
        ? dadosPrecificacao.preco_de_venda - dadosPrecificacao.lucro_bruto
        : 0, //precoPrecificacaoZero
    );

    let fator = 1;

    if (produto) {
      if (produto.unidade.tipo === POR_PESO_FIXO) fator = produto.peso_medio;
    }

    const {
      totalComMargemDeLbZero,
      totalJornalOferta,
      totalSemVerba,
      totalVerba,
      totalVerbaIncluida,
      totalZerarEstoque,
      totalComMargemDeLbZero2,
      totalCompra,
      totalMinimo,
    } = getTotais({
      qtdeUnidade: qtdeUnidadeValue,
      qtdeCaixariaNivel1: qtdeCaixariaNivel1Value,
      qtdeCaixariaNivel2: qtdeCaixariaNivel2Value,
      qtdeCaixariaNivel3: qtdeCaixariaNivel3Value,
      ///////////////////
      precoCaixariaNivel1: precoCaixariaNivel1Value,
      precoCaixariaNivel2: precoCaixariaNivel2Value,
      precoCaixariaNivel3: precoCaixariaNivel3Value,
      precoUnidade: precoUnidadeValue,
      ///////////////////
      precoVendaComMargemDeLbZeroCaixariaNivel1: precoVendaComMargemDeLbZeroCaixariaNivel1Value,
      precoVendaComMargemDeLbZeroCaixariaNivel2: precoVendaComMargemDeLbZeroCaixariaNivel2Value,
      precoVendaComMargemDeLbZeroCaixariaNivel3: precoVendaComMargemDeLbZeroCaixariaNivel3Value,
      precoVendaComMargemDeLbZeroUnidade: precoVendaComMargemDeLbZeroUnidadeValue,
      ///////////////////////
      verbaCaixariaNivel1: verbaCaixariaNivel1Value,
      verbaCaixariaNivel2: verbaCaixariaNivel2Value,
      verbaCaixariaNivel3: verbaCaixariaNivel3Value,
      verbaUnidade: verbaUnidadeValue,
      ///////////////////////////
      precoVendaLiberadoMaisVerbaIncluidaUnidade: precoVendaLiberadoMaisVerbaIncluidaUnidadeValue,
      precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel1: precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel1Value,
      precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel2: precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel2Value,
      precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel3: precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel3Value,
      /////////////////////////////
      tipoUnidadeOferta,
      ////////////////////////////
      precoOfertaJornalUnidade: precoOfertaJornalUnidadeValue,
      precoOfertaJornalCaixariaNivel1: precoOfertaJornalCaixariaNivel1Value,
      precoOfertaJornalCaixariaNivel2: precoOfertaJornalCaixariaNivel2Value,
      precoOfertaJornalCaixariaNivel3: precoOfertaJornalCaixariaNivel3Value,
      /////////////////////////////
      precoOfertaZerarEstoqueUnidade: precoOfertaZerarEstoqueUnidadeValue,
      precoOfertaZerarEstoqueCaixariaNivel1: precoOfertaZerarEstoqueCaixariaNivel1Value,
      precoOfertaZerarEstoqueCaixariaNivel2: precoOfertaZerarEstoqueCaixariaNivel2Value,
      precoOfertaZerarEstoqueCaixariaNivel3: precoOfertaZerarEstoqueCaixariaNivel3Value,
      ///////////////////////////////
      precoCompraUnidade: precoCompraUnidadeValue,
      precoCompraCaixariaNivel1: precoCompraCaixariaNivel1Value,
      precoCompraCaixariaNivel2: precoCompraCaixariaNivel2Value,
      precoCompraCaixariaNivel3: precoCompraCaixariaNivel3Value,
      ///////////////////////////////
      precoMinimoUnidade: precoMinimoUnidadeValue,
      precoMinimoCaixariaNivel1: precoMinimoCaixariaNivel1Value,
      precoMinimoCaixariaNivel2: precoMinimoCaixariaNivel2Value,
      precoMinimoCaixariaNivel3: precoMinimoCaixariaNivel3Value,
      ///////////////////////////////
      precoVendaComMargemDeLbZero2Unidade: precoVendaComMargemDeLbZero2UnidadeValue,
      precoVendaComMargemDeLbZero2CaixariaNivel1: precoVendaComMargemDeLbZero2CaixariaNivel1Value,
      precoVendaComMargemDeLbZero2CaixariaNivel2: precoVendaComMargemDeLbZero2CaixariaNivel2Value,
      precoVendaComMargemDeLbZero2CaixariaNivel3: precoVendaComMargemDeLbZero2CaixariaNivel3Value,
      //////////////////////////////
      fator,
    });

    function isInputKg() {
      if (produto) {
        const tipo = produto.unidade.tipo;
        if (tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
          if (tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_KG) {
            return true;
          }
        }
      }
      return false;
    }

    function validateProduto() {
      let error = '';
      if (!produto) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function validateValor() {
      let error = '';
      if (
        valor - verbaUnidadeValue <= 0 ||
        Number.isNaN(valor - verbaUnidadeValue)
      ) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function validateQuantidade() {
      let error = '';
      if (qtde <= 0 || Number.isNaN(qtde)) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function validateVerbaUnitaria() {
      let error = '';
      if (verbaUnitaria < 0 || Number.isNaN(verbaUnitaria)) {
        error = 'Este campo é obrigatório';
      } else if (dadosPrecificacao) {
        /////////////
      }
      return error;
    }

    function validatePrecoSugerido() {
      let error = '';
      if (precoSugerido < 0 || Number.isNaN(precoSugerido)) {
        error = 'Este campo é obrigatório';
      } else if (tipoStatusAndamento === tipoStatusAndamentoAprovarVolta) {
        ///////////
        if (manifestacaoCliente === 'nova-solicitacao') {
          if (
            comparaValoresCorreto(precoSugerido, precoSugeridoOriginal, {
              operador: '>=',
            })
          ) {
            error = `O novo preço não pode ser maior ou igual que o preço aprovado (${formatMoeda(
              precoSugeridoOriginal,
            )})!`;
          }
        }
      } else if (tipoStatusAndamento === tipoStatusAndamentoAprovar) {
        if (
          comparaValoresCorreto(precoSugerido, precoSugeridoOriginal, {
            operador: '<',
          })
        ) {
          error = `O novo preço não pode ser menor que o preço solicitado (${formatMoeda(
            precoSugeridoOriginal,
          )})!`;
        }
      } else if (tipoStatusAndamento === tipoStatusAndamentoCriarPedido) {
        const {
          minimoDescontoAplicado,
          minimoPreco,
          precoAtual,
        } = getMaiorPrecoSugerido(
          {
            precoUnidade: precoUnidadeValue,
            precoCaixariaNivel1: precoCaixariaNivel1Value,
            precoCaixariaNivel2: precoCaixariaNivel2Value,
            precoCaixariaNivel3: precoCaixariaNivel3Value,
            precoOfertaJornalUnidade: precoOfertaJornalUnidadeValue,
            precoOfertaJornalCaixariaNivel1: precoOfertaJornalCaixariaNivel1Value,
            precoOfertaJornalCaixariaNivel2: precoOfertaJornalCaixariaNivel2Value,
            precoOfertaJornalCaixariaNivel3: precoOfertaJornalCaixariaNivel3Value,
            precoOfertaZerarEstoqueUnidade: precoOfertaZerarEstoqueUnidadeValue,
            precoOfertaZerarEstoqueCaixariaNivel1: precoOfertaZerarEstoqueCaixariaNivel1Value,
            precoOfertaZerarEstoqueCaixariaNivel2: precoOfertaZerarEstoqueCaixariaNivel2Value,
            precoOfertaZerarEstoqueCaixariaNivel3: precoOfertaZerarEstoqueCaixariaNivel3Value,
            precoVendaComMargemDeLbZeroUnidade: precoVendaComMargemDeLbZeroUnidadeValue,
            precoVendaComMargemDeLbZeroCaixariaNivel1: precoVendaComMargemDeLbZeroCaixariaNivel1Value,
            precoVendaComMargemDeLbZeroCaixariaNivel2: precoVendaComMargemDeLbZeroCaixariaNivel2Value,
            precoVendaComMargemDeLbZeroCaixariaNivel3: precoVendaComMargemDeLbZeroCaixariaNivel3Value,
          },
          tipoUnidadeOfertaPrecoSugerido,
          produto ? produto.desconto_maximo_preco_venda : 0,
        );

        if (
          comparaValoresCorreto(precoSugerido, minimoPreco, { operador: '>' })
        ) {
          error = `O preço sugerido não pode ser maior que o menor preço atual incluindo ofertas (${formatMoeda(
            minimoPreco,
          )})!`;
        } else if (
          comparaValoresCorreto(
            precoAtual - precoSugerido,
            minimoDescontoAplicado,
            { operador: '>' },
          )
        ) {
          error = `O Desconto total (${formatMoeda(precoAtual)} - ${formatMoeda(
            precoSugerido,
          )} = ${formatMoeda(
            precoAtual - precoSugerido,
          )}) não pode ser maior que ${formatPreco(
            produto ? produto.desconto_maximo_preco_venda : 0,
          )}% do Preço Atual (${formatPreco(
            produto ? produto.desconto_maximo_preco_venda : 0,
          )} % X ${formatMoeda(precoAtual)} = ${formatMoeda(
            minimoDescontoAplicado,
          )})!`;
        }

        /////
      }

      return error;
    }

    function getErros() {
      const errosOld = ['', ''];
      errosOld[0] = validateProduto();
      errosOld[1] = validateValor();
      errosOld[2] = validateQuantidade();
      errosOld[3] = '';
      errosOld[4] = validatePrecoSugerido();
      return errosOld;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    const nomeProduto = produto ? produto.nome : '';

    function editarPrecificacao() {
      if (refDialogoPrecificacaoLiberacao.current) {
        refDialogoPrecificacaoLiberacao.current.handleOpen(isMargemCartao);
      }
    }

    function getLabelPrecificacao() {
      if (produto) {
        if (
          produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE ||
          produto.unidade.tipo === POR_PESO_FIXO
        )
          return 'Kg';
        return 'Un';
      }
      return '-';
    }

    const quantidadeLiberada = {
      descicao_do_kpi: 'Quantidade liberada para o cliente',
      unidade: qtdeUnidade,
      caixaria_nivel_1: qtdeCaixariaNivel1,
      caixaria_nivel_2: qtdeCaixariaNivel2,
      caixaria_nivel_3: qtdeCaixariaNivel3,
      totais: '',
    };

    const precoVendaNormalSemVerba = {
      descicao_do_kpi: 'Preço de Venda normal sem verba',
      unidade: precoUnidade,
      caixaria_nivel_1: precoCaixariaNivel1,
      caixaria_nivel_2: precoCaixariaNivel2,
      caixaria_nivel_3: precoCaixariaNivel3,
      totais: totalSemVerba,
    };

    const precoVendaJornalOferta = {
      descicao_do_kpi: 'Preço de Venda no Jornal de Oferta',
      unidade: precoOfertaJornalUnidade,
      caixaria_nivel_1: precoOfertaJornalCaixariaNivel1,
      caixaria_nivel_2: precoOfertaJornalCaixariaNivel2,
      caixaria_nivel_3: precoOfertaJornalCaixariaNivel3,
      totais: totalJornalOferta,
    };

    const precoVendaZerarEstoque = {
      descicao_do_kpi: 'Preço de Venda na Oferta de Zerar Estoque',
      unidade: precoOfertaZerarEstoqueUnidade,
      caixaria_nivel_1: precoOfertaZerarEstoqueCaixariaNivel1,
      caixaria_nivel_2: precoOfertaZerarEstoqueCaixariaNivel2,
      caixaria_nivel_3: precoOfertaZerarEstoqueCaixariaNivel3,
      totais: totalZerarEstoque,
    };

    const precoVendaComMargemDeLbZero = {
      descicao_do_kpi: `Preço de Venda Liberado com margem (${formatPreco(
        dadosPrecificacao ? dadosPrecificacao.taxa_lucro_bruto : 0,
        2,
        '',
      )} %)`,
      unidade: precoVendaComMargemDeLbZeroUnidade,
      caixaria_nivel_1: precoVendaComMargemDeLbZeroCaixariaNivel1,
      caixaria_nivel_2: precoVendaComMargemDeLbZeroCaixariaNivel2,
      caixaria_nivel_3: precoVendaComMargemDeLbZeroCaixariaNivel3,
      totais: totalComMargemDeLbZero,
    };

    const verbaAplicadaPorUnidadeDeProduto = {
      descicao_do_kpi: 'Verba aplicada por unidade de produto',
      unidade: verbaUnidade,
      caixaria_nivel_1: verbaCaixariaNivel1,
      caixaria_nivel_2: verbaCaixariaNivel2,
      caixaria_nivel_3: verbaCaixariaNivel3,
      totais: totalVerba,
    };

    const precoVendaLiberadoMaisVerbaIncluida = {
      descicao_do_kpi: 'Preço de Venda Liberado + Verba Incluída',
      unidade: precoVendaLiberadoMaisVerbaIncluidaUnidade,
      caixaria_nivel_1: precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel1,
      caixaria_nivel_2: precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel2,
      caixaria_nivel_3: precoVendaLiberadoMaisVerbaIncluidaCaixariaNivel3,
      totais: totalVerbaIncluida,
    };

    const precoVendaComMargemDeLbZero2 = {
      descicao_do_kpi: 'Preço de Venda Liberado com margem de LB Zero',
      unidade: precoVendaComMargemDeLbZero2Unidade,
      caixaria_nivel_1: precoVendaComMargemDeLbZero2CaixariaNivel1,
      caixaria_nivel_2: precoVendaComMargemDeLbZero2CaixariaNivel2,
      caixaria_nivel_3: precoVendaComMargemDeLbZero2CaixariaNivel3,
      totais: totalComMargemDeLbZero2,
    };

    const precoCompra = {
      descicao_do_kpi: 'Preço de Compra',
      unidade: precoCompraUnidade,
      caixaria_nivel_1: precoCompraCaixariaNivel1,
      caixaria_nivel_2: precoCompraCaixariaNivel2,
      caixaria_nivel_3: precoCompraCaixariaNivel3,
      totais: totalCompra,
    };

    const precoMinimo = {
      descicao_do_kpi: 'Preço de Compra com impostos',
      unidade: precoMinimoUnidade,
      caixaria_nivel_1: precoMinimoCaixariaNivel1,
      caixaria_nivel_2: precoMinimoCaixariaNivel2,
      caixaria_nivel_3: precoMinimoCaixariaNivel3,
      totais: totalMinimo,
    };
    ///////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////

    const isErrorPrecoMaiorQueOfertaDeJornal =
      precoOfertaJornalUnidadeValue > 0 &&
      precoOfertaJornalUnidadeValue <
        precoVendaLiberadoMaisVerbaIncluidaUnidadeValue;

    const isErrorPrecoMaiorQueOfertaZerarEstoque =
      precoOfertaZerarEstoqueUnidadeValue > 0 &&
      precoOfertaZerarEstoqueUnidadeValue <
        precoVendaLiberadoMaisVerbaIncluidaUnidadeValue;

    const isErrorPrecoNegativo =
      precoVendaLiberadoMaisVerbaIncluidaUnidadeValue <= 0;

    function disabledVerbaUnitaria() {
      if (dadosPrecificacao) {
        if (dadosPrecificacao.taxa_lucro_liquido > 0) return true;
      }

      if (
        tipoStatusAndamento === tipoStatusAndamentoAprovarVolta &&
        manifestacaoCliente !== 'nova-solicitacao'
      ) {
        return true;
      }

      return false;
    }

    function getMessage() {
      switch (tipoStatusAndamento) {
        case tipoStatusAndamentoCriarPedido:
          return '';
        case tipoStatusAndamentoDetalhes: {
          if (isNegociacaoEncerrada) {
            return ``;
          }
          return '';
        }

        case tipoStatusAndamentoAprovar:
          return '';
        case tipoStatusAndamentoAprovarVolta: {
          if (isNegociacaoEncerrada) {
            return `A negociação desde item foi encerrada pelo comprador, por isto não é possível prosseguir com as negociações, favor aceitar ou rejeitar a proposta final!`;
          }
          return '';
        }

        default:
          return '';
      }
    }

    const msg = getMessage();

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullWidth
          maxWidth="xl"
          //fullScreen
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={() => handleCloseDialog(0)}
            style={{ backgroundColor: PRIMARY_COLOR, color: 'white' }}
          >
            {id <= 0 ? `Nova Liberação de preço` : `Editar Liberação de preço`}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '20px 36px 20px',
              margin: '0',
            }}
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                  {produto && (
                    <>
                      <div
                        style={{
                          marginTop: '20px',
                        }}
                      >
                        <h2
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          {nomeProduto}
                        </h2>
                      </div>

                      <div>
                        <div
                          style={{
                            marginTop: '20px',
                            display: 'flex',
                            gap: '10px',
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              display: 'flex',
                            }}
                          >
                            <PrecoInput
                              name="precoOferta"
                              handleEnter={() => {}}
                              label={`Precificação Unitária (${getLabelPrecificacao()})`}
                              handleKey={() => {}}
                              value={Number.isNaN(valor) ? '' : String(valor)}
                              onChange={(value) => {
                                changeState({
                                  valor: value.target.value,
                                });
                                //setValor(parseFloat(value.target.value));
                              }}
                              error={erros[1] !== ''}
                              helperText={erros[1]}
                              fullWidth
                              variant="standard"
                              autoFocus
                              disabled={valorNegociadoComAIndustria > 0}
                            />
                            {tipoStatusAndamento ===
                            tipoStatusAndamentoCriarPedido ? (
                              <Tooltip
                                title="Editar Preço"
                                placement="bottom"
                                aria-label="add2"
                              >
                                <IconButton
                                  aria-label="editar"
                                  size="small"
                                  onClick={() => editarPrecificacao()}
                                  disabled={
                                    valorNegociadoComAIndustria <= 0 ||
                                    verbaUnitaria > 0
                                  }
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                          </div>
                          <div
                            style={{
                              flex: 1,
                              display: 'flex',
                            }}
                          >
                            {!isInputKg() ? (
                              <IntegerInput
                                name="quantidade"
                                handleEnter={() => {}}
                                label={`Quantidade (${getLabelTipoUnidade(
                                  tipoUnidadeOfertaQuantidade,
                                )})`}
                                handleKey={() => {}}
                                value={Number.isNaN(qtde2) ? '' : String(qtde2)}
                                onChange={(value) => {
                                  changeState({
                                    qtde2: parseInt(value.target.value, 10),
                                  });
                                  //setQtde(parseInt(value.target.value, 10))
                                }}
                                error={erros[2] !== ''}
                                helperText={erros[2]}
                                variant="standard"
                                fullWidth
                                disabled={
                                  tipoStatusAndamento !==
                                  tipoStatusAndamentoCriarPedido
                                }
                                InputProps={{
                                  endAdornment: (
                                    <InputTipoUnidade
                                      produto={produto}
                                      tipoUnidadeOferta={
                                        tipoUnidadeOfertaQuantidade
                                      }
                                      setTipoUnidadeUnidade={(value) => {
                                        changeState({
                                          tipoUnidadeOfertaQuantidade: value,
                                          tipoUnidadeOferta: value,
                                        });
                                      }}
                                      disabled={
                                        tipoStatusAndamento !==
                                        tipoStatusAndamentoCriarPedido
                                      }
                                    />
                                  ),
                                }}
                              />
                            ) : (
                              <WeightInput
                                name="quantidade"
                                handleEnter={() => {}}
                                label={`Quantidade (${getLabelTipoUnidade(
                                  tipoUnidadeOfertaQuantidade,
                                )})`}
                                handleKey={() => {}}
                                value={qtde2}
                                onChange={(value) => {
                                  changeState({
                                    qtde2: value.target.value,
                                  });
                                  //setQtde(value.target.value)
                                }}
                                error={erros[2] !== ''}
                                helperText={erros[2]}
                                variant="standard"
                                fullWidth
                                disabled={
                                  tipoStatusAndamento !==
                                  tipoStatusAndamentoCriarPedido
                                }
                                InputProps={{
                                  endAdornment: (
                                    <InputTipoUnidade
                                      produto={produto}
                                      tipoUnidadeOferta={
                                        tipoUnidadeOfertaQuantidade
                                      }
                                      setTipoUnidadeUnidade={(value) => {
                                        changeState({
                                          tipoUnidadeOfertaQuantidade: value,
                                        });
                                      }}
                                      disabled={
                                        tipoStatusAndamento !==
                                        tipoStatusAndamentoCriarPedido
                                      }
                                    />
                                  ),
                                }}
                              />
                            )}
                          </div>
                          {/* {tipoStatusAndamento ===
                          tipoStatusAndamentoAprovar ? (
                            <div
                              style={{
                                flex: 1,
                                display: 'flex',
                              }}
                            >
                              <PrecoInput
                                name="verba_unitaria"
                                handleEnter={() => {}}
                                label={`Verba Unitária (${getLabelTipoUnidade(
                                  tipoUnidadeOferta,
                                )})`}
                                handleKey={() => {}}
                                value={
                                  Number.isNaN(verbaUnitaria)
                                    ? ''
                                    : String(verbaUnitaria)
                                }
                                onChange={(value) => {
                                  setVerbaUnitaria2(
                                    parseFloat(value.target.value),
                                  );
                                }}
                                fullWidth
                                variant="standard"
                                error={erros[4] !== ''}
                                helperText={erros[4]}
                                disabled={
                                  disabledVerbaUnitaria() ||
                                  (tipoStatusAndamento !==
                                    tipoStatusAndamentoCriarPedido &&
                                    tipoStatusAndamento !==
                                      tipoStatusAndamentoAprovar)
                                }
                              />
                            </div>
                          ) : null} */}
                          {/* {tipoStatusAndamento ===
                          tipoStatusAndamentoCriarPedido ? (
                            <div
                              style={{
                                flex: 1,
                                display: 'flex',
                              }}
                            >
                              <PrecoInput
                                name="preco_sugerido"
                                handleEnter={() => {}}
                                label={`Preço Sugerido (${getLabelTipoUnidade(
                                  tipoUnidadeOferta,
                                )})`}
                                handleKey={() => {}}
                                value={
                                  Number.isNaN(precoSugerido)
                                    ? ''
                                    : String(precoSugerido)
                                }
                                onChange={(value) => {
                                  setPrecoSugerido(
                                    parseFloat(value.target.value),
                                  );
                                }}
                                fullWidth
                                variant="standard"
                                error={erros[4] !== ''}
                                helperText={erros[4]}
                                disabled={
                                  disabledVerbaUnitaria() ||
                                  (tipoStatusAndamento !==
                                    tipoStatusAndamentoCriarPedido &&
                                    tipoStatusAndamento !==
                                      tipoStatusAndamentoAprovar)
                                }
                              />
                            </div>
                          ) : null} */}

                          <div
                            style={{
                              flex: 1,
                              display: 'flex',
                            }}
                          >
                            <PrecoInput
                              name="preco_sugerido"
                              handleEnter={() => {}}
                              label={`Preço Sugerido (${getLabelTipoUnidade(
                                tipoUnidadeOfertaPrecoSugerido,
                              )})`}
                              handleKey={() => {}}
                              value={
                                Number.isNaN(precoSugerido)
                                  ? ''
                                  : String(precoSugerido)
                              }
                              onChange={(value) => {
                                changeState({
                                  precoSugerido: value.target.value,
                                });
                                /* setPrecoSugerido(
                                  parseFloat(value.target.value),
                                ); */
                              }}
                              fullWidth
                              variant="standard"
                              error={erros[4] !== ''}
                              helperText={erros[4]}
                              disabled={disabledVerbaUnitaria()}
                              InputProps={{
                                endAdornment: (
                                  <InputTipoUnidade
                                    produto={produto}
                                    tipoUnidadeOferta={
                                      tipoUnidadeOfertaPrecoSugerido
                                    }
                                    setTipoUnidadeUnidade={(value) => {
                                      /* setPrecoSugerido(0);
                                      setTipoUnidadeUnidadePrecoSugerido(value); */
                                      changeState({
                                        precoSugerido: 0,
                                        tipoUnidadeOfertaPrecoSugerido: value,
                                      });
                                    }}
                                    /* disabled={
                                      tipoStatusAndamento !==
                                      tipoStatusAndamentoCriarPedido
                                    } */
                                    disabled
                                  />
                                ),
                              }}
                            />
                          </div>

                          {tipoStatusAndamento ===
                          tipoStatusAndamentoAprovarVolta ? (
                            <div
                              style={{
                                flex: 1,
                                display: 'flex',
                                marginTop: '16px',
                              }}
                            >
                              <FormControl fullWidth variant="standard">
                                <InputLabel
                                  shrink
                                  id="demo-simple-select-label"
                                >
                                  Manifestação do Cliente
                                </InputLabel>
                                <SelectInput
                                  labelId="demo-simple-select-label"
                                  name="tipo"
                                  handleEnter={() => {}}
                                  label="Manifestação do Cliente"
                                  handleKey={() => {}}
                                  value={manifestacaoCliente}
                                  onChange={(value) => {
                                    if (
                                      value.target.value !== 'nova-solicitacao'
                                    ) {
                                      /* setPrecoSugerido(precoSugeridoOriginal);
                                      setManifestacaoCliente(
                                        value.target.value,
                                      ); */
                                      changeState({
                                        precoSugerido: precoSugeridoOriginal,
                                        manifestacaoCliente: value.target.value,
                                      });
                                    } else {
                                      /* setManifestacaoCliente(
                                        value.target.value,
                                      ); */
                                      changeState({
                                        manifestacaoCliente: value.target.value,
                                      });
                                    }
                                  }}
                                  /*  error={erros[4] !== ''}
                                  helperText={erros[4]} */
                                  options={optionsManifestacaoCliente}
                                  variant="standard"
                                  //
                                />
                              </FormControl>
                            </div>
                          ) : null}

                          {tipoStatusAndamento ===
                          tipoStatusAndamentoAprovar ? (
                            <div
                              style={{
                                flex: 1,
                                display: 'flex',
                                marginTop: '16px',
                              }}
                            >
                              <FormControl fullWidth variant="standard">
                                <InputLabel
                                  shrink
                                  id="demo-simple-select-label"
                                >
                                  Status da Negociação
                                </InputLabel>
                                <SelectInput
                                  labelId="demo-simple-select-label"
                                  name="tipo"
                                  handleEnter={() => {}}
                                  label="Status da Negociação"
                                  handleKey={() => {}}
                                  value={isNegociacaoEncerrada}
                                  onChange={(value) => {
                                    /* setIsNegociacaoEncerrada(
                                      value.target.value,
                                    ); */
                                    changeState({
                                      isNegociacaoEncerrada: value.target.value,
                                    });
                                  }}
                                  /* error={erros[4] !== ''}
                                  helperText={erros[4]} */
                                  options={optionsNegociacao}
                                  variant="standard"
                                  //
                                />
                              </FormControl>
                            </div>
                          ) : null}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: '10px',
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                          color: 'red',
                          fontWeight: 'bold',
                          fontSize: '12px',
                        }}
                      >
                        {isErrorPrecoMaiorQueOfertaDeJornal ? (
                          <span>
                            * Como o preço da liberação é maior que o preço do
                            jornal de ofertas, você não pode fechar esta
                            negociação, porque o preço de loja está mais barato
                          </span>
                        ) : null}
                        {isErrorPrecoMaiorQueOfertaZerarEstoque ? (
                          <span>
                            * Como o preço da liberação é maior que o preço da
                            oferta de zerar estoque, você não pode fechar esta
                            negociação, porque o preço de loja está mais barato
                          </span>
                        ) : null}
                        {isErrorPrecoNegativo ? (
                          <span>
                            * Ao aplicar as condições requeridas, o preço da
                            liberação ficou negativo ou nulo, por isso não pode
                            fechar esta negociação
                          </span>
                        ) : null}

                        {msg ? (
                          <span
                            style={{
                              margin: '10px 0',
                              color: 'green',
                              fontSize: '18px',
                            }}
                          >
                            {msg}
                          </span>
                        ) : null}
                      </div>
                      <div
                        style={{
                          marginTop: '20px',
                        }}
                      >
                        <TabelaPrecos
                          isMargemCartao={isMargemCartao}
                          tipoUnidadeOferta={tipoUnidadeOferta}
                          produto={produto}
                          qtde={qtde}
                          verbaUnitaria={verbaUnitaria}
                          valor={valor}
                          changeTipoCaixaria={(tipo) => {
                            /* setQtde(0);
                            setPrecoSugerido(0);
                            setTipoUnidadeUnidade(tipo);
                            setVerbaUnitaria2(0); */
                            /* changeState({
                              qtde2: 0,
                              precoSugerido: 0,
                              tipoUnidadeOferta: tipo,
                              verbaUnitaria2: 0,
                            });
                            refVerbaUnitaria.current = 0; */
                          }}
                          precoVendaNormalSemVerba={precoVendaNormalSemVerba}
                          precoVendaJornalOferta={precoVendaJornalOferta}
                          precoVendaZerarEstoque={precoVendaZerarEstoque}
                          precoVendaComMargemDeLbZero={
                            precoVendaComMargemDeLbZero
                          }
                          verbaAplicadaPorUnidadeDeProduto={
                            verbaAplicadaPorUnidadeDeProduto
                          }
                          precoVendaLiberadoMaisVerbaIncluida={
                            precoVendaLiberadoMaisVerbaIncluida
                          }
                          tipoCaixarias={tipoCaixarias}
                          quantidadeLiberada={quantidadeLiberada}
                          precoVendaComMargemDeLbZero2={
                            precoVendaComMargemDeLbZero2
                          }
                          precoCompra={precoCompra}
                          precoMinimo={precoMinimo}
                          tipoStatusAndamento={tipoStatusAndamento}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </DialogContent>
          <DialogActions
            style={{
              padding: '0px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={() => handleCloseDialog(id)}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="contained"
              color="primary"
            >
              Cancelar
            </Button>
            <Box>
              <div className={classes.wrapper}>
                <Button
                  onClick={handleSalvar}
                  classes={{
                    root: classes.btnAtions,
                    disabled: classes.btnAtionsDisabled,
                  }}
                  disabled={
                    erroExistente ||
                    carregando ||
                    isErrorPrecoMaiorQueOfertaDeJornal ||
                    isErrorPrecoMaiorQueOfertaZerarEstoque ||
                    isErrorPrecoNegativo
                  }
                  variant="contained"
                  color="primary"
                >
                  Salvar
                </Button>
                {carregando && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Box>
          </DialogActions>
          <DialogoPrecificacao
            ref={refDialogoPrecificacaoLiberacao}
            quantidadeComprada={quantidadeComprada}
            produto={produto}
            fornecedor={fornecedor}
            config={config}
            dadosPrecificacaoAnterior={dadosPrecificacaoAnterior}
            valorNegociadoComAIndustria={valorNegociadoComAIndustria}
            handleClose={(dados) => {
              if (dados) {
                if (dados.taxa_lucro_liquido > 0) {
                  //setVerbaUnitaria(0);
                  const valor2 = getValorFormatadoEvento(
                    produto,
                    tipoUnidadeOfertaPrecoSugerido
                      ? tipoUnidadeOfertaPrecoSugerido
                      : tipoUnidadeOferta,
                    trunc10(dados.preco_de_venda, -2),
                  );
                  //setPrecoSugerido(valor2);
                  changeState({
                    precoSugerido: valor2,
                    dadosPrecificacao: { ...dados, lote_id: loteId },
                    valor: trunc10(dados.preco_de_venda, -2),
                  });
                  // definir tipo unidade
                } else {
                  changeState({
                    dadosPrecificacao: { ...dados, lote_id: loteId },
                    valor: trunc10(dados.preco_de_venda, -2),
                  });
                }
              }
            }}
            isMargemCartao={isMargemCartao}
          />
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
