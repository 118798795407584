import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CloseIcon from '@material-ui/icons/Close';
import Rating from '@material-ui/lab/Rating';
import { useSnackbar } from 'notistack';

import {
  TextInput,
  IntegerInput,
  PorcentagemInput,
  DateInputBonito,
  AutoCompleteRemoto,
  PrecoInput,
} from '../../../Components';
import { createAPI, updateAPI, getListAllAPI } from '../../../services';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_ID = -1;

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [nome, setNome] = useState('');
  const [salarioBase, setSalarioBase] = useState(0);
  const [ajudaCusto, setAjudaCusto] = useState(0);

  const [id, setId] = useState(DEFAULT_ID);

  const [carregando, setCarregando] = useState(false);

  const classes = useStyles({ cor: '#009FD4' });

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function reiniciar() {
    setId(DEFAULT_ID);
    setNome('');
    setSalarioBase(0);
    setAjudaCusto(0);
    setCarregando(false);
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'funcoes_colaboradores',
        ['id', 'asc'],
        { id: [id] },
        [],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        setNome(data.data[0].nome);
        setSalarioBase(data.data[0].salario_base);
        setAjudaCusto(data.data[0].ajuda_de_custo);
        setCarregando(false);
      } else {
        throw 'Esta função de colaborador não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    try {
      if (id <= 0) {
        await createAPI('funcoes_colaboradores', {
          nome,
          salario_base: salarioBase,
          ajuda_de_custo: ajudaCusto,
        });
        setCarregando(false);
        enqueueSnackbar('Função de colaborador criada com sucesso!', {
          variant: 'success',
        });
      } else {
        await updateAPI('funcoes_colaboradores', id, {
          nome,
          salario_base: salarioBase,
          ajuda_de_custo: ajudaCusto,
        });
        setCarregando(false);
        enqueueSnackbar('Função de colaborador atualizada com sucesso!', {
          variant: 'success',
        });
      }

      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      reiniciar();
      setOpen(true);
    },
    handleEdit(id) {
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
    },
  }));

  function validateNome() {
    let error = '';
    if (!nome) error = 'Este campo é obrigatório';
    else if (nome.length > 80) {
      error = 'Número de caracteres maior que o permitido (máximo 80)';
    }
    return error;
  }

  function validateSalarioBase() {
    let error = '';
    if (salarioBase < 0 || Number.isNaN(salarioBase)) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateAjudaCusto() {
    let error = '';
    if (ajudaCusto < 0 || Number.isNaN(ajudaCusto)) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function getErros() {
    const errosOld = ['', '', ''];
    errosOld[0] = validateNome();
    errosOld[0] = validateSalarioBase();
    errosOld[1] = validateAjudaCusto();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          {id < 0 ? `Nova Função Colaborador` : `Editar Função Colaborador`}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <Box flex={1} mr="0.5em">
                <TextInput
                  name="nome"
                  handleEnter={() => {}}
                  label="Descrição"
                  handleKey={() => {}}
                  value={nome}
                  onChange={(value) => setNome(value.target.value)}
                  error={erros[0] !== ''}
                  helperText={erros[0]}
                  fullWidth
                  autoFocus
                />
              </Box>

              <Box flex={1} mr="0.5em">
                <PrecoInput
                  name="salario_base"
                  handleEnter={() => {}}
                  label="Salário Base"
                  handleKey={() => {}}
                  value={Number.isNaN(salarioBase) ? '' : String(salarioBase)}
                  onChange={(value) =>
                    setSalarioBase(parseFloat(value.target.value))
                  }
                  error={erros[1] !== ''}
                  helperText={erros[1]}
                  fullWidth
                />
              </Box>

              <Box flex={1} mr="0.5em">
                <PrecoInput
                  name="ajuda_custo"
                  handleEnter={() => {}}
                  label="Ajuda de Custo"
                  handleKey={() => {}}
                  value={Number.isNaN(ajudaCusto) ? '' : String(ajudaCusto)}
                  onChange={(value) =>
                    setAjudaCusto(parseFloat(value.target.value))
                  }
                  error={erros[2] !== ''}
                  helperText={erros[2]}
                  fullWidth
                />
              </Box>
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          <div className={classes.wrapper}>
            <Button
              onClick={handleSalvar}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              disabled={erroExistente || carregando}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
            {carregando && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
