import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  ListBase,
  ListToolbar,
  Pagination,
  useRefresh,
  Title,
  useListContext,
  Datagrid,
  ListContextProvider,
} from 'react-admin';

import { Card, Tabs, Tab, Divider, makeStyles } from '@material-ui/core';

import { DialogoConfirmacao } from '../../Components';
import { api, imprimirQrCodeGerente, getUserId } from '../../services';
import { STATUS_ATIVO, STATUS_INATIVO } from '../../utils/constants';
import Actions from './ActionsList';
import ActionsField from './Fields/ActionsField';
import DataCriacaoField from './Fields/DataCriacaoField';
import DataVencimentoField from './Fields/DataVencimentoField';
import Filter from './Filter';

const useStyles = makeStyles((theme) => ({
  dataHeader: {
    flex: 1,
    backgroundColor: theme.palette.primary.main,
    padding: '10px',
    color: 'white',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
}));

const tabs = [
  { id: 'ativos', name: 'Ativos' },
  { id: 'inativos', name: 'Inativos' },
];

const MyList = ({ ...props }) => {
  const refresh = useRefresh();
  const listContext = useListContext();
  const refDialogoConfirmacao = useRef(null);
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [ativos, setAtivos] = useState([]);
  const [inativos, setInativos] = useState([]);
  const classes = useStyles();

  const handleClose = () => {
    refresh();
  };

  function handleDesativar({ id }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Desativação de Qrcode',
        'Deseja realmente desativar este Qrcode?',
        { id, codigo: 1 },
      );
    }
  }

  async function desativar(id) {
    await api.delete(`/qrcodes_gerentes/${id}`);
    handleClose();
  }

  async function handleConfirma({ id, codigo }) {
    switch (codigo) {
      case 1:
        desativar(id);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (ids && ids !== filterValues.status) {
      switch (filterValues.status) {
        case STATUS_ATIVO:
          setAtivos(ids);
          break;
        case STATUS_INATIVO:
          setInativos(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.status]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters(
          {
            ...filterValues,
            status: value === 'ativos' ? STATUS_ATIVO : STATUS_INATIVO,
          },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  async function handleImprimir() {
    await imprimirQrCodeGerente(getUserId());
    refresh();
  }

  return (
    <>
      <Title title="Qrcodes" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions handleImprimir={handleImprimir} {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.status === STATUS_ATIVO ? tabs[0].id : tabs[1].id}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.status === STATUS_ATIVO && (
            <ListContextProvider value={{ ...listContext, ids: ativos }}>
              <Datagrid {...props}>
                <DataCriacaoField
                  source="created_at"
                  label="Data Criação"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                />
                <DataVencimentoField
                  source="validade"
                  label="Data Vencimento"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                />
                <ActionsField
                  source="valor"
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  handleDesativar={handleDesativar}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.status === STATUS_INATIVO && (
            <ListContextProvider value={{ ...listContext, ids: inativos }}>
              <Datagrid {...props}>
                <DataCriacaoField
                  source="created_at"
                  label="Data Criação"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>

        <TransacoesPagination />
      </Card>
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={handleConfirma}
      />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Qrcodes"
      component="div"
      filterDefaultValues={{
        status: STATUS_ATIVO,
      }}
      filter={{ user_id: getUserId() }}
    />
  </>
);

export default List2;
