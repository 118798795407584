import React, { useState, useRef } from 'react';
import { useListContext, ListBase, ListToolbar, useRefresh } from 'react-admin';
import { useHistory } from 'react-router-dom';

import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
} from '@material-ui/core';
import Avatar2 from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { stringify } from 'query-string';

import {
  DialogoCartaoCredito,
  DialogoDeletarCartaoCredito,
  DialogoDespesaCartaoCredito,
  DialogoPagarTotalFatura,
} from '../../Components';
import Avatar from '../../Components/Avatar';
import { formatMoeda, formatPreco } from '../../utils';
import Actions from './ActionsList';
import Filter from './Filter';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
    maxWidth: '670px',
  },
  media: {
    height: 200,
  },
  title: {
    paddingTop: '0px',
    paddingBottom: '0.5em',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 243,
    flexDirection: 'column',
  },
  actionSpacer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  header: {
    fontSize: '20px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  card: {},
  card2: {
    height: '315px',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: 'white',
    color: '#00796b',
    border: '2px solid #00796b',
  },
  contentHedare: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: '20px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {},
}))(MenuItem);

const TypographyValor = ({ valor }) => {
  const valorAbsoluto = valor >= 0 ? valor : valor;
  return (
    <Typography
      variant="subtitle2"
      align="center"
      style={{ color: '#f44336', fontWeight: 'bold' }}
    >
      {formatMoeda(valorAbsoluto)}
    </Typography>
  );
};

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 20,
    borderRadius: 25,
    color: 'black',
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 25,
    backgroundColor: '#00796b',
    color: 'black',
  },
}))(LinearProgress);

const CardConta = ({
  handleEdit,
  handleGraficoDespesas,
  handleHistoricoFaturas,
  handleArquivar,
  handleNewDespesaCartao,
  irParaTelaDespesasCartao,
  statusFatura,
  handlePagarFaturaTotal,
  ...props
}) => {
  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    nome,
    limite,
    limite_usado,
    dia_fechamento,
    dia_pagamento,
    fatura: { valor, mes, ano, status },
  } = props;

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dataFatura = new Date();
  dataFatura.setDate(dia_fechamento);
  dataFatura.setMonth(mes);
  dataFatura.setFullYear(ano);

  function getMesAnterior(mes, ano) {
    if (mes === 0) {
      return { mes: 11, ano: ano - 1 };
    }
    return { mes: mes - 1, ano };
  }

  const dataPagamentoFatura = new Date();
  dataPagamentoFatura.setDate(dia_pagamento);
  dataPagamentoFatura.setMonth(mes);
  dataPagamentoFatura.setFullYear(ano);

  const dataFaturaPaga = new Date();
  dataFaturaPaga.setDate(dia_pagamento);
  dataFaturaPaga.setMonth(mes);
  dataFaturaPaga.setFullYear(ano);

  function getDiaFechamento(dia_fechamento, dia_pagamento, mes, ano) {
    if (dia_fechamento < dia_pagamento) {
      const dataPagamento = new Date();
      dataPagamento.setDate(dia_fechamento);
      dataPagamento.setMonth(mes);
      dataPagamento.setFullYear(ano);
      return dataPagamento;
    }
    const { mes: mesAnterior, ano: anoAnterior } = getMesAnterior(mes, ano);
    const dataPagamento = new Date();
    dataPagamento.setDate(dia_fechamento);
    dataPagamento.setMonth(mesAnterior);
    dataPagamento.setFullYear(anoAnterior);
    return dataPagamento;
  }

  const dataFechamentoFatura = getDiaFechamento(
    dia_fechamento,
    dia_pagamento,
    mes,
    ano,
  );

  return (
    <>
      <Card
        style={{ borderRadius: '20px', cursor: 'pointer', maxWidth: '330px' }}
        className={classes.card}
        onClick={() => irParaTelaDespesasCartao({ ...props, mes, ano })}
      >
        <CardHeader
          avatar={<Avatar aria-label="recipe" texto={nome} color="#00796b" />}
          action={
            <IconButton
              aria-label="settings"
              tabindex="-1"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
          }
          title={
            <Tooltip title={nome} placement="bottom" aria-label="add2">
              <Typography
                className={classes.text}
                style={{ color: '#757575', fontWeight: 'bold' }}
              >
                {nome}
              </Typography>
            </Tooltip>
          }
          classes={{ title: classes.header, content: classes.contentHedare }}
        />
        <CardContent className={classes.title}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '15px',
            }}
          >
            <Typography
              variant="subtitle2"
              align="center"
              style={{
                fontWeight: 'bold',
                color: !statusFatura && valor ? '#ff6d18' : '#757575',
              }}
            >
              {status
                ? 'Fatura paga'
                : statusFatura
                ? 'Fatura aberta'
                : valor
                ? 'Fatura vencida'
                : 'Fatura zerada'}
            </Typography>
            <div />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '10px',
            }}
          >
            <Typography
              variant="subtitle2"
              align="center"
              style={{ fontWeight: 'bold', color: '#758f9d' }}
            >
              {status
                ? 'Valor Pago'
                : statusFatura
                ? 'Valor parcial'
                : 'Valor total'}
            </Typography>
            <TypographyValor valor={valor} />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '10px',
            }}
          >
            <Typography
              variant="subtitle2"
              align="center"
              style={{ fontWeight: 'bold', color: '#758f9d' }}
            >
              {status
                ? 'Data de pagamento'
                : statusFatura
                ? 'Fecha em'
                : 'Venceu em'}
            </Typography>
            <Typography
              variant="subtitle2"
              align="center"
              style={{ fontWeight: 'bold', color: 'black' }}
            >
              {status
                ? `${format(dataFaturaPaga, "dd 'de' MMMM 'de' yyyy", {
                    locale: ptBR,
                  })}`
                : statusFatura
                ? `${format(dataFechamentoFatura, "dd 'de' MMMM 'de' yyyy", {
                    locale: ptBR,
                  })}`
                : `${format(dataPagamentoFatura, "dd 'de' MMMM 'de' yyyy", {
                    locale: ptBR,
                  })}`}
            </Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              variant="subtitle2"
              align="center"
              style={{ fontWeight: 'bold', color: '#758f9d' }}
            >
              {`${formatMoeda(limite_usado)} de ${formatMoeda(limite)}`}
            </Typography>
          </div>
          <div style={{ display: 'flex', margi: '3px 0px' }}>
            <BorderLinearProgress
              variant="determinate"
              value={
                (limite_usado / limite) * 100 > 100
                  ? 100
                  : (limite_usado / limite) * 100
              }
              style={{ flex: 1 }}
            />
            <Typography
              variant="subtitle2"
              align="center"
              style={{ fontWeight: 'bold', marginLeft: '10px' }}
            >
              {`${formatPreco((limite_usado / limite) * 100)}%`}
            </Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              variant="subtitle2"
              align="center"
              style={{ fontWeight: 'bold', color: '#758f9d' }}
            >
              {`Limite disponível ${formatMoeda(limite - limite_usado)}`}
            </Typography>
          </div>
          <div
            style={{
              marginRight: '5px',
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '10px 0px',
              marginTop: '10px',
            }}
          >
            {status ? (
              <Button
                style={{ color: '#00796b', fontWeight: 'bold' }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                FATURA PAGA
              </Button>
            ) : statusFatura ? (
              <Button
                style={{ color: '#00796b', fontWeight: 'bold' }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleNewDespesaCartao({ ...props });
                }}
              >
                ADICIONAR DESPESA
              </Button>
            ) : (
              <Button
                style={{ color: '#f44336', fontWeight: 'bold' }}
                onClick={(e) => {
                  e.stopPropagation();
                  handlePagarFaturaTotal({
                    cartao_credito_id: props.id,
                    mes,
                    ano,
                  });
                }}
              >
                PAGAR FATURA
              </Button>
            )}
          </div>
        </CardContent>
        <Divider />
      </Card>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Zoom}
        transitionDuration={500}
      >
        <StyledMenuItem
          onClick={() => {
            handleClose();
            handleEdit(props);
          }}
        >
          <ListItemText primary="Editar cartão" />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            handleClose();
            handleGraficoDespesas(props);
          }}
        >
          <ListItemText primary="Gráfico despesas" />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            handleClose();
            handleHistoricoFaturas(props);
          }}
        >
          <ListItemText primary="Histórico de faturas" />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            handleClose();
            handleArquivar(props);
          }}
        >
          <ListItemText primary="Arquivar cartão de crédito" />
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};

const CardCriarConta = ({ handleCriar, ...props }) => {
  const classes = useStyles();
  return (
    <Card
      style={{
        borderRadius: '20px',
        cursor: 'pointer',
        height: '315px',
        maxWidth: '330px',
      }}
      onClick={() => handleCriar()}
    >
      <CardContent
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          flexDirection: 'column',
        }}
      >
        <Avatar2
          aria-label="delete"
          variant="outline"
          className={classes.large}
        >
          <AddIcon />
        </Avatar2>
        <div
          style={{
            marginTop: '20px',
            color: '#00796b',
            fontSize: '20px',
            fontWeight: 'bold',
          }}
        >
          Novo cartão de crédito
        </div>
      </CardContent>
    </Card>
  );
};

const CategoryGrid = ({
  handleEdit = () => {},
  handleCriar = () => {},
  handleGraficoDespesas = () => {},
  handleHistoricoFaturas = () => {},
  handleArquivar = () => {},
  handleNewDespesaCartao = () => {},
  irParaTelaDespesasCartao = () => {},
  handlePagarFaturaTotal = () => {},
  ...props
}) => {
  const classes = useStyles(props);
  const { data, ids, filterValues } = useListContext();
  const { statusFatura } = filterValues;
  return ids ? (
    <div style={{ flex: 2 }}>
      <Grid container spacing={2} className={classes.root}>
        <Grid key="primeiro" xs={12} sm={6} md={4} lg={6} xl={2} item>
          <CardCriarConta handleCriar={handleCriar} />
        </Grid>
        {ids.map((id) => (
          <Grid key={id} xs={12} sm={6} md={4} lg={6} xl={2} item>
            <CardConta
              {...data[id]}
              handleEdit={handleEdit}
              handleGraficoDespesas={handleGraficoDespesas}
              handleHistoricoFaturas={handleHistoricoFaturas}
              handleArquivar={handleArquivar}
              handleNewDespesaCartao={handleNewDespesaCartao}
              statusFatura={statusFatura}
              irParaTelaDespesasCartao={irParaTelaDespesasCartao}
              handlePagarFaturaTotal={handlePagarFaturaTotal}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  ) : null;
};

const CardTotal = ({ label, valor, icon, cor }) => (
  <Card style={{ borderRadius: '20px', cursor: 'pointer' }}>
    <CardContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%',
          alignItems: 'center',
        }}
      >
        <div>
          <div
            style={{
              fontSize: '17px',
              color: '#a1c3d1',
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {label}
            <ArrowForwardIosIcon
              style={{ fontSize: '10px', marginLeft: '5px' }}
            />
          </div>
          <div style={{ fontSize: '20px', color: 'black', fontWeight: 'bold' }}>
            {formatMoeda(valor)}
          </div>
        </div>
        <div>
          <Avatar2
            aria-label="recipe"
            style={{ backgroundColor: cor, color: 'white' }}
          >
            {icon}
          </Avatar2>
        </div>
      </div>
    </CardContent>
  </Card>
);

const CardText = ({ label, valor, icon, cor }) => (
  <Card style={{ borderRadius: '20px', cursor: 'pointer' }}>
    <CardContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%',
          alignItems: 'center',
        }}
      >
        <div>
          <div
            style={{
              fontSize: '17px',
              color: '#a1c3d1',
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {label}
            </div>
            <ArrowForwardIosIcon
              style={{ fontSize: '10px', marginLeft: '5px' }}
            />
          </div>
          <div style={{ fontSize: '20px', color: 'black', fontWeight: 'bold' }}>
            {valor}
          </div>
        </div>
        <div>
          <Avatar2
            aria-label="recipe"
            style={{ backgroundColor: cor, color: 'white' }}
          >
            {icon}
          </Avatar2>
        </div>
      </div>
    </CardContent>
  </Card>
);

const Aside = () => {
  const { data, ids, filterValues } = useListContext();
  const { statusFatura } = filterValues;

  function getMesPosterior(mes, ano) {
    if (mes === 11) {
      return { mes: 0, ano: ano + 1 };
    }
    return { mes: mes + 1, ano };
  }

  function calculaTotais() {
    let limiteDisponivelTotal = 0;
    let valorFaturaTotal = 0;
    let melhorCartao = 0;
    let proximaFaturaAVencer = 0;

    let dataCompare = new Date();
    let limiteRestanteDisponivel = new Date();

    if (ids.length > 0) {
      proximaFaturaAVencer = ids[0];
      dataCompare.setDate(data[ids[0]].dia_fechamento);
      dataCompare.setMonth(data[ids[0]].fatura.mes);
      dataCompare.setFullYear(data[ids[0]].fatura.ano);

      melhorCartao = ids[0];
      limiteRestanteDisponivel =
        data[ids[0]].limite - data[ids[0]].limite_usado;
    }

    for (let i = 0; i < ids.length; i += 1) {
      limiteDisponivelTotal += data[ids[i]].limite - data[ids[i]].limite_usado;
      valorFaturaTotal += data[ids[i]].fatura.valor;

      const dataCompareInterno = new Date();

      dataCompareInterno.setDate(data[ids[i]].dia_fechamento);
      dataCompareInterno.setMonth(data[ids[i]].fatura.mes);
      dataCompareInterno.setFullYear(data[ids[i]].fatura.ano);

      if (dataCompareInterno.getTime() < dataCompare.getTime()) {
        dataCompare = dataCompareInterno;
        proximaFaturaAVencer = ids[i];
      }

      const limiteRestanteDisponivelInterno =
        data[ids[i]].limite - data[ids[i]].limite_usado;

      if (limiteRestanteDisponivelInterno > limiteRestanteDisponivel) {
        limiteRestanteDisponivel = limiteRestanteDisponivelInterno;
        melhorCartao = ids[i];
      }
    }

    let proximaFaturaAVencerLabel = '';

    if (proximaFaturaAVencer > 0) {
      const dataCompareInterno = new Date();

      const { mes, ano } = getMesPosterior(
        data[proximaFaturaAVencer].fatura.mes,
        data[proximaFaturaAVencer].fatura.ano,
      );

      dataCompareInterno.setDate(data[proximaFaturaAVencer].dia_fechamento);
      dataCompareInterno.setMonth(mes);
      dataCompareInterno.setFullYear(ano);

      proximaFaturaAVencerLabel = `${format(
        dataCompareInterno,
        "dd 'de' MMMM 'de' yyyy",
        {
          locale: ptBR,
        },
      )}`;
    }

    const melhorCartaoLabel = melhorCartao > 0 ? data[melhorCartao].nome : '';

    return {
      limiteDisponivelTotal,
      valorFaturaTotal,
      proximaFaturaAVencerLabel,
      melhorCartaoLabel,
    };
  }

  const totais = calculaTotais();

  return (
    <div
      style={{
        margin: '1em 0 0 1em',
        paddingTop: '8px',
        flex: 0.75,
      }}
    >
      <CardText
        label={
          statusFatura
            ? 'Melhor cartão para comprar'
            : 'Sua próxima fatura vence em'
        }
        valor={
          statusFatura
            ? totais.melhorCartaoLabel
            : totais.proximaFaturaAVencerLabel
        }
        icon={<CreditCardIcon />}
        cor="#00796b"
      />
      <Box marginTop="20px">
        <CardTotal
          label="Limite Disponível"
          valor={totais.limiteDisponivelTotal}
          icon={<MonetizationOnIcon />}
          cor="#00796b"
        />
      </Box>
      <Box marginTop="20px">
        <CardTotal
          label="Valor total"
          valor={totais.valorFaturaTotal}
          icon={<LocalAtmIcon />}
          cor="#00796b"
        />
      </Box>
    </div>
  );
};

const MyList = ({ ...props }) => {
  const refresh = useRefresh();
  const history = useHistory();
  const refDialogo = useRef(null);
  const refDialogoDeletar = useRef(null);
  const refDialogoDespesaCartao = useRef(null);
  const refDialogoPagarFaturaTotal = useRef(null);

  const create = () => {
    if (refDialogo.current) {
      refDialogo.current.handleCreate();
    }
  };

  const edit = (data) => {
    if (refDialogo.current) {
      refDialogo.current.handleEdit(data.id);
    }
  };

  const graficosDespesas = ({ tipo, id }) => {};

  const historicoFaturas = ({ tipo, id }) => {};

  const createDespesaCartao = (data) => {
    if (refDialogoDespesaCartao.current) {
      refDialogoDespesaCartao.current.handleCreateApartirCartao(data.id);
    }
  };

  const arquivar = (data) => {
    if (refDialogoDeletar.current) {
      refDialogoDeletar.current.handleOpen(data.id);
    }
  };

  const pagarFaturaTotal = ({ cartao_credito_id, mes, ano }) => {
    if (refDialogoPagarFaturaTotal.current) {
      refDialogoPagarFaturaTotal.current.handleOpen(
        cartao_credito_id,
        mes,
        ano,
      );
    }
  };

  const cartoesArquivados = () => {
    history.push(`/cartoes_credito_cancelados`);
  };

  const handleClose = () => {
    refresh();
  };

  const irParaTelaDespesasCartao = (data) => {
    const queryOj = {};

    queryOj.displayedFilters = JSON.stringify({});
    queryOj.filter = JSON.stringify({
      cartao_credito_id: data.id,
      periodo2: {
        mes: data.mes,
        ano: data.ano,
      },
    });

    history.push(
      `/despesas_cartao_credito?${stringify(
        queryOj,
      )}&order=DESC&page=1&perPage=50&sort=data_pagamento`,
    );
  };

  return (
    <>
      <ListToolbar
        filters={<Filter />}
        actions={
          <Actions
            handleCriar={create}
            handleCartoesArquivados={cartoesArquivados}
          />
        }
        {...props}
      />
      <div style={{ borderRadius: '20px', marginTop: '30px', display: 'flex' }}>
        <CategoryGrid
          handleEdit={edit}
          handleCriar={create}
          handleGraficoDespesas={graficosDespesas}
          handleHistoricoFaturas={historicoFaturas}
          handleArquivar={arquivar}
          handleNewDespesaCartao={createDespesaCartao}
          irParaTelaDespesasCartao={irParaTelaDespesasCartao}
          handlePagarFaturaTotal={pagarFaturaTotal}
          {...props}
        />
        <Aside {...props} />
      </div>
      <DialogoCartaoCredito ref={refDialogo} handleClose={handleClose} />
      <DialogoDespesaCartaoCredito
        ref={refDialogoDespesaCartao}
        handleClose={handleClose}
      />
      <DialogoDeletarCartaoCredito
        ref={refDialogoDeletar}
        handleClose={handleClose}
      />
      <DialogoPagarTotalFatura
        ref={refDialogoPagarFaturaTotal}
        handleClose={handleClose}
      />
    </>
  );
};

const ContainerMyList = ({ ...props }) => (
  <ListBase {...props}>
    <MyList />
  </ListBase>
);

const CategoryList = (props) => (
  <>
    <ContainerMyList
      {...props}
      title=""
      component="div"
      bulkActionButtons={false}
      sort={{ field: 'nome', order: 'asc' }}
      filterDefaultValues={{
        statusFatura: false,
      }}
      filter={{ ativo: true }}
      perPage={20}
      pagination={false}
      aside={<Aside />}
    />
  </>
);

export default CategoryList;
