import React from 'react';
import { Filter as FilterAdmin } from 'react-admin';

import { PeriodoInput } from '../../Components/InputsReactAdmin';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <PeriodoInput
      source="periodoCriacao"
      label="Período de Criação"
      defaultValue={{
        start: new Date(),
        end: new Date(),
      }}
    />
  </FilterAdmin>
);

export default Filter;
