import React, { useRef, useState, useCallback, useEffect } from 'react';
import {
  TextField,
  ReferenceField,
  ShowButton,
  NumberField,
  ListBase,
  ListToolbar,
  Pagination,
  FunctionField,
  useRefresh,
  Title,
  useListContext,
  Datagrid,
  DateField,
  ListContextProvider,
  BooleanField,
} from 'react-admin';

import { Card, Divider, Tabs, Tab } from '@material-ui/core';

import { STATUS_ATIVO, STATUS_DELETADO } from '../../utils/constants';
import Actions from './ActionsList';
import DataField from './fields/DataField';
import TipoField from './fields/TipoField';
import ValorField from './fields/ValorField';
import Filter from './Filter';

const PostPanel = (props) => (
  <div>
    {props.record.criador ? (
      <div>{`Criador: ${props.record.criador.username}`}</div>
    ) : null}
    {props.record.atualizador ? (
      <div>{`último a Atualizar: ${props.record.atualizador.username}`}</div>
    ) : null}
    {props.record.deletador ? (
      <div>{`Quem Deletou: ${props.record.deletador.username}`}</div>
    ) : null}
  </div>
);

const tabs = [
  { id: 'ativos', name: 'Confirmados' },
  { id: 'inativos', name: 'Cancelados' },
];

const MyList = ({ ...props }) => {
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [ativos, setAtivos] = useState([]);
  const [inativos, setInativos] = useState([]);

  useEffect(() => {
    if (ids && ids !== filterValues.status) {
      switch (filterValues.status) {
        case STATUS_ATIVO:
          setAtivos(ids);
          break;
        case STATUS_DELETADO:
          setInativos(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.status]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters(
          {
            ...filterValues,
            status: value === 'ativos' ? STATUS_ATIVO : STATUS_DELETADO,
          },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  return (
    <>
      <Title title="Impostos" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Divider />
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.status === STATUS_ATIVO ? tabs[0].id : tabs[1].id}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.status === STATUS_ATIVO && (
            <ListContextProvider value={{ ...listContext, ids: ativos }}>
              <Datagrid {...props}>
                <TextField source="id" label="ID" />
                <TextField source="descricao" label="Descrição" />
                <TipoField source="tipo" label="Tipo" />
                <DataField source="data" label="Data" />
                <ValorField source="valor" label="Valor" />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.status === STATUS_DELETADO && (
            <ListContextProvider value={{ ...listContext, ids: inativos }}>
              <Datagrid {...props}>
                <TextField source="id" label="ID" />
                <TextField source="descricao" label="Descrição" />
                <TipoField source="tipo" label="Tipo" />
                <DataField source="data" label="Data" />
                <ValorField source="valor" label="Valor" />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
        <TransacoesPagination />
      </Card>
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List = (props) => (
  <>
    <ContainerMyList
      {...props}
      expand={<PostPanel />}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Impostos"
      filterDefaultValues={{
        status: STATUS_ATIVO,
        periodo: {
          start: new Date(),
          end: new Date(),
        },
      }}
      component="div"
    />
  </>
);

export default List;
