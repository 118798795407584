import React, { cloneElement, useState, useCallback } from 'react';
import {
  TopToolbar,
  Toolbar,
  sanitizeListRestProps,
  Button,
  useListContext,
} from 'react-admin';
import { useHistory } from 'react-router-dom';

import {
  Box,
  makeStyles,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
} from '@material-ui/core';
import Avatar2 from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import Chip from '@material-ui/core/Chip';
import Collapse from '@material-ui/core/Collapse';
import { red } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import Fade from '@material-ui/core/Fade';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { LabelImportantRounded, VoicemailRounded } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BarChartIcon from '@material-ui/icons/BarChart';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import SearchInputMobills from '../Transacoes/Inputs/SearchInputMobills';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: '20px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {},
}))(MenuItem);

const useStyles = makeStyles({
  root: {
    flex: 1,
    alignItems: 'center',
  },
});

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  // displayedFilters,
  exporter,
  // filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  enabledCreate,
  ...rest
}) => {
  const classes = useStyles();
  const listContext = useListContext();
  const history = useHistory();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;

  const aplicarFiltros = useCallback(
    (search) => {
      if (setFilters) {
        setFilters({ ...filterValues, search }, displayedFilters);
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  return (
    <TopToolbar {...sanitizeListRestProps(rest)} className={classes.root}>
      <Box
        display="flex"
        justifyContent="space-between"
        flex={1}
        alignItems="center"
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Box marginRight="15px">
            <Tooltip title="Mais Opções" placement="top" aria-label="add2">
              <Box
                boxShadow={2}
                borderRadius="50px"
                display="flex"
                alignItems="center"
                width="44px"
                style={{
                  backgroundColor: 'white',
                  height: '48px',
                  width: '48px',
                }}
              >
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  style={{ height: '48px', width: '48px' }}
                  onClick={() => history.push(`/cartoes_credito`)}
                  disableElevation
                >
                  <ArrowBackIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>
          <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
            Cartões de crédito arquivados
          </div>
        </div>
        <Box display="flex">
          <SearchInputMobills
            color="#00796b"
            onChange={(search) => aplicarFiltros(search)}
            placeholder="Pesquise por nome do cartão ou limite"
          />
        </Box>
      </Box>
    </TopToolbar>
  );
};

export default ListActions;
