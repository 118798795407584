import React, { forwardRef, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

import useDebounce from '../../../hooks/useDebounce';
import AutoCompleteFornecedor from './Template';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      opacity: '0.75',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
      color: theme.palette.secondary.main,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  }),
);

const Search = forwardRef(
  (
    {
      label,
      value,
      onChange,
      searchHandle,
      handleKey = () => {},
      filters = { ativo: true },
    },
    forwardedRef,
  ) => {
    const classes = useStyles();
    const [valueDebounce] = useDebounce(value, 500);
    const [fornecedor, setFornecedor] = useState(null);

    function submitFornecedor() {
      if (fornecedor) {
        searchHandle(fornecedor);
      } else {
        searchHandle('');
      }
      setFornecedor(null);
    }

    function keyEvent(keyCode, keyName) {
      if (keyCode === 13) {
        submitFornecedor();
      } else {
        handleKey(keyCode, keyName);
      }
    }

    return (
      <Paper
        component="form"
        className={classes.root}
        onSubmit={(e) => e.preventDefault()}
      >
        <>
          <AutoCompleteFornecedor
            inputValue={value}
            onChange={(fornecedorNew) => setFornecedor(fornecedorNew)}
            updateValue={(newValue) => onChange(newValue)}
            value={fornecedor}
            label={label}
            valueDebounce={valueDebounce}
            resource="fornecedores"
            nested={[]}
            handleKey={keyEvent}
            ref={forwardedRef}
            filters={filters}
          />
          <IconButton
            className={classes.iconButton}
            aria-label="search"
            onClick={() => submitFornecedor()}
            tabindex="-1"
          >
            <SearchIcon />
          </IconButton>
        </>
      </Paper>
    );
  },
);

export default Search;
