import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CloseIcon from '@material-ui/icons/Close';
import Rating from '@material-ui/lab/Rating';
import { set } from 'date-fns';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  TextInput,
  AutoCompleteRemoto,
  SelectInput,
} from '../../../Components';
import { createAPI, updateAPI, getListAllAPI, api } from '../../../services';
import {
  MALOTE_MALOTE_OPTION,
  MALOTE_SANGRIA_OPTION,
  MALOTE_TROCO_OPTION,
  MALOTE_SUBMALOTE_OPTION,
  formatMoeda,
} from '../../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_ID = -1;

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState(DEFAULT_ID);
  const [codigo, seCodigo] = useState('');
  const [conta, setConta] = useState('');
  const [valor, setValor] = useState('');
  const [tipo, setTipo] = useState('');
  const [horario, setHorario] = useState('');
  const [descricaoProblema, setDescricaoProblema] = useState('');

  const [carregando, setCarregando] = useState(false);

  const classes = useStyles({ cor: '#009FD4' });

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  function writeCodigoBarras(id) {
    const idString = `${id}`;
    let codigoEN13 = '789';
    for (let i = 0; i < 10 - idString.length; i += 1) {
      codigoEN13 = `${codigoEN13}0`;
    }
    codigoEN13 = `${codigoEN13}${idString}`;
    return codigoEN13;
  }

  function getTipoPacote() {
    switch (tipo) {
      case MALOTE_MALOTE_OPTION:
        return 'Malote';
      case MALOTE_SANGRIA_OPTION:
        return 'Sangria';
      case MALOTE_TROCO_OPTION:
        return 'Troco';
      case MALOTE_SUBMALOTE_OPTION:
        return 'Submalote';
      default:
        return '';
    }
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'malote_pacotes',
        ['id', 'asc'],
        { id: [id] },
        ['conta'],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        //
        setTipo(data.data[0].tipo);
        seCodigo(writeCodigoBarras(data.data[0].id));
        setConta(data.data[0].conta.nome);
        setValor(formatMoeda(data.data[0].valor));
        setHorario(
          moment(new Date(data.data[0].data_pagamento)).format('DD/MM/YYYY'),
        );
        //
        setCarregando(false);
      } else {
        throw 'Este Pacote não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    try {
      if (id <= 0) {
        /////
      } else {
        const response = await api.put(`/mandar_pacote_lixeira/${id}`, {
          descricao_problema: descricaoProblema,
        });
        const { status, message } = response.data;
        if (status) {
          setCarregando(false);
          enqueueSnackbar('Pacote enviado à lixeira com sucesso!', {
            variant: 'success',
          });
        } else {
          setCarregando(false);
          enqueueSnackbar(message, {
            variant: 'success',
          });
        }
      }

      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
      setDescricaoProblema('');
    },
  }));

  function validateNome() {
    let error = '';
    if (!descricaoProblema) error = 'Este campo é obrigatório';
    else if (descricaoProblema.length > 200) {
      error = 'Número de caracteres maior que o permitido (máximo 200)';
    }
    return error;
  }

  function getErros() {
    const errosOld = [''];
    errosOld[0] = validateNome();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          {`Enviar ${getTipoPacote()} para a lixeira`}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <Box flex={1} mr="0.5em">
                <TextInput
                  fullWidth
                  source="codigo"
                  label="Código"
                  handleEnter={() => {}}
                  handleKey={() => {}}
                  value={codigo}
                  onChange={(value) => {}}
                  disabled
                />
                <TextInput
                  fullWidth
                  source="conta"
                  label="Conta"
                  handleEnter={() => {}}
                  handleKey={() => {}}
                  value={conta}
                  onChange={(value) => {}}
                  disabled
                />
                <TextInput
                  fullWidth
                  source="data"
                  label="Data"
                  handleEnter={() => {}}
                  handleKey={() => {}}
                  value={horario}
                  onChange={(value) => {}}
                  disabled
                />
                <TextInput
                  fullWidth
                  source="valor"
                  label="Valor"
                  handleEnter={() => {}}
                  handleKey={() => {}}
                  value={valor}
                  onChange={(value) => {}}
                  disabled
                />
                <TextInput
                  fullWidth
                  source="descricao_problema"
                  label="Descrição do Problema"
                  handleEnter={() => {}}
                  handleKey={() => {}}
                  value={descricaoProblema}
                  onChange={(value) => setDescricaoProblema(value.target.value)}
                  multiline
                  autoFocus
                />
              </Box>
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={() => {
              setOpen(false);
            }}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
            variant="contained"
            color="primary"
          >
            Fechar
          </Button>
          <div className={classes.wrapper}>
            <Button
              onClick={handleSalvar}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              disabled={erroExistente || carregando}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
            {carregando && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
