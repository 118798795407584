import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '400px',
  },
}));

const diasSemana = {
  0: 'Domingo',
  1: 'Segunda - Feira',
  2: 'Terça - Feira',
  3: 'Quarta - Feira',
  4: 'Quinta - Feira',
  5: 'Sexta - Feira',
  6: 'Sábado',
};

const mesesAno = {
  0: 'Janeiro',
  1: 'Fevereiro',
  2: 'Março',
  3: 'Abril',
  4: 'Maio',
  5: 'Junho',
  6: 'Julho',
  7: 'Agosto',
  8: 'Setembro',
  9: 'Outubro',
  10: 'Novembro',
  11: 'Dezembro',
};

function formatData(data) {
  if (data) {
    const dataObj = new Date(data);
    return `${diasSemana[dataObj.getDay()]}, ${dataObj.getDate()} de ${
      mesesAno[dataObj.getMonth()]
    } de ${dataObj.getFullYear()}`;
  }
  return '-';
}

const DescricaoField = (props) => {
  const classes = useStyles();

  const record = useRecordContext(props);
  const value = record ? formatData(record.record.data) : '-';

  return (
    <Tooltip title={value} placement="bottom" aria-label="add2">
      <Typography className={classes.text}>{value}</Typography>
    </Tooltip>
  );
};

export default DescricaoField;
