import * as React from 'react';
import { useRecordContext } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PrintIcon from '@material-ui/icons/Print';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from 'moment';

function getIsEdit(data) {
  const dataTime = new Date(moment(data).startOf('day').format()).getTime();
  const atualTime = new Date(moment().startOf('day').format()).getTime();

  return atualTime > dataTime;
}

const ActionsField = ({
  tipo,
  handleEdit = () => {},
  handleDescription = () => {},
  handleShow = () => {},
  handleImprimirContraProva = () => {},
  handleTrocarTurno = () => {},
  ativo,
  ...rest
}) => {
  const record = useRecordContext(rest);
  const isEdit = record
    ? record.record
      ? getIsEdit(record.record.data_competencia) &&
        !record.record.is_verificado
      : false
    : false;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Tooltip title="Trocar Turno" placement="bottom" aria-label="excluir2">
        <IconButton
          aria-label="imprimir-checklist"
          size="small"
          onClick={() => handleTrocarTurno(record.record)}
          //disabled={gerenciado}
        >
          <TrackChangesIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title="Imprimir Última Contraprova"
        placement="bottom"
        aria-label="excluir2"
      >
        <IconButton
          aria-label="imprimir-checklist"
          size="small"
          onClick={() => handleImprimirContraProva(record.record)}
          //disabled={gerenciado}
        >
          <PrintIcon style={{ color: 'green' }} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Editar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="edit"
          size="small"
          onClick={() => handleEdit(record.record)}
          disabled={!isEdit}
        >
          <EditOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Detalhes" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleShow(record.record)}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ActionsField;
