import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
  useEffect,
} from 'react';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';

import {
  POR_UNIDADE,
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  getTotalCompra,
} from '../../../../../utils';
import DialogoPrecificacaoNova from '../InicioFrenteCaixaCompra/DialogoPrecificacaoNova';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogoPassadorLotes = forwardRef(
  ({ handleClose, handleSalvar }, ref) => {
    const [open, setOpen] = useState(false);

    const dadosLocal = useRef(null);

    const position = useRef(0);

    const [cont, setCont] = useState(0);

    const refDialogoPrecificacaoNova = useRef(null);

    useImperativeHandle(ref, () => ({
      handleOpen(
        hasRepasseNaoUnitarioIPI,
        hasRepasseNaoUnitarioFrete,
        hasRepasseNaoUnitarioST,
        hasRepasseNaoUnitarioSeguros,
        hasRepasseNaoUnitarioOutrasDespesas,
        fornecedor,
        config,
        lotes,
      ) {
        dadosLocal.current = {
          hasRepasseNaoUnitarioIPI,
          hasRepasseNaoUnitarioFrete,
          hasRepasseNaoUnitarioST,
          hasRepasseNaoUnitarioSeguros,
          hasRepasseNaoUnitarioOutrasDespesas,
          lotes,
          fornecedor,
          config,
        };
        position.current = 0;
        setOpen(true);
      },
    }));

    useEffect(() => {
      if (open && dadosLocal.current) {
        handlePrecificar(dadosLocal.current.lotes[0]);
        position.current = 0;
      }
    }, [open]);

    function handleCloseDialog() {
      dadosLocal.current = null;
      position.current = 0;
      setOpen(false);
      handleClose();
    }

    function handleClosePrecificacao(dados) {
      if (dadosLocal.current) {
        if (dados) {
          const { index, produto: produto2, ...dados2 } = dados;

          if (index >= 0) {
            let unidades = 0;
            let peso = 0;

            if (produto2.unidade.tipo === POR_PESO_FIXO) {
              unidades = dados.qtde;
              peso = unidades * produto2.peso_medio;
            } else if (produto2.unidade.tipo === POR_UNIDADE) {
              unidades = dados.qtde;
              peso = 0;
            } else {
              unidades = 0;
              peso = dados.qtde;
            }

            const dadosFormatados = {
              produto: produto2,
              peso: produto2.unidade.tipo === POR_UNIDADE ? 0 : peso,
              total: getTotalCompra(
                peso,
                unidades,
                dados2.preco_compra_impostos,
                produto2,
              ),
              unidades:
                produto2.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
                  ? 0
                  : unidades,
              unitario: dados2.preco_compra,
              uidd: `${produto2.nome}${dadosLocal.current.lotes.length}`,
              idIndicadorChange: -1,
              validade: dados2.validade,
              unitarioImposto: dados2.preco_compra_impostos,
              cg: dados2.cg,
              dadosPrecificacao: dados2.dadosPrecificacao,
              nImpressoes: dados2.nImpressoes,
              index,
            };

            const lotesCopy = dadosLocal.current.lotes.slice();
            lotesCopy[index].dadosFormatados = dadosFormatados;

            dadosLocal.current = {
              ...dadosLocal.current,
              lotes: lotesCopy,
            };

            if (index === lotesCopy.length - 1) {
              handleSalvar(
                dadosLocal.current.lotes.map((item) => item.dadosFormatados),
              );
              dadosLocal.current = null;
              position.current = 0;
              setOpen(false);
            } else {
              position.current += 1;
              handlePrecificar(dadosLocal.current.lotes[position.current]);
              setCont(cont + 1);
            }
          }
        } else if (position.current === 0) {
          handleCloseDialog();
        } else {
          position.current -= 1;
          handlePrecificar(dadosLocal.current.lotes[position.current]);
          setCont(cont + 1);
        }
      }
    }

    function format10(value) {
      return value < 10 ? `0${value}` : `${value}`;
    }

    function formatDate(date) {
      if (date) {
        const dateReal = new Date(date);
        const ano = dateReal.getFullYear();
        const mes = format10(dateReal.getMonth() + 1);
        const dia = format10(dateReal.getDate());
        return `${ano}-${mes}-${dia}`;
      }
      return '';
    }

    function handlePrecificar(dados) {
      if (refDialogoPrecificacaoNova.current && dadosLocal.current) {
        refDialogoPrecificacaoNova.current.handleEdit(
          dados.produtoId,
          dadosLocal.current.config,
          dadosLocal.current.fornecedor,
          formatDate(dados.validade),
          0,
          dados.unitario,
          dados.quantidade,
          dados.repasseDoIpi,
          dados.repasseDoFreteUnitario,
          dados.repasseDeSt,
          dados.repasseDeSeguros,
          dados.repasseDeOutrasDespesas,
          dados.index,
        );
      }
    }

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          fullScreen
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogContent dividers>
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '40px',
                fontWeight: 'bold',
              }}
            >
              {`Carregando Item ${position.current + 1}/${
                dadosLocal.current ? dadosLocal.current.lotes.length : 0
              }... `}
            </div>
          </DialogContent>
        </Dialog>
        <DialogoPrecificacaoNova
          isEmitMessage={false}
          isCloseX={false}
          ref={refDialogoPrecificacaoNova}
          handleClose={handleClosePrecificacao}
          estoque="-"
          hasRepasseNaoUnitarioIPI={
            dadosLocal.current
              ? dadosLocal.current.hasRepasseNaoUnitarioIPI
              : false
          }
          hasRepasseNaoUnitarioFrete={
            dadosLocal.current
              ? dadosLocal.current.hasRepasseNaoUnitarioFrete
              : false
          }
          hasRepasseNaoUnitarioST={
            dadosLocal.current
              ? dadosLocal.current.hasRepasseNaoUnitarioST
              : false
          }
          hasRepasseNaoUnitarioSeguros={
            dadosLocal.current
              ? dadosLocal.current.hasRepasseNaoUnitarioSeguros
              : false
          }
          hasRepasseNaoUnitarioOutrasDespesas={
            dadosLocal.current
              ? dadosLocal.current.hasRepasseNaoUnitarioOutrasDespesas
              : false
          }
          label1="Voltar"
          label2="Prosseguir"
          prefixoTitle={`Item ${position.current + 1}/${
            dadosLocal.current ? dadosLocal.current.lotes.length : 0
          } - `}
        />
      </div>
    );
  },
);

export default DialogoPassadorLotes;
