import React from 'react';
import {
  TextInput,
  Filter as FilterAdmin,
  SelectInput,
  ReferenceInput,
  BooleanInput,
} from 'react-admin';

import { STATUS_ATIVO } from '../../utils/constants';
import choices from './choices';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <TextInput label="Descrição" source="nome" />
    <SelectInput
      source="modalidade"
      choices={choices}
      label="Forma de Pagamento"
      allowEmpty={false}
    />
    <ReferenceInput
      source="conta_id"
      reference="contas"
      label="Conta"
      allowEmpty={false}
      filter={{ status: STATUS_ATIVO }}
    >
      <SelectInput optionText="nome" />
    </ReferenceInput>
    <BooleanInput source="dinheiro_vivo" label="Dinheiro vivo" />
  </FilterAdmin>
);

export default Filter;
