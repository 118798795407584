import React from 'react';
import {
  Filter as FilterAdmin,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

import {
  IntegerInput,
  PeriodoInput,
  PrecoInput,
} from '../../Components/InputsReactAdmin';
import { STATUS_ATIVO } from '../../utils';
import choices from './choices';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <ReferenceInput
      fullWidth
      label="Produto"
      source="produto_id"
      reference="produtos_search_filter"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      perPage={10}
      allowEmpty={false}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput>
    <PeriodoInput
      source="periodo"
      label="Período"
      defaultValue={{
        start: new Date(),
        end: new Date(),
      }}
    />
    <PrecoInput
      source="preco_de_venda"
      label="Preço de Venda"
      fixedDecimalScale={false}
    />
    <SelectInput
      source="tipo"
      choices={choices}
      label="Tipo"
      allowEmpty={false}
    />
    <ReferenceInput
      fullWidth
      label="Responsável"
      source="user_id"
      reference="users"
      sort={{ field: 'username', order: 'ASC' }}
      filterToQuery={(searchText) => ({ username: `${searchText}` })}
      perPage={10}
      allowEmpty={false}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
  </FilterAdmin>
);

export default Filter;
