import React from 'react';
import { useInput, InputHelperText, FieldTitle } from 'react-admin';
import NumberFormat from 'react-number-format';

import TextField from '@material-ui/core/TextField';

function NumberFormatFormat(props) {
  const {
    inputRef,
    onChange,
    formato,
    escala,
    sufixo,
    prefixo,
    fixedDecimalScale,
    ...other
  } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format={formato}
      allowEmptyFormatting={formato !== null}
      mask="_"
      thousandSeparator="."
      decimalSeparator=","
      prefix={prefixo}
      suffix={sufixo}
      decimalScale={escala}
      fixedDecimalScale={fixedDecimalScale}
      isNumericString
    />
  );
}


const TemplateInput = ({
  formato = null,
  escala = 0,
  sufixo = '',
  prefixo = '',
  fixedDecimalScale = true,
  ...rest
}) => {
  const {
    id,
    input: { name, onChange, ...rest2 },
    meta: { touched, error },
    isRequired,
  } = useInput({
    ...rest,
  });

  return (
    <TextField
      // autoComplete="off"
      id={id}
      name={name}
      onChange={onChange}
      variant="filled"
      error={!!(touched && error)}
      helperText={
        <InputHelperText
          touched={touched}
          error={error}
          helperText={rest.helperText}
        />
      }
      label={
        <FieldTitle
          label={rest.label}
          source={rest.source}
          resource={rest.resource}
          isRequired={isRequired}
        />
      }
      margin="dense"
      InputProps={{
        inputComponent: NumberFormatFormat,
      }}
      inputProps={{
        formato,
        escala,
        sufixo,
        prefixo,
        fixedDecimalScale,
      }}
      {...rest}
      {...rest2}
    />
  );
};

export default TemplateInput;
