import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
} from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';

import { api } from '../../../../../../../services';
import {
  ATALHO_VOLTAR,
  ATALHO_AVANCAR,
  SECONDARY_COLOR,
} from '../../../../../../../utils';

const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const ESC_ACTION = 'Escape';

const useStyles = makeStyles((theme) =>
  createStyles({
    action: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    root: {
      backgroundColor: theme.palette.background.paper,
    },
    button: {
      color: 'white',
      backgroundColor: theme.palette.secondary.main,
    },
    disabledButton: {
      backgroundColor: 'red',
    },
  }),
);

const DialogoCpfNota = forwardRef(
  ({ cliente, handleCarregarLiberacoes = () => {} }, ref) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    function handleClose() {
      setOpen(false);
    }

    useImperativeHandle(ref, () => ({
      handleOpen() {
        setOpen(true);
      },
    }));

    async function handleSalvar() {
      setOpen(false);
      const liberacoes = await getLiberacoes(cliente.id);
      await marcarLiberacoesComoUsadas(liberacoes);
      handleCarregarLiberacoes(liberacoes);
    }

    function handleActions(action, name) {
      switch (name) {
        case FECHAR_VENDA_ACTION:
          handleSalvar();
          break;
        case CANCELAR_VENDA_ACTION:
          handleClose();
          break;
        case ESC_ACTION:
          handleClose();
          break;
        default:
          break;
      }
    }

    async function getLiberacoes(id) {
      const response = await api.get(`/liberacoes-disponiveis-clientes/${id}`);
      return response.data;
    }

    async function marcarLiberacoesComoUsadas(liberacoes) {
      const ids = liberacoes.map((item) => item.id);
      const response = await api.put(`/marcar-liberacoes-usadas`, {
        liberacoes: ids,
      });
      return response.data;
    }

    const content = `Deseja carregar as liberações de preços disponíveis para o cliente ${
      cliente ? cliente.nome : ''
    }`;

    return (
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{
              color: 'white',
              backgroundColor: SECONDARY_COLOR,
              textAlign: 'center',
            }}
          >
            {`Carregar Liberações de Preço para o cliente - ${
              cliente ? cliente.nome : ''
            }`}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '20px 10px',
            }}
          >
            <InputBase
              label=""
              value={content}
              onChange={() => {}}
              fullWidth
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  /////
                } else handleActions(e.keyCode, e.key);
              }}
              multiline
              autoFocus
              style={{
                margin: 0,
                padding: '10px',
                border: 'none',
                outline: 'none',
              }}
            />
          </DialogContent>
          <DialogActions className={classes.action}>
            <Button
              onClick={() => handleClose()}
              color="secondary"
              variant="contained"
              tabindex="-1"
            >
              {`Cancelar (${CANCELAR_VENDA_ACTION})`}
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => handleSalvar()}
              tabindex="-1"
            >
              {`Confirmar (${FECHAR_VENDA_ACTION})`}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCpfNota;
