const TOKEN_KEY = '@controleEstoqueToken';
const REFRESH_TOKEN_KEY = '@controleEstoqueRefreshToken';
const USER_ID = '@userId';
const USERNAME = '@username';
const TIME_TELA_BLOQUEIO = '@timeTelaBloqueio';
const IS_TELA_BLOQUEIO = '@isTelaBloqueio';
export const SESSION_ID = '@sessionId';

/*export const setTimeTelaBloqueio = (timeTelaBloqueio) =>
  localStorage.setItem(TIME_TELA_BLOQUEIO, String(timeTelaBloqueio.getTime()));

export const getTimeTelaBloqueio = () => {
  const time = localStorage.getItem(TIME_TELA_BLOQUEIO);
  if (time) {
    const date = new Date();
    date.setTime(parseInt(time, 10));
    return date;
  }
  return null;
};

export const isAuthenticated = () => {
  const isAuth = localStorage.getItem(REFRESH_TOKEN_KEY) !== null;
  return isAuth;
};

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY);

export const login = (token, refreshToken) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  localStorage.setItem(IS_TELA_BLOQUEIO, 'false');
};

export const setNewToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(USERNAME);
  localStorage.removeItem(USER_ID);
  localStorage.removeItem(IS_TELA_BLOQUEIO);
};

export const setUsername = (username) =>
  localStorage.setItem(USERNAME, username);

export const getUsername = () => localStorage.getItem(USERNAME);

export const setUserId = (userId) =>
  localStorage.setItem(USER_ID, String(userId));

export const getUserId = () => {
  const userIid = String(localStorage.getItem(USER_ID));
  return parseInt(userIid, 10);
};

export const setSessionId = (sessionId) =>
  localStorage.setItem(SESSION_ID, String(sessionId));

export const getSessionId = () => {
  const sessionId = localStorage.getItem(SESSION_ID);
  return parseInt(sessionId, 10);
};

export const setIsTimeTelaBloqueio = (isTelaBloqueio) =>
  localStorage.setItem(
    IS_TELA_BLOQUEIO,
    isTelaBloqueio === true ? 'true' : 'false',
  );

export const getIsTimeTelaBloqueio = () => {
  const value = localStorage.getItem(IS_TELA_BLOQUEIO);
  if (value) {
    return value === 'true';
  }
  return false;
};*/

export const setTimeTelaBloqueio = (timeTelaBloqueio) =>
  sessionStorage.setItem(
    TIME_TELA_BLOQUEIO,
    String(timeTelaBloqueio.getTime()),
  );

export const getTimeTelaBloqueio = () => {
  const time = sessionStorage.getItem(TIME_TELA_BLOQUEIO);
  if (time) {
    const date = new Date();
    date.setTime(parseInt(time, 10));
    return date;
  }
  return null;
};

export const isAuthenticated = () => {
  const isAuth = sessionStorage.getItem(REFRESH_TOKEN_KEY) !== null;
  return isAuth;
};

export const getToken = () => sessionStorage.getItem(TOKEN_KEY);

export const getRefreshToken = () => sessionStorage.getItem(REFRESH_TOKEN_KEY);

export const login = (token, refreshToken) => {
  sessionStorage.setItem(TOKEN_KEY, token);
  sessionStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  sessionStorage.setItem(IS_TELA_BLOQUEIO, 'false');
};

export const setNewToken = (token) => {
  sessionStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
  sessionStorage.removeItem(TOKEN_KEY);
  sessionStorage.removeItem(REFRESH_TOKEN_KEY);
  sessionStorage.removeItem(USERNAME);
  sessionStorage.removeItem(USER_ID);
  sessionStorage.removeItem(IS_TELA_BLOQUEIO);
};

export const setUsername = (username) =>
  sessionStorage.setItem(USERNAME, username);

export const getUsername = () => sessionStorage.getItem(USERNAME);

export const setUserId = (userId) =>
  sessionStorage.setItem(USER_ID, String(userId));

export const getUserId = () => {
  const userIid = String(sessionStorage.getItem(USER_ID));
  return parseInt(userIid, 10);
};

export const setSessionId = (sessionId) =>
  sessionStorage.setItem(SESSION_ID, String(sessionId));

export const getSessionId = () => {
  const sessionId = sessionStorage.getItem(SESSION_ID);
  return parseInt(sessionId, 10);
};

export const setIsTimeTelaBloqueio = (isTelaBloqueio) =>
  sessionStorage.setItem(
    IS_TELA_BLOQUEIO,
    isTelaBloqueio === true ? 'true' : 'false',
  );

export const getIsTimeTelaBloqueio = () => {
  const value = sessionStorage.getItem(IS_TELA_BLOQUEIO);
  if (value) {
    return value === 'true';
  }
  return false;
};
