import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
} from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Box,
  Typography,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import { useSnackbar } from 'notistack';

import { TextInput, AutoCompleteRemoto } from '../../../Components/Inputs';
import { api, getListAllAPI } from '../../../services';
import {
  SECONDARY_COLOR,
  ATALHO_AVANCAR,
  ATALHO_VOLTAR,
  STATUS_ATIVO,
  formatMoeda,
  writeCodigoBarras,
} from '../../../utils';

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const ESC_ACTION = 'Escape';

const DialogoNota = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const [idTroco, setIdTroco] = useState(0);
  const [usernameAtual, setUsernameAtual] = useState('');
  //const [userId, setUserId] = useState(0);
  const [passwordAtual, setPasswordAtual] = useState('');

  const [usuarioNovo, setUsuarioNovo] = useState(null);
  const [passwordNovo, setPasswordNovo] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [carregando, setCarregando] = useState(false);

  const [codigo, setCodigo] = useState('');
  const [valor, setValor] = useState(0);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  async function getDataResource(id) {
    try {
      setCarregando(true);
      const data = await getListAllAPI(
        'malote_trocos',
        ['id', 'asc'],
        { id: [id] },
        ['transportador1', 'transportador2'],
      );
      if (data.data.length > 0) {
        if (data.data[0].perdido)
          throw 'Este troco foi enviado para a lixeira!';
        if (!data.data[0].visible) throw 'Este troco já foi usado em um caixa!';
        if (!data.data[0].transportador1)
          throw 'Não foi registrado neste troco quem o enviou para o cofre!';
        if (!data.data[0].data_confirmacao_transporte_1)
          throw 'Este troco não teve sua entrega no cofre confirmada!';
        if (data.data[0].data_confirmacao_transporte_2)
          throw 'Este troco já teve sua retirada do cofre confirmada anteriormente!';
        //
        setIdTroco(data.data[0].id);
        setUsernameAtual(
          data.data[0].transportador2
            ? data.data[0].transportador2.username
            : '',
        );
        setPasswordAtual('');
        setUsuarioNovo(null);
        setPasswordNovo('');

        setCodigo(writeCodigoBarras(data.data[0].id));
        setValor(formatMoeda(data.data[0].valor));
      } else {
        throw 'Este troco não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    } finally {
      setCarregando(false);
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(idTroco) {
      setOpen(true);
      getDataResource(idTroco);
    },
  }));

  async function handleSalvarDialog() {
    if (!erroExistente) {
      try {
        setCarregando(true);
        let dataUsuarioAtual = {
          data: {
            status: true,
            message: '',
          },
        };

        if (usernameAtual) {
          dataUsuarioAtual = await api.post('/login_retirada_caixa', {
            username: usernameAtual,
            password: passwordAtual,
          });
        }

        if (dataUsuarioAtual.data.status) {
          const dataUsuarioNovo = await api.post('/login_retirada_caixa', {
            username: usuarioNovo.username,
            password: passwordNovo,
          });

          if (dataUsuarioNovo.data.status) {
            const dataChangeTransportadorEntradaCofre = await api.put(
              `/alterar_transportador_saida_cofre/${idTroco}`,
              {
                newTransportador: usuarioNovo.id,
              },
            );

            if (dataChangeTransportadorEntradaCofre.data.status) {
              setOpen(false);
              handleClose();
              enqueueSnackbar(
                'Mudança do transportador desye troco até o cofre feita com sucesso!',
                {
                  variant: 'success',
                },
              );
            } else {
              enqueueSnackbar(
                dataChangeTransportadorEntradaCofre.data.message,
                {
                  variant: 'error',
                },
              );
            }
          } else {
            enqueueSnackbar(dataUsuarioNovo.data.message, {
              variant: 'error',
            });
          }
        } else {
          enqueueSnackbar(dataUsuarioAtual.data.message, {
            variant: 'error',
          });
        }
      } catch (e) {
        enqueueSnackbar('Erro inesperado!', {
          variant: 'error',
        });
      } finally {
        setCarregando(false);
      }
    }
  }

  function validatePasswordUsuarioAtual() {
    let error = '';
    if (!passwordAtual) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateUsuarioNovo() {
    let error = '';
    if (!usuarioNovo) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validatePasswordUsuarioNovo() {
    let error = '';
    if (!passwordNovo) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function getErros() {
    const errosOld = [''];
    errosOld[0] = usernameAtual ? validatePasswordUsuarioAtual() : '';
    errosOld[1] = validateUsuarioNovo();
    errosOld[2] = validatePasswordUsuarioNovo();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle
          id="form-dialog-title"
          style={{
            color: 'white',
            backgroundColor: SECONDARY_COLOR,
            textAlign: 'center',
          }}
        >
          Alterar Transportador do Troco para o Checkout
        </DialogTitle>
        <DialogContent>
          <Box>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                textAlign: 'center',
              }}
            >
              Informações do Troco
            </Typography>

            <Box
              display="flex"
              style={{
                margin: '20px 0',
              }}
            >
              <Box flex={1} mr="0.5em">
                <span
                  style={{
                    marginRight: '10px',
                    color: 'red',
                  }}
                >
                  Código:
                </span>
                <span>{codigo}</span>
              </Box>
              <Box flex={1} ml="0.5em">
                <span
                  style={{
                    marginRight: '10px',
                    color: 'red',
                  }}
                >
                  Valor:
                </span>
                <span>{valor}</span>
              </Box>
            </Box>
          </Box>
          {usernameAtual ? (
            <Box>
              <Typography
                variant="h6"
                gutterBottom
                style={{
                  textAlign: 'center',
                }}
              >
                Transportador Atual
              </Typography>

              <Box display="flex">
                <Box flex={1} mr="0.5em">
                  <TextInput
                    name="username"
                    label="Usuário"
                    value={usernameAtual}
                    onChange={() => {}}
                    fullWidth
                    handleKey={() => {}}
                    handleEnter={() => {}}
                    disabled
                  />
                </Box>
                <Box flex={1} ml="0.5em">
                  <TextInput
                    handleEnter={() => {
                      handleSalvarDialog();
                    }}
                    name="password"
                    type="password"
                    label="Senha"
                    value={passwordAtual}
                    onChange={(event) => {
                      setPasswordAtual(event.target.value);
                    }}
                    fullWidth
                    handleKey={(action, name) => {
                      /*switch (name) {
                        case FECHAR_VENDA_ACTION:
                          handleSalvarDialog();
                          break;
                        case CANCELAR_VENDA_ACTION:
                          handleCloseDialog();
                          break;
                        case ESC_ACTION:
                          handleCloseDialog();
                          break;
                        default:
                          break;
                      }*/
                    }}
                    error={erros[0] !== ''}
                    helperText={erros[0]}
                    autoFocus
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          <Visibility />
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </Box>
              </Box>
            </Box>
          ) : null}
          <Box>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                textAlign: 'center',
              }}
            >
              Novo Transportador
            </Typography>

            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <AutoCompleteRemoto
                  handleEnter={() => {}}
                  id="transportador"
                  name="transportador"
                  label="Usuário"
                  value={usuarioNovo}
                  onChange={(value) => setUsuarioNovo(value)}
                  handleKey={(action, name) => {
                    /*switch (name) {
                        case FECHAR_VENDA_ACTION:
                          handleSalvarDialog();
                          break;
                        case CANCELAR_VENDA_ACTION:
                          handleCloseDialog();
                          break;
                        case ESC_ACTION:
                          handleCloseDialog();
                          break;
                        default:
                          break;
                      }*/
                  }}
                  resource="users"
                  nested={[]}
                  toString={false}
                  fullwidth
                  campo="username"
                  filters={{ status: STATUS_ATIVO }}
                  getOptionSelected={(option, value) =>
                    option.username === value.username
                  }
                  getOptionLabel={(option) => `${option.username}`}
                  error={erros[1] !== ''}
                  helperText={erros[1]}
                />
              </Box>
              <Box flex={1} ml="0.5em">
                <TextInput
                  handleEnter={() => {
                    handleSalvarDialog();
                  }}
                  name="password"
                  type="password"
                  label="Senha"
                  value={passwordNovo}
                  onChange={(event) => {
                    setPasswordNovo(event.target.value);
                  }}
                  fullWidth
                  handleKey={(action, name) => {
                    /*switch (name) {
                        case FECHAR_VENDA_ACTION:
                          handleSalvarDialog();
                          break;
                        case CANCELAR_VENDA_ACTION:
                          handleCloseDialog();
                          break;
                        case ESC_ACTION:
                          handleCloseDialog();
                          break;
                        default:
                          break;
                      }*/
                  }}
                  error={erros[2] !== ''}
                  helperText={erros[2]}
                  autoFocus
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <Visibility />
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Button
            onClick={handleCloseDialog}
            color="secondary"
            variant="contained"
            tabindex="-1"
          >
            {`Cancelar (${CANCELAR_VENDA_ACTION})`}
          </Button>
          <div className={classes.wrapper}>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                handleSalvarDialog();
              }}
              disabled={erroExistente || carregando}
            >
              {`Confirmar (${FECHAR_VENDA_ACTION})`}
            </Button>
            {carregando && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoNota;
