import * as React from 'react';
import { useRecordContext } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

const ActionsField = ({ handleEdit = () => {}, ...rest }) => {
  const record = useRecordContext(rest);

  const isEditavel = record ? record.record.is_editavel : false;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Tooltip title="Editar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="edit"
          size="small"
          onClick={() => handleEdit(record.record)}
          disabled={!isEditavel}
        >
          <EditOutlinedIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ActionsField;
