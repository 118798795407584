import React from 'react';

import EditTemplate from '../../Components/Edit';
import Erros from './Erros';
import Form from './Form';
import validate from './Validation';

const Title = ({ record }) => <span>Editar Avaria: </span>;

const Edit = (props) => (
  <EditTemplate
    {...props}
    title={<Title />}
    validate={validate}
    onFailure={Erros}
    isBack
  >
    <Form />
  </EditTemplate>
);

export default Edit;
