import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '400px',
  },
}));

const DataNegociadaField = ({ onClick, ...props }) => {
  const classes = useStyles();

  const record = useRecordContext(props);

  let value = '-';

  if (record) {
    const dataReal = new Date(
      moment(new Date(record.record.data_pagamento)).endOf('day').format(),
    );

    const dataOriginal = new Date(
      moment(new Date(record.record.data_pagamento_original))
        .endOf('day')
        .format(),
    );

    const delta = dataReal.getTime() - dataOriginal.getTime();

    value = parseInt(delta / (24 * 60 * 60 * 1000), 10);
  }

  return (
    <Tooltip
      title="Clique aqui para mais detalhes"
      placement="bottom"
      aria-label="add2"
    >
      <Typography
        className={classes.text}
        style={{
          cursor: 'pointer',
        }}
        onClick={() =>
          onClick(
            'Diferença Real Original',
            <div>
              <h3
                style={{
                  margin: '10px 0',
                }}
              >
                O que é?
              </h3>
              <p>
                A Diferença Real Original é um indicador financeiro que aponta a
                diferença em dias da Data Real (data em que o pagamento foi
                efetivamente realizado) para a Data Original do Pagamento.
              </p>
              <h3
                style={{
                  margin: '10px 0',
                }}
              >
                Para quê serve?
              </h3>
              <p>
                A Diferença Real Original serve para lhe auxiliar a identificar
                os atrasos, gerenciar os encargos (juros, multa, mora), e evitar
                protestos, negativações ou intimações judiciais, assim como
                facilitará que seja mantidas pontuações elevadas de Score
                Serasa, SPC e afins. O ideal é que a Diferença Real Original
                seja 0. Caso haja atrasos, o ideal é que seja o menor valor
                possível.
              </p>
              <h3
                style={{
                  margin: '10px 0',
                }}
              >
                Exemplo:
              </h3>
              <p>
                Caso a Data Real do pagamento seja 10/01/2021, se a Data
                Original do pagamento era 02/01/2021, a Diferença Real Original
                será de 8 dias de atraso.
              </p>
              <h3
                style={{
                  margin: '10px 0',
                }}
              >
                Classificação dos Atrasos:
              </h3>
              <ul>
                <li>1 a 7 dias são considerados normais;</li>
                <li>8 a 14 dias são considerados risco de inadimplência;</li>
                <li>
                  15 a 21 dias é o momento que a maioria das empresas protestam
                  os títulos em cartório;
                </li>
                <li>
                  22 a 28 dias é o momento que a maioria dos cartórios protestam
                  os títulos;
                </li>
                <li>
                  29 a 35 dias é o momento que a pontuação do Serasa e SPC já
                  baixou, os protestos começam a interferir na relação com
                  outros fornecedores, impedem a abertura de novos cadastros e
                  bloqueiam todas as liberações de crédito do mercado, iniciando
                  o processo de falência.;
                </li>
              </ul>
            </div>,
          )
        }
      >
        {value}
      </Typography>
    </Tooltip>
  );
};

export default DataNegociadaField;
