import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import moment from 'moment';

import {
  formatPeso,
  formatCEP,
  formatCNPJ,
  formatCPF,
  formatTelefone,
  formatMoeda,
  formatIE,
  trunc10,
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL,
} from '.';
import { getLabelNivelCaixaria } from './precoVenda';

function totais(produtos, parcelas) {
  let totalValor = 0;
  let totalPeso = 0;
  let totalUnidades = 0;
  let totalValorParcelas = 0;
  for (let i = 0; i < produtos.length; i += 1) {
    totalValor += getTotalEnvoltoria(produtos[i], produtos[i].produto);
    totalPeso +=
      produtos[i].produto.unidade.tipo === POR_UNIDADE ? 0 : produtos[i].peso;

    const totalUnidades2 =
      produtos[i].unidades_caixa_aparente ||
      (produtos[i].unidades_caixa
        ? produtos[i].unidades / produtos[i].unidades_caixa
        : produtos[i].unidades);

    totalUnidades += totalUnidades2;
  }

  for (let i = 0; i < parcelas.length; i += 1) {
    totalValorParcelas += parcelas[i].valor;
  }

  return {
    totalValor,
    totalUnidades,
    totalPeso,
    totalValorParcelas,
  };
}

function organizarParcelas(parcelas) {
  const parcelasFomat = [
    {
      valor: '',
      data: '',
      tipoPagamento: 'Dinheiro',
      troco: '',
    },
    {
      valor: 0,
      data: '',
      tipoPagamento: 'Cartão de Crédito',
      troco: '',
    },
    {
      valor: 0,
      data: '',
      tipoPagamento: 'Cartão de Débito',
      troco: '',
    },
    {
      valor: 0,
      data: '',
      tipoPagamento: 'TED/PIX',
      troco: '',
    },
    {
      valor: '',
      data: '',
      tipoPagamento: 'Outros',
      troco: '',
    },
  ];
  for (let i = 0; i < parcelas.length; i += 1) {
    const parcela = parcelas[i];
    switch (parcela.tipoPagamento.nome) {
      case 'Dinheiro':
        parcelasFomat[0].valor = formatMoeda(parcela.valor_real);
        parcelasFomat[0].troco = formatMoeda(parcela.troco);
        break;
      case 'Cartão de Crédito':
        parcelasFomat[1].valor += parcela.valor;
        break;
      case 'Cartão de Débito':
        parcelasFomat[2].valor += parcela.valor;
        break;
      case 'TED/PIX':
        parcelasFomat[3].valor += parcela.valor;
        break;
      default:
        parcelasFomat[4].valor = formatMoeda(parcela.valor);
        parcelasFomat[4].data = moment(new Date(parcela.data_pagamento)).format(
          'DD/MM/YYYY',
        );
        break;
    }
  }

  if (parcelasFomat[1].valor === 0) parcelasFomat[1].valor = '';
  else parcelasFomat[1].valor = formatMoeda(parcelasFomat[1].valor);

  if (parcelasFomat[2].valor === 0) parcelasFomat[2].valor = '';
  else parcelasFomat[2].valor = formatMoeda(parcelasFomat[2].valor);

  if (parcelasFomat[3].valor === 0) parcelasFomat[3].valor = '';
  else parcelasFomat[3].valor = formatMoeda(parcelasFomat[3].valor);

  return parcelasFomat;
}

function organizarParcelas2(parcelas) {
  const parcelasFormat = [];
  for (let i = 0; i < parcelas.length; i += 1) {
    const parcela = parcelas[i];
    switch (parcela.tipoPagamento.id) {
      case 1:
        parcelasFormat.push({
          valor: formatMoeda(parcela.valor_real),
          data: '-',
          tipoPagamento: 'Dinheiro',
          troco: formatMoeda(parcela.troco),
          tipoPagamentoId: 1,
        });
        break;
      case 3:
        parcelasFormat.push({
          valor: formatMoeda(parcela.valor),
          data: '-',
          tipoPagamento: 'Cartão de Crédito',
          troco: '',
          tipoPagamentoId: 2,
        });
        break;
      case 4:
        parcelasFormat.push({
          valor: formatMoeda(parcela.valor),
          data: '-',
          tipoPagamento: 'Cartão de Débito',
          troco: '',
          tipoPagamentoId: 3,
        });
        break;
      case 5:
        parcelasFormat.push({
          valor: formatMoeda(parcela.valor),
          data: '-',
          tipoPagamento: 'TED/PIX',
          troco: '',
          tipoPagamentoId: 4,
        });
        break;
      case 6:
        parcelasFormat.push({
          valor: formatMoeda(parcela.valor),
          data: moment(new Date(parcela.data_pagamento)).format('DD/MM/YYYY'),
          tipoPagamento: 'Outros',
          troco: '',
          tipoPagamentoId: 5,
        });
        break;
      case 7:
        parcelasFormat.push({
          valor: formatMoeda(parcela.valor),
          data: '-',
          tipoPagamento: 'TED/PIX',
          troco: '',
          tipoPagamentoId: 4,
        });
        break;
      case 8:
        parcelasFormat.push({
          valor: formatMoeda(parcela.valor),
          data: '-',
          tipoPagamento: 'Voucher',
          troco: '',
          tipoPagamentoId: 6,
        });
        break;
      case 9:
        parcelasFormat.push({
          valor: formatMoeda(parcela.valor),
          data: '-',
          tipoPagamento: 'Devolução',
          troco: '',
          tipoPagamentoId: 7,
        });
        break;
      case 10:
        parcelasFormat.push({
          valor: formatMoeda(parcela.valor),
          data: '-',
          tipoPagamento: 'TED/PIX',
          troco: '',
          tipoPagamentoId: 4,
        });
        break;
      default:
        break;
    }
  }
  return parcelasFormat.sort((a, b) => {
    if (a.tipoPagamentoId > b.tipoPagamentoId) {
      return 1;
    }
    if (a.tipoPagamentoId < b.tipoPagamentoId) {
      return -1;
    }
    return 0;
  });
  // return parcelasFormat;
}

// adapatr para as caixas

function getTotal2(peso, unidades, precoUnitario, produto) {
  if (produto) {
    if (produto.unidade.tipo === POR_UNIDADE) {
      if (produto.nivelCaixa) {
        return trunc10(unidades * precoUnitario, -2);
      }
      return trunc10(unidades * precoUnitario, -2);
    }
    if (produto.unidade.tipo === POR_PESO_FIXO) {
      if (produto.nivelCaixa) {
        return trunc10(unidades * precoUnitario, -2);
      }
      return trunc10(peso * precoUnitario, -2);
    }
    if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
      if (produto.nivelCaixa) {
        return trunc10(unidades * precoUnitario, -2);
      }
      return trunc10(peso * precoUnitario, -2);
    }
    return trunc10(peso * precoUnitario, -2);
  }
  return 0;
}

function getTotalEnvoltoria(item, produto) {
  const unidades = item.unidades_caixa_aparente
    ? item.unidades_caixa_aparente
    : item.unidades_caixa
    ? item.unidades / item.unidades_caixa
    : item.unidades;

  const unitario = item.unidades_caixa_aparente
    ? item.preco_venda_caixa
    : item.unidades_caixa
    ? item.preco_venda_caixa
    : item.preco_venda;

  return getTotal2(item.peso, unidades, unitario, {
    ...produto,
    nivelCaixa: item.unidades_caixa_aparente ? true : !!item.unidades_caixa,
  });
}

export function adapterVendaToNota2(vendaOld, config, logo) {
  const venda = {};
  const empresa = {};
  const boletos = [];

  empresa.cnpj = config.cnpj ? formatCNPJ(config.cnpj) : '-';
  empresa.nomeFantasia = config.nome_fantasia ? config.nome_fantasia : '-';
  empresa.razaoSocial = config.razao_social ? config.razao_social : '-';
  empresa.inscricao_estadual = config.inscricao_estadual
    ? formatIE(config.inscricao_estadual)
    : '-';

  empresa.logradouro = config.logradouro ? config.logradouro : '-';
  empresa.n = config.numero ? config.numero : '-';
  empresa.bairro = config.bairro ? config.bairro : '-';
  empresa.cep = config.cep ? formatCEP(config.cep) : '-';
  empresa.fone = config.telefone ? formatTelefone(config.telefone) : '-';
  empresa.cidade = config.cidade ? config.cidade.nome : '-';

  empresa.logo = logo;

  const { totalValor, totalUnidades, totalPeso, totalValorParcelas } = totais(
    vendaOld.itens,
    vendaOld.parcelas,
  );

  venda.numero = vendaOld.numero;
  venda.data = moment(new Date(vendaOld.data)).format('DD/MM/YYYY - HH:mm');
  venda.totalValor = formatMoeda(totalValor);
  venda.totalUnidades = totalUnidades;
  venda.totalPeso = formatPeso(totalPeso);
  venda.totalValorParcelas = formatMoeda(totalValorParcelas);

  venda.cliente = {};
  venda.cliente.razaoSocial = vendaOld.cliente.razao_social
    ? vendaOld.cliente.razao_social
    : '-';
  venda.cliente.nomeFantasia = vendaOld.cliente.nome_fantasia
    ? vendaOld.cliente.nome_fantasia
    : '-';
  venda.cliente.email = vendaOld.cliente.email ? vendaOld.cliente.email : '-';
  venda.cliente.responsavel = vendaOld.cliente.nome
    ? vendaOld.cliente.nome
    : '-';
  venda.cliente.logradouro = vendaOld.cliente.logradouro
    ? vendaOld.cliente.logradouro
    : '-';
  venda.cliente.n = vendaOld.cliente.numero ? vendaOld.cliente.numero : '-';
  venda.cliente.bairro = vendaOld.cliente.bairro
    ? vendaOld.cliente.bairro
    : '-';
  venda.cliente.cep = vendaOld.cliente.cep
    ? formatCEP(vendaOld.cliente.cep)
    : '-';
  venda.cliente.fone = vendaOld.cliente.telefone
    ? formatTelefone(vendaOld.cliente.telefone)
    : '-';
  venda.cliente.cnpj = vendaOld.cliente.cnpj
    ? formatCNPJ(vendaOld.cliente.cnpj)
    : '-';
  venda.cliente.cpf = vendaOld.cliente.cpf
    ? formatCPF(vendaOld.cliente.cpf)
    : '-';

  venda.cliente.cidade = vendaOld.cliente.cidade
    ? vendaOld.cliente.cidade.nome
    : '-';
  venda.cliente.uf = vendaOld.cliente.cidade
    ? vendaOld.cliente.cidade.estado.nome
    : '-';
  venda.cliente.inscricao_estadual = vendaOld.cliente.inscricao_estadual
    ? formatIE(vendaOld.cliente.inscricao_estadual)
    : '-';

  venda.produtos = [];

  for (let i = 0; i < vendaOld.itens.length; i += 1) {
    const {
      unidades: unidadesItem,
      peso: pesoItem,
      preco_venda,
      unidades_caixa,
      preco_venda_caixa,
      peso_caixa,
      unidades_caixa_aparente,
      nivel_caixaria,
      produto: {
        nome: nomeProduto,
        unidade: { nome: nomeUnidade, tipo },
      },
    } = vendaOld.itens[i];

    /// ////////////////////

    const unidades =
      unidades_caixa_aparente ||
      (unidades_caixa ? unidadesItem / unidades_caixa : unidadesItem);
    const unitario = unidades_caixa_aparente
      ? preco_venda_caixa
      : unidades_caixa
      ? preco_venda_caixa
      : preco_venda;

    const sufixoUnidade = unidades_caixa_aparente
      ? peso_caixa > 0
        ? `${getLabelNivelCaixaria(nivel_caixaria)}(${formatPeso(
            peso_caixa,
          )} kg)`
        : `${getLabelNivelCaixaria(nivel_caixaria)}(${unidades_caixa} un)`
      : unidades_caixa
      ? `cx(${unidades_caixa} un)`
      : '';

    /// ///////////////////

    venda.produtos.push({
      descricao: `${nomeProduto}${
        sufixoUnidade !== '' ? ` - ${sufixoUnidade}` : ''
      }`,
      qtde: unidades || 0,
      tipo: nomeUnidade,
      peso: tipo === POR_UNIDADE ? '-' : formatPeso(pesoItem),
      valorUnitario: formatMoeda(unitario),
      total: getTotalEnvoltoria(vendaOld.itens[i], vendaOld.itens[i].produto),
    });
  }

  venda.parcelas = [];

  for (let i = 0; i < vendaOld.parcelas.length; i += 1) {
    const { boleto } = vendaOld.parcelas[i];
    if (boleto) boletos.push(boleto.link);
  }

  // venda.parcelas = organizarParcelas(vendaOld.parcelas);
  venda.parcelas = organizarParcelas2(vendaOld.parcelas);

  return {
    transacao: venda,
    empresa,
    boletos,
  };
}

// total compra ajeitar
function getTotaoLote(lote) {
  const unidadeFinanceira =
    lote.produto.unidade.tipo === POR_UNIDADE
      ? parseInt(lote.unidades, 10)
      : lote.peso;
  const totalValor = trunc10(lote.preco_compra2 * unidadeFinanceira, -2);
  return totalValor;
}

function getTotaoItemCompraLote(lote) {
  const unidadeFinanceira =
    lote.produto.unidade.tipo === POR_UNIDADE
      ? parseInt(lote.unidades, 10)
      : lote.peso;
  const totalValor = trunc10(lote.preco_compra * unidadeFinanceira, -2);
  return totalValor;
}

function totaisLotes(produtos) {
  let totalValor = 0;
  let totalPeso = 0;
  let totalUnidades = 0;
  const totalValorParcelas = 0;
  for (let i = 0; i < produtos.length; i += 1) {
    totalValor += getTotaoLote(produtos[i]);
    totalPeso +=
      produtos[i].produto.unidade.tipo === POR_UNIDADE ? 0 : produtos[i].peso;
    totalUnidades +=
      produtos[i].produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
        ? 0
        : parseInt(produtos[i].unidades, 10);
  }

  return {
    totalValor,
    totalUnidades,
    totalPeso,
    totalValorParcelas,
  };
}

function totaisItensCompraInsumo(produtos) {
  let totalValor = 0;
  let totalPeso = 0;
  let totalUnidades = 0;
  const totalValorParcelas = 0;
  for (let i = 0; i < produtos.length; i += 1) {
    totalValor += getTotaoItemCompraLote(produtos[i]);
    totalPeso +=
      produtos[i].produto.unidade.tipo === POR_UNIDADE ? 0 : produtos[i].peso;
    totalUnidades +=
      produtos[i].produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
        ? 0
        : parseInt(produtos[i].unidades, 10);
  }

  return {
    totalValor,
    totalUnidades,
    totalPeso,
    totalValorParcelas,
  };
}

function makeObjFatura(dia_pagamento, mes, ano) {
  const dataPagamentoFatura = new Date();
  dataPagamentoFatura.setDate(dia_pagamento);
  dataPagamentoFatura.setMonth(mes);
  dataPagamentoFatura.setFullYear(ano);
  const descricaoFatura = `${format(dataPagamentoFatura, 'MMM/yyyy', {
    locale: ptBR,
  })}`;
  return descricaoFatura;
}

function organizarParcelasCompra(parcelas) {
  const parcelasFormat = [];
  for (let i = 0; i < parcelas.length; i += 1) {
    const parcela = parcelas[i];
    if (parcela.despesa) {
      parcelasFormat.push({
        valor: formatMoeda(parcela.valor),
        nome: parcela.despesa.fatura_id
          ? makeObjFatura(
              parcela.despesa.fatura.cartao.dia_pagamento,
              parcela.despesa.fatura.mes,
              parcela.despesa.fatura.ano,
            )
          : '-',
        conta: parcela.despesa.fatura_id
          ? parcela.despesa.fatura.cartao.nome
          : parcela.despesa.conta.nome,
        status: parcela.despesa.status,
        data_pagamento: moment(new Date(parcela.despesa.data_pagamento)).format(
          'DD/MM/YYYY',
        ),
      });
    }
  }
  return parcelasFormat;
  /* return parcelasFormat.sort((a, b) => {
    if (a.tipoPagamentoId > b.tipoPagamentoId) {
      return 1;
    }
    if (a.tipoPagamentoId < b.tipoPagamentoId) {
      return -1;
    }
    return 0;
  }); */
}

function organizarParcelasCompraInsumo(parcelas) {
  const parcelasFormat = [];
  for (let i = 0; i < parcelas.length; i += 1) {
    const parcela = parcelas[i];
    if (parcela.despesa) {
      parcelasFormat.push({
        valor: formatMoeda(parcela.valor),
        nome: parcela.despesa.fatura_id
          ? makeObjFatura(
              parcela.despesa.fatura.cartao.dia_pagamento,
              parcela.despesa.fatura.mes,
              parcela.despesa.fatura.ano,
            )
          : '-',
        conta: parcela.despesa.fatura_id
          ? parcela.despesa.fatura.cartao.nome
          : parcela.despesa.conta.nome,
        status: parcela.despesa.status,
        data_pagamento: moment(new Date(parcela.despesa.data_pagamento)).format(
          'DD/MM/YYYY',
        ),
      });
    }
  }
  return parcelasFormat;
}

function formatPrecoCompra(valor) {
  return valor !== null && valor !== undefined
    ? valor.toLocaleString('de-DE', {
        minimumFractionDigits: 6,
        maximumFractionDigits: 6,
      })
    : '';
}

function formatPrecoCompraInsumo(valor) {
  return valor !== null && valor !== undefined
    ? valor.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : '';
}

export function adapterCompraToNota2(vendaOld, config, logo) {
  const venda = {};
  const empresa = {};
  const boletos = [];

  empresa.cnpj = config.cnpj ? formatCNPJ(config.cnpj) : '-';
  empresa.nomeFantasia = config.nome_fantasia ? config.nome_fantasia : '-';
  empresa.razaoSocial = config.razao_social ? config.razao_social : '-';
  empresa.inscricao_estadual = config.inscricao_estadual
    ? formatIE(config.inscricao_estadual)
    : '-';

  empresa.logradouro = config.logradouro ? config.logradouro : '-';
  empresa.n = config.numero ? config.numero : '-';
  empresa.bairro = config.bairro ? config.bairro : '-';
  empresa.cep = config.cep ? formatCEP(config.cep) : '-';
  empresa.fone = config.telefone ? formatTelefone(config.telefone) : '-';
  empresa.cidade = config.cidade ? config.cidade.nome : '-';

  empresa.logo = logo;

  const {
    totalValor,
    totalUnidades,
    totalPeso,
    totalValorParcelas,
  } = totaisLotes(vendaOld.lotes);

  venda.numero = vendaOld.id;
  venda.data = moment(new Date(vendaOld.data)).format('DD/MM/YYYY - HH:mm');
  venda.totalValor = formatMoeda(totalValor);
  venda.totalUnidades = totalUnidades;
  venda.totalPeso = formatPeso(totalPeso);
  venda.totalValorParcelas = formatMoeda(totalValorParcelas);

  venda.cliente = {};
  venda.cliente.razaoSocial = vendaOld.fornecedor.razao_social
    ? vendaOld.fornecedor.razao_social
    : '-';
  venda.cliente.nomeFantasia = vendaOld.fornecedor.nome_fantasia
    ? vendaOld.fornecedor.nome_fantasia
    : '-';
  venda.cliente.email = vendaOld.fornecedor.email
    ? vendaOld.fornecedor.email
    : '-';
  venda.cliente.responsavel = vendaOld.fornecedor.nome
    ? vendaOld.fornecedor.nome
    : '-';
  venda.cliente.logradouro = vendaOld.fornecedor.logradouro
    ? vendaOld.fornecedor.logradouro
    : '-';
  venda.cliente.n = vendaOld.fornecedor.numero
    ? vendaOld.fornecedor.numero
    : '-';
  venda.cliente.bairro = vendaOld.fornecedor.bairro
    ? vendaOld.fornecedor.bairro
    : '-';
  venda.cliente.cep = vendaOld.fornecedor.cep
    ? formatCEP(vendaOld.fornecedor.cep)
    : '-';
  venda.cliente.fone = vendaOld.fornecedor.telefone
    ? formatTelefone(vendaOld.fornecedor.telefone)
    : '-';
  venda.cliente.cnpj = vendaOld.fornecedor.cnpj
    ? formatCNPJ(vendaOld.fornecedor.cnpj)
    : '-';
  venda.cliente.cpf = vendaOld.fornecedor.cpf
    ? formatCPF(vendaOld.fornecedor.cpf)
    : '-';

  venda.cliente.cidade = vendaOld.fornecedor.cidade
    ? vendaOld.fornecedor.cidade.nome
    : '-';
  venda.cliente.uf = vendaOld.fornecedor.cidade
    ? vendaOld.fornecedor.cidade.estado.nome
    : '-';
  venda.cliente.inscricao_estadual = vendaOld.fornecedor.inscricao_estadual
    ? formatIE(vendaOld.fornecedor.inscricao_estadual)
    : '-';

  venda.produtos = [];

  for (let i = 0; i < vendaOld.lotes.length; i += 1) {
    const {
      unidades: unidadesItem,
      peso: pesoItem,
      preco_compra2,
      cg,
      produto: {
        nome: nomeProduto,
        unidade: { nome: nomeUnidade, tipo },
      },
    } = vendaOld.lotes[i];
    venda.produtos.push({
      descricao: nomeProduto,
      qtde: tipo === POR_PESO_VARIAVEL_SEM_UNIDADE ? '-' : unidadesItem,
      tipo: nomeUnidade,
      peso: tipo === POR_UNIDADE ? '-' : formatPeso(pesoItem),
      valorUnitario: formatPrecoCompra(preco_compra2),
      total: formatMoeda(getTotaoLote(vendaOld.lotes[i])),
      cg: formatPrecoCompra(cg),
    });
  }

  venda.parcelas = organizarParcelasCompra(vendaOld.parcelas);

  return {
    transacao: venda,
    empresa,
    boletos,
  };
}

/// /////////////////////////////////

export function adapterCompraInsumoToNota(vendaOld, config, logo) {
  const venda = {};
  const empresa = {};

  empresa.cnpj = config.cnpj ? formatCNPJ(config.cnpj) : '-';
  empresa.nomeFantasia = config.nome_fantasia ? config.nome_fantasia : '-';
  empresa.razaoSocial = config.razao_social ? config.razao_social : '-';
  empresa.inscricao_estadual = config.inscricao_estadual
    ? formatIE(config.inscricao_estadual)
    : '-';

  empresa.logradouro = config.logradouro ? config.logradouro : '-';
  empresa.n = config.numero ? config.numero : '-';
  empresa.bairro = config.bairro ? config.bairro : '-';
  empresa.cep = config.cep ? formatCEP(config.cep) : '-';
  empresa.fone = config.telefone ? formatTelefone(config.telefone) : '-';
  empresa.cidade = config.cidade ? config.cidade.nome : '-';

  empresa.logo = logo;

  const {
    totalValor,
    totalUnidades,
    totalPeso,
    totalValorParcelas,
  } = totaisItensCompraInsumo(vendaOld.lotes);

  venda.numero = vendaOld.id;
  venda.data = moment(new Date(vendaOld.data)).format('DD/MM/YYYY - HH:mm');
  venda.totalValor = formatMoeda(totalValor);
  venda.totalUnidades = totalUnidades;
  venda.totalPeso = formatPeso(totalPeso);
  venda.totalValorParcelas = formatMoeda(totalValorParcelas);

  venda.cliente = {};
  venda.cliente.razaoSocial = vendaOld.fornecedor.razao_social
    ? vendaOld.fornecedor.razao_social
    : '-';
  venda.cliente.nomeFantasia = vendaOld.fornecedor.nome_fantasia
    ? vendaOld.fornecedor.nome_fantasia
    : '-';
  venda.cliente.email = vendaOld.fornecedor.email
    ? vendaOld.fornecedor.email
    : '-';
  venda.cliente.responsavel = vendaOld.fornecedor.nome
    ? vendaOld.fornecedor.nome
    : '-';
  venda.cliente.logradouro = vendaOld.fornecedor.logradouro
    ? vendaOld.fornecedor.logradouro
    : '-';
  venda.cliente.n = vendaOld.fornecedor.numero
    ? vendaOld.fornecedor.numero
    : '-';
  venda.cliente.bairro = vendaOld.fornecedor.bairro
    ? vendaOld.fornecedor.bairro
    : '-';
  venda.cliente.cep = vendaOld.fornecedor.cep
    ? formatCEP(vendaOld.fornecedor.cep)
    : '-';
  venda.cliente.fone = vendaOld.fornecedor.telefone
    ? formatTelefone(vendaOld.fornecedor.telefone)
    : '-';
  venda.cliente.cnpj = vendaOld.fornecedor.cnpj
    ? formatCNPJ(vendaOld.fornecedor.cnpj)
    : '-';
  venda.cliente.cpf = vendaOld.fornecedor.cpf
    ? formatCPF(vendaOld.fornecedor.cpf)
    : '-';

  venda.cliente.cidade = vendaOld.fornecedor.cidade
    ? vendaOld.fornecedor.cidade.nome
    : '-';
  venda.cliente.uf = vendaOld.fornecedor.cidade
    ? vendaOld.fornecedor.cidade.estado.nome
    : '-';
  venda.cliente.inscricao_estadual = vendaOld.fornecedor.inscricao_estadual
    ? formatIE(vendaOld.fornecedor.inscricao_estadual)
    : '-';

  venda.produtos = [];

  for (let i = 0; i < vendaOld.lotes.length; i += 1) {
    const {
      unidades: unidadesItem,
      peso: pesoItem,
      preco_compra,
      produto: {
        nome: nomeProduto,
        unidade: { nome: nomeUnidade, tipo },
      },
    } = vendaOld.lotes[i];
    venda.produtos.push({
      descricao: nomeProduto,
      qtde: tipo === POR_PESO_VARIAVEL_SEM_UNIDADE ? '-' : unidadesItem,
      tipo: nomeUnidade,
      peso: tipo === POR_UNIDADE ? '-' : formatPeso(pesoItem),
      valorUnitario: formatPrecoCompraInsumo(preco_compra),
      total: formatMoeda(getTotaoItemCompraLote(vendaOld.lotes[i])),
    });
  }

  venda.parcelas = organizarParcelasCompraInsumo(vendaOld.parcelas);

  return {
    transacao: venda,
    empresa,
  };
}
