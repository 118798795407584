import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import StarIcon from '@material-ui/icons/Star';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { createAPI, updateAPI, getListAllAPI, api } from '../../../../services';
import {
  TIPO_FERIADO_DATA_COMEMORATIVA,
  TIPO_FERIADO_FERIADO,
  formatDateDDMMYYYY,
} from '../../../../utils';
import DialogoEditCompensacao from './DialogoEditCompensacao';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const StyledTableCell = withStyles((theme) => ({
  head: {
    //backgroundColor: 'green',
    color: 'black',
    //padding: '8px',
    //whiteSpace: 'nowrap',
    //writingMode: 'sideways-lr',
    //textOrientation: 'sideways',
    //width: 'min-content',
    width: '1px',
    whiteSpace: 'nowrap',
    //textAlign: 'center',
    //display: 'flex',
    //justifyContent: 'center',
    padding: '5px',
    border: '1px solid black',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    padding: '2px',
    border: '1px solid black',
    textAlign: 'center',
    verticalAlign: 'middle',
    //textAlign: 'center',
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
  },
}));

const DialogoCategoriaTransacao = forwardRef(
  ({ updateCompensacoes = () => {}, dados }, ref) => {
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(-1);

    const classes = useStyles({ cor: '#009FD4' });

    const refDialogoEditCompensacao = useRef(null);

    const { enqueueSnackbar } = useSnackbar();

    const handleCloseDialog = () => {
      setOpen(false);
    };

    useImperativeHandle(ref, () => ({
      handleOpen(index) {
        setIndex(index);
        setOpen(true);
      },
    }));

    function handleEditarCompensacao(dados) {
      if (dados.id) {
        if (refDialogoEditCompensacao.current) {
          refDialogoEditCompensacao.current.handleOpen(dados.id);
        }
      }
    }

    const dadosSelecionado =
      index >= 0
        ? dados
          ? dados.length - 1 >= index
            ? dados[index]
            : null
          : null
        : null;

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          disableBackdropClick
          disableEscapeKeyDown
          fullScreen
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            Compensações Feriados
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 10px 0px',
              margin: '0',
            }}
          >
            {dadosSelecionado ? (
              <div>
                <div
                  style={{
                    overflowX: 'scroll',
                    overflowY: 'scroll',
                    height: '500px',
                  }}
                >
                  <Box p="1em">
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              STATUS
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              FERIADO REFERENTE
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <Tooltip title="ENTRADA 1" placement="top-end">
                              <span
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                DATA DA COMPENSAÇÃO
                              </span>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            className={classes.rowHead}
                            align="right"
                          >
                            Ações
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dadosSelecionado.compensacoes
                          .filter(
                            (item) => !item.dados_dia_pagamento_folga_extra_id,
                          )
                          .map((compensacao) => (
                            <TableRow>
                              <StyledTableCell align="center">
                                {compensacao.presenca ? (
                                  <Avatar className={classes.confirmado}>
                                    <CheckIcon style={{ fontSize: 15 }} />
                                  </Avatar>
                                ) : (
                                  <Avatar className={classes.pendente}>
                                    <PriorityHighIcon
                                      style={{ fontSize: 15 }}
                                    />
                                  </Avatar>
                                )}
                              </StyledTableCell>
                              <StyledTableCell>
                                {`${formatDateDDMMYYYY(compensacao.data)} (${
                                  compensacao.dia.nome
                                })`}
                              </StyledTableCell>
                              <StyledTableCell>
                                {formatDateDDMMYYYY(
                                  compensacao.data_folga_extra,
                                )}
                              </StyledTableCell>
                              <StyledTableCell
                                align="right"
                                style={{
                                  display: 'flex',
                                }}
                              >
                                <Tooltip
                                  title="Editar"
                                  placement="bottom"
                                  aria-label="excluir2"
                                >
                                  <IconButton
                                    aria-label="imprimir-checklist"
                                    size="small"
                                    onClick={() =>
                                      handleEditarCompensacao(compensacao)
                                    }
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                              </StyledTableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </Box>
                </div>
              </div>
            ) : (
              <div>carregando...</div>
            )}
          </DialogContent>
          <DialogoEditCompensacao
            ref={refDialogoEditCompensacao}
            updateCompensacao={updateCompensacoes}
          />
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
