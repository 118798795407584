import React, { useRef, useState, useCallback, useEffect } from 'react';
import {
  TextField,
  ReferenceField,
  ShowButton,
  NumberField,
  ListBase,
  ListToolbar,
  Pagination,
  FunctionField,
  useRefresh,
  Title,
  useListContext,
  Datagrid,
  DateField,
  ListContextProvider,
} from 'react-admin';

import { Card, Divider, Tabs, Tab } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { api } from '../../services';
import { formatDateDDMMYYYYHHmm } from '../../utils';
import { STATUS_ATIVO, STATUS_DELETADO } from '../../utils/constants';
import Actions from './ActionsList';
import ActionsField from './fields/ActionsField';
import ClienteField from './fields/ClienteField';
import DataField from './fields/DataField';
import DevolucaoGeradora from './fields/DevolucaoGeradora';
import ValorField from './fields/ValorField';
import VendaGeradora from './fields/VendaGeradora';
import VendaUso from './fields/VendaUso';
import Filter from './Filter';

const PostPanel = (props) => (
  <div>
    <div>{`Descrição: ${props.record.descricao}`}</div>
    <div>{`Data de Criação: ${formatDateDDMMYYYYHHmm(
      props.record.created_at,
    )}`}</div>
    {props.record.criador ? (
      <div>{`Criador: ${props.record.criador.username}`}</div>
    ) : null}
  </div>
);

const tabs = [
  { id: 'ativos', name: 'Confirmados' },
  { id: 'inativos', name: 'Cancelados' },
];

const MyList = ({ ...props }) => {
  const refresh = useRefresh();
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [ativos, setAtivos] = useState([]);
  const [inativos, setInativos] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (ids && ids !== filterValues.status) {
      switch (filterValues.status) {
        case STATUS_ATIVO:
          setAtivos(ids);
          break;
        case STATUS_DELETADO:
          setInativos(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.status]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters(
          {
            ...filterValues,
            status: value === 'ativos' ? STATUS_ATIVO : STATUS_DELETADO,
          },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  async function handleEstenderValidade({ id }) {
    const response = await api.put(`/extender_prazo_credito_devolucao/${id}`);
    const data = response.data;

    if (data.status) {
      enqueueSnackbar('Prazo extendido com sucesso para esse crédito!', {
        variant: 'success',
      });
      refresh();
    } else {
      enqueueSnackbar(data.message, {
        variant: 'error',
      });
    }
  }

  return (
    <>
      <Title title="Créditos de Devolução de Venda" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Divider />
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.status === STATUS_ATIVO ? tabs[0].id : tabs[1].id}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.status === STATUS_ATIVO && (
            <ListContextProvider value={{ ...listContext, ids: ativos }}>
              <Datagrid {...props}>
                <TextField source="id" label="ID" />
                <ClienteField source="produto_id" label="Cliente" />
                <DataField source="data" label="Validade" />
                <ValorField source="valor" label="Valor" />
                <DevolucaoGeradora
                  source="devolucao_venda_id"
                  label="Devolução Que Gerou o Crédito"
                />
                <VendaUso
                  source="venda_uso_id"
                  label="Venda Que Usou o Crédito"
                />
                <ActionsField
                  source="lalala"
                  handleEstenderValidade={handleEstenderValidade}
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.status === STATUS_DELETADO && (
            <ListContextProvider value={{ ...listContext, ids: inativos }}>
              <Datagrid {...props}>
                <TextField source="id" label="ID" />
                <ClienteField source="produto_id" label="Cliente" />
                <DataField source="data" label="Validade" />
                <ValorField source="valor" label="Valor" />
                <DevolucaoGeradora
                  source="devolucao_venda_id"
                  label="Devolução Que Gerou o Crédito"
                />
                <VendaUso
                  source="venda_uso_id"
                  label="Venda Que Usou o Crédito"
                />
                <ActionsField
                  source="lalala"
                  handleEstenderValidade={handleEstenderValidade}
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
        <TransacoesPagination />
      </Card>
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List = (props) => (
  <>
    <ContainerMyList
      {...props}
      expand={<PostPanel />}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Créditos de Devolução de Venda"
      filterDefaultValues={{
        status: STATUS_ATIVO,
        periodo: {
          start: new Date(),
          end: new Date(),
        },
      }}
      component="div"
    />
  </>
);

export default List;
