import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import { useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CloseIcon from '@material-ui/icons/Close';
import ScannerIcon from '@material-ui/icons/Scanner';
import { useSnackbar } from 'notistack';

import { PesoInput, IntegerInput, SearchProduto } from '../../Components';
import { getUserId, createAPI, updateAPI, getListAllAPI } from '../../services';
import {
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  POR_UNIDADE,
  formatPeso,
} from '../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  rootDialogoSmall: {
    borderRadius: '0px',
  },
  btnAtions: {
    // backgroundColor: (props) => props.cor,
    // color: 'white',
    borderRadius: '25px',
    // width: '150px',
  },
  btnAtionsSmall: {
    // backgroundColor: (props) => props.cor,
    // color: 'white',
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    // backgroundColor: 'rgba(0, 0, 0, 0.12)',
    // color: 'red',
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    /* margin: theme.spacing(1),
    position: 'relative', */
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_ID = -1;

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose = () => {} }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [unidades, setUnidades] = useState(0);
    const [caixas, setCaixas] = useState(0);
    const [caixetas, setCaixetas] = useState(0);
    const [caixasMae, setCaixasMae] = useState(0);
    const [peso, setPeso] = useState(0);
    const [id, setId] = useState(DEFAULT_ID);
    const [carregando, setCarregando] = useState(false);
    const [produto, setProduto] = useState(null);

    const classes = useStyles({ cor: 'black' });
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const handleCloseDialog = () => {
      setOpen(false);

      handleClose();
    };

    async function reiniciar(produto) {
      setProduto(produto);
      setId(DEFAULT_ID);
      setUnidades(0);
      setCaixas(0);
      setCaixetas(0);
      setCaixasMae(0);
      setPeso(0);
      setCarregando(false);
    }

    function quebraUnidades(produto, unidadesParam) {
      if (produto) {
        if (
          produto.unidade.tipo === POR_UNIDADE ||
          produto.unidade.tipo === POR_PESO_FIXO
        ) {
          if (produto.unidades_caixa_nivel_3 > 0) {
            const caixaMae = parseInt(
              unidadesParam /
                (produto.unidades_caixa_nivel_3 *
                  produto.unidades_caixa_nivel_2 *
                  produto.unidades_caixa_nivel_1),
              10,
            );
            const resto1 =
              unidadesParam %
              (produto.unidades_caixa_nivel_3 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_1);
            const caixetas = parseInt(
              resto1 /
                (produto.unidades_caixa_nivel_2 *
                  produto.unidades_caixa_nivel_1),
              10,
            );
            const resto2 =
              resto1 %
              (produto.unidades_caixa_nivel_2 * produto.unidades_caixa_nivel_1);
            const caixas = parseInt(
              resto2 / produto.unidades_caixa_nivel_1,
              10,
            );
            const unidadesLocal = parseInt(
              resto2 % produto.unidades_caixa_nivel_1,
              10,
            );
            return {
              cxm: caixaMae,
              cxt: caixetas,
              cx: caixas,
              un: unidadesLocal,
            };
          }

          if (produto.unidades_caixa_nivel_2 > 0) {
            const caixetas = parseInt(
              unidadesParam /
                (produto.unidades_caixa_nivel_2 *
                  produto.unidades_caixa_nivel_1),
              10,
            );
            const resto =
              unidadesParam %
              (produto.unidades_caixa_nivel_2 * produto.unidades_caixa_nivel_1);
            const caixas = parseInt(resto / produto.unidades_caixa_nivel_1, 10);
            const unidadesLocal = parseInt(
              resto % produto.unidades_caixa_nivel_1,
              10,
            );
            return {
              cxm: 0,
              cxt: caixetas,
              cx: caixas,
              un: unidadesLocal,
            };
          }

          if (produto.unidades_caixa_nivel_1 > 0) {
            const caixas = parseInt(
              unidadesParam / produto.unidades_caixa_nivel_1,
              10,
            );
            const unidadesLocal = parseInt(
              unidadesParam % produto.unidades_caixa_nivel_1,
              10,
            );
            return {
              cxm: 0,
              cxt: 0,
              cx: caixas,
              un: unidadesLocal,
            };
          }
          return {
            cxm: 0,
            cxt: 0,
            cx: 0,
            un: unidadesParam,
          };
        }
        return {
          cxm: 0,
          cxt: 0,
          cx: 0,
          un: unidadesParam,
        };
      }
      return {
        cxm: 0,
        cxt: 0,
        cx: 0,
        un: 0,
      };
    }

    async function getDataResource(id) {
      try {
        const data = await getListAllAPI(
          'conferencias_itens_estoque',
          ['id', 'asc'],
          { id: [id] },
          ['produto.unidade'],
        );
        if (data.data.length > 0) {
          const { cx, cxm, cxt, un } = quebraUnidades(
            data.data[0].produto,
            data.data[0].unidades,
          );
          setId(data.data[0].id);
          setProduto(data.data[0].produto);
          setUnidades(un);
          setCaixas(cx);
          setCaixetas(cxt);
          setCaixasMae(cxm);
          setPeso(data.data[0].peso);
          setCarregando(false);
        } else {
          throw 'Esta conferência não existe!';
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    function getUnidadesTotal() {
      if (produto) {
        if (
          produto.unidade.tipo === POR_UNIDADE ||
          produto.unidade.tipo === POR_PESO_FIXO
        ) {
          if (produto.unidades_caixa_nivel_3 > 0) {
            return (
              caixasMae *
                (produto.unidades_caixa_nivel_3 *
                  produto.unidades_caixa_nivel_2 *
                  produto.unidades_caixa_nivel_1) +
              caixetas *
                (produto.unidades_caixa_nivel_2 *
                  produto.unidades_caixa_nivel_1) +
              caixas * produto.unidades_caixa_nivel_1 +
              unidades
            );
          }

          if (produto.unidades_caixa_nivel_2 > 0) {
            return (
              caixetas *
                (produto.unidades_caixa_nivel_2 *
                  produto.unidades_caixa_nivel_1) +
              caixas * produto.unidades_caixa_nivel_1 +
              unidades
            );
          }

          if (produto.unidades_caixa_nivel_1 > 0) {
            return caixas * produto.unidades_caixa_nivel_1 + unidades;
          }

          return unidades;
        }
        if (produto.unidade.tipo === POR_PESO_VARIAVEL) {
          return unidades;
        }
        return 0;
      }
      return 0;
    }

    function getPesoTotal(unidadesTotal) {
      if (produto) {
        if (produto.unidade.tipo === POR_UNIDADE) {
          return 0;
        }
        if (produto.unidade.tipo === POR_PESO_FIXO) {
          return unidadesTotal * produto.peso_medio;
        }
        return peso;
      }
      return 0;
    }

    async function handleSalvar() {
      setCarregando(true);
      try {
        if (id <= 0) {
          const unidadesTotal = getUnidadesTotal();
          await createAPI('conferencias_itens_estoque', {
            produto_id: produto.id,
            unidades: unidadesTotal,
            peso: getPesoTotal(unidadesTotal),
            horario: new Date(),
            user_id: getUserId(),
          });
          setCarregando(false);
          enqueueSnackbar('Conferência registrada com sucesso!', {
            variant: 'success',
          });
        } else {
          const unidadesTotal = getUnidadesTotal();
          await updateAPI('conferencias_itens_estoque', id, {
            unidades: unidadesTotal,
            peso: getPesoTotal(unidadesTotal),
          });
          setCarregando(false);
          enqueueSnackbar('Conferência atualizada com sucesso!', {
            variant: 'success',
          });
        }

        handleCloseDialog();
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    useImperativeHandle(ref, () => ({
      handleCreate(produto, dadosConferencia) {
        if (produto) {
          if (dadosConferencia.id === 0) {
            reiniciar(produto);
            setOpen(true);
          } else if (dadosConferencia.id > 0) {
            setCarregando(true);
            getDataResource(dadosConferencia.id);
            setId(dadosConferencia.id);
            setOpen(true);
          } else {
            enqueueSnackbar(
              `O produto ${produto.nome} já foi conferido por outra pessoa!`,
              {
                variant: 'error',
              },
            );
          }
        }
      },
      handleEdit(id) {
        setCarregando(true);
        getDataResource(id);
        setId(id);
        setOpen(true);
      },
    }));

    function getUnidades(produto) {
      if (produto) {
        if (produto.unidades_caixa_nivel_3 > 0) {
          const caixaMae = parseInt(
            produto.unidadesDisponivel /
              (produto.unidades_caixa_nivel_3 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_1),
            10,
          );
          const resto1 =
            produto.unidadesDisponivel %
            (produto.unidades_caixa_nivel_3 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_1);
          const caixetas = parseInt(
            resto1 /
              (produto.unidades_caixa_nivel_2 * produto.unidades_caixa_nivel_1),
            10,
          );
          const resto2 =
            resto1 %
            (produto.unidades_caixa_nivel_2 * produto.unidades_caixa_nivel_1);
          const caixas = parseInt(resto2 / produto.unidades_caixa_nivel_1, 10);
          const unidades = parseInt(
            resto2 % produto.unidades_caixa_nivel_1,
            10,
          );
          return `${caixaMae}cxm, ${caixetas}cxt, ${caixas}cx e ${unidades} un`;
        }

        if (produto.unidades_caixa_nivel_2 > 0) {
          const caixetas = parseInt(
            produto.unidadesDisponivel /
              (produto.unidades_caixa_nivel_2 * produto.unidades_caixa_nivel_1),
            10,
          );
          const resto =
            produto.unidadesDisponivel %
            (produto.unidades_caixa_nivel_2 * produto.unidades_caixa_nivel_1);
          const caixas = parseInt(resto / produto.unidades_caixa_nivel_1, 10);
          const unidades = parseInt(resto % produto.unidades_caixa_nivel_1, 10);
          return `${caixetas}cxt, ${caixas}cx e ${unidades} un`;
        }

        if (produto.unidades_caixa_nivel_1 > 0) {
          const caixas = parseInt(
            produto.unidadesDisponivel / produto.unidades_caixa_nivel_1,
            10,
          );
          const unidades = parseInt(
            produto.unidadesDisponivel % produto.unidades_caixa_nivel_1,
            10,
          );
          return `${caixas}cx e ${unidades} un`;
        }

        return `${produto.unidadesDisponivel} un`;
      }
      return `0 un`;
    }

    function getNivelCaixa(produto) {
      if (
        produto.unidade.tipo === POR_UNIDADE ||
        produto.unidade.tipo === POR_PESO_FIXO
      ) {
        if (produto.unidades_caixa_nivel_3 > 0) {
          return 3;
        }
        if (produto.unidades_caixa_nivel_2 > 0) {
          return 2;
        }
        if (produto.unidades_caixa_nivel_1 > 0) {
          return 1;
        }
        return 0;
      }
      return 0;
    }

    function getDadosProduto() {
      if (produto) {
        return {
          nome: produto.nome,
          pesoAnterior: formatPeso(produto.pesoDisponivel),
          unidadesAnterior:
            produto.unidade.tipo === POR_UNIDADE ||
            produto.unidade.tipo === POR_PESO_FIXO
              ? getUnidades(produto)
              : `${produto.unidadesDisponivel} un`,
          has: true,
          nivelCaixa: getNivelCaixa(produto),
          hasUnidades: produto.unidade.tipo !== POR_PESO_VARIAVEL_SEM_UNIDADE,
          hasPeso: !(
            produto.unidade.tipo === POR_UNIDADE ||
            produto.unidade.tipo === POR_PESO_FIXO
          ),
          unidadeProduto: produto.unidade,
        };
      }
      return {
        nome: '',
        pesoAnterior: 0,
        unidadesAnterior: 0,
        has: false,
        nivelCaixa: 0,
        hasUnidades: false,
        hasPeso: false,
        unidadeProduto: null,
      };
    }

    const {
      nome,
      pesoAnterior,
      unidadesAnterior,
      has,
      nivelCaixa,
      hasUnidades,
      hasPeso,
      unidadeProduto,
    } = getDadosProduto();

    function validateUnidades() {
      let error = '';
      if (hasUnidades) {
        if (unidades <= 0 || Number.isNaN(unidades)) {
          error = 'Deve ter um valor maior que 0';
        }
      }
      return error;
    }

    function validateCaixas() {
      let error = '';
      if (hasUnidades && nivelCaixa >= 1) {
        if (caixas <= 0 || Number.isNaN(caixas)) {
          error = 'Deve ter um valor maior que 0';
        }
      }
      return error;
    }

    function validateCaixetas() {
      let error = '';
      if (hasUnidades && nivelCaixa >= 2) {
        if (caixetas <= 0 || Number.isNaN(caixetas)) {
          error = 'Deve ter um valor maior que 0';
        }
      }
      return error;
    }

    function validateCaixasMae() {
      let error = '';
      if (hasUnidades && nivelCaixa >= 3) {
        if (caixasMae <= 0 || Number.isNaN(caixasMae)) {
          error = 'Deve ter um valor maior que 0';
        }
      }
      return error;
    }

    function validatePeso() {
      let error = '';
      if (hasPeso) {
        if (peso <= 0 || Number.isNaN(peso)) {
          error = 'Deve ter um valor maior que 0';
        }
      }
      return error;
    }

    function getErros() {
      const errosOld = [''];
      errosOld[0] = validateUnidades();
      errosOld[1] = validateCaixas();
      errosOld[2] = validateCaixetas();
      errosOld[3] = validateCaixasMae();
      errosOld[4] = validatePeso();
      return errosOld;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: isSmall ? classes.rootDialogoSmall : classes.rootDialogo,
          }}
          className={isSmall ? classes.rootDialogoSmall : classes.rootDialogo}
          fullWidth={!isSmall}
          fullScreen={isSmall}
          maxWidth={isSmall ? '' : 'sm'}
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: '#ff1744', color: 'white' }}
          >
            {id < 0 ? `Nova Conferência` : `Editar Conferência`}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '20px 15px 20px',
              margin: '0',
            }}
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div>
                <div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: '5px' }}>Produto:</div>
                    <div>{nome}</div>
                  </div>
                  {hasUnidades && (
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: '5px' }}>Unidades Atual:</div>
                      <div>{unidadesAnterior}</div>
                    </div>
                  )}
                  {hasPeso && (
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: '5px' }}>Peso Atual:</div>
                      <div>{pesoAnterior}</div>
                    </div>
                  )}
                </div>
                {hasUnidades && (
                  <IntegerInput
                    handleEnter={() => {}}
                    label="Unidades"
                    handleKey={() => {}}
                    value={Number.isNaN(unidades) ? '' : String(unidades)}
                    onChange={(value) =>
                      setUnidades(parseInt(value.target.value, 10))
                    }
                    fullWidth
                    error={erros[0] !== ''}
                    helperText={erros[0]}
                  />
                )}
                {nivelCaixa >= 1 && (
                  <IntegerInput
                    handleEnter={() => {}}
                    label="Caixas"
                    handleKey={() => {}}
                    value={Number.isNaN(caixas) ? '' : String(caixas)}
                    onChange={(value) =>
                      setCaixas(parseInt(value.target.value, 10))
                    }
                    fullWidth
                    error={erros[1] !== ''}
                    helperText={erros[1]}
                  />
                )}
                {nivelCaixa >= 2 && (
                  <IntegerInput
                    handleEnter={() => {}}
                    label="Caixetas"
                    handleKey={() => {}}
                    value={Number.isNaN(caixetas) ? '' : String(caixetas)}
                    onChange={(value) =>
                      setCaixetas(parseInt(value.target.value, 10))
                    }
                    fullWidth
                    error={erros[2] !== ''}
                    helperText={erros[2]}
                  />
                )}
                {nivelCaixa >= 3 && (
                  <IntegerInput
                    handleEnter={() => {}}
                    label="Caixas-Mãe"
                    handleKey={() => {}}
                    value={Number.isNaN(caixasMae) ? '' : String(caixasMae)}
                    onChange={(value) =>
                      setCaixasMae(parseInt(value.target.value, 10))
                    }
                    fullWidth
                    error={erros[3] !== ''}
                    helperText={erros[3]}
                  />
                )}
                {hasPeso && (
                  <PesoInput
                    handleEnter={() => {}}
                    label="Peso"
                    handleKey={() => {}}
                    fullWidth
                    value={Number.isNaN(peso) ? '' : String(peso)}
                    onChange={(value) =>
                      setPeso(parseFloat(value.target.value))
                    }
                    error={erros[4] !== ''}
                    helperText={erros[4]}
                    // disabled={disabledPeso()}
                  />
                )}
              </div>
            )}
          </DialogContent>
          <DialogActions
            style={{
              padding: '0px 15px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                }}
                // style={{ marginLeft: '30px' }}
                disabled={erroExistente || carregando}
                variant="contained"
                color="primary"
                fullWidth
              >
                Adicionar
              </Button>
              {carregando && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
