import { PAGO, PARCIALMENTE_PAGO, NAO_PAGO } from '../../utils';

export const choicesPagamento = [
  { id: NAO_PAGO, name: 'Não Pago' },
  { id: PARCIALMENTE_PAGO, name: 'Parcialmente Pago' },
  { id: PAGO, name: 'Pago' },
];

const COMPLETO = 'COMPLETO';
const INCONPLETO = 'INCOMPLETO';

export const choicesStatusEntrega = [
  { id: COMPLETO, name: 'Completamente Entrega' },
  { id: INCONPLETO, name: 'Pendente' },
];
