import React, { forwardRef } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const SelectInput = forwardRef(
  ({ handleEnter, handleKey, options, variant = 'outlined', ...rest }, ref) => (
    <Select
      margin="normal"
      {...rest}
      inputRef={ref}
      variant={variant}
      onKeyDown={(e) => {
        if (e.keyCode === 13) handleEnter();
        else handleKey(e.keyCode, e.key);
      }}
    >
      {options.map((element) => (
        <MenuItem value={element.value}>{element.label}</MenuItem>
      ))}
    </Select>
  ),
);

export default SelectInput;
