import React from 'react';
import { ListButton, RefreshButton, Show as ShowAdmin } from 'react-admin';

import CardActions from '@material-ui/core/CardActions';

import ContainerActionsBack from '../ContainerActionsBack';
import EditButton from '../EditButton';

const Actions = ({ basePath, data }) => (
  <CardActions>
    <ListButton basePath={basePath} />
    <RefreshButton />
    <EditButton basePath={basePath} record={data} />
  </CardActions>
);

const Show = ({
  children,
  title,
  actions = <Actions />,
  isBack = false,
  routeBack = '',
  ...props
}) => (
  <ShowAdmin
    {...props}
    actions={
      <ContainerActionsBack
        actions={actions}
        isBack={isBack}
        routeBack={routeBack}
      />
    }
    title={title}
  >
    {children}
  </ShowAdmin>
);

export default Show;
