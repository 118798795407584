import {
  TIPO_PRECIFICACAO_NORMAL,
  TIPO_PRECIFICACAO_COMPRA,
  TIPO_PRECIFICACAO_LIBERACAO_PRECO,
  TIPO_PRECIFICACAO_OFERTA,
  TIPO_PRECIFICACAO_ZERAR_ESTOQUE,
} from '../../utils';

const choices = [
  { id: TIPO_PRECIFICACAO_NORMAL, name: 'Edição Normal' },
  { id: TIPO_PRECIFICACAO_COMPRA, name: 'Compra' },
  { id: TIPO_PRECIFICACAO_LIBERACAO_PRECO, name: 'Liberação de Preço' },
  { id: TIPO_PRECIFICACAO_OFERTA, name: 'Oferta Normal' },
  {
    id: TIPO_PRECIFICACAO_ZERAR_ESTOQUE,
    name: 'Oferta de Zerar Estoque',
  },
];

export default choices;
