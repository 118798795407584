import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  ListBase,
  ListToolbar,
  Pagination,
  useRefresh,
  Title,
  useListContext,
  Datagrid,
  ListContextProvider,
} from 'react-admin';

import { Card, Tabs, Tab, Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { DialogoConfirmacao } from '../../Components';
import { api } from '../../services';
import { STATUS_ATIVO, STATUS_INATIVO } from '../../utils/constants';
import Actions from './ActionsList';
import Dialogo from './DialogoCreateEdit';
import ActionsField from './Fields/ActionsField';
import DescricaoField from './Fields/DescricaoField';
import Filter from './Filter';

const PostPanel = (props) => (
  <div>
    {props.record.colaboradores.length > 0 ? (
      <div
        style={{
          flex: 1,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" gutterBottom>
            Colaboradores
          </Typography>
        </div>
        <Box mt="1em" />
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.record.colaboradores.map((row) => (
                <TableRow>
                  <TableCell component="th" scope="row">
                    {row.nome}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    ) : null}
  </div>
);

const tabs = [
  { id: 'ativos', name: 'Ativos' },
  { id: 'inativos', name: 'Inativos' },
];

const MyList = ({ ...props }) => {
  const refDialogoCompra = useRef(null);
  const refresh = useRefresh();
  const listContext = useListContext();
  const refDialogoConfirmacao = useRef(null);
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [ativos, setAtivos] = useState([]);
  const [inativos, setInativos] = useState([]);

  function handleCriar() {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleCreate();
    }
  }
  function handleEdit({ id }) {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleEdit(id);
    }
  }

  const handleClose = () => {
    refresh();
  };

  function handleAtivar({ id }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Reativação de Departamento Colaborador',
        'Deseja realmente reativar esta Departamento Colaborador?',
        { id, codigo: 1 },
      );
    }
  }

  function handleDesativar({ id }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Desativação de Departamento Colaborador',
        'Deseja realmente desativar esta Departamento Colaborador?',
        { id, codigo: 2 },
      );
    }
  }

  function handleDeletar({ id }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Exclusão de Departamento Colaborador',
        'Deseja deletar permanentemente esta Departamento Colaborador?',
        { id, codigo: 3 },
      );
    }
  }

  async function ativar(id) {
    await api.put(`/departamentos_colaborador/status/ativar/${id}`);
    handleClose();
  }

  async function desativar(id) {
    await api.put(`/departamentos_colaborador/status/inativar/${id}`);
    handleClose();
  }

  async function deletar(id) {
    await api.put(`/departamentos_colaborador/status/deletar/${id}`);
    handleClose();
  }

  async function handleConfirma({ id, codigo }) {
    switch (codigo) {
      case 1:
        ativar(id);
        break;
      case 2:
        desativar(id);
        break;
      case 3:
        deletar(id);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (ids && ids !== filterValues.status) {
      switch (filterValues.status) {
        case STATUS_ATIVO:
          setAtivos(ids);
          break;
        case STATUS_INATIVO:
          setInativos(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.status]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters(
          {
            ...filterValues,
            status: value === 'ativos' ? STATUS_ATIVO : STATUS_INATIVO,
          },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  return (
    <>
      <Title title="Departamentos Colaborador" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions handleCriar={handleCriar} {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.status === STATUS_ATIVO ? tabs[0].id : tabs[1].id}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.status === STATUS_ATIVO && (
            <ListContextProvider value={{ ...listContext, ids: ativos }}>
              <Datagrid {...props}>
                <DescricaoField source="id" label="ID" />
                <DescricaoField source="nome" label="Título" />
                <ActionsField
                  source="valor"
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  handleEdit={handleEdit}
                  handleAtivar={handleAtivar}
                  handleDesativar={handleDesativar}
                  handleDeletar={handleDeletar}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.status === STATUS_INATIVO && (
            <ListContextProvider value={{ ...listContext, ids: inativos }}>
              <Datagrid {...props}>
                <DescricaoField source="id" label="ID" />
                <DescricaoField source="nome" label="Título" />
                <ActionsField
                  source="valor"
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  handleEdit={handleEdit}
                  handleAtivar={handleAtivar}
                  handleDesativar={handleDesativar}
                  handleDeletar={handleDeletar}
                />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>

        <TransacoesPagination />
      </Card>
      <Dialogo ref={refDialogoCompra} handleClose={handleClose} />
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={handleConfirma}
      />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      expand={<PostPanel />}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Departamentos Colaborador"
      component="div"
      filterDefaultValues={{
        status: STATUS_ATIVO,
      }}
    />
  </>
);

export default List2;
