import * as React from 'react';
import { useRecordContext } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import PrintIcon from '@material-ui/icons/Print';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useSnackbar } from 'notistack';

import { imprimirNotaPreVenda } from '../../services/impressora';

const ActionsField = ({ handleChangeStatus, ...rest }) => {
  const record = useRecordContext(rest);
  const { enqueueSnackbar } = useSnackbar();

  const statusValue = record ? record.record.is_utilizado : false;

  async function imprimir(id) {
    if (id > 0) {
      const codigo = await imprimirNotaPreVenda(id);
      if (codigo < 0) {
        enqueueSnackbar('Erro ao imprimir a nota!', {
          variant: 'error',
        });
      }
    }
  }

  return (
    <div
      style={{
        marginRight: '0px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {/* <Tooltip title="Editar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleEdit(record.record)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip> */}
      <Tooltip title="Imprimir" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="imprimir"
          size="small"
          onClick={() => imprimir(record ? record.record.id : -1)}
        >
          <PrintIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Efetivar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleChangeStatus(record.record)}
        >
          {!statusValue ? <CheckCircleOutlineIcon /> : <CancelIcon />}
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ActionsField;
