import React, {
  useReducer,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { ThemeProvider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useSnackbar } from 'notistack';

import imagem from '../../../assets/background.jpg';
import { getListAPI, getListAllAPI } from '../../../services';
import { STATUS_ATIVO, round10 } from '../../../utils';
import theme from '../../DialogoEditarVenda/themes/default';
import { reducerPedido, PedidoContext, GeralPage, ItensPage } from './Pages';
import {
  tipoStatusAndamentoCriarPedido,
  tipoStatusAndamentoAprovar,
  tipoStatusAndamentoAprovarVolta,
  tipoStatusAndamentoDetalhes,
} from './utils/constants';

const TELA_GERAL_PAGE = 0;
const TELA_ITENS_PAGE = 1;

function SwitchElements({ elementos, value }) {
  const selecionado = elementos[value];
  return <selecionado.elemento {...selecionado.props} />;
}

const DialogoCreateCompra = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const [tela, setTela] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [carregando, setCarregando] = useState(false);

  const dataMinima = new Date();
  const dataMaxima = new Date();
  dataMaxima.setTime(dataMinima.getTime() + 45 * 60 * 1000);

  const [pedido, dispatch] = useReducer(reducerPedido, {
    cliente: null,
    itens: [],
    isMargemCartao: false,
    idEdit: -1,
    carregando: true,
    dataMinima,
    dataMaxima,
    tipoStatusAndamento: tipoStatusAndamentoCriarPedido,
  });

  function resetar() {
    dispatch({ type: 'RESETAR_TUDO' });
  }

  async function reiniciar() {
    const dataMinima = new Date();
    const dataMaxima = new Date();
    dataMaxima.setTime(dataMinima.getTime() + 45 * 60 * 1000);
    dispatch({
      type: 'PREENCHER_TUDO',
      cliente: null,
      itens: [],
      isMargemCartao: false,
      idEdit: -1,
      carregando: false,
      dataMinima,
      dataMaxima,
      tipoStatusAndamento: tipoStatusAndamentoCriarPedido,
    });

    setCarregando(false);
  }

  const handleCloseDialog = () => {
    const dataMinima = new Date();
    const dataMaxima = new Date();
    dataMaxima.setTime(dataMinima.getTime() + 45 * 60 * 1000);
    setOpen(false);
    resetar();
    setTela(TELA_GERAL_PAGE);
    handleClose();
    dispatch({
      type: 'PREENCHER_TUDO',
      cliente: null,
      itens: [],
      isMargemCartao: false,
      idEdit: -1,
      carregando: true,
      dataMinima,
      dataMaxima,
      tipoStatusAndamento: tipoStatusAndamentoCriarPedido,
    });
  };

  function cancelarPedido() {
    handleCloseDialog();
  }

  function irParaTelaGeral() {
    setTela(TELA_GERAL_PAGE);
  }

  function irParaTelaItens() {
    setTela(TELA_ITENS_PAGE);
  }

  function getTipoStatusAndamento(
    is_analisado,
    is_analisado_volta,
    tipo,
    status,
    data_maxima,
  ) {
    //if (status !== STATUS_ATIVO) throw 'Este pedido já foi finalizado!';
    //if (new Date(data_maxima).getTime() < new Date().getTime()) throw 'Este pedido já foi vencido!';
    if (status !== STATUS_ATIVO) return tipoStatusAndamentoDetalhes;
    if (new Date(data_maxima).getTime() < new Date().getTime())
      return tipoStatusAndamentoDetalhes;
    if (!is_analisado) {
      if (tipo === 0) return tipoStatusAndamentoDetalhes;
      return tipoStatusAndamentoAprovar;
    }
    if (!is_analisado_volta) {
      return tipoStatusAndamentoAprovarVolta;
    }
    //throw 'Este pedido já foi usado trabalhado!';
    return tipoStatusAndamentoDetalhes;
  }

  function formatItensInit(rows, tipoStatusAndamento) {
    const itens = [];
    for (let i = 0; i < rows.length; i += 1) {
      itens.push({
        produto: rows[i].produto,
        uidd: `${rows[i].produto.nome}${rows.length}`,
        idIndicadorChange: rows[i].id,
        dadosPrecificacao: rows[i].dadosPrecificacao,
        user_id: rows[i].created_by,
        valor: rows[i].valor,
        tipo_unidade: rows[i].tipo_unidade,
        verba_unitaria: rows[i].verba_unitaria,
        verba_unitaria_solicitado:
          tipoStatusAndamento === tipoStatusAndamentoAprovar
            ? rows[i].verba_unitaria
            : rows[i].verba_unitaria_solicitado,
        quantidade: rows[i].quantidade,
        is_analisado: rows[i].is_analisado,
        is_analisado_volta: rows[i].is_analisado_volta,
        /*  status_aprovado: false,
        status_aprovado_volta: rows[i].status
          ? rows[i].status === STATUS_ATIVO
            ? true
            : false
          : null, */
        status_aprovado: rows[i].is_analisado ? true : false,
        //status_aprovado_volta: rows[i].is_analisado_volta ? true : null,
        status_aprovado_volta: rows[i].is_analisado_volta
          ? true
          : tipoStatusAndamento === tipoStatusAndamentoAprovarVolta
          ? round10(rows[i].verba_unitaria, -2) ===
            round10(rows[i].verba_unitaria_solicitado, -2)
            ? true
            : null
          : null,
        isModificado: false,
        verba_unitaria_old: rows[i].verba_unitaria,
        verba_unitaria_solicitado_old: rows[i].verba_unitaria_solicitado,
        is_fim_negociacao: rows[i].is_fim_negociacao
          ? true
          : tipoStatusAndamento === tipoStatusAndamentoAprovarVolta &&
            rows[i].verba_unitaria_solicitado === 0,
        tipo_unidade_quantidade: rows[i].tipo_unidade_quantidade,
        tipo_unidade_preco_sugerido: rows[i].tipo_unidade_preco_sugerido,
      });
    }
    return itens;
  }

  async function getDataPedido(idPedido, tipo) {
    try {
      setTela(TELA_GERAL_PAGE);
      const data = await getListAllAPI(
        'solicitacoes_liberacao_precos_edit',
        ['id', 'asc'],
        { id: idPedido },
        [
          'itens.produto.unidade',
          'itens.dadosPrecificacao',
          'cliente.cidade.estado',
        ],
      );

      if (data.data.length <= 0) {
        throw 'Este pedido de liberação não existe!';
      }

      const tipoStatusAndamento = getTipoStatusAndamento(
        data.data[0].is_analisado,
        data.data[0].is_analisado_volta,
        tipo,
        data.data[0].status,
        data.data[0].data_maxima,
      );

      dispatch({
        type: 'PREENCHER_TUDO',
        idEdit: data.data[0].id,
        itens: formatItensInit(data.data[0].itens, tipoStatusAndamento),
        cliente: data.data[0].cliente,
        isMargemCartao: data.data[0].is_margem_cartao,
        carregando: false,
        dataMinima: new Date(data.data[0].data_minima),
        dataMaxima: new Date(data.data[0].data_maxima),
        tipoStatusAndamento,
      });

      setCarregando(false);
    } catch (erros) {
      //handleCloseDialog();
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });

      setCarregando(false);
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      setCarregando(true);
      reiniciar();
      setTela(TELA_GERAL_PAGE);
      setOpen(true);
    },
    handleEdit(idPedido, tipo = 0) {
      setCarregando(true);
      getDataPedido(idPedido, tipo);
      setOpen(true);
    },
  }));

  function getDadosTamTela() {
    switch (tela) {
      case TELA_GERAL_PAGE:
        return {
          fullScreen: true,
        };
      case TELA_ITENS_PAGE:
        return {
          fullScreen: true,
        };
      default:
        return {
          fullScreen: true,
        };
    }
  }

  const dadosTela = getDadosTamTela();

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Dialog open={open} aria-labelledby="form-dialog-title" {...dadosTela}>
          <DialogContent
            style={{
              padding: '0',
              margin: '0',
              backgroundColor: '#ede8e8',
              backgroundImage: `url(${imagem})`,
              backgroundSize: 'cover',
            }}
            dividers
          >
            <PedidoContext.Provider
              value={{ pedido, dispatch, openMenu: () => {} }}
            >
              {!carregando ? (
                <SwitchElements
                  value={tela}
                  elementos={{
                    [TELA_GERAL_PAGE]: {
                      elemento: GeralPage,
                      props: {
                        voltar: cancelarPedido,
                        prosseguir: irParaTelaItens,
                      },
                    },
                    [TELA_ITENS_PAGE]: {
                      elemento: ItensPage,
                      props: {
                        voltar: irParaTelaGeral,
                        prosseguir: cancelarPedido,
                      },
                    },
                  }}
                />
              ) : (
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'red',
                    fontSize: '70px',
                    fontWeight: 'bold',
                  }}
                >
                  Carregando...
                </div>
              )}
            </PedidoContext.Provider>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </div>
  );
});

export default DialogoCreateCompra;
