import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function TableTotal({ rows, title, field }) {
  const total = rows.reduce(
    (accumulator, currentValue) => accumulator + currentValue[field],
    0,
  );
  return (
    <Table aria-label="collapsible table" size="small">
      <TableHead>
        <TableRow>
          <TableCell
            align="left"
            style={{
              backgroundColor: '#009FD4',
              color: 'white',
              fontWeight: 'bold',
            }}
          >
            {title}
          </TableCell>
          <TableCell
            align="left"
            style={{
              backgroundColor: '#009FD4',
              color: 'white',
              fontWeight: 'bold',
            }}
          >
            Total
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((item) => (
          <TableRow key={item.id}>
            <TableCell align="left">{item.nome}</TableCell>
            <TableCell align="left">{item[field]}</TableCell>
          </TableRow>
        ))}
        <TableRow key={`${title} - total`}>
          <TableCell
            align="left"
            style={{
              //color: '#009FD4',
              color: 'black',
              fontSize: '17px',
              fontWeight: 'bold',
            }}
          >
            Total
          </TableCell>
          <TableCell
            align="left"
            style={{
              //color: '#009FD4',
              color: 'black',
              fontSize: '17px',
              fontWeight: 'bold',
            }}
          >
            {total}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

const DialogoCategoriaTransacao = forwardRef(
  ({ equipes, grupos, funcoes, data, handleClose = () => {} }, ref) => {
    const [open, setOpen] = useState(false);

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    useImperativeHandle(ref, () => ({
      handleOpen() {
        setOpen(true);
      },
    }));

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          fullScreen
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            {`Resumo Turno ${data}`}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            <div
              style={{
                margin: '10px 0 10px',
                borderRadius: '20px',
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                alignItems: 'center',
              }}
            >
              <h1
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '10px',
                }}
              >
                {`Resumo Turno ${data}`}
              </h1>
              <div
                style={{
                  margin: '10px 0 10px',
                  borderRadius: '20px',
                  padding: '10px',
                  display: 'flex',
                  gap: '20px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    borderRadius: '20px',
                    border: '2px solid #009FD4',
                    padding: '10px',
                  }}
                >
                  <h2
                    style={{
                      margin: '20px 0px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    Manhã
                  </h2>
                  <div>
                    <TableTotal
                      rows={funcoes}
                      title="Função"
                      field="total_manha"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    borderRadius: '20px',
                    border: '2px solid #009FD4',
                    padding: '10px',
                  }}
                >
                  <h2
                    style={{
                      margin: '20px 0px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    Noite
                  </h2>
                  <div>
                    <TableTotal
                      rows={funcoes}
                      title="Função"
                      field="total_tarde"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    borderRadius: '20px',
                    border: '2px solid #009FD4',
                    padding: '10px',
                  }}
                >
                  <h2
                    style={{
                      margin: '20px 0px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    12 h
                  </h2>
                  <div>
                    <TableTotal
                      rows={funcoes}
                      title="Função"
                      field="total_12"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    borderRadius: '20px',
                    border: '2px solid #009FD4',
                    padding: '10px',
                  }}
                >
                  <h2
                    style={{
                      margin: '20px 0px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    Todos os Turnos
                  </h2>
                  <div>
                    <TableTotal rows={funcoes} title="Função" field="total" />
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
