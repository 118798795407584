import React, { useRef } from 'react';
import {
  TextField,
  ListBase,
  ListToolbar,
  Pagination,
  useRefresh,
} from 'react-admin';

import { makeStyles, Card } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

import Datagrid from '../../Components/Datagrid';
import EditButton from '../../Components/EditButton2';
import Actions from './ActionsList';
import DialogoCreate from './DialogoCreate';
import Filter from './Filter';

const useStyles = makeStyles((theme) => ({
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
  },
  cartao: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#00796b',
    marginLeft: '10px',
  },
}));

const StatusField = ({ status }) => {
  const classes = useStyles();
  return (
    <div style={{ display: 'flex' }}>
      {status ? (
        <Avatar className={classes.confirmado}>
          <CheckIcon style={{ fontSize: 15 }} />
        </Avatar>
      ) : (
        <Avatar className={classes.pendente}>
          <PriorityHighIcon style={{ fontSize: 15 }} />
        </Avatar>
      )}
    </div>
  );
};

const Permissao = ({ status, nome }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      width: '20%',
      marginBottom: '10px',
    }}
  >
    <StatusField status={status} />
    <div style={{ marginLeft: '10px' }}>{nome}</div>
  </div>
);

const PermissaoAba = ({ nome, permissoes }) => (
  <div>
    <div style={{ margin: '20px 0px', fontSize: '20px', fontWeight: 'bold' }}>
      {nome}
    </div>
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {permissoes.map((item) => (
        <Permissao status={item.status} nome={item.nome} />
      ))}
    </div>
  </div>
);

function sortPermissoes(record) {
  const abas = [];
  abas.push({
    nome: 'Pessoal',
    permissoes: [
      {
        status: record.pessoal_turnos,
        nome: 'Escalas',
      },
    ],
  });
  abas.push({
    nome: 'Comprador',
    permissoes: [
      {
        status: record.comprador_configuracoes_produtos,
        nome: 'Config Produtos',
      },
      {
        status: record.sistema_jornal_de_ofertas,
        nome: 'Jornal de Ofertas',
      },
      {
        status: record.mais_opcoes_banco_fornecedores,
        nome: 'Bancos Fornecedores',
      },
      {
        status: record.mais_opcoes_funcao_funcionario_fornecedor,
        nome: 'Funções Funcionários Fornecedores',
      },
      {
        status: record.sistema_cotacoes_templates,
        nome: 'Cotações Templates',
      },
      {
        status: record.sistema_oferta_zerar_estoque,
        nome: 'Oferta de Zerar Estoque',
      },
    ],
  });
  abas.push({
    nome: 'Vendas',
    permissoes: [
      {
        status: record.gerencial_vendas,
        nome: 'Vendas',
      },
      {
        status: record.gerencial_devolucoes,
        nome: 'Devoluções',
      },
      {
        status: record.gerencial_pedidos,
        nome: 'Pedidos',
      },
      {
        status: record.sistema_ratoeira,
        nome: 'Ratoeira',
      },
      {
        status: record.sistema_credito_devolucao,
        nome: 'Créditos Devoluções',
      },
      {
        status: record.sistema_pedidos_liberacoes,
        nome: 'Pedidos de Liberações de Preços',
      },
      {
        status: record.sistema_aprovacoes_pedidos_liberacoes,
        nome: 'Aprovações de Pedidos de Liberações de Preços',
      },
    ],
  });
  abas.push({
    nome: 'Fornecimento',
    permissoes: [
      {
        status: record.gerencial_compras,
        nome: 'Compras',
      },
      {
        status: record.sistema_nfe_compra,
        nome: 'NFEs Cientes',
      },
      {
        status: record.sistema_nfe_compra_problematicas,
        nome: 'NFEs Problemáticas',
      },
      {
        status: record.gerencial_fornecedores,
        nome: 'Fornecedores Mercadorias',
      },

      {
        status: record.fornecimento_fornecedores_insumos,
        nome: 'Fornecedores Insumos',
      },
      {
        status: record.fornecimento_fornecedores_servicos,
        nome: 'Fornecedores Serviços',
      },
      {
        status: record.fornecimento_fornecedores_qualquer,
        nome: 'Outros Contatos',
      },
    ],
  });
  abas.push({
    nome: 'Itens de Venda',
    permissoes: [
      {
        status: record.produtos_produtos,
        nome: 'Produtos',
      },
      {
        status: record.produtos_familias,
        nome: 'Famílias',
      },

      /*{
        status: record.produtos_ofertas,
        nome: 'Ofertas de Jornal',
      },*/
      /*{
        status: record.produtos_precos_liberados,
        nome: 'Preços Liberados',
      },*/
      {
        status: record.sistema_liberacao_de_precos,
        nome: 'Preços Liberados',
      },

      {
        status: record.produtos_categorias,
        nome: 'Categorias',
      },
      {
        status: record.produtos_locais_setores,
        nome: 'Locais',
      },
      {
        status: record.produtos_taras,
        nome: 'Taras Balança',
      },
      {
        status: record.produtos_baixar,
        nome: 'Baixar',
      },
      {
        status: record.produtos_impostos,
        nome: 'Impostos',
      },
      {
        status: record.produtos_tabela_lucro,
        nome: 'Tabela Lucro',
      },
      {
        status: record.conferencias_sugestao_produtos,
        nome: 'Sugestão Produtos',
      },
      {
        status: record.gerencial_setores,
        nome: 'Setores',
      },

      {
        status: record.sistema_conferencia_caixaria,
        nome: 'Conferência Caixaria',
      },
      {
        status: record.sistema_conferencia_grandes_pesagens,
        nome: 'Conferências Grandes Pesagens',
      },
      {
        status: record.sistema_historico_precificacao,
        nome: 'Histórico Precificações',
      },
      {
        status: record.sistema_verbas_produtos,
        nome: 'Verbas de Produtos',
      },
    ],
  });
  abas.push({
    nome: 'Financeiro',
    permissoes: [
      {
        status: record.financeiro_dashboard,
        nome: 'Dashboard',
      },
      {
        status: record.financeiro_sessoes,
        nome: 'Sessões',
      },
      {
        status: record.financeiro_nfes,
        nome: 'Verificação Nfes',
      },
      {
        status: record.financeiro_contas,
        nome: 'Contas',
      },
      {
        status: record.sistema_edit_conta,
        nome: 'Editar Contas',
      },
      {
        status: record.sistema_caixas_fc,
        nome: 'Caixas',
      },
      {
        status: record.financeiro_cartoes_credito,
        nome: 'Cartões de Crédito',
      },
      {
        status: record.financeiro_transacoes,
        nome: 'Transações',
      },
      {
        status: record.financeiro_pix,
        nome: 'Pix',
      },
      {
        status: record.financeiro_futurologia,
        nome: 'Futurologia',
      },
      {
        status: record.financeiro_tela_tranferencias,
        nome: 'Transferências',
      },
      {
        status: record.financeiro_tela_reajustes,
        nome: 'Reajustes',
      },
      {
        status: record.mais_opcoes_categorias_transacoes,
        nome: 'Categorias Transações',
      },
      {
        status: record.financeiro_transacoes_simuladas,
        nome: 'Transações Simuladas Futurologia',
      },
      {
        status: record.financeiro_vendas_faturadas,
        nome: 'Vendas Faturadas',
      },
      {
        status: record.sistema_contas_tabela,
        nome: 'Contas Tabela',
      },
      {
        status: record.sistema_transacoes_conferencias,
        nome: 'Transações Conferências',
      },
      {
        status: record.sistema_despesas_agendamentos,
        nome: 'Agendamentos',
      },
      {
        status: record.sistema_conferencia_maquineta,
        nome: 'Conferência Maquineta',
      },
      {
        status: record.sistema_transacoes_especiais,
        nome: 'Impostos',
      },
    ],
  });
  abas.push({
    nome: 'Malotes',
    permissoes: [
      {
        status: record.malote_malotes,
        nome: 'Malotes',
      },
      {
        status: record.malote_submalotes,
        nome: 'Submalotes',
      },
      {
        status: record.malote_sangrias,
        nome: 'Sangrias',
      },
      {
        status: record.malote_trocos,
        nome: 'Trocos',
      },
      {
        status: record.malote_sobras,
        nome: 'Sobras',
      },
      {
        status: record.malote_sinistros,
        nome: 'Sinistros',
      },
      {
        status: record.malote_conta_gerencial,
        nome: 'Conta Gerencial',
      },
      {
        status: record.sistema_lixeira,
        nome: 'Lixeira',
      },
      {
        status: record.sistema_tela_transportadores,
        nome: 'Transportadores',
      },
    ],
  });
  abas.push({
    nome: 'Televendas',
    permissoes: [
      {
        status: record.gerencial_clientes,
        nome: 'Clientes',
      },
      {
        status: record.mais_opcoes_categorias_clientes,
        nome: 'Categorias Clientes',
      },
    ],
  });
  abas.push({
    nome: 'Processos',
    permissoes: [
      {
        status: record.sistema_atividades_processos,
        nome: 'Atividades',
      },
    ],
  });
  abas.push({
    nome: 'Relatório',
    permissoes: [
      {
        status: record.relatorios_mensal,
        nome: 'Mensal',
      },
      {
        status: record.relatorios_anual,
        nome: 'Anual',
      },
      {
        status: record.relatorios_produtos,
        nome: 'Produtos',
      },
      {
        status: record.relatorios_clientes,
        nome: 'Clientes',
      },
      {
        status: record.relatorios_indicadores_kpi,
        nome: 'Indicadores KPI',
      },
      {
        status: record.produtos_inventario,
        nome: 'Inventário',
      },
      {
        status: record.relatorios_produtos_setores,
        nome: 'Produtos de um local',
      },
      {
        status: record.relatorios_cadastramento_clientes,
        nome: 'Relatório Cadastro Clientes',
      },
      {
        status: record.relatorios_produtos_colaborador,
        nome: 'Relatório Produtos Colaborador',
      },
      {
        status: record.relatorio_colaborador_fardamento,
        nome: 'Relatório Fardamentos Colaborador',
      },
      {
        status: record.relatorios_relatorio_cartoes,
        nome: 'Relatório Cartões',
      },
      {
        status: record.relatorios_relatorio_fluxo_caixa,
        nome: 'Relatório Fluxo de Caixa',
      },
      {
        status: record.relatorios_relatorio_scores,
        nome: 'Relatório Scores',
      },
      {
        status: record.sistema_vendas_nao_finalizadas,
        nome: 'Vendas Não Finalizadas',
      },
      {
        status: record.sistema_itens_vendas_nao_finalizadas,
        nome: 'Itens Venda Não Finalizadas',
      },
    ],
  });
  abas.push({
    nome: 'Global',
    permissoes: [
      {
        status: record.relatorios_mensal,
        nome: 'Relatório Mensal',
      },
      {
        status: record.relatorios_anual,
        nome: 'Relatório Anual',
      },
      {
        status: record.relatorios_produtos,
        nome: 'Relatório Produtos',
      },
      {
        status: record.relatorios_clientes,
        nome: 'Relatório Clientes',
      },
      {
        status: record.relatorios_indicadores_kpi,
        nome: 'Indicadores KPI',
      },
      {
        status: record.produtos_inventario,
        nome: 'Inventário',
      },
      {
        status: record.relatorios_produtos_setores,
        nome: 'Produtos de um local',
      },
      {
        status: record.relatorios_cadastramento_clientes,
        nome: 'Relatório Cadastro Clientes',
      },
      {
        status: record.relatorios_produtos_colaborador,
        nome: 'Relatório Produtos Colaborador',
      },
      {
        status: record.relatorio_colaborador_fardamento,
        nome: 'Relatório Fardamentos Colaborador',
      },
    ],
  });
  abas.push({
    nome: 'Sistema',
    permissoes: [
      {
        status: record.mais_opcoes_meios_pagamentos,
        nome: 'Meios de Pagamento',
      },
      {
        status: record.mais_opcoes_unidades,
        nome: 'Unidades',
      },
      {
        status: record.mais_opcoes_cargos,
        nome: 'Cargos',
      },
      {
        status: record.gerencial_adms,
        nome: 'Usuários',
      },
      {
        status: record.config,
        nome: 'Configurações',
      },
      {
        status: record.sistema_pergunta,
        nome: 'Perguntas',
      },
      {
        status: record.sistema_categoria_pergunta,
        nome: 'Categorias Perguntas',
      },
      {
        status: record.sistema_categoria_frente_caixa,
        nome: 'Categorias Caixas',
      },
      {
        status: record.sistema_categoria_frente_caixa,
        nome: 'Categorias Caixas',
      },
      {
        status: record.sistema_videos_tutoriais,
        nome: 'Vídeos Tutoriais FC',
      },
      {
        status: record.sistema_estados_uf,
        nome: 'Estados Brasileiros',
      },
      {
        status: record.sistema_empresas,
        nome: 'Lojas',
      },
      {
        status: record.sistema_tabela_ibtp,
        nome: 'Tabela IBTP',
      },
    ],
  });
  abas.push({
    nome: 'Contabilidade',
    permissoes: [
      {
        status: record.contabilidade_colaboradores,
        nome: 'Colaboradores',
      },
      {
        status: record.contabilidade_funcoes_colaboradores,
        nome: 'Funções Colaboradores',
      },
      {
        status: record.contabilidade_checagem_fardamento,
        nome: 'Checagem Fardamentos',
      },
      {
        status: record.contabilidade_fardamentos,
        nome: 'Fardamentos',
      },
      {
        status: record.contabilidade_tamanho_fardamento,
        nome: 'Tamanhos Fardamentos',
      },
      {
        status: record.contabilidade_qualidades,
        nome: 'Qualidades',
      },
      {
        status: record.contabilidade_defeitos,
        nome: 'Defeitos',
      },
      {
        status: record.contabilidade_feriados,
        nome: 'Feriados',
      },
      {
        status: record.contabilidade_desconto_funcionario,
        nome: 'Descontos',
      },
      {
        status: record.contabilidade_categoria_desconto_funcionario,
        nome: 'Categorias Descontos',
      },

      {
        status: record.sistema_grupo_colaborador,
        nome: 'Grupos Colaborador',
      },
      {
        status: record.sistema_equipe_colaborador,
        nome: 'Equipes Colaborador',
      },
      {
        status: record.sistema_codinomes_colaborador,
        nome: 'Codinomes',
      },
      {
        status: record.sistema_departamento_colaborador,
        nome: 'Departamentos',
      },
      /* {
        status: record.sistema_pontos_colaborador,
        nome: 'Presença',
      },
      {
        status: record.sistema_compensacao_feriados,
        nome: 'Compensação de Feriados',
      },*/
      {
        status: record.sistema_horas_extras,
        nome: 'Horas Extras',
      },
      /*{
        status: record.sistema_folha_pagamentos,
        nome: 'Folha de Pagamentos',
      },*/
    ],
  });
  abas.push({
    nome: 'Development',
    permissoes: [
      {
        status: record.development_features,
        nome: 'Features',
      },
      {
        status: record.development_softwares,
        nome: 'Softwares',
      },
    ],
  });
  abas.push({
    nome: 'Conferências',
    permissoes: [
      {
        status: record.conferencias_impressoes,
        nome: 'Freezers',
      },
    ],
  });
  abas.push({
    nome: 'Equipamentos',
    permissoes: [
      {
        status: record.equipamentos_freezers,
        nome: 'Freezers',
      },
    ],
  });
  abas.push({
    nome: 'Coletor',
    permissoes: [
      {
        status: record.coletor_solicitacao_impressao,
        nome: 'Solicitação Impressão Etiquetas',
      },
      {
        status: record.coletor_conferencia_temperatura_freezer,
        nome: 'Conferência Temperatura Freezer',
      },
    ],
  });
  abas.push({
    nome: 'Desenvolvedor',
    permissoes: [
      {
        status: record.sistema_logs_tasks,
        nome: 'Logs Relatórios',
      },
    ],
  });
  return abas;
}

const PostPanel = ({ record }) => {
  const abas = sortPermissoes(record);
  return (
    <div>
      {abas.map((aba) => (
        <PermissaoAba nome={aba.nome} permissoes={aba.permissoes} />
      ))}
    </div>
  );
};

const MyList = ({ ...props }) => {
  const refDialogoCreate = useRef(null);
  const refresh = useRefresh();

  function handleCriar() {
    if (refDialogoCreate.current) {
      refDialogoCreate.current.handleCreate();
    }
  }

  function handleEdit({ id }) {
    if (refDialogoCreate.current) {
      refDialogoCreate.current.handleEdit(id);
    }
  }

  return (
    <>
      <ListToolbar
        filters={props.filters}
        actions={<Actions handleCriar={handleCriar} {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid {...props}>
          <TextField source="nome" label="Descrição" />
          <EditButton editHundle={handleEdit} />
        </Datagrid>
        <TransacoesPagination />
      </Card>
      <DialogoCreate
        ref={refDialogoCreate}
        handleClose={() => {
          refresh();
        }}
      />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      expand={<PostPanel />}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Cargos"
    />
  </>
);

export default List2;
