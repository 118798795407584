import React from 'react';
import { TopToolbar, sanitizeListRestProps } from 'react-admin';

import { Box, makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';

import DateMobillsInput from './Inputs/DateMobillsInput2';

const useStyles = makeStyles({
  root: {
    flex: 1,
    alignItems: 'center',
  },
});

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  enabledCreate,
  handleCriar = () => {},
  ...rest
}) => {
  const classes = useStyles();
  return (
    <TopToolbar {...sanitizeListRestProps(rest)} className={classes.root}>
      <Box
        display="flex"
        justifyContent="space-between"
        flex={1}
        alignItems="center"
      >
        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>Contas</div>
        {/*<div>
          <DateMobillsInput />
  </div>*/}
        <Box display="flex">
          <Tooltip title="Nova Conta" placement="top" aria-label="add">
            <Box
              boxShadow={2}
              borderRadius="50px"
              display="flex"
              alignItems="center"
              width="44px"
              style={{
                backgroundColor: 'white',
                height: '48px',
                width: '48px',
              }}
            >
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                onClick={() => handleCriar()}
                style={{ height: '48px', width: '48px' }}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Tooltip>
        </Box>
      </Box>
    </TopToolbar>
  );
};

export default ListActions;
