import { useRef, useState, useEffect } from 'react';

import { useSnackbar } from 'notistack';

import {
  getListAllAPI,
  api,
  imprimirRetiradaMercadoria,
} from '../../../services';
import {
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  getPesoCaixa,
  changeCaixariaLocal,
  getUnidadesItemCaixa,
  getUnidadesCaixa,
  POR_PESO_FIXO,
  ZERO_APROXIMADO,
} from '../../../utils';

function useItens(itens, updateItem, vendaId, cliente, irParaTelaInit) {
  const { enqueueSnackbar } = useSnackbar();
  const refSidebarInputs = useRef(null);
  const [produto, setProduto] = useState(null);
  const [search, setSearch] = useState('');
  const refSearch = useRef(null);
  const [podeFecharVenda, setPodeFecharVenda] = useState(true);

  function addNewItemInterno(dados, produto) {
    const { unidades, peso } = dados;

    const pesoCalculado = getPesoCaixa(produto);

    updateItem({
      produto,
      peso:
        produto.unidade.tipo === POR_UNIDADE
          ? 0
          : pesoCalculado === 0
          ? peso
          : pesoCalculado * unidades,
      unidades: getUnidadesItemCaixa(produto, unidades),
      uidd: `${produto.nome}${itens.length}`,
      idIndicadorChange: -1,
      unidadesCaixa: getUnidadesCaixa(produto),
      pesoCaixa: pesoCalculado,
      unidadesCaixaAparente: produto.nivelCaixa ? unidades : 0,
      nivelCaixaria: produto.nivelCaixa ? produto.nivelCaixa : 0,
    });

    setProduto(null);
  }

  function selectProduto(produto) {
    const hideUnidades = produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE;
    if (refSidebarInputs.current)
      refSidebarInputs.current.focus(hideUnidades ? 1 : 0);
    setProduto(produto);
  }

  function addNewItem(dados) {
    addNewItemInterno(dados, produto);
  }

  function getMessagesError() {
    const errors = [];

    if (cliente === null) {
      errors.push({
        message: 'Campo cliente não foi preenchido!',
        type: 'warning',
      });
    }

    if (itens.length <= 0) {
      errors.push({
        message: 'É necessário ao menos um registro de itens!',
        type: 'warning',
      });
    }

    return errors;
  }

  function montarObjVenda(responsavel_id, conferente_id) {
    const listaItens = [];

    for (let i = 0; i < itens.length; i += 1) {
      listaItens.push({
        peso: itens[i].peso,
        unidades: itens[i].unidades,
        produto_id: itens[i].produto.id,
        idIndicadorChange: itens[i].idIndicadorChange,
        unidades_caixa: itens[i].unidadesCaixa,
        peso_caixa: itens[i].pesoCaixa,
        unidades_caixa_aparente: itens[i].unidadesCaixaAparente,
        nivel_caixaria: itens[i].nivelCaixaria,
      });
    }

    return {
      listaItens,
      data: new Date(),
      cliente_id: cliente.id,
      venda_id: vendaId,
      responsavel_id,
      conferente_id,
    };
  }

  async function submitVenda(responsavel_id, conferente_id) {
    try {
      const objVenda = montarObjVenda(responsavel_id, conferente_id);
      const data = await api.post(`/vendas_parciais`, {
        ...objVenda,
      });
      return data.data.id;
    } catch (e) {
      return -1;
    }
  }

  async function handleFinalizarVenda(responsavel_id, conferente_id) {
    const errors = getMessagesError();
    if (errors.length <= 0 && podeFecharVenda) {
      setPodeFecharVenda(false);
      const idVenda = await submitVenda(responsavel_id, conferente_id);
      if (idVenda > 0) {
        const codigo = await imprimirRetiradaMercadoria(idVenda);
        if (codigo < 0) {
          enqueueSnackbar('Erro ao imprimir a nota!', {
            variant: 'error',
          });
        }
        irParaTelaInit();
      } else {
        setPodeFecharVenda(true);
        enqueueSnackbar('Erro ao registrar a venda!', {
          variant: 'error',
        });
      }
    } else {
      for (let i = 0; i < errors.length; i += 1) {
        enqueueSnackbar(errors[i].message, {
          variant: errors[i].type,
        });
      }
    }
  }

  function changeCaixaria() {
    changeCaixariaLocal(produto, setProduto, enqueueSnackbar);
  }

  useEffect(() => {
    async function focusCerto() {
      if (produto) {
        const hasUnidade =
          produto.unidade.tipo === POR_UNIDADE ||
          produto.unidade.tipo === POR_PESO_FIXO ||
          produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE;
        if (hasUnidade) {
          switch (produto.nivelCaixaAntigo) {
            case 1:
              if (produto.unidades_caixa_nivel_2 > 0) {
                if (refSidebarInputs.current) refSidebarInputs.current.focus(0);
              } else if (
                produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
              ) {
                if (refSidebarInputs.current) refSidebarInputs.current.focus(1);
              } else if (refSidebarInputs.current)
                refSidebarInputs.current.focus(0);
              break;
            case 2:
              if (produto.unidades_caixa_nivel_3 > 0) {
                if (refSidebarInputs.current) refSidebarInputs.current.focus(0);
              } else if (
                produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
              ) {
                if (refSidebarInputs.current) refSidebarInputs.current.focus(1);
              } else if (refSidebarInputs.current)
                refSidebarInputs.current.focus(0);
              break;
            case 3:
              if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
                if (refSidebarInputs.current) refSidebarInputs.current.focus(1);
              } else if (refSidebarInputs.current)
                refSidebarInputs.current.focus(0);
              break;
            case -1:
              if (
                produto.unidades_caixa_nivel_1 > 0 ||
                produto.peso_caixa_nivel_1 > ZERO_APROXIMADO
              ) {
                if (refSidebarInputs.current) refSidebarInputs.current.focus(0);
              } else if (
                produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
              ) {
                if (refSidebarInputs.current) refSidebarInputs.current.focus(1);
              } else if (refSidebarInputs.current)
                refSidebarInputs.current.focus(0);
              break;
          }
        }
      }
    }
    focusCerto();
  }, [produto]);

  return {
    addNewItem,
    setSearch,
    produto,
    refSearch,
    search,
    refSidebarInputs,
    enqueueSnackbar,
    setProduto,
    selectProduto,
    handleFinalizarVenda,
    changeCaixaria,
  };
}

export default useItens;
