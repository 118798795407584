import React from 'react';
import {
  TextField,
  ReferenceField,
  NumberField,
  FunctionField,
  SimpleShowLayout,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  Tab,
  Button,
  Link,
  ReferenceManyField,
  ListButton,
  RefreshButton,
  DateField,
  Pagination,
} from 'react-admin';

import { useMediaQuery, Box } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import { withStyles } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';

// import Datagrid from '../../Components/Datagrid';
import Datagrid from '../../Components/Datagrid/index2';
import EditButton from '../../Components/EditButton';
import FabButtonNewResource from '../../Components/FabButtonNewResource';
import ShowTemplate from '../../Components/Show';
import { formatMoeda, round10 } from '../../utils';

const styles = {
  button: {
    marginTop: '1em',
  },
};

const AddNewCorrecaoSaldoContaeButton = withStyles(styles)(
  ({ classes, record }) => (
    <Button
      component={Link}
      className={classes.button}
      to={{
        pathname: '/correcoes_conta/create',
        search: `?conta_id=${record.id}`,
      }}
      label="Nova Correção do saldo da conta"
    >
      <AddBoxIcon />
    </Button>
  ),
);

const Actions = ({ basePath, data }) => (
  <CardActions>
    <ListButton basePath={basePath} />
    <RefreshButton />
    <EditButton basePath={basePath} record={data} />
  </CardActions>
);

const Title = ({ record }) => (
  <span>Conta: {record ? `"${record.nome}"` : ''}</span>
);

const Show = (props) => (
  <ShowTemplate
    {...props}
    title={<Title />}
    actions={<Actions />}
    isBack
    routeBack="/contas"
  >
    <TabbedShowLayout
      tabs={<TabbedShowLayoutTabs variant="fullWidth" centered {...props} />}
    >
      <Tab label="Geral">
        <TextField source="nome" label="Descrição" />
        <NumberField
          sortable={false}
          source="valor"
          locales="pt-BR"
          options={{
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'BRL',
          }}
          label="Valor Total"
        />
      </Tab>
      <Tab label="Correções">
        <ReferenceManyField
          addLabel={false}
          reference="correcoes_conta"
          target="conta_id"
          pagination={<Pagination />}
        >
          <Datagrid isDelete>
            <FunctionField
              label="Valor"
              render={(record) => `${formatMoeda(record.valor)}`}
            />
            <DateField source="data" label="Data" />
          </Datagrid>
        </ReferenceManyField>
        <FabButtonNewResource
          pathname="/correcoes_conta/create"
          search={(record) => `?conta_id=${record.id}`}
        />
      </Tab>
    </TabbedShowLayout>
  </ShowTemplate>
);

export default Show;
