import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
    cursor: 'pointer',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
    cursor: 'pointer',
  },
}));

const ActionsField = ({
  handleEdit,
  handleMarcarComoVerificada = () => {},
  handleDesmarcarComoVerificada = () => {},
  ...rest
}) => {
  const record = useRecordContext(rest);
  const classes = useStyles();

  const status = record
    ? record.record
      ? record.record.is_caixaria_verificada
      : false
    : false;

  return (
    <div
      style={{
        marginRight: '0px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Tooltip title="Editar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleEdit(record.record)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>

      {!status ? (
        <Tooltip
          title="Marcar como caixaria verificada"
          placement="bottom"
          aria-label="marcar-como-caixaria-verificada"
        >
          <Avatar
            className={classes.confirmado}
            onClick={() => handleMarcarComoVerificada(record.record)}
          >
            <SearchIcon style={{ fontSize: 15 }} />
          </Avatar>
        </Tooltip>
      ) : (
        <Tooltip
          title="Desmarcar como caixaria verificada"
          placement="bottom"
          aria-label="desmarcar-como-caixaria-verificada"
        >
          <Avatar
            className={classes.pendente}
            onClick={() => handleDesmarcarComoVerificada(record.record)}
          >
            <SearchIcon style={{ fontSize: 15 }} />
          </Avatar>
        </Tooltip>
      )}
    </div>
  );
};

export default ActionsField;
