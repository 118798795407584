import * as React from 'react';
import { useRecordContext } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ListIcon from '@material-ui/icons/List';

const ActionsField = ({ handleTransacoesEspeciais = () => {}, ...rest }) => {
  const record = useRecordContext(rest);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Tooltip title="Lista dos Impostos" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleTransacoesEspeciais(record.record)}
        >
          <ListIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};



export default ActionsField;
