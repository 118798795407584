import React, { forwardRef } from 'react';

import InputTemplate from '../TemplateNumberFormatInput';

const NumeroNotaFiscalInput = forwardRef((props, ref) => (
  <InputTemplate
    {...props}
    formato="#### #### #### #### #### #### #### #### #### #### ####"
    ref={ref}
  />
));

export default NumeroNotaFiscalInput;
