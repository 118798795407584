import React, { forwardRef, useState, useImperativeHandle } from 'react';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import {
  ceil10,
  formatMoeda,
  PRIMARY_COLOR,
  getTotalCompra,
} from '../../../../../../utils';

function formatPorcentagem(valor) {
  return valor !== null && valor !== undefined
    ? `${valor.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} %`
    : '';
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 12,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  table: {
    //minWidth: 650,
  },
  paper: {
    height: 300,
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflow: 'auto',
  },
}));

const Grafico = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [dados, setDados] = useState(null);

  const classes = useStyles({ cor: '#009FD4' });

  useImperativeHandle(ref, () => ({
    handleOpen(dados) {
      setOpen(true);
      setDados(dados);
    },
  }));

  const handleCloseDialog = () => {
    setOpen(false);
  };

  function calculaTotais() {
    if (dados) {
      if (dados.dadosOriginais) {
        if (dados.dadosOriginais.dadosPrecificacao) {
          return {
            subtotal_valor_negociado_com_a_industria: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao
                .valor_negociado_com_a_industria,
              dados.dadosOriginais.produto,
            ),
            subtotal_custo_final: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao.custo_final_do_produto,
              dados.dadosOriginais.produto,
            ),
            subtotal_custo_operacional: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao.custo_operacional,
              dados.dadosOriginais.produto,
            ),
            subtotal_custo_cartao: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao.custo_de_cartao,
              dados.dadosOriginais.produto,
            ),
            subtotal_total_venda: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao.preco_de_venda,
              dados.dadosOriginais.produto,
            ),
            subtotal_icms_compra: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao.icms_compra,
              dados.dadosOriginais.produto,
            ),
            subtotal_icms_venda: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao.icms_venda,
              dados.dadosOriginais.produto,
            ),
            subtotal_pis_compra: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao.pis_compra,
              dados.dadosOriginais.produto,
            ),
            subtotal_pis_venda: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao.pis_venda,
              dados.dadosOriginais.produto,
            ),
            subtotal_cofins_compra: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao.cofins_compra,
              dados.dadosOriginais.produto,
            ),
            subtotal_cofins_venda: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao.cofins_venda,
              dados.dadosOriginais.produto,
            ),
            subtotal_lucro_bruto: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao.lucro_bruto,
              dados.dadosOriginais.produto,
            ),
            subtotal_lucro_liquido: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao.lucro_liquido,
              dados.dadosOriginais.produto,
            ),
            subtotal_icms_antecipacao: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao.icms_antecipacao,
              dados.dadosOriginais.produto,
            ),
            subtotal_icms_fronteira: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao.icms_fronteira,
              dados.dadosOriginais.produto,
            ),
            subtotal_irpj: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao.irpj,
              dados.dadosOriginais.produto,
            ),
            subtotal_csll: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao.csll,
              dados.dadosOriginais.produto,
            ),
            subtotal_repasse_ipi: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao.repasse_do_ipi,
              dados.dadosOriginais.produto,
            ),
            subtotal_repasse_st: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao.repasse_de_st,
              dados.dadosOriginais.produto,
            ),
            subtotal_repasse_seguro: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao.repasse_do_seguro,
              dados.dadosOriginais.produto,
            ),
            subtotal_repasse_frete: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao.repasse_do_frete,
              dados.dadosOriginais.produto,
            ),
            subtotal_repasse_outras_despesas: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao.repasse_de_outras_despesas,
              dados.dadosOriginais.produto,
            ),
            //
            subtotal_desconto_nfe: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao.desconto_em_nfe,
              dados.dadosOriginais.produto,
            ),
            subtotal_desconto_boleto: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao.desconto_em_boleto,
              dados.dadosOriginais.produto,
            ),
            subtotal_desconto_bonificacao: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao.desconto_em_bonificacao,
              dados.dadosOriginais.produto,
            ),
            subtotal_desconto_backlight: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao
                .desconto_em_backlight_e_ponto_extra,
              dados.dadosOriginais.produto,
            ),
            subtotal_desconto_jornal_ofertas: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao
                .desconto_em_jornal_de_ofertas,
              dados.dadosOriginais.produto,
            ),
            subtotal_desconto_descargo_mercadorias: getTotalCompra(
              dados.dadosOriginais.peso,
              dados.dadosOriginais.unidades,
              dados.dadosOriginais.dadosPrecificacao
                .desconto_em_descargo_de_mercadorias,
              dados.dadosOriginais.produto,
            ),
          };
        }
      }
    }
    return {
      subtotal_custo_operacional: 0,
      subtotal_custo_cartao: 0,
      subtotal_total_venda: 0,
      subtotal_icms_compra: 0,
      subtotal_icms_venda: 0,
      subtotal_pis_compra: 0,
      subtotal_pis_venda: 0,
      subtotal_cofins_compra: 0,
      subtotal_cofins_venda: 0,
      subtotal_lucro_bruto: 0,
      subtotal_lucro_liquido: 0,
      subtotal_icms_antecipacao: 0,
      subtotal_icms_fronteira: 0,
      subtotal_irpj: 0,
      subtotal_csll: 0,
      subtotal_repasse_ipi: 0,
      subtotal_repasse_st: 0,
      subtotal_repasse_seguro: 0,
      subtotal_repasse_frete: 0,
      subtotal_repasse_outras_despesas: 0,
      subtotal_desconto_nfe: 0,
      subtotal_desconto_boleto: 0,
      subtotal_desconto_bonificacao: 0,
      subtotal_desconto_backlight: 0,
      subtotal_desconto_jornal_ofertas: 0,
      subtotal_desconto_descargo_mercadorias: 0,
    };
  }

  const {
    subtotal_custo_operacional,
    subtotal_custo_cartao,
    subtotal_total_venda,
    subtotal_icms_compra,
    subtotal_icms_venda,
    subtotal_pis_compra,
    subtotal_pis_venda,
    subtotal_cofins_compra,
    subtotal_cofins_venda,
    subtotal_lucro_bruto,
    subtotal_lucro_liquido,
    subtotal_icms_antecipacao,
    subtotal_icms_fronteira,
    subtotal_irpj,
    subtotal_csll,
    subtotal_repasse_ipi,
    subtotal_repasse_st,
    subtotal_repasse_seguro,
    subtotal_repasse_frete,
    subtotal_repasse_outras_despesas,
    subtotal_custo_final,
    subtotal_valor_negociado_com_a_industria,
    subtotal_desconto_backlight,
    subtotal_desconto_boleto,
    subtotal_desconto_bonificacao,
    subtotal_desconto_descargo_mercadorias,
    subtotal_desconto_jornal_ofertas,
    subtotal_desconto_nfe,
  } = calculaTotais();

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      classes={{ paper: classes.rootDialogo }}
      className={classes.rootDialogo}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        id="form-dialog-title"
        onClose={handleCloseDialog}
        style={{ backgroundColor: PRIMARY_COLOR, color: 'white' }}
      >
        Detalhes do Lote
      </DialogTitle>
      <DialogContent>
        {dados ? (
          <>
            {dados.dadosPrecificacao ? (
              <Box>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    margin: '5px 0 15px',
                    textAlign: 'center',
                    color: 'red',
                  }}
                >
                  Geral
                </Typography>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left">Imposto</StyledTableCell>
                        <StyledTableCell align="left">Unitário</StyledTableCell>
                        <StyledTableCell align="left">
                          Percentual
                        </StyledTableCell>
                        <StyledTableCell align="left">Subtotal</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow key="1">
                        <StyledTableCell align="left">
                          Valor Negociado
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(
                            dados.dadosPrecificacao
                              .valor_negociado_com_a_industria,
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="left">-</StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(
                            subtotal_valor_negociado_com_a_industria,
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow key="2">
                        <StyledTableCell align="left">
                          Custo Final
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(
                            dados.dadosPrecificacao.custo_final_do_produto,
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="left">-</StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(subtotal_custo_final)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow key="3">
                        <StyledTableCell align="left">
                          Preço de Venda
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(dados.dadosPrecificacao.preco_de_venda)}
                        </StyledTableCell>
                        <StyledTableCell align="left">-</StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(subtotal_total_venda)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow key="4">
                        <StyledTableCell align="left">
                          Lucro Bruto
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(dados.dadosPrecificacao.lucro_bruto)}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatPorcentagem(
                            dados.dadosPrecificacao.taxa_lucro_bruto,
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(subtotal_lucro_bruto)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow key="7">
                        <StyledTableCell align="left">
                          Lucro Líquido
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(dados.dadosPrecificacao.lucro_liquido)}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatPorcentagem(
                            dados.dadosPrecificacao.taxa_lucro_liquido,
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(subtotal_lucro_liquido)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow key="5">
                        <StyledTableCell align="left">
                          Custo Operacional
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(
                            dados.dadosPrecificacao.custo_operacional,
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatPorcentagem(
                            (dados.dadosPrecificacao.custo_operacional * 100) /
                              dados.dadosPrecificacao.preco_de_venda,
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(subtotal_custo_operacional)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow key="6">
                        <StyledTableCell align="left">
                          Custo com Cartão
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(dados.dadosPrecificacao.custo_de_cartao)}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatPorcentagem(
                            (dados.dadosPrecificacao.custo_de_cartao * 100) /
                              dados.dadosPrecificacao.preco_de_venda,
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(subtotal_custo_cartao)}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    margin: '25px 0 15px',
                    textAlign: 'center',
                    color: 'red',
                  }}
                >
                  Repasses
                </Typography>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left">Imposto</StyledTableCell>
                        <StyledTableCell align="left">Unitário</StyledTableCell>
                        <StyledTableCell align="left">
                          Percentual
                        </StyledTableCell>
                        <StyledTableCell align="left">Subtotal</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow key="1">
                        <StyledTableCell align="left">IPI</StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(dados.dadosPrecificacao.repasse_do_ipi)}
                        </StyledTableCell>
                        <StyledTableCell align="left">-</StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(subtotal_repasse_ipi)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow key="2">
                        <StyledTableCell align="left">Frete</StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(
                            dados.dadosPrecificacao.repasse_do_frete,
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="left">-</StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(subtotal_repasse_frete)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow key="3">
                        <StyledTableCell align="left">ST</StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(dados.dadosPrecificacao.repasse_de_st)}
                        </StyledTableCell>
                        <StyledTableCell align="left">-</StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(subtotal_repasse_st)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow key="4">
                        <StyledTableCell align="left">Seguro</StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(
                            dados.dadosPrecificacao.repasse_do_seguro,
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="left">-</StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(subtotal_repasse_seguro)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow key="5">
                        <StyledTableCell align="left">
                          Outras Despesas
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(
                            dados.dadosPrecificacao.repasse_de_outras_despesas,
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="left">-</StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(subtotal_repasse_outras_despesas)}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    margin: '25px 0 15px',
                    textAlign: 'center',
                    color: 'red',
                  }}
                >
                  Descontos
                </Typography>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left">Imposto</StyledTableCell>
                        <StyledTableCell align="left">Unitário</StyledTableCell>
                        <StyledTableCell align="left">
                          Percentual
                        </StyledTableCell>
                        <StyledTableCell align="left">Subtotal</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow key="1">
                        <StyledTableCell align="left">
                          Desconto em Nfe
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(dados.dadosPrecificacao.desconto_em_nfe)}
                        </StyledTableCell>
                        <StyledTableCell align="left">-</StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(subtotal_desconto_nfe)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow key="2">
                        <StyledTableCell align="left">
                          Desconto em Boleto
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(
                            dados.dadosPrecificacao.desconto_em_boleto,
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="left">-</StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(subtotal_desconto_boleto)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow key="3">
                        <StyledTableCell align="left">
                          Desconto em Bonificação
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(
                            dados.dadosPrecificacao.desconto_em_bonificacao,
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="left">-</StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(subtotal_desconto_bonificacao)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow key="4">
                        <StyledTableCell align="left">
                          Desconto em Backlight
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(
                            dados.dadosPrecificacao
                              .desconto_em_backlight_e_ponto_extra,
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="left">-</StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(subtotal_desconto_backlight)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow key="5">
                        <StyledTableCell align="left">
                          Desconto em Jornal de Ofertas
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(
                            dados.dadosPrecificacao
                              .desconto_em_jornal_de_ofertas,
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="left">-</StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(subtotal_desconto_jornal_ofertas)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow key="5">
                        <StyledTableCell align="left">
                          Desconto em Descarrego de Mercadorias
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(
                            dados.dadosPrecificacao
                              .desconto_em_descargo_de_mercadorias,
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="left">-</StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(subtotal_desconto_descargo_mercadorias)}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    margin: '25px 0 15px',
                    textAlign: 'center',
                    color: 'red',
                  }}
                >
                  Impostos
                </Typography>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left">Imposto</StyledTableCell>
                        <StyledTableCell align="left">Unitário</StyledTableCell>
                        <StyledTableCell align="left">
                          Percentual
                        </StyledTableCell>
                        <StyledTableCell align="left">Subtotal</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow key="1">
                        <StyledTableCell align="left">
                          PIS Compra
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(dados.dadosPrecificacao.pis_compra)}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatPorcentagem(
                            dados.dadosPrecificacao.taxa_pis_compra,
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(subtotal_pis_compra)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow key="2">
                        <StyledTableCell align="left">
                          COFINS Compra
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(dados.dadosPrecificacao.cofins_compra)}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatPorcentagem(
                            dados.dadosPrecificacao.taxa_cofins_compra,
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(subtotal_cofins_compra)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow key="3">
                        <StyledTableCell align="left">
                          ICMS Compra
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(dados.dadosPrecificacao.icms_compra)}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatPorcentagem(
                            dados.dadosPrecificacao.taxa_icms_compra,
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(subtotal_icms_compra)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow key="4">
                        <StyledTableCell align="left">
                          ICMS Antecipação
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(
                            dados.dadosPrecificacao.icms_antecipacao,
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="left">-</StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(subtotal_icms_antecipacao)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow key="5">
                        <StyledTableCell align="left">CSLL</StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(dados.dadosPrecificacao.csll)}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatPorcentagem(dados.dadosPrecificacao.taxa_csll)}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(subtotal_csll)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow key="6">
                        <StyledTableCell align="left">
                          PIS Venda
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(dados.dadosPrecificacao.pis_venda)}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatPorcentagem(
                            dados.dadosPrecificacao.taxa_pis_venda,
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(subtotal_pis_venda)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow key="7">
                        <StyledTableCell align="left">
                          COFINS Venda
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(dados.dadosPrecificacao.cofins_venda)}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatPorcentagem(
                            dados.dadosPrecificacao.taxa_cofins_venda,
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(subtotal_cofins_venda)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow key="8">
                        <StyledTableCell align="left">
                          ICMS Venda
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(dados.dadosPrecificacao.icms_venda)}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatPorcentagem(
                            dados.dadosPrecificacao.taxa_icms_venda,
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(subtotal_icms_venda)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow key="9">
                        <StyledTableCell align="left">
                          ICMS de Fronteira
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(dados.dadosPrecificacao.icms_fronteira)}
                        </StyledTableCell>
                        <StyledTableCell align="left">-</StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(subtotal_icms_fronteira)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow key="10">
                        <StyledTableCell align="left">IRPJ</StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(dados.dadosPrecificacao.irpj)}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatPorcentagem(dados.dadosPrecificacao.taxa_irpj)}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatMoeda(subtotal_irpj)}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ) : (
              <div>Nada...</div>
            )}
          </>
        ) : (
          <div>Nada...</div>
        )}
      </DialogContent>
    </Dialog>
  );
});

export default Grafico;
