import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';

function formatTemperatura(valor) {
  return valor !== null && valor !== undefined
    ? `${valor.toLocaleString('de-DE', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      })} °C`
    : '';
}

const StatusField = (props) => {
  const record = useRecordContext(props);
  const temperatura = record
    ? formatTemperatura(record.record[props.source])
    : '-';
  return (
    <Typography
      variant="subtitle1"
      style={{
        fontWeight: 'bold',
      }}
    >
      {temperatura}
    </Typography>
  );
};


export default StatusField;
