import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AlarmAddIcon from '@material-ui/icons/AlarmAdd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PrintIcon from '@material-ui/icons/Print';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { STATUS_ATIVO, STATUS_INATIVO } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    /* width: theme.spacing(3),
    height: theme.spacing(3), */
  },
}));

const ActionsField = ({
  tipo,
  handleEdit = () => {},
  handleShow = () => {},
  handleAtivar = () => {},
  handleDesativar = () => {},
  handleDeletar = () => {},
  handleHorarios = () => {},
  handleImprimir = () => {},
  handleImprimirContraProva = () => {},
  ...rest
}) => {
  const classes = useStyles();
  const record = useRecordContext(rest);
  // const value = record ? record.record[source] : '';
  const gerenciado = record
    ? record.record.user_id === 1 || record.record.user_id === 2
    : false;

  const status = record ? record.record.status : '';

  const userId = record ? record.record.user_id : null;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {status === STATUS_ATIVO && (
        <Tooltip title="Editar" placement="bottom" aria-label="add2">
          <IconButton
            aria-label="edit"
            classes={{ root: classes.root }}
            size="small"
            onClick={() => handleEdit(record.record)}
          >
            <EditOutlinedIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Detalhes" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="edit"
          classes={{ root: classes.root }}
          size="small"
          onClick={() => handleShow(record.record)}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
      {status === STATUS_ATIVO && (
        <Tooltip title="Horários" placement="bottom" aria-label="horarios">
          <IconButton
            aria-label="horarios"
            size="small"
            onClick={() => handleHorarios(record.record)}
          >
            <AlarmAddIcon />
          </IconButton>
        </Tooltip>
      )}
      {status === STATUS_ATIVO && (
        <Tooltip
          title="Imprimir Checklist"
          placement="bottom"
          aria-label="excluir2"
        >
          <IconButton
            aria-label="imprimir-checklist"
            size="small"
            onClick={() => handleImprimir(record.record)}
            //disabled={gerenciado}
          >
            <AssignmentIcon />
          </IconButton>
        </Tooltip>
      )}
      {status === STATUS_ATIVO && (
        <Tooltip
          title="Imprimir Última Contraprova"
          placement="bottom"
          aria-label="excluir2"
        >
          <IconButton
            aria-label="imprimir-checklist"
            size="small"
            onClick={() => handleImprimirContraProva(record.record)}
            //disabled={gerenciado}
          >
            <PrintIcon style={{ color: 'green' }} />
          </IconButton>
        </Tooltip>
      )}
      {status === STATUS_INATIVO && (
        <Tooltip title="Ativar" placement="bottom" aria-label="ativar2">
          <IconButton
            aria-label="ativar"
            size="small"
            onClick={() => handleAtivar(record.record)}
            disabled={userId ? false : true}
            //disabled={gerenciado}
          >
            <CheckCircleOutlineIcon />
          </IconButton>
        </Tooltip>
      )}
      {status === STATUS_ATIVO && (
        <Tooltip title="Desativar" placement="bottom" aria-label="desativar2">
          <IconButton
            aria-label="desativar"
            size="small"
            onClick={() => handleDesativar(record.record)}
            //disabled={gerenciado}
          >
            <ClearIcon />
          </IconButton>
        </Tooltip>
      )}
      {status === STATUS_INATIVO && (
        <Tooltip title="Excluir" placement="bottom" aria-label="excluir2">
          <IconButton
            aria-label="excluir"
            size="small"
            onClick={() => handleDeletar(record.record)}
            //disabled={gerenciado}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default ActionsField;
