export function createObjEstoque(linhas) {
  const saldosConta = [];
  const despesasPendentes = [];
  const fluxosCaixas = [];

  for (let i = 0; i < linhas.length; i++) {
    saldosConta.push(linhas[i].saldo_conta);
    despesasPendentes.push(linhas[i].despesas_pendentes);
    fluxosCaixas.push(linhas[i].fluxo_caixa);
  }

  return [
    {
      label: 'Saldo Contas',
      color: '#936ef7',
      data: saldosConta,
    },
    {
      label: 'Despesas Pendentes',
      color: '#d64567',
      data: despesasPendentes,
    },
    {
      label: 'Fluxo de Caixa',
      color: '#f28817',
      data: fluxosCaixas,
    },
  ];
}
