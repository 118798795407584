import React, { useRef, useState, useEffect, useCallback } from 'react';
import {
  DateField,
  NumberField,
  ReferenceField,
  TextField,
  ListBase,
  ListToolbar,
  Pagination,
  ShowButton,
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  FunctionField,
  useVersion,
  useListContext,
  useRefresh,
  ListContextProvider,
  Title,
} from 'react-admin';

import {
  useMediaQuery,
  Divider,
  Tabs,
  Tab,
  CardContent,
  Card,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import Datagrid from '../../Components/Datagrid';
import DialogoDeleteResource from '../../Components/DialogoDeleteResource';
import DialogoPedidoLiberacao from '../../Components/Dialogos/DialogoPedidoLiberacao';
import { api } from '../../services';
import { STATUS_ATIVO, STATUS_INATIVO } from '../../utils';
import ActionsField from './ActionsField';
import Actions from './ActionsList';
import AutorizadorField from './Fields/AutorizadorField';
import FrentistaField from './Fields/FrentistaField';
import StatusField from './Fields/StatusField';
import Filter from './Filter';

const tabs = [
  { id: 'pendente', name: 'Pendentes' },
  { id: 'verificado', name: 'Verificados' },
];

const MyList = ({ ...props }) => {
  const refDialogoDeleteResource = useRef(null);
  const refresh = useRefresh();
  const { enqueueSnackbar } = useSnackbar();
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [ativos, setAtivos] = useState([]);
  const [inativos, setInativos] = useState([]);
  const refDialogoPedidoLiberacao = useRef(null);

  useEffect(() => {
    if (ids && ids !== filterValues.is_analisado) {
      switch (filterValues.is_analisado) {
        case STATUS_INATIVO:
          setInativos(ids);
          break;
        case STATUS_ATIVO:
          setAtivos(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.is_analisado]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters(
          {
            ...filterValues,
            is_analisado: value === 'pendente' ? STATUS_INATIVO : STATUS_ATIVO,
          },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );
  function handleCriar() {
    if (refDialogoPedidoLiberacao.current) {
      refDialogoPedidoLiberacao.current.handleCreate();
    }
  }

  function handleClose() {
    refresh();
  }

  function handleAprovacao(dados) {
    if (refDialogoPedidoLiberacao.current) {
      refDialogoPedidoLiberacao.current.handleEdit(dados.id, 1);
    }
  }

  function handleDetalhes(dados) {
    if (refDialogoPedidoLiberacao.current) {
      refDialogoPedidoLiberacao.current.handleEdit(dados.id, 1);
    }
  }

  return (
    <>
      <Title title="Aprovações de Pedidos Liberações de preço" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions handleCriar={handleCriar} {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Tabs
          variant="fullWidth"
          centered
          value={
            filterValues.is_analisado === STATUS_INATIVO
              ? tabs[0].id
              : tabs[1].id
          }
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.is_analisado === STATUS_INATIVO && (
            <ListContextProvider value={{ ...listContext, ids: inativos }}>
              <Datagrid {...props}>
                <StatusField source="id" label="Status" sortable={false} />
                <TextField source="id" label="Nº Pedido" sortable={false} />
                <ReferenceField
                  label="Cliente"
                  source="cliente_id"
                  reference="clientes"
                  sortable={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <FrentistaField
                  label="Criador"
                  source="responsavel"
                  sortable={false}
                />
                <AutorizadorField
                  label="Autorizador"
                  source="analisador"
                  sortable={false}
                />
                <DateField
                  source="data_minima"
                  label="Início"
                  showTime
                  sortable={false}
                />
                <DateField
                  source="data_maxima"
                  label="Vencimento"
                  showTime
                  sortable={false}
                />
                <ActionsField
                  source="lalala"
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  handleAprovacao={handleAprovacao}
                  handleDetalhes={handleDetalhes}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.is_analisado === STATUS_ATIVO && (
            <ListContextProvider value={{ ...listContext, ids: ativos }}>
              <Datagrid {...props}>
                <StatusField source="id" label="Status" sortable={false} />
                <TextField source="id" label="Nº Pedido" sortable={false} />
                <ReferenceField
                  label="Cliente"
                  source="cliente_id"
                  reference="clientes"
                  sortable={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <FrentistaField
                  label="Criador"
                  source="responsavel"
                  sortable={false}
                />
                <AutorizadorField
                  label="Autorizador"
                  source="analisador"
                  sortable={false}
                />
                <DateField
                  source="data_minima"
                  label="Início"
                  showTime
                  sortable={false}
                />
                <DateField
                  source="data_maxima"
                  label="Vencimento"
                  showTime
                  sortable={false}
                />
                <ActionsField
                  source="lalala"
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  handleAprovacao={handleAprovacao}
                  handleDetalhes={handleDetalhes}
                />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
        <TransacoesPagination />
      </Card>
      <DialogoDeleteResource ref={refDialogoDeleteResource} />
      <DialogoPedidoLiberacao
        ref={refDialogoPedidoLiberacao}
        handleClose={handleClose}
      />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Aprovações de Pedidos Liberações de preço"
      filterDefaultValues={{
        /* periodo: {
          start: new Date(),
          end: new Date(),
        }, */
        is_analisado: STATUS_INATIVO,
      }}
    />
  </>
);

export default List2;
