import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Typography } from '@material-ui/core';

function getIsJornalVigente(dados) {
  if (dados) {
    const timeMinima = new Date(dados.data_minima).getTime();
    const timeMaxima = new Date(dados.data_maxima).getTime();
    const timeAtual = new Date().getTime();
    if (timeMinima < timeAtual && timeAtual < timeMaxima) {
      return true;
    }
  }
  return false;
}

const DataMaximaField = (props) => {
  const record = useRecordContext(props);
  const isJornalVigente = record
    ? record.record
      ? getIsJornalVigente(record.record)
      : false
    : false;

  return (
    <Typography
      variant="subtitle1"
      style={{
        fontSize: '25px',
        fontWeight: 'bold',
        color: 'red',
      }}
    >
      {isJornalVigente ? 'Jornal Vigente' : ''}
    </Typography>
  );
};

export default DataMaximaField;
