import React from 'react';
import {
  Filter as FilterAdmin,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  BooleanInput,
} from 'react-admin';

import {
  IntegerInput,
  PeriodoInput,
  PrecoInput,
} from '../../Components/InputsReactAdmin';
import { STATUS_ATIVO } from '../../utils/constants';

const Filter = (props) => (
  <FilterAdmin {...props}>
    {/* <BooleanInput label="Cancelados" source="cancelado" /> */}
    {/* <ReferenceInput
      fullWidth
      label="Cliente"
      source="cliente_id"
      reference="clientes"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput> */}
    {/* <SelectInput
      source="status"
      choices={choicesPagamento}
      allowEmpty={false}
    /> */}
    <ReferenceInput
      fullWidth
      label="Cliente"
      source="cliente_id"
      reference="clientes"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput>
    <PeriodoInput
      source="periodo"
      label="Peŕiodo"
      defaultValue={{
        start: new Date(),
        end: new Date(),
      }}
    />
    <ReferenceInput
      source="conta_id"
      reference="contas_normal"
      label="Caixa"
      filter={{ is_caixa: true, status: STATUS_ATIVO }}
      allowEmpty={false}
    >
      <SelectInput optionText="nome" allowEmpty={false} />
    </ReferenceInput>
    <ReferenceInput
      fullWidth
      label="Frentista"
      source="frentista_id"
      reference="users"
      sort={{ field: 'username', order: 'ASC' }}
      filterToQuery={(searchText) => ({ username: `${searchText}` })}
      perPage={10}
      allowEmpty={false}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <IntegerInput source="id" label="Número de pré venda" prefixo="#" />
    <PrecoInput
      source="valor"
      label="Valor da pré venda"
      fixedDecimalScale={false}
    />
  </FilterAdmin>
);

export default Filter;
