import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  tipoStatusAndamentoAprovar,
  tipoStatusAndamentoAprovarVolta,
  tipoStatusAndamentoCriarPedido,
  tipoStatusAndamentoDetalhes,
} from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'block',
  },
  sectionDesktop: {
    display: 'flex',
    gap: '10px',
  },
}));

function getPrefixo(tipoStatusAndamento) {
  switch (tipoStatusAndamento) {
    case tipoStatusAndamentoAprovar:
      return 'APROVAÇÃO - ';
    case tipoStatusAndamentoAprovarVolta:
      return 'ACEITAÇÃO - ';
    default:
      return '';
  }
}

export default function PrimarySearchAppBar({
  disabledBack = false,
  disabledForward = false,
  disabledDeleteItem = false,
  onClick,
  FECHAR_PEDIDO_ACTION,
  CANCELAR_PEDIDO_ACTION,
  DELETAR_ITEM_ACTION,
  numeroPedido,
  idEdit,
  hideDeleteItem = false,
  tipoStatusAndamento,
}) {
  const classes = useStyles();

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar>
          <Tooltip title="Cancelar">
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              size="small"
              disabled={disabledBack}
              onClick={() => onClick(CANCELAR_PEDIDO_ACTION)}
            >
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Prosseguir">
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              size="small"
              disabled={disabledForward}
              onClick={() => onClick(FECHAR_PEDIDO_ACTION)}
            >
              <ArrowForwardIcon />
            </IconButton>
          </Tooltip>
          <Typography className={classes.title} variant="h6" noWrap>
            {}
          </Typography>
          <div className={classes.grow}>
            {idEdit <= 0 ? (
              <div>
                <span
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  NOVO PEDIDO DE LIBERAÇÃO - ITENS - 2/2
                </span>
              </div>
            ) : (
              <div>
                <span
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {`${getPrefixo(
                    tipoStatusAndamento,
                  )}PEDIDO DE LIBERAÇÃO Nº ${numeroPedido} - ITENS - 2/2`}
                </span>
              </div>
            )}
          </div>
          <div className={classes.sectionDesktop}>
            {!hideDeleteItem ? (
              <Tooltip title="Deletar Item">
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={() => onClick(DELETAR_ITEM_ACTION)}
                  size="small"
                  disabled={disabledDeleteItem}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            ) : null}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
