import { ShoppingCart } from '@material-ui/icons';

import List from './List';
import Show from './Show';

export default {
  list: List,
  show: Show,
  icon: ShoppingCart,
};
