import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import Create from './Create';
import Edit from './Edit';
import List from './List2';
import Show from './Show';

export default {
  list: List,
  create: Create,
  edit: Edit,
  icon: AccountBalanceIcon,
  show: Show,
};
