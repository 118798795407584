import React, { useCallback } from 'react';
import { TopToolbar, useListContext } from 'react-admin';

import { Box, makeStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import PeriodoInput from './PeriodoInput';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    alignItems: 'center',
    margin: 0,
    padding: '0 10px 0 0',
    marginBottom: '15px',
    display: 'flex',
  },
}));

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  exporter,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  enabledCreate,
  ...rest
}) => {
  const classes = useStyles();
  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters } = listContext;

  const aplicarFiltros = useCallback(
    (status) => {
      if (setFilters) {
        setFilters({ ...filterValues, status }, displayedFilters);
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  const handleChange = (event) => {
    aplicarFiltros(event.target.checked);
  };

  return (
    <TopToolbar className={classes.root}>
      <Box
        display="flex"
        justifyContent="space-between"
        flex={1}
        alignItems="center"
      >
        <PeriodoInput />
        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center" mr="15px" flex={1} />
        </Box>
      </Box>
    </TopToolbar>
  );
};

export default ListActions;
