import React, { useRef } from 'react';
import {
  TextField,
  NumberField,
  ListBase,
  ListToolbar,
  Pagination,
  FunctionField,
} from 'react-admin';

import { Card } from '@material-ui/core';

import Datagrid from '../../Components/Datagrid';
import DeleteButton from '../../Components/DeleteButton';
import DialogoDeleteResource from '../../Components/DialogoDeleteResource';
import EditButton from '../../Components/EditButton2';
import { formatTelefone } from '../../utils';
import Actions from './ActionsList';
import Dialogo from './ContainerDialogoProduto';
import Filter from './Filter';

const MyList = ({ ...props }) => {
  const refDialogoCompra = useRef(null);
  const refDialogoDeleteResource = useRef(null);

  function handleCriar() {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleCreate();
    }
  }
  function handleEdit({ id }) {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleEdit(id);
    }
  }
  function deleteHundle(record) {
    if (refDialogoDeleteResource.current)
      refDialogoDeleteResource.current.handleOpen(record);
  }

  return (
    <>
      <ListToolbar
        filters={props.filters}
        actions={<Actions handleCriar={handleCriar} {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid {...props}>
          <TextField source="id" label="ID" />
          <TextField source="nome" label="Nome" />
          <FunctionField
            label="Telefone"
            render={(record) => formatTelefone(record.telefone)}
          />
          <EditButton editHundle={handleEdit} />
          <DeleteButton
            label="Excluir"
            deleteHundle={deleteHundle}
            disabled={() => false}
          />
        </Datagrid>
        <TransacoesPagination />
      </Card>
      <Dialogo ref={refDialogoCompra} />
      <DialogoDeleteResource ref={refDialogoDeleteResource} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Clientes Televendas"
    />
  </>
);

export default List2;
