import React from 'react';
import {
  Filter as FilterAdmin,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
} from 'react-admin';

import { IntegerInput, PeriodoInput } from '../../Components/InputsReactAdmin';
import { STATUS_ATIVO } from '../../utils/constants';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <ReferenceInput
      fullWidth
      label="Cliente"
      source="cliente_id"
      reference="clientes"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput>
    <PeriodoInput
      source="periodo"
      label="Peŕiodo"
      defaultValue={{
        start: new Date(),
        end: new Date(),
      }}
    />
    <TextInput source="numero" label="Número da venda" />
  </FilterAdmin>
);

export default Filter;

// mudar aqui
