import React, { useRef } from 'react';
import {
  ListBase,
  ListToolbar,
  Pagination,
  useRefresh,
  Title,
  useListContext,
  Datagrid,
} from 'react-admin';

import { Card, makeStyles } from '@material-ui/core';

import { STATUS_ATIVO } from '../../utils';
import DialogoShowProduto from '../Produtos/DialogoShow';
import Actions from './ActionsList';
import Dialogo from './DialogoCreateEdit';
import ActionsField from './Fields/ActionsField';
import DescontoMaximoPrecoVendaField from './Fields/DescontoMaximoPrecoVendaField';
import DescricaoField from './Fields/DescricaoField';
import PermissaoLIberarLiberacaoPrecosField from './Fields/PermissaoLIberarLiberacaoPrecosField';
import PermissaoLIberarOfertaJornalField from './Fields/PermissaoLIberarOfertaJornalField';
import Filter from './Filter';

const useStyles = makeStyles((theme) => ({
  statusHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  statusCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  statusContent: {
    flex: 5,
    marginLeft: '30px',
    maxWidth: '100px',
  },
  dataHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoHeader: {
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoCell: {
    flex: 2,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaHeader: {
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaCell: {
    flex: 2,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaHeader: {
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaCell: {
    flex: 2,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
}));

const MyList = ({ ...props }) => {
  const refDialogoCompra = useRef(null);
  const refresh = useRefresh();
  const classes = useStyles();
  const refDialogoShowProduto = useRef(null);

  function handleEdit({ id }) {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleOpen(id);
    }
  }
  function handleDetalhes({ id }) {
    if (refDialogoShowProduto.current)
      refDialogoShowProduto.current.handleOpen(id);
  }

  const handleClose = () => {
    refresh();
  };

  return (
    <>
      <Title title="Config Produtos" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid {...props}>
          <PermissaoLIberarLiberacaoPrecosField
            source="urgencia"
            label="Permissão Liberação de Preço"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <PermissaoLIberarOfertaJornalField
            source="dificuldade"
            label="Permissão Oferta de Jornal"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <DescricaoField
            source="nome"
            label="Descrição"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <DescontoMaximoPrecoVendaField
            source="color"
            label="Desconto Máximo Permitido"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            sortable={false}
          />

          <ActionsField
            source="valor"
            textAlign="right"
            label="Ações"
            sortable={false}
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            handleDetalhes={handleDetalhes}
            handleEdit={handleEdit}
          />
        </Datagrid>
        <TransacoesPagination />
      </Card>
      <Dialogo ref={refDialogoCompra} handleClose={handleClose} />
      <DialogoShowProduto ref={refDialogoShowProduto} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Config Produtos"
      filterDefaultValues={{}}
      filter={{ status: STATUS_ATIVO }}
      sort={{ field: 'id', order: 'DESC' }}
      component="div"
    />
  </>
);

export default List2;
