import React, { useRef } from 'react';

//import { Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import DialogoConfig from '../../../Resources/Config/DialogoEdit';

/*const Config = () => (
  <>
    <Redirect to="/config/1" />
  </>
);*/

const Config = () => {
  const refDialogo = useRef(null);

  function openDialogoConfig() {
    if (refDialogo.current) {
      refDialogo.current.handleOpen();
    }
  }

  return (
    <>
      <div
        style={{
          height: '300px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button variant="contained" color="primary" onClick={openDialogoConfig}>
          Editar Configurações
        </Button>
      </div>
      <DialogoConfig ref={refDialogo} />
    </>
  );
};

export default Config;
