import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

const useStyles = makeStyles((theme) => ({
  solicitado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
  },
  analisado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#e2e629',
  },
  analisadoVolta: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
  },
}));

const StatusField = (props) => {
  const classes = useStyles();
  const record = useRecordContext(props);
  const is_analisado = record
    ? record.record
      ? record.record.is_analisado
      : false
    : false;

  return (
    <div style={{ display: 'flex' }}>
      {!is_analisado ? (
        <Avatar className={classes.solicitado}>
          <PriorityHighIcon style={{ fontSize: 15 }} />
        </Avatar>
      ) : (
        <Avatar className={classes.analisadoVolta}>
          <CheckIcon style={{ fontSize: 15 }} />
        </Avatar>
      )}
    </div>
  );
};

export default StatusField;
