import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { TimeInputBonito } from '../../../Components';
import TimeInput from '../../../Components/Inputs/TimeInput';
import { getListAllAPI, getUserId, api } from '../../../services';
import { writeCodigoBarras } from '../../../utils/codigoBarras';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DialogoCategoriaTransacao = forwardRef(
  (
    {
      pontos,
      horarioEntrada1,
      horarioSaida1,
      horarioEntrada2,
      horarioSaida2,
      handleSalvar = () => {},
    },
    ref,
  ) => {
    const [open, setOpen] = useState(false);

    const [inputCode, setInputCode] = useState(0);

    const classes = useStyles({ cor: '#009FD4' });

    const handleCloseDialog = () => {
      setOpen(false);
    };

    useImperativeHandle(ref, () => ({
      handleOpen(inputCode) {
        setInputCode(inputCode);
        setOpen(true);
      },
    }));

    function formatData2(data) {
      if (data) {
        return moment(data).format('HH:mm');
      }
      return '-';
    }

    function handleSalvarLocal(data) {
      handleSalvar(data, inputCode);
      handleCloseDialog();
    }

    function getTitle() {
      switch (inputCode) {
        case 0:
          return 'Definir Entrada 1º Expediente';
        case 1:
          return 'Definir Saída 1º Expediente';
        case 2:
          return 'Definir Entrada 2º Expediente';
        case 3:
          return 'Definir Saída 2º Expediente';
        default:
          return '';
      }
    }

    const pontosRestantes = pontos.filter((item) => {
      const data = new Date(item.data);
      const horasPonto = data.getHours();
      const minutosPonto = data.getMinutes();

      if (horarioEntrada1) {
        const horasHorarioEntrada1 = horarioEntrada1.getHours();
        const minutosHorarioEntrada1 = horarioEntrada1.getMinutes();

        if (
          horasPonto === horasHorarioEntrada1 &&
          minutosPonto === minutosHorarioEntrada1
        ) {
          return false;
        }
      }

      if (horarioSaida1) {
        const horasHorarioSaida1 = horarioSaida1.getHours();
        const minutosHorarioSaida1 = horarioSaida1.getMinutes();

        if (
          horasPonto === horasHorarioSaida1 &&
          minutosPonto === minutosHorarioSaida1
        ) {
          return false;
        }
      }

      if (horarioEntrada2) {
        const horasHorarioEntrada2 = horarioEntrada2.getHours();
        const minutosHorarioEntrada2 = horarioEntrada2.getMinutes();

        if (
          horasPonto === horasHorarioEntrada2 &&
          minutosPonto === minutosHorarioEntrada2
        ) {
          return false;
        }
      }

      if (horarioSaida2) {
        const horasHorarioSaida2 = horarioSaida2.getHours();
        const minutosHorarioSaida2 = horarioSaida2.getMinutes();

        if (
          horasPonto === horasHorarioSaida2 &&
          minutosPonto === minutosHorarioSaida2
        ) {
          return false;
        }
      }

      return true;
    });

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          TransitionComponent={Transition}
          keepMounted
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            {getTitle()}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 10px 20px',
              margin: '0',
            }}
          >
            <Box p="1em">
              <Box>
                <Box style={{ marginTop: '15px' }}>
                  <Typography
                    variant="h5"
                    style={{
                      marginBottom: '20px',
                    }}
                  >
                    Batidas de Ponto Não Usadas
                  </Typography>

                  <Box>
                    <TableContainer component={Paper} className={classes.root}>
                      <Table aria-label="collapsible table">
                        <TableHead
                          style={{
                            color: 'white',
                            fontWeight: 'bold',
                            backgroundColor: '#009FD4',
                          }}
                        >
                          <TableRow className={classes.rowHead}>
                            <TableCell className={classes.rowHead}>
                              Código
                            </TableCell>
                            <TableCell className={classes.rowHead}>
                              Horário
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {pontosRestantes.map((row) => (
                            <TableRow
                              key={row.id}
                              onClick={() => {
                                handleSalvarLocal(new Date(row.data));
                              }}
                              style={{
                                cursor: 'pointer',
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {writeCodigoBarras(row.id)}
                              </TableCell>
                              <TableCell>{formatData2(row.data)}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
