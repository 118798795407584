import React, { useImperativeHandle, forwardRef, useRef } from 'react';
import { useRefresh, useNotify } from 'react-admin';

import { DialogoConfirmacao } from '../../../Components';
import { api } from '../../../services';

const FormDialog = forwardRef(({ campoAtivo }, ref) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const refDialogoConfirmacao = useRef(null);

  useImperativeHandle(ref, () => ({
    handleOpen(record) {
      if (refDialogoConfirmacao.current) {
        refDialogoConfirmacao.current.handleOpen(
          'Conferir Produto',
          'Tem certeza da modificação do status desse produto',
          record,
        );
      }
    },
  }));

  async function handleConfirma(record) {
    await api.put(`/${'produtos_lotes/confirmar'}/${record.id}`);
    refresh();
  }

  return (
    <DialogoConfirmacao
      ref={refDialogoConfirmacao}
      handleConfirma={handleConfirma}
    />
  );
});

export default FormDialog;
