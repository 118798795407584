import React, { forwardRef, useImperativeHandle, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import { api } from '../../../services';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

/* const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);

  const classes = useStyles({ cor: '#009FD4' });

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
    },
  }));

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          Importar Tabela IBTP
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          <iframe
            name="dummyframe"
            id="dummyframe"
            style={{ display: 'none' }}
            title="oi"
          />
          <form
            method="POST"
            action="http://192.168.0.119:3333/tabela-ibtp/importar-do-arquivo-csv"
            encType="multipart/form-data"
            target="dummyframe"
          >
            <input type="file" name="tabela_ibtp" />
            <button type="submit"> Submit </button>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}); */

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles({ cor: '#009FD4' });
  const [carregando, setCarregando] = useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
    },
  }));

  async function onFormSubmit(e) {
    e.preventDefault(); // Stop form submit
    if (!file) {
      enqueueSnackbar('necessário enviar um arquivo csv', {
        variant: 'error',
      });
    } else {
      try {
        setCarregando(true);
        const formData = new FormData();
        formData.append('tabela_ibtp', file);
        const response = await api.post(
          '/tabela-ibtp/importar-do-arquivo-csv',
          formData,
          {
            headers: {
              'content-type': 'multipart/form-data',
            },
          },
        );
        const { status, message } = response.data;
        if (!status) throw message;
        enqueueSnackbar('Importação feita com Sucesso', {
          variant: 'success',
        });
      } catch (e) {
        enqueueSnackbar(e ? e.toString() : 'ERRO', {
          variant: 'error',
        });
      } finally {
        handleCloseDialog();
        setCarregando(false);
        setFile(null);
      }
    }
  }

  function onChange(e) {
    setFile(e.target.files[0]);
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          Importar Tabela IBTP
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          {!carregando ? (
            <form onSubmit={onFormSubmit}>
              <input type="file" name="tabela_ibtp" onChange={onChange} />
              <button type="submit"> Submit </button>
            </form>
          ) : (
            <>
              <CircularProgress />
              carregando...
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
