import React, { useRef } from 'react';
import {
  TextField,
  NumberField,
  ListBase,
  ListToolbar,
  Pagination,
  useRefresh,
  Title,
} from 'react-admin';

import { Box, makeStyles, Card } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

import Datagrid from '../../Components/Datagrid';
import EditButton from '../../Components/EditButton2';
import { formatMoeda, round10 } from '../../utils';
import Actions from './ActionsList';
import DialogoCreate from './DialogoCreate';
/* import DialogoDeletar from './DialogoDeletar';  */
import Filter from './Filter';

const useStyles = makeStyles((theme) => ({
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
  },
  cartao: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#00796b',
    marginLeft: '10px',
  },
}));

const StatusField = ({ status }) => {
  const classes = useStyles();
  return (
    <div style={{ display: 'flex' }}>
      {status ? (
        <Avatar className={classes.confirmado}>
          <CheckIcon style={{ fontSize: 15 }} />
        </Avatar>
      ) : (
        <Avatar className={classes.pendente}>
          <PriorityHighIcon style={{ fontSize: 15 }} />
        </Avatar>
      )}
    </div>
  );
};

const Permissao = ({ status, nome }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      width: '20%',
      marginBottom: '10px',
    }}
  >
    <StatusField status={status} />
    <div style={{ marginLeft: '10px' }}>{nome}</div>
  </div>
);

const PermissaoAba = ({ nome, permissoes }) => (
  <div>
    <div style={{ margin: '20px 0px', fontSize: '20px', fontWeight: 'bold' }}>
      {nome}
    </div>
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {permissoes.map((item) => (
        <Permissao status={item.status} nome={item.nome} />
      ))}
    </div>
  </div>
);

function sortPermissoes(record) {
  const abas = [];
  abas.push({
    nome: 'Funcionalidades',
    permissoes: [
      {
        status: record.criar_venda,
        nome: 'Criar Venda',
      },
      {
        status: record.consultar_precos,
        nome: 'Consultar Preço',
      },
      {
        status: record.utilizar_troco,
        nome: 'Utilizar Troco',
      },
      {
        status: record.utilizar_troco_abertura_caixa,
        nome: 'Utilizar troco na abertura',
      },
      {
        status: record.criar_pedido,
        nome: 'Criar Pedido',
      },
      {
        status: record.criar_retirada,
        nome: 'Criar Retirada',
      },
      {
        status: record.carregar_pedido,
        nome: 'Carregar Pedido',
      },
      {
        status: record.criar_cliente,
        nome: 'Criar Cliente',
      },
      {
        status: record.editar_cliente,
        nome: 'Editar Cliente',
      },
      {
        status: record.pesquisa_por_nomes,
        nome: 'Pesquisa por Nomes',
      },
      {
        status: record.pesquisa_por_codigo_de_balanca,
        nome: 'Pesquisa por Código de Balança',
      },
      {
        status: record.conferencia_grandes_pesagens,
        nome: 'Conferência de Grandes Pesagens',
      },
    ],
  });
  abas.push({
    nome: 'Meios de Pagamento',
    permissoes: [
      {
        status: record.tipo_pagamento_dinheiro,
        nome: 'Dinheiro',
      },
      {
        status: record.tipo_pagamento_cartao_credito,
        nome: 'Cartão Crédito',
      },
      {
        status: record.tipo_pagamento_cartao_debito,
        nome: 'Cartão Débito',
      },
      {
        status: record.tipo_pagamento_pix_qrcode,
        nome: 'Pix Qrcode',
      },
      {
        status: record.tipo_pagamento_voucher,
        nome: 'Voucher',
      },
      {
        status: record.tipo_pagamento_devolucao,
        nome: 'Devolução',
      },
      {
        status: record.tipo_pagamento_pix_distancia,
        nome: 'Pix à distância',
      },
      {
        status: record.tipo_pagamento_credito_fidelidade,
        nome: 'Crédito Fidelidade',
      },
    ],
  });
  return abas;
}

const PostPanel = ({ record }) => {
  const abas = sortPermissoes(record);
  return (
    <div>
      {abas.map((aba) => (
        <PermissaoAba nome={aba.nome} permissoes={aba.permissoes} />
      ))}
    </div>
  );
};

const MyList = ({ ...props }) => {
  const refDialogoCreate = useRef(null);
  const refDialogoEdit = useRef(null);
  const refDialogoDelete = useRef(null);
  const refresh = useRefresh();

  function handleCriar() {
    if (refDialogoCreate.current) {
      refDialogoCreate.current.handleCreate();
    }
  }
  function handleEdit({ id }) {
    if (refDialogoCreate.current) {
      refDialogoCreate.current.handleEdit(id);
    }
  }
  function deleteHundle({ id }) {
    if (refDialogoDelete.current) refDialogoDelete.current.handleOpen(id);
  }

  return (
    <>
      <Title title="Categorias Caixas" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions handleCriar={handleCriar} {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid {...props}>
          <TextField source="nome" label="Descrição" />
          <EditButton
            editHundle={handleEdit}
            disabled={(record) => (record ? record.id === 1 : true)}
          />
        </Datagrid>
        <TransacoesPagination />
      </Card>
      <DialogoCreate
        ref={refDialogoCreate}
        handleClose={() => {
          refresh();
        }}
      />
      {/* <DialogoDeletar
        ref={refDialogoDelete}
        handleClose={() => {
          refresh();
        }}
      /> */}
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      expand={<PostPanel />}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Categorias Caixas"
      // filterDefaultValues={{ ativo: true }}
    />
  </>
);

export default List2;
