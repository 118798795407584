import React from 'react';
import {
  Create as CreateAdmin,
  FormWithRedirect,
  useNotify,
  useRedirect,
} from 'react-admin';

import ContainerActionsBack from '../ContainerActionsBack';
import { CreateToolbar } from '../Toolbar';
import Actions from './Actions';

const FormContainer = ({
  children,
  hideSalvarAdd,
  labelSalvarListar,
  rotaSalvarListar,
  ...props
}) => (
  <FormWithRedirect
    {...props}
    warnWhenUnsavedChanges
    render={(formProps) => (
      <form onSubmit={formProps.handleSubmit}>
        {children}
        <CreateToolbar
          rota="list"
          hideSalvarAdd={hideSalvarAdd}
          labelSalvarListar={labelSalvarListar}
          rotaSalvarListar={rotaSalvarListar}
          {...formProps}
        />
      </form>
    )}
  />
);

const Create = ({
  children,
  title,
  defaultValue = {},
  transform = (props) => props,
  validate = () => {},
  onFailure = () => ({ message: 'algo deu errado', type: 'warning' }),
  hideSalvarAdd = false,
  labelSalvarListar = 'Salvar e listar',
  rotaSalvarListar = '',
  isBack = false,
  routeBack = '',
  ...props
}) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onFailureLocal = (error) => {
    const { message, type } = onFailure(error);
    notify(message, type);
    redirect('list', props.basePath);
  };
  return (
    <CreateAdmin
      {...props}
      title={title}
      transform={transform}
      onFailure={onFailureLocal}
      actions={
        <ContainerActionsBack
          actions={<Actions />}
          isBack={isBack}
          routeBack={routeBack}
        />
      }
    >
      <FormContainer
        validate={validate}
        defaultValue={defaultValue}
        hideSalvarAdd={hideSalvarAdd}
        labelSalvarListar={labelSalvarListar}
        rotaSalvarListar={rotaSalvarListar}
      >
        {children}
      </FormContainer>
    </CreateAdmin>
  );
};

export default Create;
