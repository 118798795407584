import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  text: {
    /* whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis', */
    // color: 'red',
    // marginLeft: '15px',
    /* maxWidth: '150px', */
  },
  avatar: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    color: 'white',
    backgroundColor: '#f44336',
  },
}));

function getStatusEditTransportador(record) {
  if (!record) return false;
  if (!record.record) return false;
  if (record.record.perdido) return false;
  if (!record.record.visible) return false;
  if (!record.record.transportador1) return false;
  if (!record.record.data_confirmacao_transporte_1) return false;
  if (record.record.data_confirmacao_transporte_2) return false;
  return true;
}

function getStatusVisibleCheckEntradaCofre(record) {
  if (!record) return false;
  if (!record.record) return false;
  //if (record.record.perdido) return false;
  //if (!record.record.visible) return false;
  if (!record.record.transportador1) return false;
  if (!record.record.data_confirmacao_transporte_1) return false;
  if (!record.record.transportador2) return false;
  if (record.record.data_confirmacao_transporte_2) return false;
  return true;
}

const DescricaoField = ({
  handleChange = () => {},
  handleEntradaCofre = () => {},
  enableChangeTransportador = false,
  enableCheckEntrada = true,
  ...props
}) => {
  const classes = useStyles();
  // const { source } = props;
  const record = useRecordContext(props);
  const value = record
    ? record.record
      ? record.record.transportador2
        ? record.record.transportador2.username
        : ''
      : ''
    : '';

  const isEditTransportador = enableChangeTransportador
    ? getStatusEditTransportador(record)
    : false;
  const visibleCheckEntradaCofre = enableCheckEntrada
    ? getStatusVisibleCheckEntradaCofre(record)
    : false;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title={value} placement="bottom" aria-label="add2">
        <Typography className={classes.text}>{value}</Typography>
      </Tooltip>
      {isEditTransportador ? (
        <Tooltip
          title="Mudar Transportador (Volta para Checkout)"
          placement="bottom"
          aria-label="excluir2"
        >
          <IconButton
            aria-label="excluir"
            size="small"
            onClick={() => handleChange(record)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      {visibleCheckEntradaCofre ? (
        <Tooltip
          title="Confirmar Saída do Cofre"
          placement="bottom"
          aria-label="excluir2"
        >
          <IconButton
            aria-label="excluir"
            size="small"
            onClick={() => handleEntradaCofre(record)}
          >
            <DoneIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </div>
  );
};



export default DescricaoField;
