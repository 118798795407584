import * as React from 'react';
import { useRecordContext } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DetailsIcon from '@material-ui/icons/Details';
import PrintIcon from '@material-ui/icons/Print';
import { useSnackbar } from 'notistack';

import { imprimirTroco } from '../../../services/impressora';

const ActionsField = ({
  handleEfetivar,
  handleShow,
  handleDeletar,
  handleLixeira,
  ...rest
}) => {
  const record = useRecordContext(rest);
  const statusValue = record
    ? record.record
      ? record.record.status
      : false
    : false;
  const visibleValue = record
    ? record.record
      ? record.record.visible
      : false
    : false;

  const { enqueueSnackbar } = useSnackbar();

  async function imprimir(id) {
    if (id > 0) {
      const codigo = await imprimirTroco(id);
      if (codigo < 0) {
        enqueueSnackbar('Erro ao imprimir a nota!', {
          variant: 'error',
        });
      }
    }
  }

  return (
    <div
      style={{
        marginRight: '0px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {visibleValue ? (
        <Tooltip title="Efetivar" placement="bottom" aria-label="add2">
          <IconButton
            aria-label="check"
            size="small"
            onClick={() => handleEfetivar(record)}
          >
            {!statusValue ? <CheckCircleOutlineIcon /> : <CancelIcon />}
          </IconButton>
        </Tooltip>
      ) : null}
      <Tooltip title="Detalhes" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="detalhes"
          size="small"
          onClick={() => handleShow(record)}
        >
          <DetailsIcon />
        </IconButton>
      </Tooltip>
      {true ? (
        <Tooltip title="Imprimir" placement="bottom" aria-label="add2">
          <IconButton
            aria-label="imprimir"
            size="small"
            onClick={() => imprimir(record ? record.record.id : -1)}
          >
            <PrintIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      {visibleValue ? (
        <Tooltip title="Excluir" placement="bottom" aria-label="excluir2">
          <IconButton
            aria-label="excluir"
            size="small"
            onClick={() => handleDeletar(record)}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      {visibleValue ? (
        <Tooltip
          title="Mandar para a lixeira"
          placement="bottom"
          aria-label="excluir2"
        >
          <IconButton
            aria-label="excluir"
            size="small"
            onClick={() => handleLixeira(record)}
          >
            <DeleteForeverIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </div>
  );
};


export default ActionsField;
