import React, { useCallback, cloneElement } from 'react';
import { TopToolbar, sanitizeListRestProps, useListContext } from 'react-admin';

import { Box, makeStyles } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import PrintIcon from '@material-ui/icons/Print';

import SearchInputMobills from './Inputs/SearchInputMobills';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    alignItems: 'center',
    marginBottom: '15px',
  },
}));

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  exporter,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  enabledCreate,
  handleCriar = () => {},
  handleChangeFila = () => {},
  ...rest
}) => {
  const classes = useStyles();
  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters } = listContext;

  const aplicarFiltros = useCallback(
    (nome) => {
      if (setFilters) {
        setFilters({ ...filterValues, nome }, displayedFilters);
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  const aplicarFiltros2 = useCallback(
    (status) => {
      if (setFilters) {
        setFilters({ ...filterValues, status }, displayedFilters);
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  const handleChange = (event) => {
    aplicarFiltros2(event.target.checked);
  };

  return (
    <TopToolbar {...sanitizeListRestProps(rest)} className={classes.root}>
      <Box
        display="flex"
        justifyContent="space-between"
        flex={1}
        alignItems="center"
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '15px',
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
        >
          <FormControlLabel
            labelPlacement="end"
            control={
              <Switch
                checked={filterValues.status}
                onChange={handleChange}
                name="checkedB"
                color="primary"
              />
            }
            label="Concluídos"
          />
        </div>
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })}
        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center" mr="15px">
            <Box marginLeft="15px">
              <SearchInputMobills
                color="green"
                onChange={(search) => aplicarFiltros(search)}
                placeholder="Pesquise por produto"
              />
            </Box>
          </Box>
          <Tooltip title="Definir fila" placement="top" aria-label="add">
            <Box
              boxShadow={2}
              borderRadius="50px"
              display="flex"
              alignItems="center"
              width="44px"
              style={{
                backgroundColor: 'white',
                height: '48px',
                width: '48px',
              }}
            >
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                onClick={() => handleChangeFila()}
                style={{ height: '48px', width: '48px' }}
              >
                <PrintIcon />
              </IconButton>
            </Box>
          </Tooltip>
          <Tooltip title="Nova Conferência" placement="top" aria-label="add">
            <Box
              boxShadow={2}
              borderRadius="50px"
              display="flex"
              alignItems="center"
              width="44px"
              style={{
                backgroundColor: 'white',
                height: '48px',
                width: '48px',
                marginLeft: '10px',
              }}
            >
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                onClick={() => handleCriar()}
                style={{ height: '48px', width: '48px' }}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Tooltip>
        </Box>
      </Box>
    </TopToolbar>
  );
};

export default ListActions;
