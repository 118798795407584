import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
  useEffect,
} from 'react';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Slide from '@material-ui/core/Slide';
import { makeStyles, createStyles, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import TodayIcon from '@material-ui/icons/Today';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  IntegerInput,
  PesoInput,
  PrecoInput,
  DialogoProduto2 as DialogoProduto,
  PorcentagemInput,
  DateInput,
  SelectInput,
} from '../../../../..';
import { updateAPI, getListAllAPI } from '../../../../../../services';
import {
  formatMoeda,
  POR_UNIDADE,
  POR_PESO_FIXO,
  ceil10,
  round10,
  REGIME_TRIBUTARIO_LUCRO_PRESUMIDO,
  REGIME_TRIBUTARIO_LUCRO_REAL,
  REGIME_TRIBUTARIO_MEI,
  REGIME_TRIBUTARIO_PRODUTOR_RURAL,
  REGIME_TRIBUTARIO_SIMPLES_NACIONAL,
  REGIME_TRIBUTARIO_SEM_NFE,
} from '../../../../../../utils';
import DialogoCreateImpressao from '../../../../DialogoProduto2/DialogoCreateImpressao';
import DialogoGrafico from '../DialogoGrafico';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: 'black',
    width: '1px',
    whiteSpace: 'nowrap',
    padding: '5px',
    border: '1px solid black',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    padding: '5px',
    border: '1px solid black',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
}))(TableCell);

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function formatPorcentagem(valor) {
  return valor !== null && valor !== undefined
    ? `${valor.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} %`
    : '';
}

function calculaValorNegociadoComAIndustria(bc, qtde, pesoMedio, tipoUnidade) {
  if (tipoUnidade === POR_UNIDADE) {
    return bc / qtde;
  }

  if (tipoUnidade === POR_PESO_FIXO) {
    return bc / (qtde * pesoMedio);
  }

  return bc / qtde;
}

function calculaQuantidadeComprada(valorNegociado, bc, pesoMedio, tipoUnidade) {
  if (tipoUnidade === POR_UNIDADE) {
    return Math.round(bc / valorNegociado);
  }

  if (tipoUnidade === POR_PESO_FIXO) {
    return Math.round(bc / (valorNegociado * pesoMedio));
  }

  return round10(bc / valorNegociado, -3);
}

function calculaBaseDeCalculo(valorNegociado, qtde, pesoMedio, tipoUnidade) {
  if (tipoUnidade === POR_UNIDADE) {
    return valorNegociado * qtde;
  }

  if (tipoUnidade === POR_PESO_FIXO) {
    return valorNegociado * (qtde * pesoMedio);
  }

  return valorNegociado * qtde;
}

function getQuantidadeRepasse(qtde, pesoMedio, tipoUnidade) {
  if (tipoUnidade === POR_UNIDADE) {
    return qtde;
  }

  if (tipoUnidade === POR_PESO_FIXO) {
    return qtde * pesoMedio;
  }

  return qtde;
}

const optionsAlvo = [
  {
    label: 'Descobrir Valor negociado com a indústria',
    value: 'descobrir_valor_unitario',
  },
  {
    label: 'Descobrir Base de Cálculo',
    value: 'descobrir_valor_total',
  },
  {
    label: 'Descobrir Quantidade Comprada',
    value: 'descobrir_quantidade',
  },
];

const optionsRepassesUnitarios = [
  {
    label: 'Unitário',
    value: 0,
  },
  {
    label: 'Total',
    value: 1,
  },
  {
    label: 'Porcentagem',
    value: 2,
  },
];

const optionsDescontos = [
  {
    label: 'Unitário',
    value: 0,
  },
  {
    label: 'Total',
    value: 1,
  },
];

function InputRepasse({
  tipoRepasse,
  value,
  onChange,
  errorMessage,
  disabled,
  onChangeTipo,
  label,
  name,
}) {
  switch (tipoRepasse) {
    case optionsRepassesUnitarios[0].value:
      return (
        <PrecoInput
          name={name}
          label={label}
          handleEnter={() => {}}
          handleKey={() => {}}
          value={Number.isNaN(value) ? '' : String(value)}
          onChange={(value) => onChange(parseFloat(value.target.value))}
          error={errorMessage !== ''}
          helperText={errorMessage}
          fullWidth
          escala={6}
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <SelectInput
                variant="standard"
                labelId="demo-simple-select-label-2"
                name="tipo"
                handleEnter={() => {}}
                label="Tipo"
                handleKey={() => {}}
                value={tipoRepasse}
                onChange={(value) => {
                  onChangeTipo(value.target.value);
                  onChange(0);
                }}
                options={optionsRepassesUnitarios}
              />
            ),
          }}
        />
      );
    case optionsRepassesUnitarios[1].value:
      return (
        <PrecoInput
          name={name}
          label={label}
          handleEnter={() => {}}
          handleKey={() => {}}
          value={Number.isNaN(value) ? '' : String(value)}
          onChange={(value) => onChange(parseFloat(value.target.value))}
          error={errorMessage !== ''}
          helperText={errorMessage}
          fullWidth
          escala={6}
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <SelectInput
                variant="standard"
                labelId="demo-simple-select-label-2"
                name="tipo"
                handleEnter={() => {}}
                label="Tipo"
                handleKey={() => {}}
                value={tipoRepasse}
                onChange={(value) => {
                  onChangeTipo(value.target.value);
                  onChange(0);
                }}
                options={optionsRepassesUnitarios}
              />
            ),
          }}
        />
      );
    case optionsRepassesUnitarios[2].value:
      return (
        <PorcentagemInput
          name={name}
          label={label}
          handleEnter={() => {}}
          handleKey={() => {}}
          value={Number.isNaN(value) ? '' : String(value)}
          onChange={(value) => onChange(parseFloat(value.target.value))}
          error={errorMessage !== ''}
          helperText={errorMessage}
          fullWidth
          escala={3}
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <SelectInput
                variant="standard"
                labelId="demo-simple-select-label-2"
                name="tipo"
                handleEnter={() => {}}
                label="Tipo"
                handleKey={() => {}}
                value={tipoRepasse}
                onChange={(value) => {
                  onChangeTipo(value.target.value);
                  onChange(0);
                }}
                options={optionsRepassesUnitarios}
              />
            ),
          }}
        />
      );
    default:
      return <div>Problema</div>;
  }
}

function InputDesconto({
  tipoRepasse,
  value,
  onChange,
  errorMessage,
  disabled = false,
  onChangeTipo,
  label,
  name,
}) {
  switch (tipoRepasse) {
    case optionsDescontos[0].value:
      return (
        <PrecoInput
          name={name}
          label={label}
          handleEnter={() => {}}
          handleKey={() => {}}
          value={Number.isNaN(value) ? '' : String(value)}
          onChange={(value) => onChange(parseFloat(value.target.value))}
          error={errorMessage !== ''}
          helperText={errorMessage}
          fullWidth
          escala={6}
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <SelectInput
                variant="standard"
                labelId="demo-simple-select-label-2"
                name="tipo"
                handleEnter={() => {}}
                label="Tipo"
                handleKey={() => {}}
                value={tipoRepasse}
                onChange={(value) => {
                  onChangeTipo(value.target.value);
                  onChange(0);
                }}
                options={optionsDescontos}
              />
            ),
          }}
        />
      );
    case optionsDescontos[1].value:
      return (
        <PrecoInput
          name={name}
          label={label}
          handleEnter={() => {}}
          handleKey={() => {}}
          value={Number.isNaN(value) ? '' : String(value)}
          onChange={(value) => onChange(parseFloat(value.target.value))}
          error={errorMessage !== ''}
          helperText={errorMessage}
          fullWidth
          escala={2}
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <SelectInput
                variant="standard"
                labelId="demo-simple-select-label-2"
                name="tipo"
                handleEnter={() => {}}
                label="Tipo"
                handleKey={() => {}}
                value={tipoRepasse}
                onChange={(value) => {
                  onChangeTipo(value.target.value);
                  onChange(0);
                }}
                options={optionsDescontos}
              />
            ),
          }}
        />
      );
    default:
      return <div>Problema</div>;
  }
}

function formatDate(date) {
  if (date === '') {
    return null;
  }
  const dataQuebrada = date.split('-');
  const ano = parseInt(dataQuebrada[0], 10);
  const mes = parseInt(dataQuebrada[1], 10) - 1;
  const dia = parseInt(dataQuebrada[2], 10);
  const dataNova = new Date();
  dataNova.setFullYear(ano);
  dataNova.setMonth(mes);
  dataNova.setDate(dia);
  return dataNova;
}

const COLOR_RED = '#ff0000';
const BACKGROUND_PREVISAO_LUCRO = '#92d050';
const BACKGROUND_PRECO_VENDA = '#ffff00';
const BACKGROUND_CREDITO_COMPRA = '#e2efd9';
const BACKGROUND_DEBITO_VENDA = '#fef2cb';
const BACKGROUND_ANALISE_FLUXO_CAIXA = '#fbe4d5';
const BACKGROUND_CUSTOS_ADICIONAIS = '#d6dce4';
const BACKGROUND_DESCONTOS = '#82ae55';

const DialogoBoletos = forwardRef(
  (
    {
      handleClose,
      estoque,
      hasRepasseNaoUnitarioIPI = false,
      hasRepasseNaoUnitarioFrete = false,
      hasRepasseNaoUnitarioST = false,
      hasRepasseNaoUnitarioSeguros = false,
      hasRepasseNaoUnitarioOutrasDespesas = false,
      label1 = 'Cancelar',
      label2 = 'Salvar',
      prefixoTitle = '',
      isCloseX = true,
      isEmitMessage = true,
    },
    ref,
  ) => {
    const [index, setIndex] = React.useState(-1);
    const [open, setOpen] = React.useState(false);
    const [carregando, setCarregando] = useState(false);
    const classes = useStyles();
    const [produto, setProduto] = useState(null);
    const [fornecedor, setFornecedor] = useState(null);
    const [config, setConfig] = useState(null);
    const [
      valorNegociadoComAIndustria,
      setValorNegociadoComAIndustria,
    ] = useState(0);
    const [quantidadeComprada, setQuantidadeComprada] = useState(0);
    //
    const [repasseDoIpi, setRepasseDoIpi] = useState(0);
    const [repasseDoFreteUnitario, setRepasseDoFreteUnitario] = useState(0);
    const [repasseDeSt, setRepasseDeSt] = useState(0);
    const [repasseDeSeguros, setRepasseDeSeguros] = useState(0);
    const [repasseDeOutrasDespesas, setRepasseDeOutrasDespesas] = useState(0);
    const [manterPrecoAnterior, setManterPrecoAnterior] = useState(false);
    const [validade, setValidade] = useState('');

    const [baseDeCalculo, setBaseDeCalculo] = useState(0);
    const [alvo, setAlvo] = useState(optionsAlvo[1].value);

    const [tipoRepasseDoIpi, setTipoRepasseDoIpi] = useState(
      optionsRepassesUnitarios[0].value,
    );

    const [tipoRepasseDoFrete, setTipoRepasseDoFrete] = useState(
      optionsRepassesUnitarios[0].value,
    );

    const [tipoRepasseDoSt, setTipoRepasseDoSt] = useState(
      optionsRepassesUnitarios[0].value,
    );

    const [tipoRepasseDoSeguros, setTipoRepasseDoSeguros] = useState(
      optionsRepassesUnitarios[0].value,
    );

    const [
      tipoRepasseDoOutrasDespesas,
      setTipoRepasseDoOutrasDespesas,
    ] = useState(optionsRepassesUnitarios[0].value);

    const [nImpressoes, setNImpressoes] = useState(0);

    const refDialogoImpressao = useRef(null);
    const refDialogoProduto = useRef(null);
    const refDialogoGrafico = useRef(null);

    const { enqueueSnackbar } = useSnackbar();

    //////////////////////////////

    const [descontoNfe, setDescontoNfe] = useState(0);
    const [descontoBoleto, setDescontoBoleto] = useState(0);
    const [descontoBonificacao, setDescontoBonificacao] = useState(0);
    const [descontoBacklight, setDescontoBacklight] = useState(0);
    const [descontoJornalOfertas, setDescontoJornalOfertas] = useState(0);
    const [
      descontoDescargoMercadorias,
      setDescontoDescargoMercadorias,
    ] = useState(0);

    //////////////////////////////

    const [tipoDescontoNfe, setTipoDescontoNfe] = useState(
      optionsDescontos[1].value,
    );

    const [tipoDescontoBoleto, setTipoDescontoBoleto] = useState(
      optionsDescontos[1].value,
    );

    const [tipoDescontoBonificacao, setTipoDescontoBonificacao] = useState(
      optionsDescontos[1].value,
    );

    const [tipoDescontoBacklight, setTipoDescontoBacklight] = useState(
      optionsDescontos[1].value,
    );

    const [tipoDescontoJornalOfertas, setTipoDescontoJornalOfertas] = useState(
      optionsDescontos[1].value,
    );

    const [
      tipoDescontoDescargoMercadorias,
      setTipoDescontoDescargoMercadorias,
    ] = useState(optionsDescontos[1].value);

    //////////////////////////////

    useImperativeHandle(ref, () => ({
      handleOpen(codigo, config, fornecedor, id) {
        getDataResource(codigo, config, fornecedor, id);
        //setOpen(true);
        setIndex(-1);
      },
      handleEdit(
        produtoId,
        config,
        fornecedor,
        validade,
        nImpressoes,
        valorNegociadoComAIndustria,
        quantidadeComprada,
        repasseDoIpi,
        repasseDoFreteUnitario,
        repasseDeSt,
        repasseDeSeguros,
        repasseDeOutrasDespesas,
        index,
        descontoNfe = 0,
        descontoBoleto = 0,
        descontoBonificacao = 0,
        descontoBacklight = 0,
        descontoJornalOfertas = 0,
        descontoDescargoMercadorias = 0,
      ) {
        //setOpen(true);
        getDataResourceEditar(
          produtoId,
          config,
          fornecedor,
          validade,
          nImpressoes,
          valorNegociadoComAIndustria,
          quantidadeComprada,
          repasseDoIpi,
          repasseDoFreteUnitario,
          repasseDeSt,
          repasseDeSeguros,
          repasseDeOutrasDespesas,
          //
          descontoNfe,
          descontoBoleto,
          descontoBonificacao,
          descontoBacklight,
          descontoJornalOfertas,
          descontoDescargoMercadorias,
        );
        setIndex(index);
      },
    }));

    const handleCloseDialog = (dadosRetorno = null) => {
      setOpen(false);
      handleClose(dadosRetorno);

      setProduto(null);
      setConfig(null);
      setFornecedor(null);
      setManterPrecoAnterior(false);

      //
      setValidade('');
      setValorNegociadoComAIndustria(0);
      setQuantidadeComprada(0);
      setRepasseDoIpi(0);
      setRepasseDoFreteUnitario(0);
      setRepasseDeSt(0);
      setRepasseDeSeguros(0);
      setRepasseDeOutrasDespesas(0);
      setBaseDeCalculo(0);
      setAlvo(optionsAlvo[0].value);
      //

      setTipoRepasseDoIpi(optionsRepassesUnitarios[0].value);
      setTipoRepasseDoFrete(optionsRepassesUnitarios[0].value);
      setTipoRepasseDoSt(optionsRepassesUnitarios[0].value);
      setTipoRepasseDoSeguros(optionsRepassesUnitarios[0].value);
      setTipoRepasseDoOutrasDespesas(optionsRepassesUnitarios[0].value);

      setNImpressoes(0);

      setDescontoNfe(0);
      setDescontoBoleto(0);
      setDescontoBonificacao(0);
      setDescontoBacklight(0);
      setDescontoJornalOfertas(0);
      setDescontoDescargoMercadorias(0);

      setTipoDescontoNfe(optionsDescontos[1].value);
      setTipoDescontoBoleto(optionsDescontos[1].value);
      setTipoDescontoBonificacao(optionsDescontos[1].value);
      setTipoDescontoBacklight(optionsDescontos[1].value);
      setTipoDescontoJornalOfertas(optionsDescontos[1].value);
      setTipoDescontoDescargoMercadorias(optionsDescontos[1].value);

      setCarregando(false);
    };

    /*useEffect(() => {
      setQuantidadeComprada(0);
      setValorNegociadoComAIndustria(0);
      setBaseDeCalculo(0);
    }, [alvo]);*/

    function calculaDados({
      estadoOrigem, //fornecedor
      estadoDestino, // config
      quantidadeComprada, // input
      valorNegociadoComAIndustria, // input
      irpjPorcentagemLb, // config
      csllPorcentagemLb, // config
      lucroBrutoPorcentagem, // produto
      aliquotaInternaCompraPorcentagem, //fornecedor
      pisCompraPorcentagem, // config
      cofinsCompraPorcentagem, // config
      aliquotaInternaVendaPorcentagem, //fornecedor
      agregadoDoCnaePorcentagem, // produto
      mvaDaPauta, // produto
      margemDeCustoOperacionalPorcentagem, // config
      margemDeCustoDeCartaoPorcentagem, // config
      repasseDoIpi, // input
      repasseDoFreteUnitario, // input
      repasseDeSt, // input
      repasseDeSeguros, // input
      repasseDeOutrasDespesas, // input
      pisVendaPorcentagem, // input
      cofinsVendaPorcentagem, // input
      isAntecipacao,
      isFronteira,
      incluirIcmsDeFronteira,
      incluirIcmsDeAntecipacao,

      //
      icmsAntecipacaoPorcentagemInput,
      icmsFronteiraPorcentagemInput,
      //

      //
      pis_credito,
      is_caso_especial_pis_credito,
      pis_debito,
      is_caso_especial_pis_debito,
      cofins_credito,
      is_caso_especial_cofins_credito,
      cofins_debito,
      is_caso_especial_cofins_debito,
      ipi_credito,
      is_caso_especial_ipi_credito,
      ipi_debito,
      is_caso_especial_ipi_debito,
      icms_normal_credito,
      is_caso_especial_icms_normal_credito,
      icms_normal_debito,
      is_caso_especial_icms_normal_debito,
      icms_fronteira,
      is_caso_especial_icms_fronteira,
      icms_antecipacao,
      is_caso_especial_icms_antecipacao,

      ///

      descontoBacklight,
      descontoBoleto,
      descontoBonificacao,
      descontoDescargoMercadorias,
      descontoJornalOfertas,
      descontoNfe,
    }) {
      //const estadoOrigem = 'Alagoas';
      //const estadoDestino = 'Pernambuco';
      //const quantidadeComprada = 1000; //entrada
      //const valorNegociadoComAIndustria = 100; //entrada
      //const irpjPorcentagemLb = 25; //entrada
      //const csllPorcentagemLb = 9; //entrada
      //const lucroBrutoPorcentagem = 3; //entrada
      //const estado = 'Alagoas'; // entrada
      //const aliquotaInternaCompraPorcentagem = 12; // encontrar de acordo com o estado
      //const pisCompraPorcentagem = 1.65; // entrada
      //const cofinsCompraPorcentagem = 7.6; // entrada
      //const aliquotaInternaVendaPorcentagem = 18; //entrada
      //const agregadoDoCnaePorcentagem = 30; //entrada
      //const mvaDaPauta = 0; //entrada
      //const margemDeCustoOperacionalPorcentagem = 5; //entrada
      //const margemDeCustoDeCartaoPorcentagem = 3; //entrada
      //const repasseDoIpi = 0; //entrada
      //const repasseDoFreteUnitario = 0; //entrada
      //const repasseDeSt = 0; //entrada
      //const repasseDeSeguros = 0; //entrada
      //const repasseDeOutrasDespesas = 0; //entrada

      const icmsAntecipacaoPorcentagem =
        estadoOrigem === estadoDestino
          ? isAntecipacao
            ? icmsAntecipacaoPorcentagemInput
            : 0
          : 0; //entrada

      const custoFinalDoProduto =
        valorNegociadoComAIndustria +
        repasseDoIpi +
        repasseDoFreteUnitario +
        repasseDeSt +
        repasseDeSeguros +
        repasseDeOutrasDespesas;

      const aliquotaInternaCompra =
        valorNegociadoComAIndustria * (aliquotaInternaCompraPorcentagem / 100);

      const pisCompra =
        valorNegociadoComAIndustria * (pisCompraPorcentagem / 100);

      const cofinsCompra =
        valorNegociadoComAIndustria * (cofinsCompraPorcentagem / 100);

      const custoLivreDeImpostoPorcentagem =
        custoFinalDoProduto - aliquotaInternaCompra - pisCompra - cofinsCompra;

      //const pisVendaPorcentagem = pisCompraPorcentagem;

      //const cofinsVendaPorcentagem = cofinsCompraPorcentagem;

      let custosAMais = 0;

      //if (isAntecipacao && incluirIcmsDeAntecipacao) {
      /*if (false) {
        custosAMais += icmsAntecipacaoPorcentagem;
      }*/

      const calculoDaPautaDeIcmsDeFronteira =
        mvaDaPauta > 0
          ? mvaDaPauta * aliquotaInternaVendaPorcentagem - aliquotaInternaCompra
          : 0;

      const icmsAntecipacao =
        (icmsAntecipacaoPorcentagem / 100) * valorNegociadoComAIndustria;
      const icmsAntecipacaoTotal = icmsAntecipacao * quantidadeComprada;

      let icmsAntecipacaoVenda = 0;

      if (isAntecipacao && incluirIcmsDeAntecipacao) {
        custosAMais += icmsAntecipacao;
        icmsAntecipacaoVenda = icmsAntecipacao;
      }

      let fronteira = 0;
      let fronteiraPorcentagem = 0;
      let icmsDeFronteiraAdotado = 0;
      let icmsDeFronteiraAdotadoPorcentagem = 0;
      let icmsDeFronteiraAdotadoTotal = 0;

      if (icmsFronteiraPorcentagemInput < 0) {
        fronteira = isFronteira
          ? estadoOrigem === estadoDestino
            ? 0
            : ((custoFinalDoProduto - aliquotaInternaCompra) /
                (1 - aliquotaInternaVendaPorcentagem / 100)) *
                (1 + agregadoDoCnaePorcentagem / 100) *
                (aliquotaInternaVendaPorcentagem / 100) -
              aliquotaInternaCompra
          : 0;

        fronteiraPorcentagem =
          estadoOrigem === estadoDestino
            ? 0
            : (fronteira / valorNegociadoComAIndustria) * 100;

        icmsDeFronteiraAdotado = isFronteira
          ? fronteira > calculoDaPautaDeIcmsDeFronteira
            ? fronteira
            : calculoDaPautaDeIcmsDeFronteira
          : 0;

        icmsDeFronteiraAdotadoPorcentagem =
          (icmsDeFronteiraAdotado / valorNegociadoComAIndustria) * 100;
        icmsDeFronteiraAdotadoTotal =
          icmsDeFronteiraAdotado * quantidadeComprada;
      } else {
        fronteiraPorcentagem =
          estadoOrigem === estadoDestino ? 0 : icmsFronteiraPorcentagemInput;

        fronteira = (fronteiraPorcentagem / 100) * valorNegociadoComAIndustria;

        icmsDeFronteiraAdotado = fronteira;

        icmsDeFronteiraAdotadoPorcentagem = fronteiraPorcentagem;

        icmsDeFronteiraAdotadoTotal =
          icmsDeFronteiraAdotado * quantidadeComprada;
      }

      let icmsDeFronteiraVenda = 0;

      if (isFronteira && incluirIcmsDeFronteira) {
        custosAMais += icmsDeFronteiraAdotado;
        icmsDeFronteiraVenda = icmsDeFronteiraAdotado;
      }

      //if (isFronteira && incluirIcmsDeFronteira) {
      /*if (false) {
        custosAMais += icmsDeFronteiraAdotadoPorcentagem;
      }*/

      const precoVenda =
        (custoLivreDeImpostoPorcentagem + custosAMais) /
        (1 -
          (aliquotaInternaVendaPorcentagem +
            pisVendaPorcentagem +
            cofinsVendaPorcentagem +
            lucroBrutoPorcentagem +
            margemDeCustoOperacionalPorcentagem +
            margemDeCustoDeCartaoPorcentagem) /
            100);
      const precoVendaTotal = precoVenda * quantidadeComprada;

      const valorNegociadoComAIndustriaPorcentagem =
        (valorNegociadoComAIndustria / precoVenda) * 100;
      const valorNegociadoComAIndustriaTotal =
        valorNegociadoComAIndustria * quantidadeComprada;

      const lucroLiquidoPorcentagem =
        lucroBrutoPorcentagem *
        (1 - (irpjPorcentagemLb + csllPorcentagemLb) / 100);
      const lucroLiquido = precoVenda * (lucroLiquidoPorcentagem / 100);
      const lucroLiquidoTotal = lucroLiquido * quantidadeComprada;

      const lucroBruto = (lucroBrutoPorcentagem / 100) * precoVenda;

      const irpj = (irpjPorcentagemLb / 100) * lucroBruto;
      const irpjPorcentagemPv = (irpj / precoVenda) * 100;
      const irpjTotal = irpj * quantidadeComprada;

      const csll = (csllPorcentagemLb / 100) * lucroBruto;
      const csllPorcentagemPv = (csll / precoVenda) * 100;
      const csllTotal = csll * quantidadeComprada;

      const lucroBrutoTotal = lucroBruto * quantidadeComprada;

      const aliquotaInternaCompraTotal =
        aliquotaInternaCompra * quantidadeComprada;

      const pisCompraTotal = pisCompra * quantidadeComprada;

      const cofinsCompraTotal = cofinsCompra * quantidadeComprada;

      const totalDeCreditosCompra =
        aliquotaInternaCompra + pisCompra + cofinsCompra;
      const totalDeCreditosCompraPorcentagem =
        (totalDeCreditosCompra / precoVenda) * 100;
      const totalDeCreditosCompraTotal =
        totalDeCreditosCompra * quantidadeComprada;

      const aliquotaInternaVenda =
        (aliquotaInternaVendaPorcentagem / 100) * precoVenda;
      const aliquotaInternaVendaTotal =
        aliquotaInternaVenda * quantidadeComprada;

      const pisVenda = (pisVendaPorcentagem / 100) * precoVenda;
      const pisVendaTotal = pisVenda * quantidadeComprada;

      const cofinsVenda = (cofinsVendaPorcentagem / 100) * precoVenda;
      const cofinsVendaTotal = cofinsVenda * quantidadeComprada;

      const totalDeDebitosVenda = aliquotaInternaVenda + pisVenda + cofinsVenda;
      const totalDeDebitosVendaPorcentagem =
        (totalDeDebitosVenda / precoVenda) * 100;
      const totalDeDebitosVendaTotal = totalDeDebitosVenda * quantidadeComprada;

      const totalDeIcmsAntecipado = icmsAntecipacao + icmsDeFronteiraAdotado;
      const totalDeIcmsAntecipadoPorcentagem =
        (totalDeIcmsAntecipado / precoVenda) * 100;
      const totalDeIcmsAntecipadoTotal =
        totalDeIcmsAntecipado * quantidadeComprada;

      const diferencaDeIcms = aliquotaInternaVenda - aliquotaInternaCompra;
      const diferencaDeIcmsPorcentagem = (diferencaDeIcms / precoVenda) * 100;
      const diferencaDeIcmsTotal = diferencaDeIcms * quantidadeComprada;

      const diferencaDePis = pisVenda - pisCompra;
      const diferencaDePisPorcentagem = (diferencaDePis / precoVenda) * 100;
      const diferencaDePisTotal = diferencaDePis * quantidadeComprada;

      const diferencaDeCofins = cofinsVenda - cofinsCompra;
      const diferencaDeCofinsPorcentagem =
        (diferencaDeCofins / precoVenda) * 100;
      const diferencaDeCofinsTotal = diferencaDeCofins * quantidadeComprada;

      const tributacaoEfetiva = totalDeDebitosVenda - totalDeCreditosCompra;
      const tributacaoEfetivaPorcentagem =
        (tributacaoEfetiva / precoVenda) * 100;
      const tributacaoEfetivaTotal = tributacaoEfetiva * quantidadeComprada;

      const resultadoDoFluxoDeCaixa = tributacaoEfetiva + totalDeIcmsAntecipado;
      const resultadoDoFluxoDeCaixaPorcentagem =
        (resultadoDoFluxoDeCaixa / precoVenda) * 100;
      const resultadoDoFluxoDeCaixaTotal =
        resultadoDoFluxoDeCaixa * quantidadeComprada;

      const margemDeCustoOperacional =
        (margemDeCustoOperacionalPorcentagem / 100) * precoVenda;
      const margemDeCustoOperacionalTotal =
        margemDeCustoOperacional * quantidadeComprada;

      const margemDeCustoDeCartao =
        (margemDeCustoDeCartaoPorcentagem / 100) * precoVenda;
      const margemDeCustoDeCartaoTotal =
        margemDeCustoDeCartao * quantidadeComprada;

      const repasseDoIpiPorcentagem =
        (repasseDoIpi / valorNegociadoComAIndustria) * 100;
      const repasseDoIpiTotal = repasseDoIpi * quantidadeComprada;

      const repasseDoFreteUnitarioPorcentagem =
        (repasseDoFreteUnitario / valorNegociadoComAIndustria) * 100;
      const repasseDoFreteUnitarioTotal =
        repasseDoFreteUnitario * quantidadeComprada;

      const repasseDeStPorcentagem =
        (repasseDeSt / valorNegociadoComAIndustria) * 100;
      const repasseDeStTotal = repasseDeSt * quantidadeComprada;

      const repasseDeSegurosPorcentagem =
        (repasseDeSeguros / valorNegociadoComAIndustria) * 100;
      const repasseDeSegurosTotal = repasseDeSeguros * quantidadeComprada;

      const repasseDeOutrasDespesasPorcentagem =
        (repasseDeOutrasDespesas / valorNegociadoComAIndustria) * 100;
      const repasseDeOutrasDespesasTotal =
        repasseDeOutrasDespesas * quantidadeComprada;

      ///////////////////////////////////////////

      const descontoNfePorcentagem =
        (descontoNfe / valorNegociadoComAIndustria) * 100;
      const descontoNfeTotal = descontoNfe * quantidadeComprada;

      const descontoBoletoPorcentagem =
        (descontoBoleto / valorNegociadoComAIndustria) * 100;
      const descontoBoletoTotal = descontoBoleto * quantidadeComprada;

      const descontoBonificacaoPorcentagem =
        (descontoBonificacao / valorNegociadoComAIndustria) * 100;
      const descontoBonificacaoTotal = descontoBonificacao * quantidadeComprada;

      const descontoJornalOfertasPorcentagem =
        (descontoJornalOfertas / valorNegociadoComAIndustria) * 100;
      const descontoJornalOfertasTotal =
        descontoJornalOfertas * quantidadeComprada;

      const descontoDescargoMercadoriasPorcentagem =
        (descontoDescargoMercadorias / valorNegociadoComAIndustria) * 100;
      const descontoDescargoMercadoriasTotal =
        descontoDescargoMercadorias * quantidadeComprada;

      const descontoBacklightPorcentagem =
        (descontoBacklight / valorNegociadoComAIndustria) * 100;
      const descontoBacklightTotal = descontoBacklight * quantidadeComprada;

      ///////////////////////////////////////////

      const totalDeCustosDeRepasses =
        repasseDoIpi +
        repasseDoFreteUnitario +
        repasseDeSt +
        repasseDeSeguros +
        repasseDeOutrasDespesas;
      const totalDeCustosDeRepassesPorcentagem =
        (totalDeCustosDeRepasses / precoVenda) * 100;
      const totalDeCustosDeRepassesTotal =
        totalDeCustosDeRepasses * quantidadeComprada;

      const custoFinalDoProdutoPorcentagem =
        (custoFinalDoProduto / precoVenda) * 100;
      const custoFinalDoProdutoTotal = custoFinalDoProduto * quantidadeComprada;

      ///

      const icmsAntecipacaoPorcentagemVenda =
        (icmsAntecipacaoVenda / precoVenda) * 100;

      const icmsDeFronteiraAdotadoPorcentagemVenda =
        (icmsDeFronteiraVenda / precoVenda) * 100;

      ///

      const conferenciaDoGraficoDeSecaoCircular =
        valorNegociadoComAIndustriaPorcentagem +
        margemDeCustoOperacionalPorcentagem +
        diferencaDeIcmsPorcentagem +
        lucroBrutoPorcentagem +
        diferencaDeCofinsPorcentagem +
        margemDeCustoDeCartaoPorcentagem +
        diferencaDePisPorcentagem +
        totalDeCustosDeRepassesPorcentagem +
        icmsAntecipacaoPorcentagemVenda +
        icmsDeFronteiraAdotadoPorcentagemVenda;

      const margemTotal = precoVenda - valorNegociadoComAIndustria;
      const margemTotalPorcentagem =
        (margemTotal / valorNegociadoComAIndustria) * 100;
      const margemTotalTotal = margemTotal * quantidadeComprada;

      const dataDeValidade = '';

      ////////////

      const repasseDoIpiPorcentagemVenda = repasseDoIpi;

      const repasseDoFreteUnitarioPorcentagemVenda = repasseDoFreteUnitario;

      const repasseDeStPorcentagemVenda = repasseDeSt;

      const repasseDeSegurosPorcentagemVenda = repasseDeSeguros;

      const repasseDeOutrasDespesasPorcentagemVenda = repasseDeOutrasDespesas;

      /////////////

      return {
        estadoOrigem,
        estadoDestino,
        valorNegociadoComAIndustriaPorcentagem,
        valorNegociadoComAIndustria,
        valorNegociadoComAIndustriaTotal,
        custoLivreDeImpostoPorcentagem,
        lucroLiquidoPorcentagem,
        lucroLiquido,
        lucroLiquidoTotal,
        irpjPorcentagemPv,
        irpjPorcentagemLb,
        irpj,
        irpjTotal,
        csllPorcentagemPv,
        csllPorcentagemLb,
        csll,
        csllTotal,
        lucroBrutoPorcentagem,
        lucroBruto,
        lucroBrutoTotal,
        precoVenda:
          precoVenda -
          (descontoBacklight +
            descontoBoleto +
            descontoBonificacao +
            descontoDescargoMercadorias +
            descontoJornalOfertas +
            descontoNfe),
        //precoVenda,
        precoVendaTotal,
        aliquotaInternaCompraPorcentagem,
        aliquotaInternaCompra,
        aliquotaInternaCompraTotal,
        pisCompraPorcentagem,
        pisCompra,
        pisCompraTotal,
        cofinsCompraPorcentagem,
        cofinsCompra,
        cofinsCompraTotal,
        totalDeCreditosCompraPorcentagem,
        totalDeCreditosCompra,
        totalDeCreditosCompraTotal,
        aliquotaInternaVendaPorcentagem,
        aliquotaInternaVenda,
        aliquotaInternaVendaTotal,
        pisVendaPorcentagem,
        pisVenda,
        pisVendaTotal,
        cofinsVendaPorcentagem,
        cofinsVenda,
        cofinsVendaTotal,
        totalDeDebitosVendaPorcentagem,
        totalDeDebitosVenda,
        totalDeDebitosVendaTotal,
        icmsAntecipacaoPorcentagem,
        icmsAntecipacao,
        icmsAntecipacaoTotal,
        agregadoDoCnaePorcentagem,
        mvaDaPauta,
        calculoDaPautaDeIcmsDeFronteira,
        fronteiraPorcentagem,
        fronteira,
        icmsDeFronteiraAdotadoPorcentagem,
        icmsDeFronteiraAdotado,
        icmsDeFronteiraAdotadoTotal,
        totalDeIcmsAntecipadoPorcentagem,
        totalDeIcmsAntecipado,
        totalDeIcmsAntecipadoTotal,
        diferencaDeIcmsPorcentagem,
        diferencaDeIcms,
        diferencaDeIcmsTotal,
        diferencaDePisPorcentagem,
        diferencaDePis,
        diferencaDePisTotal,
        diferencaDeCofinsPorcentagem,
        diferencaDeCofins,
        diferencaDeCofinsTotal,
        tributacaoEfetivaPorcentagem,
        tributacaoEfetiva,
        tributacaoEfetivaTotal,
        resultadoDoFluxoDeCaixaPorcentagem,
        resultadoDoFluxoDeCaixa,
        resultadoDoFluxoDeCaixaTotal,
        margemDeCustoOperacionalPorcentagem,
        margemDeCustoOperacional,
        margemDeCustoOperacionalTotal,
        margemDeCustoDeCartaoPorcentagem,
        margemDeCustoDeCartao,
        margemDeCustoDeCartaoTotal,
        repasseDoIpiPorcentagem,
        repasseDoIpi,
        repasseDoIpiTotal,
        repasseDoFreteUnitarioPorcentagem,
        repasseDoFreteUnitario,
        repasseDoFreteUnitarioTotal,
        repasseDeStPorcentagem,
        repasseDeSt,
        repasseDeStTotal,
        repasseDeSegurosPorcentagem,
        repasseDeSeguros,
        repasseDeSegurosTotal,
        repasseDeOutrasDespesasPorcentagem,
        repasseDeOutrasDespesas,
        repasseDeOutrasDespesasTotal,
        totalDeCustosDeRepassesPorcentagem,
        totalDeCustosDeRepasses,
        totalDeCustosDeRepassesTotal,
        custoFinalDoProdutoPorcentagem,
        custoFinalDoProduto,
        custoFinalDoProdutoTotal,
        conferenciaDoGraficoDeSecaoCircular,
        margemTotalPorcentagem,
        margemTotal,
        margemTotalTotal,
        dataDeValidade,
        quantidadeComprada,
        //
        repasseDoIpiPorcentagemVenda,
        repasseDoFreteUnitarioPorcentagemVenda,
        repasseDeStPorcentagemVenda,
        repasseDeSegurosPorcentagemVenda,
        repasseDeOutrasDespesasPorcentagemVenda,
        //
        icmsAntecipacaoPorcentagemVenda: icmsAntecipacaoVenda,
        icmsDeFronteiraAdotadoPorcentagemVenda: icmsDeFronteiraVenda,
        descontoBacklight,
        descontoBoleto,
        descontoBonificacao,
        descontoDescargoMercadorias,
        descontoJornalOfertas,
        descontoNfe,
        //
        descontoNfePorcentagem,
        descontoNfeTotal,
        descontoBoletoPorcentagem,
        descontoBoletoTotal,
        descontoBonificacaoPorcentagem,
        descontoBonificacaoTotal,
        descontoJornalOfertasPorcentagem,
        descontoJornalOfertasTotal,
        descontoDescargoMercadoriasPorcentagem,
        descontoDescargoMercadoriasTotal,
        descontoBacklightPorcentagem,
        descontoBacklightTotal,
        descontoTotal:
          descontoBacklight +
          descontoBoleto +
          descontoBonificacao +
          descontoDescargoMercadorias +
          descontoJornalOfertas +
          descontoNfe,
        ////
        descontoTotalTotal:
          descontoNfeTotal +
          descontoBoletoTotal +
          descontoBonificacaoTotal +
          descontoJornalOfertasTotal +
          descontoDescargoMercadoriasTotal +
          descontoBacklightTotal,
        //
        descontoPorcentagem:
          descontoNfePorcentagem +
          descontoBoletoPorcentagem +
          descontoBonificacaoPorcentagem +
          descontoJornalOfertasPorcentagem +
          descontoDescargoMercadoriasPorcentagem +
          descontoBacklightPorcentagem,
      };
    }

    function formataDados(entradas) {
      const dados = calculaDados(entradas);
      return {
        estadoOrigem: dados.estadoOrigem,
        estadoDestino: dados.estadoDestino,
        valorNegociadoComAIndustriaPorcentagem: formatPorcentagem(
          dados.valorNegociadoComAIndustriaPorcentagem,
        ),
        valorNegociadoComAIndustria: formatMoeda(
          dados.valorNegociadoComAIndustria,
        ),
        valorNegociadoComAIndustriaTotal: formatMoeda(
          dados.valorNegociadoComAIndustriaTotal,
        ),
        custoLivreDeImpostoPorcentagem: formatMoeda(
          dados.custoLivreDeImpostoPorcentagem,
        ),
        lucroLiquidoPorcentagem: formatPorcentagem(
          dados.lucroLiquidoPorcentagem,
        ),
        lucroLiquido: formatMoeda(dados.lucroLiquido),
        lucroLiquidoTotal: formatMoeda(dados.lucroLiquidoTotal),
        irpjPorcentagemPv: formatPorcentagem(dados.irpjPorcentagemPv),
        irpjPorcentagemLb: formatPorcentagem(dados.irpjPorcentagemLb),
        irpj: formatMoeda(dados.irpj),
        irpjTotal: formatMoeda(dados.irpjTotal),
        csllPorcentagemPv: formatPorcentagem(dados.csllPorcentagemPv),
        csllPorcentagemLb: formatPorcentagem(dados.csllPorcentagemLb),
        csll: formatMoeda(dados.csll),
        csllTotal: formatMoeda(dados.csllTotal),
        lucroBrutoPorcentagem: formatPorcentagem(dados.lucroBrutoPorcentagem),
        lucroBruto: formatMoeda(dados.lucroBruto),
        lucroBrutoTotal: formatMoeda(dados.lucroBrutoTotal),
        precoVenda: formatMoeda(dados.precoVenda),
        precoVendaTotal: formatMoeda(dados.precoVendaTotal),
        aliquotaInternaCompraPorcentagem: formatPorcentagem(
          dados.aliquotaInternaCompraPorcentagem,
        ),
        aliquotaInternaCompra: formatMoeda(dados.aliquotaInternaCompra),
        aliquotaInternaCompraTotal: formatMoeda(
          dados.aliquotaInternaCompraTotal,
        ),
        pisCompraPorcentagem: formatPorcentagem(dados.pisCompraPorcentagem),
        pisCompra: formatMoeda(dados.pisCompra),
        pisCompraTotal: formatMoeda(dados.pisCompraTotal),
        cofinsCompraPorcentagem: formatPorcentagem(
          dados.cofinsCompraPorcentagem,
        ),
        cofinsCompra: formatMoeda(dados.cofinsCompra),
        cofinsCompraTotal: formatMoeda(dados.cofinsCompraTotal),
        totalDeCreditosCompraPorcentagem: formatPorcentagem(
          dados.totalDeCreditosCompraPorcentagem,
        ),
        totalDeCreditosCompra: formatMoeda(dados.totalDeCreditosCompra),
        totalDeCreditosCompraTotal: formatMoeda(
          dados.totalDeCreditosCompraTotal,
        ),
        aliquotaInternaVendaPorcentagem: formatPorcentagem(
          dados.aliquotaInternaVendaPorcentagem,
        ),
        aliquotaInternaVenda: formatMoeda(dados.aliquotaInternaVenda),
        aliquotaInternaVendaTotal: formatMoeda(dados.aliquotaInternaVendaTotal),
        pisVendaPorcentagem: formatPorcentagem(dados.pisVendaPorcentagem),
        pisVenda: formatMoeda(dados.pisVenda),
        pisVendaTotal: formatMoeda(dados.pisVendaTotal),
        cofinsVendaPorcentagem: formatPorcentagem(dados.cofinsVendaPorcentagem),
        cofinsVenda: formatMoeda(dados.cofinsVenda),
        cofinsVendaTotal: formatMoeda(dados.cofinsVendaTotal),
        totalDeDebitosVendaPorcentagem: formatPorcentagem(
          dados.totalDeDebitosVendaPorcentagem,
        ),
        totalDeDebitosVenda: formatMoeda(dados.totalDeDebitosVenda),
        totalDeDebitosVendaTotal: formatMoeda(dados.totalDeDebitosVendaTotal),
        icmsAntecipacaoPorcentagem: formatPorcentagem(
          dados.icmsAntecipacaoPorcentagem,
        ),
        icmsAntecipacao: formatMoeda(dados.icmsAntecipacao),
        icmsAntecipacaoTotal: formatMoeda(dados.icmsAntecipacaoTotal),
        agregadoDoCnaePorcentagem: formatPorcentagem(
          dados.agregadoDoCnaePorcentagem,
        ),
        mvaDaPauta: formatMoeda(dados.mvaDaPauta),
        calculoDaPautaDeIcmsDeFronteira: formatMoeda(
          dados.calculoDaPautaDeIcmsDeFronteira,
        ),
        fronteiraPorcentagem: formatPorcentagem(dados.fronteiraPorcentagem),
        fronteira: formatMoeda(dados.fronteira),
        icmsDeFronteiraAdotadoPorcentagem: formatPorcentagem(
          dados.icmsDeFronteiraAdotadoPorcentagem,
        ),
        icmsDeFronteiraAdotado: formatMoeda(dados.icmsDeFronteiraAdotado),
        icmsDeFronteiraAdotadoTotal: formatMoeda(
          dados.icmsDeFronteiraAdotadoTotal,
        ),
        totalDeIcmsAntecipadoPorcentagem: formatPorcentagem(
          dados.totalDeIcmsAntecipadoPorcentagem,
        ),
        totalDeIcmsAntecipado: formatMoeda(dados.totalDeIcmsAntecipado),
        totalDeIcmsAntecipadoTotal: formatMoeda(
          dados.totalDeIcmsAntecipadoTotal,
        ),
        diferencaDeIcmsPorcentagem: formatPorcentagem(
          dados.diferencaDeIcmsPorcentagem,
        ),
        diferencaDeIcms: formatMoeda(dados.diferencaDeIcms),
        diferencaDeIcmsTotal: formatMoeda(dados.diferencaDeIcmsTotal),
        diferencaDePisPorcentagem: formatPorcentagem(
          dados.diferencaDePisPorcentagem,
        ),
        diferencaDePis: formatMoeda(dados.diferencaDePis),
        diferencaDePisTotal: formatMoeda(dados.diferencaDePisTotal),
        diferencaDeCofinsPorcentagem: formatPorcentagem(
          dados.diferencaDeCofinsPorcentagem,
        ),
        diferencaDeCofins: formatMoeda(dados.diferencaDeCofins),
        diferencaDeCofinsTotal: formatMoeda(dados.diferencaDeCofinsTotal),
        tributacaoEfetivaPorcentagem: formatPorcentagem(
          dados.tributacaoEfetivaPorcentagem,
        ),
        tributacaoEfetiva: formatMoeda(dados.tributacaoEfetiva),
        tributacaoEfetivaTotal: formatMoeda(dados.tributacaoEfetivaTotal),
        resultadoDoFluxoDeCaixaPorcentagem: formatPorcentagem(
          dados.resultadoDoFluxoDeCaixaPorcentagem,
        ),
        resultadoDoFluxoDeCaixa: formatMoeda(dados.resultadoDoFluxoDeCaixa),
        resultadoDoFluxoDeCaixaTotal: formatMoeda(
          dados.resultadoDoFluxoDeCaixaTotal,
        ),
        margemDeCustoOperacionalPorcentagem: formatPorcentagem(
          dados.margemDeCustoOperacionalPorcentagem,
        ),
        margemDeCustoOperacional: formatMoeda(dados.margemDeCustoOperacional),
        margemDeCustoOperacionalTotal: formatMoeda(
          dados.margemDeCustoOperacionalTotal,
        ),
        margemDeCustoDeCartaoPorcentagem: formatPorcentagem(
          dados.margemDeCustoDeCartaoPorcentagem,
        ),
        margemDeCustoDeCartao: formatMoeda(dados.margemDeCustoDeCartao),
        margemDeCustoDeCartaoTotal: formatMoeda(
          dados.margemDeCustoDeCartaoTotal,
        ),
        repasseDoIpiPorcentagem: formatPorcentagem(
          dados.repasseDoIpiPorcentagem,
        ),
        repasseDoIpi: formatMoeda(dados.repasseDoIpi),
        repasseDoIpiTotal: formatMoeda(dados.repasseDoIpiTotal),
        repasseDoFreteUnitarioPorcentagem: formatPorcentagem(
          dados.repasseDoFreteUnitarioPorcentagem,
        ),
        repasseDoFreteUnitario: formatMoeda(dados.repasseDoFreteUnitario),
        repasseDoFreteUnitarioTotal: formatMoeda(
          dados.repasseDoFreteUnitarioTotal,
        ),
        repasseDeStPorcentagem: formatPorcentagem(dados.repasseDeStPorcentagem),
        repasseDeSt: formatMoeda(dados.repasseDeSt),
        repasseDeStTotal: formatMoeda(dados.repasseDeStTotal),
        repasseDeSegurosPorcentagem: formatPorcentagem(
          dados.repasseDeSegurosPorcentagem,
        ),
        repasseDeSeguros: formatMoeda(dados.repasseDeSeguros),
        repasseDeSegurosTotal: formatMoeda(dados.repasseDeSegurosTotal),
        repasseDeOutrasDespesasPorcentagem: formatPorcentagem(
          dados.repasseDeOutrasDespesasPorcentagem,
        ),
        repasseDeOutrasDespesas: formatMoeda(dados.repasseDeOutrasDespesas),
        repasseDeOutrasDespesasTotal: formatMoeda(
          dados.repasseDeOutrasDespesasTotal,
        ),
        totalDeCustosDeRepassesPorcentagem: formatPorcentagem(
          dados.totalDeCustosDeRepassesPorcentagem,
        ),
        totalDeCustosDeRepasses: formatMoeda(dados.totalDeCustosDeRepasses),
        totalDeCustosDeRepassesTotal: formatMoeda(
          dados.totalDeCustosDeRepassesTotal,
        ),
        custoFinalDoProdutoPorcentagem: formatPorcentagem(
          dados.custoFinalDoProdutoPorcentagem,
        ),
        custoFinalDoProduto: formatMoeda(dados.custoFinalDoProduto),
        custoFinalDoProdutoTotal: formatMoeda(dados.custoFinalDoProdutoTotal),
        conferenciaDoGraficoDeSecaoCircular: formatPorcentagem(
          dados.conferenciaDoGraficoDeSecaoCircular,
        ),
        margemTotalPorcentagem: formatPorcentagem(dados.margemTotalPorcentagem),
        margemTotal: formatMoeda(dados.margemTotal),
        margemTotalTotal: formatMoeda(dados.margemTotalTotal),
        dataDeValidade: dados.dataDeValidade,
        quantidadeComprada: dados.quantidadeComprada,
        descontoTotal: formatMoeda(dados.descontoTotal),
        descontoPorcentagem: formatPorcentagem(dados.descontoPorcentagem),
        descontoTotalTotal: formatMoeda(dados.descontoTotalTotal),
        dadosOriginais: dados,
        //
        descontoBacklight: formatMoeda(dados.descontoBacklight),
        descontoBoleto: formatMoeda(dados.descontoBoleto),
        descontoBonificacao: formatMoeda(dados.descontoBonificacao),
        descontoDescargoMercadorias: formatMoeda(
          dados.descontoDescargoMercadorias,
        ),
        descontoJornalOfertas: formatMoeda(dados.descontoJornalOfertas),
        descontoNfe: formatMoeda(dados.descontoNfe),
        //
        descontoNfePorcentagem: formatPorcentagem(dados.descontoNfePorcentagem),
        descontoNfeTotal: formatMoeda(dados.descontoNfeTotal),
        descontoBoletoPorcentagem: formatPorcentagem(
          dados.descontoBoletoPorcentagem,
        ),
        descontoBoletoTotal: formatMoeda(dados.descontoBoletoTotal),
        descontoBonificacaoPorcentagem: formatPorcentagem(
          dados.descontoBonificacaoPorcentagem,
        ),
        descontoBonificacaoTotal: formatMoeda(dados.descontoBonificacaoTotal),
        descontoJornalOfertasPorcentagem: formatPorcentagem(
          dados.descontoJornalOfertasPorcentagem,
        ),
        descontoJornalOfertasTotal: formatMoeda(
          dados.descontoJornalOfertasTotal,
        ),
        descontoDescargoMercadoriasPorcentagem: formatPorcentagem(
          dados.descontoDescargoMercadoriasPorcentagem,
        ),
        descontoDescargoMercadoriasTotal: formatMoeda(
          dados.descontoDescargoMercadoriasTotal,
        ),
        descontoBacklightPorcentagem: formatPorcentagem(
          dados.descontoBacklightPorcentagem,
        ),
        descontoBacklightTotal: formatMoeda(dados.descontoBacklightTotal),
      };
    }

    const linhas = [];
    /*linhas.push(
    formataDados({
      estadoOrigem: 'Alagoas',
      estadoDestino: 'Pernambuco',
      quantidadeComprada: 1000,
      valorNegociadoComAIndustria: 100,
      irpjPorcentagemLb: 25,
      csllPorcentagemLb: 9,
      lucroBrutoPorcentagem: 3,
      aliquotaInternaCompraPorcentagem: 12,
      pisCompraPorcentagem: 1.65,
      cofinsCompraPorcentagem: 7.6,
      aliquotaInternaVendaPorcentagem: 18,
      agregadoDoCnaePorcentagem: 30,
      mvaDaPauta: 0,
      margemDeCustoOperacionalPorcentagem: 5,
      margemDeCustoDeCartaoPorcentagem: 3,
      repasseDoIpi: 0,
      repasseDoFreteUnitario: 0,
      repasseDeSt: 0,
      repasseDeSeguros: 0,
      repasseDeOutrasDespesas: 0,
    }),
  );*/

    function getValorRepasse(
      tipoRepasse,
      repasseValue,
      qtdeRepasse,
      baseDeCalculoComputado,
    ) {
      switch (tipoRepasse) {
        case optionsRepassesUnitarios[0].value:
          return repasseValue;
        case optionsRepassesUnitarios[1].value:
          return repasseValue / qtdeRepasse;
        case optionsRepassesUnitarios[2].value:
          return ((repasseValue / 100) * baseDeCalculoComputado) / qtdeRepasse;
        default:
          return 0;
      }
    }

    function getDadosRepassesComputado() {
      switch (alvo) {
        case optionsAlvo[0].value: {
          const baseDeCalculoComputado = baseDeCalculo;
          const qtdeRepasse = getQuantidadeRepasse(
            quantidadeComprada,
            produto ? produto.peso_medio : 0,
            produto ? produto.unidade.tipo : '',
          );
          return {
            valorNegociadoComAIndustriaComputado: calculaValorNegociadoComAIndustria(
              baseDeCalculo,
              quantidadeComprada,
              produto ? produto.peso_medio : 0,
              produto ? produto.unidade.tipo : '',
            ),
            quantidadeCompradaConputado: quantidadeComprada,
            baseDeCalculoComputado,
            repasseDoIpiComputado: getValorRepasse(
              tipoRepasseDoIpi,
              repasseDoIpi,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
            repasseDoFreteUnitarioComputado: getValorRepasse(
              tipoRepasseDoFrete,
              repasseDoFreteUnitario,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
            repasseDeStComputado: getValorRepasse(
              tipoRepasseDoSt,
              repasseDeSt,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
            repasseDeSegurosComputado: getValorRepasse(
              tipoRepasseDoSeguros,
              repasseDeSeguros,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
            repasseDeOutrasDespesasComputado: getValorRepasse(
              tipoRepasseDoOutrasDespesas,
              repasseDeOutrasDespesas,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
          };
        }
        case optionsAlvo[1].value: {
          const baseDeCalculoComputado = calculaBaseDeCalculo(
            valorNegociadoComAIndustria,
            quantidadeComprada,
            produto ? produto.peso_medio : 0,
            produto ? produto.unidade.tipo : '',
          );
          const qtdeRepasse = getQuantidadeRepasse(
            quantidadeComprada,
            produto ? produto.peso_medio : 0,
            produto ? produto.unidade.tipo : '',
          );
          return {
            valorNegociadoComAIndustriaComputado: valorNegociadoComAIndustria,
            quantidadeCompradaConputado: quantidadeComprada,
            baseDeCalculoComputado,
            repasseDoIpiComputado: getValorRepasse(
              tipoRepasseDoIpi,
              repasseDoIpi,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
            repasseDoFreteUnitarioComputado: getValorRepasse(
              tipoRepasseDoFrete,
              repasseDoFreteUnitario,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
            repasseDeStComputado: getValorRepasse(
              tipoRepasseDoSt,
              repasseDeSt,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
            repasseDeSegurosComputado: getValorRepasse(
              tipoRepasseDoSeguros,
              repasseDeSeguros,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
            repasseDeOutrasDespesasComputado: getValorRepasse(
              tipoRepasseDoOutrasDespesas,
              repasseDeOutrasDespesas,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
          };
        }
        case optionsAlvo[2].value: {
          const baseDeCalculoComputado = baseDeCalculo;
          const quantidadeCompradaConputado = calculaQuantidadeComprada(
            valorNegociadoComAIndustria,
            baseDeCalculo,
            produto ? produto.peso_medio : 0,
            produto ? produto.unidade.tipo : '',
          );
          const qtdeRepasse = getQuantidadeRepasse(
            quantidadeCompradaConputado,
            produto ? produto.peso_medio : 0,
            produto ? produto.unidade.tipo : '',
          );
          return {
            valorNegociadoComAIndustriaComputado: valorNegociadoComAIndustria,
            quantidadeCompradaConputado,
            baseDeCalculoComputado,
            repasseDoIpiComputado: getValorRepasse(
              tipoRepasseDoIpi,
              repasseDoIpi,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
            repasseDoFreteUnitarioComputado: getValorRepasse(
              tipoRepasseDoFrete,
              repasseDoFreteUnitario,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
            repasseDeStComputado: getValorRepasse(
              tipoRepasseDoSt,
              repasseDeSt,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
            repasseDeSegurosComputado: getValorRepasse(
              tipoRepasseDoSeguros,
              repasseDeSeguros,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
            repasseDeOutrasDespesasComputado: getValorRepasse(
              tipoRepasseDoOutrasDespesas,
              repasseDeOutrasDespesas,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
          };
        }
        default: {
          return {
            valorNegociadoComAIndustriaComputado: 0,
            quantidadeCompradaConputado: 0,
            baseDeCalculoComputado: 0,
            repasseDoIpiComputado: 0,
            repasseDoFreteUnitarioComputado: 0,
            repasseDeStComputado: 0,
            repasseDeSegurosComputado: 0,
            repasseDeOutrasDespesasComputado: 0,
          };
        }
      }
    }

    function getValorDesconto(tipo, value, qtde) {
      switch (tipo) {
        case optionsDescontos[0].value:
          return value;
        case optionsDescontos[1].value:
          return value / qtde;
        default:
          return 0;
      }
    }

    function getDescontosComputados() {
      const qtdeRepasse = getQuantidadeRepasse(
        quantidadeComprada,
        produto ? produto.peso_medio : 0,
        produto ? produto.unidade.tipo : '',
      );

      return {
        descontoNfeComputado: getValorDesconto(
          tipoDescontoNfe,
          descontoNfe,
          qtdeRepasse,
        ),
        descontoBoletoComputado: getValorDesconto(
          tipoDescontoBoleto,
          descontoBoleto,
          qtdeRepasse,
        ),
        descontoBonificacaoComputado: getValorDesconto(
          tipoDescontoBonificacao,
          descontoBonificacao,
          qtdeRepasse,
        ),
        descontoBacklightComputado: getValorDesconto(
          tipoDescontoBacklight,
          descontoBacklight,
          qtdeRepasse,
        ),
        descontoJornalOfertasComputado: getValorDesconto(
          tipoDescontoJornalOfertas,
          descontoJornalOfertas,
          qtdeRepasse,
        ),
        descontoDescargoMercadoriasComputado: getValorDesconto(
          tipoDescontoDescargoMercadorias,
          descontoDescargoMercadorias,
          qtdeRepasse,
        ),
      };
    }

    function getMessageAviso() {
      if (fornecedor && produto && config) {
        const isSt = repasseDeStComputado > 0;
        switch (fornecedor.regime_tributario) {
          case REGIME_TRIBUTARIO_PRODUTOR_RURAL: {
            return 'Devido ao fato deste fornecedor ser "PRODUTOR RURAL" os créditos de PIS, COFINS e ICMS são nulos (exceto para os casos particulares, quando especificado no produto)!';
          }
          case REGIME_TRIBUTARIO_MEI: {
            return 'Devido ao fato deste fornecedor ser "MEI" o crédito de ICMS é nulo (exceto para os casos particulares, quando especificado no produto)!';
          }
          case REGIME_TRIBUTARIO_SIMPLES_NACIONAL: {
            return 'Devido ao fato deste fornecedor ser "SIMPLES NACIONAL" o crédito de ICMS é nulo (exceto para os casos particulares, quando especificado no produto)!';
          }
          case REGIME_TRIBUTARIO_LUCRO_PRESUMIDO: {
            return '';
          }
          case REGIME_TRIBUTARIO_LUCRO_REAL: {
            return '';
          }
          case REGIME_TRIBUTARIO_SEM_NFE: {
            return 'Devido ao fato deste fornecedor ser "SEM NFE" todos os impostos são nulos (exceto para os casos particulares, quando especificado no produto)!';
          }
          default: {
            return '';
          }
        }
      } else {
        return '';
      }
    }

    function getMessageAviso2() {
      const isSt = repasseDeStComputado > 0;
      if (isSt) {
        return 'Devido ao fato de haver "Repasse Unitário de Substituição Tributária" o crédito e débito de ICMS são nulos (exceto para os casos particulares, quando especificado no produto)!';
      }
      return '';
    }

    const {
      baseDeCalculoComputado,
      quantidadeCompradaConputado,
      valorNegociadoComAIndustriaComputado,
      repasseDeOutrasDespesasComputado,
      repasseDeSegurosComputado,
      repasseDeStComputado,
      repasseDoFreteUnitarioComputado,
      repasseDoIpiComputado,
    } = getDadosRepassesComputado();

    const {
      descontoBacklightComputado,
      descontoBoletoComputado,
      descontoBonificacaoComputado,
      descontoDescargoMercadoriasComputado,
      descontoJornalOfertasComputado,
      descontoNfeComputado,
    } = getDescontosComputados();

    function getDadosEspeciais(produto) {
      return {
        pis_credito: produto.dadosImposto[0].pis_credito,
        is_caso_especial_pis_credito:
          produto.dadosImposto[0].is_caso_especial_pis_credito,
        pis_debito: produto.dadosImposto[0].pis_debito,
        is_caso_especial_pis_debito:
          produto.dadosImposto[0].is_caso_especial_pis_debito,
        cofins_credito: produto.dadosImposto[0].cofins_credito,
        is_caso_especial_cofins_credito:
          produto.dadosImposto[0].is_caso_especial_cofins_credito,
        cofins_debito: produto.dadosImposto[0].cofins_debito,
        is_caso_especial_cofins_debito:
          produto.dadosImposto[0].is_caso_especial_cofins_debito,
        ipi_credito: produto.dadosImposto[0].ipi_credito,
        is_caso_especial_ipi_credito:
          produto.dadosImposto[0].is_caso_especial_ipi_credito,
        ipi_debito: produto.dadosImposto[0].ipi_debito,
        is_caso_especial_ipi_debito:
          produto.dadosImposto[0].is_caso_especial_ipi_debito,
        icms_normal_credito: produto.dadosImposto[0].icms_normal_credito,
        is_caso_especial_icms_normal_credito:
          produto.dadosImposto[0].is_caso_especial_icms_normal_credito,
        icms_normal_debito: produto.dadosImposto[0].icms_normal_debito,
        is_caso_especial_icms_normal_debito:
          produto.dadosImposto[0].is_caso_especial_icms_normal_debito,
        icms_fronteira: produto.dadosImposto[0].icms_fronteira,
        is_caso_especial_icms_fronteira:
          produto.dadosImposto[0].is_caso_especial_icms_fronteira,
        icms_antecipacao: produto.dadosImposto[0].icms_antecipacao,
        is_caso_especial_icms_antecipacao:
          produto.dadosImposto[0].is_caso_especial_icms_antecipacao,
      };
    }

    function getDadosImpostosComputado() {
      if (fornecedor && produto && config) {
        const isSt = repasseDeStComputado > 0;
        const {
          pis_credito,
          is_caso_especial_pis_credito,
          pis_debito,
          is_caso_especial_pis_debito,
          cofins_credito,
          is_caso_especial_cofins_credito,
          cofins_debito,
          is_caso_especial_cofins_debito,
          icms_normal_credito,
          is_caso_especial_icms_normal_credito,
          icms_normal_debito,
          is_caso_especial_icms_normal_debito,
          icms_fronteira,
          is_caso_especial_icms_fronteira,
          icms_antecipacao,
          is_caso_especial_icms_antecipacao,
        } = getDadosEspeciais(produto);
        switch (fornecedor.regime_tributario) {
          case REGIME_TRIBUTARIO_PRODUTOR_RURAL: {
            return {
              aliquotaInternaCompraPorcentagemComputado: is_caso_especial_icms_normal_credito
                ? icms_normal_credito
                : 0,
              aliquotaInternaVendaPorcentagemComputado: is_caso_especial_icms_normal_debito
                ? icms_normal_debito
                : config.cidade.estado.icms_ai_compra,
              pisCompraPorcentagemComputado: is_caso_especial_pis_credito
                ? pis_credito
                : 0,
              pisVendaPorcentagemComputado: is_caso_especial_pis_debito
                ? pis_debito
                : config.pis,
              cofinsCompraPorcentagemComputado: is_caso_especial_cofins_credito
                ? cofins_credito
                : 0,
              cofinsVendaPorcentagemComputado: is_caso_especial_cofins_debito
                ? cofins_debito
                : config.cofins,
              isFronteira: is_caso_especial_icms_fronteira ? true : false,
              isAntecipacao: is_caso_especial_icms_antecipacao ? true : false,
              icmsFronteiraPorcentagemInput: is_caso_especial_icms_fronteira
                ? icms_fronteira
                : -10,
              icmsAntecipacaoPorcentagemInput: is_caso_especial_icms_antecipacao
                ? icms_antecipacao
                : config.icms_antecipacao,
            };
          }
          case REGIME_TRIBUTARIO_MEI: {
            return {
              aliquotaInternaCompraPorcentagemComputado: is_caso_especial_icms_normal_credito
                ? icms_normal_credito
                : 0,
              aliquotaInternaVendaPorcentagemComputado: is_caso_especial_icms_normal_debito
                ? icms_normal_debito
                : !isSt
                ? config.cidade.estado.icms_ai_compra
                : 0,
              pisCompraPorcentagemComputado: is_caso_especial_pis_credito
                ? pis_credito
                : config.pis,
              pisVendaPorcentagemComputado: is_caso_especial_pis_debito
                ? pis_debito
                : config.pis,
              cofinsCompraPorcentagemComputado: is_caso_especial_cofins_credito
                ? cofins_credito
                : config.cofins,
              cofinsVendaPorcentagemComputado: is_caso_especial_cofins_debito
                ? cofins_debito
                : config.cofins,
              isFronteira: is_caso_especial_icms_fronteira ? true : !isSt,
              isAntecipacao: is_caso_especial_icms_antecipacao ? true : !isSt,
              icmsFronteiraPorcentagemInput: is_caso_especial_icms_fronteira
                ? icms_fronteira
                : -10,
              icmsAntecipacaoPorcentagemInput: is_caso_especial_icms_antecipacao
                ? icms_antecipacao
                : config.icms_antecipacao,
            };
          }
          case REGIME_TRIBUTARIO_SIMPLES_NACIONAL: {
            return {
              aliquotaInternaCompraPorcentagemComputado: is_caso_especial_icms_normal_credito
                ? icms_normal_credito
                : 0,
              aliquotaInternaVendaPorcentagemComputado: is_caso_especial_icms_normal_debito
                ? icms_normal_debito
                : !isSt
                ? config.cidade.estado.icms_ai_compra
                : 0,
              pisCompraPorcentagemComputado: is_caso_especial_pis_credito
                ? pis_credito
                : config.pis,
              pisVendaPorcentagemComputado: is_caso_especial_pis_debito
                ? pis_debito
                : config.pis,
              cofinsCompraPorcentagemComputado: is_caso_especial_cofins_credito
                ? cofins_credito
                : config.cofins,
              cofinsVendaPorcentagemComputado: is_caso_especial_cofins_debito
                ? cofins_debito
                : config.cofins,
              isFronteira: is_caso_especial_icms_fronteira ? true : !isSt,
              isAntecipacao: is_caso_especial_icms_antecipacao ? true : !isSt,
              icmsFronteiraPorcentagemInput: is_caso_especial_icms_fronteira
                ? icms_fronteira
                : -10,
              icmsAntecipacaoPorcentagemInput: is_caso_especial_icms_antecipacao
                ? icms_antecipacao
                : config.icms_antecipacao,
            };
          }
          case REGIME_TRIBUTARIO_LUCRO_PRESUMIDO: {
            return {
              aliquotaInternaCompraPorcentagemComputado: is_caso_especial_icms_normal_credito
                ? icms_normal_credito
                : !isSt
                ? fornecedor.estado.icms_ai_compra
                : 0,

              aliquotaInternaVendaPorcentagemComputado: is_caso_especial_icms_normal_debito
                ? icms_normal_debito
                : !isSt
                ? config.cidade.estado.icms_ai_compra
                : 0,
              pisCompraPorcentagemComputado: is_caso_especial_pis_credito
                ? pis_credito
                : config.pis,
              pisVendaPorcentagemComputado: is_caso_especial_pis_debito
                ? pis_debito
                : config.pis,
              cofinsCompraPorcentagemComputado: is_caso_especial_cofins_credito
                ? cofins_credito
                : config.cofins,
              cofinsVendaPorcentagemComputado: is_caso_especial_cofins_debito
                ? cofins_debito
                : config.cofins,
              isFronteira: is_caso_especial_icms_fronteira ? true : !isSt,
              isAntecipacao: is_caso_especial_icms_antecipacao ? true : !isSt,
              icmsFronteiraPorcentagemInput: is_caso_especial_icms_fronteira
                ? icms_fronteira
                : -10,
              icmsAntecipacaoPorcentagemInput: is_caso_especial_icms_antecipacao
                ? icms_antecipacao
                : config.icms_antecipacao,
            };
          }
          case REGIME_TRIBUTARIO_LUCRO_REAL: {
            return {
              aliquotaInternaCompraPorcentagemComputado: is_caso_especial_icms_normal_credito
                ? icms_normal_credito
                : !isSt
                ? fornecedor.estado.icms_ai_compra
                : 0,
              aliquotaInternaVendaPorcentagemComputado: is_caso_especial_icms_normal_debito
                ? icms_normal_debito
                : !isSt
                ? config.cidade.estado.icms_ai_compra
                : 0,
              pisCompraPorcentagemComputado: is_caso_especial_pis_credito
                ? pis_credito
                : config.pis,
              pisVendaPorcentagemComputado: is_caso_especial_pis_debito
                ? pis_debito
                : config.pis,
              cofinsCompraPorcentagemComputado: is_caso_especial_cofins_credito
                ? cofins_credito
                : config.cofins,
              cofinsVendaPorcentagemComputado: is_caso_especial_cofins_debito
                ? cofins_debito
                : config.cofins,
              isFronteira: is_caso_especial_icms_fronteira ? true : !isSt,
              isAntecipacao: is_caso_especial_icms_antecipacao ? true : !isSt,
              icmsFronteiraPorcentagemInput: is_caso_especial_icms_fronteira
                ? icms_fronteira
                : -10,
              icmsAntecipacaoPorcentagemInput: is_caso_especial_icms_antecipacao
                ? icms_antecipacao
                : config.icms_antecipacao,
            };
          }
          case REGIME_TRIBUTARIO_SEM_NFE: {
            return {
              aliquotaInternaCompraPorcentagemComputado: is_caso_especial_icms_normal_credito
                ? icms_normal_credito
                : 0,
              aliquotaInternaVendaPorcentagemComputado: is_caso_especial_icms_normal_debito
                ? icms_normal_debito
                : 0,
              pisCompraPorcentagemComputado: is_caso_especial_pis_credito
                ? pis_credito
                : 0,
              pisVendaPorcentagemComputado: is_caso_especial_pis_debito
                ? pis_debito
                : 0,
              cofinsCompraPorcentagemComputado: is_caso_especial_cofins_credito
                ? cofins_credito
                : 0,
              cofinsVendaPorcentagemComputado: is_caso_especial_cofins_debito
                ? cofins_debito
                : 0,
              isFronteira: is_caso_especial_icms_fronteira ? true : false,
              isAntecipacao: is_caso_especial_icms_antecipacao ? true : false,
              icmsFronteiraPorcentagemInput: is_caso_especial_icms_fronteira
                ? icms_fronteira
                : -10,
              icmsAntecipacaoPorcentagemInput: is_caso_especial_icms_antecipacao
                ? icms_antecipacao
                : config.icms_antecipacao,
            };
          }
          default: {
            return {
              aliquotaInternaCompraPorcentagemComputado: 0,
              aliquotaInternaVendaPorcentagemComputado: 0,
              pisCompraPorcentagemComputado: 0,
              pisVendaPorcentagemComputado: 0,
              cofinsCompraPorcentagemComputado: 0,
              cofinsVendaPorcentagemComputado: 0,
            };
          }
        }
      } else {
        return {
          aliquotaInternaCompraPorcentagemComputado: 0,
          aliquotaInternaVendaPorcentagemComputado: 0,
          pisCompraPorcentagemComputado: 0,
          pisVendaPorcentagemComputado: 0,
          cofinsCompraPorcentagemComputado: 0,
          cofinsVendaPorcentagemComputado: 0,
        };
      }
    }

    const {
      aliquotaInternaCompraPorcentagemComputado,
      aliquotaInternaVendaPorcentagemComputado,
      cofinsCompraPorcentagemComputado,
      cofinsVendaPorcentagemComputado,
      pisCompraPorcentagemComputado,
      pisVendaPorcentagemComputado,
      isAntecipacao,
      isFronteira,
      icmsFronteiraPorcentagemInput,
      icmsAntecipacaoPorcentagemInput,
    } = getDadosImpostosComputado();

    function getDescontosUnitarios() {}

    linhas.push(
      formataDados({
        estadoOrigem: fornecedor ? fornecedor.estado.nome : '',
        estadoDestino: config ? config.cidade.estado.nome : '',
        quantidadeComprada: quantidadeCompradaConputado,
        valorNegociadoComAIndustria: valorNegociadoComAIndustriaComputado,
        irpjPorcentagemLb: config ? config.irpj : 0,
        csllPorcentagemLb: config ? config.csll : 0,
        lucroBrutoPorcentagem: produto ? produto.taxa_de_lucro : 0,
        //aliquotaInternaCompraPorcentagem: fornecedor ? fornecedor.estado.icms_ai_compra : 0,
        aliquotaInternaCompraPorcentagem: aliquotaInternaCompraPorcentagemComputado,
        //pisCompraPorcentagem: produto ? produto.dadosImposto[0].pis : 0,
        pisCompraPorcentagem: pisCompraPorcentagemComputado,
        //cofinsCompraPorcentagem: produto ? produto.dadosImposto[0].cofins : 0,
        cofinsCompraPorcentagem: cofinsCompraPorcentagemComputado,
        //aliquotaInternaVendaPorcentagem: config ? config.cidade.estado.icms_ai_compra : 0,
        aliquotaInternaVendaPorcentagem: aliquotaInternaVendaPorcentagemComputado,
        agregadoDoCnaePorcentagem: 0,
        mvaDaPauta: 0,
        margemDeCustoOperacionalPorcentagem: config ? config.pcf : 0,
        margemDeCustoDeCartaoPorcentagem: config ? config.ppc : 0,
        repasseDoIpi: repasseDoIpiComputado,
        repasseDoFreteUnitario: repasseDoFreteUnitarioComputado,
        repasseDeSt: repasseDeStComputado,
        repasseDeSeguros: repasseDeSegurosComputado,
        repasseDeOutrasDespesas: repasseDeOutrasDespesasComputado,
        //pisVendaPorcentagem: produto ? produto.dadosImposto[0].pis : 0,
        pisVendaPorcentagem: pisVendaPorcentagemComputado,
        //cofinsVendaPorcentagem: produto ? produto.dadosImposto[0].cofins : 0,
        cofinsVendaPorcentagem: cofinsVendaPorcentagemComputado,
        isAntecipacao,
        isFronteira,
        incluirIcmsDeFronteira: config
          ? config.incluir_icms_fronteira_na_precificao
          : false,
        incluirIcmsDeAntecipacao: config
          ? config.incluir_icms_antecipacao_na_precificacao
          : false,
        //
        /*pis_credito: produto.dadosImposto[0].pis_credito,
        is_caso_especial_pis_credito:
          produto.dadosImposto[0].is_caso_especial_pis_credito,
        pis_debito: produto.dadosImposto[0].pis_debito,
        is_caso_especial_pis_debito:
          produto.dadosImposto[0].is_caso_especial_pis_debito,
        cofins_credito: produto.dadosImposto[0].cofins_credito,
        is_caso_especial_cofins_credito:
          produto.dadosImposto[0].is_caso_especial_cofins_credito,
        cofins_debito: produto.dadosImposto[0].cofins_debito,
        is_caso_especial_cofins_debito:
          produto.dadosImposto[0].is_caso_especial_cofins_debito,
        ipi_credito: produto.dadosImposto[0].ipi_credito,
        is_caso_especial_ipi_credito:
          produto.dadosImposto[0].is_caso_especial_ipi_credito,
        ipi_debito: produto.dadosImposto[0].ipi_debito,
        is_caso_especial_ipi_debito:
          produto.dadosImposto[0].is_caso_especial_ipi_debito,
        icms_normal_credito: produto.dadosImposto[0].icms_normal_credito,
        is_caso_especial_icms_normal_credito:
          produto.dadosImposto[0].is_caso_especial_icms_normal_credito,
        icms_normal_debito: produto.dadosImposto[0].icms_normal_debito,
        is_caso_especial_icms_normal_debito:
          produto.dadosImposto[0].is_caso_especial_icms_normal_debito,
        icms_fronteira: produto.dadosImposto[0].icms_fronteira,
        is_caso_especial_icms_fronteira:
          produto.dadosImposto[0].is_caso_especial_icms_fronteira,
        icms_antecipacao: produto.dadosImposto[0].icms_antecipacao,
        is_caso_especial_icms_antecipacao:
          produto.dadosImposto[0].is_caso_especial_icms_antecipacao,*/
        icmsAntecipacaoPorcentagemInput,
        icmsFronteiraPorcentagemInput,
        ///
        descontoBacklight: descontoBacklightComputado,
        descontoBoleto: descontoBoletoComputado,
        descontoBonificacao: descontoBonificacaoComputado,
        descontoDescargoMercadorias: descontoDescargoMercadoriasComputado,
        descontoJornalOfertas: descontoJornalOfertasComputado,
        descontoNfe: descontoNfeComputado,
      }),
    );

    const {
      estadoOrigem,
      estadoDestino,
      valorNegociadoComAIndustriaPorcentagem,
      //valorNegociadoComAIndustria,
      valorNegociadoComAIndustriaTotal,
      custoLivreDeImpostoPorcentagem,
      lucroLiquidoPorcentagem,
      lucroLiquido,
      lucroLiquidoTotal,
      irpjPorcentagemPv,
      irpjPorcentagemLb,
      irpj,
      irpjTotal,
      csllPorcentagemPv,
      csllPorcentagemLb,
      csll,
      csllTotal,
      lucroBrutoPorcentagem,
      lucroBruto,
      lucroBrutoTotal,
      precoVenda,
      precoVendaTotal,
      aliquotaInternaCompraPorcentagem,
      aliquotaInternaCompra,
      aliquotaInternaCompraTotal,
      pisCompraPorcentagem,
      pisCompra,
      pisCompraTotal,
      cofinsCompraPorcentagem,
      cofinsCompra,
      cofinsCompraTotal,
      totalDeCreditosCompraPorcentagem,
      totalDeCreditosCompra,
      totalDeCreditosCompraTotal,
      aliquotaInternaVendaPorcentagem,
      aliquotaInternaVenda,
      aliquotaInternaVendaTotal,
      pisVendaPorcentagem,
      pisVenda,
      pisVendaTotal,
      cofinsVendaPorcentagem,
      cofinsVenda,
      cofinsVendaTotal,
      totalDeDebitosVendaPorcentagem,
      totalDeDebitosVenda,
      totalDeDebitosVendaTotal,
      icmsAntecipacaoPorcentagem,
      icmsAntecipacao,
      icmsAntecipacaoTotal,
      agregadoDoCnaePorcentagem,
      mvaDaPauta,
      calculoDaPautaDeIcmsDeFronteira,
      fronteiraPorcentagem,
      fronteira,
      icmsDeFronteiraAdotadoPorcentagem,
      icmsDeFronteiraAdotado,
      icmsDeFronteiraAdotadoTotal,
      totalDeIcmsAntecipadoPorcentagem,
      totalDeIcmsAntecipado,
      totalDeIcmsAntecipadoTotal,
      diferencaDeIcmsPorcentagem,
      diferencaDeIcms,
      diferencaDeIcmsTotal,
      diferencaDePisPorcentagem,
      diferencaDePis,
      diferencaDePisTotal,
      diferencaDeCofinsPorcentagem,
      diferencaDeCofins,
      diferencaDeCofinsTotal,
      tributacaoEfetivaPorcentagem,
      tributacaoEfetiva,
      tributacaoEfetivaTotal,
      resultadoDoFluxoDeCaixaPorcentagem,
      resultadoDoFluxoDeCaixa,
      resultadoDoFluxoDeCaixaTotal,
      margemDeCustoOperacionalPorcentagem,
      margemDeCustoOperacional,
      margemDeCustoOperacionalTotal,
      margemDeCustoDeCartaoPorcentagem,
      margemDeCustoDeCartao,
      margemDeCustoDeCartaoTotal,
      repasseDoIpiPorcentagem,
      repasseDoIpi: repasseDoIpi2,
      repasseDoIpiTotal,
      repasseDoFreteUnitarioPorcentagem,
      repasseDoFreteUnitario: repasseDoFreteUnitario2,
      repasseDoFreteUnitarioTotal,
      repasseDeStPorcentagem,
      repasseDeSt: repasseDeSt2,
      repasseDeStTotal,
      repasseDeSegurosPorcentagem,
      repasseDeSeguros: repasseDeSeguros2,
      repasseDeSegurosTotal,
      repasseDeOutrasDespesasPorcentagem,
      repasseDeOutrasDespesas: repasseDeOutrasDespesas2,
      repasseDeOutrasDespesasTotal,
      totalDeCustosDeRepassesPorcentagem,
      totalDeCustosDeRepasses,
      totalDeCustosDeRepassesTotal,
      custoFinalDoProdutoPorcentagem,
      custoFinalDoProduto,
      custoFinalDoProdutoTotal,
      conferenciaDoGraficoDeSecaoCircular,
      margemTotalPorcentagem,
      margemTotal,
      margemTotalTotal,
      dataDeValidade,
      descontoTotal,
      descontoTotalTotal,
      descontoPorcentagem,
      //quantidadeComprada,
      dadosOriginais,
      //
      descontoBacklight: descontoBacklight2,
      descontoBoleto: descontoBoleto2,
      descontoBonificacao: descontoBonificacao2,
      descontoDescargoMercadorias: descontoDescargoMercadorias2,
      descontoJornalOfertas: descontoJornalOfertas2,
      descontoNfe: descontoNfe2,
      //
      descontoNfePorcentagem,
      descontoNfeTotal,
      descontoBoletoPorcentagem,
      descontoBoletoTotal,
      descontoBonificacaoPorcentagem,
      descontoBonificacaoTotal,
      descontoJornalOfertasPorcentagem,
      descontoJornalOfertasTotal,
      descontoDescargoMercadoriasPorcentagem,
      descontoDescargoMercadoriasTotal,
      descontoBacklightPorcentagem,
      descontoBacklightTotal,
    } = linhas[0];

    function abrirGrafico(dados) {
      if (refDialogoGrafico.current) {
        //refDialogoGrafico.current.handleOpen(data);
        refDialogoGrafico.current.handleOpen(
          [
            {
              label: 'Valor Negociado com a indústria',
              backgroundColor: 'rgba(68, 114, 196, 1)',
              borderColor: 'rgba(68, 114, 196, 1)',
              valor: ceil10(dados.valorNegociadoComAIndustria, -2),
            },
            {
              label: 'Margem de Custo Operacional',
              backgroundColor: 'rgba(237, 125, 49, 1)',
              borderColor: 'rgba(237, 125, 49, 1)',
              valor: ceil10(dados.margemDeCustoOperacional, -2),
            },
            {
              label: 'Diferença de ICMS',
              backgroundColor: 'rgba(165, 165, 165, 1)',
              borderColor: 'rgba(165, 165, 165, 1)',
              valor: ceil10(dados.diferencaDeIcms, -2),
            },
            {
              label: 'Lucro Líquido',
              backgroundColor: 'rgba(91, 155, 213, 1)',
              borderColor: 'rgba(91, 155, 213, 1)',
              valor: ceil10(dados.lucroBruto, -2),
            },
            {
              label: 'Diferença de COFINS',
              backgroundColor: 'rgba(112, 173, 71, 1)',
              borderColor: 'rgba(112, 173, 71, 1)',
              valor: ceil10(dados.diferencaDeCofins, -2),
            },
            {
              label: 'Margem de Custo de Cartão',
              backgroundColor: 'rgba(10, 224, 196, 1)',
              borderColor: 'rgba(10, 224, 196, 1)',
              valor: ceil10(dados.margemDeCustoDeCartao, -2),
            },
            {
              label: 'Diferença de PIS',
              backgroundColor: 'rgba(7, 15, 49, 1)',
              borderColor: 'rgba(7, 15, 49, 1)',
              valor: ceil10(dados.diferencaDePis, -2),
            },
            {
              label: 'Repasse do IPI',
              backgroundColor: 'rgba(200, 165, 165, 1)',
              borderColor: 'rgba(200, 165, 165, 1)',
              valor: ceil10(dados.repasseDoIpiPorcentagemVenda, -2),
            },
            {
              label: 'Repasse do Frete',
              backgroundColor: 'rgba(90, 1, 213, 1)',
              borderColor: 'rgba(90, 1, 213, 1)',
              valor: ceil10(dados.repasseDoFreteUnitarioPorcentagemVenda, -2),
            },
            {
              label: 'Repasse do ST',
              backgroundColor: 'rgba(200, 0, 0, 1)',
              borderColor: 'rgba(200, 0, 0, 1)',
              valor: ceil10(dados.repasseDeStPorcentagemVenda, -2),
            },
            {
              label: 'Repasse do Seguro',
              backgroundColor: 'rgba(60, 60, 6, 1)',
              borderColor: 'rgba(60, 60, 6, 1)',
              valor: ceil10(dados.repasseDeSegurosPorcentagemVenda, -2),
            },
            {
              label: 'Repasse de Outras Despesas',
              backgroundColor: 'rgba(5, 5, 5, 1)',
              borderColor: 'rgba(5, 5, 5, 1)',
              valor: ceil10(dados.repasseDeOutrasDespesasPorcentagemVenda, -2),
            },
            {
              label: 'ICMS de Fronteira',
              backgroundColor: 'rgba(100, 50, 100, 1)',
              borderColor: 'rgba(100, 50, 100)',
              valor: ceil10(dados.icmsDeFronteiraAdotadoPorcentagemVenda, -2),
            },
            {
              label: 'ICMS de Antecipação',
              backgroundColor: 'rgba(10, 20, 250, 1)',
              borderColor: 'rgba(10, 20, 250, 1)',
              valor: ceil10(dados.icmsAntecipacaoPorcentagemVenda, -2),
            },
            {
              label: 'Desconto Total',
              backgroundColor: 'rgba(37, 205, 200, 1)',
              borderColor: 'rgba(37, 205, 200, 1)',
              valor: ceil10(-1 * dados.descontoTotal, -2),
            },
          ],
          dados.precoVenda,
        );
      }
    }

    async function handleSalvar() {
      if (fornecedor || config || produto) {
        try {
          setCarregando(true);
          /*await updateAPI(
            'update_produto_preco_venda_precificacao',
            produto.id,
            {
              preco_venda: linhas[0].dadosOriginais.precoVenda,
              manterPrecoAnterior,
            },
          );
          if (true) {
            if (refDialogoImpressao.current) {
              refDialogoImpressao.current.handleCreate(produto.id);
            }
          }*/

          if (isEmitMessage) {
            enqueueSnackbar('Produto atualizado com sucesso!', {
              variant: 'success',
            });
          }
          handleCloseDialog({
            produto,
            index,
            cg: repasseDoFreteUnitario,
            preco_compra: linhas[0].dadosOriginais.valorNegociadoComAIndustria,
            /*preco_compra_repasses:
              linhas[0].dadosOriginais.valorNegociadoComAIndustria,*/
            preco_compra_impostos: linhas[0].dadosOriginais.custoFinalDoProduto,
            qtde: quantidadeCompradaConputado,
            validade: formatDate(validade),
            //validade,
            nImpressoes,
            dadosPrecificacao: {
              valor_negociado_com_a_industria:
                linhas[0].dadosOriginais.valorNegociadoComAIndustria,
              custo_operacional:
                linhas[0].dadosOriginais.margemDeCustoOperacional,
              diferenca_de_icms: linhas[0].dadosOriginais.diferencaDeIcms,
              lucro_bruto: linhas[0].dadosOriginais.lucroBruto,
              diferenca_de_cofins: linhas[0].dadosOriginais.diferencaDeCofins,
              custo_de_cartao: linhas[0].dadosOriginais.margemDeCustoDeCartao,
              difereca_de_pis: linhas[0].dadosOriginais.diferencaDePis,
              repasse_do_ipi: hasRepasseNaoUnitarioIPI
                ? 0
                : linhas[0].dadosOriginais.repasseDoIpiPorcentagemVenda,
              repasse_do_frete: hasRepasseNaoUnitarioFrete
                ? 0
                : linhas[0].dadosOriginais
                    .repasseDoFreteUnitarioPorcentagemVenda,
              repasse_de_st: hasRepasseNaoUnitarioST
                ? 0
                : linhas[0].dadosOriginais.repasseDeStPorcentagemVenda,
              repasse_do_seguro: hasRepasseNaoUnitarioSeguros
                ? 0
                : linhas[0].dadosOriginais.repasseDeSegurosPorcentagemVenda,
              repasse_de_outras_despesas: hasRepasseNaoUnitarioOutrasDespesas
                ? 0
                : linhas[0].dadosOriginais
                    .repasseDeOutrasDespesasPorcentagemVenda,
              custo_final_do_produto:
                linhas[0].dadosOriginais.custoFinalDoProduto,
              preco_de_venda: round10(linhas[0].dadosOriginais.precoVenda, -2),
              repasse_do_ipi_distribuido: 0,
              repasse_do_frete_distribuido: 0,
              repasse_de_st_distribuido: 0,
              repasse_do_seguro_distribuido: 0,
              repasse_de_outras_despesas_distribuido: 0,
              //
              taxa_irpj: linhas[0].dadosOriginais.irpjPorcentagemLb, //
              taxa_csll: linhas[0].dadosOriginais.csllPorcentagemLb, //
              taxa_lucro_bruto: linhas[0].dadosOriginais.lucroBrutoPorcentagem, //
              taxa_lucro_liquido:
                linhas[0].dadosOriginais.lucroLiquidoPorcentagem, //
              taxa_pis_compra: linhas[0].dadosOriginais.pisCompraPorcentagem, //
              taxa_pis_venda: linhas[0].dadosOriginais.pisVendaPorcentagem, //
              taxa_cofins_compra:
                linhas[0].dadosOriginais.cofinsCompraPorcentagem, //
              taxa_cofins_venda:
                linhas[0].dadosOriginais.cofinsVendaPorcentagem, //
              taxa_icms_antecipacao:
                linhas[0].dadosOriginais.icmsAntecipacaoPorcentagem, //
              taxa_icms_compra:
                linhas[0].dadosOriginais.aliquotaInternaCompraPorcentagem, //
              taxa_icms_venda:
                linhas[0].dadosOriginais.aliquotaInternaVendaPorcentagem, //
              agregado_do_cnae:
                linhas[0].dadosOriginais.agregadoDoCnaePorcentagem, //
              mva_da_pauta: linhas[0].dadosOriginais.mvaDaPauta, //
              icms_compra: linhas[0].dadosOriginais.aliquotaInternaCompra, //
              icms_venda: linhas[0].dadosOriginais.aliquotaInternaVenda,
              icms_fronteira: linhas[0].dadosOriginais.icmsDeFronteiraAdotado, //
              icms_antecipacao: linhas[0].dadosOriginais.icmsAntecipacao, //
              pis_compra: linhas[0].dadosOriginais.pisCompra, //
              pis_venda: linhas[0].dadosOriginais.pisVenda, //
              cofins_compra: linhas[0].dadosOriginais.cofinsCompra, //
              cofins_venda: linhas[0].dadosOriginais.cofinsVenda, //
              lucro_liquido: linhas[0].dadosOriginais.lucroLiquido, //
              irpj: linhas[0].dadosOriginais.irpj, //
              csll: linhas[0].dadosOriginais.csll, //
              //
              taxa_custo_operacional:
                linhas[0].dadosOriginais.margemDeCustoOperacionalPorcentagem,
              taxa_custo_de_cartao:
                linhas[0].dadosOriginais.margemDeCustoDeCartaoPorcentagem,
              ///////
              desconto_em_nfe: descontoNfeComputado,
              desconto_em_boleto: descontoBoletoComputado,
              desconto_em_bonificacao: descontoBonificacaoComputado,
              desconto_em_backlight_e_ponto_extra: descontoBacklightComputado,
              desconto_em_jornal_de_ofertas: descontoJornalOfertasComputado,
              desconto_em_descargo_de_mercadorias: descontoDescargoMercadoriasComputado,
            },
          });
        } catch (erros) {
          enqueueSnackbar(`${erros}`, {
            variant: 'error',
          });
          handleCloseDialog();
        } finally {
          setCarregando(false);
        }
      } else {
        enqueueSnackbar('Ocorreu um erro ao carregar os dados', {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    async function getDataResourceEditar(
      produtoId,
      config,
      fornecedor,
      validade,
      nImpressoes,
      valorNegociadoComAIndustria,
      quantidadeComprada,
      repasseDoIpi,
      repasseDoFreteUnitario,
      repasseDeSt,
      repasseDeSeguros,
      repasseDeOutrasDespesas,
      //
      descontoNfe,
      descontoBoleto,
      descontoBonificacao,
      descontoBacklight,
      descontoJornalOfertas,
      descontoDescargoMercadorias,
    ) {
      try {
        setCarregando(true);

        const data = await getListAllAPI(
          'produtos',
          ['id', 'asc'],
          { id: produtoId },
          ['dadosImposto', 'unidade'],
        );

        if (data.data.length > 0) {
          setProduto(data.data[0]);
          setConfig(config);
          setFornecedor(fornecedor);
          setManterPrecoAnterior(false);

          //
          setValidade(validade);
          setValorNegociadoComAIndustria(valorNegociadoComAIndustria);
          setQuantidadeComprada(quantidadeComprada);
          setRepasseDoIpi(repasseDoIpi);
          setRepasseDoFreteUnitario(repasseDoFreteUnitario);
          setRepasseDeSt(repasseDeSt);
          setRepasseDeSeguros(repasseDeSeguros);
          setRepasseDeOutrasDespesas(repasseDeOutrasDespesas);
          setBaseDeCalculo(0);
          setAlvo(optionsAlvo[1].value);
          //
          setTipoRepasseDoIpi(optionsRepassesUnitarios[0].value);
          setTipoRepasseDoFrete(optionsRepassesUnitarios[0].value);
          setTipoRepasseDoSt(optionsRepassesUnitarios[0].value);
          setTipoRepasseDoSeguros(optionsRepassesUnitarios[0].value);
          setTipoRepasseDoOutrasDespesas(optionsRepassesUnitarios[0].value);

          setDescontoNfe(descontoNfe);
          setDescontoBoleto(descontoBoleto);
          setDescontoBonificacao(descontoBonificacao);
          setDescontoBacklight(descontoBacklight);
          setDescontoJornalOfertas(descontoJornalOfertas);
          setDescontoDescargoMercadorias(descontoDescargoMercadorias);

          setTipoDescontoNfe(optionsDescontos[0].value);
          setTipoDescontoBoleto(optionsDescontos[0].value);
          setTipoDescontoBonificacao(optionsDescontos[0].value);
          setTipoDescontoBacklight(optionsDescontos[0].value);
          setTipoDescontoJornalOfertas(optionsDescontos[0].value);
          setTipoDescontoDescargoMercadorias(optionsDescontos[0].value);

          setNImpressoes(nImpressoes);

          setOpen(true);
        } else {
          throw 'Este produto não existe!';
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      } finally {
        setCarregando(false);
      }
    }

    async function getDataResource(codigo, config, fornecedor, id) {
      try {
        setCarregando(true);
        const data = await getListAllAPI(
          'produtos',
          ['id', 'asc'],
          id ? { id } : { codigo },
          ['dadosImposto', 'unidade'],
        );

        if (data.data.length > 0) {
          setProduto(data.data[0]);
          setConfig(config);
          setFornecedor(fornecedor);
          setManterPrecoAnterior(false);

          //
          setValidade('');
          setValorNegociadoComAIndustria(0);
          setQuantidadeComprada(0);
          setRepasseDoIpi(0);
          setRepasseDoFreteUnitario(0);
          setRepasseDeSt(0);
          setRepasseDeSeguros(0);
          setRepasseDeOutrasDespesas(0);
          setBaseDeCalculo(0);
          setAlvo(optionsAlvo[0].value);
          //

          setTipoRepasseDoIpi(optionsRepassesUnitarios[0].value);
          setTipoRepasseDoFrete(optionsRepassesUnitarios[0].value);
          setTipoRepasseDoSt(optionsRepassesUnitarios[0].value);
          setTipoRepasseDoSeguros(optionsRepassesUnitarios[0].value);
          setTipoRepasseDoOutrasDespesas(optionsRepassesUnitarios[0].value);

          setDescontoNfe(0);
          setDescontoBoleto(0);
          setDescontoBonificacao(0);
          setDescontoBacklight(0);
          setDescontoJornalOfertas(0);
          setDescontoDescargoMercadorias(0);

          setTipoDescontoNfe(optionsDescontos[1].value);
          setTipoDescontoBoleto(optionsDescontos[1].value);
          setTipoDescontoBonificacao(optionsDescontos[1].value);
          setTipoDescontoBacklight(optionsDescontos[1].value);
          setTipoDescontoJornalOfertas(optionsDescontos[1].value);
          setTipoDescontoDescargoMercadorias(optionsDescontos[1].value);

          setNImpressoes(0);

          setOpen(true);
        } else {
          throw 'Este produto não existe!';
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      } finally {
        setCarregando(false);
      }
    }

    async function getProduto() {
      if (produto) {
        try {
          const data = await getListAllAPI(
            'produtos',
            ['id', 'asc'],
            { id: produto.id },
            ['dadosImposto', 'unidade'],
          );

          if (data.data.length > 0) {
            setProduto(data.data[0]);
            //setCarregando(false);
          } else {
            throw 'Este produto não existe!';
          }
        } catch (erros) {
          enqueueSnackbar(`${erros}`, {
            variant: 'error',
          });
          handleCloseDialog();
        }
      } else {
        enqueueSnackbar(`Produto não encontrado!!!`, {
          variant: 'error',
        });
      }
    }

    const tipoUnidade = produto ? produto.unidade.tipo : '';

    function validateQuantidadeComprada() {
      let error = '';
      if (alvo !== optionsAlvo[2].value) {
        if (quantidadeComprada <= 0 || Number.isNaN(quantidadeComprada)) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    function validateValorNegociadoComAIndustria() {
      let error = '';
      if (alvo !== optionsAlvo[0].value) {
        if (
          valorNegociadoComAIndustria <= 0 ||
          Number.isNaN(valorNegociadoComAIndustria)
        ) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    function validateRepasseIpi() {
      let error = '';
      if (!hasRepasseNaoUnitarioIPI) {
        if (repasseDoIpi < 0 || Number.isNaN(repasseDoIpi)) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    function validateRepasseFreteUitario() {
      let error = '';
      if (!hasRepasseNaoUnitarioFrete) {
        if (
          repasseDoFreteUnitario < 0 ||
          Number.isNaN(repasseDoFreteUnitario)
        ) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    function validateRepasseSt() {
      let error = '';
      if (!hasRepasseNaoUnitarioST) {
        if (repasseDeSt < 0 || Number.isNaN(repasseDeSt)) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    function validateRepasseSeguros() {
      let error = '';
      if (!hasRepasseNaoUnitarioSeguros) {
        if (repasseDeSeguros < 0 || Number.isNaN(repasseDeSeguros)) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    function validateRepasseOutrasDespesas() {
      let error = '';
      if (!hasRepasseNaoUnitarioOutrasDespesas) {
        if (
          repasseDeOutrasDespesas < 0 ||
          Number.isNaN(repasseDeOutrasDespesas)
        ) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    function validateValidade() {
      const error = '';
      //if (validade === null || validade === '') error = 'Data Inválida!';
      return error;
    }

    function validateBaseDeCalculo() {
      let error = '';
      if (alvo !== optionsAlvo[1].value) {
        if (baseDeCalculo <= 0 || Number.isNaN(baseDeCalculo)) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    function validateNImpressoes() {
      let error = '';
      if (nImpressoes < 0 || Number.isNaN(nImpressoes)) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function validateDescontoNfe() {
      let error = '';
      if (descontoNfe < 0 || Number.isNaN(descontoNfe)) {
        error = 'Este campo deve ser um número válido maior ou igual a zero';
      }
      return error;
    }

    function validateDescontoBoleto() {
      let error = '';
      if (descontoBoleto < 0 || Number.isNaN(descontoBoleto)) {
        error = 'Este campo deve ser um número válido maior ou igual a zero';
      }
      return error;
    }

    function validateDescontoBonificacao() {
      let error = '';
      if (descontoBonificacao < 0 || Number.isNaN(descontoBonificacao)) {
        error = 'Este campo deve ser um número válido maior ou igual a zero';
      }
      return error;
    }

    function validateDescontoBacklight() {
      let error = '';
      if (descontoBacklight < 0 || Number.isNaN(descontoBacklight)) {
        error = 'Este campo deve ser um número válido maior ou igual a zero';
      }
      return error;
    }

    function validateDescontoJornalOfertas() {
      let error = '';
      if (descontoJornalOfertas < 0 || Number.isNaN(descontoJornalOfertas)) {
        error = 'Este campo deve ser um número válido maior ou igual a zero';
      }
      return error;
    }

    function validateDescontoDescargoMercadorias() {
      let error = '';
      if (
        descontoDescargoMercadorias < 0 ||
        Number.isNaN(descontoDescargoMercadorias)
      ) {
        error = 'Este campo deve ser um número válido maior ou igual a zero';
      }
      return error;
    }

    function validateDescontoTotal() {
      let error = '';
      /*       const descontoTotal =
        descontoBacklightComputado +
        descontoBoletoComputado +
        descontoBonificacaoComputado +
        descontoDescargoMercadoriasComputado +
        descontoJornalOfertasComputado +
        descontoNfeComputado;

      if (!Number.isNaN(descontoTotal)) {
        if (dadosOriginais) {
          const precoVenda = dadosOriginais.precoVenda;
          console.log({
            descontoTotal,
            precoVenda,
          });
          if (!Number.isNaN(precoVenda)) {
            const precoVendaOriginal = precoVenda + descontoTotal;
            if (descontoTotal / precoVendaOriginal > 0.5) {
              error =
                'O desconto total não pode ser maior que 50% do preço de venda';
            }
          }
        }
      } */
      if (
        linhas[0].dadosOriginais.precoVenda <
        linhas[0].dadosOriginais.valorNegociadoComAIndustria
      ) {
        error = `O preço de venda (${formatMoeda(
          linhas[0].dadosOriginais.precoVenda,
        )}) não pode ser menor que o preço negociado com a indústria (${formatMoeda(
          linhas[0].dadosOriginais.valorNegociadoComAIndustria,
        )})!`;
      }
      return error;
    }

    function getErros() {
      const errosImpostos = ['', ''];
      errosImpostos[0] = validateQuantidadeComprada();
      errosImpostos[1] = validateValorNegociadoComAIndustria();

      errosImpostos[2] = validateRepasseIpi();
      errosImpostos[3] = validateRepasseFreteUitario();
      errosImpostos[4] = validateRepasseSt();
      errosImpostos[5] = validateRepasseSeguros();
      errosImpostos[6] = validateRepasseOutrasDespesas();
      errosImpostos[7] = validateValidade();
      errosImpostos[8] = validateBaseDeCalculo();
      errosImpostos[9] = validateNImpressoes();

      errosImpostos[10] = validateDescontoNfe();
      errosImpostos[11] = validateDescontoBoleto();
      errosImpostos[12] = validateDescontoBonificacao();
      errosImpostos[13] = validateDescontoBacklight();
      errosImpostos[14] = validateDescontoJornalOfertas();
      errosImpostos[15] = validateDescontoDescargoMercadorias();
      errosImpostos[16] = validateDescontoTotal();

      return errosImpostos;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    function handleEditProduto() {
      if (refDialogoProduto.current) {
        refDialogoProduto.current.handleEdit(produto.id);
      }
    }

    return (
      <div>
        <Dialog
          open={open}
          onClose={() => handleCloseDialog()}
          aria-labelledby="form-dialog-title"
          fullScreen
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={isCloseX ? () => handleCloseDialog() : null}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            {`${prefixoTitle}Precificação - ${produto ? produto.nome : ''}`}
          </DialogTitle>
          {!carregando ? (
            <DialogContent dividers>
              <div className={classes.root}>
                <div
                  style={{
                    margin: '10px 0px',
                    color: 'red',
                  }}
                >
                  {getMessageAviso()}
                </div>
                <div
                  style={{
                    margin: '10px 0px',
                    color: 'red',
                  }}
                >
                  {getMessageAviso2()}
                </div>

                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginTop: '0px' }}
                >
                  Informações
                </Typography>
                <Box
                  style={{
                    display: 'flex',
                    gap: '10px',
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <PrecoInput
                      name="preco_venda_original"
                      label="Preço Anterior de Venda"
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      value={
                        Number.isNaN(produto ? produto.preco_venda : 0)
                          ? ''
                          : String(produto ? produto.preco_venda : 0)
                      }
                      onChange={(value) => {}}
                      fullWidth
                      escala={2}
                      disabled
                    />
                  </div>
                  <div
                    style={{
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <FormControlLabel
                      value="end"
                      control={
                        <Switch
                          checked={manterPrecoAnterior}
                          onChange={(event) => {
                            setManterPrecoAnterior(event.target.checked);
                          }}
                          name="isNota"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      }
                      label="Manter Preço de Venda Anterior"
                      labelPlacement="end"
                    />
                  </div>
                </Box>

                <Box
                  style={{
                    display: 'flex',
                    gap: '10px',
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <PorcentagemInput
                      name="taxa_lucro"
                      label="Margem de Lucro Líquido"
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      value={
                        Number.isNaN(produto ? produto.taxa_de_lucro : 0)
                          ? ''
                          : String(produto ? produto.taxa_de_lucro : 0)
                      }
                      onChange={(value) => {}}
                      fullWidth
                      escala={2}
                      disabled
                    />
                  </div>
                  <div
                    style={{
                      //flex: 1,
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <Tooltip
                      title="Editar Produto"
                      placement="bottom"
                      aria-label="add2"
                    >
                      <IconButton
                        aria-label="edit"
                        classes={{ root: classes.root }}
                        size="small"
                        onClick={() => handleEditProduto()}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bold',
                        marginRight: '10px',
                      }}
                    >
                      Estoque:
                    </span>
                    {`${estoque}`}
                  </div>
                </Box>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginTop: '10px', marginBottom: '20px' }}
                >
                  Valores Cálculados
                </Typography>
                <Box
                  style={{
                    display: 'flex',
                    gap: '10px',
                    marginBottom: '25px',
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <span
                      style={{
                        fontWeight: 'bold',
                        marginRight: '10px',
                      }}
                    >
                      Preço de Venda:
                    </span>
                    {`${formatMoeda(linhas[0].dadosOriginais.precoVenda)}`}
                  </div>

                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    {/*<span
                  style={{
                    fontWeight: 'bold',
                    marginRight: '10px',
                  }}
                >
                  Valor negociado com a indústria:
                </span>
                {`${formatMoeda(
                  linhas[0].dadosOriginais.valorNegociadoComAIndustria,
                )}`}*/}
                  </div>
                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bold',
                        marginRight: '10px',
                      }}
                    >
                      Custo Final do Produto:
                    </span>
                    {`${formatMoeda(
                      linhas[0].dadosOriginais.custoFinalDoProduto,
                    )}`}
                  </div>
                </Box>
                {erros[16] !== '' ? (
                  <div
                    style={{
                      color: 'red',
                      margin: '10px 0',
                      fontWeight: 'bold',
                      fontSize: '20px',
                    }}
                  >
                    * {erros[16]}
                  </div>
                ) : null}
                <Box>
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ marginTop: '10px' }}
                  >
                    Geral
                  </Typography>
                  <Box
                    display="flex"
                    style={{
                      gap: '10px',
                    }}
                  >
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth
                      style={{
                        marginTop: '16px',
                      }}
                    >
                      <InputLabel id="demo-simple-select-label-2">
                        Alvo
                      </InputLabel>
                      <SelectInput
                        labelId="demo-simple-select-label-2"
                        name="tipo"
                        handleEnter={() => {}}
                        label="Alvo"
                        handleKey={() => {}}
                        value={alvo}
                        onChange={(value) => {
                          setAlvo(value.target.value);
                          setQuantidadeComprada(0);
                          setValorNegociadoComAIndustria(0);
                          setBaseDeCalculo(0);
                        }}
                        //error={erros[4] !== ''}
                        //helperText={erros[4]}
                        options={optionsAlvo}
                        //
                      />
                    </FormControl>
                    <DateInput
                      name="validade"
                      handleEnter={() => {}}
                      label="Validade"
                      handleKey={() => {}}
                      value={validade}
                      onChange={(value) => setValidade(value.target.value)}
                      error={erros[7] !== ''}
                      helperText={erros[7]}
                      fullWidth
                    />
                    <IntegerInput
                      name="impressoes"
                      label="Nº Impressões de Etiquetas"
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      value={
                        Number.isNaN(nImpressoes) ? '' : String(nImpressoes)
                      }
                      onChange={(value) => {
                        setNImpressoes(parseInt(value.target.value, 10));
                      }}
                      error={erros[9] !== ''}
                      helperText={erros[9]}
                      fullWidth
                      //escala={2}
                      //autoFocus
                      //disabled={alvo === optionsAlvo[2].value}
                    />
                  </Box>
                  <Box
                    display="flex"
                    style={{
                      gap: '10px',
                    }}
                  >
                    <PrecoInput
                      name="valor_negociado_com_a_industria"
                      label="Valor negociado com a indústria"
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      value={
                        Number.isNaN(valorNegociadoComAIndustriaComputado)
                          ? ''
                          : String(valorNegociadoComAIndustriaComputado)
                      }
                      onChange={(value) => {
                        if (alvo !== optionsAlvo[0].value) {
                          setValorNegociadoComAIndustria(
                            parseFloat(value.target.value),
                          );
                        }
                      }}
                      error={erros[1] !== ''}
                      helperText={erros[1]}
                      disabled={alvo === optionsAlvo[0].value}
                      fullWidth
                      escala={6}
                    />
                    {tipoUnidade === POR_UNIDADE ||
                    tipoUnidade === POR_PESO_FIXO ? (
                      <IntegerInput
                        name="quantidade_comprada"
                        label="Quantidade Comprada (Un.)"
                        handleEnter={() => {}}
                        handleKey={() => {}}
                        value={
                          Number.isNaN(quantidadeCompradaConputado)
                            ? ''
                            : String(quantidadeCompradaConputado)
                        }
                        onChange={(value) => {
                          if (alvo !== optionsAlvo[2].value) {
                            setQuantidadeComprada(
                              parseInt(value.target.value, 10),
                            );
                          }
                        }}
                        error={erros[0] !== ''}
                        helperText={erros[0]}
                        fullWidth
                        //escala={2}
                        autoFocus
                        disabled={alvo === optionsAlvo[2].value}
                      />
                    ) : (
                      <PesoInput
                        name="quantidade_comprada"
                        label="Quantidade Comprada (Kg)"
                        handleEnter={() => {}}
                        handleKey={() => {}}
                        value={
                          Number.isNaN(quantidadeCompradaConputado)
                            ? ''
                            : String(quantidadeCompradaConputado)
                        }
                        onChange={(value) => {
                          if (alvo !== optionsAlvo[2].value) {
                            setQuantidadeComprada(
                              parseFloat(value.target.value),
                            );
                          }
                        }}
                        error={erros[0] !== ''}
                        helperText={erros[0]}
                        fullWidth
                        escala={2}
                        autoFocus
                        disabled={alvo === optionsAlvo[2].value}
                      />
                    )}
                    <PrecoInput
                      name="valor_negociado_com_a_industria"
                      label="Base de Cálculo"
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      value={
                        Number.isNaN(baseDeCalculoComputado)
                          ? ''
                          : String(baseDeCalculoComputado)
                      }
                      onChange={(value) => {
                        if (alvo !== optionsAlvo[1].value) {
                          setBaseDeCalculo(parseFloat(value.target.value));
                        }
                      }}
                      error={erros[8] !== ''}
                      helperText={erros[8]}
                      fullWidth
                      escala={2}
                      disabled={alvo === optionsAlvo[1].value}
                    />
                  </Box>
                </Box>

                <Box>
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ marginTop: '10px' }}
                  >
                    Repasses Unitários
                  </Typography>
                  <Box
                    display="flex"
                    style={{
                      gap: '10px',
                    }}
                  >
                    <InputRepasse
                      tipoRepasse={tipoRepasseDoIpi}
                      value={repasseDoIpi}
                      onChange={setRepasseDoIpi}
                      errorMessage={erros[2]}
                      disabled={hasRepasseNaoUnitarioIPI}
                      onChangeTipo={setTipoRepasseDoIpi}
                      label="Repasse Unitário do IPI"
                      name="repasseDoIpi"
                    />
                    <InputRepasse
                      tipoRepasse={tipoRepasseDoFrete}
                      value={repasseDoFreteUnitario}
                      onChange={setRepasseDoFreteUnitario}
                      errorMessage={erros[3]}
                      disabled={hasRepasseNaoUnitarioFrete}
                      onChangeTipo={setTipoRepasseDoFrete}
                      label="Repasse Unitário Frete Unitário"
                      name="repasseDoFreteUnitario"
                    />
                  </Box>
                  <Box
                    display="flex"
                    style={{
                      gap: '10px',
                    }}
                  >
                    <InputRepasse
                      tipoRepasse={tipoRepasseDoSt}
                      value={repasseDeSt}
                      onChange={setRepasseDeSt}
                      errorMessage={erros[4]}
                      disabled={hasRepasseNaoUnitarioST}
                      onChangeTipo={setTipoRepasseDoSt}
                      label="Repasse Unitário Substituição Tributária"
                      name="repasseDeSt"
                    />
                    <InputRepasse
                      tipoRepasse={tipoRepasseDoSeguros}
                      value={repasseDeSeguros}
                      onChange={setRepasseDeSeguros}
                      errorMessage={erros[5]}
                      disabled={hasRepasseNaoUnitarioSeguros}
                      onChangeTipo={setTipoRepasseDoSeguros}
                      label="Repasse Unitário Seguros"
                      name="repasseDeSeguros"
                    />
                  </Box>
                  <Box
                    display="flex"
                    style={{
                      gap: '10px',
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                      }}
                    >
                      <InputRepasse
                        tipoRepasse={tipoRepasseDoOutrasDespesas}
                        value={repasseDeOutrasDespesas}
                        onChange={setRepasseDeOutrasDespesas}
                        errorMessage={erros[6]}
                        disabled={hasRepasseNaoUnitarioOutrasDespesas}
                        onChangeTipo={setTipoRepasseDoOutrasDespesas}
                        label="Repasses de Outras Despesas"
                        name="repasseDeOutrasDespesas"
                      />
                    </div>
                    <div
                      style={{
                        flex: 1,
                      }}
                    />
                  </Box>
                </Box>
                <Box>
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ marginTop: '10px' }}
                  >
                    Verbas de Desconto
                  </Typography>

                  <Box
                    display="flex"
                    style={{
                      gap: '10px',
                    }}
                  >
                    <InputDesconto
                      tipoRepasse={tipoDescontoNfe}
                      value={descontoNfe}
                      onChange={setDescontoNfe}
                      errorMessage={erros[10]}
                      onChangeTipo={setTipoDescontoNfe}
                      label="Desconto em NFe"
                      name="desconto_nfe"
                    />
                    {/*      <PrecoInput
                      label="Desconto em NFe"
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      value={
                        Number.isNaN(descontoNfe) ? '' : String(descontoNfe)
                      }
                      onChange={(value) =>
                        setDescontoNfe(parseFloat(value.target.value))
                      }
                      error={erros[10] !== ''}
                      helperText={erros[10]}
                      fullWidth
                      escala={6}
                    /> */}
                    <InputDesconto
                      tipoRepasse={tipoDescontoBoleto}
                      value={descontoBoleto}
                      onChange={setDescontoBoleto}
                      errorMessage={erros[11]}
                      onChangeTipo={setTipoDescontoBoleto}
                      label="Desconto em Boleto"
                      name="desconto_boleto"
                    />
                    {/*      <PrecoInput
                      label="Desconto em Boleto"
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      value={
                        Number.isNaN(descontoBoleto)
                          ? ''
                          : String(descontoBoleto)
                      }
                      onChange={(value) =>
                        setDescontoBoleto(parseFloat(value.target.value))
                      }
                      error={erros[11] !== ''}
                      helperText={erros[11]}
                      fullWidth
                      escala={6}
                    /> */}
                  </Box>
                  <Box
                    display="flex"
                    style={{
                      gap: '10px',
                    }}
                  >
                    <InputDesconto
                      tipoRepasse={tipoDescontoBonificacao}
                      value={descontoBonificacao}
                      onChange={setDescontoBonificacao}
                      errorMessage={erros[12]}
                      onChangeTipo={setTipoDescontoBonificacao}
                      label="Desconto em Bonificação"
                      name="desconto_bonificacao"
                    />
                    {/*      <PrecoInput
                      label="Desconto em Bonificação"
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      value={
                        Number.isNaN(descontoBonificacao)
                          ? ''
                          : String(descontoBonificacao)
                      }
                      onChange={(value) =>
                        setDescontoBonificacao(parseFloat(value.target.value))
                      }
                      error={erros[12] !== ''}
                      helperText={erros[12]}
                      fullWidth
                      escala={6}
                    /> */}
                    <InputDesconto
                      tipoRepasse={tipoDescontoBacklight}
                      value={descontoBacklight}
                      onChange={setDescontoBacklight}
                      errorMessage={erros[13]}
                      onChangeTipo={setTipoDescontoBacklight}
                      label="Desconto em Backlight"
                      name="desconto_backlight"
                    />
                    {/*             <PrecoInput
                      label="Desconto em Backlight e Ponto Extra"
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      value={
                        Number.isNaN(descontoBacklight)
                          ? ''
                          : String(descontoBacklight)
                      }
                      onChange={(value) =>
                        setDescontoBacklight(parseFloat(value.target.value))
                      }
                      error={erros[13] !== ''}
                      helperText={erros[13]}
                      fullWidth
                      escala={6}
                    /> */}
                  </Box>
                  <Box
                    display="flex"
                    style={{
                      gap: '10px',
                    }}
                  >
                    <InputDesconto
                      tipoRepasse={tipoDescontoJornalOfertas}
                      value={descontoJornalOfertas}
                      onChange={setDescontoJornalOfertas}
                      errorMessage={erros[14]}
                      onChangeTipo={setTipoDescontoJornalOfertas}
                      label="Desconto em Jornal de Ofertas"
                      name="desconto_jornal_ofertas"
                    />
                    {/*      <PrecoInput
                      label="Desconto em Jornal de Ofertas"
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      value={
                        Number.isNaN(descontoJornalOfertas)
                          ? ''
                          : String(descontoJornalOfertas)
                      }
                      onChange={(value) =>
                        setDescontoJornalOfertas(parseFloat(value.target.value))
                      }
                      error={erros[14] !== ''}
                      helperText={erros[14]}
                      fullWidth
                      escala={6}
                    /> */}
                    <InputDesconto
                      tipoRepasse={tipoDescontoDescargoMercadorias}
                      value={descontoDescargoMercadorias}
                      onChange={setDescontoDescargoMercadorias}
                      errorMessage={erros[15]}
                      onChangeTipo={setTipoDescontoDescargoMercadorias}
                      label="Desconto em Descargo de Mercadorias"
                      name="desconto_descargo_mercadorias"
                    />
                    {/*          <PrecoInput
                      label="Desconto em Descargo de Mercadorias"
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      value={
                        Number.isNaN(descontoDescargoMercadorias)
                          ? ''
                          : String(descontoDescargoMercadorias)
                      }
                      onChange={(value) =>
                        setDescontoDescargoMercadorias(
                          parseFloat(value.target.value),
                        )
                      }
                      error={erros[15] !== ''}
                      helperText={erros[15]}
                      fullWidth
                      escala={6}
                    /> */}
                  </Box>
                </Box>
                <div style={{ overflowX: 'scroll' }}>
                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      variant="h4"
                      gutterBottom
                      style={{ marginTop: '10px' }}
                    >
                      Análise
                    </Typography>
                    {linhas.length > 0 ? (
                      <Tooltip title="Gráfico de pizza">
                        <IconButton
                          aria-label="delete"
                          onClick={() => abrirGrafico(linhas[0].dadosOriginais)}
                        >
                          <DataUsageIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </div>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          colSpan={8}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_PRECO_VENDA,
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          Geral
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          colSpan={2}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_PRECO_VENDA,
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Preço de Venda Calculado
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_PRECO_VENDA,
                          }}
                        >
                          Valor Negociado com a indústria
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_PRECO_VENDA,
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Custo final do produto
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>

                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'yellow',
                            fontSize: '30px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          {precoVenda}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {precoVendaTotal}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {valorNegociadoComAIndustriaPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {valorNegociadoComAIndustria}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {valorNegociadoComAIndustriaTotal}
                        </StyledTableCell>

                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {custoFinalDoProdutoPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'yellow',
                            fontSize: '30px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          {custoFinalDoProduto}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {custoFinalDoProdutoTotal}
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_PRECO_VENDA,
                          }}
                        >
                          Conferência do Gráfico de Seção Circular
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_PRECO_VENDA,
                          }}
                        >
                          Margem Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_PRECO_VENDA,
                          }}
                        >
                          Quantidade Comprada
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {conferenciaDoGraficoDeSecaoCircular}
                        </StyledTableCell>

                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {margemTotalPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {margemTotal}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {margemTotalTotal}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {quantidadeComprada}
                        </StyledTableCell>
                        {/*<StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <Tooltip title="Gráfico de pizza">
                              <IconButton
                                aria-label="delete"
                                onClick={() => abrirGrafico(dadosOriginais)}
                              >
                                <DataUsageIcon />
                              </IconButton>
                            </Tooltip>
                          </StyledTableCell>*/}
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          colSpan={15}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          PREVISÃO DE LUCRO
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                          }}
                        >
                          <Tooltip title="Custo Livre de Impostos">
                            <div>CLI</div>
                          </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                          }}
                        >
                          Lucro Líquido
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={4}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                          }}
                        >
                          IRPJ
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={4}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                          }}
                        >
                          CSLL
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                          }}
                        >
                          Lucro Bruto
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          % PV
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          % do LB
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          % PV
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          % do LB
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          % do LB
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {custoLivreDeImpostoPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {lucroLiquidoPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {lucroLiquido}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {lucroLiquidoTotal}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {irpjPorcentagemPv}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {irpjPorcentagemLb}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {irpj}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {irpjTotal}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {csllPorcentagemPv}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {csllPorcentagemLb}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {csll}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {csllTotal}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {lucroBrutoPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {lucroBruto}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {lucroBrutoTotal}
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          colSpan={13}
                          rowSpan={1}
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                            backgroundColor: BACKGROUND_CREDITO_COMPRA,
                          }}
                        >
                          Crédito de Compra
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          colSpan={4}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_CREDITO_COMPRA,
                          }}
                        >
                          ALÍQUOTA INTERNA / ICMS NORMAL
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_CREDITO_COMPRA,
                          }}
                        >
                          PIS
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_CREDITO_COMPRA,
                          }}
                        >
                          COFINS
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_CREDITO_COMPRA,
                          }}
                        >
                          TOTAL DE CRÉDITOS
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          Estado
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {estadoOrigem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {aliquotaInternaCompraPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {aliquotaInternaCompra}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {aliquotaInternaCompraTotal}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {pisCompraPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {pisCompra}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {pisCompraTotal}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {cofinsCompraPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {cofinsCompra}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {cofinsCompraTotal}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {totalDeCreditosCompraPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {totalDeCreditosCompra}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {totalDeCreditosCompraTotal}
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          colSpan={13}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_DEBITO_VENDA,
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          Débito de Venda
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          colSpan={4}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_DEBITO_VENDA,
                          }}
                        >
                          ALÍQUOTA INTERNA / ICMS NORMAL
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_DEBITO_VENDA,
                          }}
                        >
                          PIS
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_DEBITO_VENDA,
                          }}
                        >
                          CONFINS
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_DEBITO_VENDA,
                          }}
                        >
                          TOTAL DE DÉBITOS
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          Estado
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {estadoDestino}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {aliquotaInternaVendaPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {aliquotaInternaVenda}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {aliquotaInternaVendaTotal}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {pisVendaPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {pisVenda}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {pisVendaTotal}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {cofinsVendaPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {cofinsVenda}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {cofinsVendaTotal}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {totalDeDebitosVendaPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {totalDeDebitosVenda}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {totalDeDebitosVendaTotal}
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          colSpan={15}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          Análise de Fluxo de Caixa
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={2}
                          style={{
                            backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                          }}
                        >
                          <Tooltip
                            title="Usado em caso de compras de dentro do
                        estado. Havendo Antecipação não pode existir Fronteira."
                          >
                            <div>ICMS ANTECIPAÇÃO</div>
                          </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={8}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                          }}
                        >
                          ICMS de Fronteira
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={2}
                          style={{
                            backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                          }}
                        >
                          <Tooltip
                            title="A SER COLOCADO COMO CRÉDITO NO
                          ICMS NORMAL DO PRÓXIMO MÊS"
                          >
                            <div>TOTAL DE ICMS ANTECIPADO</div>
                          </Tooltip>
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                          }}
                        >
                          <Tooltip
                            title="30% para produtos normais e 35% para
                        derivados do leite"
                          >
                            <div>Agregado do CNAE</div>
                          </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                          }}
                        >
                          MVA da Pauta
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                          }}
                        >
                          Cálculo da Pauta de ICMS de Fronteira
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={2}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                          }}
                        >
                          <Tooltip
                            title="Usado em caso de compras de fora do estado.
                          Havendo Fronteira não pode haver Antecipação."
                          >
                            <div>FRONTEIRA</div>
                          </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                          }}
                        >
                          ICMS de Fronteira Adotado
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {icmsAntecipacaoPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {icmsAntecipacao}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {icmsAntecipacaoTotal}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {agregadoDoCnaePorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {mvaDaPauta}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {calculoDaPautaDeIcmsDeFronteira}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {fronteiraPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {fronteira}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {icmsDeFronteiraAdotadoPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {icmsDeFronteiraAdotado}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {icmsDeFronteiraAdotadoTotal}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {totalDeIcmsAntecipadoPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {totalDeIcmsAntecipado}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {totalDeIcmsAntecipadoTotal}
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>

                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                          }}
                        >
                          Diferença de ICMS
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                          }}
                        >
                          Diferença de PIS
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                          }}
                        >
                          Diferença de COFINS
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                          }}
                        >
                          TRIBUTAÇÃO EFETIVA
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                          }}
                        >
                          RESULTADO DO FLUXO DE CAIXA
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {diferencaDeIcmsPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {diferencaDeIcms}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {diferencaDeIcmsTotal}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {diferencaDePisPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {diferencaDePis}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {diferencaDePisTotal}
                        </StyledTableCell>

                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {diferencaDeCofinsPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {diferencaDeCofins}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {diferencaDeCofinsTotal}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {tributacaoEfetivaPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {tributacaoEfetiva}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {tributacaoEfetivaTotal}
                        </StyledTableCell>

                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {resultadoDoFluxoDeCaixaPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {resultadoDoFluxoDeCaixa}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {resultadoDoFluxoDeCaixaTotal}
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>

                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          colSpan={27}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          Custos Adicionais
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                          }}
                        >
                          Repasse do IPI
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                          }}
                        >
                          Repasse de Frete Unitário
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                          }}
                        >
                          <Tooltip title="Substituição Tributária">
                            <div>Repasse de ST</div>
                          </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                          }}
                        >
                          Repasse de Seguros
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                          }}
                        >
                          Repasse de Outras Despesas
                        </StyledTableCell>
                      </TableRow>

                      <TableRow>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {repasseDoIpiPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {repasseDoIpi2}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {repasseDoIpiTotal}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {repasseDoFreteUnitarioPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {repasseDoFreteUnitario2}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {repasseDoFreteUnitarioTotal}
                        </StyledTableCell>

                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {repasseDeStPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {repasseDeSt2}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {repasseDeStTotal}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {repasseDeSegurosPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {repasseDeSeguros2}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {repasseDeSegurosTotal}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {repasseDeOutrasDespesasPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {repasseDeOutrasDespesas2}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {repasseDeOutrasDespesasTotal}
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                          }}
                        >
                          Margem de Custo operacional
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                          }}
                        >
                          Margem de Custo de cartão
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                          }}
                        >
                          Total de Custos de Repasses
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                          }}
                        >
                          Custo final do produto
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {margemDeCustoOperacionalPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {margemDeCustoOperacional}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {margemDeCustoOperacionalTotal}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {margemDeCustoDeCartaoPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {margemDeCustoDeCartao}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {margemDeCustoDeCartaoTotal}
                        </StyledTableCell>

                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {totalDeCustosDeRepassesPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {totalDeCustosDeRepasses}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {totalDeCustosDeRepassesTotal}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {custoFinalDoProdutoPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {custoFinalDoProduto}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {custoFinalDoProdutoTotal}
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>

                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          colSpan={9}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_DESCONTOS,
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          Descontos
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_DESCONTOS,
                          }}
                        >
                          Desconto em Nfe
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_DESCONTOS,
                          }}
                        >
                          Desconto em Boleto
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_DESCONTOS,
                          }}
                        >
                          Desconto em Bonificação
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {descontoNfePorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {descontoNfe2}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {descontoNfeTotal}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {descontoBoletoPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {descontoBoleto2}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {descontoBoletoTotal}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {descontoBonificacaoPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {descontoBonificacao2}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {descontoBonificacaoTotal}
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_DESCONTOS,
                          }}
                        >
                          Desconto em Backlight
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_DESCONTOS,
                          }}
                        >
                          Desconto em Jornal de Ofertas
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_DESCONTOS,
                          }}
                        >
                          Desconto em Descargo de Mercadorias
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={3}
                          rowSpan={1}
                          style={{
                            backgroundColor: BACKGROUND_DESCONTOS,
                            fontWeight: 'bold',
                          }}
                        >
                          Descontos Totais
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          R$ Total
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {descontoBacklightPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {descontoBacklight2}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {descontoBacklightTotal}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {descontoJornalOfertasPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {descontoJornalOfertas2}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {descontoJornalOfertasTotal}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {descontoDescargoMercadoriasPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {descontoDescargoMercadorias2}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {descontoDescargoMercadoriasTotal}
                        </StyledTableCell>

                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {descontoPorcentagem}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {descontoTotal}
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {descontoTotalTotal}
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
                {/*<div style={{ overflowX: 'scroll' }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginTop: '10px' }}
                >
                  Análise
                  </Typography>
                               <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={3}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        Valor Negociado com a indústria
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={15}
                        rowSpan={2}
                        style={{
                          backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                        }}
                      >
                        PREVISÃO DE LUCRO
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={2}
                        rowSpan={3}
                        style={{
                          backgroundColor: BACKGROUND_PRECO_VENDA,
                        }}
                      >
                        Preço de venda
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={13}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_CREDITO_COMPRA,
                        }}
                      >
                        Crédito de Compra
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={10}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_DEBITO_VENDA,
                        }}
                      >
                        Débito de Venda
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={29}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                        }}
                      >
                        Análise de Fluxo de Caixa
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={27}
                        rowSpan={2}
                        style={{
                          backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                        }}
                      >
                        Custos Adicionais
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={3}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        Conferência do Gráfico de Seção Circular
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={3}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        Margem Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={3}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        Data de Validade
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={3}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        Quantidade Comprada
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={4}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        Ações
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        colSpan={4}
                        rowSpan={2}
                        style={{
                          backgroundColor: BACKGROUND_CREDITO_COMPRA,
                        }}
                      >
                        ALÍQUOTA INTERNA / ICMS NORMAL
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={2}
                        style={{
                          backgroundColor: BACKGROUND_CREDITO_COMPRA,
                        }}
                      >
                        PIS
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={2}
                        style={{
                          backgroundColor: BACKGROUND_CREDITO_COMPRA,
                        }}
                      >
                        COFINS
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={2}
                        style={{
                          backgroundColor: BACKGROUND_CREDITO_COMPRA,
                        }}
                      >
                        TOTAL DE CRÉDITOS
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={2}
                        style={{
                          backgroundColor: BACKGROUND_DEBITO_VENDA,
                        }}
                      >
                        ALÍQUOTA INTERNA / ICMS NORMAL
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={2}
                        rowSpan={2}
                        style={{
                          backgroundColor: BACKGROUND_DEBITO_VENDA,
                        }}
                      >
                        PIS
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={2}
                        rowSpan={2}
                        style={{
                          backgroundColor: BACKGROUND_DEBITO_VENDA,
                        }}
                      >
                        CONFINS
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={2}
                        style={{
                          backgroundColor: BACKGROUND_DEBITO_VENDA,
                        }}
                      >
                        TOTAL DE DÉBITOS
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={2}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                        }}
                      >
                        ICMS ANTECIPAÇÃO (Usado em caso de compras de dentro do
                        estado. Havendo Antecipação não pode existir Fronteira.)
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={8}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                        }}
                      >
                        ICMS de Fronteira
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={2}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                        }}
                      >
                        TOTAL DE ICMS ANTECIPADO (A SER COLOCADO COMO CRÉDITO NO
                        ICMS NORMAL DO PRÓXIMO MÊS)
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={2}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                        }}
                      >
                        Diferença de ICMS
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={2}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                        }}
                      >
                        Diferença de PIS
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={2}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                        }}
                      >
                        Diferença de COFINS
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={2}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                        }}
                      >
                        TRIBUTAÇÃO EFETIVA
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={2}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                        }}
                      >
                        RESULTADO DO FLUXO DE CAIXA
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                        }}
                      >
                        CLI (Custo Livre de Impostos)
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                        }}
                      >
                        Lucro Líquido
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={4}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                        }}
                      >
                        IRPJ
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={4}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                        }}
                      >
                        CSLL
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                        }}
                      >
                        Lucro Bruto
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                        }}
                      >
                        Agregado do CNAE (30% para produtos normais e 35% para
                        derivados do leite)
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                        }}
                      >
                        MVA da Pauta
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                        }}
                      >
                        Cálculo da Pauta de ICMS de Fronteira
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={2}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                        }}
                      >
                        FRONTEIRA (Usado em caso de compras de fora do estado.
                        Havendo Fronteira não pode haver Antecipação.)
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                        }}
                      >
                        ICMS de Fronteira Adotado
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                        }}
                      >
                        Margem de Custo operacional
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                        }}
                      >
                        Margem de Custo de cartão
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                        }}
                      >
                        Repasse do IPI
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                        }}
                      >
                        Repasse de Frete Unitário
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                        }}
                      >
                        Repasse de ST (Substituição Tributária)
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                        }}
                      >
                        Repasse de Seguros
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                        }}
                      >
                        Repasse de Outras Despesas
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                        }}
                      >
                        Total de Custos de Repasses
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                        }}
                      >
                        Custo final do produto
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        % PV
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        % do LB
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        % PV
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        % do LB
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        % do LB
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        Estado
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      />
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {linhas.map(
                      ({
                        valorNegociadoComAIndustriaPorcentagem,
                        valorNegociadoComAIndustria,
                        valorNegociadoComAIndustriaTotal,
                        custoLivreDeImpostoPorcentagem,
                        lucroLiquidoPorcentagem,
                        lucroLiquido,
                        lucroLiquidoTotal,
                        irpjPorcentagemPv,
                        irpjPorcentagemLb,
                        irpj,
                        irpjTotal,
                        csllPorcentagemPv,
                        csllPorcentagemLb,
                        csll,
                        csllTotal,
                        lucroBrutoPorcentagem,
                        lucroBruto,
                        lucroBrutoTotal,
                        precoVenda,
                        precoVendaTotal,
                        estadoOrigem,
                        aliquotaInternaCompraPorcentagem,
                        aliquotaInternaCompra,
                        aliquotaInternaCompraTotal,
                        pisCompraPorcentagem,
                        pisCompra,
                        pisCompraTotal,
                        cofinsCompraPorcentagem,
                        cofinsCompra,
                        cofinsCompraTotal,
                        totalDeCreditosCompraPorcentagem,
                        totalDeCreditosCompra,
                        totalDeCreditosCompraTotal,
                        aliquotaInternaVendaPorcentagem,
                        aliquotaInternaVenda,
                        aliquotaInternaVendaTotal,
                        pisVendaPorcentagem,
                        pisVenda,
                        pisVendaTotal,
                        cofinsVendaPorcentagem,
                        cofinsVenda,
                        cofinsVendaTotal,
                        totalDeDebitosVendaPorcentagem,
                        totalDeDebitosVenda,
                        totalDeDebitosVendaTotal,
                        icmsAntecipacaoPorcentagem,
                        icmsAntecipacao,
                        icmsAntecipacaoTotal,
                        agregadoDoCnaePorcentagem,
                        mvaDaPauta,
                        calculoDaPautaDeIcmsDeFronteira,
                        fronteiraPorcentagem,
                        fronteira,
                        icmsDeFronteiraAdotadoPorcentagem,
                        icmsDeFronteiraAdotado,
                        icmsDeFronteiraAdotadoTotal,
                        totalDeIcmsAntecipadoPorcentagem,
                        totalDeIcmsAntecipado,
                        totalDeIcmsAntecipadoTotal,
                        diferencaDeIcmsPorcentagem,
                        diferencaDeIcms,
                        diferencaDeIcmsTotal,
                        diferencaDePisPorcentagem,
                        diferencaDePis,
                        diferencaDePisTotal,
                        diferencaDeCofinsPorcentagem,
                        diferencaDeCofins,
                        diferencaDeCofinsTotal,
                        tributacaoEfetivaPorcentagem,
                        tributacaoEfetiva,
                        tributacaoEfetivaTotal,
                        resultadoDoFluxoDeCaixaPorcentagem,
                        resultadoDoFluxoDeCaixa,
                        resultadoDoFluxoDeCaixaTotal,
                        margemDeCustoOperacionalPorcentagem,
                        margemDeCustoOperacional,
                        margemDeCustoOperacionalTotal,
                        margemDeCustoDeCartaoPorcentagem,
                        margemDeCustoDeCartao,
                        margemDeCustoDeCartaoTotal,
                        repasseDoIpiPorcentagem,
                        repasseDoIpi,
                        repasseDoIpiTotal,
                        repasseDoFreteUnitarioPorcentagem,
                        repasseDoFreteUnitario,
                        repasseDoFreteUnitarioTotal,
                        repasseDeStPorcentagem,
                        repasseDeSt,
                        repasseDeStTotal,
                        repasseDeSegurosPorcentagem,
                        repasseDeSeguros,
                        repasseDeSegurosTotal,
                        repasseDeOutrasDespesasPorcentagem,
                        repasseDeOutrasDespesas,
                        repasseDeOutrasDespesasTotal,
                        totalDeCustosDeRepassesPorcentagem,
                        totalDeCustosDeRepasses,
                        totalDeCustosDeRepassesTotal,
                        custoFinalDoProdutoPorcentagem,
                        custoFinalDoProduto,
                        custoFinalDoProdutoTotal,
                        conferenciaDoGraficoDeSecaoCircular,
                        margemTotalPorcentagem,
                        margemTotal,
                        margemTotalTotal,
                        dataDeValidade,
                        quantidadeComprada,
                        dadosOriginais,
                      }) => (
                        <TableRow>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {valorNegociadoComAIndustriaPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {valorNegociadoComAIndustria}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {valorNegociadoComAIndustriaTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {custoLivreDeImpostoPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {lucroLiquidoPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {lucroLiquido}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {lucroLiquidoTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {irpjPorcentagemPv}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {irpjPorcentagemLb}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {irpj}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {irpjTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {csllPorcentagemPv}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {csllPorcentagemLb}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {csll}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {csllTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {lucroBrutoPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {lucroBruto}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {lucroBrutoTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {precoVenda}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {precoVendaTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {estadoOrigem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {aliquotaInternaCompraPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {aliquotaInternaCompra}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {aliquotaInternaCompraTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {pisCompraPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {pisCompra}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {pisCompraTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {cofinsCompraPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {cofinsCompra}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {cofinsCompraTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totalDeCreditosCompraPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totalDeCreditosCompra}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totalDeCreditosCompraTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {aliquotaInternaVendaPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {aliquotaInternaVenda}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {aliquotaInternaVendaTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {pisVenda}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {pisVendaTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {cofinsVenda}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {cofinsVendaTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totalDeDebitosVendaPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totalDeDebitosVenda}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totalDeDebitosVendaTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {icmsAntecipacaoPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {icmsAntecipacao}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {icmsAntecipacaoTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {agregadoDoCnaePorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {mvaDaPauta}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {calculoDaPautaDeIcmsDeFronteira}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {fronteiraPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {fronteira}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {icmsDeFronteiraAdotadoPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {icmsDeFronteiraAdotado}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {icmsDeFronteiraAdotadoTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totalDeIcmsAntecipadoPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totalDeIcmsAntecipado}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totalDeIcmsAntecipadoTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {diferencaDeIcmsPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {diferencaDeIcms}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {diferencaDeIcmsTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {diferencaDePisPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {diferencaDePis}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {diferencaDePisTotal}
                          </StyledTableCell>

                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {diferencaDeCofinsPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {diferencaDeCofins}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {diferencaDeCofinsTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {tributacaoEfetivaPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {tributacaoEfetiva}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {tributacaoEfetivaTotal}
                          </StyledTableCell>

                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {resultadoDoFluxoDeCaixaPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {resultadoDoFluxoDeCaixa}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {resultadoDoFluxoDeCaixaTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {margemDeCustoOperacionalPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {margemDeCustoOperacional}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {margemDeCustoOperacionalTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {margemDeCustoDeCartaoPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {margemDeCustoDeCartao}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {margemDeCustoDeCartaoTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {repasseDoIpiPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {repasseDoIpi}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {repasseDoIpiTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {repasseDoFreteUnitarioPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {repasseDoFreteUnitario}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {repasseDoFreteUnitarioTotal}
                          </StyledTableCell>

                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {repasseDeStPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {repasseDeSt}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {repasseDeStTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {repasseDeSegurosPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {repasseDeSeguros}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {repasseDeSegurosTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {repasseDeOutrasDespesasPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {repasseDeOutrasDespesas}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {repasseDeOutrasDespesasTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totalDeCustosDeRepassesPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totalDeCustosDeRepasses}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totalDeCustosDeRepassesTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {custoFinalDoProdutoPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {custoFinalDoProduto}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {custoFinalDoProdutoTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {conferenciaDoGraficoDeSecaoCircular}
                          </StyledTableCell>

                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {margemTotalPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {margemTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {margemTotalTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dataDeValidade}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {quantidadeComprada}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <Tooltip title="Gráfico de pizza">
                              <IconButton
                                aria-label="delete"
                                onClick={() => abrirGrafico(dadosOriginais)}
                              >
                                <DataUsageIcon />
                              </IconButton>
                            </Tooltip>
                          </StyledTableCell>
                        </TableRow>
                      ),
                    )}
                  </TableBody>
                          </Table>
              </div>*/}
              </div>
            </DialogContent>
          ) : (
            <DialogContent dividers>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '40px',
                  fontWeight: 'bold',
                }}
              >
                Carregando...
              </div>
            </DialogContent>
          )}
          <DialogActions
            style={{
              padding: '0px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={() => handleCloseDialog()}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="contained"
              color="primary"
            >
              {label1}
            </Button>
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                disabled={erroExistente || carregando}
                variant="contained"
                color="primary"
              >
                {label2}
              </Button>
              {carregando && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Dialog>
        <DialogoCreateImpressao
          handleClose={() => {}}
          ref={refDialogoImpressao}
        />
        <DialogoProduto
          ref={refDialogoProduto}
          handleClose={(idUpdate) => {
            getProduto(idUpdate);
          }}
          isImprimir={false}
        />
        <DialogoGrafico ref={refDialogoGrafico} />
      </div>
    );
  },
);

export default DialogoBoletos;
