import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CloseIcon from '@material-ui/icons/Close';
import TodayIcon from '@material-ui/icons/Today';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useSnackbar } from 'notistack';

import { AutoCompleteLocalCustomizado, DateInputBonito } from '../..';
import { getListAllAPI, api } from '../../../services';
import { formatMoeda } from '../../../utils';
import { STATUS_ATIVO } from '../../../utils/constants';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, textAlign = 'left', ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5" style={{ fontWeight: 'bold', textAlign }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: 'black' }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const useStyles3 = makeStyles({
  chip: {
    borderColor: (props) => props.color,
    backgroundColor: 'white',
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  deleteIcon: {
    color: (props) => props.color,
    cursor: 'pointer',
    '&:hover': {
      color: (props) => props.color,
    },
  },
});

const ChipConta = ({ option, injetado, campo }) => {
  const classes2 = useStyles3({ color: option.color });
  const { onDelete, ...rest } = injetado;
  return (
    <Chip
      classes={{ outlined: classes2.chip, deleteIcon: classes2.deleteIcon }}
      variant="outlined"
      label={option[campo]}
      {...rest}
      avatar={
        <Avatar
          style={{
            backgroundColor: option.color,
            marginRight: '3px',
            color: 'white',
          }}
        >
          {option.nome.charAt(0)}
        </Avatar>
      }
    />
  );
};

const DEFAULT_FATURA = null;
const DEFAULT_CONTA = [];
const DEFAULT_DATA_PAGAMENTO = new Date();

const ETAPA_INICIO = 0;
const ETAPA_FIM = 1;

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose, campo = 'nome' }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [fatura, setFatura] = useState(DEFAULT_FATURA);
    const [conta, setConta] = useState(DEFAULT_CONTA);
    const [contasOptions, setContasOptions] = useState([]);
    const [dataPagamento, setDataPagamento] = useState(DEFAULT_DATA_PAGAMENTO);
    const [etapa, setEtapa] = useState(ETAPA_INICIO);
    const classes = useStyles();

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    async function getDataResource(cartao_credito_id, mes, ano) {
      try {
        const data = await getListAllAPI(
          'faturas',
          ['id', 'asc'],
          { cartao_credito_id, mes, ano },
          [],
        );
        if (data.data.length > 0) {
          await getContas(data.data[0].cartao.conta.id);
          const dataPagamentoFatura = new Date();
          dataPagamentoFatura.setDate(data.data[0].cartao.dia_pagamento);
          dataPagamentoFatura.setMonth(mes);
          dataPagamentoFatura.setFullYear(ano);
          const descricaoFatura = `${format(
            dataPagamentoFatura,
            "dd 'de' MMMM 'de' yyyy",
            {
              locale: ptBR,
            },
          )}`;
          data.data[0].descricao = descricaoFatura;
          setConta([data.data[0].cartao.conta]);
          setFatura(data.data[0]);
        } else {
          throw 'Esta fatura não existe!';
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    async function handleSalvar() {
      try {
        await api.put(
          `/pagar_total_fatura/${fatura.cartao_credito_id}/${fatura.mes}/${fatura.ano}`,
          {
            dataPagamento,
            conta_id: conta[0].id,
          },
        );
        enqueueSnackbar('Fatura paga com sucesso!', {
          variant: 'success',
        });
        handleCloseDialog();
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    useImperativeHandle(ref, () => ({
      handleOpen(cartao_credito_id, mes, ano) {
        getDataResource(cartao_credito_id, mes, ano);
        setEtapa(ETAPA_INICIO);
        setOpen(true);
      },
    }));

    const renderTagConta = (option, injetado) => (
      <ChipConta option={option} injetado={injetado} campo={campo} />
    );

    const renderOptionConta = (option) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          style={{
            backgroundColor: option.color,
            marginRight: '10px',
            width: '30px',
            height: '30px',
          }}
        >
          {option.nome.charAt(0)}
        </Avatar>
        {option[campo]}
      </div>
    );

    const handleOnChangeConta = (value) => {
      if (value.length > 0) {
        if (value.length === 1) {
          setConta([value[0]]);
        } else if (value.length === 2) {
          setConta([value[1]]);
        }
      } else {
        setConta([]);
      }
    };

    async function getContas(id = 0) {
      try {
        const dataContas = await getListAllAPI(
          'contas_normal',
          ['id', 'asc'],
          id === 0
            ? { is_caixa: false, status: STATUS_ATIVO }
            : { is_caixa: false, status: STATUS_ATIVO, ids_incluso: [id] },
          [],
        );
        setContasOptions(dataContas.data);
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    /* useEffect(() => {
      
      if (open) {
        getContas();
      }
    }, [open]); */

    function validateConta() {
      let error = '';
      if (conta.length <= 0) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function getErros() {
      const errosOld = [''];
      errosOld[0] = validateConta();
      return errosOld;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    function getDadosFatura() {
      if (fatura) {
        return {
          nomeCartao: fatura.cartao.nome,
          valorFatura: fatura.valor,
          nomeFatura: fatura.descricao,
        };
      }
      return {
        nomeCartao: '',
        valorFatura: 0,
        nomeFatura: '',
      };
    }

    const { nomeCartao, nomeFatura, valorFatura } = getDadosFatura();

    return (
      <div>
        {etapa === ETAPA_FIM ? (
          <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            classes={{ paper: classes.rootDialogo }}
            className={classes.rootDialogo}
            fullWidth
            maxWidth="sm"
            TransitionComponent={Transition}
            keepMounted
          >
            <DialogTitle
              id="form-dialog-title"
              onClose={handleCloseDialog}
              style={{ color: 'black' }}
            >
              Pagamento total
            </DialogTitle>
            <DialogContent
              style={{
                padding: '0px 36px 20px',
                margin: '0',
              }}
            >
              <div>
                <div
                  style={{
                    marginBottom: '16px',
                    color: 'rgba(0, 0, 0, 0.87)',
                    fontWeight: 500,
                    fontSize: '1.15rem',
                  }}
                >
                  {`Cartão: ${nomeCartao}`}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                >
                  <div
                    style={{ color: 'rgba(0, 0, 0, 0.54)', fontWeight: 400 }}
                  >
                    Fatura:
                  </div>
                  <div
                    style={{ color: 'rgba(0, 0, 0, 0.87)', fontWeight: 500 }}
                  >
                    {nomeFatura}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '30px',
                  }}
                >
                  <div
                    style={{ color: 'rgba(0, 0, 0, 0.54)', fontWeight: 400 }}
                  >
                    Valor total:
                  </div>
                  <div
                    style={{ color: 'rgba(0, 0, 0, 0.87)', fontWeight: 500 }}
                  >
                    {formatMoeda(valorFatura)}
                  </div>
                </div>
                <DateInputBonito
                  showTodayButton
                  disableFuture
                  todayLabel="Hoje"
                  name="data_pagamento"
                  handleEnter={() => {}}
                  style={{ marginBottom: '15px' }}
                  label="Data do pagamento"
                  handleKey={() => {}}
                  value={dataPagamento}
                  onChange={(value) => {
                    setDataPagamento(value);
                  }}
                  format="dd MMMM yyyy"
                  fullWidth
                  disabled={false}
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <>
                        <TodayIcon
                          style={{
                            marginRight: '24px',
                            color: 'rgba(0, 0, 0, 0.7)',
                          }}
                        />
                      </>
                    ),
                  }}
                />
                <AutoCompleteLocalCustomizado
                  name="conta"
                  handleEnter={() => {}}
                  label=""
                  handleKey={() => {}}
                  value={conta}
                  disableClearable
                  onChangeLocal={(value) => handleOnChangeConta(value)}
                  campo="nome"
                  options={contasOptions}
                  variant="standard"
                  renderTag={renderTagConta}
                  renderOption={renderOptionConta}
                  style={{ marginBottom: '15px' }}
                  disabled={false}
                  error={erros[0] !== ''}
                  helperText={erros[0]}
                  startAdornment={
                    <InputAdornment position="start">
                      <AccountBalanceIcon
                        style={{
                          marginRight: '16px',
                          color: 'rgba(0, 0, 0, 0.7)',
                        }}
                      />
                    </InputAdornment>
                  }
                />
              </div>
            </DialogContent>
            <DialogActions
              style={{
                padding: '0px 36px 20px',
                margin: '0',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                variant="contained"
                style={{ backgroundColor: 'white', color: '#00796b' }}
                color="primary"
              >
                CANCELAR
              </Button>
              <div className={classes.wrapper}>
                <Button
                  onClick={handleSalvar}
                  classes={{
                    root: classes.btnAtions,
                    disabled: classes.btnAtionsDisabled,
                  }}
                  variant="contained"
                  style={{ backgroundColor: '#00796b', color: 'white' }}
                  color="primary"
                  disabled={erroExistente}
                >
                  PAGAR TOTAL
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        ) : (
          <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            classes={{ paper: classes.rootDialogo }}
            className={classes.rootDialogo}
            fullWidth
            maxWidth="xs"
            TransitionComponent={Transition}
            keepMounted
          >
            <DialogTitle
              id="form-dialog-title"
              onClose={handleCloseDialog}
              style={{ color: 'black' }}
              textAlign="center"
            >
              Pagamento total
            </DialogTitle>
            <DialogContent
              style={{
                padding: '0px 36px 20px',
                margin: '0',
              }}
            >
              <div>
                <p
                  style={{
                    textAlign: 'center',
                    marginBottom: '8px',
                    color: 'rgba(0, 0, 0, 0.54)',
                    fontWeight: 400,
                  }}
                >
                  Trata-se do pagamento completo da sua fatura, recomendamos que
                  você utilize essa funcionalidade após o dia de fechamento.
                </p>
                <p
                  style={{
                    textAlign: 'center',
                    color: 'rgba(0, 0, 0, 0.54)',
                    fontWeight: 400,
                  }}
                >
                  Se sua fatura fechou com o valor de R$ 700,00, por exemplo,
                  aqui você pagará esse valor todo de uma só vez.
                </p>
              </div>
            </DialogContent>
            <DialogActions
              style={{
                padding: '0px 36px 20px',
                margin: '0',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => setEtapa(ETAPA_FIM)}
                variant="contained"
                style={{
                  backgroundColor: '#00796b',
                  color: 'white',
                  borderRadius: '25px',
                }}
                color="primary"
                fullWidth
              >
                PROSSEGUIR
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
