import React, { useImperativeHandle, forwardRef, useRef } from 'react';
import { useRefresh } from 'react-admin';

import { DialogoConfirmacao } from '../../../Components';
import { api, getUserId } from '../../../services';

const FormDialog = forwardRef((props, ref) => {
  const refresh = useRefresh();
  const refDialogoConfirmacao = useRef(null);

  useImperativeHandle(ref, () => ({
    handleOpen(record) {
      if (refDialogoConfirmacao.current) {
        refDialogoConfirmacao.current.handleOpen(
          'Criar Lista de conferências de freezers de hoje',
          'Tem certeza de que deseja criar a lista de hoje',
          record,
        );
      }
    },
  }));

  async function handleConfirma() {
    await api.post('/criar_lista_conferencia_freezers_de_hoje', {
      user_id: getUserId(),
    });
    refresh();
  }

  return (
    <DialogoConfirmacao
      ref={refDialogoConfirmacao}
      handleConfirma={handleConfirma}
    />
  );
});

export default FormDialog;
