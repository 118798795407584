import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import {
  TIPO_PRECIFICACAO_NORMAL,
  TIPO_PRECIFICACAO_COMPRA,
  TIPO_PRECIFICACAO_LIBERACAO_PRECO,
  TIPO_PRECIFICACAO_OFERTA,
  TIPO_PRECIFICACAO_ZERAR_ESTOQUE,
} from '../../../utils';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '400px',
  },
}));

function getTipoPrecificacao(value) {
  switch (value) {
    case TIPO_PRECIFICACAO_NORMAL:
      return 'Edição Normal';
    case TIPO_PRECIFICACAO_COMPRA:
      return 'Compra';
    case TIPO_PRECIFICACAO_LIBERACAO_PRECO:
      return 'Liberação de Preço';
    case TIPO_PRECIFICACAO_OFERTA:
      return 'Oferta Normal';
    case TIPO_PRECIFICACAO_ZERAR_ESTOQUE:
      return 'Oferta de Zerar Estoque';
    default:
      return '-';
  }
}

const DescricaoField = (props) => {
  const classes = useStyles();

  const record = useRecordContext(props);
  const value = record
    ? record.record
      ? getTipoPrecificacao(record.record.tipo)
      : ''
    : '';

  return (
    <Tooltip title={value} placement="bottom" aria-label="add2">
      <Typography className={classes.text}>{value}</Typography>
    </Tooltip>
  );
};

export default DescricaoField;
