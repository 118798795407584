import React from 'react';
import { TextField } from 'react-admin';

import EditButton from '../../Components/EditButton';
import ListTemplate from '../../Components/List';
import Filter from './Filter';

const List = (props) => (
  <ListTemplate {...props} filters={<Filter />} title="Taras balança">
    <TextField source="nome" label="Descrição" />
    <TextField source="codigo_interno" label="Código" />
    <TextField source="valor" label="Valor (g)" />
    <EditButton disabled={(record) => (record ? record.id === 1 : false)} />
  </ListTemplate>
);

export default List;
