import React, { cloneElement } from 'react';
import {
  TopToolbar,
  sanitizeListRestProps,
  ExportButton,
  Button,
} from 'react-admin';

import AddIcon from '@material-ui/icons/Add';

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  enabledCreate,
  handleCriar = () => {},
  handleCriarCompatibilizacao = () => {},
  ...rest
}) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {filters &&
      cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    {/* <ExportButton
      disabled={total === 0}
      resource={resource}
      filterValues={filterValues}
    /> */}
    <Button onClick={handleCriar} label="Novo">
      <AddIcon />
    </Button>
    <Button onClick={handleCriarCompatibilizacao} label="Novo Compatibilização">
      <AddIcon />
    </Button>
    {/* <Button onClick={handleCriarCompatibilizacao} label="Novo">
      Compatibilizar
    </Button> */}
  </TopToolbar>
);

export default ListActions;
