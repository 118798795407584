import * as React from 'react';
import { useRecordContext } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';

const ActionsField = ({ handleAtivar, ...rest }) => {
  const record = useRecordContext(rest);
  return (
    <div
      style={{
        marginRight: '0px',
        // width: '120px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Tooltip title="Restaurar" placement="bottom" aria-label="Restaurar">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleAtivar(record)}
        >
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ActionsField;
