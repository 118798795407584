import * as React from 'react';
import { useRecordContext } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

const ActionsField = ({
  tipo,
  handleEdit,
  handleDelete,
  handleChangeStatus,
  filtroStatus,
  ...rest
}) => {
  const record = useRecordContext(rest);

  return (
    <div
      style={{
        marginRight: '0px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Tooltip title="Editar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="edit"
          size="small"
          onClick={() => handleEdit(record.record)}
          style={{ color: '#00897B' }}
        >
          <EditOutlinedIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};



export default ActionsField;
