import React from 'react';
import {
  Filter as FilterAdmin,
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

import { PeriodoInput, PrecoInput } from '../../Components/InputsReactAdmin';
import { TIPO_TRANSACAO_ESPECIAL_VERBA, STATUS_ATIVO } from '../../utils';

const choices = [{ id: TIPO_TRANSACAO_ESPECIAL_VERBA, name: 'Verba' }];

const Filter = (props) => (
  <FilterAdmin {...props}>
    <TextInput label="Descrição" source="descricao" />
    <PeriodoInput
      source="periodo"
      label="Período"
      defaultValue={{
        start: new Date(),
        end: new Date(),
      }}
    />
    <PrecoInput source="valor" label="Valor" fixedDecimalScale={false} />
    <SelectInput
      source="tipo"
      choices={choices}
      label="Tipo"
      allowEmpty={false}
    />
    <ReferenceInput
      fullWidth
      label="Criador"
      source="criador_id"
      reference="users"
      sort={{ field: 'username', order: 'ASC' }}
      filterToQuery={(searchText) => ({ username: `${searchText}` })}
      perPage={10}
      allowEmpty={false}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <ReferenceInput
      fullWidth
      label="último a atualizar"
      source="atualizador_id"
      reference="users"
      sort={{ field: 'username', order: 'ASC' }}
      filterToQuery={(searchText) => ({ username: `${searchText}` })}
      perPage={10}
      allowEmpty={false}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <ReferenceInput
      fullWidth
      label="Quem deletou"
      source="deletador_id"
      reference="users"
      sort={{ field: 'username', order: 'ASC' }}
      filterToQuery={(searchText) => ({ username: `${searchText}` })}
      perPage={10}
      allowEmpty={false}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    {/*<ReferenceInput
      fullWidth
      label="Verba do Produto"
      source="id_filtro_transacoes_especiais"
      reference="users"
      sort={{ field: 'id', order: 'DESC' }}
      filterToQuery={(searchText) => ({
        id_filtro_transacoes_especiais: `${searchText}`,
      })}
      perPage={10}
      allowEmpty={false}
    >
      <AutocompleteInput optionText="ID" />
    </ReferenceInput>*/}
  </FilterAdmin>
);

export default Filter;
