import React from 'react';
import {
  TextInput,
  DateTimeInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  FormDataConsumer,
  DateInput,
} from 'react-admin';

import { Typography, Box } from '@material-ui/core';

import { PrecoInput, IntegerInput } from '../../Components/InputsReactAdmin';
import { choicesEdit, choicesTiposEdicao } from './choices';

const Form = ({ isCreate = true }) => (
  <Box p="1em">
    <Box display="flex">
      <Box flex={2} mr="1em">
        <Typography variant="h6" gutterBottom>
          Geral
        </Typography>

        <Box display="flex">
          <Box flex={1} mr="0.5em">
            <TextInput fullWidth source="nome" label="Descrição" autoFocus />
          </Box>
          <Box flex={1} ml="0.5em">
            <FormDataConsumer>
              {({ formData, dispatch, ...rest }) => (
                <PrecoInput
                  fullWidth
                  source="valor"
                  label="Valor"
                  disabled={formData.tipo_edicao === 2 && isCreate === false}
                />
              )}
            </FormDataConsumer>
          </Box>
          <Box flex={1} ml="0.5em">
            <FormDataConsumer>
              {({ formData, dispatch, ...rest }) => (
                <DateInput
                  source="data_pagamento"
                  label="Data de pagamento"
                  fullWidth
                  disabled={formData.tipo_edicao !== 0 && isCreate === false}
                />
              )}
            </FormDataConsumer>
          </Box>
        </Box>

        <Box display="flex">
          <Box flex={1} mr="0.5em">
            <ReferenceInput
              source="categoria_transacao_id"
              reference="categorias_transacao"
              label="Categoria"
              filter={isCreate ? { ativo: true } : {}}
              fullWidth
            >
              <SelectInput optionText="nome" />
            </ReferenceInput>
          </Box>
          <Box flex={1} ml="0.5em">
            <FormDataConsumer>
              {({ formData, dispatch, ...rest }) => (
                <ReferenceInput
                  source="conta_id"
                  reference="contas"
                  label="Conta"
                  fullWidth
                  disabled={formData.tipo_edicao === 2 && isCreate === false}
                >
                  <SelectInput optionText="nome" />
                </ReferenceInput>
              )}
            </FormDataConsumer>
          </Box>
          <Box flex={1} ml="0.5em">
            <FormDataConsumer>
              {({ formData, dispatch, ...rest }) => (
                <BooleanInput
                  source="status"
                  label="Foi Recebido"
                  fullWidth
                  disabled={formData.tipo_edicao !== 0 && isCreate === false}
                />
              )}
            </FormDataConsumer>
          </Box>
        </Box>

        <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => (
            <>
              {!isCreate && formData.grupo_id && (
                <Box display="flex">
                  <Box flex={1} mr="0.5em">
                    <SelectInput
                      source="tipo_edicao"
                      choices={choicesTiposEdicao}
                      label="Você deseja"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} ml="0.5em" />
                  <Box flex={1} ml="0.5em" />
                </Box>
              )}
            </>
          )}
        </FormDataConsumer>

        {isCreate && (
          <Box display="flex">
            <Box flex={1} ml="20px">
              {isCreate && (
                <BooleanInput
                  source="fixo"
                  label="Recorrente"
                  fullWidth
                  disabled={!isCreate}
                />
              )}
            </Box>
            <Box flex={1} ml="0.5em">
              <FormDataConsumer>
                {({ formData, dispatch, ...rest }) => (
                  <>
                    {formData.fixo && (
                      <IntegerInput
                        source="repeticoes"
                        label="Repetições"
                        fullWidth
                      />
                    )}
                  </>
                )}
              </FormDataConsumer>
            </Box>
            <Box flex={1} ml="0.5em">
              <FormDataConsumer>
                {({ formData, dispatch, ...rest }) => (
                  <>
                    {formData.fixo && (
                      <SelectInput
                        source="tipo_periodo"
                        choices={choicesEdit}
                        label="Forma de recorrencia"
                        fullWidth
                      />
                    )}
                  </>
                )}
              </FormDataConsumer>
            </Box>
          </Box>
        )}

        <Box mt="1em" />
      </Box>
    </Box>
  </Box>
);

export default Form;
