import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
} from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { formatPeso, formatUnidades } from '../../../utils';
import Grid from './GridSomaPesos';

const useStyles = makeStyles((theme) =>
  createStyles({
    action: {
      backgroundColor: theme.palette.secondary.main,
    },
    root: {
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

const DialogoSomaPesosCerto = forwardRef(({ onChangeTotal }, ref) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [totais, setTotais] = useState({ unidades: 0, peso: 0 });
  const refGrid = useRef(null);

  function handleReset() {
    if (refGrid.current) refGrid.current.handleReset(0, 0);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleSalvar = () => {
    onChangeTotal(totais);
    handleClose();
  };

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
    },
  }));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Total Unidades e pesos</DialogTitle>
      <DialogContent dividers style={{ padding: '10px' }}>
        <div className={classes.root}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '0px',
            }}
          >
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p style={{ color: 'red', marginRight: '10px' }}>
                Totais Unidades:
              </p>
              {formatUnidades(totais.unidades)}
            </div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p style={{ color: 'red', marginRight: '10px' }}>Totais Peso:</p>
              {`${formatPeso(totais.peso)} Kg`}
            </div>
          </div>

          <Grid onChangeTotal={(value) => setTotais(value)} ref={refGrid} />
        </div>
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button
          onClick={handleReset}
          color="primary"
          style={{ color: 'white' }}
        >
          Resetar
        </Button>
        <Button
          onClick={handleClose}
          color="primary"
          style={{ color: 'white' }}
        >
          Fechar
        </Button>
        <Button
          onClick={handleSalvar}
          color="primary"
          style={{ color: 'white' }}
        >
          Obter
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default DialogoSomaPesosCerto;
