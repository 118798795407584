import React, {
  useRef,
  cloneElement,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  TextField,
  EditButton,
  DateField,
  NumberField,
  ReferenceField,
  SelectField,
  List,
  Datagrid,
  DatagridBody,
  ListBase,
  ListToolbar,
  BulkActionsToolbar,
  Pagination,
  useListContext,
  useRefresh,
  useVersion,
} from 'react-admin';
import { useHistory } from 'react-router-dom';
// import { useVersion } from 'react-admin';

import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Button,
  Box,
  makeStyles,
} from '@material-ui/core';
import Avatar2 from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Drawer from '@material-ui/core/Drawer';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import { ArrowDownward } from '@material-ui/icons';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import StarIcon from '@material-ui/icons/Star';
import Skeleton from '@material-ui/lab/Skeleton';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import MaterialIcon from 'material-icons-react';
import moment from 'moment';
import { stringify } from 'query-string';

import {
  DialogoTransacao,
  DialogoChangeStatusTransacao,
  DialogoDeletarTransacao,
  AutoCompleteLocalCustomizado,
  DialogoDespesaCartaoCredito,
  DialogoDeletarDespesaCartaoCredito,
  DialogoExplicacaoIndicador,
} from '../../Components';
// import Datagrid from '../../Components/Datagrid';
import DialogoDeleteResource from '../../Components/DialogoDeleteResource';
import ListTemplate from '../../Components/List';
import { getListAllAPI, api } from '../../services';
import { formatMoeda } from '../../utils';
import {
  STATUS_ATIVO,
  TIPO_FERIADO_FERIADO,
  TIPO_FERIADO_DATA_COMEMORATIVA,
  TIPO_DESPESA,
} from '../../utils/constants';
import DialogoShowCompra from '../Compras/DialogoShow';
import Actions from './ActionsList';
import { choicesList } from './choices';
// import Datagrid from './Datagrid';
import DialogoAgendar from './DialogoAgendar';
import DialogoDesagendar from './DialogoDesagendar';
import ActionsField from './Fields/ActionsField';
import AgendadorField from './Fields/AgendadorField';
import CategoriaField from './Fields/CategoriaField2';
import ContaField from './Fields/ContaField2';
import ContaField3 from './Fields/ContaField3';
import DataNegociada from './Fields/DataNegociada';
import DataPagamentoField from './Fields/DataPagamentoField';
import DataPagamentoFieldOriginal from './Fields/DataPagamentoFieldOriginal';
import DescricaoField from './Fields/DescricaoField';
import DiasDiferencaCriacao from './Fields/DiasDiferencaCriacao';
import DiasDiferencaNegociacao from './Fields/DiasDiferencaNegociacao';
import DiasNegociadosField from './Fields/DiasNegociados';
import DiasOriginalProvavel from './Fields/DiasOriginalProvavel';
import DiasRealOriginal from './Fields/DiasRealOriginal';
import HorarioAgendamentoField from './Fields/HorarioAgendamentoField';
import ProblemaField from './Fields/ProblemaField';
import ReferenceFieldCategoria from './Fields/ReferenceFieldCategoria';
import StatusAgendamentoField from './Fields/StatusAgendamentoField';
import StatusDespesasAtrasadasField from './Fields/StatusDespesasAtrasadasField';
import StatusField from './Fields/StatusField';
import ValorField from './Fields/ValorField';
import ValorTransferenciaField from './Fields/ValorTransferenciaField';
import Filter from './Filter';
import AgendamentoInput from './Inputs/AgendamentoInput';
import CategoriaInput from './Inputs/CategoriaInput';
import CategoriaProblemaInput from './Inputs/CategoriaProblemaInput';
import ChipInput from './Inputs/ChipInput/Container';
import ContaInput from './Inputs/ContaInput';
import DateMobillsInput from './Inputs/DateInputMobills';
import PeriodoInput from './Inputs/PeriodoInput';
import PeriodoInput2 from './Inputs/PeriodoInput2';
import SelectMultiplo from './Inputs/SelectMultiplo';
import SituacoesInput from './Inputs/SituacoesInput';
import TiposInput from './Inputs/TiposInput';

/// ////////////

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{ padding: '30px 40px 0px' }}
      // style={{ height: '100%' }}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const CardTotal = ({ label, valor, icon, cor, loading = false }) => (
  <Card style={{ borderRadius: '20px', cursor: 'pointer', flex: 1 }}>
    <CardContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          // height: '100%',
          alignItems: 'center',
        }}
      >
        <div>
          <div
            style={{
              fontSize: '17px',
              color: '#737477',
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {label}
            <ArrowForwardIosIcon
              style={{ fontSize: '10px', marginLeft: '5px' }}
            />
          </div>
          {!loading ? (
            <div
              style={{ fontSize: '20px', color: 'black', fontWeight: 'bold' }}
            >
              {formatMoeda(valor)}
            </div>
          ) : (
            <div
              style={{ fontSize: '20px', color: 'black', fontWeight: 'bold' }}
            >
              <Skeleton variant="text" />
            </div>
          )}
        </div>
        <div>
          <Avatar2
            aria-label="recipe"
            style={{ backgroundColor: cor, color: 'white' }}
            // sizes="large"
          >
            {icon}
          </Avatar2>
        </div>
      </div>
    </CardContent>
  </Card>
);

const MyDatagridRow = ({
  record,
  resource,
  id,
  onToggleItem,
  children,
  selected,
  selectable,
  basePath,
}) => (
  <TableRow key={id}>
    {React.Children.map(children, (field) => (
      <TableCell key={`${id}-${field.props.source}`}>
        {React.cloneElement(field, {
          record,
          basePath,
          resource,
        })}
      </TableCell>
    ))}
  </TableRow>
);

const MyDatagridBody = (props) => (
  <DatagridBody {...props} row={<MyDatagridRow />} />
);
const MyDatagrid = (props) => <Datagrid {...props} body={<MyDatagridBody />} />;

const useStylesDatagrid = makeStyles((theme) => ({
  statusHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    paddingLeft: '30px',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  statusCell: {
    // marginLeft: '30px',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingLeft: '30px',
  },
  statusContent: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 5,
    marginLeft: '30px',
    maxWidth: '100px',
  },
  dataHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoCell: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaCell: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  actionsHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingRight: '100px',
  },
  actionsCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingRight: '100px',
    /* display: 'flex',
    justifyContent: 'flex-end', */
  },
}));

const MES_ATUAL = new Date().getMonth();
const ANO_ATUAL = new Date().getFullYear();

function formatDate(value) {
  return moment(new Date(value)).format('DD/MM/YYYY - HH:mm');
}

const PostPanel = (props) => (
  <div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        marginBottom: '20px',
        border: '1px solid red',
        padding: '10px',
        borderRadius: '20px',
      }}
    >
      <h3 style={{ marginBottom: '10px', color: '#193bf299' }}>Geral:</h3>
      <div>
        <span
          style={{
            fontWeight: 'bold',
            marginRight: '10px',
          }}
        >
          ID:
        </span>
        <span>{props.record.id}</span>
      </div>
      {props.record.tipo === TIPO_DESPESA && (
        <>
          <div>
            <span
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
              }}
            >
              Categoria de Problema:
            </span>
            <span>
              {props.record.problema ? props.record.problema.nome : '-'}
            </span>
          </div>
          <div>
            <span
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
              }}
            >
              Código do Boleto:
            </span>
            <span>
              {props.record.codigo_boleto ? props.record.codigo_boleto : '-'}
            </span>
          </div>
          <div>
            <span
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
              }}
            >
              Juros:
            </span>
            <span>{formatMoeda(props.record.juros)}</span>
          </div>
          <div>
            <span
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
              }}
            >
              Mora:
            </span>
            <span>{formatMoeda(props.record.mora)}</span>
          </div>
          <div>
            <span
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
              }}
            >
              Multa:
            </span>
            <span>{formatMoeda(props.record.multa)}</span>
          </div>
          <div>
            <span
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
              }}
            >
              Total de encargos:
            </span>
            <span>{formatMoeda(props.record.total_juros)}</span>
          </div>
          <div>
            <span
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
              }}
            >
              Desconto:
            </span>
            <span>{formatMoeda(props.record.desconto)}</span>
          </div>
          <div>
            <span
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
              }}
            >
              Valor Original:
            </span>
            <span>{formatMoeda(props.record.valor_original)}</span>
          </div>
          <div>
            <span
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
              }}
            >
              Valor Atual:
            </span>
            <span>{formatMoeda(props.record.valor)}</span>
          </div>
          <div>
            <span
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
              }}
            >
              Data de Pagamento Original:
            </span>
            <span>
              {props.record.categoria_problema_despesa_id !== 1
                ? props.record.data_pagamento_original
                  ? moment(
                      new Date(props.record.data_pagamento_original),
                    ).format('DD/MM/YYYY')
                  : '-'
                : '-'}
            </span>
          </div>
          <div>
            <span
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
              }}
            >
              Data de Pagamento Real:
            </span>
            <span>
              {props.record.categoria_problema_despesa_id !== 1
                ? props.record.data_pagamento
                  ? moment(new Date(props.record.data_pagamento)).format(
                      'DD/MM/YYYY',
                    )
                  : '-'
                : '-'}
            </span>
          </div>
          <div>
            <span
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
              }}
            >
              Data Provável:
            </span>
            <span>
              {props.record.data_negociada
                ? moment(new Date(props.record.data_negociada)).format(
                    'DD/MM/YYYY',
                  )
                : '-'}
            </span>
          </div>
          <div>
            <span
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
              }}
            >
              Prazo de pagamento:
            </span>
            <span>
              {props.record.dias_prazo ? props.record.dias_prazo : '-'}
            </span>
          </div>
          {props.record.tipo === TIPO_DESPESA && (
            <div>
              <span
                style={{
                  fontWeight: 'bold',
                  marginRight: '10px',
                }}
              >
                Fornecedor:
              </span>
              <span>
                {props.record.fornecedor ? props.record.fornecedor.nome : '-'}
              </span>
            </div>
          )}
        </>
      )}
    </div>

    {props.record.tipo === TIPO_DESPESA && (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          marginBottom: '20px',
          border: '1px solid red',
          padding: '10px',
          borderRadius: '20px',
        }}
      >
        <h3 style={{ marginBottom: '10px', color: '#193bf299' }}>
          Agendamento:
        </h3>
        <div>
          <span
            style={{
              fontWeight: 'bold',
              marginRight: '10px',
            }}
          >
            Agendador:
          </span>
          <span>
            {props.record.agendador ? props.record.agendador.username : '-'}
          </span>
        </div>
        <div>
          <span
            style={{
              fontWeight: 'bold',
              marginRight: '10px',
            }}
          >
            Data:
          </span>
          <span>
            {props.record.agendamento_data
              ? moment(new Date(props.record.agendamento_data)).format(
                  'DD/MM/YYYY',
                )
              : '-'}
          </span>
        </div>
        <div>
          <span
            style={{
              fontWeight: 'bold',
              marginRight: '10px',
            }}
          >
            Horário:
          </span>
          <span>
            {props.record.agendamento_data
              ? moment(new Date(props.record.agendamento_data)).format('HH:mm')
              : '-'}
          </span>
        </div>
      </div>
    )}
    {props.record.tipo === TIPO_DESPESA && (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          border: '1px solid red',
          padding: '10px',
          borderRadius: '20px',
        }}
      >
        <h3 style={{ marginBottom: '10px', color: '#193bf299' }}>Relatos:</h3>
        {props.record.textosProblemas.map((item) => (
          <div>
            <span
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
              }}
            >
              {`${item.user ? item.user.username : ''}(${formatDate(
                item.data,
              )}):`}
            </span>{' '}
            {`${item.descricao_problema}`}
          </div>
        ))}
      </div>
    )}
  </div>
);

const postRowStyle = (record, index) => {
  if (record) {
    if (!record.status) {
      const dataAtual = new Date(moment(new Date()).endOf('day').format());

      const dataOriginal = new Date(
        moment(new Date(record.data_pagamento_original)).endOf('day').format(),
      );

      if (dataAtual.getTime() > dataOriginal.getTime()) {
        return {
          backgroundColor: '#e89696ad',
        };
      }
    }
  }
  return {
    backgroundColor: 'white',
  };
};

const DataGridLocal = ({
  handleEdit = () => {},
  handleDelete = () => {},
  handleEfetivar = () => {},
  handleAgendar = () => {},
  handleDesagendar = () => {},
  handleExplicacaoIndicador = () => {},
  handleAtrazadas = () => {},
  openDialogoCompra = () => {},
  ...props
}) => {
  const classes = useStylesDatagrid();
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;

  const showPrazos = filterValues.showPrazos;

  const middlewareExplicacaoIndicador = (titulo, content) => {
    handleExplicacaoIndicador(titulo, content);
  };

  return (
    <>
      <Datagrid
        {...props}
        bulkActionButtons={false}
        rowStyle={postRowStyle}
        // classes={{ headerCell: classes.row }}
      >
        {/*<DescricaoField
          source="id"
          label="ID"
          sortable={false}
          headerClassName={classes.descricaoHeader}
          cellClassName={classes.descricaoCell}
        />*/}

        <DataPagamentoFieldOriginal
          source="data_pagamento_original"
          label="Data Original"
          headerClassName={classes.dataHeader}
          cellClassName={classes.dataCell}
        />
        <DataPagamentoField
          source="data_pagamento"
          label="Data Real"
          headerClassName={classes.dataHeader}
          cellClassName={classes.dataCell}
        />
        <DiasDiferencaCriacao
          source="created_at"
          label="Nível Urgência"
          headerClassName={classes.valorHeader}
          cellClassName={classes.valorCell}
          onClick={middlewareExplicacaoIndicador}
          //sortable={false}
        />
        {/*showPrazos && (
          <DiasNegociadosField
            source="data_negociada"
            label="Prazo de Pagamento"
            headerClassName={classes.valorHeader}
            cellClassName={classes.valorCell}
            sortable={false}
          />
        )*/}
        {/*showPrazos && (
          <DataNegociada
            source="data_negociada"
            label="Data Provável"
            headerClassName={classes.valorHeader}
            cellClassName={classes.valorCell}
            sortable={false}
          />
        )*/}
        {/*<DiasDiferencaNegociacao
          source="data_negociada"
          label="Diferença em Dias"
          headerClassName={classes.valorHeader}
          cellClassName={classes.valorCell}
          sortable={false}
      />*/}
        <DiasOriginalProvavel
          source="data_negociada"
          label="Diferença Provável Original"
          headerClassName={classes.valorHeader}
          cellClassName={classes.valorCell}
          sortable={false}
          onClick={middlewareExplicacaoIndicador}
        />
        <DiasRealOriginal
          source="data_negociada"
          label="Diferença Real Original"
          headerClassName={classes.valorHeader}
          cellClassName={classes.valorCell}
          sortable={false}
          onClick={middlewareExplicacaoIndicador}
        />
        {filterValues.tipo !== 'Transferência' && (
          <DescricaoField
            source="descricao"
            label="Descrição"
            sortable={false}
            headerClassName={classes.descricaoHeader}
            cellClassName={classes.descricaoCell}
          />
        )}
        {/*filterValues.tipo === 'Despesa' && (
          <ProblemaField
            label="Problema"
            source="problema"
            sortable={false}
            headerClassName={classes.categoriaHeader}
            cellClassName={classes.categoriaCell}
          />
        )*/}
        {filterValues.tipo !== 'Transferência' && (
          <CategoriaField
            label="Categoria"
            source="categoria_transacao_2_id"
            // reference="categorias_transacao"
            sortable={false}
            headerClassName={classes.categoriaHeader}
            cellClassName={classes.categoriaCell}
            // link={false}
          />
        )}
        {filterValues.tipo !== 'Transferência' && (
          <ContaField
            label="Conta"
            source="conta_id"
            sortable={false}
            headerClassName={classes.categoriaHeader}
            cellClassName={classes.categoriaCell}
          />
        )}
        {filterValues.tipo === 'Transferência' && (
          <ContaField
            label="De"
            source="conta_id"
            sortable={false}
            headerClassName={classes.categoriaHeader}
            cellClassName={classes.categoriaCell}
          />
        )}
        {filterValues.tipo === 'Transferência' && (
          <ContaField3
            label="Para"
            source="conta_id"
            sortable={false}
            headerClassName={classes.categoriaHeader}
            cellClassName={classes.categoriaCell}
          />
        )}

        {filterValues.tipo !== 'Transferência' && (
          <ValorField
            source="valor"
            /* locales="pt-BR"
          options={{
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'BRL',
          }} */
            textAlign="right"
            label="Valor"
            headerClassName={classes.valorHeader}
            cellClassName={classes.valorCell}
          />
        )}
        {filterValues.tipo === 'Transferência' && (
          <ValorTransferenciaField
            source="valor"
            /* locales="pt-BR"
          options={{
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'BRL',
          }} */
            textAlign="right"
            label="Valor"
            headerClassName={classes.valorHeader}
            cellClassName={classes.valorCell}
          />
        )}

        {/*filterValues.tipo === 'Despesa' && (
          <StatusAgendamentoField
            label="Status Agendamento"
            source="agendamento_user_id"
            sortable={false}
            headerClassName={classes.categoriaHeader}
            cellClassName={classes.categoriaCell}
          />
        )*/}

        {/*filterValues.tipo === 'Despesa' && (
          <HorarioAgendamentoField
            label="Data Agendamento"
            source="agendamento_data"
            sortable={false}
            headerClassName={classes.categoriaHeader}
            cellClassName={classes.categoriaCell}
          />
        )*/}

        {/*filterValues.tipo === 'Despesa' && (
          <AgendadorField
            label="Agendador"
            source="agendamento_user_id"
            sortable={false}
            headerClassName={classes.categoriaHeader}
            cellClassName={classes.categoriaCell}
          />
        )*/}
        <StatusDespesasAtrasadasField
          source="n_despesas_atrasadas"
          label="Despesas Atrasadas"
          sortable={false}
          //tipo={filterValues.tipo}
          headerClassName={classes.statusHeader}
          cellClassName={classes.statusCell}
          onClick={handleAtrazadas}
          textAlign="right"
          // className={classes.statusContent}
        />
        {filterValues.tipo !== 'Transferência' && (
          <StatusField
            source="status"
            label="Status"
            sortable={false}
            //tipo={filterValues.tipo}
            headerClassName={classes.statusHeader}
            cellClassName={classes.statusCell}
            handleEfetivar={handleEfetivar}
            textAlign="right"
            // className={classes.statusContent}
          />
        )}
        <ActionsField
          source="lalala"
          /* locales="pt-BR"
          options={{
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'BRL',
          }} */
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleEfetivar={handleEfetivar}
          handleAgendar={handleAgendar}
          handleDesagendar={handleDesagendar}
          openDialogoCompra={openDialogoCompra}
          textAlign="right"
          label="Ações"
          headerClassName={classes.actionsHeader}
          cellClassName={classes.actionsCell}
          tipo={filterValues.tipo}
          sortable={false}
        />
        {/* <ChangeStatusTransacoesButton /> */}
        {/*  <EditButton label="Editar" /> */}
      </Datagrid>
    </>
  );
};

const SessaoTotaisTransacaoes = ({
  hasFilters,
  dadosTotais: {
    despesasPagas,
    despesasPendentes,
    receitasRecebidas,
    receitasPendentes,
    saldoAtual,
    balancoMensal,
  },
  loading,
}) => (
  <>
    {!hasFilters && (
      <Box flex={1}>
        <CardTotal
          label="Saldo atual"
          valor={saldoAtual}
          icon={<AccountBalanceIcon />}
          cor="#009FD4"
          loading={loading}
        />
      </Box>
    )}
    <Box flex={1} marginLeft="10px">
      <CardTotal
        label="Receitas"
        valor={receitasRecebidas + receitasPendentes}
        icon={<ArrowUpwardIcon />}
        cor="#4caf50"
        loading={loading}
      />
    </Box>
    <Box flex={1} marginLeft="10px">
      <CardTotal
        label="Despesas"
        valor={despesasPagas + despesasPendentes}
        icon={<ArrowDownward />}
        cor="#f44336"
        loading={loading}
      />
    </Box>
    <Box flex={1} marginLeft="10px">
      <CardTotal
        label="Balanço mensal"
        valor={balancoMensal}
        icon={<AccountBalanceWalletIcon />}
        cor="#009FD4"
        loading={loading}
      />
    </Box>
  </>
);

const SessaoTotaisDespesas = ({
  dadosTotais: {
    despesasPagas,
    despesasPendentes,
    receitasRecebidas,
    receitasPendentes,
    saldoAtual,
  },
  loading,
  filterValues,
}) => {
  function getStatus() {
    if (filterValues) {
      if (filterValues.situacoes) {
        if (filterValues.situacoes.length > 0) {
          if (filterValues.situacoes[0] === 2) {
            return true;
          }
        } else {
          return true;
        }
      }
    }
    return false;
  }
  return (
    <>
      <Box flex={1}>
        <CardTotal
          label="Despesas pendentes"
          valor={despesasPendentes}
          icon={<ArrowUpwardIcon />}
          cor="#f44336"
          loading={loading}
        />
      </Box>
      <Box flex={1} marginLeft="10px">
        <CardTotal
          label="Despesas pagas"
          valor={despesasPagas}
          icon={<ArrowDownward />}
          cor="#f44336"
          loading={loading}
        />
      </Box>
      <Box flex={1} marginLeft="10px">
        <CardTotal
          label="Total"
          valor={despesasPendentes + despesasPagas}
          icon={<AccountBalanceWalletIcon />}
          cor="#f44336"
          loading={loading}
        />
      </Box>
    </>
  );
};

const SessaoTotaisReceitas = ({
  dadosTotais: {
    despesasPagas,
    despesasPendentes,
    receitasRecebidas,
    receitasPendentes,
    saldoAtual,
  },
  loading,
}) => (
  <>
    <Box flex={1}>
      <CardTotal
        label="Receitas pendentes"
        valor={receitasPendentes}
        icon={<ArrowUpwardIcon />}
        cor="#4caf50"
        loading={loading}
      />
    </Box>
    <Box flex={1} marginLeft="10px">
      <CardTotal
        label="Receitas recebidas"
        valor={receitasRecebidas}
        icon={<ArrowDownward />}
        cor="#4caf50"
        loading={loading}
      />
    </Box>
    <Box flex={1} marginLeft="10px">
      <CardTotal
        label="Total"
        valor={receitasPendentes + receitasRecebidas}
        icon={<AccountBalanceWalletIcon />}
        cor="#4caf50"
        loading={loading}
      />
    </Box>
  </>
);

const SessaoTotaisTransferencias = ({
  hasFilters,
  dadosTotais: {
    despesasPagas,
    despesasPendentes,
    receitasRecebidas,
    receitasPendentes,
    saldoAtual,
    balancoMensal,
  },
  loading,
}) => (
  <>
    {!hasFilters && (
      <Box flex={1}>
        <CardTotal
          label="Saldo atual"
          valor={saldoAtual}
          icon={<AccountBalanceIcon />}
          cor="#009FD4"
          loading={loading}
        />
      </Box>
    )}
    <Box flex={1} marginLeft="10px">
      <CardTotal
        label="Receitas"
        valor={receitasRecebidas + receitasPendentes}
        icon={<ArrowUpwardIcon />}
        cor="#4caf50"
        loading={loading}
      />
    </Box>
    <Box flex={1} marginLeft="10px">
      <CardTotal
        label="Despesas"
        valor={despesasPagas + despesasPendentes}
        icon={<ArrowDownward />}
        cor="#f44336"
        loading={loading}
      />
    </Box>
    <Box flex={1} marginLeft="10px">
      <CardTotal
        label="Balanço mensal"
        valor={balancoMensal}
        icon={<AccountBalanceWalletIcon />}
        cor="#009FD4"
        loading={loading}
      />
    </Box>
  </>
);

function selectComponent(tipo, hasFilters, dadosTotais, loading, filterValues) {
  switch (tipo) {
    case 'Transacao':
      return (
        <SessaoTotaisTransacaoes
          hasFilters={hasFilters}
          dadosTotais={dadosTotais}
          loading={loading}
        />
      );
    case 'Despesa':
      return (
        <SessaoTotaisDespesas
          dadosTotais={dadosTotais}
          loading={loading}
          filterValues={filterValues}
        />
      );
    case 'Receita':
      return (
        <SessaoTotaisReceitas dadosTotais={dadosTotais} loading={loading} />
      );
    case 'Transferência':
      return (
        <SessaoTotaisTransferencias
          hasFilters={hasFilters}
          dadosTotais={dadosTotais}
          loading={loading}
        />
      );
    default:
      return null;
  }
}

const SessaoTotais = () => {
  const listContext = useListContext();
  const version = useVersion();
  const { ids, filterValues, setFilters, displayedFilters, data } = listContext;

  const [despesasPagas, setDespesasPagas] = useState(0);
  const [despesasPendentes, setDespesasPendentes] = useState(0);
  const [receitasRecebidas, setReceitasRecebidas] = useState(0);
  const [receitasPendentes, setReceitasPendentes] = useState(0);
  const [saldoAtual, setSaldoAtual] = useState(0);
  const [balancoMensal, setBalancoMensal] = useState(0);
  const [loading, setLoading] = useState(false);

  const hasFilters =
    filterValues.categorias ||
    filterValues.contas ||
    filterValues.situacoes ||
    filterValues.tipos ||
    filterValues.agendamento ||
    filterValues.categorias_problemas ||
    filterValues.periodo;

  async function getDados() {
    setLoading(true);
    const dados = await api.get(
      `/totais_transacoes?${stringify({
        filter: JSON.stringify({
          tipo: filterValues.tipo,
          categorias: filterValues.categorias,
          categorias_problemas: filterValues.categorias_problemas,
          contas: filterValues.contas,
          tipos: filterValues.tipos,
          situacoes: filterValues.situacoes,
          periodo: filterValues.periodo,
          periodo2: filterValues.periodo2,
          search: filterValues.search,
          inverso: filterValues.inverso,
          agendamento: filterValues.agendamento,
        }),
      })}`,
    );

    setDespesasPagas(dados.data.totalDespesasPagasPeriodo);
    setDespesasPendentes(dados.data.totalDespesasPendentesPeriodo);
    setReceitasRecebidas(dados.data.totalReceitasRecebidasPeriodo);
    setReceitasPendentes(dados.data.totalReceitasPendentesPeriodo);
    setSaldoAtual(dados.data.saldoAtual);
    setBalancoMensal(dados.data.balancoMensal);
    setLoading(false);
  }

  useEffect(() => {
    getDados();
    // }, [version]);
  }, [
    filterValues.tipo,
    filterValues.categorias,
    filterValues.contas,
    filterValues.tipos,
    filterValues.situacoes,
    filterValues.periodo,
    filterValues.periodo2,
    filterValues.search,
    filterValues.categorias_problemas,
    filterValues.agendamento,
    filterValues.inverso,
    version,
  ]);

  const dadosTotais = {
    despesasPagas,
    despesasPendentes,
    receitasRecebidas,
    receitasPendentes,
    saldoAtual,
    balancoMensal,
  };

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        margin: '30px 0 15px',
        maxHeight: '100px',
      }}
    >
      {selectComponent(
        filterValues.tipo,
        hasFilters,
        dadosTotais,
        loading,
        filterValues,
      )}
    </div>
  );
};

const options = [
  {
    id: 1,
    label: 'OPT 1',
  },
  {
    id: 2,
    label: 'OPT 2',
  },
  {
    id: 3,
    label: 'OPT 3',
  },
];

const useStyles3 = makeStyles({
  chip: {
    borderColor: (props) => props.color,
    backgroundColor: 'white',
    cursor: 'default',
    color: '#8e8e8e',
    '&:hover': {
      color: (props) => props.color,
      backgroundColor: 'white',
    },
  },
  deleteIcon: {
    color: (props) => props.color,
    cursor: 'pointer',
    '&:hover': {
      color: (props) => props.color,
    },
  },
});

const ChipRenderer = ({
  value,
  text,
  isFocused,
  isDisabled,
  isReadOnly,
  handleClick,
  handleDelete,
  className,
  key,
}) => {
  const classes2 = useStyles3({ color: value.color });
  return (
    <>
      {value.id === suggestionPadrao.id ? (
        <Chip
          key={key}
          classes={{ outlined: classes2.chip, deleteIcon: classes2.deleteIcon }}
          style={{
            pointerEvents: isDisabled || isReadOnly ? 'none' : undefined,
            color: '#8e8e8e',
            borderColor: '#8e8e8e',
          }}
          onClick={handleClick}
          label={value.label}
          variant="outlined"
          // className={className}
        />
      ) : (
        <>
          {value.icon !== '' ? (
            <Chip
              key={key}
              classes={{
                outlined: classes2.chip,
                deleteIcon: classes2.deleteIcon,
              }}
              style={{
                pointerEvents: isDisabled || isReadOnly ? 'none' : undefined,
              }}
              onClick={handleClick}
              onDelete={handleDelete}
              label={value.label}
              variant="outlined"
              className={className}
              avatar={
                <Avatar2
                  style={{ backgroundColor: 'white', marginRight: '3px' }}
                >
                  <MaterialIcon icon={value.icon} color={value.color} />
                </Avatar2>
              }
            />
          ) : (
            <Chip
              key={key}
              classes={{
                outlined: classes2.chip,
                deleteIcon: classes2.deleteIcon,
              }}
              style={{
                pointerEvents: isDisabled || isReadOnly ? 'none' : undefined,
              }}
              onClick={handleClick}
              onDelete={handleDelete}
              label={value.label}
              variant="outlined"
              className={className}
            />
          )}
        </>
      )}
    </>
  );
};

function renderSuggestion(suggestion, { query, isHighlighted }) {
  return (
    <MenuItem
      selected={isHighlighted}
      component="div"
      onMouseDown={(e) => e.preventDefault()}
    >
      {suggestion.icon !== '' ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar2
            style={{
              backgroundColor: suggestion.color,
              marginRight: '10px',
              width: '30px',
              height: '30px',
            }}
          >
            <MaterialIcon icon={suggestion.icon} color="white" size={20} />
          </Avatar2>
          {suggestion.label}
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {suggestion.label}
        </div>
      )}
    </MenuItem>
  );
}

const suggestionsTipos = [
  {
    label: 'Despesas',
    id: 1,
    color: '#8e8e8e',
    icon: '',
  },
  {
    label: 'Receitas',
    id: 2,
    color: '#8e8e8e',
    icon: '',
  },
  {
    label: 'Transferências de entrada',
    id: 3,
    color: '#8e8e8e',
    icon: '',
  },
  {
    label: 'Transferências de saída',
    id: 4,
    color: '#8e8e8e',
    icon: '',
  },
];

const suggestionsSituacoes = [
  {
    label: 'Efetuadas',
    id: 1,
    color: '#8e8e8e',
    icon: '',
  },
  {
    label: 'Pendentes',
    id: 2,
    color: '#8e8e8e',
    icon: '',
  },
  {
    label: 'Ignoradas',
    id: 3,
    color: '#8e8e8e',
    icon: '',
  },
];

const suggestions = [
  {
    label: 'Ajustes',
    id: 1,
    color: 'red',
    icon: 'alarm_on',
  },
  {
    label: 'Carro e moto',
    id: 2,
    color: 'blue',
    icon: 'dashboard',
  },
  {
    label: 'Albania',
    id: 3,
    color: 'green',
    icon: 'alarm_on',
  },
  {
    label: 'Algeria',
    id: 4,
    color: 'black',
    icon: 'dashboard',
  },
  {
    label: 'Algeria',
    id: 5,
    color: 'black',
    icon: 'dashboard',
  },
  {
    label: 'Algeria',
    id: 6,
    color: 'black',
    icon: 'dashboard',
  },
  {
    label: 'Algeria',
    id: 7,
    color: 'black',
    icon: 'dashboard',
  },
  {
    label: 'Algeria',
    id: 8,
    color: 'black',
    icon: 'dashboard',
  },
  {
    label: 'Algeria',
    id: 9,
    color: 'black',
    icon: 'dashboard',
  },
  {
    label: 'Algeria',
    id: 10,
    color: 'black',
    icon: 'dashboard',
  },
];

const textVazioPadrao = 'Todas as categorias';

const suggestionPadrao = {
  label: textVazioPadrao,
  id: -1,
  cor: 'black',
  icone: '',
};

const ContainerChipInput = ({
  label,
  textoVazio = 'Todas as categorias',
  options,
}) => {
  const [value, setValue] = useState([]);

  const chipRenderer2 = (data, key) => <ChipRenderer {...data} key={key} />;

  const suggestionPadrao = {
    label: textoVazio,
    id: -1,
    cor: 'black',
    icone: '',
  };

  return (
    <div style={{ marginBottom: '24px' }}>
      <ChipInput
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        valorVazio={suggestionPadrao}
        chipRenderer={chipRenderer2}
        options={options}
        renderSuggestion={renderSuggestion}
        label={label}
      />
    </div>
  );
};

const ContainerPeriodoInput = ({ label }) => {
  const [value, setValue] = useState({ start: new Date(), end: new Date() });

  return (
    <div style={{ marginBottom: '24px' }}>
      <PeriodoInput
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        name="periodo"
        variant="standard"
      />
    </div>
  );
};

const tiposOptions = [
  {
    nome: 'Despesas',
    id: 1,
    color: '#8e8e8e',
    icon: '',
  },
  {
    nome: 'Receitas',
    id: 2,
    color: '#8e8e8e',
    icon: '',
  },
  {
    nome: 'Transferências de entrada',
    id: 3,
    color: '#8e8e8e',
    icon: '',
  },
  {
    nome: 'Transferências de saída',
    id: 4,
    color: '#8e8e8e',
    icon: '',
  },
];

const situacoesOptions = [
  {
    nome: 'Efetuadas',
    id: 1,
    color: '#8e8e8e',
    icon: '',
  },
  {
    nome: 'Pendentes',
    id: 2,
    color: '#8e8e8e',
    icon: '',
  },
  {
    nome: 'Cartão de crédito',
    id: 3,
    color: '#8e8e8e',
    icon: '',
  },
];

const agendamentoOptions = [
  {
    nome: 'Não Agendados',
    id: 1,
    color: '#8e8e8e',
    icon: '',
  },
  {
    nome: 'Agendados',
    id: 2,
    color: '#8e8e8e',
    icon: '',
  },
];

const todasCategoriasProblemas = {
  nome: 'Todas Problemáticas',
  id: 0,
  color: 'red',
  icone: '',
};

const DrawerFilters = ({
  open,
  handleCancelar,
  handleAplicarFiltros,
  tipo,
}) => {
  const classes = useStylesDrawer();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [teste, setTest] = React.useState([]);
  const [categorias, setCategorias] = useState([]);
  const [categoriasOptions, setCategoriasOptions] = useState([]);
  const [contas, setContas] = useState([]);
  const [contasOptions, setContasOptions] = useState([]);
  const [categoriasProblemas, setCategoriasProblemas] = useState([]);
  const [categoriasProblemasOptions, setCategoriasProblemasOptions] = useState(
    [],
  );
  const [situacoes, setSituacoes] = useState([]);
  const [agendamento, setAgendamento] = useState([]);
  const [tipos, setTipos] = useState([]);
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [periodo, setPeriodo] = useState({
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
  });

  const aplicarFiltros = useCallback(
    (filters) => {
      if (setFilters) {
        setFilters({ tipo: filterValues.tipo, ...filters }, displayedFilters);
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  function initFiltros(
    contasOptions,
    categoriasOptions,
    categoriasProblemasOptions,
  ) {
    if (filterValues.categorias) {
      const categoriasLocal = [];
      for (let i = 0; i < filterValues.categorias.length; i += 1) {
        for (let j = 0; j < categoriasOptions.length; j += 1) {
          if (filterValues.categorias[i] === categoriasOptions[j].id) {
            categoriasLocal.push(categoriasOptions[j]);
          }
        }
      }
      setCategorias(categoriasLocal);
    } else {
      setCategorias([]);
    }

    if (filterValues.categorias_problemas) {
      const categoriasProblemasLocal = [];
      for (let i = 0; i < filterValues.categorias_problemas.length; i += 1) {
        for (let j = 0; j < categoriasProblemasOptions.length; j += 1) {
          if (
            filterValues.categorias_problemas[i] ===
            categoriasProblemasOptions[j].id
          ) {
            categoriasProblemasLocal.push(categoriasProblemasOptions[j]);
          }
        }
      }
      setCategoriasProblemas(categoriasProblemasLocal);
    } else {
      setCategoriasProblemas([]);
    }

    if (filterValues.contas) {
      const contasLocal = [];
      for (let i = 0; i < filterValues.contas.length; i += 1) {
        for (let j = 0; j < contasOptions.length; j += 1) {
          if (filterValues.contas[i] === contasOptions[j].id) {
            contasLocal.push(contasOptions[j]);
          }
        }
      }
      setContas(contasLocal);
    } else {
      setContas([]);
    }

    if (filterValues.situacoes) {
      const situacoesLocal = [];
      for (let i = 0; i < filterValues.situacoes.length; i += 1) {
        for (let j = 0; j < situacoesOptions.length; j += 1) {
          if (filterValues.situacoes[i] === situacoesOptions[j].id) {
            situacoesLocal.push(situacoesOptions[j]);
          }
        }
      }
      setSituacoes(situacoesLocal);
    } else {
      setSituacoes([]);
    }

    if (filterValues.agendamento) {
      const agendamentoLocal = [];
      for (let i = 0; i < filterValues.agendamento.length; i += 1) {
        for (let j = 0; j < agendamentoOptions.length; j += 1) {
          if (filterValues.agendamento[i] === agendamentoOptions[j].id) {
            agendamentoLocal.push(agendamentoOptions[j]);
          }
        }
      }
      setSituacoes(agendamentoLocal);
    } else {
      setSituacoes([]);
    }

    if (filterValues.tipos) {
      const tiposLocal = [];
      for (let i = 0; i < filterValues.tipos.length; i += 1) {
        for (let j = 0; j < tiposOptions.length; j += 1) {
          if (filterValues.tipos[i] === tiposOptions[j].id) {
            tiposLocal.push(tiposOptions[j]);
          }
        }
      }
      setTipos(tiposLocal);
    } else {
      setTipos([]);
    }

    if (filterValues.periodo) {
      setPeriodo(filterValues.periodo);
    } else {
      setPeriodo({
        start: startOfMonth(new Date()),
        end: endOfMonth(new Date()),
      });
    }
  }

  const removeTiposTransferencias = (arrayCategorias) => {
    const newArray = [];
    for (let i = 0; i < arrayCategorias.length; i += 1) {
      if (arrayCategorias[i].tipo !== 'Transferência') {
        newArray.push(arrayCategorias[i]);
      }
    }
    return newArray;
  };

  useEffect(() => {
    async function getData() {
      const dataContas = await getListAllAPI(
        'contas',
        ['id', 'asc'],
        { status: STATUS_ATIVO },
        [],
      );

      const dataCategorias = await getListAllAPI(
        'categorias_transacao',
        ['id', 'asc'],
        filterValues.tipo === 'Receita'
          ? { tipo: 'Receita', status: STATUS_ATIVO }
          : filterValues.tipo === 'Despesa'
          ? { tipo: 'Despesa', status: STATUS_ATIVO }
          : { status: STATUS_ATIVO },
        [],
      );

      const dataCategoriasProblemas = await getListAllAPI(
        'categorias-problemas-despesas',
        ['id', 'asc'],
        { status: STATUS_ATIVO },
        [],
      );

      setContasOptions(dataContas.data);
      // const newCategorias = removeTiposTransferencias(dataCategorias.data);
      // setCategoriasOptions(newCategorias);
      // setCategoriasOptions(dataCategorias.data);

      const newCategorias = removeTiposTransferencias(dataCategorias.data);

      setCategoriasOptions(newCategorias);

      const dataCategoriasProblemasTodas = [
        todasCategoriasProblemas,
        ...dataCategoriasProblemas.data,
      ];
      //dataCategoriasProblemasTodas.push(todasCategoriasProblemas);
      setCategoriasProblemasOptions(dataCategoriasProblemasTodas);

      // setContas([]);
      // setCategorias([]);
      // setTipos([]);
      // setSituacoes([]);

      initFiltros(
        dataContas.data,
        dataCategorias.data,
        dataCategoriasProblemasTodas,
      );
    }
    if (open) {
      getData();
    }
  }, [open]);

  const handleAplicarFiltrosLocal = (index) => {
    handleAplicarFiltros();
    const filterCategorias = categorias.map((item) => item.id);
    const filterCategoriasProblemas = categoriasProblemas.map(
      (item) => item.id,
    );
    const filterContas = contas.map((item) => item.id);
    const filterSituacoes = situacoes.map((item) => item.id);
    const filterAgendamento = agendamento.map((item) => item.id);
    const filterTipos = tipos.map((item) => item.id);
    const filterPeriodo = periodo;
    aplicarFiltros({
      categorias: filterCategorias,
      contas: filterContas,
      situacoes: filterSituacoes,
      tipos: filterTipos,
      periodo: filterPeriodo,
      categorias_problemas: filterCategoriasProblemas,
      agendamento: filterAgendamento,
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  return (
    <Drawer
      anchor="right"
      open={open}
      PaperProps={{}}
      ModalProps={{}}
      classes={{ paper: classes.paper }}
      onClose={handleCancelar}
    >
      <div
        style={{
          height: '12vh',
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid #ededed',
        }}
      >
        <div
          style={{ marginLeft: '40px', fontSize: '20px', fontWeight: 'bold' }}
        >
          Filtro de transações
        </div>
      </div>
      <div
        style={{
          height: '71vh',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Paper square>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
            variant="fullWidth"
            classes={{ indicator: classes.indicatorTab }}
          >
            <Tab
              label="NOVO FILTRO"
              classes={{ root: classes.tab, selected: classes.tab }}
            />
            <Tab label="FILTROS SALVOS" />
          </Tabs>
        </Paper>
        <div style={{ overflowY: 'auto' }}>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <div style={{ marginBottom: '24px' }}>
              <CategoriaInput
                label="Categorias"
                options={categoriasOptions}
                value={categorias}
                onChange={setCategorias}
              />
            </div>
            {filterValues.tipo === 'Despesa' && (
              <div style={{ marginBottom: '24px' }}>
                <CategoriaProblemaInput
                  label="Categorias Problemas"
                  options={categoriasProblemasOptions}
                  value={categoriasProblemas}
                  onChange={setCategoriasProblemas}
                />
              </div>
            )}

            <div style={{ marginBottom: '24px' }}>
              <ContaInput
                label="Contas"
                options={contasOptions}
                value={contas}
                onChange={setContas}
              />
            </div>
            <div style={{ marginBottom: '24px' }}>
              <SituacoesInput
                label="Situações"
                value={situacoes}
                onChange={setSituacoes}
              />
            </div>
            {filterValues.tipo === 'Transacao' && (
              <div style={{ marginBottom: '24px' }}>
                <TiposInput label="Tipos" value={tipos} onChange={setTipos} />
              </div>
            )}
            {filterValues.tipo === 'Despesa' && (
              <div style={{ marginBottom: '24px' }}>
                <AgendamentoInput
                  label="Agendamento"
                  value={agendamento}
                  onChange={setAgendamento}
                />
              </div>
            )}
            <div style={{ marginBottom: '24px' }}>
              <PeriodoInput2 value={periodo} onChange={setPeriodo} />
            </div>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            Item Two
          </TabPanel>
        </div>
      </div>
      <div
        style={{
          height: '17vh',
          backgroundColor: 'white',
          borderTop: '1px solid #ededed',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 20px',
        }}
      >
        <Button
          style={{ borderRadius: '5em', color: 'blue', fontWeight: 'bold' }}
          onClick={handleCancelar}
        >
          CANCELAR
        </Button>
        <Button
          style={{
            borderRadius: '5em',
            backgroundColor: 'blue',
            color: 'white',
            fontWeight: 'bold',
          }}
          onClick={handleAplicarFiltrosLocal}
        >
          APLICAR FILTROS
        </Button>
      </div>
    </Drawer>
  );
};

//

const useStyles4 = makeStyles({
  chip: {
    borderColor: (props) => props.color,
    backgroundColor: 'white',
    cursor: 'default',
    color: '#8e8e8e',
    '&:hover': {
      color: (props) => props.color,
      backgroundColor: 'white',
    },
  },
  deleteIcon: {
    color: (props) => props.color,
    cursor: 'pointer',
    '&:hover': {
      color: (props) => props.color,
    },
  },
  chip2: {
    borderColor: '#8e8e8e',
    backgroundColor: 'white',
    cursor: 'default',
    color: '#8e8e8e',
    '&:hover': {
      color: '#8e8e8e',
      backgroundColor: 'white',
    },
  },
  deleteIcon2: {
    color: '#8e8e8e',
    cursor: 'pointer',
    '&:hover': {
      color: '#8e8e8e',
    },
  },
});

const optionsTipos = [
  {
    nome: 'Despesas',
    id: 1,
    color: '#8e8e8e',
    icon: '',
  },
  {
    nome: 'Receitas',
    id: 2,
    color: '#8e8e8e',
    icon: '',
  },
  {
    nome: 'Transferências de entrada',
    id: 3,
    color: '#8e8e8e',
    icon: '',
  },
  {
    nome: 'Transferências de saída',
    id: 4,
    color: '#8e8e8e',
    icon: '',
  },
];

const optionsSituacoes = [
  {
    nome: 'Efetivadas',
    id: 1,
    color: '#8e8e8e',
    icon: '',
  },
  {
    nome: 'Pendentes',
    id: 2,
    color: '#8e8e8e',
    icon: '',
  },
  {
    nome: 'Cartão de crédito',
    id: 3,
    color: '#8e8e8e',
    icon: '',
  },
];

const ChipCategoria = ({ item, onDelete }) => {
  const classes2 = useStyles4({ color: item.color });
  return (
    <Chip
      classes={{
        outlined: classes2.chip,
        deleteIcon: classes2.deleteIcon,
      }}
      variant="outlined"
      label={item.nome}
      avatar={
        <Avatar2
          style={{
            backgroundColor: 'white',
            marginRight: '3px',
          }}
        >
          <MaterialIcon icon={item.icon} size={20} color={item.color} />
        </Avatar2>
      }
      onDelete={onDelete}
    />
  );
};

const ChipCategoriaProblema = ({ item, onDelete }) => {
  const classes2 = useStyles4({ color: item.color });
  return (
    <Chip
      classes={{
        outlined: classes2.chip,
        deleteIcon: classes2.deleteIcon,
      }}
      variant="outlined"
      label={item.nome}
      avatar={
        <Avatar2
          style={{
            backgroundColor: 'black',
            marginRight: '3px',
            color: 'white',
          }}
        >
          {item.nome.charAt(0)}
        </Avatar2>
      }
      onDelete={onDelete}
    />
  );
};

const ChipConta = ({ item, onDelete }) => {
  const classes2 = useStyles4({ color: item.color });
  return (
    <Chip
      classes={{
        outlined: classes2.chip,
        deleteIcon: classes2.deleteIcon,
      }}
      variant="outlined"
      label={item.nome}
      avatar={
        <Avatar2
          style={{
            backgroundColor: item.color,
            color: 'white',
            marginRight: '3px',
          }}
        >
          {item.nome.charAt(0)}
        </Avatar2>
      }
      onDelete={onDelete}
    />
  );
};

const FiltrosLista = () => {
  const classes2 = useStyles4({ color: 'red' });
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const valueTeste = true;
  const [optionsCategorias, setOptionsCategorias] = useState([]);
  const [optionsCategoriasProblemas, setOptionsCategoriasProblemas] = useState(
    [],
  );
  const [optionsContas, setOptionsContas] = useState([]);
  const [labelFeriado, setLabelFeriado] = useState('');

  useEffect(() => {
    async function getData() {
      if (filterValues.showPrazos) {
        const data = new Date(filterValues.periodo.start);

        const ano = data.getFullYear();
        const mes = data.getMonth();
        const dia = data.getDate();

        const dataDia = await api.get(`feriados/onedados/${dia}/${mes}/${ano}`);
        if (
          dataDia.data.tipo === TIPO_FERIADO_FERIADO ||
          dataDia.data.tipo === TIPO_FERIADO_DATA_COMEMORATIVA
        ) {
          setLabelFeriado(dataDia.data.nome);
        } else {
          setLabelFeriado('');
        }
      } else {
        setLabelFeriado('');
      }
    }

    getData();
  }, []);

  useEffect(() => {
    async function getData() {
      const dataContas = await getListAllAPI(
        'contas',
        ['id', 'asc'],
        { status: STATUS_ATIVO },
        [],
      );

      const dataCategorias = await getListAllAPI(
        'categorias_transacao',
        ['id', 'asc'],
        { status: STATUS_ATIVO },
        [],
      );

      const dataCategoriasProblemas = await getListAllAPI(
        'categorias-problemas-despesas',
        ['id', 'asc'],
        { status: STATUS_ATIVO },
        [],
      );

      const dataCategoriasProblemasTodas = [
        todasCategoriasProblemas,
        ...dataCategoriasProblemas.data,
      ];

      setOptionsContas(dataContas.data);
      setOptionsCategorias(dataCategorias.data);
      setOptionsCategoriasProblemas(dataCategoriasProblemasTodas);
    }

    getData();
  }, []);

  const aplicarFiltros = useCallback(
    (filters) => {
      if (setFilters) {
        setFilters({ ...filterValues, ...filters }, displayedFilters);
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  const resetarFiltros = useCallback(() => {
    if (setFilters) {
      setFilters(
        {
          tipo: filterValues.tipo,
          periodo2: { mes: MES_ATUAL, ano: ANO_ATUAL },
        },
        displayedFilters,
      );
    }
  }, [displayedFilters, filterValues, setFilters]);

  function removeFilter(id, arrayName, array) {
    const newArray = array.filter((item) => item !== id);
    return {
      [arrayName]: newArray,
    };
  }

  const hasFilters =
    filterValues.categorias ||
    filterValues.contas ||
    filterValues.situacoes ||
    filterValues.tipos ||
    filterValues.categorias_problemas ||
    filterValues.agendamento ||
    filterValues.periodo;

  const filtersCategorias = filterValues.categorias
    ? optionsCategorias.filter((item) => {
        let pertence = false;
        for (
          let i = 0;
          i < filterValues.categorias.length && pertence === false;
          i++
        ) {
          if (item.id === filterValues.categorias[i]) {
            pertence = true;
          }
        }
        return pertence;
      })
    : [];

  const filtersCategoriasProblemas = filterValues.categorias_problemas
    ? optionsCategoriasProblemas.filter((item) => {
        let pertence = false;
        for (
          let i = 0;
          i < filterValues.categorias_problemas.length && pertence === false;
          i++
        ) {
          if (item.id === filterValues.categorias_problemas[i]) {
            pertence = true;
          }
        }
        return pertence;
      })
    : [];

  const filtersContas = filterValues.contas
    ? optionsContas.filter((item) => {
        let pertence = false;
        for (
          let i = 0;
          i < filterValues.contas.length && pertence === false;
          i++
        ) {
          if (item.id === filterValues.contas[i]) {
            pertence = true;
          }
        }
        return pertence;
      })
    : [];

  const filtersSituacoes = filterValues.situacoes
    ? optionsSituacoes.filter((item) => {
        let pertence = false;
        for (
          let i = 0;
          i < filterValues.situacoes.length && pertence === false;
          i++
        ) {
          if (item.id === filterValues.situacoes[i]) {
            pertence = true;
          }
        }
        return pertence;
      })
    : [];

  const filtersAgendamento = filterValues.agendamento
    ? agendamentoOptions.filter((item) => {
        let pertence = false;
        for (
          let i = 0;
          i < filterValues.agendamento.length && pertence === false;
          i++
        ) {
          if (item.id === filterValues.agendamento[i]) {
            pertence = true;
          }
        }
        return pertence;
      })
    : [];

  const filtersTipos = filterValues.tipos
    ? optionsTipos.filter((item) => {
        let pertence = false;
        for (
          let i = 0;
          i < filterValues.tipos.length && pertence === false;
          i++
        ) {
          if (item.id === filterValues.tipos[i]) {
            pertence = true;
          }
        }
        return pertence;
      })
    : [];

  return (
    <>
      {!hasFilters ? (
        <DateMobillsInput />
      ) : (
        <div
          style={{ display: 'flex', alignItems: 'center', margin: '0px 20px' }}
        >
          <div>Filtros:</div>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {filterValues.periodo && (
              <div style={{ margin: '4px' }}>
                <Chip
                  classes={{
                    outlined: classes2.chip2,
                    deleteIcon: classes2.deleteIcon2,
                  }}
                  variant="outlined"
                  label={`De ${format(
                    new Date(filterValues.periodo.start),
                    "dd 'de' MMM 'de' yyyy",
                    { locale: ptBR },
                  )} até ${format(
                    new Date(filterValues.periodo.end),
                    "dd 'de' MMM 'de' yyyy",
                    { locale: ptBR },
                  )}${filterValues.periodo.original ? ' | DATA ORIGINAL' : ''}`}
                  // onDelete={() => aplicarFiltros({ periodo: {} })}
                  style={{
                    color: filterValues.periodo.original
                      ? '#f44336'
                      : '#8e8e8e',
                    borderColor: filterValues.periodo.original
                      ? '#f44336'
                      : '#8e8e8e',
                  }}
                />
                {labelFeriado && (
                  <Tooltip title={labelFeriado} placement="top-end">
                    <StarIcon style={{ color: 'yellow' }} />
                  </Tooltip>
                )}
              </div>
            )}
            {filterValues.categorias && (
              <>
                {filtersCategorias.map((item) => (
                  <div style={{ margin: '4px' }}>
                    <ChipCategoria
                      item={item}
                      onDelete={() =>
                        aplicarFiltros(
                          removeFilter(
                            item.id,
                            'categorias',
                            filterValues.categorias,
                          ),
                        )
                      }
                    />
                  </div>
                ))}
              </>
            )}
            {filterValues.categorias_problemas && (
              <>
                {filtersCategoriasProblemas.map((item) => (
                  <div style={{ margin: '4px' }}>
                    <ChipCategoriaProblema
                      item={item}
                      onDelete={() =>
                        aplicarFiltros(
                          removeFilter(
                            item.id,
                            'categorias_problemas',
                            filterValues.categorias_problemas,
                          ),
                        )
                      }
                    />
                  </div>
                ))}
              </>
            )}
            {filterValues.contas && (
              <>
                {filtersContas.map((item) => (
                  <div style={{ margin: '4px' }}>
                    <ChipConta
                      item={item}
                      onDelete={() =>
                        aplicarFiltros(
                          removeFilter(item.id, 'contas', filterValues.contas),
                        )
                      }
                    />
                  </div>
                ))}
              </>
            )}
            {filterValues.situacoes && (
              <>
                {filtersSituacoes.map((item) => (
                  <div style={{ margin: '4px' }}>
                    <Chip
                      classes={{
                        outlined: classes2.chip2,
                        deleteIcon: classes2.deleteIcon2,
                      }}
                      variant="outlined"
                      label={item.nome}
                      onDelete={() =>
                        aplicarFiltros(
                          removeFilter(
                            item.id,
                            'situacoes',
                            filterValues.situacoes,
                          ),
                        )
                      }
                      style={{
                        color: '#8e8e8e',
                        borderColor: '#8e8e8e',
                      }}
                    />
                  </div>
                ))}
              </>
            )}
            {filterValues.agendamento && (
              <>
                {filtersAgendamento.map((item) => (
                  <div style={{ margin: '4px' }}>
                    <Chip
                      classes={{
                        outlined: classes2.chip2,
                        deleteIcon: classes2.deleteIcon2,
                      }}
                      variant="outlined"
                      label={item.nome}
                      onDelete={() =>
                        aplicarFiltros(
                          removeFilter(
                            item.id,
                            'agendamento',
                            filterValues.agendamento,
                          ),
                        )
                      }
                      style={{
                        color: '#8e8e8e',
                        borderColor: '#8e8e8e',
                      }}
                    />
                  </div>
                ))}
              </>
            )}
            {filterValues.tipos && (
              <>
                {filtersTipos.map((item) => (
                  <div style={{ margin: '4px' }}>
                    <Chip
                      classes={{
                        outlined: classes2.chip2,
                        deleteIcon: classes2.deleteIcon2,
                      }}
                      variant="outlined"
                      label={item.nome}
                      onDelete={() =>
                        aplicarFiltros(
                          removeFilter(item.id, 'tipos', filterValues.tipos),
                        )
                      }
                      style={{
                        color: '#8e8e8e',
                        borderColor: '#8e8e8e',
                      }}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
          <div>
            <IconButton aria-label="delete" onClick={() => resetarFiltros()}>
              <HighlightOffIcon style={{ color: 'blue' }} />
            </IconButton>
          </div>
        </div>
      )}
    </>
  );
};
const MyList = ({ handleOpenFiltros, ...props }) => {
  const refresh = useRefresh();
  const refDialogo = useRef(null);
  const refDialogoStatus = useRef(null);
  const refDialogoDeletar = useRef(null);
  const refDialogoDeletarDespesaCartao = useRef(null);
  const refDialogoDespesaCartao = useRef(null);

  const refDialogoAgendar = useRef(null);
  const refDialogoDesagendar = useRef(null);
  const refDialogoExplicacaoIndicador = useRef(null);

  const refDialogoShowCompra = useRef(null);

  const history = useHistory();

  const create = (tipo) => {
    if (tipo === 'Despesa_cartao') {
      if (refDialogoDespesaCartao.current) {
        refDialogoDespesaCartao.current.handleCreate();
      }
    } else if (refDialogo.current) {
      refDialogo.current.handleCreate(tipo);
    }
  };

  const edit = (data) => {
    if (data.record.fatura_id) {
      if (refDialogoDespesaCartao.current) {
        refDialogoDespesaCartao.current.handleEdit(data.record.id);
      }
    } else if (refDialogo.current) {
      refDialogo.current.handleEdit(data.record.id);
    }
  };

  const efetivar = (data) => {
    if (refDialogoStatus.current) {
      refDialogoStatus.current.handleOpen(data.record.id);
    }
  };

  const deletar = (data) => {
    if (data.record.fatura_id) {
      if (refDialogoDeletarDespesaCartao.current) {
        refDialogoDeletarDespesaCartao.current.handleOpen(data.record.id);
      }
    } else if (refDialogoDeletar.current) {
      refDialogoDeletar.current.handleOpen(data.record.id);
    }
  };

  const agendar = (data) => {
    if (refDialogoAgendar.current) {
      refDialogoAgendar.current.handleOpen(data.record.id);
    }
  };

  const desagendar = (data) => {
    if (refDialogoDesagendar.current) {
      refDialogoDesagendar.current.handleOpen(data.record.id);
    }
  };

  const explicacaoIndicador = (title, content) => {
    if (refDialogoExplicacaoIndicador.current) {
      refDialogoExplicacaoIndicador.current.handleOpen(title, content);
    }
  };

  const atrazadas = (data) => {
    const queryOj = {};

    queryOj.displayedFilters = JSON.stringify({
      fornecedor_id: true,
    });

    queryOj.filter = JSON.stringify({
      fornecedor_id: data.fornecedor_id,
    });

    history.push(
      `/parcelas_nao_pagas_fornecedores?${stringify(
        queryOj,
      )}&order=DESC&page=1&perPage=50&sort=data_pagamento`,
    );
  };

  function openDialogoCompra(id) {
    if (refDialogoShowCompra.current) {
      refDialogoShowCompra.current.handleOpen(id);
    }
  }

  const handleClose = () => {
    refresh();
  };

  return (
    <>
      <ListToolbar
        filters={props.filters}
        actions={
          <Actions
            handleOpenFiltros={handleOpenFiltros}
            handleCriar={create}
            {...props}
          />
        }
        {...props}
      />
      <SessaoTotais {...props} />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Box flex={1} margin="20px 0">
          <FiltrosLista />
        </Box>
        <DataGridLocal
          handleEdit={edit}
          handleEfetivar={efetivar}
          handleDelete={deletar}
          handleAgendar={agendar}
          handleDesagendar={desagendar}
          handleExplicacaoIndicador={explicacaoIndicador}
          handleAtrazadas={atrazadas}
          openDialogoCompra={openDialogoCompra}
          {...props}
        />
        <TransacoesPagination />
      </Card>
      <DialogoTransacao ref={refDialogo} handleClose={handleClose} />
      <DialogoChangeStatusTransacao
        ref={refDialogoStatus}
        handleClose={handleClose}
      />
      <DialogoDeletarTransacao
        ref={refDialogoDeletar}
        handleClose={handleClose}
      />
      <DialogoDeletarDespesaCartaoCredito
        ref={refDialogoDeletarDespesaCartao}
        handleClose={handleClose}
      />
      <DialogoDespesaCartaoCredito
        ref={refDialogoDespesaCartao}
        handleClose={handleClose}
      />
      <DialogoAgendar ref={refDialogoAgendar} handleClose={handleClose} />
      <DialogoDesagendar ref={refDialogoDesagendar} handleClose={handleClose} />
      <DialogoExplicacaoIndicador ref={refDialogoExplicacaoIndicador} />
      <DialogoShowCompra ref={refDialogoShowCompra} handleClose={() => {}} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => {
  const [openDrawerFilters, setOpenDrawerFilters] = useState(false);
  const handleCancelar = () => {
    setOpenDrawerFilters(false);
  };

  const handleAplicarFiltros = () => {
    setOpenDrawerFilters(false);
  };

  const handleOpenFiltros = () => {
    setOpenDrawerFilters(true);
  };

  return (
    <>
      <ListBase {...props}>
        <MyList handleOpenFiltros={handleOpenFiltros} {...props} />
        <DrawerFilters
          open={openDrawerFilters}
          handleCancelar={handleCancelar}
          handleAplicarFiltros={handleAplicarFiltros}
        />
      </ListBase>
    </>
  );
};

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]} {...props} />
);

const useStylesDrawer = makeStyles((theme) => ({
  paper: {
    borderRadius: '20px 0 0 20px',
    width: '33vw',
  },
  indicatorTab: {
    backgroundColor: 'blue',
  },
  tab: {
    color: 'blue',
    '&:selected': {
      color: 'blue',
    },
  },
}));

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      //filters={<Filter />}
      title="Transações"
      /* isDelete
      disabledDelete={(record) =>
        record ? !!record.parcela_compra_id || !!record.parcela_venda_id : true
      } */
      expand={<PostPanel />}
      component="div"
      bulkActionButtons={false}
      sort={{ field: 'data_pagamento', order: 'DESC' }}
      pagination={<TransacoesPagination />}
      // perPage={200}
      perPage={50}
      filterDefaultValues={{
        tipo: 'Transacao',
        periodo2: { mes: MES_ATUAL, ano: ANO_ATUAL },
        inverso: false,
      }}
      filter={{ visible: true }}
    />
  </>
);

export default List2;

/* fulters:
      categorias
      contas
      situações
      tipos
      de ate   */
/* 
      <ContainerChipInput label="Categorias" options={suggestions} />
      <ContainerChipInput label="Contas" options={suggestions} />
      <ContainerChipInput
        label="Situações"
        options={suggestionsSituacoes}
      />
      <ContainerChipInput label="Tipos" options={suggestionsTipos} />
      <ContainerPeriodoInput /> */

/*       {filterValues.tipo !== 'Transferência' && (
        <ReferenceFieldCategoria
          label="Categoria"
          source="categoria_transacao_2_id"
          reference="categorias_transacao"
          sortable={false}
          headerClassName={classes.categoriaHeader}
          cellClassName={classes.categoriaCell}
          link={false}
        />
      )}
      {filterValues.tipo !== 'Transferência' && (
        <ReferenceField
          label="Conta"
          source="conta_id"
          reference="contas"
          sortable={false}
          headerClassName={classes.contaHeader}
          cellClassName={classes.contaCell}
          link={false}
        >
          <ContaField source="nome" />
        </ReferenceField>
      )}
      {filterValues.tipo === 'Transferência' && (
        <ReferenceField
          label="De"
          source="conta_id"
          reference="contas"
          sortable={false}
          headerClassName={classes.contaHeader}
          cellClassName={classes.contaCell}
          link={false}
        >
          <ContaField source="nome" />
        </ReferenceField>
      )}
      {filterValues.tipo === 'Transferência' && (
        <ReferenceField
          label="Para"
          source="transacao_id"
          reference="transacoes"
          sortable={false}
          headerClassName={classes.contaHeader}
          cellClassName={classes.contaCell}
        >
          <ReferenceField
            label="Para"
            source="conta_id"
            reference="contas"
            sortable={false}
            headerClassName={classes.contaHeader}
            cellClassName={classes.contaCell}
            link={false}
          >
            <ContaField source="nome" />
          </ReferenceField>
        </ReferenceField>
      )} */
