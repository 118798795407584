import React, {
  useRef,
  cloneElement,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  TextField,
  EditButton,
  DateField,
  NumberField,
  ReferenceField,
  SelectField,
  List,
  Datagrid,
  DatagridBody,
  ListBase,
  ListToolbar,
  BulkActionsToolbar,
  Pagination,
  useListContext,
  useRefresh,
  useVersion,
} from 'react-admin';

// import { useVersion } from 'react-admin';

import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Button,
  Box,
  makeStyles,
} from '@material-ui/core';
import Avatar2 from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Drawer from '@material-ui/core/Drawer';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import { ArrowDownward } from '@material-ui/icons';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import EventIcon from '@material-ui/icons/Event';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Skeleton from '@material-ui/lab/Skeleton';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import MaterialIcon from 'material-icons-react';
import { stringify } from 'query-string';

import {
  DialogoTransacao,
  DialogoChangeStatusTransacao,
  DialogoDeletarTransacao,
  DialogoPagarTotalFatura,
  DialogoPagarParcialFatura,
  DialogoReabrirFatura,
  DialogoDespesaCartaoCredito,
  DialogoAjustarFatura,
  DialogoPagarAdiantadoFatura,
  DialogoDeletarDespesaCartaoCredito,
  AutoCompleteLocalCustomizado,
} from '../../Components';
// import Datagrid from '../../Components/Datagrid';
import DialogoDeleteResource from '../../Components/DialogoDeleteResource';
import ListTemplate from '../../Components/List';
import { getListAllAPI, api } from '../../services';
import { formatMoeda } from '../../utils';
import { STATUS_ATIVO } from '../../utils/constants';
import Actions from './ActionsList';
import { choicesList } from './choices';
// import Datagrid from './Datagrid';
import ActionsField from './Fields/ActionsField';
import CategoriaField from './Fields/CategoriaField2';
import ContaField from './Fields/ContaField2';
import ContaField3 from './Fields/ContaField3';
import DescricaoField from './Fields/DescricaoField';
import ReferenceFieldCategoria from './Fields/ReferenceFieldCategoria';
import StatusField from './Fields/StatusField';
import ValorField from './Fields/ValorField';
import ValorTransferenciaField from './Fields/ValorTransferenciaField';
import Filter from './Filter';
import CategoriaInput from './Inputs/CategoriaInput';
import ChipInput from './Inputs/ChipInput/Container';
import ContaInput from './Inputs/ContaInput';
import DateMobillsInput from './Inputs/DateInputMobills';
import PeriodoInput from './Inputs/PeriodoInput';
import PeriodoInput2 from './Inputs/PeriodoInput2';
import SelectMultiplo from './Inputs/SelectMultiplo';
import SituacoesInput from './Inputs/SituacoesInput';
import TiposInput from './Inputs/TiposInput';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{ padding: '30px 40px 0px' }}
      // style={{ height: '100%' }}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const CardTotal = ({ label, valor, icon, cor, loading = false }) => (
  <Card style={{ borderRadius: '20px', cursor: 'pointer', flex: 1 }}>
    <CardContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          // height: '100%',
          alignItems: 'center',
        }}
      >
        <div>
          <div
            style={{
              fontSize: '17px',
              color: '#737477',
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {label}
            <ArrowForwardIosIcon
              style={{ fontSize: '10px', marginLeft: '5px' }}
            />
          </div>
          {!loading ? (
            <div
              style={{ fontSize: '20px', color: 'black', fontWeight: 'bold' }}
            >
              {formatMoeda(valor)}
            </div>
          ) : (
            <div
              style={{ fontSize: '20px', color: 'black', fontWeight: 'bold' }}
            >
              <Skeleton variant="text" />
            </div>
          )}
        </div>
        <div>
          <Avatar2
            aria-label="recipe"
            style={{ backgroundColor: cor, color: 'white' }}
            // sizes="large"
          >
            {icon}
          </Avatar2>
        </div>
      </div>
    </CardContent>
  </Card>
);

const CardTotalText = ({ label, valor, icon, cor, loading = false }) => (
  <Card style={{ borderRadius: '20px', cursor: 'pointer', flex: 1 }}>
    <CardContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          // height: '100%',
          alignItems: 'center',
        }}
      >
        <div>
          <div
            style={{
              fontSize: '17px',
              color: '#737477',
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {label}
            <ArrowForwardIosIcon
              style={{ fontSize: '10px', marginLeft: '5px' }}
            />
          </div>
          {!loading ? (
            <div
              style={{ fontSize: '20px', color: 'black', fontWeight: 'bold' }}
            >
              {valor}
            </div>
          ) : (
            <div
              style={{ fontSize: '20px', color: 'black', fontWeight: 'bold' }}
            >
              <Skeleton variant="text" />
            </div>
          )}
        </div>
        <div>
          <Avatar2
            aria-label="recipe"
            style={{ backgroundColor: cor, color: 'white' }}
            // sizes="large"
          >
            {icon}
          </Avatar2>
        </div>
      </div>
    </CardContent>
  </Card>
);

const MyDatagridRow = ({
  record,
  resource,
  id,
  onToggleItem,
  children,
  selected,
  selectable,
  basePath,
}) => (
  <TableRow key={id}>
    {React.Children.map(children, (field) => (
      <TableCell key={`${id}-${field.props.source}`}>
        {React.cloneElement(field, {
          record,
          basePath,
          resource,
        })}
      </TableCell>
    ))}
  </TableRow>
);

const MyDatagridBody = (props) => (
  <DatagridBody {...props} row={<MyDatagridRow />} />
);
const MyDatagrid = (props) => <Datagrid {...props} body={<MyDatagridBody />} />;

const useStylesDatagrid = makeStyles((theme) => ({
  statusHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    paddingLeft: '30px',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  statusCell: {
    // marginLeft: '30px',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingLeft: '30px',
  },
  statusContent: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 5,
    marginLeft: '30px',
    maxWidth: '100px',
  },
  dataHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoCell: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaCell: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  actionsHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingRight: '100px',
  },
  actionsCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingRight: '100px',
    /* display: 'flex',
    justifyContent: 'flex-end', */
  },
}));

const MES_ATUAL = new Date().getMonth();
const ANO_ATUAL = new Date().getFullYear();

const DataGridLocal = ({
  handleEdit = () => {},
  handleDelete = () => {},
  handleEfetivar = () => {},
  ...props
}) => {
  const classes = useStylesDatagrid();
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  return (
    <>
      <Datagrid
        {...props}
        bulkActionButtons={false}
        // classes={{ headerCell: classes.row }}
      >
        {filterValues.tipo !== 'Transferência' && (
          <StatusField
            source="status"
            label="Situação"
            sortable={false}
            headerClassName={classes.statusHeader}
            cellClassName={classes.statusCell}
            // className={classes.statusContent}
          />
        )}
        <DateField
          source="data_pagamento"
          label="Data"
          headerClassName={classes.dataHeader}
          cellClassName={classes.dataCell}
        />
        {filterValues.tipo !== 'Transferência' && (
          <DescricaoField
            source="descricao"
            label="Descrição"
            sortable={false}
            headerClassName={classes.descricaoHeader}
            cellClassName={classes.descricaoCell}
          />
        )}
        {filterValues.tipo !== 'Transferência' && (
          <CategoriaField
            label="Categoria"
            source="categoria_transacao_2_id"
            // reference="categorias_transacao"
            sortable={false}
            headerClassName={classes.categoriaHeader}
            cellClassName={classes.categoriaCell}
            // link={false}
          />
        )}
        {filterValues.tipo === 'Transferência' && (
          <ContaField
            label="De"
            source="conta_id"
            sortable={false}
            headerClassName={classes.categoriaHeader}
            cellClassName={classes.categoriaCell}
          />
        )}
        {filterValues.tipo === 'Transferência' && (
          <ContaField3
            label="Para"
            source="conta_id"
            sortable={false}
            headerClassName={classes.categoriaHeader}
            cellClassName={classes.categoriaCell}
          />
        )}
        {filterValues.tipo !== 'Transferência' && (
          <ValorField
            source="valor"
            /* locales="pt-BR"
          options={{
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'BRL',
          }} */
            // textAlign="right"
            label="Valor"
            headerClassName={classes.valorHeader}
            cellClassName={classes.valorCell}
          />
        )}
        {filterValues.tipo === 'Transferência' && (
          <ValorTransferenciaField
            source="valor"
            /* locales="pt-BR"
          options={{
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'BRL',
          }} */
            textAlign="right"
            label="Valor"
            headerClassName={classes.valorHeader}
            cellClassName={classes.valorCell}
          />
        )}
        <ActionsField
          source="lalala"
          /* locales="pt-BR"
          options={{
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'BRL',
          }} */
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleEfetivar={handleEfetivar}
          textAlign="right"
          label="Ações"
          headerClassName={classes.actionsHeader}
          cellClassName={classes.actionsCell}
          tipo={filterValues.tipo}
          sortable={false}
        />
        {/* <ChangeStatusTransacoesButton /> */}
        {/*  <EditButton label="Editar" /> */}
      </Datagrid>
    </>
  );
};

function getMesAnterior(mes, ano) {
  if (mes === 0) {
    return { mes: 11, ano: ano - 1 };
  }
  return { mes: mes - 1, ano };
}

function getMesPosterior(mes, ano) {
  if (mes === 11) {
    return { mes: 0, ano: ano + 1 };
  }
  return { mes: mes + 1, ano };
}

const getStatus = (cartao, valor, status) =>
  status
    ? 'Fatura paga'
    : valor
    ? getIsVencida(cartao)
      ? 'Fatura vencida'
      : 'Fatura aberta'
    : 'Fatura zerada';

const getIsVencida = (cartao) => {
  if (cartao) {
    const diaAtual = new Date().getDate();
    const mesAtual = new Date().getMonth();
    const anoAtual = new Date().getFullYear();

    const diaVenciamento = cartao.cartao.dia_fechamento;
    const mesVencimento = cartao.mes;
    const anoVencimento = cartao.ano;
    if (cartao.dia_fechamento < cartao.dia_pagamento) {
      if (anoAtual > anoVencimento) {
        return true;
      }
      if (anoAtual < anoVencimento) {
        return false;
      }
      if (mesAtual > mesVencimento) {
        return true;
      }
      if (mesAtual < mesVencimento) {
        return false;
      }
      if (diaAtual >= diaVenciamento) {
        return true;
      }
      return false;
    } /* else {
      ////
    } */
  }
  return false; // aberta
};

function getDiaFechamento(dia_fechamento, dia_pagamento, mes, ano) {
  const diaAtual = new Date().getDate();
  const mesAtual = new Date().getMonth();
  const anoAtual = new Date().getFullYear();
  if (dia_fechamento < dia_pagamento) {
    const dataPagamento = new Date();
    dataPagamento.setDate(dia_fechamento);
    dataPagamento.setMonth(mes);
    dataPagamento.setFullYear(ano);
    return dataPagamento;
  }
  const { mes: mesAnterior, ano: anoAnterior } = getMesAnterior(mes, ano);
  const dataPagamento = new Date();
  dataPagamento.setDate(dia_fechamento);
  dataPagamento.setMonth(mesAnterior);
  dataPagamento.setFullYear(anoAnterior);
  return dataPagamento;
}

const SessaoTotaisTransacaoes = ({ cartao, loading }) => {
  let infos = {
    valor: 0,
    status: '',
    diaFechamento: '',
    diaPagamento: '',
  };
  if (cartao) {
    const dataFechamento = new Date();
    dataFechamento.setDate(cartao.cartao.dia_fechamento);
    dataFechamento.setMonth(cartao.mes);
    dataFechamento.setFullYear(cartao.ano);
    const dataPagamento = new Date();
    dataPagamento.setDate(cartao.cartao.dia_pagamento);
    dataPagamento.setMonth(cartao.mes);
    dataPagamento.setFullYear(cartao.ano);

    infos = {
      valor: cartao.valor,
      status: getStatus(cartao, cartao.valor, cartao.status),
      diaFechamento: `${format(
        getDiaFechamento(
          cartao.cartao.dia_fechamento,
          cartao.cartao.dia_pagamento,
          cartao.mes,
          cartao.ano,
        ),
        "dd 'de' MMMM",
        {
          locale: ptBR,
        },
      )}`,
      diaPagamento: `${format(dataPagamento, "dd 'de' MMMM", {
        locale: ptBR,
      })}`,
    };
  }
  return (
    <>
      <Box flex={1}>
        <CardTotal
          label="Total da fatura"
          valor={infos.valor}
          icon={<LocalAtmIcon />}
          cor="#00796b"
          loading={loading}
        />
      </Box>
      <Box flex={1} marginLeft="10px">
        <CardTotalText
          label="Status"
          valor={infos.status}
          icon={<ReceiptIcon />}
          cor="#00796b"
          loading={loading}
        />
      </Box>
      <Box flex={1} marginLeft="10px">
        <CardTotalText
          label="Dia de fechamento"
          valor={infos.diaFechamento}
          icon={<EventIcon />}
          cor="#00796b"
          loading={loading}
        />
      </Box>
      <Box flex={1} marginLeft="10px">
        <CardTotalText
          label="Data vencimento"
          valor={infos.diaPagamento}
          icon={<EventAvailableIcon />}
          cor="#00796b"
          loading={loading}
        />
      </Box>
    </>
  );
};

function selectComponent(cartao, loading) {
  return <SessaoTotaisTransacaoes cartao={cartao} loading={loading} />;
}

const SessaoTotais = ({ cartaoSelecionado }) => (
  <div
    style={{
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
      margin: '30px 0 15px',
      maxHeight: '100px',
    }}
  >
    {selectComponent(cartaoSelecionado, false)}
  </div>
);

const options = [
  {
    id: 1,
    label: 'OPT 1',
  },
  {
    id: 2,
    label: 'OPT 2',
  },
  {
    id: 3,
    label: 'OPT 3',
  },
];

const useStyles3 = makeStyles({
  chip: {
    borderColor: (props) => props.color,
    backgroundColor: 'white',
    cursor: 'default',
    color: '#8e8e8e',
    '&:hover': {
      color: (props) => props.color,
      backgroundColor: 'white',
    },
  },
  deleteIcon: {
    color: (props) => props.color,
    cursor: 'pointer',
    '&:hover': {
      color: (props) => props.color,
    },
  },
});

const ChipRenderer = ({
  value,
  text,
  isFocused,
  isDisabled,
  isReadOnly,
  handleClick,
  handleDelete,
  className,
  key,
}) => {
  const classes2 = useStyles3({ color: value.color });
  return (
    <>
      {value.id === suggestionPadrao.id ? (
        <Chip
          key={key}
          classes={{ outlined: classes2.chip, deleteIcon: classes2.deleteIcon }}
          style={{
            pointerEvents: isDisabled || isReadOnly ? 'none' : undefined,
            color: '#8e8e8e',
            borderColor: '#8e8e8e',
          }}
          onClick={handleClick}
          label={value.label}
          variant="outlined"
          // className={className}
        />
      ) : (
        <>
          {value.icon !== '' ? (
            <Chip
              key={key}
              classes={{
                outlined: classes2.chip,
                deleteIcon: classes2.deleteIcon,
              }}
              style={{
                pointerEvents: isDisabled || isReadOnly ? 'none' : undefined,
              }}
              onClick={handleClick}
              onDelete={handleDelete}
              label={value.label}
              variant="outlined"
              className={className}
              avatar={
                <Avatar2
                  style={{ backgroundColor: 'white', marginRight: '3px' }}
                >
                  <MaterialIcon icon={value.icon} color={value.color} />
                </Avatar2>
              }
            />
          ) : (
            <Chip
              key={key}
              classes={{
                outlined: classes2.chip,
                deleteIcon: classes2.deleteIcon,
              }}
              style={{
                pointerEvents: isDisabled || isReadOnly ? 'none' : undefined,
              }}
              onClick={handleClick}
              onDelete={handleDelete}
              label={value.label}
              variant="outlined"
              className={className}
            />
          )}
        </>
      )}
    </>
  );
};

function renderSuggestion(suggestion, { query, isHighlighted }) {
  return (
    <MenuItem
      selected={isHighlighted}
      component="div"
      onMouseDown={(e) => e.preventDefault()}
    >
      {suggestion.icon !== '' ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar2
            style={{
              backgroundColor: suggestion.color,
              marginRight: '10px',
              width: '30px',
              height: '30px',
            }}
          >
            <MaterialIcon icon={suggestion.icon} color="white" size={20} />
          </Avatar2>
          {suggestion.label}
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {suggestion.label}
        </div>
      )}
    </MenuItem>
  );
}

const suggestionsTipos = [
  {
    label: 'Despesas',
    id: 1,
    color: '#8e8e8e',
    icon: '',
  },
  {
    label: 'Receitas',
    id: 2,
    color: '#8e8e8e',
    icon: '',
  },
  {
    label: 'Transferências de entrada',
    id: 3,
    color: '#8e8e8e',
    icon: '',
  },
  {
    label: 'Transferências de saída',
    id: 4,
    color: '#8e8e8e',
    icon: '',
  },
];

const suggestionsSituacoes = [
  {
    label: 'Efetuadas',
    id: 1,
    color: '#8e8e8e',
    icon: '',
  },
  {
    label: 'Pendentes',
    id: 2,
    color: '#8e8e8e',
    icon: '',
  },
  {
    label: 'Ignoradas',
    id: 3,
    color: '#8e8e8e',
    icon: '',
  },
];

const suggestions = [
  {
    label: 'Ajustes',
    id: 1,
    color: 'red',
    icon: 'alarm_on',
  },
  {
    label: 'Carro e moto',
    id: 2,
    color: 'blue',
    icon: 'dashboard',
  },
  {
    label: 'Albania',
    id: 3,
    color: 'green',
    icon: 'alarm_on',
  },
  {
    label: 'Algeria',
    id: 4,
    color: 'black',
    icon: 'dashboard',
  },
  {
    label: 'Algeria',
    id: 5,
    color: 'black',
    icon: 'dashboard',
  },
  {
    label: 'Algeria',
    id: 6,
    color: 'black',
    icon: 'dashboard',
  },
  {
    label: 'Algeria',
    id: 7,
    color: 'black',
    icon: 'dashboard',
  },
  {
    label: 'Algeria',
    id: 8,
    color: 'black',
    icon: 'dashboard',
  },
  {
    label: 'Algeria',
    id: 9,
    color: 'black',
    icon: 'dashboard',
  },
  {
    label: 'Algeria',
    id: 10,
    color: 'black',
    icon: 'dashboard',
  },
];

const textVazioPadrao = 'Todas as categorias';

const suggestionPadrao = {
  label: textVazioPadrao,
  id: -1,
  cor: 'black',
  icone: '',
};

const ContainerChipInput = ({
  label,
  textoVazio = 'Todas as categorias',
  options,
}) => {
  const [value, setValue] = useState([]);

  const chipRenderer2 = (data, key) => <ChipRenderer {...data} key={key} />;

  const suggestionPadrao = {
    label: textoVazio,
    id: -1,
    cor: 'black',
    icone: '',
  };

  return (
    <div style={{ marginBottom: '24px' }}>
      <ChipInput
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        valorVazio={suggestionPadrao}
        chipRenderer={chipRenderer2}
        options={options}
        renderSuggestion={renderSuggestion}
        label={label}
      />
    </div>
  );
};

const ContainerPeriodoInput = ({ label }) => {
  const [value, setValue] = useState({ start: new Date(), end: new Date() });

  return (
    <div style={{ marginBottom: '24px' }}>
      <PeriodoInput
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        name="periodo"
        variant="standard"
      />
    </div>
  );
};

const tiposOptions = [
  {
    nome: 'Despesas',
    id: 1,
    color: '#8e8e8e',
    icon: '',
  },
  {
    nome: 'Receitas',
    id: 2,
    color: '#8e8e8e',
    icon: '',
  },
  {
    nome: 'Transferências de entrada',
    id: 3,
    color: '#8e8e8e',
    icon: '',
  },
  {
    nome: 'Transferências de saída',
    id: 4,
    color: '#8e8e8e',
    icon: '',
  },
];

const situacoesOptions = [
  {
    nome: 'Efetuadas',
    id: 1,
    color: '#8e8e8e',
    icon: '',
  },
  {
    nome: 'Pendentes',
    id: 2,
    color: '#8e8e8e',
    icon: '',
  },
];

const DrawerFilters = ({
  open,
  handleCancelar,
  handleAplicarFiltros,
  tipo,
}) => {
  const classes = useStylesDrawer();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [teste, setTest] = React.useState([]);
  const [categorias, setCategorias] = useState([]);
  const [categoriasOptions, setCategoriasOptions] = useState([]);
  const [contas, setContas] = useState([]);
  const [contasOptions, setContasOptions] = useState([]);
  const [situacoes, setSituacoes] = useState([]);
  const [tipos, setTipos] = useState([]);
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [periodo, setPeriodo] = useState({
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
  });

  const aplicarFiltros = useCallback(
    (filters) => {
      if (setFilters) {
        setFilters({ tipo: filterValues.tipo, ...filters }, displayedFilters);
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  function initFiltros(contasOptions, categoriasOptions) {
    if (filterValues.categorias) {
      const categoriasLocal = [];
      for (let i = 0; i < filterValues.categorias.length; i += 1) {
        for (let j = 0; j < categoriasOptions.length; j += 1) {
          if (filterValues.categorias[i] === categoriasOptions[j].id) {
            categoriasLocal.push(categoriasOptions[j]);
          }
        }
      }
      setCategorias(categoriasLocal);
    } else {
      setCategorias([]);
    }

    if (filterValues.contas) {
      const contasLocal = [];
      for (let i = 0; i < filterValues.contas.length; i += 1) {
        for (let j = 0; j < contasOptions.length; j += 1) {
          if (filterValues.contas[i] === contasOptions[j].id) {
            contasLocal.push(contasOptions[j]);
          }
        }
      }
      setContas(contasLocal);
    } else {
      setContas([]);
    }

    if (filterValues.situacoes) {
      const situacoesLocal = [];
      for (let i = 0; i < filterValues.situacoes.length; i += 1) {
        for (let j = 0; j < situacoesOptions.length; j += 1) {
          if (filterValues.situacoes[i] === situacoesOptions[j].id) {
            situacoesLocal.push(situacoesOptions[j]);
          }
        }
      }
      setSituacoes(situacoesLocal);
    } else {
      setSituacoes([]);
    }

    if (filterValues.tipos) {
      const tiposLocal = [];
      for (let i = 0; i < filterValues.tipos.length; i += 1) {
        for (let j = 0; j < tiposOptions.length; j += 1) {
          if (filterValues.tipos[i] === tiposOptions[j].id) {
            tiposLocal.push(tiposOptions[j]);
          }
        }
      }
      setTipos(tiposLocal);
    } else {
      setTipos([]);
    }

    if (filterValues.periodo) {
      setPeriodo(filterValues.periodo);
    } else {
      setPeriodo({
        start: startOfMonth(new Date()),
        end: endOfMonth(new Date()),
      });
    }
  }

  const removeTiposTransferencias = (arrayCategorias) => {
    const newArray = [];
    for (let i = 0; i < arrayCategorias.length; i += 1) {
      if (arrayCategorias[i].tipo !== 'Transferência') {
        newArray.push(arrayCategorias[i]);
      }
    }
    return newArray;
  };

  useEffect(() => {
    async function getData() {
      const dataContas = await getListAllAPI(
        'contas',
        ['id', 'asc'],
        { status: STATUS_ATIVO },
        [],
      );

      const dataCategorias = await getListAllAPI(
        'categorias_transacao',
        ['id', 'asc'],
        filterValues.tipo === 'Receita'
          ? { tipo: 'Receita', status: STATUS_ATIVO }
          : filterValues.tipo === 'Despesa'
          ? { tipo: 'Despesa', status: STATUS_ATIVO }
          : { status: STATUS_ATIVO },
        [],
      );
      setContasOptions(dataContas.data);
      // const newCategorias = removeTiposTransferencias(dataCategorias.data);
      // setCategoriasOptions(newCategorias);
      // setCategoriasOptions(dataCategorias.data);

      const newCategorias = removeTiposTransferencias(dataCategorias.data);

      setCategoriasOptions(newCategorias);

      // setContas([]);
      // setCategorias([]);
      // setTipos([]);
      // setSituacoes([]);

      initFiltros(dataContas.data, dataCategorias.data);
    }
    if (open) {
      getData();
    }
  }, [open]);

  const handleAplicarFiltrosLocal = (index) => {
    handleAplicarFiltros();
    const filterCategorias = categorias.map((item) => item.id);
    const filterContas = contas.map((item) => item.id);
    const filterSituacoes = situacoes.map((item) => item.id);
    const filterTipos = tipos.map((item) => item.id);
    const filterPeriodo = periodo;
    aplicarFiltros({
      categorias: filterCategorias,
      contas: filterContas,
      situacoes: filterSituacoes,
      tipos: filterTipos,
      periodo: filterPeriodo,
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  return (
    <Drawer
      anchor="right"
      open={open}
      PaperProps={{}}
      ModalProps={{}}
      classes={{ paper: classes.paper }}
      onClose={handleCancelar}
    >
      <div
        style={{
          height: '12vh',
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid #ededed',
        }}
      >
        <div
          style={{ marginLeft: '40px', fontSize: '20px', fontWeight: 'bold' }}
        >
          Filtro de transações
        </div>
      </div>
      <div
        style={{
          height: '71vh',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Paper square>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
            variant="fullWidth"
            classes={{ indicator: classes.indicatorTab }}
          >
            <Tab
              label="NOVO FILTRO"
              classes={{ root: classes.tab, selected: classes.tab }}
            />
            <Tab label="FILTROS SALVOS" />
          </Tabs>
        </Paper>
        <div style={{ overflowY: 'auto' }}>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <div style={{ marginBottom: '24px' }}>
              <CategoriaInput
                label="Categorias"
                options={categoriasOptions}
                value={categorias}
                onChange={setCategorias}
              />
            </div>
            <div style={{ marginBottom: '24px' }}>
              <ContaInput
                label="Contas"
                options={contasOptions}
                value={contas}
                onChange={setContas}
              />
            </div>
            <div style={{ marginBottom: '24px' }}>
              <SituacoesInput
                label="Situações"
                value={situacoes}
                onChange={setSituacoes}
              />
            </div>
            {filterValues.tipo === 'Transacao' && (
              <div style={{ marginBottom: '24px' }}>
                <TiposInput label="Tipos" value={tipos} onChange={setTipos} />
              </div>
            )}
            <div style={{ marginBottom: '24px' }}>
              <PeriodoInput2 value={periodo} onChange={setPeriodo} />
            </div>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            Item Two
          </TabPanel>
        </div>
      </div>
      <div
        style={{
          height: '17vh',
          backgroundColor: 'white',
          borderTop: '1px solid #ededed',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 20px',
        }}
      >
        <Button
          style={{ borderRadius: '5em', color: 'blue', fontWeight: 'bold' }}
          onClick={handleCancelar}
        >
          CANCELAR
        </Button>
        <Button
          style={{
            borderRadius: '5em',
            backgroundColor: 'blue',
            color: 'white',
            fontWeight: 'bold',
          }}
          onClick={handleAplicarFiltrosLocal}
        >
          APLICAR FILTROS
        </Button>
      </div>
    </Drawer>
  );
};

//

const useStyles4 = makeStyles({
  chip: {
    borderColor: (props) => props.color,
    backgroundColor: 'white',
    cursor: 'default',
    color: '#8e8e8e',
    '&:hover': {
      color: (props) => props.color,
      backgroundColor: 'white',
    },
  },
  deleteIcon: {
    color: (props) => props.color,
    cursor: 'pointer',
    '&:hover': {
      color: (props) => props.color,
    },
  },
  chip2: {
    borderColor: '#8e8e8e',
    backgroundColor: 'white',
    cursor: 'default',
    color: '#8e8e8e',
    '&:hover': {
      color: '#8e8e8e',
      backgroundColor: 'white',
    },
  },
  deleteIcon2: {
    color: '#8e8e8e',
    cursor: 'pointer',
    '&:hover': {
      color: '#8e8e8e',
    },
  },
});

const optionsTipos = [
  {
    nome: 'Despesas',
    id: 1,
    color: '#8e8e8e',
    icon: '',
  },
  {
    nome: 'Receitas',
    id: 2,
    color: '#8e8e8e',
    icon: '',
  },
  {
    nome: 'Transferências de entrada',
    id: 3,
    color: '#8e8e8e',
    icon: '',
  },
  {
    nome: 'Transferências de saída',
    id: 4,
    color: '#8e8e8e',
    icon: '',
  },
];

const optionsSituacoes = [
  {
    nome: 'Efetuadas',
    id: 1,
    color: '#8e8e8e',
    icon: '',
  },
  {
    nome: 'Pendentes',
    id: 2,
    color: '#8e8e8e',
    icon: '',
  },
];

const ChipCategoria = ({ item, onDelete }) => {
  const classes2 = useStyles4({ color: item.color });
  return (
    <Chip
      classes={{
        outlined: classes2.chip,
        deleteIcon: classes2.deleteIcon,
      }}
      variant="outlined"
      label={item.nome}
      avatar={
        <Avatar2
          style={{
            backgroundColor: 'white',
            marginRight: '3px',
          }}
        >
          <MaterialIcon icon={item.icon} size={20} color={item.color} />
        </Avatar2>
      }
      onDelete={onDelete}
    />
  );
};

const ChipConta = ({ item, onDelete }) => {
  const classes2 = useStyles4({ color: item.color });
  return (
    <Chip
      classes={{
        outlined: classes2.chip,
        deleteIcon: classes2.deleteIcon,
      }}
      variant="outlined"
      label={item.nome}
      avatar={
        <Avatar2
          style={{
            backgroundColor: item.color,
            color: 'white',
            marginRight: '3px',
          }}
        >
          {item.nome.charAt(0)}
        </Avatar2>
      }
      onDelete={onDelete}
    />
  );
};

const FiltrosLista = () => <DateMobillsInput />;

const MyList = ({ handleOpenFiltros, ...props }) => {
  const refresh = useRefresh();
  const refDialogo = useRef(null);
  const refDialogoAjustarFatura = useRef(null);
  const refDialogoPagarAdiantado = useRef(null);
  const refDialogoStatus = useRef(null);
  const refDialogoDeletar = useRef(null);
  const refDialogoPagarTotalFatura = useRef(null);
  const refDialogoPagarParcialFatura = useRef(null);
  const refDialogoReabrirFatura = useRef(null);
  const [cartaoSelecionado, setCartaoSelecionado] = useState(null);
  const version = useVersion();
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;

  async function getDados2() {
    const dataCartoes = await getListAllAPI(
      'faturas',
      ['id', 'asc'],
      {
        cartao_credito_id: filterValues.cartao_credito_id,
        mes: filterValues.periodo2.mes,
        ano: filterValues.periodo2.ano,
      },
      ['cartao'],
    );
    if (dataCartoes.data.length > 0) {
      setCartaoSelecionado(dataCartoes.data[0]);
    } else {
      setCartaoSelecionado(null);
    }
  }

  useEffect(() => {
    getDados2();
  }, [filterValues.periodo2, filterValues.cartao_credito_id, version]);

  function getQuebraDados() {
    if (cartaoSelecionado) {
      return {
        mes: cartaoSelecionado.mes,
        ano: cartaoSelecionado.ano,
        cartao_credito_id: cartaoSelecionado.cartao.id,
      };
    }
    return {
      mes: -1,
      ano: -1,
      cartao_credito_id: -1,
    };
  }

  const { mes, ano, cartao_credito_id } = getQuebraDados();

  const create = (tipo) => {
    if (refDialogo.current) {
      refDialogo.current.handleCreateApartirFatura(cartao_credito_id, mes, ano);
    }
  };

  const edit = (data) => {
    if (refDialogo.current) {
      refDialogo.current.handleEdit(data.record.id);
    }
  };

  const efetivar = (data) => {
    if (refDialogoStatus.current) {
      refDialogoStatus.current.handleOpen(data.record.id);
    }
  };

  const deletar = (data) => {
    if (refDialogoDeletar.current) {
      refDialogoDeletar.current.handleOpen(data.record.id);
    }
  };

  const pagarParcial = () => {
    if (refDialogoPagarParcialFatura.current) {
      // refDialogoPagarParcialFatura.current.handleOpen();
    }
  };

  const pagarAdiantado = () => {
    if (refDialogoPagarAdiantado.current) {
      refDialogoPagarAdiantado.current.handleOpen(cartao_credito_id, mes, ano);
    }
  };

  const ajustarFatura = () => {
    if (refDialogoAjustarFatura.current) {
      refDialogoAjustarFatura.current.handleOpen(cartao_credito_id, mes, ano);
    }
  };

  const historicoFatura = () => {};

  const graficoDespesas = () => {};

  const visaoGeral = () => {};

  const pagarTudo = () => {
    if (refDialogoPagarTotalFatura.current) {
      refDialogoPagarTotalFatura.current.handleOpen(
        cartao_credito_id,
        mes,
        ano,
      );
    }
  };

  const reabrirFatura = () => {
    if (refDialogoReabrirFatura.current) {
      refDialogoReabrirFatura.current.handleOpen(cartao_credito_id, mes, ano);
    }
  };

  const handleClose = () => {
    refresh();
  };

  return (
    <>
      <ListToolbar
        // filters={<Filter />}
        actions={
          <Actions
            handleOpenFiltros={handleOpenFiltros}
            handleCriar={create}
            handlePagarParcial={pagarParcial}
            handlePagarAdiantado={pagarAdiantado}
            handleAjustarFatura={ajustarFatura}
            handleHistoricoFatura={historicoFatura}
            handleGraficoDespesas={graficoDespesas}
            handleVisaoGeral={visaoGeral}
            handlePagarTudo={pagarTudo}
            handleReabrirFatura={reabrirFatura}
            cartaoSelecionado={cartaoSelecionado}
            {...props}
          />
        }
      />
      <SessaoTotais {...props} cartaoSelecionado={cartaoSelecionado} />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Box flex={1} margin="20px 0">
          <FiltrosLista />
        </Box>
        <DataGridLocal
          handleEdit={edit}
          handleEfetivar={efetivar}
          handleDelete={deletar}
        />
        <TransacoesPagination />
      </Card>
      <DialogoDespesaCartaoCredito ref={refDialogo} handleClose={handleClose} />
      <DialogoChangeStatusTransacao
        ref={refDialogoStatus}
        handleClose={handleClose}
      />
      <DialogoDeletarDespesaCartaoCredito
        ref={refDialogoDeletar}
        handleClose={handleClose}
      />
      <DialogoPagarTotalFatura
        ref={refDialogoPagarTotalFatura}
        handleClose={handleClose}
      />
      <DialogoPagarParcialFatura
        ref={refDialogoPagarParcialFatura}
        handleClose={handleClose}
      />
      <DialogoReabrirFatura
        ref={refDialogoReabrirFatura}
        handleClose={handleClose}
      />
      <DialogoAjustarFatura
        ref={refDialogoAjustarFatura}
        handleClose={handleClose}
      />
      <DialogoPagarAdiantadoFatura
        ref={refDialogoPagarAdiantado}
        handleClose={handleClose}
      />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => {
  const [openDrawerFilters, setOpenDrawerFilters] = useState(false);
  const handleCancelar = () => {
    setOpenDrawerFilters(false);
  };

  const handleAplicarFiltros = () => {
    setOpenDrawerFilters(false);
  };

  const handleOpenFiltros = () => {
    setOpenDrawerFilters(true);
  };

  return (
    <>
      <ListBase {...props}>
        <MyList handleOpenFiltros={handleOpenFiltros} />
        {/* <DrawerFilters
          open={openDrawerFilters}
          handleCancelar={handleCancelar}
          handleAplicarFiltros={handleAplicarFiltros}
        /> */}
      </ListBase>
    </>
  );
};

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]} {...props} />
);

const useStylesDrawer = makeStyles((theme) => ({
  paper: {
    borderRadius: '20px 0 0 20px',
    width: '33vw',
  },
  indicatorTab: {
    backgroundColor: 'blue',
  },
  tab: {
    color: 'blue',
    '&:selected': {
      color: 'blue',
    },
  },
}));

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      filters={<Filter />}
      title="Transações"
      /* isDelete
      disabledDelete={(record) =>
        record ? !!record.parcela_compra_id || !!record.parcela_venda_id : true
      } */
      component="div"
      bulkActionButtons={false}
      sort={{ field: 'data_pagamento', order: 'DESC' }}
      pagination={<TransacoesPagination />}
      // perPage={200}
      perPage={50}
      /* filterDefaultValues={{
        tipo: 'Transacao',
        periodo2: { mes: MES_ATUAL, ano: ANO_ATUAL },
      }} */
      filter={{ visible: true }}
    />
  </>
);

export default List2;

/* fulters:
      categorias
      contas
      situações
      tipos
      de ate   */
/* 
      <ContainerChipInput label="Categorias" options={suggestions} />
      <ContainerChipInput label="Contas" options={suggestions} />
      <ContainerChipInput
        label="Situações"
        options={suggestionsSituacoes}
      />
      <ContainerChipInput label="Tipos" options={suggestionsTipos} />
      <ContainerPeriodoInput /> */

/*       {filterValues.tipo !== 'Transferência' && (
        <ReferenceFieldCategoria
          label="Categoria"
          source="categoria_transacao_2_id"
          reference="categorias_transacao"
          sortable={false}
          headerClassName={classes.categoriaHeader}
          cellClassName={classes.categoriaCell}
          link={false}
        />
      )}
      {filterValues.tipo !== 'Transferência' && (
        <ReferenceField
          label="Conta"
          source="conta_id"
          reference="contas"
          sortable={false}
          headerClassName={classes.contaHeader}
          cellClassName={classes.contaCell}
          link={false}
        >
          <ContaField source="nome" />
        </ReferenceField>
      )}
      {filterValues.tipo === 'Transferência' && (
        <ReferenceField
          label="De"
          source="conta_id"
          reference="contas"
          sortable={false}
          headerClassName={classes.contaHeader}
          cellClassName={classes.contaCell}
          link={false}
        >
          <ContaField source="nome" />
        </ReferenceField>
      )}
      {filterValues.tipo === 'Transferência' && (
        <ReferenceField
          label="Para"
          source="transacao_id"
          reference="transacoes"
          sortable={false}
          headerClassName={classes.contaHeader}
          cellClassName={classes.contaCell}
        >
          <ReferenceField
            label="Para"
            source="conta_id"
            reference="contas"
            sortable={false}
            headerClassName={classes.contaHeader}
            cellClassName={classes.contaCell}
            link={false}
          >
            <ContaField source="nome" />
          </ReferenceField>
        </ReferenceField>
      )} */
