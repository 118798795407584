import React from 'react';
import {
  Filter as FilterAdmin,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  BooleanInput,
} from 'react-admin';

import { PeriodoInput, PrecoInput } from '../../Components/InputsReactAdmin';
import { STATUS_ATIVO } from '../../utils/constants';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <BooleanInput label="Criado Manualmente" source="is_artificial" />
    <TextInput source="codigo" label="Código" />
    <PeriodoInput
      source="periodo"
      label="Criação"
      defaultValue={{
        start: new Date(),
        end: new Date(),
      }}
    />
    <PrecoInput source="search" label="Valor" fixedDecimalScale={false} />
    <ReferenceInput
      fullWidth
      label="Criador"
      source="criador_id"
      reference="users"
      sort={{ field: 'username', order: 'ASC' }}
      filterToQuery={(searchText) => ({ username: `${searchText}` })}
      perPage={10}
      allowEmpty={false}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <ReferenceInput
      fullWidth
      label="Quem Atualizou"
      source="updated_by"
      reference="users"
      sort={{ field: 'username', order: 'ASC' }}
      filterToQuery={(searchText) => ({ username: `${searchText}` })}
      perPage={10}
      allowEmpty={false}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
  </FilterAdmin>
);

export default Filter;
