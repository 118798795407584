import React from 'react';

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import moment from 'moment';

import logo from '../../../../../assets/logo.png';
import { formatMoeda } from '../../../../../utils';

const styles = StyleSheet.create({
  page: {},
  body: {
    width: '100%',
    fontSize: '7',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    borderBottom: '1 solid black',
  },
  headerFinais: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    borderBottom: '1 solid black',
  },
  header2: {
    display: 'flex',
    backgroundColor: '#E4E4E4',
    color: 'black',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10',
    fontWeight: 'bold',
    borderBottom: '1 solid black',
    padding: '2px 0',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid black',
  },
  contentTotais: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid black',
    backgroundColor: '#ffff00',
  },
  contentTotaisTotais: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid black',
    backgroundColor: '#f96c25',
  },
  item: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  item3: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  negativo: {
    backgroundColor: '#ffc7cf',
  },
  positivo: {
    backgroundColor: '#cbf5d0',
  },
});

const ListaProdutos = ({ linhas, totais, porcentagens, medias, mes, ano }) => (
  <Document
    author="anderson"
    title={`CARUARU FRIOS - RELATÓRIO - ${
      mes + 1 < 10 ? `0${mes + 1}` : mes + 1
    }/${ano}`}
  >
    <Page size="A4" style={styles.page} wrap>
      <View style={styles.body}>
        <View
          style={[
            styles.title,
            { margin: '10 0', fontWeight: 'bold', fontSize: '15' },
          ]}
        >
          <Image src={logo} style={{ width: '30%' }} />
          <Text style={{ marginTop: '15px' }}>{`Relatório Mensal ${
            mes + 1 < 10 ? `0${mes + 1}` : mes + 1
          }/${ano}`}</Text>
        </View>

        <View style={styles.header}>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>Dia</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>Dinheiro</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>Pix</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>Crédito</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>Débito</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>Voucher</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>Devolução</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>Boleto</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>Total</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>Total Acum.</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>Lucro</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>Lucro Acum.</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>Despesas</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>Despesas Acum.</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>Lucro Líq.</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>Lucro Líq. Acum.</Text>
          </View>
        </View>

        {linhas.map((dia) => (
          <View style={styles.content}>
            <View style={[styles.item3, { flex: 1 }]}>
              <Text>{dia.dia}</Text>
            </View>
            <View style={[styles.item3, { flex: 1 }]}>
              <Text>{formatMoeda(dia.dinheiro)}</Text>
            </View>
            <View style={[styles.item, { flex: 1 }]}>
              <Text>{formatMoeda(dia.pix)}</Text>
            </View>
            <View style={[styles.item, { flex: 1 }]}>
              <Text>{formatMoeda(dia.credito)}</Text>
            </View>
            <View style={[styles.item, { flex: 1 }]}>
              <Text>{formatMoeda(dia.debito)}</Text>
            </View>
            <View style={[styles.item, { flex: 1 }]}>
              <Text>{formatMoeda(dia.vonchuer)}</Text>
            </View>
            <View style={[styles.item, { flex: 1 }]}>
              <Text>{formatMoeda(dia.descontoDevolucao)}</Text>
            </View>
            <View style={[styles.item, { flex: 1 }]}>
              <Text>{formatMoeda(dia.boleto)}</Text>
            </View>
            <View style={[styles.item, { flex: 1 }]}>
              <Text>{formatMoeda(dia.total)}</Text>
            </View>
            <View style={[styles.item, { flex: 1 }]}>
              <Text>{formatMoeda(dia.totalAcumulado)}</Text>
            </View>
            <View style={[styles.item, { flex: 1 }]}>
              <Text>{formatMoeda(dia.lucroMensal)}</Text>
            </View>
            <View style={[styles.item, { flex: 1 }]}>
              <Text>{formatMoeda(dia.totalLucroAcumulado)}</Text>
            </View>
            <View style={[styles.item, { flex: 1 }]}>
              <Text>{formatMoeda(dia.totalDespesasAcumulado)}</Text>
            </View>
            <View style={[styles.item, { flex: 1 }]}>
              <Text>{formatMoeda(dia.totalLucroLiquidoAcumulado)}</Text>
            </View>
          </View>
        ))}

        <View style={styles.headerFinais}>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>Totais</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{formatMoeda(totais.dinheiro)}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{formatMoeda(totais.pix)}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{formatMoeda(totais.credito)}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{formatMoeda(totais.debito)}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{formatMoeda(totais.vonchuer)}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{formatMoeda(totais.descontoDevolucao)}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{formatMoeda(totais.boleto)}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{formatMoeda(totais.total)}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>-</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{formatMoeda(totais.lucro)}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>-</Text>
          </View>

          <View style={[styles.item, { flex: 1 }]}>
            <Text>{formatMoeda(totais.despesas)}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>-</Text>
          </View>

          <View style={[styles.item, { flex: 1 }]}>
            <Text>{formatMoeda(totais.lucroLiquido)}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>-</Text>
          </View>
        </View>

        <View style={styles.headerFinais}>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>%</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text />
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{porcentagens.dinheiro}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{porcentagens.pix}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{porcentagens.credito}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{porcentagens.debito}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{porcentagens.vonchuer}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{porcentagens.descontoDevolucao}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{porcentagens.boleto}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{porcentagens.total}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>-</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{porcentagens.lucro}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>-</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{porcentagens.despesas}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>-</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{porcentagens.lucroLiquido}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>-</Text>
          </View>
        </View>

        <View style={styles.headerFinais}>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>Média</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text />
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{formatMoeda(medias.dinheiro)}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{formatMoeda(medias.pix)}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{formatMoeda(medias.credito)}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{formatMoeda(medias.debito)}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{formatMoeda(medias.vonchuer)}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{formatMoeda(medias.descontoDevolucao)}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{formatMoeda(medias.boleto)}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{formatMoeda(medias.total)}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>-</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{formatMoeda(medias.lucro)}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>-</Text>
          </View>

          <View style={[styles.item, { flex: 1 }]}>
            <Text>{formatMoeda(medias.despesas)}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>-</Text>
          </View>

          <View style={[styles.item, { flex: 1 }]}>
            <Text>{formatMoeda(medias.lucroLiquido)}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>-</Text>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);
export default ListaProdutos;
