import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '400px',
  },
}));

const DataField = (props) => {
  const classes = useStyles();

  const record = useRecordContext(props);
  const value = record
    ? record.record.created_at
      ? moment(new Date(record.record.created_at)).format('DD/MM/YYYY')
      : '-'
    : '-';

  return <Typography className={classes.text}>{value}</Typography>;
};

export default DataField;
