import React, { useRef, useContext } from 'react';

import { Box } from '@material-ui/core';

import { DialogoConfirmacao, SearchProduto } from '../../../..';
import { STATUS_ATIVO, toStringPedidos } from '../../../../../utils';
import { DialogoDeletarComSenha } from '../../../../DialogoEditarVenda/components';
import { useItens } from '../../hooks/useItens';
import {
  tipoStatusAndamentoCriarPedido,
  tipoStatusAndamentoAprovar,
  tipoStatusAndamentoAprovarVolta,
  tipoStatusAndamentoDetalhes,
} from '../../utils/constants';
import { PedidoContext } from '../contextPedido';
import AppBar from './AppBar';
import DialogoAprovacaoVolta from './DialogoAprovacaoVolta';
import DialogoLiberacao from './DialogoLiberacao';
import DialogoNegociacoes from './DialogoNegociacoes';
import Tabela from './Tabela';

const FECHAR_PEDIDO_ACTION = 'F8';
const CANCELAR_PEDIDO_ACTION = 'F4';
const DELETAR_ITEM_ACTION = 'F2';

const FrenteCompra = ({ voltar, prosseguir }) => {
  const {
    pedido: {
      itens,
      idEdit,
      cliente,
      isMargemCartao,
      dataMinima,
      dataMaxima,
      tipoStatusAndamento,
    },
    dispatch,
  } = useContext(PedidoContext);
  const refDialogoConfirmacao = useRef(null);
  const refDialogoDeletar = useRef(null);
  const refDialogoLiberacao = useRef(null);
  const refDialogoAprovacaoVolta = useRef(null);
  const refDialogoNegociacoes = useRef(null);

  const {
    searchHandle,
    addNewItem,
    setSearch,
    refSearch,
    search,
    enqueueSnackbar,
    handleOpenNota,
  } = useItens(
    itens,
    updateItem,
    cliente,
    idEdit > 0,
    idEdit,
    isMargemCartao,
    dataMinima,
    dataMaxima,
    tipoStatusAndamento,
  );

  function updateItem(body) {
    dispatch({
      type: 'ADD_ITEM',
      item: body,
    });
    if (refSearch.current) refSearch.current.focus();
  }

  function removeItens(indices) {
    dispatch({ type: 'REMOVE_ITEM', indices });
  }

  function atualizarItem(body, uuid) {
    dispatch({
      type: 'UPDATE_ITEM',
      item: body,
      uuid,
      tipoStatusAndamento,
    });
    if (refSearch.current) refSearch.current.focus();
  }

  function handleConfirma(codigo) {
    switch (codigo) {
      case CANCELAR_PEDIDO_ACTION:
        voltar();
        break;
      case 0:
        handleOpenNota2();
        break;
      default:
        break;
    }
  }

  async function handleOpenNota2() {
    await handleOpenNota();
    prosseguir();
  }

  function getTituloFechar(tipoStatusAndamento) {
    switch (tipoStatusAndamento) {
      case tipoStatusAndamentoAprovar:
        return 'Aprovação do Pedido de Liberação de Preços';
      case tipoStatusAndamentoAprovarVolta:
        return 'Aceitação do Pedido de Liberação de Preços';
      case tipoStatusAndamentoCriarPedido:
        return 'Criação de Pedido';
      default:
        return '';
    }
  }

  function getMensagemFechar(tipoStatusAndamento) {
    switch (tipoStatusAndamento) {
      case tipoStatusAndamentoAprovar:
        return 'Deseja realmente finalizar a Aprovação deste Pedido de Liberação de Preços?';
      case tipoStatusAndamentoAprovarVolta:
        return 'Deseja realmente finalizar a Aceitação deste Pedido de Liberação de Preços?';
      case tipoStatusAndamentoCriarPedido:
        return 'Deseja realmente finalizar a criação deste Pedido de Liberação de Preços?';
      default:
        return '';
    }
  }

  function handleActions(action) {
    switch (action) {
      case FECHAR_PEDIDO_ACTION:
        if (itens.length > 0) {
          if (tipoStatusAndamento !== tipoStatusAndamentoDetalhes) {
            if (refDialogoConfirmacao.current) {
              refDialogoConfirmacao.current.handleOpen(
                getTituloFechar(tipoStatusAndamento),
                getMensagemFechar(tipoStatusAndamento),
                0,
              );
            }
          } else {
            handleOpenNota2();
          }
        } else {
          enqueueSnackbar('É necessário ao menos um item no pedido!', {
            variant: 'warning',
          });
        }
        break;
      case CANCELAR_PEDIDO_ACTION:
        voltar();
        break;
      case DELETAR_ITEM_ACTION:
        if (itens.length > 0) {
          if (refDialogoDeletar.current) {
            refDialogoDeletar.current.handleOpen();
          }
        }
        break;
      default:
        break;
    }
  }

  function verificaDuplicidadeLocal(produto, itens) {
    for (let i = 0; i < itens.length; i++) {
      if (produto.id === itens[i].produto.id) return true;
    }
    return false;
  }

  async function openDialogoLiberacao(produto) {
    if (produto.permitir_criacao_liberacoes) {
      if (!verificaDuplicidadeLocal(produto, itens)) {
        if (refDialogoLiberacao.current) {
          refDialogoLiberacao.current.handleCreate(produto, isMargemCartao);
        }
      } else {
        enqueueSnackbar('Este item já foi adicionado neste pedido!', {
          variant: 'error',
        });
      }
    } else {
      enqueueSnackbar(
        'Este item foi travado pelo comprador para não ser utilizado em liberações de preço!',
        {
          variant: 'error',
        },
      );
    }
  }

  function middlewareSearchHandle(codigo, id) {
    searchHandle(codigo, id, openDialogoLiberacao);
  }

  function handleEditar(row, uidd) {
    if (refDialogoLiberacao.current) {
      refDialogoLiberacao.current.handleEdit(
        row.uidd,
        row.valor + row.verba_unitaria,
        row.tipo_unidade,
        row.produto,
        row.quantidade,
        row.dadosPrecificacao,
        row.verba_unitaria_solicitado,
        isMargemCartao,
        0,
        true,
        row.is_fim_negociacao,
        row.tipo_unidade_quantidade,
        row.tipo_unidade_preco_sugerido,
      );
    }
  }

  function handleAprovacao(row, uidd) {
    if (refDialogoLiberacao.current) {
      refDialogoLiberacao.current.handleEdit(
        row.uidd,
        row.valor + row.verba_unitaria,
        row.tipo_unidade,
        row.produto,
        row.quantidade,
        row.dadosPrecificacao,
        //row.verba_unitaria_solicitado,
        row.verba_unitaria,
        isMargemCartao,
        row.verba_unitaria_old,
        false,
        row.is_fim_negociacao,
        row.tipo_unidade_quantidade,
        row.tipo_unidade_preco_sugerido,
      );
    }
  }

  function handleAprovacaoVolta(row, uidd) {
    /* if (refDialogoAprovacaoVolta.current) {
      refDialogoAprovacaoVolta.current.handleOpen();
    } */
    if (refDialogoLiberacao.current) {
      refDialogoLiberacao.current.handleEdit(
        row.uidd,
        row.valor + row.verba_unitaria,
        row.tipo_unidade,
        row.produto,
        row.quantidade,
        row.dadosPrecificacao,
        row.verba_unitaria_solicitado,
        isMargemCartao,
        row.verba_unitaria_solicitado_old,
        false,
        row.is_fim_negociacao,
        row.tipo_unidade_quantidade,
        row.tipo_unidade_preco_sugerido,
      );
    }
  }

  function handleDetalhes(row, uidd) {}

  function handleGrafico(row, uidd) {}

  function isEditarAprovacao(row) {
    if (row) {
      if (row.verba_unitaria > 0) {
        return true;
      }
    }
    return false;
  }

  function getDisabledForward() {
    switch (tipoStatusAndamento) {
      case tipoStatusAndamentoCriarPedido: {
        return !(itens.length > 0);
      }
      case tipoStatusAndamentoAprovar: {
        for (let i = 0; i < itens.length; i++) {
          if (!itens[i].status_aprovado && isEditarAprovacao(itens[i]))
            return true;
        }
        return false;
      }
      case tipoStatusAndamentoAprovarVolta: {
        for (let i = 0; i < itens.length; i++) {
          if (itens[i].status_aprovado_volta === null) return true;
        }
        return false;
      }
      case tipoStatusAndamentoDetalhes: {
        return false;
      }

      default:
        return true;
    }
  }

  function handleNegociacoes(row, uidd) {
    if (refDialogoNegociacoes.current) {
      refDialogoNegociacoes.current.handleOpen();
    }
  }

  return (
    <>
      <AppBar
        disabledBack={false}
        disabledForward={getDisabledForward()}
        disabledDeleteItem={
          itens.length === 0 ||
          tipoStatusAndamento !== tipoStatusAndamentoCriarPedido
        }
        onClick={handleActions}
        FECHAR_PEDIDO_ACTION={FECHAR_PEDIDO_ACTION}
        CANCELAR_PEDIDO_ACTION={CANCELAR_PEDIDO_ACTION}
        DELETAR_ITEM_ACTION={DELETAR_ITEM_ACTION}
        numeroPedido={idEdit}
        idEdit={idEdit}
        hideDeleteItem={tipoStatusAndamento !== tipoStatusAndamentoCriarPedido}
        tipoStatusAndamento={tipoStatusAndamento}
      />
      {tipoStatusAndamento === tipoStatusAndamentoCriarPedido ? (
        <Box padding="10px">
          <SearchProduto
            label="Pesquisar produto"
            value={search}
            onChange={(e) => setSearch(e)}
            fullwidth
            searchHandle={middlewareSearchHandle}
            ref={refSearch}
            handleKey={(keyCode, keyName) => {
              handleActions(keyName);
            }}
            filters={{
              status: STATUS_ATIVO,
              //permitir_criacao_liberacoes: true,
            }}
            mantemFiltroPadrao={false}
          />
        </Box>
      ) : null}
      {itens.length > 0 ? (
        <div
          style={{
            margin: '10px auto',
            backgroundColor: 'white',
            padding: '10px',
            opacity: '70%',
            borderRadius: '5px',
            fontWeight: 'bold',
            width: '50%',
            border: '5px solid red',
          }}
        >
          <h2
            style={{
              marginBottom: '10px',
              textAlign: 'center',
              textDecoration: 'underline',
              textDecorationColor: 'red',
            }}
          >
            LEGENDA
          </h2>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '5px',
            }}
          >
            <div>
              <span style={{ color: 'green' }}>VERDE</span> - Solicitado pelo
              Cliente
            </div>
            <div>
              <span style={{ color: 'red' }}>VERMELHO</span> - Aprovado pelo
              Comprador
            </div>
          </div>
        </div>
      ) : null}
      <Box display="flex" justifyContent="space-between" padding="10px">
        {itens.length > 0 ? (
          <Box padding="0 0px" flex={4}>
            <Tabela
              handleEditar={handleEditar}
              rows={toStringPedidos(itens)}
              tipoStatusAndamento={tipoStatusAndamento}
              handleAprovacao={handleAprovacao}
              handleAprovacaoVolta={handleAprovacaoVolta}
              handleDetalhes={handleDetalhes}
              handleGrafico={handleGrafico}
              handleNegociacoes={handleNegociacoes}
              headCells={
                tipoStatusAndamento === tipoStatusAndamentoCriarPedido ||
                tipoStatusAndamento === tipoStatusAndamentoDetalhes
                  ? [
                      {
                        field: 'numeroItem',
                        label: 'Cód.',
                      },
                      {
                        field: 'total',
                        label: 'Produto',
                        right: true,
                      },
                      {
                        field: 'total',
                        label: 'Preço Atual',
                        right: true,
                      },
                      {
                        field: 'total',
                        label: 'Preço Solicitado',
                        right: true,
                      },
                      {
                        field: 'total',
                        label: 'QTDE',
                        right: true,
                      },
                      {
                        field: 'total',
                        label: 'Total Original',
                        right: true,
                      },
                      {
                        field: 'total',
                        label: 'Total com Desconto',
                        right: true,
                      },
                      {
                        field: 'total',
                        label: 'Desconto Total',
                        right: true,
                      },
                    ]
                  : [
                      {
                        field: 'status',
                        label: 'Status.',
                      },
                      {
                        field: 'numeroItem',
                        label: 'Cód.',
                      },
                      {
                        field: 'total',
                        label: 'Produto',
                        right: true,
                      },
                      {
                        field: 'total',
                        label: 'Preço Atual',
                        right: true,
                      },
                      {
                        field: 'total',
                        label: 'Preço Solicitado',
                        right: true,
                      },
                      /* {
                        field: 'total',
                        label: 'Preço Aprovado',
                        right: true,
                      }, */
                      {
                        field: 'total',
                        label: 'QTDE',
                        right: true,
                      },
                      {
                        field: 'total',
                        label: 'Total Original',
                        right: true,
                      },
                      {
                        field: 'total',
                        label: 'Total com Desconto',
                        right: true,
                      },
                      {
                        field: 'total',
                        label: 'Desconto Total',
                        right: true,
                      },
                    ]
              }
            />
          </Box>
        ) : null}
      </Box>
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={handleConfirma}
      />
      <DialogoDeletarComSenha
        ref={refDialogoDeletar}
        itens={itens}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={(numeroItem) => {
          removeItens([itens[numeroItem - 1].uidd]);
        }}
        title="Autorização para remover item!"
        usarSenha={false}
      />
      <DialogoLiberacao
        ref={refDialogoLiberacao}
        cliente={cliente}
        handleClose={() => {}}
        handleSalvar2={(dados, id) => {
          if (id <= 0) {
            addNewItem(dados);
          } else {
            atualizarItem(dados, id);
          }
        }}
        tipoStatusAndamento={tipoStatusAndamento}
      />
      <DialogoAprovacaoVolta
        ref={refDialogoAprovacaoVolta}
        handleConfirma={handleConfirma}
      />
      <DialogoNegociacoes ref={refDialogoNegociacoes} />
    </>
  );
};

export default FrenteCompra;
