import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PrintIcon from '@material-ui/icons/Print';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { getListAllAPI, imprimirContraProva } from '../../services';
import { writeCodigoBarras } from '../../utils/codigoBarras';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_ID = -1;

const DialogoCategoriaTransacao = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState(DEFAULT_ID);

  const [carregando, setCarregando] = useState(false);

  const [horarioEntrada1, setHorarioEntrada1] = useState(null);
  const [horarioSaida1, setHorarioSaida1] = useState(null);
  const [horarioEntrada2, setHorarioEntrada2] = useState(null);
  const [horarioSaida2, setHorarioSaida2] = useState(null);

  const [colaborador, setColaborador] = useState('');
  const [data, setData] = useState('');

  const [pontos, setPontos] = useState([]);

  const classes = useStyles({ cor: '#009FD4' });

  const handleCloseDialog = () => {
    setOpen(false);
  };

  function formatData(data) {
    return moment(data).format('DD/MM/YYYY');
  }

  function formatData2(data) {
    if (data) {
      //return moment(data).format('DD/MM/YYYY - HH:mm');
      return moment(data).format('HH:mm');
    }
    return '-';
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'pontos_colaborador',
        ['id', 'asc'],
        { id: [id] },
        ['colaborador', 'dia', 'pontosUnitarios'],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);

        setHorarioEntrada1(
          data.data[0].horario_entrada_1
            ? new Date(data.data[0].horario_entrada_1)
            : null,
        );

        setHorarioSaida1(
          data.data[0].horario_saida_1
            ? new Date(data.data[0].horario_saida_1)
            : null,
        );

        setHorarioEntrada2(
          data.data[0].horario_entrada_2
            ? new Date(data.data[0].horario_entrada_2)
            : null,
        );

        setHorarioSaida2(
          data.data[0].horario_saida_2
            ? new Date(data.data[0].horario_saida_2)
            : null,
        );

        setColaborador(data.data[0].colaborador.nome);

        setData(moment(new Date(data.data[0].dia.data)).format('DD/MM/YYYY'));

        setPontos(data.data[0].pontosUnitarios);

        setCarregando(false);
      } else {
        throw 'Esta Ponto não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
    },
  }));

  async function handleImprimirContraProva(id) {
    const codigo = await imprimirContraProva(id);
    if (codigo < 0) {
      enqueueSnackbar('Erro ao imprimir este ponto!', {
        variant: 'error',
      });
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="md"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          {`Detalhes Ponto Colaborador - ${
            colaborador ? colaborador : ''
          } - ${data}`}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 10px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Box p="1em">
              <Typography
                variant="h6"
                style={{
                  marginBottom: '5px',
                  fontWeight: 'bold',
                }}
              >
                1º Expediente
              </Typography>

              <Box
                display="flex"
                style={{
                  marginBottom: '40px',
                  marginLeft: '20px',
                }}
              >
                <Box flex={1} mr="0.5em">
                  <span
                    style={{
                      fontWeight: 'bold',
                      marginRight: '5px',
                    }}
                  >
                    Entrada:{' '}
                  </span>{' '}
                  <span>{formatData2(horarioEntrada1)}</span>
                </Box>
                <Box flex={1} ml="0.5em">
                  <span
                    style={{
                      fontWeight: 'bold',
                      marginRight: '5px',
                    }}
                  >
                    Saída:{' '}
                  </span>{' '}
                  <span>{formatData2(horarioSaida1)}</span>
                </Box>
              </Box>

              <Typography
                variant="h6"
                style={{
                  marginBottom: '5px',
                  fontWeight: 'bold',
                }}
              >
                2º Expediente
              </Typography>

              <Box
                display="flex"
                style={{
                  marginBottom: '40px',
                  marginLeft: '20px',
                }}
              >
                <Box flex={1} mr="0.5em">
                  <span
                    style={{
                      fontWeight: 'bold',
                      marginRight: '5px',
                    }}
                  >
                    Entrada:{' '}
                  </span>{' '}
                  <span>{formatData2(horarioEntrada2)}</span>
                </Box>
                <Box flex={1} ml="0.5em">
                  <span
                    style={{
                      fontWeight: 'bold',
                      marginRight: '5px',
                    }}
                  >
                    Saída:{' '}
                  </span>{' '}
                  <span>{formatData2(horarioSaida2)}</span>
                </Box>
              </Box>

              <Typography
                variant="h6"
                style={{
                  marginBottom: '5px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                Batidas de Ponto
              </Typography>

              <Box>
                <TableContainer component={Paper} className={classes.root}>
                  <Table aria-label="collapsible table">
                    <TableHead
                      style={{
                        color: 'white',
                        fontWeight: 'bold',
                        backgroundColor: '#009FD4',
                      }}
                    >
                      <TableRow className={classes.rowHead}>
                        <TableCell className={classes.rowHead}>
                          Código
                        </TableCell>
                        <TableCell className={classes.rowHead}>
                          Horário
                        </TableCell>
                        <TableCell className={classes.rowHead}>Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pontos.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row">
                            {writeCodigoBarras(row.id)}
                          </TableCell>
                          <TableCell>{formatData2(row.data)}</TableCell>
                          <TableCell>
                            <Tooltip
                              title="Imprimir Última Contraprova"
                              placement="bottom"
                              aria-label="excluir2"
                            >
                              <IconButton
                                aria-label="imprimir-checklist"
                                size="small"
                                onClick={() =>
                                  handleImprimirContraProva(row.id)
                                }
                              >
                                <PrintIcon style={{ color: 'green' }} />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
