import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
} from 'react';

import { Box, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { AutoCompleteRemoto } from '../../../Components';
import {
  formatCPF,
  formatCNPJ,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  ATALHO_AVANCAR,
  ATALHO_VOLTAR,
  STATUS_ATIVO,
  TIPO_OFERTA_PRODUTO_LIBERACAO,
} from '../../../utils';

const colorHeader = SECONDARY_COLOR;

const useStyles = makeStyles((theme) => ({
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  textField: {},
  btnImprimir: {
    backgroundColor: theme.palette.secondary.main,
    opacity: 0.4,
  },
  header: {
    height: '17%',
  },
  body: {
    height: '83%',
  },
  footer: {
    height: '15vh',
    padding: '10px',
    paddingBottom: '20px',
  },
  textOverflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const DialogoConfirmacao = forwardRef(({ handleConfirma }, ref) => {
  const [open, setOpen] = useState(false);
  const [codigo, setCodigo] = useState(0);
  const [modo, setModo] = useState();

  const classes = useStyles();
  const [cliente, setCliente] = useState(null);

  const refNome = useRef(null);
  const refNomeFantasia = useRef(null);
  const refCpf = useRef(null);
  const refCnpj = useRef(null);

  useImperativeHandle(ref, () => ({
    handleOpen(dados) {
      setOpen(true);
      setCodigo(dados);
      setModo(0);
      setCliente(null);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleSalvarCriacao = () => {
    setOpen(false);
    handleConfirma(codigo, -1);
  };

  const handleSalvarEditar = () => {
    setOpen(false);
    handleConfirma(codigo, cliente.id);
  };

  return (
    <div>
      {modo === 0 ? (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title">
            Resolução de problema de cadastro do cliente para a liberação da
            devolução de venda
          </DialogTitle>
          <DialogContent dividers>
            <h2
              style={{
                textAlign: 'center',
                margin: '10px',
                marginBottom: '30px',
              }}
            >
              Qual das opções você deseja?
            </h2>
            <div
              style={{
                display: 'flex',
                gap: '10px',
                justifyContent: 'space-between',
              }}
            >
              <Button
                onClick={handleSalvarCriacao}
                color="secondary"
                variant="contained"
              >
                Criar Cadastro de Cliente
              </Button>
              <Button
                onClick={() => setModo(1)}
                color="secondary"
                variant="contained"
              >
                Selecionar Cliente Cadastrado
              </Button>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="alert-dialog-title">
            Seleção de Cliente para a Devolução
          </DialogTitle>
          <DialogContent dividers>
            <Box padding="10px" className={classes.header}>
              <Box
                display="flex"
                css={{
                  backgroundColor: 'white',
                  opacity: '0.75',
                  height: '100%',
                  padding: '0px 10px',
                  flexDirection: 'column',
                }}
              >
                <Box display="flex" alignItems="center" flex={1}>
                  <AutoCompleteRemoto
                    ref={refNome}
                    id="cliente"
                    name="cliente"
                    label="Responsável"
                    value={cliente}
                    onChange={(value) => {
                      setCliente(value);
                    }}
                    handleKey={(keyCode, keyName) => {}}
                    handleEnter={() => {
                      if (refNomeFantasia.current) {
                        refNomeFantasia.current.focus();
                        refNomeFantasia.current.select();
                      }
                    }}
                    resource="clientes"
                    nested={['cidade.estado', 'categoria']}
                    toString={false}
                    fullwidth
                    autoFocus
                    filters={{ status: STATUS_ATIVO, ids_excluso: [1] }}
                  />
                  <AutoCompleteRemoto
                    ref={refNomeFantasia}
                    id="cliente"
                    name="cliente"
                    label="Nome Fantasia"
                    value={cliente}
                    onChange={(value) => {
                      setCliente(value);
                    }}
                    handleKey={(keyCode, keyName) => {}}
                    handleEnter={() => {
                      if (refCpf.current) {
                        refCpf.current.focus();
                        refCpf.current.select();
                      }
                    }}
                    resource="clientes"
                    campo="nome_fantasia"
                    nested={['cidade.estado', 'categoria']}
                    toString={false}
                    style={{ marginLeft: '10px' }}
                    fullwidth
                    filters={{ status: STATUS_ATIVO, ids_excluso: [1] }}
                  />
                </Box>
                <Box display="flex" alignItems="center" flex={1}>
                  <AutoCompleteRemoto
                    ref={refCpf}
                    id="cliente"
                    name="cliente"
                    label="CPF"
                    value={cliente}
                    onChange={(value) => {
                      setCliente(value);
                    }}
                    handleKey={(keyCode, keyName) => {}}
                    handleEnter={() => {
                      if (refCnpj.current) {
                        refCnpj.current.focus();
                        refCnpj.current.select();
                      }
                    }}
                    resource="clientes"
                    campo="cpf"
                    campoPesquisa="cpf"
                    nested={['cidade.estado', 'categoria']}
                    toString={false}
                    fullwidth
                    filters={{ status: STATUS_ATIVO, ids_excluso: [1] }}
                  />
                  <AutoCompleteRemoto
                    ref={refCnpj}
                    id="cliente"
                    name="cliente"
                    label="CNPJ"
                    value={cliente}
                    onChange={(value) => {
                      setCliente(value);
                    }}
                    handleKey={(keyCode, keyName) => {}}
                    handleEnter={() => {
                      if (refNome.current) {
                        refNome.current.focus();
                        refNome.current.select();
                      }
                    }}
                    resource="clientes"
                    campo="cnpj"
                    campoPesquisa="cnpj"
                    nested={['cidade.estado', 'categoria']}
                    toString={false}
                    style={{ marginLeft: '10px' }}
                    fullwidth
                    filters={{ status: STATUS_ATIVO, ids_excluso: [1] }}
                  />
                </Box>
                {cliente ? (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flex={1}
                    style={{
                      border: `1px solid ${PRIMARY_COLOR}`,
                      marginLeft: '0px',
                      padding: '5px',
                      borderRadius: '5px',
                      marginBottom: '10px',
                    }}
                  >
                    <Box
                      marginLeft="10px"
                      display="flex"
                      flexDirection="column"
                      flex={1}
                    >
                      <Box
                        marginLeft="0px"
                        fontSize="15px"
                        fontWeight="bold"
                        display="flex"
                        flex={1}
                      >
                        <p
                          className={classes.textOverflow}
                          style={{
                            marginBottom: '0px',
                            color: colorHeader,
                            marginRight: '10px',
                          }}
                        >
                          Responsável:
                        </p>
                        {cliente.nome}
                      </Box>
                      <Box
                        marginLeft="0px"
                        fontSize="15px"
                        fontWeight="bold"
                        display="flex"
                        flex={1}
                      >
                        <p
                          className={classes.textOverflow}
                          style={{
                            marginBottom: '0px',
                            color: colorHeader,
                            marginRight: '10px',
                          }}
                        >
                          Razão Social:
                        </p>
                        {cliente.razao_social}
                      </Box>
                      <Box
                        marginLeft="0px"
                        fontSize="15px"
                        fontWeight="bold"
                        display="flex"
                        flex={1}
                      >
                        <p
                          className={classes.textOverflow}
                          style={{
                            marginBottom: '0px',
                            color: colorHeader,
                            marginRight: '10px',
                          }}
                        >
                          CPF:
                        </p>
                        {formatCPF(cliente.cpf)}
                      </Box>
                      <Box
                        marginLeft="0px"
                        fontSize="15px"
                        fontWeight="bold"
                        display="flex"
                        flex={1}
                      >
                        <p
                          className={classes.textOverflow}
                          style={{
                            marginBottom: '0px',
                            color: colorHeader,
                            marginRight: '10px',
                          }}
                        >
                          CNPJ:
                        </p>
                        {formatCNPJ(cliente.cnpj)}
                      </Box>
                    </Box>
                    <Box
                      marginLeft="10px"
                      display="flex"
                      flexDirection="column"
                      flex={1}
                    >
                      <Box
                        marginLeft="0px"
                        fontSize="15px"
                        fontWeight="bold"
                        display="flex"
                        flex={1}
                        justifyContent="flex-start"
                      >
                        <p
                          className={classes.textOverflow}
                          style={{
                            marginBottom: '0px',
                            color: colorHeader,
                            marginRight: '10px',
                          }}
                        >
                          Nome Fantasia:
                        </p>
                        {cliente.nome_fantasia}
                      </Box>
                      <Box
                        marginLeft="0px"
                        fontSize="15px"
                        fontWeight="bold"
                        display="flex"
                        flex={1}
                        justifyContent="flex-start"
                      >
                        <p
                          className={classes.textOverflow}
                          style={{
                            marginBottom: '0px',
                            color: colorHeader,
                            marginRight: '10px',
                          }}
                        >
                          Bairro:
                        </p>
                        {cliente.bairro}
                      </Box>
                      <Box
                        marginLeft="0px"
                        fontSize="15px"
                        fontWeight="bold"
                        display="flex"
                        flex={1}
                        justifyContent="flex-start"
                      >
                        <p
                          className={classes.textOverflow}
                          style={{
                            marginBottom: '0px',
                            color: colorHeader,
                            marginRight: '10px',
                          }}
                        >
                          Cidade:
                        </p>
                        {cliente.cidade
                          ? `${cliente.cidade.nome} - ${cliente.cidade.estado.sigla}`
                          : ''}
                      </Box>
                      <Box
                        marginLeft="0px"
                        fontSize="15px"
                        fontWeight="bold"
                        display="flex"
                        flex={1}
                        justifyContent="flex-start"
                      >
                        <p
                          className={classes.textOverflow}
                          style={{
                            marginBottom: '0px',
                            color: colorHeader,
                            marginRight: '10px',
                          }}
                        >
                          Logradouro:
                        </p>
                        {cliente.logradouro}
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <h2>é necessário selecionar o cliente</h2>
                )}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button onClick={handleClose} color="secondary" variant="outlined">
              Cancelar
            </Button>
            <Button
              onClick={handleSalvarEditar}
              color="secondary"
              //autoFocus
              disabled={!cliente}
              variant="outlined"
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
});

export default DialogoConfirmacao;
