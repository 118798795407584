import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
} from 'react';

import {
  makeStyles,
  createStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import {
  formatPeso,
  formatUnidades,
  SECONDARY_COLOR,
  ATALHO_AVANCAR,
  ATALHO_VOLTAR,
} from '../../../../../utils';
import { IntegerInput, PesoInput } from '../../Inputs';

const inputs = [];

const linhas1 = [];

for (let i = 0; i < 100; i++) {
  inputs.push(0);
}

for (let i = 0; i < 50; i++) {
  linhas1.push({
    unidades: 0,
    peso: 0,
  });
}

const useStyles = makeStyles((theme) =>
  createStyles({
    action: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    root: {
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;

const DialogoSomaPesosCerto = forwardRef(({ onChangeTotal }, ref) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [totais, setTotais] = useState({ unidades: 0, peso: 0 });

  const [linhas, setLinhas] = useState(linhas1);
  const refs = useRef([]);
  refs.current = inputs.map(
    (ref, index) => (refs.current[index] = React.createRef()),
  );

  function getRefNextInput(index) {
    let position = -1;
    if (index <= 98) {
      position = index + 1;
    } else {
      position = 0;
    }

    return position;
  }

  function handleNextInput(index) {
    const position = getRefNextInput(index);
    if (position === -1) {
      refs.current[0].current.focus();
      refs.current[0].current.select();
    } else if (refs.current[position].current) {
      refs.current[position].current.focus();
      refs.current[position].current.select();
    }
  }

  function handleActions(action) {
    switch (action) {
      case FECHAR_VENDA_ACTION:
        handleSalvar();
        break;
      case CANCELAR_VENDA_ACTION:
        handleClose();
        break;
      default:
        break;
    }
  }

  function handleKey(keyCode, keyName) {
    handleActions(keyName);
  }

  function updateUnidade(index, unidades) {
    const gridOld = linhas.slice();
    const unidadesCorrigidas = Number.isNaN(unidades) ? 0 : unidades;
    setTotais({
      unidades: totais.unidades - gridOld[index].unidades + unidadesCorrigidas,
      peso: totais.peso,
    });
    gridOld[index].unidades = unidadesCorrigidas;
    setLinhas(gridOld);
  }

  function updatePeso(index, peso) {
    const gridOld = linhas.slice();
    const pesoCorrigido = Number.isNaN(peso) ? 0 : peso;
    setTotais({
      unidades: totais.unidades,
      peso: totais.peso - gridOld[index].peso + pesoCorrigido,
    });
    gridOld[index].peso = pesoCorrigido;
    setLinhas(gridOld);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleSalvar = () => {
    onChangeTotal(totais);
    handleClose();
  };

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
      setLinhas(linhas1);
      setTotais({ peso: 0, unidades: 0 });
    },
  }));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle
        id="form-dialog-title"
        style={{ color: 'white', backgroundColor: SECONDARY_COLOR }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{ flex: 1, textAlign: 'left' }}
          >{`Unidades = ${formatUnidades(totais.unidades)}`}</div>
          <div style={{ flex: 1, textAlign: 'left' }}>{`Peso = ${formatPeso(
            totais.peso,
          )} Kg`}</div>
        </div>
      </DialogTitle>
      <DialogContent dividers style={{ padding: '10px' }}>
        <div className={classes.root}>
          <div>
            <div>
              {linhas1.map((value, index) => (
                <div>
                  <IntegerInput
                    ref={refs.current[2 * index]}
                    handleEnter={() => handleNextInput(2 * index)}
                    label=""
                    handleKey={handleKey}
                    value={
                      Number.isNaN(linhas[index].unidades)
                        ? ''
                        : String(linhas[index].unidades)
                    }
                    onChange={(value) =>
                      updateUnidade(index, parseInt(value.target.value, 10))
                    }
                    style={{ marginRight: '10px' }}
                    size="small"
                    margin="dense"
                    autoFocus={index === 0}
                  />
                  <PesoInput
                    ref={refs.current[2 * index + 1]}
                    handleEnter={() => handleNextInput(2 * index + 1)}
                    label=""
                    handleKey={handleKey}
                    value={
                      Number.isNaN(linhas[index].peso)
                        ? ''
                        : String(linhas[index].peso)
                    }
                    onChange={(value) =>
                      updatePeso(index, parseFloat(value.target.value))
                    }
                    size="small"
                    margin="dense"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button
          onClick={handleClose}
          color="secondary"
          variant="contained"
          tabindex="-1"
        >
          {`Fechar (${CANCELAR_VENDA_ACTION})`}
        </Button>
        <Button
          onClick={handleSalvar}
          color="secondary"
          variant="contained"
          tabindex="-1"
        >
          {`Obter (${FECHAR_VENDA_ACTION})`}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default DialogoSomaPesosCerto;
