import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Typography } from '@material-ui/core';

const DevolucaoGeradoraField = (props) => {
  const record = useRecordContext(props);
  const numero = record
    ? record.record
      ? record.record.devolucaoVenda
        ? record.record.devolucaoVenda.id
        : '-'
      : '-'
    : '-';
  return (
    <Typography
      variant="subtitle1"
      style={{
        fontWeight: 'bold',
      }}
    >
      {numero}
    </Typography>
  );
};

export default DevolucaoGeradoraField;
