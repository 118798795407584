import React from 'react';
import { Filter as FilterAdmin, TextInput, BooleanInput } from 'react-admin';

import { PrecoInput } from '../../Components/InputsReactAdmin';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <TextInput label="Descrição" source="nome" />
    <PrecoInput source="valor" label="Valor" fixedDecimalScale={false} />
    <BooleanInput label="Conferido" source="conferido_maquineta" />
  </FilterAdmin>
);

export default Filter;
