import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import * as MuiIcons from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import MaterialIcon, { colorPalette } from 'material-icons-react';

const useStyles = makeStyles((theme) => ({
  transacao: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    // color: '#0039cb',
    // backgroundColor: '#0039cb',
  },
}));

const nameIconTest = 'Search';

const StatusField = (props) => {
  const classes = useStyles();
  const { source } = props;
  const record = useRecordContext(props);
  const value = `${record ? record.record[source] : ''}`;
  const color = record ? record.record.color : 'black';
  /*  return <Icon style={{ color: '#737477' }}>{value}</Icon>; */
  return (
    <Avatar
      className={classes.transacao}
      style={{ backgroundColor: 'rgb(218, 218, 218)' }}
    >
      <MaterialIcon icon={value} size={20} color="#000000" />
    </Avatar>
  );
};



export default StatusField;
