import React from 'react';
import {
  Filter as FilterAdmin,
  TextInput,
  SelectInput,
  BooleanInput,
} from 'react-admin';

import choices from './choices';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <TextInput label="Descrição" source="nome" />
    <BooleanInput label="Ativos" source="ativo" alwaysOn />
    <SelectInput
      source="tipo"
      choices={choices}
      label="Gerenciamento"
      allowEmpty={false}
    />
  </FilterAdmin>
);

export default Filter;
