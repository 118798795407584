import React from 'react';
import { Filter as FilterAdmin, SelectInput, BooleanInput } from 'react-admin';

import {
  IntegerInput,
  PeriodoInput,
  PrecoInput,
} from '../../Components/InputsReactAdmin';
import {
  LOG_TASK_CALCULA_RELATORIO_CLIENTE,
  LOG_TASK_CALCULA_RELATORIO_PRODUTO,
  LOG_TASK_RELATORIO_ANUAL,
  LOG_TASK_RELATORIO_MENSAL,
} from '../../utils';

const choices = [
  { id: LOG_TASK_CALCULA_RELATORIO_CLIENTE, name: 'Relátorio Clientes' },
  { id: LOG_TASK_CALCULA_RELATORIO_PRODUTO, name: 'Relatório Produtos' },
  { id: LOG_TASK_RELATORIO_ANUAL, name: 'Relatório Anual' },
  { id: LOG_TASK_RELATORIO_MENSAL, name: 'Relatório Mensal' },
];

const Filter = (props) => (
  <FilterAdmin {...props}>
    <PeriodoInput
      source="periodo"
      label="Período"
      defaultValue={{
        start: new Date(),
        end: new Date(),
      }}
    />
    <BooleanInput label="Status" source="sucesso" />
    <SelectInput
      label="Relatório"
      source="task"
      choices={choices}
      allowEmpty={false}
    />
  </FilterAdmin>
);

export default Filter;
