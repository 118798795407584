import React, { useState, useEffect } from 'react';

import moment from 'moment';

const Temporizador = (props) => {
  const [hora, setHora] = useState(moment().format('HH:mm:ss'));

  useEffect(() => {
    const timer = setTimeout(() => {
      setHora(moment().format('HH:mm:ss'));
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <span style={{ fontWeight: 'bold' }}>Hora: </span>
      <span>{hora}</span>
    </div>
  );
};

export default Temporizador;
