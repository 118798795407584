import React from 'react';
import {
  TextInput,
  Filter as FilterAdmin,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

import { STATUS_ATIVO } from '../../utils/constants';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <TextInput label="Descrição" source="nome" />
    <TextInput label="Código de barras" source="codigo" />
    <ReferenceInput
      fullWidth
      label="Categoria"
      source="categoria_id"
      reference="categorias"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      perPage={30}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput>
    <ReferenceInput
      fullWidth
      label="Local"
      source="local_id"
      reference="locais_setores"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      filter={{ status: STATUS_ATIVO }}
      perPage={30}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput>
    <ReferenceInput
      fullWidth
      label="Fornecedor"
      source="fornecedor_id"
      reference="fornecedores"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      perPage={30}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput>
  </FilterAdmin>
);

export default Filter;
