import React, { useRef } from 'react';
import {
  DateField,
  ListBase,
  ListToolbar,
  Pagination,
  Datagrid,
  Title,
} from 'react-admin';

import { Divider, Card, makeStyles } from '@material-ui/core';

import { stylesMalote } from '../../utils';
import ValorTransferenciaField from '../Transacoes/Fields/ValorTransferenciaField';
import Actions from './ActionsList';
import CodigoField from './Fields/CodigoField';
import ResponsavelField from './Fields/ResponsavelField';
import Filter from './Filter';

const useStylesDatagrid = makeStyles((theme) => stylesMalote);

const PostPanel = (props) => (
  <div>{`Descrição: ${props.record.descricao}`}</div>
);

const MyList = ({ ...props }) => {
  const classes = useStylesDatagrid();

  return (
    <>
      <Title title="Sinistros" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Divider />
        <div>
          <Datagrid {...props}>
            <CodigoField
              source="codigo"
              label="Código"
              headerClassName={classes.dataHeader}
              cellClassName={classes.dataCell}
              sortable={false}
            />
            <DateField
              source="data_pagamento"
              label="Horário"
              headerClassName={classes.dataHeader}
              cellClassName={classes.dataCell}
              options={{ hour: 'numeric', minute: 'numeric' }}
              sortable={false}
            />
            <ResponsavelField
              label="Responsável"
              source="responsavel"
              sortable={false}
              headerClassName={classes.dataHeader}
              cellClassName={classes.categoriaCell}
            />
            <ValorTransferenciaField
              source="valor"
              textAlign="right"
              label="Valor"
              headerClassName={classes.dataHeader}
              cellClassName={classes.valorCell}
              sortable={false}
            />
          </Datagrid>
        </div>
        <TransacoesPagination />
      </Card>
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      expand={<PostPanel />}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Sinistros"
    />
  </>
);

export default List2;
