import React, { useRef, useState } from 'react';

import { Box } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import { DialogoConfirmacao, SelectInput } from '../../../..';
import AppBar from './AppBar';

const FECHAR_COMPRA_ACTION = 'F8';
const CANCELAR_COMPRA_ACTION = 'F4';

const TIPO_COMPRA_MANUAL = 'tipo_compra_manual';
const TIPO_COMPRA_IMPORTACAO_XML = 'tipo_compra_importacao_xml';

const optionsTipoCompra = [
  {
    label: 'Manual',
    value: TIPO_COMPRA_MANUAL,
  },
  {
    label: 'Importação XML',
    value: TIPO_COMPRA_IMPORTACAO_XML,
  },
];

function TipoCompra({
  cancelarCompra,
  irParaTelaFrentedeCaixa,
  irParaTelaCarregamentoXml,
}) {
  const refDialogoConfirmacao = useRef(null);
  const [tipoCompraValue, setTipoCompraValue] = useState(TIPO_COMPRA_MANUAL);

  function handleActions(action) {
    switch (action) {
      case FECHAR_COMPRA_ACTION: {
        if (tipoCompraValue === TIPO_COMPRA_MANUAL) {
          irParaTelaFrentedeCaixa();
        } else {
          irParaTelaCarregamentoXml();
        }
        break;
      }
      case CANCELAR_COMPRA_ACTION:
        if (refDialogoConfirmacao.current) {
          refDialogoConfirmacao.current.handleOpen(
            'Cancelamento da compra',
            'Tem certeza que deseja cancelar essa compra!',
            CANCELAR_COMPRA_ACTION,
          );
        }
        break;
      default:
        break;
    }
  }

  function handleConfirma(codigo) {
    switch (codigo) {
      case CANCELAR_COMPRA_ACTION:
        cancelarCompra();
        break;
      default:
        break;
    }
  }

  return (
    <>
      <AppBar
        disabledBack={false}
        disabledForward={false}
        onClick={handleActions}
        FECHAR_COMPRA_ACTION={FECHAR_COMPRA_ACTION}
        CANCELAR_COMPRA_ACTION={CANCELAR_COMPRA_ACTION}
      />
      <Box display="flex" justifyContent="space-between">
        <FormControl
          variant="outlined"
          style={{
            marginTop: '16px',
            backgroundColor: 'white',
          }}
          fullWidth
        >
          <InputLabel shrink id="status-do-ponto" />
          <SelectInput
            labelId="status-do-ponto"
            name="status-do-ponto"
            handleEnter={() => {}}
            label=""
            handleKey={() => {}}
            value={tipoCompraValue}
            onChange={(value) => setTipoCompraValue(value.target.value)}
            options={optionsTipoCompra}
          />
        </FormControl>
      </Box>
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={handleConfirma}
      />
    </>
  );
}

export default TipoCompra;
