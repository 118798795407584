import React, {
  useRef,
  cloneElement,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  TextField,
  EditButton,
  DateField,
  NumberField,
  ReferenceField,
  SelectField,
  List,
  Datagrid,
  DatagridBody,
  ListBase,
  ListToolbar,
  BulkActionsToolbar,
  Pagination,
  useListContext,
  useRefresh,
  Title,
  ListContextProvider,
} from 'react-admin';

import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Button,
  Box,
  makeStyles,
  Divider,
  Tabs,
  Tab,
} from '@material-ui/core';
import Avatar2 from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { ArrowDownward } from '@material-ui/icons';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import {
  DialogoCategoriaTransacao,
  DialogoConfirmacao,
} from '../../Components';
// import Datagrid from '../../Components/Datagrid';
import DialogoDeleteResource from '../../Components/DialogoDeleteResource';
import ListTemplate from '../../Components/List';
import { api } from '../../services';
import { formatMoeda } from '../../utils';
import { STATUS_ATIVO, STATUS_INATIVO } from '../../utils/constants';
import Actions from './ActionsList';
import DialogoDeletarCategoriaTransacao from './DialogoDeletar';
import ActionsField from './Fields/ActionsField';
import ColorField from './Fields/ColorField';
import DescricaoField from './Fields/DescricaoField';
import IconField from './Fields/IconField';
import StatusField from './Fields/StatusField';
import ValorField from './Fields/ValorField';
import Filter from './Filter';

const useStylesDatagrid = makeStyles((theme) => ({
  statusHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  statusCell: {
    // marginLeft: '30px',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    // paddingLeft: '30px',
  },
  statusContent: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 5,
    marginLeft: '30px',
    maxWidth: '100px',
  },
  dataHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoCell: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaCell: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
}));

const tabs = [
  { id: 'ativos', name: 'Ativos' },
  { id: 'inativos', name: 'Inativos' },
];

const DataGridLocal = ({
  handleEdit = () => {},
  handleDelete = () => {},
  handleDescription = () => {},
  ...props
}) => {
  const classes = useStylesDatagrid();
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  return (
    <>
      <Datagrid
        {...props}
        bulkActionButtons={false}
        // classes={{ headerCell: classes.row }}
      >
        <DescricaoField
          source="nome"
          label="Nome"
          headerClassName={classes.dataHeader}
          cellClassName={classes.dataCell}
        />
        <IconField
          source="icon"
          label="Icone"
          headerClassName={classes.dataHeader}
          cellClassName={classes.dataCell}
          sortable={false}
        />
        <ColorField
          source="color"
          label="Cor"
          headerClassName={classes.dataHeader}
          cellClassName={classes.dataCell}
          sortable={false}
        />

        <StatusField
          source="contabiliza_no_lucro"
          label="Lucro"
          sortable={false}
          headerClassName={classes.statusHeader}
          cellClassName={classes.statusCell}
          // className={classes.statusContent}
        />
        <ValorField
          source="totalTransacoesMesPago"
          label="Transações Pagas"
          headerClassName={classes.dataHeader}
          cellClassName={classes.dataCell}
          sortable={false}
        />
        <ValorField
          source="totalTransacoesMesNaoPago"
          label="Transações Não Pagas"
          headerClassName={classes.dataHeader}
          cellClassName={classes.dataCell}
          sortable={false}
        />
        <ValorField
          source="totalTransacoesMes"
          label="Transações Total"
          headerClassName={classes.dataHeader}
          cellClassName={classes.dataCell}
          sortable={false}
        />
        <ActionsField
          source="valor"
          textAlign="right"
          label="Ações"
          sortable={false}
          headerClassName={classes.dataHeader}
          cellClassName={classes.dataCell}
          tipo={filterValues.tipo}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          handleDescription={handleDescription}
        />
      </Datagrid>
    </>
  );
};

const MyList = ({ ...props }) => {
  const refresh = useRefresh();
  const refDialogo = useRef(null);
  const refDialogoDeletar = useRef(null);
  const classes = useStylesDatagrid();
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const refDialogoConfirmacao = useRef(null);
  const [ativos, setAtivos] = useState([]);
  const [inativos, setInativos] = useState([]);

  const create = (tipo) => {
    if (refDialogo.current) {
      refDialogo.current.handleCreate(tipo);
    }
  };

  const edit = ({ id }) => {
    if (refDialogo.current) {
      refDialogo.current.handleEdit(id);
    }
  };

  // const deletar = (data) => {
  //   if (refDialogoDeletar.current) {
  //     refDialogoDeletar.current.handleOpen(data.record.id);
  //   }
  // };

  function handleAtivar({ id }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Reativação de Categoria de transação',
        'Deseja realmente reativar este categoria de transação?',
        { id, codigo: 1 },
      );
    }
  }

  function handleDesativar({ id }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Desativação de Categoria de transação',
        'Deseja realmente desativar este categoria de transação?',
        { id, codigo: 2 },
      );
    }
  }

  function handleDeletar({ id }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Exclusão de Categoria de transação',
        'Deseja deletar permanentemente este categoria de transação?',
        { id, codigo: 3 },
      );
    }
  }

  async function ativar(id) {
    await api.put(`/categorias_transacao/status/ativar/${id}`);
    handleClose();
  }

  async function desativar(id) {
    await api.put(`/categorias_transacao/status/inativar/${id}`);
    handleClose();
  }

  async function deletar(id) {
    await api.put(`/categorias_transacao/status/deletar/${id}`);
    handleClose();
  }

  async function handleConfirma({ id, codigo }) {
    switch (codigo) {
      case 1:
        ativar(id);
        break;
      case 2:
        desativar(id);
        break;
      case 3:
        deletar(id);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (ids && ids !== filterValues.status) {
      switch (filterValues.status) {
        case STATUS_ATIVO:
          setAtivos(ids);
          break;
        case STATUS_INATIVO:
          setInativos(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.status]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters(
          {
            ...filterValues,
            status: value === 'ativos' ? STATUS_ATIVO : STATUS_INATIVO,
          },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  const handleClose = () => {
    refresh();
  };

  return (
    <>
      <Title title="Categorias Transações" />
      <ListToolbar
        filters={<Filter />}
        actions={<Actions handleCriar={create} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px', marginTop: '30px' }} {...props}>
        <Divider />
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.status === STATUS_ATIVO ? tabs[0].id : tabs[1].id}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.status === STATUS_ATIVO && (
            <ListContextProvider value={{ ...listContext, ids: ativos }}>
              <Datagrid
                {...props}
                bulkActionButtons={false}
                // classes={{ headerCell: classes.row }}
              >
                <DescricaoField
                  source="nome"
                  label="Nome"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                />
                <IconField
                  source="icon"
                  label="Icone"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <ColorField
                  source="color"
                  label="Cor"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />

                <StatusField
                  source="contabiliza_no_lucro"
                  label="Lucro"
                  sortable={false}
                  headerClassName={classes.statusHeader}
                  cellClassName={classes.statusCell}
                  // className={classes.statusContent}
                />
                <ValorField
                  source="totalTransacoesMesPago"
                  label="Transações Pagas"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <ValorField
                  source="totalTransacoesMesNaoPago"
                  label="Transações Não Pagas"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <ValorField
                  source="totalTransacoesMes"
                  label="Transações Total"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <ActionsField
                  source="valor"
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  tipo={filterValues.tipo}
                  handleEdit={edit}
                  handleAtivar={handleAtivar}
                  handleDesativar={handleDesativar}
                  handleDeletar={handleDeletar}
                  handleDescription={() => {}}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.status === STATUS_INATIVO && (
            <ListContextProvider value={{ ...listContext, ids: inativos }}>
              <Datagrid
                {...props}
                bulkActionButtons={false}
                // classes={{ headerCell: classes.row }}
              >
                <DescricaoField
                  source="nome"
                  label="Nome"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                />
                <IconField
                  source="icon"
                  label="Icone"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <ColorField
                  source="color"
                  label="Cor"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />

                <StatusField
                  source="contabiliza_no_lucro"
                  label="Lucro"
                  sortable={false}
                  headerClassName={classes.statusHeader}
                  cellClassName={classes.statusCell}
                  // className={classes.statusContent}
                />
                <ValorField
                  source="totalTransacoesMesPago"
                  label="Transações Pagas"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <ValorField
                  source="totalTransacoesMesNaoPago"
                  label="Transações Não Pagas"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <ValorField
                  source="totalTransacoesMes"
                  label="Transações Total"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <ActionsField
                  source="valor"
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  tipo={filterValues.tipo}
                  handleEdit={edit}
                  handleAtivar={handleAtivar}
                  handleDesativar={handleDesativar}
                  handleDeletar={handleDeletar}
                  handleDescription={() => {}}
                />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
        <TransacoesPagination />
      </Card>
      <DialogoCategoriaTransacao ref={refDialogo} handleClose={handleClose} />
      <DialogoDeletarCategoriaTransacao
        ref={refDialogoDeletar}
        handleClose={handleClose}
      />
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={handleConfirma}
      />
    </>
  );
};

const ContainerMyList = ({ ...props }) => (
  <ListBase {...props}>
    <MyList />
  </ListBase>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      title=""
      /* isDelete
      disabledDelete={(record) =>
        record ? !!record.parcela_compra_id || !!record.parcela_venda_id : true
      } */
      filterDefaultValues={{
        tipo: 'Despesa',
        periodo: { ano: new Date().getFullYear(), mes: new Date().getMonth() },
        status: STATUS_ATIVO,
      }}
      component="div"
      bulkActionButtons={false}
      sort={{ field: 'nome', order: 'DESC' }}
      pagination={<TransacoesPagination />}
      perPage={200}
      /* filterDefaultValues={{ tipo: 'Despesa' }} */
    />
  </>
);

export default List2;
