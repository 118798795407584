import React, { forwardRef } from 'react';

import { DialogoProduto2 as DialogoProduto } from '../../Components';

const Container = forwardRef(({ handleClose }, ref) => (
  <>
    <DialogoProduto ref={ref} handleClose={handleClose} />
  </>
));

export default Container;
