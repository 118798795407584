import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

const useStyles = makeStyles((theme) => ({
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
    cursor: 'pointer',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
    cursor: 'pointer',
  },
}));

const StatusField = ({ handleEfetivar, ...props }) => {
  const classes = useStyles();
  const record = useRecordContext(props);

  const status = record ? record.record.status : false;

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      {status ? (
        <Avatar className={classes.confirmado}>
          <CheckIcon style={{ fontSize: 15 }} />
        </Avatar>
      ) : (
        <Avatar className={classes.pendente}>
          <PriorityHighIcon style={{ fontSize: 15 }} />
        </Avatar>
      )}
    </div>
  );
};

export default StatusField;
