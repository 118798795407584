import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import { STATUS_LOJA_ABERTA, STATUS_LOJA_FECHADA } from '../../utils';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '400px',
  },
}));

function getStatusLoja(value) {
  switch (value) {
    case STATUS_LOJA_ABERTA:
      return 'Aberta';
    case STATUS_LOJA_FECHADA:
      return 'Fechada';
    default:
      return '-';
  }
}

const DescricaoField = (props) => {
  const classes = useStyles();

  const record = useRecordContext(props);
  const value = record ? getStatusLoja(record.record.status_loja) : '-';

  return (
    <Tooltip title={value} placement="bottom" aria-label="add2">
      <Typography className={classes.text}>{value}</Typography>
    </Tooltip>
  );
};

export default DescricaoField;
