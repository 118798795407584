import React, { useRef } from 'react';
import {
  Datagrid,
  Pagination,
  ListBase,
  ListToolbar,
  useRefresh,
  useListContext,
  List as ListAdmin,
} from 'react-admin';

import {
  Card,
  Typography,
  Box,
  makeStyles,
  useMediaQuery,
  IconButton,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import moment from 'moment';

import { getUserId } from '../../services/alth';
import { SECONDARY_COLOR } from '../../utils';
import ActionsList from './ActionsList';
import DialogoEdit from './DialogoEdit';
import ActionsField from './Fields/ActionsField';
import Freezer from './Fields/Freezer';
import Horario from './Fields/Horario';
import StatusField from './Fields/StatusField';
import Temperatura from './Fields/Temperatura';

const useStylesDatagrid = makeStyles((theme) => ({
  header: {
    backgroundColor: '#f8f8f8',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
  },
  cell: {
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
    maxWidth: '670px',
  },
  primaryText: {
    fontWeight: 'bold',
    marginBottom: '10px',
  },
}));

function formatTemperatura(valor) {
  return valor !== null && valor !== undefined
    ? `${valor.toLocaleString('de-DE', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      })} °C`
    : '';
}

const CategoryLista = ({
  handleEdit = () => {},
  handleDelete = () => {},
  handleChangeStatus = () => {},
  ...props
}) => {
  const classes = useStyles(props);
  const { data, ids, filterValues } = useListContext();
  return ids ? (
    <List className={classes.root}>
      {ids.map((id) => {
        const dadosItem = data[id];
        const { turno } = filterValues;
        const statusTurno =
          turno === 'manha' ? dadosItem.status_manha : dadosItem.status_tarde;
        return (
          <>
            <ListItem alignItems="flex-start">
              <ListItemIcon>
                {statusTurno ? (
                  <CheckIcon
                    style={{
                      color: 'white',
                      backgroundColor: '#4caf50',
                      borderRadius: '50%',
                    }}
                  />
                ) : (
                  <PriorityHighIcon
                    style={{
                      color: 'white',
                      backgroundColor: '#f44336',
                      borderRadius: '50%',
                    }}
                  />
                )}
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.primaryText }}
                primary={dadosItem.freezer.nome}
                secondary={
                  <>
                    <div>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                        style={{ fontWeight: 'bold', color: SECONDARY_COLOR }}
                      >
                        {`Temperatura: `}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {turno === 'manha'
                          ? formatTemperatura(dadosItem.temperatura_manha)
                          : formatTemperatura(dadosItem.temperatura_tarde)}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                        style={{ fontWeight: 'bold', color: SECONDARY_COLOR }}
                      >
                        {`Horário: `}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {`${moment(
                          turno === 'manha'
                            ? dadosItem.horario_manha
                            : dadosItem.horario_tarde,
                        ).format('HH:mm')}`}
                      </Typography>
                    </div>
                  </>
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    handleEdit({ record: dadosItem });
                  }}
                  style={{ color: '#00897B' }}
                >
                  <EditIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="inset" component="li" />
          </>
        );
      })}
    </List>
  ) : null;
};

const DataGridLocal = ({
  handleEdit = () => {},
  handleDelete = () => {},
  handleChangeStatus = () => {},
  ...props
}) => {
  const classes = useStylesDatagrid();
  const { filterValues } = useListContext();
  return (
    <>
      <Datagrid {...props} bulkActionButtons={false}>
        {filterValues.turno === 'manha' ? (
          <StatusField
            source="status_manha"
            label="Status"
            headerClassName={classes.header}
            cellClassName={classes.cell}
            sortable={false}
          />
        ) : (
          <StatusField
            source="status_tarde"
            label="Status"
            headerClassName={classes.header}
            cellClassName={classes.cell}
            sortable={false}
          />
        )}
        <Freezer
          source="nome"
          label="Freezer"
          sortable={false}
          headerClassName={classes.header}
          cellClassName={classes.cell}
          //sortable={false}
        />
        {filterValues.turno === 'manha' ? (
          <Temperatura
            source="temperatura_manha"
            label="Temperatura Manhã"
            headerClassName={classes.header}
            cellClassName={classes.cell}
          />
        ) : (
          <Temperatura
            source="temperatura_tarde"
            label="Temperatura Tarde"
            headerClassName={classes.header}
            cellClassName={classes.cell}
          />
        )}
        {filterValues.turno === 'manha' ? (
          <Horario
            source="horario_manha"
            label="Horário"
            headerClassName={classes.header}
            cellClassName={classes.cell}
          />
        ) : (
          <Horario
            source="horario_tarde"
            label="Horário"
            headerClassName={classes.header}
            cellClassName={classes.cell}
          />
        )}
        <ActionsField
          source="lalala"
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleChangeStatus={handleChangeStatus}
          textAlign="right"
          label="Ações"
          //source="status"
          sortable={false}
          headerClassName={classes.header}
          cellClassName={classes.cell}
          filtroStatus={filterValues.status}
        />
      </Datagrid>
    </>
  );
};

const MyList = ({ handleOpenFiltros, ...props }) => {
  const refresh = useRefresh();
  const refDialogoEdit = useRef(null);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { filterValues } = useListContext();

  const edit = (data) => {
    if (refDialogoEdit.current) {
      if (filterValues.turno === 'manha') {
        refDialogoEdit.current.handleEditManha(data.id);
      } else {
        refDialogoEdit.current.handleEditTarde(data.id);
      }
    }
  };

  const handleClose = () => {
    refresh();
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '15px',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          marginBottom: '5px',
        }}
      >
        <Typography style={{ fontWeight: 'bold', color: SECONDARY_COLOR }}>
          Conferências Freezers
        </Typography>
      </div>

      <ListToolbar
        filters={props.filters}
        actions={<ActionsList {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        {isSmall ? (
          <CategoryLista handleEdit={edit} {...props} />
        ) : (
          <DataGridLocal handleEdit={edit} {...props} />
        )}
        <TransacoesPagination />
      </Card>
      <DialogoEdit handleClose={handleClose} ref={refDialogoEdit} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      component="div"
      pagination={<TransacoesPagination />}
      perPage={50}
      filterDefaultValues={{
        turno: 'manha',
      }}
    />
  </>
);

export default List2;
