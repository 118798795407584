import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import Rating from '@material-ui/lab/Rating';
import { useSnackbar } from 'notistack';

import { TextInput, IntegerInput, SearchProduto } from '../../../Components';
import { createAPI, updateAPI, getListAllAPI, api } from '../../../services';
import { STATUS_ATIVO } from '../../../utils/constants';
import TransferList from './TransferList';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const atributos = [
  {
    label: 'IPI',
    value: 'ipi',
  },
  {
    label: 'PIS',
    value: 'pis',
  },
  {
    label: 'COFINS',
    value: 'cofins',
  },
  {
    label: 'ICMS AI',
    value: 'icms_ai',
  },
  {
    label: 'ICMS IT',
    value: 'icms_int',
  },
  {
    label: 'NCM',
    value: 'ncm',
  },
  {
    label: 'DF',
    value: 'df',
  },
  {
    label: 'CST',
    value: 'cst',
  },
  {
    label: 'CFOP',
    value: 'cfop',
  },

  {
    label: 'RBC',
    value: 'rbc',
  },
  {
    label: 'CST PIS ENTRADA',
    value: 'cst_pis_entrada',
  },
  {
    label: 'CST PIS SAÍDA',
    value: 'cst_pis_saida',
  },
  {
    label: 'CST COFINS ENTRADA',
    value: 'cst_cofins_entrada',
  },
  {
    label: 'CST COFINS SAÍDA',
    value: 'cst_cofins_saida',
  },
  {
    label: 'CEST',
    value: 'cest',
  },
];

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [produtoBase, setProdutoBase] = useState(null);
  const [produtos, setProdutos] = useState([]);
  const [searchProdutoBase, setSearchProdutoBase] = useState('');
  const [searchProdutos, setSearchProdutos] = useState('');
  const [left, setLeft] = React.useState(atributos);
  const [right, setRight] = React.useState([]);
  const [checked, setChecked] = React.useState([]);

  const classes = useStyles({ cor: '#009FD4' });

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function handleSalvar() {
    try {
      await api.put('/edicao_produtos_em_massa', {
        produto_base_id: produtoBase.id,
        atributos: right.map((item) => item.value),
        produtos_id: produtos.map((item) => item.id),
      });
      enqueueSnackbar('Edição em massa feita com sucesso!', {
        variant: 'success',
      });
      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  function reiniciar() {
    setSearchProdutoBase('');
    setSearchProdutos('');
    setProdutoBase(null);
    setProdutos([]);
    setLeft(atributos);
    setRight([]);
  }

  useImperativeHandle(ref, () => ({
    handleOpen() {
      reiniciar();
      setOpen(true);
    },
  }));

  async function searchHandleProdutoBase(codigo, id) {
    const produtoData = await getListAllAPI(
      'produtos',
      ['id', 'asc'],
      id
        ? { id }
        : { codigo, is_kit: false, is_caixa: false, status: STATUS_ATIVO },
      ['dadosImposto'],
    );
    if (produtoData.data.length > 0) {
      setProdutoBase(produtoData.data[0]);
    } else {
      setProdutoBase(null);
    }
    setSearchProdutoBase('');
  }

  async function searchHandleProdutos(codigo, id) {
    const produtoData = await getListAllAPI(
      'produtos',
      ['id', 'asc'],
      id
        ? { id }
        : { codigo, is_kit: false, is_caixa: false, status: STATUS_ATIVO },
      [],
    );
    if (produtoData.data.length > 0) {
      const produto = produtoData.data[0];
      if (!produtos.some((item) => item.id === produto.id)) {
        setProdutos([...produtos, produto]);
      }
    }
    setSearchProdutoBase('');
  }

  function removerProduto(id) {
    setProdutos(produtos.filter((item) => item.id !== id));
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        /*  fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted */
        fullScreen
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          Edição de produtos em massa
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          <div
            style={{
              display: 'flex',
            }}
          >
            <div
              style={{
                flex: 1,
                padding: '15px',
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
              }}
            >
              <SearchProduto
                label="Pesquisar produto base"
                value={searchProdutoBase}
                onChange={(e) => setSearchProdutoBase(e)}
                fullwidth
                searchHandle={searchHandleProdutoBase}
                handleKey={() => {}}
              />
              <h2>{`Produto Base: ${produtoBase ? produtoBase.nome : ''}`}</h2>
              <TransferList
                left={left}
                right={right}
                checked={checked}
                setLeft={setLeft}
                setRight={setRight}
                setChecked={setChecked}
              />
            </div>
            <div
              style={{
                flex: 1,
                padding: '15px',
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
              }}
            >
              <SearchProduto
                label="Pesquisar produto"
                value={searchProdutos}
                onChange={(e) => setSearchProdutos(e)}
                fullwidth
                searchHandle={searchHandleProdutos}
                handleKey={() => {}}
              />
              <div
                style={{
                  overflowY: 'auto',
                  height: '60vh',
                }}
              >
                <List>
                  {produtos.map((produto) => {
                    const labelId = `list-produtos-${produto.id}-label`;

                    return (
                      <ListItem
                        key={labelId}
                        role="listitem"
                        button
                        style={{
                          listStyle: 'none',
                        }}
                        // onClick={handleToggle(value)}
                      >
                        {/* <ListItemIcon>
                        <Checkbox
                          checked={checked.indexOf(value) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon> */}
                        <ListItemText
                          id={labelId}
                          primary={`${produto.nome}`}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => removerProduto(produto.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={handleCloseDialog}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
            variant="contained"
            color="primary"
          >
            Fechar
          </Button>
          <Button
            onClick={handleSalvar}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
            disabled={
              !produtoBase || produtos.length === 0 || right.length === 0
            }
            variant="contained"
            color="primary"
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
