import React from 'react';
import { useInput, InputHelperText, FieldTitle } from 'react-admin';
import InputMask from 'react-input-mask';

import TextField from '@material-ui/core/TextField';

import InputTemplate from './Template';

export const CpfInput = (props) => (
  <InputTemplate {...props} formato="###.###.###-##" />
);

export const CepInput = (props) => (
  <InputTemplate {...props} formato="#####-###" />
);

export const NfeInput = (props) => (
  <InputTemplate {...props} formato="###.###.###" />
);

export const ChaveAcessoNfeInput = (props) => (
  <InputTemplate
    {...props}
    formato="#### #### #### #### #### #### #### #### #### #### ####"
  />
);

export const CnpjInput = (props) => (
  <InputTemplate {...props} formato="##.###.###/####-##" />
);

export const CodigoInternoInput = (props) => (
  <InputTemplate {...props} formato="######" />
);

export const IEInput = (props) => (
  <InputTemplate {...props} formato="###.###.###" />
);

export const IntegerInput = (props) => <InputTemplate {...props} />;

export const FloatInput = (props) => (
  <InputTemplate {...props} escala={2} sufixo="" />
);

export const PesoInput = (props) => (
  <InputTemplate {...props} escala={3} sufixo="Kg" />
);

export const AreaInput = (props) => (
  <InputTemplate {...props} escala={2} sufixo="M2" />
);

export const PesoGramasInput = (props) => (
  <InputTemplate {...props} sufixo="g" />
);

export const PorcentagemInput = (props) => (
  <InputTemplate {...props} escala={3} sufixo="%" />
);

export const PrecoInput = (props) => (
  <InputTemplate {...props} escala={2} prefixo="R$: " />
);

export const TelefoneInput = (props) => (
  <InputTemplate {...props} formato="(##) #####-####" />
);

export { default as PeriodoInput } from './PeriodoInput';

export { default as BarcodeInput } from './BarcodeInput';

function formatEntrada(value) {
  let valueNew = '';
  if (value) {
    let j = 0;
    for (let i = 0; i < 5; i += 1) {
      if (i === 2) {
        valueNew += '-';
      } else {
        if (j < value.length) {
          valueNew += value[j];
        } else {
          valueNew += '_';
        }
        j += 1;
      }
    }
  }
}

function formatSaida(value) {
  let valueNew = '';
  if (value) {
    for (let i = 0; i < value.length; i += 1) {
      if (value[i] !== '-' && value[i] !== '_') {
        valueNew += value[i];
      }
    }
  }
}

export const TeclaInput = ({ ...rest }) => {
  const {
    id,
    input: { name, onChange, ...rest2 },
    meta: { touched, error },
    isRequired,
  } = useInput({
    ...rest,
  });
  return (
    <InputMask
      mask="**-**"
      {...rest}
      {...rest2}
      /* onChange={(e) => {
        e.target.value = formatSaida(e.target.value);
      }} */
      onChange={onChange}
      // value={formatEntrada(rest.value)}
    >
      {() => (
        <TextField
          id={id}
          name={name}
          variant="filled"
          error={!!(touched && error)}
          helperText={
            <InputHelperText
              touched={touched}
              error={error}
              helperText={rest.helperText}
            />
          }
          label={
            <FieldTitle
              label={rest.label}
              source={rest.source}
              resource={rest.resource}
              isRequired={isRequired}
            />
          }
          margin="dense"
        />
      )}
    </InputMask>
  );
};
