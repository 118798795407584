import React from 'react';
import {
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
} from 'react-admin';

import { Box } from '@material-ui/core';

import { AreaInput } from '../../Components/InputsReactAdmin';

const Form = (props) => (
  <Box p="1em">
    <Box display="flex">
      <Box flex={2} mr="0.5em">
        <TextInput
          source="nome"
          autoFocus
          label="Descrição"
          fullWidth
          {...props}
        />
      </Box>
      <Box flex={2} mr="0.5em">
        <ReferenceInput
          source="colaborador_id"
          reference="colaboradores"
          label="Responsável"
          fullWidth
        >
          <SelectInput optionText="nome" allowEmpty={false} />
        </ReferenceInput>
      </Box>
      <Box flex={2} mr="0.5em">
        <AreaInput source="area" label="Área" fullWidth {...props} />
      </Box>
    </Box>
  </Box>
);

export default Form;
