import React, { useRef } from 'react';
import {
  ListBase,
  ListToolbar,
  Pagination,
  useRefresh,
  Title,
  Datagrid,
  FunctionField,
  TextField,
  DateField,
  SelectField,
  ReferenceField,
} from 'react-admin';

import { Card, makeStyles } from '@material-ui/core';

import ActionsField from './ActionsField';
import ActionsList from './ActionsList';
import DialogoCreateUpdate from './DialogoCreateUpdate';
import DialogoShow from './DialogoShow';
import Filter from './Filter';

////

const MyList = ({ ...props }) => {
  const refDialogo = useRef(null);
  const refresh = useRefresh();
  const refDialogoShow = useRef(null);

  function handleCreate() {
    if (refDialogo.current) {
      refDialogo.current.handleCreate();
    }
  }

  function handleEdit({ id }) {
    if (refDialogo.current) {
      refDialogo.current.handleEdit(id);
    }
  }

  function handleShow({ id }) {
    if (refDialogoShow.current) {
      refDialogoShow.current.handleOpen(id);
    }
  }

  return (
    <>
      <Title title="Categorias de Produtos" />
      <ListToolbar
        filters={props.filters}
        actions={<ActionsList handleCreate={handleCreate} {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid {...props}>
          <TextField source="nome" label="Descrição" />
          <ReferenceField
            label="Setor"
            source="setor_id"
            reference="setores"
            sortable={false}
          >
            <TextField source="nome" />
          </ReferenceField>
          <ActionsField
            source="lalala"
            textAlign="right"
            label="Ações"
            sortable={false}
            handleEdit={handleEdit}
            handleShow={handleShow}
          />
        </Datagrid>
        <TransacoesPagination />
      </Card>
      <DialogoCreateUpdate
        ref={refDialogo}
        handleClose={() => {
          refresh();
        }}
      />
      <DialogoShow
        ref={refDialogoShow}
        handleClose={() => {
          refresh();
        }}
      />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Categorias de Produtos"
      component="div"
    />
  </>
);

export default List2;
