import React, { useState, useCallback, useEffect } from 'react';
import { useListContext } from 'react-admin';

import { Button, Box, Typography, makeStyles } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import moment from 'moment';

import { DateInputBonito } from '../../../Components';
import useDebounce from '../../../hooks/useDebounce';

const useStyles = makeStyles({
  btn: {
    borderRadius: '50px',
    color: (props) => props.color,
    borderColor: (props) => props.color,
    '&:hover': {
      backgroundColor: (props) => props.color,
      color: 'white',
    },
  },
});

const meses = [
  {
    label: 'Janeiro',
    sigla: 'Jan',
  },
  {
    label: 'Fevereiro',
    sigla: 'Fev',
  },
  {
    label: 'Março',
    sigla: 'Mar',
  },
  {
    label: 'Abril',
    sigla: 'Abr',
  },
  {
    label: 'Maio',
    sigla: 'Mai',
  },
  {
    label: 'Junho',
    sigla: 'Jun',
  },
  {
    label: 'Julho',
    sigla: 'Jul',
  },
  {
    label: 'Agosto',
    sigla: 'Ago',
  },
  {
    label: 'Setembro',
    sigla: 'Set',
  },
  {
    label: 'Outubro',
    sigla: 'Out',
  },
  {
    label: 'Novembro',
    sigla: 'Nov',
  },
  {
    label: 'Dezembro',
    sigla: 'Dez',
  },
];

const DIA_ATUAL = new Date().getDate();
const MES_ATUAL = new Date().getMonth();
const ANO_ATUAL = new Date().getFullYear();

const DateMobillsInput = ({ color = '#59c3fa' }) => {
  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters } = listContext;
  const [data, setData] = useState(buildData(DIA_ATUAL, MES_ATUAL, ANO_ATUAL));

  /*   parseInt(filterValues.periodo.dia, 10),
      parseInt(filterValues.periodo.mes, 10),
      parseInt(filterValues.periodo.ano, 10), */

  const classes = useStyles({ color });
  const [dataDebounce] = useDebounce(data, 250);

  const aplicarFiltros = useCallback(
    (filters) => {
      if (setFilters) {
        setFilters({ ...filterValues, ...filters }, displayedFilters);
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  function handleData({ mes, ano }) {
    aplicarFiltros({ periodo: { dia, mes, ano } });
  }

  function getData(data) {
    return {
      dia: parseInt(data.substring(0, 2), 10),
      mes: parseInt(data.substring(2, 4), 10),
      ano: parseInt(data.substring(4), 10),
    };
  }

  function buildData(dia, mes, ano) {
    return `${dia < 10 ? `0${dia}` : dia}${mes < 10 ? `0${mes}` : mes}${ano}`;
  }

  useEffect(() => {
    handleData(getData(dataDebounce));
  }, [dataDebounce]);

  const { dia, mes, ano } = getData(data);

  const handleBefore = () => {
    const dataBase = new Date();
    dataBase.setDate(dia);
    dataBase.setMonth(mes);
    dataBase.setFullYear(ano);

    const provisorio = moment(dataBase).subtract(1, 'days').format('DDMMYYYY');
    const objProvisorio = getData(provisorio);
    setData(
      buildData(objProvisorio.dia, objProvisorio.mes - 1, objProvisorio.ano),
    );
  };

  const handleNext = () => {
    const dataBase = new Date();
    dataBase.setDate(dia);
    dataBase.setMonth(mes);
    dataBase.setFullYear(ano);

    const provisorio = moment(dataBase).add(1, 'days').format('DDMMYYYY');
    const objProvisorio = getData(provisorio);
    setData(
      buildData(objProvisorio.dia, objProvisorio.mes - 1, objProvisorio.ano),
    );
  };

  function getObjData() {
    const dataBase = new Date();
    dataBase.setDate(dia);
    dataBase.setMonth(mes);
    dataBase.setFullYear(ano);
    return dataBase;
  }

  const dataObj = getObjData();

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <IconButton aria-label="delete" onClick={handleBefore} style={{ color }}>
        <NavigateBeforeIcon />
      </IconButton>
      <Box marginLeft="10px" marginRight="10px">
        <Button
          variant="outlined"
          className={classes.btn}
          style={
            {
              // width: '200px',
            }
          }
          onClick={() => {}}
        >
          {/* <span
            style={{ marginRight: '5px', fontWeight: 'bold' }}
          >{`${dia} de ${meses[mes].sigla} de ${ano}`}</span> */}
          <DateInputBonito
            showTodayButton
            // disableFuture
            todayLabel="Hoje"
            name="data_pagamento"
            handleEnter={() => {}}
            style={{ marginBottom: '0px' }}
            label=""
            handleKey={() => {}}
            value={dataObj}
            onChange={(value) => {
              // setDataPagamento(value);
              const provisorio = moment(value).format('DDMMYYYY');
              const objProvisorio = getData(provisorio);
              setData(
                buildData(
                  objProvisorio.dia,
                  objProvisorio.mes - 1,
                  objProvisorio.ano,
                ),
              );
            }}
            format="dd MMMM yyyy"
            fullWidth
            disabled={false}
            variant="standard"
            inputProps={{
              style: {
                textAlign: 'center',
                color: '#59c3fa',
                fontWeight: 'bold',
                border: 'none',
              },
            }}
          />
        </Button>
      </Box>
      <IconButton aria-label="delete" onClick={handleNext} style={{ color }}>
        <NavigateNextIcon />
      </IconButton>
    </Box>
  );
};

export default DateMobillsInput;
