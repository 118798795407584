import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import { TemperaturaInput } from '../../Components/Inputs';
import AutoCompleteRemoto from '../../Components/Inputs/AutoComplete/AutoCompleteRemoto';
import { createAPI, updateAPI, getListAllAPI, getUserId } from '../../services';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_ID = -1;

const PERIODO_MANHA = 'manha';
const PERIODO_TARDE = 'tarde';

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [freezer, setFrezzer] = useState(null);
  const [temperaturaManha, setTemperaturaManha] = useState(0);
  const [temperaturaTarde, setTemperaturaTarde] = useState(0);
  const [tipoPeriodo, setTipoPeriodo] = useState(PERIODO_MANHA);

  const [id, setId] = useState(DEFAULT_ID);

  const [carregando, setCarregando] = useState(false);

  const classes = useStyles({ cor: '#009FD4' });

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'conferencias_temperatura_freezers',
        ['id', 'asc'],
        { id: [id] },
        ['freezer'],
      );

      if (data.data.length > 0) {
        setId(data.data[0].id);
        setFrezzer(data.data[0].freezer);
        setTemperaturaManha(data.data[0].temperatura_manha);
        setTemperaturaTarde(data.data[0].temperatura_tarde);
        setCarregando(false);
      } else {
        throw 'Esta conf. freezer não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    try {
      if (id <= 0) {
        await createAPI(
          'conferencias_temperatura_freezers',
          tipoPeriodo === PERIODO_MANHA
            ? {
                equipamento_freezer_id: freezer.id,
                temperatura_manha: temperaturaManha,
                temperatura_tarde: temperaturaTarde,
                horario_manha: new Date(),
                horario_tarde: new Date(),
                user_id_1: getUserId(),
                user_id_2: getUserId(),
                status_manha: true,
              }
            : {
                equipamento_freezer_id: freezer.id,
                temperatura_manha: temperaturaManha,
                temperatura_tarde: temperaturaTarde,
                horario_manha: new Date(),
                horario_tarde: new Date(),
                user_id_1: getUserId(),
                user_id_2: getUserId(),
                status_tarde: true,
              },
        );
        setCarregando(false);
        enqueueSnackbar('Confer. Frezzer criada com sucesso!', {
          variant: 'success',
        });
      } else {
        await updateAPI(
          'conferencias_temperatura_freezers',
          id,
          tipoPeriodo === PERIODO_MANHA
            ? {
                equipamento_freezer_id: freezer.id,
                temperatura_manha: temperaturaManha,
                temperatura_tarde: temperaturaTarde,
                horario_manha: new Date(),
                horario_tarde: new Date(),
                user_id_1: getUserId(),
                user_id_2: getUserId(),
                status_manha: true,
              }
            : {
                equipamento_freezer_id: freezer.id,
                temperatura_manha: temperaturaManha,
                temperatura_tarde: temperaturaTarde,
                horario_manha: new Date(),
                horario_tarde: new Date(),
                user_id_1: getUserId(),
                user_id_2: getUserId(),
                status_tarde: true,
              },
        );
        setCarregando(false);
        enqueueSnackbar('Conf. Frezzer realizada com sucesso!', {
          variant: 'success',
        });
      }

      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleEditManha(id) {
      setTipoPeriodo(PERIODO_MANHA);
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
    },
    handleEditTarde(id) {
      setTipoPeriodo(PERIODO_TARDE);
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
    },
  }));

  function validateFreezer() {
    let error = '';
    if (!freezer) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateTemperaturaManha() {
    let error = '';
    if (Number.isNaN(temperaturaManha)) {
      error = 'Este campo é obrigatório!';
    }
    return error;
  }

  function validateTemperaturaTarde() {
    let error = '';
    if (Number.isNaN(temperaturaTarde)) {
      error = 'Este campo é obrigatório!';
    }
    return error;
  }

  function getErros() {
    const errosOld = ['', '', ''];
    errosOld[0] = validateFreezer();
    errosOld[1] =
      tipoPeriodo === PERIODO_MANHA ? validateTemperaturaManha() : '';
    errosOld[2] =
      tipoPeriodo === PERIODO_TARDE ? validateTemperaturaTarde() : '';
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  const marginLine = 2;

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          {id < 0 ? `Nova Conf. Freezer` : `Editar Conf. Freezer`}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <Box
                component="fieldset"
                mb={marginLine}
                borderColor="transparent"
              >
                <AutoCompleteRemoto
                  handleEnter={() => {}}
                  label="Freezer"
                  handleKey={() => {}}
                  value={freezer}
                  onChange={(value) => setFrezzer(value)}
                  error={erros[0] !== ''}
                  helperText={erros[0]}
                  fullWidth
                  toString={false}
                  getOptionSelected={(option, value) =>
                    option.nome === value.nome
                  }
                  getOptionLabel={(option) => `${option.nome}`}
                  nested={[]}
                  filters={{}}
                  resource="equipamentos_freezer"
                  disabled={id > 0}
                />
              </Box>

              {tipoPeriodo === PERIODO_MANHA && (
                <Box
                  component="fieldset"
                  mb={marginLine}
                  borderColor="transparent"
                >
                  <TemperaturaInput
                    name="temperatura-manha"
                    handleEnter={() => {}}
                    label="Temperatura Manhã"
                    handleKey={() => {}}
                    value={
                      Number.isNaN(temperaturaManha)
                        ? ''
                        : String(temperaturaManha)
                    }
                    onChange={(value) =>
                      setTemperaturaManha(parseFloat(value.target.value, 10))
                    }
                    error={erros[1] !== ''}
                    helperText={erros[1]}
                    fullWidth
                  />
                </Box>
              )}

              {tipoPeriodo === PERIODO_TARDE && (
                <Box
                  component="fieldset"
                  mb={marginLine}
                  borderColor="transparent"
                >
                  <TemperaturaInput
                    name="temperatura-tarde"
                    handleEnter={() => {}}
                    label="Temperatura Tarde"
                    handleKey={() => {}}
                    value={
                      Number.isNaN(temperaturaTarde)
                        ? ''
                        : String(temperaturaTarde)
                    }
                    onChange={(value) =>
                      setTemperaturaTarde(parseFloat(value.target.value, 10))
                    }
                    error={erros[2] !== ''}
                    helperText={erros[2]}
                    fullWidth
                  />
                </Box>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Button
              onClick={handleSalvar}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              disabled={erroExistente || carregando}
              variant="contained"
              color="primary"
              style={{ flex: 1 }}
            >
              Salvar
            </Button>
            {carregando && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
