import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';

import {
  POR_PESO_VARIAVEL,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  formatPeso,
} from '../../../utils';

const useStyles = makeStyles((theme) => ({
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
  },
}));

const ValorField = (props) => {
  const record = useRecordContext(props);
  const peso = record ? record.record.peso : 0;
  const showPeso = record
    ? record.record.produto.unidade.tipo === POR_PESO_VARIAVEL ||
      record.record.produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
    : false;
  return (
    <Typography
      variant="subtitle1"
      style={{
        // color: tipo === 'Receita' ? '#4caf67' : '#f44336',
        fontWeight: 'bold',
      }}
    >
      {showPeso ? formatPeso(peso) : '-'}
    </Typography>
  );
};



export default ValorField;
