import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DetailsIcon from '@material-ui/icons/Details';
import PrintIcon from '@material-ui/icons/Print';
import { useSnackbar } from 'notistack';

import { imprimirPacoteMalote } from '../../../services/impressora';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    width: theme.spacing(4),
    height: theme.spacing(4),
    cursor: 'pointer',
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
}));

const ActionsField = ({
  handleShow,
  handleRemoverLixeira = () => {},
  ...rest
}) => {
  const record = useRecordContext(rest);

  return (
    <div
      style={{
        marginRight: '0px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Tooltip title="Detalhes" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="detalhes"
          size="small"
          onClick={() => handleShow(record)}
        >
          <DetailsIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Remover da lixeira" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="detalhes"
          size="small"
          onClick={() => handleRemoverLixeira(record)}
        >
          <ClearAllIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};



export default ActionsField;
