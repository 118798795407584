import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import StarIcon from '@material-ui/icons/Star';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  TextInput,
  SearchProduto,
  PrecoInput,
  DateInput,
} from '../../../Components';
import { createAPI, updateAPI, getListAllAPI, api } from '../../../services';
import {
  STATUS_ATIVO,
  TIPO_FERIADO_DATA_COMEMORATIVA,
  TIPO_FERIADO_FERIADO,
  TURNO_MANHA,
  TURNO_NOITE,
  TURNO_12_HORAS,
  formatDateDDMMYYYY,
  formatCNPJ,
} from '../../../utils';
import DialogoCriarPonto from './DialogoCriarPonto';
import DialogoEditPonto from './DialogoEditPonto';
import DialogoMudarDadosDia from './DialogoMudarDadosDia';
import DialogoPresencasColaboradorPDF from './DialogoPresencasColaboradorPDF';
import DialogoShowPonto from './DialogoShowPonto';
import Filters from './FiltersPresencasColaborador';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}))(Tooltip);

const StyledTableCell = withStyles((theme) => ({
  head: {
    //backgroundColor: 'green',
    color: 'black',
    //padding: '8px',
    //whiteSpace: 'nowrap',
    //writingMode: 'sideways-lr',
    //textOrientation: 'sideways',
    //width: 'min-content',
    width: '1px',
    whiteSpace: 'nowrap',
    //textAlign: 'center',
    //display: 'flex',
    //justifyContent: 'center',
    padding: '1px',
    border: '1px solid black',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    padding: '1px',
    border: '1px solid black',
    textAlign: 'center',
    verticalAlign: 'middle',
    //textAlign: 'center',
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const mesSiglas = {
  0: 'JAN',
  1: 'FEV',
  2: 'MAR',
  3: 'ABR',
  4: 'MAI',
  5: 'JUN',
  6: 'JUL',
  7: 'AGO',
  8: 'SET',
  9: 'OUT',
  10: 'NOV',
  11: 'DEZ',
};

function formataApuracao(mes, ano) {
  if (mes >= 0 && ano >= 0) {
    return `${mesSiglas[mes]}.-${ano - 2000}`;
  }
  return '-';
}

function getDadosHeader(dadosSelecionado) {
  if (dadosSelecionado) {
    let razaoSocial = '-';
    let cnpj = '-';
    let nomeFantasia = '-';
    if (dadosSelecionado.empresa) {
      razaoSocial = dadosSelecionado.empresa.razao_social;
      cnpj = formatCNPJ(dadosSelecionado.empresa.cnpj);
      nomeFantasia = dadosSelecionado.empresa.nome_fantasia;
    }
    return {
      razaoSocial,
      cnpj,
      nomeFantasia,
      nomeColaborador: dadosSelecionado.colaborador,
      dataAdmissao: formatDateDDMMYYYY(dadosSelecionado.dataEntrada),
      cargo: dadosSelecionado.funcao,
      periodoApuracao: formataApuracao(
        dadosSelecionado.mes,
        dadosSelecionado.ano,
      ),
      totalHorasExtras: dadosSelecionado.horasExtrasFinais,
      somaHorasTrabalhadas: dadosSelecionado.totalHorasTrabalhadas,
      horasExtras: dadosSelecionado.totalHorasExtrasSemTolerancia,
      horasExtrasTolerancia: dadosSelecionado.totalHorasExtras,
      domingosTrabalhados: dadosSelecionado.domingosTrabalhado,
      feriadosTrabalhados: dadosSelecionado.feriadosTrabalhados,
      atestados: dadosSelecionado.atestados,
      folgas: dadosSelecionado.folgas,
      folha: dadosSelecionado.folha,
      empresa: dadosSelecionado.empresa,
      grupo: dadosSelecionado.grupo,
      funcao: dadosSelecionado.funcao,
      turno: getTurnoLabel(dadosSelecionado.turno),
    };
  }
  return {
    razaoSocial: '-',
    cnpj: '-',
    nomeFantasia: '-',
    nomeColaborador: '-',
    dataAdmissao: '-',
    cargo: '-',
    periodoApuracao: '-',
    totalHorasExtras: '-',
    somaHorasTrabalhadas: 0,
    horasExtras: 0,
    horasExtrasTolerancia: 0,
    domingosTrabalhados: 0,
    feriadosTrabalhados: 0,
    atestados: 0,
    folgas: 0,
    folha: null,
    empresa: '-',
    grupo: '-',
    funcao: '-',
    turno: '-',
  };
}

function getGradeHorarios(turno, isSS) {
  if (turno === TURNO_MANHA) {
    //manha
    //

    if (isSS) {
      return (
        <Table
          aria-label="customized table"
          style={{
            flex: 3,
          }}
        >
          <TableBody>
            <TableRow>
              <StyledTableCell
                style={{
                  backgroundColor: '#dae1f3',
                }}
              >
                Entrada 1
              </StyledTableCell>
              <StyledTableCell
                style={{
                  backgroundColor: '#dae1f3',
                }}
              >
                Entrada 2
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>07:00:00</StyledTableCell>
              <StyledTableCell>14:00:00</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell
                style={{
                  backgroundColor: '#dae1f3',
                }}
              >
                Saída 1
              </StyledTableCell>
              <StyledTableCell
                style={{
                  backgroundColor: '#dae1f3',
                }}
              >
                Saída 2
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>12:00:00</StyledTableCell>
              <StyledTableCell>16:20:00</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell
                style={{
                  backgroundColor: '#dae1f3',
                }}
              >
                Soma das Horas
              </StyledTableCell>
              <StyledTableCell
                style={{
                  backgroundColor: '#dae1f3',
                }}
              >
                Soma das Horas
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>05:00:00</StyledTableCell>
              <StyledTableCell>02:20:00</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell
                style={{
                  fontWeight: 'bold',
                  backgroundColor: '#dae1f3',
                }}
              >
                Carga Horária
              </StyledTableCell>
              <StyledTableCell
                style={{
                  backgroundColor: '#dae1f3',
                }}
              >
                07:20:00
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell
                style={{
                  fontWeight: 'bold',
                }}
              >
                Almoço
              </StyledTableCell>
              <StyledTableCell>02:00:00</StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    }
    return (
      <Table
        aria-label="customized table"
        style={{
          flex: 3,
        }}
      >
        <TableBody>
          <TableRow>
            <StyledTableCell
              colSpan={2}
              style={{
                fontWeight: 'bold',
              }}
            >
              DIA NORMAL
            </StyledTableCell>
            <StyledTableCell
              colSpan={2}
              style={{
                fontWeight: 'bold',
              }}
            >
              DOMINGO
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Entrada 1
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Entrada 2
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Entrada 1
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Entrada 2
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>07:00:00</StyledTableCell>
            <StyledTableCell>14:00:00</StyledTableCell>
            <StyledTableCell>07:00:00</StyledTableCell>
            <StyledTableCell />
          </TableRow>
          <TableRow>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Saída 1
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Saída 2
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Saída 1
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Saída 2
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>12:00:00</StyledTableCell>
            <StyledTableCell>17:04:00</StyledTableCell>
            <StyledTableCell>13:00:00</StyledTableCell>
            <StyledTableCell />
          </TableRow>
          <TableRow>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Soma das Horas
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Soma das Horas
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Soma das Horas
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Soma das Horas
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>05:00:00</StyledTableCell>
            <StyledTableCell>03:04:00</StyledTableCell>
            <StyledTableCell>06:00:00</StyledTableCell>
            <StyledTableCell>00:00:00</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell
              style={{
                fontWeight: 'bold',
                backgroundColor: '#fce4d7',
              }}
            >
              Carga Horária
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              08:04:00
            </StyledTableCell>
            <StyledTableCell
              style={{
                fontWeight: 'bold',
                backgroundColor: '#fce4d7',
              }}
            >
              Carga Horária
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              06:00:00
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell
              style={{
                fontWeight: 'bold',
              }}
            >
              Almoço
            </StyledTableCell>
            <StyledTableCell>02:00:00</StyledTableCell>
            <StyledTableCell
              style={{
                fontWeight: 'bold',
              }}
            >
              Almoço
            </StyledTableCell>
            <StyledTableCell />
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  if (turno === TURNO_NOITE) {
    //NOITE

    if (isSS) {
      return (
        <Table
          aria-label="customized table"
          style={{
            flex: 3,
          }}
        >
          <TableBody>
            <TableRow>
              <StyledTableCell
                style={{
                  backgroundColor: '#dae1f3',
                }}
              >
                Entrada 1
              </StyledTableCell>
              <StyledTableCell
                style={{
                  backgroundColor: '#dae1f3',
                }}
              >
                Entrada 2
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>12:00:00</StyledTableCell>
              <StyledTableCell>16:20:20</StyledTableCell>
              <StyledTableCell />
            </TableRow>
            <TableRow>
              <StyledTableCell
                style={{
                  backgroundColor: '#dae1f3',
                }}
              >
                Saída 1
              </StyledTableCell>
              <StyledTableCell
                style={{
                  backgroundColor: '#dae1f3',
                }}
              >
                Saída 2
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>14:20:00</StyledTableCell>
              <StyledTableCell>21:20:00</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell
                style={{
                  backgroundColor: '#dae1f3',
                }}
              >
                Soma das Horas
              </StyledTableCell>
              <StyledTableCell
                style={{
                  backgroundColor: '#dae1f3',
                }}
              >
                Soma das Horas
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>02:20:00</StyledTableCell>
              <StyledTableCell>05:00:00</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell
                style={{
                  fontWeight: 'bold',
                  backgroundColor: '#dae1f3',
                }}
              >
                Carga Horária
              </StyledTableCell>
              <StyledTableCell
                style={{
                  backgroundColor: '#dae1f3',
                }}
              >
                07:20:00
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell
                style={{
                  fontWeight: 'bold',
                }}
              >
                Almoço
              </StyledTableCell>
              <StyledTableCell>02:00:00</StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    }
    return (
      <Table
        aria-label="customized table"
        style={{
          flex: 3,
        }}
      >
        <TableBody>
          <TableRow>
            <StyledTableCell
              colSpan={2}
              style={{
                fontWeight: 'bold',
              }}
            >
              DIA NORMAL
            </StyledTableCell>
            <StyledTableCell
              colSpan={2}
              style={{
                fontWeight: 'bold',
              }}
            >
              DOMINGO
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Entrada 1
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Entrada 2
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Entrada 1
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Entrada 2
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>11:46:00</StyledTableCell>
            <StyledTableCell>16:50:00</StyledTableCell>
            <StyledTableCell>12:00:00</StyledTableCell>
            <StyledTableCell />
          </TableRow>
          <TableRow>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Saída 1
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Saída 2
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Saída 1
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Saída 2
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>14:50:00</StyledTableCell>
            <StyledTableCell>21:50:00</StyledTableCell>
            <StyledTableCell>18:00:00</StyledTableCell>
            <StyledTableCell />
          </TableRow>
          <TableRow>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Soma das Horas
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Soma das Horas
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Soma das Horas
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Soma das Horas
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>03:04:00</StyledTableCell>
            <StyledTableCell>05:00:00</StyledTableCell>
            <StyledTableCell>06:00:00</StyledTableCell>
            <StyledTableCell>00:00:00</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell
              style={{
                fontWeight: 'bold',
                backgroundColor: '#fce4d7',
              }}
            >
              Carga Horária
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              08:04:00
            </StyledTableCell>
            <StyledTableCell
              style={{
                fontWeight: 'bold',
                backgroundColor: '#fce4d7',
              }}
            >
              Carga Horária
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              06:00:00
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell
              style={{
                fontWeight: 'bold',
              }}
            >
              Almoço
            </StyledTableCell>
            <StyledTableCell>02:00:00</StyledTableCell>
            <StyledTableCell
              style={{
                fontWeight: 'bold',
              }}
            >
              Almoço
            </StyledTableCell>
            <StyledTableCell />
          </TableRow>
        </TableBody>
      </Table>
    );

    //
  }

  if (turno === TURNO_12_HORAS) {
    return null;
  }

  return null;
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function getTurnoLabel(value) {
  switch (value) {
    case TURNO_MANHA:
      return 'Manhã';
    case TURNO_NOITE:
      return 'Noite';
    case TURNO_12_HORAS:
      return '12 Horas';
    default:
      return '';
  }
}

const DialogoCategoriaTransacao = forwardRef(
  (
    {
      data,
      updatePonto = () => {},
      dados,
      nDias = 0,
      updatePonto2 = () => {},
      handleEfetivarColaborador = () => {},
      folhaGeral,
      statusFolha,
    },
    ref,
  ) => {
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(-1);
    const [value, setValue] = React.useState(0);

    const classes = useStyles({ cor: '#009FD4' });

    const refDialogoEditPonto = useRef(null);
    const refDialogoShowPonto = useRef(null);
    const refDialogoPDF = useRef(null);
    const refDialogoCriarPonto = useRef(null);

    const refDialogoMudarDadosDia = useRef(null);
    const { enqueueSnackbar } = useSnackbar();

    const [filters, setFilters] = useState({});

    const handleCloseDialog = () => {
      setOpen(false);
      setIndex(-1);
    };

    useImperativeHandle(ref, () => ({
      handleOpen(index) {
        setOpen(true);
        setIndex(index);
      },
    }));

    function formataNumero(numero) {
      if (numero < 10) return `0${numero}`;
      return numero;
    }

    function hanldeDetalhesPonto(dados) {
      if (dados.ponto) {
        if (refDialogoShowPonto.current) {
          refDialogoShowPonto.current.handleOpen(dados.ponto.id);
        }
      }
    }

    function handleEditarPonto(dados) {
      if (dados.ponto) {
        if (refDialogoEditPonto.current) {
          refDialogoEditPonto.current.handleOpen(dados.ponto.id);
        }
      }
    }

    function aplicaFiltros(dados, filters) {
      if (dados) {
        let dias = dados.dias;
        if (filters.dia) {
          dias = dias.filter((item) => item.dia === filters.dia);
        }

        if (filters.status === true || filters.status === false) {
          dias = dias.filter((item) =>
            item.ponto ? item.ponto.status === filters.status : false,
          );
        }

        return {
          ...dados,
          dias,
        };
      }
      return null;
    }

    const dadosSelecionado2 =
      index >= 0
        ? dados
          ? dados.length - 1 >= index
            ? dados[index]
            : null
          : null
        : null;

    const dadosSelecionado = aplicaFiltros(dadosSelecionado2, filters);

    const dadosHedader = getDadosHeader(dadosSelecionado);

    const {
      cargo,
      cnpj,
      dataAdmissao,
      nomeColaborador,
      nomeFantasia,
      periodoApuracao,
      razaoSocial,
      totalHorasExtras,
      atestados,
      domingosTrabalhados,
      feriadosTrabalhados,
      folgas,
      horasExtras,
      horasExtrasTolerancia,
      somaHorasTrabalhadas,
      folha,
      empresa,
      funcao,
      grupo,
      turno,
    } = dadosHedader;

    const tabelaHorarios = getGradeHorarios(
      dadosSelecionado ? dadosSelecionado.turno : '',
      dadosSelecionado ? dadosSelecionado.tipoJornada === 'SS' : false,
    );

    function handlePrint() {
      if (refDialogoPDF.current) {
        refDialogoPDF.current.handleOpen();
      }
    }

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const compensacoes = [];

    function handleMudarDados(dia) {
      if (dia.ponto) {
        if (refDialogoMudarDadosDia.current) {
          refDialogoMudarDadosDia.current.handleOpen(dia.ponto.id);
        }
      }
    }

    function handleCriarBatidaPonto(dia) {
      if (dia.ponto) {
        if (refDialogoCriarPonto.current) {
          refDialogoCriarPonto.current.handleOpen(dia.ponto.id);
        }
      }
    }

    async function handleEfetivar() {
      if (dadosSelecionado) {
        const response = await api.post(
          `fechar-folha-pagamentos-colaborador/${dadosSelecionado.colaborador_id}/${data.mes}/${data.ano}`,
        );

        const data2 = response.data;

        if (data2.status) {
          handleEfetivarColaborador(
            dadosSelecionado.colaborador_id,
            data.mes,
            data.ano,
            data2.dados,
          );
          enqueueSnackbar(data2.message, {
            variant: 'success',
          });
        } else {
          /*handleEfetivarColaborador(
            dadosSelecionado.colaborador_id,
            data.mes,
            data.ano,
            data2.dados,
          );*/
          enqueueSnackbar(data2.message, {
            variant: 'error',
          });
        }
      }
    }

    const statusCheck = folha ? folha.status : false;
    const statusCheckGeral = folhaGeral ? folhaGeral.status : false;

    function disabledEdit(dia) {
      if (!dia.ponto) return true;
      if (dia.ponto.is_folga) return true;
      if (dia.ponto.is_faltou) return true;
      if (dia.ponto.is_ferias) return true;
      return false;
    }

    function isDomingoTrabalhado(dia) {
      if (!dia.ponto) return false;
      if (dia.ponto.is_folga) return false;
      if (dia.ponto.is_faltou) return false;
      if (dia.ponto.is_ferias) return false;
      return dia.dia_semana === 0;
    }

    function isFeriadoTrabalhado(dia) {
      if (!dia.ponto) return false;
      if (dia.ponto.is_folga) return false;
      if (dia.ponto.is_faltou) return false;
      if (dia.ponto.is_ferias) return false;
      return dia.tipo === TIPO_FERIADO_FERIADO;
    }

    function isAtestado(dia) {
      if (!dia.ponto) return false;
      if (dia.ponto.is_folga) return false;
      if (dia.ponto.is_ferias) return false;
      if (dia.ponto.is_faltou) {
        if (dia.ponto.is_atestado) {
          return true;
        }
        return false;
      }
      if (dia.ponto.is_atestado) {
        return true;
      }
      return false;
    }

    function isFolga(dia) {
      if (!dia.ponto) return false;
      if (dia.ponto.is_folga) return true;
      return false;
    }

    function getColor(dia) {
      if (!dia.ponto) return 'white';
      if (dia.ponto.is_folga) return 'white';
      if (dia.ponto.is_faltou) return '#d17d7d';
      if (dia.ponto.is_ferias) return '#e3bf5d';
      return '#7dd194';
    }

    function getDescricao(dia) {
      if (dia.compensacao) {
        const label = dia.compensacao.diaFeriado
          ? ` - ${formatDateDDMMYYYY(dia.compensacao.diaFeriado.data)} (${
              dia.compensacao.diaFeriado.nome
            })`
          : '';
        return (
          <Tooltip title={`Compensação do Feriado${label}`} placement="top-end">
            <StarIcon style={{ color: 'green' }} />
          </Tooltip>
        );
      }

      if (dia.tipo === TIPO_FERIADO_FERIADO) {
        return (
          <Tooltip title={dia.nome} placement="top-end">
            <StarIcon style={{ color: 'red' }} />
          </Tooltip>
        );
      }

      if (dia.tipo === TIPO_FERIADO_DATA_COMEMORATIVA) {
        return (
          <Tooltip title={dia.nome} placement="top-end">
            <StarIcon style={{ color: 'orange' }} />
          </Tooltip>
        );
      }

      return null;
    }

    // Legenda: a letra V significa que o colaborador
    //trabalhou no Domingo/Feriado ou que estava de
    //Folga/Atestado naquele dia a depender da coluna.

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          disableBackdropClick
          disableEscapeKeyDown
          fullScreen
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            {`Resumo Horas extras - ${
              dadosSelecionado ? dadosSelecionado.colaborador : ''
            } - ${formataNumero(data.mes + 1)}/${formataNumero(data.ano)}`}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 10px 0px',
              margin: '0',
            }}
          >
            {/*<Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Horas Extras" {...a11yProps(0)} />
              <Tab label="Folgas Extras" {...a11yProps(1)} />
          </Tabs>*/}
            {/*<TabPanel value={value} index={0}>*/}
            {dadosSelecionado ? (
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '40px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      //color: 'red',
                      gap: '10px',
                      marginTop: '10px',
                      flex: 1,
                    }}
                  >
                    {/* <div
                      style={{
                        display: 'flex',
                        gap: '20px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        Loja:
                      </span>
                      <span>{`${empresa}`}</span>
                    </div> */}
                    <div
                      style={{
                        display: 'flex',
                        gap: '20px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        Grupo:
                      </span>
                      <span>{`${grupo}`}</span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '20px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        Função:
                      </span>
                      <span>{`${funcao}`}</span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '20px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        Turno:
                      </span>
                      <span>{`${turno}`}</span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      //color: 'red',
                      gap: '10px',
                      marginTop: '10px',
                      flex: 1,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          //color: '#7dd194',
                          marginRight: '5px',
                        }}
                      >
                        * LINHA VERDE
                      </span>
                      <span>- DIA TRABALHADO</span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          //color: 'white',
                          marginRight: '5px',
                        }}
                      >
                        * LINHA BRANCA
                      </span>
                      <span>- FOLGA</span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          //color: '#d17d7d',
                          marginRight: '5px',
                        }}
                      >
                        * LINHA VERMELHO
                      </span>
                      <span>- FALTA</span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          //color: '#d17d7d',
                          marginRight: '5px',
                        }}
                      >
                        * LINHA AMARELA
                      </span>
                      <span>- FÉRIAS</span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      //color: 'red',
                      gap: '10px',
                      marginTop: '10px',
                      flex: 1,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          //color: '#7dd194',
                          marginRight: '5px',
                        }}
                      >
                        <StarIcon style={{ color: 'green' }} />
                      </span>
                      <span>- COMPENSAÇÃO DE FERIADO</span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          //color: 'white',
                          marginRight: '5px',
                        }}
                      >
                        <StarIcon style={{ color: 'red' }} />
                      </span>
                      <span>- FERIADO</span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          //color: '#d17d7d',
                          marginRight: '5px',
                        }}
                      >
                        <StarIcon style={{ color: 'orange' }} />
                      </span>
                      <span>- DATA COMEMORATIVA</span>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: '10px',
                      flex: 1,
                    }}
                  >
                    Legenda: a letra V significa que o colaborador trabalhou no
                    Domingo/Feriado ou que estava de Folga/Atestado naquele dia
                    a depender da coluna.
                  </div>
                </div>
                <Filters
                  nDias={nDias}
                  handleFilters={(filters) => {
                    setFilters(filters);
                  }}
                  handlePrint={handlePrint}
                  folha={folha}
                  handleEfetivar={handleEfetivar}
                />
                <div
                  style={
                    {
                      //overflowX: 'scroll',
                      //overflowY: 'scroll',
                      //height: '500px',
                    }
                  }
                >
                  <Box p="1em">
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={2}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Razão Social
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={3}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {razaoSocial}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={1}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              CNPJ
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={3}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {cnpj}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={2}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Nome Fantasia
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={2}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {nomeFantasia}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={4}
                            rowSpan={2}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            />
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={2}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Nome Colaborador
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={4}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {nomeColaborador}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={2}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {`Data de Admissão: ${dataAdmissao}`}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={2}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {`Cargo: ${cargo}`}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={2}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Período de Apuração
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={1}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {periodoApuracao}
                            </span>
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={2}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Carga Horária
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={5}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {tabelaHorarios}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={3}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              TOTAL DE HORAS EXTRAS
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={1}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {totalHorasExtras}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={6}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            />
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={2}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Assinatura do Colaborador
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={6}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={1}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {somaHorasTrabalhadas}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={1}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {horasExtras}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={1}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {horasExtrasTolerancia}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={1}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {domingosTrabalhados}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={1}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {feriadosTrabalhados}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={1}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {atestados}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={1}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {folgas}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={1}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                            colSpan={1}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            />
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              STATUS
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              DATA
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <Tooltip title="ENTRADA 1" placement="top-end">
                              <span
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                E1
                              </span>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <Tooltip title="SAÍDA 1" placement="top-end">
                              <span
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                S1
                              </span>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <Tooltip title="ENTRADA 2" placement="top-end">
                              <span
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                E2
                              </span>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <Tooltip title="SAÍDA 2" placement="top-end">
                              <span
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                S2
                              </span>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <Tooltip title="TURNO 1" placement="top-end">
                              <span
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                T1
                              </span>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <Tooltip title="TURNO 2" placement="top-end">
                              <span
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                T2
                              </span>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <Tooltip title="SOMA DAS HORAS" placement="top-end">
                              <span
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                S. H.
                              </span>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <Tooltip title="HORAS EXTRAS" placement="top-end">
                              <span
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                H. E.
                              </span>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <Tooltip
                              title="HORAS EXTRAS COM TOLERÂNCIA"
                              placement="top-end"
                            >
                              <span
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                H. E. T.
                              </span>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <Tooltip
                              title="DOMINGO TRABALHADO"
                              placement="top-end"
                            >
                              <span
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                D. T.
                              </span>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <Tooltip
                              title="FERIADO TRABALHADO"
                              placement="top-end"
                            >
                              <span
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                F. T.
                              </span>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <Tooltip title="ATESTADO" placement="top-end">
                              <span
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                ATESTADO
                              </span>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <Tooltip title="FOLGA" placement="top-end">
                              <span
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                FOLGA
                              </span>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <Tooltip title="TIPO DE ESCALA" placement="top-end">
                              <span
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                T. E.
                              </span>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            className={classes.rowHead}
                            align="right"
                          >
                            Ações
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dadosSelecionado.dias.map((dia) => {
                          const color = getColor(dia);
                          const isCompensacao = dia.compensacao ? true : false;
                          return (
                            <>
                              {dia.ponto ? (
                                <TableRow
                                  style={{
                                    backgroundColor: color,
                                  }}
                                >
                                  <StyledTableCell align="center">
                                    {dia.ponto ? (
                                      dia.ponto.status || isCompensacao ? (
                                        <Avatar className={classes.confirmado}>
                                          <CheckIcon style={{ fontSize: 15 }} />
                                        </Avatar>
                                      ) : (
                                        <Avatar className={classes.pendente}>
                                          <PriorityHighIcon
                                            style={{ fontSize: 15 }}
                                          />
                                        </Avatar>
                                      )
                                    ) : null}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      textAlign: 'start',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    {dia.descricao}
                                    {getDescricao(dia)}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {dia.ponto ? dia.ponto.horarioEntrada1 : ''}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {dia.ponto ? dia.ponto.horarioSaida1 : ''}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {dia.ponto ? dia.ponto.horarioEntrada2 : ''}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {dia.ponto ? dia.ponto.horarioSaida2 : ''}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {dia.ponto
                                      ? dia.ponto.horasTrabalhadasTurno1
                                      : ''}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {dia.ponto
                                      ? dia.ponto.horasTrabalhadasTurno2
                                      : ''}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {dia.ponto
                                      ? dia.ponto.horasTrabalhadasTotais
                                      : ''}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {dia.ponto ? dia.ponto.horasExtras : ''}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {dia.ponto
                                      ? dia.ponto.horasExtrasComTolerancia
                                      : ''}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {isDomingoTrabalhado(dia) ? 'V' : ''}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {isFeriadoTrabalhado(dia) ? 'V' : ''}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {isAtestado(dia) ? 'V' : ''}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {isFolga(dia) ? 'V' : ''}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {dia.ponto
                                      ? dia.ponto.tipoJornada
                                      : dadosSelecionado.tipoJornada}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="right"
                                    style={{
                                      display: 'flex',
                                    }}
                                  >
                                    <Tooltip
                                      title="Criar Batida de Ponto"
                                      placement="bottom"
                                      aria-label="excluir3"
                                    >
                                      <IconButton
                                        aria-label="imprimir-checklist"
                                        size="small"
                                        onClick={() =>
                                          handleCriarBatidaPonto(dia)
                                        }
                                        disabled={
                                          statusCheck ||
                                          statusCheckGeral ||
                                          disabledEdit(dia) ||
                                          isCompensacao
                                        }
                                      >
                                        <AddIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                      title="Editar Dados Dia"
                                      placement="bottom"
                                      aria-label="excluir3"
                                    >
                                      <IconButton
                                        aria-label="imprimir-checklist"
                                        size="small"
                                        onClick={() => handleMudarDados(dia)}
                                        disabled={
                                          statusCheck ||
                                          statusCheckGeral ||
                                          isCompensacao
                                        }
                                      >
                                        <ChangeHistoryIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                      title="Detalhes"
                                      placement="bottom"
                                      aria-label="excluir2"
                                    >
                                      <IconButton
                                        aria-label="imprimir-checklist"
                                        size="small"
                                        onClick={() => hanldeDetalhesPonto(dia)}
                                      >
                                        <VisibilityIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                      title="Editar"
                                      placement="bottom"
                                      aria-label="excluir2"
                                    >
                                      <IconButton
                                        aria-label="imprimir-checklist"
                                        size="small"
                                        onClick={() => handleEditarPonto(dia)}
                                        disabled={
                                          statusCheck ||
                                          statusCheckGeral ||
                                          disabledEdit(dia) ||
                                          isCompensacao
                                        }
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </StyledTableCell>
                                </TableRow>
                              ) : null}
                            </>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Box>
                </div>
              </div>
            ) : (
              <div> Trabalhando nos dados...</div>
            )}
            {/*</TabPanel>*/}
            {/*<TabPanel value={value} index={1}>
              {dadosSelecionado ? (
                <div
                  style={{
                    overflowX: 'scroll',
                    overflowY: 'scroll',
                    height: '500px',
                  }}
                >
                  <Box p="1em">
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Status
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Feriado Referente
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Data da Compensação
                            </span>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Ações
                            </span>
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {compensacoes.map((dia) => (
                          <TableRow>
                            <StyledTableCell align="center">
                              {dia.ponto ? (
                                dia.ponto.status ? (
                                  <Avatar className={classes.confirmado}>
                                    <CheckIcon style={{ fontSize: 15 }} />
                                  </Avatar>
                                ) : (
                                  <Avatar className={classes.pendente}>
                                    <PriorityHighIcon
                                      style={{ fontSize: 15 }}
                                    />
                                  </Avatar>
                                )
                              ) : null}
                            </StyledTableCell>
                            <StyledTableCell
                              align="right"
                              style={{
                                display: 'flex',
                              }}
                            >
                              <Tooltip
                                title="Editar"
                                placement="bottom"
                                aria-label="excluir2"
                              >
                                <IconButton
                                  aria-label="imprimir-checklist"
                                  size="small"
                                  //onClick={() => handleEditarPonto(dia)}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            </StyledTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </div>
              ) : (
                <div> Trabalhando nos dados...</div>
              )}
              </TabPanel>*/}
          </DialogContent>
          <DialogoEditPonto
            ref={refDialogoEditPonto}
            updatePonto={updatePonto}
          />
          <DialogoShowPonto ref={refDialogoShowPonto} />
          <DialogoPresencasColaboradorPDF
            ref={refDialogoPDF}
            dadosSelecionado={dadosSelecionado}
            dadosHeader={dadosHedader}
          />
          <DialogoMudarDadosDia
            ref={refDialogoMudarDadosDia}
            updatePonto={updatePonto2}
          />
          <DialogoCriarPonto ref={refDialogoCriarPonto} />
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
