import React from 'react';
import {
  Filter as FilterAdmin,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
} from 'react-admin';

import { PeriodoInput } from '../../Components/InputsReactAdmin';
import { choicesSituacao } from './choices';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <ReferenceInput
      source="cartao_cerdito_id"
      reference="cartoes_credito"
      label="Cartão"
      allowEmpty={false}
      alwaysOn
    >
      <SelectInput optionText="nome" allowEmpty={false} alwaysOn />
    </ReferenceInput>
  </FilterAdmin>
);

export default Filter;
