import React, { useRef } from 'react';
import {
  ListBase,
  ListToolbar,
  useRefresh,
  Datagrid,
  Title,
} from 'react-admin';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import ActionsField from './ActionsField';
import Actions from './ActionsList';
import DialogoDetalhesItens from './DialogoDetalhesItens';
import DataField from './Fields/DataField';
import PrecoVendaField from './Fields/PrecoVendaField';
import ProdutoField from './Fields/ProdutoField';
import ResponsavelField from './Fields/ResponsavelField';
import TipoHorarioField from './Fields/TipoHorarioField';
import Filter from './Filter';

const useStylesDatagrid = makeStyles((theme) => ({
  dataHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
}));

const PostPanel = (props) => (
  <div>{`Descrição: ${props.record.descricao}`}</div>
);

const DataGridLocal = ({ handleShow = () => {}, ...props }) => {
  const classes = useStylesDatagrid();
  return (
    <>
      <Datagrid {...props} bulkActionButtons={false}>
        <DataField
          source="updated_at"
          label="Data"
          sortable={false}
          headerClassName={classes.dataHeader}
          cellClassName={classes.dataCell}
        />
        <TipoHorarioField
          source="tipo"
          label="Tipo"
          sortable={false}
          headerClassName={classes.dataHeader}
          cellClassName={classes.dataCell}
        />
        <ProdutoField
          source="produto_id"
          label="Produto"
          sortable={false}
          headerClassName={classes.dataHeader}
          cellClassName={classes.dataCell}
        />
        <ResponsavelField
          source="user_id"
          label="Responsável"
          sortable={false}
          headerClassName={classes.dataHeader}
          cellClassName={classes.dataCell}
        />
        <PrecoVendaField
          source="preco_venda"
          label="Preço de Venda"
          sortable={false}
          headerClassName={classes.dataHeader}
          cellClassName={classes.dataCell}
        />
        <ActionsField
          source="lalala"
          handleShow={handleShow}
          textAlign="right"
          label="Ações"
          headerClassName={classes.actionsHeader}
          cellClassName={classes.actionsCell}
          sortable={false}
        />
      </Datagrid>
    </>
  );
};

const MyList = ({ ...props }) => {
  const refDialogoShow = useRef(null);

  const handleShow = (data) => {
    if (refDialogoShow.current) {
      refDialogoShow.current.handleOpen(data);
    }
  };

  return (
    <>
      <Title title="Histórico Precificações" />
      <ListToolbar filters={<Filter />} actions={<Actions />} {...props} />
      <Paper
        elevation={1}
        style={{
          borderRadius: '20px',
          marginTop: '30px',
          display: 'flex',
          padding: '10px',
        }}
      >
        <DataGridLocal handleShow={handleShow} {...props} />
      </Paper>
      <DialogoDetalhesItens ref={refDialogoShow} />
    </>
  );
};

const ContainerMyList = ({ ...props }) => (
  <ListBase {...props}>
    <MyList {...props} />
  </ListBase>
);

const CategoryList = (props) => (
  <>
    <ContainerMyList
      {...props}
      expand={<PostPanel />}
      title="Histórico Precificações"
      component="div"
      bulkActionButtons={false}
      sort={{ field: 'created_at', order: 'desc' }}
      perPage={20}
      pagination={false}
    />
  </>
);

export default CategoryList;
