import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '400px',
  },
}));

const ClienteField = (props) => {
  const classes = useStyles();

  const record = useRecordContext(props);
  const value = record ? record.record.venda.cliente.nome : '';

  return <Typography className={classes.text}>{value}</Typography>;
};

export default ClienteField;
