import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import {
  TextInput,
  SelectInput,
  CpfInput,
  CnpjInput,
  AutoCompleteRemoto,
  TelefoneInput,
  PesoInput,
} from '../../Components';
import {
  formatCPF,
  formatCNPJ,
  PIX_ALEATORIA_OPTION,
  PIX_CNPJ_OPTION,
  PIX_CPF_OPTION,
  PIX_EMAIL_OPTION,
  PIX_TELEFONE_OPTION,
  formatTelefone,
} from '../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));
/*
const GenericoInput = forwardRef(({ formato, ...props }, ref) => (
  <TemplateNumberFormatInput {...props} formato={formato} ref={ref} />
)); */

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_ID = -1;

const DialogoCategoriaTransacao = forwardRef(({ handleSave }, ref) => {
  const [open, setOpen] = useState(false);
  const [peso, setPeso] = useState(0);

  const [id, setId] = useState(DEFAULT_ID);

  const classes = useStyles({ cor: '#009FD4' });

  const handleCloseDialog = () => {
    setOpen(false);
  };

  async function handleSalvar() {
    handleSave(id, peso);
    handleCloseDialog();
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      setId(-1);
      setPeso(0);
      setOpen(true);
    },
    handleEdit(id, peso) {
      setId(id);
      setPeso(peso);
      setOpen(true);
    },
  }));

  function validatePeso() {
    let error = '';
    if (peso <= 0 || Number.isNaN(peso)) {
      error = 'Valor inválido';
    }
    return error;
  }

  function getErros() {
    const errosOld = [''];
    errosOld[0] = validatePeso();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          {id < 0 ? 'Nova Pesagem' : 'Editar Pesagem'}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '10px 36px 20px',
            margin: '0',
          }}
        >
          <div>
            <Box flex={1} mr="0.5em" mt="20px">
              <PesoInput
                handleEnter={() => {}}
                label="Peso"
                handleKey={() => {}}
                value={Number.isNaN(peso) ? '' : String(peso)}
                onChange={(value) => setPeso(parseFloat(value.target.value))}
                error={erros[0] !== ''}
                helperText={erros[0]}
                fullWidth
              />
            </Box>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={handleCloseDialog}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
            variant="contained"
            color="primary"
          >
            Fechar
          </Button>
          <div className={classes.wrapper}>
            <Button
              onClick={handleSalvar}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              disabled={erroExistente}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
