import React, { useEffect } from 'react';
import { FormDataConsumer } from 'react-admin';
import { useForm } from 'react-final-form';

import { Typography, Box } from '@material-ui/core';

import { IntegerInput, PesoInput } from '../../Components/InputsReactAdmin';

const Form = (props) => (
  <Box p="1em">
    <Box display="flex">
      <Box flex={2} mr="1em">
        <Typography variant="h6" gutterBottom>
          Geral
        </Typography>

        <Box display="flex">
          <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => (
              <>
                {formData.showUnidades && (
                  <Box flex={1} ml="0.5em">
                    <IntegerInput
                      source="unidades"
                      label="Unidades"
                      fullWidth
                      {...rest}
                    />
                  </Box>
                )}
              </>
            )}
          </FormDataConsumer>

          <Box flex={1} ml="0.5em">
            <FormDataConsumer>
              {({ formData, dispatch, ...rest }) => (
                <>
                  {formData.showPeso && (
                    <PesoInput
                      source="peso"
                      label="Peso"
                      fullWidth
                      {...rest}
                      disabled={formData.pm}
                    />
                  )}
                </>
              )}
            </FormDataConsumer>
          </Box>

          <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => (
              <>{!formData.showUnidades && <Box flex={1} ml="0.5em" />}</>
            )}
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => {
              const form = useForm();
              useEffect(() => {
                if (formData.pm) {
                  form.change('peso', formData.unidades * formData.pm);
                }
              }, [formData.unidades]);
            }}
          </FormDataConsumer>
        </Box>

        <Box mt="1em" />
      </Box>
    </Box>
  </Box>
);

export default Form;
