import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import { useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import { IntegerInput } from '../../Components/Inputs';
import SearchProduto from '../../Components/Inputs/SearchProduto';
import { getUserId } from '../../services/alth';
import { createAPI, getListAllAPI, updateAPI } from '../../services/api';
import { SECONDARY_COLOR, formatMoeda } from '../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  rootDialogoSmall: {
    borderRadius: '0px',
  },
  btnAtions: {
    borderRadius: '25px',
  },
  btnAtionsSmall: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [carregando, setCarregando] = useState(false);

  const classes = useStyles({ cor: 'black' });
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [search, setSearch] = useState('');
  const [produto, setProduto] = useState(null);
  const refSearch = useRef(null);
  const [copias, setCopias] = useState(0);

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function reiniciar() {
    setCarregando(false);
    setProduto(null);
  }

  async function handleSalvar() {
    setCarregando(true);
    try {
      const conferenciasData = await getListAllAPI(
        'solicitacoes_impressoes_etiqueta_2',
        ['id', 'asc'],
        { produto_id: produto.id, user_id: getUserId() },
        [],
      );
      if (conferenciasData.data.length <= 0) {
        await createAPI('solicitacoes_impressoes_etiqueta_2', {
          produto_id: produto.id,
          copias,
          tipo_impressao: 0,
          horario: new Date(),
          user_id: getUserId(),
          status: false,
        });
      } else {
        await updateAPI(
          'solicitacoes_impressoes_etiqueta_2',
          conferenciasData.data[0].id,
          {
            copias,
            horario: new Date(),
            status: false,
          },
        );
      }
      setCarregando(false);
      enqueueSnackbar('Solicitação de impressão registrada com sucesso!', {
        variant: 'success',
      });
      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      reiniciar();
      setOpen(true);
    },
  }));

  function analisaCodigo(codigo) {
    if (codigo.length === 13 && codigo.charAt(0) === '2') {
      return {
        tipo: 0,
        data: {
          codigo_interno: codigo.substring(1, 7),
          peso:
            parseInt(codigo.substring(7, 9), 10) +
            parseInt(codigo.substring(9, 12), 10) / 1000,
          digitoVerificador: parseInt(codigo.substring(12, 13), 10),
        },
      };
    }
    return {
      tipo: 1,
      data: {},
    };
  }

  async function searchHandle(codigo, id) {
    if (!id) {
      const { tipo, data } = analisaCodigo(codigo);
      if (tipo === 0) {
        const { codigo_interno, peso } = data;
        const produtoData = await getListAllAPI(
          'produtos',
          ['id', 'asc'],
          { codigo_interno, ativo: true, is_kit: false, is_caixa: false },
          ['unidade'],
        );
        if (produtoData.data.length > 0) {
          setProduto(produtoData.data[0]);
        } else {
          setProduto(null);
          if (refSearch.current) refSearch.current.focus();
          enqueueSnackbar(
            `Produto não encotrado com este codigo interno: ${codigo_interno}!`,
            {
              variant: 'error',
            },
          );
        }
      } else {
        const produtoData = await getListAllAPI(
          'produtos',
          ['id', 'asc'],
          { codigo, ativo: true, is_kit: false, is_caixa: false },
          ['unidade'],
        );
        if (produtoData.data.length > 0) {
          setProduto(produtoData.data[0]);
        } else {
          const produtoDataCaixa = await getListAllAPI(
            'produtos/caixa',
            ['id', 'asc'],
            { codigo },
            ['unidade'],
          );
          if (produtoDataCaixa.data.length > 0) {
            setProduto(produtoDataCaixa.data[0]);
          } else {
            setProduto(null);
            if (refSearch.current) refSearch.current.focus();
            enqueueSnackbar(
              `Produto não encotrado com este código: ${codigo}!`,
              {
                variant: 'error',
              },
            );
            if (refSearch.current) refSearch.current.focus();
          }
        }
      }
    } else {
      const produtoData = await getListAllAPI(
        'produtos',
        ['id', 'asc'],
        { id },
        ['unidade'],
      );
      if (produtoData.data.length > 0) {
        setProduto(produtoData.data[0]);
      }
    }

    setSearch('');
  }

  function validateCopias() {
    let error = '';
    if (copias <= 0 || Number.isNaN(copias)) {
      error = 'Deve ter um valor maior que 0';
    }
    return error;
  }

  function getErros() {
    const errosOld = ['', ''];
    errosOld[0] = validateCopias();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  function getDadosProduto() {
    if (produto) {
      return {
        nome: produto.nome,
        precoVenda: produto.preco_venda,
      };
    }
    return {
      nome: '',
      precoVenda: 0,
    };
  }

  const { nome, precoVenda } = getDadosProduto();

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{
          paper: isSmall ? classes.rootDialogoSmall : classes.rootDialogo,
        }}
        className={isSmall ? classes.rootDialogoSmall : classes.rootDialogo}
        fullWidth={!isSmall}
        fullScreen={isSmall}
        maxWidth={isSmall ? '' : 'sm'}
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: SECONDARY_COLOR, color: 'white' }}
        >
          Criar impressão
        </DialogTitle>
        <DialogContent
          style={{
            padding: '20px 15px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <SearchProduto
                label="Pesquisar produto"
                value={search}
                onChange={(e) => setSearch(e)}
                fullwidth
                searchHandle={searchHandle}
                handleKey={(keyCode, keyName) => {}}
              />
              {produto && (
                <div>
                  <div>
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: '5px' }}>Produto:</div>
                      <div>{nome}</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: '5px' }}>Preco venda:</div>
                      <div>{formatMoeda(precoVenda)}</div>
                    </div>
                  </div>
                  <IntegerInput
                    handleEnter={() => {}}
                    label="Cópias"
                    handleKey={() => {}}
                    value={Number.isNaN(copias) ? '' : String(copias)}
                    onChange={(value) =>
                      setCopias(parseInt(value.target.value, 10))
                    }
                    fullWidth
                    error={erros[0] !== ''}
                    helperText={erros[0]}
                  />
                </div>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 15px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className={classes.wrapper}>
            <Button
              onClick={handleSalvar}
              classes={{
                root: classes.btnAtions,
              }}
              style={{ marginLeft: '30px' }}
              disabled={erroExistente}
              variant="contained"
              color="primary"
              fullWidth
            >
              Salvar
            </Button>
            {carregando && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
