import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '400px',
    // color: 'red',
  },
}));

const DiasNegociadosField = (props) => {
  const classes = useStyles();

  const record = useRecordContext(props);

  const value = record
    ? record.record.dias_prazo
      ? record.record.dias_prazo
      : '-'
    : '-';

  return <Typography className={classes.text}>{value}</Typography>;
};

export default DiasNegociadosField;
