const Validation = (values) => {
  const errors = {};
  if (!values.nome) errors.nome = 'Este campo é obrigatório';
  else if (values.nome.length > 80)
    if (values.valor <= 0 || Number.isNaN(values.valor))
      errors.valor = 'Valor inválido';
  if (!values.conta_id) errors.conta_id = 'Este campo é obrigatório';
  if (!values.horario) errors.horario = 'Este campo é obrigatório';
  if (values.fixo) {
    if (values.repeticoes <= 0 || Number.isNaN(values.repeticoes))
      errors.repeticoes = 'Valor inválido';
  }
  return errors;
};

export default Validation;
