import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles, Typography } from '@material-ui/core';

import { formatPeso, disablePeso } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '400px',
  },
}));

function getPeso(data) {
  return disablePeso(data.produto) ? formatPeso(data.peso) : '-';
}

const PesoField = (props) => {
  const classes = useStyles();

  const record = useRecordContext(props);
  const value = record ? getPeso(record.record) : '';

  return <Typography className={classes.text}>{value}</Typography>;
};

export default PesoField;
