import { useEffect, useRef, useState } from 'react';

import { useSnackbar } from 'notistack';

import { getListAllAPI, api } from '../../../services';
import {
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  POR_PESO_VARIAVEL,
  POR_PESO_FIXO,
  getSubTotal,
  STATUS_ATIVO,
  getPrecoUnitarioVerdadeiroCaixa,
  getNivelCaixaCodigo,
  analisaCodigo,
  getUnitarioCaixa,
  getPesoCaixa,
  changeCaixariaLocal,
  getTotal2,
  getUnidadesItemCaixa,
  getUnidadesCaixa,
  ZERO_APROXIMADO,
  TIPO_UNIDADE_OFERTA_KG,
  TIPO_UNIDADE_OFERTA_UN,
  TIPO_UNIDADE_OFERTA_CAIXA,
  TIPO_UNIDADE_OFERTA_CAIXETA,
  TIPO_UNIDADE_OFERTA_CAIXA_MAE,
  TIPO_OFERTA_PRODUTO_LIBERACAO,
} from '../../../utils';

function useItens(itens, updateItem, addItensLiberacao) {
  const { enqueueSnackbar } = useSnackbar();
  const refSidebarInputs = useRef(null);
  const [produto, setProduto] = useState(null);
  const [editPrice, setEditPrice] = useState(false);

  const [search, setSearch] = useState('');
  const [subTotal, setSubTotal] = useState('');
  const refSearch = useRef(null);

  useEffect(() => {
    setSubTotal(getSubTotal(itens));
  }, [itens]);

  function addNewItemInterno(dados, produto) {
    const { unidades, peso, unitario, cgp } = dados;

    const pesoCalculado = getPesoCaixa(produto);

    updateItem({
      produto,
      peso: produto.unidade.tipo === POR_UNIDADE ? 0 : peso,
      total: getTotal2(peso, unidades, unitario, produto),
      unidades: getUnidadesItemCaixa(produto, unidades),
      unitario: getUnitarioCaixa(produto, unitario),
      uidd: `${produto.nome}${itens.length}`,
      idIndicadorChange: -1,
      unidadesCaixa: getUnidadesCaixa(produto),
      unitarioCaixa: unitario,
      pesoCaixa: pesoCalculado,
      unidadesCaixaAparente: produto.nivelCaixa ? unidades : 0,
      nivelCaixaria: produto.nivelCaixa ? produto.nivelCaixa : 0,
      cgp,
      grupo_oferta_preco_produto_id: null,
      tipo_oferta: null,
      is_margem_cartao: true,
    });

    switch (produto.unidade.tipo) {
      case POR_UNIDADE:
        if (produto.unidadesDisponivel < 0) {
          enqueueSnackbar('Estoque insuficiente!', {
            variant: 'error',
          });
        }
        break;
      case POR_PESO_VARIAVEL_SEM_UNIDADE:
        if (produto.pesoDisponivel < 0) {
          enqueueSnackbar('Estoque insuficiente!', {
            variant: 'error',
          });
        }
        break;
      case POR_PESO_VARIAVEL:
        if (produto.pesoDisponivel < 0 || produto.unidadesDisponivel < 0) {
          enqueueSnackbar('Estoque insuficiente!', {
            variant: 'error',
          });
        }
        break;
      case POR_PESO_FIXO:
        if (produto.unidadesDisponivel < 0) {
          enqueueSnackbar('Estoque insuficiente!', {
            variant: 'error',
          });
        }
        break;
      default:
        break;
    }

    setProduto(null);
    setEditPrice(false);
  }

  function searchItemInArray(produto, itens) {
    let unidadesUsadas = 0;
    let pesoUsado = 0;
    for (let i = 0; i < itens.length; i += 1) {
      if (
        produto.id === itens[i].produto.id &&
        itens[i].idIndicadorChange < 0
      ) {
        unidadesUsadas += itens[i].unidades;
        pesoUsado += itens[i].peso;
      }
    }

    if (produto.unidade.tipo === POR_UNIDADE) {
      pesoUsado = 0;
    } else if (produto.unidade.tipo === POR_PESO_FIXO) {
      pesoUsado = unidadesUsadas * produto.peso_medio;
    }

    return {
      unidadesUsadas,
      pesoUsado,
    };
  }

  async function searchHandle(codigo, id) {
    if (!id) {
      const { tipo, data } = analisaCodigo(codigo);
      if (tipo === 0) {
        const { codigo_interno, peso } = data;
        const produtoData = await getListAllAPI(
          'produtos',
          ['id', 'asc'],
          {
            codigo_interno,
            /* ativo: true, */ is_kit: false,
            is_caixa: false,
            status: STATUS_ATIVO,
            ofertasProdutos: true,
          },
          ['unidade'],
        );
        if (produtoData.data.length > 0) {
          /* if (
            produtoData.data[0].precoCompraMedio >= 0
            && produtoData.data[0].precoCompraMedioImpostos >= 0
          ) { */
          if (true) {
            if (
              produtoData.data[0].preco_venda > 0 &&
              produtoData.data[0].preco_revenda > 0
            ) {
              if (
                produtoData.data[0].unidade.tipo ===
                  POR_PESO_VARIAVEL_SEM_UNIDADE ||
                produtoData.data[0].unidade.tipo === POR_PESO_VARIAVEL
              ) {
                const index = searchItemInArray(produtoData.data[0], itens);
                produtoData.data[0].unidadesDisponivel -= index.unidadesUsadas;
                produtoData.data[0].pesoDisponivel -= index.pesoUsado;
                const hideUnidades =
                  produtoData.data[0].unidade.tipo ===
                  POR_PESO_VARIAVEL_SEM_UNIDADE;
                const unidades2 = hideUnidades ? 0 : 1;
                addNewItemInterno(
                  {
                    unidades: unidades2,
                    peso,
                    // unitario: produtoData.data[0].preco_venda,
                    //unitario: getPrecoUnitarioOferta(produtoData.data[0]),
                    unitario: getPrecoUnitarioVerdadeiroCaixa(
                      produtoData.data[0],
                      unidades2,
                      peso,
                    ),
                  },
                  produtoData.data[0],
                );
                setProduto(null);
                if (refSearch.current) refSearch.current.focus();
              } else {
                if (refSearch.current) refSearch.current.focus();
                enqueueSnackbar(
                  'Este produto não usa balança, deve-se especificar apenas unidades, e não o peso!',
                  {
                    variant: 'error',
                  },
                );
              }
            } else {
              if (refSearch.current) refSearch.current.focus();
              enqueueSnackbar(
                'Este produto possui o preço de venda ou revenda inválido!',
                {
                  variant: 'error',
                },
              );
            }
          } else {
            enqueueSnackbar('Este Produto não possui nenhum lote cadastrado!', {
              variant: 'error',
            });
          }
        } else {
          setProduto(null);
          if (refSearch.current) refSearch.current.focus();
          enqueueSnackbar(
            `Produto não encotrado com este codigo interno: ${codigo_interno}!`,
            {
              variant: 'error',
            },
          );
        }
      } else if (tipo === 'cgp') {
        //////////////////////////////////
        const { codigo_interno, peso } = data;
        const cgp = await api.get(
          `/conferencias_grandes_pesagens/${codigo_interno}`,
        );
        if (cgp.data.status === STATUS_ATIVO) {
          const produtoData = await getListAllAPI(
            'produtos',
            ['id', 'asc'],
            {
              id: cgp.data.produto_id,
              ofertasProdutos: true,
            },
            ['unidade'],
          );
          if (produtoData.data.length > 0) {
            /* if (
            produtoData.data[0].precoCompraMedio >= 0
            && produtoData.data[0].precoCompraMedioImpostos >= 0
          ) { */
            if (true) {
              if (
                produtoData.data[0].preco_venda > 0 &&
                produtoData.data[0].preco_revenda > 0
              ) {
                if (
                  produtoData.data[0].unidade.tipo ===
                    POR_PESO_VARIAVEL_SEM_UNIDADE ||
                  produtoData.data[0].unidade.tipo === POR_PESO_VARIAVEL
                ) {
                  const index = searchItemInArray(produtoData.data[0], itens);
                  produtoData.data[0].unidadesDisponivel -=
                    index.unidadesUsadas;
                  produtoData.data[0].pesoDisponivel -= index.pesoUsado;
                  const hideUnidades =
                    produtoData.data[0].unidade.tipo ===
                    POR_PESO_VARIAVEL_SEM_UNIDADE;
                  const unidades2 = hideUnidades ? 0 : 1;
                  const peso2 = cgp.data.peso_liquido_total;
                  addNewItemInterno(
                    {
                      unidades: unidades2,
                      peso: peso2,
                      // unitario: produtoData.data[0].preco_venda,
                      //unitario: getPrecoUnitarioOferta(produtoData.data[0]),
                      unitario: getPrecoUnitarioVerdadeiroCaixa(
                        produtoData.data[0],
                        unidades2,
                        peso2,
                      ),
                      cgp: cgp.data.id,
                    },
                    produtoData.data[0],
                  );
                  setProduto(null);
                  if (refSearch.current) refSearch.current.focus();
                } else {
                  if (refSearch.current) refSearch.current.focus();
                  enqueueSnackbar(
                    'Este produto não usa balança, deve-se especificar apenas unidades, e não o peso!',
                    {
                      variant: 'error',
                    },
                  );
                }
              } else {
                if (refSearch.current) refSearch.current.focus();
                enqueueSnackbar(
                  'Este produto possui o preço de venda ou revenda inválido!',
                  {
                    variant: 'error',
                  },
                );
              }
            } else {
              enqueueSnackbar(
                'Este Produto não possui nenhum lote cadastrado!',
                {
                  variant: 'error',
                },
              );
            }
          } else {
            setProduto(null);
            if (refSearch.current) refSearch.current.focus();
            enqueueSnackbar(
              `Produto não encotrado com este codigo interno: ${codigo_interno}!`,
              {
                variant: 'error',
              },
            );
          }
        } else {
          if (refSearch.current) refSearch.current.focus();
          enqueueSnackbar(
            'Esta Conferência de Grandes Pesagens já foi utilizada!',
            {
              variant: 'error',
            },
          );
        }
        //////////////////////////////////
      } else {
        const produtoData = await getListAllAPI(
          'produtos',
          ['id', 'asc'],
          {
            codigo,
            /* ativo: true, */ is_kit: false,
            is_caixa: false,
            status: STATUS_ATIVO,
            ofertasProdutos: true,
          },
          ['unidade'],
        );
        if (produtoData.data.length > 0) {
          /* if (
              produtoData.data[0].precoCompraMedio >= 0
              && produtoData.data[0].precoCompraMedioImpostos >= 0
            ) { */
          if (true) {
            if (
              produtoData.data[0].preco_venda > 0 &&
              produtoData.data[0].preco_revenda > 0
            ) {
              const index = searchItemInArray(produtoData.data[0], itens);
              produtoData.data[0].unidadesDisponivel -= index.unidadesUsadas;
              produtoData.data[0].pesoDisponivel -= index.pesoUsado;
              const hideUnidades =
                produtoData.data[0].unidade.tipo ===
                POR_PESO_VARIAVEL_SEM_UNIDADE;
              setProduto(produtoData.data[0]);
              if (refSidebarInputs.current)
                refSidebarInputs.current.focus(hideUnidades ? 1 : 0);
            } else {
              if (refSearch.current) refSearch.current.focus();
              enqueueSnackbar(
                'Este produto possui o preço de venda ou revenda inválido!',
                {
                  variant: 'error',
                },
              );
            }
          } else {
            enqueueSnackbar('Este Produto não possui nenhum lote cadastrado!', {
              variant: 'error',
            });
          }
        } else {
          const produtoDataCaixa = await getListAllAPI(
            'produtos/caixa',
            ['id', 'asc'],
            { codigo, status: STATUS_ATIVO, ofertasProdutos: true },
            ['unidade'],
          );
          if (produtoDataCaixa.data.length > 0) {
            /* if (
                produtoDataCaixa.data[0].precoCompraMedio >= 0
                && produtoDataCaixa.data[0].precoCompraMedioImpostos >= 0
              ) { */
            if (true) {
              if (
                produtoDataCaixa.data[0].preco_venda > 0 &&
                produtoDataCaixa.data[0].preco_revenda > 0
              ) {
                const index = searchItemInArray(
                  produtoDataCaixa.data[0],
                  itens,
                );
                produtoDataCaixa.data[0].unidadesDisponivel -=
                  index.unidadesUsadas;
                produtoDataCaixa.data[0].pesoDisponivel -= index.pesoUsado;
                produtoDataCaixa.data[0].nivelCaixa = getNivelCaixaCodigo(
                  produtoDataCaixa.data[0],
                  codigo,
                );
                const hideUnidades =
                  produtoDataCaixa.data[0].unidade.tipo ===
                  POR_PESO_VARIAVEL_SEM_UNIDADE;
                // ajustar preços medios de compras
                if (produtoDataCaixa.data[0].nivelCaixa > 0) {
                  setProduto(produtoDataCaixa.data[0]);
                  if (refSidebarInputs.current)
                    refSidebarInputs.current.focus(0);
                } else {
                  setProduto(null);
                  if (refSearch.current) refSearch.current.focus();
                }
              } else {
                if (refSearch.current) refSearch.current.focus();
                enqueueSnackbar(
                  'Este produto possui o preço de venda ou revenda inválido!',
                  {
                    variant: 'error',
                  },
                );
              }
            } else {
              enqueueSnackbar(
                'Este Produto não possui nenhum lote cadastrado!',
                {
                  variant: 'error',
                },
              );
            }
          } else {
            setProduto(null);
            if (refSearch.current) refSearch.current.focus();
            enqueueSnackbar(
              'Produto com código de barras não cadastrado ou código de barras errado!',
              {
                variant: 'error',
              },
            );
          }
        }
      }
    } else {
      const produtoData = await getListAllAPI(
        'produtos',
        ['id', 'asc'],
        {
          id,
          ofertasProdutos: true,
        },
        ['unidade'],
      );
      if (produtoData.data.length > 0) {
        /* if (
          produtoData.data[0].precoCompraMedio >= 0
          && produtoData.data[0].precoCompraMedioImpostos >= 0
        ) { */
        if (true) {
          if (
            produtoData.data[0].preco_venda > 0 &&
            produtoData.data[0].preco_revenda > 0
          ) {
            const index = searchItemInArray(produtoData.data[0], itens);
            produtoData.data[0].unidadesDisponivel -= index.unidadesUsadas;
            produtoData.data[0].pesoDisponivel -= index.pesoUsado;
            const hideUnidades =
              produtoData.data[0].unidade.tipo ===
              POR_PESO_VARIAVEL_SEM_UNIDADE;
            setProduto(produtoData.data[0]);
            if (refSidebarInputs.current)
              refSidebarInputs.current.focus(hideUnidades ? 1 : 0);
          } else {
            if (refSearch.current) refSearch.current.focus();
            enqueueSnackbar(
              'Este produto possui o preço de venda ou revenda inválido!',
              {
                variant: 'error',
              },
            );
          }
        } else {
          enqueueSnackbar('Este Produto não possui nenhum lote cadastrado!', {
            variant: 'error',
          });
        }
      }
    }

    setSearch('');
  }

  function changeCaixaria() {
    changeCaixariaLocal(produto, setProduto, enqueueSnackbar);
  }

  async function handleCloseDialogoSenha(senha) {
    const dataConfig = await api.get('/config/1');
    if (senha === dataConfig.data.senha_alterar_preco_frente_caixa) {
      setEditPrice(true);
    } else {
      enqueueSnackbar('Senha incorreta!', {
        variant: 'error',
      });
    }
  }

  function addNewItem(dados) {
    addNewItemInterno(dados, produto);
  }

  useEffect(() => {
    async function focusCerto() {
      if (produto) {
        const hasUnidade =
          produto.unidade.tipo === POR_UNIDADE ||
          produto.unidade.tipo === POR_PESO_FIXO ||
          produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE;
        if (hasUnidade) {
          switch (produto.nivelCaixaAntigo) {
            case 1:
              if (produto.unidades_caixa_nivel_2 > 0) {
                if (refSidebarInputs.current) refSidebarInputs.current.focus(0);
              } else if (
                produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
              ) {
                if (refSidebarInputs.current) refSidebarInputs.current.focus(1);
              } else if (refSidebarInputs.current)
                refSidebarInputs.current.focus(0);
              break;
            case 2:
              if (produto.unidades_caixa_nivel_3 > 0) {
                if (refSidebarInputs.current) refSidebarInputs.current.focus(0);
              } else if (
                produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
              ) {
                if (refSidebarInputs.current) refSidebarInputs.current.focus(1);
              } else if (refSidebarInputs.current)
                refSidebarInputs.current.focus(0);
              break;
            case 3:
              if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
                if (refSidebarInputs.current) refSidebarInputs.current.focus(1);
              } else if (refSidebarInputs.current)
                refSidebarInputs.current.focus(0);
              break;
            case -1:
              if (
                produto.unidades_caixa_nivel_1 > 0 ||
                produto.peso_caixa_nivel_1 > ZERO_APROXIMADO
              ) {
                if (refSidebarInputs.current) refSidebarInputs.current.focus(0);
              } else if (
                produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
              ) {
                if (refSidebarInputs.current) refSidebarInputs.current.focus(1);
              } else if (refSidebarInputs.current)
                refSidebarInputs.current.focus(0);
              break;
          }
        }
      }
    }
    focusCerto();
  }, [produto]);

  function getQuantidades(produto, qtde, tipoUnidadeOferta, precoUnitario) {
    switch (produto.unidade.tipo) {
      case POR_UNIDADE: {
        switch (tipoUnidadeOferta) {
          case TIPO_UNIDADE_OFERTA_UN: {
            return { unidades: qtde, peso: 0, precoCaixa: precoUnitario };
          }
          case TIPO_UNIDADE_OFERTA_CAIXA: {
            return {
              unidades: qtde,
              peso: 0,
              precoCaixa: precoUnitario * produto.unidades_caixa_nivel_1,
            };
          }
          case TIPO_UNIDADE_OFERTA_CAIXETA: {
            return {
              unidades: qtde,
              peso: 0,
              precoCaixa:
                precoUnitario *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2,
            };
          }
          case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
            return {
              unidades: qtde,
              peso: 0,
              precoCaixa:
                precoUnitario *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3,
            };
          }
          default: {
            return { unidades: qtde, peso: 0, precoCaixa: precoUnitario };
          }
        }
      }
      case POR_PESO_VARIAVEL_SEM_UNIDADE: {
        switch (tipoUnidadeOferta) {
          case TIPO_UNIDADE_OFERTA_KG: {
            return { unidades: 0, peso: qtde, precoCaixa: precoUnitario };
          }
          case TIPO_UNIDADE_OFERTA_CAIXA: {
            return {
              unidades: qtde,
              peso: qtde * produto.peso_caixa_nivel_1,
              precoCaixa: precoUnitario * produto.peso_caixa_nivel_1,
            };
          }
          case TIPO_UNIDADE_OFERTA_CAIXETA: {
            return {
              unidades: qtde,
              peso:
                qtde *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2,
              precoCaixa:
                precoUnitario *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2,
            };
          }
          case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
            return {
              unidades: qtde,
              peso:
                qtde *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3,
              precoCaixa:
                precoUnitario *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3,
            };
          }
          default: {
            return { unidades: qtde, peso: 0, precoCaixa: precoUnitario };
          }
        }
      }
      case POR_PESO_VARIAVEL: {
        return { unidades: 0, peso: 0, precoCaixa: 0 };
      }
      case POR_PESO_FIXO: {
        switch (tipoUnidadeOferta) {
          case TIPO_UNIDADE_OFERTA_UN: {
            return {
              unidades: qtde,
              peso: qtde * produto.peso_medio,
              precoCaixa: precoUnitario,
            };
          }
          case TIPO_UNIDADE_OFERTA_CAIXA: {
            return {
              unidades: qtde,
              peso: qtde * produto.unidades_caixa_nivel_1 * produto.peso_medio,
              precoCaixa:
                precoUnitario *
                produto.unidades_caixa_nivel_1 *
                produto.peso_medio,
            };
          }
          case TIPO_UNIDADE_OFERTA_CAIXETA: {
            return {
              unidades: qtde,
              peso:
                qtde *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.peso_medio,
              precoCaixa:
                precoUnitario *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.peso_medio,
            };
          }
          case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
            return {
              unidades: qtde,
              peso:
                qtde *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3 *
                produto.peso_medio,
              precoCaixa:
                precoUnitario *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3 *
                produto.peso_medio,
            };
          }
          default: {
            return { unidades: qtde, peso: 0, precoCaixa: precoUnitario };
          }
        }
      }
      default:
        return { unidades: 0, peso: 0, precoCaixa: 0 };
    }
  }

  function getValueCaixaria(tipo) {
    switch (tipo) {
      case TIPO_UNIDADE_OFERTA_KG:
        return 0;
      case TIPO_UNIDADE_OFERTA_UN:
        return 0;
      case TIPO_UNIDADE_OFERTA_CAIXA:
        return 1;
      case TIPO_UNIDADE_OFERTA_CAIXETA:
        return 2;
      case TIPO_UNIDADE_OFERTA_CAIXA_MAE:
        return 3;
      default:
        return '';
    }
  }

  function handleCarregarLiberacoes(liberacoes) {
    const itensNovos = [];
    for (let i = 0; i < liberacoes.length; i++) {
      const produto = {
        ...liberacoes[i].produto,
        nivelCaixa: getValueCaixaria(liberacoes[i].tipo_unidade),
      };
      const { peso, unidades, precoCaixa } = getQuantidades(
        produto,
        liberacoes[i].escalonadas[0].quantidade,
        liberacoes[i].tipo_unidade,
        liberacoes[i].valor,
      );

      const unitario = precoCaixa;
      const pesoCalculado = getPesoCaixa(produto);

      itensNovos.push({
        produto,
        peso:
          produto.unidade.tipo === POR_UNIDADE
            ? 0
            : pesoCalculado === 0
            ? peso
            : pesoCalculado * unidades,
        total: getTotal2(peso, unidades, unitario, produto),
        unidades: getUnidadesItemCaixa(produto, unidades),
        unitario: getUnitarioCaixa(produto, unitario),
        uidd: `${produto.nome}${itens.length + i}`,
        idIndicadorChange: -1,
        unidadesCaixa: getUnidadesCaixa(produto),
        unitarioCaixa: unitario,
        pesoCaixa: pesoCalculado,
        unidadesCaixaAparente: produto.nivelCaixa ? unidades : 0,
        nivelCaixaria: produto.nivelCaixa ? produto.nivelCaixa : 0,
        cgp: null,
        grupo_oferta_preco_produto_id: liberacoes[i].id,
        tipo_oferta: liberacoes[i].tipo,
        is_margem_cartao: liberacoes[i].is_margem_cartao,
      });
    }
    addItensLiberacao(itensNovos);
  }

  return {
    searchHandle,
    handleCloseDialogoSenha,
    addNewItem,
    setSearch,
    editPrice,
    produto,
    subTotal,
    refSearch,
    search,
    refSidebarInputs,
    enqueueSnackbar,
    setProduto,
    changeCaixaria,
    handleCarregarLiberacoes,
  };
}

export default useItens;
