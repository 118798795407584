import React from 'react';

import { Paper, Box, makeStyles } from '@material-ui/core';

import { formatMoeda } from '../../../../../utils';

const useStyles = makeStyles((theme) => ({
  containerOpaco: {
    padding: '10px',
    backgroundColor: theme.palette.secondary.main,
    height: '120px',
    marginTop: '0px',
    color: 'white',
    boxSizing: 'border-box',
    opacity: '0.75',
  },
  containerInterno: {
    height: '100%',
    marginTop: '10px',
    fontSize: '35px',
  },
}));

const LabelSubtotal = ({ valor, label = 'TOTAL DA NOTA' }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.containerOpaco} elevation={3}>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        color="white"
      >
        <div style={{ textAlign: 'center' }}>{label}</div>

        <Paper className={classes.containerInterno} elevation={3}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            color="black"
          >
            {formatMoeda(valor)}
          </Box>
        </Paper>
      </Box>
    </Paper>
  );
};

export default LabelSubtotal;
