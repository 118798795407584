import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import { AutoCompleteRemoto } from '../../../Components';
import { getListAllAPI } from '../../../services';
import { STATUS_ATIVO } from '../../../utils';
import LocaisInput from '../../Colaboradores/LocaisInput';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));
/*
const GenericoInput = forwardRef(({ formato, ...props }, ref) => (
  <TemplateNumberFormatInput {...props} formato={formato} ref={ref} />
)); */

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_ID = -1;

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose = () => {}, handleSave }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const [locaisOptions, setLocaisOptions] = useState([]);
    const [colaboradoresOptions, setColaboradoresOptions] = useState([]);

    const [locais, setLocais] = useState([]);
    const [colaboradores, setColaboradores] = useState([]);

    const [gestor, setGestor] = useState(null);

    const [id, setId] = useState(DEFAULT_ID);
    const [isCreate, setIsCreate] = useState(true);

    const classes = useStyles({ cor: '#009FD4' });

    useEffect(() => {
      async function getDadosOptions() {
        try {
          const data = await getListAllAPI(
            'locais_setores',
            ['id', 'asc'],
            { status: STATUS_ATIVO },
            [],
          );
          const data2 = await getListAllAPI(
            'colaboradores',
            ['id', 'asc'],
            { status: STATUS_ATIVO },
            [],
          );
          setLocaisOptions(data.data);
          setColaboradoresOptions(data2.data);
        } catch (erros) {
          enqueueSnackbar(`${erros}`, {
            variant: 'error',
          });
          handleCloseDialog();
        }
      }
      if (open) getDadosOptions();
    }, [open]);

    const handleCloseDialog = () => {
      setOpen(false);
    };

    async function reiniciar() {
      setId(DEFAULT_ID);
      setLocais([]);
      setColaboradores([]);
      setGestor(null);
      setIsCreate(true);
      setOpen(true);
    }

    async function handleSalvar() {
      try {
        if (isCreate) {
          handleSave(id, locais, colaboradores, gestor, isCreate);
          enqueueSnackbar('Delegação criada com sucesso!!', {
            variant: 'success',
          });
        } else {
          handleSave(id, locais, colaboradores, gestor, isCreate);
          enqueueSnackbar('Delegação Atualizada com Sucesso!', {
            variant: 'success',
          });
        }
        handleCloseDialog();
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    useImperativeHandle(ref, () => ({
      handleCreate() {
        reiniciar();
        setOpen(true);
      },
      handleEdit(id, locais, colaboradores, gestor) {
        setId(id);
        setLocais(locais);
        setColaboradores(colaboradores);
        setGestor(gestor);
        setIsCreate(false);
        setOpen(true);
      },
    }));

    function validateLocais() {
      const error = '';
      /*if (locais.length === 0) {
        error = 'Este campo é obrigatório';
      }*/
      return error;
    }

    function validateColaboradores() {
      let error = '';
      if (colaboradores.length === 0) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function validateGestor() {
      let error = '';
      if (!gestor) error = 'Este campo é obrigatório';
      return error;
    }

    function getErros() {
      const errosOld = ['', ''];
      errosOld[0] = validateLocais();
      errosOld[1] = validateColaboradores();
      errosOld[2] = validateGestor();
      return errosOld;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullWidth
          maxWidth="sm"
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            {id < 0 ? 'Nova Delegação' : 'Editar Delegação'}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 15px 0px',
              margin: '0',
            }}
          >
            <div>
              <Box flex={1} marginTop="20px">
                <AutoCompleteRemoto
                  id="gestor"
                  name="gestor"
                  label="Gestor"
                  value={gestor}
                  onChange={(value) => setGestor(value)}
                  handleKey={() => {}}
                  handleEnter={() => {}}
                  resource="colaboradores"
                  toString={false}
                  fullwidth
                  //autoFocus
                  filters={{ status: STATUS_ATIVO, is_gestor: true }}
                  error={erros[2] !== ''}
                  helperText={erros[2]}
                />
              </Box>
              <Box flex={1} marginTop="20px">
                <LocaisInput
                  label="Locais"
                  options={locaisOptions}
                  value={locais}
                  onChange={setLocais}
                  limitTags={10}
                />
              </Box>
              <Box flex={1} marginTop="20px">
                <LocaisInput
                  label="Colaboradores"
                  options={colaboradoresOptions}
                  value={colaboradores}
                  onChange={setColaboradores}
                  limitTags={10}
                />
              </Box>
            </div>
          </DialogContent>
          <DialogActions
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                disabled={erroExistente}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
