import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
} from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import { TextInput } from '../..';
import {
  ATALHO_VOLTAR,
  ATALHO_AVANCAR,
  writeCodigoBarras,
  readCodigoBarras,
} from '../../../../../utils';

const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;

const useStyles = makeStyles((theme) =>
  createStyles({
    action: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    root: {
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

const DialogoConfirmacao = forwardRef(({ handlePedido = () => {} }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const classes = useStyles();
  const [codigo, setCodigo] = useState('');

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
      setCodigo('');
    },
  }));

  function validateCodigo() {
    if (codigo.length !== 13) {
      return 'Este campo deve conter 13 dígitos!';
    }
    return '';
  }

  const erroCodigo = validateCodigo();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSalvar = () => {
    if (erroCodigo !== '') {
      enqueueSnackbar(erroCodigo, {
        variant: 'error',
      });
    } else {
      setOpen(false);
      handlePedido(readCodigoBarras(codigo));
    }
  };

  function limparCodigo(codigo) {
    if (codigo) {
      const codigoSemEspacos = codigo.trim();
      return codigoSemEspacos.replace(/\D/g, '');
    }
    return '';
  }

  function handleActions(key, name) {
    switch (name) {
      case CANCELAR_VENDA_ACTION:
        handleClose();
        break;
      case FECHAR_VENDA_ACTION:
        handleSalvar();
        break;
      default:
        break;
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: { backgroundColor: theme.palette.secondary.main },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ color: 'white', textAlign: 'center', fontWeight: 'bold' }}
        >
          CARREGAR PEDIDO
        </DialogTitle>
        <DialogContent>
          <TextInput
            handleEnter={() => handleSalvar()}
            label=""
            handleKey={handleActions}
            value={codigo}
            onChange={(value) => setCodigo(limparCodigo(value.target.value))}
            fullWidth
            style={{ backgroundColor: 'white' }}
            autoFocus
          />
        </DialogContent>
        <DialogActions className={classes.action}>
          <Button
            onClick={handleClose}
            color="secondary"
            style={{ color: 'white' }}
            tabindex="-1"
          >
            {`Fechar (${CANCELAR_VENDA_ACTION})`}
          </Button>
          <Button
            onClick={handleSalvar}
            color="secondary"
            style={{ color: 'white' }}
            tabindex="-1"
            disabled={erroCodigo !== ''}
          >
            {`Carregar (${FECHAR_VENDA_ACTION})`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoConfirmacao;
