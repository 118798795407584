import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles, Typography } from '@material-ui/core';

import { formatPeso, getLabelNivelCaixaria } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '400px',
  },
}));

function getNomeProduto(data) {
  const sufixoUnidade = data.unidades_caixa_aparente
    ? data.peso_caixa > 0
      ? `${getLabelNivelCaixaria(data.nivel_caixaria)}(${formatPeso(
          data.peso_caixa,
        )} kg)`
      : `${getLabelNivelCaixaria(data.nivel_caixaria)}(${
          data.unidades_caixa
        } un)`
    : data.unidades_caixa
    ? `cx(${data.unidades_caixa} un)`
    : '';

  return `${data.produto.nome}${
    sufixoUnidade !== '' ? ` - ${sufixoUnidade}` : ''
  }`;
}

const DescricaoField = (props) => {
  const classes = useStyles();

  const record = useRecordContext(props);
  const value = record ? getNomeProduto(record.record) : '';

  return <Typography className={classes.text}>{value}</Typography>;
};

export default DescricaoField;
