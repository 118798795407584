import React from 'react';

import { Box, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

const Dica = ({ title }) => (
  <Box>
    <Typography>{title}</Typography>
  </Box>
);

export default ({ title, value }) => (
  <Tooltip TransitionComponent={Zoom} title={<Dica title={title} />}>
    <VpnKeyIcon style={{ cursor: 'pointer', color: value ? 'green' : 'red' }} />
  </Tooltip>
);
