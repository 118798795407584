import React, { useRef, useState, useCallback, useEffect } from 'react';
import {
  TextField,
  ReferenceField,
  ShowButton,
  NumberField,
  ListBase,
  ListToolbar,
  Pagination,
  FunctionField,
  useRefresh,
  Title,
  useListContext,
  Datagrid,
  DateField,
  ListContextProvider,
  BooleanField,
} from 'react-admin';
import { useHistory } from 'react-router-dom';

import { Card } from '@material-ui/core';
import { stringify } from 'query-string';

import { STATUS_ATIVO } from '../../utils';
import ActionsField from './ActionsField';
import Actions from './ActionsList';
import Filter from './Filter';

const MyList = ({ ...props }) => {
  const history = useHistory();
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;

  function handleTransacoesEspeciais({ tipo_transacao_especial }) {
    const queryOj = {};

    queryOj.displayedFilters = JSON.stringify({
      tipo: true,
      status: true,
      periodo: true,
    });

    queryOj.filter = JSON.stringify({
      tipo: tipo_transacao_especial,
      status: STATUS_ATIVO,
      periodo: filterValues.periodo,
    });

    history.push(
      `/transacoes_especiais?${stringify(
        queryOj,
      )}&order=DESC&page=1&perPage=50&sort=data`,
    );
  }

  return (
    <>
      <Title title="Contas Especiais" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid {...props}>
          <TextField source="nome" label="Descrição" />
          <NumberField
            sortable={false}
            source="valor"
            locales="pt-BR"
            options={{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            }}
            label="Saldo"
          />
          <ActionsField
            source="valor"
            textAlign="right"
            label="Ações"
            sortable={false}
            handleTransacoesEspeciais={handleTransacoesEspeciais}
          />
        </Datagrid>
        <TransacoesPagination />
      </Card>
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10]} {...props} />
);

const List = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Contas Especiais"
      filterDefaultValues={{
        periodo: {
          start: new Date(),
          end: new Date(),
        },
      }}
      component="div"
    />
  </>
);

export default List;
