export function createObjEstoque(linhas) {
  const serasaPF = [];
  const serasaPJ = [];
  const spcPF = [];

  for (let i = 0; i < linhas.length; i++) {
    serasaPF.push(linhas[i].serasa_pf);
    serasaPJ.push(linhas[i].serasa_pj);
    spcPF.push(linhas[i].spc_pf);
  }

  return [
    {
      label: 'SERASA PF',
      color: '#f28817',
      data: serasaPF,
    },
    {
      label: 'SERASA PJ',
      color: '#4f10ff',
      data: serasaPJ,
    },
    {
      label: 'SPC PF',
      color: '#414142',
      data: spcPF,
    },
  ];
}
