import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import { getListAllAPI } from '../../../services';
import {
  formatCNPJ,
  formatCEP,
  formatIE,
  SECONDARY_COLOR,
} from '../../../utils';

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_ID = -1;

const SidebarInputs = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [id, setId] = useState(DEFAULT_ID);
  const [bairro, setBairro] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [razaoSocial, setRazaoSocial] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [ie, setIe] = useState('');
  const [cep, setCep] = useState('');
  const [cidade, setCidade] = useState(null);

  const [carregando, setCarregando] = useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'empresa-loja',
        ['id', 'asc'],
        { id: [id] },
        ['cidade.estado'],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        setBairro(data.data[0].bairro);
        setLogradouro(data.data[0].logradouro);
        setNumero(data.data[0].numero);
        setRazaoSocial(data.data[0].razao_social);
        setNomeFantasia(data.data[0].nome_fantasia);
        setCnpj(data.data[0].cnpj);
        setCep(data.data[0].cep);
        setCidade(data.data[0].cidade);
        setIe(data.data[0].inscricao_estadual);
        setCarregando(false);
      } else {
        throw 'Este fornecedor não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(-1);
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setOpen(true);
      setCarregando(true);
      setId(id);
      getDataResource(id);
    },
  }));

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen
    >
      <DialogTitle id="form-dialog-title">
        {`Detalhes Loja ${nomeFantasia}`}
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ height: '100%' }}>
          <Box
            display={{ xs: 'block', sm: 'flex' }}
            flexDirection="column"
            marginBottom="30px"
            height="100%"
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '300px',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Box p="1em">
                <Box mt="1em" />
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{ margin: '30px 0', color: SECONDARY_COLOR }}
                >
                  Dados Empresariais
                </Typography>
                <Box mt="1em" />
                <Box display="flex">
                  <Box flex={1} mr="0.5em" mb="10px">
                    <span style={{ fontWeight: 'bold', marginRight: '10px' }}>
                      Razão Social:{' '}
                    </span>
                    {`${razaoSocial || ''}`}
                  </Box>
                  <Box flex={1} ml="0.5em" mb="10px">
                    <span style={{ fontWeight: 'bold', marginRight: '10px' }}>
                      Nome Fantasia:{' '}
                    </span>
                    {`${nomeFantasia || ''}`}
                  </Box>
                </Box>
                <Box display="flex">
                  <Box
                    flex={1}
                    mr="0.5em"
                    display="flex"
                    alignItems="center"
                    mb="10px"
                  >
                    <span style={{ fontWeight: 'bold', marginRight: '10px' }}>
                      cnpj:{' '}
                    </span>
                    {`${cnpj ? formatCNPJ(cnpj) : ''}`}
                  </Box>
                  <Box flex={1} ml="0.5em" mb="10px">
                    <span style={{ fontWeight: 'bold', marginRight: '10px' }}>
                      IE:{' '}
                    </span>
                    {`${ie ? formatIE(ie) : ''}`}
                  </Box>
                </Box>
                <Box mt="1em" />
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{ margin: '30px 0', color: SECONDARY_COLOR }}
                >
                  Endereço
                </Typography>
                <Box mt="1em" />
                <Box display="flex">
                  <Box flex={1} mr="0.5em" mb="10px">
                    <span style={{ fontWeight: 'bold', marginRight: '10px' }}>
                      Cidade:{' '}
                    </span>
                    {`${cidade ? cidade.nome : ''}`}
                  </Box>
                  <Box flex={1} ml="0.5em" mb="10px">
                    <span style={{ fontWeight: 'bold', marginRight: '10px' }}>
                      Bairro:{' '}
                    </span>
                    {`${bairro || ''}`}
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" mb="10px">
                    <span style={{ fontWeight: 'bold', marginRight: '10px' }}>
                      Logradouro:{' '}
                    </span>
                    {`${logradouro || ''}`}
                  </Box>
                  <Box flex={1} ml="0.5em" mb="10px">
                    <span style={{ fontWeight: 'bold', marginRight: '10px' }}>
                      Nº:{' '}
                    </span>
                    {`${numero || ''}`}
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" mb="10px">
                    <span style={{ fontWeight: 'bold', marginRight: '10px' }}>
                      CEP:{' '}
                    </span>
                    {`${cep ? formatCEP(cep) : ''}`}
                  </Box>
                  <Box flex={1} ml="0.5em" mb="10px" />
                </Box>
              </Box>
            )}
          </Box>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.wrapper}>
          <Button
            color="primary"
            onClick={() => {
              handleCloseDialog();
            }}
            disabled={carregando}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
          >
            Fechar
          </Button>
          {carregando && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
});

export default SidebarInputs;
