import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { Store } from '@material-ui/icons';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailsIcon from '@material-ui/icons/Details';

import { formatMoeda } from '../../../../../utils';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'block',
  },
  sectionDesktop: {
    display: 'flex',
    gap: '10px',
  },
}));

export default function PrimarySearchAppBar({
  disabledBack = false,
  disabledForward = false,
  disabledDeletarParcela = false,
  onClick,
  VOLTAR_ACTION,
  FINALIZAR_ACTION,
  DELETAR_PARCELA_ACTION,
  ADICIONAR_LOTE_ACTION,
  DETALHES_ACTION,
  numeroCompra,
  idEdit,
  subtotal,
  resto,
  totalParcelas,
}) {
  const classes = useStyles();

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar>
          <Tooltip title="Cancelar">
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              size="small"
              disabled={disabledBack}
              onClick={() => onClick(VOLTAR_ACTION)}
            >
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Prosseguir">
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              size="small"
              disabled={disabledForward}
              onClick={() => onClick(FINALIZAR_ACTION)}
            >
              <ArrowForwardIcon
                className={resto > 10 || resto < -10 ? '' : 'btnImprimir'}
              />
            </IconButton>
          </Tooltip>
          <Typography className={classes.title} variant="h6" noWrap>
            {}
          </Typography>
          <div className={classes.grow}>
            {idEdit <= 0 ? (
              <div>
                <span
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  NOVA COMPRA
                </span>
                <span
                  style={{
                    margin: '0 10px',
                  }}
                >
                  |
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    marginRight: '7px',
                    color: 'red',
                  }}
                >
                  SUBTOTAL:
                </span>
                <span>{formatMoeda(subtotal)}</span>
                <span
                  style={{
                    margin: '0 10px',
                  }}
                >
                  |
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    marginRight: '7px',
                  }}
                >
                  TOTAL DE PARCELAS:
                </span>
                <span>{formatMoeda(totalParcelas)}</span>
                <span
                  style={{
                    margin: '0 10px',
                  }}
                >
                  |
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    marginRight: '7px',
                    color: 'red',
                  }}
                >
                  RESTANTE:
                </span>
                <span>{formatMoeda(resto)}</span>
              </div>
            ) : (
              <div>
                <span
                  style={{
                    fontWeight: 'bold',
                    marginRight: '7px',
                    color: 'red',
                  }}
                >
                  COMPRA Nº:
                </span>
                <span>{numeroCompra}</span>
                <span
                  style={{
                    margin: '0 10px',
                  }}
                >
                  |
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    marginRight: '7px',
                    color: 'red',
                  }}
                >
                  SUBTOTAL:
                </span>
                <span>{formatMoeda(subtotal)}</span>
                <span
                  style={{
                    margin: '0 10px',
                  }}
                >
                  |
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    marginRight: '7px',
                  }}
                >
                  TOTAL DE PARCELAS:
                </span>
                <span>{formatMoeda(totalParcelas)}</span>
                <span
                  style={{
                    margin: '0 10px',
                  }}
                >
                  |
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    marginRight: '7px',
                    color: 'red',
                  }}
                >
                  RESTANTE:
                </span>
                <span>{formatMoeda(resto)}</span>
              </div>
            )}
          </div>
          <div className={classes.sectionDesktop}>
            <Tooltip title="Detalhes">
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                color="inherit"
                onClick={() => onClick(DETALHES_ACTION)}
                size="small"
              >
                <DetailsIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Adicionar Parcela Compra">
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                color="inherit"
                onClick={() => onClick(ADICIONAR_LOTE_ACTION)}
                size="small"
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Deletar Parcela Compra">
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                color="inherit"
                onClick={() => onClick(DELETAR_PARCELA_ACTION)}
                size="small"
                disabled={disabledDeletarParcela}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
