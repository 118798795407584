import * as React from 'react';
import { useRecordContext } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import LineWeightIcon from '@material-ui/icons/LineWeight';
import MinimizeIcon from '@material-ui/icons/Minimize';
import { useSnackbar } from 'notistack';

const ActionsField = (props) => {
  //const record = useRecordContext(props);
  const boletoValue = props
    ? props.record
      ? props.record.codigo_boleto
      : ''
    : '';

  const { enqueueSnackbar } = useSnackbar();

  async function copiarParaAreaDeTranferencia(value) {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(value);

      enqueueSnackbar('Código copiado para a área de transferência!', {
        variant: 'success',
      });
    } else {
      enqueueSnackbar('Não tem acesso a api de copia e cola!', {
        variant: 'error',
      });
    }

    // if ('clipboard' in navigator) {
    //   await navigator.clipboard.writeText(value);
    // } else {
    //   document.execCommand('copy', true, value);
    // }
  }

  return (
    <div
      style={{
        marginRight: '0px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {boletoValue && (
        <Tooltip title="Copiar" placement="bottom" aria-label="add2">
          <IconButton
            aria-label="check"
            size="small"
            onClick={() => copiarParaAreaDeTranferencia(boletoValue)}
          >
            <LineWeightIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};



export default ActionsField;
