import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useState,
} from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import { getListAllAPI } from '../../../services';
import {
  formatMoeda,
  SECONDARY_COLOR,
  ATALHO_VOLTAR,
  MALOTE_MALOTE_OPTION,
  MALOTE_SANGRIA_OPTION,
  MALOTE_TROCO_OPTION,
  MALOTE_SUBMALOTE_OPTION,
} from '../../../utils';
import { formatarPacote } from '../../../utils/malote';

const inputs = [
  {
    nome: 'valor20000',
    valorNota: 200,
    label: 'Cédulas R$ 200,00',
    field: 'cedulas_200_reais',
  },
  {
    nome: 'valor10000',
    valorNota: 100,
    label: 'Cédulas R$ 100,00',
    field: 'cedulas_100_reais',
  },
  {
    nome: 'valor5000',
    valorNota: 50,
    label: 'Cédulas R$ 50,00',
    field: 'cedulas_50_reais',
  },
  {
    nome: 'valor2000',
    valorNota: 20,
    label: 'Cédulas R$ 20,00',
    field: 'cedulas_20_reais',
  },
  {
    nome: 'valor1000',
    valorNota: 10,
    label: 'Cédulas R$ 10,00',
    field: 'cedulas_10_reais',
  },
  {
    nome: 'valor500',
    valorNota: 5,
    label: 'Cédulas R$ 5,00',
    field: 'cedulas_5_reais',
  },
  {
    nome: 'valor200',
    valorNota: 2,
    label: 'Cédulas R$ 2,00',
    field: 'cedulas_2_reais',
  },
  {
    nome: 'valor100',
    valorNota: 1,
    label: 'Moedas R$ 1,00',
    field: 'moedas_1_real',
  },
  {
    nome: 'valor050',
    valorNota: 0.5,
    label: 'Moedas R$ 0,50',
    field: 'moedas_50_centavos',
  },
  {
    nome: 'valor025',
    valorNota: 0.25,
    label: 'Moedas R$ 0,25',
    field: 'moedas_25_centavos',
  },
  {
    nome: 'valor010',
    valorNota: 0.1,
    label: 'Moedas R$ 0,10',
    field: 'moedas_10_centavos',
  },
  {
    nome: 'valor005',
    valorNota: 0.05,
    label: 'Moedas R$ 0,05',
    field: 'moedas_5_centavos',
  },
];

const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;

const DialogoContadorCedulas = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const refs = useRef([]);
  const [dados, setDados] = useState(null);
  const [tipo, setTipo] = useState('');

  refs.current = inputs.map(
    (ref, index) => (refs.current[index] = React.createRef()),
  );

  function getQtde(field) {
    if (dados) {
      return dados.notas[field];
    }
    return 0;
  }

  function calculaTotal() {
    let totalSaldo = 0;
    for (let i = 0; i < inputs.length; i += 1) {
      totalSaldo += inputs[i].valorNota * getQtde(inputs[i].field);
    }
    return totalSaldo;
  }

  const valorTotal = calculaTotal();

  const handleCloseLocal = () => {
    setOpen(false);
  };

  async function getDataRetirada(retiradaId) {
    const data = await getListAllAPI(
      'lixeira_malotes',
      ['id', 'asc'],
      { id: retiradaId },
      [],
    );
    setTipo(data.data[0].tipo);
    setDados(formatarPacote(data.data[0]));
  }

  useImperativeHandle(ref, () => ({
    handleOpen(retiradaId) {
      getDataRetirada(retiradaId);
      setOpen(true);
    },
  }));

  function getTipoPacote() {
    switch (tipo) {
      case MALOTE_MALOTE_OPTION:
        return 'Malote';
      case MALOTE_SANGRIA_OPTION:
        return 'Sangria';
      case MALOTE_TROCO_OPTION:
        return 'Troco';
      case MALOTE_SUBMALOTE_OPTION:
        return 'Submalote';
      default:
        return '';
    }
  }

  function getTitle(total) {
    return `${getTipoPacote()} - ${formatMoeda(total)}`;
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseLocal}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        scroll="paper"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ color: 'white', backgroundColor: SECONDARY_COLOR }}
        >
          {getTitle(valorTotal)}
        </DialogTitle>
        <DialogContent dividers>
          <div style={{ height: '100%' }}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              padding="0px"
              overflow="auto"
            >
              {inputs.map((input, indexInput) => {
                const qtde = getQtde(input.field);
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{ color: SECONDARY_COLOR, width: '75px' }}
                    >{`${formatMoeda(input.valorNota)}`}</div>
                    <div style={{ marginLeft: '10px' }}>X</div>
                    <div style={{ marginLeft: '10px' }}>{qtde}</div>
                    <div style={{ marginLeft: '10px' }}>{' = '}</div>
                    <div
                      style={{ width: '150px', marginLeft: '10px' }}
                    >{`${formatMoeda(input.valorNota * qtde)}`}</div>
                  </div>
                );
              })}
            </Box>
          </div>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={handleCloseLocal}
            color="secondary"
            variant="contained"
            tabindex="-1"
          >
            {`Fechar (${CANCELAR_VENDA_ACTION})`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoContadorCedulas;
