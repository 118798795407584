import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

const CodigoField = (props) => {
  const record = useRecordContext(props);
  const value = record ? record.record.id : '';

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title={value} placement="bottom" aria-label="add2">
        <Typography>{value}</Typography>
      </Tooltip>
    </div>
  );
};


export default CodigoField;
