import React, { useState } from 'react';

import { makeStyles, responsiveFontSizes } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import MaterialIcon from 'material-icons-react';

import { AutoCompleteLocalCustomizado } from '../../../../Components';

const Vazio = [
  {
    nome: 'Todas as categorias',
    id: -1,
    cor: 'red',
    icone: '',
  },
];

const useStyles3 = makeStyles({
  chip: {
    borderColor: (props) => props.color,
    backgroundColor: 'white',
    cursor: 'default',
    color: '#8e8e8e',
    '&:hover': {
      color: (props) => props.color,
      backgroundColor: 'white',
    },
  },
  deleteIcon: {
    color: (props) => props.color,
    cursor: 'pointer',
    '&:hover': {
      color: (props) => props.color,
    },
  },
});

const ChipCategoria = ({ option, injetado, campo }) => {
  const classes2 = useStyles3({ color: option.color });
  const { onDelete, ...rest } = injetado;
  return (
    <>
      {option.id < 0 ? (
        <Chip
          style={{
            color: '#8e8e8e',
            borderColor: '#8e8e8e',
          }}
          variant="outlined"
          label={option[campo]}
          {...rest}
        />
      ) : (
        <Chip
          classes={{ outlined: classes2.chip, deleteIcon: classes2.deleteIcon }}
          variant="outlined"
          label={option[campo]}
          {...rest}
          avatar={
            <Avatar style={{ backgroundColor: 'white', marginRight: '3px' }}>
              <MaterialIcon icon={option.icon} size={20} color={option.color} />
            </Avatar>
          }
          onDelete={onDelete}
        />
      )}
    </>
  );
};

const Componente = ({
  options,
  campo = 'nome',
  label,
  handleEnter = () => {},
  handleKey = () => {},
  value,
  onChange,
}) => {
  const handleOnChange = (value) => {
    if (value.length > 0) {
      if (value[0].id < 0) {
        onChange(value.slice(1));
      } else {
        onChange(value);
      }
    } else {
      onChange(value);
    }
  };

  const renderTag = (option, injetado) => (
    <ChipCategoria option={option} injetado={injetado} campo={campo} />
  );

  const renderOption = (option, { selected }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Avatar
        style={{
          backgroundColor: option.color,
          marginRight: '10px',
          width: '30px',
          height: '30px',
        }}
      >
        <MaterialIcon icon={option.icon} size={20} color="#000000" />
      </Avatar>
      {option[campo]}
    </div>
  );

  return (
    <AutoCompleteLocalCustomizado
      name={label}
      handleEnter={handleEnter}
      label={label}
      handleKey={handleKey}
      value={value.length <= 0 ? Vazio : value}
      disableClearable={value.length <= 0}
      onChangeLocal={handleOnChange}
      campo={campo}
      options={options}
      variant="standard"
      renderTag={renderTag}
      renderOption={renderOption}
      style={{ marginTop: 0, marginBottom: 0 }}
    />
  );
};

export default Componente;
