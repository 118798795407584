import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  DateField,
  ListContextProvider,
  useListContext,
  ListBase,
  ListToolbar,
  Pagination,
  Datagrid,
  useRefresh,
  Title,
} from 'react-admin';

import { Divider, Tabs, Tab, Card, makeStyles } from '@material-ui/core';

import { DialogoTransacao, DialogoDeletarTransacao } from '../../Components';
import ContaField from '../Transacoes/Fields/ContaField2';
import DescricaoField from '../Transacoes/Fields/DescricaoField';
import ValorField from '../Transacoes/Fields/ValorField';
import Actions from './ActionsList';
import DialogoAtivarResource from './DialogoAtivarResource';
import DialogoDesativarResource from './DialogoDesativarResource';
import ActionsField from './Fields/ActionsField';
import Filter from './Filter';

const useStylesDatagrid = makeStyles((theme) => ({
  statusHeader: {
    paddingLeft: '30px',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  statusCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingLeft: '30px',
  },
  statusContent: {
    flex: 5,
    marginLeft: '30px',
    maxWidth: '100px',
  },
  dataHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoHeader: {
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoCell: {
    flex: 2,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaHeader: {
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaCell: {
    flex: 2,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaHeader: {
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaCell: {
    flex: 2,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  actionsHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingRight: '100px',
  },
  actionsCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingRight: '100px',
  },
}));

const tabs = [
  { id: 'nao_conferido', name: 'Não Conferido' },
  { id: 'conferido', name: 'Conferido' },
];

const MyList = ({ ...props }) => {
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [confirmados, setConfirmados] = useState([]);
  const [cancelados, setCancelados] = useState([]);
  const refDialogoAtivaResource = useRef(null);
  const refDialogoDesativarResource = useRef(null);
  const refresh = useRefresh();
  const classes = useStylesDatagrid();
  const refDialogo = useRef(null);
  const refDialogoDeletar = useRef(null);

  function ativarHandle(record) {
    if (refDialogoAtivaResource.current)
      refDialogoAtivaResource.current.handleOpen(record.record.id);
  }

  function desativarHandle(record) {
    if (refDialogoDesativarResource.current)
      refDialogoDesativarResource.current.handleOpen(record.record.id);
  }

  const edit = (data) => {
    if (refDialogo.current) {
      refDialogo.current.handleEdit(data.record.id);
    }
  };

  const deletar = (data) => {
    if (refDialogoDeletar.current) {
      refDialogoDeletar.current.handleOpen(data.record.id);
    }
  };

  useEffect(() => {
    if (ids && ids !== filterValues.transferencia_verificado) {
      switch (filterValues.transferencia_verificado) {
        case false:
          setCancelados(ids);
          break;
        case true:
          setConfirmados(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.transferencia_verificado]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters(
          { ...filterValues, transferencia_verificado: value === 'conferido' },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  function handleClose() {
    refresh();
  }

  return (
    <>
      <Title title="Reajustes" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Tabs
          variant="fullWidth"
          centered
          value={
            filterValues.transferencia_verificado ? tabs[1].id : tabs[0].id
          }
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.transferencia_verificado === false && (
            <ListContextProvider value={{ ...listContext, ids: cancelados }}>
              <Datagrid {...props}>
                <DateField
                  source="data_pagamento"
                  label="Data"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                />
                <DescricaoField
                  source="descricao"
                  label="Descrição"
                  sortable={false}
                  headerClassName={classes.descricaoHeader}
                  cellClassName={classes.descricaoCell}
                />
                <ContaField
                  label="Conta"
                  source="conta_id"
                  sortable={false}
                  headerClassName={classes.categoriaHeader}
                  cellClassName={classes.categoriaCell}
                />
                <ValorField
                  source="valor"
                  textAlign="right"
                  label="Valor"
                  headerClassName={classes.valorHeader}
                  cellClassName={classes.valorCell}
                />
                <ActionsField
                  status_tipo={false}
                  source="lalala"
                  handleEdit={edit}
                  handleDelete={deletar}
                  handleEfetivar={ativarHandle}
                  textAlign="right"
                  label="Ações"
                  headerClassName={classes.actionsHeader}
                  cellClassName={classes.actionsCell}
                  tipo={filterValues.tipo}
                  sortable={false}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.transferencia_verificado === true && (
            <ListContextProvider value={{ ...listContext, ids: confirmados }}>
              <Datagrid {...props}>
                <DateField
                  source="data_pagamento"
                  label="Data"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                />
                <DescricaoField
                  source="descricao"
                  label="Descrição"
                  sortable={false}
                  headerClassName={classes.descricaoHeader}
                  cellClassName={classes.descricaoCell}
                />
                <ContaField
                  label="Conta"
                  source="conta_id"
                  sortable={false}
                  headerClassName={classes.categoriaHeader}
                  cellClassName={classes.categoriaCell}
                />
                <ValorField
                  source="valor"
                  textAlign="right"
                  label="Valor"
                  headerClassName={classes.valorHeader}
                  cellClassName={classes.valorCell}
                />
                <ActionsField
                  status_tipo
                  source="lalala"
                  handleEdit={edit}
                  handleDelete={deletar}
                  handleEfetivar={desativarHandle}
                  textAlign="right"
                  label="Ações"
                  headerClassName={classes.actionsHeader}
                  cellClassName={classes.actionsCell}
                  tipo={filterValues.tipo}
                  sortable={false}
                />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
        <TransacoesPagination />
      </Card>
      <DialogoAtivarResource
        ref={refDialogoAtivaResource}
        handleClose={handleClose}
      />
      <DialogoDesativarResource ref={refDialogoDesativarResource} />
      <DialogoTransacao ref={refDialogo} handleClose={handleClose} />
      <DialogoDeletarTransacao
        ref={refDialogoDeletar}
        handleClose={handleClose}
      />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Reajustes"
      filterDefaultValues={{
        transferencia_verificado: false,
      }}
    />
  </>
);

export default List2;
