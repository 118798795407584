import React, { useState, useCallback } from 'react';
import { TopToolbar, sanitizeListRestProps, useListContext } from 'react-admin';

import { Box, makeStyles, Card } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: '20px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {},
}))(MenuItem);

const useStyles = makeStyles({
  root: {
    flex: 1,
    alignItems: 'center',
  },
});

const FilterStatusFatura = (props) => {
  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters } = listContext;

  const { statusFatura } = filterValues;

  const aplicarFiltros = useCallback(
    (filters) => {
      if (setFilters) {
        setFilters({ ...filters }, displayedFilters);
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  function changeStatusFatura(value) {
    aplicarFiltros({ statusFatura: value });
  }

  return (
    <Card
      style={{
        borderRadius: '20px',
        cursor: 'pointer',
        width: '360px',
        display: 'flex',
      }}
    >
      <button
        type="button"
        style={{
          borderRadius: '20px',
          color: statusFatura ? 'white' : 'black',
          backgroundColor: statusFatura ? '#00796b' : 'white',
          height: '100%',
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: 'none',
          fontWeight: 'bold',
        }}
        onClick={() => changeStatusFatura(true)}
      >
        Faturas abertas
      </button>
      <button
        type="button"
        style={{
          borderRadius: '20px',
          color: !statusFatura ? 'white' : 'black',
          backgroundColor: !statusFatura ? '#00796b' : 'white',
          height: '100%',
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: 'none',
          fontWeight: 'bold',
        }}
        onClick={() => changeStatusFatura(false)}
      >
        Faturas fechadas
      </button>
    </Card>
  );
};

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  enabledCreate,
  handleCriar = () => {},
  handleCartoesArquivados = () => {},
  ...rest
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TopToolbar {...sanitizeListRestProps(rest)} className={classes.root}>
      <Box
        display="flex"
        justifyContent="space-between"
        flex={1}
        alignItems="center"
      >
        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
          Cartões de crédito
        </div>
        <Box display="flex">
          <FilterStatusFatura />
          <Box marginLeft="15px">
            <Tooltip
              title="Novo cartão de crédito"
              placement="top"
              aria-label="add"
            >
              <Box
                boxShadow={2}
                borderRadius="50px"
                display="flex"
                alignItems="center"
                width="44px"
                style={{
                  backgroundColor: 'white',
                  height: '48px',
                  width: '48px',
                }}
              >
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  onClick={() => handleCriar()}
                  style={{ height: '48px', width: '48px', color: '#00796b' }}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>
          <Box marginLeft="15px">
            <Tooltip title="Mais Opções" placement="top" aria-label="add2">
              <Box
                boxShadow={2}
                borderRadius="50px"
                display="flex"
                alignItems="center"
                width="44px"
                style={{
                  backgroundColor: 'white',
                  height: '48px',
                  width: '48px',
                }}
              >
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  style={{ height: '48px', width: '48px' }}
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Zoom}
        transitionDuration={500}
      >
        <StyledMenuItem
          onClick={() => {
            handleClose();
            handleCartoesArquivados();
          }}
        >
          <ListItemText primary="Cartões Arquivados" />
        </StyledMenuItem>
      </StyledMenu>
    </TopToolbar>
  );
};

export default ListActions;
