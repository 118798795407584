import React, { useRef } from 'react';
import { Datagrid as DatagridAdmin } from 'react-admin';

import ChangeAtivoButton from '../ChangeAtivoButton';
import DeleteButton from '../DeleteButton';
import DialogoAtivarResources from '../DialogoAtivarResource';
import DialogoDeleteResource from '../DialogoDeleteResource';
import useStyles from './headerStyles';

const Datagrid = ({
  children,
  isDelete = false,
  isButtonAtivo = false,
  campoAtivo = 'ativo',
  inverterAtivo = false,
  ...rest
}) => {
  const classes = useStyles();
  const refDialogoDeleteResource = useRef(null);
  const refDialogoAtivaResource = useRef(null);

  function deleteHundle(record) {
    if (refDialogoDeleteResource.current)
      refDialogoDeleteResource.current.handleOpen(record);
  }
  function ativarHandle(record) {
    if (refDialogoAtivaResource.current)
      refDialogoAtivaResource.current.handleOpen(record);
  }
  return (
    <DatagridAdmin classes={{ headerCell: classes.row }} {...rest}>
      {children}
      {isDelete && <DeleteButton label="Excluir" deleteHundle={deleteHundle} />}
      {isButtonAtivo && (
        <ChangeAtivoButton
          deleteHundle={ativarHandle}
          campoAtivo={campoAtivo}
          inverterAtivo={inverterAtivo}
        />
      )}
      <DialogoDeleteResource ref={refDialogoDeleteResource} />
      <DialogoAtivarResources
        ref={refDialogoAtivaResource}
        campoAtivo={campoAtivo}
      />
    </DatagridAdmin>
  );
};

export default Datagrid;
