import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '400px',
  },
}));

const DataCriacaoField = (props) => {
  const classes = useStyles();

  const record = useRecordContext(props);
  const value = record
    ? record.record.created_at
      ? moment(new Date(record.record.created_at)).format('DD/MM/YYYY - HH:mm')
      : '-'
    : '-';

  return (
    <Tooltip title={value} placement="bottom" aria-label="add2">
      <Typography className={classes.text}>{value}</Typography>
    </Tooltip>
  );
};

export default DataCriacaoField;
