import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';

import { Paper, Box, Button } from '@material-ui/core';

import {
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL,
  pesoEfetivo,
  getQuantidadeCaixaria,
} from '../../utils';
import { getQuantidadeBasicas } from '../../utils/precoVenda';
import { IntegerInput, PesoInput } from '../DialogoEditarVenda/components';

const FormItemVenda = forwardRef(
  (
    { handleNewItem, handleKey = () => {}, disabledSubmit = false, produto },
    ref,
  ) => {
    const [unidades, setUnidades] = useState(0);
    const [peso, setPeso] = useState(0);
    const [erros, setErros] = useState(['', '']);

    const refs = useRef([]);
    const refButton = useRef(null);
    const inputs = [
      {
        nome: 'unidades',
        defaultValue: 0,
        label: 'Quantidade',
      },
      {
        nome: 'peso',
        defaultValue: 0,
        label: 'Peso',
      },
    ];

    refs.current = inputs.map(
      (ref, index) => (refs.current[index] = React.createRef()),
    );

    useEffect(() => {
      if (produto) {
        setErros(['', '']);
        setUnidades(inputs[0].defaultValue);
        setPeso(inputs[1].defaultValue);
      }
    }, [produto]);

    useEffect(() => {
      setPeso(pesoEfetivo(produto, unidades, peso));
    }, [unidades, produto]);

    function hideUnidades() {
      return produto
        ? produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE &&
            !produto.nivelCaixa
        : true;
    }

    function hidePeso() {
      return produto ? produto.unidade.tipo === POR_UNIDADE : true;
    }

    function disabledPeso() {
      return produto
        ? produto.unidade.tipo === POR_PESO_FIXO ||
            (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE &&
              produto.nivelCaixa)
        : true;
    }

    function validateUnidades() {
      let error = '';
      const condicao = produto
        ? produto.unidade.tipo !== POR_PESO_VARIAVEL_SEM_UNIDADE ||
          (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE &&
            produto.nivelCaixa)
        : false;
      if (condicao) {
        if (unidades <= 0 || Number.isNaN(unidades)) {
          error = 'Este campo é obrigatório';
        } else if (unidades > 100000) {
          error = 'Valor muito alto!';
        } else if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
          if (produto.nivelCaixa) {
            const { peso } = getQuantidadeCaixaria(produto, produto.nivelCaixa);
            if (unidades > produto.pesoFinal / peso) {
              error = 'Valor acima do disponível!';
            }
          }
        } else if (
          produto.unidade.tipo === POR_UNIDADE ||
          produto.unidade.tipo === POR_PESO_FIXO
        ) {
          if (produto.nivelCaixa) {
            const { unidades: unidadesLocal } = getQuantidadeCaixaria(
              produto,
              produto.nivelCaixa,
            );
            if (unidades > produto.unidadesFinal / unidadesLocal) {
              error = 'Valor acima do disponível!';
            }
          } else if (unidades > produto.unidadesFinal) {
            error = 'Valor acima do disponível!';
          }
        } else if (unidades > produto.unidadesFinal) {
          error = 'Valor acima do disponível!';
        }
      }
      return error;
    }

    function validatePeso() {
      let error = '';

      const condicao = produto
        ? produto.unidade.tipo === POR_PESO_VARIAVEL ||
          (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE &&
            !produto.nivelCaixa)
        : false;
      if (condicao) {
        if (peso <= 0 || Number.isNaN(peso)) {
          error = 'Este campo é obrigatório';
        } else if (peso > 1000000) {
          error = 'Valor muito alto!';
        } else if (peso > produto.pesoFinal) {
          error = 'Valor acima do disponível!';
        }
      }
      return error;
    }

    function getErros() {
      const errosOld = erros.slice();
      errosOld[0] = validateUnidades();
      errosOld[1] = validatePeso();
      return errosOld;
    }

    useEffect(() => {
      setErros(getErros());
    }, [unidades, peso]);

    function resetValues() {
      setErros(['', '']);
      setUnidades(inputs[0].defaultValue);
      setPeso(inputs[1].defaultValue);
    }

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (i === 2) {
          if (erros[i] === 'Este campo é obrigatório') return true;
        } else if (erros[i] !== '') return true;
      }
      return false;
    }

    function handleSubmit() {
      if (!hasError()) {
        handleNewItem({
          unidades,
          peso,
        });
        resetValues();
      }
    }

    useImperativeHandle(ref, () => ({
      focus(index = 0) {
        if (refs.current[index].current) {
          refs.current[index].current.focus();
          refs.current[index].current.select();
        }
      },
      submit() {
        handleSubmit();
      },
      redefineValues(unidadesNew, pesoNew) {
        setUnidades(unidadesNew);
        setPeso(pesoNew);
      },
    }));

    function getRefNextInput(index) {
      let position = -1;
      switch (index) {
        case 0:
          if (!hidePeso() && !disabledPeso()) position = 1;
          break;
        default:
          break;
      }
      return position;
    }

    function handleNextInput(index) {
      const position = getRefNextInput(index);
      if (position === -1) {
        if (refButton.current && !disabledSubmit) {
          refButton.current.click();
        }
      } else if (refs.current[position].current) {
        refs.current[position].current.focus();
        refs.current[position].current.select();
      }
    }

    return (
      <Paper elevation={3} style={{ opacity: '0.75' }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          padding={disabledSubmit ? '0px' : '15px'}
          overflow="auto"
        >
          {!hideUnidades() && (
            <IntegerInput
              name={inputs[0].nome}
              ref={refs.current[0]}
              handleEnter={() => handleNextInput(0)}
              label={inputs[0].label}
              handleKey={handleKey}
              value={Number.isNaN(unidades) ? '' : String(unidades)}
              onChange={(value) =>
                setUnidades(parseInt(value.target.value, 10))
              }
              error={erros[0] !== ''}
              helperText={erros[0]}
            />
          )}
          {!hidePeso() && (
            <PesoInput
              name={inputs[1].nome}
              ref={refs.current[1]}
              handleEnter={() => handleNextInput(1)}
              label={inputs[1].label}
              handleKey={handleKey}
              value={Number.isNaN(peso) ? '' : String(peso)}
              onChange={(value) => setPeso(parseFloat(value.target.value))}
              error={erros[1] !== ''}
              helperText={erros[1]}
              disabled={disabledPeso()}
            />
          )}
          {!disabledSubmit && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              ref={refButton}
              disabled={hasError()}
            >
              Adicionar à lista
            </Button>
          )}
        </Box>
      </Paper>
    );
  },
);

export default FormItemVenda;
