import React, { useRef, useEffect } from 'react';
import {
  Datagrid,
  Pagination,
  ListBase,
  ListToolbar,
  useRefresh,
  useListContext,
  List as ListAdmin,
  useUpdateMany,
  useNotify,
  useUnselectAll,
  BulkActionsToolbar,
} from 'react-admin';

import {
  Card,
  Typography,
  Box,
  makeStyles,
  useMediaQuery,
  IconButton,
  Button,
  Checkbox,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import InboxIcon from '@material-ui/icons/Inbox';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import moment from 'moment';

import { getUserId } from '../../services/alth';
import { SECONDARY_COLOR } from '../../utils';
import ActionsList from './ActionsList';
import DialogoChangeStatus from './DialogoChangeStatus';
import DialogoChangeStatusSelect from './DialogoChangeStatusSelect';
import DialogoCreate from './DialogoCreate';
import DialogoDeletar from './DialogoDeletar';
import DialogoEdit from './DialogoEdit';
import ActionsField from './Fields/ActionsField';
import CheckboxField from './Fields/Checkbox';
import Horario from './Fields/Horario';
import Produto from './Fields/Produto';
import StatusField from './Fields/StatusField';
import UnidadesField from './Fields/Unidades';
import DateInputMobills from './Inputs/DateInputMobills';

const useStylesDatagrid = makeStyles((theme) => ({
  header: {
    backgroundColor: '#f8f8f8',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
  },
  cell: {
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
    maxWidth: '670px',
  },
  primaryText: {
    fontWeight: 'bold',
    marginBottom: '10px',
  },
}));

const CategoryLista = ({
  handleEdit = () => {},
  handleDelete = () => {},
  handleChangeStatus = () => {},
  ...props
}) => {
  const classes = useStyles(props);
  const {
    data,
    ids,
    onToggleItem,
    selectedIds,
    filterValues,
  } = useListContext();
  return ids ? (
    <List className={classes.root}>
      {ids.map((id) => {
        const dadosItem = data[id];
        const checked = selectedIds.indexOf(id) >= 0;
        return (
          <>
            <ListItem alignItems="flex-start">
              <ListItemIcon>
                <Checkbox
                  color="primary"
                  className={`select-item ${classes.checkbox}`}
                  checked={checked}
                  onClick={() => onToggleItem(id)}
                />
              </ListItemIcon>
              {/* <ListItemIcon>
                {dadosItem.status ? (
                  <CheckIcon
                    style={{
                      color: 'white',
                      backgroundColor: '#4caf50',
                      borderRadius: '50%',
                    }}
                  />
                ) : (
                  <PriorityHighIcon
                    style={{
                      color: 'white',
                      backgroundColor: '#f44336',
                      borderRadius: '50%',
                    }}
                  />
                )}
              </ListItemIcon> */}
              <ListItemText
                classes={{ primary: classes.primaryText }}
                primary={dadosItem.produto.nome}
                secondary={
                  <>
                    <div>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                        style={{ fontWeight: 'bold', color: SECONDARY_COLOR }}
                      >
                        {`Cópias: `}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {`${dadosItem.copias}`}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                        style={{ fontWeight: 'bold', color: SECONDARY_COLOR }}
                      >
                        {`Horário: `}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {`${moment(dadosItem.horario).format('HH:mm')}`}
                      </Typography>
                    </div>
                  </>
                }
              />
              <ListItemSecondaryAction>
                {!filterValues.status ? (
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      handleChangeStatus({ record: dadosItem });
                    }}
                    style={{ color: '#00897B' }}
                  >
                    <CheckCircleOutlineIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      handleChangeStatus({ record: dadosItem });
                    }}
                    style={{ color: '#f44336' }}
                  >
                    <PriorityHighIcon />
                  </IconButton>
                )}
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    handleEdit({ record: dadosItem });
                  }}
                  style={{ color: '#00897B' }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    handleDelete({ record: dadosItem });
                  }}
                  style={{ color: '#00897B' }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="inset" component="li" />
          </>
        );
      })}
    </List>
  ) : null;
};

const DataGridLocal = ({
  handleEdit = () => {},
  handleDelete = () => {},
  handleChangeStatus = () => {},
  ...props
}) => {
  const classes = useStylesDatagrid();
  const { onToggleItem, selectedIds, filterValues } = useListContext();
  return (
    <>
      <Datagrid {...props} bulkActionButtons={false}>
        <CheckboxField
          source="status"
          label=""
          headerClassName={classes.header}
          cellClassName={classes.cell}
          selectedIds={selectedIds}
          handleToggleSelection={(data) => onToggleItem(data.id)}
          sortable={false}
        />
        <StatusField
          source="status"
          label="Status"
          headerClassName={classes.header}
          cellClassName={classes.cell}
          sortable={false}
        />
        <Produto
          source="nome"
          label="Produto"
          sortable={false}
          headerClassName={classes.header}
          cellClassName={classes.cell}
          //sortable={false}
        />
        <Horario
          source="horario"
          label="Horário"
          headerClassName={classes.header}
          cellClassName={classes.cell}
        />
        <UnidadesField
          source="copias"
          label="Cópias"
          headerClassName={classes.header}
          cellClassName={classes.cell}
        />
        <ActionsField
          source="lalala"
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleChangeStatus={handleChangeStatus}
          textAlign="right"
          label="Ações"
          //source="status"
          sortable={false}
          headerClassName={classes.header}
          cellClassName={classes.cell}
          filtroStatus={filterValues.status}
        />
      </Datagrid>
    </>
  );
};

const MyList = ({ handleOpenFiltros, ...props }) => {
  const refresh = useRefresh();
  const refDialogoCreate = useRef(null);
  const refDialogoEdit = useRef(null);
  const refDialogoDeletar = useRef(null);
  const refDialogoChangeStatus = useRef(null);
  const refDialogoChangeStatusSelect = useRef(null);
  const unselectAll = useUnselectAll();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { filterValues, selectedIds } = useListContext();

  const create = () => {
    if (refDialogoCreate.current) {
      refDialogoCreate.current.handleCreate();
    }
  };

  const edit = (data) => {
    if (refDialogoEdit.current) {
      refDialogoEdit.current.handleEdit(data.id);
    }
  };

  const changeStatus = (data) => {
    if (refDialogoChangeStatus.current) {
      refDialogoChangeStatus.current.handleOpen(data.id);
    }
  };

  const changeStatusSelect = () => {
    if (refDialogoChangeStatusSelect.current) {
      refDialogoChangeStatusSelect.current.handleOpen(
        selectedIds,
        filterValues.status,
      );
    }
  };

  const deletar = (data) => {
    if (refDialogoDeletar.current) {
      refDialogoDeletar.current.handleOpen(data.id);
    }
  };

  const handleClose = () => {
    refresh();
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '15px',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          marginBottom: '5px',
        }}
      >
        <Typography style={{ fontWeight: 'bold', color: SECONDARY_COLOR }}>
          {filterValues.status ? 'Impressões Feitas' : 'Fila de impressão'}
        </Typography>
      </div>

      <BulkActionsToolbar {...props}>
        <PostBulkActionButtons handleCheck={changeStatusSelect} />
      </BulkActionsToolbar>
      <ListToolbar
        filters={props.filters}
        actions={<ActionsList handleCriar={create} {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        {filterValues.status && (
          <Box flex={1} margin="0px 0 20px">
            <DateInputMobills />
          </Box>
        )}

        {isSmall ? (
          <CategoryLista
            handleEdit={edit}
            handleDelete={deletar}
            handleChangeStatus={changeStatus}
            {...props}
          />
        ) : (
          <DataGridLocal
            handleEdit={edit}
            handleDelete={deletar}
            handleChangeStatus={changeStatus}
            {...props}
          />
        )}
        <TransacoesPagination />
      </Card>
      <DialogoCreate handleClose={handleClose} ref={refDialogoCreate} />
      <DialogoEdit handleClose={handleClose} ref={refDialogoEdit} />
      <DialogoChangeStatus
        handleClose={handleClose}
        ref={refDialogoChangeStatus}
      />
      <DialogoChangeStatusSelect
        handleClose={() => {
          handleClose();
          unselectAll('solicitacoes_impressoes_etiqueta_2');
        }}
        ref={refDialogoChangeStatusSelect}
      />
      <DialogoDeletar handleClose={handleClose} ref={refDialogoDeletar} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]} {...props} />
);

const DIA_ATUAL = new Date().getDate();
const MES_ATUAL = new Date().getMonth();
const ANO_ATUAL = new Date().getFullYear();

const CustomResetViewsButton = ({ handleCheck }) => (
  /* const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll(); */

  <Button
    type="button"
    // onClick={() => unselectAll('solicitacoes_impressoes_etiqueta_2')}
    onClick={() => handleCheck()}
    style={{ backgroundColor: 'white' }}
  >
    <CheckIcon />
  </Button>
);
const PostBulkActionButtons = (props) => (
  <>
    <CustomResetViewsButton {...props} />
  </>
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      /* bulkActionButtons */
      component="div"
      /* bulkActionButtons={false} */
      sort={{ field: 'horario', order: 'DESC' }}
      pagination={<TransacoesPagination />}
      perPage={50}
      filter={{ user_id: getUserId() }}
      filterDefaultValues={{
        /* periodo: { dia: DIA_ATUAL, mes: MES_ATUAL, ano: ANO_ATUAL }, */
        status: false,
      }}
    >
      {/* <DataGridLocal /> */}
    </ContainerMyList>
  </>
);

export default List2;
