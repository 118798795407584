import React, { useImperativeHandle, forwardRef } from 'react';
import { useRefresh, useNotify } from 'react-admin';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { api } from '../../../services';

const FormDialog = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState({});
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClose = () => {
    setOpen(false);
  };

  async function handleConfirma(e) {
    try {
      await api.delete(`/vendas_parciais/${value.id}`);
      refresh();
      setOpen(false);
    } catch (erros) {
      setOpen(false);
      notify(`Erro ao deletar a venda parcial!`);
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(record) {
      setOpen(true);
      setValue(record);
    },
  }));

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Exluir Registro de Venda parcial
        </DialogTitle>
        <DialogContent>
          <div>
            <div>Tem certeza que deseja excluir essa venda parcial!</div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
          <Button onClick={handleConfirma} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default FormDialog;
