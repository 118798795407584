import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Typography } from '@material-ui/core';

const ClienteField = (props) => {
  const record = useRecordContext(props);
  const descricao = record
    ? record.record
      ? record.record.cliente
        ? record.record.cliente.nome
        : '-'
      : '-'
    : '-';

  return (
    <Typography
      variant="subtitle1"
      style={
        {
          //fontWeight: 'bold',
        }
      }
    >
      {descricao}
    </Typography>
  );
};

export default ClienteField;
