import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import {
  STATUS_ANDAMENTO_ATIVIDADE_EM_ANDAMENTO,
  STATUS_ANDAMENTO_ATIVIDADE_FINALIZADA,
  STATUS_ANDAMENTO_ATIVIDADE_NAO_VISUALIZADA,
  STATUS_ANDAMENTO_ATIVIDADE_PROBLEMATICA,
  STATUS_ANDAMENTO_ATIVIDADE_VISUALIZADA,
} from '../../../utils';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '400px',
  },
}));

function getStatusAndamento(value) {
  switch (value) {
    case STATUS_ANDAMENTO_ATIVIDADE_EM_ANDAMENTO:
      return 'Em Andamento';
    case STATUS_ANDAMENTO_ATIVIDADE_FINALIZADA:
      return 'Finalizada';
    /*case STATUS_ANDAMENTO_ATIVIDADE_NAO_VISUALIZADA:
      return 'Não Visualizada';
    case STATUS_ANDAMENTO_ATIVIDADE_PROBLEMATICA:
      return 'Problemática';
    case STATUS_ANDAMENTO_ATIVIDADE_VISUALIZADA:
      return 'Visualizada';*/
    default:
      return '-';
  }
}

const DescricaoField = (props) => {
  const classes = useStyles();

  const record = useRecordContext(props);
  const value = record ? getStatusAndamento(record.record.andamento) : '-';

  return (
    <Tooltip title={value} placement="bottom" aria-label="add2">
      <Typography className={classes.text}>{value}</Typography>
    </Tooltip>
  );
};

export default DescricaoField;
