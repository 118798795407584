import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useState,
} from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'notistack';

import DialogoGrafico from '../../../Components/Dialogos/DialogoCompra/Pages/InicioFrenteCaixaCompra/DialogoGrafico';
import { getListAllAPI } from '../../../services';
import {
  formatMoeda,
  SECONDARY_COLOR,
  ATALHO_VOLTAR,
  ceil10,
  formatDateDDMMYYYY,
} from '../../../utils';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: SECONDARY_COLOR,
    color: 'white',
    fontSize: 12,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function formatEscalasInput(ofertas) {
  const ofertas2 = [];
  for (let i = 0; i < ofertas.length; i++) {
    const esc = ofertas[i];
    if (esc.status) {
      ofertas2.push({
        id: esc.id,
        valor: esc.valor,
        tipo_unidade: esc.tipo_unidade,
        produto: esc.produto,
        dadosPrecificacao: esc.dadosPrecificacao,
        isEdited: false,
        nImpressoes: 0,
      });
    }
  }
  return ofertas2;
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogoContadorCedulas = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState(-1);
  const [dataMinima, setDataMinima] = useState('');
  const [dataMaxima, setDataMaxima] = useState('');
  const [ofertas, setOfertas] = useState([]);
  const refDialogoGrafico = useRef(null);

  const handleCloseLocal = () => {
    setOpen(false);
  };

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'jornais_de_ofertas',
        ['id', 'asc'],
        { id: [id] },
        ['ofertas.produto.unidade'],
      );
      if (data.data.length > 0) {
        setId(new Date(data.data[0].id));
        setDataMinima(formatDateDDMMYYYY(new Date(data.data[0].data_minima)));
        setDataMaxima(formatDateDDMMYYYY(new Date(data.data[0].data_maxima)));
        setOfertas(formatEscalasInput(data.data[0].ofertas));
      } else {
        throw 'Este Jornal de Ofertas não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseLocal(0);
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      getDataResource(id);
      setOpen(true);
    },
  }));

  function abrirGrafico(dados) {
    if (refDialogoGrafico.current) {
      if (dados.dadosPrecificacao) {
        refDialogoGrafico.current.handleOpen(
          [
            {
              label: 'Valor Negociado com a indústria',
              backgroundColor: 'rgba(68, 114, 196, 1)',
              borderColor: 'rgba(68, 114, 196, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.valor_negociado_com_a_industria,
                -2,
              ),
            },
            {
              label: 'Margem de Custo Operacional',
              backgroundColor: 'rgba(237, 125, 49, 1)',
              borderColor: 'rgba(237, 125, 49, 1)',
              valor: ceil10(dados.dadosPrecificacao.custo_operacional, -2),
            },
            {
              label: 'Diferença de ICMS',
              backgroundColor: 'rgba(165, 165, 165, 1)',
              borderColor: 'rgba(165, 165, 165, 1)',
              valor: ceil10(dados.dadosPrecificacao.diferenca_de_icms, -2),
            },
            {
              label: 'Lucro Líquido',
              backgroundColor: 'rgba(91, 155, 213, 1)',
              borderColor: 'rgba(91, 155, 213, 1)',
              valor: ceil10(dados.dadosPrecificacao.lucro_bruto, -2),
            },
            {
              label: 'Diferença de COFINS',
              backgroundColor: 'rgba(112, 173, 71, 1)',
              borderColor: 'rgba(112, 173, 71, 1)',
              valor: ceil10(dados.dadosPrecificacao.diferenca_de_cofins, -2),
            },
            {
              label: 'Margem de Custo de Cartão',
              backgroundColor: 'rgba(10, 224, 196, 1)',
              borderColor: 'rgba(10, 224, 196, 1)',
              valor: ceil10(dados.dadosPrecificacao.custo_de_cartao, -2),
            },
            {
              label: 'Diferença de PIS',
              backgroundColor: 'rgba(7, 15, 49, 1)',
              borderColor: 'rgba(7, 15, 49, 1)',
              valor: ceil10(dados.dadosPrecificacao.difereca_de_pis, -2),
            },
            {
              label: 'Repasse do IPI',
              backgroundColor: 'rgba(200, 165, 165, 1)',
              borderColor: 'rgba(200, 165, 165, 1)',
              valor: ceil10(dados.dadosPrecificacao.repasse_do_ipi, -2),
            },
            {
              label: 'Repasse do Frete',
              backgroundColor: 'rgba(90, 1, 213, 1)',
              borderColor: 'rgba(90, 1, 213, 1)',
              valor: ceil10(dados.dadosPrecificacao.repasse_do_frete, -2),
            },
            {
              label: 'Repasse do ST',
              backgroundColor: 'rgba(200, 0, 0, 1)',
              borderColor: 'rgba(200, 0, 0, 1)',
              valor: ceil10(dados.dadosPrecificacao.repasse_de_st, -2),
            },
            {
              label: 'Repasse do Seguro',
              backgroundColor: 'rgba(60, 60, 6, 1)',
              borderColor: 'rgba(60, 60, 6, 1)',
              valor: ceil10(dados.dadosPrecificacao.repasse_do_seguro, -2),
            },
            {
              label: 'Repasse de Outras Despesas',
              backgroundColor: 'rgba(5, 5, 5, 1)',
              borderColor: 'rgba(5, 5, 5, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.repasse_de_outras_despesas,
                -2,
              ),
            },
            {
              label: 'ICMS de Fronteira',
              backgroundColor: 'rgba(100, 50, 100, 1)',
              borderColor: 'rgba(100, 50, 100)',
              valor: ceil10(dados.dadosPrecificacao.icms_fronteira, -2),
            },
            {
              label: 'ICMS de Antecipação',
              backgroundColor: 'rgba(10, 20, 250, 1)',
              borderColor: 'rgba(10, 20, 250, 1)',
              valor: ceil10(dados.dadosPrecificacao.icms_antecipacao, -2),
            },
          ],
          dados.dadosPrecificacao.preco_de_venda,
          dados.produto.nome,
        );
      } else {
        enqueueSnackbar(`Este lote não tem dados de precificação salvos!`, {
          variant: 'error',
        });
      }
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseLocal}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        scroll="paper"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ color: 'white', backgroundColor: SECONDARY_COLOR }}
          onClose={handleCloseLocal}
        >
          Detalhes Jornal de Ofertas
        </DialogTitle>
        <DialogContent dividers>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                marginTop: '20px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  gap: '20px',
                }}
              >
                <span
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  Data Início:
                </span>
                <span>{dataMinima}</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: '20px',
                }}
              >
                <span
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  Data Término:
                </span>
                <span>{dataMaxima}</span>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '20px 0',
              }}
            >
              <h2
                style={{ textAlign: 'center' }}
              >{`Ofertas (${ofertas.length})`}</h2>
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Produto</StyledTableCell>
                    <StyledTableCell>Preço Atual de Venda</StyledTableCell>
                    <StyledTableCell>Preço de Oferta</StyledTableCell>
                    <StyledTableCell align="right">Ações</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ofertas.map((row) => (
                    <StyledTableRow key={row.uidd}>
                      <StyledTableCell>{row.produto.nome}</StyledTableCell>
                      <StyledTableCell>
                        {formatMoeda(row.produto.preco_venda)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {formatMoeda(row.valor)}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Tooltip title="Gráfico de pizza">
                          <IconButton
                            aria-label="delete"
                            onClick={() => abrirGrafico(row)}
                            size="small"
                          >
                            <DataUsageIcon />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </DialogContent>
        <DialogoGrafico ref={refDialogoGrafico} />
      </Dialog>
    </div>
  );
});

export default DialogoContadorCedulas;
