import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AlarmIcon from '@material-ui/icons/Alarm';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { PrecoInput, DateInputBonito, IntegerInput } from '../../../Components';
import { PriceInput as PrecoInput2 } from '../../../libNova/inputs/PriceInput';
import {
  getListAllAPI,
  createAPI,
  updateAPI,
  getUserId,
  api,
  getManyAPI,
} from '../../../services';
import {
  formatMoeda,
  POR_UNIDADE,
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  TIPO_UNIDADE_OFERTA_KG,
  TIPO_UNIDADE_OFERTA_UN,
  TIPO_UNIDADE_OFERTA_CAIXA,
  TIPO_UNIDADE_OFERTA_CAIXETA,
  TIPO_UNIDADE_OFERTA_CAIXA_MAE,
  getNivelCaixaQtde,
  getLabelTipoUnidade,
  getQuantidadeCaixariaLabel,
  getTotalCaixaTipoOferta,
  getLabelTipoUnidadeCaixaria,
  getOptionsTipoUnidadeOferta,
  getTipoUnidade,
  getPrecoUnitarioVerdadeiroCaixa2,
  ceil10,
  round10,
} from '../../../utils';
import DialogoPrecificacaoNova from '../DialogoPrecificacaoNovaOfertas';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#00796b',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const TAMANHO_PEQUENO = 'sm';

const DialogoCategoriaTransacao = forwardRef(({ handleSlavar }, ref) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState(-1);
  const [produto, setProduto] = useState(null);
  const [valor, setValor] = useState(0);

  const [carregando, setCarregando] = useState(false);
  const [dadosPrecificacaoAnterior, setDadosPrecificacaoAnterior] = useState(
    null,
  );
  const [quantidadeComprada, setQuantidadeComprada] = useState(0);
  const [
    valorNegociadoComAIndustria,
    setValorNegociadoComAIndustria,
  ] = useState(null);
  const [fornecedor, setFornecedor] = useState(null);
  const [dadosPrecificacao, setDadosPrecificacao] = useState(null);
  const [config, setConfig] = useState(null);
  const [nImpressoes, setNImpressoes] = useState(0);

  const refDialogoPrecificacao = useRef(null);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  async function getProduto(id) {
    const data = await getManyAPI(
      'produtos/search/autocomplete',
      { id: [id], ofertasProdutos: true },
      ['dadosImposto', 'unidade'],
    );
    if (data.data.length > 0) {
      const produto = data.data[0];
      return {
        ...produto,
        preco_oferta: getPrecoUnitarioVerdadeiroCaixa2(
          { ...produto, nivelCaixa: 0 },
          0,
          0,
        ),
      };
    }
    throw 'Este produto não existe!';
  }

  async function reiniciar(produto) {
    try {
      setId(-1);

      const produto2 = await getProduto(produto.id);
      setProduto(produto2);
      setConfig(await getConfig());
      setValor(0);
      //
      const {
        fornecedor,
        dadosPrecificacaoAnterior,
        quantidadeComprada,
        valorNegociadoComAIndustria,
      } = await getDadosPrecificacaoAnterior(produto.id);
      setFornecedor(fornecedor);
      setDadosPrecificacaoAnterior(dadosPrecificacaoAnterior);
      setQuantidadeComprada(quantidadeComprada);
      setValorNegociadoComAIndustria(valorNegociadoComAIndustria);
      setNImpressoes(0);
      //
      setCarregando(false);
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function getConfig() {
    const config = await getListAllAPI('config', ['id', 'asc'], { id: 1 }, [
      'cidade.estado',
    ]);
    if (config.data.length <= 0) {
      throw 'Configurações inacessíveis!';
    }
    return config.data[0];
  }

  async function getDadosPrecificacaoAnterior(produto_id) {
    const dados = await api.get(`/get_ultimo_lote_precificado/${produto_id}`);
    return dados.data;
  }

  async function getDataResource(id, valor, produto_id) {
    try {
      setId(id);
      const produto = await getProduto(produto_id);
      setProduto(produto);
      setConfig(await getConfig());
      setValor(valor);
      //
      const {
        fornecedor,
        dadosPrecificacaoAnterior,
        quantidadeComprada,
        valorNegociadoComAIndustria,
      } = await getDadosPrecificacaoAnterior(produto.id);
      setFornecedor(fornecedor);
      setDadosPrecificacaoAnterior(dadosPrecificacaoAnterior);
      setQuantidadeComprada(quantidadeComprada);
      setValorNegociadoComAIndustria(valorNegociadoComAIndustria);
      setNImpressoes(0);
      //
      setCarregando(false);
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    try {
      if (id <= 0) {
        handleSlavar({
          produto,
          user_id: getUserId(),
          dadosPrecificacao,
          id,
          isEdited: false,
          valor,
          nImpressoes,
        });
        setCarregando(false);
        enqueueSnackbar(`Oferta de Jornal criada com sucesso!`, {
          variant: 'success',
        });
      } else {
        handleSlavar({
          produto,
          user_id: getUserId(),
          dadosPrecificacao,
          id,
          isEdited: true,
          valor,
          nImpressoes,
        });
        setCarregando(false);
        enqueueSnackbar(`Oferta de Jornal atualizada com sucesso!`, {
          variant: 'success',
        });
      }
      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate(produto = null) {
      setCarregando(true);
      reiniciar(produto);
      setOpen(true);
    },
    handleEdit(id, valor, produto_id) {
      setCarregando(true);
      getDataResource(id, valor, produto_id);
      setOpen(true);
    },
  }));

  function validateProduto() {
    let error = '';
    if (!produto) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateValor() {
    let error = '';
    if (valor <= 0 || Number.isNaN(valor)) {
      error = 'Este campo é obrigatório';
    } else if (produto) {
      if (valor >= produto.preco_venda) {
        error =
          'Este valor não pode ser maior ou igual que o preço original de venda!';
      }
    }
    return error;
  }

  function validateNImpressoes() {
    let error = '';
    if (nImpressoes < 0 || Number.isNaN(nImpressoes)) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function getErros() {
    const errosOld = ['', ''];
    errosOld[0] = validateProduto();
    errosOld[1] = validateValor();
    errosOld[2] = validateNImpressoes();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  const nomeProduto = produto ? produto.nome : '';

  function editarPrecificacao() {
    if (refDialogoPrecificacao.current) {
      refDialogoPrecificacao.current.handleOpen();
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth={TAMANHO_PEQUENO}
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={() => handleCloseDialog()}
          style={{ backgroundColor: '#00796b', color: 'white' }}
        >
          {id <= 0 ? `Nova Oferta de Jornal` : `Editar Oferta de Jornal`}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '20px 36px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                {produto && (
                  <>
                    <div
                      style={{
                        marginTop: '20px',
                      }}
                    >
                      <h2
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        {nomeProduto}
                      </h2>
                    </div>
                    <div>
                      <div style={{ marginTop: '15px' }}>
                        <PrecoInput
                          name="precoVenda"
                          handleEnter={() => {}}
                          label="Preço Atual de Venda"
                          handleKey={() => {}}
                          value={
                            Number.isNaN(produto.preco_venda)
                              ? ''
                              : String(produto.preco_venda)
                          }
                          onChange={(value) => {}}
                          fullWidth
                          variant="standard"
                          disabled
                        />
                      </div>
                      <div
                        style={{
                          flex: 1,
                          display: 'flex',
                        }}
                      >
                        <PrecoInput2
                          name="precoOferta"
                          handleEnter={() => {}}
                          label="Preço de Oferta"
                          handleKey={() => {}}
                          value={valor}
                          onChange={(e) => {
                            setValor(e.target.value);
                          }}
                          error={erros[1] !== ''}
                          helperText={erros[1]}
                          fullWidth
                          variant="standard"
                          disabled
                        />
                        <Tooltip
                          title="Editar Preço"
                          placement="bottom"
                          aria-label="add2"
                        >
                          <IconButton
                            aria-label="editar"
                            size="small"
                            onClick={() => editarPrecificacao()}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <IntegerInput
                        name="impressoes"
                        label="Nº Impressões de Etiquetas"
                        handleEnter={() => {}}
                        handleKey={() => {}}
                        value={
                          Number.isNaN(nImpressoes) ? '' : String(nImpressoes)
                        }
                        onChange={(value) => {
                          setNImpressoes(parseInt(value.target.value, 10));
                        }}
                        error={erros[2] !== ''}
                        helperText={erros[2]}
                        fullWidth
                        variant="standard"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={() => handleCloseDialog()}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
            variant="contained"
            color="primary"
          >
            Cancelar
          </Button>
          <Box>
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                disabled={erroExistente || carregando}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
              {carregando && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Box>
        </DialogActions>
        <DialogoPrecificacaoNova
          ref={refDialogoPrecificacao}
          quantidadeComprada={quantidadeComprada}
          produto={produto}
          fornecedor={fornecedor}
          config={config}
          dadosPrecificacaoAnterior={dadosPrecificacaoAnterior}
          valorNegociadoComAIndustria={valorNegociadoComAIndustria}
          handleClose={(dados) => {
            if (dados) {
              setDadosPrecificacao(dados);
              setValor(round10(dados.preco_de_venda, -2));
            }
          }}
        />
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
