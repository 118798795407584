import React from 'react';
import { useRefresh } from 'react-admin';

// import { Button } from '@material-ui/core';
import pin from '@iconify/icons-mdi/pin';
import pinOff from '@iconify/icons-mdi/pin-off';
import { Icon, InlineIcon } from '@iconify/react';
import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ContentCreate from '@material-ui/icons/Create';

import { api } from '../services';

/* const StyledButton = withStyles(theme => ({
    root: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.contrastText,
            color: 'black',
        }
    },
}))(Button); */

const StyledFab = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: 'green',
    '&:hover': {
      backgroundColor: 'red',
      color: 'white',
    },
  },
}))(Fab);

const ChangeStatusButton = ({
  query = '',
  basePath = '',
  label = 'ra.action.edit',
  record,
  icon = defaultIcon,
  resource,
  createquery = (record) => ``,
  disabled = () => false,
  deleteHundle = () => {},
  campoAtivo,
  inverterAtivo,
  ...rest
}) => (
  <>
    <StyledFab
      color="primary"
      size="small"
      aria-label="add"
      onClick={(e) => {
        stopPropagation(e);
        deleteHundle({ resource, ...record });
      }}
      {...rest}
      disabled={disabled(record)}
      style={{
        backgroundColor: record
          ? (record[campoAtivo] && !inverterAtivo) ||
            (!record[campoAtivo] && inverterAtivo)
            ? 'green'
            : 'red'
          : 'red',
      }}
    >
      <Icon
        icon={
          record
            ? (record[campoAtivo] && !inverterAtivo) ||
              (!record[campoAtivo] && inverterAtivo)
              ? pin
              : pinOff
            : pinOff
        }
        style={{ transform: 'rotate(30deg)' }}
      />
    </StyledFab>
  </>
);

const defaultIcon = <ContentCreate />;
const stopPropagation = (e) => e.stopPropagation();

export default ChangeStatusButton;
