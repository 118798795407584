import React, { useState, useCallback } from 'react';
import {
  TopToolbar,
  Toolbar,
  sanitizeListRestProps,
  Button,
  useListContext,
} from 'react-admin';

import { Box, makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { green, pink } from '@material-ui/core/colors';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import AddIcon from '@material-ui/icons/Add';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import BarChartIcon from '@material-ui/icons/BarChart';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import MaterialIcon, { colorPalette } from 'material-icons-react';

import SearchInputMobills from '../Transacoes/Inputs/SearchInputMobills';
import DateMobillsInput from './Inputs/DateMobillsInput2';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    alignItems: 'center',
  },
  small: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: pink[500],
    backgroundColor: pink[500],
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
  transacao: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: '#0039cb',
    backgroundColor: '#0039cb',
  },
  despesa: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: '#f44336',
    backgroundColor: '#f44336',
  },
  receita: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: '#4caf50',
    backgroundColor: '#4caf50',
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: '20px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {},
}))(MenuItem);

const TipoTransacaoSelect = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickItem = (code) => {
    handleClose();
    handleChange(code);
  };

  const handleChange = useCallback(
    (code) => {
      if (setFilters) {
        setFilters({ ...filterValues, tipo: code }, displayedFilters);
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  function getDados() {
    switch (filterValues.tipo) {
      case 'Despesa':
        return {
          color: '#f44336',
          label: 'Categorias de Despesas',
        };
      case 'Receita':
        return {
          color: '#4caf50',
          label: 'categorias de Receitas',
        };
      default:
        return {
          color: '',
          label: '',
        };
    }
  }

  const dados = getDados();

  return (
    <>
      <Fab
        variant="extended"
        style={{ backgroundColor: dados.color, color: 'white' }}
        onClick={handleClick}
      >
        <ExpandMoreIcon style={{ color: 'white' }} />
        {dados.label}
      </Fab>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Zoom}
        transitionDuration={500}
      >
        <StyledMenuItem onClick={() => handleClickItem('Receita')}>
          <ListItemText primary="Categorias de Receitas" />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleClickItem('Despesa')}>
          <ListItemText primary="Categorias de Despesas" />
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  // displayedFilters,
  exporter,
  // filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  enabledCreate,
  handleCriar = () => {},
  ...rest
}) => {
  const classes = useStyles();
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;

  const aplicarFiltros = useCallback(
    (search) => {
      if (setFilters) {
        setFilters({ ...filterValues, search }, displayedFilters);
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  function getDados() {
    switch (filterValues.tipo) {
      case 'Despesa':
        return {
          color: '#f44336',
          label: 'Despesa',
        };
      case 'Receita':
        return {
          color: '#4caf50',
          label: 'Receita',
        };
      default:
        return {
          color: '',
          label: '',
        };
    }
  }

  const dados = getDados();

  return (
    <TopToolbar {...sanitizeListRestProps(rest)} className={classes.root}>
      <Box
        display="flex"
        justifyContent="space-between"
        flex={1}
        alignItems="center"
      >
        <TipoTransacaoSelect />
        <div>
          <DateMobillsInput />
        </div>
        <Box display="flex" alignItems="center">
          {/* <Fab
            // variant="extended"
            // style={{ backgroundColor: dados.color, color: 'white' }}
            // onClick={handleClick}
            style={{
              backgroundColor: 'white',
              color: dados.color,
              fontWeight: 'bold',
            }}
            onClick={(e) => {}}
          >
            <AddIcon
              style={{
                color: dados.color,
                // backgroundColor: '#009FD4',
              }}
            />
          </Fab> */}

          <Box
            boxShadow={2}
            borderRadius="50px"
            display="flex"
            alignItems="center"
            width="44px"
            style={{
              backgroundColor: 'white',
              height: '48px',
              width: '48px',
            }}
          >
            <IconButton
              className={classes.iconButton}
              aria-label="search"
              onClick={() => handleCriar(filterValues.tipo)}
              style={{ height: '48px', width: '48px' }}
            >
              <AddIcon
                style={{
                  color: dados.color,
                  // backgroundColor: '#009FD4',
                }}
              />
            </IconButton>
          </Box>
          <Box marginLeft="15px">
            <SearchInputMobills
              color={dados.color}
              placeholder="Pesquise pelo nome da categoria"
              onChange={(search) => aplicarFiltros(search)}
            />
          </Box>
          <Box marginLeft="15px">
            <Tooltip title="Mais opções" placement="top" aria-label="add2">
              {/* <Fab
                aria-label="add"
                onClick={handleCriar}
                style={{ backgroundColor: 'white', color: '#737477' }}
              >
                <MoreVertIcon />
              </Fab> */}

              <Box
                boxShadow={2}
                borderRadius="50px"
                display="flex"
                alignItems="center"
                width="44px"
                style={{
                  backgroundColor: 'white',
                  height: '48px',
                  width: '48px',
                }}
              >
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  // onClick={handleModoAberto}
                  style={{ height: '48px', width: '48px' }}
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </TopToolbar>
  );
};

export default ListActions;
