import React, { useImperativeHandle, forwardRef, useRef } from 'react';

import { DialogoConfirmacao } from '../../../Components';
import { api } from '../../../services';

const FormDialog = forwardRef(({ handleClose }, ref) => {
  const refDialogoConfirmacao = useRef(null);

  useImperativeHandle(ref, () => ({
    handleOpen(record) {
      const isData = record ? record.status : false;
      if (refDialogoConfirmacao.current) {
        refDialogoConfirmacao.current.handleOpen(
          !isData ? 'Conferir' : 'Desconferir',
          'Tem certeza de que deseja alterar o status de conferência desta sangria?',
          record,
        );
      }
    },
  }));

  async function handleConfirma(id) {
    await api.put(`/malote_sangrias/change_status/${id}`);
    handleClose();
  }

  return (
    <DialogoConfirmacao
      ref={refDialogoConfirmacao}
      handleConfirma={handleConfirma}
    />
  );
});

export default FormDialog;
