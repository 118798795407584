import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const ActionsField = ({
  handleEdit = () => {},
  handleDetalhes = () => {},
  ...rest
}) => {
  const classes = useStyles();
  const record = useRecordContext(rest);
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Tooltip title="Editar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="edit"
          classes={{ root: classes.root }}
          size="small"
          onClick={() => handleEdit(record.record)}
        >
          <EditOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Detalhes" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="edit"
          classes={{ root: classes.root }}
          size="small"
          onClick={() => handleDetalhes(record.record)}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ActionsField;
