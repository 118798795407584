import React from 'react';
import { TextInput, SelectInput } from 'react-admin';

import { Box } from '@material-ui/core';

import choices from './choices';

const Form = ({ isCreate = true }) => (
  <Box p="1em">
    <Box display="flex">
      <Box flex={2} mr="0.5em">
        <TextInput source="nome" label="Descrição" autoFocus fullWidth />
      </Box>
      <Box flex={2} mr="0.5em">
        <SelectInput
          source="tipo"
          choices={choices}
          label="Gerenciamento"
          fullWidth
          disabled={!isCreate}
        />
      </Box>
    </Box>
  </Box>
);

export default Form;
