import { createContext } from 'react';

export const VendaContext = createContext({
  venda: {
    vendaId: -1,
    cliente: null,
    itens: [],
    itensEstoque: [],
    subTotal: 0,
    venda: null,
  },
  dispatch: () => {},
});
