import React from 'react';
import {
  Filter as FilterAdmin,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
} from 'react-admin';

import { IntegerInput, PeriodoInput } from '../../Components/InputsReactAdmin';
import { STATUS_ATIVO } from '../../utils';
import { choicesStatusAndamento, choicesAvaliacao } from './choices';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <TextInput label="Descrição" source="nome" />
    <ReferenceInput
      fullWidth
      label="Template"
      source="atividades_processo_id"
      reference="atividades_processos_limpa"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      allowEmpty={false}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="nome" allowEmpty={false} />
    </ReferenceInput>
    <ReferenceInput
      fullWidth
      label="Colaborador"
      source="colaborador_id"
      reference="colaboradores"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      allowEmpty={false}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="nome" allowEmpty={false} />
    </ReferenceInput>
    <ReferenceInput
      fullWidth
      label="Avaliador"
      source="avaliador_id"
      reference="colaboradores"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      allowEmpty={false}
      filter={{ status: STATUS_ATIVO, is_gestor: true }}
    >
      <AutocompleteInput optionText="nome" allowEmpty={false} />
    </ReferenceInput>
    <ReferenceInput
      fullWidth
      label="Departamento"
      source="departamento_colaborador_id"
      reference="departamentos_colaborador"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      allowEmpty={false}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="nome" allowEmpty={false} />
    </ReferenceInput>
    <IntegerInput source="grau_urgencia" label="Grau de Urgência" />
    <IntegerInput source="grau_importancia" label="Grau de Importância" />
    <PeriodoInput
      source="periodoDataEntrega"
      label="Período"
      defaultValue={{
        start: new Date(),
        end: new Date(),
      }}
    />
    <SelectInput
      source="andamento"
      choices={choicesStatusAndamento}
      label="Status"
      allowEmpty={false}
    />
    <SelectInput
      source="avaliacao"
      choices={choicesAvaliacao}
      label="Avaliação"
      allowEmpty={false}
    />
  </FilterAdmin>
);

export default Filter;
