import React, { forwardRef } from 'react';

import { TextField } from '@material-ui/core';

import NumberFormatInput from '../NumberFormatInput';

const TemplateInput = forwardRef(
  (
    {
      label,
      value,
      onChange,
      fullWidth,
      disabled = false,
      error = false,
      helperText = '',
      handleEnter = () => {},
      formato = null,
      escala = 0,
      sufixo = '',
      prefixo = '',
      handleKey = () => {},
      toString2 = false,
      variant = 'outlined',
      InputProps = {},
      styleInput = {},
      ...rest
    },
    forwardedRef,
  ) => (
    <TextField
      margin="normal"
      {...rest}
      // autoFocus={false}
      label={label}
      type="text"
      // value={String(value)}
      value={value}
      variant={variant}
      fullWidth={fullWidth}
      // color="secondary"
      disabled={disabled}
      /* onChange={(e) => {
        if (!toString) {
          if (escala === 0) {
            e.target.value = parseInt(e.target.value, 10);
          } else {
            e.target.value = parseFloat(e.target.value);
          }
        }
        onChange(e);
      }} */
      onChange={(e) => {
        // if (!toString2) {
        if (false) {
          if (escala === 0) {
            onChange(parseInt(e.target.value, 10));
          } else {
            onChange(parseFloat(e.target.value));
          }
        } else {
          onChange(e);
        }
      }}
      InputProps={{
        inputComponent: NumberFormatInput,
        ...InputProps,
      }}
      inputProps={{
        formato,
        escala,
        sufixo,
        prefixo,
        style: styleInput,
      }}
      error={error}
      helperText={helperText}
      inputRef={forwardedRef}
      onKeyDown={(e) => {
        if (e.keyCode === 13) handleEnter();
        else handleKey(e.keyCode, e.key);
      }}
      // autoComplete={false}
    />
  ),
);

export default TemplateInput;
