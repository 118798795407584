import React, { useContext, useState, useEffect, forwardRef } from 'react';

import { Box } from '@material-ui/core';

import { NumeroNotaFiscalInput, DateInput } from '../../../..';
import useDebounce from '../../../../../hooks/useDebounce';
import { CompraContext } from '../contextCompra';

const timeDebounce = 1000;

const Container = forwardRef(({ handleKey, handleEnter }, ref) => {
  const { compra, dispatch } = useContext(CompraContext);
  const { numeroNotaFiscal, data_faturamento } = compra;
  const [numeroNota, setNumeroNota] = useState(numeroNotaFiscal);
  const [numeroNotaDebounce] = useDebounce(numeroNota, timeDebounce);
  const [data, setData] = useState(data_faturamento);
  const [dataDebounce, setDataDebounce] = useDebounce(data, timeDebounce);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NUMERO_NOTA_FISCAL',
      numeroNotaFiscal: numeroNotaDebounce,
    });
  }, [numeroNotaDebounce]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_DATA_FATURAMENTO',
      data_faturamento: dataDebounce,
    });
  }, [dataDebounce]);

  return (
    <Box
      display="flex"
      alignItems="center"
      style={{
        gap: '10px',
      }}
    >
      <NumeroNotaFiscalInput
        label="Número nota fiscal"
        onChange={(value) => setNumeroNota(value.target.value)}
        value={numeroNota}
        error={false}
        helperText=""
        disabled={false}
        ref={ref}
        handleEnter={handleEnter}
        handleKey={handleKey}
        style={{
          flex: 1,
        }}
        //fullwidth
        //style={{ width: '150px' }}
      />
      {numeroNotaFiscal.length === 9 && (
        <DateInput
          name="data_faturamento"
          handleEnter={() => {}}
          label="Data Faturamento"
          handleKey={() => {}}
          value={data}
          //fullwidth
          // disabled={hasCaixa}
          style={{
            flex: 1,
          }}
          onChange={(e) => {
            setData(e.target.value);
          }}
        />
      )}
    </Box>
  );
});

export default Container;
