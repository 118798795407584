import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import {
  AVALIACAO_FEITO_COM_EXCELENCIA,
  AVALIACAO_MALFEITO,
  AVALIACAO_NAO_REALIZADO,
} from '../../../utils';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '400px',
  },
}));

function getStatusAvaliacao(value) {
  switch (value) {
    case AVALIACAO_FEITO_COM_EXCELENCIA:
      return 'Feito com Excelência';
    case AVALIACAO_MALFEITO:
      return 'Malfeito';
    case AVALIACAO_NAO_REALIZADO:
      return 'Não Realizado';
    default:
      return '-';
  }
}

const DescricaoField = (props) => {
  const classes = useStyles();

  const record = useRecordContext(props);
  const value = record ? getStatusAvaliacao(record.record.avaliacao) : '-';

  return (
    <Tooltip title={value} placement="bottom" aria-label="add2">
      <Typography className={classes.text}>{value}</Typography>
    </Tooltip>
  );
};

export default DescricaoField;
