import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DetailsIcon from '@material-ui/icons/Details';
import DoneIcon from '@material-ui/icons/Done';
import PrintIcon from '@material-ui/icons/Print';
import { useSnackbar } from 'notistack';

import {
  imprimirPacoteMalote,
  imprimirTroco,
} from '../../../services/impressora';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    width: theme.spacing(4),
    height: theme.spacing(4),
    cursor: 'pointer',
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
}));

const ActionsField = ({
  handleEfetivar,
  handleShow,
  handleDeletar,
  handleLixeira,
  //handleEntradaCofre,
  //handleEntradaCheckout,
  //enableActionsTransportador = false,
  ...rest
}) => {
  const record = useRecordContext(rest);
  const statusValue = record
    ? record.record
      ? record.record.visible
      : false
    : false;
  const visibleValue = record
    ? record.record
      ? record.record.visible
      : false
    : false;
  const visibleCheckEntradaCofre = record
    ? record.record
      ? record.record.visible
      : false
    : false;
  const visibleCheckEntradaCheckout = record
    ? record.record
      ? record.record.visible
      : false
    : false;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  async function imprimir(id) {
    if (id > 0) {
      const codigo = await imprimirTroco(id);
      if (codigo < 0) {
        enqueueSnackbar('Erro ao imprimir a nota!', {
          variant: 'error',
        });
      }
    }
  }

  return (
    <div
      style={{
        marginRight: '0px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {/* {statusValue ? (
        <Tooltip title="Ajustar" placement="bottom" aria-label="add2">
          <IconButton
            aria-label="check"
            size="small"
            onClick={() => handleEfetivar(record)}
          >
            {statusValue ? <CheckCircleOutlineIcon /> : <CancelIcon />}
          </IconButton>
        </Tooltip>
      ) : (
        <div />
      )} */}
      {statusValue ? (
        <Tooltip
          title="Converter em sangria"
          placement="bottom"
          aria-label="add2"
        >
          <Box>
            <Avatar
              className={classes.orange}
              onClick={() => handleEfetivar(record)}
            >
              S
            </Avatar>
          </Box>
        </Tooltip>
      ) : null}
      <Tooltip title="Detalhes" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="detalhes"
          size="small"
          onClick={() => handleShow(record)}
        >
          <DetailsIcon />
        </IconButton>
      </Tooltip>
      {true ? (
        <Tooltip title="Imprimir" placement="bottom" aria-label="add2">
          <IconButton
            aria-label="imprimir"
            size="small"
            onClick={() => imprimir(record ? record.record.id : -1)}
          >
            <PrintIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      {statusValue ? (
        <Tooltip title="Excluir" placement="bottom" aria-label="excluir2">
          <IconButton
            aria-label="excluir"
            size="small"
            onClick={() => handleDeletar(record)}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      {visibleValue ? (
        <Tooltip
          title="Mandar para a lixeira"
          placement="bottom"
          aria-label="excluir2"
        >
          <IconButton
            aria-label="excluir"
            size="small"
            onClick={() => handleLixeira(record)}
          >
            <DeleteForeverIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      {/*visibleCheckEntradaCofre ? (
        <Tooltip
          title="Confirmar Recebimento no Cofre"
          placement="bottom"
          aria-label="excluir2"
        >
          <IconButton
            aria-label="excluir"
            size="small"
            onClick={() => handleEntradaCofre(record)}
          >
            <DoneIcon />
          </IconButton>
        </Tooltip>
      ) : null*/}
      {/*visibleCheckEntradaCheckout ? (
        <Tooltip
          title="Confirmar Saída do Cofre"
          placement="bottom"
          aria-label="excluir2"
        >
          <IconButton
            aria-label="excluir"
            size="small"
            onClick={() => handleEntradaCheckout(record)}
          >
            <DoneIcon />
          </IconButton>
        </Tooltip>
      ) : null*/}
    </div>
  );
};



export default ActionsField;
