import {
  TIPO_RECORRENCIA_DIARIA_NORMAL,
  TIPO_RECORRENCIA_NAO_RECORRENCIA,
} from '../../utils/constants';

const choicesTipoRecorrencia = [
  { id: TIPO_RECORRENCIA_NAO_RECORRENCIA, name: 'Sem Recorrência' },
  { id: TIPO_RECORRENCIA_DIARIA_NORMAL, name: 'Recorrência Diária' },
];

export { choicesTipoRecorrencia };
