import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles, Checkbox } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({}));

const StatusField = ({ handleToggleSelection, selectedIds, ...props }) => {
  const classes = useStyles();
  const record = useRecordContext(props);

  const checked = record
    ? record.record
      ? selectedIds.indexOf(record.record.id) >= 0
      : false
    : false;

  return (
    <Checkbox
      color="primary"
      className={`select-item ${classes.checkbox}`}
      checked={checked}
      onClick={() => handleToggleSelection(record.record)}
    />
  );
};

export default StatusField;
