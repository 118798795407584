import React from 'react';

import { parse } from 'query-string';

import CreateTemplate from '../../Components/Create';
import Erros from './Erros';
import Form from './Form';
import validate from './Validation';

const transform = (data) => ({
  ...data,
  data: new Date(),
});

const Create = (props) => {
  const { conta_id: conta_id_string } = parse(props.location.search);
  const conta_id = conta_id_string ? parseInt(conta_id_string, 10) : '';
  // const redirect = produto_id ? `/sessoes/${produto_id}/show/2` : 'show';
  return (
    <CreateTemplate
      {...props}
      title="Nova Correção do saldo da Conta"
      defaultValue={{
        conta_id,
      }}
      transform={transform}
      validate={validate}
      onFailure={Erros}
      isBack
    >
      <Form />
    </CreateTemplate>
  );
};

export default Create;
