import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Typography } from '@material-ui/core';

import { formatMoeda } from '../../../utils';

const ValorField = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  const valor = record ? (record.record ? record.record.valor : 0) : 0;
  return (
    <Typography
      variant="subtitle1"
      style={{
        fontWeight: 'bold',
        color: valor >= 0 ? '#4caf67' : '#f44336',
      }}
    >
      {formatMoeda(valor)}
    </Typography>
  );
};



export default ValorField;
