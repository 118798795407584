import React, { useImperativeHandle, forwardRef, useRef } from 'react';

import { DialogoConfirmacao } from '../../../Components';
import { api, getUserId } from '../../../services';
import { formatMoeda } from '../../../utils';

const FormDialog = forwardRef(({ handleClose }, ref) => {
  const refDialogoConfirmacao = useRef(null);

  useImperativeHandle(ref, () => ({
    handleOpen(record) {
      if (refDialogoConfirmacao.current) {
        refDialogoConfirmacao.current.handleOpen(
          'Fechar Malote',
          `Tem certeza de que deseja fechar este malote, Valor: ${formatMoeda(
            record ? record.valor : 0,
          )}`,
          record,
        );
      }
    },
  }));

  async function handleConfirma(id) {
    await api.put(`/malote_malotes/fechar/${id}`, {
      user_id: getUserId(),
    });
    handleClose();
  }

  return (
    <DialogoConfirmacao
      ref={refDialogoConfirmacao}
      handleConfirma={handleConfirma}
    />
  );
});

export default FormDialog;
