import React, { useEffect, useState, useRef } from 'react';
import { useVersion, Title } from 'react-admin';
import { useSelector } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import AdjustIcon from '@material-ui/icons/Adjust';
import CheckIcon from '@material-ui/icons/Check';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { useSnackbar } from 'notistack';

import { api } from '../../../services';
import {
  TURNO_MANHA,
  TURNO_NOITE,
  TURNO_12_HORAS,
  TIPO_FERIADO_FERIADO,
  formatDateHHmm,
  formatMoeda,
  TIPO_HORARIO_DOMINGO,
} from '../../../utils';
import MobillsInput from '../RelatorioVendaMensal/DateInputMobills';
import DialogoCompensacoesFeriados from './DialogoCompensacoesFeriados';
import DialogoPresencasColaborador from './DialogoPresencaColaborador';
import DialogoRelatorioDia from './DialogoRelatorioDia';
import Filters from './Filters';

const StyledTableCell = withStyles((theme) => ({
  head: {
    //backgroundColor: 'green',
    color: 'black',
    //padding: '8px',
    //whiteSpace: 'nowrap',
    //writingMode: 'sideways-lr',
    //textOrientation: 'sideways',
    //width: 'min-content',
    width: '1px',
    whiteSpace: 'nowrap',
    //textAlign: 'center',
    //display: 'flex',
    //justifyContent: 'center',
    padding: '1px',
    border: '1px solid black',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    padding: '1px',
    border: '1px solid black',
    textAlign: 'center',
    verticalAlign: 'middle',
    //textAlign: 'center',
  },
}))(TableCell);

//const horasMes = 220;
//const porcentagemHoraExtra = 75;

const diasSemana = {
  0: 'domingo',
  1: 'segunda-feira',
  2: 'terça-feira',
  3: 'quarta-feira',
  4: 'quinta-feira',
  5: 'sexta-feira',
  6: 'sábado',
};

const diasSemana2 = {
  0: 'domingo',
  1: 'segunda',
  2: 'terça',
  3: 'quarta',
  4: 'quinta',
  5: 'sexta',
  6: 'sábado',
};

const meses = {
  0: 'janeiro',
  1: 'fevereiro',
  2: 'março',
  3: 'abril',
  4: 'maio',
  5: 'junho',
  6: 'julho',
  7: 'agosto',
  8: 'setembro',
  9: 'outubro',
  10: 'novembro',
  11: 'dezembro',
};

const GL1 = 'GL1';
const GL2 = 'GL2';
const GL3 = 'GL3';
const C1 = 'C1';

function formataNumero(numero) {
  if (numero < 10) return `0${numero}`;
  return `${numero}`;
}

function formatData(data) {
  const data1 = new Date(data);
  const message = `${diasSemana[data1.getDay()]}, ${
    meses[data1.getMonth()]
  } ${formataNumero(data1.getDate())}, ${data1.getFullYear()}`;
  return message;
}

function formatData2(data) {
  const data1 = new Date(data);
  const message = `${formataNumero(data1.getDate())}/${formataNumero(
    data1.getMonth() + 1,
  )}/${data1.getFullYear()}`;
  return message;
}

function formatData3(data) {
  const data1 = new Date(data);
  const message = `${diasSemana2[data1.getDay()]}`;
  return message;
}

function diferencaEmMinutos(data1, data2) {
  if (data1 && data2) {
    const data1Obj = new Date(data1);
    const data2Obj = new Date(data2);

    const time = data2Obj.getTime() - data1Obj.getTime();

    return time / (1000 * 60);
  }
  return 0;
}

function getRelacaoMinValor(min) {
  switch (min) {
    case 1:
      return 0.02;
    case 2:
      return 0.03;
    case 3:
      return 0.05;
    case 4:
      return 0.07;
    case 5:
      return 0.08;
    case 6:
      return 0.1;
    case 7:
      return 0.12;
    case 8:
      return 0.13;
    case 9:
      return 0.15;
    case 10:
      return 0.17;
    case 11:
      return 0.18;
    case 12:
      return 0.2;
    case 13:
      return 0.22;
    case 14:
      return 0.23;
    case 15:
      return 0.25;
    case 16:
      return 0.27;
    case 17:
      return 0.28;
    case 18:
      return 0.3;
    case 19:
      return 0.32;
    case 20:
      return 0.33;
    case 21:
      return 0.35;
    case 22:
      return 0.37;
    case 23:
      return 0.38;
    case 24:
      return 0.4;
    case 25:
      return 0.42;
    case 26:
      return 0.43;
    case 27:
      return 0.45;
    case 28:
      return 0.47;
    case 29:
      return 0.48;
    case 30:
      return 0.5;
    case 31:
      return 0.52;
    case 32:
      return 0.53;
    case 33:
      return 0.55;
    case 34:
      return 0.57;
    case 35:
      return 0.58;
    case 36:
      return 0.6;
    case 37:
      return 0.62;
    case 38:
      return 0.63;
    case 39:
      return 0.65;
    case 40:
      return 0.67;
    case 41:
      return 0.68;
    case 42:
      return 0.7;
    case 43:
      return 0.72;
    case 44:
      return 0.73;
    case 45:
      return 0.75;
    case 46:
      return 0.77;
    case 47:
      return 0.78;
    case 48:
      return 0.8;
    case 49:
      return 0.82;
    case 50:
      return 0.83;
    case 51:
      return 0.85;
    case 52:
      return 0.87;
    case 53:
      return 0.88;
    case 54:
      return 0.9;
    case 55:
      return 0.92;
    case 56:
      return 0.93;
    case 57:
      return 0.95;
    case 58:
      return 0.97;
    case 59:
      return 0.98;
    default:
      return 0;
  }
}

function converterHorasDecimais(min) {
  //return minutos / 60;

  const horas = parseInt(min / 60, 10);
  const minutos = parseInt(min % 60, 10);

  const decimal = getRelacaoMinValor(minutos);

  return horas + decimal;
}

function formataHorasExtras(min) {
  const horas = parseInt(min / 60, 10);
  const minutos = parseInt(min % 60, 10);
  if (horas === 0) {
    if (minutos === 0) {
      return '-';
    }
    return `${minutos}min`;
  }
  if (minutos === 0) {
    return `${horas}h`;
  }
  return `${horas}h ${minutos}min`;
}

function getHorasIdeal(
  turno,
  isSS = false,
  isAbrirLoja = true,
  isDomingo = false,
) {
  switch (turno) {
    case TURNO_MANHA: {
      if (!isDomingo) {
        if (!isSS) return 484;
        return 440;
      }
      return 360;
    }
    case TURNO_NOITE: {
      if (!isDomingo) {
        if (!isSS) return 484;
        return 440;
      }
      return 360;
    }
    case TURNO_12_HORAS: {
      if (!isDomingo) {
        if (isAbrirLoja) {
          return 600;
        }
        return 660;
      }
      return 540;
    }
    default:
      return 0;
  }
}

function procuraGerentoes(codinome) {
  let isGl1 = false;
  let isGl2 = false;
  let isGl3 = false;
  let isC1 = false;

  switch (codinome) {
    case GL1:
      isGl1 = true;
      break;
    case GL2:
      isGl2 = true;
      break;
    case GL3:
      isGl3 = true;
      break;
    case C1:
      isC1 = true;
      break;
  }

  if (isGl1) return GL1;
  if (isGl2) return GL2;
  if (isGl3) return GL3;
  if (isC1) return C1;
  return null;
}

const useStyles = makeStyles((theme) => ({
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
  },
}));

function formatPorcentagem(valor) {
  return valor !== null && valor !== undefined
    ? `${valor.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} %`
    : '';
}

function formatarResumo(dados, data) {
  if (dados) {
    const {
      dias,
      pontos,
      colaboradores,
      grupos,
      funcoes,
      codinomes,
      empresas,
      folhaParticulares,
      folhaGeral,
      configs,
      compensacoesTotais,
      diasTotais,
      compensacoes2,
      compensacoesPendentes,
    } = dados;
    const dadosFormatados = [];
    const diasFormatados = {};
    const diasTotaisFormatados = {};
    const codinomesFormatados = {};
    //const tolerancia = 10;

    const config = configs[0];

    const folhaGeralValue = folhaGeral
      ? folhaGeral.length > 0
        ? folhaGeral[0]
        : null
      : null;

    const tolerancia = folhaGeralValue
      ? folhaGeralValue.tolerancia_batida_ponto
      : config.tolerancia_hora_extra;
    const horasMes = folhaGeralValue
      ? folhaGeralValue.horas_trabalho_mes
      : config.horas_trabalho_mes;
    const porcentagemHoraExtra = folhaGeralValue
      ? folhaGeralValue.porcentagem_hora_extra
      : config.taxa_adicional_hora_extra;
    const valorDomingoTrabalhado = folhaGeralValue
      ? folhaGeralValue.valor_domingo_trabalhado
      : config.valor_domingo_trabalhado;
    const valorFeriadoTrabalhado = folhaGeralValue
      ? folhaGeralValue.valor_feriado_trabalhado
      : config.valor_feriado_trabalhado;

    for (let i = 0; i < diasTotais.length; i++) {
      const dia = diasTotais[i];
      diasTotaisFormatados[dia.id] = {
        descricao: formatData(dia.data),
        descricao2: formatData2(dia.data),
        descricao3: formatData3(dia.data),
        ...dia,
      };
    }

    for (let i = 0; i < dias.length; i++) {
      const dia = dias[i];
      diasFormatados[dia.id] = {
        descricao: formatData(dia.data),
        descricao2: formatData2(dia.data),
        descricao3: formatData3(dia.data),
        ...dia,
      };
      dias[i].descricao = formatData(dia.data);
      dias[i].descricao2 = formatData2(dia.data);
      dias[i].descricao3 = formatData3(dia.data);
    }

    const compensacoesFormatados = {};
    for (let i = 0; i < compensacoes2.length; i++) {
      const compensacao = compensacoes2[i];
      if (compensacoesFormatados[compensacao.colaborador_id]) {
        /*if (
          compensacoesFormatados[compensacao.colaborador_id][
            compensacao.dados_dia_pagamento_folga_extra_id
          ]
        ) {*/
        const diaFeriado = diasTotaisFormatados[compensacao.dados_dia_id];
        compensacoesFormatados[compensacao.colaborador_id][
          compensacao.dados_dia_pagamento_folga_extra_id
        ] = { ...compensacao, diaFeriado };
        //}
      } else {
        compensacoesFormatados[compensacao.colaborador_id] = {};
        /*if (
          compensacoesFormatados[compensacao.colaborador_id][
            compensacao.dados_dia_pagamento_folga_extra_id
          ]
        ) {*/
        const diaFeriado = diasTotaisFormatados[compensacao.dados_dia_id];
        compensacoesFormatados[compensacao.colaborador_id][
          compensacao.dados_dia_pagamento_folga_extra_id
        ] = { ...compensacao, diaFeriado };
        //}
      }
    }

    const compensacoesPendentesFormatados = {};

    for (let i = 0; i < compensacoesPendentes.length; i++) {
      const compensacao = compensacoesPendentes[i];
      if (compensacoesPendentesFormatados[compensacao.colaborador_id]) {
        /*if (
          compensacoesPendentesFormatados[compensacao.colaborador_id][
            compensacao.dados_dia_id
          ]
        ) {*/
        const dia = diasTotaisFormatados[compensacao.dados_dia_id];
        compensacoesPendentesFormatados[compensacao.colaborador_id][
          compensacao.dados_dia_id
        ] = { ...compensacao, dia };
        //}
      } else {
        compensacoesPendentesFormatados[compensacao.colaborador_id] = {};
        /*if (
          compensacoesPendentesFormatados[compensacao.colaborador_id][
            compensacao.dados_dia_id
          ]
        ) {*/
        const dia = diasTotaisFormatados[compensacao.dados_dia_id];
        compensacoesPendentesFormatados[compensacao.colaborador_id][
          compensacao.dados_dia_id
        ] = { ...compensacao, dia };
        //}
      }
    }

    const folhaParticularesFormatados = {};
    for (let i = 0; i < folhaParticulares.length; i++) {
      const folha = folhaParticulares[i];
      folhaParticularesFormatados[folha.colaborador_id] = folha;
    }

    const funcoesFormatados = {};
    for (let i = 0; i < funcoes.length; i++) {
      const funcao = funcoes[i];
      funcoesFormatados[funcao.id] = funcao;
    }

    const gruposFormatados = {};
    for (let i = 0; i < grupos.length; i++) {
      const grupo = grupos[i];
      gruposFormatados[grupo.id] = grupo;
    }

    const empresasFormatados = {};
    for (let i = 0; i < empresas.length; i++) {
      const empresa = empresas[i];
      empresasFormatados[empresa.id] = empresa;
    }

    for (let i = 0; i < codinomes.length; i++) {
      const codinome = codinomes[i];
      codinomesFormatados[codinome.id] = codinome;
    }

    let totalValorColaboradoresHorasExtras = 0;
    let totalValorColaboradoresHorasExtrasFinais = 0;

    for (let i = 0; i < colaboradores.length; i++) {
      const colaborador = colaboradores[i];
      const folha = folhaParticularesFormatados[colaborador.id];

      const grupo =
        gruposFormatados[
          folha ? folha.grupo_colaborador_id : colaborador.grupo_colaborador_id
        ];
      const funcao =
        funcoesFormatados[
          folha
            ? folha.funcao_colaborador_id
            : colaborador.funcao_colaborador_id
        ];
      const empresa =
        empresasFormatados[folha ? folha.empresa_id : colaborador.empresa_id];

      const turno = folha ? folha.turno : colaborador.turno;

      const pontosColaborador = pontos
        .filter((item) => item.colaborador_id === colaborador.id)
        .map((item) => {
          let compensacao = null;

          if (compensacoesFormatados[colaborador.id]) {
            if (compensacoesFormatados[colaborador.id][item.dados_dia_id]) {
              compensacao =
                compensacoesFormatados[colaborador.id][item.dados_dia_id];
            }
          }

          const is_folga = item.is_folga ? true : compensacao ? true : false;
          const is_atestado = item.is_atestado;
          const is_faltou = item.is_faltou;
          const is_ferias = item.is_ferias;
          const is_declaracao = item.is_declaracao;
          const minutos_declaracao = item.minutos_declaracao;

          const grupo = gruposFormatados[item.grupo_colaborador_id];

          let horasTrabalhadasTurno1 = 0;
          if (!is_folga && !is_faltou && !is_ferias) {
            horasTrabalhadasTurno1 = diferencaEmMinutos(
              item.horario_entrada_1,
              item.horario_saida_1,
            );
          }

          let horasTrabalhadasTurno2 = 0;
          if (!is_folga && !is_faltou && !is_ferias) {
            horasTrabalhadasTurno2 = diferencaEmMinutos(
              item.horario_entrada_2,
              item.horario_saida_2,
            );
          }

          const horasTrabalhadasTotais =
            horasTrabalhadasTurno1 + horasTrabalhadasTurno2;

          const dadosDia = diasFormatados[item.dados_dia_id];

          const isDomingo =
            dadosDia.dia_semana === 0
              ? true
              : dadosDia.tipo_horario === TIPO_HORARIO_DOMINGO;

          const isSS = grupo ? grupo.nome.trim() === 'SS' : false;

          //const codinome = codinomesFormatados[colaborador.codinome_colaborador_id];

          const codinome = codinomesFormatados[item.codinome_colaborador_id];

          const gerentaoAbreLoja = procuraGerentoes(
            codinome ? codinome.nome : '',
          );

          const isAbrirLoja =
            gerentaoAbreLoja === null ? false : codinome === gerentaoAbreLoja;

          let horasIdeal = 0;

          if (!is_folga && !is_ferias) {
            horasIdeal = getHorasIdeal(
              item.turno,
              isSS,
              isAbrirLoja,
              isDomingo,
            );
          }

          let horasExtras = 0;
          let horasExtrasComTolerancia = 0;

          if (!is_atestado) {
            horasExtras = horasTrabalhadasTotais - horasIdeal;
            horasExtrasComTolerancia =
              horasIdeal - tolerancia <= horasTrabalhadasTotais &&
              horasIdeal + tolerancia >= horasTrabalhadasTotais
                ? 0
                : horasExtras;
          }

          //////////////////

          let horarioEntrada1 = '-';

          if (is_folga || is_ferias) {
            horarioEntrada1 = '';
          } else {
            horarioEntrada1 = item.horario_entrada_1
              ? formatDateHHmm(item.horario_entrada_1)
              : '-';
          }

          //////////////////

          let horarioSaida1 = '-';

          if (is_folga || is_ferias) {
            horarioSaida1 = '';
          } else {
            horarioSaida1 = item.horario_saida_1
              ? formatDateHHmm(item.horario_saida_1)
              : '-';
          }

          //////////////////

          let horarioEntrada2 = '-';

          if (is_folga || is_ferias) {
            horarioEntrada2 = '';
          } else {
            horarioEntrada2 = item.horario_entrada_2
              ? formatDateHHmm(item.horario_entrada_2)
              : '-';
          }

          //////////////////

          let horarioSaida2 = '-';

          if (is_folga || is_ferias) {
            horarioSaida2 = '';
          } else {
            horarioSaida2 = item.horario_saida_2
              ? formatDateHHmm(item.horario_saida_2)
              : '-';
          }

          //////////////////

          return {
            id: item.id,
            status: item.is_verificado,
            horarioEntrada1,
            horarioSaida1,
            horarioEntrada2,
            horarioSaida2,
            horasTrabalhadasTurno1: horasTrabalhadasTurno1
              ? formataHorasExtras(horasTrabalhadasTurno1)
              : '-',
            horasTrabalhadasTurno2: horasTrabalhadasTurno2
              ? formataHorasExtras(horasTrabalhadasTurno2)
              : '-',
            horasTrabalhadasTotais: horasTrabalhadasTotais
              ? formataHorasExtras(horasTrabalhadasTotais)
              : '-',
            horasIdeal,
            horasExtras: horasExtras ? formataHorasExtras(horasExtras) : '-',
            horasExtrasComTolerancia: horasExtrasComTolerancia
              ? formataHorasExtras(horasExtrasComTolerancia)
              : '-',
            dados_dia_id: item.dados_dia_id,
            tipoJornada: grupo.nome.trim() === 'SS' ? 'SS' : 'ESCALADO',
            horasTrabalhadasTotaisValor: horasTrabalhadasTotais,
            horasExtrasSemToleranciaValor: horasExtras,
            horasExtrasComToleranciaValor: horasExtrasComTolerancia,
            turno: item.turno,
            is_folga,
            is_faltou,
            is_atestado,
            compensacao,
            is_ferias,
            //is_declaracao,
            //minutos_declaracao,
          };
        });

      const pontosFormatados = {};
      for (let i = 0; i < pontosColaborador.length; i++) {
        const ponto = pontosColaborador[i];
        pontosFormatados[ponto.dados_dia_id] = ponto;
      }

      const diasColaborador = [];
      let folgas = 0;
      let domingosTrabalhado = 0;
      let feriadosTrabalhados = 0;
      let totalHorasTrabalhadas = 0;
      let totalHorasIdeal = 0;
      let totalHorasExtras = 0;
      let totalHorasExtrasSemTolerancia = 0;
      let nDiasTrabalhados = 0;
      let atestados = 0;

      for (let i = 0; i < dias.length; i++) {
        const ponto = pontosFormatados[dias[i].id];

        const compensacao = ponto ? ponto.compensacao : null;

        if (ponto) {
          if (!ponto.is_ferias) {
            if (ponto.is_folga) {
              folgas++;
            } else {
              if (
                dias[i].dia_semana === 0 &&
                !ponto.is_faltou &&
                !ponto.is_folga
              ) {
                domingosTrabalhado++;
              }

              if (
                dias[i].tipo === TIPO_FERIADO_FERIADO &&
                !ponto.is_faltou &&
                !ponto.is_folga
              ) {
                feriadosTrabalhados++;
              }

              //if (ponto.is_faltou) {
              if (ponto.is_atestado) {
                atestados++;
              }
              //}

              totalHorasTrabalhadas += ponto.horasTrabalhadasTotaisValor;
              totalHorasExtras += ponto.horasExtrasComToleranciaValor;
              totalHorasIdeal += ponto.horasIdeal;
              totalHorasExtrasSemTolerancia +=
                ponto.horasExtrasSemToleranciaValor;

              if (!ponto.is_faltou) {
                nDiasTrabalhados++;
              }
            }
          }
        }

        diasColaborador.push({
          ...dias[i],
          ponto,
          compensacao,
        });
      }

      const salarioMensal = folha ? folha.salario_base : funcao.salario_base;
      const valorHoraNormal = salarioMensal / horasMes;
      const valorHoraExtra = valorHoraNormal * (porcentagemHoraExtra / 100 + 1);

      const horasExtras =
        converterHorasDecimais(totalHorasExtras) * valorHoraExtra;
      const horasExtrasFinais = horasExtras > 0 ? horasExtras : 0;

      totalValorColaboradoresHorasExtras += horasExtras;
      totalValorColaboradoresHorasExtrasFinais += horasExtrasFinais;

      let compensacoes2 = [];

      if (compensacoesPendentesFormatados[colaborador.id]) {
        compensacoes2 = Object.values(
          compensacoesPendentesFormatados[colaborador.id],
        );
      }

      const totalValorDomingos = domingosTrabalhado * valorDomingoTrabalhado;
      const totalValorFeriados = feriadosTrabalhados * valorFeriadoTrabalhado;

      dadosFormatados.push({
        status: folha ? (folha.status ? true : false) : false,
        //status: colaborador.id % 2 === 0,
        colaborador: colaborador.nome,
        colaborador_id: colaborador.id,
        dias: diasColaborador,
        tipoJornada: grupo.nome.trim() === 'SS' ? 'SS' : 'ESCALADO',
        nDiasTrabalhados,
        salarioMensal: formatMoeda(salarioMensal),
        valorHoraNormal: formatMoeda(valorHoraNormal),
        valorHoraExtra: formatMoeda(valorHoraExtra),
        totalHorasTrabalhadas: totalHorasTrabalhadas
          ? formataHorasExtras(totalHorasTrabalhadas)
          : '-',
        totalHorasExtras: totalHorasExtras
          ? formataHorasExtras(totalHorasExtras)
          : '-',
        totalHorasExtrasSemTolerancia: totalHorasExtrasSemTolerancia
          ? formataHorasExtras(totalHorasExtrasSemTolerancia)
          : '-',
        horasExtras: horasExtras ? formatMoeda(horasExtras) : '-',
        horasExtrasFinais: horasExtrasFinais
          ? formatMoeda(horasExtrasFinais)
          : '-',
        domingosTrabalhado,
        feriadosTrabalhados,
        folgas,
        atestados,
        totalHorasIdeal: totalHorasIdeal
          ? formataHorasExtras(totalHorasIdeal)
          : '-',
        mes: data ? data.mes : -1,
        ano: data ? data.ano : -1,
        funcao: funcao ? funcao.nome : '-',
        dataEntrada: colaborador.data_entrada,
        turno,
        //empresa: empresa ? empresa.nome : '-',
        empresa,
        grupo: grupo ? grupo.nome : '-',
        compensacoes: compensacoes2,
        folha,
        totalValorDomingos: formatMoeda(totalValorDomingos),
        totalValorFeriados: formatMoeda(totalValorFeriados),
      });
    }

    const dados4 = dadosFormatados;

    /*if (filters.colaboradorSearch) {
      dados4 = dadosFormatados.filter((item) =>
        item.colaborador
          .toLowerCase()
          .includes(filters.colaboradorSearch.toLowerCase()),
      );
    }

    if (filters.status === true || filters.status === false) {
      dados4 = dadosFormatados.filter((item) => item.status === filters.status);
    }*/

    return {
      colaboradores: dados4,
      folhaGeral: folhaGeral
        ? folhaGeral.length > 0
          ? folhaGeral[0]
          : null
        : null,
      tolerancia,
      horasMes,
      porcentagemHoraExtra,
      valorDomingoTrabalhado,
      valorFeriadoTrabalhado,
    };
  }
  return null;
}

function HorasExtras() {
  const version = useVersion();
  const [data, setData] = useState({
    mes: new Date().getMonth(),
    ano: new Date().getFullYear(),
  });
  const [dadosOriginais, setDadosOriginais] = useState(null);
  const [dados, setDados] = useState(null);
  const refDialogoPresencaColaborador = useRef(null);
  const refDialogoCompensacoesFeriados = useRef(null);

  const classes = useStyles();
  const [filters, setFilters] = useState({});
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const refDialogoRelatorioDia = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  function handleData({ ano, mes }) {
    setData({
      mes,
      ano,
    });
  }

  async function getDadosResumo(mes, ano) {
    const dados = await api.get(`/resumo_pontos_do_mes/${mes}/${ano}`);
    return dados;
  }

  async function setDadosBrutos({ mes, ano }) {
    const dados = await getDadosResumo(mes, ano);
    setDadosOriginais(dados.data);
  }

  useEffect(() => {
    setDadosBrutos(data);
  }, [version, data]);

  function abrirDialogo(index) {
    if (refDialogoPresencaColaborador.current) {
      refDialogoPresencaColaborador.current.handleOpen(index);
    }
  }

  function updatePonto(dados2, dia, conpensacao) {
    const {
      id,
      horario_entrada_1,
      horario_saida_1,
      horario_entrada_2,
      horario_saida_2,
      dados_dia_id,
      colaborador_id,
      is_verificado,
    } = dados2;

    if (dados) {
      const { pontos, compensacoesPendentes, compensacoes2 } = dadosOriginais;

      const pontoIndex = pontos.findIndex((item) => item.id === id);

      const compensacoesPendentesCopy = [...compensacoesPendentes];

      const compensacoes2Copy = [...compensacoes2];
      const pontosCopy = [...pontos];

      if (pontoIndex >= 0) {
        pontosCopy[pontoIndex] = {
          ...pontosCopy[pontoIndex],
          horario_entrada_1,
          horario_saida_1,
          horario_entrada_2,
          horario_saida_2,
          is_verificado,
        };
      }

      if (conpensacao) {
        if (dia) {
          compensacoesPendentesCopy.push(conpensacao);
        } else {
          const compensacaoIndex = compensacoes2.findIndex(
            (item) => item.id === conpensacao.id,
          );
          if (compensacaoIndex >= 0) {
            compensacoes2Copy.splice(compensacaoIndex, 1);
          }
        }
      }

      setDadosOriginais({
        ...dadosOriginais,
        pontos: pontosCopy,
        compensacoesPendentes: compensacoesPendentesCopy,
        compensacoes2: compensacoes2Copy,
      });
    }
  }

  function updatePonto2(dados2, dia, conpensacao) {
    const {
      id,
      turno,
      grupo_colaborador_id,
      codinome_colaborador_id,
      is_folga,
      is_atestado,
      is_faltou,
      is_verificado,
      is_declaracao,
      minutos_declaracao,
      is_ferias,
    } = dados2;

    if (dados) {
      const { pontos, compensacoesPendentes, compensacoes2 } = dadosOriginais;

      const pontoIndex = pontos.findIndex((item) => item.id === id);

      const compensacoesPendentesCopy = [...compensacoesPendentes];

      const compensacoes2Copy = [...compensacoes2];
      const pontosCopy = [...pontos];

      if (pontoIndex >= 0) {
        pontosCopy[pontoIndex] = {
          ...pontosCopy[pontoIndex],
          turno,
          grupo_colaborador_id,
          codinome_colaborador_id,
          is_folga,
          is_atestado,
          is_faltou,
          is_verificado,
          is_declaracao,
          minutos_declaracao,
          is_ferias,
        };

        if (conpensacao) {
          if (dia) {
            compensacoesPendentesCopy.push(conpensacao);
          } else {
            const compensacaoIndex = compensacoes2.findIndex(
              (item) => item.id === conpensacao.id,
            );
            if (compensacaoIndex >= 0) {
              compensacoes2Copy.splice(compensacaoIndex, 1);
            }
          }
        }

        setDadosOriginais({
          ...dadosOriginais,
          pontos: pontosCopy,
          compensacoesPendentes: compensacoesPendentesCopy,
          compensacoes2: compensacoes2Copy,
        });
      }
    }
  }

  useEffect(() => {
    setDados(formatarResumo(dadosOriginais, data));
  }, [dadosOriginais]);

  function aplicaFiltros(dados, filters) {
    if (dados) {
      let dados4 = dados.colaboradores;
      if (filters.colaboradorSearch) {
        dados4 = dados4.filter((item) =>
          item.colaborador
            .toLowerCase()
            .includes(filters.colaboradorSearch.toLowerCase()),
        );
      }

      if (filters.status === true || filters.status === false) {
        dados4 = dados4.filter((item) => item.status === filters.status);
      }

      return {
        ...dados,
        colaboradores: dados4,
      };
    }
    return null;
  }

  const dadosFormatados = aplicaFiltros(dados, filters);

  const nDias = dadosOriginais
    ? dadosOriginais.dias
      ? dadosOriginais.dias.length
      : 0
    : 0;

  function handleEfetivarColaborador(colaborador_id, mes, ano, dados) {
    if (dados) {
      const { folhaParticulares } = dadosOriginais;

      const folhaParticularIndex = folhaParticulares.findIndex(
        (item) => item.colaborador_id === colaborador_id,
      );

      if (folhaParticularIndex >= 0) {
        const folhaParticularesCopy = [...folhaParticulares];

        if (dados) {
          folhaParticularesCopy[folhaParticularIndex] = {
            ...folhaParticularesCopy[folhaParticularIndex],
            status: dados.status,
          };
        } else {
          folhaParticularesCopy.splice(folhaParticularIndex, 1);
        }

        setDadosOriginais({
          ...dadosOriginais,
          folhaParticulares: folhaParticularesCopy,
        });
      } else if (dados) {
        const folhaParticularesCopy = [...folhaParticulares];

        folhaParticularesCopy.push({
          id: dados.id,
          colaborador_id: dados.colaborador_id,
          status: dados.status,

          turno: dados.turno,
          codinome_colaborador_id: dados.codinome_colaborador_id,
          grupo_colaborador_id: dados.grupo_colaborador_id,
          equipe_colaborador_id: dados.equipe_colaborador_id,
          funcao_colaborador_id: dados.funcao_colaborador_id,
          empresa_id: dados.empresa_id,
        });

        setDadosOriginais({
          ...dadosOriginais,
          folhaParticulares: folhaParticularesCopy,
        });
      }
    }
  }

  function abrirDialogoCompensacoesFeriados(index) {
    if (refDialogoCompensacoesFeriados.current) {
      refDialogoCompensacoesFeriados.current.handleOpen(index);
    }
  }

  function updateCompensacoes(dados2) {
    const {
      id,
      colaborador_id,
      dados_dia_id,
      data,
      presenca,
      dados_dia_pagamento_folga_extra_id,
      data_folga_extra,
      dia,
    } = dados2;

    if (dados) {
      const {
        compensacoesPendentes,
        diasTotais,
        compensacoes2,
      } = dadosOriginais;

      const compensacaoIndex = compensacoesPendentes.findIndex(
        (item) => item.id === id,
      );

      if (compensacaoIndex >= 0) {
        const compensacoesCopy = [...compensacoesPendentes];
        const diasTotaisCopy = [...diasTotais];
        const compensacoes2Copy = [...compensacoes2];
        diasTotaisCopy.push(dia);

        compensacoes2Copy.push({
          id,
          colaborador_id,
          dados_dia_id,
          data,
          presenca,
          dados_dia_pagamento_folga_extra_id,
          data_folga_extra,
        });

        compensacoesCopy[compensacaoIndex] = {
          ...compensacoesCopy[compensacaoIndex],
          presenca,
          dados_dia_pagamento_folga_extra_id,
          data_folga_extra,
        };

        setDadosOriginais({
          ...dadosOriginais,
          compensacoesPendentes: compensacoesCopy,
          diasTotais: diasTotaisCopy,
          compensacoes2: compensacoes2Copy,
        });
      }
    }
  }

  async function handleEfetivarGeral() {
    const response = await api.post(
      `fechar-folha-pagamentos-geral/${data.mes}/${data.ano}`,
    );

    const data2 = response.data;

    if (data2.status) {
      const { folhaGeral } = dadosOriginais;

      if (folhaGeral.length > 0) {
        const folhaGeralCopy = [...folhaGeral];

        folhaGeralCopy[0] = {
          ...folhaGeralCopy[0],
          id: data2.data.id,
          status: data2.data.status,
          tolerancia_batida_ponto: data2.data.tolerancia_batida_ponto,
          horas_trabalho_mes: data2.data.horas_trabalho_mes,
          porcentagem_hora_extra: data2.data.porcentagem_hora_extra,
        };

        setDadosOriginais({
          ...dadosOriginais,
          folhaGeral: folhaGeralCopy,
        });
      } else if (dados) {
        const folhaGeralCopy = [];

        folhaGeralCopy.push({
          id: data2.data.id,
          status: data2.data.status,
          tolerancia_batida_ponto: data2.data.tolerancia_batida_ponto,
          horas_trabalho_mes: data2.data.horas_trabalho_mes,
          porcentagem_hora_extra: data2.data.porcentagem_hora_extra,
        });

        setDadosOriginais({
          ...dadosOriginais,
          folhaGeral: folhaGeralCopy,
        });
      }
      enqueueSnackbar(data2.message, {
        variant: 'success',
      });
    } else {
      enqueueSnackbar(data2.message, {
        variant: 'error',
      });
    }
  }

  // N. C. : NOME DO COLABORADOR
  // T. J. : TIPO DE JORNADA
  // N. D. T. : Nº DE DIAS TRABALHADOS
  // C. H. I. : CARGA HORÁRIA IDEAL
  // S. M. C. :  SALÁRIO MENSAL NA CARTEIRA
  //R$. H. N. : R$/HORA NORMAL
  // R$. H. E. : R$/HORA EXTRA
  // T. H. T. : TOTAL DE HORAS TRABALHADAS
  // T. H. E. : TOTAL DE HORAS EXTRAS
  // H. E. : HORAS EXTRAS
  // H. E. F. :  HORAS EXTRAS FINAIS
  // D. T. :  DOMINGO TRABALHADO
  // F. T. :  FERIADO TRABALHADO
  // AT. :  ATESTADO
  // FOL. :  FOLGA

  const tolerancia = dadosFormatados ? dadosFormatados.tolerancia : 0;
  const horasMes = dadosFormatados ? dadosFormatados.horasMes : 0;
  const porcentagemHoraExtra = dadosFormatados
    ? dadosFormatados.porcentagemHoraExtra
    : 0;
  const valorDomingoTrabalhado = dadosFormatados
    ? dadosFormatados.valorDomingoTrabalhado
    : 0;
  const valorFeriadoTrabalhado = dadosFormatados
    ? dadosFormatados.valorFeriadoTrabalhado
    : 0;

  const statusFolha = dadosFormatados
    ? dadosFormatados.folhaGeral
      ? dadosFormatados.folhaGeral.status
      : false
    : false;

  function handleCompensacoes() {}

  function handleRelatorioPresencasDia() {
    if (refDialogoRelatorioDia.current) {
      refDialogoRelatorioDia.current.handleOpen();
    }
  }

  return (
    <>
      <div>
        <div
          style={{
            fontSize: '25px',
            fontWeight: 'bold',
            marginTop: '35px',
            marginBottom: '30px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h1
            style={{
              fontSize: '25px',
            }}
          >
            HORAS EXTRAS
          </h1>
          <Filters
            handleFilters={(filters) => {
              setFilters(filters);
            }}
            handleEfetivar={handleEfetivarGeral}
            folha={dadosFormatados ? dadosFormatados.folhaGeral : null}
            handleCompensacoes={handleCompensacoes}
            handleRelatorioPresencasDia={handleRelatorioPresencasDia}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '20px',
            }}
          >
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              Tolerância Horas Extras:
            </span>
            <span>{`${tolerancia} min`}</span>
          </div>
          <div
            style={{
              display: 'flex',
              gap: '20px',
            }}
          >
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              Horas de Trabalho no Mês:
            </span>
            <span>{`${horasMes} h`}</span>
          </div>
          <div
            style={{
              display: 'flex',
              gap: '20px',
            }}
          >
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              Porcentagem Hora Extra:
            </span>
            <span>{`${formatPorcentagem(porcentagemHoraExtra)}`}</span>
          </div>
          <div
            style={{
              display: 'flex',
              gap: '20px',
            }}
          >
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              Valor Domingo:
            </span>
            <span>{`${formatMoeda(valorDomingoTrabalhado)}`}</span>
          </div>
          <div
            style={{
              display: 'flex',
              gap: '20px',
            }}
          >
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              Valor Feriado:
            </span>
            <span>{`${formatMoeda(valorFeriadoTrabalhado)}`}</span>
          </div>
        </div>
        <Title title="HORAS EXTRAS" />
        <div
          style={{
            margin: '10px 0 20px',
          }}
        >
          <MobillsInput handleData={handleData} />
        </div>
        <div
          style={
            {
              //overflowX: 'scroll',
              //width: open ? 'calc(100vw - 290px)' : 'calc(100vw - 100px)',
              //height: '50vh',
              //border: '5px solid black',
            }
          }
        >
          {dadosFormatados ? (
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    <span
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      STATUS
                    </span>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    <Tooltip title="NOME DO COLABORADOR" placement="top-end">
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        N. C.
                      </span>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    <Tooltip title="TIPO DE JORNADA" placement="top-end">
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        T. J.
                      </span>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    <Tooltip title="Nº DE DIAS TRABALHADOS" placement="top-end">
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        N. D. T.
                      </span>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    <Tooltip title="CARGA HORÁRIA IDEAL" placement="top-end">
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        C. H. I.
                      </span>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    <Tooltip
                      title="SALÁRIO MENSAL NA CARTEIRA"
                      placement="top-end"
                    >
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        S. M. C.
                      </span>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    <Tooltip title="R$/HORA NORMAL" placement="top-end">
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        R$. H. N.
                      </span>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    <Tooltip title="R$/HORA EXTRA" placement="top-end">
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        R$. H. E.
                      </span>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    <Tooltip
                      title="TOTAL DE HORAS TRABALHADAS"
                      placement="top-end"
                    >
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        T. H. T.
                      </span>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    <Tooltip title="TOTAL DE HORAS EXTRAS" placement="top-end">
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        T. H. E.
                      </span>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    <Tooltip title="HORAS EXTRAS" placement="top-end">
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        H. E.
                      </span>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    <Tooltip title="HORAS EXTRAS FINAIS" placement="top-end">
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        H. E. F.
                      </span>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    <Tooltip title="DOMINGO TRABALHADO" placement="top-end">
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        D. T.
                      </span>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    <Tooltip title="FERIADO TRABALHADO" placement="top-end">
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        F. T.
                      </span>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    <Tooltip title="ATESTADO" placement="top-end">
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        AT.
                      </span>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    <Tooltip title="FOLGA" placement="top-end">
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        FOL.
                      </span>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    <span
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Ações
                    </span>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dadosFormatados.colaboradores.map((colaborador, index) => (
                  <TableRow>
                    <StyledTableCell align="center">
                      {colaborador.status ? (
                        <Avatar className={classes.confirmado}>
                          <CheckIcon style={{ fontSize: 15 }} />
                        </Avatar>
                      ) : (
                        <Avatar className={classes.pendente}>
                          <PriorityHighIcon style={{ fontSize: 15 }} />
                        </Avatar>
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        textAlign: 'start',
                      }}
                    >
                      {colaborador.colaborador}
                    </StyledTableCell>
                    <StyledTableCell>{colaborador.tipoJornada}</StyledTableCell>
                    <StyledTableCell>
                      {colaborador.nDiasTrabalhados}
                    </StyledTableCell>
                    <StyledTableCell>
                      {colaborador.totalHorasIdeal}
                    </StyledTableCell>
                    <StyledTableCell>
                      {colaborador.salarioMensal}
                    </StyledTableCell>
                    <StyledTableCell>
                      {colaborador.valorHoraNormal}
                    </StyledTableCell>
                    <StyledTableCell>
                      {colaborador.valorHoraExtra}
                    </StyledTableCell>
                    <StyledTableCell>
                      {colaborador.totalHorasTrabalhadas}
                    </StyledTableCell>
                    <StyledTableCell>
                      {colaborador.totalHorasExtras}
                    </StyledTableCell>
                    <StyledTableCell>{colaborador.horasExtras}</StyledTableCell>
                    <StyledTableCell>
                      {colaborador.horasExtrasFinais}
                    </StyledTableCell>
                    <StyledTableCell>
                      {colaborador.domingosTrabalhado}
                    </StyledTableCell>
                    <StyledTableCell>
                      {colaborador.feriadosTrabalhados}
                    </StyledTableCell>
                    <StyledTableCell>{colaborador.atestado}</StyledTableCell>
                    <StyledTableCell>{colaborador.folgas}</StyledTableCell>
                    <StyledTableCell>
                      <Tooltip title="Horas Extras">
                        <IconButton
                          aria-label="detalhes"
                          onClick={() => abrirDialogo(index)}
                        >
                          <DataUsageIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Compensações de Feriados">
                        <IconButton
                          aria-label="detalhes"
                          onClick={() =>
                            abrirDialogoCompensacoesFeriados(index)
                          }
                        >
                          <AdjustIcon />
                        </IconButton>
                      </Tooltip>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <div>Buscando os Dados...</div>
          )}
        </div>
        <DialogoPresencasColaborador
          data={data}
          ref={refDialogoPresencaColaborador}
          dados={dadosFormatados ? dadosFormatados.colaboradores : []}
          updatePonto={updatePonto}
          nDias={nDias}
          updatePonto2={updatePonto2}
          handleEfetivarColaborador={handleEfetivarColaborador}
          folhaGeral={dadosFormatados ? dadosFormatados.folhaGeral : null}
          statusFolha={statusFolha}
        />
        <DialogoCompensacoesFeriados
          ref={refDialogoCompensacoesFeriados}
          dados={dadosFormatados ? dadosFormatados.colaboradores : []}
          updateCompensacoes={updateCompensacoes}
        />
        <DialogoRelatorioDia
          ref={refDialogoRelatorioDia}
          mes={data ? data.mes : 0}
          ano={data ? data.ano : 0}
          dados={dadosFormatados ? dadosFormatados.colaboradores : []}
        />
      </div>
    </>
  );
}

export default HorasExtras;
