import React, { forwardRef } from 'react';

import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles({
  listbox: {
    borderRadius: '25px',
    maxHeight: '300px',
    overflowY: 'auto',
  },
  paper: {
    borderRadius: '25px',
  },
});

const AutoCompleteNormal = forwardRef(
  (
    {
      label,
      campo = 'nome',
      options,
      value,
      onChangeLocal,
      handleKey,
      fullWidth = true,
      autoFocus = false,
      variant = 'outlined',
      handleEnter = () => {},
      renderTag,
      renderOption,
      disableClearable,
      startAdornment = <></>,
      endAdornment = <></>,
      disabled = false,
      filterSelectedOptions = true,
      limitTags = 2,
      // open,
      ...rest
    },
    forwardedRef,
  ) => {
    const classes = useStyles();
    const [inputValue, setInputValue] = React.useState('');

    return (
      <Autocomplete
        disabled={disabled}
        disableClearable={disableClearable}
        classes={{ listbox: classes.listbox, paper: classes.paper }}
        options={options}
        multiple
        limitTags={limitTags}
        disableCloseOnSelect={false}
        filterSelectedOptions={filterSelectedOptions}
        noOptionsText="Nenhum resultado"
        getOptionSelected={(option, value) => option[campo] === value[campo]}
        getOptionLabel={(option) => option[campo]}
        value={value}
        onChange={(event, newValue) => {
          onChangeLocal(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderOption={renderOption}
        renderTags={(value, getTagProps) =>
          value.map((option, index) =>
            renderTag(option, getTagProps({ index }), index),
          )
        }
        fullWidth={fullWidth}
        autoFocus={autoFocus}
        renderInput={(params) => {
          params.InputProps.startAdornment = (
            <>
              {startAdornment}
              {params.InputProps.startAdornment}
            </>
          );
          params.InputProps.endAdornment = (
            <>
              {endAdornment}
              {params.InputProps.endAdornment}
            </>
          );
          return (
            <TextField
              margin="normal"
              {...rest}
              {...params}
              label={label}
              variant={variant}
              fullWidth
              inputRef={forwardedRef}
              onKeyDown={(e) => {
                if (e.keyCode === 13) handleEnter();
                else handleKey(e.keyCode, e.key);
              }}
              autoFocus={autoFocus}
            />
          );
        }}
      />
    );
  },
);

export default AutoCompleteNormal;
