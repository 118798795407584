import React, {
  useReducer,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { ThemeProvider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useSnackbar } from 'notistack';

import imagem from '../../../assets/background.jpg';
import { getListAllAPI, getListAPI } from '../../../services';
import { formatItens, CLIENTE_DEFAULT } from '../../../utils';
import theme from '../../DialogoEditarVenda/themes/default';
import InicioFrenteCaixaVenda from './Pages/CreateVenda/_SubPages/Itens';
import { VendaContext } from './Pages/CreateVenda/context';
import reducerVenda from './Pages/CreateVenda/reducer';

const DialogoCreateVenda = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [venda, dispatch] = useReducer(reducerVenda, {
    cliente: null,
    itens: [],
    idEdit: -1,
    isEditPrice: false,
  });

  async function reiniciar() {
    const dataClientes = await getListAllAPI(
      'clientes',
      ['id', 'asc'],
      { nome: CLIENTE_DEFAULT },
      ['cidade.estado', 'categoria'],
    );

    dispatch({
      type: 'PREENCHER_TUDO',
      idEdit: -1,
      itens: [],
      cliente: dataClientes.data[0],
      isEditPrice: false,
    });
  }

  function resetar() {
    dispatch({ type: 'RESETAR_TUDO' });
  }

  const handleCloseDialog = () => {
    setOpen(false);
    resetar();
    handleClose();
  };

  function irParaTelaInit() {
    handleCloseDialog();
  }

  async function getDataVenda(idVenda) {
    try {
      const data = await getListAPI(
        'vendas',
        ['id', 'asc'],
        [1, 1],
        { id: [idVenda] },
        ['itens.produto.unidade', 'cliente.cidade.estado', 'cliente.categoria'],
      );
      if (data.data.length <= 0) {
        throw 'Esta devolução não existe!';
      }
      dispatch({
        type: 'PREENCHER_TUDO',
        idEdit: data.data[0].id,
        itens: formatItens(data.data[0].itens),
        cliente: data.data[0].cliente,
        isEditPrice: false,
      });
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      irParaTelaInit();
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      reiniciar();
      setOpen(true);
    },
    handleEdit(idVenda) {
      getDataVenda(idVenda);
      setOpen(true);
    },
  }));

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          fullScreen
          style={{
            padding: '0',
            margin: '0',
          }}
        >
          <DialogContent
            style={{
              padding: '0',
              margin: '0',
              backgroundImage: `url(${imagem})`,
              backgroundSize: 'cover',
            }}
            dividers
          >
            <VendaContext.Provider
              value={{ venda, dispatch, openMenu: () => {} }}
            >
              <InicioFrenteCaixaVenda irParaTelaInit={irParaTelaInit} />
            </VendaContext.Provider>
          </DialogContent>
        </Dialog>
      </div>
    </ThemeProvider>
  );
});

export default DialogoCreateVenda;
