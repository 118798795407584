import React from 'react';

import CreateTemplate from '../../Components/Create';
import Erros from './Erros';
import Form from './Form';
import validate from './Validation';

function transform({ nome, ...data }) {
  return {
    nome,
  };
}

const Create = (props) => (
  <CreateTemplate
    {...props}
    title="Novo Freezer"
    validate={validate}
    onFailure={Erros}
    transform={transform}
    isBack
  >
    <Form />
  </CreateTemplate>
);

export default Create;
