import React from 'react';
import { TextField, EditButton, ReferenceField } from 'react-admin';

import ListTemplate from '../../Components/List';
import Filter from './Filter';

const List = (props) => (
  <ListTemplate {...props} filters={<Filter />} title="Freezers" isDelete>
    <TextField source="nome" label="Descrição" />
    <EditButton />
  </ListTemplate>
);

export default List;
