import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ListIcon from '@material-ui/icons/List';

const useStyles = makeStyles((theme) => ({
  root: {
    /* width: theme.spacing(3),
    height: theme.spacing(3), */
  },
}));

const ActionsField = ({
  tipo,
  handleEdit = () => {},
  handleDelete = () => {},
  handleDescription = () => {},
  handleCotacao = () => {},
  handleListCotacoes = () => {},
  ativo,
  ...rest
}) => {
  const classes = useStyles();
  const record = useRecordContext(rest);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Tooltip title="Editar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="edit"
          classes={{ root: classes.root }}
          size="small"
          onClick={() => handleEdit(record.record)}
        >
          <EditOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Listagem Cotações" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="edit"
          classes={{ root: classes.root }}
          size="small"
          onClick={() => handleListCotacoes(record.record)}
        >
          <ListIcon />
        </IconButton>
      </Tooltip>
      {/*<Tooltip title="Criar Cotação" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="edit"
          classes={{ root: classes.root }}
          size="small"
          onClick={() => handleCotacao(record.record)}
        >
          <AddBoxIcon />
        </IconButton>
    </Tooltip>*/}
      {/*<Tooltip title="Deletar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="delete"
          classes={{ root: classes.root }}
          size="small"
          onClick={() => handleDelete(record.record)}
          disabled={gerenciado}
        >
          <DeleteOutlineIcon />
        </IconButton>
    </Tooltip>*/}
    </div>
  );
};



export default ActionsField;
