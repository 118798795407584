import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from 'react';

import { useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import AddIcon from '@material-ui/icons/Add';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TodayIcon from '@material-ui/icons/Today';
import Rating from '@material-ui/lab/Rating';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  TextInput,
  IntegerInput,
  AutoCompleteRemoto,
  DateInput,
  CpfInput,
  TelefoneInput,
  CnpjInput,
  CepInput,
  IEInput,
  PrecoInput,
  DateInputBonito,
  SelectInput,
  SwitchInput,
} from '../../../Components';
import { createAPI, updateAPI, getListAllAPI } from '../../../services';
import {
  formatCNPJ,
  formatCPF,
  formatCEP,
  formatIE,
  STATUS_ATIVO,
  TURNO_MANHA,
  TURNO_NOITE,
  TURNO_12_HORAS,
} from '../../../utils';
import DialogoCreateEditFilho from '../DialogoCreateEditFilho';
import DialogoDeletarFilho from '../DialogoDeletarFilho';
import LocaisInput from '../LocaisInput';
import DialogoCreateEditConta from './DialogoCreateEditConta';
import DialogoCreateEditPix from './DialogoCreateEditPix';
import DialogoDeletarConta from './DialogoDeletarConta';
import DialogoDeletarPix from './DialogoDeletarPix';
import { getLabelTipo, getValorFormatado } from './utils';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    //borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_ID = -1;

function validateEmail2(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const optionsTurno = [
  {
    label: 'Manhã',
    value: TURNO_MANHA,
  },
  {
    label: 'Noite',
    value: TURNO_NOITE,
  },
  {
    label: '12H',
    value: TURNO_12_HORAS,
  },
];

function getLabelFolga(grupo) {
  if (grupo) {
    if (!grupo.equipe_colaborador_id) return 'SS (folga no domingo)';
    switch (grupo.dia_da_folga) {
      case 0:
        return `Escalado (folga no domingo)`;
      case 1:
        return `Escalado (folga na segunda-feira)`;
      case 2:
        return `Escalado (folga na terça-feira)`;
      case 3:
        return `Escalado (folga na quarta-feira)`;
      case 4:
        return `Escalado (folga na quinta-feira)`;
      case 5:
        return `Escalado (folga na sexta-feira)`;
      case 6:
        return `Escalado (folga no sábado)`;
      default:
        return '';
    }
  }
  return '';
}

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [nome, setNome] = useState('');
  const [funcao, setFuncao] = useState(null);
  const [locais, setLocais] = useState([]);
  const [locaisOptions, setLocaisOptions] = useState([]);
  const [fardamentos, setFardamentos] = useState([]);
  const [fardamentosOptions, setFardamentosOptions] = useState([]);
  const [qualidades, setQualidades] = useState([]);
  const [qualidadesOptions, setQualidadesOptions] = useState([]);
  const [defeitos, setDefeitos] = useState([]);
  const [defeitosOptions, setDefeitosOptions] = useState([]);
  //
  const [cpf, setCpf] = useState('');
  const [telefone, setTelefone] = useState('');
  const [bairro, setBairro] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [email, setEmail] = useState('');
  const [cep, setCep] = useState('');
  const [cidade, setCidade] = useState(null);
  //
  const [descontoPalestra, setDescontoPalestra] = useState(0);
  const [descontoPlanoSaude, setDescontoPlanoSaude] = useState(0);
  const [descontoValeTransporte, setDescontoValeTransporte] = useState(0);
  const [descontoPlanoOdontologico, setDescontoPlanoOdontologico] = useState(0);
  const [horasExtras, setHorasExtras] = useState(0);
  //
  const [contaCorrente, setContaCorrente] = useState('');
  const [agencia, setAgencia] = useState('');
  //
  const [dataEntrada, setDataEntrada] = useState(moment().format('YYYY-MM-DD'));
  //
  const [filhos, setFilhos] = useState([]);

  const [user, setUser] = useState(null);

  const [isGestor, setIsGestor] = useState(false);

  const [id, setId] = useState(DEFAULT_ID);

  const [userId, setUserId] = useState(0);

  const [carregando, setCarregando] = useState(false);

  const refDialogoFilho = useRef(null);

  const refDialogoDeletarFilho = useRef(null);

  const classes = useStyles({ cor: '#009FD4' });

  const [value, setValue] = React.useState(0);

  const [contas, setContas] = useState([]);
  const [dadosPix, setDadosPix] = useState([]);

  //////

  const [turno, setTurno] = useState(TURNO_MANHA);
  const [grupo, setGrupo] = useState(null);
  const [codinome, setCodinome] = useState(null);

  const [funcaoOriginalId, setFuncaoOriginalId] = useState(null);

  const [isFuncionarioInterno, setIsFuncionarioInterno] = useState(true);

  //////

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [cargo, setCargo] = useState(null);
  const [dataAniversario, setDataAniversario] = useState(null);
  const [isResponsavel, setIsResponsavel] = useState(false);

  const [empresa, setEmpresa] = useState(null);

  const [empresaOriginalId, setEmpresaOriginalId] = useState(null);

  const [codinomeOriginalId, setCodinomeOriginalId] = useState(null);

  /////

  const refDialogoContas = useRef(null);
  const refDialogoDeletarConta = useRef(null);

  const refDialogoPix = useRef(null);
  const refDialogoDeletarPix = useRef(null);

  const theme = useTheme();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  function getTipo(type) {
    switch (type) {
      case 'conta_corrente':
        return 'Conta Corrente';
      case 'conta_poupanca':
        return 'Conta Poupança';
      case 'conta_pagamento':
        return 'Conta Pagamento';
      default:
        return '';
    }
  }

  function formataDadaosFardamentos(dados) {
    return dados.map(({ id, nome, tamanho }) => ({
      id,
      nome: `${nome}${tamanho ? ` ${tamanho.nome}` : ''}`,
    }));
  }

  const fabs = [
    {
      key: 'bancos',
      value: 1,
      onClick: criarConta,
    },
    {
      key: 'pix',
      value: 3,
      onClick: criarPix,
    },
  ];

  useEffect(() => {
    async function getDadosLocais() {
      try {
        const data = await getListAllAPI(
          'locais_setores',
          ['id', 'asc'],
          { status: STATUS_ATIVO },
          [],
        );
        const data2 = await getListAllAPI(
          'fardamentos',
          ['id', 'asc'],
          { status: STATUS_ATIVO },
          ['tamanho'],
        );
        const data3 = await getListAllAPI(
          'qualidades_colaborador',
          ['id', 'asc'],
          { status: STATUS_ATIVO },
          [],
        );
        const data4 = await getListAllAPI(
          'defeitos_colaborador',
          ['id', 'asc'],
          { status: STATUS_ATIVO },
          [],
        );
        setLocaisOptions(data.data);
        setFardamentosOptions(data2.data);
        setQualidadesOptions(data3.data);
        setDefeitosOptions(data4.data);
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }
    if (open) getDadosLocais();
  }, [open]);

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function reiniciar() {
    setId(DEFAULT_ID);
    setNome('');
    setFuncao(null);
    setCarregando(false);
    setLocais([]);
    setFardamentos([]);
    setQualidades([]);
    setDefeitos([]);
    setCpf('');
    setTelefone('');
    setBairro('');
    setLogradouro('');
    setNumero('');
    setEmail('');
    setCep('');
    setCidade(null);
    setDescontoPalestra(0);
    setDescontoPlanoSaude(0);
    setDescontoValeTransporte(0);
    setDescontoPlanoOdontologico(0);
    setHorasExtras(0);
    setContaCorrente('');
    setAgencia('');
    setFilhos([]);
    setUserId(0);
    setUser(null);
    setDataEntrada(moment().format('YYYY-MM-DD'));
    setContas([]);
    setDadosPix([]);
    setGrupo(null);
    setTurno(TURNO_MANHA);
    setCodinome(null);
    setFuncaoOriginalId(null);
    setIsFuncionarioInterno(true);
    setIsGestor(false);
    setValue(0);
    setUsername('');
    setPassword('');
    setCargo(null);
    setDataAniversario(null);
    setIsResponsavel(false);
    setEmpresa(null);
    setEmpresaOriginalId(null);
    setCodinomeOriginalId(null);
    /* const dataSetores = await getListAllAPI(
        'locais_setores',
        ['id', 'asc'],
        {},
        [],
      );
  
      setLocaisOptions(filtrarLocais(dataSetores.data, -1)); */
  }

  /* function filtrarLocais(locais, colaborador_id) {
      const novoLocais = [];
      for (let i = 0; i < locais.length; i += 1) {
        if (
          locais.colaborador_id === colaborador_id ||
          locais.colaborador_id === null
        ) {
          novoLocais.push(novoLocais);
        }
      }
      return novoLocais;
    } */

  function adaptarFilhosRetornoApi(filhos) {
    const newFilhos = filhos.map((item) => ({
      nome: item.nome,
      data: item.aniversario,
    }));
    return newFilhos;
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'colaboradores',
        ['id', 'asc'],
        { id: [id] },
        [
          'funcao',
          'locais',
          'fardamentos.tamanho',
          'qualidades',
          'defeitos',
          'cidade.estado',
          'filhos',
          'user',
          'dadosBancarios.banco',
          'dadosPix',
          'grupoColaborador',
          'codinome',
          'user.cargo',
          'empresa',
        ],
      );

      /* const dataSetores = await getListAllAPI(
          'locais_setores',
          ['id', 'asc'],
          {},
          [],
        ); */

      /* setLocaisOptions(filtrarLocais(dataSetores.data, id)); */

      if (data.data.length > 0) {
        setId(data.data[0].id);
        setNome(data.data[0].nome);
        setFuncao(data.data[0].funcao);
        setFuncaoOriginalId(
          data.data[0].funcao ? data.data[0].funcao.id : null,
        );
        setLocais(data.data[0].locais);
        setFardamentos(data.data[0].fardamentos);
        setDefeitos(data.data[0].defeitos);
        setQualidades(data.data[0].qualidades);
        setCpf(data.data[0].cpf);
        setTelefone(data.data[0].telefone);
        setBairro(data.data[0].bairro);
        setLogradouro(data.data[0].logradouro);
        setNumero(data.data[0].numero);
        setEmail(data.data[0].email);
        setCep(data.data[0].cep);
        setCidade(data.data[0].cidade);
        setDescontoPalestra(data.data[0].desconto_da_palestra);
        setDescontoPlanoSaude(data.data[0].desconto_do_plano_de_saude);
        setDescontoValeTransporte(data.data[0].desconto_do_vale_transporte);
        setDescontoPlanoOdontologico(
          data.data[0].desconto_do_plano_odontologico,
        );
        setHorasExtras(data.data[0].hora_extra);
        setContaCorrente(data.data[0].conta);
        setAgencia(data.data[0].agencia);
        setFilhos(recalcularIds(adaptarFilhosRetornoApi(data.data[0].filhos)));
        setUserId(data.data[0].user_id ? data.data[0].user_id : 0);
        setUser(data.data[0].user ? data.data[0].user : null);
        setDataEntrada(moment(data.data[0].data_entrada).format('YYYY-MM-DD'));
        setContas(data.data[0].dadosBancarios);
        setDadosPix(data.data[0].dadosPix);
        setGrupo(data.data[0].grupoColaborador);
        setTurno(data.data[0].turno);
        setCodinome(data.data[0].codinome);
        setIsFuncionarioInterno(data.data[0].is_funcionario_interno);
        setIsGestor(!!data.data[0].is_gestor);
        setValue(0);
        //
        setUsername(data.data[0].user ? data.data[0].user.username : '');
        setPassword('');
        setCargo(data.data[0].user ? data.data[0].user.cargo : null);
        setDataAniversario(null);
        setIsResponsavel(
          data.data[0].user ? data.data[0].user.is_responsavel : false,
        );
        setEmpresa(data.data[0].empresa);
        setEmpresaOriginalId(
          data.data[0].empresa ? data.data[0].empresa.id : null,
        );
        setCodinomeOriginalId(
          data.data[0].codinome ? data.data[0].codinome.id : null,
        );
        //
        setCarregando(false);
      } else {
        throw 'Este colaborador não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  function getIdsOptions(array) {
    const ids = [];
    for (let i = 0; i < array.length; i += 1) {
      ids.push(array[i].id);
    }
    return ids;
  }

  function formatDados(dados) {
    return dados.map(({ banco, ...rest }) => ({
      ...rest,
      banco_fornecedor_id: banco.id,
    }));
  }

  async function handleSalvar() {
    setCarregando(true);
    try {
      if (id <= 0) {
        const dados = await createAPI('colaboradores', {
          nome,
          funcao_colaborador_id: funcao.id,
          locais: getIdsOptions(locais),
          fardamentos: getIdsOptions(fardamentos),
          qualidades: getIdsOptions(qualidades),
          defeitos: getIdsOptions(defeitos),
          cpf,
          telefone,
          bairro,
          logradouro,
          numero,
          email,
          cep,
          cidade_id: cidade ? cidade.id : null,
          desconto_da_palestra: descontoPalestra,
          desconto_do_plano_de_saude: descontoPlanoSaude,
          desconto_do_vale_transporte: descontoValeTransporte,
          desconto_do_plano_odontologico: descontoPlanoOdontologico,
          hora_extra: horasExtras,
          conta: contaCorrente,
          agencia,
          filhos,
          user_id: null,
          data_entrada: buildData(dataEntrada),
          contas: formatDados(contas),
          dadosPix,
          turno,
          grupo_colaborador_id: grupo ? grupo.id : null,
          codinome_colaborador_id: codinome ? codinome.id : null,
          is_funcionario_interno: isFuncionarioInterno,
          is_gestor: isGestor,
          //
          dia_aniversario: 18,
          mes_aniversario: 11,
          ano_aniversario: 1995,
          username,
          password,
          funcao: 0,
          cargo_id: cargo ? cargo.id : null,
          is_responsavel: isResponsavel,
          empresa_id: empresa ? empresa.id : null,
        });
        setCarregando(false);
        if (dados.data.status) {
          enqueueSnackbar('Colaborador criado com sucesso!', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(dados.data.message, {
            variant: 'error',
          });
        }
      } else {
        const dados = await updateAPI('colaboradores', id, {
          nome,
          funcao_colaborador_id: funcao.id,
          locais: getIdsOptions(locais),
          fardamentos: getIdsOptions(fardamentos),
          qualidades: getIdsOptions(qualidades),
          defeitos: getIdsOptions(defeitos),
          cpf,
          telefone,
          bairro,
          logradouro,
          numero,
          email,
          cep,
          cidade_id: cidade ? cidade.id : null,
          desconto_da_palestra: descontoPalestra,
          desconto_do_plano_de_saude: descontoPlanoSaude,
          desconto_do_vale_transporte: descontoValeTransporte,
          desconto_do_plano_odontologico: descontoPlanoOdontologico,
          hora_extra: horasExtras,
          conta: contaCorrente,
          agencia,
          filhos,
          user_id: user ? user.id : null,
          data_entrada: buildData(dataEntrada),
          contas: formatDados(contas),
          dadosPix,
          turno,
          grupo_colaborador_id: grupo ? grupo.id : null,
          codinome_colaborador_id: codinome ? codinome.id : null,
          is_funcionario_interno: isFuncionarioInterno,
          is_gestor: isGestor,
          //
          dia_aniversario: 18,
          mes_aniversario: 11,
          ano_aniversario: 1995,
          username,
          password: password ? password : null,
          funcao: 0,
          cargo_id: cargo ? cargo.id : null,
          is_responsavel: isResponsavel,
          empresa_id: empresa ? empresa.id : null,
        });
        setCarregando(false);
        if (dados.data.status) {
          enqueueSnackbar('Colaborador atualizado com sucesso!', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(dados.data.message, {
            variant: 'error',
          });
        }
      }

      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      reiniciar();
      setOpen(true);
    },
    handleEdit(id) {
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
    },
  }));

  function validateNome() {
    let error = '';
    if (!nome) error = 'Este campo é obrigatório';
    else if (nome.length > 80) {
      error = 'Número de caracteres maior que o permitido (máximo 80)';
    }
    return error;
  }
  function validateContaCorrente() {
    let error = '';
    /*if (!contaCorrente) error = 'Este campo é obrigatório';
    else if (contaCorrente.length > 80) {
      error = 'Número de caracteres maior que o permitido (máximo 80)';
    }*/
    if (contaCorrente) {
      if (contaCorrente.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
    }
    return error;
  }

  function validateAgencia() {
    let error = '';
    /*if (!agencia) error = 'Este campo é obrigatório';
    else if (agencia.length > 80) {
      error = 'Número de caracteres maior que o permitido (máximo 80)';
    }*/
    if (agencia) {
      if (agencia.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
    }
    return error;
  }

  function validateHorasExtras() {
    let error = '';
    if (horasExtras < 0 || Number.isNaN(horasExtras)) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateDescontoPalestra() {
    let error = '';
    if (descontoPalestra < 0 || Number.isNaN(descontoPalestra)) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateDescontoPlanoSaude() {
    let error = '';
    if (descontoPlanoSaude < 0 || Number.isNaN(descontoPlanoSaude)) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateDescontoValeTransporte() {
    let error = '';
    if (descontoValeTransporte < 0 || Number.isNaN(descontoValeTransporte)) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateDescontoPlanoOdontologico() {
    let error = '';
    if (
      descontoPlanoOdontologico < 0 ||
      Number.isNaN(descontoPlanoOdontologico)
    ) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateFuncao() {
    let error = '';
    if (!funcao) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateCpf() {
    let error = '';
    if (cpf) {
      if (cpf.length < 11) error = 'O CPF deve ter 11 dígitos';
    }
    return error;
  }

  function validateTelefone() {
    let error = '';
    if (telefone) {
      if (telefone.length < 10) {
        error = 'O Telefone deve ter 10 ou 11 dígitos';
      }
    }
    return error;
  }

  function validateBairro() {
    let error = '';
    if (bairro) {
      if (bairro.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
    }
    return error;
  }

  function validateLogradouro() {
    let error = '';
    if (logradouro) {
      if (logradouro.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
    }
    return error;
  }

  function validateNumero() {
    let error = '';
    if (numero) {
      if (numero.length > 6) {
        error = 'Máximo de 6 letras';
      }
    }
    return error;
  }

  function validateEmail() {
    let error = '';
    if (email) {
      if (!validateEmail2(email)) error = 'Email Inválido';
      if (email.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
    }
    return error;
  }

  function validateCep() {
    let error = '';
    if (cep) {
      if (cep.length < 8) error = 'O CEP deve ter 8 dígitos';
    }
    return error;
  }

  function validateCidade() {
    const error = '';
    return error;
  }

  function validateDataEntrada() {
    let error = '';
    if (dataEntrada === null || dataEntrada === '') error = 'Data Inválida!';
    return error;
  }

  function validateUser() {
    const error = '';
    /*if (id > 0 && userId === 0) {
      if (!user) {
        error = 'Campo Obrigatório!';
      }
    }*/
    return error;
  }

  ////////////////

  function validateUsername() {
    let error = '';
    if (username.length < 5)
      error = 'O Username deve ser maior que 5 caracteres';
    if (username.length > 80)
      error = 'O Username deve ser menor que 80 caracteres';
    return error;
  }

  function validatePassword() {
    let error = '';
    if (id <= 0) {
      if (password.length < 3)
        error = 'A senha deve ser maior que 3 caracteres';
      if (password.length > 30)
        error = 'A senha deve ser menor que 50 caracteres';
    } else if (password) {
      if (password.length < 3)
        error = 'A senha deve ser maior que 3 caracteres';
      if (password.length > 30)
        error = 'A senha deve ser menor que 50 caracteres';
    }
    return error;
  }

  function validateCargo() {
    let error = '';
    if (!cargo) {
      error = 'Campo Obrigatório!';
    }
    return error;
  }

  function validateGrupo() {
    let error = '';
    if (!grupo) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateEmpresa() {
    let error = '';
    if (!empresa) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateCodinome() {
    let error = '';
    if (!codinome) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  ////////////////

  function buildData(data) {
    const ano = parseInt(data.substring(0, 4), 10);
    const mes = parseInt(data.substring(5, 7), 10) - 1;
    const dia = parseInt(data.substring(8, 10), 10);

    const dataFormatada = new Date();
    dataFormatada.setFullYear(ano);
    dataFormatada.setDate(dia);
    dataFormatada.setMonth(mes);
    dataFormatada.setDate(dia);

    return dataFormatada;
  }

  function getErros() {
    const errosOld = ['', '', ''];
    errosOld[0] = validateNome();
    errosOld[1] = validateFuncao();
    errosOld[2] = validateCpf();
    errosOld[3] = validateTelefone();
    errosOld[4] = validateBairro();
    errosOld[5] = validateLogradouro();
    errosOld[6] = validateNumero();
    errosOld[7] = validateEmail();
    errosOld[8] = validateCep();
    errosOld[9] = validateCidade();
    errosOld[10] = validateDescontoPalestra();
    errosOld[11] = validateDescontoPlanoSaude();
    errosOld[12] = validateDescontoValeTransporte();
    errosOld[13] = validateDescontoPlanoOdontologico();
    errosOld[14] = validateHorasExtras();
    errosOld[15] = validateContaCorrente();
    errosOld[16] = validateAgencia();
    errosOld[17] = validateDataEntrada();
    errosOld[18] = validateUser();
    //
    errosOld[19] = validateUsername();
    errosOld[20] = validatePassword();
    errosOld[21] = validateCargo();

    errosOld[22] = validateGrupo();
    errosOld[23] = validateEmpresa();
    errosOld[24] = validateCodinome();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  function completaZeros(value) {
    if (value < 10) {
      return `0${value}`;
    }
    return `${value}`;
  }

  function calculaIdade(dataInitial) {
    const data = new Date(dataInitial);
    const time = new Date().getTime() - data.getTime();
    const idade = parseInt(time / (365 * 24 * 60 * 60 * 1000), 10);
    const dataNascimentoFormatada = `${completaZeros(
      data.getDate(),
    )}/${completaZeros(data.getMonth() + 1)}/${data.getFullYear()}`;
    return {
      idade,
      dataNascimentoFormatada,
    };
  }

  function criarFilho() {
    if (refDialogoFilho.current) {
      refDialogoFilho.current.handleCreate();
    }
  }

  function editarFilho(id, nome, data) {
    if (refDialogoFilho.current) {
      refDialogoFilho.current.handleEdit(id, nome, data);
    }
  }

  function handleSave(id, nome, data) {
    const newArray = filhos.slice().map((item) => {
      if (item.id === id) {
        return {
          id,
          nome,
          data,
        };
      }
      return item;
    });
    if (id < 0) {
      newArray.push({
        id: filhos.length + 1,
        nome,
        data,
      });
    }
    setFilhos(recalcularIds(newArray));
  }

  function recalcularIds(arrayOld) {
    const newArray = [];
    for (let i = 0; i < arrayOld.length; i++) {
      newArray.push({
        ...arrayOld[i],
        //nome: arrayOld[i].nome,
        //data: arrayOld[i].data,
        id: i + 1,
      });
    }
    return newArray;
  }

  function deletarFilho(id, nome, data) {
    if (refDialogoDeletarFilho.current) {
      refDialogoDeletarFilho.current.handleDelete(id, nome, data);
    }
  }

  function handleDeletarFilho(id) {
    const newArray = filhos.slice().filter((item) => item.id !== id);
    setFilhos(recalcularIds(newArray));
  }

  function criarConta() {
    if (refDialogoContas.current) {
      refDialogoContas.current.handleCreate();
    }
  }

  function criarPix() {
    if (refDialogoPix.current) {
      refDialogoPix.current.handleCreate();
    }
  }

  function editarConta(id, titular, banco, conta, agencia, tipo, cpf, cnpj) {
    if (refDialogoContas.current) {
      refDialogoContas.current.handleEdit(
        id,
        titular,
        banco,
        conta,
        agencia,
        tipo,
        cpf,
        cnpj,
      );
    }
  }

  function editarPix(id, valor, tipo) {
    if (refDialogoPix.current) {
      refDialogoPix.current.handleEdit(id, valor, tipo);
    }
  }

  function handleSaveConta(
    id,
    titular,
    banco,
    conta,
    agencia,
    tipo,
    cpf,
    cnpj,
  ) {
    const newArray = contas.slice().map((item) => {
      if (item.id === id) {
        return {
          id,
          titular,
          banco,
          conta,
          agencia,
          tipo,
          cpf,
          cnpj,
        };
      }
      return item;
    });
    if (id < 0) {
      newArray.push({
        id: contas.length + 1,
        titular,
        banco,
        conta,
        agencia,
        tipo,
        cpf,
        cnpj,
      });
    }
    setContas(recalcularIds(newArray));
  }

  function handleSavePix(id, valor, tipo) {
    const newArray = dadosPix.slice().map((item) => {
      if (item.id === id) {
        return {
          id,
          valor,
          tipo,
        };
      }
      return item;
    });
    if (id < 0) {
      newArray.push({
        id: dadosPix.length + 1,
        valor,
        tipo,
      });
    }
    setDadosPix(recalcularIds(newArray));
  }

  function deletarConta(id, titular, banco, conta, agencia, tipo, cpf, cnpj) {
    if (refDialogoDeletarConta.current) {
      refDialogoDeletarConta.current.handleDelete(
        id,
        titular,
        banco,
        conta,
        agencia,
        tipo,
        cpf,
        cnpj,
      );
    }
  }

  function handleDeletarConta(id) {
    const newArray = contas.slice().filter((item) => item.id !== id);
    setContas(recalcularIds(newArray));
  }

  function deletarPix(id, valor, tipo) {
    if (refDialogoDeletarPix.current) {
      refDialogoDeletarPix.current.handleDelete(id, valor, tipo);
    }
  }

  function handleDeletarPix(id) {
    const newArray = dadosPix.slice().filter((item) => item.id !== id);
    setDadosPix(recalcularIds(newArray));
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        /* fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted */
        disableBackdropClick
        disableEscapeKeyDown
        fullScreen
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          {id < 0 ? `Novo Colaborador` : `Editar Colaborador`}
        </DialogTitle>
        <DialogContent
          /* style={{
            padding: '0px 36px 20px',
            margin: '0',
          }} */
          dividers
        >
          {/* {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <Box
                component="fieldset"
                mb={marginLine}
                borderColor="transparent"
              >
                <TextInput
                  name="nome"
                  handleEnter={() => {}}
                  label="Nome"
                  handleKey={() => {}}
                  value={nome}
                  onChange={(value) => setNome(value.target.value)}
                  error={erros[0] !== ''}
                  helperText={erros[0]}
                  fullWidth
                />
              </Box>

              <Box
                component="fieldset"
                mb={marginLine}
                borderColor="transparent"
              >
                <AutoCompleteRemoto
                  handleEnter={() => {}}
                  label="Função"
                  handleKey={() => {}}
                  value={funcao}
                  onChange={(value) => setFuncao(value)}
                  error={erros[1] !== ''}
                  helperText={erros[1]}
                  fullWidth
                  toString={false}
                  getOptionSelected={(option, value) =>
                    option.nome === value.nome
                  }
                  getOptionLabel={(option) => `${option.nome}`}
                  nested={[]}
                  filters={{}}
                  resource="funcoes_colaboradores"
                  disabled={false}
                />
              </Box>

              <Box
                component="fieldset"
                mb={marginLine}
                borderColor="transparent"
              >
                <LocaisInput
                  label="Locais"
                  options={locaisOptions}
                  value={locais}
                  onChange={setLocais}
                />
              </Box>

              <Box
                component="fieldset"
                mb={marginLine}
                borderColor="transparent"
              >
                <LocaisInput
                  label="Fardamentos"
                  options={fardamentosOptions}
                  value={fardamentos}
                  onChange={setFardamentos}
                  renderOptionItem={(option) =>
                    `${option.nome}${
                      option.tamanho ? ` ${option.tamanho.nome}` : ''
                    }`
                  }
                />
              </Box>

              <Box
                component="fieldset"
                mb={marginLine}
                borderColor="transparent"
              >
                <LocaisInput
                  label="Qualidades"
                  options={qualidadesOptions}
                  value={qualidades}
                  onChange={setQualidades}
                />
              </Box>

              <Box
                component="fieldset"
                mb={marginLine}
                borderColor="transparent"
              >
                <LocaisInput
                  label="Defeitos"
                  options={defeitosOptions}
                  value={defeitos}
                  onChange={setDefeitos}
                />
              </Box>
            </div>
          )} */}
          <div style={{ height: '100%' }}>
            <Box
              display={{ xs: 'block', sm: 'flex' }}
              flexDirection="column"
              marginBottom="30px"
              height="100%"
            >
              {carregando ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '300px',
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <Box p="1em">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                  >
                    <Tab label="Pessoal" {...a11yProps(0)} />
                    <Tab label="Pix" {...a11yProps(1)} />
                    <Tab label="Contas" {...a11yProps(2)} />
                    <Tab label="Operacional" {...a11yProps(3)} />
                    <Tab label="Contabilidade" {...a11yProps(4)} />
                  </Tabs>

                  <Box display="flex">
                    <Box flex={2} mr="1em">
                      <TabPanel value={value} index={0}>
                        <Typography variant="h6" gutterBottom>
                          Dados Pessoais
                        </Typography>

                        <Box mt="1em" />

                        <Box display="flex">
                          <Box flex={1} mr="0.5em">
                            <TextInput
                              name="nome"
                              handleEnter={() => {}}
                              label="Nome"
                              handleKey={() => {}}
                              value={nome}
                              onChange={(value) => setNome(value.target.value)}
                              error={erros[0] !== ''}
                              helperText={erros[0]}
                              fullWidth
                              autoFocus
                            />
                          </Box>
                          <Box flex={1} ml="0.5em">
                            <CpfInput
                              name="cpf"
                              handleEnter={() => {}}
                              label="CPF"
                              handleKey={() => {}}
                              value={cpf}
                              onChange={(value) => setCpf(value.target.value)}
                              error={erros[2] !== ''}
                              helperText={erros[2]}
                              fullWidth
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box flex={1} mr="0.5em">
                            <TelefoneInput
                              name="telefone"
                              handleEnter={() => {}}
                              label="Telefone"
                              handleKey={() => {}}
                              value={telefone}
                              onChange={(value) =>
                                setTelefone(value.target.value)
                              }
                              error={erros[3] !== ''}
                              helperText={erros[3]}
                              fullWidth
                            />
                          </Box>
                          <Box flex={1} ml="0.5em">
                            <TextInput
                              name="Email"
                              handleEnter={() => {}}
                              label="Email"
                              handleKey={() => {}}
                              value={email}
                              onChange={(value) => setEmail(value.target.value)}
                              error={erros[7] !== ''}
                              helperText={erros[7]}
                              fullWidth
                            />
                          </Box>
                        </Box>

                        <Box mt="1em" />

                        <Typography variant="h6" gutterBottom>
                          Endereço
                        </Typography>

                        <Box display="flex">
                          <Box flex={1} mr="0.5em">
                            <AutoCompleteRemoto
                              name="cidade"
                              handleEnter={() => {}}
                              resource="cidades"
                              nested={['estado']}
                              filters={{ ativo: true }}
                              label="Cidade"
                              handleKey={() => {}}
                              value={cidade}
                              onChange={(value) => setCidade(value)}
                              error={erros[9] !== ''}
                              helperText={erros[9]}
                              toString={false}
                              fullWidth
                              getOptionSelected={(option, value) =>
                                option.nome === value.nome
                              }
                              getOptionLabel={(option) =>
                                `${option.nome} - ${option.estado.sigla}`
                              }
                            />
                          </Box>
                          <Box flex={1} ml="0.5em">
                            <TextInput
                              name="bairro"
                              handleEnter={() => {}}
                              label="Bairro"
                              handleKey={() => {}}
                              value={bairro}
                              onChange={(value) =>
                                setBairro(value.target.value)
                              }
                              error={erros[4] !== ''}
                              helperText={erros[4]}
                              fullWidth
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box flex={1} mr="0.5em">
                            <TextInput
                              name="logradouro"
                              handleEnter={() => {}}
                              label="Logradouro"
                              handleKey={() => {}}
                              value={logradouro}
                              onChange={(value) =>
                                setLogradouro(value.target.value)
                              }
                              error={erros[5] !== ''}
                              helperText={erros[5]}
                              fullWidth
                            />
                          </Box>
                          <Box flex={1} ml="0.5em">
                            <TextInput
                              name="n"
                              handleEnter={() => {}}
                              label="nº"
                              handleKey={() => {}}
                              value={numero}
                              onChange={(value) =>
                                setNumero(value.target.value)
                              }
                              error={erros[6] !== ''}
                              helperText={erros[6]}
                              fullWidth
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box flex={1} mr="0.5em">
                            <CepInput
                              name="cep"
                              handleEnter={() => {}}
                              label="CEP"
                              handleKey={() => {}}
                              value={cep}
                              onChange={(value) => setCep(value.target.value)}
                              error={erros[8] !== ''}
                              helperText={erros[8]}
                              fullWidth
                            />
                          </Box>
                          <Box flex={1} ml="0.5em" />
                        </Box>

                        <Box mt="1em" />

                        <Typography variant="h6" gutterBottom>
                          Dados Bancários
                        </Typography>

                        <Box mt="1em" />

                        <Box display="flex">
                          <Box flex={1} mr="0.5em">
                            <TextInput
                              name="conta_corrente"
                              handleEnter={() => {}}
                              label="Conta Corrente"
                              handleKey={() => {}}
                              value={contaCorrente}
                              onChange={(value) =>
                                setContaCorrente(value.target.value)
                              }
                              error={erros[15] !== ''}
                              helperText={erros[15]}
                              fullWidth
                            />
                          </Box>
                          <Box flex={1} ml="0.5em">
                            <TextInput
                              name="gerencia"
                              handleEnter={() => {}}
                              label="Agência"
                              handleKey={() => {}}
                              value={agencia}
                              onChange={(value) =>
                                setAgencia(value.target.value)
                              }
                              error={erros[16] !== ''}
                              helperText={erros[16]}
                              fullWidth
                            />
                          </Box>
                        </Box>

                        <Box mt="1em" />

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Typography variant="h6" gutterBottom>
                            Filhos
                          </Typography>
                          <Tooltip
                            title="Criar"
                            placement="bottom"
                            aria-label="add2"
                          >
                            <IconButton
                              aria-label="criar"
                              //size="small"
                              onClick={() => criarFilho()}
                            >
                              <AddCircleOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <Box mt="1em" />

                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Nome</StyledTableCell>
                                <StyledTableCell>
                                  Data Nascimento
                                </StyledTableCell>
                                <StyledTableCell>Idade</StyledTableCell>
                                <StyledTableCell align="right">
                                  Ações
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {filhos.map((row) => {
                                const {
                                  idade,
                                  dataNascimentoFormatada,
                                } = calculaIdade(row.data);
                                return (
                                  <StyledTableRow key={row.id}>
                                    <StyledTableCell component="th" scope="row">
                                      {row.nome}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {dataNascimentoFormatada}
                                    </StyledTableCell>
                                    <StyledTableCell>{idade}</StyledTableCell>
                                    <StyledTableCell align="right">
                                      <Tooltip
                                        title="Editar"
                                        placement="bottom"
                                        aria-label="add2"
                                      >
                                        <IconButton
                                          aria-label="editar"
                                          size="small"
                                          onClick={() =>
                                            editarFilho(
                                              row.id,
                                              row.nome,
                                              row.data,
                                            )
                                          }
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip
                                        title="Editar"
                                        placement="bottom"
                                        aria-label="add2"
                                      >
                                        <IconButton
                                          aria-label="editar"
                                          size="small"
                                          onClick={() =>
                                            deletarFilho(
                                              row.id,
                                              row.nome,
                                              row.data,
                                            )
                                          }
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Typography variant="h6" gutterBottom>
                            Pix
                          </Typography>
                          <Tooltip
                            title="Criar"
                            placement="bottom"
                            aria-label="add2"
                          >
                            <IconButton
                              aria-label="criar"
                              //size="small"
                              onClick={() => criarPix()}
                            >
                              <AddCircleOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <Box mt="1em" />
                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Tipo</StyledTableCell>
                                <StyledTableCell>Chave</StyledTableCell>
                                <StyledTableCell align="right">
                                  Ações
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {dadosPix.map((row) => (
                                <StyledTableRow key={row.id}>
                                  <StyledTableCell>
                                    {getLabelTipo(row.tipo)}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {getValorFormatado(row.valor, row.tipo)}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    <Tooltip
                                      title="Editar"
                                      placement="bottom"
                                      aria-label="add2"
                                    >
                                      <IconButton
                                        aria-label="editar"
                                        size="small"
                                        onClick={() =>
                                          editarPix(row.id, row.valor, row.tipo)
                                        }
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                      title="Editar"
                                      placement="bottom"
                                      aria-label="add2"
                                    >
                                      <IconButton
                                        aria-label="editar"
                                        size="small"
                                        onClick={() =>
                                          deletarPix(
                                            row.id,
                                            row.valor,
                                            row.tipo,
                                          )
                                        }
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TabPanel>
                      <TabPanel value={value} index={2}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Typography variant="h6" gutterBottom>
                            Contas
                          </Typography>
                          <Tooltip
                            title="Criar"
                            placement="bottom"
                            aria-label="add2"
                          >
                            <IconButton
                              aria-label="criar"
                              //size="small"
                              onClick={() => criarConta()}
                            >
                              <AddCircleOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <Box mt="1em" />

                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Banco</StyledTableCell>
                                <StyledTableCell>Conta</StyledTableCell>
                                <StyledTableCell>Agência</StyledTableCell>
                                <StyledTableCell>Tipo</StyledTableCell>
                                <StyledTableCell align="right">
                                  Ações
                                </StyledTableCell>
                                {/* <StyledTableCell align="right">
                                Deletar
                              </StyledTableCell> */}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {contas.map((row) => (
                                <StyledTableRow key={row.id}>
                                  <StyledTableCell>
                                    {row.banco ? row.banco.nome : ''}
                                  </StyledTableCell>
                                  <StyledTableCell>{row.conta}</StyledTableCell>
                                  <StyledTableCell>
                                    {row.agencia}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {getTipo(row.tipo)}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    <Tooltip
                                      title="Editar"
                                      placement="bottom"
                                      aria-label="add2"
                                    >
                                      <IconButton
                                        aria-label="editar"
                                        size="small"
                                        onClick={() =>
                                          editarConta(
                                            row.id,
                                            row.titular,
                                            row.banco,
                                            row.conta,
                                            row.agencia,
                                            row.tipo,
                                            row.cpf,
                                            row.cnpj,
                                          )
                                        }
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                      title="Editar"
                                      placement="bottom"
                                      aria-label="add2"
                                    >
                                      <IconButton
                                        aria-label="editar"
                                        size="small"
                                        onClick={() =>
                                          deletarConta(
                                            row.id,
                                            row.titular,
                                            row.banco,
                                            row.conta,
                                            row.agencia,
                                            row.tipo,
                                            row.cpf,
                                            row.cnpj,
                                          )
                                        }
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TabPanel>
                      <TabPanel value={value} index={3}>
                        <Typography variant="h6" gutterBottom>
                          Dados Funcionais
                        </Typography>

                        <Box mt="1em" />

                        <Box display="flex">
                          <Box flex={1} mr="0.5em">
                            <AutoCompleteRemoto
                              name="codinome"
                              label="Codinome"
                              value={codinome}
                              onChange={(value) => {
                                if (value) {
                                  setCodinome(value);
                                  //setEmpresa(value.empresa);
                                  /*setIsFuncionarioInterno(
                                    value.is_funcionario_interno,
                                  );*/
                                  setGrupo(value.grupoColaborador);
                                  setFuncao(value.funcao);
                                  setTurno(value.turno);
                                } else {
                                  setCodinome(null);
                                  //setEmpresa(null);
                                  //setIsFuncionarioInterno(true);
                                  setGrupo(null);
                                  setFuncao(null);
                                  setTurno(TURNO_MANHA);
                                }
                              }}
                              handleKey={() => {}}
                              handleEnter={() => {}}
                              resource="codinomes_colaborador_2"
                              nested={['funcao', 'grupoColaborador', 'empresa']}
                              toString={false}
                              perPage={100}
                              //filters={{ usado: false }}
                              filters={
                                codinomeOriginalId
                                  ? {
                                      ids_incluso: [codinomeOriginalId],
                                    }
                                  : { ids_incluso: [] }
                              }
                              fullWidth
                              error={erros[24] !== ''}
                              helperText={erros[24]}
                            />
                          </Box>
                          <Box
                            flex={1}
                            mr="0.5em"
                            ml="0.5em"
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <SwitchInput
                              name="is_funcionario_interno"
                              handleEnter={() => {}}
                              label="Funcionário Interno"
                              handleKey={() => {}}
                              checked={isFuncionarioInterno}
                              onChange={(value) => {
                                setIsFuncionarioInterno(value.target.checked);
                              }}
                              //disabled
                            />
                          </Box>
                          <Box
                            flex={1}
                            ml="0.5em"
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <span>{getLabelFolga(grupo)}</span>
                          </Box>
                        </Box>

                        <Box display="flex" mb="0px">
                          <Box flex={1} mr="0.5em">
                            <AutoCompleteRemoto
                              handleEnter={() => {}}
                              label="Loja"
                              handleKey={() => {}}
                              value={empresa}
                              onChange={(value) => setEmpresa(value)}
                              fullWidth
                              toString={false}
                              getOptionSelected={(option, value) =>
                                option.nome_fantasia === value.nome_fantasia
                              }
                              getOptionLabel={(option) =>
                                `${option.nome_fantasia}`
                              }
                              nested={[]}
                              filters={
                                empresaOriginalId
                                  ? {
                                      ids_incluso: [empresaOriginalId],
                                      status: STATUS_ATIVO,
                                    }
                                  : { status: STATUS_ATIVO }
                              }
                              resource="empresa-loja"
                              error={erros[23] !== ''}
                              helperText={erros[23]}
                              //disabled
                            />
                          </Box>
                          <Box flex={1} ml="0.5em">
                            <AutoCompleteRemoto
                              handleEnter={() => {}}
                              label="Função"
                              handleKey={() => {}}
                              value={funcao}
                              onChange={(value) => setFuncao(value)}
                              fullWidth
                              toString={false}
                              getOptionSelected={(option, value) =>
                                option.nome === value.nome
                              }
                              getOptionLabel={(option) => `${option.nome}`}
                              nested={[]}
                              filters={
                                funcaoOriginalId
                                  ? {
                                      ids_incluso: [funcaoOriginalId],
                                      status: STATUS_ATIVO,
                                    }
                                  : { status: STATUS_ATIVO }
                              }
                              resource="funcoes_colaboradores"
                              error={erros[1] !== ''}
                              helperText={erros[1]}
                              disabled
                            />
                          </Box>
                        </Box>

                        <Box display="flex" mb="16px">
                          <Box flex={1} mr="0.5em">
                            <AutoCompleteRemoto
                              name="grupo"
                              label="Grupo"
                              value={grupo}
                              onChange={(value) => setGrupo(value)}
                              handleKey={() => {}}
                              handleEnter={() => {}}
                              resource="grupos_colaborador"
                              nested={[]}
                              toString={false}
                              fullWidth
                              error={erros[22] !== ''}
                              helperText={erros[22]}
                              disabled
                            />
                          </Box>
                          <Box flex={1} ml="0.5em">
                            <FormControl
                              variant="outlined"
                              style={{
                                marginTop: '16px',
                              }}
                              className={classes.formControl}
                              fullWidth
                            >
                              <InputLabel shrink id="turno-colaborador">
                                Turno
                              </InputLabel>
                              <SelectInput
                                labelId="turno-colaborador"
                                name="turno"
                                handleEnter={() => {}}
                                label="Turno"
                                handleKey={() => {}}
                                value={turno}
                                onChange={(value) =>
                                  setTurno(value.target.value)
                                }
                                options={optionsTurno}
                                disabled
                              />
                            </FormControl>
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box flex={1} mr="0.5em">
                            <LocaisInput
                              label="Locais"
                              options={locaisOptions}
                              value={locais}
                              onChange={setLocais}
                              limitTags={10}
                            />
                          </Box>
                          <Box flex={1} ml="0.5em">
                            <LocaisInput
                              label="Fardamentos"
                              options={fardamentosOptions}
                              value={fardamentos}
                              onChange={setFardamentos}
                              renderOptionItem={(option) =>
                                `${option.nome}${
                                  option.tamanho
                                    ? ` ${option.tamanho.nome}`
                                    : ''
                                }`
                              }
                            />
                          </Box>
                        </Box>

                        <Box mt="1em" />

                        <Box display="flex">
                          <Box
                            flex={1}
                            mr="0.5em"
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <SwitchInput
                              name="is_gestor"
                              handleEnter={() => {}}
                              label="Gestor"
                              handleKey={() => {}}
                              checked={isGestor}
                              onChange={(value) => {
                                setIsGestor(value.target.checked);
                              }}
                            />
                          </Box>
                          <Box flex={1} ml="0.5em">
                            <DateInput
                              name="data_entrada"
                              handleEnter={() => {}}
                              label="Data entrada na empresa"
                              handleKey={() => {}}
                              value={dataEntrada}
                              onChange={(value) =>
                                setDataEntrada(value.target.value)
                              }
                              error={erros[17] !== ''}
                              helperText={erros[17]}
                              fullWidth
                            />
                          </Box>
                        </Box>

                        <Box mt="1em" />

                        <Box display="flex">
                          <Box flex={1} mr="0.5em">
                            <LocaisInput
                              label="Qualidades"
                              options={qualidadesOptions}
                              value={qualidades}
                              onChange={setQualidades}
                            />
                          </Box>
                          <Box flex={1} ml="0.5em">
                            <LocaisInput
                              label="Defeitos"
                              options={defeitosOptions}
                              value={defeitos}
                              onChange={setDefeitos}
                            />
                          </Box>
                        </Box>

                        <Box mt="1em" />

                        <Typography variant="h6" gutterBottom>
                          Usuário
                        </Typography>

                        <Box mt="1em" />

                        <Box display="flex">
                          <Box flex={1} mr="0.5em">
                            <TextInput
                              name="username"
                              handleEnter={() => {}}
                              label="Username"
                              handleKey={() => {}}
                              value={username}
                              onChange={(value) =>
                                setUsername(value.target.value)
                              }
                              error={erros[19] !== ''}
                              helperText={erros[19]}
                              fullWidth
                            />
                          </Box>
                          <Box flex={1} ml="0.5em">
                            <TextInput
                              name="password"
                              handleEnter={() => {}}
                              label="Password"
                              handleKey={() => {}}
                              value={password}
                              onChange={(value) =>
                                setPassword(value.target.value)
                              }
                              error={erros[20] !== ''}
                              helperText={erros[20]}
                              fullWidth
                            />
                          </Box>
                        </Box>
                        <Box mt="1em" />
                        <Box display="flex">
                          <Box flex={1} mr="0.5em">
                            <AutoCompleteRemoto
                              name="cargos"
                              handleEnter={() => {}}
                              resource="cargos"
                              nested={[]}
                              filters={{}}
                              label="Cargo"
                              handleKey={() => {}}
                              value={cargo}
                              onChange={(value) => setCargo(value)}
                              error={erros[21] !== ''}
                              helperText={erros[21]}
                              toString={false}
                              fullWidth
                            />
                          </Box>
                          <Box flex={1} ml="0.5em">
                            <SwitchInput
                              name="is_responsavel"
                              handleEnter={() => {}}
                              label="Gerente de Caixa"
                              handleKey={() => {}}
                              checked={isResponsavel}
                              onChange={(value) => {
                                setIsResponsavel(value.target.checked);
                              }}
                            />
                          </Box>
                        </Box>
                      </TabPanel>
                      <TabPanel value={value} index={4}>
                        <Box display="flex">
                          <Box flex={1} mr="0.5em">
                            <PrecoInput
                              name="desconto_palestra"
                              handleEnter={() => {}}
                              label="Desconto da Palestra"
                              handleKey={() => {}}
                              value={
                                Number.isNaN(descontoPalestra)
                                  ? ''
                                  : String(descontoPalestra)
                              }
                              onChange={(value) =>
                                setDescontoPalestra(
                                  parseFloat(value.target.value),
                                )
                              }
                              error={erros[10] !== ''}
                              helperText={erros[10]}
                              fullWidth
                            />
                          </Box>
                          <Box flex={1} ml="0.5em">
                            <PrecoInput
                              name="desconto_plano_saude"
                              handleEnter={() => {}}
                              label="Desconto do Plano de Saúde"
                              handleKey={() => {}}
                              value={
                                Number.isNaN(descontoPlanoSaude)
                                  ? ''
                                  : String(descontoPlanoSaude)
                              }
                              onChange={(value) =>
                                setDescontoPlanoSaude(
                                  parseFloat(value.target.value),
                                )
                              }
                              error={erros[11] !== ''}
                              helperText={erros[11]}
                              fullWidth
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box flex={1} mr="0.5em">
                            <PrecoInput
                              name="desconto_plano_odontologico"
                              handleEnter={() => {}}
                              label="Desconto do Plano Odontológico"
                              handleKey={() => {}}
                              value={
                                Number.isNaN(descontoPlanoOdontologico)
                                  ? ''
                                  : String(descontoPlanoOdontologico)
                              }
                              onChange={(value) =>
                                setDescontoPlanoOdontologico(
                                  parseFloat(value.target.value),
                                )
                              }
                              error={erros[13] !== ''}
                              helperText={erros[13]}
                              fullWidth
                            />
                          </Box>
                          <Box flex={1} ml="0.5em">
                            <PrecoInput
                              name="horas_extras"
                              handleEnter={() => {}}
                              label="Valor Horas Extras"
                              handleKey={() => {}}
                              value={
                                Number.isNaN(horasExtras)
                                  ? ''
                                  : String(horasExtras)
                              }
                              onChange={(value) =>
                                setHorasExtras(parseFloat(value.target.value))
                              }
                              error={erros[14] !== ''}
                              helperText={erros[14] !== ''}
                              fullWidth
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box flex={1} mr="0.5em">
                            <PrecoInput
                              name="desconto_vale_transporte"
                              handleEnter={() => {}}
                              label="Desconto do Vale Transporte"
                              handleKey={() => {}}
                              value={
                                Number.isNaN(descontoValeTransporte)
                                  ? ''
                                  : String(descontoValeTransporte)
                              }
                              onChange={(value) =>
                                setDescontoValeTransporte(
                                  parseFloat(value.target.value),
                                )
                              }
                              error={erros[12] !== ''}
                              helperText={erros[12]}
                              fullWidth
                            />
                          </Box>
                          <Box flex={1} mr="0.5em" />
                        </Box>
                      </TabPanel>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={handleCloseDialog}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
            variant="contained"
            color="primary"
          >
            Cancelar
          </Button>
          <div className={classes.wrapper}>
            <Button
              onClick={handleSalvar}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              disabled={erroExistente || carregando}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
            {carregando && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
        <DialogoCreateEditFilho
          ref={refDialogoFilho}
          handleClose={() => {}}
          handleSave={handleSave}
        />
        <DialogoDeletarFilho
          ref={refDialogoDeletarFilho}
          handleClose={() => {}}
          handleDelete={handleDeletarFilho}
        />
        <DialogoCreateEditConta
          ref={refDialogoContas}
          handleClose={() => {}}
          handleSave={handleSaveConta}
        />
        <DialogoDeletarConta
          ref={refDialogoDeletarConta}
          handleClose={() => {}}
          handleDelete={handleDeletarConta}
        />
        <DialogoCreateEditPix
          ref={refDialogoPix}
          handleClose={() => {}}
          handleSave={handleSavePix}
        />
        <DialogoDeletarPix
          ref={refDialogoDeletarPix}
          handleClose={() => {}}
          handleDelete={handleDeletarPix}
        />
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
