import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  SelectInput,
  AutoCompleteRemoto,
  SwitchInput,
} from '../../../Components';
import { getListAllAPI, getUserId, api } from '../../../services';
import { TURNO_MANHA, TURNO_NOITE, TURNO_12_HORAS } from '../../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_ID = -1;

const optionsTurno = [
  {
    label: 'Manhã',
    value: TURNO_MANHA,
  },
  {
    label: 'Noite',
    value: TURNO_NOITE,
  },
  {
    label: '12H',
    value: TURNO_12_HORAS,
  },
];

const STATUS_PONTO_TRABALHOU = 'trabalhou';
const STATUS_PONTO_FOLGA = 'folga';
const STATUS_PONTO_FALTA = 'falta';
const STATUS_PONTO_FERIAS = 'ferias';

const optionsStatusPonto = [
  {
    label: 'Trabalhou',
    value: STATUS_PONTO_TRABALHOU,
  },
  {
    label: 'Folga',
    value: STATUS_PONTO_FOLGA,
  },
  {
    label: 'Falta',
    value: STATUS_PONTO_FALTA,
  },
  {
    label: 'Férias',
    value: STATUS_PONTO_FERIAS,
  },
];

function getStatusPontoValue(ponto) {
  if (ponto.is_folga) return STATUS_PONTO_FOLGA;
  if (ponto.is_faltou) return STATUS_PONTO_FALTA;
  if (ponto.is_ferias) return STATUS_PONTO_FERIAS;
  return STATUS_PONTO_TRABALHOU;
}

function getValores(statusPonto, isAtestado) {
  switch (statusPonto) {
    case STATUS_PONTO_TRABALHOU:
      return {
        is_folga: false,
        is_atestado: isAtestado,
        is_faltou: false,
        is_declaracao: false,
        minutos_declaracao: 0,
        is_ferias: false,
      };
    case STATUS_PONTO_FOLGA:
      return {
        is_folga: true,
        is_atestado: false,
        is_faltou: false,
        is_declaracao: false,
        minutos_declaracao: 0,
        is_ferias: false,
      };
    case STATUS_PONTO_FALTA:
      return {
        is_folga: false,
        is_atestado: isAtestado,
        is_faltou: true,
        is_declaracao: false,
        minutos_declaracao: 0,
        is_ferias: false,
      };
    case STATUS_PONTO_FERIAS:
      return {
        is_folga: false,
        is_atestado: false,
        is_faltou: false,
        is_declaracao: false,
        minutos_declaracao: 0,
        is_ferias: true,
      };
    default:
      return {
        is_folga: false,
        is_atestado: false,
        is_faltou: false,
        is_declaracao: false,
        minutos_declaracao: 0,
        is_ferias: false,
      };
  }
}

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose = () => {}, updatePonto = () => {} }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [id, setId] = useState(DEFAULT_ID);

    const [carregando, setCarregando] = useState(false);

    const [colaborador, setColaborador] = useState('');
    const [data, setData] = useState('');
    const [turno, setTurno] = useState(TURNO_MANHA);
    const [grupo, setGrupo] = useState(null);
    const [codinome, setCodinome] = useState(null);
    //const [isFolga, setIsFolga] = useState(false);
    const [isAtestado, setIsAtestado] = useState(false);
    //const [isFaltou, setIsFaltou] = useState(false);
    //const [isDeclaracao, setIsDeclaracao] = useState(false);
    //const [minutosDeclaracao, setMinutosDeclaracao] = useState(0);
    const [isFerias, setIsFerias] = useState(false);
    const [statusPonto, setStatusPonto] = useState(false);

    const classes = useStyles({ cor: '#009FD4' });

    const handleCloseDialog = () => {
      setOpen(false);
    };

    const handleCloseDialog2 = () => {
      setOpen(false);
      handleClose();
    };

    async function getDataResource(id) {
      try {
        const data = await getListAllAPI(
          'pontos_colaborador',
          ['id', 'asc'],
          { id: [id] },
          ['colaborador', 'dia', 'grupoColaborador', 'codinome'],
        );
        if (data.data.length > 0) {
          setId(data.data[0].id);

          setTurno(data.data[0].turno);

          setColaborador(data.data[0].colaborador.nome);

          setGrupo(data.data[0].grupoColaborador);

          setCodinome(data.data[0].codinome);

          setData(moment(new Date(data.data[0].dia.data)).format('DD/MM/YYYY'));

          setIsAtestado(data.data[0].is_atestado);

          //setIsFolga(data.data[0].is_folga);

          //setIsFaltou(data.data[0].is_faltou);

          //setIsDeclaracao(data.data[0].is_declaracao);

          //setMinutosDeclaracao(data.data[0].minutos_declaracao);

          setIsFerias(data.data[0].is_ferias);

          setStatusPonto(getStatusPontoValue(data.data[0]));

          setCarregando(false);
        } else {
          throw 'Este ponto não existe!';
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    async function handleSalvar() {
      setCarregando(true);
      try {
        const {
          is_atestado,
          is_declaracao,
          is_faltou,
          is_ferias,
          is_folga,
          minutos_declaracao,
        } = getValores(statusPonto, isAtestado);

        const response = await api.put(`/alterar_turno_colaborador_dia/${id}`, {
          turno,
          grupo_colaborador_id: grupo ? grupo.id : null,
          codinome_colaborador_id: codinome ? codinome.id : null,
          is_folga,
          is_atestado,
          is_faltou,
          is_declaracao,
          minutos_declaracao,
          is_ferias,
        });

        const data = response.data;
        if (data.status) {
          updatePonto(
            {
              id,
              turno: data.data.ponto.turno,
              grupo_colaborador_id: data.data.ponto.grupo_colaborador_id,
              codinome_colaborador_id: data.data.ponto.codinome_colaborador_id,
              is_folga: data.data.ponto.is_folga,
              is_atestado: data.data.ponto.is_atestado,
              is_faltou: data.data.ponto.is_faltou,
              is_verificado: data.data.ponto.is_verificado,
              is_declaracao: data.data.ponto.is_declaracao,
              minutos_declaracao: data.data.ponto.minutos_declaracao,
              is_ferias: data.data.ponto.is_ferias,
            },
            data.data.dia,
            data.data.conpensacao,
          );
          setCarregando(false);
          enqueueSnackbar(
            `Dados atualizados com sucesso, para o colaborador ${colaborador} para a data ${data}!`,
            {
              variant: 'success',
            },
          );

          handleCloseDialog2();
        } else {
          setCarregando(false);
          enqueueSnackbar(data.message, {
            variant: 'success',
          });

          handleCloseDialog();
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    useImperativeHandle(ref, () => ({
      handleOpen(id) {
        setCarregando(true);
        getDataResource(id);
        setId(id);
        setOpen(true);
      },
    }));

    function validateTurno() {
      const error = '';
      return error;
    }

    function validateGrupo() {
      let error = '';
      if (!grupo) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function validateCodinome() {
      const error = '';
      /*if (!codinome) {
        error = 'Este campo é obrigatório';
      }*/
      return error;
    }

    function getErros() {
      const errosOld = ['', '', ''];
      errosOld[0] = validateTurno();
      errosOld[1] = validateGrupo();
      errosOld[2] = validateCodinome();
      return errosOld;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          TransitionComponent={Transition}
          keepMounted
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            {`Alterar dados de presença do colaborador - ${colaborador} - para a data - ${data} -`}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 10px 20px',
              margin: '0',
            }}
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Box p="1em">
                <Box display="flex">
                  <Box flex={1} mr="0.5em">
                    <FormControl
                      variant="outlined"
                      style={{
                        marginTop: '16px',
                      }}
                      className={classes.formControl}
                      fullWidth
                    >
                      <InputLabel shrink id="status-do-ponto">
                        Status do Ponto
                      </InputLabel>
                      <SelectInput
                        labelId="status-do-ponto"
                        name="status-do-ponto"
                        handleEnter={() => {}}
                        label="Status do Ponto"
                        handleKey={() => {}}
                        value={statusPonto}
                        onChange={(value) => setStatusPonto(value.target.value)}
                        options={optionsStatusPonto}
                      />
                    </FormControl>
                  </Box>
                  {statusPonto === STATUS_PONTO_TRABALHOU ||
                  statusPonto === STATUS_PONTO_FALTA ? (
                    <Box
                      flex={1}
                      ml="0.5em"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <SwitchInput
                        name="is_atestado"
                        handleEnter={() => {}}
                        label="Atestado"
                        handleKey={() => {}}
                        checked={isAtestado}
                        onChange={(value) => {
                          setIsAtestado(value.target.checked);
                        }}
                      />
                    </Box>
                  ) : null}
                </Box>
                {/*<Box
                  flex={1}
                  ml="0.5em"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <SwitchInput
                    name="is_folga"
                    handleEnter={() => {}}
                    label="Folga"
                    handleKey={() => {}}
                    checked={isFolga}
                    onChange={(value) => {
                      const value2 = value.target.checked;
                      if (isFolga) {
                        setIsFaltou(false);
                        setIsAtestado(false);
                      }
                      setIsFolga(value2);
                    }}
                  />
                  </Box>*/}
                {/*!isFolga ? (
                  <Box display="flex">
                    <Box
                      flex={1}
                      ml="0.5em"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <SwitchInput
                        name="is_faltou"
                        handleEnter={() => {}}
                        label="Falta"
                        handleKey={() => {}}
                        checked={isFaltou}
                        onChange={(value) => {
                          const value2 = value.target.checked;
                          if (!value2) {
                            setIsAtestado(false);
                          }
                          setIsFaltou(value.target.checked);
                        }}
                      />
                    </Box>
                    <Box
                      flex={1}
                      ml="0.5em"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <SwitchInput
                        name="is_atestado"
                        handleEnter={() => {}}
                        label="Atestado"
                        handleKey={() => {}}
                        checked={isAtestado}
                        onChange={(value) => {
                          setIsAtestado(value.target.checked);
                        }}
                        disabled={!isFaltou}
                      />
                    </Box>
                  </Box>
                      ) : null*/}
                {statusPonto === STATUS_PONTO_TRABALHOU ||
                statusPonto === STATUS_PONTO_FALTA ? (
                  <Box display="flex">
                    <Box flex={1} mr="0.5em">
                      <AutoCompleteRemoto
                        name="grupo"
                        label="Grupo"
                        value={grupo}
                        onChange={(value) => setGrupo(value)}
                        handleKey={() => {}}
                        handleEnter={() => {}}
                        resource="grupos_colaborador"
                        nested={[]}
                        toString={false}
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} ml="0.5em">
                      <FormControl
                        variant="outlined"
                        style={{
                          marginTop: '16px',
                        }}
                        className={classes.formControl}
                        fullWidth
                      >
                        <InputLabel shrink id="turno-colaborador">
                          Turno
                        </InputLabel>
                        <SelectInput
                          labelId="turno-colaborador"
                          name="turno"
                          handleEnter={() => {}}
                          label="Turno"
                          handleKey={() => {}}
                          value={turno}
                          onChange={(value) => setTurno(value.target.value)}
                          options={optionsTurno}
                        />
                      </FormControl>
                    </Box>
                  </Box>
                ) : null}
                {statusPonto === STATUS_PONTO_TRABALHOU ||
                statusPonto === STATUS_PONTO_FALTA ? (
                  <Box display="flex">
                    <Box flex={1} mr="0.5em">
                      <AutoCompleteRemoto
                        name="codinome"
                        label="Codinome"
                        value={codinome}
                        onChange={(value) => setCodinome(value)}
                        handleKey={() => {}}
                        handleEnter={() => {}}
                        resource="codinomes_colaborador"
                        nested={[]}
                        toString={false}
                        //filters={{ usado: false }}
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} ml="0.5em" />
                  </Box>
                ) : null}
              </Box>
            )}
          </DialogContent>
          <DialogActions
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                disabled={carregando || erroExistente}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
              {carregando && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
