import React, { forwardRef } from 'react';
import { useRefresh } from 'react-admin';

import DialogoCreateVendaParcial from '../../Components/DialogoCreateVendaParcial';

const Container = forwardRef((props, ref) => {
  const refresh = useRefresh();
  function handleClose() {
    refresh();
  }
  return <DialogoCreateVendaParcial ref={ref} handleClose={handleClose} />;
});

export default Container;
