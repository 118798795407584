import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  DateField,
  ListContextProvider,
  useListContext,
  ListBase,
  ListToolbar,
  Pagination,
  Datagrid,
  Title,
  useRefresh,
  TextField,
} from 'react-admin';

import { Divider, Tabs, Tab, Card, makeStyles } from '@material-ui/core';
import moment from 'moment';

import {
  stylesMalote,
  MALOTE_MALOTE_OPTION,
  MALOTE_SANGRIA_OPTION,
  MALOTE_TROCO_OPTION,
  MALOTE_SUBMALOTE_OPTION,
} from '../../utils';
import Transportador2Field from '../MaloteTrocos/Fields/Transportador2Field';
import TransportadorFieldkk from '../MaloteTrocos/Fields/TransportadorField';
import ValorTransferenciaField from '../Transacoes/Fields/ValorTransferenciaField';
import Actions from './ActionsList';
import DialogoRemoverLixeira from './DialogoRemoverLixeira';
import DialogoShowTroco from './DialogoShowTroco';
import ActionsField from './Fields/ActionsField';
import AtualizadorField from './Fields/AtualizadorField';
import CodigoField from './Fields/CodigoField';
import Conta2Field from './Fields/Conta2Field';
import ContaField from './Fields/ContaField';
import CriadorField from './Fields/CriadorField';
import Frentista2Field from './Fields/Frentista2Field';
import FrentistaField from './Fields/FrentistaField';
import IsCriadoManualmenteField from './Fields/IsCriadoManualmenteField';
import Responsavel2Field from './Fields/Responsavel2Field';
import ResponsavelField from './Fields/ResponsavelField';
import StatusField from './Fields/StatusField';
import TransportadorField from './Fields/TransportadorField';
import Filter from './Filter';

const useStylesDatagrid = makeStyles((theme) => stylesMalote);

const tabs = [
  { id: MALOTE_SANGRIA_OPTION, name: 'Sangrias' },
  { id: MALOTE_TROCO_OPTION, name: 'Trocos' },
  { id: MALOTE_MALOTE_OPTION, name: 'Malotes' },
  { id: MALOTE_SUBMALOTE_OPTION, name: 'Submalotes' },
];

const PostPanel = (props) => (
  <div>
    <div>
      {`Descrição do Problema: ${
        props.record.descricao_problema ? props.record.descricao_problema : ''
      }`}
    </div>
    <div>
      {`Data de envio para a lixeira: ${
        props.record.updated_at
          ? moment(new Date(props.record.updated_at)).format(
              'DD/MM/YYYY - HH:mm',
            )
          : ''
      }`}
    </div>
    {props.record.tipo === MALOTE_TROCO_OPTION ? (
      <>
        <div>
          {`Data Entrada no cofre: ${
            props.record.data_confirmacao_transporte_1
              ? moment(
                  new Date(props.record.data_confirmacao_transporte_1),
                ).format('DD/MM/YYYY - HH:mm')
              : ''
          }`}
        </div>
        <div>
          {`Data Saída do Cofre: ${
            props.record.data_confirmacao_transporte_2
              ? moment(
                  new Date(props.record.data_confirmacao_transporte_2),
                ).format('DD/MM/YYYY - HH:mm')
              : ''
          }`}
        </div>
      </>
    ) : null}
  </div>
);

const MyList = ({ ...props }) => {
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [sangrias, setSangrias] = useState([]);
  const [trocos, setTrocos] = useState([]);
  const [malotes, setMalotes] = useState([]);
  const [submalotes, setSubmalotes] = useState([]);
  const classes = useStylesDatagrid();
  const refDialogoShowTroco = useRef(null);
  const refDialogoRemoverLixeira = useRef(null);
  const refresh = useRefresh();

  const show = (data) => {
    if (refDialogoShowTroco.current) {
      refDialogoShowTroco.current.handleOpen(data.record.id);
    }
  };

  useEffect(() => {
    if (ids && ids !== filterValues.tipo) {
      switch (filterValues.tipo) {
        case MALOTE_MALOTE_OPTION:
          setMalotes(ids);
          break;
        case MALOTE_SANGRIA_OPTION:
          setSangrias(ids);
          break;
        case MALOTE_TROCO_OPTION:
          setTrocos(ids);
          break;
        case MALOTE_SUBMALOTE_OPTION:
          setSubmalotes(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.visible]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters({ ...filterValues, tipo: value }, displayedFilters);
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  function handleClose() {
    refresh();
  }

  function handleRemoverLixeira(data) {
    if (refDialogoRemoverLixeira.current) {
      refDialogoRemoverLixeira.current.handleOpen(data.record.id);
    }
  }

  return (
    <>
      <Title title="Lixeira" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Divider />
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.tipo}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.tipo === MALOTE_SANGRIA_OPTION && (
            <ListContextProvider value={{ ...listContext, ids: sangrias }}>
              <Datagrid {...props}>
                <CodigoField
                  source="codigo"
                  label="Código"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <DateField
                  source="data_pagamento"
                  label="Horário"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  options={{ hour: 'numeric', minute: 'numeric' }}
                  sortable={false}
                />
                <TextField
                  source="sessao_id"
                  label="Sessão"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <ContaField
                  label="Caixa"
                  source="conta_id"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <FrentistaField
                  label="Frentista"
                  source="responsavel"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <ResponsavelField
                  label="Responsável"
                  source="responsavel"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <TransportadorField
                  label="Transportador Ida"
                  source="transportador1_id"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <ValorTransferenciaField
                  source="valor"
                  textAlign="right"
                  label="Valor"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.valorCell}
                  sortable={false}
                />
                <ActionsField
                  source="lalala"
                  handleShow={show}
                  textAlign="right"
                  label="Ações"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.actionsCell}
                  sortable={false}
                  handleRemoverLixeira={handleRemoverLixeira}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.tipo === MALOTE_TROCO_OPTION && (
            <ListContextProvider value={{ ...listContext, ids: trocos }}>
              <Datagrid {...props}>
                <CodigoField
                  source="codigo"
                  label="Código"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <DateField
                  source="data_pagamento"
                  label="Horário Ida"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  options={{ hour: 'numeric', minute: 'numeric' }}
                  sortable={false}
                />
                <TextField
                  source="sessao_id"
                  label="Sessão (Ida ao Cofre)"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <ContaField
                  label="Caixa Ida"
                  source="conta_id"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <FrentistaField
                  label="Frentista Ida"
                  source="responsavel"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <ResponsavelField
                  label="Responsável Ida"
                  source="responsavel"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <TransportadorFieldkk
                  label="Transportador (Ida ao Cofre)"
                  source="transportador1_id"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                  enableCheckEntrada
                />
                <Transportador2Field
                  label="Transportador (Volta ao Checkout)"
                  source="transportador2_id"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                  enableCheckEntrada
                />
                <ValorTransferenciaField
                  source="valor"
                  textAlign="right"
                  label="Valor"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.valorCell}
                  sortable={false}
                />
                <ActionsField
                  source="lalala"
                  handleShow={show}
                  textAlign="right"
                  label="Ações"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.actionsCell}
                  sortable={false}
                  handleRemoverLixeira={handleRemoverLixeira}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.tipo === MALOTE_MALOTE_OPTION && (
            <ListContextProvider value={{ ...listContext, ids: malotes }}>
              <Datagrid {...props}>
                <CodigoField
                  source="codigo"
                  label="Código"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <DateField
                  source="data_pagamento"
                  label="Criação"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  options={{ hour: 'numeric', minute: 'numeric' }}
                  sortable={false}
                />
                <CriadorField
                  label="Criador"
                  source="responsavel"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <AtualizadorField
                  label="Quem Atualizou"
                  source="responsavel"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <ContaField
                  label="Conta"
                  source="conta_id"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <ValorTransferenciaField
                  source="valor"
                  textAlign="right"
                  label="Valor"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.valorCell}
                  sortable={false}
                />
                <ActionsField
                  source="lalala"
                  handleShow={show}
                  textAlign="right"
                  label="Ações"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.actionsCell}
                  sortable={false}
                  handleRemoverLixeira={handleRemoverLixeira}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.tipo === MALOTE_SUBMALOTE_OPTION && (
            <ListContextProvider value={{ ...listContext, ids: submalotes }}>
              <Datagrid {...props}>
                <IsCriadoManualmenteField
                  source="is_artificial"
                  label="Criado Manualmente"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <CodigoField
                  source="codigo"
                  label="Código"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <DateField
                  source="data_pagamento"
                  label="Criação"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  options={{ hour: 'numeric', minute: 'numeric' }}
                  sortable={false}
                />
                <CriadorField
                  label="Criador"
                  source="responsavel"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <AtualizadorField
                  label="Quem Atualizou"
                  source="responsavel"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <ValorTransferenciaField
                  source="valor"
                  textAlign="right"
                  label="Valor"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.valorCell}
                  sortable={false}
                />
                <ActionsField
                  source="lalala"
                  handleShow={show}
                  textAlign="right"
                  label="Ações"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.actionsCell}
                  sortable={false}
                  handleRemoverLixeira={handleRemoverLixeira}
                />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
        <TransacoesPagination />
      </Card>
      <DialogoShowTroco ref={refDialogoShowTroco} />
      <DialogoRemoverLixeira
        ref={refDialogoRemoverLixeira}
        handleClose={handleClose}
      />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      expand={<PostPanel />}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Lixeira"
      filterDefaultValues={{
        tipo: MALOTE_SANGRIA_OPTION,
      }}
    />
  </>
);

export default List2;
