import React, { useState, useRef } from 'react';
import { useListContext, ListBase, ListToolbar, useRefresh } from 'react-admin';
import { useHistory } from 'react-router-dom';

import { Grid, Card, CardContent, Typography } from '@material-ui/core';
import Avatar2 from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useSnackbar } from 'notistack';
import { stringify } from 'query-string';

import { DialogoConta, DialogoDeletarConta } from '../../Components';
import Avatar from '../../Components/Avatar';
import { formatMoeda } from '../../utils';
import { STATUS_ATIVO } from '../../utils/constants';
import Actions from './ActionsList';
import Filter from './Filter';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
  },
  media: {
    height: 200,
  },
  title: {
    paddingTop: '0px',
    paddingBottom: '0.5em',
    display: 'flex',
    justifyContent: 'space-between',
    height: 200,
    flexDirection: 'column',
  },
  actionSpacer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  header: {
    fontSize: '20px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  card: {},
  card2: {
    height: '272px',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: 'white',
    color: '#009FD4',
    border: '2px solid #009FD4',
  },
  contentHedare: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: '20px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {},
}))(MenuItem);

const TypographyValor = ({ valor }) => {
  const valorAbsoluto = valor >= 0 ? valor : valor;
  return (
    <Typography
      variant="subtitle1"
      align="center"
      style={{
        color: valor >= 0 ? '#4caf67' : '#f44336',
        fontWeight: 'bold',
        fontSize: '25px',
      }}
    >
      {formatMoeda(valorAbsoluto)}
    </Typography>
  );
};

const CardConta = ({
  handleEdit,
  handleTransacoes,
  handleReajusteSaldo,
  handleNewTransacao,
  handleDeletar,
  labelPrevisto,
  handleSessoes,
  handleTransacoesUltimaSessao,
  ...props
}) => {
  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = useState(null);
  const { nome, color, saldoAtual } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Card
        style={{ borderRadius: '20px', cursor: 'pointer' }}
        className={classes.card}
      >
        <CardHeader
          avatar={<Avatar aria-label="recipe" texto={nome} color={color} />}
          action={
            <IconButton
              aria-label="settings"
              tabindex="-1"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
          }
          title={
            <Tooltip title={nome} placement="bottom" aria-label="add2">
              <Typography className={classes.text}>{nome}</Typography>
            </Tooltip>
          }
          classes={{ title: classes.header, content: classes.contentHedare }}
        />
        <CardContent
          className={classes.title}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <TypographyValor valor={saldoAtual} />
          </div>
        </CardContent>
        <Divider />
      </Card>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Zoom}
        transitionDuration={500}
      >
        <StyledMenuItem
          onClick={() => {
            handleClose();
            handleEdit(props);
          }}
        >
          <ListItemText primary="Editar" />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            handleClose();
            handleSessoes(props);
          }}
        >
          <ListItemText primary="Sessões" />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            handleClose();
            handleTransacoesUltimaSessao(props);
          }}
        >
          <ListItemText primary="Transações Última Sessão" />
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};

const CategoryGrid = ({
  handleEdit = () => {},
  handleCriar = () => {},
  handleDeletar = () => {},
  handleSessoes = () => {},
  handleTransacoesUltimaSessao = () => {},
  ...props
}) => {
  const classes = useStyles(props);
  const { data, ids } = useListContext();
  return ids ? (
    <div style={{ flex: 2 }}>
      <Grid container spacing={2} className={classes.root}>
        {ids.map((id) => (
          <Grid key={id} xs={12} sm={3} md={4} lg={3} xl={2} item>
            <CardConta
              {...data[id]}
              handleEdit={handleEdit}
              handleDeletar={handleDeletar}
              handleSessoes={handleSessoes}
              handleTransacoesUltimaSessao={handleTransacoesUltimaSessao}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  ) : null;
};

const CardTotal = ({ label, valor, icon, cor }) => (
  <Card style={{ borderRadius: '20px', cursor: 'pointer' }}>
    <CardContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%',
          alignItems: 'center',
        }}
      >
        <div>
          <div
            style={{
              fontSize: '17px',
              color: '#a1c3d1',
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {label}
            <ArrowForwardIosIcon
              style={{ fontSize: '10px', marginLeft: '5px' }}
            />
          </div>
          <div style={{ fontSize: '20px', color: 'black', fontWeight: 'bold' }}>
            {formatMoeda(valor)}
          </div>
        </div>
        <div>
          <Avatar2
            aria-label="recipe"
            style={{ backgroundColor: cor, color: 'white' }}
          >
            {icon}
          </Avatar2>
        </div>
      </div>
    </CardContent>
  </Card>
);

const Aside = () => {
  const { data, ids } = useListContext();

  function calculaTotais() {
    let saldoCaixas = 0;

    for (let i = 0; i < ids.length; i += 1) {
      saldoCaixas += data[ids[i]].saldoAtual;
    }

    return {
      saldoCaixas,
    };
  }

  const totais = calculaTotais();

  return (
    <Grid container spacing={2}>
      <Grid key={1} xs={12} sm={3} md={4} lg={3} xl={2} item>
        <CardTotal
          label="Saldo caixas"
          valor={totais.saldoCaixas}
          icon={<LocalAtmIcon />}
          cor="#4caf67"
        />
      </Grid>
      <Grid key={2} xs={12} sm={3} md={4} lg={3} xl={2} item>
        <div />
      </Grid>
      <Grid key={3} xs={12} sm={3} md={4} lg={3} xl={2} item>
        <div />
      </Grid>
    </Grid>
  );
};

const MyList = ({ ...props }) => {
  const refresh = useRefresh();
  const refDialogo = useRef(null);
  const refDialogoDeletar = useRef(null);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const create = (tipo) => {
    if (refDialogo.current) {
      refDialogo.current.handleCreateCaixa(tipo);
    }
  };

  const edit = (data) => {
    if (refDialogo.current) {
      refDialogo.current.handleEdit(data.id);
    }
  };

  const deletar = (data) => {
    if (refDialogoDeletar.current) {
      refDialogoDeletar.current.handleOpen(data.id);
    }
  };

  const sessoes = (data) => {
    const queryOj = {};

    queryOj.displayedFilters = JSON.stringify({
      conta_id: true,
    });
    queryOj.filter = JSON.stringify({
      conta_id: data.id,
      is_fechamento: false,
    });

    history.push(
      `/sessoes?${stringify(queryOj)}&order=desc&page=1&perPage=10&sort=id`,
    );
  };

  const transacoesUltimaSessao = (data) => {
    if (data.ultimaSessao) {
      const queryOj = {};

      queryOj.displayedFilters = JSON.stringify({
        conta_id: true,
        id_sessao2: true,
        visible: true,
      });
      queryOj.filter = JSON.stringify({
        conta_id: data.id,
        id_sessao2: data.ultimaSessao,
        visible: true,
      });

      history.push(
        `/transacoes_limpas?${stringify(
          queryOj,
        )}&order=ASC&page=1&perPage=50&sort=id`,
      );
    } else {
      enqueueSnackbar('Este caixa ainda não contém sessões!', {
        variant: 'error',
      });
    }
  };

  const handleClose = () => {
    refresh();
  };

  return (
    <>
      <ListToolbar
        filters={<Filter />}
        actions={<Actions handleCriar={create} />}
        {...props}
      />
      <div style={{ borderRadius: '20px', marginTop: '30px' }}>
        <Aside {...props} />
        <CategoryGrid
          handleEdit={edit}
          handleCriar={create}
          handleDeletar={deletar}
          handleSessoes={sessoes}
          handleTransacoesUltimaSessao={transacoesUltimaSessao}
          {...props}
        />
      </div>
      <DialogoConta ref={refDialogo} handleClose={handleClose} />
      <DialogoDeletarConta ref={refDialogoDeletar} handleClose={handleClose} />
    </>
  );
};

const ContainerMyList = ({ ...props }) => (
  <ListBase {...props}>
    <MyList />
  </ListBase>
);

const CategoryList = (props) => (
  <>
    <ContainerMyList
      {...props}
      title=""
      component="div"
      bulkActionButtons={false}
      sort={{ field: 'nome', order: 'asc' }}
      filterDefaultValues={{
        tipo: 'Despesa',
        periodo: { ano: new Date().getFullYear(), mes: new Date().getMonth() },
        status: STATUS_ATIVO,
      }}
      perPage={50}
      pagination={false}
    />
  </>
);

export default CategoryList;
