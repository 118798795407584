import React from 'react';
import {
  Filter as FilterAdmin,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <TextInput label="Pesquisar" source="search" />
    <ReferenceInput
      fullWidth
      label="Tag"
      source="categoria_id"
      reference="categorias_perguntas"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      perPage={30}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput>
  </FilterAdmin>
);

export default Filter;
