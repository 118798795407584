import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';

const ValorField = (props) => {
  const record = useRecordContext(props);
  const copias = record ? record.record.copias : 0;
  return (
    <Typography
      variant="subtitle1"
      style={{
        // color: tipo === 'Receita' ? '#4caf67' : '#f44336',
        fontWeight: 'bold',
      }}
    >
      {copias}
    </Typography>
  );
};



export default ValorField;
