import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
  useLayoutEffect,
} from 'react';

import { Box } from '@material-ui/core';
// import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import LinkIcon from '@material-ui/icons/Link';
import LockIcon from '@material-ui/icons/Lock';
import NoteIcon from '@material-ui/icons/Note';
import RefreshIcon from '@material-ui/icons/Refresh';
import StayCurrentPortraitIcon from '@material-ui/icons/StayCurrentPortrait';
import TodayIcon from '@material-ui/icons/Today';
import MaterialIcon, { colorPalette } from 'material-icons-react';
import { useSnackbar } from 'notistack';

import {
  getListAllAPI,
  createAPI,
  updateAPI,
  getUserId,
} from '../../../services';
import { STATUS_ATIVO } from '../../../utils/constants';
import {
  PrecoInput,
  SwitchInput,
  DateInputBonito,
  TextInput,
  AutoCompleteLocalCustomizado,
  SelectInput,
  AutoCompleteRemoto,
} from '../../Inputs';
import DialogoSenha from './DialogoSenha';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    // backgroundColor: (props) => props.cor,
    // color: 'white',
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    // backgroundColor: 'rgba(0, 0, 0, 0.12)',
    // color: 'red',
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const RECEITA = 'Receita';
const DESPESA = 'Despesa';
const TRANSFERENCIA = 'Transferência';

const useStyles3 = makeStyles({
  chip: {
    borderColor: (props) => props.color,
    backgroundColor: 'white',
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  deleteIcon: {
    color: (props) => props.color,
    cursor: 'pointer',
    '&:hover': {
      color: (props) => props.color,
    },
  },
});

const ChipCategoria = ({ option, injetado, campo }) => {
  const classes2 = useStyles3({ color: option.color });
  const { onDelete, ...rest } = injetado;
  return (
    <Chip
      classes={{ outlined: classes2.chip, deleteIcon: classes2.deleteIcon }}
      variant="outlined"
      label={option[campo]}
      {...rest}
      avatar={
        <Avatar style={{ backgroundColor: 'white', marginRight: '3px' }}>
          <MaterialIcon icon={option.icon} size={20} color={option.color} />
        </Avatar>
      }
    />
  );
};

const ChipCategoriaProblema = ({ option, injetado, campo }) => {
  const classes2 = useStyles3({ color: 'black' });
  const { onDelete, ...rest } = injetado;
  return (
    <Chip
      classes={{ outlined: classes2.chip, deleteIcon: classes2.deleteIcon }}
      variant="outlined"
      label={option[campo]}
      {...rest}
      avatar={
        <Avatar
          style={{
            backgroundColor: 'black',
            marginRight: '3px',
            color: 'white',
          }}
        >
          {option.nome.charAt(0)}
        </Avatar>
      }
    />
  );
};

const ChipConta = ({ option, injetado, campo }) => {
  const classes2 = useStyles3({ color: option.color });
  const { onDelete, ...rest } = injetado;
  return (
    <Chip
      classes={{ outlined: classes2.chip, deleteIcon: classes2.deleteIcon }}
      variant="outlined"
      label={option[campo]}
      {...rest}
      avatar={
        <Avatar
          style={{
            backgroundColor: option.color,
            marginRight: '3px',
            color: 'white',
          }}
        >
          {option.nome.charAt(0)}
        </Avatar>
      }
    />
  );
};

const POR_ANO = 'Anual';
const POR_MES = 'Mensal';
const POR_SEMANA = 'Semanal';
const POR_DIA = 'Diario';

const options = [
  {
    label: 'Dias',
    value: POR_DIA,
  },
  {
    label: 'Semanas',
    value: POR_SEMANA,
  },
  {
    label: 'Meses',
    value: POR_MES,
  },
  {
    label: 'Anos',
    value: POR_ANO,
  },
];

const ESPACO_INPUTS = '24px';
const TAMANHO_PEQUENO = 'sm';
const TAMANHO_GRANDE = 'lg';

const DEFAULT_DESCRICAO = '';
const DEFAULT_CONTA = [];
const DEFAULT_CONTA_DESTINO = [];
const DEFAULT_CATEGORIA = [];
const DEFAULT_VALOR = 0;
const DEFAULT_DATA_PAGAMENTO = new Date();
const DEFAULT_ID = -1;
const DEFAULT_TAMANHO = TAMANHO_PEQUENO;
const DEFAULT_TIPO_EDICAO = 0;
const DEFAULT_TIPO_PERIODO = POR_MES;
const DEFAULT_GRUPO_ID = -1;
const DEFAULT_REPETIR = false;
const DEFAULT_VEZES_REPETIR = 2;
const DEFAULT_STATUS = true;

//////////////
const DEFAULT_JUROS = 0;
const DEFAULT_MORA = 0;
const DEFAULT_MULTA = 0;
const DEFAULT_DESCONTO = 0;
const DEFAULT_NUMERO_BOLETO = '';
///////////////////

const Vazio = [
  {
    nome: 'Despesa Não problemática',
    id: -1,
    cor: 'red',
    icone: '',
  },
];

function makeData2500() {
  const data = new Date();
  data.setFullYear(2500);
  data.setMonth(2);
  data.setDate(10);
  data.setHours(5);
  data.setMinutes(30);
  data.setSeconds(0);
  data.setMilliseconds(0);

  return data;
}

const DialogoCategoriaTransacao = forwardRef(
  (
    {
      handleClose,
      // cor = '#2196f3',
      campo = 'nome',
    },
    ref,
  ) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [descricao, setDescricao] = useState(DEFAULT_DESCRICAO);
    const [conta, setConta] = useState(DEFAULT_CONTA);
    const [contaDestino, setContaDestino] = useState(DEFAULT_CONTA_DESTINO);
    const [categoria, setCategoria] = useState(DEFAULT_CATEGORIA);
    const [categoriaProblemas, setCategoriaProblema] = useState(
      DEFAULT_CATEGORIA,
    );
    const [valorOriginal, setValorOriginal] = useState(DEFAULT_VALOR);
    const [dataPagamento, setDataPagamento] = useState(DEFAULT_DATA_PAGAMENTO);
    const [contasOptions, setContasOptions] = useState([]);
    const [categoriasOptions, setCategoriasOptions] = useState([]);
    const [
      categoriasProblemasOptions,
      setCategoriasProblemasOptions,
    ] = useState([]);
    const [id, setId] = useState(DEFAULT_ID);
    const [tipoTransacao, setTipoTransacao] = useState(RECEITA);
    const classes = useStyles();
    const [tamanho, setTamanho] = useState(DEFAULT_TAMANHO);
    const [tipoEdicao, setTipoEdicao] = React.useState(DEFAULT_TIPO_EDICAO);
    const [tipoPeriodo, setTipoPeriodo] = useState(DEFAULT_TIPO_PERIODO);
    const [grupoId, setGrupoId] = useState(DEFAULT_GRUPO_ID);
    const [repetir, setRepetir] = useState(DEFAULT_REPETIR);
    const [vezesRepetir, setVezesRepetir] = useState(DEFAULT_VEZES_REPETIR);
    const [status, setStatus] = useState(DEFAULT_STATUS);
    const [carregando, setCarregando] = useState(false);
    const [contasSemCaixasOptions, setContasSemCaixasOptions] = useState([]);
    const [dataPagamentoOriginal, setDataPagamentoOriginal] = useState(
      new Date(),
    );
    const [descricaoProblema, setDescricaoProblema] = useState('');
    //
    const [juros, setJuros] = useState(DEFAULT_JUROS);
    const [mora, setMora] = useState(DEFAULT_MORA);
    const [multa, setMulta] = useState(DEFAULT_MULTA);
    const [desconto, setDesconto] = useState(DEFAULT_DESCONTO);
    const [numeroBoleto, setNumeroBoleto] = useState(DEFAULT_NUMERO_BOLETO);
    //
    const [
      dataPagamentoOriginalCorreto,
      setDataPagamentoOriginalCorreto,
    ] = useState(new Date());
    //
    const [podeMudarDataOriginal, setPodeMudarDataOriginal] = useState(false);
    //
    const [fornecedor, setFornecedor] = useState(null);
    const [isParcelaCompra, setIsParcelaCompra] = useState(false);
    const [idFornecedorOriginal, setIdFornecedorOriginal] = useState(0);
    //
    const refValorField = useRef(null);
    const refDialogoSenha = useRef(null);

    const handleChange = (event) => {
      setTipoEdicao(parseInt(event.target.value, 10));
    };

    /* useEffect(() => {

    if (open) {
      getContas();
    }
  }, [open]); */

    async function getContas(
      tipoTransacao,
      idIncluso = [],
      idInclusoCategoria = [],
      dInclusoCategoriaProblemas = [],
    ) {
      // setCarregando(true);
      const dataContas = await getListAllAPI(
        'contas_normal',
        ['id', 'asc'],
        idIncluso.length === 0
          ? { is_caixa: false, status: STATUS_ATIVO }
          : { is_caixa: false, status: STATUS_ATIVO, ids_incluso: idIncluso },
        [],
      );

      const dataContasSemCaixas = await getListAllAPI(
        'contas_normal',
        ['id', 'asc'],
        idIncluso.length === 0
          ? { is_caixa: false, status: STATUS_ATIVO }
          : { is_caixa: false, status: STATUS_ATIVO, ids_incluso: idIncluso },
        [],
      );

      const filterCategoria = {};

      switch (tipoTransacao) {
        case RECEITA:
          filterCategoria.tipo = 'Receita';
          break;
        case DESPESA:
          filterCategoria.tipo = 'Despesa';
          break;
        default:
          break;
      }

      const dataCategorias = await getListAllAPI(
        'categorias_transacao',
        ['id', 'asc'],
        idInclusoCategoria.length === 0
          ? { status: STATUS_ATIVO, ...filterCategoria, gerenciado: false }
          : {
              status: STATUS_ATIVO,
              ids_incluso: idInclusoCategoria,
              ...filterCategoria,
              gerenciado: false,
            },
        [],
      );

      const dataCategoriasProblemas = await getListAllAPI(
        'categorias-problemas-despesas',
        ['id', 'asc'],
        dInclusoCategoriaProblemas.length === 0
          ? { status: STATUS_ATIVO }
          : {
              status: STATUS_ATIVO,
              ids_incluso: dInclusoCategoriaProblemas,
            },
        [],
      );

      setContasOptions(dataContas.data);
      setContasSemCaixasOptions(dataContasSemCaixas.data);
      setCategoriasOptions(dataCategorias.data);
      setCategoriasProblemasOptions([
        Vazio[0],
        ...dataCategoriasProblemas.data,
      ]);
      // setCarregando(false);
    }

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    useEffect(() => {
      if (contaDestino.length > 0) {
        if (conta.length > 0) {
          if (conta[0].id === contaDestino[0].id) {
            setConta([]);
          }
        }
      }
    }, [contaDestino]);

    useEffect(() => {
      if (contaDestino.length > 0) {
        if (conta.length > 0) {
          if (conta[0].id === contaDestino[0].id) {
            setContaDestino([]);
          }
        }
      }
    }, [conta]);

    async function reiniciar(tipoTransacao) {
      try {
        await getContas(tipoTransacao);
        setDescricao(DEFAULT_DESCRICAO);
        setConta(DEFAULT_CONTA);
        setContaDestino(DEFAULT_CONTA_DESTINO);
        setCategoria(DEFAULT_CATEGORIA);
        setValorOriginal(DEFAULT_VALOR);
        setDataPagamento(DEFAULT_DATA_PAGAMENTO);
        setId(DEFAULT_ID);
        setTipoTransacao(tipoTransacao);
        setTamanho(DEFAULT_TAMANHO);
        setTipoEdicao(DEFAULT_TIPO_EDICAO);
        setTipoPeriodo(DEFAULT_TIPO_PERIODO);
        setGrupoId(DEFAULT_GRUPO_ID);
        setRepetir(DEFAULT_REPETIR);
        setVezesRepetir(DEFAULT_VEZES_REPETIR);
        setCarregando(false);
        setDataPagamentoOriginal(new Date());
        setCategoriaProblema([]);
        setDescricaoProblema('');
        //////
        setJuros(DEFAULT_JUROS);
        setMora(DEFAULT_MORA);
        setMulta(DEFAULT_MULTA);
        setDesconto(DEFAULT_DESCONTO);
        setNumeroBoleto(DEFAULT_NUMERO_BOLETO);
        setDataPagamentoOriginalCorreto(new Date());
        //////
        setPodeMudarDataOriginal(false);
        ///
        setFornecedor(null);
        setIsParcelaCompra(false);
        setIdFornecedorOriginal(0);
        ///
        setStatus(false);
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    async function reiniciarApartirConta(tipoTransacao, idConta) {
      try {
        const dataContas = await getListAllAPI(
          'contas_normal',
          ['id', 'asc'],
          { id: [idConta] },
          [],
        );
        if (dataContas.data.length <= 0) throw 'Esta conta não existe!';
        await getContas(tipoTransacao);
        setDescricao(DEFAULT_DESCRICAO);
        setConta([dataContas.data[0]]);
        setContaDestino(DEFAULT_CONTA_DESTINO);
        setCategoria(DEFAULT_CATEGORIA);
        setValorOriginal(DEFAULT_VALOR);
        setDataPagamento(DEFAULT_DATA_PAGAMENTO);
        setId(DEFAULT_ID);
        setTipoTransacao(tipoTransacao);
        setTamanho(DEFAULT_TAMANHO);
        setTipoEdicao(DEFAULT_TIPO_EDICAO);
        setTipoPeriodo(DEFAULT_TIPO_PERIODO);
        setGrupoId(DEFAULT_GRUPO_ID);
        setRepetir(DEFAULT_REPETIR);
        setVezesRepetir(DEFAULT_VEZES_REPETIR);
        setCarregando(false);
        setDataPagamentoOriginal(new Date());
        setCategoriaProblema([]);
        setDescricaoProblema('');
        //////
        setJuros(DEFAULT_JUROS);
        setMora(DEFAULT_MORA);
        setMulta(DEFAULT_MULTA);
        setDesconto(DEFAULT_DESCONTO);
        setNumeroBoleto(DEFAULT_NUMERO_BOLETO);
        setDataPagamentoOriginalCorreto(new Date());
        //////
        setPodeMudarDataOriginal(false);
        ///
        setFornecedor(null);
        setIsParcelaCompra(false);
        setIdFornecedorOriginal(0);
        ///
        setStatus(false);
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    async function getDataResource(id) {
      try {
        const data = await getListAllAPI(
          'transacoes',
          ['id', 'asc'],
          { id: [id] },
          ['conta', 'categoria', 'transacao.conta', 'problema', 'fornecedor'],
        );
        if (data.data.length > 0) {
          if (data.data[0].is_transferencia) {
            await getContas(TRANSFERENCIA, [
              data.data[0].transacao.conta.id,
              data.data[0].conta.id,
            ]);
            setDescricao(DEFAULT_DESCRICAO);
            setConta(
              data.data[0].tipo === RECEITA
                ? [data.data[0].transacao.conta]
                : [data.data[0].conta],
            );
            setContaDestino(
              data.data[0].tipo === RECEITA
                ? [data.data[0].conta]
                : [data.data[0].transacao.conta],
            );
            setCategoria([]);
            setCategoriaProblema([]);
            setValorOriginal(data.data[0].valor_original);
            setDataPagamento(new Date(data.data[0].data_pagamento));
            setTipoTransacao(TRANSFERENCIA);
            setTamanho(TAMANHO_PEQUENO);
            setTipoEdicao(DEFAULT_TIPO_EDICAO);
            setTipoPeriodo(DEFAULT_TIPO_PERIODO);
            setGrupoId(DEFAULT_GRUPO_ID);
            setRepetir(DEFAULT_REPETIR);
            setVezesRepetir(DEFAULT_VEZES_REPETIR);
            setDataPagamentoOriginal(new Date(data.data[0].data_pagamento));
            setDescricaoProblema('');
            //
            //////
            setJuros(data.data[0].juros);
            setMora(data.data[0].mora);
            setMulta(data.data[0].multa);
            setDesconto(data.data[0].desconto);
            setNumeroBoleto(data.data[0].codigo_boleto);
            setDataPagamentoOriginalCorreto(
              new Date(data.data[0].data_pagamento_original),
            );
            setPodeMudarDataOriginal(false);
            ////
            setFornecedor(data.data[0].fornecedor);
            setIsParcelaCompra(false);
            setIdFornecedorOriginal(0);
            //////
          } else {
            setStatus(data.data[0].status);
            await getContas(
              data.data[0].tipo,
              [data.data[0].conta.id],
              [data.data[0].categoria.id],
              data.data[0].problema ? [data.data[0].problema.id] : [],
            );
            setDescricao(data.data[0].nome);
            setConta([data.data[0].conta]);
            setContaDestino([]);
            setCategoria([data.data[0].categoria]);
            setCategoriaProblema(
              data.data[0].problema ? [data.data[0].problema] : [],
            );
            setValorOriginal(data.data[0].valor_original);
            setDataPagamento(
              data.data[0].categoria_problema_despesa_id === 1
                ? new Date()
                : new Date(data.data[0].data_pagamento),
            );
            setTipoTransacao(data.data[0].tipo === RECEITA ? RECEITA : DESPESA);
            setTamanho(
              data.data[0].grupo_id ? TAMANHO_GRANDE : TAMANHO_PEQUENO,
            );
            setTipoEdicao(DEFAULT_TIPO_EDICAO);
            setTipoPeriodo(DEFAULT_TIPO_PERIODO);
            setGrupoId(
              data.data[0].grupo_id ? data.data[0].grupo_id : DEFAULT_GRUPO_ID,
            );
            setRepetir(DEFAULT_REPETIR);
            setVezesRepetir(DEFAULT_VEZES_REPETIR);
            setDataPagamentoOriginal(new Date(data.data[0].data_pagamento));
            setDescricaoProblema('');
            //////
            setJuros(data.data[0].juros);
            setMora(data.data[0].mora);
            setMulta(data.data[0].multa);
            setDesconto(data.data[0].desconto);
            setNumeroBoleto(data.data[0].codigo_boleto);
            setDataPagamentoOriginalCorreto(
              new Date(data.data[0].data_pagamento_original),
            );
            setPodeMudarDataOriginal(false);
            ///
            setFornecedor(data.data[0].fornecedor);
            setIsParcelaCompra(!!data.data[0].parcela_compra_id);
            setIdFornecedorOriginal(
              data.data[0].fornecedor ? data.data[0].fornecedor.id : 0,
            );
            ///
            //////
            // setDescricaoProblema(
            //   data.data[0].problema
            //     ? data.data[0].descricao_problema
            //       ? data.data[0].descricao_problema
            //       : ''
            //     : '',
            // );
            //descricao_problema
          }
          setCarregando(false);
        } else {
          throw 'Esta transação não existe!';
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    async function handleSalvar() {
      setCarregando(true);
      try {
        if (id <= 0) {
          const isDespesaSemData = isProblemaSemData();
          await createAPI('transacoes', {
            nome: descricao,
            valor_original: valorOriginal,
            conta_id: conta[0].id,
            // data_pagamento: dataPagamento,
            categoria_transacao_2_id:
              categoria.length > 0 ? categoria[0].id : null,
            status: isDespesaSemData ? false : status,
            tipo: tipoTransacao,
            conta_destino_id:
              contaDestino.length > 0 ? contaDestino[0].id : null,
            categoria_problema_despesa_id:
              categoriaProblemas.length > 0 ? categoriaProblemas[0].id : null,
            repetir,
            vezesRepetir,
            tipoPeriodo,
            descricao_problema:
              categoriaProblemas.length > 0 ? descricaoProblema : '',
            user_id: getUserId(),
            juros: tipoTransacao === DESPESA && status ? juros : 0,
            mora: tipoTransacao === DESPESA && status ? mora : 0,
            multa: tipoTransacao === DESPESA && status ? multa : 0,
            desconto: tipoTransacao === DESPESA ? desconto : 0,
            codigo_boleto: tipoTransacao === DESPESA ? numeroBoleto : '',
            data_pagamento: hasCaixa
              ? new Date()
              : isDespesaSemData
              ? makeData2500()
              : dataPagamento,
            data_pagamento_original: isEditHasCaixa
              ? dataPagamentoOriginal
              : isDespesaSemData
              ? makeData2500()
              : dataPagamentoOriginalCorreto,
            fornecedor_id:
              tipoTransacao === DESPESA ? (fornecedor ? fornecedor.id : 1) : 1,
          });
          setCarregando(false);
          enqueueSnackbar(`${tipoTransacao} criada com sucesso!`, {
            variant: 'success',
          });
        } else {
          const isDespesaSemData = isProblemaSemData();
          await updateAPI('transacoes', id, {
            nome: descricao,
            valor_original: valorOriginal,
            conta_id: conta[0].id,
            // data_pagamento: dataPagamento,
            categoria_transacao_2_id:
              categoria.length > 0 ? categoria[0].id : null,
            categoria_problema_despesa_id:
              categoriaProblemas.length > 0 ? categoriaProblemas[0].id : null,
            status: isDespesaSemData ? false : status,
            conta_destino_id:
              contaDestino.length > 0 ? contaDestino[0].id : null,
            tipoEdicao,
            descricao_problema:
              categoriaProblemas.length > 0 ? descricaoProblema : '',
            user_id: getUserId(),
            juros: tipoTransacao === DESPESA && status ? juros : 0,
            mora: tipoTransacao === DESPESA && status ? mora : 0,
            multa: tipoTransacao === DESPESA && status ? multa : 0,
            desconto: tipoTransacao === DESPESA ? desconto : 0,
            codigo_boleto: tipoTransacao === DESPESA ? numeroBoleto : '',
            data_pagamento: isEditHasCaixa
              ? dataPagamentoOriginal
              : isDespesaSemData
              ? makeData2500()
              : // : tipoTransacao === DESPESA && !status
                // ? dataPagamentoOriginalCorreto
                dataPagamento,
            data_pagamento_original: isEditHasCaixa
              ? dataPagamentoOriginal
              : isDespesaSemData
              ? makeData2500()
              : dataPagamentoOriginalCorreto,
            //: tipoTransacao === DESPESA
            //? dataPagamentoOriginalCorreto
            //: dataPagamento,
            fornecedor_id:
              tipoTransacao === DESPESA ? (fornecedor ? fornecedor.id : 1) : 1,
          });
          setCarregando(false);
          enqueueSnackbar(`${tipoTransacao} atualizada com sucesso!`, {
            variant: 'success',
          });
        }
        handleCloseDialog();
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    function focusValorField() {
      if (refValorField.current) {
        refValorField.current.focus();
        refValorField.current.select();
      }
    }

    const tempo = 1000;

    useEffect(() => {
      const timer = setTimeout(() => {
        if (open) {
          focusValorField();
        }
      }, tempo);
      return () => clearTimeout(timer);
    }, [open]);

    /* useLayoutEffect(() => {
    if (open) {
      focusValorField();
    }
  }, []); */

    useImperativeHandle(ref, () => ({
      handleCreate(tipoTransacao) {
        setCarregando(true);
        reiniciar(tipoTransacao);
        setOpen(true);
        // focusValorField();
      },
      handleCreateApartirConta(tipoTransacao, idConta) {
        setCarregando(true);
        reiniciarApartirConta(tipoTransacao, idConta);
        setOpen(true);
        // focusValorField();
      },
      handleEdit(id) {
        setCarregando(true);
        getDataResource(id);
        setOpen(true);
        setId(id);
        // focusValorField();
      },
    }));

    const renderTagConta = (option, injetado) => (
      <ChipConta option={option} injetado={injetado} campo={campo} />
    );

    const renderOptionConta = (option) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          style={{
            backgroundColor: option.color,
            marginRight: '10px',
            width: '30px',
            height: '30px',
          }}
        >
          {option.nome.charAt(0)}
        </Avatar>
        {option[campo]}
      </div>
    );

    const renderTagCategoria = (option, injetado) => (
      <ChipCategoria option={option} injetado={injetado} campo={campo} />
    );

    const renderOptionCategoria = (option) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          style={{
            backgroundColor: option.color,
            marginRight: '10px',
            width: '30px',
            height: '30px',
          }}
        >
          <MaterialIcon icon={option.icon} size={20} color="#000000" />
        </Avatar>
        {option[campo]}
      </div>
    );

    const renderTagCategoriaProblema = (option, injetado) => (
      <ChipCategoriaProblema
        option={option}
        injetado={injetado}
        campo={campo}
        color="black"
      />
    );

    const renderOptionCategoriaProblema = (option) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {option[campo]}
      </div>
    );

    const handleOnChangeConta = (value) => {
      if (value.length > 0) {
        if (value.length === 1) {
          setConta([value[0]]);
        } else if (value.length === 2) {
          setConta([value[1]]);
        }
      } else {
        setConta([]);
      }
    };

    const handleOnChangeConta2 = (value) => {
      if (value.length > 0) {
        if (value.length === 1) {
          setContaDestino([value[0]]);
        } else if (value.length === 2) {
          setContaDestino([value[1]]);
        }
      } else {
        setContaDestino([]);
      }
    };

    const handleOnChangeCategoria = (value) => {
      if (value.length > 0) {
        if (value.length === 1) {
          setCategoria([value[0]]);
        } else if (value.length === 2) {
          setCategoria([value[1]]);
        }
      } else {
        setCategoria([]);
      }
    };

    const handleOnChangeCategoriaProblema = (value) => {
      // if (value.length > 0) {
      //   if (value.length === 1) {
      //     setCategoriaProblema([value[0]]);
      //   } else if (value.length === 2) {
      //     setCategoriaProblema([value[1]]);
      //   }
      // } else {
      //   setCategoriaProblema([]);
      // }
      if (value.length > 0) {
        if (value[0].id < 0) {
          if (value.length === 1) {
            setCategoriaProblema([]);
          } else {
            const valorCategoria = value[value.length - 1];
            if (valorCategoria.id < 0) {
              setCategoriaProblema([]);
            } else {
              setCategoriaProblema([valorCategoria]);
            }
          }
        } else {
          const valorCategoria = value[value.length - 1];
          if (valorCategoria.id < 0) {
            setCategoriaProblema([]);
          } else {
            setCategoriaProblema([valorCategoria]);
          }
        }
      } else {
        setCategoriaProblema(value);
      }
    };

    function getErrorDataPagamento() {
      const ano = dataPagamento.getFullYear();

      const isProblemaSemDataValue = isProblemaSemData();

      if (isProblemaSemDataValue) {
        return '';
      }
      if (ano > 2100) {
        return 'O ano máximo é de 2100!';
      }
      return '';
    }

    function validateDataPagamentoOriginal() {
      if (tipoTransacao === DESPESA && !repetir && status) {
        const ano = dataPagamentoOriginalCorreto.getFullYear();

        const isProblemaSemDataValue = isProblemaSemData();

        if (isProblemaSemDataValue) {
          return '';
        }
        if (ano > 2100) {
          return 'O ano máximo é de 2100!';
        }
        return '';
      }
      return '';
    }

    function validateJuros() {
      let error = '';
      if (tipoTransacao === DESPESA && !repetir && status) {
        if (juros < 0 || Number.isNaN(juros)) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    function validateMora() {
      let error = '';
      if (tipoTransacao === DESPESA && !repetir && status) {
        if (mora < 0 || Number.isNaN(mora)) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    function validateMulta() {
      let error = '';
      if (tipoTransacao === DESPESA && !repetir && status) {
        if (multa < 0 || Number.isNaN(multa)) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    function validateDesconto() {
      let error = '';
      if (tipoTransacao === DESPESA && !repetir) {
        if (desconto < 0 || Number.isNaN(desconto)) {
          error = 'Este campo é obrigatório';
        } else if (desconto > valorOriginal) {
          error = 'O desconto não pode ser maior que o valor original!';
        }
      }
      return error;
    }

    function validateNumeroBoleto() {
      let error = '';
      if (tipoTransacao === DESPESA && !repetir) {
        if (numeroBoleto) {
          if (numeroBoleto.length < 47) {
            error = 'O número do boleto deve ter no mínimo 47 dígitos!';
          }
        }
      }
      return error;
    }

    function validateFornecedor() {
      let error = '';
      if (tipoTransacao === DESPESA) {
        if (!fornecedor) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    const errorValor =
      valorOriginal <= 0 || Number.isNaN(valorOriginal)
        ? 'Deve ter um valor maior que 0'
        : '';
    const errorConta = conta.length <= 0 ? 'Este campo é obrigatório' : '';
    const errorContaDestino =
      contaDestino.length <= 0 ? 'Este campo é obrigatório' : '';
    const errorCategoria =
      categoria.length <= 0 ? 'Este campo é obrigatório' : '';
    const errorCategoriaProblema = categoriaProblemas.length <= 0 ? '' : '';
    const errorVezesRepetir =
      vezesRepetir < 2 ? 'Deve ser um valor maior que 2' : '';
    const errorDescricao =
      descricao === '' || descricao === null ? 'Este campo é obrigatório' : '';
    const errorDescricaoProblema =
      descricaoProblema.length > 200
        ? 'A descrição deve ter no máximo 200 caracteres'
        : '';
    const errorDataPagamento = getErrorDataPagamento();

    const errorJuros = validateJuros();
    const errorMora = validateMora();
    const errorMulta = validateMulta();
    const errorDesconto = validateDesconto();
    const errorBoleto = validateNumeroBoleto();
    const errorDataPagamentoOriginal = validateDataPagamentoOriginal();
    const errorFornecedor = validateFornecedor();

    function isDisabledSubmit() {
      switch (tipoTransacao) {
        case TRANSFERENCIA:
          return (
            errorValor !== '' ||
            errorConta !== '' ||
            errorContaDestino !== '' ||
            errorDataPagamento !== ''
          );
        default:
          return (
            errorValor !== '' ||
            errorConta !== '' ||
            errorCategoria !== '' ||
            errorDescricao !== '' ||
            (errorVezesRepetir !== '' && repetir === true) ||
            errorDataPagamento !== '' ||
            errorJuros !== '' ||
            errorMora !== '' ||
            errorMulta !== '' ||
            errorDesconto !== '' ||
            errorBoleto !== '' ||
            errorDataPagamentoOriginal !== '' ||
            errorFornecedor !== ''
          );
      }
    }

    function getColor() {
      switch (tipoTransacao) {
        case RECEITA:
          return '#4caf50';
        case DESPESA:
          return '#f44336';
        case TRANSFERENCIA:
          return '#009FD4';
        default:
          return '#009FD4';
      }
    }

    const hasCaixa =
      tipoTransacao === TRANSFERENCIA
        ? (conta.length > 0 ? conta[0].is_caixa : false) ||
          (contaDestino.length > 0 ? contaDestino[0].is_caixa : false)
        : false;

    const isEditHasCaixa = id > 0 && hasCaixa;

    function isProblemaSemData() {
      if (categoriaProblemas.length > 0) {
        const categoria = categoriaProblemas[0];
        if (categoria.id === 1) {
          return true;
        }
        return false;
      }
      return false;
    }

    const isProblemaSemDataValue = isProblemaSemData();

    const valor_real =
      valorOriginal - desconto + (status ? juros + mora + multa : 0);

    const totalEncargos = status ? juros + mora + multa : 0;

    function handleOpenDialogoSenha() {
      if (refDialogoSenha.current) {
        refDialogoSenha.current.handleOpen(0);
      }
    }

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullWidth
          maxWidth={tamanho}
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: getColor(), color: 'white' }}
          >
            {id <= 0 ? `Nova ${tipoTransacao}` : `Editar ${tipoTransacao}`}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                  <PrecoInput
                    name="valor"
                    // ref={refs.current[1]}
                    ref={refValorField}
                    handleEnter={() => {}}
                    label="Valor Original"
                    handleKey={() => {}}
                    value={
                      Number.isNaN(valorOriginal) ? '' : String(valorOriginal)
                    }
                    onChange={(value) => {
                      const newValue = parseFloat(value.target.value);
                      if (newValue <= 0 || Number.isNaN(newValue)) {
                        setValorOriginal(0);
                      } else {
                        setValorOriginal(newValue);
                      }
                      // setValor(parseFloat(value.target.value))
                    }}
                    error={errorValor !== ''}
                    helperText={errorValor}
                    style={{ marginBottom: ESPACO_INPUTS }}
                    fullWidth
                    variant="standard"
                    disabled={tipoEdicao === 2}
                    autoFocus
                    InputProps={{
                      startAdornment: (
                        <>
                          <StayCurrentPortraitIcon
                            style={{ marginRight: '24px' }}
                          />
                        </>
                      ),
                    }}
                  />

                  {tipoTransacao === DESPESA && (
                    <PrecoInput
                      name="valor_real"
                      handleEnter={() => {}}
                      label="Valor real"
                      handleKey={() => {}}
                      value={Number.isNaN(valor_real) ? '' : String(valor_real)}
                      onChange={() => {}}
                      style={{ marginBottom: ESPACO_INPUTS }}
                      fullWidth
                      variant="standard"
                      disabled
                      autoFocus
                      InputProps={{
                        startAdornment: (
                          <>
                            <StayCurrentPortraitIcon
                              style={{ marginRight: '24px' }}
                            />
                          </>
                        ),
                      }}
                    />
                  )}

                  {tipoTransacao !==
                    TRANSFERENCIA /*&& !isProblemaSemData()*/ && (
                    <div
                      style={{
                        marginBottom: ESPACO_INPUTS,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CheckCircleOutlineIcon
                          style={{ marginRight: '24px' }}
                        />
                        <div style={{ fontWeight: 'bold' }}>Foi paga</div>
                      </div>
                      <SwitchInput
                        name="caixa"
                        handleEnter={() => {}}
                        label=""
                        handleKey={() => {}}
                        disabled={tipoEdicao !== 0 || isProblemaSemDataValue}
                        checked={isProblemaSemDataValue ? false : status}
                        onChange={(value) => setStatus(value.target.checked)}
                      />
                    </div>
                  )}
                  {tipoTransacao === DESPESA && (
                    <div
                      style={{
                        display: 'flex',
                        gap: '30px',
                      }}
                    >
                      <DateInputBonito
                        showTodayButton
                        disableFuture={tipoTransacao === TRANSFERENCIA}
                        todayLabel="Hoje"
                        name="data_pagamento_original"
                        handleEnter={() => {}}
                        style={{
                          marginBottom: ESPACO_INPUTS,
                          marginTop: '0px',
                        }}
                        label="Data de pagamento original"
                        handleKey={() => {}}
                        value={
                          isProblemaSemDataValue
                            ? null
                            : dataPagamentoOriginalCorreto
                        }
                        onChange={(value) => {
                          setDataPagamentoOriginalCorreto(new Date(value));
                        }}
                        format="dd MMMM yyyy"
                        fullWidth
                        disabled={
                          tipoEdicao !== 0 ||
                          hasCaixa ||
                          isProblemaSemDataValue ||
                          !podeMudarDataOriginal
                        }
                        variant="standard"
                        InputProps={{
                          startAdornment: (
                            <>
                              <TodayIcon style={{ marginRight: '24px' }} />
                            </>
                          ),
                        }}
                      />
                      {!podeMudarDataOriginal && (
                        <IconButton
                          aria-label="check"
                          size="small"
                          onClick={() => handleOpenDialogoSenha()}
                        >
                          <LockIcon />
                        </IconButton>
                      )}
                    </div>
                  )}

                  {/*!(tipoTransacao === DESPESA && !status) ? (
                    <DateInputBonito
                      showTodayButton
                      disableFuture={tipoTransacao === TRANSFERENCIA}
                      todayLabel="Hoje"
                      name="data_pagamento"
                      handleEnter={() => {}}
                      style={{ marginBottom: ESPACO_INPUTS, marginTop: '0px' }}
                      label={
                        tipoTransacao === DESPESA
                          ? 'Data de pagamento real'
                          : 'Data de pagamento'
                      }
                      handleKey={() => {}}
                      value={
                        hasCaixa
                          ? dataPagamentoOriginal
                          : isProblemaSemDataValue
                          ? null
                          : dataPagamento
                      }
                      onChange={(value) => {
                        const newData = new Date(value);

                        const dataAtual = new Date(value);

                        const atual = new Date();

                        dataAtual.setDate(atual.getDate());
                        dataAtual.setMonth(atual.getMonth());
                        dataAtual.setFullYear(atual.getFullYear());

                        if (newData.getTime() > dataAtual.getTime()) {
                          setStatus(false);
                        }

                        setDataPagamento(new Date(value));
                      }}
                      format="dd MMMM yyyy"
                      fullWidth
                      disabled={
                        tipoEdicao !== 0 || hasCaixa || isProblemaSemDataValue
                      }
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <>
                            <TodayIcon style={{ marginRight: '24px' }} />
                          </>
                        ),
                      }}
                    />
                  ) : (
                    <DateInputBonito
                      showTodayButton
                      disableFuture={tipoTransacao === TRANSFERENCIA}
                      todayLabel="Hoje"
                      name="data_pagamento_original"
                      handleEnter={() => {}}
                      style={{ marginBottom: ESPACO_INPUTS, marginTop: '0px' }}
                      label="Data de pagamento"
                      handleKey={() => {}}
                      value={
                        isProblemaSemDataValue
                          ? null
                          : dataPagamentoOriginalCorreto
                      }
                      onChange={(value) => {
                        setDataPagamentoOriginalCorreto(new Date(value));
                      }}
                      format="dd MMMM yyyy"
                      fullWidth
                      disabled={
                        tipoEdicao !== 0 || hasCaixa || isProblemaSemDataValue
                      }
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <>
                            <TodayIcon style={{ marginRight: '24px' }} />
                          </>
                        ),
                      }}
                    />
                    )*/}

                  <DateInputBonito
                    showTodayButton
                    disableFuture={tipoTransacao === TRANSFERENCIA}
                    todayLabel="Hoje"
                    name="data_pagamento"
                    handleEnter={() => {}}
                    style={{ marginBottom: ESPACO_INPUTS, marginTop: '0px' }}
                    label={
                      tipoTransacao === DESPESA
                        ? 'Data de pagamento real'
                        : 'Data de pagamento'
                    }
                    handleKey={() => {}}
                    value={
                      hasCaixa
                        ? dataPagamentoOriginal
                        : isProblemaSemDataValue
                        ? null
                        : dataPagamento
                    }
                    onChange={(value) => {
                      const newData = new Date(value);

                      const dataAtual = new Date(value);

                      const atual = new Date();

                      dataAtual.setDate(atual.getDate());
                      dataAtual.setMonth(atual.getMonth());
                      dataAtual.setFullYear(atual.getFullYear());

                      if (newData.getTime() > dataAtual.getTime()) {
                        setStatus(false);
                      }

                      setDataPagamento(new Date(value));

                      if (
                        tipoTransacao !== DESPESA ||
                        (tipoTransacao === DESPESA &&
                          !podeMudarDataOriginal &&
                          id <= 0)
                      ) {
                        setDataPagamentoOriginalCorreto(new Date(value));
                      }
                    }}
                    format="dd MMMM yyyy"
                    fullWidth
                    disabled={
                      tipoEdicao !== 0 || hasCaixa || isProblemaSemDataValue
                    }
                    variant="standard"
                    InputProps={{
                      startAdornment: (
                        <>
                          <TodayIcon style={{ marginRight: '24px' }} />
                        </>
                      ),
                    }}
                  />

                  {tipoTransacao !== TRANSFERENCIA && (
                    <TextInput
                      name="descricao"
                      // ref={refs.current[1]}
                      handleEnter={() => {}}
                      label="Descrição"
                      handleKey={() => {}}
                      value={descricao}
                      onChange={(value) => setDescricao(value.target.value)}
                      error={errorDescricao !== ''}
                      helperText={errorDescricao}
                      style={{ marginBottom: ESPACO_INPUTS, marginTop: '0px' }}
                      fullWidth
                      variant="standard"
                      disabled={
                        categoria.length > 0 && id > 0
                          ? categoria[0].gerenciado
                          : false
                      }
                      InputProps={{
                        startAdornment: (
                          <>
                            <NoteIcon style={{ marginRight: '24px' }} />
                          </>
                        ),
                      }}
                    />
                  )}

                  {tipoTransacao !== TRANSFERENCIA && (
                    <AutoCompleteLocalCustomizado
                      name="categoria"
                      handleEnter={() => {}}
                      label="Categoria"
                      handleKey={() => {}}
                      value={categoria}
                      disableClearable
                      onChangeLocal={(value) => handleOnChangeCategoria(value)}
                      campo="nome"
                      options={categoriasOptions}
                      variant="standard"
                      renderTag={renderTagCategoria}
                      renderOption={renderOptionCategoria}
                      style={{ marginBottom: ESPACO_INPUTS, marginTop: '0px' }}
                      // defaultValue={[]}
                      disabled={
                        categoria.length > 0 && id > 0
                          ? categoria[0].gerenciado
                          : false
                      }
                      error={errorCategoria !== ''}
                      helperText={errorCategoria}
                      startAdornment={
                        <InputAdornment position="start">
                          <BookmarkBorderIcon style={{ marginRight: '16px' }} />
                        </InputAdornment>
                      }
                    />
                  )}

                  <AutoCompleteLocalCustomizado
                    name="conta"
                    handleEnter={() => {}}
                    label="Conta"
                    handleKey={() => {}}
                    value={conta}
                    disableClearable
                    onChangeLocal={(value) => handleOnChangeConta(value)}
                    campo="nome"
                    options={
                      tipoTransacao !== TRANSFERENCIA ||
                      (tipoTransacao === TRANSFERENCIA && id > 0)
                        ? contasSemCaixasOptions
                        : contasOptions
                    }
                    variant="standard"
                    renderTag={renderTagConta}
                    renderOption={renderOptionConta}
                    style={{ marginBottom: ESPACO_INPUTS, marginTop: '0px' }}
                    disabled={tipoEdicao === 2 || isEditHasCaixa}
                    // defaultValue={[]}
                    error={errorConta !== ''}
                    helperText={errorConta}
                    startAdornment={
                      <InputAdornment position="start">
                        <AccountBalanceIcon style={{ marginRight: '16px' }} />
                      </InputAdornment>
                    }
                  />

                  {tipoTransacao === TRANSFERENCIA && (
                    <AutoCompleteLocalCustomizado
                      name="conta_destino"
                      handleEnter={() => {}}
                      label="Conta Destino"
                      handleKey={() => {}}
                      value={contaDestino}
                      disableClearable
                      onChangeLocal={(value) => handleOnChangeConta2(value)}
                      campo="nome"
                      // options={tipoTransacao !== TRANSFERENCIA || isEditHasCaixa ? contasSemCaixasOptions : contasOptions}
                      options={
                        tipoTransacao !== TRANSFERENCIA ||
                        (tipoTransacao === TRANSFERENCIA && id > 0)
                          ? contasSemCaixasOptions
                          : contasOptions
                      }
                      variant="standard"
                      renderTag={renderTagConta}
                      renderOption={renderOptionConta}
                      style={{ marginBottom: ESPACO_INPUTS, marginTop: '0px' }}
                      disabled={tipoEdicao === 2 || isEditHasCaixa}
                      // defaultValue={[]}
                      error={errorContaDestino !== ''}
                      helperText={errorContaDestino}
                      startAdornment={
                        <InputAdornment position="start">
                          <AccountBalanceIcon style={{ marginRight: '16px' }} />
                        </InputAdornment>
                      }
                    />
                  )}

                  {tipoTransacao === DESPESA && !repetir && (
                    <AutoCompleteLocalCustomizado
                      name="categoria_problema"
                      handleEnter={() => {}}
                      label="Categoria de Problema"
                      handleKey={() => {}}
                      value={
                        categoriaProblemas.length <= 0
                          ? Vazio
                          : categoriaProblemas
                      }
                      disableClearable
                      onChangeLocal={(value) =>
                        handleOnChangeCategoriaProblema(value)
                      }
                      campo="nome"
                      options={categoriasProblemasOptions}
                      variant="standard"
                      renderTag={renderTagCategoriaProblema}
                      renderOption={renderOptionCategoriaProblema}
                      style={{ marginBottom: ESPACO_INPUTS, marginTop: '0px' }}
                      // defaultValue={[]}
                      disabled={tipoEdicao !== 0}
                      error={errorCategoriaProblema !== ''}
                      helperText={errorCategoriaProblema}
                      filterSelectedOptions={false}
                      startAdornment={
                        <InputAdornment position="start">
                          <BookmarkBorderIcon style={{ marginRight: '16px' }} />
                        </InputAdornment>
                      }
                    />
                  )}

                  {categoriaProblemas.length > 0 && !repetir && (
                    <TextInput
                      name="descricao_problema"
                      multiline
                      maxRows={4}
                      // ref={refs.current[1]}
                      handleEnter={() => {}}
                      label="Descrição de Problema"
                      handleKey={() => {}}
                      value={descricaoProblema}
                      onChange={(value) =>
                        setDescricaoProblema(value.target.value)
                      }
                      error={errorDescricaoProblema !== ''}
                      helperText={errorDescricaoProblema}
                      style={{ marginBottom: ESPACO_INPUTS, marginTop: '0px' }}
                      fullWidth
                      variant="standard"
                      disabled={tipoEdicao !== 0}
                    />
                  )}

                  {tipoTransacao === DESPESA &&
                    status &&
                    !isProblemaSemDataValue && (
                      <PrecoInput
                        name="juros"
                        handleEnter={() => {}}
                        label="Juros"
                        handleKey={() => {}}
                        value={Number.isNaN(juros) ? '' : String(juros)}
                        onChange={(value) => {
                          const newValue = parseFloat(value.target.value);
                          if (newValue <= 0 || Number.isNaN(newValue)) {
                            setJuros(0);
                          } else {
                            setJuros(newValue);
                          }
                        }}
                        error={errorJuros !== ''}
                        helperText={errorJuros}
                        style={{ marginBottom: ESPACO_INPUTS }}
                        fullWidth
                        variant="standard"
                        disabled={tipoEdicao !== 0 || repetir}
                        autoFocus
                        InputProps={{
                          startAdornment: (
                            <>
                              <StayCurrentPortraitIcon
                                style={{ marginRight: '24px' }}
                              />
                            </>
                          ),
                        }}
                      />
                    )}

                  {tipoTransacao === DESPESA &&
                    status &&
                    !isProblemaSemDataValue && (
                      <PrecoInput
                        name="mora"
                        handleEnter={() => {}}
                        label="Mora"
                        handleKey={() => {}}
                        value={Number.isNaN(mora) ? '' : String(mora)}
                        onChange={(value) => {
                          const newValue = parseFloat(value.target.value);
                          if (newValue <= 0 || Number.isNaN(newValue)) {
                            setMora(0);
                          } else {
                            setMora(newValue);
                          }
                        }}
                        error={errorMora !== ''}
                        helperText={errorMora}
                        style={{ marginBottom: ESPACO_INPUTS }}
                        fullWidth
                        variant="standard"
                        disabled={tipoEdicao !== 0 || repetir}
                        autoFocus
                        InputProps={{
                          startAdornment: (
                            <>
                              <StayCurrentPortraitIcon
                                style={{ marginRight: '24px' }}
                              />
                            </>
                          ),
                        }}
                      />
                    )}

                  {tipoTransacao === DESPESA &&
                    status &&
                    !isProblemaSemDataValue && (
                      <PrecoInput
                        name="multa"
                        handleEnter={() => {}}
                        label="Multa"
                        handleKey={() => {}}
                        value={Number.isNaN(multa) ? '' : String(multa)}
                        onChange={(value) => {
                          const newValue = parseFloat(value.target.value);
                          if (newValue <= 0 || Number.isNaN(newValue)) {
                            setMulta(0);
                          } else {
                            setMulta(newValue);
                          }
                        }}
                        error={errorMulta !== ''}
                        helperText={errorMulta}
                        style={{ marginBottom: ESPACO_INPUTS }}
                        fullWidth
                        variant="standard"
                        disabled={tipoEdicao !== 0 || repetir}
                        autoFocus
                        InputProps={{
                          startAdornment: (
                            <>
                              <StayCurrentPortraitIcon
                                style={{ marginRight: '24px' }}
                              />
                            </>
                          ),
                        }}
                      />
                    )}

                  {tipoTransacao === DESPESA &&
                    status &&
                    !isProblemaSemDataValue && (
                      <PrecoInput
                        name="total_encargos"
                        handleEnter={() => {}}
                        label="Total Encargos"
                        handleKey={() => {}}
                        value={
                          Number.isNaN(totalEncargos)
                            ? ''
                            : String(totalEncargos)
                        }
                        onChange={() => {}}
                        style={{ marginBottom: ESPACO_INPUTS }}
                        fullWidth
                        variant="standard"
                        disabled
                        autoFocus
                        InputProps={{
                          startAdornment: (
                            <>
                              <StayCurrentPortraitIcon
                                style={{ marginRight: '24px' }}
                              />
                            </>
                          ),
                        }}
                      />
                    )}

                  {tipoTransacao === DESPESA && (
                    <PrecoInput
                      name="desconto"
                      handleEnter={() => {}}
                      label="Desconto"
                      handleKey={() => {}}
                      value={Number.isNaN(desconto) ? '' : String(desconto)}
                      onChange={(value) => {
                        const newValue = parseFloat(value.target.value);
                        if (newValue <= 0 || Number.isNaN(newValue)) {
                          setDesconto(0);
                        } else {
                          setDesconto(newValue);
                        }
                      }}
                      error={errorDesconto !== ''}
                      helperText={errorDesconto}
                      style={{ marginBottom: ESPACO_INPUTS }}
                      fullWidth
                      variant="standard"
                      disabled={tipoEdicao !== 0 || repetir}
                      autoFocus
                      InputProps={{
                        startAdornment: (
                          <>
                            <StayCurrentPortraitIcon
                              style={{ marginRight: '24px' }}
                            />
                          </>
                        ),
                      }}
                    />
                  )}

                  {tipoTransacao === DESPESA && (
                    <TextInput
                      name="boleto"
                      handleEnter={() => {}}
                      label="Boleto"
                      handleKey={() => {}}
                      value={numeroBoleto}
                      onChange={(value) =>
                        setNumeroBoleto(
                          value.target.value.replaceAll(/[^0-9]/g, ''),
                        )
                      }
                      error={errorBoleto !== ''}
                      helperText={errorBoleto}
                      style={{ marginBottom: ESPACO_INPUTS, marginTop: '0px' }}
                      fullWidth
                      variant="standard"
                      disabled={tipoEdicao !== 0 || repetir}
                      multiline
                      maxRows={4}
                      InputProps={{
                        startAdornment: (
                          <>
                            <NoteIcon style={{ marginRight: '24px' }} />
                          </>
                        ),
                      }}
                    />
                  )}

                  {tipoTransacao === DESPESA && (
                    <AutoCompleteRemoto
                      id="fornecedor"
                      name="fornecedor"
                      label="Fornecedor"
                      value={fornecedor}
                      onChange={(value) => setFornecedor(value)}
                      handleKey={(keyCode, keyName) => {}}
                      handleEnter={() => {}}
                      resource="fornecedores_todos_tipos"
                      nested={[]}
                      toString={false}
                      fullwidth
                      variant="standard"
                      filters={
                        id > 0
                          ? {
                              status: STATUS_ATIVO,
                              tipo: [2, 3],
                              ids_incluso: [idFornecedorOriginal],
                            }
                          : {
                              status: STATUS_ATIVO,
                              tipo: [2, 3],
                            }
                      }
                      getOptionSelected={(option, value) =>
                        option.nome === value.nome
                      }
                      getOptionLabel={(option) =>
                        `${option.nome}${
                          option.nome_fantasia
                            ? ` - (${option.nome_fantasia})`
                            : ''
                        }`
                      }
                      error={errorFornecedor !== ''}
                      helperText={errorFornecedor}
                      disabled={isParcelaCompra || (id > 0 && tipoEdicao !== 2)}
                    />
                  )}

                  {/* <div style={{
                display: 'flex', alignItems: 'center', justifyContent: 'space-between',
              }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ErrorOutlineIcon style={{ marginRight: '24px' }} />
                  <div style={{ fontWeight: 'bold' }}>Ignorar Transação</div>
                </div>
                <SwitchInput
                  name="caixa"
                  handleEnter={() => {}}
                  label=""
                  handleKey={() => {}}
                />

              </div> */}

                  {tipoTransacao !== TRANSFERENCIA &&
                    (id <= 0 || (id > 0 && grupoId > 0)) && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          margin: '24px 0px',
                        }}
                      >
                        <Button
                          onClick={() =>
                            tamanho === TAMANHO_PEQUENO
                              ? setTamanho(TAMANHO_GRANDE)
                              : setTamanho(TAMANHO_PEQUENO)
                          }
                          style={{
                            // backgroundColor: cor,
                            // color: 'white',
                            borderRadius: '25px',
                            // width: '150px',
                            // fontSize: '1.25rem',
                            fontWeight: 'bold',
                          }}
                        >
                          {tamanho === TAMANHO_PEQUENO ? (
                            <>
                              <div>Mais Detalhes</div>
                              <ChevronRightIcon />
                            </>
                          ) : (
                            <>
                              <div>Menos Detalhes</div>
                              <ChevronLeftIcon />
                            </>
                          )}
                        </Button>
                      </div>
                    )}
                </div>
                {tamanho === 'lg' && tipoTransacao !== TRANSFERENCIA && (
                  <div style={{ flex: 1, marginLeft: '50px' }}>
                    {id <= 0 && (
                      <>
                        <div
                          style={{
                            marginTop: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <RefreshIcon style={{ marginRight: '24px' }} />
                            <div style={{ fontWeight: 'bold' }}>Repetir</div>
                          </div>
                          <SwitchInput
                            name="caixa"
                            handleEnter={() => {}}
                            label=""
                            handleKey={() => {}}
                            checked={repetir}
                            onChange={(value) => {
                              if (value.target.checked) {
                                setCategoriaProblema(Vazio);
                              }
                              setRepetir(value.target.checked);
                            }}
                          />
                        </div>

                        <div
                          style={{ display: 'flex', alignItems: 'baseline' }}
                        >
                          <TextInput
                            name="vezes"
                            // ref={refs.current[1]}
                            handleEnter={() => {}}
                            label=""
                            handleKey={() => {}}
                            type="number"
                            // value={nome}
                            // onChange={(value) => setValor(value.target.value)}
                            error={errorVezesRepetir !== ''}
                            helperText={errorVezesRepetir}
                            disabled={!repetir}
                            fullWidth
                            variant="standard"
                            value={vezesRepetir}
                            onChange={(e) =>
                              setVezesRepetir(parseInt(e.target.value, 10))
                            }
                            InputProps={{
                              endAdornment: (
                                <div style={{ marginLeft: '35px' }}>vezes</div>
                              ),
                            }}
                          />

                          <SelectInput
                            name="tipo_periodo"
                            handleEnter={() => {}}
                            label=""
                            handleKey={() => {}}
                            value={tipoPeriodo}
                            onChange={(value) =>
                              setTipoPeriodo(value.target.value)
                            }
                            options={options}
                            fullWidth
                            variant="standard"
                            style={{ marginLeft: '25px' }}
                            disabled={!repetir}
                          />
                        </div>
                      </>
                    )}
                    {id > 0 && grupoId > 0 && (
                      <>
                        <div
                          style={{
                            margin: '8px 0px',
                            color: 'rgba(0, 0, 0, 0.87)',
                            fontSize: '20px',
                            fontWeight: 'bold',
                          }}
                        >
                          <div
                            style={{ marginBottom: '5px' }}
                          >{`Atenção esta é uma ${tipoTransacao}.`}</div>
                          <div>Você deseja...</div>
                        </div>
                        <FormControl
                          component="fieldset"
                          style={{ marginBottom: '9px' }}
                        >
                          <RadioGroup
                            aria-label="gender"
                            name="gender1"
                            value={tipoEdicao}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              value={0}
                              control={<Radio />}
                              label="Editar somente esta"
                            />
                            <FormControlLabel
                              value={1}
                              control={<Radio />}
                              label="Editar esta, e as futuras"
                            />
                            <FormControlLabel
                              value={2}
                              control={<Radio />}
                              label="Editar todas (incluindo efetivadas)"
                            />
                          </RadioGroup>
                        </FormControl>
                        {tipoEdicao === 1 && (
                          <div>{`*Não é possível alterar a Data ou Efetivar a ${tipoTransacao}.`}</div>
                        )}
                        {tipoEdicao === 2 && (
                          <div>{`*Não é possível alterar o Valor, Data, Conta ou Efetivar a ${tipoTransacao}.`}</div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
          </DialogContent>
          <DialogActions
            style={{
              padding: '0px 36px 20px',
              margin: '0',
              /* display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center', */
            }}
          >
            <Box>
              <div className={classes.wrapper}>
                <Button
                  onClick={handleSalvar}
                  classes={{
                    root: classes.btnAtions,
                    disabled: classes.btnAtionsDisabled,
                  }}
                  /* style={{
                //backgroundColor: cor, color: 'white', borderRadius: '25px', width: '150px',
              }} */
                  disabled={isDisabledSubmit() || carregando}
                  variant="contained"
                  color="primary"
                >
                  Salvar
                </Button>
                {carregando && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Box>
          </DialogActions>
          <DialogoSenha
            ref={refDialogoSenha}
            handleCancelar={() => {}}
            handleSalvar={() => {
              setPodeMudarDataOriginal(true);
            }}
            title="Autorização mudar data original de pagamento"
            enqueueSnackbar={enqueueSnackbar}
            mensagemSucesso="Edição da data original de pagamento liberada!!"
          />
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
