import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  avatar: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    color: 'white',
    backgroundColor: '#f44336',
  },
}));

const DescricaoField = (props) => {
  const classes = useStyles();
  const record = useRecordContext(props);
  const value = record
    ? record.record.user
      ? record.record.user.username
      : ''
    : '';

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title={value} placement="bottom" aria-label="add2">
        <Typography className={classes.text}>{value}</Typography>
      </Tooltip>
    </div>
  );
};

export default DescricaoField;
