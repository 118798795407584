import { createContext } from 'react';

import { tipoStatusAndamentoCriarPedido } from '../utils/constants';

export const PedidoContext = createContext({
  pedido: {
    cliente: null,
    itens: [],
    isMargemCartao: false,
    idEdit: -1,
    carregando: true,
    dataMinima: new Date(),
    dataMaxima: new Date(),
    tipoStatusAndamento: tipoStatusAndamentoCriarPedido,
  },
  dispatch: () => {},
});
