import * as React from 'react';
import { useRecordContext } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ClearIcon from '@material-ui/icons/Clear';

const ActionsField = ({ handleDesativar = () => {}, ...rest }) => {
  const record = useRecordContext(rest);
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Tooltip title="Desativar" placement="bottom" aria-label="desativar2">
        <IconButton
          aria-label="desativar"
          size="small"
          onClick={() => handleDesativar(record.record)}
        >
          <ClearIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ActionsField;
