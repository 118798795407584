import AccessTimeIcon from '@material-ui/icons/AccessTime';

import List from './List2';
import Show from './Show';

export default {
  show: Show,
  list: List,
  icon: AccessTimeIcon,
};
