import React from 'react';
import { TextInput } from 'react-admin';

import { Box } from '@material-ui/core';

const Form = (props) => (
  <Box p="1em">
    <Box display="flex">
      <Box flex={2} mr="0.5em">
        <TextInput
          source="nome"
          autoFocus
          label="Descrição"
          fullWidth
          {...props}
        />
      </Box>
    </Box>
  </Box>
);

export default Form;
