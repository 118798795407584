const Validation = (values) => {
  const errors = {};
  if (!values.nome) errors.nome = 'Este campo é obrigatório';
  else if (values.nome.length > 80)
    errors.nome = 'Número de caracteres maior que o permitido (máximo 80)';
  if (!values.setor_id) errors.categoria_id = 'Este campo é obrigatório';
  return errors;
};

export default Validation;
