import React from 'react';

import {
  withStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import DetailsIcon from '@material-ui/icons/Details';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import ShowChartIcon from '@material-ui/icons/ShowChart';

import {
  tipoStatusAndamentoCriarPedido,
  tipoStatusAndamentoAprovar,
  tipoStatusAndamentoAprovarVolta,
  tipoStatusAndamentoDetalhes,
} from '../../../utils/constants';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 12,
  },
  body: {
    fontSize: 12,
  },
  root: {
    borderBottom: 'none',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  paper: {
    height: 300,
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflow: 'auto',
  },
  solicitado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
  },
  analisado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#e2e629',
  },
  analisadoVolta: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
  },
}));

function riscarTexto(row) {
  if (row.verba_unitaria !== row.verba_unitaria_solicitado) return true;
  return false;
}

function riscarTexto2(row) {
  if (row.preco_aprovado !== row.preco_solicitado) return true;
  return false;
}

function isEditarAprovacao(row) {
  if (row) {
    if (row.dadosOriginais) {
      if (row.dadosOriginais.verba_unitaria > 0) {
        return true;
      }
    }
  }
  return false;
}

export default function Tabela({
  headCells,
  rows,
  handleGrafico = () => {},
  handleDetalhes = () => {},
  handleEditar = () => {},
  handleAprovacao = () => {},
  handleAprovacaoVolta = () => {},
  handleNegociacoes = () => {},
  tipoStatusAndamento,
}) {
  const classes = useStyles();
  return (
    <TableContainer
      component={Paper}
      style={{
        opacity: '0.75',
        height: '100%',
        width: '100%',
        overflow: 'auto',
      }}
    >
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <StyledTableCell align="left">{headCell.label}</StyledTableCell>
            ))}
            <StyledTableCell align="right">Ações</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            const isEditarAprovacaoValue = isEditarAprovacao(row);
            const isRiscarTexto = riscarTexto2(row);
            return (
              <>
                <StyledTableRow key={`${row.uidd}lalsasalksla`}>
                  {tipoStatusAndamento === tipoStatusAndamentoAprovar ? (
                    <StyledTableCell component="th" scope="row">
                      {!row.status_aprovado && isEditarAprovacaoValue ? (
                        <div
                          style={{
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'center',
                          }}
                        >
                          <Avatar className={classes.solicitado}>
                            <PriorityHighIcon style={{ fontSize: 15 }} />
                          </Avatar>
                          <span style={{ fontWeight: 'bold' }}>
                            Não Conferido
                          </span>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'center',
                          }}
                        >
                          <Avatar className={classes.analisadoVolta}>
                            <DoneAllIcon style={{ fontSize: 15 }} />
                          </Avatar>
                          <span style={{ fontWeight: 'bold' }}>Conferido</span>
                        </div>
                      )}
                    </StyledTableCell>
                  ) : null}
                  {tipoStatusAndamento === tipoStatusAndamentoAprovarVolta ? (
                    <StyledTableCell component="th" scope="row">
                      {row.status_aprovado_volta === false ? (
                        <div
                          style={{
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'center',
                          }}
                        >
                          <Avatar className={classes.solicitado}>
                            <PriorityHighIcon style={{ fontSize: 15 }} />
                          </Avatar>
                          <span style={{ fontWeight: 'bold' }}>Rejeitado</span>
                        </div>
                      ) : row.status_aprovado_volta === true ? (
                        <div
                          style={{
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'center',
                          }}
                        >
                          {!row.isModificado ? (
                            <Avatar className={classes.analisadoVolta}>
                              <DoneAllIcon style={{ fontSize: 15 }} />
                            </Avatar>
                          ) : (
                            <Avatar className={classes.analisado}>
                              <CheckIcon style={{ fontSize: 15 }} />
                            </Avatar>
                          )}
                          {!row.isModificado ? (
                            <span style={{ fontWeight: 'bold' }}>Aceito</span>
                          ) : (
                            <span style={{ fontWeight: 'bold' }}>
                              Nova Solicitação
                            </span>
                          )}
                        </div>
                      ) : (
                        <span style={{ fontWeight: 'bold' }}>
                          Não Manifesto
                        </span>
                      )}
                    </StyledTableCell>
                  ) : null}
                  {/* <StyledTableCell component="th" scope="row">
                  {!row.status_aprovado ? (
                    <div
                      style={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                      }}
                    >
                      <Avatar className={classes.solicitado}>
                        <PriorityHighIcon style={{ fontSize: 15 }} />
                      </Avatar>
                      <span style={{ fontWeight: 'bold' }}>Não Conferido</span>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                      }}
                    >
                      <Avatar className={classes.analisadoVolta}>
                        <DoneAllIcon style={{ fontSize: 15 }} />
                      </Avatar>
                      <span style={{ fontWeight: 'bold' }}>Comferido</span>
                    </div>
                  )}
                </StyledTableCell> */}
                  <StyledTableCell component="th" scope="row">
                    {row.numeroItem}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="left">
                    {row.produto}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="left">
                    {row.preco_atual}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="left">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        ///alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {tipoStatusAndamento ===
                      tipoStatusAndamentoCriarPedido ? (
                        <div
                          style={{
                            color: 'green',
                            fontWeight: 'bold',
                            fontSize: '15px',
                          }}
                        >
                          {row.preco_solicitado}
                        </div>
                      ) : null}

                      {tipoStatusAndamento !==
                      tipoStatusAndamentoCriarPedido ? (
                        <>
                          {!isRiscarTexto ? (
                            <div
                              style={{
                                color: 'green',
                                fontWeight: 'bold',
                                fontSize: '15px',
                              }}
                            >
                              {row.preco_solicitado}
                            </div>
                          ) : (
                            <>
                              <div
                                style={{
                                  color: 'red',
                                  fontWeight: 'bold',
                                  fontSize: '20px',
                                }}
                              >
                                {row.preco_aprovado}
                              </div>
                              <div
                                style={{
                                  textDecoration: 'line-through',
                                  color: 'green',
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                }}
                              >
                                {row.preco_solicitado}
                              </div>
                            </>
                          )}
                        </>
                      ) : null}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="left">
                    {row.quantidade}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="left">
                    {row.totalSemDesconto}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="left">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        ///alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {tipoStatusAndamento ===
                      tipoStatusAndamentoCriarPedido ? (
                        <div
                          style={{
                            color: 'green',
                            fontWeight: 'bold',
                            fontSize: '15px',
                          }}
                        >
                          {row.totalComDescontoSolicitado}
                        </div>
                      ) : null}
                      {tipoStatusAndamento !==
                      tipoStatusAndamentoCriarPedido ? (
                        <>
                          {!isRiscarTexto ? (
                            <div
                              style={{
                                color: 'green',
                                fontWeight: 'bold',
                                fontSize: '15px',
                              }}
                            >
                              {row.totalComDescontoSolicitado}
                            </div>
                          ) : (
                            <>
                              <div
                                style={{
                                  color: 'red',
                                  fontWeight: 'bold',
                                  fontSize: '20px',
                                }}
                              >
                                {row.totalComDesconto}
                              </div>
                              <div
                                style={{
                                  textDecoration: 'line-through',
                                  color: 'green',
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                }}
                              >
                                {row.totalComDescontoSolicitado}
                              </div>
                            </>
                          )}
                        </>
                      ) : null}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="left">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        ///alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {tipoStatusAndamento ===
                      tipoStatusAndamentoCriarPedido ? (
                        <div
                          style={{
                            color: 'green',
                            fontWeight: 'bold',
                            fontSize: '15px',
                          }}
                        >
                          {row.desconto_total_solicitado}
                        </div>
                      ) : null}

                      {tipoStatusAndamento !==
                      tipoStatusAndamentoCriarPedido ? (
                        <>
                          {!isRiscarTexto ? (
                            <div
                              style={{
                                color: 'green',
                                fontWeight: 'bold',
                                fontSize: '15px',
                              }}
                            >
                              {row.desconto_total_solicitado}
                            </div>
                          ) : (
                            <>
                              <div
                                style={{
                                  color: 'red',
                                  fontWeight: 'bold',
                                  fontSize: '20px',
                                }}
                              >
                                {row.desconto_total}
                              </div>
                              <div
                                style={{
                                  textDecoration: 'line-through',
                                  color: 'green',
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                }}
                              >
                                {row.desconto_total_solicitado}
                              </div>
                            </>
                          )}
                        </>
                      ) : null}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="right">
                    {tipoStatusAndamento === tipoStatusAndamentoAprovar &&
                    false ? (
                      <Tooltip title="Gráfico de pizza">
                        <IconButton
                          aria-label="delete"
                          onClick={() =>
                            handleGrafico(row.dadosOriginais, row.uidd)
                          }
                          size="small"
                        >
                          <DataUsageIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {tipoStatusAndamento === tipoStatusAndamentoAprovar ? (
                      <Tooltip
                        title="Aprovação"
                        placement="bottom"
                        aria-label="add2"
                      >
                        <IconButton
                          aria-label="check"
                          size="small"
                          onClick={() =>
                            handleAprovacao(row.dadosOriginais, row.uidd)
                          }
                          disabled={!isEditarAprovacaoValue}
                        >
                          <CheckCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {tipoStatusAndamento === tipoStatusAndamentoAprovarVolta ? (
                      <Tooltip
                        title="Aprovação"
                        placement="bottom"
                        aria-label="add2"
                      >
                        <IconButton
                          aria-label="check"
                          size="small"
                          onClick={() =>
                            handleAprovacaoVolta(row.dadosOriginais, row.uidd)
                          }
                        >
                          <CheckCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {(tipoStatusAndamento === tipoStatusAndamentoAprovar ||
                      tipoStatusAndamento === tipoStatusAndamentoDetalhes) &&
                    false ? (
                      <Tooltip title="Detalhes">
                        <IconButton
                          aria-label="delete"
                          onClick={() =>
                            handleDetalhes(row.dadosOriginais, row.uidd)
                          }
                          size="small"
                        >
                          <DetailsIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {tipoStatusAndamento === tipoStatusAndamentoCriarPedido ? (
                      <Tooltip title="Editar">
                        <IconButton
                          aria-label="editar"
                          onClick={() =>
                            handleEditar(row.dadosOriginais, row.uidd)
                          }
                          size="small"
                          disabled={row.idIndicadorChange !== -1}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {row.is_fim_negociacao ? (
                      <Tooltip title="Negociação encerrada pelo comprador">
                        <IconButton
                          aria-label="negociacao-encerrada"
                          size="small"
                          //disabled={row.idIndicadorChange !== -1}
                        >
                          <LockIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {tipoStatusAndamento !== tipoStatusAndamentoCriarPedido ? (
                      <Tooltip title="Histórico de Negociações">
                        <IconButton
                          aria-label="editar"
                          onClick={() =>
                            handleNegociacoes(row.dadosOriginais, row.uidd)
                          }
                          size="small"
                        >
                          <ShowChartIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </StyledTableCell>
                </StyledTableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
