import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import { getListAllAPI } from '../../../services';
import { TURNO_MANHA, TURNO_NOITE, TURNO_12_HORAS } from '../../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const LABEL_FOLGA = 'FOLGA';

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose = () => {} }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [nome, setNome] = useState('');
    const [grupo, setGrupo] = useState(null);
    const [equipe, setEquipe] = useState(null);
    const [turno, setTurno] = useState(TURNO_MANHA);
    const [codinome, setCodinome] = useState('');

    const [carregando, setCarregando] = useState(false);

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    async function getDataResource(id) {
      try {
        const data = await getListAllAPI(
          'colaboradores',
          ['id', 'asc'],
          { id: [id] },
          ['grupoColaborador.equipe', 'codinome'],
        );

        if (data.data.length > 0) {
          setNome(data.data[0].nome);
          setGrupo(data.data[0].grupoColaborador);
          setEquipe(
            data.data[0].grupoColaborador
              ? data.data[0].grupoColaborador.equipe
              : null,
          );
          setTurno(data.data[0].turno);
          setCodinome(data.data[0].codinome);
          setCarregando(false);
        } else {
          throw 'Este colaborador não existe!';
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    useImperativeHandle(ref, () => ({
      handleOpen(id) {
        setCarregando(true);
        getDataResource(id);
        setOpen(true);
      },
    }));

    function getDadosExpedientes() {
      return {
        expediente1: {
          entrada:
            grupo.dia_da_folga === 0
              ? turno === TURNO_MANHA
                ? '07:00'
                : '12:00'
              : turno === TURNO_MANHA
              ? '07:00'
              : '12:00',
          saida:
            grupo.dia_da_folga === 0
              ? turno === TURNO_MANHA
                ? '12:00'
                : '14:20'
              : turno === TURNO_MANHA
              ? '12:00'
              : '15:04',
        },
        expediente2: {
          entrada:
            grupo.dia_da_folga === 0
              ? turno === TURNO_MANHA
                ? '14:00'
                : '16:20'
              : turno === TURNO_MANHA
              ? '13:16'
              : '14:20',
          saida:
            grupo.dia_da_folga === 0
              ? turno === TURNO_MANHA
                ? '16:20'
                : '21:20'
              : turno === TURNO_MANHA
              ? '16:20'
              : '21:20',
        },
      };
    }

    function getLabelTurno(value) {
      switch (value) {
        case TURNO_MANHA:
          return 'Manhã';
        case TURNO_NOITE:
          return 'Noite';
        case TURNO_12_HORAS:
          return '12 H';
        default:
          return '';
      }
    }

    function formatDados() {
      if (grupo && equipe) {
        const dadosExpedientes = getDadosExpedientes();
        return {
          turno: getLabelTurno(turno),
          grupo: grupo.nome,
          equipe: equipe.nome,
          codinome: codinome ? codinome.nome : '-',
          dias: [
            {
              dia: 'Domingo',
              expediente1: {
                entrada:
                  grupo.dia_da_folga === 0
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente1.entrada,
                saida:
                  grupo.dia_da_folga === 0
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente1.saida,
              },
              expediente2: {
                entrada:
                  grupo.dia_da_folga === 0
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente2.entrada,
                saida:
                  grupo.dia_da_folga === 0
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente2.saida,
              },
            },
            {
              dia: 'Segunda - Feira',
              expediente1: {
                entrada:
                  grupo.dia_da_folga === 1
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente1.entrada,
                saida:
                  grupo.dia_da_folga === 1
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente1.saida,
              },
              expediente2: {
                entrada:
                  grupo.dia_da_folga === 1
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente2.entrada,
                saida:
                  grupo.dia_da_folga === 1
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente2.saida,
              },
            },
            {
              dia: 'Terça - Feira',
              expediente1: {
                entrada:
                  grupo.dia_da_folga === 2
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente1.entrada,
                saida:
                  grupo.dia_da_folga === 2
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente1.saida,
              },
              expediente2: {
                entrada:
                  grupo.dia_da_folga === 2
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente2.entrada,
                saida:
                  grupo.dia_da_folga === 2
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente2.saida,
              },
            },
            {
              dia: 'Quarta - Feira',
              expediente1: {
                entrada:
                  grupo.dia_da_folga === 3
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente1.entrada,
                saida:
                  grupo.dia_da_folga === 3
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente1.saida,
              },
              expediente2: {
                entrada:
                  grupo.dia_da_folga === 3
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente2.entrada,
                saida:
                  grupo.dia_da_folga === 3
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente2.saida,
              },
            },
            {
              dia: 'Quinta - Feira',
              expediente1: {
                entrada:
                  grupo.dia_da_folga === 4
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente1.entrada,
                saida:
                  grupo.dia_da_folga === 4
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente1.saida,
              },
              expediente2: {
                entrada:
                  grupo.dia_da_folga === 4
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente2.entrada,
                saida:
                  grupo.dia_da_folga === 4
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente2.saida,
              },
            },
            {
              dia: 'Sexta - Feira',
              expediente1: {
                entrada:
                  grupo.dia_da_folga === 5
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente1.entrada,
                saida:
                  grupo.dia_da_folga === 5
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente1.saida,
              },
              expediente2: {
                entrada:
                  grupo.dia_da_folga === 5
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente2.entrada,
                saida:
                  grupo.dia_da_folga === 5
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente2.saida,
              },
            },
            {
              dia: 'Sábado - Feira',
              expediente1: {
                entrada:
                  grupo.dia_da_folga === 6
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente1.entrada,
                saida:
                  grupo.dia_da_folga === 6
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente1.saida,
              },
              expediente2: {
                entrada:
                  grupo.dia_da_folga === 6
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente2.entrada,
                saida:
                  grupo.dia_da_folga === 6
                    ? LABEL_FOLGA
                    : dadosExpedientes.expediente2.saida,
              },
            },
          ],
        };
      }

      return {
        turno: '-',
        grupo: '-',
        equipe: '-',
        codinome: '-',
        dias: [],
      };
    }

    const dadosFormatados = formatDados();

    return (
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          disableBackdropClick
          disableEscapeKeyDown
          //fullScreen
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            {`Horário Semanal - ${nome}`}
          </DialogTitle>
          <DialogContent dividers>
            <div style={{ height: '100%' }}>
              <Box
                display={{ xs: 'block', sm: 'flex' }}
                flexDirection="column"
                marginBottom="30px"
                height="100%"
              >
                {carregando ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '300px',
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <Box p="1em">
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      style={{
                        marginBottom: '10px',
                      }}
                    >
                      <div>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          Turno:{' '}
                        </span>
                        <span>{dadosFormatados.turno}</span>
                      </div>
                      <div>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          Grupo:{' '}
                        </span>
                        <span>{dadosFormatados.grupo}</span>
                      </div>
                      <div>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          Equipe:{' '}
                        </span>
                        <span>{dadosFormatados.equipe}</span>
                      </div>
                      <div>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          Codinome:{' '}
                        </span>
                        <span>{dadosFormatados.codinome}</span>
                      </div>
                    </Box>
                    <TableContainer component={Paper}>
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Dia da Semana</StyledTableCell>
                            <StyledTableCell>Expediente</StyledTableCell>
                            <StyledTableCell>
                              Horário de Entrada
                            </StyledTableCell>
                            <StyledTableCell>Horário de Saída</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dadosFormatados.dias.map((row) => (
                            <>
                              <StyledTableRow key={`${row.dia}1`}>
                                <StyledTableCell rowSpan={2}>
                                  {row.dia}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {`${dadosFormatados.turno} 1`}
                                </StyledTableCell>
                                {row.expediente1.entrada !== LABEL_FOLGA ? (
                                  <>
                                    <StyledTableCell>
                                      {row.expediente1.entrada}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.expediente1.saida}
                                    </StyledTableCell>
                                  </>
                                ) : (
                                  <StyledTableCell
                                    colSpan={2}
                                    rowSpan={2}
                                    style={{
                                      textAlign: 'center',
                                      color: 'red',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {row.expediente1.entrada}
                                  </StyledTableCell>
                                )}
                              </StyledTableRow>
                              <StyledTableRow key={`${row.dia}2`}>
                                <StyledTableCell>
                                  {`${dadosFormatados.turno} 2`}
                                </StyledTableCell>
                                {row.expediente2.entrada !== LABEL_FOLGA && (
                                  <>
                                    <StyledTableCell>
                                      {row.expediente2.entrada}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.expediente2.saida}
                                    </StyledTableCell>
                                  </>
                                )}
                              </StyledTableRow>
                            </>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
              </Box>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
