import React, { forwardRef, useRef, useImperativeHandle } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

function formatNumero(numero) {
  return numero < 10 ? `0${numero}` : `${numero}`;
}

const NumberInput = forwardRef(
  (
    {
      value,
      onChange,
      handleEnter = () => {},
      handleKey = () => {},
      name,
      label,
      error,
      helperText,
      autoFocus = false,
      ...rest
    },
    ref,
  ) => {
    const refHoras = useRef(null);
    const refMinutos = useRef(null);

    function formatNumberMask(value) {
      let replaceQuantity = value;
      if (value.length < 2) {
        let replaceQuantity2 = value;
        for (let i = 0; i < 2 - value.length; i++) {
          replaceQuantity2 = `0${replaceQuantity2}`;
        }
        replaceQuantity = replaceQuantity2;
      } else if (replaceQuantity.length > 2) {
        if (replaceQuantity[0] === '0') {
          let replaceQuantity2 = replaceQuantity;
          replaceQuantity2 = replaceQuantity.substring(
            replaceQuantity.length - 2,
          );
          replaceQuantity = replaceQuantity2;
        }
      }
      return replaceQuantity;
    }

    const horas = value ? formatNumero(value.getHours()) : '00';
    const minutos = value ? formatNumero(value.getMinutes()) : '00';

    function sanitizeHoras(value) {
      let valueFormated = parseInt(formatNumberMask(value), 10);
      valueFormated = valueFormated > 23 ? parseInt(horas, 10) : valueFormated;
      const data = new Date();
      data.setHours(valueFormated);
      data.setMinutes(parseInt(minutos, 10));
      return data;
    }

    function sanitizeMinutos(value) {
      let valueFormated = parseInt(formatNumberMask(value), 10);
      valueFormated =
        valueFormated > 59 ? parseInt(minutos, 10) : valueFormated;
      const data = new Date();
      data.setHours(parseInt(horas, 10));
      data.setMinutes(valueFormated);
      return data;
    }

    const handleChangeHoras = (event) => {
      const newValue = event.target.value;
      onChange(sanitizeHoras(newValue));
    };

    const handleChangeMinutos = (event) => {
      const newValue = event.target.value;
      onChange(sanitizeMinutos(newValue));
    };

    useImperativeHandle(ref, () => ({
      focus() {
        if (refHoras.current) {
          refHoras.current.focus();
        }
      },
      select() {},
    }));

    return (
      <>
        {value ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '5px',
              alignItems: 'center',
            }}
          >
            <label>{label}</label>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '3px',
                padding: '5px',
                border: '1px solid black',
                borderRadius: '3px',
                width: 'min-content',
                fontSize: '30px',
              }}
            >
              <input
                {...rest}
                value={horas}
                onChange={handleChangeHoras}
                ref={refHoras}
                style={{
                  width: '50px',
                  padding: '0px',
                  fontSize: '30px',
                  textAlign: 'center',
                  border: 'none',
                }}
                autoFocus={autoFocus}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    handleEnter();
                  } else if (e.keyCode === 32) {
                    if (refMinutos.current) {
                      refMinutos.current.focus();
                    }
                  } else if (e.keyCode === 46) {
                    onChange(null);
                  } else handleKey(e.keyCode, e.key);
                }}
              />
              <div
                style={{
                  fontWeight: 'bold',
                }}
              >
                :
              </div>
              <input
                {...rest}
                value={minutos}
                onChange={handleChangeMinutos}
                ref={refMinutos}
                style={{
                  width: '50px',
                  padding: '0px',
                  fontSize: '30px',
                  textAlign: 'center',
                  border: 'none',
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    handleEnter();
                  } else if (e.keyCode === 32) {
                    if (refHoras.current) {
                      refHoras.current.focus();
                    }
                  } else if (e.keyCode === 46) {
                    onChange(null);
                  } else handleKey(e.keyCode, e.key);
                }}
              />
              {/*<Tooltip title="Remover">
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={() => onChange(null)}
                  size="small"
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Tooltip>*/}
            </div>
            {error ? (
              <div
                style={{
                  color: 'red',
                  fontSize: '12px',
                }}
              >
                {helperText}
              </div>
            ) : null}
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '5px',
              alignItems: 'center',
            }}
          >
            <label>{label}</label>
            <Tooltip title="Adicionar">
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                color="inherit"
                onClick={() => onChange(new Date())}
                size="small"
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </>
    );
  },
);

export default NumberInput;
