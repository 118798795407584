import React from 'react';
import {
  Filter as FilterAdmin,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

import { PeriodoInput, PrecoInput } from '../../Components/InputsReactAdmin';
import { STATUS_ATIVO } from '../../utils';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <ReferenceInput
      fullWidth
      label="Cliente"
      source="cliente_id"
      reference="clientes"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput>
    {/*<ReferenceInput
      fullWidth
      label="Devolução que gerou o crédito"
      source="devolucao_venda_id"
      reference="devolucoes_vendas"
      sort={{ field: 'id', order: 'ASC' }}
      filterToQuery={(searchText) => ({ id: parseInt(searchText, 10) })}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="id" />
</ReferenceInput>*/}
    <PeriodoInput
      source="periodo"
      label="Período"
      defaultValue={{
        start: new Date(),
        end: new Date(),
      }}
    />
    <ReferenceInput
      fullWidth
      label="Criador"
      source="criador_id"
      reference="users"
      sort={{ field: 'username', order: 'ASC' }}
      filterToQuery={(searchText) => ({ username: `${searchText}` })}
      perPage={10}
      allowEmpty={false}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
  </FilterAdmin>
);

export default Filter;
