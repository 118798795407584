import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import { useMediaQuery, MenuItem, Select } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import { IntegerInput, SearchProduto } from '../../Components';
import { getUserId, createAPI, updateAPI, getListAllAPI } from '../../services';
import { STATUS_ATIVO } from '../../utils/constants';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  rootDialogoSmall: {
    borderRadius: '0px',
  },
  btnAtions: {
    borderRadius: '25px',
  },
  btnAtionsSmall: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_ID = -1;

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose = () => {} }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [copias, setCopias] = useState(0);
    const [tipoImpressao, setTipoImpressao] = useState(0);
    const [id, setId] = useState(DEFAULT_ID);
    const [carregando, setCarregando] = useState(false);
    const [produto, setProduto] = useState(null);
    const [search, setSearch] = useState('');
    const refSearch = useRef(null);

    const classes = useStyles({ cor: 'black' });
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    async function reiniciar(produto) {
      setProduto(produto);
      setId(DEFAULT_ID);
      setCopias(1);
      setTipoImpressao(0);
      setCarregando(false);
    }

    async function getDataResource(id) {
      try {
        const data = await getListAllAPI(
          'solicitacoes_impressoes_etiqueta_2',
          ['id', 'asc'],
          { id: [id] },
          ['produto.unidade'],
        );
        if (data.data.length > 0) {
          setId(data.data[0].id);
          setProduto(data.data[0].produto);
          setCopias(data.data[0].copias);
          setTipoImpressao(data.data[0].tipo_impressao);
          setCarregando(false);
        } else {
          throw 'Esta solicitação de impressão não existe!';
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    async function handleSalvar() {
      setCarregando(true);
      try {
        if (id <= 0) {
          await createAPI('solicitacoes_impressoes_etiqueta_2', {
            produto_id: produto.id,
            copias,
            tipo_impressao: tipoImpressao,
            horario: new Date(),
            user_id: getUserId(),
            status: false,
          });
          setCarregando(false);
          enqueueSnackbar('Solicitação de impressão registrada com sucesso!', {
            variant: 'success',
          });
        } else {
          await updateAPI('solicitacoes_impressoes_etiqueta_2', id, {
            copias,
            tipo_impressao: tipoImpressao,
          });
          setCarregando(false);
          enqueueSnackbar('Solicitação de impressão atualizada com sucesso!', {
            variant: 'success',
          });
        }

        handleCloseDialog();
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    useImperativeHandle(ref, () => ({
      handleCreate2(produto, dadosConferencia) {
        if (produto) {
          if (dadosConferencia.id === 0) {
            reiniciar(produto);
            setOpen(true);
          } else if (dadosConferencia.id > 0) {
            setCarregando(true);
            getDataResource(dadosConferencia.id);
            setId(dadosConferencia.id);
            setOpen(true);
          } else {
            enqueueSnackbar(
              `O produto ${produto.nome} já tem impressões na fila por outra pessoa!`,
              {
                variant: 'error',
              },
            );
          }
        }
      },
      handleCreate() {
        reiniciar(null);
        setOpen(true);
      },
      handleEdit(id) {
        setCarregando(true);
        getDataResource(id);
        setId(id);
        setOpen(true);
      },
    }));

    function validateCopias() {
      let error = '';
      if (copias <= 0 || Number.isNaN(copias)) {
        error = 'Deve ter um valor maior que 0';
      }
      return error;
    }

    function validateTipoImpressao() {
      let error = '';
      if (tipoImpressao < 0 || Number.isNaN(tipoImpressao)) {
        error = 'Deve ter um valor maior que 0';
      }
      return error;
    }

    function validateProduto() {
      let error = '';
      if (!produto) {
        error = 'Campo Obrigatório!';
      }
      return error;
    }

    function getErros() {
      const errosOld = ['', ''];
      errosOld[0] = validateCopias();
      errosOld[1] = validateTipoImpressao();
      errosOld[2] = validateProduto();
      return errosOld;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    function getDadosProduto() {
      if (produto) {
        return {
          nome: produto.nome,
        };
      }
      return {
        nome: '',
      };
    }

    const { nome } = getDadosProduto();

    const handleChange = (event) => {
      setTipoImpressao(parseInt(event.target.value, 10));
    };

    function analisaCodigo(codigo) {
      if (codigo.length === 13 && codigo.charAt(0) === '2') {
        return {
          tipo: 0,
          data: {
            codigo_interno: codigo.substring(1, 7),
            peso:
              parseInt(codigo.substring(7, 9), 10) +
              parseInt(codigo.substring(9, 12), 10) / 1000,
            digitoVerificador: parseInt(codigo.substring(12, 13), 10),
          },
        };
      }
      return {
        tipo: 1,
        data: {},
      };
    }

    async function searchHandle(codigo, id) {
      if (!id) {
        const { tipo, data } = analisaCodigo(codigo);
        if (tipo === 0) {
          const { codigo_interno, peso } = data;
          const produtoData = await getListAllAPI(
            'produtos',
            ['id', 'asc'],
            { codigo_interno, status: STATUS_ATIVO },
            ['unidade'],
          );
          if (produtoData.data.length > 0) {
            setProduto(produtoData.data[0]);
          } else {
            setProduto(null);
            if (refSearch.current) refSearch.current.focus();
            enqueueSnackbar(
              `Produto não encotrado com este codigo interno: ${codigo_interno}!`,
              {
                variant: 'error',
              },
            );
          }
        } else {
          const produtoData = await getListAllAPI(
            'produtos',
            ['id', 'asc'],
            { codigo, status: STATUS_ATIVO },
            ['unidade'],
          );
          if (produtoData.data.length > 0) {
            setProduto(produtoData.data[0]);
          } else {
            const produtoDataCaixa = await getListAllAPI(
              'produtos/caixa',
              ['id', 'asc'],
              { codigo, status: STATUS_ATIVO },
              ['unidade'],
            );
            if (produtoDataCaixa.data.length > 0) {
              setProduto(produtoDataCaixa.data[0]);
            } else {
              setProduto(null);
              if (refSearch.current) refSearch.current.focus();
            }
          }
        }
      } else {
        const produtoData = await getListAllAPI(
          'produtos',
          ['id', 'asc'],
          { id },
          ['unidade'],
        );
        if (produtoData.data.length > 0) {
          setProduto(produtoData.data[0]);
        }
      }

      setSearch('');
    }

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: isSmall ? classes.rootDialogoSmall : classes.rootDialogo,
          }}
          className={isSmall ? classes.rootDialogoSmall : classes.rootDialogo}
          fullWidth={!isSmall}
          fullScreen={isSmall}
          maxWidth={isSmall ? '' : 'sm'}
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: '#ff1744', color: 'white' }}
          >
            {id < 0 ? `Nova Impressão` : `Editar Impressão`}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '20px 15px 20px',
              margin: '0',
            }}
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div>
                <div />
                <SearchProduto
                  label="Pesquisar produto"
                  value={search}
                  onChange={(e) => setSearch(e)}
                  fullwidth
                  searchHandle={searchHandle}
                  handleKey={(keyCode, keyName) => {}}
                />
                <div style={{ display: 'flex', margin: '25px 0px 20px' }}>
                  <div
                    style={{
                      marginRight: '5px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      flex: 1,
                      fontSize: '20px',
                    }}
                  >{`Produto: ${produto ? nome : 'NENHUM'}`}</div>
                </div>
                {produto && (
                  <>
                    <IntegerInput
                      handleEnter={() => {}}
                      label="Cópias"
                      handleKey={() => {}}
                      value={Number.isNaN(copias) ? '' : String(copias)}
                      onChange={(value) =>
                        setCopias(parseInt(value.target.value, 10))
                      }
                      fullWidth
                      error={erros[0] !== ''}
                      helperText={erros[0]}
                    />
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth
                      style={{ marginTop: '15px' }}
                    >
                      <InputLabel id="demo-simple-select-filled-label">
                        Tipo impressão
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={tipoImpressao}
                        onChange={handleChange}
                      >
                        <MenuItem value={0}>Varejo</MenuItem>
                        <MenuItem value={2}>Varejo|Atacado</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                )}
              </div>
            )}
          </DialogContent>
          <DialogActions
            style={{
              padding: '0px 15px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                }}
                disabled={erroExistente || carregando}
                variant="contained"
                color="primary"
                fullWidth
              >
                Adicionar
              </Button>
              {carregando && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
