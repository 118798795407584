import { SupervisorAccount } from '@material-ui/icons';

import Create from './Create';
import Edit from './Edit';
import List from './List2';

export default {
  list: List,
  create: Create,
  edit: Edit,
  icon: SupervisorAccount,
};
