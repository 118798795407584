import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { PrecoInput } from '../../../Components';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DialogoCategoriaTransacao = forwardRef(({ handleSalvar }, ref) => {
  const [open, setOpen] = useState(false);
  const [pixQrCodeInputValue, setPixQrCodeInputValue] = useState(0);
  const [creditoInputValue, setCreditoInputValue] = useState(0);
  const [debitoInputValue, setDebitoInputValue] = useState(0);
  const [vonchuerInputValue, setVonchuerInputValue] = useState(0);

  const classes = useStyles({ cor: '#009FD4' });

  const handleCloseDialog = () => {
    setOpen(false);
  };

  function salvar() {
    handleCloseDialog();
    handleSalvar({
      pixQrCodeInputValue,
      creditoInputValue,
      debitoInputValue,
      vonchuerInputValue,
    });
  }

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
      setPixQrCodeInputValue(0);
      setCreditoInputValue(0);
      setDebitoInputValue(0);
      setVonchuerInputValue(0);
    },
  }));

  function validatePreco(value) {
    let error = '';
    if (value < 0 || Number.isNaN(value)) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function getErros() {
    const errosOld = ['', '', '', ''];
    errosOld[0] = validatePreco(pixQrCodeInputValue);
    errosOld[1] = validatePreco(creditoInputValue);
    errosOld[2] = validatePreco(debitoInputValue);
    errosOld[3] = validatePreco(vonchuerInputValue);
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          Valores fornecidos pela adquirintes
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          <div>
            <Box flex={1} mr="0.5em">
              <PrecoInput
                name="pix qrcode"
                handleEnter={() => {}}
                label="Pix QrCode"
                handleKey={() => {}}
                value={
                  Number.isNaN(pixQrCodeInputValue)
                    ? ''
                    : String(pixQrCodeInputValue)
                }
                onChange={(value) =>
                  setPixQrCodeInputValue(parseFloat(value.target.value))
                }
                error={erros[0] !== ''}
                helperText={erros[0]}
                fullWidth
              />
            </Box>

            <Box flex={1} mr="0.5em">
              <PrecoInput
                name="credito"
                handleEnter={() => {}}
                label="Crédito"
                handleKey={() => {}}
                value={
                  Number.isNaN(creditoInputValue)
                    ? ''
                    : String(creditoInputValue)
                }
                onChange={(value) =>
                  setCreditoInputValue(parseFloat(value.target.value))
                }
                error={erros[1] !== ''}
                helperText={erros[1]}
                fullWidth
              />
            </Box>

            <Box flex={1} mr="0.5em">
              <PrecoInput
                name="debito"
                handleEnter={() => {}}
                label="Débito"
                handleKey={() => {}}
                value={
                  Number.isNaN(debitoInputValue) ? '' : String(debitoInputValue)
                }
                onChange={(value) =>
                  setDebitoInputValue(parseFloat(value.target.value))
                }
                error={erros[2] !== ''}
                helperText={erros[2]}
                fullWidth
              />
            </Box>

            <Box flex={1} mr="0.5em">
              <PrecoInput
                name="vonchuer"
                handleEnter={() => {}}
                label="Vonchuer"
                handleKey={() => {}}
                value={
                  Number.isNaN(vonchuerInputValue)
                    ? ''
                    : String(vonchuerInputValue)
                }
                onChange={(value) =>
                  setVonchuerInputValue(parseFloat(value.target.value))
                }
                error={erros[3] !== ''}
                helperText={erros[3]}
                fullWidth
              />
            </Box>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          <Button onClick={() => handleCloseDialog()} color="primary">
            Cancelar
          </Button>
          <div className={classes.wrapper}>
            <Button
              onClick={salvar}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              disabled={erroExistente}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
