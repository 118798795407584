import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from 'react';

import { Box } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import CloseIcon from '@material-ui/icons/Close';
import NoteIcon from '@material-ui/icons/Note';
import StayCurrentPortraitIcon from '@material-ui/icons/StayCurrentPortrait';
import TodayIcon from '@material-ui/icons/Today';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { getListAllAPI, updateAPI } from '../../../services';
import { STATUS_ATIVO } from '../../../utils/constants';
import {
  PrecoInput,
  DateInputBonito,
  AutoCompleteLocalCustomizado,
  TextInput,
  AutoCompleteRemoto,
} from '../../Inputs';
import DialogoConfirmacao from '../DialogoConfirmacao';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    // backgroundColor: (props) => props.cor,
    // color: 'white',
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    // backgroundColor: 'rgba(0, 0, 0, 0.12)',
    // color: 'red',
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const RECEITA = 'Receita';
const DESPESA = 'Despesa';

const useStyles3 = makeStyles((theme) => ({
  chip: {
    borderColor: (props) => props.color,
    backgroundColor: 'white',
    cursor: 'default',
    color: (props) => props.color,
    '&:hover': {
      color: (props) => props.color,
      backgroundColor: 'white',
    },
  },
  deleteIcon: {
    color: (props) => props.color,
    cursor: 'pointer',
    '&:hover': {
      color: (props) => props.color,
    },
  },
}));

const ChipConta = ({ option, injetado, campo }) => {
  const classes2 = useStyles3({ color: option.color });
  const { onDelete, ...rest } = injetado;
  return (
    <Chip
      classes={{ outlined: classes2.chip, deleteIcon: classes2.deleteIcon }}
      variant="outlined"
      label={option[campo]}
      {...rest}
      avatar={
        <Avatar
          style={{
            backgroundColor: option.color,
            marginRight: '3px',
            color: 'white',
          }}
        >
          {option.nome.charAt(0)}
        </Avatar>
      }
    />
  );
};

const Vazio = [
  {
    nome: 'Despesa Não problemática',
    id: -1,
    cor: 'red',
    icone: '',
  },
];

//////////////
const DEFAULT_JUROS = 0;
const DEFAULT_MORA = 0;
const DEFAULT_MULTA = 0;
const DEFAULT_DESCONTO = 0;
const DEFAULT_NUMERO_BOLETO = '';
///////////////////

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose, cor = '#2196f3', campo = 'nome' }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [conta, setConta] = useState([]);
    const [valor, setValor] = useState(0);
    const [dataPagamento, setDataPagamento] = useState(
      moment().format('YYYY-MM-DD'),
    );
    const [contasOptions, setContasOptions] = useState([]);
    const [id, setId] = useState(-1);
    const [tipoTransacao, setTipoTransacao] = useState(RECEITA);
    const [carregando, setCarregando] = useState(false);
    const [isProblema, setIsProblema] = useState(false);
    const classes = useStyles();
    const refDialogoConfirmacao = useRef(null);
    const [categoria, setCategoria] = useState([]);
    const [nome, setNome] = useState('');
    const [problema, setProblema] = useState('');

    const [juros, setJuros] = useState(DEFAULT_JUROS);
    const [mora, setMora] = useState(DEFAULT_MORA);
    const [multa, setMulta] = useState(DEFAULT_MULTA);
    const [desconto, setDesconto] = useState(DEFAULT_DESCONTO);
    const [numeroBoleto, setNumeroBoleto] = useState(DEFAULT_NUMERO_BOLETO);
    const [fornecedor, setFornecedor] = useState(null);

    /* useEffect(() => {
      
      getContas();
    }, []); */

    async function getContas(id = 0) {
      const dataContas = await getListAllAPI(
        'contas_normal',
        ['id', 'asc'],
        id === 0
          ? { is_caixa: false, status: STATUS_ATIVO }
          : { is_caixa: false, status: STATUS_ATIVO, ids_incluso: [id] },
        [],
      );
      if (dataContas.data.length > 0) setConta([dataContas.data[0]]);
      setContasOptions(dataContas.data);
    }

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    async function getDataResource(id) {
      try {
        const data = await getListAllAPI(
          'transacoes',
          ['id', 'asc'],
          { id: [id] },
          ['conta', 'categoria', 'problema', 'fornecedor'],
        );
        if (data.data.length > 0) {
          await getContas(data.data[0].conta.id);
          if (data.data[0].is_transferencia) {
            setCarregando(false);
            throw 'Não pode mudar o status de transferências!';
          } else {
            setConta([data.data[0].conta]);
            setValor(data.data[0].valor_original);
            // setDataPagamento(data.data[0].data_pagamento);
            setDataPagamento(new Date());
            setTipoTransacao(data.data[0].tipo === RECEITA ? RECEITA : DESPESA);
            setIsProblema(!!data.data[0].categoria_problema_despesa_id);
            //
            setNome(data.data[0].nome);
            setCategoria([data.data[0].categoria]);
            setProblema(
              data.data[0].problema ? [data.data[0].problema] : Vazio,
            );
            //
            setJuros(data.data[0].juros);
            setMora(data.data[0].mora);
            setMulta(data.data[0].multa);
            setDesconto(data.data[0].desconto);
            setNumeroBoleto(data.data[0].codigo_boleto);
            setFornecedor(data.data[0].fornecedor);
            //
            setCarregando(false);
          }
        } else {
          setCarregando(false);
          throw 'Esta transação não existe!';
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    async function handleSalvar() {
      setCarregando(true);
      try {
        if (id <= 0) {
          setCarregando(false);
          enqueueSnackbar('Nenhuma transação aqui!!!', {
            variant: 'error',
          });
        } else {
          await updateAPI('transacoes/efetivar', id, {
            valor_original: valor,
            conta_id: conta[0].id,
            data_pagamento: dataPagamento,
            juros: tipoTransacao === DESPESA ? juros : 0,
            mora: tipoTransacao === DESPESA ? mora : 0,
            multa: tipoTransacao === DESPESA ? multa : 0,
            desconto: tipoTransacao === DESPESA ? desconto : 0,
          });
          enqueueSnackbar(`${tipoTransacao} confirmada com sucesso!`, {
            variant: 'success',
          });
          setCarregando(false);
        }
        handleCloseDialog();
      } catch (erros) {
        setCarregando(false);
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    function middleware() {
      if (isProblema) {
        if (refDialogoConfirmacao.current) {
          refDialogoConfirmacao.current.handleOpen(
            'Transação problemática',
            'Esta Transação é problemática, deseja efetiva-la assim mesmo?',
            1,
          );
        }
      } else {
        handleSalvar();
      }
    }

    useImperativeHandle(ref, () => ({
      handleOpen(id) {
        setCarregando(true);
        getDataResource(id);
        setOpen(true);
        setId(id);
      },
    }));

    const errorValor =
      valor <= 0 || Number.isNaN(valor) ? 'Este campo é obrigatório' : '';
    const errorDataPagamento =
      dataPagamento === null || dataPagamento === '' ? 'Data Inválida!' : '';
    const errorConta = conta.length <= 0 ? 'Este campo é obrigatório' : '';

    function validateJuros() {
      let error = '';
      if (tipoTransacao === DESPESA) {
        if (juros < 0 || Number.isNaN(juros)) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    function validateMora() {
      let error = '';
      if (tipoTransacao === DESPESA) {
        if (mora < 0 || Number.isNaN(mora)) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    function validateMulta() {
      let error = '';
      if (tipoTransacao === DESPESA) {
        if (multa < 0 || Number.isNaN(multa)) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    function validateDesconto() {
      let error = '';
      if (tipoTransacao === DESPESA) {
        if (desconto < 0 || Number.isNaN(desconto)) {
          error = 'Este campo é obrigatório';
        } else if (desconto > valor) {
          error = 'O desconto não pode ser maior que o valor original!';
        }
      }
      return error;
    }

    const errorJuros = validateJuros();
    const errorMora = validateMora();
    const errorMulta = validateMulta();
    const errorDesconto = validateDesconto();

    function isDisabledSubmit() {
      return (
        errorValor !== '' ||
        errorDataPagamento !== '' ||
        errorConta !== '' ||
        errorJuros !== '' ||
        errorMora !== '' ||
        errorMulta !== '' ||
        errorDesconto !== ''
      );
    }

    const renderTag = (option, injetado) => (
      <ChipConta option={option} injetado={injetado} campo={campo} />
    );

    const renderOption = (option) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          style={{
            backgroundColor: option.color,
            marginRight: '10px',
            width: '30px',
            height: '30px',
          }}
        >
          {option.nome.charAt(0)}
        </Avatar>
        {option[campo]}
      </div>
    );

    const handleOnChangeConta = (value) => {
      if (value.length > 0) {
        if (value.length === 1) {
          setConta([value[0]]);
        } else if (value.length === 2) {
          setConta([value[1]]);
        }
      } else {
        setConta([]);
      }
    };

    function getColor() {
      switch (tipoTransacao) {
        case RECEITA:
          return '#4caf50';
        case DESPESA:
          return '#f44336';
        default:
          return '#009FD4';
      }
    }

    const valorReal =
      valor + (tipoTransacao === DESPESA ? juros + mora + multa - desconto : 0);

    const totalEncargos = tipoTransacao === DESPESA ? juros + mora + multa : 0;

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullWidth
          maxWidth="sm"
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: getColor(), color: 'white' }}
          >
            {`Deseja efetivar esta ${tipoTransacao} ?`}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div>
                <div
                  style={{
                    padding: '10px',
                    marginBottom: '12px',
                    color: 'rgba(0, 0, 0, 0.54)',
                    fontSize: '1rem',
                    fontWeight: 400,
                    lineHeight: 1.5,
                  }}
                >
                  {tipoTransacao === DESPESA
                    ? 'Ao efetivar essa despesa será descontado o valor na Conta'
                    : 'Ao efetivar essa receita será acrescido o valor na Conta'}
                </div>

                <Box marginBottom="24px">
                  <TextInput
                    name="descricao"
                    // ref={refs.current[1]}
                    handleEnter={() => {}}
                    label="Descrição"
                    handleKey={() => {}}
                    value={nome}
                    onChange={(value) => {}}
                    fullWidth
                    variant="standard"
                    disabled
                    InputProps={{
                      startAdornment: (
                        <>
                          <NoteIcon style={{ marginRight: '24px' }} />
                        </>
                      ),
                    }}
                  />
                  <PrecoInput
                    name="valor"
                    // ref={refs.current[1]}
                    handleEnter={() => {}}
                    label={
                      tipoTransacao === DESPESA ? 'Valor Original' : 'Valor'
                    }
                    handleKey={() => {}}
                    value={Number.isNaN(valor) ? '' : String(valor)}
                    onChange={(value) => {
                      const newValue = parseFloat(value.target.value);
                      if (newValue <= 0 || Number.isNaN(newValue)) {
                        setValor(0);
                      } else {
                        setValor(newValue);
                      }
                      // setValor(parseFloat(value.target.value))
                    }}
                    error={errorValor !== ''}
                    helperText={errorValor}
                    fullWidth
                    variant="standard"
                    style={{ marginBottom: '20px' }}
                    InputProps={{
                      startAdornment: (
                        <>
                          <StayCurrentPortraitIcon
                            style={{ marginRight: '24px' }}
                          />
                        </>
                      ),
                    }}
                  />

                  {tipoTransacao === DESPESA && (
                    <PrecoInput
                      name="valor"
                      handleEnter={() => {}}
                      label="Valor Real"
                      handleKey={() => {}}
                      value={Number.isNaN(valorReal) ? '' : String(valorReal)}
                      onChange={(value) => {}}
                      fullWidth
                      variant="standard"
                      style={{ marginBottom: '20px' }}
                      disabled
                      InputProps={{
                        startAdornment: (
                          <>
                            <StayCurrentPortraitIcon
                              style={{ marginRight: '24px' }}
                            />
                          </>
                        ),
                      }}
                    />
                  )}

                  <DateInputBonito
                    showTodayButton
                    todayLabel="Hoje"
                    name="data_pagamento"
                    handleEnter={() => {}}
                    label="Data Pagamento"
                    handleKey={() => {}}
                    value={dataPagamento}
                    onChange={(value) => setDataPagamento(value)}
                    format="dd MMMM yyyy"
                    fullWidth
                    variant="standard"
                    InputProps={{
                      startAdornment: (
                        <>
                          <TodayIcon style={{ marginRight: '24px' }} />
                        </>
                      ),
                    }}
                  />
                  <AutoCompleteLocalCustomizado
                    name="conta"
                    handleEnter={() => {}}
                    label="Conta"
                    handleKey={() => {}}
                    value={conta}
                    disableClearable
                    onChangeLocal={(value) => handleOnChangeConta(value)}
                    campo="nome"
                    options={contasOptions}
                    variant="standard"
                    renderTag={renderTag}
                    renderOption={renderOption}
                    error={errorConta !== ''}
                    helperText={errorConta}
                    startAdornment={
                      <InputAdornment position="start">
                        <AccountBalanceIcon />
                      </InputAdornment>
                    }
                  />

                  {tipoTransacao === DESPESA && (
                    <PrecoInput
                      name="juros"
                      handleEnter={() => {}}
                      label="Juros"
                      handleKey={() => {}}
                      value={Number.isNaN(juros) ? '' : String(juros)}
                      onChange={(value) => {
                        const newValue = parseFloat(value.target.value);
                        if (newValue <= 0 || Number.isNaN(newValue)) {
                          setJuros(0);
                        } else {
                          setJuros(newValue);
                        }
                      }}
                      error={errorJuros !== ''}
                      helperText={errorJuros}
                      fullWidth
                      variant="standard"
                      autoFocus
                      InputProps={{
                        startAdornment: (
                          <>
                            <StayCurrentPortraitIcon
                              style={{ marginRight: '24px' }}
                            />
                          </>
                        ),
                      }}
                    />
                  )}

                  {tipoTransacao === DESPESA && (
                    <PrecoInput
                      name="mora"
                      handleEnter={() => {}}
                      label="Mora"
                      handleKey={() => {}}
                      value={Number.isNaN(mora) ? '' : String(mora)}
                      onChange={(value) => {
                        const newValue = parseFloat(value.target.value);
                        if (newValue <= 0 || Number.isNaN(newValue)) {
                          setMora(0);
                        } else {
                          setMora(newValue);
                        }
                      }}
                      error={errorMora !== ''}
                      helperText={errorMora}
                      fullWidth
                      variant="standard"
                      autoFocus
                      InputProps={{
                        startAdornment: (
                          <>
                            <StayCurrentPortraitIcon
                              style={{ marginRight: '24px' }}
                            />
                          </>
                        ),
                      }}
                    />
                  )}

                  {tipoTransacao === DESPESA && (
                    <PrecoInput
                      name="multa"
                      handleEnter={() => {}}
                      label="Multa"
                      handleKey={() => {}}
                      value={Number.isNaN(multa) ? '' : String(multa)}
                      onChange={(value) => {
                        const newValue = parseFloat(value.target.value);
                        if (newValue <= 0 || Number.isNaN(newValue)) {
                          setMulta(0);
                        } else {
                          setMulta(newValue);
                        }
                      }}
                      error={errorMulta !== ''}
                      helperText={errorMulta}
                      fullWidth
                      variant="standard"
                      autoFocus
                      InputProps={{
                        startAdornment: (
                          <>
                            <StayCurrentPortraitIcon
                              style={{ marginRight: '24px' }}
                            />
                          </>
                        ),
                      }}
                    />
                  )}

                  {tipoTransacao === DESPESA && (
                    <PrecoInput
                      name="total_encargos"
                      handleEnter={() => {}}
                      label="Total Encargos"
                      handleKey={() => {}}
                      value={
                        Number.isNaN(totalEncargos) ? '' : String(totalEncargos)
                      }
                      onChange={() => {}}
                      fullWidth
                      variant="standard"
                      disabled
                      autoFocus
                      InputProps={{
                        startAdornment: (
                          <>
                            <StayCurrentPortraitIcon
                              style={{ marginRight: '24px' }}
                            />
                          </>
                        ),
                      }}
                    />
                  )}

                  {tipoTransacao === DESPESA && (
                    <PrecoInput
                      name="desconto"
                      handleEnter={() => {}}
                      label="Desconto"
                      handleKey={() => {}}
                      value={Number.isNaN(desconto) ? '' : String(desconto)}
                      onChange={(value) => {
                        const newValue = parseFloat(value.target.value);
                        if (newValue <= 0 || Number.isNaN(newValue)) {
                          setDesconto(0);
                        } else {
                          setDesconto(newValue);
                        }
                      }}
                      error={errorDesconto !== ''}
                      helperText={errorDesconto}
                      fullWidth
                      variant="standard"
                      autoFocus
                      InputProps={{
                        startAdornment: (
                          <>
                            <StayCurrentPortraitIcon
                              style={{ marginRight: '24px' }}
                            />
                          </>
                        ),
                      }}
                    />
                  )}

                  {tipoTransacao === DESPESA && (
                    <TextInput
                      name="boleto"
                      handleEnter={() => {}}
                      label="Boleto"
                      handleKey={() => {}}
                      value={numeroBoleto}
                      onChange={(value) => {}}
                      fullWidth
                      variant="standard"
                      multiline
                      maxRows={4}
                      disabled
                      InputProps={{
                        startAdornment: (
                          <>
                            <NoteIcon style={{ marginRight: '24px' }} />
                          </>
                        ),
                      }}
                    />
                  )}

                  <AutoCompleteLocalCustomizado
                    name="categoria"
                    handleEnter={() => {}}
                    label="Categoria"
                    handleKey={() => {}}
                    value={categoria}
                    disableClearable
                    onChangeLocal={(value) => {}}
                    campo="nome"
                    options={categoria}
                    variant="standard"
                    renderTag={renderTag}
                    renderOption={renderOption}
                    disabled
                    startAdornment={
                      <InputAdornment position="start">
                        <BookmarkBorderIcon />
                      </InputAdornment>
                    }
                  />
                  {tipoTransacao === DESPESA && (
                    <AutoCompleteLocalCustomizado
                      name="categoria-problema"
                      handleEnter={() => {}}
                      label="Tipo de Problema"
                      handleKey={() => {}}
                      value={problema}
                      disableClearable
                      onChangeLocal={(value) => {}}
                      campo="nome"
                      options={problema}
                      variant="standard"
                      renderTag={renderTag}
                      renderOption={renderOption}
                      disabled
                      startAdornment={
                        <InputAdornment position="start">
                          <BookmarkBorderIcon />
                        </InputAdornment>
                      }
                    />
                  )}
                  {tipoTransacao === DESPESA && (
                    <AutoCompleteRemoto
                      id="fornecedor"
                      name="fornecedor"
                      label="Fornecedor"
                      value={fornecedor}
                      onChange={(value) => setFornecedor(value)}
                      handleKey={(keyCode, keyName) => {}}
                      handleEnter={() => {}}
                      resource="fornecedores_todos_tipos"
                      nested={[]}
                      toString={false}
                      fullwidth
                      variant="standard"
                      filters={{ status: STATUS_ATIVO }}
                      getOptionSelected={(option, value) =>
                        option.nome === value.nome
                      }
                      getOptionLabel={(option) =>
                        `${option.nome}${
                          option.nome_fantasia
                            ? ` - (${option.nome_fantasia})`
                            : ''
                        }`
                      }
                      disabled
                    />
                  )}
                </Box>
              </div>
            )}
          </DialogContent>
          <DialogActions
            style={{
              padding: '32px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={handleCloseDialog}
              variant="outlined"
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              color="primary"
              /* style={{
              color: cor, borderRadius: '25px', width: '100px',
            }} */
              // disabled={nome === '' || nome.length > 80}
            >
              Cancelar
            </Button>
            <Box>
              <div className={classes.wrapper}>
                <Button
                  onClick={middleware}
                  classes={{
                    root: classes.btnAtions,
                    disabled: classes.btnAtionsDisabled,
                  }}
                  /* style={{
                backgroundColor: cor, color: 'white', borderRadius: '25px', width: '100px',
              }} */
                  disabled={isDisabledSubmit() || carregando}
                  variant="contained"
                  color="primary"
                >
                  {tipoTransacao === RECEITA ? 'Receber' : 'Pagar'}
                </Button>
                {carregando && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Box>
          </DialogActions>
          <DialogoConfirmacao
            ref={refDialogoConfirmacao}
            handleConfirma={() => {
              handleSalvar();
            }}
          />
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
