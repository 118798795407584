import React, { forwardRef } from 'react';

import { TextField } from '@material-ui/core';

const TextInput = forwardRef(
  (
    {
      handleEnter,
      handleKey,
      variant = 'outlined',
      autoCompleteState = 'off',
      ...rest
    },
    ref,
  ) => (
    <TextField
      margin="normal"
      {...rest}
      inputRef={ref}
      variant={variant}
      autoComplete={autoCompleteState}
      onKeyDown={(e) => {
        if (e.keyCode === 13) handleEnter();
        else handleKey(e.keyCode, e.key);
      }}
    />
  ),
);

export default TextInput;
