import React from 'react';
import { Filter as FilterAdmin, TextInput } from 'react-admin';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <TextInput label="Título" source="nome" />
  </FilterAdmin>
);

export default Filter;
