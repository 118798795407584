import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const ActionsField = ({
  tipo,
  handleEdit = () => {},
  handleDelete = () => {},
  handleDescription = () => {},
  ativo,
  ...rest
}) => {
  const classes = useStyles();
  const record = useRecordContext(rest);
  const gerenciado = record ? record.record.gerenciado : false;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Tooltip title="Editar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="edit"
          classes={{ root: classes.root }}
          size="small"
          onClick={() => handleEdit(record.record)}
          disabled={gerenciado}
        >
          <EditOutlinedIcon />
        </IconButton>
      </Tooltip>
      {!ativo && (
        <Tooltip title="Deletar" placement="bottom" aria-label="add2">
          <IconButton
            aria-label="delete"
            classes={{ root: classes.root }}
            size="small"
            onClick={() => handleDelete(record.record)}
            disabled={gerenciado}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};



export default ActionsField;
