import React, { useState, useRef } from 'react';
import { useListContext, ListBase, ListToolbar, useRefresh } from 'react-admin';
import { useHistory } from 'react-router-dom';

import { Grid, Card, CardContent, Typography, Box } from '@material-ui/core';
import Avatar2 from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { startOfMonth, endOfMonth } from 'date-fns';
import { stringify } from 'query-string';

import {
  DialogoConta,
  DialogoTransacao,
  DialogoReajusteSaldoConta,
  DialogoDeletarConta,
} from '../../Components';
import Avatar from '../../Components/Avatar';
import { formatMoeda } from '../../utils';
import { STATUS_ATIVO } from '../../utils/constants';
import AcessoRapido from './AcessoRapido';
import Actions from './ActionsList';
import Filter from './Filter';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
  },
  media: {
    height: 200,
  },
  title: {
    paddingTop: '0px',
    paddingBottom: '0.5em',
    display: 'flex',
    justifyContent: 'space-between',
    height: 200,
    flexDirection: 'column',
  },
  actionSpacer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  header: {
    fontSize: '20px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  card: {},
  card2: {
    height: '272px',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: 'white',
    color: '#009FD4',
    border: '2px solid #009FD4',
  },
  contentHedare: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: '20px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {},
}))(MenuItem);

const TypographyValor = ({ valor }) => {
  const valorAbsoluto = valor >= 0 ? valor : valor;
  return (
    <Typography
      variant="subtitle1"
      align="center"
      style={{
        color: valor >= 0 ? '#4caf67' : '#f44336',
        fontWeight: 'bold',
        fontSize: '25px',
      }}
    >
      {formatMoeda(valorAbsoluto)}
    </Typography>
  );
};

const anoHoje = new Date().getFullYear();
const mesHoje = new Date().getMonth();

const CardConta = ({
  handleEdit,
  handleTransacoes,
  handleReajusteSaldo,
  handleNewTransacao,
  handleDeletar,
  labelPrevisto,
  ...props
}) => {
  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = useState(null);
  const { nome, valor, color, is_caixa, saldoAtual, saldoPrevisto } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Card
        style={{ borderRadius: '20px', cursor: 'pointer' }}
        className={classes.card}
      >
        <CardHeader
          avatar={<Avatar aria-label="recipe" texto={nome} color={color} />}
          action={
            <IconButton
              aria-label="settings"
              tabindex="-1"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
          }
          title={
            <Tooltip title={nome} placement="bottom" aria-label="add2">
              <Typography className={classes.text}>{nome}</Typography>
            </Tooltip>
          }
          classes={{ title: classes.header, content: classes.contentHedare }}
        />
        <CardContent className={classes.title}>
          {is_caixa ? (
            <Chip
              label="Caixa"
              size="small"
              style={{
                color: 'white',
                width: '70px',
                backgroundColor: '#4caf67',
              }}
            />
          ) : (
            <Chip
              label=""
              size="small"
              style={{
                color: 'white',
                width: '70px',
                backgroundColor: 'white',
              }}
            />
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <TypographyValor valor={saldoAtual} />
          </div>
          <div style={{ marginRight: '5px' }}>
            <AcessoRapido
              isCaixa={is_caixa}
              handleNewTransacao={(tipo) =>
                handleNewTransacao({ tipo, ...props })
              }
            />
          </div>
        </CardContent>
        <Divider />
      </Card>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Zoom}
        transitionDuration={500}
      >
        <StyledMenuItem
          onClick={() => {
            handleClose();
            handleEdit(props);
          }}
        >
          <ListItemText primary="Editar" />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            handleClose();
            handleTransacoes(props);
          }}
        >
          <ListItemText primary="Transações" />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            handleClose();
            handleReajusteSaldo(props);
          }}
        >
          <ListItemText primary="Reajuste de saldo" />
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};

const CategoryGrid = ({
  handleEdit = () => {},
  handleCriar = () => {},
  handleTransacoes = () => {},
  handleReajusteSaldo = () => {},
  handleNewTransacao = () => {},
  handleDeletar = () => {},
  ...props
}) => {
  const classes = useStyles(props);
  const { data, ids } = useListContext();
  return ids ? (
    <div style={{ flex: 2 }}>
      <Grid container spacing={2} className={classes.root}>
        {ids.map((id) => (
          <Grid key={id} xs={12} sm={3} md={4} lg={3} xl={2} item>
            <CardConta
              {...data[id]}
              handleEdit={handleEdit}
              handleTransacoes={handleTransacoes}
              handleReajusteSaldo={handleReajusteSaldo}
              handleNewTransacao={handleNewTransacao}
              handleDeletar={handleDeletar}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  ) : null;
};

const CardTotal = ({ label, valor, icon, cor }) => (
  <Card style={{ borderRadius: '20px', cursor: 'pointer' }}>
    <CardContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%',
          alignItems: 'center',
        }}
      >
        <div>
          <div
            style={{
              fontSize: '17px',
              color: '#a1c3d1',
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {label}
            <ArrowForwardIosIcon
              style={{ fontSize: '10px', marginLeft: '5px' }}
            />
          </div>
          <div style={{ fontSize: '20px', color: 'black', fontWeight: 'bold' }}>
            {formatMoeda(valor)}
          </div>
        </div>
        <div>
          <Avatar2
            aria-label="recipe"
            style={{ backgroundColor: cor, color: 'white' }}
          >
            {icon}
          </Avatar2>
        </div>
      </div>
    </CardContent>
  </Card>
);

const Aside = () => {
  const { data, ids, filterValues } = useListContext();

  function calculaTotais() {
    let saldoAtual = 0;
    let saldoPrevisto = 0;
    let saldoCaixas = 0;

    for (let i = 0; i < ids.length; i += 1) {
      saldoAtual += data[ids[i]].saldoAtual;
      saldoPrevisto += data[ids[i]].saldoPrevisto;
      if (data[ids[i]].is_caixa) {
        saldoCaixas += data[ids[i]].saldoAtual;
      }
    }

    return {
      saldoAtual,
      saldoPrevisto,
      saldoCaixas,
    };
  }

  const totais = calculaTotais();

  return (
    <div
      style={{
        margin: '1em 0 0 1em',
        paddingTop: '8px',
        flex: 0.75,
      }}
    >
      <CardTotal
        label="Saldo atual"
        valor={totais.saldoAtual}
        icon={<MonetizationOnIcon />}
        cor="#009FD4"
      />
      <Box marginTop="20px">
        <CardTotal
          label={
            filterValues.periodo.mes >= mesHoje &&
            filterValues.periodo.ano >= anoHoje
              ? 'Saldo previsto'
              : 'Saldo final do mês'
          }
          valor={totais.saldoPrevisto}
          icon={<LocalAtmIcon />}
          cor="#009FD4"
        />
      </Box>
      <Box marginTop="20px">
        <CardTotal
          label="Saldo caixas"
          valor={totais.saldoCaixas}
          icon={<LocalAtmIcon />}
          cor="#4caf67"
        />
      </Box>
    </div>
  );
};

const MyList = ({ ...props }) => {
  const refresh = useRefresh();
  const history = useHistory();
  const refDialogo = useRef(null);
  const refDialogoReajuste = useRef(null);
  const refDialogoTransacao = useRef(null);
  const refDialogoDeletar = useRef(null);

  const { filterValues } = useListContext();

  const create = (tipo) => {
    if (refDialogo.current) {
      refDialogo.current.handleCreate(tipo);
    }
  };

  const edit = (data) => {
    if (refDialogo.current) {
      refDialogo.current.handleEdit(data.id);
    }
  };

  const transacoes = (data) => {
    const dataMes = new Date();
    dataMes.setFullYear(filterValues.periodo.ano);
    dataMes.setMonth(filterValues.periodo.mes);

    const queryOj = {};

    queryOj.displayedFilters = JSON.stringify({});
    queryOj.filter = JSON.stringify({
      tipo: 'Transacao',
      contas: [data.id],
      periodo: {
        start: startOfMonth(dataMes),
        end: endOfMonth(dataMes),
      },
    });

    history.push(
      `/transacoes?${stringify(
        queryOj,
      )}&order=DESC&page=1&perPage=50&sort=data_pagamento`,
    );
  };

  const reajusteSaldo = (data) => {
    if (refDialogoReajuste.current) {
      refDialogoReajuste.current.handleOpen(data.id);
    }
  };

  const createTransacao = ({ tipo, id }) => {
    if (refDialogoTransacao.current) {
      refDialogoTransacao.current.handleCreateApartirConta(tipo, id);
    }
  };

  const deletar = (data) => {
    if (refDialogoDeletar.current) {
      refDialogoDeletar.current.handleOpen(data.id);
    }
  };

  const handleClose = () => {
    refresh();
  };

  return (
    <>
      <ListToolbar
        filters={<Filter />}
        actions={<Actions handleCriar={create} />}
        {...props}
      />
      <div style={{ borderRadius: '20px', marginTop: '30px', display: 'flex' }}>
        <CategoryGrid
          handleEdit={edit}
          handleCriar={create}
          handleTransacoes={transacoes}
          handleReajusteSaldo={reajusteSaldo}
          handleNewTransacao={createTransacao}
          handleDeletar={deletar}
          {...props}
        />
      </div>
      <DialogoConta ref={refDialogo} handleClose={handleClose} />
      <DialogoTransacao ref={refDialogoTransacao} handleClose={handleClose} />
      <DialogoReajusteSaldoConta
        ref={refDialogoReajuste}
        handleClose={handleClose}
      />
      <DialogoDeletarConta ref={refDialogoDeletar} handleClose={handleClose} />
    </>
  );
};

const ContainerMyList = ({ ...props }) => (
  <ListBase {...props}>
    <MyList />
  </ListBase>
);

const CategoryList = (props) => (
  <>
    <ContainerMyList
      {...props}
      title=""
      component="div"
      bulkActionButtons={false}
      sort={{ field: 'nome', order: 'asc' }}
      filterDefaultValues={{
        tipo: 'Despesa',
        periodo: { ano: new Date().getFullYear(), mes: new Date().getMonth() },
        status: STATUS_ATIVO,
      }}
      perPage={50}
      pagination={false}
      filter={{ is_caixa: false }}
    />
  </>
);

export default CategoryList;
