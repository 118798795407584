import React from 'react';
import { ReferenceField } from 'react-admin';

import CategoriaField from './CategoriaField';

const ReferenceFieldCategoria = ({ children, ...props }) => (
  <ReferenceField {...props}>
    <CategoriaField source="nome" recordPai={props.record} />
  </ReferenceField>
);

export default ReferenceFieldCategoria;
