import React from 'react';
import { Layout } from 'react-admin';

import { makeStyles, Box, Button, Paper } from '@material-ui/core';

import Menu from './Menu';
import Appbar from './Toolbar';

const useStyles = makeStyles(() => ({
  layout: {
    width: '100vw',
  },
}));

const LayoutCustom = (props) => {
  const classes = useStyles();
  return <Layout {...props} appBar={Appbar} menu={Menu} />;
};

export default LayoutCustom;
