import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '400px',
  },
}));

const DataNegociadaField = ({ onClick, ...props }) => {
  const classes = useStyles();

  const record = useRecordContext(props);

  let value = '-';

  if (record) {
    if (record.record.categoria_problema_despesa_id) {
      if (record.record.created_at) {
        const dataCriacao = new Date(
          moment(new Date(record.record.created_at)).endOf('day').format(),
        );

        const dataAtual = new Date(moment(new Date()).endOf('day').format());

        const delta = dataAtual.getTime() - dataCriacao.getTime();

        value = parseInt(delta / (24 * 60 * 60 * 1000), 10);
      }
    }
  }

  return (
    <Tooltip
      title="Clique aqui para mais detalhes"
      placement="bottom"
      aria-label="add2"
    >
      <Typography
        className={classes.text}
        style={{
          cursor: 'pointer',
        }}
        onClick={() =>
          onClick(
            'Nível de Urgência',
            <div>
              <h3
                style={{
                  margin: '10px 0',
                }}
              >
                O que é?
              </h3>
              <p>
                O Nível de urgência é um indicador financeiro que aponta o
                número de dias que uma transação problemática possui. Quanto
                maior for o Nível de Urgência, pior é a situação.
              </p>
              <h3
                style={{
                  margin: '10px 0',
                }}
              >
                Para quê serve?
              </h3>
              <p>
                O Nível de urgência serve para lhe auxiliar a resolver uma
                transação problemática o mais rápido possível. A solução de
                problemas financeiros evitará negativações, protestos ou
                intimações judiciais, assim como facilitará que seja mantidas
                pontuações elevadas de Score Serasa, SPC e afins.
              </p>
              <h3
                style={{
                  margin: '10px 0',
                }}
              >
                Exemplo:
              </h3>
              <p>
                Se uma transação foi cadastrada em 01/01/2021 e no dia
                20/01/2021 ainda não foi resolvida o Nível de Urgência dela será
                19, mostrando que há 19 dias o problema foi cadastrado e até
                agora está sem solução.,
              </p>
            </div>,
          )
        }
      >
        {value}
      </Typography>
    </Tooltip>
  );
};

export default DataNegociadaField;
