import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import { SelectInput } from '../../Components';
import { getListAllAPI, updateAPI } from '../../services';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState(-1);
  const [carregando, setCarregando] = useState(false);
  const [users, setUsers] = useState([]);

  const classes = useStyles();

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function getDataResource() {
    try {
      const data = await getListAllAPI(
        'config',
        ['id', 'asc'],
        { id: [1] },
        [],
      );
      if (data.data.length > 0) {
        setId(data.data[0].user_impressao);
        setCarregando(false);
      } else {
        throw 'Erro ao buscar a fila atual!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function handleSalvar() {
    try {
      if (id <= 0) {
        enqueueSnackbar('Erro ao atualizar a fila!!!', {
          variant: 'error',
        });
      } else {
        await updateAPI('config', 1, {
          user_impressao: id,
        });
        setCarregando(false);
        enqueueSnackbar(`Atualização da fila feita com sucesso!`, {
          variant: 'success',
        });
      }
      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(
        'Existem registros relacionados a esta solicitação de impressão!',
        {
          variant: 'error',
        },
      );
      handleCloseDialog();
    }
  }

  function formatUsers(users) {
    const usersFormat = [];
    for (let i = 0; i < users.length; i += 1) {
      usersFormat.push({
        value: users[i].id,
        label: users[i].username,
      });
    }
    return usersFormat;
  }

  useEffect(() => {
    async function getUsers() {
      try {
        const data = await getListAllAPI(
          'users_impressoes',
          ['id', 'asc'],
          {},
          [],
        );
        if (data.data.length > 0) {
          setUsers(formatUsers(data.data));
        } else {
          throw 'Erro ao buscar os adms!';
        }
      } catch (erros) {
        enqueueSnackbar(`Nenhuma fila disponível!`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }
    if (open) {
      getUsers();
    }
  }, [open]);

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setCarregando(true);
      getDataResource();
      setOpen(true);
    },
  }));

  const handleChange = (event) => {
    setId(parseInt(event.target.value, 10));
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{
            backgroundColor: '#4caf67',
            color: 'white',
          }}
        >
          Definir Fila
        </DialogTitle>
        <DialogContent>
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '20px',
                }}
              >
                <SelectInput
                  options={users}
                  handleEnter={() => {}}
                  handleKey={() => {}}
                  value={id}
                  onChange={handleChange}
                />
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions
          style={{
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={handleCloseDialog}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
            variant="outlined"
            color="primary"
          >
            Fechar
          </Button>
          <Box>
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                variant="contained"
                color="primary"
                disabled={carregando}
              >
                Confirmar
              </Button>
              {carregando && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
