import React, { useRef } from 'react';
import {
  ListBase,
  ListToolbar,
  useRefresh,
  Datagrid,
  Title,
} from 'react-admin';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import ActionsField from './ActionsField';
import Actions from './ActionsList';
import Dialogo from './DialogoCreateEdit';
import DialogoDeleteResource from './DialogoDeletar';
import TituloField from './Fields/TituloField';
import Filter from './Filter';

const useStylesDatagrid = makeStyles((theme) => ({
  statusHeader: {
    paddingLeft: '30px',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  statusCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingLeft: '30px',
  },
  statusContent: {
    flex: 5,
    marginLeft: '30px',
    maxWidth: '100px',
  },
  dataHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoHeader: {
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 'bold',
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoCell: {
    flex: 2,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaHeader: {
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaCell: {
    flex: 2,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaHeader: {
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaCell: {
    flex: 2,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 'bold',
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  actionsHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 'bold',
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingRight: '100px',
  },
  actionsCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingRight: '100px',
  },
}));

const DataGridLocal = ({
  handleEdit = () => {},
  handleDeletar = () => {},
  ...props
}) => {
  const classes = useStylesDatagrid();
  return (
    <>
      <Datagrid {...props} bulkActionButtons={false}>
        <TituloField
          source="nome"
          label="Título"
          sortable={false}
          headerClassName={classes.descricaoHeader}
          cellClassName={classes.descricaoCell}
        />
        <ActionsField
          source="lalala"
          handleEdit={handleEdit}
          handleDeletar={handleDeletar}
          textAlign="right"
          label="Ações"
          headerClassName={classes.actionsHeader}
          cellClassName={classes.actionsCell}
          sortable={false}
        />
      </Datagrid>
    </>
  );
};

const MyList = ({ ...props }) => {
  const refresh = useRefresh();
  const refDialogo = useRef(null);
  const refDialogoDeletar = useRef(null);

  const create = (tipo) => {
    if (refDialogo.current) {
      refDialogo.current.handleCreate(tipo);
    }
  };

  const edit = (data) => {
    if (refDialogo.current) {
      refDialogo.current.handleEdit(data.id);
    }
  };

  const deletar = (data) => {
    if (refDialogoDeletar.current) {
      refDialogoDeletar.current.handleOpen(data.id);
    }
  };

  const handleClose = () => {
    refresh();
  };

  return (
    <>
      <Title title="Vídeos Tutoriais FC" />
      <ListToolbar
        filters={<Filter />}
        actions={<Actions handleCriar={create} />}
        {...props}
      />
      <Paper
        elevation={1}
        style={{
          borderRadius: '20px',
          marginTop: '30px',
          display: 'flex',
          padding: '10px',
        }}
      >
        <DataGridLocal handleEdit={edit} handleDeletar={deletar} {...props} />
      </Paper>
      <Dialogo ref={refDialogo} handleClose={handleClose} />
      <DialogoDeleteResource
        ref={refDialogoDeletar}
        handleClose={handleClose}
      />
    </>
  );
};

const ContainerMyList = ({ ...props }) => (
  <ListBase {...props}>
    <MyList {...props} />
  </ListBase>
);

const PostPanel = ({ record }) => (
  <div>
    <br />
    <p>
      <span style={{ fontWeight: 'bold' }}>Link: </span>
      {record.link}
    </p>
    <br />
    <p>
      <span style={{ fontWeight: 'bold' }}>Descrição: </span>
      {record.descricao}
    </p>
    <br />
  </div>
);

const CategoryList = (props) => (
  <>
    <ContainerMyList
      {...props}
      title="Vídeos Tutoriais FC"
      expand={<PostPanel />}
      component="div"
      bulkActionButtons={false}
      sort={{ field: 'nome', order: 'asc' }}
      perPage={20}
      pagination={false}
    />
  </>
);

export default CategoryList;
