import React from 'react';
import { SaveButton, Toolbar, DeleteButton } from 'react-admin';
import { useHistory } from 'react-router-dom';

import BodyToolbar from './BodyToolbar';

export const EditToolbar = ({ rota = '', isDelete = true, ...rest }) => {
  const history = useHistory();
  const hasSearch = history.location.search !== '';
  const voltar = () => {
    history.goBack();
  };
  return (
    <Toolbar {...rest}>
      <BodyToolbar>
        <SaveButton
          redirect={hasSearch ? voltar : rota === '' ? 'show' : rota}
        />
        {isDelete ? (
          <DeleteButton
            redirect={hasSearch ? voltar : rota === '' ? 'show' : rota}
          />
        ) : null}
      </BodyToolbar>
    </Toolbar>
  );
};

export const CreateToolbar = ({
  rotaSalvarListar = '',
  rotaSalvarAdd = '',
  alignContent = 'default',
  hideSalvarListar = false,
  hideSalvarAdd = false,
  labelSalvarListar = 'Salvar e listar',
  labelSalavarAdd = 'Salvar e adicionar',
  ...rest
}) => {
  const history = useHistory();
  const hasSearch = history.location.search !== '';
  const voltar = () => {
    history.goBack();
  };
  return (
    <Toolbar {...rest}>
      <BodyToolbar alignContent={alignContent}>
        {!hideSalvarListar && (
          <SaveButton
            label={labelSalvarListar}
            redirect={
              hasSearch
                ? voltar
                : rotaSalvarListar === ''
                ? 'list'
                : rotaSalvarListar
            }
            submitOnEnter={false}
          />
        )}
        {!hideSalvarAdd && !hasSearch && (
          <SaveButton
            label={labelSalavarAdd}
            redirect={
              hasSearch ? voltar : rotaSalvarAdd === '' ? false : rotaSalvarAdd
            }
            submitOnEnter={false}
            variant="text"
          />
        )}
      </BodyToolbar>
    </Toolbar>
  );
};
