import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';

import Chip from '@material-ui/core/Chip';
import blue from '@material-ui/core/colors/blue';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import ChipInput from './index';

const suggestions2 = [
  {
    label: 'Ajustes',
    id: 1,
    cor: 'red',
    icone: '',
  },
  {
    label: 'Carro e moto',
    id: 2,
    cor: 'blue',
    icone: '',
  },
  {
    label: 'Albania',
    id: 3,
    cor: 'green',
    icone: '',
  },
  {
    label: 'Algeria',
    id: 4,
    cor: 'black',
    icone: '',
  },
];

const textVazioPadrao = 'Todas as categorias';

const suggestionPadrao = {
  label: textVazioPadrao,
  id: -1,
  cor: 'black',
  icone: '',
};

const defaultChipRenderer = (
  {
    value,
    text,
    isFocused,
    isDisabled,
    isReadOnly,
    handleClick,
    handleDelete,
    className,
  },
  key,
) => (
  <>
    {value.id === suggestionPadrao.id ? (
      <Chip
        key={key}
        className={className}
        style={{
          pointerEvents: isDisabled || isReadOnly ? 'none' : undefined,
          backgroundColor: isFocused ? blue[300] : undefined,
        }}
        onClick={handleClick}
        label={value.label}
      />
    ) : (
      <Chip
        key={key}
        className={className}
        style={{
          pointerEvents: isDisabled || isReadOnly ? 'none' : undefined,
          backgroundColor: isFocused ? blue[300] : undefined,
        }}
        onClick={handleClick}
        onDelete={handleDelete}
        label={value.label}
      />
    )}
  </>
);

function defaultRenderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem
      selected={isHighlighted}
      component="div"
      onMouseDown={(e) => e.preventDefault()}
    >
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <span key={String(index)}>{part.text}</span>
          ),
        )}
      </div>
    </MenuItem>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 10,
    left: 0,
    right: 0,
    zIndex: 1000000000000,
    overflowX: 'auto',
    borderRadius: '25px',
    maxHeight: '200px',
    padding: '0px 0',
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    zIndex: 1000000000000,
  },
  textField: {
    width: '100%',
  },
}));

const ReactAutosuggest1 = ({
  valorVazio = suggestionPadrao,
  chipRenderer = defaultChipRenderer,
  renderSuggestion = defaultRenderSuggestion,
  options = suggestions2,
  value,
  onChange,
  label,
  ...other
}) => {
  const classes = useStyles();
  const [suggestions, setSuggestions] = useState([]);
  const [textFieldInput, setTextFieldInput] = useState('');

  function getSuggestions(value, chipUsados) {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    function retornaNaoUsados(array) {
      return array.filter((suggestion) => {
        let isPertense = false;
        chipUsados.forEach((chip) => {
          if (!isPertense) {
            if (chip.id === suggestion.id) {
              isPertense = true;
            }
          }
        });
        return !isPertense;
      });
    }

    return inputLength === 0
      ? retornaNaoUsados(options)
      : retornaNaoUsados(
          options.filter((suggestion) => {
            const keep =
              suggestion.label.toLowerCase().slice(0, inputLength) ===
              inputValue;

            return keep;
          }),
        );
  }

  const handleSuggestionsFetchRequested = ({ value: newValue }) => {
    setSuggestions(getSuggestions(newValue, value));
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handletextFieldInputChange = (event, { newValue }) => {
    if (newValue) {
      if (newValue.label) {
        setTextFieldInput(newValue.label);
      }
    }
  };

  const handleAddChip = (chip) => {
    onChange([...value, chip]);
    setTextFieldInput('');
  };

  const handleDeleteChip = (chip, index) => {
    const temp = value.slice();
    temp.splice(index, 1);
    onChange(temp);
  };

  function renderInput(inputProps) {
    const { value, onChange, chips, ref, label, ...other } = inputProps;

    return (
      <ChipInput
        clearInputValueOnChange
        onUpdateInput={onChange}
        value={chips}
        inputRef={ref}
        fullWidth
        chipRenderer={chipRenderer}
        label={label}
        {...other}
      />
    );
  }

  function getSuggestionValue(suggestion) {
    return suggestion;
  }

  function renderSuggestionsContainer(options) {
    const { containerProps, children } = options;

    return (
      <Paper
        {...containerProps}
        // square
        /* style={{
          borderRadius: '25px',
          maxHeight: '300px',
          padding: '0px 0',
          // zIndex: 1000000000,
        }} */
      >
        {children}
      </Paper>
    );
  }

  return (
    <Autosuggest
      theme={{
        container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion,
      }}
      renderInputComponent={renderInput}
      suggestions={suggestions}
      onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
      onSuggestionsClearRequested={handleSuggestionsClearRequested}
      renderSuggestionsContainer={renderSuggestionsContainer}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      onSuggestionSelected={(e, { suggestionValue }) => {
        handleAddChip(suggestionValue);
        e.preventDefault();
      }}
      focusInputOnSuggestionClick
      highlightFirstSuggestion
      shouldRenderSuggestions={() => true}
      inputProps={{
        chips: value.length > 0 ? value : [valorVazio],
        value: textFieldInput,
        onChange: handletextFieldInputChange,
        onAdd: (chip) => handleAddChip(chip),
        onDelete: (chip, index) => handleDeleteChip(chip, index),
        label,
        ...other,
      }}
    />
  );
};

export default ReactAutosuggest1;
