import React, { useRef, useState, useEffect } from 'react';
import {
  ListBase,
  ListToolbar,
  Pagination,
  useRefresh,
  Title,
  useListContext,
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  ReferenceField,
  TextField,
  useVersion,
} from 'react-admin';

import { Card, makeStyles, CardContent } from '@material-ui/core';
import { stringify } from 'query-string';

import { api } from '../../services';
import { formatMoeda, SECONDARY_COLOR } from '../../utils';
import DateMobillsInput from '../Transacoes/Inputs/DateInputMobills';
import Actions from './ActionsList';
import Dialogo from './DialogoCreateEdit';
import DialogoDeleteResource from './DialogoDeletar';
import DialogoDescontos from './DialogoDescontos';
import ActionsField from './Fields/ActionsField';
import DescricaoField from './Fields/DescricaoField';
import Filter from './Filter';

const MES_ATUAL = new Date().getMonth();
const ANO_ATUAL = new Date().getFullYear();

const useStyles = makeStyles((theme) => ({
  statusHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  statusCell: {
    // marginLeft: '30px',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    // paddingLeft: '30px',
  },
  statusContent: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 5,
    marginLeft: '30px',
    maxWidth: '100px',
  },
  dataHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: SECONDARY_COLOR,
    padding: '10px',
    color: 'white',
    fontWeight: 'bold',
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoCell: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaCell: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
}));

const CardTotal = ({ label, value, onClick }) => (
  <Card
    style={{ borderRadius: '20px', cursor: 'pointer', width: '50%' }}
    onClick={onClick}
  >
    <CardContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%',
          alignItems: 'center',
        }}
      >
        <div>
          <div
            style={{
              fontSize: '17px',
              color: '#a1c3d1',
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {label}
          </div>
          <div style={{ fontSize: '20px', color: 'black', fontWeight: 'bold' }}>
            {formatMoeda(value)}
          </div>
        </div>
      </div>
    </CardContent>
  </Card>
);

const SessaoTotais = () => {
  const listContext = useListContext();
  const version = useVersion();
  const { ids, filterValues, setFilters, displayedFilters, data } = listContext;

  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  async function getDados() {
    setLoading(true);
    const dados = await api.get(
      `/total_descontos_funcionarios?${stringify({
        filter: JSON.stringify({
          search: filterValues.search,
          categoria_desconto_funcionario_id:
            filterValues.categoria_desconto_funcionario_id,
          user_id: filterValues.user_id,
          periodo2: filterValues.periodo2,
          desconto_aceitavel_funcionario:
            filterValues.desconto_aceitavel_funcionario,
        }),
      })}`,
    );

    setTotal(dados.data.result);
    setLoading(false);
  }

  useEffect(() => {
    getDados();
  }, [
    filterValues.search,
    filterValues.categoria_desconto_funcionario_id,
    filterValues.user_id,
    filterValues.periodo2,
    filterValues.desconto_aceitavel_funcionario,
    version,
  ]);

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        margin: '30px 0 15px',
        maxHeight: '100px',
      }}
    >
      <CardTotal label="Total" value={total} onClick={() => {}} />
    </div>
  );
};

const PostPanel = (props) => (
  <div>{`Descrição: ${props.record.descricao}`}</div>
);

const MyList = ({ ...props }) => {
  const refDialogoCompra = useRef(null);
  const refDialogoDescontos = useRef(null);
  const refDialogoDeleteResource = useRef(null);
  const refresh = useRefresh();
  const listContext = useListContext();
  const classes = useStyles();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;

  function handleCriar() {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleCreate();
    }
  }
  function handleEdit({ id }) {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleEdit(id);
    }
  }
  function deleteHundle({ id }) {
    if (refDialogoDeleteResource.current)
      refDialogoDeleteResource.current.handleOpen(id);
  }

  const handleClose = () => {
    refresh();
  };

  function handleDialogoDesconto() {
    if (refDialogoDescontos.current) {
      refDialogoDescontos.current.handleOpen();
    }
  }

  return (
    <>
      <Title title="Desconto de funcionários" />
      <ListToolbar
        filters={props.filters}
        actions={
          <Actions
            handleCriar={handleCriar}
            handleDialogoDesconto={handleDialogoDesconto}
            {...props}
          />
        }
        {...props}
      />
      <div>
        <DateMobillsInput />
      </div>
      <SessaoTotais {...props} />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid {...props}>
          <ReferenceField
            label="Funcionário"
            source="user_id"
            reference="users"
            sortable={false}
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          >
            <TextField source="username" />
          </ReferenceField>
          <ReferenceField
            label="Categoria"
            source="categoria_desconto_funcionario_id"
            reference="categorias_descontos_funcionarios"
            sortable={false}
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          >
            <TextField source="nome" />
          </ReferenceField>
          {/* <DescricaoField
            source="descricao"
            label="Descrição"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          /> */}
          <NumberField
            source="valor"
            locales="pt-BR"
            options={{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            }}
            label="Valor"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <DateField
            source="data"
            label="Data"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />

          <ActionsField
            source="valor"
            textAlign="right"
            label="Ações"
            sortable={false}
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            handleDeletar={deleteHundle}
            handleEdit={handleEdit}
          />
        </Datagrid>
        <TransacoesPagination />
      </Card>
      <Dialogo ref={refDialogoCompra} handleClose={handleClose} />
      <DialogoDeleteResource
        ref={refDialogoDeleteResource}
        handleClose={handleClose}
      />
      <DialogoDescontos ref={refDialogoDescontos} handleClose={handleClose} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      expand={<PostPanel />}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Desconto de funcionários"
      component="div"
      filterDefaultValues={{
        periodo2: { mes: MES_ATUAL, ano: ANO_ATUAL },
      }}
    />
  </>
);

export default List2;
