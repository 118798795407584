import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import {
  TextInput,
  DateInputBonito,
  AutoCompleteLocal,
  SwitchInput,
  AutoCompleteRemoto,
} from '../../../Components';
import { getListAllAPI, api } from '../../../services';
import {
  TIPO_FERIADO_DATA_COMEMORATIVA,
  TIPO_FERIADO_FERIADO,
  TIPO_FERIADO_DIA_COMUM,
  UNIVERSO_FERIADO_ESTADUAL,
  UNIVERSO_FERIADO_MUNICIPAL,
  UNIVERSO_FERIADO_NACIONAL,
  UNIVERSO_FERIADO_SINDICATO,
  STATUS_LOJA_ABERTA,
  STATUS_LOJA_FECHADA,
  TIPO_HORARIO_NORMAL,
  TIPO_HORARIO_DOMINGO,
} from '../../../utils/constants';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_ID = -1;

const optionsTipo = [
  { value: TIPO_FERIADO_FERIADO, label: 'Feriado' },
  {
    value: TIPO_FERIADO_DATA_COMEMORATIVA,
    label: 'Data Comemorativa',
  },
];

const optionsUniverso = [
  { value: UNIVERSO_FERIADO_MUNICIPAL, label: 'Municipal' },
  {
    value: UNIVERSO_FERIADO_ESTADUAL,
    label: 'Estadual',
  },
  { value: UNIVERSO_FERIADO_NACIONAL, label: 'Nacional' },
  { value: UNIVERSO_FERIADO_SINDICATO, label: 'Sindicato' },
];

const optionsStatusLoja = [
  { value: STATUS_LOJA_ABERTA, label: 'Aberta' },
  {
    value: STATUS_LOJA_FECHADA,
    label: 'Fechada',
  },
];

const optionsTipoHorario = [
  { value: TIPO_HORARIO_NORMAL, label: 'Normal' },
  {
    value: TIPO_HORARIO_DOMINGO,
    label: 'Domingo',
  },
];

function getTipo(value) {
  switch (value) {
    case TIPO_FERIADO_FERIADO:
      return optionsTipo[0];
    case TIPO_FERIADO_DATA_COMEMORATIVA:
      return optionsTipo[1];
    default:
      return null;
  }
}

function getUniverso(value) {
  switch (value) {
    case UNIVERSO_FERIADO_MUNICIPAL:
      return optionsUniverso[0];
    case UNIVERSO_FERIADO_ESTADUAL:
      return optionsUniverso[1];
    case UNIVERSO_FERIADO_NACIONAL:
      return optionsUniverso[2];
    case UNIVERSO_FERIADO_SINDICATO:
      return optionsUniverso[3];
    default:
      return null;
  }
}

function getStatusLoja(value) {
  switch (value) {
    case STATUS_LOJA_ABERTA:
      return optionsStatusLoja[0];
    case STATUS_LOJA_FECHADA:
      return optionsStatusLoja[1];
    default:
      return null;
  }
}

function getTipoHorario(value) {
  switch (value) {
    case TIPO_HORARIO_NORMAL:
      return optionsTipoHorario[0];
    case TIPO_HORARIO_DOMINGO:
      return optionsTipoHorario[1];
    default:
      return null;
  }
}

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [nome, setNome] = useState('');
  const [data, setData] = useState(new Date());
  const [tipo, setTipo] = useState(optionsTipo[0]);
  const [universo, setUniverso] = useState(optionsUniverso[0]);
  const [id, setId] = useState(DEFAULT_ID);
  const [isRecorrente, setIsRecorrente] = useState(false);
  const [equipe, setEquipe] = useState(null);

  const [statusLoja, setStatusLoja] = useState(optionsStatusLoja[0]);
  const [tipoHorario, setTipoHorario] = useState(optionsTipoHorario[0]);

  const [carregando, setCarregando] = useState(false);

  const classes = useStyles({ cor: '#009FD4' });

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function reiniciar() {
    setId(DEFAULT_ID);
    setNome('');
    setData(new Date());
    setTipo(optionsTipo[0]);
    setUniverso(optionsUniverso[0]);
    setIsRecorrente(false);
    setEquipe(null);
    setStatusLoja(optionsStatusLoja[0]);
    setTipoHorario(optionsTipoHorario[0]);
    setCarregando(false);
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'feriados',
        ['id', 'asc'],
        { id: [id] },
        ['equipe'],
      );
      if (data.data.length > 0) {
        if (data.data.tipo === TIPO_FERIADO_DIA_COMUM)
          throw 'Este dia não é feriado!';
        setId(data.data[0].id);
        setNome(data.data[0].nome);
        setData(data.data[0].data);
        setTipo(getTipo(data.data[0].tipo));
        setUniverso(getUniverso(data.data[0].universo));
        setIsRecorrente(!!data.data[0].grupo_feriado_id);
        setEquipe(data.data[0].equipe);

        setStatusLoja(getStatusLoja(data.data[0].status_loja));
        setTipoHorario(getTipoHorario(data.data[0].tipo_horario));
        setCarregando(false);
      } else {
        throw 'Este feriado não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    try {
      const data2 = new Date(data);

      const dia = data2.getDate();
      const mes = data2.getMonth();
      const ano = data2.getFullYear();

      await api.put(`feriados/${dia}/${mes}/${ano}`, {
        nome,
        tipo: tipo.value,
        universo: universo.value,
        isRecorrente,
        equipe_colaborador_id: equipe ? equipe.id : null,
        status_loja: statusLoja.value,
        tipo_horario: tipoHorario.value,
        id,
      });
      setCarregando(false);
      enqueueSnackbar('Feriado atualizado com sucesso!', {
        variant: 'success',
      });

      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      reiniciar();
      setOpen(true);
    },
    handleEdit(id) {
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
    },
  }));

  function validateNome() {
    let error = '';
    if (!nome) error = 'Este campo é obrigatório';
    else if (nome.length > 80) {
      error = 'Número de caracteres maior que o permitido (máximo 80)';
    }
    return error;
  }

  function validateTipo() {
    let error = '';
    if (!tipo) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateUniverso() {
    let error = '';
    if (!universo) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateStatusLoja() {
    let error = '';
    if (!statusLoja) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateTipoHorario() {
    let error = '';
    if (!tipoHorario) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function getErros() {
    const errosOld = [''];
    errosOld[0] = validateNome();
    errosOld[1] = validateTipo();
    errosOld[2] = validateUniverso();
    errosOld[3] = validateStatusLoja();
    errosOld[4] = validateTipoHorario();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          {id < 0 ? `Novo Feriado` : `Editar Feriado`}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <Box flex={1} mr="0.5em">
                <TextInput
                  name="nome"
                  handleEnter={() => {}}
                  label="Descrição"
                  handleKey={() => {}}
                  value={nome}
                  onChange={(value) => setNome(value.target.value)}
                  error={erros[0] !== ''}
                  helperText={erros[0]}
                  fullWidth
                  autoFocus
                  variant="standard"
                />
              </Box>
              <Box flex={1} mr="0.5em">
                <DateInputBonito
                  showTodayButton
                  disablePast
                  todayLabel="Hoje"
                  name="data"
                  handleEnter={() => {}}
                  label="Data"
                  handleKey={() => {}}
                  value={data}
                  onChange={(value) => {
                    setData(value);
                  }}
                  format="dd MMMM yyyy"
                  fullWidth
                  variant="standard"
                />
              </Box>
              <Box flex={1} mr="0.5em">
                <AutoCompleteLocal
                  name="tipo"
                  handleEnter={() => {}}
                  label="Tipo"
                  handleKey={() => {}}
                  value={tipo}
                  onChangeLocal={(value) => setTipo(value)}
                  error={erros[1] !== ''}
                  helperText={erros[1]}
                  options={optionsTipo}
                  autoFocus
                  campo="label"
                  variant="standard"
                />
              </Box>
              <Box flex={1} mr="0.5em">
                <AutoCompleteLocal
                  name="universo"
                  handleEnter={() => {}}
                  label="Universo"
                  handleKey={() => {}}
                  value={universo}
                  onChangeLocal={(value) => setUniverso(value)}
                  error={erros[2] !== ''}
                  helperText={erros[2]}
                  options={optionsUniverso}
                  autoFocus
                  campo="label"
                  variant="standard"
                />
              </Box>
              <Box flex={1} mr="0.5em">
                <AutoCompleteLocal
                  name="status_loja"
                  handleEnter={() => {}}
                  label="Status da Loja"
                  handleKey={() => {}}
                  value={statusLoja}
                  onChangeLocal={(value) => setStatusLoja(value)}
                  error={erros[3] !== ''}
                  helperText={erros[3]}
                  options={optionsStatusLoja}
                  autoFocus
                  campo="label"
                  variant="standard"
                />
              </Box>
              <Box flex={1} mr="0.5em">
                <AutoCompleteLocal
                  name="tipo_horario"
                  handleEnter={() => {}}
                  label="Tipo de Horário"
                  handleKey={() => {}}
                  value={tipoHorario}
                  onChangeLocal={(value) => setTipoHorario(value)}
                  error={erros[4] !== ''}
                  helperText={erros[4]}
                  options={optionsTipoHorario}
                  autoFocus
                  campo="label"
                  variant="standard"
                />
              </Box>
              <Box flex={1} mr="0.5em">
                <AutoCompleteRemoto
                  name="equipe"
                  handleEnter={() => {}}
                  resource="equipes_colaborador"
                  nested={[]}
                  label="Equipe"
                  handleKey={() => {}}
                  value={equipe}
                  onChange={(value) => setEquipe(value)}
                  toString={false}
                  variant="standard"
                  fullWidth
                />
              </Box>
              <Box flex={1} mr="0.5em">
                <SwitchInput
                  name="recorrente"
                  handleEnter={() => {}}
                  label="Recorrente"
                  handleKey={() => {}}
                  checked={isRecorrente}
                  onChange={(value) => {
                    setIsRecorrente(value.target.checked);
                  }}
                />
              </Box>
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          <div className={classes.wrapper}>
            <Button
              onClick={handleSalvar}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              disabled={erroExistente || carregando}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
            {carregando && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
