import React from 'react';

import { parse } from 'query-string';

import CreateTemplate from '../../Components/Create';
import Form from './Form';
import validate from './Validation';

const POR_MES = 'Mensal';

function transform({
  valor,
  nome,
  horario,
  conta_id,
  categoria_transacao_id,
  status,
  tipo_edicao,
  fixo,
  repeticoes,
  tipo_periodo,
  ...data
}) {
  return {
    valor: parseFloat(valor),
    nome,
    horario,
    conta_id,
    categoria_transacao_id,
    status,
    tipo_edicao,
    fixo,
    repeticoes: fixo ? parseInt(repeticoes, 10) : 0,
    tipo_periodo,
  };
}

const Create = (props) => (
  <CreateTemplate
    {...props}
    title="Nova Despesa"
    validate={validate}
    defaultValue={{
      horario: new Date(),
      fixo: false,
      tipo_periodo: POR_MES,
      status: true,
    }}
    transform={transform}
    isBack
  >
    <Form />
  </CreateTemplate>
);

export default Create;
