import * as React from 'react';
import { useRecordContext } from 'react-admin';

const ActionsField = ({ handleEfetivar, ...rest }) => {
  const record = useRecordContext(rest);
  const value = record ? record.record.nome : '';

  return (
    <div
      style={{
        marginRight: '0px',
        width: '120px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {value.includes('Pix à Distância')
        ? 'Pix à Distância'
        : 'Pix por QR CODE'}
    </div>
  );
};

export default ActionsField;
