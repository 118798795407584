import React, { useState } from 'react';

import { makeStyles, responsiveFontSizes } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';

import { AutoCompleteLocalCustomizado } from '../../../../Components';

const Vazio = [
  {
    nome: 'Todas as situações',
    id: -1,
    cor: 'red',
    icone: '',
  },
];

const useStyles3 = makeStyles({
  chip: {
    borderColor: (props) => props.color,
    backgroundColor: 'white',
    cursor: 'default',
    color: (props) => props.color,
    '&:hover': {
      color: (props) => props.color,
      backgroundColor: 'white',
    },
  },
  deleteIcon: {
    color: (props) => props.color,
    cursor: 'pointer',
    '&:hover': {
      color: (props) => props.color,
    },
  },
});

const options = [
  {
    nome: 'Efetuadas',
    id: 1,
    color: '#8e8e8e',
    icon: '',
  },
  {
    nome: 'Pendentes',
    id: 2,
    color: '#8e8e8e',
    icon: '',
  },
  {
    nome: 'Cartão de crédito',
    id: 3,
    color: '#8e8e8e',
    icon: '',
  },
];

const ChipCategoria = ({ option, injetado, campo }) => {
  const classes2 = useStyles3({ color: option.color });
  const { onDelete, ...rest } = injetado;
  return (
    <>
      {option.id < 0 ? (
        <Chip
          style={{
            color: '#8e8e8e',
            borderColor: '#8e8e8e',
          }}
          variant="outlined"
          label={option[campo]}
          {...rest}
        />
      ) : (
        <Chip
          classes={{ outlined: classes2.chip, deleteIcon: classes2.deleteIcon }}
          variant="outlined"
          label={option[campo]}
          {...rest}
          style={{
            color: '#8e8e8e',
            borderColor: '#8e8e8e',
          }}
          onDelete={onDelete}
        />
      )}
    </>
  );
};

const Componente = ({
  campo = 'nome',
  label,
  handleEnter = () => {},
  handleKey = () => {},
  value,
  onChange,
}) => {
  const handleOnChange2 = (value) => {
    if (value.length > 0) {
      /* if (value[0].id < 0) {
        onChange(value.slice(1));
      } else {
        onChange(value);
      } */
      onChange([value[value.length - 1]]);
    } else {
      onChange(value);
    }
  };

  const handleOnChange = (value) => {
    const copia = value.slice();
    if (value.length > 0) {
      if (value[0].id < 0) {
        onChange(value.slice(1));
      } else {
        let positionPendentes = -1;
        let positionEfetuados = -1;
        for (let i = 0; i < value.length; i += 1) {
          if (value[i].id === 1) {
            positionEfetuados = i;
          }

          if (value[i].id === 2) {
            positionPendentes = i;
          }
        }
        if (positionEfetuados === -1 || positionPendentes === -1) {
          onChange(value);
        } else {
          const menorPosicao =
            positionEfetuados < positionPendentes
              ? positionEfetuados
              : positionPendentes;

          copia.splice(menorPosicao, 1);
          onChange(copia);
        }
      }
    } else {
      onChange(value);
    }
  };

  const renderTag = (option, injetado) => (
    <ChipCategoria option={option} injetado={injetado} campo={campo} />
  );

  const renderOption = (option, { selected }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>{option[campo]}</div>
  );

  return (
    <AutoCompleteLocalCustomizado
      name={label}
      handleEnter={handleEnter}
      label={label}
      handleKey={handleKey}
      value={value.length <= 0 ? Vazio : value}
      disableClearable={value.length <= 0}
      onChangeLocal={handleOnChange}
      campo={campo}
      options={options}
      variant="standard"
      renderTag={renderTag}
      renderOption={renderOption}
      style={{ marginTop: 0, marginBottom: 0 }}
    />
  );
};

export default Componente;
