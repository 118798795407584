import React from 'react';
import {
  TextField,
  DateField,
  FunctionField,
  SimpleShowLayout,
  ReferenceField,
  BooleanField,
} from 'react-admin';

import ShowTemplate from '../../Components/Show';
import {
  formatCEP,
  formatCNPJ,
  formatCPF,
  formatTelefone,
  formatIE,
} from '../../utils';

const Title = ({ record }) => (
  <span>Cliente: {record ? `"${record.nome}"` : ''}</span>
);

const Show = (props) => (
  <ShowTemplate {...props} title={<Title />} isBack>
    <SimpleShowLayout>
      <TextField label="Nome" source="nome" />
      <TextField label="Razão Social" source="razao_social" />
      <FunctionField label="CPF" render={(record) => formatCPF(record.cpf)} />
      <TextField label="RG" source="rg" />
      <FunctionField
        label="CNPJ"
        render={(record) => formatCNPJ(record.cnpj)}
      />
      <FunctionField
        label="Inscrição Estadual"
        render={(record) => formatIE(record.inscricao_estadual)}
      />
      <TextField label="Nome Fantasia" source="nome_fantasia" />
      <DateField label="Aniversário" source="dataAniversario" />
      <DateField source="created_at" label="Data Cadastramento" />
      <ReferenceField
        source="user_id"
        reference="users"
        label="Adm"
        allowEmpty={false}
        sortable={false}
      >
        <TextField source="username" />
      </ReferenceField>
      <TextField label="Logradouro" source="logradouro" />
      <TextField label="Nº" source="numero" />
      <TextField label="Complemento" source="complemento" multiline />
      <TextField label="Bairro" source="bairro" />
      <FunctionField label="CEP" render={(record) => formatCEP(record.cep)} />
      <ReferenceField
        source="cidade_id"
        reference="cidades"
        label="Cidade"
        sortable={false}
      >
        <TextField source="nome" />
      </ReferenceField>
      <FunctionField
        label="Telefone"
        render={(record) => formatTelefone(record.telefone)}
      />
      <TextField label="Email" source="email" type="email" />
      {/* <BooleanField source="Fidelidade" label="is_fidelidade" /> */}
      <FunctionField
        label="Score"
        render={(record) => (!record.is_fidelidade ? '-' : record.score_total)}
      />
      <FunctionField
        label="Créditos não usados"
        render={(record) =>
          !record.is_fidelidade ? '-' : record.score_nao_usado
        }
      />
    </SimpleShowLayout>
  </ShowTemplate>
);

export default Show;
