import React, { useRef } from 'react';
import {
  TextField,
  ListBase,
  ListToolbar,
  Pagination,
  useRefresh,
  Title,
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  ReferenceField,
  Datagrid,
  FunctionField,
} from 'react-admin';

import { Card, makeStyles, Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { api, imprimirContraProva } from '../../services';
import {
  STATUS_ATIVO,
  STATUS_INATIVO,
  TURNO_MANHA,
  TURNO_NOITE,
  TURNO_12_HORAS,
} from '../../utils/constants';
import DateInputMobills from '../ConferenciaTemperaturaFreezer/Inputs/DateInputMobills';
import Actions from './ActionsList';
import DialogoHorarios from './DialogoHorarios';
import DialogoMudarTurnoDia from './DialogoMudarTurnoDia';
import DialogoShow from './DialogoShow';
import ActionsField from './Fields/ActionsField';
import DataHorarioField from './Fields/DataHorarioField';
import StatusField from './Fields/StatusField';
import Filter from './Filter';

const useStyles = makeStyles((theme) => ({
  dataHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
}));

const optionsTurno = {
  [TURNO_MANHA]: 'Manhã',
  [TURNO_NOITE]: 'Noite',
  [TURNO_12_HORAS]: '12H',
};

const MyList = ({ ...props }) => {
  const refresh = useRefresh();
  const classes = useStyles();
  const refDialogoHorarios = useRef(null);
  const refDialogoShow = useRef(null);
  const refDialogoMudarTurnoDia = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    refresh();
  };

  function handleOpenDialogoHorarios({ id }) {
    if (refDialogoHorarios.current) {
      refDialogoHorarios.current.handleOpen(id);
    }
  }

  function handleOpenDialogoShow({ id }) {
    if (refDialogoShow.current) {
      refDialogoShow.current.handleOpen(id);
    }
  }

  async function handleImprimirContraProva({ colaborador_id }) {
    const response = await api.get(
      `/ultimo_ponto_colaborador/${colaborador_id}`,
    );
    if (response.data.id) {
      const codigo = await imprimirContraProva(response.data.id);
      if (codigo < 0) {
        enqueueSnackbar(
          'Erro ao imprimir a última contraprova deste colaborador!',
          {
            variant: 'error',
          },
        );
      }
    } else {
      enqueueSnackbar('Erro ao encontrar este ponto!', {
        variant: 'error',
      });
    }
  }

  function handleTrocarTurno({ id }) {
    if (refDialogoMudarTurnoDia.current) {
      refDialogoMudarTurnoDia.current.handleOpen(id);
    }
  }

  return (
    <>
      <Title title="Presenças" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Box flex={1} margin="0px 0 20px">
          <DateInputMobills />
        </Box>
        <Datagrid {...props}>
          <StatusField
            source="is_verificado"
            label="Conferido"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            sortable={false}
          />
          <ReferenceField
            label="Colaborador"
            source="colaborador_id"
            reference="colaboradores"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          >
            <TextField source="nome" />
          </ReferenceField>
          <FunctionField
            label="Turno"
            render={(record) => optionsTurno[record.turno]}
          />
          <DataHorarioField
            source="horario_entrada_1"
            label="Entrada Expediente 1"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            sortable={false}
          />
          <DataHorarioField
            source="horario_saida_1"
            label="Saída Expediente 1"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            sortable={false}
          />
          <DataHorarioField
            source="horario_entrada_2"
            label="Entrada Expediente 2"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            sortable={false}
          />
          <DataHorarioField
            source="horario_saida_2"
            label="Saída Expediente 2"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            sortable={false}
          />
          <ActionsField
            source="valor"
            textAlign="right"
            label="Ações"
            sortable={false}
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            handleEdit={handleOpenDialogoHorarios}
            handleShow={handleOpenDialogoShow}
            handleImprimirContraProva={handleImprimirContraProva}
            handleTrocarTurno={handleTrocarTurno}
          />
        </Datagrid>
        <TransacoesPagination />
        <DialogoHorarios ref={refDialogoHorarios} handleClose={handleClose} />
        <DialogoShow ref={refDialogoShow} />
        <DialogoMudarTurnoDia
          ref={refDialogoMudarTurnoDia}
          handleClose={handleClose}
        />
      </Card>
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const DIA_ATUAL = new Date().getDate();
const MES_ATUAL = new Date().getMonth();
const ANO_ATUAL = new Date().getFullYear();

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Presenças"
      filterDefaultValues={{
        periodo: { dia: DIA_ATUAL, mes: MES_ATUAL, ano: ANO_ATUAL },
      }}
    />
  </>
);

export default List2;
