import React from 'react';
import {
  Filter as FilterAdmin,
  TextInput,
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin';

import { PeriodoInput } from '../../Components/InputsReactAdmin';
import { STATUS_ATIVO } from '../../utils/constants';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <ReferenceInput
      fullWidth
      label="Fornecedor"
      source="fornecedor_id"
      reference="fornecedores_todos_tipos"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput>
    <PeriodoInput
      source="periodo2"
      label="Período"
      defaultValue={{
        start: new Date(),
        end: new Date(),
      }}
    />
  </FilterAdmin>
);

export default Filter;
