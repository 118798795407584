import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useState,
} from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
//import clsx from 'clsx';
import { useSnackbar } from 'notistack';

import { api, getListAllAPI } from '../../services';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {},
  withoutLabel: {},
  textField: {},
}));

const DialogoNota = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [passwordCliente, setPasswordCliente] = useState('');
  const [showPasswordCliente, setShowPasswordCliente] = useState(false);

  const [passwordGerencial, setPasswordGerencial] = useState('');
  const [showPasswordGerencial, setShowPasswordGerencial] = useState(false);

  const [id, setId] = useState(-1);
  const [nome, setNome] = useState('');

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'clientes',
        ['id', 'asc'],
        { id: [id] },
        [],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        setNome(data.data[0].nome);
      } else {
        throw 'Este cliente não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function savePassword() {
    try {
      const response = await api.put(`update_senha_cliente/${id}`, {
        password: passwordCliente,
        senhaGerencial: passwordGerencial,
      });
      const { message } = response.data;
      if (message === 'falha') {
        throw 'Erro ao alterar a senha do cliente!';
      }
      enqueueSnackbar(`Senha alterada com sucesso`, {
        variant: 'success',
      });
      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setOpen(true);
      getDataResource(id);
      setPasswordCliente('');
      setShowPasswordCliente(false);
      setPasswordGerencial('');
      setShowPasswordGerencial(false);
      setId(id);
    },
  }));

  function validateNome(field) {
    let error = '';
    if (!field) error = 'Este campo é obrigatório';
    else if (field.length > 20) {
      error = 'Número de caracteres maior que o permitido (máximo 80)';
    }
    return error;
  }

  const errorSenhaCliente = validateNome(passwordCliente);
  const errorSenhaGerencial = validateNome(passwordGerencial);

  const isError = errorSenhaCliente !== '' || errorSenhaGerencial !== '';

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="form-dialog-title">{`Resetar Senha Cliente - ${nome}`}</DialogTitle>
        <DialogContent>
          <FormControl
            //className={clsx(classes.margin, classes.textField)}
            variant="outlined"
            fullWidth
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Senha Cliente
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPasswordCliente ? 'text' : 'password'}
              value={passwordCliente}
              onChange={(event) => setPasswordCliente(event.target.value)}
              fullWidth
              autoFocus
              error={errorSenhaCliente !== ''}
              helperText={errorSenhaCliente}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {}}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPasswordCliente ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </FormControl>

          <FormControl
            //className={clsx(classes.margin, classes.textField)}
            variant="outlined"
            fullWidth
            style={{ marginTop: '30px' }}
          >
            <InputLabel htmlFor="outlined-adornment-password2">
              Senha Gerencial
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password2"
              type={showPasswordGerencial ? 'text' : 'password'}
              value={passwordGerencial}
              onChange={(event) => setPasswordGerencial(event.target.value)}
              fullWidth
              error={errorSenhaGerencial !== ''}
              helperText={errorSenhaGerencial}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {}}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPasswordGerencial ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </FormControl>
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Button onClick={handleCloseDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={savePassword} color="primary" disabled={isError}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoNota;
