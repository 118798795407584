import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '400px',
  },
}));

const DataNegociadaField = ({ onClick, ...props }) => {
  const classes = useStyles();

  const record = useRecordContext(props);

  let value = '-';

  if (record) {
    if (record.record.data_negociada) {
      const dataOriginal = new Date(
        moment(new Date(record.record.data_pagamento_original))
          .endOf('day')
          .format(),
      );

      const dataProvavel = new Date(
        moment(new Date(record.record.data_negociada)).endOf('day').format(),
      );

      const delta = dataProvavel.getTime() - dataOriginal.getTime();

      value = parseInt(delta / (24 * 60 * 60 * 1000), 10);
    }
  }

  return (
    <Tooltip
      title="Clique aqui para mais detalhes"
      placement="bottom"
      aria-label="add2"
    >
      <Typography
        className={classes.text}
        style={{
          cursor: 'pointer',
        }}
        onClick={() =>
          onClick(
            'Diferença Provável Original',
            <div>
              <h3
                style={{
                  margin: '10px 0',
                }}
              >
                O que é?
              </h3>
              <p>
                A Diferença Provável Original é um indicador financeiro que
                aponta a diferença em dias da Data Provável (data calculada com
                base no prazo negociado pelo comprador com o fornecedor) para a
                Data Original do Pagamento.
              </p>
              <h3
                style={{
                  margin: '10px 0',
                }}
              >
                Para quê serve?
              </h3>
              <p>
                A Diferença Provável Original serve para lhe auxiliar a
                identificar se o fornecedor está impondo prazos diferentes do
                negociado. Um dos maiores problemas de fluxo de caixa pode ser
                devido ao fornecedor negociar um prazo e na prática, trabalhar
                com prazos menores, seja devido ao prazo de logística, seja por
                causa de erros no financeiro do fornecedor. A devida atenção
                desse indicador irá lhe ajudar a manter o Fluxo de Caixa mais
                saudável, mantendo o dinheiro na empresa por mais tempo.
              </p>
              <h3
                style={{
                  margin: '10px 0',
                }}
              >
                Exemplo:
              </h3>
              <p>
                Se o comprador da empresa negociou 30 dias de prazo com algum
                fornecedor, a mercadoria chegando em 01/01/2021, a Data Provável
                deveria ser em 31/01/2021, contudo o fornecedor levou 28 dias de
                logística para entregar a mercadoria pois vinha de outro estado
                longínquo, então a Data Original do boleto foi 03/01/2021, a
                Diferença Provável Original será de 28, o que significa que o
                fornecedor defraudou a empresa em 28 dias.
              </p>
            </div>,
          )
        }
      >
        {value}
      </Typography>
    </Tooltip>
  );
};

export default DataNegociadaField;
