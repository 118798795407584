import React, { useCallback } from 'react';
import { useListContext } from 'react-admin';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

export default function RadioButtonsGroup() {
  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters } = listContext;

  const aplicarFiltros = useCallback(
    (turno) => {
      if (setFilters) {
        setFilters({ ...filterValues, turno }, displayedFilters);
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  const handleChange = (event) => {
    aplicarFiltros(event.target.value);
  };

  const value = filterValues.turno;

  return (
    <FormControl
      component="fieldset"
      style={{
        display: 'flex',
        justifyContent: 'center',
        margin: '20px 20px 0px',
        width: '100%',
      }}
    >
      <RadioGroup
        aria-label="turno"
        name="turno"
        value={value}
        onChange={handleChange}
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <FormControlLabel value="manha" control={<Radio />} label="Manhã" />
        <FormControlLabel value="tarde" control={<Radio />} label="Tarde" />
      </RadioGroup>
    </FormControl>
  );
}
