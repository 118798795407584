import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
} from 'react';

import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useSnackbar } from 'notistack';

import {
  AutoCompleteRemoto,
  SelectInput,
  PrecoInput,
  DialogoConfirmacao,
} from '../../../../Components';
import { api, getUserId } from '../../../../services';
import { STATUS_ATIVO } from '../../../../utils/constants';

const optionsTipoParcela = [
  {
    label: 'Devolução de Venda',
    value: 'devolucao-venda',
  },
  {
    label: 'Pix à Distância',
    value: 'pix-a-distancia',
  },
];

const FormDialog = forwardRef(({ handlePermitido, subTotal }, ref) => {
  const [open, setOpen] = React.useState(false);
  const [cliente, setCliente] = useState(null);
  const [tipoParcela, setTipoParcela] = useState('devolucao-venda');
  const [conta, setConta] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const refDialogoConfirmacao = useRef(null);

  const handleClose = () => {
    setOpen(false);
  };

  async function handleSubmit() {
    try {
      handleClose();
      handlePermitido(getUserId(), tipoParcela, conta ? conta.id : null);
    } catch (error) {
      enqueueSnackbar(`${error}`, {
        variant: 'error',
      });
    }
  }

  async function getData(cliente_id) {
    try {
      const responseCliente = await api.get(`/clientes/${cliente_id}`);

      setCliente(responseCliente.data);
    } catch (error) {
      handleClose();
      enqueueSnackbar(`${error}`, {
        variant: 'error',
      });
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(cliente_id) {
      setOpen(true);
      getData(cliente_id);
    },
  }));

  function validateConta() {
    const error = '';
    if (tipoParcela === 'pix-a-distancia') {
      if (!conta) return 'campo obrigatório!';
    }
    return error;
  }

  function getErros() {
    const errosOld = [];
    errosOld[0] = validateConta();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  function handleConfirma(record) {
    setTipoParcela('pix-a-distancia');
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          Fechamento da Devolução
        </DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="center" flexDirection="column">
            <AutoCompleteRemoto
              name=""
              handleEnter={() => {}}
              resource="clientes"
              nested={[]}
              filters={{}}
              label="Cliente"
              handleKey={() => {}}
              value={cliente}
              onChange={(value) => setCliente(value)}
              toString={false}
              fullWidth
              getOptionSelected={(option, value) => option.nome === value.nome}
              getOptionLabel={(option) => `${option.nome}`}
              disabled
            />
            <PrecoInput
              name="preco"
              handleEnter={() => {}}
              label="Valor da Parcela"
              handleKey={() => {}}
              value={Number.isNaN(subTotal) ? '' : String(subTotal)}
              onChange={(value) => {}}
              fullWidth
              disabled
            />
            <FormControl
              variant="outlined"
              style={{
                marginTop: '16px',
              }}
              fullWidth
            >
              <InputLabel shrink id="turno-colaborador">
                Forma de Pagamento
              </InputLabel>
              <SelectInput
                labelId="turno-colaborador"
                name="turno"
                handleEnter={() => {}}
                label="Forma de Pagamento"
                handleKey={() => {}}
                value={tipoParcela}
                onChange={(value) => {
                  const newValue = value.target.value;
                  if (
                    newValue === 'pix-a-distancia' &&
                    newValue !== tipoParcela
                  ) {
                    refDialogoConfirmacao.current.handleOpen(
                      'Autorização de pix à distância na devolução',
                      'Tem certeza de que deseja colocar a parcela pix?',
                      'pix-a-distancia',
                    );
                  } else {
                    setTipoParcela(newValue);
                  }
                }}
                options={optionsTipoParcela}
              />
            </FormControl>
            {tipoParcela === 'pix-a-distancia' ? (
              <AutoCompleteRemoto
                name=""
                handleEnter={() => {}}
                resource="get-contas-pix-a-distancia-paginate"
                nested={[]}
                filters={{}}
                label="Conta"
                handleKey={() => {}}
                value={conta}
                onChange={(value) => setConta(value)}
                toString={false}
                fullWidth
                getOptionSelected={(option, value) =>
                  option.nome === value.nome
                }
                getOptionLabel={(option) => `${option.nome}`}
                perPage={100}
                error={erros[0] !== ''}
                helperText={erros[0]}
              />
            ) : null}
          </Box>
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={erroExistente}
          >
            Finalizar
          </Button>
        </DialogActions>
        <DialogoConfirmacao
          ref={refDialogoConfirmacao}
          handleConfirma={handleConfirma}
        />
      </Dialog>
    </div>
  );
});

export default FormDialog;
