import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from 'react';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import StarIcon from '@material-ui/icons/Star';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  TextInput,
  SearchProduto,
  PrecoInput,
  DateInput,
  SelectInput,
} from '../../../Components';
import { createAPI, updateAPI, getListAllAPI, api } from '../../../services';
import {
  STATUS_ATIVO,
  TIPO_FERIADO_DATA_COMEMORATIVA,
  TIPO_FERIADO_FERIADO,
  TURNO_MANHA,
  TURNO_NOITE,
  TURNO_12_HORAS,
  formatDateDDMMYYYY,
  formatCNPJ,
} from '../../../utils';
import DialogoCriarPonto from './DialogoCriarPonto';
import DialogoEditPonto from './DialogoEditPonto';
import DialogoMudarDadosDia from './DialogoMudarDadosDia';
import DialogoPresencasColaboradorPDF from './DialogoPresencasColaboradorPDF';
import DialogoShowPonto from './DialogoShowPonto';
import Filters from './FiltersPresencasColaborador';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const StyledTableCell = withStyles((theme) => ({
  head: {
    //backgroundColor: 'green',
    color: 'black',
    //padding: '8px',
    //whiteSpace: 'nowrap',
    //writingMode: 'sideways-lr',
    //textOrientation: 'sideways',
    //width: 'min-content',
    width: '1px',
    whiteSpace: 'nowrap',
    //textAlign: 'center',
    //display: 'flex',
    //justifyContent: 'center',
    padding: '1px',
    border: '1px solid black',
    textAlign: 'center',
    verticalAlign: 'middle',
    backgroundColor: '#f44336',
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    padding: '1px',
    border: '1px solid black',
    textAlign: 'center',
    verticalAlign: 'middle',
    //textAlign: 'center',
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
  },
}));

function diasNoMes(mes, ano) {
  const data = new Date(ano, mes, 0);
  return data.getDate();
}

function getOptions(mes, ano) {
  const totalDias = diasNoMes(mes, ano);
  const options = [];
  for (let i = 1; i <= totalDias; i++) {
    options.push({
      label: `${i}`,
      value: i,
    });
  }
  return options;
}

function getTurnoLabel(value) {
  switch (value) {
    case TURNO_MANHA:
      return 'Manhã';
    case TURNO_NOITE:
      return 'Noite';
    case TURNO_12_HORAS:
      return '12 Horas';
    default:
      return '';
  }
}

function getColaboradoresDia(dados, dia2, mes, ano, batidas) {
  const batidasDivididas = {};
  for (let i = 0; i < batidas.length; i++) {
    const bat = batidas[i];
    if (batidasDivididas[bat.colaborador_id]) {
      batidasDivididas[bat.colaborador_id].push(
        moment(bat.data).format('HH:mm'),
      );
    } else {
      batidasDivididas[bat.colaborador_id] = [moment(bat.data).format('HH:mm')];
    }
  }
  const colaboradores = [];
  let nomeFeriado = '';
  for (let i = 0; i < dados.length; i++) {
    const col = dados[i];
    let adiciona = false;
    let continua = true;
    for (let j = 0; j < col.dias.length && continua; j++) {
      const dia = col.dias[j];
      if (dia.dia === dia2 && dia.mes === mes && dia.ano === ano) {
        nomeFeriado = dia.nome;
        continua = false;
        const ponto = dia.ponto;
        if (ponto) {
          if (!ponto.is_faltou && !ponto.is_folga && !ponto.is_ferias) {
            adiciona = true;
          }
        }
      }
    }

    if (adiciona) {
      colaboradores.push({
        id: col.colaborador_id,
        nome: col.colaborador,
        funcao: col.funcao,
        turno: getTurnoLabel(col.turno),
        pontos: batidasDivididas[col.colaborador_id],
      });
    }
  }
  return {
    colaboradores,
    nomeFeriado,
  };
}

const DialogoCategoriaTransacao = forwardRef(({ mes, ano, dados }, ref) => {
  const [open, setOpen] = useState(false);
  const refDialogoPDF = useRef(null);
  const classes = useStyles({ cor: '#009FD4' });
  const [dia, setDia] = useState(null);
  const [nomeFeriado, setNomeFeriado] = useState(null);
  const [dadosFormatados, setDadosFormatados] = useState([]);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setDia(null);
      setDadosFormatados([]);
      setOpen(true);
    },
  }));

  const options = getOptions(mes + 1, ano);

  function verificaMenorQue10(value) {
    if (value < 10) {
      return `0${value}`;
    }
    return `${value}`;
  }

  function getLabelDia() {
    return `${verificaMenorQue10(dia)}/${verificaMenorQue10(mes + 1)}/${ano}`;
  }

  async function getBatidas(dia, mes, ano) {
    const response = await api.get(
      `/batidas_de_ponto_dia/${dia}/${mes}/${ano}`,
    );

    return response.data;
  }

  async function formataDados() {
    const batidas = await getBatidas(dia, mes, ano);
    const colaboradoresFormatados = getColaboradoresDia(
      dados,
      dia,
      mes,
      ano,
      batidas,
    );

    setDadosFormatados(colaboradoresFormatados.colaboradores);
    setNomeFeriado(colaboradoresFormatados.nomeFeriado);
  }

  useEffect(() => {
    if (dia) {
      formataDados();
    }
  }, [dia]);

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      classes={{ paper: classes.rootDialogo }}
      className={classes.rootDialogo}
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen
    >
      <DialogTitle
        id="form-dialog-title"
        onClose={handleCloseDialog}
        style={{ backgroundColor: '#009FD4', color: 'white' }}
      >
        {`Relatório presenças (${getLabelDia()})${
          nomeFeriado ? ` - ${nomeFeriado}` : ''
        }`}
      </DialogTitle>
      <DialogContent
        style={{
          padding: '0px 10px 0px',
          margin: '0',
        }}
      >
        <Box
          style={{
            marginTop: '20px',
          }}
        >
          <FormControl
            className={classes.formControl}
            variant="outlined"
            fullWidth
          >
            <InputLabel shrink id="input-dia">
              Dia
            </InputLabel>
            <SelectInput
              //variant="outlined"
              label="Dia"
              labelId="input-dia"
              name="dia"
              handleEnter={() => {}}
              handleKey={() => {}}
              value={dia}
              onChange={(value) => {
                setDia(value.target.value);
              }}
              options={options}
            />
          </FormControl>
        </Box>
        <Box
          style={{
            marginTop: '20px',
          }}
        >
          <Table aria-label="customized table">
            <TableHead>
              <StyledTableCell
                style={{
                  backgroundColor: '#bac1c2',
                  flex: 1,
                }}
              >
                <span
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  Colaborador
                </span>
              </StyledTableCell>
              <StyledTableCell
                style={{
                  backgroundColor: '#bac1c2',
                  flex: 1,
                }}
              >
                <span
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  Função
                </span>
              </StyledTableCell>
              <StyledTableCell
                style={{
                  backgroundColor: '#bac1c2',
                  flex: 1,
                }}
              >
                <span
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  Turno
                </span>
              </StyledTableCell>
              <StyledTableCell
                style={{
                  backgroundColor: '#bac1c2',
                  flex: 1,
                }}
              >
                <span
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  Pontos
                </span>
              </StyledTableCell>
            </TableHead>
            <TableBody>
              {dadosFormatados.map((col) => (
                <TableRow key={col.id}>
                  <StyledTableCell>{col.nome}</StyledTableCell>
                  <StyledTableCell>{col.funcao}</StyledTableCell>
                  <StyledTableCell>{col.turno}</StyledTableCell>
                  <StyledTableCell>{col.pontos.join(' | ')}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </DialogContent>
    </Dialog>
  );
});

export default DialogoCategoriaTransacao;
