import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { api, getOneAPI } from '../../../services';
import {
  A_VISTA,
  A_PRAZO,
  TIPO_OFERTA_PRODUTO_LIBERACAO,
} from '../../../utils';

const idTiposPagamentoRepetidos = [3, 4, 5, 7, 8, 9, 10, 11];

function useParcelasVenda(
  parcelas,
  itens,
  cliente,
  subtotal,
  sessionId,
  updateParcela,
  irParaTelaInit,
  idEdit,
  folga,
  descontoOriginal,
  idSession,
) {
  const { enqueueSnackbar } = useSnackbar();
  const [podeFecharVenda, setPodeFecharVenda] = useState(true);

  function getTotalAVistaObrigatorio() {
    let total = 0;
    for (let i = 0; i < itens.length; i++) {
      const item = itens[i];
      if (item.tipo_oferta === TIPO_OFERTA_PRODUTO_LIBERACAO) {
        if (!item.is_margem_cartao) {
          total += item.total;
        }
      }
    }
    return total;
  }

  function getTotalAVistaCalculado() {
    let total = 0;
    for (let i = 0; i < parcelas.length; i++) {
      const parcela = parcelas[i];
      if (parcela.tipo_pagamento.id === 1 || parcela.tipo_pagamento.id === 10) {
        total += parcela.valor;
      }
    }
    return total;
  }

  const totalAVistaObrigatorio = getTotalAVistaObrigatorio();
  const totalAVistaCalculado = getTotalAVistaCalculado();

  function parcelaInclusa(id) {
    for (let i = 0; i < idTiposPagamentoRepetidos.length; i += 1) {
      if (id === idTiposPagamentoRepetidos[i]) return true;
    }
    return false;
  }

  function getProcuraPagamentoDinheiro() {
    for (let i = 0; i < parcelas.length; i += 1) {
      if (parcelas[i].tipo_pagamento.id === 1) return true;
    }
    return false;
  }

  function getProcuraPagamentoNaoPadrao() {
    return false;
  }

  function getPodeEncerrarTransacao(resto) {
    if (resto >= -1 * folga && resto <= folga) return true;
    return false;
  }

  function getValorRestante() {
    let soma = 0;
    for (let i = 0; i < parcelas.length; i += 1) {
      soma += parcelas[i].valor;
    }
    return subtotal - soma;
  }

  function getTotalParcelas() {
    let soma = 0;
    for (let i = 0; i < parcelas.length; i += 1) {
      soma += parcelas[i].valor;
    }
    return soma;
  }

  function getDescontoUsado() {
    let soma = 0;
    for (let i = 0; i < parcelas.length; i += 1) {
      if (parcelas[i].tipo_pagamento.id === 11) {
        soma += parcelas[i].valor;
      }
    }
    return soma;
  }

  function getDescontosDisponiveisCliente() {
    if (cliente) {
      return (
        parseInt(cliente.score_nao_usado / 100, 10) * 100 + descontoOriginal
      );
    }
    return 0;
  }

  const resto = getValorRestante();
  const podeEncerrarTransacao = getPodeEncerrarTransacao(resto);
  const hasPagamentoDinheiro = getProcuraPagamentoDinheiro();
  const hasPagamentoNaoPadrao = getProcuraPagamentoNaoPadrao();
  const totalParcelas = getTotalParcelas();
  const descontoUsadoCliente = getDescontoUsado();
  const descontoDisponivelCliente = getDescontosDisponiveisCliente();

  function getMessagesError() {
    const errors = [];
    if (cliente === null) {
      errors.push({
        message: 'Campo cliente não foi preenchido!',
        type: 'warning',
      });
    }
    if (parcelas.length <= 0) {
      errors.push({
        message: 'É necessário ao menos um registro de parcela!',
        type: 'warning',
      });
    }
    /* const erroDistribuicao = hasPagamentoDinheiro
        ? !podeEncerrarTransacao : (resto < -2 || resto > 2); */
    const erroDistribuicao = resto < -1 * folga || resto > folga;
    if (erroDistribuicao) {
      errors.push({
        message:
          'O valor total da venda não foi integralmente distribuído nas parcelas!',
        type: 'warning',
      });
    }
    return errors;
  }

  useEffect(() => {
    if (getMessagesError().length === 0) {
      enqueueSnackbar('Agora você pode finalizar a Venda!!!', {
        variant: 'success',
      });
    }
  }, [parcelas]);

  function montarObjVenda() {
    const listaItens = [];
    const listaParcelas = [];

    for (let i = 0; i < itens.length; i += 1) {
      listaItens.push({
        peso: itens[i].peso,
        unidades: itens[i].unidades,
        preco_venda: itens[i].unitario,
        preco_compra: itens[i].produto.precoCompraMedio,
        produto_id: itens[i].produto.id,
        idIndicadorChange: itens[i].idIndicadorChange,
        unidades_caixa: itens[i].unidadesCaixa,
        preco_venda_caixa: itens[i].unitarioCaixa,
        peso_caixa: itens[i].pesoCaixa,
        unidades_caixa_aparente: itens[i].unidadesCaixaAparente,
        nivel_caixaria: itens[i].nivelCaixaria,
        cgp: itens[i].cgp,
        grupo_oferta_preco_produto_id: itens[i].grupo_oferta_preco_produto_id
          ? itens[i].grupo_oferta_preco_produto_id
          : null,
        tipo_oferta: itens[i].tipo_oferta ? itens[i].tipo_oferta : null,
        is_margem_cartao: itens[i].is_margem_cartao === true ? true : false,
      });
    }

    for (let i = 0; i < parcelas.length; i += 1) {
      listaParcelas.push({
        tipo_pagamento_id: parcelas[i].tipo_pagamento.id,
        data_pagamento: parcelas[i].data_pagamento,
        valor: parcelas[i].valor,
        data_pagamento_real:
          parcelas[i].tipo_pagamento.modalidade === A_VISTA ? new Date() : null,
        valor_recebido:
          parcelas[i].tipo_pagamento.dinheiro_vivo &&
          parcelas[i].tipo_pagamento.modalidade === A_VISTA
            ? parcelas[i].valor_recebido
            : 0,
        idIndicadorChange: parcelas[i].idIndicadorChange,
        autorizador_id: parcelas[i].autorizador_id
          ? parcelas[i].autorizador_id
          : null,
        conta_id: parcelas[i].conta_id ? parcelas[i].conta_id : null,
      });
    }

    return {
      listaItens,
      listaParcelas,
      data: new Date(),
      cliente_id: cliente.id,
      sessao_id: sessionId,
      devolucao: false,
    };
  }

  async function submitVenda() {
    try {
      const objVenda = montarObjVenda();
      const data = await api.post(`/edit_vendas_frente_caixa_novo/${idEdit}`, {
        ...objVenda,
      });
      return data.data.id;
    } catch (e) {
      return -1;
    }
  }

  async function isSessionsClose() {
    const response = await getOneAPI('sessoes', idSession, []);
    return response.data.is_fechamento;
  }

  async function handleFinalizarVenda() {
    const errors = getMessagesError();
    if (errors.length <= 0 && podeFecharVenda) {
      setPodeFecharVenda(false);
      const isSessionsCloseValue = await isSessionsClose();
      if (!isSessionsCloseValue) {
        const idVenda = await submitVenda();
        if (idVenda > 0) {
          /* const codigo = await imprimirNotaVenda(idVenda);
          if (codigo < 0) {
            enqueueSnackbar('Erro ao imprimir a nota!', {
              variant: 'error',
            });
          } */
          irParaTelaInit();
        } else {
          setPodeFecharVenda(true);
          enqueueSnackbar('Erro ao registrar a venda!', {
            variant: 'error',
          });
        }
      } else {
        setPodeFecharVenda(true);
        enqueueSnackbar(
          'Não pode editar esta venda, a sessão ja foi fechada!!',
          {
            variant: 'error',
          },
        );
      }
    } else {
      for (let i = 0; i < errors.length; i += 1) {
        enqueueSnackbar(errors[i].message, {
          variant: errors[i].type,
        });
      }
    }
  }

  function handleNewItem({
    valor,
    tipo_pagamento,
    data_pagamento,
    troco,
    valor_recebido,
  }) {
    if (
      tipo_pagamento.modalidade === A_VISTA ||
      (tipo_pagamento.modalidade === A_PRAZO &&
        cliente.nome &&
        cliente.cpf &&
        cliente.telefone &&
        tipo_pagamento.boleto_gerencianet) ||
      (tipo_pagamento.modalidade === A_PRAZO &&
        !tipo_pagamento.boleto_gerencianet)
    ) {
      updateParcela({
        data_pagamento,
        tipo_pagamento,
        valor,
        uidd: `${tipo_pagamento.nome}${parcelas.length}`,
        troco,
        valor_recebido,
        idIndicadorChange: -1,
      });
    } else {
      enqueueSnackbar(
        'Este cliente não possui dados suficientes para o cadastro de boletos na plataforma Gerencianet!',
        {
          variant: 'warning',
        },
      );
    }
  }

  function getPodeUsarParcela(id) {
    const idInclusoRepetidos = parcelaInclusa(id);

    if (idInclusoRepetidos) {
      return {
        status: true,
        message: '',
      };
    }
    if (id === 1) {
      if (hasPagamentoDinheiro) {
        return {
          status: false,
          message: 'Já existem uma parcela em dinheiro registrada!',
        };
      }
      return {
        status: true,
        message: '',
      };
    }
    if (hasPagamentoNaoPadrao) {
      return {
        status: false,
        message: 'Já existem uma parcela á prazo registrada!',
      };
    }
    return {
      status: true,
      message: '',
    };
  }

  return {
    resto,
    podeEncerrarTransacao,
    hasPagamentoDinheiro,
    totalParcelas,
    handleFinalizarVenda,
    handleNewItem,
    enqueueSnackbar,
    getPodeUsarParcela,
    hasPagamentoNaoPadrao,
    podeFecharVenda,
    descontoUsadoCliente,
    descontoDisponivelCliente,
    totalAVistaObrigatorio,
    totalAVistaCalculado,
  };
}

export default useParcelasVenda;
