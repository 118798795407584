import React from 'react';
import {
  Filter as FilterAdmin,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
} from 'react-admin';

import { PeriodoInput, PrecoInput } from '../../Components/InputsReactAdmin';
import { STATUS_ATIVO } from '../../utils/constants';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <PeriodoInput
      source="periodo"
      label="Período"
      defaultValue={{
        start: new Date(),
        end: new Date(),
      }}
    />
    <PrecoInput source="search" label="Valor" fixedDecimalScale={false} />
    <ReferenceInput
      source="conta"
      reference="contas_normal"
      label="Caixa"
      allowEmpty={false}
      filter={{ is_caixa: true, status: STATUS_ATIVO }}
    >
      <SelectInput optionText="nome" allowEmpty={false} />
    </ReferenceInput>
    <ReferenceInput
      fullWidth
      label="Frentista"
      source="frentista_id"
      reference="users"
      sort={{ field: 'username', order: 'ASC' }}
      filterToQuery={(searchText) => ({ username: `${searchText}` })}
      perPage={10}
      allowEmpty={false}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <ReferenceInput
      fullWidth
      label="Responsável"
      source="responsavel_id"
      reference="users"
      sort={{ field: 'username', order: 'ASC' }}
      filterToQuery={(searchText) => ({ username: `${searchText}` })}
      perPage={10}
      allowEmpty={false}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
  </FilterAdmin>
);

export default Filter;
