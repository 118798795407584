import React, { useState, forwardRef, useImperativeHandle } from 'react';

import { Box } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import CloseIcon from '@material-ui/icons/Close';
import NoteIcon from '@material-ui/icons/Note';
import { useSnackbar } from 'notistack';

import { getListAllAPI, createAPI } from '../../../services';
import { formatMoeda } from '../../../utils';
import {
  PrecoInput,
  AutoCompleteLocalCustomizado,
  TextInput,
} from '../../Inputs';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    // backgroundColor: (props) => props.cor,
    // color: 'white',
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    // backgroundColor: 'rgba(0, 0, 0, 0.12)',
    // color: 'red',
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const useStyles3 = makeStyles({
  chip: {
    borderColor: (props) => props.color,
    backgroundColor: 'white',
    cursor: 'default',
    color: (props) => props.color,
    '&:hover': {
      color: (props) => props.color,
      backgroundColor: 'white',
    },
  },
  deleteIcon: {
    color: (props) => props.color,
    cursor: 'pointer',
    '&:hover': {
      color: (props) => props.color,
    },
  },
});

const ChipCategoria = ({ option, injetado, campo }) => {
  const classes2 = useStyles3({ color: option.color });
  const { onDelete, ...rest } = injetado;
  return (
    <Chip
      classes={{ outlined: classes2.chip, deleteIcon: classes2.deleteIcon }}
      variant="outlined"
      label={option[campo]}
      {...rest}
      avatar={
        <Avatar style={{ backgroundColor: 'white', marginRight: '3px' }}>
          <Icon style={{ color: option.color }}>{option.icon}</Icon>
        </Avatar>
      }
    />
  );
};

const ESPACO_INPUTS = '24px';

const DialogoCategoriaTransacao = forwardRef(
  (
    {
      handleClose,
      // cor = '#2196f3',
      campo = 'nome',
    },
    ref,
  ) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [valor, setValor] = useState(0);
    const [saldoAtual, setSaldoAtual] = useState(0);
    const [id, setId] = useState(-1);
    const [descricao, setDescricao] = useState('');
    const [categoria, setCategoria] = useState([]);
    const [carregando, setCarregando] = useState(false);

    const classes = useStyles();

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    async function getDataResource(id) {
      try {
        const data = await getListAllAPI(
          'contas',
          ['id', 'asc'],
          { id: [id] },
          [],
        );

        const dataCategoria = await getListAllAPI(
          'categorias_transacao',
          ['id', 'asc'],
          { id: [4] },
          [],
        );

        if (data.data.length > 0 && dataCategoria.data.length > 0) {
          setValor(data.data[0].saldoAtual);
          setSaldoAtual(data.data[0].saldoAtual);
          setCategoria([dataCategoria.data[0]]);
          setDescricao('');
          setCarregando(false);
        } else {
          throw 'Esta conta não existe!';
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    async function handleSalvar() {
      setCarregando(true);
      try {
        if (id <= 0) {
          enqueueSnackbar('Nenhuma conta aqui!!!', {
            variant: 'error',
          });
        } else {
          await createAPI(`contas/ajuste/${id}`, {
            valor,
            descricao,
          });
        }
        setCarregando(false);
        enqueueSnackbar('Saldo da conta reajustado com sucesso!', {
          variant: 'success',
        });
        handleCloseDialog();
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    useImperativeHandle(ref, () => ({
      handleOpen(id) {
        setCarregando(true);
        getDataResource(id);
        setOpen(true);
        setId(id);
      },
    }));

    const errorValor = Number.isNaN(valor) ? 'Este campo é obrigatório' : '';
    const errorDescricao =
      descricao === '' || descricao === null ? 'Este campo é obrigatório' : '';

    function isDisabledSubmit() {
      return errorValor !== '' || errorDescricao !== '';
    }

    const renderTagCategoria = (option, injetado) => (
      <ChipCategoria option={option} injetado={injetado} campo={campo} />
    );

    const renderOptionCategoria = (option) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          style={{
            backgroundColor: option.color,
            marginRight: '10px',
            width: '30px',
            height: '30px',
          }}
        >
          <Icon style={{ color: 'black' }}>{option.icon}</Icon>
        </Avatar>
        {option[campo]}
      </div>
    );

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullWidth
          maxWidth="sm"
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            Reajuste de saldo
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div>
                <div
                  style={{
                    padding: '10px 10px 10px 0px',
                    marginBottom: '12px',
                    color: 'rgba(0, 0, 0, 0.54)',
                    fontSize: '1rem',
                    fontWeight: 400,
                    lineHeight: 1.5,
                  }}
                >
                  {`Saldo atual: ${formatMoeda(saldoAtual)}`}
                </div>
                <Box marginBottom="24px">
                  <PrecoInput
                    name="valor"
                    // ref={refs.current[1]}
                    handleEnter={() => {}}
                    label=""
                    handleKey={() => {}}
                    value={Number.isNaN(valor) ? '' : String(valor)}
                    onChange={(value) => {
                      const newValue = parseFloat(value.target.value);
                      if (Number.isNaN(newValue)) {
                        setValor(0);
                      } else {
                        setValor(newValue);
                      }
                      // setValor(parseFloat(value.target.value))
                    }}
                    error={errorValor !== ''}
                    helperText={errorValor}
                    fullWidth
                    variant="standard"
                  />
                </Box>

                <TextInput
                  name="descricao"
                  // ref={refs.current[1]}
                  handleEnter={() => {}}
                  label=""
                  handleKey={() => {}}
                  value={descricao}
                  onChange={(value) => setDescricao(value.target.value)}
                  error={errorDescricao !== ''}
                  helperText={errorDescricao}
                  style={{ marginBottom: ESPACO_INPUTS, marginTop: '0px' }}
                  fullWidth
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <>
                        <NoteIcon style={{ marginRight: '24px' }} />
                      </>
                    ),
                  }}
                />
                <AutoCompleteLocalCustomizado
                  name="categoria"
                  handleEnter={() => {}}
                  label=""
                  handleKey={() => {}}
                  value={categoria}
                  disableClearable
                  onChangeLocal={() => {}}
                  campo="nome"
                  options={categoria}
                  variant="standard"
                  renderTag={renderTagCategoria}
                  renderOption={renderOptionCategoria}
                  style={{ marginBottom: ESPACO_INPUTS, marginTop: '0px' }}
                  disabled
                  startAdornment={
                    <InputAdornment position="start">
                      <BookmarkBorderIcon style={{ marginRight: '16px' }} />
                    </InputAdornment>
                  }
                />
              </div>
            )}
          </DialogContent>
          <DialogActions
            style={{
              padding: '32px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={handleCloseDialog}
              variant="outlined"
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              color="primary"
              /* style={{
              color: cor, borderRadius: '25px', width: '100px',
            }} */
              // disabled={nome === '' || nome.length > 80}
            >
              Cancelar
            </Button>
            <Box>
              <div className={classes.wrapper}>
                <Button
                  onClick={handleSalvar}
                  classes={{
                    root: classes.btnAtions,
                    disabled: classes.btnAtionsDisabled,
                  }}
                  /* style={{
                backgroundColor: cor, color: 'white', borderRadius: '25px', width: '100px',
              }} */
                  disabled={isDisabledSubmit() || carregando}
                  variant="contained"
                  color="primary"
                >
                  Salvar
                </Button>
                {carregando && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Box>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
