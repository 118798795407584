import React, { forwardRef } from 'react';
import { useRefresh } from 'react-admin';

import Dialogo from './DialogoCreateEdit';

const Container = forwardRef((props, ref) => {
  const refresh = useRefresh();
  function handleClose() {
    refresh();
  }
  return <Dialogo ref={ref} handleClose={handleClose} />;
});

export default Container;
