import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import { DialogoConfirmacao } from '../../Components';
import { getListAllAPI, api } from '../../services';
import { STATUS_ATIVO } from '../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState(-1);
  const [carregando, setCarregando] = useState(false);
  const [produto, setProduto] = useState(null);
  const [qtde, setQtde] = useState(0);
  const [data, setData] = useState(new Date());
  const [verbaUnitaria, setVerbaUnitaria] = useState(0);

  const refDialogoConfirmacao = useRef(null);

  const classes = useStyles();

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'verbas_produtos',
        ['id', 'asc'],
        { id: [id] },
        [],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        setProduto(data.data[0].produto);
        setQtde(data.data[0].qtde);
        setData(data.data[0].data);
        setCarregando(false);
      } else {
        throw 'Esta verba de produto não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function getLiberacoes() {
    const data = await getListAllAPI(
      'ofertas_liberacao_produtos',
      ['id', 'asc'],
      { status: STATUS_ATIVO, verba_produto_id: id },
      [],
    );

    return data.data.length;
  }

  async function handleSalvar() {
    try {
      if (id <= 0) {
        enqueueSnackbar('Nenhuma verba de produto aqui!!!', {
          variant: 'error',
        });
      } else {
        await api.delete(`/verbas_produtos/${id}`);
        setCarregando(false);
        enqueueSnackbar(`Verba de produto deletada com sucesso!`, {
          variant: 'success',
        });
      }
      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar('Algum erro ocorreu', {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function handlePreSalvar() {
    const nLiberacoes = await getLiberacoes();
    if (nLiberacoes > 0) {
      if (nLiberacoes === 1) {
        if (refDialogoConfirmacao.current) {
          refDialogoConfirmacao.current.handleOpen(
            'Confirmação de exclusão da verba de produto',
            `Deseja realmente excluir esta verba de produto? Ela possui ${nLiberacoes} liberação de preço ainda não utilizada!`,
            1,
          );
        }
      } else if (refDialogoConfirmacao.current) {
        refDialogoConfirmacao.current.handleOpen(
          'Confirmação de exclusão da verba de produto',
          `Deseja realmente excluir esta verba de produto? Ela possui ${nLiberacoes} liberações de preço ainda não utilizadas!`,
          1,
        );
      }
    } else if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Confirmação de exclusão da verba de produto',
        'Deseja realmente excluir esta verba de produto?',
        1,
      );
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setCarregando(true);
      getDataResource(id);
      setOpen(true);
      setId(id);
    },
  }));

  const nomeProduto = produto ? produto.nome : '';

  function handleConfirma(codigo) {
    switch (codigo) {
      case 1:
        handleSalvar();
        break;
      default:
        break;
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth="xs"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{
            backgroundColor: '#f44336',
            color: 'white',
          }}
        >
          Deletar Verba de Produto
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '20px',
                  height: '100px',
                }}
              >
                {`Tem certeza que deseja deletar esta Verba de Produto (${nomeProduto})?`}
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '32px 36px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={handleCloseDialog}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
            variant="outlined"
            color="primary"
          >
            Cancelar
          </Button>
          <Box>
            <div className={classes.wrapper}>
              <Button
                onClick={handlePreSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                variant="contained"
                color="primary"
                disabled={carregando}
              >
                Deletar
              </Button>
              {carregando && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Box>
        </DialogActions>
      </Dialog>
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={handleConfirma}
      />
    </div>
  );
});

export default DialogoCategoriaTransacao;
