import React from 'react';

import EditTemplate from '../../Components/Edit';
import Erros from './Erros';
import Form from './Form';
import validate from './Validation';

const Title = ({ record }) => (
  <span>Editar Categoria de cliente: {record ? `"${record.nome}"` : ''}</span>
);

function transform({ nome, ...data }) {
  return {
    nome,
  };
}

const Edit = (props) => (
  <EditTemplate
    {...props}
    title={<Title />}
    validate={validate}
    onFailure={Erros}
    transform={transform}
    isBack
  >
    <Form />
  </EditTemplate>
);

export default Edit;
