import React, { useState, useEffect } from 'react';

import { Button, Box, makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import useDebounce from '../../hooks/useDebounce';

const useStyles = makeStyles({
  btn: {
    borderRadius: '50px',
    color: (props) => props.color,
    borderColor: (props) => props.color,
    '&:hover': {
      backgroundColor: (props) => props.color,
      color: 'white',
    },
  },
});

const DateMobillsInput = ({ color = '#59c3fa', handleAno = () => {} }) => {
  const [ano, setAno] = useState(new Date().getFullYear());
  const classes = useStyles({ color });
  const [anoDebounce] = useDebounce(ano, 1000);

  useEffect(() => {
    handleAno(anoDebounce);
  }, [anoDebounce]);

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <IconButton
        aria-label="delete"
        onClick={() => setAno(ano - 1)}
        style={{ color }}
      >
        <NavigateBeforeIcon />
      </IconButton>
      <Box marginLeft="10px" marginRight="10px">
        <Button
          variant="outlined"
          className={classes.btn}
          style={{
            width: '160px',
          }}
        >
          <span
            style={{ marginRight: '5px', fontWeight: 'bold' }}
          >{`${ano}`}</span>
        </Button>
      </Box>
      <IconButton
        aria-label="delete"
        onClick={() => setAno(ano + 1)}
        style={{ color }}
      >
        <NavigateNextIcon />
      </IconButton>
    </Box>
  );
};

export default DateMobillsInput;
