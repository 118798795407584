import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import GroupIcon from '@material-ui/icons/Group';

import {
  tipoStatusAndamentoAprovar,
  tipoStatusAndamentoAprovarVolta,
  tipoStatusAndamentoCriarPedido,
  tipoStatusAndamentoDetalhes,
} from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'block',
  },
  sectionDesktop: {
    display: 'flex',
    gap: '10px',
  },
}));

function getPrefixo(tipoStatusAndamento) {
  switch (tipoStatusAndamento) {
    case tipoStatusAndamentoAprovar:
      return 'APROVAÇÃO - ';
    case tipoStatusAndamentoAprovarVolta:
      return 'ACEITAÇÃO - ';
    default:
      return '';
  }
}

export default function PrimarySearchAppBar({
  disabledBack = false,
  disabledForward = false,
  onClick,
  CANCELAR_PEDIDO_ACTION,
  FECHAR_PEDIDO_ACTION,
  NOVO_CLIENTE_ACTION,
  numeroPedido,
  idEdit,
  disabledCliente = false,
  hideCliente = false,
  tipoStatusAndamento,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleMenuClose();
          onClick(NOVO_CLIENTE_ACTION);
        }}
      >
        Novo
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar>
          <Tooltip title="Cancelar">
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              size="small"
              disabled={disabledBack}
              onClick={() => onClick(CANCELAR_PEDIDO_ACTION)}
            >
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Prosseguir">
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              size="small"
              disabled={disabledForward}
              onClick={() => onClick(FECHAR_PEDIDO_ACTION)}
            >
              <ArrowForwardIcon />
            </IconButton>
          </Tooltip>
          <Typography className={classes.title} variant="h6" noWrap />
          <div className={classes.grow}>
            {idEdit <= 0 ? (
              <div>
                <span
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  NOVO PEDIDO DE LIBERAÇÃO - CONFIGURAÇÕES - 1/2
                </span>
              </div>
            ) : (
              <div>
                <span
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {`${getPrefixo(
                    tipoStatusAndamento,
                  )}PEDIDO DE LIBERAÇÃO Nº ${numeroPedido} - CONFIGURAÇÕES - 1/2`}
                </span>
              </div>
            )}
          </div>
          <div className={classes.sectionDesktop}>
            {!hideCliente ? (
              <Tooltip title="Clientes">
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={handleProfileMenuOpen}
                  size="small"
                  disabled={disabledCliente}
                >
                  <GroupIcon />
                </IconButton>
              </Tooltip>
            ) : null}
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </div>
  );
}
