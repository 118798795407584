import React from 'react';
import { Link } from 'react-admin';

import Fab from '@material-ui/core/Fab';
import { withStyles, useTheme } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import AddIcon from '@material-ui/icons/Add';

const styles = (theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
});

const FabButtonNewResource = withStyles(styles)(
  ({ classes, record, pathname, search = () => '' }) => {
    const theme = useTheme();
    const transitionDuration = {
      enter: theme.transitions.duration.enteringScreen,
      exit: theme.transitions.duration.leavingScreen,
    };
    return (
      <Zoom
        key={pathname}
        in
        timeout={transitionDuration}
        style={{
          transitionDelay: `${transitionDuration.exit}ms`,
        }}
        unmountOnExit
      >
        <Fab
          component={Link}
          className={classes.fab}
          to={{
            pathname,
            search: search(record),
          }}
          color="primary"
        >
          <AddIcon />
        </Fab>
      </Zoom>
    );
  },
);

export default FabButtonNewResource;
