import React, { forwardRef, useState, useImperativeHandle } from 'react';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import {
  ceil10,
  formatMoeda,
  PRIMARY_COLOR,
  getTotalCompra,
} from '../../../../../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 12,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  table: {
    //minWidth: 650,
  },
  paper: {
    height: 300,
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflow: 'auto',
  },
}));

const Grafico = forwardRef(
  (
    {
      subTotalProdutos,
      //total_repasse_frete,
      //total_repasse_ipi,
      //total_repasse_outras_despesas,
      //total_repasse_seguros,
      //total_repasse_st,
      repasse_frete,
      repasse_ipi,
      repasse_outras_despesas,
      repasse_seguros,
      repasse_st,
      subTotal,
      lotes,
    },
    ref,
  ) => {
    const [open, setOpen] = useState(false);

    const classes = useStyles({ cor: '#009FD4' });

    useImperativeHandle(ref, () => ({
      handleOpen() {
        setOpen(true);
      },
    }));

    const handleCloseDialog = () => {
      setOpen(false);
    };

    //const total_repasse_frete_2 = total_repasse_frete + repasse_frete;
    //const total_repasse_ipi_2 = total_repasse_ipi + repasse_ipi;
    //const total_repasse_outras_despesas_2 =
    //total_repasse_outras_despesas + repasse_outras_despesas;
    //const total_repasse_seguros_2 = total_repasse_seguros + repasse_seguros;
    //const total_repasse_st_2 = total_repasse_st + repasse_st;

    function calculaTotais(dados) {
      if (dados) {
        if (dados.dadosPrecificacao) {
          return {
            subtotal_valor_negociado_com_a_industria: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.valor_negociado_com_a_industria,
              dados.produto,
            ),
            subtotal_custo_final: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.custo_final_do_produto,
              dados.produto,
            ),
            subtotal_custo_operacional: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.custo_operacional,
              dados.produto,
            ),
            subtotal_custo_cartao: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.custo_de_cartao,
              dados.produto,
            ),
            subtotal_total_venda: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.preco_de_venda,
              dados.produto,
            ),
            subtotal_icms_compra: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.icms_compra,
              dados.produto,
            ),
            subtotal_icms_venda: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.icms_venda,
              dados.produto,
            ),
            subtotal_pis_compra: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.pis_compra,
              dados.produto,
            ),
            subtotal_pis_venda: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.pis_venda,
              dados.produto,
            ),
            subtotal_cofins_compra: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.cofins_compra,
              dados.produto,
            ),
            subtotal_cofins_venda: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.cofins_venda,
              dados.produto,
            ),
            subtotal_lucro_bruto: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.lucro_bruto,
              dados.produto,
            ),
            subtotal_lucro_liquido: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.lucro_liquido,
              dados.produto,
            ),
            subtotal_icms_antecipacao: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.icms_antecipacao,
              dados.produto,
            ),
            subtotal_icms_fronteira: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.icms_fronteira,
              dados.produto,
            ),
            subtotal_irpj: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.irpj,
              dados.produto,
            ),
            subtotal_csll: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.csll,
              dados.produto,
            ),
            subtotal_repasse_ipi: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.repasse_do_ipi,
              dados.produto,
            ),
            subtotal_repasse_st: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.repasse_de_st,
              dados.produto,
            ),
            subtotal_repasse_seguro: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.repasse_do_seguro,
              dados.produto,
            ),
            subtotal_repasse_frete: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.repasse_do_frete,
              dados.produto,
            ),
            subtotal_repasse_outras_despesas: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.repasse_de_outras_despesas,
              dados.produto,
            ),
            //
            subtotal_desconto_nfe: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.desconto_em_nfe,
              dados.produto,
            ),
            subtotal_desconto_boleto: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.desconto_em_boleto,
              dados.produto,
            ),
            subtotal_desconto_bonificacao: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.desconto_em_bonificacao,
              dados.produto,
            ),
            subtotal_desconto_backlight: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.desconto_em_backlight_e_ponto_extra,
              dados.produto,
            ),
            subtotal_desconto_jornal_ofertas: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.desconto_em_jornal_de_ofertas,
              dados.produto,
            ),
            subtotal_desconto_descargo_mercadorias: getTotalCompra(
              dados.peso,
              dados.unidades,
              dados.dadosPrecificacao.desconto_em_descargo_de_mercadorias,
              dados.produto,
            ),
          };
        }
        return {
          subtotal_custo_operacional: 0,
          subtotal_custo_cartao: 0,
          subtotal_total_venda: 0,
          subtotal_icms_compra: 0,
          subtotal_icms_venda: 0,
          subtotal_pis_compra: 0,
          subtotal_pis_venda: 0,
          subtotal_cofins_compra: 0,
          subtotal_cofins_venda: 0,
          subtotal_lucro_bruto: 0,
          subtotal_lucro_liquido: 0,
          subtotal_icms_antecipacao: 0,
          subtotal_icms_fronteira: 0,
          subtotal_irpj: 0,
          subtotal_csll: 0,
          subtotal_repasse_ipi: 0,
          subtotal_repasse_st: 0,
          subtotal_repasse_seguro: 0,
          subtotal_repasse_frete: 0,
          subtotal_repasse_outras_despesas: 0,
          subtotal_desconto_nfe: 0,
          subtotal_desconto_boleto: 0,
          subtotal_desconto_bonificacao: 0,
          subtotal_desconto_backlight: 0,
          subtotal_desconto_jornal_ofertas: 0,
          subtotal_desconto_descargo_mercadorias: 0,
        };
      }
      return {
        subtotal_custo_operacional: 0,
        subtotal_custo_cartao: 0,
        subtotal_total_venda: 0,
        subtotal_icms_compra: 0,
        subtotal_icms_venda: 0,
        subtotal_pis_compra: 0,
        subtotal_pis_venda: 0,
        subtotal_cofins_compra: 0,
        subtotal_cofins_venda: 0,
        subtotal_lucro_bruto: 0,
        subtotal_lucro_liquido: 0,
        subtotal_icms_antecipacao: 0,
        subtotal_icms_fronteira: 0,
        subtotal_irpj: 0,
        subtotal_csll: 0,
        subtotal_repasse_ipi: 0,
        subtotal_repasse_st: 0,
        subtotal_repasse_seguro: 0,
        subtotal_repasse_frete: 0,
        subtotal_repasse_outras_despesas: 0,
        subtotal_desconto_nfe: 0,
        subtotal_desconto_boleto: 0,
        subtotal_desconto_bonificacao: 0,
        subtotal_desconto_backlight: 0,
        subtotal_desconto_jornal_ofertas: 0,
        subtotal_desconto_descargo_mercadorias: 0,
      };
    }

    const lotesFormatados = lotes.map((item) => calculaTotais(item));

    function calculaTotaisNota(lotes) {
      const totais = {
        total_custo_operacional: 0,
        total_custo_cartao: 0,
        total_total_venda: 0,
        total_icms_compra: 0,
        total_icms_venda: 0,
        total_pis_compra: 0,
        total_pis_venda: 0,
        total_cofins_compra: 0,
        total_cofins_venda: 0,
        total_lucro_bruto: 0,
        total_lucro_liquido: 0,
        total_icms_antecipacao: 0,
        total_icms_fronteira: 0,
        total_irpj: 0,
        total_csll: 0,
        total_repasse_ipi: 0,
        total_repasse_st: 0,
        total_repasse_seguro: 0,
        total_repasse_frete: 0,
        total_repasse_outras_despesas: 0,
        total_desconto_backlight: 0,
        total_desconto_boleto: 0,
        total_desconto_bonificacao: 0,
        total_desconto_descargo_mercadorias: 0,
        total_desconto_jornal_ofertas: 0,
        total_desconto_nfe: 0,
      };
      for (let i = 0; i < lotes.length; i++) {
        const lote = lotes[i];

        totais.total_custo_operacional += lote.subtotal_custo_operacional;
        totais.total_custo_cartao += lote.subtotal_custo_cartao;
        totais.total_total_venda += lote.subtotal_total_venda;
        totais.total_icms_compra += lote.subtotal_icms_compra;
        totais.total_icms_venda += lote.subtotal_icms_venda;
        totais.total_pis_compra += lote.subtotal_pis_compra;
        totais.total_pis_venda += lote.subtotal_pis_venda;
        totais.total_cofins_compra += lote.subtotal_cofins_compra;
        totais.total_cofins_venda += lote.subtotal_cofins_venda;
        totais.total_lucro_bruto += lote.subtotal_lucro_bruto;
        totais.total_lucro_liquido += lote.subtotal_lucro_liquido;
        totais.total_icms_antecipacao += lote.subtotal_icms_antecipacao;
        totais.total_icms_fronteira += lote.subtotal_icms_fronteira;
        totais.total_irpj += lote.subtotal_irpj;
        totais.total_csll += lote.subtotal_csll;
        totais.total_repasse_ipi += lote.subtotal_repasse_ipi;
        totais.total_repasse_st += lote.subtotal_repasse_st;
        totais.total_repasse_seguro += lote.subtotal_repasse_seguro;
        totais.total_repasse_frete += lote.subtotal_repasse_frete;
        totais.total_repasse_outras_despesas +=
          lote.subtotal_repasse_outras_despesas;
        //
        totais.total_desconto_backlight += lote.subtotal_desconto_backlight;
        totais.total_desconto_boleto += lote.subtotal_desconto_boleto;
        totais.total_desconto_bonificacao += lote.subtotal_desconto_bonificacao;
        totais.total_desconto_descargo_mercadorias +=
          lote.subtotal_desconto_descargo_mercadorias;
        totais.total_desconto_jornal_ofertas +=
          lote.subtotal_desconto_jornal_ofertas;
        totais.total_desconto_nfe += lote.subtotal_desconto_nfe;
      }
      return totais;
    }

    const {
      total_custo_operacional,
      total_custo_cartao,
      total_total_venda,
      total_icms_compra,
      total_icms_venda,
      total_pis_compra,
      total_pis_venda,
      total_cofins_compra,
      total_cofins_venda,
      total_lucro_bruto,
      total_lucro_liquido,
      total_icms_antecipacao,
      total_icms_fronteira,
      total_irpj,
      total_csll,
      total_repasse_ipi,
      total_repasse_st,
      total_repasse_seguro,
      total_repasse_frete,
      total_repasse_outras_despesas,
      total_desconto_backlight,
      total_desconto_boleto,
      total_desconto_bonificacao,
      total_desconto_descargo_mercadorias,
      total_desconto_jornal_ofertas,
      total_desconto_nfe,
    } = calculaTotaisNota(lotesFormatados);

    return (
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: PRIMARY_COLOR, color: 'white' }}
        >
          Detalhes Da Compra
        </DialogTitle>
        <DialogContent>
          <Box>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                margin: '5px 0 15px',
                textAlign: 'center',
                color: 'red',
              }}
            >
              Geral
            </Typography>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Imposto</StyledTableCell>
                    <StyledTableCell align="left">SubTotal</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow key="1">
                    <StyledTableCell align="left">
                      Valor da Nota
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(subTotal)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="2">
                    <StyledTableCell align="left">
                      Total dos Produtos
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(subTotalProdutos)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="3">
                    <StyledTableCell align="left">Venda</StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(total_total_venda)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="4">
                    <StyledTableCell align="left">Lucro Bruto</StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(total_lucro_bruto)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="7">
                    <StyledTableCell align="left">
                      Lucro Líquido
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(total_lucro_liquido)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="5">
                    <StyledTableCell align="left">
                      Custo Operacional
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(total_custo_operacional)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="6">
                    <StyledTableCell align="left">
                      Custo com Cartão
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(total_custo_cartao)}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                margin: '25px 0 15px',
                textAlign: 'center',
                color: 'red',
              }}
            >
              Repasses
            </Typography>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Imposto</StyledTableCell>
                    <StyledTableCell align="left">SubTotal</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow key="1">
                    <StyledTableCell align="left">IPI</StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(total_repasse_ipi + repasse_ipi)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="2">
                    <StyledTableCell align="left">Frete</StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(total_repasse_frete + repasse_frete)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="3">
                    <StyledTableCell align="left">ST</StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(total_repasse_st + repasse_st)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="4">
                    <StyledTableCell align="left">Seguro</StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(total_repasse_seguro + repasse_seguros)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="5">
                    <StyledTableCell align="left">
                      Outras Despesas
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(
                        total_repasse_outras_despesas + repasse_outras_despesas,
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                margin: '25px 0 15px',
                textAlign: 'center',
                color: 'red',
              }}
            >
              Descontos
            </Typography>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Desconto</StyledTableCell>
                    <StyledTableCell align="left">SubTotal</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow key="1">
                    <StyledTableCell align="left">
                      Desconto em Nfe
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(total_desconto_nfe)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="2">
                    <StyledTableCell align="left">
                      Desconto em Boleto
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(total_desconto_boleto)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="3">
                    <StyledTableCell align="left">
                      Desconto em Bonificação
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(total_desconto_bonificacao)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="4">
                    <StyledTableCell align="left">
                      Desconto em Backlight
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(total_desconto_backlight)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="5">
                    <StyledTableCell align="left">
                      Desconto em Jornal de Ofertas
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(total_desconto_jornal_ofertas)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="5">
                    <StyledTableCell align="left">
                      Desconto em Descarrego de Mercadorias
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(total_desconto_descargo_mercadorias)}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                margin: '25px 0 15px',
                textAlign: 'center',
                color: 'red',
              }}
            >
              Impostos
            </Typography>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Imposto</StyledTableCell>
                    <StyledTableCell align="left">SubTotal</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow key="1">
                    <StyledTableCell align="left">PIS Compra</StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(total_pis_compra)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="2">
                    <StyledTableCell align="left">
                      COFINS Compra
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(total_cofins_compra)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="3">
                    <StyledTableCell align="left">ICMS Compra</StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(total_icms_compra)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="4">
                    <StyledTableCell align="left">
                      ICMS Antecipação
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(total_icms_antecipacao)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="5">
                    <StyledTableCell align="left">CSLL</StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(total_csll)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="6">
                    <StyledTableCell align="left">PIS Venda</StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(total_pis_venda)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="7">
                    <StyledTableCell align="left">COFINS Venda</StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(total_cofins_venda)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="8">
                    <StyledTableCell align="left">ICMS Venda</StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(total_icms_venda)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="9">
                    <StyledTableCell align="left">
                      ICMS de Fronteira
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(total_icms_fronteira)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="10">
                    <StyledTableCell align="left">IRPJ</StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(total_irpj)}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
      </Dialog>
    );
  },
);

export default Grafico;
