import React, { useRef } from 'react';

import DialogoCarregarNota from './DialogoCarregarNota';

const TesteLeituraXml = () => {
  const refDialogoCarregarNota = useRef(null);

  function handleOpenCarregarNota() {
    if (refDialogoCarregarNota.current) {
      refDialogoCarregarNota.current.handleOpen();
    }
  }

  return (
    <div>
      <div>TesteLeituraXml</div>
      <button type="button" onClick={handleOpenCarregarNota}>
        Abrir Nota
      </button>
      <DialogoCarregarNota ref={refDialogoCarregarNota} />
    </div>
  );
};

export default TesteLeituraXml;
