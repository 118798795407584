import React from 'react';
import {
  Filter as FilterAdmin,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  BooleanInput,
  TextInput,
} from 'react-admin';

import {
  IntegerInput,
  PeriodoInput,
  PrecoInput,
} from '../../Components/InputsReactAdmin';
import { STATUS_ATIVO } from '../../utils/constants';
import { choicesPagamento } from '../Vendas/choices';
import choices from './choices';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <ReferenceInput
      source="caixa_id"
      reference="contas_normal"
      label="Caixa"
      allowEmpty={false}
      filter={{ is_caixa: true, status: STATUS_ATIVO }}
    >
      <SelectInput optionText="nome" allowEmpty={false} />
    </ReferenceInput>
    {/* <SelectInput
      source="tipo_pagamento_id"
      choices={choices}
      label="Tipo Pagamento"
      allowEmpty={false}
    /> */}
    <ReferenceInput
      fullWidth
      label="Cliente"
      source="cliente_id"
      reference="clientes"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput>
    {/* <SelectInput
      source="status"
      choices={choicesPagamento}
      allowEmpty={false}
    /> */}
    <PeriodoInput
      source="periodo"
      label="Peŕiodo"
      defaultValue={{
        start: new Date(),
        end: new Date(),
      }}
    />
    <TextInput source="numero" label="Número da venda" />
    <PrecoInput
      source="valor"
      label="Valor da venda"
      fixedDecimalScale={false}
    />
    <PrecoInput
      source="valorDinheiro"
      label="Valor Parcela Dinheiro"
      fixedDecimalScale={false}
    />
    <PrecoInput
      source="valorPix"
      label="Valor Parcela Pix"
      fixedDecimalScale={false}
    />
    <PrecoInput
      source="valorDebito"
      label="Valor Parcela Débito"
      fixedDecimalScale={false}
    />
    <PrecoInput
      source="valorVonchuer"
      label="Valor Parcela Voucher"
      fixedDecimalScale={false}
    />
    <PrecoInput
      source="valorDescontoDevolucao"
      label="Valor Parcela Devolução"
      fixedDecimalScale={false}
    />
    <PrecoInput
      source="valorCredito"
      label="Valor Parcela Crédito"
      fixedDecimalScale={false}
    />
  </FilterAdmin>
);

export default Filter;

// mudar aqui
