export function createObjEstoque(tipoProduto, linhas) {
  const valorEstoque = [];

  for (let i = 0; i < linhas.length; i++) {
    valorEstoque.push(
      tipoProduto === 0 ? linhas[i].unidades_estoque : linhas[i].peso_estoque,
    );
  }

  return [
    {
      label: 'Total',
      color: 'rgba(75, 192, 192, 1)',
      data: valorEstoque,
    },
  ];
}
