import React, { useRef, useState, useCallback, useEffect } from 'react';
import {
  TextField,
  ReferenceField,
  ListBase,
  ListToolbar,
  Pagination,
  useRefresh,
  Title,
  useListContext,
  Datagrid,
  DateField,
  ListContextProvider,
  BooleanField,
  FunctionField,
} from 'react-admin';

import { makeStyles, Card, Divider, Tabs, Tab } from '@material-ui/core';

import { DialogoConfirmacao, DialogoCompra } from '../../Components';
import { api } from '../../services';
import { SECONDARY_COLOR, formatMoeda } from '../../utils';
import { STATUS_ATIVO, STATUS_INATIVO } from '../../utils/constants';
import DialogoPdf from '../NfeCompras/DialogoPdf';
import CnpjField from '../NfeCompras/Fields/CnpjField';
import DataEmissaoField from '../NfeCompras/Fields/DataEmissaoField';
import ManifestacaoField from '../NfeCompras/Fields/ManifestacaoField';
import ValorField from '../NfeCompras/Fields/ValorField';
import ActionsField from './ActionsField';
import Actions from './ActionsList';
import Filter from './Filter';

const tabs = [
  { id: 'ativos', name: 'Não Concluídos' },
  { id: 'inativos', name: 'Concluídos' },
];

const useStyles = makeStyles((theme) => ({
  statusHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 1,
    backgroundColor: SECONDARY_COLOR,
    padding: '10px',
    color: 'white',
    fontWeight: 'bold',
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  statusCell: {
    // marginLeft: '30px',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    // paddingLeft: '30px',
  },
  statusContent: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 5,
    marginLeft: '30px',
    maxWidth: '100px',
  },
  dataHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoCell: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaCell: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
}));

const PostPanel = ({ record }) => (
  <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
    <div>
      <span
        style={{
          fontWeight: 'bold',
          marginRight: '10px',
        }}
      >
        Chave de Acesso:
      </span>
      <span>{record.chave}</span>
    </div>
    {record.justificativaManifestacao ? (
      <div>
        <span
          style={{
            fontWeight: 'bold',
            marginRight: '10px',
          }}
        >
          Justificativa:
        </span>
        <span>{record.justificativaManifestacao}</span>
      </div>
    ) : null}
  </div>
);

const MyList = ({ ...props }) => {
  const refresh = useRefresh();
  const classes = useStyles();
  const refDialogoPdf = useRef(null);

  function handlePDF(dados) {
    if (refDialogoPdf.current) {
      refDialogoPdf.current.handleOpen(dados.chave);
    }
  }

  const handleClose = () => {
    refresh();
  };

  return (
    <>
      <Title title="NFEs Problemáticas" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <div>
          <Datagrid {...props}>
            <TextField
              source="nsu"
              label="NSU"
              sortable={false}
              headerClassName={classes.statusHeader}
              cellClassName={classes.dataCell}
            />
            <TextField
              source="emitRazao"
              label="Razão Social do Emitente"
              sortable={false}
              headerClassName={classes.statusHeader}
              cellClassName={classes.dataCell}
            />
            <CnpjField
              source="emitCnpj"
              label="CNPJ do Emitente"
              sortable={false}
              headerClassName={classes.statusHeader}
              cellClassName={classes.dataCell}
            />
            <DataEmissaoField
              source="dhEmis"
              label="Data da Emissão"
              sortable={false}
              headerClassName={classes.statusHeader}
              cellClassName={classes.dataCell}
            />
            <ValorField
              source="vNF"
              label="Valor da NFE"
              sortable={false}
              headerClassName={classes.statusHeader}
              cellClassName={classes.dataCell}
            />
            <ManifestacaoField
              source="tipo_manifestacao"
              label="Manifestação"
              sortable={false}
              headerClassName={classes.statusHeader}
              cellClassName={classes.dataCell}
            />
            <ActionsField
              source="valor"
              textAlign="right"
              label="Ações"
              sortable={false}
              handlePdf={handlePDF}
              headerClassName={classes.statusHeader}
              cellClassName={classes.dataCell}
            />
          </Datagrid>
        </div>
        <TransacoesPagination />
      </Card>
      <DialogoPdf ref={refDialogoPdf} handleClose={handleClose} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List = (props) => (
  <>
    <ContainerMyList
      {...props}
      expand={<PostPanel />}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="NFEs Problemáticas"
      component="div"
    />
  </>
);

export default List;
