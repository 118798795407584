import * as React from 'react';
import { useRecordContext } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useSnackbar } from 'notistack';

import { imprimirNotaGrandesPesagens } from '../../../services';
import { STATUS_ATIVO } from '../../../utils';

const ActionsField = ({
  handleEdit = () => {},
  handleShow = () => {},
  handleDelete = () => {},
  ...rest
}) => {
  const record = useRecordContext(rest);
  const status = record ? (record.record ? record.record.status : '') : '';
  const { enqueueSnackbar } = useSnackbar();

  async function imprimir(id) {
    if (id > 0) {
      const codigo = await imprimirNotaGrandesPesagens(id);
      if (codigo < 0) {
        enqueueSnackbar('Erro ao imprimir a nota!', {
          variant: 'error',
        });
      }
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Tooltip title="Imprimir" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="imprimir"
          size="small"
          onClick={() => imprimir(record ? record.record.id : -1)}
        >
          <PrintIcon />
        </IconButton>
      </Tooltip>

      {status === STATUS_ATIVO && (
        <Tooltip title="Editar" placement="bottom" aria-label="add2">
          <IconButton
            aria-label="check"
            size="small"
            onClick={() => handleEdit(record.record)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}

      {status === STATUS_ATIVO && (
        <Tooltip title="Excluir" placement="bottom" aria-label="excluir2">
          <IconButton
            aria-label="excluir"
            size="small"
            onClick={() => handleDelete(record.record)}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      )}

      <Tooltip title="Detalhes" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleShow(record.record)}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ActionsField;
