import * as React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';

const ActionsField = ({
  handleEdit,
  handleDelete,
  handleShow,
  isDelete = false,
  isEdit = false,
  isShow = false,
  ...rest
}) => (
  <div style={{ marginRight: '0px' }} {...rest}>
    {isShow ? (
      <Tooltip title="Detalhes" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="show"
          size="small"
          onClick={() => handleShow(rest)}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
    ) : null}
    {isEdit ? (
      <Tooltip title="Editar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="edit"
          size="small"
          onClick={() => handleEdit(rest)}
        >
          <EditOutlinedIcon />
        </IconButton>
      </Tooltip>
    ) : null}
    {isDelete ? (
      <Tooltip title="Deletar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => handleDelete(rest)}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </Tooltip>
    ) : null}
  </div>
);

export default ActionsField;
