import * as React from 'react';
import { useRecordContext } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PrintIcon from '@material-ui/icons/Print';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useSnackbar } from 'notistack';

import { imprimirRelatorioCaixa } from '../../services/impressora';

const ActionsField = ({
  handleFechar = () => {},
  handleShow = () => {},
  ...rest
}) => {
  const record = useRecordContext(rest);
  const { enqueueSnackbar } = useSnackbar();

  const is_fechamento = record ? record.record.is_fechamento : false;

  const fechamento = record ? record.record.fechamento : null;

  async function imprimir(id) {
    if (id > 0) {
      const codigo = await imprimirRelatorioCaixa(id);
      if (codigo < 0) {
        enqueueSnackbar('Erro ao imprimir o relatorio!', {
          variant: 'error',
        });
      }
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {!is_fechamento && fechamento && (
        <Tooltip title="conferir" placement="bottom" aria-label="conferir">
          <IconButton
            aria-label="conferir"
            size="small"
            onClick={() => handleFechar(record.record)}
          >
            <CheckCircleOutlineIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Imprimir" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="imprimir"
          size="small"
          onClick={() => imprimir(record ? record.record.id : -1)}
        >
          <PrintIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Detalhes" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleShow(record.record)}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ActionsField;
