import AutorenewIcon from '@material-ui/icons/Autorenew';

import List from './List2';
import Show from './Show';

export default {
  list: List,
  show: Show,
  icon: AutorenewIcon,
};
