import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useListContext } from 'react-admin';

import { Card, CardContent } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CloseIcon from '@material-ui/icons/Close';
import Rating from '@material-ui/lab/Rating';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { getListAllAPI, getOneAPI } from '../../../services';
import { formatMoeda } from '../../../utils';
import Tabela from './Tabela';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    //borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const CardTotal = ({ label, value, onClick }) => (
  <Card
    style={{ borderRadius: '20px', cursor: 'pointer', margin: '10px 0px' }}
    onClick={onClick}
  >
    <CardContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%',
          alignItems: 'center',
        }}
      >
        <div>
          <div
            style={{
              fontSize: '17px',
              color: '#a1c3d1',
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {label}
          </div>
          <div style={{ fontSize: '20px', color: 'black', fontWeight: 'bold' }}>
            {formatMoeda(value)}
          </div>
        </div>
      </div>
    </CardContent>
  </Card>
);

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose = () => {} }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [descontos, setDescontos] = useState([]);
    const [descontosObj, setDescontosObj] = useState({});
    const [selected, setSelected] = useState([]);
    const [descontoAceitavel, setDescontoAceitavel] = useState(0);

    const listContext = useListContext();
    const { filterValues } = listContext;

    const [carregando, setCarregando] = useState(false);

    const classes = useStyles({ cor: '#009FD4' });

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    function formatDescontos(descontos, base) {
      const rowsFormat = [];
      for (let i = 0; i < descontos.length; i++) {
        const desc = descontos[i];
        rowsFormat.push({
          id: desc.id,
          funcionario: desc.user.username,
          categoria: desc.categoria.nome,
          data: moment(desc.data).format('DD/MM/YYYY'),
          valor: formatMoeda(desc.valor),
          isAcima: desc.valor > base,
        });
      }
      return rowsFormat;
    }

    function initSelects(rows, base) {
      const selects = [];
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].valor > base) selects.push(rows[i].id);
      }
      return selects;
    }

    function formatObj(rows) {
      const rowsFormat = {};
      for (let i = 0; i < rows.length; i++) {
        const desc = rows[i];
        rowsFormat[desc.id] = {
          valor: desc.valor,
        };
      }
      return rowsFormat;
    }

    async function getDataResource() {
      try {
        const data = await getListAllAPI(
          'descontos_funcionarios',
          ['data', 'desc'],
          {
            search: filterValues.search,
            categoria_desconto_funcionario_id:
              filterValues.categoria_desconto_funcionario_id,
            user_id: filterValues.user_id,
            periodo2: filterValues.periodo2,
            //desconto_aceitavel_funcionario:
            //filterValues.desconto_aceitavel_funcionario,
          },
          ['user', 'categoria'],
        );
        const data2 = await getOneAPI('config', 1, []);
        setDescontoAceitavel(data2.data.desconto_aceitavel_funcionario);
        setDescontos(
          formatDescontos(data.data, data2.data.desconto_aceitavel_funcionario),
        );
        setSelected(
          initSelects(data.data, data2.data.desconto_aceitavel_funcionario),
        );
        setDescontosObj(formatObj(data.data));
        setCarregando(false);
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    useImperativeHandle(ref, () => ({
      handleOpen() {
        setCarregando(true);
        getDataResource();
        setOpen(true);
      },
    }));

    function getTotal(selected, descontosObj) {
      let soma = 0;
      for (let i = 0; i < selected.length; i++) {
        if (descontosObj[selected[i]]) {
          soma += descontosObj[selected[i]].valor;
        }
      }
      return soma;
    }

    const total = getTotal(selected, descontosObj);

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullScreen
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            Descontos
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div>
                <CardTotal label="Total" value={total} onClick={() => {}} />
                <Tabela
                  rows={descontos}
                  selected={selected}
                  setSelected={setSelected}
                  descontoAceitavel={descontoAceitavel}
                />
              </div>
            )}
          </DialogContent>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
