import React, { useRef } from 'react';
import { List as ListAdmin } from 'react-admin';

import ChangeAtivoButton from '../ChangeAtivoButton';
import ContainerActionsBack from '../ContainerActionsBack';
import Datagrid from '../Datagrid';
import DeleteButton from '../DeleteButton';
import DialogoAtivarResources from '../DialogoAtivarResource';
import DialogoDeleteResource from '../DialogoDeleteResource';
import Actions from './Actions';

const ActionVazio = () => <div />;
const vazioElemet = <ActionVazio />;

const BodyList = ({
  children,
  isDelete,
  disabledDelete,
  isButtonAtivo,
  campoAtivo,
  inverterAtivo,
  ...props
}) => {
  const refDialogoDeleteResource = useRef(null);
  const refDialogoAtivaResource = useRef(null);
  function deleteHundle(record) {
    if (refDialogoDeleteResource.current)
      refDialogoDeleteResource.current.handleOpen(record);
  }
  function ativarHandle(record) {
    if (refDialogoAtivaResource.current)
      refDialogoAtivaResource.current.handleOpen(record);
  }
  return (
    <>
      <Datagrid {...props}>
        {children}
        {isDelete && (
          <DeleteButton
            label="Excluir"
            deleteHundle={deleteHundle}
            disabled={disabledDelete}
          />
        )}
        {isButtonAtivo && (
          <ChangeAtivoButton
            deleteHundle={ativarHandle}
            campoAtivo={campoAtivo}
            inverterAtivo={inverterAtivo}
          />
        )}
      </Datagrid>
      <DialogoDeleteResource ref={refDialogoDeleteResource} />
      <DialogoAtivarResources
        ref={refDialogoAtivaResource}
        campoAtivo={campoAtivo}
      />
    </>
  );
};

const List = ({
  children,
  title,
  enabledCreate = true,
  actions = <Actions enabledCreate={enabledCreate} />,
  isBack = false,
  isDelete = false,
  routeBack = '',
  isButtonAtivo = false,
  campoAtivo = 'ativo',
  inverterAtivo = false,
  disabledDelete = () => false,
  expand = null,
  ...props
}) => (
  <>
    {isBack && (
      <ContainerActionsBack
        actions={vazioElemet}
        isBack={isBack}
        routeBack={routeBack}
      />
    )}
    <ListAdmin
      {...props}
      exporter={false}
      bulkActionButtons={false}
      title={title}
      actions={actions}
    >
      <BodyList
        isDelete={isDelete}
        disabledDelete={disabledDelete}
        isButtonAtivo={isButtonAtivo}
        campoAtivo={campoAtivo}
        inverterAtivo={inverterAtivo}
        expand={expand}
      >
        {children}
      </BodyList>
    </ListAdmin>
  </>
);
export default List;
