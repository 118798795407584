function calculaMargeamento({
  valorNegociadoComAIndustria,
  irpjPorcentagemLb,
  csllPorcentagemLb,
  precoVenda,
  aliquotaInternaCompraPorcentagem,
  pisCompraPorcentagem,
  cofinsCompraPorcentagem,
  aliquotaInternaVendaPorcentagem,
  margemDeCustoOperacionalPorcentagem,
  margemDeCustoDeCartaoPorcentagem,
  repasseDoIpi,
  repasseDoFreteUnitario,
  repasseDeSt,
  repasseDeSeguros,
  repasseDeOutrasDespesas,
  icmsAntecipacao,
  icmsDeFronteira,
}) {
  const custoFinalDoProduto =
    valorNegociadoComAIndustria +
    repasseDoIpi +
    repasseDoFreteUnitario +
    repasseDeSt +
    repasseDeSeguros +
    repasseDeOutrasDespesas;

  const aliquotaInternaCompra =
    valorNegociadoComAIndustria * (aliquotaInternaCompraPorcentagem / 100);

  const pisCompra = valorNegociadoComAIndustria * (pisCompraPorcentagem / 100);

  const cofinsCompra =
    valorNegociadoComAIndustria * (cofinsCompraPorcentagem / 100);

  const custoLivreDeImpostoPorcentagem =
    custoFinalDoProduto - aliquotaInternaCompra - pisCompra - cofinsCompra;

  const custoAMais = icmsAntecipacao + icmsDeFronteira;

  const lucroBrutoPorcentagem =
    (1 -
      ((pisCompraPorcentagem +
        cofinsCompraPorcentagem +
        aliquotaInternaVendaPorcentagem +
        margemDeCustoOperacionalPorcentagem +
        margemDeCustoDeCartaoPorcentagem) /
        100 +
        (custoLivreDeImpostoPorcentagem + custoAMais) / precoVenda)) *
    100;

  const lucroLiquidoPorcentagem =
    lucroBrutoPorcentagem * (1 - (irpjPorcentagemLb + csllPorcentagemLb) / 100);

  return {
    lucroBrutoPorcentagem,
    lucroLiquido: lucroLiquidoPorcentagem,
  };
}

export { calculaMargeamento };
