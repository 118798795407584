import { useRef, useState } from 'react';

import { useSnackbar } from 'notistack';

import { getListAllAPI, getUserId, api } from '../../../../services';
import {
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  STATUS_ATIVO,
  getTotalCompra,
  searchItemInArray3,
} from '../../../../utils';
import {
  tipoStatusAndamentoAprovar,
  tipoStatusAndamentoAprovarVolta,
  tipoStatusAndamentoCriarPedido,
  tipoStatusAndamentoDetalhes,
} from '../utils/constants';

function useItens(
  itens,
  updateItem,
  cliente,
  editarModo,
  idEdit,
  isMargemCartao,
  dataMinima,
  dataMaxima,
  tipoStatusAndamento,
) {
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState('');
  const refSearch = useRef(null);
  const refPodeFecharPedido = useRef(true);

  async function searchHandle(codigo, id, openDialogoLiberacao) {
    const produtoData = await getListAllAPI(
      'produtos',
      ['id', 'asc'],
      id
        ? { id }
        : {
            codigo,
            is_kit: false,
            is_caixa: false,
            status: STATUS_ATIVO,
            ofertasProdutos: true,
          },
      ['unidade', 'categoria', 'familia'],
    );
    if (produtoData.data.length > 0) {
      const index = searchItemInArray3(produtoData.data[0], itens);
      produtoData.data[0].unidadesDisponivel += index.unidadesUsadas;
      produtoData.data[0].pesoDisponivel += index.pesoUsado;
      openDialogoLiberacao(produtoData.data[0]);
    } else if (refSearch.current) refSearch.current.focus();
    setSearch('');
  }

  function addNewItemInterno(dados) {
    const {
      produto,
      user_id,
      valor,
      tipo_unidade,
      dadosPrecificacao,
      verba_unitaria,
      quantidade,
      is_fim_negociacao,
      tipo_unidade_quantidade,
      tipo_unidade_preco_sugerido,
    } = dados;

    updateItem({
      produto,
      uidd: `${produto.nome}${itens.length}`,
      idIndicadorChange: -1,
      dadosPrecificacao,
      user_id,
      valor,
      tipo_unidade,
      verba_unitaria,
      quantidade,
      verba_unitaria_solicitado: verba_unitaria,
      status_aprovado: true,
      status_aprovado_volta: null,
      verba_unitaria_old: verba_unitaria,
      verba_unitaria_solicitado_old: verba_unitaria,
      is_fim_negociacao,
      tipo_unidade_quantidade,
      tipo_unidade_preco_sugerido,
    });
  }

  function addNewItem(dados) {
    addNewItemInterno(dados);
  }

  function getMessagesError() {
    const errors = [];
    if (cliente === null) {
      errors.push({
        message: "Campo 'Cliente' não foi preenchido!",
        type: 'warning',
      });
    }
    if (itens.length <= 0) {
      errors.push({
        message: 'É necessário ao menos um registro de item!',
        type: 'warning',
      });
    }
    return errors;
  }

  function montarObjVenda() {
    const listaItens = [];

    for (let i = 0; i < itens.length; i += 1) {
      listaItens.push({
        produto_id: itens[i].produto.id,
        dadosPrecificacao: itens[i].dadosPrecificacao,
        user_id: itens[i].user_id,
        valor: itens[i].valor,
        tipo_unidade: itens[i].tipo_unidade,
        verba_unitaria: itens[i].verba_unitaria,
        quantidade: itens[i].quantidade,
        idIndicadorChange: itens[i].idIndicadorChange,
        verba_unitaria_solicitado: itens[i].verba_unitaria_solicitado,
        status_aprovado_volta: itens[i].status_aprovado_volta,
        isModificado: itens[i].isModificado,
        is_fim_negociacao: itens[i].is_fim_negociacao,
        tipo_unidade_quantidade: itens[i].tipo_unidade_quantidade,
        tipo_unidade_preco_sugerido: itens[i].tipo_unidade_preco_sugerido,
      });
    }

    return {
      listaItens,
      cliente_id: cliente.id,
      user_id: getUserId(),
      isMargemCartao,
      dataMinima,
      dataMaxima,
    };
  }

  async function submitCompra() {
    try {
      switch (tipoStatusAndamento) {
        case tipoStatusAndamentoCriarPedido: {
          const objVenda = montarObjVenda();
          const data = await api.post(`/pedidos_liberacao_precos`, {
            ...objVenda,
          });
          const { status, message, data: data2 } = data.data;
          if (status) {
            return {
              id: data2.id,
              message: '',
            };
          }
          throw message;
        }

        case tipoStatusAndamentoDetalhes: {
          return {
            id: idEdit,
            message: '',
          };
        }

        case tipoStatusAndamentoAprovar: {
          const objVenda = montarObjVenda();
          const data = await api.put(
            `/solicitacoes_liberacao_precos_aprovacao/${idEdit}`,
            {
              ...objVenda,
            },
          );
          const { status, message, data: data2 } = data.data;
          if (status) {
            return {
              id: idEdit,
              message: '',
            };
          }
          throw message;

          /* return {
            id: idEdit,
            message: '',
          }; */
        }

        case tipoStatusAndamentoAprovarVolta: {
          const objVenda = montarObjVenda();
          const data = await api.put(
            `/solicitacoes_liberacao_precos_aprovacao_volta/${idEdit}`,
            {
              ...objVenda,
            },
          );
          const { status, message, data: data2 } = data.data;
          if (status) {
            return {
              id: idEdit,
              message: '',
            };
          }
          throw message;

          /* return {
            id: idEdit,
            message: '',
          }; */
        }

        default:
          break;
      }
    } catch (e) {
      return {
        id: -1,
        message: e,
      };
    }
  }

  async function handleOpenNota() {
    const errors = getMessagesError();
    if (errors.length <= 0 && refPodeFecharPedido.current) {
      refPodeFecharPedido.current = false;
      const { id, message } = await submitCompra();
      if (id > 0) {
        /////////
      } else {
        refPodeFecharPedido.current = true;
        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    } else {
      for (let i = 0; i < errors.length; i += 1) {
        enqueueSnackbar(errors[i].message, {
          variant: errors[i].type,
        });
      }
    }
  }

  return {
    searchHandle,
    addNewItem,
    setSearch,
    refSearch,
    search,
    enqueueSnackbar,
    handleOpenNota,
  };
}

export { useItens };
