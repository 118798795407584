import { createMuiTheme } from '@material-ui/core/styles';

import { PRIMARY_COLOR, SECONDARY_COLOR } from '../utils';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  overrides: {
    MuiAppBar: {
      root: {
        border: 0,
      },
    },
  },
  sidebar: {
    //width: 250,
    //closedWidth: 70,
  },
});

export default theme;
