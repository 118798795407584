import React from 'react';

import EditTemplate from '../../Components/Edit';
import Erros from './Erros';
import Form from './Form';
import validate from './Validation';

const Title = ({ record }) => (
  <span>Editar Adm: {record ? `"${record.username}"` : ''}</span>
);

function transform({
  passwordNew,
  dataAniversario,
  username,
  funcao,
  cargo_id,
  is_responsavel,
  ...data
}) {
  let dia_aniversario = null;
  let mes_aniversario = null;
  let ano_aniversario = null;

  if (dataAniversario !== null) {
    const dataAniversarioObj = new Date(dataAniversario);
    dia_aniversario = dataAniversarioObj.getDate();
    mes_aniversario = dataAniversarioObj.getMonth();
    ano_aniversario = dataAniversarioObj.getFullYear();
  }
  return passwordNew
    ? {
        dia_aniversario,
        mes_aniversario,
        ano_aniversario,
        username,
        password: passwordNew,
        funcao: 0,
        cargo_id,
        is_responsavel,
      }
    : {
        dia_aniversario,
        mes_aniversario,
        ano_aniversario,
        username,
        funcao: 0,
        cargo_id,
        is_responsavel,
      };
}

const Edit = (props) => (
  <EditTemplate
    {...props}
    title={<Title />}
    transform={transform}
    validate={validate}
    onFailure={Erros}
    defaultValue={{ isCreate: false }}
    isBack
    isDelete={false}
  >
    <Form />
  </EditTemplate>
);

export default Edit;
