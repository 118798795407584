import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CloseIcon from '@material-ui/icons/Close';
import EventIcon from '@material-ui/icons/Event';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import NoteIcon from '@material-ui/icons/Note';
import { useSnackbar } from 'notistack';

import { getListAllAPI, createAPI, updateAPI } from '../../../services';
import { STATUS_ATIVO } from '../../../utils/constants';
import {
  TextInput,
  PrecoInput,
  AutoCompleteLocalCustomizado,
} from '../../Inputs';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    // backgroundColor: (props) => props.cor,
    // color: 'white',
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    // backgroundColor: 'rgba(0, 0, 0, 0.12)',
    // color: 'red',
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const useStyles3 = makeStyles({
  chip: {
    borderColor: (props) => props.color,
    backgroundColor: 'white',
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  deleteIcon: {
    color: (props) => props.color,
    cursor: 'pointer',
    '&:hover': {
      color: (props) => props.color,
    },
  },
});

const ChipConta = ({ option, injetado, campo }) => {
  const classes2 = useStyles3({ color: option.color });
  const { onDelete, ...rest } = injetado;
  return (
    <Chip
      classes={{ outlined: classes2.chip, deleteIcon: classes2.deleteIcon }}
      variant="outlined"
      label={option[campo]}
      {...rest}
      avatar={
        <Avatar
          style={{
            backgroundColor: option.color,
            marginRight: '3px',
            color: 'white',
          }}
        >
          {option.nome.charAt(0)}
        </Avatar>
      }
    />
  );
};

const ChipDiaFechamento = ({ option, injetado }) => {
  const classes2 = useStyles3({ color: '#ccd6e3' });
  const { onDelete, ...rest } = injetado;
  return (
    <Chip
      classes={{ outlined: classes2.chip, deleteIcon: classes2.deleteIcon }}
      variant="outlined"
      label={option.nome}
      {...rest}
    />
  );
};

const diasOptions = [];

for (let i = 1; i <= 28; i += 1) {
  diasOptions.push({ value: i, nome: `${i}` });
}

const DEFAULT_NOME = '';
const DEFAULT_VALOR = 0;
const DEFAULT_CONTA = [];
const DEFAULT_DIA_FECHAMENTO = [diasOptions[0]];
const DEFAULT_DIA_PAGAMENTO = [diasOptions[4]];
const DEFAULT_ID = -1;

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose, campo = 'nome' }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [nome, setNome] = useState(DEFAULT_NOME);
    const [valor, setValor] = useState(DEFAULT_VALOR);
    const [conta, setConta] = useState(DEFAULT_CONTA);
    const [contasOptions, setContasOptions] = useState([]);
    const [diaFechamento, setDiaFechamento] = useState(DEFAULT_DIA_FECHAMENTO);
    const [diaPagamento, setDiaPagamento] = useState(DEFAULT_DIA_PAGAMENTO);
    const [id, setId] = useState(DEFAULT_ID);
    const [carregando, setCarregando] = useState(false);
    const classes = useStyles();

    const refValorField = useRef(null);

    function focusValorField() {
      if (refValorField.current) {
        refValorField.current.focus();
        refValorField.current.select();
      }
    }

    const tempo = 1000;

    useEffect(() => {
      const timer = setTimeout(() => {
        if (open) {
          focusValorField();
        }
      }, tempo);
      return () => clearTimeout(timer);
    }, [open]);

    async function getContas(id = 0) {
      const dataContasSemCaixas = await getListAllAPI(
        'contas_normal',
        ['id', 'asc'],
        id === 0
          ? { is_caixa: false, status: STATUS_ATIVO }
          : { is_caixa: false, status: STATUS_ATIVO, ids_incluso: [id] },
        [],
      );
      setContasOptions(dataContasSemCaixas.data);
    }

    /* useEffect(() => {
      if (open) getContas();
    }, [open]); */

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    async function reiniciar() {
      setNome(DEFAULT_NOME);
      setValor(DEFAULT_VALOR);
      setConta(DEFAULT_CONTA);
      setDiaFechamento(DEFAULT_DIA_FECHAMENTO);
      setDiaPagamento(DEFAULT_DIA_PAGAMENTO);
      setId(DEFAULT_ID);
      setCarregando(false);
      await getContas();
    }

    async function getDataResource(id) {
      try {
        const data = await getListAllAPI(
          'cartoes_credito',
          ['id', 'asc'],
          { id: [id] },
          ['conta'],
        );
        if (data.data.length > 0) {
          await getContas(data.data[0].conta.id);
          setNome(data.data[0].nome);
          setValor(data.data[0].limite);
          setConta([data.data[0].conta]);
          setDiaFechamento([
            {
              value: data.data[0].dia_fechamento,
              nome: `${data.data[0].dia_fechamento}`,
            },
          ]);
          setDiaPagamento([
            {
              value: data.data[0].dia_pagamento,
              nome: `${data.data[0].dia_pagamento}`,
            },
          ]);
          setId(data.data[0].id);
          setCarregando(false);
        } else {
          throw 'Este cartão de crédito não existe!';
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    async function handleSalvar() {
      setCarregando(true);
      try {
        if (id <= 0) {
          await createAPI('cartoes_credito', {
            nome,
            limite: valor,
            conta_id: conta[0].id,
            dia_fechamento: diaFechamento[0].value,
            dia_pagamento: diaPagamento[0].value,
            limite_restante: valor,
          });
          setCarregando(false);
          enqueueSnackbar('Cartão de crédito criado com sucesso!', {
            variant: 'success',
          });
        } else {
          await updateAPI('cartoes_credito', id, {
            nome,
            limite: valor,
            conta_id: conta[0].id,
            dia_fechamento: diaFechamento[0].value,
            dia_pagamento: diaPagamento[0].value,
          });
          setCarregando(false);
          enqueueSnackbar('Cartão de crédito atualizado com sucesso!', {
            variant: 'success',
          });
        }
        handleCloseDialog();
      } catch (erros) {
        setCarregando(false);
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    useImperativeHandle(ref, () => ({
      handleCreate() {
        reiniciar();
        setOpen(true);
      },
      handleEdit(id) {
        setCarregando(true);
        getDataResource(id);
        setId(id);
        setOpen(true);
      },
    }));

    const renderTagConta = (option, injetado) => (
      <ChipConta option={option} injetado={injetado} campo={campo} />
    );

    const renderOptionConta = (option) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          style={{
            backgroundColor: option.color,
            marginRight: '10px',
            width: '30px',
            height: '30px',
          }}
        >
          {option.nome.charAt(0)}
        </Avatar>
        {option[campo]}
      </div>
    );

    const handleOnChangeConta = (value) => {
      if (value.length > 0) {
        if (value.length === 1) {
          setConta([value[0]]);
        } else if (value.length === 2) {
          setConta([value[1]]);
        }
      } else {
        setConta([]);
      }
    };

    const renderTagDiaFechamento = (option, injetado) => (
      <ChipDiaFechamento option={option} injetado={injetado} campo="nome" />
    );

    const renderOptionDiaFechamento = (option) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>{option.nome}</div>
    );

    const handleOnChangeDiaFechamento = (value) => {
      if (value.length > 0) {
        if (value.length === 1) {
          setDiaFechamento([value[0]]);
        } else if (value.length === 2) {
          setDiaFechamento([value[1]]);
        }
      } else {
        setDiaFechamento([]);
      }
    };

    const handleOnChangeDiaPagamento = (value) => {
      if (value.length > 0) {
        if (value.length === 1) {
          setDiaPagamento([value[0]]);
        } else if (value.length === 2) {
          setDiaPagamento([value[1]]);
        }
      } else {
        setDiaPagamento([]);
      }
    };

    function validateValor() {
      return valor <= 0 || Number.isNaN(valor)
        ? 'Deve ter um valor maior que 0'
        : '';
    }

    function validateNome() {
      let error = '';
      if (!nome) error = 'Este campo é obrigatório';
      else if (nome.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
      return error;
    }

    function validateConta() {
      return conta.length <= 0 ? 'Este campo é obrigatório' : '';
    }

    function validateDiaFechamento() {
      return diaFechamento.length <= 0 ? 'Este campo é obrigatório' : '';
    }

    function validateDiaPagamento() {
      return diaPagamento.length <= 0 ? 'Este campo é obrigatório' : '';
    }

    function getErros() {
      const errosOld = ['', '', '', '', ''];
      errosOld[0] = validateValor();
      errosOld[1] = validateNome();
      errosOld[2] = validateConta();
      errosOld[3] = validateDiaFechamento();
      errosOld[4] = validateDiaPagamento();

      return errosOld;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullWidth
          maxWidth="sm"
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: '#00796b', color: 'white' }}
          >
            {id < 0 ? 'Novo cartão de crédito' : 'Editar cartão de crédito'}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div>
                <PrecoInput
                  name="valor"
                  // ref={refs.current[1]}
                  ref={refValorField}
                  handleEnter={() => {}}
                  label="Limite"
                  handleKey={() => {}}
                  value={Number.isNaN(valor) ? '' : String(valor)}
                  onChange={(value) => {
                    const newValue = parseFloat(value.target.value);
                    if (newValue <= 0 || Number.isNaN(newValue)) {
                      setValor(0);
                    } else {
                      setValor(newValue);
                    }
                    // setValor(parseFloat(value.target.value))
                  }}
                  error={erros[0] !== ''}
                  helperText={erros[0]}
                  // style={{ marginBottom: ESPACO_INPUTS }}
                  fullWidth
                  variant="standard"
                  autoFocus
                  /* InputProps={{

                  }} */
                  // inputProps={{ fontSize: '20px' }}
                  styleInput={{ fontSize: '25px', color: '#00796b' }}
                />
                <TextInput
                  name="nome"
                  handleEnter={() => {}}
                  label="Descrição"
                  handleKey={() => {}}
                  value={nome}
                  onChange={(value) => setNome(value.target.value)}
                  error={erros[1] !== ''}
                  helperText={erros[1]}
                  fullWidth
                  autoFocus
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <>
                        <NoteIcon
                          style={{
                            marginRight: '24px',
                            transform: 'rotate(-90deg) rotateX(180deg)',
                          }}
                        />
                      </>
                    ),
                  }}
                />
                <AutoCompleteLocalCustomizado
                  name="conta"
                  handleEnter={() => {}}
                  label="Conta"
                  handleKey={() => {}}
                  value={conta}
                  disableClearable
                  onChangeLocal={(value) => handleOnChangeConta(value)}
                  campo="nome"
                  options={contasOptions}
                  variant="standard"
                  renderTag={renderTagConta}
                  renderOption={renderOptionConta}
                  // style={{ marginBottom: ESPACO_INPUTS, marginTop: '0px' }}
                  // disabled={tipoEdicao === 2 || isEditHasCaixa}
                  // defaultValue={[]}
                  error={erros[2] !== ''}
                  helperText={erros[2]}
                  startAdornment={
                    <InputAdornment position="start">
                      <AccountBalanceIcon style={{ marginRight: '16px' }} />
                    </InputAdornment>
                  }
                />
                <AutoCompleteLocalCustomizado
                  name="fechamento"
                  handleEnter={() => {}}
                  label="Dia de fechamento"
                  handleKey={() => {}}
                  value={diaFechamento}
                  disableClearable
                  onChangeLocal={(value) => handleOnChangeDiaFechamento(value)}
                  campo="nome"
                  options={diasOptions}
                  variant="standard"
                  renderTag={renderTagDiaFechamento}
                  renderOption={renderOptionDiaFechamento}
                  filterSelectedOptions={false}
                  // style={{ marginBottom: ESPACO_INPUTS, marginTop: '0px' }}
                  // disabled={tipoEdicao === 2 || isEditHasCaixa}
                  // defaultValue={[]}
                  error={erros[3] !== ''}
                  helperText={erros[3]}
                  startAdornment={
                    <InputAdornment position="start">
                      <EventIcon style={{ marginRight: '16px' }} />
                    </InputAdornment>
                  }
                />
                <AutoCompleteLocalCustomizado
                  name="pagamento"
                  handleEnter={() => {}}
                  label="Dia de pagamento"
                  handleKey={() => {}}
                  value={diaPagamento}
                  disableClearable
                  onChangeLocal={(value) => handleOnChangeDiaPagamento(value)}
                  campo="nome"
                  options={diasOptions}
                  variant="standard"
                  renderTag={renderTagDiaFechamento}
                  renderOption={renderOptionDiaFechamento}
                  filterSelectedOptions={false}
                  // style={{ marginBottom: ESPACO_INPUTS, marginTop: '0px' }}
                  // disabled={tipoEdicao === 2 || isEditHasCaixa}
                  // defaultValue={[]}
                  error={erros[4] !== ''}
                  helperText={erros[4]}
                  startAdornment={
                    <InputAdornment position="start">
                      <EventAvailableIcon style={{ marginRight: '16px' }} />
                    </InputAdornment>
                  }
                />
              </div>
            )}
          </DialogContent>
          <DialogActions
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                disabled={erroExistente || carregando}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
              {carregando && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
