import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { URL_SERVER_RESTART } from '../../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DialogoCategoriaTransacao = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [carregando, setCarregando] = useState(false);
  const [message, setMessage] = useState('');

  const classes = useStyles();

  const handleCloseDialog = () => {
    setOpen(false);
  };

  async function restart() {
    const response = await fetch(URL_SERVER_RESTART);
    const data = await response.json();
    setMessage(JSON.stringify(data));
    setCarregando(false);
  }

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setCarregando(true);
      setMessage('');
      restart();
      setOpen(true);
    },
  }));

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth="xs"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{
            backgroundColor: '#f44336',
            color: 'white',
          }}
        >
          Restartar Servidor
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '20px',
                  height: '100px',
                }}
              >
                {`Mensagem do servidor: ${message}`}
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '32px 36px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={handleCloseDialog}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
            variant="outlined"
            color="primary"
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
