import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import { formatDateDDMMYYYYHHmm } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    color: 'white',
    backgroundColor: '#f44336',
  },
}));

const DataEmissaoField = (props) => {
  const classes = useStyles();
  const record = useRecordContext(props);
  const value = record
    ? record.record
      ? formatDateDDMMYYYYHHmm(record.record.dhEmis)
      : ''
    : '';

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title={value} placement="bottom" aria-label="add2">
        <Typography className={classes.text}>{value}</Typography>
      </Tooltip>
    </div>
  );
};

export default DataEmissaoField;
