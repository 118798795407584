import React, { useRef } from 'react';
import {
  TextField,
  ReferenceField,
  NumberField,
  FunctionField,
  SimpleShowLayout,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  Tab,
  Button,
  Link,
  ReferenceManyField,
  ListButton,
  RefreshButton,
  DateField,
  Pagination,
  BooleanField,
} from 'react-admin';

import { useMediaQuery, Box } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AssignmentIcon from '@material-ui/icons/Assignment';

// import Datagrid from '../../Components/Datagrid';
import Datagrid from '../../Components/Datagrid/index2';
import DialogoListaProdutos from '../../Components/DialogoListaProdutos';
import EditButton from '../../Components/EditButton';
import FabButtonNewResource from '../../Components/FabButtonNewResource';
import ShowTemplate from '../../Components/Show';
import {
  formatMoeda,
  round10,
  POR_PESO_VARIAVEL,
  POR_PESO_FIXO,
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
} from '../../utils';
import TextInputLala from './TextInput';

function formatPrcentagem(valor) {
  return valor !== null && valor !== undefined
    ? `${valor.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} %`
    : '';
}

const styles = (theme) => ({
  button: {
    marginTop: '1em',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
});

const AddNewCorrecaoEstoqueButton = withStyles(styles)(
  ({ classes, record }) => (
    <Button
      component={Link}
      className={classes.button}
      to={{
        pathname: '/correcoes_estoque/create',
        search: `?produto_id=${record.id}`,
      }}
      label="Nova Correção de estoque"
    >
      <AddBoxIcon />
    </Button>
  ),
);

/* const Actions = ({ basePath, data }) => (
  <CardActions>
    <ListButton basePath={basePath} />
    <RefreshButton />
    <EditButton basePath={basePath} record={data} />
  </CardActions>
); */

const Actions = ({ basePath, data }) => (
  /* const refDialogoRelatorio = useRef(null);

  function openNota() {
    refDialogoRelatorio.current.handleOpen();
  } */

  <CardActions>
    <RefreshButton />
    <EditButton basePath={basePath} record={data} />
    {/* <Button onClick={openNota} label="Lista Produtos">
      <AssignmentIcon />
    </Button> */}
    {/* {data && (
        <DialogoListaProdutos
          ref={refDialogoRelatorio}
          handleClose={() => {}}
        />
      )} */}
  </CardActions>
);
const Title = ({ record }) => (
  <span>Produto: {record ? `"${record.nome}"` : ''}</span>
);

function showUnidades(tipo) {
  switch (tipo) {
    case POR_UNIDADE:
      return 1;
    case POR_PESO_FIXO:
      return 1;
    case POR_PESO_VARIAVEL:
      return 1;
    default:
      return 0;
  }
}

function showPeso(tipo) {
  switch (tipo) {
    case POR_PESO_VARIAVEL:
      return 1;
    case POR_PESO_VARIAVEL_SEM_UNIDADE:
      return 1;
    case POR_PESO_FIXO:
      return 1;
    default:
      return 0;
  }
}

function showPesoMedio(tipo) {
  switch (tipo) {
    case POR_PESO_FIXO:
      return 1;
    default:
      return 0;
  }
}

function fatorPesoMedio(produto) {
  if (produto.unidade.tipo === POR_PESO_FIXO) {
    return produto.peso_medio;
  }
  return 1;
}

const Show = (props) => (
  <ShowTemplate
    {...props}
    title={<Title />}
    actions={<Actions />}
    isBack
    routeBack="/produtos"
  >
    <TabbedShowLayout
      tabs={<TabbedShowLayoutTabs variant="fullWidth" centered {...props} />}
    >
      <Tab label="Geral">
        <TextField source="nome" label="Nome" />
        <FunctionField
          label="Produto Industrializado"
          render={(record) => (record.is_industrializado ? `SIM` : 'NÃO')}
        />
        <ReferenceField
          label="Produto Base"
          source="produto_base_id"
          reference="produtos"
          sortable={false}
        >
          <TextField source="nome" />
        </ReferenceField>
        <NumberField
          source="peso_medio"
          options={{
            maximumFractionDigits: 3,
            minimumFractionDigits: 3,
          }}
          label="Peso Médio"
        />
        <NumberField
          source="estoque_minimo"
          options={{
            maximumFractionDigits: 3,
            minimumFractionDigits: 3,
          }}
          label="Estoque Mínimo"
        />
        <TextField source="codigo" label="Código" sortable={false} />
        <TextField
          source="codigo_interno"
          label="Código Interno"
          sortable={false}
        />
        <TextField source="tecla" label="Tecla Associada" sortable={false} />
        <NumberField
          source="preco_venda"
          locales="pt-BR"
          options={{
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'BRL',
          }}
          label="Preço de Venda"
        />
        {/* <NumberField
          source="preco_revenda"
          locales="pt-BR"
          options={{
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'BRL',
          }}
          label="Preço de Revenda"
        /> */}
        <FunctionField
          label="Preço de Compra Médio"
          render={(record) =>
            record.precoCompraMedio > 0
              ? `${formatMoeda(record.precoCompraMedio)}`
              : '-'
          }
        />
        <FunctionField
          label="Preço de Compra Médio Imposto"
          render={(record) =>
            record.precoCompraMedio > 0
              ? `${formatMoeda(record.precoCompraMedioImpostos)}`
              : '-'
          }
        />
        <FunctionField
          label="Lucro"
          render={(record) =>
            record.precoCompraMedio > 0
              ? `${formatMoeda(record.preco_venda - record.precoCompraMedio)}`
              : '-'
          }
        />
        <FunctionField
          label="Margem de Lucro (%)"
          render={(record) =>
            record.precoCompraMedio > 0
              ? `${round10(
                  ((record.preco_venda - record.precoCompraMedio) /
                    record.precoCompraMedio) *
                    100,
                  -2,
                )}%`
              : '-'
          }
        />
        <TextField
          source="unidadesDisponivel"
          label="Unidades"
          sortable={false}
        />
        <TextField source="pesoDisponivel" label="Peso (Kg)" sortable={false} />
        <ReferenceField
          label="Unidade"
          source="unidade_id"
          reference="unidades"
          sortable={false}
        >
          <TextField source="nome" />
        </ReferenceField>
        <ReferenceField
          label="Categoria"
          source="categoria_id"
          reference="categorias"
          sortable={false}
        >
          <TextField source="nome" />
        </ReferenceField>
        <ReferenceField
          label="Fornecedor Padrão"
          source="fornecedor_id"
          reference="fornecedores"
          sortable={false}
        >
          <TextField source="nome" />
        </ReferenceField>
        {/*<FunctionField
          label="Desconto da caixa"
          render={(record) => formatPrcentagem(record.desconto_caixa)}
        />*/}
      </Tab>
      <Tab label="Caixaria">
        <FunctionField
          label="Código Caixa (Nível 1)"
          render={(record) =>
            record.codigo_caixa_nivel_1 ? `${record.codigo_caixa_nivel_1}` : '-'
          }
        />
        <FunctionField
          label="Unidades Caixa (Nível 1)"
          render={(record) =>
            record.unidades_caixa_nivel_1 > 0
              ? `${record.unidades_caixa_nivel_1}`
              : '-'
          }
        />
        <FunctionField
          label="Preço Venda Caixa (Nível 1)"
          render={(record) =>
            record.unidades_caixa_nivel_1 > 0
              ? `${formatMoeda(
                  record.preco_venda *
                    record.unidades_caixa_nivel_1 *
                    fatorPesoMedio(record),
                )}`
              : '-'
          }
        />
        <FunctionField
          label="Código Caixeta (Nível 2)"
          render={(record) =>
            record.codigo_caixa_nivel_2 ? `${record.codigo_caixa_nivel_2}` : '-'
          }
        />
        <FunctionField
          label="Unidades Caixeta (Nível 2)"
          render={(record) =>
            record.unidades_caixa_nivel_2 > 0
              ? `${record.unidades_caixa_nivel_2}`
              : '-'
          }
        />
        <FunctionField
          label="Preço Venda Caixeta (Nível 2)"
          render={(record) =>
            record.unidades_caixa_nivel_2 > 0
              ? `${formatMoeda(
                  record.preco_venda *
                    record.unidades_caixa_nivel_1 *
                    record.unidades_caixa_nivel_2 *
                    fatorPesoMedio(record),
                )}`
              : '-'
          }
        />
        <FunctionField
          label="Código Caixa-Mãe (Nível 3)"
          render={(record) =>
            record.codigo_caixa_nivel_3 ? `${record.codigo_caixa_nivel_3}` : '-'
          }
        />
        <FunctionField
          label="Unidades Caixa-Mãe (Nível 3)"
          render={(record) =>
            record.unidades_caixa_nivel_3 > 0
              ? `${record.unidades_caixa_nivel_3}`
              : '-'
          }
        />
        <FunctionField
          label="Preço Venda Caixa-Mãe (Nível 3)"
          render={(record) =>
            record.unidades_caixa_nivel_3 > 0
              ? `${formatMoeda(
                  record.preco_venda *
                    record.unidades_caixa_nivel_1 *
                    record.unidades_caixa_nivel_2 *
                    record.unidades_caixa_nivel_3 *
                    fatorPesoMedio(record),
                )}`
              : '-'
          }
        />
      </Tab>
      <Tab label="Locais">
        <ReferenceManyField
          addLabel={false}
          reference="locais_produtos"
          target="produto_id"
          pagination={false}
        >
          <Datagrid isDelete={false}>
            <TextField source="nome" label="Descrição" sortable={false} />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Fornecedores">
        <ReferenceManyField
          addLabel={false}
          reference="fornecedores_produtos"
          target="produto_id"
          pagination={false}
        >
          <Datagrid isDelete={false}>
            <TextField source="nome" label="Nome" sortable={false} />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Impostos">
        <ReferenceManyField
          addLabel={false}
          reference="dados_produto_imposto"
          target="produto_id"
          pagination={false}
        >
          <Datagrid isDelete={false}>
            <NumberField
              source="ipi"
              locales="pt-BR"
              options={{
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                currency: 'BRL',
              }}
              label="IPI (%)"
            />
            <NumberField
              source="pis"
              locales="pt-BR"
              options={{
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                currency: 'BRL',
              }}
              label="PIS (%)"
            />
            <NumberField
              source="cofins"
              locales="pt-BR"
              options={{
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                currency: 'BRL',
              }}
              label="COFINS (%)"
            />
            <NumberField
              source="icms_ai"
              locales="pt-BR"
              options={{
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                currency: 'BRL',
              }}
              label="ICMS AI (%)"
            />
            <NumberField
              source="icms_int"
              locales="pt-BR"
              options={{
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                currency: 'BRL',
              }}
              label="ICMS INT (%)"
            />
            <NumberField
              source="rbc"
              locales="pt-BR"
              options={{
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                currency: 'BRL',
              }}
              label="RBC (%)"
            />
            <NumberField
              source="df"
              locales="pt-BR"
              options={{
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                currency: 'BRL',
              }}
              label="DF (%)"
            />
            <TextField source="ncm" label="NCM" />
            <TextField source="cst" label="CST ICMS ENTRADA E SAÍDA" />
            <TextField source="cfop" label="CFOP" />

            <TextField source="cst_pis_entrada" label="CST PIS ENTRADA" />
            <TextField source="cst_pis_saida" label="CST PIS SAÍDA" />
            <TextField source="cst_cofins_entrada" label="CST COFINS ENTRADA" />
            <TextField source="cst_cofins_saida" label="CST COFINS SAÍDA" />
            <TextField source="cest" label="CEST" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Balança">
        <ReferenceManyField
          addLabel={false}
          reference="dados_produto_balanca"
          target="produto_id"
          pagination={false}
        >
          <Datagrid isDelete={false}>
            <TextField source="d1" label="Descrição reduzida" />
            <TextField source="dias_validade" label="Dias Validade" />
            <TextField source="taraobj.valor" label="Tara (g)" />
            {/* <ReferenceField
              label="Tara"
              source="tara_id"
              reference="taras_balanca"
              sortable={false}
            >
              <TextField source="valor" label="Tara (g)" />
            </ReferenceField> */}
            <BooleanField
              source="exibir_dias_validade"
              label="Exibir dias de validade"
            />
            <BooleanField
              source="exibir_data_embalagem"
              label="Exibir data validade"
            />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Avarias">
        <ReferenceManyField
          addLabel={false}
          reference="avarias"
          target="produto_id"
          pagination={<Pagination />}
        >
          <Datagrid isDelete>
            <TextField source="unidades" label="Unidades" />
            <TextField source="peso" label="Peso (Kg)" />
            <DateField source="data" label="Data" />
          </Datagrid>
        </ReferenceManyField>

        <FabButtonNewResource
          pathname="/avarias/create"
          search={(record) =>
            `?produto_id=${record.id}&unidades=${showUnidades(
              record.unidade.tipo,
            )}&peso=${showPeso(record.unidade.tipo)}${
              showPesoMedio(record.unidade.tipo) === 1
                ? `&pm=${record.peso_medio}`
                : ''
            }`
          }
        />
      </Tab>
      <Tab label="Correções no estoque">
        <ReferenceManyField
          addLabel={false}
          reference="correcoes_estoque"
          target="produto_id"
          pagination={<Pagination />}
        >
          <Datagrid isDelete>
            <TextField source="unidades" label="Unidades" />
            <TextField source="peso" label="Peso (Kg)" />
            <DateField source="data" label="Data" />
            <ReferenceField
              label="Usuário"
              source="user_id"
              reference="users"
              sortable={false}
              link={false}
            >
              <TextInputLala source="nome" />
            </ReferenceField>
          </Datagrid>
        </ReferenceManyField>
        <FabButtonNewResource
          pathname="/correcoes_estoque/create"
          search={(record) =>
            `?produto_id=${record.id}&unidades=${showUnidades(
              record.unidade.tipo,
            )}&peso=${showPeso(record.unidade.tipo)}${
              showPesoMedio(record.unidade.tipo) === 1
                ? `&pm=${record.peso_medio}`
                : ''
            }`
          }
        />
      </Tab>
      <Tab label="Custos">
        <ReferenceManyField
          addLabel={false}
          reference="custos_produtos"
          target="produto_id"
          pagination={false}
        >
          <Datagrid isDelete={false}>
            <TextField source="nome" label="Descrição" sortable={false} />
            <NumberField
              source="preco"
              locales="pt-BR"
              options={{
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: 'currency',
                currency: 'BRL',
              }}
              label="Preço Unitário"
            />
            <NumberField
              source="peso"
              options={{
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }}
              label="Qtde"
            />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </ShowTemplate>
);

export default Show;
