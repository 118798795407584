import React from 'react';
import { Filter as FilterAdmin, TextInput, SelectInput } from 'react-admin';

import {
  CnpjInput,
  ChaveAcessoNfeInput,
  IntegerInput,
  PeriodoInput,
  PrecoInput,
} from '../../Components/InputsReactAdmin';
import {
  EVENTO_MANIFESTACAO_NFE_CIENCIA_DA_OPERACAO,
  EVENTO_MANIFESTACAO_NFE_CONFIRMACAO_DA_OPERACAO,
  EVENTO_MANIFESTACAO_NFE_DESCONHECIMENTO_DA_OPERACAO,
  EVENTO_MANIFESTACAO_NFE_REGISTRO_DA_OPERACAO_NÃO_FINALIZADA,
} from '../../utils';

const choices = [
  {
    id: EVENTO_MANIFESTACAO_NFE_CIENCIA_DA_OPERACAO,
    name: 'Ciência da Emissão',
  },
  {
    id: EVENTO_MANIFESTACAO_NFE_CONFIRMACAO_DA_OPERACAO,
    name: 'Confirmação da Operação',
  },
  {
    id: EVENTO_MANIFESTACAO_NFE_DESCONHECIMENTO_DA_OPERACAO,
    name: 'Desconhecimento da Operação',
  },
  {
    id: EVENTO_MANIFESTACAO_NFE_REGISTRO_DA_OPERACAO_NÃO_FINALIZADA,
    name: 'Operação não Finalizada',
  },
];

const Filter = (props) => (
  <FilterAdmin {...props}>
    <TextInput label="Razão Social do Emitente" source="emitRazao" />
    <IntegerInput label="NSU" source="nsu" />
    <ChaveAcessoNfeInput label="Chave de Acesso" source="chave" />
    <CnpjInput label="CNPJ do Emitente" source="emitCnpj" />
    <PeriodoInput
      source="periodo"
      label="Data de Emissão"
      defaultValue={{
        start: new Date(),
        end: new Date(),
      }}
    />
    <PrecoInput source="vNF" label="Valor da NFE" fixedDecimalScale={false} />
    {/*<SelectInput
      source="tipo_manifestacao"
      choices={choices}
      label="Tipo de Manifestação"
      allowEmpty={false}
    />*/}
  </FilterAdmin>
);

export default Filter;
