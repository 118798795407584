import { Store } from '@material-ui/icons';

import List from './List3';
import Show from './Show';

export default {
  list: List,
  show: Show,
  icon: Store,
};
