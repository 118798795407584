import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import { Box, requirePropFactory } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'notistack';

import {
  TextInput,
  IntegerInput,
  SearchProduto,
  PesoInput,
} from '../../Components';
import {
  getListAllAPI,
  api,
  getUserId,
  createAPI,
  updateAPI,
} from '../../services';
import {
  writeCodigoBarrasCGP,
  formatPeso,
  round10,
  STATUS_ATIVO,
  SECONDARY_COLOR,
} from '../../utils';
import DialogoDeletarPesagem from './DialogoDeletarPesagem';
import DialogoPesagem from './DialogoPesagem';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: SECONDARY_COLOR,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState(-1);
  const [carregando, setCarregando] = useState(false);
  const [produto, setProduto] = useState(null);
  const [numeroCaixas, setNumeroCaixas] = useState(0);
  const [taraUnitaria, setTaraUnitaria] = useState(0);
  const [pesagens, setPesagens] = useState([]);
  const [searchProduto, setSearchProduto] = useState('');
  const refDialogoPesagem = useRef(null);
  const refDialogoDeletarPesagem = useRef(null);

  const classes = useStyles();

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  const handleCloseDialogLocal = () => {
    setOpen(false);
    handleClose();
  };

  async function reiniciar() {
    setId(-1);
    setNumeroCaixas(0);
    setPesagens([]);
    setTaraUnitaria(0);
    setProduto(null);
    setSearchProduto('');
    setCarregando(false);
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'conferencias_grandes_pesagens',
        ['id', 'asc'],
        { id: [id] },
        ['produto'],
      );
      if (data.data.length > 0) {
        setProduto(data.data[0].produto);
        setNumeroCaixas(data.data[0].numero_caixas);
        setTaraUnitaria(round10(data.data[0].tara_unitaria * 1000));
        setPesagens(parsePesagens(data.data[0].pesagens));
        setCarregando(false);
      } else {
        throw 'Esta Conferência de Grandes Pesagens não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  function getValoresComputados() {
    const taraTotal = (taraUnitaria / 1000) * numeroCaixas;
    let pesoBrutoTotal = 0;
    for (let i = 0; i < pesagens.length; i++) {
      pesoBrutoTotal += pesagens[i];
    }
    const pesoLiquidoTotal = pesoBrutoTotal - taraTotal;

    return {
      taraTotal,
      pesoBrutoTotal,
      pesoLiquidoTotal,
    };
  }

  const {
    pesoBrutoTotal,
    pesoLiquidoTotal,
    taraTotal,
  } = getValoresComputados();

  function toStringPesagens(pesagens) {
    return pesagens.map((value) => round10(value, -3).toFixed(3)).join(';');
  }

  function parsePesagens(pesagens) {
    return pesagens.split(';').map((value) => round10(parseFloat(value), -3));
  }

  async function handleSalvar() {
    if (pesagens.length > 0) {
      if (pesoLiquidoTotal > 0) {
        setCarregando(true);
        try {
          if (id <= 0) {
            const {
              pesoBrutoTotal,
              pesoLiquidoTotal,
              taraTotal,
            } = getValoresComputados();

            await createAPI('conferencias_grandes_pesagens', {
              produto_id: produto ? produto.id : null,
              responsavel_id: getUserId(),
              tara_unitaria: taraUnitaria / 1000,
              tara_total: taraTotal,
              numero_caixas: numeroCaixas,
              peso_bruto_total: pesoBrutoTotal,
              peso_liquido_total: pesoLiquidoTotal,
              pesagens: toStringPesagens(pesagens),
              codigo: 'codigo',
            });
            setCarregando(false);
            enqueueSnackbar(
              'Conferência de Grandes Pesagens criada com sucesso!',
              {
                variant: 'success',
              },
            );
          } else {
            await updateAPI('conferencias_grandes_pesagens', id, {
              produto_id: produto ? produto.id : null,
              tara_unitaria: taraUnitaria / 1000,
              tara_total: taraTotal,
              numero_caixas: numeroCaixas,
              peso_bruto_total: pesoBrutoTotal,
              peso_liquido_total: pesoLiquidoTotal,
              pesagens: toStringPesagens(pesagens),
            });
            setCarregando(false);
            enqueueSnackbar(
              'Conferência de Grandes Pesagens atualizada com sucesso!',
              {
                variant: 'success',
              },
            );
          }

          handleCloseDialog();
        } catch (erros) {
          enqueueSnackbar(`${erros}`, {
            variant: 'error',
          });
          handleCloseDialog();
        }
      } else {
        enqueueSnackbar(
          `O total de tara está maior que o peso bruto da mercadoria!!!`,
          {
            variant: 'error',
          },
        );
      }
    } else {
      enqueueSnackbar(`Deve haver ao menos uma pesagem!!!`, {
        variant: 'error',
      });
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      reiniciar();
      setOpen(true);
    },
    handleEdit(id) {
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
    },
  }));

  async function searchHandleProdutos(codigo, id) {
    const produtoData = await getListAllAPI(
      'produtos',
      ['id', 'asc'],
      id
        ? { id }
        : { codigo, is_kit: false, is_caixa: false, status: STATUS_ATIVO },
      [],
    );
    if (produtoData.data.length > 0) {
      const produto = produtoData.data[0];
      setProduto(produto);
    }
    setSearchProduto('');
  }

  function validateProduto() {
    let error = '';
    if (!produto) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateTaraUnitaria() {
    let error = '';
    if (taraUnitaria < 0 || Number.isNaN(taraUnitaria)) {
      error = 'Valor inválido';
    }
    return error;
  }

  function validateNumeroCaixas() {
    let error = '';
    if (numeroCaixas < 0 || Number.isNaN(numeroCaixas)) {
      error = 'Valor inválido';
    }
    return error;
  }

  function getErros() {
    const errosOld = ['', '', ''];
    errosOld[0] = validateProduto();
    errosOld[1] = validateTaraUnitaria();
    errosOld[2] = validateNumeroCaixas();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  function criarPesagem() {
    if (refDialogoPesagem.current) {
      refDialogoPesagem.current.handleCreate();
    }
  }

  function editarPesagem(index, value) {
    if (refDialogoPesagem.current) {
      refDialogoPesagem.current.handleEdit(index, value);
    }
  }

  function deletarPesagem(index, value) {
    if (refDialogoDeletarPesagem.current) {
      refDialogoDeletarPesagem.current.handleOpen(index, value);
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth="sm"
        //fullScreen
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialogLocal}
          style={{
            backgroundColor: '#009FD4',
            color: 'white',
          }}
        >
          {id > 0
            ? 'Editar Conferência de Grandes Pesagens'
            : 'Nova Conferência de Grandes Pesagens'}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div
              style={{
                marginTop: '20px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              {id < 0 ? (
                <SearchProduto
                  label="Pesquisar produto base"
                  value={searchProduto}
                  onChange={(e) => setSearchProduto(e)}
                  fullwidth
                  searchHandle={searchHandleProdutos}
                  handleKey={() => {}}
                  resource="produtos/search/autocomplete_grandes_pesagens"
                />
              ) : null}

              {produto ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                    }}
                  >
                    <div>
                      <span
                        style={{
                          fontWeight: 'bold',
                          marginRight: '10px',
                        }}
                      >
                        Produto:
                      </span>
                      <span>{`${produto.nome}`}</span>
                    </div>
                    {id > 0 ? (
                      <div>
                        <span
                          style={{
                            fontWeight: 'bold',
                            marginRight: '10px',
                          }}
                        >
                          Código:
                        </span>
                        <span>{`${writeCodigoBarrasCGP(id)}`}</span>
                      </div>
                    ) : null}
                    <div>
                      <span
                        style={{
                          fontWeight: 'bold',
                          marginRight: '10px',
                        }}
                      >
                        Tara Total:
                      </span>
                      <span>{`${formatPeso(taraTotal)} Kg`}</span>
                    </div>
                    <div>
                      <span
                        style={{
                          fontWeight: 'bold',
                          marginRight: '10px',
                        }}
                      >
                        Peso Bruto Total:
                      </span>
                      <span>{`${formatPeso(pesoBrutoTotal)} Kg`}</span>
                    </div>
                    <div>
                      <span
                        style={{
                          fontWeight: 'bold',
                          marginRight: '10px',
                        }}
                      >
                        Peso Líquido Total:
                      </span>
                      <span>{`${formatPeso(pesoLiquidoTotal)} Kg`}</span>
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                    }}
                  >
                    <IntegerInput
                      handleEnter={() => {}}
                      label="Tara Unitária (gramas)"
                      handleKey={() => {}}
                      value={
                        Number.isNaN(taraUnitaria) ? '' : String(taraUnitaria)
                      }
                      onChange={(value) =>
                        setTaraUnitaria(parseInt(value.target.value, 10))
                      }
                      error={erros[1] !== ''}
                      helperText={erros[1]}
                      fullWidth
                    />

                    <IntegerInput
                      handleEnter={() => {}}
                      label="Nº de Caixas"
                      handleKey={() => {}}
                      value={
                        Number.isNaN(numeroCaixas) ? '' : String(numeroCaixas)
                      }
                      onChange={(value) =>
                        setNumeroCaixas(parseInt(value.target.value, 10))
                      }
                      error={erros[2] !== ''}
                      helperText={erros[2]}
                      fullWidth
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: '10px 0',
                      }}
                    >
                      <h1>Pesagens</h1>
                      <div>
                        <Button
                          onClick={criarPesagem}
                          color="secondary"
                          variant="contained"
                          tabindex="-1"
                        >
                          Novo
                        </Button>
                      </div>
                    </div>
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Ordem</StyledTableCell>
                            <StyledTableCell>Peso</StyledTableCell>
                            <StyledTableCell align="right">
                              Ações
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {pesagens.map((row, ind) => (
                            <StyledTableRow key={`${ind}-row`}>
                              <StyledTableCell>{ind + 1}</StyledTableCell>
                              <StyledTableCell>
                                {formatPeso(row)}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <Tooltip
                                  title="Editar"
                                  placement="bottom"
                                  aria-label="add2"
                                >
                                  <IconButton
                                    aria-label="editar"
                                    size="small"
                                    onClick={() => editarPesagem(ind, row)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  title="Editar"
                                  placement="bottom"
                                  aria-label="add2"
                                >
                                  <IconButton
                                    aria-label="editar"
                                    size="small"
                                    onClick={() => deletarPesagem(ind, row)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </>
              ) : null}
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '32px 36px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={handleCloseDialogLocal}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
            variant="outlined"
            //color="primary"
            style={{
              backgroundColor: '#009FD4',
            }}
          >
            Cancelar
          </Button>
          <Box>
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                variant="contained"
                //color="primary"
                disabled={carregando || erroExistente}
                style={{
                  backgroundColor: '#009FD4',
                }}
              >
                Salvar
              </Button>
              {carregando && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Box>
        </DialogActions>
      </Dialog>
      <DialogoPesagem
        ref={refDialogoPesagem}
        handleSave={(id, peso) => {
          const copia = [...pesagens];
          if (id >= 0) {
            copia[id] = peso;
          } else {
            copia.push(peso);
          }
          setPesagens(copia);
        }}
      />
      <DialogoDeletarPesagem
        ref={refDialogoDeletarPesagem}
        handleDelete={(id) => {
          setPesagens(pesagens.filter((item, index) => index !== id));
        }}
      />
    </div>
  );
});

export default DialogoCategoriaTransacao;
