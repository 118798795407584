import React, { useState, useEffect } from 'react';

import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import SearchIcon from '@material-ui/icons/Search';

import useDebounce from '../../../hooks/useDebounce';

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: '2px 4px',
    padding: '0px 0px 0px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    borderRadius: '50px',
  },
  input: {
    marginLeft: theme.spacing(1),
    // marginBottom: '2px',
    flex: 1,
    borderBottom: (props) => `2px solid ${props.color}`,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  containerIcon: {},
  icon: {
    width: '50px',
    height: '50px',
  },
}));

const MODO_FECHADO = 0;
const MODO_ABERTO = 1;

export default function SearchMobillInput({
  color = 'blue',
  placeholder = 'Pesquise por descrição, categoria ou valor',
  onChange = () => {},
}) {
  const [modo, setModo] = useState(MODO_FECHADO);
  const classes = useStyles({ color });
  const [text, setText] = useState('');
  const [textDebounce] = useDebounce(text, 1000);

  useEffect(() => {
    onChange(textDebounce);
  }, [textDebounce]);

  const handleModoAberto = () => {
    setModo(MODO_ABERTO);
  };

  const handleModoFechado = () => {
    if (!text) {
      setModo(MODO_FECHADO);
    }
  };

  return (
    <>
      {modo === MODO_ABERTO ? (
        <Zoom
          in={modo === MODO_ABERTO}
          timeout={{ enter: 1000, exit: 1000 }}
          mountOnEnter
          unmountOnExit
        >
          <Box
            component="div"
            boxShadow={2}
            className={classes.root}
            style={{ backgroundColor: 'white' }}
          >
            <InputBase
              className={classes.input}
              placeholder={placeholder}
              inputProps={{ 'aria-label': 'search google maps' }}
              onBlur={handleModoFechado}
              autoFocus
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <IconButton
              className={classes.iconButton}
              aria-label="search"
              onClick={handleModoFechado}
            >
              <SearchIcon />
            </IconButton>
          </Box>
        </Zoom>
      ) : (
        <Tooltip title="Pesquisar" placement="top" aria-label="add2">
          <Box
            boxShadow={2}
            borderRadius="50px"
            display="flex"
            alignItems="center"
            width="44px"
            style={{ backgroundColor: 'white', height: '48px', width: '48px' }}
          >
            <IconButton
              className={classes.iconButton}
              aria-label="search"
              onClick={handleModoAberto}
              style={{ height: '48px', width: '48px' }}
            >
              <SearchIcon />
            </IconButton>
          </Box>
        </Tooltip>
      )}
    </>
  );
}
