import {
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  POR_UNIDADE,
} from '../../utils';

function renameItensUIDD(itens) {
  const arrayNew = itens.slice();
  for (let i = 0; i < itens.length; i += 1) {
    arrayNew[i].uidd = `${arrayNew[i].produto.nome}${i}`;
  }
  return arrayNew;
}

function isEstoqueAcabado(item) {
  switch (item.produto.unidade.tipo) {
    case POR_PESO_FIXO:
      return item.unidadesFinal > 0;
    case POR_PESO_VARIAVEL:
      return item.unidadesFinal > 0 && item.pesoFinal > 0;
    case POR_PESO_VARIAVEL_SEM_UNIDADE:
      return item.pesoFinal > 0;
    case POR_UNIDADE:
      return item.unidadesFinal > 0;
    default:
      return false;
  }
}

const reducer = (state, action) => {
  let arrayNew = state.itens.slice();
  switch (action.type) {
    case 'ADD_ITEM': {
      const item = state.itensEstoque.find(
        (item) => item.produto.id === action.item.produto.id,
      );

      // const itensEstoque = [...state.itensEstoque];

      item.unidadesFinal -= action.item.unidades;
      item.pesoFinal -= action.item.peso;

      // item.unidadesFinal -= action.item.unidades;

      item.usado = !isEstoqueAcabado(item);

      return {
        ...state,
        itens: [...arrayNew, action.item],
      };
    }

    case 'REMOVE_ITEM': {
      for (let i = 0; i < action.indices.length; i++) {
        const itemNormal = state.itens[action.indices[i]];
        const item = state.itensEstoque.find(
          (item) => item.produto.id === itemNormal.produto.id,
        );

        item.unidadesFinal += itemNormal.unidades;
        item.pesoFinal += itemNormal.peso;

        item.usado = !isEstoqueAcabado(item);
      }

      for (let i = 0; i < action.indices.length; i += 1) {
        arrayNew = arrayNew.filter((obj) => obj.uidd !== action.indices[i]);
      }
      return {
        ...state,
        itens: [...renameItensUIDD(arrayNew)],
      };
    }

    case 'UPDATE_ITEMS':
      return {
        ...state,
        itens: [...renameItensUIDD(action.itens)],
      };

    case 'RESETAR_TUDO':
      return {
        ...state,
        vendaId: -1,
        itens: [],
        cliente: null,
        itensEstoque: [],
      };

    case 'PREENCHER_TUDO':
      return {
        ...state,
        vendaId: action.vendaId,
        itens: [...renameItensUIDD(action.itens)],
        cliente: action.cliente,
        itensEstoque: [...renameItensUIDD(action.itensEstoque)].map((item) => ({
          ...item,
          usado: false,
          unidadesFinal: item.unidades,
          pesoFinal: item.peso,
          unidadesCaixaFinal: item.unidadesCaixa,
        })),
      };
    case 'CARREGAR_RESTANTE':
      return {
        ...state,
        itens: [...renameItensUIDD(state.itensEstoque)].map((item) => ({
          produto: item.produto,
          peso: item.peso,
          unidades: item.unidades,
          uidd: -1,
          idIndicadorChange: -1,
          unidadesCaixa: 0,
          pesoCaixa: 0,
          unidadesCaixaAparente: 0,
          nivelCaixaria: 0,
        })),
        itensEstoque: [...renameItensUIDD(state.itensEstoque)].map((item) => ({
          ...item,
          usado: true,
          unidadesFinal: 0,
          pesoFinal: 0,
          unidadesCaixaFinal: 0,
        })),
      };
    default:
      return state;
  }
};

export default reducer;
