import React, { useEffect, useState, useRef } from 'react';
import { useVersion, Title } from 'react-admin';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { getListAllAPI, getOneAPI, api } from '../../../services';
import {
  STATUS_ATIVO,
  TURNO_MANHA,
  TURNO_NOITE,
  TURNO_12_HORAS,
  TIPO_FERIADO_DIA_COMUM,
  STATUS_LOJA_FECHADA,
  STATUS_LOJA_ABERTA,
  TIPO_FERIADO_DATA_COMEMORATIVA,
  TIPO_FERIADO_FERIADO,
  formatDateHHmm,
  formatMoeda,
  TIPO_HORARIO_DOMINGO,
} from '../../../utils';
import MobillsInput from '../RelatorioVendaMensal/DateInputMobills';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}))(Tooltip);

const StyledTableCell = withStyles((theme) => ({
  head: {
    //backgroundColor: 'green',
    color: 'black',
    //padding: '8px',
    //whiteSpace: 'nowrap',
    //writingMode: 'sideways-lr',
    //textOrientation: 'sideways',
    //width: 'min-content',
    width: '1px',
    whiteSpace: 'nowrap',
    //textAlign: 'center',
    //display: 'flex',
    //justifyContent: 'center',
    padding: '5px',
    border: '1px solid black',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    padding: '2px',
    border: '1px solid black',
    textAlign: 'center',
    verticalAlign: 'middle',
    //textAlign: 'center',
  },
}))(TableCell);

const horasMes = 220;
const porcentagemHoraExtra = 75;

const diasSemana = {
  0: 'domingo',
  1: 'segunda-feira',
  2: 'terça-feira',
  3: 'quarta-feira',
  4: 'quinta-feira',
  5: 'sexta-feira',
  6: 'sábado',
};

const meses = {
  0: 'janeiro',
  1: 'fevereiro',
  2: 'março',
  3: 'abril',
  4: 'maio',
  5: 'junho',
  6: 'julho',
  7: 'agosto',
  8: 'setembro',
  9: 'outubro',
  10: 'novembro',
  11: 'dezembro',
};

const GL1 = 'GL1';
const GL2 = 'GL2';
const GL3 = 'GL3';
const C1 = 'C1';

function formataNumero(numero) {
  if (numero < 10) return `0${numero}`;
  return `${numero}`;
}

function formatData(data) {
  const data1 = new Date(data);
  const message = `${diasSemana[data1.getDay()]}, ${
    meses[data1.getMonth()]
  } ${formataNumero(data1.getDate())}, ${data1.getFullYear()}`;
  return message;
}

function diferencaEmMinutos(data1, data2) {
  if (data1 && data2) {
    const data1Obj = new Date(data1);
    const data2Obj = new Date(data2);

    const time = data2Obj.getTime() - data1Obj.getTime();

    return time / (1000 * 60);
  }
  return 0;
}

function converterHorasDecimais(minutos) {
  return minutos / 60;
}

function formataHorasExtras(min) {
  const horas = parseInt(min / 60, 10);
  const minutos = parseInt(min % 60, 10);
  return `${horas}h ${minutos}min`;
}

function getHorasIdeal(
  turno,
  isSS = false,
  isAbrirLoja = true,
  isDomingo = false,
) {
  switch (turno) {
    case TURNO_MANHA: {
      if (!isDomingo) {
        if (!isSS) return 484;
        return 440;
      }
      return 360;
    }
    case TURNO_NOITE: {
      if (!isDomingo) {
        if (!isSS) return 484;
        return 440;
      }
      return 360;
    }
    case TURNO_12_HORAS: {
      if (!isDomingo) {
        if (isAbrirLoja) {
          return 600;
        }
        return 660;
      }
      return 540;
    }
    default:
      return 0;
  }
}

function procuraGerentoes(codinome) {
  let isGl1 = false;
  let isGl2 = false;
  let isGl3 = false;
  let isC1 = false;

  switch (codinome) {
    case GL1:
      isGl1 = true;
      break;
    case GL2:
      isGl2 = true;
      break;
    case GL3:
      isGl3 = true;
      break;
    case C1:
      isC1 = true;
      break;
  }

  if (isGl1) return GL1;
  if (isGl2) return GL2;
  if (isGl3) return GL3;
  if (isC1) return C1;
  return null;
}

function formatarResumo(
  dados,
  valor_domingo_trabalhado,
  valor_feriado_trabalhado,
) {
  const { dias, pontos, colaboradores, grupos, funcoes, codinomes } = dados;
  const dadosFormatados = [];
  const diasFormatados = {};
  const codinomesFormatados = {};

  for (let i = 0; i < dias.length; i++) {
    const dia = dias[i];
    diasFormatados[dia.id] = {
      descricao: formatData(dia.data),
      ...dia,
    };
    dias[i].descricao = formatData(dia.data);
  }

  const funcoesFormatados = {};
  for (let i = 0; i < funcoes.length; i++) {
    const funcao = funcoes[i];
    funcoesFormatados[funcao.id] = funcao;
  }

  const gruposFormatados = {};
  for (let i = 0; i < grupos.length; i++) {
    const grupo = grupos[i];
    gruposFormatados[grupo.id] = grupo;
  }

  for (let i = 0; i < codinomes.length; i++) {
    const codinome = codinomes[i];
    codinomesFormatados[codinome.id] = codinome;
  }

  for (let i = 0; i < colaboradores.length; i++) {
    const colaborador = colaboradores[i];
    const grupo = gruposFormatados[colaborador.grupo_colaborador_id];

    const pontosColaborador = pontos
      .filter((item) => item.colaborador_id === colaborador.id)
      .map((item) => {
        const grupo = gruposFormatados[item.grupo_colaborador_id];

        return {
          dados_dia_id: item.dados_dia_id,
          tipoJornada: grupo.nome.trim() === 'SS' ? 'SS' : 'ESCALADO',
        };
      });

    const pontosFormatados = {};
    for (let i = 0; i < pontosColaborador.length; i++) {
      const ponto = pontosColaborador[i];
      pontosFormatados[ponto.dados_dia_id] = ponto;
    }

    let domingosTrabalhado = 0;
    let feriadosTrabalhados = 0;

    for (let i = 0; i < dias.length; i++) {
      const ponto = pontosFormatados[dias[i].id];

      if (ponto) {
        if (dias[i].dia_semana === 0) {
          domingosTrabalhado++;
        }

        if (dias[i].tipo === TIPO_FERIADO_FERIADO) {
          feriadosTrabalhados++;
        }
      }
    }

    dadosFormatados.push({
      colaborador: colaborador.nome,
      tipoJornada: grupo.nome.trim() === 'SS' ? 'SS' : 'ESCALADO',
      domingosTrabalhado,
      feriadosTrabalhados,
      totalDomingos: domingosTrabalhado * valor_domingo_trabalhado,
      totalFeriados: feriadosTrabalhados * valor_feriado_trabalhado,
    });
  }
  return dadosFormatados;
}

function HorasExtras() {
  const version = useVersion();
  const [data, setData] = useState({
    mes: new Date().getMonth(),
    ano: new Date().getFullYear(),
  });
  const [dados, setDados] = useState(null);
  const [valorDomingosTrabalhados, setValorDomingosTrabalhados] = useState(0);
  const [valorFeriadosTrabalhados, setValorFeriadosTrabalhados] = useState(0);

  function handleData({ ano, mes }) {
    setData({
      mes,
      ano,
    });
  }

  async function getDadosResumo(mes, ano) {
    const dados = await api.get(`/resumo_pontos_do_mes/${mes}/${ano}`);
    return dados;
  }

  async function setDadosBrutos({ mes, ano }) {
    const dados = await getDadosResumo(mes, ano);
    const response = await api.get('/config/1');
    const data = response.data;
    const { valor_domingo_trabalhado, valor_feriado_trabalhado } = data;
    const dadosFormatados = formatarResumo(
      dados.data,
      valor_domingo_trabalhado,
      valor_feriado_trabalhado,
    );
    setDados(dadosFormatados);
  }

  useEffect(() => {
    setDadosBrutos(data);
  }, [version, data]);

  return (
    <>
      <div>
        <div
          style={{
            fontSize: '25px',
            fontWeight: 'bold',
            marginTop: '35px',
            marginBottom: '30px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h1
            style={{
              fontSize: '25px',
            }}
          >
            HORAS EXTRAS
          </h1>
        </div>
        <Title title="HORAS EXTRAS" />
        <div
          style={{
            margin: '10px 0 20px',
          }}
        >
          <MobillsInput handleData={handleData} />
        </div>
        {dados ? (
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{
                    backgroundColor: 'white',
                  }}
                >
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    NOME DO COLABORADOR
                  </span>
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    backgroundColor: 'white',
                  }}
                >
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    TIPO DE JORNADA
                  </span>
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    backgroundColor: 'white',
                  }}
                >
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    DOMINGOS TRABALHADOS
                  </span>
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    backgroundColor: 'white',
                  }}
                >
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    TOTAL DOMINGOS (R$)
                  </span>
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    backgroundColor: 'white',
                  }}
                >
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    FERIADOS TRABALHADOS
                  </span>
                </StyledTableCell>

                <StyledTableCell
                  style={{
                    backgroundColor: 'white',
                  }}
                >
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    TOTAL FERIADOS (R$)
                  </span>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dados.map((colaborador) => (
                <TableRow>
                  <StyledTableCell
                    style={{
                      textAlign: 'start',
                    }}
                  >
                    {colaborador.colaborador}
                  </StyledTableCell>
                  <StyledTableCell>{colaborador.tipoJornada}</StyledTableCell>
                  <StyledTableCell>
                    {colaborador.domingosTrabalhado}
                  </StyledTableCell>
                  <StyledTableCell>
                    {formatMoeda(colaborador.totalDomingos)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {colaborador.feriadosTrabalhados}
                  </StyledTableCell>
                  <StyledTableCell>
                    {formatMoeda(colaborador.totalFeriados)}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <div>Buscando os Dados...</div>
        )}
      </div>
    </>
  );
}

export default HorasExtras;
