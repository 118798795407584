import React from 'react';

import { makeStyles } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

import { AutoCompleteLocalCustomizado } from '../../../Components';

const useStyles3 = makeStyles({
  chip: {
    borderColor: (props) => props.color,
    backgroundColor: 'white',
    cursor: 'default',
    color: '#8e8e8e',
    '&:hover': {
      color: (props) => props.color,
      backgroundColor: 'white',
    },
  },
  deleteIcon: {
    color: (props) => props.color,
    cursor: 'pointer',
    '&:hover': {
      color: (props) => props.color,
    },
  },
});

const ChipLocal = ({ option, injetado, campo, label }) => {
  const classes2 = useStyles3({ color: 'red' });
  const { onDelete, ...rest } = injetado;
  return (
    <>
      {option.id < 0 ? (
        <Chip
          style={{
            color: '#8e8e8e',
            borderColor: '#8e8e8e',
          }}
          variant="outlined"
          // label={option[campo]}
          label={label}
          {...rest}
        />
      ) : (
        <Chip
          classes={{ outlined: classes2.chip, deleteIcon: classes2.deleteIcon }}
          variant="outlined"
          // label={option[campo]}
          label={label}
          {...rest}
          onDelete={onDelete}
        />
      )}
    </>
  );
};

const LocaisInputs = ({
  options,
  campo = 'nome',
  label,
  handleEnter = () => {},
  handleKey = () => {},
  value,
  onChange,
  renderOptionItem = (option, campo) => option[campo],
  limitTags = 2,
}) => {
  const handleOnChange = (value) => {
    if (value.length > 0) {
      if (value[0].id < 0) {
        onChange(value.slice(1));
      } else {
        onChange(value);
      }
    } else {
      onChange(value);
    }
  };

  const renderTag = (option, injetado) => (
    <ChipLocal
      option={option}
      injetado={injetado}
      campo={campo}
      label={renderOptionItem(option, campo)}
    />
  );

  const renderOption = (option, { selected }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {renderOptionItem(option, campo)}
    </div>
  );

  return (
    <AutoCompleteLocalCustomizado
      name={label}
      handleEnter={handleEnter}
      label={label}
      handleKey={handleKey}
      value={value}
      disableClearable={value.length <= 0}
      onChangeLocal={handleOnChange}
      campo={campo}
      options={options}
      variant="outlined"
      renderTag={renderTag}
      renderOption={renderOption}
      limitTags={limitTags}
      style={{ marginTop: 0, marginBottom: 0 }}
    />
  );
};

export default LocaisInputs;
