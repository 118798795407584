import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Typography } from '@material-ui/core';
import moment from 'moment';

const diasDaSemana = [
  'Domingo',
  'Segunda-Feira',
  'Terça-Feira',
  'Quarta-Feira',
  'Quinta-Feira',
  'Sexta-Feira',
  'Sábado',
];

const DataMaximaField = (props) => {
  const record = useRecordContext(props);
  const horario = record
    ? record.record.data_maxima
      ? `${moment(new Date(record.record.data_maxima)).format(
          'DD/MM/YYYY - HH:mm',
        )} (${diasDaSemana[new Date(record.record.data_maxima).getDay()]})`
      : '-'
    : '';

  return (
    <Typography
      variant="subtitle1"
      style={
        {
          //fontWeight: 'bold',
        }
      }
    >
      {horario}
    </Typography>
  );
};

export default DataMaximaField;
