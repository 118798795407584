import React, { useState } from 'react';

import { DateInputBonito } from '../../../../Components';

const PeriodoInput = ({ value, onChange }) => (
  <div style={{ display: 'flex' }}>
    <DateInputBonito
      showTodayButton
      todayLabel="Hoje"
      name="De"
      handleEnter={() => {}}
      label="De"
      handleKey={() => {}}
      value={value.start}
      onChange={(newValue) => onChange({ ...value, start: newValue })}
      format="dd MMMM yyyy"
      fullWidth
      style={{ marginRight: '20px' }}
      maxDate={value.end}
    />
    <DateInputBonito
      showTodayButton
      todayLabel="Hoje"
      name="Para"
      handleEnter={() => {}}
      label="Para"
      handleKey={() => {}}
      value={value.end}
      onChange={(newValue) => onChange({ ...value, end: newValue })}
      format="dd MMMM yyyy"
      fullWidth
      minDate={value.start}
    />
  </div>
);

export default PeriodoInput;
