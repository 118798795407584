import React from 'react';

import { Paper, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { formatMoeda } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '10px',
    backgroundColor: theme.palette.secondary.main,
    marginTop: '15px',
    color: 'white',
    boxSizing: 'border-box',
    opacity: '0.75',
  },
  containerOpaco: {
    padding: '10px',
    backgroundColor: theme.palette.secondary.main,
    marginTop: '15px',
    color: 'white',
    boxSizing: 'border-box',
  },
  containerInterno: {
    marginTop: '10px',
    fontSize: '20px',
  },
}));

const LabelTotalParcelas = ({ total, totalRestante, isOpaco = false }) => {
  const classes = useStyles();

  return (
    <Paper
      className={isOpaco ? classes.containerOpaco : classes.container}
      elevation={3}
    >
      <Box display="flex" flexDirection="column" width="100%" color="white">
        <Box display="flex" justifyContent="space-between">
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            Total Parcelas:
          </div>
          <div>|</div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            Total Restante:
          </div>
        </Box>

        <Paper className={classes.containerInterno} elevation={3}>
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            height="40px"
            color="black"
          >
            <Box display="flex" justifyContent="space-between" width="100%">
              <div
                style={{ flex: 1, display: 'flex', justifyContent: 'center' }}
              >
                {formatMoeda(total)}
              </div>
              <div>|</div>
              <div
                style={{ flex: 1, display: 'flex', justifyContent: 'center' }}
              >
                {formatMoeda(totalRestante)}
              </div>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Paper>
  );
};

export default LabelTotalParcelas;
