import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { TextInput, DialogoConfirmacao } from '../../Components';
import { getListAllAPI, getUserId, api, getUsername } from '../../services';
import { SECONDARY_COLOR, formatMoeda, MALOTE_TROCO_OPTION } from '../../utils';
import DialogoAutorizaTransporte from './DialogoAutorizaTransporte';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: SECONDARY_COLOR,
    color: 'white',
    fontSize: 12,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose, caminho }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [codigo, setCodigo] = useState('');
    const [trocos, setTrocos] = useState([]);

    const [carregando, setCarregando] = useState(false);

    const classes = useStyles({ cor: '#009FD4' });

    const refDialogoConfirmacao = useRef(null);
    const refDialogoAutorizaTransporte = useRef(null);

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    const handleCloseLocal = () => {
      setOpen(false);
    };

    async function getTrocos(trocosIds) {
      try {
        setCarregando(true);
        const data = await getListAllAPI(
          'malote_trocos',
          ['id', 'asc'],
          {
            trocoIds: trocosIds,
            visible: true,
            [caminho ? 'transportador1_id' : 'transportador2_id']: getUserId(),
          },
          [],
        );
        if (data.data.length > 0) {
          setTrocos(data.data.map((item) => formatarTroco(item)));
        } else {
          throw 'Nenhum troco carregado!';
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseLocal();
      } finally {
        setCarregando(false);
      }
    }

    function isHasTroco(codigo) {
      return trocos.some((item) => item.codigo === codigo);
    }

    async function getTroco() {
      try {
        setCarregando(true);
        const codigoFormatado = codigo.trim();
        if (codigoFormatado.length > 0) {
          if (!isHasTroco(codigoFormatado)) {
            const data = await getListAllAPI(
              'malote_trocos',
              ['id', 'asc'],
              {
                id: [readCodigoBarras(codigoFormatado)],
                //visible: true,
                /*[caminho
                  ? 'transportador1_id'
                  : 'transportador2_id']: getUserId(),*/
              },
              [],
            );
            if (data.data.length > 0) {
              const troco = data.data[0];
              if (troco.tipo !== MALOTE_TROCO_OPTION)
                throw 'ESte pacote não é um troco!';
              if (troco.perdido) throw 'Este troco foi para a lixeira!';
              if (!troco.visible) throw 'Este troco já foi utilizado!';
              if (caminho) {
                if (troco.data_confirmacao_transporte_1)
                  throw 'Este troco já foi entregue ao cofre!';
                if (troco.data_confirmacao_transporte_2)
                  throw 'Este troco já saiu do cofre para o checkout!';
                if (!troco.transportador1_id)
                  throw 'Este troco não possui um transportador registrado para chegar até o cofre!';
                if (troco.transportador2_id)
                  throw 'Este troco já possui um transportador para a volta ao checkout!';
              } else {
                if (!troco.data_confirmacao_transporte_1)
                  throw 'Este troco não foi entregue ao cofre!';
                if (troco.data_confirmacao_transporte_2)
                  throw 'Este troco já saiu do cofre para o checkout!';
                if (!troco.transportador1_id)
                  throw 'Este troco não possui um transportador registrado para chegar até o cofre!';
                if (!troco.transportador2_id)
                  throw 'Este troco já possui um transportador para a volta ao checkout!';
              }
              setTrocos([...trocos, formatarTroco(troco)]);
            }
          } else {
            enqueueSnackbar(`Nenhum troco carregado!`, {
              variant: 'error',
            });
          }
        }
        setCodigo('');
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        setCodigo('');
      } finally {
        setCarregando(false);
      }
    }

    async function handleSalvar() {
      if (refDialogoAutorizaTransporte.current) {
        refDialogoAutorizaTransporte.current.handleOpen(getUsername());
      }
    }

    useImperativeHandle(ref, () => ({
      handleOpen(trocosIds = null) {
        if (trocosIds) {
          getTrocos(trocosIds);
        } else {
          setTrocos([]);
        }
        setOpen(true);
        setCodigo('');
      },
    }));

    function validateCodigo() {
      let error = '';
      if (!codigo) error = 'Este campo é obrigatório';
      else if (codigo.length !== 13) {
        error = 'O código deve conter 13 dígitos!';
      }
      return error;
    }

    function getErros() {
      const errosOld = [''];
      errosOld[0] = validateCodigo();
      return errosOld;
    }

    const erros = getErros();

    function limparCodigo(codigo) {
      if (codigo) {
        const codigoSemEspacos = codigo.trim();
        return codigoSemEspacos.replace(/\D/g, '');
      }
      return '';
    }

    function removerPacote(codigo) {
      if (refDialogoConfirmacao.current) {
        refDialogoConfirmacao.current.handleOpen(
          'Remover Troco',
          'Deseja realmente remover este troco?',
          {
            code: 2,
            data: codigo,
          },
        );
      }
    }

    async function handleConfirma({ code, data }) {
      switch (code) {
        case 2:
          setTrocos(trocos.filter((item) => item.codigo !== data));
          break;
        default:
          break;
      }
    }

    function writeCodigoBarras(id) {
      const idString = `${id}`;
      let codigoEN13 = '789';
      for (let i = 0; i < 10 - idString.length; i += 1) {
        codigoEN13 = `${codigoEN13}0`;
      }
      codigoEN13 = `${codigoEN13}${idString}`;
      return codigoEN13;
    }

    function readCodigoBarras(codigo) {
      return parseInt(codigo.substring(3), 10);
    }

    function formatarData(data) {
      return moment(new Date(data)).format('DD/MM/YYYY HH:mm');
    }

    function formatarTroco(pacote) {
      if (pacote) {
        return {
          id: pacote.id,
          codigo: writeCodigoBarras(pacote.id),
          data: formatarData(pacote.data_pagamento),
          sessao: pacote.sessao_id,
          valor: formatMoeda(pacote.valor),
          checkout: pacote.conta.nome,
        };
      }
      return null;
    }

    async function handleTransferirResponsabilidade(user_id) {
      try {
        setCarregando(true);
        const response = await api.put('/transferir_responsabilidade', {
          user_id: getUserId(),
          user2_id: user_id,
          caminho,
          troco_ids: trocos.map(({ id }) => id),
        });
        const { status, message } = response.data;
        if (status) {
          enqueueSnackbar(
            `Transferência de responsabilidade feita com sucesso!`,
            {
              variant: 'success',
            },
          );
          handleCloseDialog();
        } else {
          throw message;
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
      } finally {
        setCarregando(false);
      }
    }

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullWidth
          maxWidth="md"
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseLocal}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            {`Transferir Responsabilidade do transporte dos trocos para ${
              caminho ? 'o cofre' : 'o checkout'
            }`}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div>
                <Box flex={1} mr="0.5em">
                  <TextInput
                    name="nome"
                    handleEnter={() => {
                      if (!carregando) getTroco();
                      else
                        enqueueSnackbar(
                          `O troco anteriormente bipado provavelmente ainda está em processamento!`,
                          {
                            variant: 'error',
                          },
                        );
                    }}
                    label="Código"
                    handleKey={() => {}}
                    value={codigo}
                    onChange={(value) =>
                      setCodigo(limparCodigo(value.target.value))
                    }
                    error={erros[0] !== ''}
                    helperText={erros[0]}
                    fullWidth
                    autoFocus
                    variant="outlined"
                  />
                  {trocos.length > 0 && (
                    <>
                      <h2
                        style={{
                          margin: '20px 0 10px',
                          textAlign: 'center',
                        }}
                      >
                        Trocos
                      </h2>
                      <div>
                        <TableContainer
                          component={Paper}
                          style={{
                            height: '100%',
                            width: '100%',
                            overflow: 'auto',
                          }}
                        >
                          <Table size="small" aria-label="a dense table">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Código</StyledTableCell>
                                <StyledTableCell>Data</StyledTableCell>
                                <StyledTableCell>Sessão</StyledTableCell>
                                <StyledTableCell>Checkout</StyledTableCell>
                                <StyledTableCell>Valor</StyledTableCell>
                                <StyledTableCell>Ações</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {trocos.reverse().map((row) => (
                                <StyledTableRow key={row.codigo}>
                                  <StyledTableCell component="th" scope="row">
                                    {row.codigo}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {row.data}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {row.sessao}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {row.checkout}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {row.valor}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    <IconButton
                                      aria-label="detalhes"
                                      size="small"
                                      onClick={() => removerPacote(row.codigo)}
                                    >
                                      <DeleteOutlineIcon />
                                    </IconButton>
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </>
                  )}
                </Box>
              </div>
            )}
          </DialogContent>
          <DialogActions
            style={{
              padding: '0px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={handleCloseLocal}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              disabled={trocos.length <= 0 || carregando}
              variant="contained"
              color="primary"
            >
              Cancelar
            </Button>
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                disabled={trocos.length <= 0 || carregando}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
              {carregando && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Dialog>
        <DialogoConfirmacao
          ref={refDialogoConfirmacao}
          handleConfirma={handleConfirma}
        />
        <DialogoAutorizaTransporte
          ref={refDialogoAutorizaTransporte}
          handleClose={() => {}}
          handleAutoriza={handleTransferirResponsabilidade}
        />
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
