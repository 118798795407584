import React, { forwardRef } from 'react';
import { MenuItemLink } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import SystemUpdateIcon from '@material-ui/icons/SystemUpdate';

import { APP_VERSION, APP_VERSION_FUNCIONALIDADES } from '../../utils';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.main,
    height: '50px',
  },
  icon2: {
    color: theme.palette.primary.main,
  },
}));

const versao = `Versão: ${APP_VERSION}`;

const funcionalidades = APP_VERSION_FUNCIONALIDADES;

const VersionamentoMenuItem = forwardRef((props, ref) => {
  const classes = useStyles();
  return (
    <Tooltip title={funcionalidades} placement="bottom">
      <MenuItemLink
        ref={ref}
        to="/"
        leftIcon={<SystemUpdateIcon className={classes.icon2} />}
        primaryText={versao}
        className={classes.icon}
      />
    </Tooltip>
  );
});

export default VersionamentoMenuItem;
