import React from 'react';

import { parse } from 'query-string';

import CreateTemplate from '../../Components/Create';
import Erros from './Erros';
import Form from './Form';
import validate from './Validation';

const transform = ({ showPeso, showUnidades, pm, ...rest }) => ({
  ...rest,
  unidades: showUnidades ? rest.unidades : 0,
  peso: showPeso ? rest.peso : 0,
  data: new Date(),
});

const Create = (props) => {
  const {
    produto_id: produto_id_string,
    unidades: unidades_string,
    peso: peso_string,
    pm: pm_string,
  } = parse(props.location.search);
  const produto_id = produto_id_string ? parseInt(produto_id_string, 10) : '';
  const showUnidades = unidades_string ? parseInt(unidades_string, 10) : '';
  const showPeso = peso_string ? parseInt(peso_string, 10) : '';
  const pm = pm_string ? parseFloat(pm_string) : null;
  return (
    <CreateTemplate
      {...props}
      title="Nova Avaria"
      defaultValue={{
        produto_id,
        showPeso: showPeso === 1,
        showUnidades: showUnidades === 1,
        pm,
      }}
      transform={transform}
      validate={validate}
      onFailure={Erros}
      isBack
    >
      <Form />
    </CreateTemplate>
  );
};

export default Create;
