import ListIcon from '@material-ui/icons/List';

import Create from './Create';
import Edit from './Edit';
import List from './List2';

export default {
  create: Create,
  edit: Edit,
  list: List,
  icon: ListIcon,
};
