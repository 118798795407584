import {
  createAPI,
  deleteAPI,
  deleteManyAPI,
  getListAPI,
  getManyAPI,
  getOneAPI,
  updateAPI,
  updateManyAPI,
} from '../services';

export default {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    return getListAPI(
      resource,
      [field, order],
      [page, perPage],
      params.filter,
      [],
    );
  },
  getOne: (resource, params) => getOneAPI(resource, params.id, []),
  getMany: (resource, params) => getManyAPI(resource, { id: params.ids }, []),
  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    return getListAPI(
      resource,
      [field, order],
      [page, perPage],
      {
        ...params.filter,
        [params.target]: params.id,
      },
      [],
    );
  },
  update: (resource, params) => updateAPI(resource, params.id, params.data),
  updateMany: (resource, params) =>
    updateManyAPI(resource, { id: params.ids }, params.data),
  create: (resource, params) => createAPI(resource, params.data),
  delete: (resource, params) => deleteAPI(resource, params.id),
  deleteMany: (resource, params) => deleteManyAPI(resource, { id: params.ids }),
};
