import React, { useImperativeHandle, forwardRef, useRef } from 'react';
import { useRefresh, useNotify } from 'react-admin';

import { useSnackbar } from 'notistack';

import { DialogoConfirmacao } from '../../../Components';
import { api, getListAllAPI } from '../../../services';
import { formatDateDDMMYYYYHHmm } from '../../../utils';

const FormDialog = forwardRef(({ campoAtivo }, ref) => {
  const refresh = useRefresh();
  const { enqueueSnackbar } = useSnackbar();
  const refDialogoConfirmacao = useRef(null);

  async function getDataResource(record) {
    try {
      const data = await getListAllAPI(
        'vendas',
        ['id', 'asc'],
        { id: [record.id] },
        ['devolucoes'],
      );
      if (data.data.length > 0) {
        const venda = data.data[0];
        if (!venda.cancelado) {
          if (venda.devolucoes.length > 0) {
            throw 'Esta venda não pode ser cancelada, pois possui devoluções registradas nela!';
          } else if (refDialogoConfirmacao.current) {
            refDialogoConfirmacao.current.handleOpen(
              'Cancelamento de Venda',
              `Tem certeza que deseja cancelar a venda nº ${
                venda.numero
              } realidada em ${formatDateDDMMYYYYHHmm(venda.data)} ?`,
              {
                ...record,
                statusCancelado: false,
              },
            );
          }
        } else {
          refDialogoConfirmacao.current.handleOpen(
            'Reativação de Venda',
            `Tem certeza que deseja reativar a venda nº ${
              venda.numero
            } realidada em ${formatDateDDMMYYYYHHmm(venda.data)} ?`,
            {
              ...record,
              statusCancelado: true,
            },
          );
        }
      } else {
        throw 'Esta venda não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(record) {
      getDataResource(record);
    },
  }));

  async function handleConfirma(record) {
    const response = await api.put(`/vendas/${record.id}`, {
      status: !record.statusCancelado,
    });
    const data = response.data;
    const { status, message } = data;
    if (!status) {
      enqueueSnackbar(`${message}`, {
        variant: 'error',
      });
    }
    refresh();
  }

  return (
    <DialogoConfirmacao
      ref={refDialogoConfirmacao}
      handleConfirma={handleConfirma}
    />
  );
});

export default FormDialog;
