import { AttachMoney } from '@material-ui/icons';

import List from './List2';
import Show from './Show';

export default {
  list: List,
  show: Show,
  icon: AttachMoney,
};
