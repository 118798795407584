import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import { DateInputBonito } from '../../../../Components';
import { getListAllAPI, getUserId, api } from '../../../../services';
import { formatDateDDMMYYYY } from '../../../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_ID = -1;

const DialogoCategoriaTransacao = forwardRef(
  ({ updateCompensacao = () => {} }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [id, setId] = useState(DEFAULT_ID);

    const [carregando, setCarregando] = useState(false);

    const [colaborador, setColaborador] = useState(null);
    const [dataCompensacao, setDataCompensacao] = useState(new Date());

    const [diaFeriado, setDiaFeriado] = useState(null);

    const [diaCompensacao, setDiaCompensacao] = useState(null);

    const classes = useStyles({ cor: '#009FD4' });

    const handleCloseDialog = () => {
      setOpen(false);
    };

    async function getDataResource(id) {
      try {
        const data = await getListAllAPI(
          'compensacoes_feriados',
          ['id', 'asc'],
          { id: [id] },
          ['colaborador', 'diaFeriado', 'diaCompensacao'],
        );
        if (data.data.length > 0) {
          setId(data.data[0].id);
          setColaborador(data.data[0].colaborador);
          setDiaFeriado(data.data[0].diaFeriado);
          setDiaCompensacao(data.data[0].diaCompensacao);
          setDataCompensacao(new Date());
          setCarregando(false);
        } else {
          throw 'Esta Ponto não existe!';
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    async function handleSalvar() {
      setCarregando(true);
      try {
        const response = await api.put(`/compensacoes_feriados/${id}`, {
          dataCompensacao,
          user_id: getUserId(),
        });

        const data = response.data;

        if (data.status) {
          if (data.data.dados_dia_pagamento_folga_extra_id) {
            updateCompensacao({
              id: data.data.id,
              colaborador_id: data.data.colaborador_id,
              dados_dia_id: data.data.dados_dia_id,
              data: data.data.data,
              presenca: data.data.presenca,
              dados_dia_pagamento_folga_extra_id:
                data.data.dados_dia_pagamento_folga_extra_id,
              data_folga_extra: data.data.data_folga_extra,
              dia: data.data.dia,
            });
            setCarregando(false);
            enqueueSnackbar('Esta Compensação foi atualizada com sucesso!', {
              variant: 'success',
            });

            handleCloseDialog();
          } else {
            setCarregando(false);
            enqueueSnackbar('Não foi registrada a data da compensação', {
              variant: 'error',
            });

            handleCloseDialog();
          }
        } else {
          setCarregando(false);
          enqueueSnackbar('erro inesperado', {
            variant: 'error',
          });

          handleCloseDialog();
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    useImperativeHandle(ref, () => ({
      handleOpen(id) {
        setCarregando(true);
        getDataResource(id);
        setId(id);
        setOpen(true);
      },
    }));

    function getErros() {
      const errosOld = [];
      return errosOld;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          TransitionComponent={Transition}
          keepMounted
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            {`${
              colaborador ? colaborador.nome : ''
            } - Feriado: ${formatDateDDMMYYYY(
              diaFeriado ? diaFeriado.data : null,
            )}`}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 10px 20px',
              margin: '0',
            }}
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div>
                <Box
                  p="1em"
                  style={{
                    display: 'flex',
                  }}
                >
                  <Box flex={1}>
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        {`Feriado (${
                          diaFeriado ? diaFeriado.nome : ''
                        }): ${formatDateDDMMYYYY(
                          diaFeriado ? diaFeriado.data : null,
                        )}`}
                      </Box>
                      <Box flex={1} mr="0.5em">
                        <DateInputBonito
                          showTodayButton
                          //disablePast
                          todayLabel="Hoje"
                          name="data"
                          handleEnter={() => {}}
                          label="Data Compensação"
                          handleKey={() => {}}
                          value={dataCompensacao}
                          onChange={(value) => {
                            setDataCompensacao(value);
                          }}
                          format="dd MMMM yyyy"
                          fullWidth
                          variant="outlined"
                          inputVariant="outlined"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </div>
            )}
          </DialogContent>
          <DialogActions
            style={{
              padding: '0px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={handleCloseDialog}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="contained"
              color="primary"
            >
              Calcelar
            </Button>
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                disabled={erroExistente || carregando}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
              {carregando && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
