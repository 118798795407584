import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import { TextInput, ColorInput, PrecoInput } from '../../../Components';
import { createAPI, updateAPI, getListAllAPI } from '../../../services';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_NOME = '';
const DEFAULT_COLOR = '#f44336';
const DEFAULT_PRECO_VENDA = 0;
const DEFAULT_ID = -1;

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [nome, setNome] = useState(DEFAULT_NOME);
  const [color, setColor] = useState(DEFAULT_COLOR);
  const [id, setId] = useState(DEFAULT_ID);
  const [precoVenda, setPrecoVenda] = useState(DEFAULT_PRECO_VENDA);
  const [carregando, setCarregando] = useState(false);

  const classes = useStyles({ cor: '#009FD4' });

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function reiniciar(tipoCategoria) {
    setNome(DEFAULT_NOME);
    setId(DEFAULT_ID);
    setColor(DEFAULT_COLOR);
    setPrecoVenda(DEFAULT_PRECO_VENDA);
    setCarregando(false);
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'familias_produtos',
        ['id', 'asc'],
        { id: [id] },
        [],
      );
      if (data.data.length > 0) {
        setNome(data.data[0].nome);
        setId(data.data[0].id);
        setColor(data.data[0].color);
        setPrecoVenda(data.data[0].preco_venda);
        setCarregando(false);
      } else {
        throw 'Esta família não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    try {
      if (id <= 0) {
        await createAPI('familias_produtos', {
          nome,
          color,
          preco_venda: precoVenda,
          preco_revenda: precoVenda,
        });
        setCarregando(false);
        enqueueSnackbar('Família de produto criada com sucesso!', {
          variant: 'success',
        });
      } else {
        await updateAPI('familias_produtos', id, {
          nome,
          color,
          preco_venda: precoVenda,
          preco_revenda: precoVenda,
        });
        setCarregando(false);
        enqueueSnackbar('Família de produto atualizada com sucesso!', {
          variant: 'success',
        });
      }

      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      reiniciar();
      setOpen(true);
    },
    handleEdit(id) {
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
    },
  }));

  function validateNome() {
    let error = '';
    if (!nome) error = 'Este campo é obrigatório';
    else if (nome.length > 80) {
      error = 'Número de caracteres maior que o permitido (máximo 80)';
    }
    return error;
  }

  function validatePrecoVenda() {
    let error = '';
    if (precoVenda <= 0 || Number.isNaN(precoVenda)) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function getErros() {
    const errosOld = ['', ''];
    errosOld[0] = validateNome();
    errosOld[1] = validatePrecoVenda();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          {id < 0 ? `Nova Família de produto` : `Editar Família de produto`}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <TextInput
                name="nome"
                handleEnter={() => {}}
                label="Nome"
                handleKey={() => {}}
                value={nome}
                onChange={(value) => setNome(value.target.value)}
                error={erros[0] !== ''}
                helperText={erros[0]}
                fullWidth
                autoFocus
                variant="standard"
              />
              <PrecoInput
                name="precoVenda"
                handleEnter={() => {}}
                label="Preço venda"
                handleKey={() => {}}
                value={Number.isNaN(precoVenda) ? '' : String(precoVenda)}
                onChange={(value) => {
                  const newValue = parseFloat(value.target.value);
                  if (newValue <= 0 || Number.isNaN(newValue)) {
                    setPrecoVenda(0);
                  } else {
                    setPrecoVenda(newValue);
                  }
                }}
                error={erros[1] !== ''}
                helperText={erros[1]}
                style={{ marginTop: '24px' }}
                fullWidth
                variant="standard"
                autoFocus
              />
              <div style={{ marginTop: '24px' }}>
                <div>
                  <div>Cor da categoria</div>
                </div>
                <div style={{ margin: '16px 6.4px' }}>
                  <ColorInput
                    value={color}
                    onChange={(newColor) => setColor(newColor)}
                  />
                </div>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          <div className={classes.wrapper}>
            <Button
              onClick={handleSalvar}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              disabled={erroExistente || carregando}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
            {carregando && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
