import React, { useRef } from 'react';
import {
  DateField,
  Datagrid,
  ListBase,
  ListToolbar,
  Pagination,
  useRefresh,
  Title,
} from 'react-admin';

import { Card, makeStyles, Box } from '@material-ui/core';
import moment from 'moment';

import { formatMoeda } from '../../utils';
import DateInputMobills from '../ConferenciaTemperaturaFreezer/Inputs/DateInputMobills';
import DescricaoField from '../Transacoes/Fields/DescricaoField';
import ValorField from '../Transacoes/Fields/ValorField';
import StatusField from '../TransacoesPix/Fields/StatusField';
import ActionsField from './ActionsField';
import Actions from './ActionsList';
import DialogoCarregarPlanilha from './DialogoCarregarPlanilha';
import DialogoChangeStatus from './DialogoChangeStatus';
import Filter from './Filter';
import TipoField from './ModalidadeField';

const useStylesDatagrid = makeStyles((theme) => ({
  dataHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
}));

const MyList = ({ ...props }) => {
  const refresh = useRefresh();
  const refDialogoStatus = useRef(null);
  const refDialogoCarregarPlanilha = useRef(null);

  const classes = useStylesDatagrid();

  const handleClose = () => {
    refresh();
  };

  function handleCarregarPlanilha() {
    if (refDialogoCarregarPlanilha.current) {
      refDialogoCarregarPlanilha.current.handleOpen();
    }
  }

  function getMeioPagamento(nome) {
    if (nome.includes('Cartão de Crédito')) return 'Crédito';
    if (nome.includes('Cartão de Débito')) return 'Débito';
    if (nome.includes('Voucher')) return 'Voucher';
    return '';
  }

  const handleEfetivar = (data) => {
    if (refDialogoStatus.current) {
      const id = data.record.id;
      const descricao = data.record.nome;
      const dataPagamento = moment(new Date(data.record.data_pagamento)).format(
        'DD/MM/YYYY - HH:mm',
      );
      const valor = formatMoeda(data.record.valor);
      const modalidade = getMeioPagamento(descricao);
      const conferidoMaquineta = data.record.conferido_maquineta;
      refDialogoStatus.current.handleOpen(
        id,
        descricao,
        dataPagamento,
        valor,
        modalidade,
        conferidoMaquineta,
      );
    }
  };

  return (
    <>
      <Title title="Conferências Maquineta" />
      <ListToolbar
        filters={props.filters}
        actions={
          <Actions {...props} handleCarregarPlanilha={handleCarregarPlanilha} />
        }
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Box flex={1} margin="0px 0 20px">
          <DateInputMobills />
        </Box>
        <Datagrid {...props} bulkActionButtons={false}>
          <StatusField
            source="conferido_maquineta"
            label="Conferido"
            sortable={false}
            headerClassName={classes.statusHeader}
            cellClassName={classes.statusCell}
          />
          {/*<DateField
            source="data_pagamento"
            label="Data"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            showTime
      />*/}
          <DateField
            source="created_at"
            label="Data"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            showTime
          />
          <DescricaoField
            source="nome"
            label="Descrição"
            sortable={false}
            headerClassName={classes.descricaoHeader}
            cellClassName={classes.descricaoCell}
          />
          {/*<ContaField
            label="Conta"
            source="conta_id"
            sortable={false}
            headerClassName={classes.categoriaHeader}
            cellClassName={classes.categoriaCell}
      />*/}
          <ValorField
            source="valor"
            textAlign="right"
            label="Valor"
            headerClassName={classes.valorHeader}
            cellClassName={classes.valorCell}
          />
          <TipoField
            source="valor"
            textAlign="right"
            label="Modalidade"
            headerClassName={classes.valorHeader}
            cellClassName={classes.valorCell}
            sortable={false}
          />
          <ActionsField
            source="lalala"
            handleEfetivar={handleEfetivar}
            textAlign="right"
            label="Ações"
            headerClassName={classes.actionsHeader}
            cellClassName={classes.actionsCell}
            sortable={false}
          />
        </Datagrid>
        <TransacoesPagination />
        <DialogoCarregarPlanilha
          ref={refDialogoCarregarPlanilha}
          handleClose={handleClose}
        />
        <DialogoChangeStatus ref={refDialogoStatus} handleClose={handleClose} />
      </Card>
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      filters={<Filter />}
      title="Conferências Maquineta"
      component="div"
      bulkActionButtons={false}
      sort={{ field: 'data_pagamento', order: 'DESC' }}
      pagination={<TransacoesPagination />}
      perPage={50}
      filterDefaultValues={{
        periodo: {
          start: new Date(),
          end: new Date(),
        },
      }}
    />
  </>
);

export default List2;
