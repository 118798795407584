import React, {
  useReducer,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { ThemeProvider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useSnackbar } from 'notistack';

import imagem from '../../../assets/background.jpg';
import theme from '../../../Components/DialogoEditarVenda/themes/default';
import { getListAPI, api } from '../../../services';
import {
  formatItens,
  formatDateDDMMYYYYHHmm,
  formatPreco,
} from '../../../utils';
import InicioFrenteCaixaVenda from './Content';
import { VendaContext } from './context';
import reducerVenda from './reducer';

const DialogoCreateVenda = forwardRef(({ handleClose = () => {} }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [venda, dispatch] = useReducer(reducerVenda, {
    vendaId: -1,
    cliente: null,
    itens: [],
    itensEstoque: [],
    subTotal: 0,
    venda: null,
  });

  function resetar() {
    dispatch({ type: 'RESETAR_TUDO' });
  }

  const handleCloseDialog = () => {
    setOpen(false);
    resetar();
    handleClose();
  };

  function irParaTelaInit() {
    handleCloseDialog();
  }

  async function getHasCreditoDevolucao(cliente_id) {
    const response = await api.get(
      `/has_devolucao_venda_credito_cliente/${cliente_id}`,
    );

    const data = response.data;

    const { numeroDevolucoesVendaCreditoPendentes } = data;

    return numeroDevolucoesVendaCreditoPendentes;
  }

  function formatDadosVenda(dados) {
    return {
      numero: dados.numero,
      data: formatDateDDMMYYYYHHmm(dados.data),
      valor: formatPreco(dados.valor),
    };
  }

  async function getDataVenda(vendaId) {
    try {
      const data = await getListAPI(
        'vendas',
        ['id', 'asc'],
        [1, 1],
        { id: [vendaId] },
        [
          'cliente.cidade.estado',
          'cliente.categoria',
          'itens.produto.unidade',
          'devolucoes',
        ],
      );

      if (data.data.length <= 0) {
        throw 'Esta venda não existe!';
      }

      const data2 = await api.get(
        `/resumo_mercadorias_venda_cliente_devolucao/${vendaId}`,
      );

      /*const credito = await getHasCreditoDevolucao(data.data[0].cliente.id);

      if (credito > 0) {
        throw 'Este cliente possui créditos de devolução que ainda não foram utilizados!';
      }*/

      const itensEstoque = formatItens(data2.data);

      for (let i = 0; i < itensEstoque.length; i++) {
        itensEstoque[i].produto = {
          ...itensEstoque[i].produto,
          nivelCaixa: itensEstoque[i].nivelCaixaria,
        };
      }

      dispatch({
        type: 'PREENCHER_TUDO',
        vendaId,
        cliente: data.data[0].cliente,
        itens: [],
        itensEstoque,
        venda: formatDadosVenda(data.data[0]),
      });
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      irParaTelaInit();
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate(vendaId) {
      getDataVenda(vendaId);
      setOpen(true);
    },
  }));

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          fullScreen
          style={{
            padding: '0',
            margin: '0',
          }}
        >
          <DialogContent
            style={{
              padding: '0',
              margin: '0',
              backgroundImage: `url(${imagem})`,
              backgroundSize: 'cover',
            }}
            dividers
          >
            <VendaContext.Provider value={{ venda, dispatch }}>
              <InicioFrenteCaixaVenda irParaTelaInit={irParaTelaInit} />
            </VendaContext.Provider>
          </DialogContent>
        </Dialog>
      </div>
    </ThemeProvider>
  );
});

export default DialogoCreateVenda;
