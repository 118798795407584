export function createObjEstoque(linhas) {
  const valorCotacoes = [];

  for (let i = 0; i < linhas.length; i++) {
    valorCotacoes.push(linhas[i].preco);
  }

  return [
    {
      label: 'Cotação',
      color: 'rgba(75, 192, 192, 1)',
      data: valorCotacoes,
    },
  ];
}
