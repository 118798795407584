import React, { useState, useEffect } from 'react';
import { useVersion, Title } from 'react-admin';
import { useLocation } from 'react-router-dom';

import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PrintIcon from '@material-ui/icons/Print';

import { api } from '../../../services';
import { imprimirResumoMercadorias } from '../../../services/impressora';
import {
  formatCPF,
  formatCNPJ,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  formatCEP,
  formatPeso,
  getEstoqueLabel,
} from '../../../utils';

const colorHeader = SECONDARY_COLOR;

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const ResumoMercadoriasCliente = () => {
  const classes = useStyles();
  const [cliente, setCliente] = useState(null);
  const [linhas, setLinhas] = useState([]);
  const query = useQuery();
  const version = useVersion();

  const cliente_id = query.get('cliente_id');

  useEffect(() => {
    async function getData() {
      const response = await api.get(
        `resumo_mercadorias_cliente/${cliente_id}`,
      );

      const { cliente, linhas } = response.data;

      setCliente(cliente);
      setLinhas(linhas);
    }
    getData();
  }, [version, cliente_id]);

  async function handleImprimir() {
    await imprimirResumoMercadorias(cliente_id);
  }

  return (
    <div>
      <Title title="Resumo Mercadorias Cliente" />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ flex: 1 }} />
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={handleImprimir}
        >
          <PrintIcon />
        </IconButton>
      </div>
      {cliente ? (
        <Box
          display="flex"
          justifyContent="space-between"
          flex={1}
          style={{
            border: `1px solid ${PRIMARY_COLOR}`,
            // marginLeft: '10px',
            padding: '5px',
            borderRadius: '5px',
            margin: '20px 0px',
          }}
        >
          <Box marginLeft="10px" display="flex" flexDirection="column" flex={1}>
            <Box
              marginLeft="0px"
              fontSize="15px"
              fontWeight="bold"
              display="flex"
              flex={1}
              marginTop="5px"
            >
              <p
                className={classes.textOverflow}
                style={{
                  marginBottom: '0px',
                  color: colorHeader,
                  marginRight: '10px',
                }}
              >
                Responsável:
              </p>
              {cliente.nome}
            </Box>
            <Box
              marginLeft="0px"
              fontSize="15px"
              fontWeight="bold"
              display="flex"
              flex={1}
              marginTop="5px"
            >
              <p
                className={classes.textOverflow}
                style={{
                  marginBottom: '0px',
                  color: colorHeader,
                  marginRight: '10px',
                }}
              >
                Razão Social:
              </p>
              {cliente.razao_social}
            </Box>
            <Box
              marginLeft="0px"
              fontSize="15px"
              fontWeight="bold"
              display="flex"
              flex={1}
              marginTop="5px"
            >
              <p
                className={classes.textOverflow}
                style={{
                  marginBottom: '0px',
                  color: colorHeader,
                  marginRight: '10px',
                }}
              >
                CPF:
              </p>
              {formatCPF(cliente.cpf)}
            </Box>
            <Box
              marginLeft="0px"
              fontSize="15px"
              fontWeight="bold"
              display="flex"
              flex={1}
              marginTop="5px"
              marginBottom="5px"
            >
              <p
                className={classes.textOverflow}
                style={{
                  marginBottom: '0px',
                  color: colorHeader,
                  marginRight: '10px',
                }}
              >
                CNPJ:
              </p>
              {formatCNPJ(cliente.cnpj)}
            </Box>
          </Box>
          <Box marginLeft="10px" display="flex" flexDirection="column" flex={1}>
            <Box
              marginLeft="0px"
              fontSize="15px"
              fontWeight="bold"
              display="flex"
              flex={1}
              justifyContent="flex-start"
              marginTop="5px"
            >
              <p
                className={classes.textOverflow}
                style={{
                  marginBottom: '0px',
                  color: colorHeader,
                  marginRight: '10px',
                }}
              >
                CEP:
              </p>
              {cliente.cep}
            </Box>
            <Box
              marginLeft="0px"
              fontSize="15px"
              fontWeight="bold"
              display="flex"
              flex={1}
              justifyContent="flex-start"
              marginTop="5px"
            >
              <p
                className={classes.textOverflow}
                style={{
                  marginBottom: '0px',
                  color: colorHeader,
                  marginRight: '10px',
                }}
              >
                Bairro:
              </p>
              {cliente.bairro}
            </Box>
            <Box
              marginLeft="0px"
              fontSize="15px"
              fontWeight="bold"
              display="flex"
              flex={1}
              justifyContent="flex-start"
              marginTop="5px"
            >
              <p
                className={classes.textOverflow}
                style={{
                  marginBottom: '0px',
                  color: colorHeader,
                  marginRight: '10px',
                }}
              >
                Cidade:
              </p>
              {cliente.cidade
                ? `${cliente.cidade.nome} - ${cliente.cidade.estado.sigla}`
                : ''}
            </Box>
            <Box
              marginLeft="0px"
              fontSize="15px"
              fontWeight="bold"
              display="flex"
              flex={1}
              justifyContent="flex-start"
              marginTop="5px"
              marginBottom="5px"
            >
              <p
                className={classes.textOverflow}
                style={{
                  marginBottom: '0px',
                  color: colorHeader,
                  marginRight: '10px',
                }}
              >
                Logradouro:
              </p>
              {cliente.logradouro}
            </Box>
          </Box>
        </Box>
      ) : (
        <div />
      )}
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Produto</StyledTableCell>
              <StyledTableCell align="right">Unidades</StyledTableCell>
              <StyledTableCell align="right">Peso (Kg)</StyledTableCell>
              <StyledTableCell align="right">Estoque</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {linhas.map((row) => (
              <StyledTableRow key={row.produto.id}>
                <StyledTableCell>{row.produto.nome}</StyledTableCell>
                <StyledTableCell align="right">
                  {row.unidades ? row.unidades : '-'}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.peso ? formatPeso(row.peso) : '-'}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {getEstoqueLabel(row.produto, row.unidades, row.peso)}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ResumoMercadoriasCliente;
