function getItemDataset(label, color, data) {
  return {
    label,
    data,
    fill: false,
    borderColor: color,
    backgroundColor: color,
  };
}

function getData(datasets1, days) {
  const datasets = [];
  const labels = [];
  for (let i = 1; i <= days; i++) {
    labels.push(i);
  }
  for (let i = 0; i < datasets1.length; i++) {
    const { label, color, data } = datasets1[i];
    datasets.push(getItemDataset(label, color, data));
  }
  return {
    labels,
    datasets,
  };
}

function getOptions(text) {
  return {
    title: {
      display: true,
      text,
      fontColor: 'red',
      fontSize: 14,
    },
  };
}

export function getInfos(label, datasets, days) {
  return {
    data: getData(datasets, days),
    options: getOptions(label),
  };
}

export const legend = {
  display: true,
  position: 'bottom',
  labels: {
    fontColor: '#323130',
    fontSize: 14,
  },
};
