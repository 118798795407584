import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
  text: {
    /* whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis', */
    // color: 'red',
    // marginLeft: '15px',
    /* maxWidth: '150px', */
  },
  avatar: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    color: 'white',
    backgroundColor: '#f44336',
  },
}));

function writeCodigoBarras(id) {
  const idString = `${id}`;
  let codigoEN13 = '789';
  for (let i = 0; i < 10 - idString.length; i += 1) {
    codigoEN13 = `${codigoEN13}0`;
  }
  codigoEN13 = `${codigoEN13}${idString}`;
  return codigoEN13;
}

const CodigoField = (props) => {
  const classes = useStyles();
  // const { source } = props;
  const record = useRecordContext(props);
  const value = record ? writeCodigoBarras(record.record.id) : '';

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title={value} placement="bottom" aria-label="add2">
        <Typography className={classes.text}>{value}</Typography>
      </Tooltip>
    </div>
  );
};



export default CodigoField;
