import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import {
  TIPO_RECORRENCIA_NAO_RECORRENCIA,
  TIPO_RECORRENCIA_DIARIA_NORMAL,
  TIPO_RECORRENCIA_SEMANAL_NORMAL,
  TIPO_RECORRENCIA_MENSAL_NORMAL,
  TIPO_RECORRENCIA_ANUAL_NORMAL,
} from '../../utils';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '400px',
  },
}));

function getStatusTipoRecorrencia(value) {
  switch (value) {
    case TIPO_RECORRENCIA_NAO_RECORRENCIA:
      return 'Sem Recorrência';
    case TIPO_RECORRENCIA_DIARIA_NORMAL:
      return 'Recorrência Diária';
    case TIPO_RECORRENCIA_SEMANAL_NORMAL:
      return 'Recorrência Semanal';
    case TIPO_RECORRENCIA_MENSAL_NORMAL:
      return 'Recorrência Mensal';
    case TIPO_RECORRENCIA_ANUAL_NORMAL:
      return 'Recorrência Anual';
    default:
      return '-';
  }
}

const DescricaoField = (props) => {
  const classes = useStyles();

  const record = useRecordContext(props);
  const value = record
    ? getStatusTipoRecorrencia(record.record.tipo_recorrencia)
    : '-';

  return (
    <Tooltip title={value} placement="bottom" aria-label="add2">
      <Typography className={classes.text}>{value}</Typography>
    </Tooltip>
  );
};

export default DescricaoField;
