import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AlarmIcon from '@material-ui/icons/Alarm';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'notistack';

import {
  PrecoInput,
  DateTimeInputBonito,
  IntegerInput,
  SwitchInput,
  PesoInput,
} from '../../../Components';
import { PriceInput as PrecoInput2 } from '../../../libNova/inputs/PriceInput';
import { WeightInput } from '../../../libNova/inputs/WeightInput';
import {
  getListAllAPI,
  createAPI,
  updateAPI,
  getUserId,
  getManyAPI,
  api,
} from '../../../services';
import {
  POR_UNIDADE,
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  TIPO_UNIDADE_OFERTA_KG,
  TIPO_UNIDADE_OFERTA_UN,
  TIPO_UNIDADE_OFERTA_CAIXA,
  TIPO_UNIDADE_OFERTA_CAIXETA,
  TIPO_UNIDADE_OFERTA_CAIXA_MAE,
  getNivelCaixaQtde,
  getLabelTipoUnidade,
  getQuantidadeCaixariaLabel,
  getPrecoUnitarioVerdadeiroCaixa2,
  getOptionsTipoUnidadeOferta,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  formatCPF,
  formatCNPJ,
  ZERO_APROXIMADO,
  formatMoeda,
  STATUS_ATIVO,
  formatPeso,
} from '../../../utils';
import DialogoIntervalo from '../DialogoIntervalo';
import DialogoPrecificacaoNova from '../DialogoPrecificacaoNova';
import DialogoSelecionarCliente from '../DialogoSelecionarCliente';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    //borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const TAMANHO_PEQUENO = 'sm';
const TAMANHO_GRANDE = 'lg';
const colorHeader = SECONDARY_COLOR;

function getQtdeEfetivaCalculado(produto, tipoUnidadeOferta, qtde) {
  if (produto) {
    const tipo = produto.unidade.tipo;
    const tipoOferta = tipoUnidadeOferta;
    switch (tipo) {
      case POR_UNIDADE: {
        switch (tipoOferta) {
          case TIPO_UNIDADE_OFERTA_UN:
            return qtde;
          case TIPO_UNIDADE_OFERTA_CAIXA:
            return qtde * produto.unidades_caixa_nivel_1;

          case TIPO_UNIDADE_OFERTA_CAIXETA:
            return (
              qtde *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2
            );

          case TIPO_UNIDADE_OFERTA_CAIXA_MAE:
            return (
              qtde *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3
            );

          default:
            return 0;
        }
      }
      case POR_PESO_FIXO: {
        switch (tipoOferta) {
          case TIPO_UNIDADE_OFERTA_UN:
            return qtde;
          case TIPO_UNIDADE_OFERTA_CAIXA:
            return qtde * produto.unidades_caixa_nivel_1;

          case TIPO_UNIDADE_OFERTA_CAIXETA:
            return (
              qtde *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2
            );

          case TIPO_UNIDADE_OFERTA_CAIXA_MAE:
            return (
              qtde *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3
            );

          default:
            return 0;
        }
      }
      case POR_PESO_VARIAVEL: {
        return 0;
      }
      case POR_PESO_VARIAVEL_SEM_UNIDADE: {
        switch (tipoOferta) {
          case TIPO_UNIDADE_OFERTA_KG:
            return qtde;
          case TIPO_UNIDADE_OFERTA_CAIXA:
            return qtde * produto.peso_caixa_nivel_1;

          case TIPO_UNIDADE_OFERTA_CAIXETA:
            return (
              qtde * produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2
            );

          case TIPO_UNIDADE_OFERTA_CAIXA_MAE:
            return (
              qtde *
              produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3
            );

          default:
            return 0;
        }
      }
      default:
        return 0;
    }
  }
  return 0;
}

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState(-1);
  const [produto, setProduto] = useState(null);
  const [valor, setValor] = useState(0);
  const [dataMinima, setDataMinima] = useState(new Date());
  const [dataMaxima, setDataMaxima] = useState(new Date());
  const [tipoUnidadeOferta, setTipoUnidadeUnidade] = useState(
    TIPO_UNIDADE_OFERTA_UN,
  );
  const [qtde, setQtde] = useState(0);

  const [config, setConfig] = useState(null);
  const [fornecedor, setFornecedor] = useState(null);
  const [dadosPrecificacaoAnterior, setDadosPrecificacaoAnterior] = useState(
    null,
  );
  const [quantidadeComprada, setQuantidadeComprada] = useState(0);
  const [
    valorNegociadoComAIndustria,
    setValorNegociadoComAIndustria,
  ] = useState(null);
  const [loteId, setLoteId] = useState(0);

  const classes = useStyles();
  const [tamanho, setTamanho] = useState(TAMANHO_GRANDE);
  const [carregando, setCarregando] = useState(false);
  const refDialogoPrecificacaoLiberacao = useRef(null);
  const [dadosPrecificacao, setDadosPrecificacao] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [isMargemCartao, setIsMargemCartao] = useState(true);
  const [verbaUnitaria2, setVerbaUnitaria2] = useState(0);
  const [verbaProduto, setVerbaProduto] = useState(null);
  const [isVerbaProduto2, setIsVerbaProduto2] = useState(false);
  const [qtdeDisponivel, setQtdeDisponivel] = useState(0);

  const refDialogoIntervalo = useRef(null);
  const refDialogoSelecionarCliente = useRef(null);

  const handleChange = (event) => {
    setTipoUnidadeUnidade(event.target.value);
  };

  const handleCloseDialog = (status, idProduto = -1) => {
    setOpen(false);
    //handleClose(status, idProduto);
    handleClose(status, -1);
  };

  async function getLiberacoes(verbaProduto, id, produto) {
    const data = await getListAllAPI(
      'ofertas_liberacao_produtos',
      ['id', 'asc'],
      { verba_produto_id: verbaProduto.id },
      [],
    );

    let total = 0;

    for (let i = 0; i < data.data.length; i++) {
      const liberacao = data.data[i];
      if (
        liberacao.status ||
        (!liberacao.status && liberacao.transacao_especial_id)
      ) {
        if (liberacao.id !== id) {
          total += getQtdeEfetivaCalculado(
            produto,
            liberacao.tipo_unidade,
            liberacao.escalonadas[0].quantidade,
          );
        }
      }
    }

    return verbaProduto.qtde - total;
  }

  async function getProduto(id) {
    const data = await getManyAPI(
      'produtos/search/autocomplete',
      { id: [id], ofertasProdutos: true },
      ['dadosImposto', 'unidade'],
    );
    if (data.data.length > 0) {
      const produto = data.data[0];
      return {
        ...produto,
        preco_oferta: getPrecoUnitarioVerdadeiroCaixa2(
          { ...produto, nivelCaixa: 0 },
          0,
          0,
        ),
      };
    }
    throw 'Este produto não existe!';
  }

  function getUnidadeInicial(produto) {
    if (produto) {
      const tipo = produto.unidade.tipo;
      switch (tipo) {
        case POR_UNIDADE:
          return TIPO_UNIDADE_OFERTA_UN;
        case POR_PESO_FIXO:
          return TIPO_UNIDADE_OFERTA_UN;
        case POR_PESO_VARIAVEL:
          return TIPO_UNIDADE_OFERTA_KG;
        case POR_PESO_VARIAVEL_SEM_UNIDADE:
          return TIPO_UNIDADE_OFERTA_KG;
        default:
          return TIPO_UNIDADE_OFERTA_UN;
      }
    }
    return TIPO_UNIDADE_OFERTA_UN;
  }

  async function getConfig() {
    const config = await getListAllAPI('config', ['id', 'asc'], { id: 1 }, [
      'cidade.estado',
    ]);
    if (config.data.length <= 0) {
      throw 'Configurações inacessíveis!';
    }
    return config.data[0];
  }

  async function getDadosPrecificacaoAnterior(produto_id) {
    const dados = await api.get(`/get_ultimo_lote_precificado/${produto_id}`);
    return dados.data;
  }

  async function getVerbaProduto(produto) {
    if (produto) {
      const data = await getListAllAPI(
        'verbas_produtos',
        ['id', 'asc'],
        { status: STATUS_ATIVO, produto_id: produto.id },
        [],
      );

      if (data.data.length > 0) {
        return data.data[0];
      }
      return null;
    }
    return null;
  }

  async function reiniciar(produto) {
    try {
      const dataMinimaInitial = new Date();
      const timeNovo = dataMinimaInitial.getTime() + 15 * 60 * 1000;
      const novoData = new Date();
      novoData.setTime(timeNovo);
      setId(-1);
      const produto2 = await getProduto(produto.id);
      setProduto(produto2);
      setConfig(await getConfig());
      const {
        fornecedor,
        dadosPrecificacaoAnterior,
        quantidadeComprada,
        valorNegociadoComAIndustria,
      } = await getDadosPrecificacaoAnterior(produto.id);
      setFornecedor(fornecedor);
      setDadosPrecificacaoAnterior(dadosPrecificacaoAnterior);
      setQuantidadeComprada(quantidadeComprada);
      setValorNegociadoComAIndustria(valorNegociadoComAIndustria);
      setValor(0);
      setDataMinima(dataMinimaInitial);
      setDataMaxima(novoData);
      setTamanho(TAMANHO_GRANDE);
      setQtde(0);
      setTipoUnidadeUnidade(getUnidadeInicial(produto));
      setIsMargemCartao(true);
      setCliente(null);
      const verbaProduto = await getVerbaProduto(produto);
      if (verbaProduto) {
        const total = await getLiberacoes(verbaProduto, -1, produto2);
        setQtdeDisponivel(total);
      } else {
        setQtdeDisponivel(0);
      }
      setVerbaUnitaria2(verbaProduto ? verbaProduto.verba_unitaria : 0);
      setVerbaProduto(verbaProduto);
      setIsVerbaProduto2(false);
      setCarregando(false);
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(0);
    }
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'ofertas_liberacao_produtos',
        ['id', 'asc'],
        { id: [id] },
        [],
      );
      if (data.data.length > 0) {
        const produto = await getProduto(data.data[0].produto_id);
        setProduto(produto);
        setConfig(await getConfig());
        const {
          fornecedor,
          dadosPrecificacaoAnterior,
          quantidadeComprada,
          valorNegociadoComAIndustria,
        } = await getDadosPrecificacaoAnterior(produto.id);
        setFornecedor(fornecedor);
        setDadosPrecificacaoAnterior(dadosPrecificacaoAnterior);
        setQuantidadeComprada(quantidadeComprada);
        setValorNegociadoComAIndustria(valorNegociadoComAIndustria);
        setValor(data.data[0].valor + data.data[0].verba_unitaria);
        setDataMinima(new Date(data.data[0].data_minima));
        setDataMaxima(new Date(data.data[0].data_maxima));
        setTamanho(TAMANHO_GRANDE);
        //setEscalonadas(data.data[0].escalonadas);
        setQtde(data.data[0].escalonadas[0].quantidade);
        setTipoUnidadeUnidade(data.data[0].tipo_unidade);
        setIsMargemCartao(data.data[0].is_margem_cartao);
        setCliente(data.data[0].cliente);
        setVerbaUnitaria2(data.data[0].verba_unitaria);
        const verbaProduto = data.data[0].verbaProduto;
        setVerbaProduto(verbaProduto);
        setIsVerbaProduto2(data.data[0].is_verba_produto);
        if (verbaProduto) {
          const total = await getLiberacoes(verbaProduto, id, produto);
          setQtdeDisponivel(total);
        } else {
          setQtdeDisponivel(0);
        }
        setCarregando(false);
      } else {
        throw 'Esta Liberação de Preço não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(0);
    }
  }

  async function isOfertaExistente() {
    /*  const data = await getListAllAPI(
      'ofertas_liberacao_produtos',
      ['id', 'asc'],
      { produto_id: produto.id, cliente_id: cliente.id, status: STATUS_ATIVO },
      [],
    );

    if (data.data.length > 0) {
      return true;
    } */
    return false;
  }

  const isVerbaProduto = qtdeDisponivel >= 1 ? isVerbaProduto2 : false;

  const verbaUnitaria = isVerbaProduto ? verbaUnitaria2 : 0;

  async function handleSalvar() {
    setCarregando(true);
    try {
      if (id <= 0) {
        if (!(await isOfertaExistente())) {
          await createAPI('ofertas_liberacao_produtos', {
            //cliente_id: null,
            produto_id: produto.id,
            data_minima: dataMinima,
            data_maxima: dataMaxima,
            user_id: getUserId(),
            valor: valor - verbaUnitaria,
            escalonadas: [
              {
                id: 1,
                valor: valor - verbaUnitaria,
                quantidade: qtde,
              },
            ],
            tipo_unidade: tipoUnidadeOferta,
            dadosPrecificacao,
            is_margem_cartao: isMargemCartao,
            cliente_id: cliente ? cliente.id : null,
            verba_unitaria: verbaUnitaria,
            verba_total: verbaTotal,
            verba_produto_id: isVerbaProduto
              ? verbaProduto
                ? verbaProduto.id
                : null
              : null,
            is_verba_produto: isVerbaProduto,
          });
          setCarregando(false);
          enqueueSnackbar(`Liberação de Preço criada com sucesso!`, {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(
            `Já existe uma liberação de preço ativa para este produto e este cliente!`,
            {
              variant: 'error',
            },
          );
        }
      } else {
        await updateAPI('ofertas_liberacao_produtos', id, {
          //cliente_id: null,
          produto_id: produto.id,
          data_minima: dataMinima,
          data_maxima: dataMaxima,
          user_id: getUserId(),
          valor: valor - verbaUnitaria,
          escalonadas: [
            {
              id: 1,
              valor: valor - verbaUnitaria,
              quantidade: qtde,
            },
          ],
          tipo_unidade: tipoUnidadeOferta,
          dadosPrecificacao,
          is_margem_cartao: isMargemCartao,
          cliente_id: cliente ? cliente.id : null,
          verba_unitaria: verbaUnitaria,
          verba_total: verbaTotal,
          verba_produto_id: isVerbaProduto
            ? verbaProduto
              ? verbaProduto.id
              : null
            : null,
          is_verba_produto: isVerbaProduto,
        });
        setCarregando(false);
        enqueueSnackbar(`Liberação de Preço atualizada com sucesso!`, {
          variant: 'success',
        });
      }
      handleCloseDialog(0, produto.id);
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(0);
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate(produto = null) {
      setCarregando(true);
      reiniciar(produto);
      setOpen(true);
    },
    handleEdit(id) {
      setCarregando(true);
      getDataResource(id);
      setOpen(true);
      setId(id);
    },
  }));

  function isInputKg() {
    if (produto) {
      const tipo = produto.unidade.tipo;
      if (tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
        if (tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_KG) {
          return true;
        }
      }
    }
    return false;
  }

  function validateProduto() {
    let error = '';
    if (!produto) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateValor() {
    let error = '';
    if (valor - verbaUnitaria <= 0 || Number.isNaN(valor)) {
      error = 'Este campo é obrigatório';
    } else if (produto) {
      if (produto.preco_venda > produto.preco_oferta) {
        if (valor - verbaUnitaria >= produto.preco_oferta) {
          error =
            'Este valor não pode ser maior ou igual que o preço da oferta!';
        }
      } else if (valor - verbaUnitaria >= produto.preco_venda) {
        error =
          'Este valor não pode ser maior ou igual que o preço original de venda!';
      }
    }
    return error;
  }

  function getQtdeDisponivelVerba() {
    return qtdeDisponivel;
  }

  function getQtdeEfetiva() {
    if (produto) {
      const tipo = produto.unidade.tipo;
      const tipoOferta = tipoUnidadeOferta;
      switch (tipo) {
        case POR_UNIDADE: {
          switch (tipoOferta) {
            case TIPO_UNIDADE_OFERTA_UN:
              return qtde;
            case TIPO_UNIDADE_OFERTA_CAIXA:
              return qtde * produto.unidades_caixa_nivel_1;

            case TIPO_UNIDADE_OFERTA_CAIXETA:
              return (
                qtde *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              );

            case TIPO_UNIDADE_OFERTA_CAIXA_MAE:
              return (
                qtde *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              );

            default:
              return 0;
          }
        }
        case POR_PESO_FIXO: {
          switch (tipoOferta) {
            case TIPO_UNIDADE_OFERTA_UN:
              return qtde;
            case TIPO_UNIDADE_OFERTA_CAIXA:
              return qtde * produto.unidades_caixa_nivel_1;

            case TIPO_UNIDADE_OFERTA_CAIXETA:
              return (
                qtde *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              );

            case TIPO_UNIDADE_OFERTA_CAIXA_MAE:
              return (
                qtde *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              );

            default:
              return 0;
          }
        }
        case POR_PESO_VARIAVEL: {
          return 0;
        }
        case POR_PESO_VARIAVEL_SEM_UNIDADE: {
          switch (tipoOferta) {
            case TIPO_UNIDADE_OFERTA_KG:
              return qtde;
            case TIPO_UNIDADE_OFERTA_CAIXA:
              return qtde * produto.peso_caixa_nivel_1;

            case TIPO_UNIDADE_OFERTA_CAIXETA:
              return (
                qtde *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              );

            case TIPO_UNIDADE_OFERTA_CAIXA_MAE:
              return (
                qtde *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              );

            default:
              return 0;
          }
        }
        default:
          return 0;
      }
    }
    return 0;
  }

  const qtdeDisponivelVerba = getQtdeDisponivelVerba();
  const qtdeEfetiva = getQtdeEfetiva();

  const isKg = produto
    ? produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
    : false;

  function validateQuantidade() {
    let error = '';
    if (qtde <= 0 || Number.isNaN(qtde)) {
      error = 'Este campo é obrigatório';
    } else if (isVerbaProduto && qtdeEfetiva > qtdeDisponivelVerba) {
      error = `Quantidade Atual (${
        isKg ? `${formatPeso(qtdeEfetiva)}` : `${qtdeEfetiva} Un`
      }) está acima do disponível para usar verba (${
        isKg
          ? `${formatPeso(qtdeDisponivelVerba)}`
          : `${qtdeDisponivelVerba} Un`
      })`;
    }
    return error;
  }

  function validateCliente() {
    let error = '';
    if (!cliente) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateVerbaUnitaria() {
    let error = '';
    if (verbaUnitaria < 0 || Number.isNaN(verbaUnitaria)) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function getErros() {
    const errosOld = ['', ''];
    errosOld[0] = validateProduto();
    errosOld[1] = validateValor();
    errosOld[2] = validateQuantidade();
    errosOld[3] = validateCliente();
    errosOld[4] = validateVerbaUnitaria();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  const nomeProduto = produto ? produto.nome : '';

  const optionsTipoUnidadeOferta = getOptionsTipoUnidadeOferta(produto);

  function valorMenorMultiplo15(valor) {
    const resto = valor % 15;

    return valor - resto;
  }

  function addTempo() {
    const timeInicio = dataMinima.getTime();
    const timeFim = dataMaxima.getTime();

    const minutosDiferenca = parseInt((timeFim - timeInicio) / (60 * 1000), 10);

    let novosMinutos = minutosDiferenca + 15;

    novosMinutos = novosMinutos > 120 ? 15 : valorMenorMultiplo15(novosMinutos);

    const timeNovo = dataMinima.getTime() + novosMinutos * 60 * 1000;
    const novoData = new Date();
    novoData.setTime(timeNovo);
    setDataMaxima(novoData);
  }

  function getIntervaloAtual() {
    const timeInicio = dataMinima.getTime();
    const timeFim = dataMaxima.getTime();

    const minutos = parseInt((timeFim - timeInicio) / (60 * 1000), 10);

    return minutos;
  }

  function editarTempo() {
    if (refDialogoIntervalo.current) {
      refDialogoIntervalo.current.handleOpen(getIntervaloAtual());
    }
  }

  const isOferta = produto ? produto.preco_venda > produto.preco_oferta : false;

  function editarPrecificacao() {
    if (refDialogoPrecificacaoLiberacao.current) {
      refDialogoPrecificacaoLiberacao.current.handleOpen(isMargemCartao);
    }
  }

  function editarCliente() {
    if (refDialogoSelecionarCliente.current) {
      refDialogoSelecionarCliente.current.handleOpen(cliente);
    }
  }

  const precoVendaOriginal = produto ? produto.preco_venda : 0;

  function getDados(tipoUnidadeOferta, precoUnitario, quantidade, produto) {
    switch (tipoUnidadeOferta) {
      case TIPO_UNIDADE_OFERTA_KG:
        return {
          isCaixa: false,
          precoCaixa: precoUnitario,
          total: precoUnitario * quantidade,
          totalOriginal: precoVendaOriginal * quantidade,
          verbaTotal: verbaUnitaria * quantidade,
          /*desconto: precoVendaOriginal - precoUnitario + verbaUnitaria,
          descontoTotal:
            (precoVendaOriginal - precoUnitario + verbaUnitaria) * quantidade,*/
          desconto: precoVendaOriginal - precoUnitario,
          descontoTotal: (precoVendaOriginal - precoUnitario) * quantidade,
        };
      case TIPO_UNIDADE_OFERTA_UN: {
        const factor = produto
          ? produto.unidade.tipo === POR_PESO_FIXO
            ? produto.peso_medio
            : 1
          : 1;
        return {
          isCaixa: false,
          precoCaixa: precoUnitario,
          total: precoUnitario * quantidade * factor,
          totalOriginal: precoVendaOriginal * quantidade * factor,
          verbaTotal: verbaUnitaria * quantidade * factor,
          /*desconto: precoVendaOriginal - precoUnitario + verbaUnitaria,
          descontoTotal:
            (precoVendaOriginal - precoUnitario + verbaUnitaria) * quantidade,*/
          desconto: precoVendaOriginal - precoUnitario,
          descontoTotal:
            (precoVendaOriginal - precoUnitario) * quantidade * factor,
        };
      }
      case TIPO_UNIDADE_OFERTA_CAIXA: {
        const qtdeCx1 =
          produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
            ? produto.peso_caixa_nivel_1
            : produto.unidades_caixa_nivel_1;
        const factor =
          produto.unidade.tipo === POR_PESO_FIXO ? produto.peso_medio : 1;
        return {
          isCaixa: true,
          precoCaixa: precoUnitario * qtdeCx1 * factor,
          total: precoUnitario * qtdeCx1 * factor * quantidade,
          totalOriginal: precoVendaOriginal * qtdeCx1 * factor * quantidade,
          verbaTotal: verbaUnitaria * qtdeCx1 * factor * quantidade,
          /*desconto: precoVendaOriginal - precoUnitario + verbaUnitaria,
          descontoTotal:
            (precoVendaOriginal - precoUnitario + verbaUnitaria) *
            qtdeCx1 *
            factor *
            quantidade,*/
          desconto: precoVendaOriginal - precoUnitario,
          descontoTotal:
            (precoVendaOriginal - precoUnitario) *
            qtdeCx1 *
            factor *
            quantidade,
        };
      }
      case TIPO_UNIDADE_OFERTA_CAIXETA: {
        const qtdeCx1 =
          produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
            ? produto.peso_caixa_nivel_1
            : produto.unidades_caixa_nivel_1;
        const qtdeCx2 = produto.unidades_caixa_nivel_2;
        const factor =
          produto.unidade.tipo === POR_PESO_FIXO ? produto.peso_medio : 1;
        return {
          isCaixa: true,
          precoCaixa: precoUnitario * qtdeCx1 * qtdeCx2 * factor,
          total: precoUnitario * qtdeCx1 * qtdeCx2 * factor * quantidade,
          totalOriginal:
            precoVendaOriginal * qtdeCx1 * qtdeCx2 * factor * quantidade,
          verbaTotal: verbaUnitaria * qtdeCx1 * qtdeCx2 * factor * quantidade,
          /*desconto: precoVendaOriginal - precoUnitario + verbaUnitaria,
          descontoTotal:
            (precoVendaOriginal - precoUnitario + verbaUnitaria) *
            qtdeCx1 *
            qtdeCx2 *
            factor *
            quantidade,*/
          desconto: precoVendaOriginal - precoUnitario,
          descontoTotal:
            (precoVendaOriginal - precoUnitario) *
            qtdeCx1 *
            qtdeCx2 *
            factor *
            quantidade,
        };
      }
      case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
        const qtdeCx1 =
          produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
            ? produto.peso_caixa_nivel_1
            : produto.unidades_caixa_nivel_1;
        const qtdeCx2 = produto.unidades_caixa_nivel_2;
        const qtdeCx3 = produto.unidades_caixa_nivel_3;
        const factor =
          produto.unidade.tipo === POR_PESO_FIXO ? produto.peso_medio : 1;
        return {
          isCaixa: true,
          precoCaixa: precoUnitario * qtdeCx1 * qtdeCx2 * qtdeCx3 * factor,
          total:
            precoUnitario * qtdeCx1 * qtdeCx2 * qtdeCx3 * factor * quantidade,
          totalOriginal:
            precoVendaOriginal *
            qtdeCx1 *
            qtdeCx2 *
            qtdeCx3 *
            factor *
            quantidade,
          verbaTotal:
            verbaUnitaria * qtdeCx1 * qtdeCx2 * qtdeCx3 * factor * quantidade,
          /*desconto: precoVendaOriginal - precoUnitario + verbaUnitaria,
          descontoTotal:
            (precoVendaOriginal - precoUnitario + verbaUnitaria) *
            qtdeCx1 *
            qtdeCx2 *
            qtdeCx3 *
            factor *
            quantidade,*/
          desconto: precoVendaOriginal - precoUnitario,
          descontoTotal:
            (precoVendaOriginal - precoUnitario) *
            qtdeCx1 *
            qtdeCx2 *
            qtdeCx3 *
            factor *
            quantidade,
        };
      }
      default:
        return {
          isCaixa: false,
          precoCaixa: 0,
          total: 0,
          verbaTotal: 0,
          desconto: 0,
          descontoTotal: 0,
        };
    }
  }

  const {
    total,
    totalOriginal,
    verbaTotal,
    desconto,
    descontoTotal,
  } = getDados(tipoUnidadeOferta, valor, qtde, produto);

  function getDados2(precoUnitario, produto) {
    if (produto) {
      switch (produto.unidade.tipo) {
        case POR_UNIDADE: {
          return {
            is_caixa_1: produto.unidades_caixa_nivel_1 > 0,
            preco_caixa_1: produto.unidades_caixa_nivel_1 * precoUnitario,
            is_caixa_2: produto.unidades_caixa_nivel_2 > 0,
            preco_caixa_2:
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              precoUnitario,
            is_caixa_3: produto.unidades_caixa_nivel_3 > 0,
            preco_caixa_3:
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3 *
              precoUnitario,
          };
        }
        case POR_PESO_VARIAVEL_SEM_UNIDADE: {
          return {
            is_caixa_1: produto.peso_caixa_nivel_1 > ZERO_APROXIMADO,
            preco_caixa_1: produto.peso_caixa_nivel_1 * precoUnitario,
            is_caixa_2: produto.unidades_caixa_nivel_2 > 0,
            preco_caixa_2:
              produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              precoUnitario,
            is_caixa_3: produto.unidades_caixa_nivel_3 > 0,
            preco_caixa_3:
              produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3 *
              precoUnitario,
          };
        }
        case POR_PESO_VARIAVEL: {
          return {
            is_caixa_1: false,
            preco_caixa_1: 0,
            is_caixa_2: false,
            preco_caixa_2: 0,
            is_caixa_3: false,
            preco_caixa_3: 0,
          };
        }
        case POR_PESO_FIXO: {
          return {
            is_caixa_1: produto.unidades_caixa_nivel_1 > 0,
            preco_caixa_1:
              produto.unidades_caixa_nivel_1 *
              precoUnitario *
              produto.peso_medio,
            is_caixa_2: produto.unidades_caixa_nivel_2 > 0,
            preco_caixa_2:
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              precoUnitario *
              produto.peso_medio,
            is_caixa_3: produto.unidades_caixa_nivel_3 > 0,
            preco_caixa_3:
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3 *
              precoUnitario *
              produto.peso_medio,
          };
        }
        default:
          return {
            is_caixa_1: false,
            preco_caixa_1: 0,
            is_caixa_2: false,
            preco_caixa_2: 0,
            is_caixa_3: false,
            preco_caixa_3: 0,
          };
      }
    }
    return {
      is_caixa_1: false,
      preco_caixa_1: 0,
      is_caixa_2: false,
      preco_caixa_2: 0,
      is_caixa_3: false,
      preco_caixa_3: 0,
    };
  }

  const {
    is_caixa_1,
    is_caixa_2,
    is_caixa_3,
    preco_caixa_1,
    preco_caixa_2,
    preco_caixa_3,
  } = getDados2(valor, produto);

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        //fullWidth
        //maxWidth={tamanho}
        fullScreen
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={() => handleCloseDialog(0)}
          style={{ backgroundColor: '#00796b', color: 'white' }}
        >
          {id <= 0 ? `Nova Liberação de preço` : `Editar Liberação de preço`}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '20px 36px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                {produto && (
                  <>
                    <div
                      style={{
                        marginTop: '20px',
                      }}
                    >
                      <h2
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        {nomeProduto}
                      </h2>
                    </div>

                    <div>
                      <div style={{ marginTop: '15px' }}>
                        <PrecoInput
                          name="precoVenda"
                          handleEnter={() => {}}
                          label="Preço de Venda"
                          handleKey={() => {}}
                          value={
                            Number.isNaN(produto.preco_venda)
                              ? ''
                              : String(produto.preco_venda)
                          }
                          onChange={(value) => {}}
                          fullWidth
                          variant="standard"
                          disabled
                        />
                      </div>
                      {isOferta && (
                        <div style={{ marginTop: '15px' }}>
                          <PrecoInput
                            name="precoOferta"
                            handleEnter={() => {}}
                            label="Preço de Oferta"
                            handleKey={() => {}}
                            value={
                              Number.isNaN(produto.preco_oferta)
                                ? ''
                                : String(produto.preco_oferta)
                            }
                            onChange={(value) => {}}
                            fullWidth
                            variant="standard"
                            disabled
                          />
                        </div>
                      )}
                      <div
                        style={{
                          margin: '10px 0',
                          display: 'flex',
                          justifyContent: 'center',
                          gap: '10px',
                        }}
                      >
                        <h2
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          Dados do Cliente
                        </h2>
                        <Tooltip
                          title="Editar Cliente"
                          placement="bottom"
                          aria-label="add2"
                        >
                          <IconButton
                            aria-label="editar"
                            size="small"
                            onClick={editarCliente}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                      {cliente ? (
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          flex={1}
                          style={{
                            border: `1px solid ${PRIMARY_COLOR}`,
                            marginLeft: '0px',
                            padding: '5px',
                            borderRadius: '5px',
                            marginBottom: '10px',
                          }}
                        >
                          <Box
                            marginLeft="10px"
                            display="flex"
                            flexDirection="column"
                            flex={1}
                          >
                            <Box
                              marginLeft="0px"
                              fontSize="15px"
                              fontWeight="bold"
                              display="flex"
                              flex={1}
                            >
                              <p
                                className={classes.textOverflow}
                                style={{
                                  marginBottom: '0px',
                                  color: colorHeader,
                                  marginRight: '10px',
                                }}
                              >
                                Responsável:
                              </p>
                              {cliente.nome}
                            </Box>
                            <Box
                              marginLeft="0px"
                              fontSize="15px"
                              fontWeight="bold"
                              display="flex"
                              flex={1}
                            >
                              <p
                                className={classes.textOverflow}
                                style={{
                                  marginBottom: '0px',
                                  color: colorHeader,
                                  marginRight: '10px',
                                }}
                              >
                                Razão Social:
                              </p>
                              {cliente.razao_social}
                            </Box>
                            <Box
                              marginLeft="0px"
                              fontSize="15px"
                              fontWeight="bold"
                              display="flex"
                              flex={1}
                            >
                              <p
                                className={classes.textOverflow}
                                style={{
                                  marginBottom: '0px',
                                  color: colorHeader,
                                  marginRight: '10px',
                                }}
                              >
                                CPF:
                              </p>
                              {formatCPF(cliente.cpf)}
                            </Box>
                            <Box
                              marginLeft="0px"
                              fontSize="15px"
                              fontWeight="bold"
                              display="flex"
                              flex={1}
                            >
                              <p
                                className={classes.textOverflow}
                                style={{
                                  marginBottom: '0px',
                                  color: colorHeader,
                                  marginRight: '10px',
                                }}
                              >
                                CNPJ:
                              </p>
                              {formatCNPJ(cliente.cnpj)}
                            </Box>
                          </Box>

                          <Box
                            marginLeft="10px"
                            display="flex"
                            flexDirection="column"
                            flex={1}
                          >
                            <Box
                              marginLeft="0px"
                              fontSize="15px"
                              fontWeight="bold"
                              display="flex"
                              flex={1}
                              justifyContent="flex-start"
                            >
                              <p
                                className={classes.textOverflow}
                                style={{
                                  marginBottom: '0px',
                                  color: colorHeader,
                                  marginRight: '10px',
                                }}
                              >
                                Nome Fantasia:
                              </p>
                              {cliente.nome_fantasia}
                            </Box>
                            <Box
                              marginLeft="0px"
                              fontSize="15px"
                              fontWeight="bold"
                              display="flex"
                              flex={1}
                              justifyContent="flex-start"
                            >
                              <p
                                className={classes.textOverflow}
                                style={{
                                  marginBottom: '0px',
                                  color: colorHeader,
                                  marginRight: '10px',
                                }}
                              >
                                Bairro:
                              </p>
                              {cliente.bairro}
                            </Box>
                            <Box
                              marginLeft="0px"
                              fontSize="15px"
                              fontWeight="bold"
                              display="flex"
                              flex={1}
                              justifyContent="flex-start"
                            >
                              <p
                                className={classes.textOverflow}
                                style={{
                                  marginBottom: '0px',
                                  color: colorHeader,
                                  marginRight: '10px',
                                }}
                              >
                                Cidade:
                              </p>
                              {cliente.cidade
                                ? `${cliente.cidade.nome} - ${cliente.cidade.estado.sigla}`
                                : ''}
                            </Box>
                            <Box
                              marginLeft="0px"
                              fontSize="15px"
                              fontWeight="bold"
                              display="flex"
                              flex={1}
                              justifyContent="flex-start"
                            >
                              <p
                                className={classes.textOverflow}
                                style={{
                                  marginBottom: '0px',
                                  color: colorHeader,
                                  marginRight: '10px',
                                }}
                              >
                                Logradouro:
                              </p>
                              {cliente.logradouro}
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <div style={{ color: 'red' }}>
                          Nenhum Cliente selecionado
                        </div>
                      )}
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            //justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: '10px',
                            marginTop: '15px',
                            flex: 1,
                          }}
                        >
                          <Typography
                            variant="h6"
                            gutterBottom
                            style={{
                              fontSize: '15px',
                              //marginRight: '10px',
                            }}
                          >
                            Tipo Unidade:
                          </Typography>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={tipoUnidadeOferta}
                            onChange={handleChange}
                          >
                            {optionsTipoUnidadeOferta.map(
                              ({ label, value }) => (
                                <MenuItem value={value}>{label}</MenuItem>
                              ),
                            )}
                          </Select>
                        </div>
                        <div
                          style={{
                            flex: 1,
                            display: 'flex',

                            justifyContent: 'flex-end',
                          }}
                        >
                          {qtdeDisponivel >= 1 ? (
                            <SwitchInput
                              handleEnter={() => {}}
                              handleKey={() => {}}
                              label="Incluir Verba"
                              checked={isVerbaProduto}
                              onChange={(e) =>
                                setIsVerbaProduto2(e.target.checked)
                              }
                            />
                          ) : (
                            <div
                              style={{
                                color: 'red',
                                fontWeight: 'bold',
                              }}
                            >
                              Nenhuma verba disponível para este produto!
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          marginTop: '0px',
                          display: 'flex',
                          gap: '10px',
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                            display: 'flex',
                          }}
                        >
                          {/*!isInputKg() ? (
                            <IntegerInput
                              name="quantidade"
                              handleEnter={() => {}}
                              label={`Quantidade (${getLabelTipoUnidade(
                                tipoUnidadeOferta,
                              )})`}
                              handleKey={() => {}}
                              value={Number.isNaN(qtde) ? '' : String(qtde)}
                              onChange={(value) =>
                                setQtde(parseInt(value.target.value, 10))
                              }
                              error={erros[2] !== ''}
                              helperText={erros[2]}
                              variant="standard"
                              fullWidth
                            />
                          ) : (
                            <PesoInput
                              name="quantidade"
                              handleEnter={() => {}}
                              label={`Quantidade (${getLabelTipoUnidade(
                                tipoUnidadeOferta,
                              )})`}
                              handleKey={() => {}}
                              value={Number.isNaN(qtde) ? '' : String(qtde)}
                              onChange={(value) =>
                                setQtde(parseFloat(value.target.value))
                              }
                              error={erros[2] !== ''}
                              helperText={erros[2]}
                              variant="standard"
                              fullWidth
                            />
                            )*/}

                          {!isInputKg() ? (
                            <IntegerInput
                              name="quantidade"
                              handleEnter={() => {}}
                              label={`Quantidade (${getLabelTipoUnidade(
                                tipoUnidadeOferta,
                              )})`}
                              handleKey={() => {}}
                              value={Number.isNaN(qtde) ? '' : String(qtde)}
                              onChange={(value) =>
                                setQtde(parseInt(value.target.value, 10))
                              }
                              error={erros[2] !== ''}
                              helperText={erros[2]}
                              variant="standard"
                              fullWidth
                            />
                          ) : (
                            <WeightInput
                              name="quantidade"
                              handleEnter={() => {}}
                              label={`Quantidade (${getLabelTipoUnidade(
                                tipoUnidadeOferta,
                              )})`}
                              handleKey={() => {}}
                              value={qtde}
                              onChange={(value) => setQtde(value.target.value)}
                              error={erros[2] !== ''}
                              helperText={erros[2]}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        </div>
                        <div
                          style={{
                            flex: 1,
                            display: 'flex',
                          }}
                        >
                          <PrecoInput
                            name="verba_unitaria"
                            handleEnter={() => {}}
                            label="Verba Unitária"
                            handleKey={() => {}}
                            value={
                              Number.isNaN(verbaUnitaria)
                                ? ''
                                : String(verbaUnitaria)
                            }
                            onChange={(value) => {
                              //setVerbaUnitaria(parseFloat(value.target.value));
                            }}
                            fullWidth
                            variant="standard"
                            error={erros[4] !== ''}
                            helperText={erros[4]}
                            disabled
                          />
                        </div>
                        <div
                          style={{
                            flex: 1,
                            display: 'flex',
                          }}
                        >
                          <PrecoInput
                            name="precoOferta"
                            handleEnter={() => {}}
                            label="Precificação"
                            handleKey={() => {}}
                            value={Number.isNaN(valor) ? '' : String(valor)}
                            onChange={(value) => {
                              setValor(parseFloat(value.target.value));
                            }}
                            error={erros[1] !== ''}
                            helperText={erros[1]}
                            fullWidth
                            variant="standard"
                            autoFocus
                            disabled={valorNegociadoComAIndustria > 0}
                          />
                          <Tooltip
                            title="Editar Preço"
                            placement="bottom"
                            aria-label="add2"
                          >
                            <IconButton
                              aria-label="editar"
                              size="small"
                              onClick={() => editarPrecificacao()}
                              disabled={valorNegociadoComAIndustria <= 0}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                        {/*<div
                          style={{
                            flex: 1,
                          }}
                        >
                          <PrecoInput
                            name="total"
                            handleEnter={() => {}}
                            label="Preço Liberado"
                            handleKey={() => {}}
                            value={
                              Number.isNaN(valor - verbaUnitaria)
                                ? ''
                                : String(valor - verbaUnitaria)
                            }
                            onChange={(value) => {}}
                            fullWidth
                            variant="standard"
                            disabled
                          />
                          </div>*/}
                      </div>
                      {/*<div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: '10px',
                        }}
                      >
                        <PrecoInput
                          name="total"
                          handleEnter={() => {}}
                          label="Desconto Unitário"
                          handleKey={() => {}}
                          value={Number.isNaN(desconto) ? '' : String(desconto)}
                          onChange={(value) => {}}
                          fullWidth
                          variant="standard"
                          disabled
                        />
                        <PrecoInput
                          name="total"
                          handleEnter={() => {}}
                          label="Desconto Total"
                          handleKey={() => {}}
                          value={
                            Number.isNaN(descontoTotal)
                              ? ''
                              : String(descontoTotal)
                          }
                          onChange={(value) => {}}
                          fullWidth
                          variant="standard"
                          disabled
                        />
                        </div>*/}
                      {/*<div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: '10px',
                        }}
                      >
                        <PrecoInput
                          name="verba_unitaria"
                          handleEnter={() => {}}
                          label="Verba Unitária"
                          handleKey={() => {}}
                          value={
                            Number.isNaN(verbaUnitaria)
                              ? ''
                              : String(verbaUnitaria)
                          }
                          onChange={(value) => {
                            setVerbaUnitaria(parseFloat(value.target.value));
                          }}
                          fullWidth
                          variant="standard"
                          error={erros[4] !== ''}
                          helperText={erros[4]}
                        />
                        <PrecoInput
                          name="total"
                          handleEnter={() => {}}
                          label="Verba Total"
                          handleKey={() => {}}
                          value={
                            Number.isNaN(verbaTotal) ? '' : String(verbaTotal)
                          }
                          onChange={(value) => {}}
                          fullWidth
                          variant="standard"
                          disabled
                        />
                        </div>*/}

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: '10px',
                        }}
                      />
                      {/*<div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: '10px',
                        }}
                      >
                        <PrecoInput
                          name="total"
                          handleEnter={() => {}}
                          label="Total Sem Desconto"
                          handleKey={() => {}}
                          value={Number.isNaN(total) ? '' : String(total)}
                          onChange={(value) => {}}
                          fullWidth
                          variant="standard"
                          disabled
                        />
                        <PrecoInput
                          name="total"
                          handleEnter={() => {}}
                          label="Total Final"
                          handleKey={() => {}}
                          value={
                            Number.isNaN(total - descontoTotal)
                              ? ''
                              : String(total - descontoTotal)
                          }
                          onChange={(value) => {}}
                          fullWidth
                          variant="standard"
                          disabled
                        />
                        </div>*/}
                      {/*<div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: '10px',
                        }}
                      >
                        {is_caixa_1 ? (
                          <PrecoInput
                            name="precoCaixa"
                            handleEnter={() => {}}
                            label="Preço Caixaria Nível 1"
                            handleKey={() => {}}
                            value={
                              Number.isNaN(preco_caixa_1)
                                ? ''
                                : String(preco_caixa_1)
                            }
                            onChange={(value) => {}}
                            fullWidth
                            variant="standard"
                            disabled
                          />
                        ) : (
                          <div style={{ flex: 1 }} />
                        )}
                        {is_caixa_2 ? (
                          <PrecoInput
                            name="precoCaixa"
                            handleEnter={() => {}}
                            label="Preço Caixaria Nível 2"
                            handleKey={() => {}}
                            value={
                              Number.isNaN(preco_caixa_2)
                                ? ''
                                : String(preco_caixa_2)
                            }
                            onChange={(value) => {}}
                            fullWidth
                            variant="standard"
                            disabled
                          />
                        ) : (
                          <div style={{ flex: 1 }} />
                        )}
                        {is_caixa_3 ? (
                          <PrecoInput
                            name="precoCaixa"
                            handleEnter={() => {}}
                            label="Preço Caixaria Nível 3"
                            handleKey={() => {}}
                            value={
                              Number.isNaN(preco_caixa_3)
                                ? ''
                                : String(preco_caixa_3)
                            }
                            onChange={(value) => {}}
                            fullWidth
                            variant="standard"
                            disabled
                          />
                        ) : (
                          <div style={{ flex: 1 }} />
                        )}
                        </div>*/}
                      <div
                        style={{
                          display: 'flex',
                          gap: '10px',
                        }}
                      >
                        <div style={{ marginTop: '15px', flex: 1 }}>
                          <DateTimeInputBonito
                            showTodayButton
                            todayLabel="Hoje"
                            name="dataMinima"
                            handleEnter={() => {}}
                            label="Horário de Início"
                            handleKey={() => {}}
                            value={dataMinima}
                            onChange={(value) => setDataMinima(value)}
                            format="dd MMMM yyyy - HH:mm"
                            fullWidth
                            variant="standard"
                            maxDate={dataMaxima}
                            disabled
                          />
                        </div>
                        <div
                          style={{
                            marginTop: '15px',
                            display: 'flex',
                            flex: 1,
                          }}
                        >
                          <DateTimeInputBonito
                            showTodayButton
                            todayLabel="Hoje"
                            name="dataLimite"
                            handleEnter={() => {}}
                            label="Horário Limite"
                            handleKey={() => {}}
                            value={dataMaxima}
                            onChange={(value) => {
                              if (value.getTime() > dataMinima.getTime()) {
                                setDataMaxima(value);
                              }
                            }}
                            format="dd MMMM yyyy - HH:mm"
                            fullWidth
                            variant="standard"
                            minDate={
                              new Date().getTime() > dataMinima.getTime()
                                ? new Date()
                                : dataMinima
                            }
                            disabled
                          />
                          <Tooltip
                            title="Mudar para próximos 15 minutos"
                            placement="bottom"
                            aria-label="add2"
                          >
                            <IconButton
                              aria-label="editar"
                              size="small"
                              onClick={() => addTempo()}
                            >
                              <AlarmIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title="Editar período"
                            placement="bottom"
                            aria-label="add2"
                          >
                            <IconButton
                              aria-label="editar"
                              size="small"
                              onClick={() => editarTempo()}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {tamanho === TAMANHO_GRANDE && (
                <div style={{ flex: 1, display: 'flex', marginLeft: '25px' }}>
                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{
                      backgroundColor: '#00796b',
                    }}
                  />
                  <div style={{ flex: 1, marginLeft: '25px' }}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      Detalhes
                    </Typography>
                    <div
                      style={{
                        display: 'flex',
                        gap: '10px',
                        fontSize: '30px',
                        margin: '20px 0px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                          color: 'red',
                        }}
                      >
                        Preço Liberado:
                      </span>
                      <span>{formatMoeda(valor - verbaUnitaria)}</span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '10px',
                      }}
                    >
                      {is_caixa_1 ? (
                        <PrecoInput
                          name="precoCaixa"
                          handleEnter={() => {}}
                          label="Preço Caixaria Nível 1"
                          handleKey={() => {}}
                          value={
                            Number.isNaN(preco_caixa_1)
                              ? ''
                              : String(preco_caixa_1)
                          }
                          onChange={(value) => {}}
                          fullWidth
                          variant="standard"
                          disabled
                        />
                      ) : (
                        <div style={{ flex: 1 }} />
                      )}
                      {is_caixa_2 ? (
                        <PrecoInput
                          name="precoCaixa"
                          handleEnter={() => {}}
                          label="Preço Caixaria Nível 2"
                          handleKey={() => {}}
                          value={
                            Number.isNaN(preco_caixa_2)
                              ? ''
                              : String(preco_caixa_2)
                          }
                          onChange={(value) => {}}
                          fullWidth
                          variant="standard"
                          disabled
                        />
                      ) : (
                        <div style={{ flex: 1 }} />
                      )}
                      {is_caixa_3 ? (
                        <PrecoInput
                          name="precoCaixa"
                          handleEnter={() => {}}
                          label="Preço Caixaria Nível 3"
                          handleKey={() => {}}
                          value={
                            Number.isNaN(preco_caixa_3)
                              ? ''
                              : String(preco_caixa_3)
                          }
                          onChange={(value) => {}}
                          fullWidth
                          variant="standard"
                          disabled
                        />
                      ) : (
                        <div style={{ flex: 1 }} />
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '10px',
                      }}
                    >
                      <PrecoInput
                        name="total"
                        handleEnter={() => {}}
                        label="Verba Total"
                        handleKey={() => {}}
                        value={
                          Number.isNaN(verbaTotal) ? '' : String(verbaTotal)
                        }
                        onChange={(value) => {}}
                        fullWidth
                        variant="standard"
                        disabled
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '10px',
                      }}
                    >
                      <PrecoInput
                        name="total"
                        handleEnter={() => {}}
                        label="Desconto Unitário"
                        handleKey={() => {}}
                        value={Number.isNaN(desconto) ? '' : String(desconto)}
                        onChange={(value) => {}}
                        fullWidth
                        variant="standard"
                        disabled
                      />
                      <PrecoInput
                        name="total"
                        handleEnter={() => {}}
                        label="Desconto Total"
                        handleKey={() => {}}
                        value={
                          Number.isNaN(descontoTotal)
                            ? ''
                            : String(descontoTotal)
                        }
                        onChange={(value) => {}}
                        fullWidth
                        variant="standard"
                        disabled
                      />
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '10px',
                      }}
                    >
                      <PrecoInput
                        name="total"
                        handleEnter={() => {}}
                        label="Total Sem Desconto"
                        handleKey={() => {}}
                        value={
                          Number.isNaN(totalOriginal)
                            ? ''
                            : String(totalOriginal)
                        }
                        onChange={(value) => {}}
                        fullWidth
                        variant="standard"
                        disabled
                      />
                      <PrecoInput
                        name="total"
                        handleEnter={() => {}}
                        label="Total Final"
                        handleKey={() => {}}
                        value={
                          Number.isNaN(
                            totalOriginal - descontoTotal - verbaTotal,
                          )
                            ? ''
                            : String(totalOriginal - descontoTotal - verbaTotal)
                        }
                        onChange={(value) => {}}
                        fullWidth
                        variant="standard"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={() => handleCloseDialog(id)}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
            variant="contained"
            color="primary"
          >
            Cancelar
          </Button>
          <Box>
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                disabled={erroExistente || carregando}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
              {carregando && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Box>
        </DialogActions>
        <DialogoIntervalo
          ref={refDialogoIntervalo}
          handleSalvar={(value) => {
            const timeNovo = dataMinima.getTime() + value * 60 * 1000;
            const novoData = new Date();
            novoData.setTime(timeNovo);
            setDataMaxima(novoData);
          }}
        />
        <DialogoPrecificacaoNova
          ref={refDialogoPrecificacaoLiberacao}
          quantidadeComprada={quantidadeComprada}
          produto={produto}
          fornecedor={fornecedor}
          config={config}
          dadosPrecificacaoAnterior={dadosPrecificacaoAnterior}
          valorNegociadoComAIndustria={valorNegociadoComAIndustria}
          handleClose={(dados, isMargemCartao) => {
            if (dados) {
              setDadosPrecificacao(dados);
              setValor(dados.preco_de_venda);
              setIsMargemCartao(isMargemCartao);
            }
          }}
        />
        <DialogoSelecionarCliente
          ref={refDialogoSelecionarCliente}
          handleSalvar={(cliente) => setCliente(cliente)}
        />
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;

/*<Typography
                        variant="h6"
                        gutterBottom
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        Ofertas de Jornal Escaladas
                      </Typography>*/
