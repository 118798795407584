import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Typography } from '@material-ui/core';

import {
  formatMoeda,
  POR_UNIDADE,
  round10,
  formatPeso,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  TIPO_UNIDADE_OFERTA_UN,
  TIPO_UNIDADE_OFERTA_CAIXA,
  TIPO_UNIDADE_OFERTA_CAIXETA,
  TIPO_UNIDADE_OFERTA_CAIXA_MAE,
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL,
  TIPO_UNIDADE_OFERTA_KG,
} from '../../../utils';

function getQtdeEfetivaCalculado(produto, tipoUnidadeOferta, qtde) {
  if (produto) {
    const tipo = produto.unidade.tipo;
    const tipoOferta = tipoUnidadeOferta;
    switch (tipo) {
      case POR_UNIDADE: {
        switch (tipoOferta) {
          case TIPO_UNIDADE_OFERTA_UN:
            return qtde;
          case TIPO_UNIDADE_OFERTA_CAIXA:
            return qtde * produto.unidades_caixa_nivel_1;

          case TIPO_UNIDADE_OFERTA_CAIXETA:
            return (
              qtde *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2
            );

          case TIPO_UNIDADE_OFERTA_CAIXA_MAE:
            return (
              qtde *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3
            );

          default:
            return 0;
        }
      }
      case POR_PESO_FIXO: {
        switch (tipoOferta) {
          case TIPO_UNIDADE_OFERTA_UN:
            return qtde;
          case TIPO_UNIDADE_OFERTA_CAIXA:
            return qtde * produto.unidades_caixa_nivel_1;

          case TIPO_UNIDADE_OFERTA_CAIXETA:
            return (
              qtde *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2
            );

          case TIPO_UNIDADE_OFERTA_CAIXA_MAE:
            return (
              qtde *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3
            );

          default:
            return 0;
        }
      }
      case POR_PESO_VARIAVEL: {
        return 0;
      }
      case POR_PESO_VARIAVEL_SEM_UNIDADE: {
        switch (tipoOferta) {
          case TIPO_UNIDADE_OFERTA_KG:
            return qtde;
          case TIPO_UNIDADE_OFERTA_CAIXA:
            return qtde * produto.peso_caixa_nivel_1;

          case TIPO_UNIDADE_OFERTA_CAIXETA:
            return (
              qtde * produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2
            );

          case TIPO_UNIDADE_OFERTA_CAIXA_MAE:
            return (
              qtde *
              produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3
            );

          default:
            return 0;
        }
      }
      default:
        return 0;
    }
  }
  return 0;
}

function getTotal(produto, liberacoes, qtde) {
  let total = 0;

  for (let i = 0; i < liberacoes.length; i++) {
    const liberacao = liberacoes[i];
    if (
      liberacao.status ||
      (!liberacao.status && liberacao.transacao_especial_id)
    ) {
      total += getQtdeEfetivaCalculado(
        produto,
        liberacao.tipo_unidade,
        liberacao.escalonadas[0].quantidade,
      );
    }
  }

  return qtde - total;
}

const QuantidadeField = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  const qtdeDispoivel = record
    ? record.record
      ? getTotal(
          record.record.produto,
          record.record.liberacoes,
          record.record.qtde,
        )
      : 0
    : 0;
  const isTipoUnidade = record
    ? record.record
      ? record.record.produto.unidade
        ? record.record.produto.unidade.tipo !== POR_PESO_VARIAVEL_SEM_UNIDADE
        : true
      : true
    : true;

  const value = isTipoUnidade
    ? `${round10(qtdeDispoivel)} Un`
    : `${formatPeso(round10(qtdeDispoivel, -3))}`;
  return (
    <Typography
      variant="subtitle1"
      style={{
        fontWeight: 'bold',
      }}
    >
      {value}
    </Typography>
  );
};

export default QuantidadeField;
