import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

import { getUsername } from '../../services';

const useStyles = makeStyles({
  avatar: {
    height: 30,
    width: 30,
  },
});

const colors = [
  '#00AA55',
  '#009FD4',
  '#B381B3',
  '#939393',
  '#E3BC00',
  '#D47500',
  '#DC2A2A',
];

const retornaCor = () => {
  const username = getUsername();
  const codigo = parseInt(!username ? 65 : username.charCodeAt(0), 10);
  return colors[codigo % colors.length];
};

const AvatarCustom = () => {
  const classes = useStyles();
  const username = getUsername();
  return (
    <Avatar
      className={classes.avatar}
      style={{ backgroundColor: `${retornaCor()}` }}
    >
      {username == null ? '0' : username.charAt(0)}
    </Avatar>
  );
};

export default AvatarCustom;
