import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Typography } from '@material-ui/core';

function getTempoRestante(data) {
  const timeDataLimite = new Date(data).getTime();
  const timeDataAtual = new Date().getTime();

  const tempo = timeDataLimite - timeDataAtual;

  if (tempo > 0) {
    const dias = parseInt(tempo / (24 * 60 * 60 * 1000), 10);
    const resto1 = tempo % (24 * 60 * 60 * 1000);
    const horas = parseInt(resto1 / (60 * 60 * 1000), 10);
    const resto2 = resto1 % (60 * 60 * 1000);
    const minutos = parseInt(resto2 / (60 * 1000), 10);
    const resto3 = resto2 % (60 * 1000);
    const segundos = parseInt(resto3 / 1000, 10);

    if (dias) {
      return `${dias}d, ${horas}h, ${minutos}m e ${segundos}s`;
    }
    if (horas) {
      return `${horas}h, ${minutos}m e ${segundos}s`;
    }
    if (minutos) {
      return `${minutos}m e ${segundos}s`;
    }
    if (segundos) {
      return `${segundos}s`;
    }
    return '';
  }

  return 'Encerrado';
}

const StatusField = (props) => {
  const record = useRecordContext(props);
  const horario = record
    ? record.record
      ? record.record.data_maxima
        ? getTempoRestante(record.record.data_maxima)
        : '-'
      : ''
    : '';

  return <Typography variant="subtitle1">{horario}</Typography>;
};

export default StatusField;
