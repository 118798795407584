import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useSnackbar } from 'notistack';

import { getListAllAPI, api } from '../../../services';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, textAlign = 'left', ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5" style={{ fontWeight: 'bold', textAlign }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: 'black' }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [fatura, setFatura] = useState(null);
  const classes = useStyles();

  const handleCloseDialog = () => {
    setOpen(false);
    setFatura(null);
    handleClose();
  };

  async function getDataResource(cartao_credito_id, mes, ano) {
    try {
      const data = await getListAllAPI(
        'faturas',
        ['id', 'asc'],
        { cartao_credito_id, mes, ano },
        [],
      );
      if (data.data.length > 0) {
        const dataPagamentoFatura = new Date();
        dataPagamentoFatura.setDate(data.data[0].cartao.dia_pagamento);
        dataPagamentoFatura.setMonth(mes);
        dataPagamentoFatura.setFullYear(ano);
        const descricaoFatura = `${format(
          dataPagamentoFatura,
          "dd 'de' MMMM 'de' yyyy",
          {
            locale: ptBR,
          },
        )}`;
        data.data[0].descricao = descricaoFatura;
        setFatura(data.data[0]);
      } else {
        throw 'Esta fatura não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function handleSalvar() {
    try {
      await api.put(
        `/reabrir_fatura/${fatura.cartao_credito_id}/${fatura.mes}/${fatura.ano}`,
      );
      enqueueSnackbar('Fatura aberta com sucesso!', {
        variant: 'success',
      });
      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(cartao_credito_id, mes, ano) {
      getDataResource(cartao_credito_id, mes, ano);
      setOpen(true);
    },
  }));

  function getDadosFatura() {
    if (fatura) {
      return {
        nomeCartao: fatura.cartao.nome,
        nomeFatura: fatura.descricao,
      };
    }
    return {
      nomeCartao: '',
      nomeFatura: '',
    };
  }

  const { nomeCartao, nomeFatura } = getDadosFatura();

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth="xs"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ color: 'black' }}
          textAlign="center"
        >
          {'Reabrir fatura '}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          <div>
            <p
              style={{
                textAlign: 'center',
                marginBottom: '8px',
                color: 'rgba(0, 0, 0, 0.54)',
                fontWeight: 400,
              }}
            >
              {`Você tem certeza que deseja reabrir esta fatura (${nomeFatura} do cartão ${nomeCartao})? `}
            </p>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={handleSalvar}
            variant="contained"
            style={{
              backgroundColor: '#00796b',
              color: 'white',
              borderRadius: '25px',
            }}
            color="primary"
            fullWidth
          >
            REABRIR
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
