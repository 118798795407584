import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import { Box, requirePropFactory } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'notistack';

import { getListAllAPI } from '../../services';
import {
  writeCodigoBarrasCGP,
  formatPeso,
  round10,
  STATUS_ATIVO,
  SECONDARY_COLOR,
} from '../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: SECONDARY_COLOR,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DialogoCategoriaTransacao = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState(-1);
  const [carregando, setCarregando] = useState(false);
  const [produto, setProduto] = useState(null);
  const [numeroCaixas, setNumeroCaixas] = useState(0);
  const [taraUnitaria, setTaraUnitaria] = useState(0);
  const [pesagens, setPesagens] = useState([]);

  const classes = useStyles();

  const handleCloseDialog = () => {
    setOpen(false);
  };

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'conferencias_grandes_pesagens',
        ['id', 'asc'],
        { id: [id] },
        ['produto'],
      );
      if (data.data.length > 0) {
        setProduto(data.data[0].produto);
        setNumeroCaixas(data.data[0].numero_caixas);
        setTaraUnitaria(round10(data.data[0].tara_unitaria * 1000));
        setPesagens(parsePesagens(data.data[0].pesagens));
        setCarregando(false);
      } else {
        throw 'Esta Conferência de Grandes Pesagens não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  function getValoresComputados() {
    const taraTotal = (taraUnitaria / 1000) * numeroCaixas;
    let pesoBrutoTotal = 0;
    for (let i = 0; i < pesagens.length; i++) {
      pesoBrutoTotal += pesagens[i];
    }
    const pesoLiquidoTotal = pesoBrutoTotal - taraTotal;

    return {
      taraTotal,
      pesoBrutoTotal,
      pesoLiquidoTotal,
    };
  }

  const {
    pesoBrutoTotal,
    pesoLiquidoTotal,
    taraTotal,
  } = getValoresComputados();

  function parsePesagens(pesagens) {
    return pesagens.split(';').map((value) => round10(parseFloat(value), -3));
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
    },
  }));

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth="sm"
        //fullScreen
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{
            backgroundColor: '#009FD4',
            color: 'white',
          }}
        >
          Detalhes Conferência Grandes Pesagens
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div
              style={{
                marginTop: '20px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              {produto ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                    }}
                  >
                    <div>
                      <span
                        style={{
                          fontWeight: 'bold',
                          marginRight: '10px',
                        }}
                      >
                        Produto:
                      </span>
                      <span>{`${produto.nome}`}</span>
                    </div>
                    <div>
                      <span
                        style={{
                          fontWeight: 'bold',
                          marginRight: '10px',
                        }}
                      >
                        Cpdigo:
                      </span>
                      <span>{`${writeCodigoBarrasCGP(id)}`}</span>
                    </div>
                    <div>
                      <span
                        style={{
                          fontWeight: 'bold',
                          marginRight: '10px',
                        }}
                      >
                        Tara Unitária:
                      </span>
                      <span>{`${formatPeso(taraUnitaria)} Kg`}</span>
                    </div>
                    <div>
                      <span
                        style={{
                          fontWeight: 'bold',
                          marginRight: '10px',
                        }}
                      >
                        Nº de Caixas:
                      </span>
                      <span>{numeroCaixas}</span>
                    </div>
                    <div>
                      <span
                        style={{
                          fontWeight: 'bold',
                          marginRight: '10px',
                        }}
                      >
                        Tara Total:
                      </span>
                      <span>{`${formatPeso(taraTotal)} Kg`}</span>
                    </div>
                    <div>
                      <span
                        style={{
                          fontWeight: 'bold',
                          marginRight: '10px',
                        }}
                      >
                        Peso Bruto Total:
                      </span>
                      <span>{`${formatPeso(pesoBrutoTotal)} Kg`}</span>
                    </div>
                    <div>
                      <span
                        style={{
                          fontWeight: 'bold',
                          marginRight: '10px',
                        }}
                      >
                        Peso Líquido Total:
                      </span>
                      <span>{`${formatPeso(pesoLiquidoTotal)} Kg`}</span>
                    </div>
                  </div>
                  <div>
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Numero</StyledTableCell>
                            <StyledTableCell>Peso</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {pesagens.map((row, ind) => (
                            <StyledTableRow key={`${ind}-row`}>
                              <StyledTableCell>{ind + 1}</StyledTableCell>
                              <StyledTableCell>
                                {formatPeso(row)}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </>
              ) : null}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
