import * as React from 'react';
import { useRecordContext } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const ActionsField = ({ handleEfetivar, handleNota, ...rest }) => {
  const record = useRecordContext(rest);
  const value = record ? record.record.numero_nota_fiscal : false;
  return (
    <div
      style={{
        marginRight: '0px',
        width: '120px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Tooltip
        title={!value ? 'Marcar como conferido' : 'Marcar como não conferido'}
        placement="bottom"
        aria-label="add2"
      >
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleEfetivar(record)}
        >
          {!value ? <CheckCircleOutlineIcon /> : <CancelIcon />}
        </IconButton>
      </Tooltip>
      <Tooltip title="Ver Nota da venda" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleNota(record)}
        >
          <AssignmentIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};



export default ActionsField;
