import React, { useRef, useState, useEffect, useContext } from 'react';

import { makeStyles, Box, Paper } from '@material-ui/core';

import {
  DialogoDeletarComSenha,
  DialogoSenha,
  DialogoSomaPesos,
  Tabela,
  DialogoConsultorPrecos,
  DialogoConfirmacao,
  AutoCompleteLocal,
} from '../../../Components/DialogoEditarVenda/components';
import {
  //getNomeProduto,
  POR_PESO_VARIAVEL,
  toStringItem,
  ATALHO_AVANCAR,
  ATALHO_VOLTAR,
  POR_UNIDADE,
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
} from '../../../utils';
import { formatPeso } from '../../../utils/formats';
import { VendaContext } from './context';
import DialogoPermissoesFechamento from './DialogoPermissoesFechamento';
import FormItemVenda from './Form';
import useItens from './hooks/useItens';
import LabelCliente from './Labels/LabelCliente';
import LabelEstoque from './Labels/LabelEstoque';
import LabelSubtotal from './Labels/LabelSubtotal';
import LabelTeclasAtalho from './Labels/LabelTeclasAtalho';
import LabelVenda from './Labels/LabelVenda';
import SearchProduto from './SearchProduto';

const useStyles = makeStyles((theme) => ({
  btn: {
    marginTop: '10px',
    opacity: '0.75',
  },
  footer: {
    height: '15vh',
    padding: '10px',
    paddingBottom: '20px',
  },
  headerLivre: {
    height: '17%',
  },
  bodyLivre: {
    height: '83%',
  },
  rootProduto: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50px',
    fontSize: '32px',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    textAlign: 'center',
    opacity: '0.75',
  },
}));

function getDadosCaixa(produto) {
  if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
    return `${formatPeso(produto.itemVenda.pesoCaixa)} Kg`;
  }

  return `${produto.itemVenda.unidadesCaixa} Un`;
}

function getNomeProduto(
  produto,
  mensagem2 = 'NOVO PEDIDO',
  mensagem1 = 'Nenhum Produto',
) {
  if (produto) {
    if (produto.nivelCaixa) {
      switch (produto.nivelCaixa) {
        case 1:
          return `${produto.nome} - cx(${getDadosCaixa(produto)}) - Nível 1`;
        case 2:
          return `${produto.nome} - cx(${getDadosCaixa(produto)}) - Nível 2`;
        case 3:
          return `${produto.nome} - cx(${getDadosCaixa(produto)}) - Nível 3`;
        default:
          return mensagem1;
      }
    } else {
      return produto.nome;
    }
  } else {
    return mensagem2;
  }
}

function getNomeProduto2(
  produto,
  mensagem2 = 'NOVO PEDIDO',
  mensagem1 = 'Nenhum Produto',
) {
  if (produto) {
    return produto.nome;
  }
  return mensagem2;
}

const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const DELETAR_ITEM_ACTION = 'F2';
const CARREGAR_RESTANTE_ACTION = 'F1';

const FrenteVenda = ({ irParaTelaInit }) => {
  const {
    venda: { itens, cliente, vendaId, itensEstoque, subTotal, venda },
    dispatch,
  } = useContext(VendaContext);
  const refDialogoSenha = useRef(null);
  const refDialogoDeletar = useRef(null);
  const refDialogoPermissoesFechamento = useRef(null);

  const refDialogoConsultorPrecos = useRef(null);
  const {
    addNewItem,
    produto,
    refSearch,
    refSidebarInputs,
    enqueueSnackbar,
    selectProduto,
    handleFinalizarVenda,
    changeCaixaria,
    search,
    setSearch,
  } = useItens(itens, updateItem, vendaId, cliente, irParaTelaInit, subTotal);

  const refSidebarPesosPorPartes = useRef(null);
  const refDialogoConfirmacao = useRef(null);

  const classes = useStyles();

  const nomeProduto = getNomeProduto2(produto, 'DEVOLUÇÃO DE VENDA');

  function updateItem(body) {
    dispatch({
      type: 'ADD_ITEM',
      item: body,
    });
    if (refSearch.current) refSearch.current.focus();
  }

  function removeItens(indices) {
    dispatch({ type: 'REMOVE_ITEM', indices });
  }

  function recarregarTodosItens() {
    dispatch({ type: 'CARREGAR_RESTANTE' });
  }

  function openConfirmacaoTelaInit() {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Deseja cancelar a devolução de venda!',
        'Tem certeza que deseja cancelar esta devolução de venda!',
        10,
      );
    }
  }

  function handleActions(action) {
    switch (action) {
      case FECHAR_VENDA_ACTION:
        if (itens.length > 0) {
          if (refDialogoPermissoesFechamento.current) {
            refDialogoPermissoesFechamento.current.handleOpen(cliente.id);
          }
        } else {
          enqueueSnackbar(
            'É necessário ao menos um item na devolução de venda!',
            {
              variant: 'warning',
            },
          );
        }
        break;
      case CANCELAR_VENDA_ACTION:
        openConfirmacaoTelaInit();
        break;
      case DELETAR_ITEM_ACTION:
        if (itens.length > 0) {
          if (refDialogoDeletar.current) {
            refDialogoDeletar.current.handleOpen();
          }
        }
        break;
      case CARREGAR_RESTANTE_ACTION:
        if (refDialogoConfirmacao.current) {
          refDialogoConfirmacao.current.handleOpen(
            'Carregar mercadorias restante!',
            'Tem certeza de que deseja fazer a carga de todos os itens restantes desta venda, isso apagará todos os itens que você registrou nesta devolução de venda?',
            20,
          );
        }
        break;
      default:
        break;
    }
  }

  function handleConfirma(codigo) {
    switch (codigo) {
      case 10:
        irParaTelaInit();
        break;
      case 20:
        recarregarTodosItens();
        break;
      default:
        break;
    }
  }

  const optionsProdutos = itensEstoque
    .filter((item) => !item.usado)
    .map((item) => {
      const { produto, ...rest } = item;
      const newItem = {
        ...produto,
        itemVenda: rest,
      };
      const novoNome = getNomeProduto(newItem);
      newItem.nome = novoNome;
      return newItem;
    });

  return (
    <>
      <Box padding="10px" className={classes.header}>
        <Box margin="0px 0px 10px">
          <Paper className={classes.rootProduto}>{nomeProduto}</Paper>
        </Box>
        {/*<AutoCompleteLocal
          autoFocus
          name="search-input"
          ref={refSearch}
          handleEnter={() => {}}
          label=""
          handleKey={(keyCode, keyName) => {
            handleActions(keyName);
          }}
          value={null}
          onChangeLocal={(value) => selectProduto(value)}
          campo="nome"
          options={optionsProdutos}
          margin="10px"
          fullwidth
          style={{
            backgroundColor: 'white',
          }}
        />*/}

        <SearchProduto
          label="Pesquisar produto"
          value={search}
          onChange={(e) => setSearch(e)}
          fullwidth
          searchHandle={selectProduto}
          ref={refSearch}
          handleKey={(keyCode, keyName) => {
            handleActions(keyName);
          }}
          options={optionsProdutos}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        padding="10px"
        className={classes.body}
      >
        <Box flex={1.5} display="flex" flexDirection="column" overflow="auto">
          <Box>
            {cliente && <LabelCliente cliente={cliente} />}
            {venda && (
              <Box mt="15px">
                <LabelVenda venda={venda} />
              </Box>
            )}
            {produto && (
              <Box mt="15px">
                <LabelEstoque produto={produto} />
              </Box>
            )}
            <Box mt="15px">
              <LabelSubtotal valor={subTotal} />
            </Box>
          </Box>
          <LabelTeclasAtalho
            fontSize="13px"
            grid={[
              [
                { atalho: CANCELAR_VENDA_ACTION, label: 'Voltar' },
                { atalho: FECHAR_VENDA_ACTION, label: 'Concluir' },
              ],
              [
                {
                  atalho: DELETAR_ITEM_ACTION,
                  label: 'Remover item',
                  status: itens.length > 0,
                },
                {
                  atalho: CARREGAR_RESTANTE_ACTION,
                  label: 'Car. Restante',
                },
              ],
            ]}
          />
        </Box>
        <Box padding="0 10px" flex={3}>
          <Tabela
            rows={toStringItem(itens)}
            headCells={[
              {
                field: 'numeroItem',
                label: 'Cód.',
              },
              {
                field: 'produto',
                label: 'Produto',
              },
              {
                field: 'unidades',
                right: true,
                label: 'Unidades',
              },
              {
                field: 'peso',
                right: true,
                label: 'Peso (Kg)',
              },
              {
                field: 'unitario',
                right: true,
                label: 'Unitário',
              },
              {
                field: 'total',
                right: true,
                label: 'SubTotal',
              },
              {
                field: 'isIntegro',
                right: true,
                label: 'Situação',
              },
            ]}
          />
        </Box>
        <Box flex={1.5} display="flex" flexDirection="column" overflow="auto">
          <FormItemVenda
            handleKey={(keyCode, keyName) => {
              handleActions(keyName);
            }}
            handleNewItem={addNewItem}
            disabledSubmit={produto === null}
            ref={refSidebarInputs}
            produto={produto}
            itensEstoque={itensEstoque}
          />
        </Box>
      </Box>
      <DialogoSenha
        ref={refDialogoSenha}
        handleClose={() => {}}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={() => {
          irParaTelaInit();
        }}
        title="Autorização cancelamento de retirada de venda!"
      />
      <DialogoDeletarComSenha
        ref={refDialogoDeletar}
        itens={itens}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={(numeroItem) => {
          //removeItens([itens[numeroItem - 1].uidd]);
          removeItens([numeroItem - 1]);
        }}
        title="Autorização para remover item!"
      />
      <DialogoConsultorPrecos ref={refDialogoConsultorPrecos} />
      <DialogoSomaPesos
        ref={refSidebarPesosPorPartes}
        onChangeTotal={(totais) => {
          if (refSidebarInputs.current) {
            refSidebarInputs.current.redefineValues(
              totais.unidades,
              totais.peso,
            );
          }
        }}
      />
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={handleConfirma}
      />
      <DialogoPermissoesFechamento
        ref={refDialogoPermissoesFechamento}
        handlePermitido={(responsavel_id, tipo_parcela, conta_id) => {
          handleFinalizarVenda(responsavel_id, tipo_parcela, conta_id);
        }}
        subTotal={subTotal}
      />
    </>
  );
};

export default FrenteVenda;
