import { renameItensUIDD, round10 } from '../../../../utils';
import {
  tipoStatusAndamentoCriarPedido,
  tipoStatusAndamentoAprovar,
  tipoStatusAndamentoAprovarVolta,
} from '../utils/constants';

const reducer = (state, action) => {
  let arrayNew = state.itens.slice();
  switch (action.type) {
    case 'ADD_ITEM': {
      const newArray = [...arrayNew, action.item];
      return {
        ...state,
        itens: [...renameItensUIDD(newArray)],
      };
    }
    case 'REMOVE_ITEM': {
      for (let i = 0; i < action.indices.length; i += 1) {
        arrayNew = arrayNew.filter((obj) => obj.uidd !== action.indices[i]);
      }
      return {
        ...state,
        itens: [...renameItensUIDD(arrayNew)],
      };
    }
    case 'UPDATE_ITEM': {
      const index = arrayNew.findIndex((item) => item.uidd === action.uuid);
      if (index >= 0) {
        if (action.tipoStatusAndamento === tipoStatusAndamentoCriarPedido) {
          arrayNew[index] = {
            ...arrayNew[index],
            produto: action.item.produto,
            dadosPrecificacao: action.item.dadosPrecificacao,
            valor: action.item.valor,
            tipo_unidade: action.item.tipo_unidade,
            verba_unitaria: action.item.verba_unitaria,
            verba_unitaria_solicitado: action.item.verba_unitaria,
            quantidade: action.item.quantidade,
            status_aprovado: true,
            status_aprovado_volta: null,
            isModificado: false,
            tipo_unidade_quantidade: action.item.tipo_unidade_quantidade,
            tipo_unidade_preco_sugerido:
              action.item.tipo_unidade_preco_sugerido,
          };
        } else if (action.tipoStatusAndamento === tipoStatusAndamentoAprovar) {
          arrayNew[index] = {
            ...arrayNew[index],
            verba_unitaria_solicitado: action.item.verba_unitaria,
            isModificado: false,
            status_aprovado: true,
            status_aprovado_volta: null,
            is_fim_negociacao: action.item.is_fim_negociacao,
          };
        } else if (
          action.tipoStatusAndamento === tipoStatusAndamentoAprovarVolta
        ) {
          arrayNew[index] = {
            ...arrayNew[index],
            valor: action.item.valor,
            status_aprovado: true,
            status_aprovado_volta: action.item.status_aprovado_volta,
            isModificado:
              round10(arrayNew[index].verba_unitaria_solicitado_old, -2) !==
              round10(action.item.verba_unitaria, -2),
            verba_unitaria: action.item.verba_unitaria,
            //verba_unitaria_solicitado: action.item.verba_unitaria,
          };
        }
        return {
          ...state,
          itens: [...renameItensUIDD(arrayNew)],
        };
      }
      return {
        ...state,
      };
    }
    case 'UPDATE_ITENS': {
      return {
        ...state,
        itens: [...renameItensUIDD(action.itens)],
      };
    }
    case 'UPDATE_ID_EDIT':
      return {
        ...state,
        idEdit: action.idEdit,
      };
    case 'RESETAR_TUDO':
      return {
        ...state,
        cliente: null,
        itens: [],
        isMargemCartao: true,
        idEdit: -1,
        carregando: false,
        dataMinima: new Date(),
        dataMaxima: new Date(),
      };
    case 'PREENCHER_TUDO': {
      return {
        ...state,
        idEdit: action.idEdit,
        itens: [...renameItensUIDD(action.itens)],
        cliente: action.cliente,
        isMargemCartao: action.isMargemCartao,
        carregando: false,
        dataMinima: action.dataMinima,
        dataMaxima: action.dataMaxima,
        tipoStatusAndamento: action.tipoStatusAndamento,
      };
    }
    case 'UPDATE_DADOS_TELA_INICIAL': {
      return {
        ...state,
        cliente: action.cliente,
        isMargemCartao: action.isMargemCartao,
        dataMinima: action.dataMinima,
        dataMaxima: action.dataMaxima,
      };
    }
    default:
      return state;
  }
};

export default reducer;
