import React, {
  forwardRef, useImperativeHandle,
} from 'react';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { makeStyles } from '@material-ui/core/styles';

const FECHAR_VENDA_ACTION = 'F8';
const CANCELAR_VENDA_ACTION = 'F4';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const DialogoDeletar = forwardRef(({
  handleDelete = () => {},
  handleCloseDialogo = () => {},
  labelResource = '',
  itens,
  getListItemContent,
  headerDialogo,
}, ref) => {
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState([]);
  const classes = useStyles();

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
    },
  }));

  function handleClose() {
    setOpen(false);
    handleCloseDialogo();
  }

  function handleConfirmar() {
    handleDelete(checked);
    setChecked([]);
    setOpen(false);
  }

  function handleActions(action) {
    switch (action) {
      case FECHAR_VENDA_ACTION:
        handleConfirmar();
        break;
      case CANCELAR_VENDA_ACTION:
        handleClose();
        break;
      default:
        break;
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      scroll="paper"
      fullScreen
    >
      <DialogTitle id="form-dialog-title">
        {`Deletar ${labelResource}`}
      </DialogTitle>
      <DialogContent
        dividers
        style={{ flex: 1 }}
      >
        <div style={{ height: '100%' }}>
          {headerDialogo}
          <List className={classes.root}>
            {itens.map((value, index) => {
              const labelId = `checkbox-list-label-${value.uidd}`;

              return (
                <>
                  <ListItem
                    key={value}
                    role={undefined}
                    dense
                    button
                    onClick={handleToggle(value.uidd)}
                    onKeyDown={(e) => {
                      handleActions(e.key);
                    }}
                    autoFocus={index === 0}
                    style={{ margin: 0, padding: 0 }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked.indexOf(value.uidd) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    {getListItemContent(value, labelId, index)}
                  </ListItem>
                  { (index !== itens.length - 1) && <Divider variant="inset" component="li" />}
                </>
              );
            })}
          </List>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar (F4)
        </Button>
        <Button
          color="primary"
          onClick={handleConfirmar}
        >
          Confirmar (F8)
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default DialogoDeletar;
