import * as React from 'react';
import { useRecordContext } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIcon from '@material-ui/icons/Assignment';

const ActionsField = ({
  handleImportar = () => {},
  handlePdf = () => {},
  handleManifestacao = () => {},
  ...rest
}) => {
  const record = useRecordContext(rest);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Tooltip title="exibir PDF" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="edit"
          size="small"
          onClick={() => handlePdf(record.record)}
        >
          <AssignmentIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ActionsField;
