import {
  POR_PESO_VARIAVEL,
  POR_PESO_FIXO,
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
} from '../../utils';

const choices = [
  { id: POR_PESO_VARIAVEL, name: 'Por Peso Variável' },
  { id: POR_PESO_FIXO, name: 'Por Peso Fixo' },
  { id: POR_UNIDADE, name: 'Por Unidade' },
  { id: POR_PESO_VARIAVEL_SEM_UNIDADE, name: 'Por Peso Variável Sem Unidade' },
];

export default choices;
