import React, { forwardRef, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { useDebounce } from 'use-debounce';

import AutoCompleteProduto from './Template';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      opacity: '0.75',
    },
    iconButton: {
      padding: 10,
      color: theme.palette.primary.main,
    },
  }),
);

const Search = forwardRef(
  (
    { label, value, onChange, searchHandle, handleKey = () => {}, options },
    forwardedRef,
  ) => {
    const classes = useStyles();
    const [valueDebounce] = useDebounce(value, 500);
    const [produto, setProduto] = useState(null);

    function submitProduto() {
      if (produto) {
        searchHandle(produto);
      } else {
        searchHandle(null);
      }
      setProduto(null);
    }

    function keyEvent(keyCode, keyName) {
      if (keyCode === 13) {
        submitProduto();
      } else {
        handleKey(keyCode, keyName);
      }
    }

    return (
      <Paper
        component="form"
        className={classes.root}
        onSubmit={(e) => e.preventDefault()}
      >
        <>
          <AutoCompleteProduto
            inputValue={value}
            onChange={(produtoNew) => setProduto(produtoNew)}
            updateValue={(newValue) => onChange(newValue)}
            value={produto}
            label={label}
            valueDebounce={valueDebounce}
            handleKey={keyEvent}
            ref={forwardedRef}
            options={options}
          />
          <IconButton
            className={classes.iconButton}
            aria-label="search"
            onClick={() => submitProduto()}
            tabindex="-1"
          >
            <SearchIcon />
          </IconButton>
        </>
      </Paper>
    );
  },
);

export default Search;
