import React from 'react';

import { makeStyles, Box, Button, Paper } from '@material-ui/core';
//import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  btn: {
    marginBottom: '10px',
  },
  container: {
    opacity: '0.75',
  },
}));

const Actions = ({ onClick, opcoes }) => {
  const classes = useStyles();
  return (
    <Paper elevation={3} className={classes.container}>
      <Box display="flex" flexDirection="column" padding="15px" height="100%">
        {opcoes.map(
          (item) =>
            !item.hide && (
              <Button
                variant="contained"
                color="secondary"
                //className={clsx(item.className, classes.btn)}
                onClick={() => onClick(item.value)}
                disabled={item.disabled}
                tabindex="-1"
              >
                {item.text}
              </Button>
            ),
        )}
      </Box>
    </Paper>
  );
};

export default Actions;
