import React from 'react';
import { TextInput } from 'react-admin';

import { Box } from '@material-ui/core';

import { PrecoInput } from '../../Components/InputsReactAdmin';

const Form = (props) => (
  <Box p="1em">
    <Box display="flex">
      <Box flex={1} mr="0.5em">
        <TextInput
          source="nome"
          autoFocus
          label="Descrição"
          fullWidth
          {...props}
        />
      </Box>
    </Box>
    <Box display="flex">
      <Box flex={1} mr="0.5em">
        <PrecoInput
          source="preco_venda"
          label="Preço de Venda"
          fullWidth
          {...props}
        />
      </Box>
      <Box flex={1} mr="0.5em">
        <PrecoInput
          source="preco_revenda"
          label="Preço de Revenda"
          fullWidth
          {...props}
        />
      </Box>
    </Box>
  </Box>
);

export default Form;
