import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '400px',
    // color: 'red',
  },
}));

const DataNegociadaField = (props) => {
  const classes = useStyles();

  const record = useRecordContext(props);

  let value = '-';

  if (record) {
    const dataReal = new Date(
      moment(new Date(record.record.data_pagamento)).endOf('day').format(),
    );

    const dataOriginal = new Date(
      moment(new Date(record.record.data_pagamento_original))
        .endOf('day')
        .format(),
    );

    if (dataReal.getTime() > dataOriginal.getTime()) {
      const delta = dataReal.getTime() - dataOriginal.getTime();

      value = parseInt(delta / (24 * 60 * 60 * 1000), 10);
    }
  }

  return (
    <Tooltip
      title="Este indicador calcula a diferença em dias entre a data real de pagamento e a data original dada pelo fornecedor."
      placement="bottom"
      aria-label="add2"
    >
      <Typography className={classes.text}>{value}</Typography>
    </Tooltip>
  );
};

export default DataNegociadaField;
