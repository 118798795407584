import React from 'react';

import { Box } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';

import BackButton from '../BackButton';

const ContainerActionsBack = ({
  basePath,
  data,
  actions,
  isBack,
  routeBack = '',
  ...rest
}) => {
  const actionsPersonal = React.cloneElement(actions, {
    basePath,
    data,
    ...rest,
  });
  return (
    <>
      {isBack ? (
        <Box display="flex" justifyContent="space-between">
          <CardActions>
            <BackButton routeBack={routeBack} />
          </CardActions>
          <Box display="flex" justifyContent="flex-end">
            {actionsPersonal}
          </Box>
        </Box>
      ) : (
        <>{actionsPersonal}</>
      )}
    </>
  );
};

export default ContainerActionsBack;
