import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Typography } from '@material-ui/core';
import moment from 'moment';

const diasDaSemana = [
  'Domingo',
  'Segunda-Feira',
  'Terça-Feira',
  'Quarta-Feira',
  'Quinta-Feira',
  'Sexta-Feira',
  'Sábado',
];

const DataMaximaField = (props) => {
  const record = useRecordContext(props);
  const horario = record ? (record.record ? record.record.id : 0) : 0;

  return <Typography variant="subtitle1">{horario}</Typography>;
};

export default DataMaximaField;
