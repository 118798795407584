import React, { useState, useEffect, useRef } from 'react';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { TrainRounded } from '@material-ui/icons';
import AdjustIcon from '@material-ui/icons/Adjust';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

import { AutoCompleteLocal } from '../../../Components';
import useDebounce from '../../../hooks/useDebounce';
import { api, getListAllAPI } from '../../../services';
import {
  POR_UNIDADE,
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  formatPeso,
  STATUS_ATIVO,
} from '../../../utils';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {},
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  pendente: {
    //width: theme.spacing(3),
    //height: theme.spacing(3),
    //color: 'white',
    //backgroundColor: '#f44336',
    color: '#f44336',
    cursor: 'pointer',
    border: '2px solid #f44336',
    borderRadius: '50%',
  },
  confirmado: {
    //width: theme.spacing(3),
    //height: theme.spacing(3),
    //color: 'white',
    //backgroundColor: '#4caf50',
    color: '#4caf50',
    cursor: 'pointer',
    border: '2px solid #4caf50',
    borderRadius: '50%',
  },
  cartao: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#00796b',
    marginLeft: '10px',
  },
}));

const Filters = ({
  handleFilters = () => {},
  folha,
  handleEfetivar = () => {},
  handleCompensacoes = () => {},
  handleRelatorioPresencasDia = () => {},
}) => {
  const [colaboradorSearch, setColaboradorSearch] = useState('');
  const [showColaboradorSearch, setShowColaboradorSearch] = useState(false);
  const [colaboradorSearchDebounce] = useDebounce(colaboradorSearch, 1000);
  const [showColaboradorSearchDebounce] = useDebounce(
    showColaboradorSearch,
    500,
  );

  const [status, setStatus] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [statusDebounce] = useDebounce(status, 1000);
  const [showStatusDebounce] = useDebounce(showStatus, 500);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const iconButtonRef = useRef(null);

  const classes = useStyles();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    function getData() {
      const filters = {};
      if (showColaboradorSearchDebounce)
        filters.colaboradorSearch = colaboradorSearchDebounce;

      if (showStatusDebounce) filters.status = statusDebounce;

      handleFilters(filters);
    }
    getData();
  }, [
    colaboradorSearchDebounce,
    showColaboradorSearchDebounce,
    statusDebounce,
    showStatusDebounce,
  ]);

  const statusCheck = folha ? folha.status : false;

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          {showStatus && (
            <div style={{ marginRight: '20px' }}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => setShowStatus(false)}
              >
                <CloseIcon />
              </IconButton>
              <FormControlLabel
                control={
                  <Switch
                    checked={status}
                    onChange={(e) => setStatus(e.target.checked)}
                    name="checkedA"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    margin="normal"
                  />
                }
                label="Status"
                value="start"
                labelPlacement="top"
                style={{ margin: 0 }}
              />
            </div>
          )}
          {showColaboradorSearch && (
            <div style={{ marginRight: '20px' }}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => setShowColaboradorSearch(false)}
              >
                <CloseIcon />
              </IconButton>
              <TextField
                id="colaborador"
                label="colaborador"
                variant="outlined"
                value={colaboradorSearch}
                onChange={(e) => setColaboradorSearch(e.target.value)}
                margin="normal"
              />
            </div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            gap: '10px',
          }}
        >
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            ref={iconButtonRef}
            onClick={handleClick}
          >
            <FilterListIcon />
          </IconButton>
          {statusCheck ? (
            <Tooltip
              title="Reabrir Dados Mês"
              placement="bottom"
              aria-label="add2"
            >
              <IconButton component="span" onClick={() => handleEfetivar()}>
                <CheckIcon className={classes.confirmado} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              title="Fechar Dados desse mês"
              placement="bottom"
              aria-label="add2"
            >
              <IconButton component="span" onClick={() => handleEfetivar()}>
                <PriorityHighIcon className={classes.pendente} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Compensações de Feriados">
            <IconButton
              aria-label="detalhes"
              onClick={() => handleCompensacoes()}
            >
              <AdjustIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Relatório Presenças Diário">
            <IconButton
              aria-label="detalhes"
              onClick={() => handleRelatorioPresencasDia()}
            >
              <AssignmentIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!showColaboradorSearch && (
          <StyledMenuItem onClick={() => setShowColaboradorSearch(true)}>
            <ListItemText primary="Colaborador" />
          </StyledMenuItem>
        )}
        {!showStatus && (
          <StyledMenuItem onClick={() => setShowStatus(true)}>
            <ListItemText primary="Status" />
          </StyledMenuItem>
        )}
      </StyledMenu>
    </>
  );
};

export default Filters;
