import React, { cloneElement } from 'react';
import {
  TopToolbar,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
} from 'react-admin';

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  enabledCreate,
  ...rest
}) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {filters &&
      cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    {enabledCreate !== (null || false) ? (
      <CreateButton basePath={basePath} />
    ) : null}
    {exporter !== (null || false) ? (
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
        maxResults={maxResults}
      />
    ) : null}
  </TopToolbar>
);

export default ListActions;
