import React, {
  useReducer,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { ThemeProvider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useSnackbar } from 'notistack';

import imagem from '../../assets/background.jpg';
import { getListAPI, api } from '../../services';
import theme from '../DialogoEditarVenda/themes/default';
import InicioFrenteCaixaVenda from './Content';
import { VendaContext } from './context';
import reducerVenda from './reducer';

export function formatItens(itensOld) {
  const itensNew = [];
  for (let i = 0; i < itensOld.length; i += 1) {
    itensNew.push({
      produto: itensOld[i].produto,
      peso: itensOld[i].peso,
      unidades: itensOld[i].unidades,
      uidd: `${itensOld[i].produto.nome}${itensNew.length}`,
      id: itensOld[i].id,
      idIndicadorChange: itensOld[i].id,
      unidadesCaixa: 0,
    });
  }
  return itensNew;
}

const DialogoCreateVenda = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [venda, dispatch] = useReducer(reducerVenda, {
    cliente: null,
    itens: [],
    vendaId: -1,
    itensEstoque: [],
  });

  function resetar() {
    dispatch({ type: 'RESETAR_TUDO' });
  }

  const handleCloseDialog = () => {
    setOpen(false);
    resetar();
    handleClose();
  };

  function irParaTelaInit() {
    handleCloseDialog();
  }

  async function getDataVenda(vendaId) {
    try {
      const data = await getListAPI(
        'vendas',
        ['id', 'asc'],
        [1, 1],
        { id: [vendaId] },
        ['cliente.cidade.estado', 'cliente.categoria'],
      );

      if (data.data.length <= 0) {
        throw 'Esta venda não existe!';
      }

      const data2 = await api.get(
        `/resumo_mercadorias_venda_cliente/${vendaId}`,
      );

      dispatch({
        type: 'PREENCHER_TUDO',
        vendaId,
        itens: [],
        cliente: data.data[0].cliente,
        itensEstoque: formatItens(data2.data),
      });
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      irParaTelaInit();
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate(vendaId) {
      getDataVenda(vendaId);
      setOpen(true);
    },
  }));

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          fullScreen
          style={{
            padding: '0',
            margin: '0',
          }}
        >
          <DialogContent
            style={{
              padding: '0',
              margin: '0',
              backgroundImage: `url(${imagem})`,
              backgroundSize: 'cover',
            }}
            dividers
          >
            <VendaContext.Provider value={{ venda, dispatch }}>
              <InicioFrenteCaixaVenda irParaTelaInit={irParaTelaInit} />
            </VendaContext.Provider>
          </DialogContent>
        </Dialog>
      </div>
    </ThemeProvider>
  );
});

export default DialogoCreateVenda;
