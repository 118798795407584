import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
} from 'react';

import {
  Box,
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import { useParcelas } from '../../../hooks';
import { VendaContext } from './context';
import { getUsername, getListAllAPI } from '../../../../../services';
import {
  formatCPF,
  formatCNPJ,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  ATALHO_AVANCAR,
  ATALHO_VOLTAR,
  STATUS_ATIVO,
} from '../../../../../utils';
import { AutoCompleteRemoto } from '../../Inputs';

import { DialogoCliente } from '..';

const useStyles = makeStyles((theme) => ({
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  textField: {},
  btnImprimir: {
    backgroundColor: theme.palette.secondary.main,
    opacity: 0.4,
  },
  header: {
    height: '17%',
  },
  body: {
    height: '83%',
  },
  footer: {
    height: '15vh',
    padding: '10px',
    paddingBottom: '20px',
  },
  textOverflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const colorHeader = SECONDARY_COLOR;
const NOVO_CLIENTE_ACTION = 'F9';
const EDITAR_CLIENTE_ACTION = 'F7';

const DialogoConfirmacao = forwardRef(
  ({ handleSelecionado = () => {}, handleFechado = () => {} }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const refDialogoCliente = useRef(null);
    const theme = useTheme();
    const classes = useStyles();
    const { venda, dispatch } = useContext(VendaContext);
    const { cliente } = venda;

    useImperativeHandle(ref, () => ({
      handleOpen() {
        setOpen(true);
      },
    }));

    const handleClose = () => {
      setOpen(false);
      handleFechado();
    };

    const handleSalvar = () => {
      if (cliente) {
        setOpen(false);
        handleSelecionado();
      } else {
        enqueueSnackbar(`${'É necessário especificar o cliente!'}`, {
          variant: 'error',
        });
      }
    };

    function createCliente() {
      if (refDialogoCliente.current) refDialogoCliente.current.handleCreate();
    }

    function editarCliente() {
      if (refDialogoCliente.current) {
        const idCliente = cliente ? cliente.id : 0;
        if (idCliente > 0) {
          refDialogoCliente.current.handleEdit(idCliente);
        }
      }
    }

    function handleActions(name) {
      switch (name) {
        case CANCELAR_VENDA_ACTION:
          handleClose();
          break;
        case FECHAR_VENDA_ACTION:
          handleSalvar();
          break;
        case NOVO_CLIENTE_ACTION:
          createCliente();
          break;
        case EDITAR_CLIENTE_ACTION:
          editarCliente();
          break;
        default:
          break;
      }
    }

    async function updateCliente(idUpdate) {
      try {
        const data = await getListAllAPI(
          'clientes',
          ['id', 'asc'],
          { id: [idUpdate] },
          ['cidade.estado', 'categoria'],
        );
        if (data.data.length > 0) {
          dispatch({ type: 'UPDATE_CLIENTE', cliente: data.data[0] });
        } else {
          throw 'Este cliente não existe!';
        }
      } catch (erros) {
        // nada
      }
    }

    return (
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
          maxWidth="md"
          // style={{ backgroundColor: 'red' }}
          PaperProps={{
            style: { backgroundColor: theme.palette.secondary.main },
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ color: 'white', textAlign: 'center', fontWeight: 'bold' }}
          >
            CLIENTE
          </DialogTitle>
          <DialogContent>
            <Box padding="10px" className={classes.header}>
              <Box
                display="flex"
                css={{
                  backgroundColor: 'white',
                  opacity: '0.75',
                  height: '100%',
                  padding: '0px 10px',
                  flexDirection: 'column',
                }}
              >
                <Box display="flex" alignItems="center" flex={1}>
                  {/* <AutoCompleteRemoto
                    id="cliente"
                    name="cliente"
                    label="Responsável"
                    value={cliente}
                    onChange={(value) =>
                      dispatch({ type: 'UPDATE_CLIENTE', cliente: value })
                    }
                    handleKey={(keyCode, keyName) => {
                      handleActions(keyName);
                    }}
                    handleEnter={() => {}}
                    resource="clientes"
                    nested={['cidade.estado', 'categoria']}
                    toString={false}
                    // style={{ width: '300px', margin: 0, padding: 0 }}
                    fullwidth
                    autoFocus
                    filters={{ ativo: true }}
                    getOptionSelected={(option, value) =>
                      option.nome === value.nome
                    }
                    getOptionLabel={(option) =>
                      `${option.nome}${
                        option.nome_fantasia
                          ? ` - (${option.nome_fantasia})`
                          : ''
                      }`
                    }
                  />
                  <AutoCompleteRemoto
                    id="cliente"
                    name="cliente"
                    label="Nome Fantasia"
                    value={cliente}
                    onChange={(value) =>
                      dispatch({ type: 'UPDATE_CLIENTE', cliente: value })
                    }
                    handleKey={(keyCode, keyName) => {
                      handleActions(keyName);
                    }}
                    handleEnter={() => {}}
                    resource="clientes"
                    campo="nome_fantasia"
                    nested={['cidade.estado', 'categoria']}
                    toString={false}
                    style={{ marginLeft: '10px' }}
                    fullwidth
                    filters={{ ativo: true }}
                  /> */}
                  <AutoCompleteRemoto
                    id="cliente"
                    name="cliente"
                    label="CPF ou CNPJ"
                    value={cliente}
                    onChange={(value) => {
                      dispatch({ type: 'UPDATE_CLIENTE', cliente: value });
                    }}
                    handleKey={(keyCode, keyName) => {
                      handleActions(keyName);
                    }}
                    handleEnter={() => {}}
                    campo="cpf"
                    resource="clientes_pesquisa_fc"
                    nested={['cidade.estado', 'categoria']}
                    toString={false}
                    style={{ /* width: '300px', */ margin: 0, padding: 0 }}
                    fullwidth
                    autoFocus
                    filters={{ status: STATUS_ATIVO }}
                    getOptionSelected={(option, value) =>
                      option.cpf === value.cpf || option.cnpj === value.cnpj
                    }
                    getOptionLabel={(option) =>
                      `${option.cpf} - ${option.cnpj}`
                    }
                  />
                </Box>
                {cliente && (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flex={1}
                    style={{
                      border: `1px solid ${PRIMARY_COLOR}`,
                      marginLeft: '0px',
                      padding: '5px',
                      borderRadius: '5px',
                      marginBottom: '10px',
                    }}
                  >
                    <Box
                      marginLeft="10px"
                      display="flex"
                      flexDirection="column"
                      flex={1}
                    >
                      <Box
                        marginLeft="0px"
                        fontSize="15px"
                        fontWeight="bold"
                        display="flex"
                        flex={1}
                      >
                        <p
                          className={classes.textOverflow}
                          style={{
                            marginBottom: '0px',
                            color: colorHeader,
                            marginRight: '10px',
                          }}
                        >
                          Responsável:
                        </p>
                        {cliente.nome}
                      </Box>
                      <Box
                        marginLeft="0px"
                        fontSize="15px"
                        fontWeight="bold"
                        display="flex"
                        flex={1}
                      >
                        <p
                          className={classes.textOverflow}
                          style={{
                            marginBottom: '0px',
                            color: colorHeader,
                            marginRight: '10px',
                          }}
                        >
                          Razão Social:
                        </p>
                        {cliente.razao_social}
                      </Box>
                      <Box
                        marginLeft="0px"
                        fontSize="15px"
                        fontWeight="bold"
                        display="flex"
                        flex={1}
                      >
                        <p
                          className={classes.textOverflow}
                          style={{
                            marginBottom: '0px',
                            color: colorHeader,
                            marginRight: '10px',
                          }}
                        >
                          CPF:
                        </p>
                        {formatCPF(cliente.cpf)}
                      </Box>
                      <Box
                        marginLeft="0px"
                        fontSize="15px"
                        fontWeight="bold"
                        display="flex"
                        flex={1}
                      >
                        <p
                          className={classes.textOverflow}
                          style={{
                            marginBottom: '0px',
                            color: colorHeader,
                            marginRight: '10px',
                          }}
                        >
                          CNPJ:
                        </p>
                        {formatCNPJ(cliente.cnpj)}
                      </Box>
                    </Box>
                    <Box
                      marginLeft="10px"
                      display="flex"
                      flexDirection="column"
                      flex={1}
                    >
                      <Box
                        marginLeft="0px"
                        fontSize="15px"
                        fontWeight="bold"
                        display="flex"
                        flex={1}
                        justifyContent="flex-start"
                      >
                        <p
                          className={classes.textOverflow}
                          style={{
                            marginBottom: '0px',
                            color: colorHeader,
                            marginRight: '10px',
                          }}
                        >
                          Nome Fantasia:
                        </p>
                        {cliente.nome_fantasia}
                      </Box>
                      <Box
                        marginLeft="0px"
                        fontSize="15px"
                        fontWeight="bold"
                        display="flex"
                        flex={1}
                        justifyContent="flex-start"
                      >
                        <p
                          className={classes.textOverflow}
                          style={{
                            marginBottom: '0px',
                            color: colorHeader,
                            marginRight: '10px',
                          }}
                        >
                          Bairro:
                        </p>
                        {cliente.bairro}
                      </Box>
                      <Box
                        marginLeft="0px"
                        fontSize="15px"
                        fontWeight="bold"
                        display="flex"
                        flex={1}
                        justifyContent="flex-start"
                      >
                        <p
                          className={classes.textOverflow}
                          style={{
                            marginBottom: '0px',
                            color: colorHeader,
                            marginRight: '10px',
                          }}
                        >
                          Cidade:
                        </p>
                        {cliente.cidade
                          ? `${cliente.cidade.nome} - ${cliente.cidade.estado.sigla}`
                          : ''}
                      </Box>
                      <Box
                        marginLeft="0px"
                        fontSize="15px"
                        fontWeight="bold"
                        display="flex"
                        flex={1}
                        justifyContent="flex-start"
                      >
                        <p
                          className={classes.textOverflow}
                          style={{
                            marginBottom: '0px',
                            color: colorHeader,
                            marginRight: '10px',
                          }}
                        >
                          Logradouro:
                        </p>
                        {cliente.logradouro}
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Button
              onClick={handleClose}
              color="secondary"
              variant="contained"
              tabindex="-1"
            >
              {`Cancelar (${CANCELAR_VENDA_ACTION})`}
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={createCliente}
              tabindex="-1"
            >
              {`Novo cliente (${NOVO_CLIENTE_ACTION})`}
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={editarCliente}
              tabindex="-1"
            >
              {`Editar cliente (${EDITAR_CLIENTE_ACTION})`}
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleSalvar}
              tabindex="-1"
            >
              {`Concluir (${FECHAR_VENDA_ACTION})`}
            </Button>
          </DialogActions>
        </Dialog>
        <DialogoCliente
          ref={refDialogoCliente}
          handleClose={(idUpdate) => {
            // if (refSidebarInputs.current) refSidebarInputs.current.focus();
            if (idUpdate > 0) {
              updateCliente(idUpdate);
            }
          }}
        />
      </div>
    );
  },
);

export default DialogoConfirmacao;
