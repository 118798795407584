import moment from 'moment';

import { formatMoeda } from './formats';

function writeCodigoBarras(id) {
  const idString = `${id}`;
  let codigoEN13 = '789';
  for (let i = 0; i < 10 - idString.length; i += 1) {
    codigoEN13 = `${codigoEN13}0`;
  }
  codigoEN13 = `${codigoEN13}${idString}`;
  return codigoEN13;
}

function readCodigoBarras(codigo) {
  return parseInt(codigo.substring(3), 10);
}

function formatarData(data) {
  return moment(new Date(data)).format('DD/MM/YYYY HH:mm');
}

function getLabelPacote(tipo) {
  switch (tipo) {
    case 'malote_troco':
      return 'Troco';
    case 'malote_submalote':
      return 'Submalote';
    case 'malote_sangria':
      return 'Sangria';
    case 'malote_malote':
      return 'Malote';
    default:
      return '';
  }
}

function formatarPacote(pacote) {
  if (pacote) {
    return {
      codigo: writeCodigoBarras(pacote.id),
      tipo: getLabelPacote(pacote.tipo),
      data: formatarData(pacote.data_pagamento),
      responsavel: pacote.criador.username,
      valor: formatMoeda(pacote.valor),
      notas: {
        moedas_5_centavos: pacote.moedas_5_centavos,
        moedas_10_centavos: pacote.moedas_10_centavos,
        moedas_25_centavos: pacote.moedas_25_centavos,
        moedas_50_centavos: pacote.moedas_50_centavos,
        moedas_1_real: pacote.moedas_1_real,
        cedulas_2_reais: pacote.cedulas_2_reais,
        cedulas_5_reais: pacote.cedulas_5_reais,
        cedulas_10_reais: pacote.cedulas_10_reais,
        cedulas_20_reais: pacote.cedulas_20_reais,
        cedulas_50_reais: pacote.cedulas_50_reais,
        cedulas_100_reais: pacote.cedulas_100_reais,
        cedulas_200_reais: pacote.cedulas_200_reais,
      },
    };
  }
  return null;
}

function formatarMalote(malote) {
  if (malote) {
    return {
      codigo: writeCodigoBarras(malote.id),
      tipo: getLabelPacote(malote.tipo),
      data: formatarData(malote.data_pagamento),
      responsavel: malote.criador.username,
      valor: formatMoeda(malote.valor),
      notas: {
        moedas_5_centavos: malote.moedas_5_centavos,
        moedas_10_centavos: malote.moedas_10_centavos,
        moedas_25_centavos: malote.moedas_25_centavos,
        moedas_50_centavos: malote.moedas_50_centavos,
        moedas_1_real: malote.moedas_1_real,
        cedulas_2_reais: malote.cedulas_2_reais,
        cedulas_5_reais: malote.cedulas_5_reais,
        cedulas_10_reais: malote.cedulas_10_reais,
        cedulas_20_reais: malote.cedulas_20_reais,
        cedulas_50_reais: malote.cedulas_50_reais,
        cedulas_100_reais: malote.cedulas_100_reais,
        cedulas_200_reais: malote.cedulas_200_reais,
      },
      notasPermitidas: malote.notasPermitidas,
    };
  }
  return null;
}

export { formatarPacote, formatarMalote, readCodigoBarras };
