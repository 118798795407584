import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
} from 'react';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import RelatorioPDF from './RelatorioPDF';

const DialogoTrocoInicial = forwardRef(
  ({ dadosSelecionado, dadosHeader }, ref) => {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
      setOpen(false);
    };

    useImperativeHandle(ref, () => ({
      handleOpen() {
        setOpen(true);
      },
    }));

    return (
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          disableBackdropClick
          disableEscapeKeyDown
          scroll="paper"
          fullScreen
        >
          <DialogTitle id="form-dialog-title">PDF</DialogTitle>
          <DialogContent dividers style={{ padding: 0, height: '100%' }}>
            <div style={{ height: '100%' }}>
              <Box
                display={{ xs: 'block', sm: 'flex' }}
                flexDirection="column"
                marginBottom="0px"
                height="100%"
              >
                <RelatorioPDF
                  open={open}
                  dadosSelecionado={dadosSelecionado}
                  dadosHeader={dadosHeader}
                />
              </Box>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoTrocoInicial;
