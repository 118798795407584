import React, { useRef } from 'react';
import {
  TextField,
  ListBase,
  ListToolbar,
  Pagination,
  Title,
  Datagrid,
  DateField,
  ReferenceField,
  NumberField,
} from 'react-admin';

import { Card, makeStyles } from '@material-ui/core';

import StatusField from '../Vendas/StatusField';
import Actions from './ActionsList';
import DialogoShow from './DialogoShow';
import ActionsField from './Fields/ActionsField';
import Filter from './Filter';

const useStyles = makeStyles((theme) => ({
  dataHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
}));

const MyList = ({ ...props }) => {
  const classes = useStyles();
  const refDialogoShow = useRef(null);

  function handleShow({ id }) {
    if (refDialogoShow.current) {
      refDialogoShow.current.handleOpen(id);
    }
  }

  return (
    <>
      <Title title="Vendas Não Finalizadas" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid {...props}>
          <StatusField
            source="status"
            label="Tipo"
            sortable={false}
            handleVendasParciais={() => {}}
          />
          <DateField source="data" label="Data da Venda" />
          <TextField source="sessao_id" label="Id Sessão" sortable={false} />
          <ReferenceField
            label="Cliente"
            source="cliente_id"
            reference="clientes"
          >
            <TextField source="nome" />
          </ReferenceField>
          <NumberField
            source="valor"
            locales="pt-BR"
            options={{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            }}
            label="Valor Total"
          />
          <NumberField
            source="lucro"
            locales="pt-BR"
            options={{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            }}
            label="Lucro"
          />
          <ReferenceField
            label="Reponsável"
            source="responsavel_id"
            reference="users"
          >
            <TextField source="username" />
          </ReferenceField>
          <ActionsField
            source="lalala"
            handleShow={handleShow}
            textAlign="right"
            label="Ações"
            sortable={false}
          />
        </Datagrid>
        <TransacoesPagination />
      </Card>
      <DialogoShow ref={refDialogoShow} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      title="Vendas Não Finalizadas"
      filters={<Filter />}
      filterDefaultValues={{
        periodo: {
          start: new Date(),
          end: new Date(),
        },
      }}
    />
  </>
);

export default List2;
