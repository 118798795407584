import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  text: {
    //whiteSpace: 'nowrap',
    //overflow: 'hidden',
    //textOverflow: 'ellipsis',
    //maxWidth: '400px',
  },
}));

const DescricaoField = (props) => {
  const classes = useStyles();

  const { source } = props;
  const record = useRecordContext(props);
  const colaborador = record
    ? record.record
      ? record.record.colaborador
        ? record.record.colaborador.nome
        : ''
      : ''
    : '';

  return <Typography className={classes.text}>{colaborador}</Typography>;
};

export default DescricaoField;
