import React, { useState, useEffect } from 'react';
import {
  Button,
  Box,
  Typography,
  makeStyles,
  Paper,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Grow from '@material-ui/core/Grow';
import Popover from '@material-ui/core/Popover';
import Chip from '@material-ui/core/Chip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles({
  btn: {
    borderRadius: '50px',
    color: (props) => props.color,
    borderColor: (props) => props.color,
    '&:hover': {
      backgroundColor: (props) => props.color,
      color: 'white',
    },
  },
  actions: {
    borderRadius: '50px',
    color: '#2962ff',
    borderColor: 'white',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: 'rgb(238, 238, 238)',
      // color: 'white',
    },
  },
  btnMes: {
    borderRadius: '50px',
    color: '#979693',
    borderColor: 'white',
    // fontWeight: 'bold',
    '&:hover': {
      backgroundColor: 'rgb(238, 238, 238)',
      // color: 'white',
    },
  },
  paper: {
    borderRadius: '25px',
  },
});

const meses = [
  {
    label: 'Janeiro',
    sigla: 'Jan',
  },
  {
    label: 'Fevereiro',
    sigla: 'Fev',
  },
  {
    label: 'Março',
    sigla: 'Mar',
  },
  {
    label: 'Abril',
    sigla: 'Abr',
  },
  {
    label: 'Maio',
    sigla: 'Mai',
  },
  {
    label: 'Junho',
    sigla: 'Jun',
  },
  {
    label: 'Julho',
    sigla: 'Jul',
  },
  {
    label: 'Agosto',
    sigla: 'Ago',
  },
  {
    label: 'Setembro',
    sigla: 'Set',
  },
  {
    label: 'Outubro',
    sigla: 'Out',
  },
  {
    label: 'Novembro',
    sigla: 'Nov',
  },
  {
    label: 'Dezembro',
    sigla: 'Dez',
  },
];

const anoHoje = new Date().getFullYear();
const mesHoje = new Date().getMonth();

const DateMobillsInput = ({
  color = '#59c3fa', anoAtual = 2021, mesAtual = 5, handleNewDate = () => {},
}) => {
  const [ano, setAno] = useState(anoHoje);
  // const [mes, setMes] = useState(new Date().getMonth());
  const classes = useStyles({ color });
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    setAno(anoAtual);
  }, [anoAtual]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleBeforeAno = () => {
    setAno(ano - 1);
  };

  const handleNextAno = () => {
    setAno(ano + 1);
  };

  const confirmar = (data) => {
    handleNewDate(data);
    handleClose();
  };

  return (
    <>
      <Chip
        label={`${meses[mesAtual].label} ${anoAtual}`}
        clickable
            // color="primary"
        onDelete={handleClick}
        deleteIcon={<ExpandMoreIcon style={{ color: '#009FD4' }} />}
        variant="outlined"
        style={{
          color: '#009FD4',
          // width: '70px',
          backgroundColor: 'white',
          borderColor: '#009FD4',
        }}
        onClick={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classes.paper }}
      >
        <Paper style={{ borderRadius: '25px', padding: '0px', width: '300px' }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            style={{
              minHeight: '64px', padding: '0px 24px', backgroundColor: '#2962ff', borderRadius: '25px 25px 0px 0px',
            }}
          >
            <IconButton aria-label="delete" onClick={handleBeforeAno} style={{ color: 'white' }}>
              <NavigateBeforeIcon />
            </IconButton>
            <Box marginLeft="10px" marginRight="10px">
              <Typography variant="h5" style={{ color: 'white' }}>{`${ano}`}</Typography>
            </Box>
            <IconButton aria-label="delete" onClick={handleNextAno} style={{ color: 'white' }}>
              <NavigateNextIcon />
            </IconButton>
          </Box>
          <Box display="flex" flexWrap="wrap" padding="20px 10px">
            {meses.map((item, index) => (
              <Button
                variant="outlined"
                className={classes.btnMes}
                style={{ flex: '1 0 25%', color: mesAtual === index && ano === anoAtual ? '#2962ff' : '#979693' }}
                onClick={() => confirmar({ mes: index, ano })}
              >
                {item.sigla}
              </Button>
            ))}
          </Box>
          <Box display="flex" justifyContent="space-between" padding="0px 20px 20px">
            <Button
              variant="outlined"
              className={classes.actions}
              onClick={() => handleClose()}
            >
              CANCELAR
            </Button>
            <Button
              variant="outlined"
              className={classes.actions}
              onClick={() => confirmar({ mes: mesHoje, ano: anoHoje })}
            >
              MÊS ATUAL
            </Button>
          </Box>
        </Paper>
      </Popover>
    </>
  );
};

export default DateMobillsInput;
