import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
  PureComponent,
} from 'react';
import ReactDataSheet from 'react-datasheet';
import NumberFormat from 'react-number-format';

import 'react-datasheet/lib/react-datasheet.css';
import './index.css';
import { formatPeso, formatUnidades } from '../../../../utils';

const TOTAL_LINHAS = 100;

const gridInit = [];

gridInit.push([
  { value: 'Unidades', readOnly: true, disableEvents: true },
  { value: 'Peso (Kg)', readOnly: true, disableEvents: true },
]);

for (let i = 0; i < TOTAL_LINHAS; i += 1) {
  gridInit.push([{ value: 0 }, { value: 0 }]);
}

class NumberEditor extends PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleKey = this.handleKey.bind(this);
  }

  componentDidMount() {
    this.input.focus();
  }

  handleChange(values) {
    this.props.onChange(values.value);
  }

  handleKey(event) {
    this.props.onKeyDown(event);
  }

  render() {
    const { value } = this.props;
    return (
      <NumberFormat
        getInputRef={(input) => {
          this.input = input;
        }}
        className="data-editor"
        value={Number.isNaN(value) ? '' : String(value)}
        onValueChange={this.handleChange}
        onKeyDown={this.handleKey}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={this.props.escala}
        isNumericString
        allowEmptyFormatting
        mask="_"
      />
    );
  }
}

const DialogoSomaPesos = forwardRef(({ onChangeTotal }, ref) => {
  const [grid, setGrid] = useState(gridInit);

  function getTotais() {
    let unidadesLocal = 0;
    let pesoLocal = 0;
    for (let i = 1; i < TOTAL_LINHAS + 1; i += 1) {
      unidadesLocal += parseInt(grid[i][0].value, 10);
      pesoLocal += parseFloat(grid[i][1].value);
    }
    return {
      unidades: unidadesLocal,
      peso: pesoLocal,
    };
  }

  useEffect(() => onChangeTotal(getTotais()), [grid]);

  useImperativeHandle(ref, () => ({
    handleReset(unidades, peso) {
      const gridOld = gridInit.map((row) => [...row]);
      gridOld[1][0] = { ...gridOld[1][0], value: unidades };
      gridOld[1][1] = { ...gridOld[1][1], value: peso };
      setGrid(gridOld);
    },
  }));

  function unidadesCorrigidas(value) {
    const valor = parseInt(value, 10);
    if (Number.isNaN(valor)) {
      return 0;
    }
    return parseInt(valor, 10);
  }

  function pesoCorrigido(row, value) {
    const valor = parseFloat(value, 10);
    if (Number.isNaN(valor)) {
      return 0;
    }
    return valor;
  }

  function corrigirValor(row, col, value) {
    if (col === 0) return unidadesCorrigidas(value);
    return pesoCorrigido(row, value);
  }

  const valueRenderer = (cell, i, j) => {
    if (j === 0) {
      return formatUnidades(cell.value);
    }
    return formatPeso(cell.value);
  };

  const onCellsChanged = (changes) => {
    const gridOld = grid.map((row) => [...row]);
    changes.forEach(({ row, col, value }) => {
      gridOld[row][col] = {
        ...gridOld[row][col],
        value: corrigirValor(row, col, value),
      };
    });
    setGrid(gridOld);
  };

  const onContextMenu = (e, cell) =>
    cell.readOnly ? e.preventDefault() : null;

  return (
    <div className="sheet-container">
      <ReactDataSheet
        data={grid}
        valueRenderer={valueRenderer}
        onContextMenu={onContextMenu}
        onCellsChanged={onCellsChanged}
        dataEditor={(props) => (
          <NumberEditor {...props} escala={props.col === 0 ? 0 : 3} />
        )}
      />
    </div>
  );
});

export default DialogoSomaPesos;
