import {
  renameParcelasCompraUIDD,
  renameItensUIDD,
  getSubTotal,
  getTotalCompra,
} from '../../../../utils';

function somaRepasses(dados) {
  let soma = 0;
  soma += dados.repasse_ipi;
  soma += dados.repasse_frete;
  soma += dados.repasse_st;
  soma += dados.repasse_seguros;
  soma += dados.repasse_outras_despesas;
  return soma;
}

function calculaTotais(lotes) {
  let subTotalProdutos = 0;
  let total_repasse_ipi = 0;
  let total_repasse_frete = 0;
  let total_repasse_st = 0;
  let total_repasse_seguros = 0;
  let total_repasse_outras_despesas = 0;
  for (let i = 0; i < lotes.length; i++) {
    const lote = lotes[i];
    subTotalProdutos += getTotalCompra(
      lote.peso,
      lote.unidades,
      lote.unitario,
      lote.produto,
    );
    total_repasse_ipi += getTotalCompra(
      lote.peso,
      lote.unidades,
      lote.dadosPrecificacao ? lote.dadosPrecificacao.repasse_do_ipi : 0,
      lote.produto,
    );
    total_repasse_frete += getTotalCompra(
      lote.peso,
      lote.unidades,
      lote.dadosPrecificacao ? lote.dadosPrecificacao.repasse_do_frete : 0,
      lote.produto,
    );
    total_repasse_st += getTotalCompra(
      lote.peso,
      lote.unidades,
      lote.dadosPrecificacao ? lote.dadosPrecificacao.repasse_de_st : 0,
      lote.produto,
    );
    total_repasse_seguros += getTotalCompra(
      lote.peso,
      lote.unidades,
      lote.dadosPrecificacao ? lote.dadosPrecificacao.repasse_do_seguro : 0,
      lote.produto,
    );
    total_repasse_outras_despesas += getTotalCompra(
      lote.peso,
      lote.unidades,
      lote.dadosPrecificacao
        ? lote.dadosPrecificacao.repasse_de_outras_despesas
        : 0,
      lote.produto,
    );
  }
  return {
    subTotalProdutos,
    total_repasse_ipi,
    total_repasse_frete,
    total_repasse_st,
    total_repasse_seguros,
    total_repasse_outras_despesas,
  };
}

const reducer = (state, action) => {
  let arrayNew = state.lotes.slice();
  let arrayNewParcelas = state.parcelas.slice();
  switch (action.type) {
    case 'ADD_LOTE': {
      const newArray = [...arrayNew, action.lote];
      const {
        subTotalProdutos,
        total_repasse_ipi,
        total_repasse_frete,
        total_repasse_st,
        total_repasse_seguros,
        total_repasse_outras_despesas,
      } = calculaTotais(newArray);
      return {
        ...state,
        lotes: [...arrayNew, action.lote],
        subTotal: getSubTotal(newArray) + somaRepasses(state),
        subTotalProdutos,
        total_repasse_ipi,
        total_repasse_frete,
        total_repasse_st,
        total_repasse_seguros,
        total_repasse_outras_despesas,
      };
    }
    case 'REMOVE_LOTE': {
      for (let i = 0; i < action.indices.length; i += 1) {
        arrayNew = arrayNew.filter((obj) => obj.uidd !== action.indices[i]);
      }
      const {
        subTotalProdutos,
        total_repasse_ipi,
        total_repasse_frete,
        total_repasse_st,
        total_repasse_seguros,
        total_repasse_outras_despesas,
      } = calculaTotais(arrayNew);
      return {
        ...state,
        lotes: [...renameItensUIDD(arrayNew)],
        subTotal: getSubTotal([...arrayNew]) + somaRepasses(state),
        subTotalProdutos,
        total_repasse_ipi,
        total_repasse_frete,
        total_repasse_st,
        total_repasse_seguros,
        total_repasse_outras_despesas,
      };
    }
    case 'UPDATE_LOTE': {
      const index = arrayNew.findIndex(
        (item) => item.uidd === action.lote.index,
      );
      if (index >= 0) {
        arrayNew[index] = {
          ...arrayNew[index],
          produto: action.lote.produto,
          peso: action.lote.peso,
          total: action.lote.total,
          unidades: action.lote.unidades,
          unitario: action.lote.unitario,
          validade: action.lote.validade,
          unitarioImposto: action.lote.unitarioImposto,
          cg: action.lote.cg,
          dadosPrecificacao: action.lote.dadosPrecificacao,
          nImpressoes: action.lote.nImpressoes,
        };
        const {
          subTotalProdutos,
          total_repasse_ipi,
          total_repasse_frete,
          total_repasse_st,
          total_repasse_seguros,
          total_repasse_outras_despesas,
        } = calculaTotais(arrayNew);
        return {
          ...state,
          lotes: [...renameItensUIDD(arrayNew)],
          subTotal: getSubTotal([...arrayNew]) + somaRepasses(state),
          subTotalProdutos,
          total_repasse_ipi,
          total_repasse_frete,
          total_repasse_st,
          total_repasse_seguros,
          total_repasse_outras_despesas,
        };
      }
      return {
        ...state,
      };
    }
    case 'UPDATE_LOTES': {
      const {
        subTotalProdutos,
        total_repasse_ipi,
        total_repasse_frete,
        total_repasse_st,
        total_repasse_seguros,
        total_repasse_outras_despesas,
      } = calculaTotais(action.lotes);
      return {
        ...state,
        lotes: [...renameItensUIDD(action.lotes)],
        subTotal: getSubTotal([...action.lotes]) + somaRepasses(state),
        subTotalProdutos,
        total_repasse_ipi,
        total_repasse_frete,
        total_repasse_st,
        total_repasse_seguros,
        total_repasse_outras_despesas,
      };
    }

    case 'UPDATE_FORNECEDOR':
      return {
        ...state,
        fornecedor: action.fornecedor,
      };

    case 'UPDATE_NUMERO_NOTA_FISCAL':
      return {
        ...state,
        numeroNotaFiscal: action.numeroNotaFiscal,
      };

    case 'UPDATE_DATA_FATURAMENTO':
      return {
        ...state,
        data_faturamento: action.data_faturamento,
      };

    case 'ADD_PARCELA':
      return {
        ...state,
        parcelas: [...arrayNewParcelas, action.parcela],
      };
    case 'REMOVE_PARCELA':
      for (let i = 0; i < action.indices.length; i += 1) {
        arrayNewParcelas = arrayNewParcelas.filter(
          (obj) => obj.uidd !== action.indices[i],
        );
      }
      return {
        ...state,
        parcelas: [...renameParcelasCompraUIDD(arrayNewParcelas)],
      };
    case 'UPDATE_PARCELAS':
      return {
        ...state,
        parcelas: [...renameParcelasCompraUIDD(action.parcelas)],
      };

    case 'UPDATE_ID_EDIT':
      return {
        ...state,
        idEdit: action.idEdit,
      };

    case 'UPDATE_LOJA':
      return {
        ...state,
        loja: action.loja,
      };

    case 'RESETAR_TUDO':
      return {
        ...state,
        idEdit: -1,
        lotes: [],
        parcelas: [],
        subTotal: 0,
        fornecedor: null,
        numeroNotaFiscal: '',
        data_faturamento: '',
        data_criacao: new Date(),
        loja: {
          nome: 'CARUARU FRIOS',
          value: 'CF',
        },
        repasse_ipi: 0,
        repasse_frete: 0,
        repasse_st: 0,
        repasse_seguros: 0,
        repasse_outras_despesas: 0,
        numeroCompra: '-',
        total_repasse_ipi: 0,
        total_repasse_frete: 0,
        total_repasse_st: 0,
        total_repasse_seguros: 0,
        total_repasse_outras_despesas: 0,
        subTotalProdutos: 0,
        isProdutosSomenteFornecedor: true,
        isIncluiInativos: false,
        serieNotaFiscal: '',
        chaveAcessoNotaFiscal: '',
        isImportacaoXml: false,
      };

    case 'PREENCHER_TUDO': {
      const {
        subTotalProdutos,
        total_repasse_ipi,
        total_repasse_frete,
        total_repasse_st,
        total_repasse_seguros,
        total_repasse_outras_despesas,
      } = calculaTotais(action.lotes);
      return {
        ...state,
        idEdit: action.idEdit,
        lotes: [...renameItensUIDD(action.lotes)],
        subTotal: getSubTotal([...action.lotes]) + somaRepasses(action),
        parcelas: [...renameParcelasCompraUIDD(action.parcelas)],
        fornecedor: action.fornecedor,
        numeroNotaFiscal: action.numeroNotaFiscal,
        data_faturamento: action.data_faturamento,
        data_criacao: action.data_criacao,
        loja: action.loja,
        repasse_ipi: action.repasse_ipi,
        repasse_frete: action.repasse_frete,
        repasse_st: action.repasse_st,
        repasse_seguros: action.repasse_seguros,
        repasse_outras_despesas: action.repasse_outras_despesas,
        carregando: action.carregando,
        config: action.config,
        numeroCompra: action.numeroCompra,
        subTotalProdutos,
        total_repasse_ipi,
        total_repasse_frete,
        total_repasse_st,
        total_repasse_seguros,
        total_repasse_outras_despesas,
        isProdutosSomenteFornecedor: true,
        isIncluiInativos: false,
        serieNotaFiscal: action.serieNotaFiscal,
        chaveAcessoNotaFiscal: action.chaveAcessoNotaFiscal,
        isImportacaoXml: action.isImportacaoXml,
      };
    }
    case 'UPDATE_DADOS_TELA_INICIAL': {
      const {
        subTotalProdutos,
        total_repasse_ipi,
        total_repasse_frete,
        total_repasse_st,
        total_repasse_seguros,
        total_repasse_outras_despesas,
      } = calculaTotais(state.lotes);
      return {
        ...state,
        subTotal: getSubTotal([...state.lotes]) + somaRepasses(action),
        fornecedor: action.fornecedor,
        loja: action.loja,
        numeroNotaFiscal: action.numeroNotaFiscal,
        data_faturamento: action.data_faturamento,
        repasse_frete: action.repasse_frete,
        repasse_ipi: action.repasse_ipi,
        repasse_outras_despesas: action.repasse_outras_despesas,
        repasse_seguros: action.repasse_seguros,
        repasse_st: action.repasse_st,
        subTotalProdutos,
        total_repasse_ipi,
        total_repasse_frete,
        total_repasse_st,
        total_repasse_seguros,
        total_repasse_outras_despesas,
        serieNotaFiscal: action.serieNotaFiscal,
        chaveAcessoNotaFiscal: action.chaveAcessoNotaFiscal,
        //isImportacaoXml: action.isImportacaoXml,
      };
    }
    case 'UPDATE_FILTROS_PESQUISA':
      return {
        ...state,
        isProdutosSomenteFornecedor: action.isProdutosSomenteFornecedor,
        isIncluiInativos: action.isIncluiInativos,
      };
    default:
      return state;
  }
};

export default reducer;
