import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CloseIcon from '@material-ui/icons/Close';
import TodayIcon from '@material-ui/icons/Today';
import Rating from '@material-ui/lab/Rating';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  TextInput,
  IntegerInput,
  AutoCompleteRemoto,
  DateInput,
  CpfInput,
  TelefoneInput,
  CnpjInput,
  CepInput,
  IEInput,
  PrecoInput,
  DateInputBonito,
} from '../../../Components';
import { createAPI, updateAPI, getListAllAPI } from '../../../services';
import {
  formatCNPJ,
  formatCPF,
  formatCEP,
  formatIE,
  formatMoeda,
  formatTelefone,
  STATUS_ATIVO,
} from '../../../utils';
import { getLabelTipo, getValorFormatado } from '../DialogoCreateEdit/utils';
import DialogoCreateEditFilho from '../DialogoCreateEditFilho';
import DialogoDeletarFilho from '../DialogoDeletarFilho';
import LocaisInput from '../LocaisInput';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_ID = -1;

function validateEmail2(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function getTipo(type) {
  switch (type) {
    case 'conta_corrente':
      return 'Conta Corrente';
    case 'conta_poupanca':
      return 'Conta Poupança';
    case 'conta_pagamento':
      return 'Conta Pagamento';
    default:
      return '';
  }
}

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [nome, setNome] = useState('');
  const [funcao, setFuncao] = useState(null);
  const [locais, setLocais] = useState([]);
  const [locaisOptions, setLocaisOptions] = useState([]);
  const [fardamentos, setFardamentos] = useState([]);
  const [fardamentosOptions, setFardamentosOptions] = useState([]);
  const [qualidades, setQualidades] = useState([]);
  const [qualidadesOptions, setQualidadesOptions] = useState([]);
  const [defeitos, setDefeitos] = useState([]);
  const [defeitosOptions, setDefeitosOptions] = useState([]);
  //
  const [cpf, setCpf] = useState('');
  const [telefone, setTelefone] = useState('');
  const [bairro, setBairro] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [email, setEmail] = useState('');
  const [cep, setCep] = useState('');
  const [cidade, setCidade] = useState(null);
  //
  const [descontoPalestra, setDescontoPalestra] = useState(0);
  const [descontoPlanoSaude, setDescontoPlanoSaude] = useState(0);
  const [descontoValeTransporte, setDescontoValeTransporte] = useState(0);
  const [descontoPlanoOdontologico, setDescontoPlanoOdontologico] = useState(0);
  const [horasExtras, setHorasExtras] = useState(0);
  //
  const [contaCorrente, setContaCorrente] = useState('');
  const [agencia, setAgencia] = useState('');
  //
  const [dataEntrada, setDataEntrada] = useState(moment().format('YYYY-MM-DD'));
  //
  const [filhos, setFilhos] = useState([]);

  const [id, setId] = useState(DEFAULT_ID);

  const [carregando, setCarregando] = useState(false);

  const refDialogoFilho = useRef(null);

  const refDialogoDeletarFilho = useRef(null);

  const [value, setValue] = React.useState(0);

  const [contas, setContas] = useState([]);
  const [dadosPix, setDadosPix] = useState([]);

  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(0);

  const [empresa, setEmpresa] = useState(null);

  const classes = useStyles({ cor: '#009FD4' });

  function formataDadaosFardamentos(dados) {
    return dados.map(({ id, nome, tamanho }) => ({
      id,
      nome: `${nome}${tamanho ? ` ${tamanho.nome}` : ''}`,
    }));
  }

  useEffect(() => {
    async function getDadosLocais() {
      try {
        const data = await getListAllAPI(
          'locais_setores',
          ['id', 'asc'],
          { status: STATUS_ATIVO },
          [],
        );
        const data2 = await getListAllAPI(
          'fardamentos',
          ['id', 'asc'],
          { status: STATUS_ATIVO },
          ['tamanho'],
        );
        const data3 = await getListAllAPI(
          'qualidades_colaborador',
          ['id', 'asc'],
          { status: STATUS_ATIVO },
          [],
        );
        const data4 = await getListAllAPI(
          'defeitos_colaborador',
          ['id', 'asc'],
          { status: STATUS_ATIVO },
          [],
        );
        setLocaisOptions(data.data);
        setFardamentosOptions(data2.data);
        setQualidadesOptions(data3.data);
        setDefeitosOptions(data4.data);
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }
    if (open) getDadosLocais();
  }, [open]);

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function reiniciar() {
    setId(DEFAULT_ID);
    setNome('');
    setFuncao(null);
    setCarregando(false);
    setLocais([]);
    setFardamentos([]);
    setQualidades([]);
    setDefeitos([]);
    setCpf('');
    setTelefone('');
    setBairro('');
    setLogradouro('');
    setNumero('');
    setEmail('');
    setCep('');
    setCidade(null);
    setDescontoPalestra(0);
    setDescontoPlanoSaude(0);
    setDescontoValeTransporte(0);
    setDescontoPlanoOdontologico(0);
    setHorasExtras(0);
    setContaCorrente('');
    setAgencia('');
    setFilhos([]);
    setUserId(0);
    setUser(null);
    setDataEntrada(moment().format('YYYY-MM-DD'));
    setContas([]);
    setDadosPix([]);
    setEmpresa(null);
    setValue(0);
    /* const dataSetores = await getListAllAPI(
        'locais_setores',
        ['id', 'asc'],
        {},
        [],
      );
  
      setLocaisOptions(filtrarLocais(dataSetores.data, -1)); */
  }

  /* function filtrarLocais(locais, colaborador_id) {
      const novoLocais = [];
      for (let i = 0; i < locais.length; i += 1) {
        if (
          locais.colaborador_id === colaborador_id ||
          locais.colaborador_id === null
        ) {
          novoLocais.push(novoLocais);
        }
      }
      return novoLocais;
    } */

  function adaptarFilhosRetornoApi(filhos) {
    const newFilhos = filhos.map((item) => ({
      nome: item.nome,
      data: item.aniversario,
    }));
    return newFilhos;
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'colaboradores',
        ['id', 'asc'],
        { id: [id] },
        [
          'funcao',
          'locais',
          'fardamentos.tamanho',
          'qualidades',
          'defeitos',
          'cidade.estado',
          'filhos',
          'user',
          'dadosBancarios.banco',
          'dadosPix',
          'empresa',
        ],
      );

      if (data.data.length > 0) {
        setId(data.data[0].id);
        setNome(data.data[0].nome);
        setFuncao(data.data[0].funcao);
        setLocais(data.data[0].locais);
        setFardamentos(data.data[0].fardamentos);
        setDefeitos(data.data[0].defeitos);
        setQualidades(data.data[0].qualidades);
        setCpf(data.data[0].cpf);
        setTelefone(data.data[0].telefone);
        setBairro(data.data[0].bairro);
        setLogradouro(data.data[0].logradouro);
        setNumero(data.data[0].numero);
        setEmail(data.data[0].email);
        setCep(data.data[0].cep);
        setCidade(data.data[0].cidade);
        setDescontoPalestra(data.data[0].desconto_da_palestra);
        setDescontoPlanoSaude(data.data[0].desconto_do_plano_de_saude);
        setDescontoValeTransporte(data.data[0].desconto_do_vale_transporte);
        setDescontoPlanoOdontologico(
          data.data[0].desconto_do_plano_odontologico,
        );
        setHorasExtras(data.data[0].hora_extra);
        setContaCorrente(data.data[0].conta);
        setAgencia(data.data[0].agencia);
        setFilhos(recalcularIds(adaptarFilhosRetornoApi(data.data[0].filhos)));
        setUserId(data.data[0].user_id ? data.data[0].user_id : 0);
        setUser(data.data[0].user ? data.data[0].user : null);
        setDataEntrada(moment(data.data[0].data_entrada).format('YYYY-MM-DD'));
        setContas(data.data[0].dadosBancarios);
        setDadosPix(data.data[0].dadosPix);
        setEmpresa(data.data[0].empresa);
        setValue(0);
        setCarregando(false);
      } else {
        throw 'Este colaborador não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  function getIdsOptions(array) {
    const ids = [];
    for (let i = 0; i < array.length; i += 1) {
      ids.push(array[i].id);
    }
    return ids;
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
    },
  }));

  function completaZeros(value) {
    if (value < 10) {
      return `0${value}`;
    }
    return `${value}`;
  }

  function calculaIdade(dataInitial) {
    const data = new Date(dataInitial);
    const time = new Date().getTime() - data.getTime();
    const idade = parseInt(time / (365 * 24 * 60 * 60 * 1000), 10);
    const dataNascimentoFormatada = `${completaZeros(
      data.getDate(),
    )}/${completaZeros(data.getMonth() + 1)}/${data.getFullYear()}`;
    return {
      idade,
      dataNascimentoFormatada,
    };
  }

  function getDataFormatada(data) {
    if (data) {
      const dataFormatada = new Date(data);
      return `${
        dataFormatada.getDate() < 10
          ? `0${dataFormatada.getDate()}`
          : dataFormatada.getDate()
      }/${
        dataFormatada.getMonth() + 1 < 10
          ? `0${dataFormatada.getMonth() + 1}`
          : dataFormatada.getMonth() + 1
      }/${dataFormatada.getFullYear()}`;
    }
    return '';
  }

  function recalcularIds(arrayOld) {
    const newArray = [];
    for (let i = 0; i < arrayOld.length; i++) {
      newArray.push({
        nome: arrayOld[i].nome,
        data: arrayOld[i].data,
        id: i + 1,
      });
    }
    return newArray;
  }

  function joinArrayNames(arr) {
    return arr.map((item) => item.nome).join(', ');
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        fullScreen
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          {`Detalhes colaborador ${nome}`}
        </DialogTitle>
        <DialogContent dividers>
          <div style={{ height: '100%' }}>
            <Box
              display={{ xs: 'block', sm: 'flex' }}
              flexDirection="column"
              marginBottom="30px"
              height="100%"
            >
              {carregando ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '300px',
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <Box p="1em">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                  >
                    <Tab label="Pessoal" {...a11yProps(0)} />
                    <Tab label="Pix" {...a11yProps(1)} />
                    <Tab label="Contas" {...a11yProps(2)} />
                    <Tab label="Operacional" {...a11yProps(3)} />
                    <Tab label="Contabilidade" {...a11yProps(4)} />
                  </Tabs>
                  <Box display="flex">
                    <Box flex={2} mr="1em">
                      <TabPanel value={value} index={0}>
                        <Typography
                          variant="h5"
                          gutterBottom
                          style={{ margin: '30px 0' }}
                        >
                          Dados Pessoais
                        </Typography>

                        <Box mt="1em" />

                        <Box display="flex">
                          <Box flex={1} mr="0.5em" mb="10px">
                            <span
                              style={{
                                //fontWeight: 'bold',
                                marginRight: '10px',
                              }}
                            >
                              Nome:
                            </span>
                            {`Nome: ${nome || ''}`}
                          </Box>
                          <Box flex={1} ml="0.5em" mb="10px">
                            <span
                              style={{
                                //fontWeight: 'bold',
                                marginRight: '10px',
                              }}
                            >
                              CPF:
                            </span>
                            {`${cpf ? formatCPF(cpf) : ''}`}
                          </Box>
                        </Box>

                        <Box mt="1em" />

                        <Box display="flex">
                          <Box flex={1} mr="0.5em" mb="10px">
                            <span
                              style={{
                                //fontWeight: 'bold',
                                marginRight: '10px',
                              }}
                            >
                              Telefone:
                            </span>
                            {`${telefone ? formatTelefone(telefone) : ''}`}
                          </Box>
                          <Box flex={1} ml="0.5em" mb="10px">
                            <span
                              style={{
                                //fontWeight: 'bold',
                                marginRight: '10px',
                              }}
                            >
                              Email:
                            </span>
                            {`${email || ''}`}
                          </Box>
                        </Box>

                        <Box mt="1em" />

                        <Typography
                          variant="h5"
                          gutterBottom
                          style={{ margin: '30px 0' }}
                        >
                          Endereço
                        </Typography>

                        <Box mt="1em" />

                        <Box display="flex">
                          <Box flex={1} mr="0.5em" mb="10px">
                            <span
                              style={{
                                //fontWeight: 'bold',
                                marginRight: '10px',
                              }}
                            >
                              Cidade:
                            </span>
                            {`${cidade ? cidade.nome : ''}`}
                          </Box>
                          <Box flex={1} ml="0.5em " mb="10px">
                            <span
                              style={{
                                //fontWeight: 'bold',
                                marginRight: '10px',
                              }}
                            >
                              Bairro:
                            </span>
                            {`${bairro || ''}`}
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box flex={1} mr="0.5em" mb="10px">
                            <span
                              style={{
                                //fontWeight: 'bold',
                                marginRight: '10px',
                              }}
                            >
                              Logradouro:
                            </span>
                            {`${logradouro || ''}`}
                          </Box>
                          <Box flex={1} ml="0.5em" mb="10px">
                            <span
                              style={{
                                //fontWeight: 'bold',
                                marginRight: '10px',
                              }}
                            >
                              Nº:
                            </span>
                            {`${numero || ''}`}
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box flex={1} mr="0.5em" mb="10px">
                            <span
                              style={{
                                //fontWeight: 'bold',
                                marginRight: '10px',
                              }}
                            >
                              CEP:
                            </span>
                            {`${cep ? formatCEP(cep) : ''}`}
                          </Box>
                          <Box flex={1} ml="0.5em" mb="10px" />
                        </Box>

                        <Box mt="1em" />

                        <Typography
                          variant="h5"
                          gutterBottom
                          style={{ margin: '30px 0' }}
                        >
                          Dados Bancários
                        </Typography>

                        <Box mt="1em" />

                        <Box display="flex">
                          <Box flex={1} mr="0.5em" mb="10px">
                            <span
                              style={{
                                //fontWeight: 'bold',
                                marginRight: '10px',
                              }}
                            >
                              Conta Corrente:
                            </span>
                            {`${contaCorrente || ''}`}
                          </Box>
                          <Box flex={1} ml="0.5em" mb="10px">
                            <span
                              style={{
                                //fontWeight: 'bold',
                                marginRight: '10px',
                              }}
                            >
                              Agência:
                            </span>
                            {`${agencia || ''}`}
                          </Box>
                        </Box>

                        <Box mt="1em" />

                        <Typography
                          variant="h5"
                          gutterBottom
                          style={{ margin: '30px 0' }}
                        >
                          Filhos
                        </Typography>

                        <Box mt="1em" />

                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Nome</StyledTableCell>
                                <StyledTableCell align="right">
                                  Data Nascimento
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  Idade
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {filhos.map((row) => {
                                const {
                                  idade,
                                  dataNascimentoFormatada,
                                } = calculaIdade(row.data);
                                return (
                                  <StyledTableRow key={row.id}>
                                    <StyledTableCell component="th" scope="row">
                                      {row.nome}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      {dataNascimentoFormatada}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      {idade}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TabPanel>

                      <TabPanel value={value} index={1}>
                        <Typography
                          variant="h5"
                          gutterBottom
                          style={{ margin: '30px 0' }}
                        >
                          Pix
                        </Typography>

                        <Box mt="1em" />

                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Tipo</StyledTableCell>
                                <StyledTableCell>Chave</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {dadosPix.map((row) => (
                                <StyledTableRow key={row.id}>
                                  <StyledTableCell component="th" scope="row">
                                    {getLabelTipo(row.tipo)}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {getValorFormatado(row.valor, row.tipo)}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TabPanel>

                      <TabPanel value={value} index={2}>
                        <Typography
                          variant="h5"
                          gutterBottom
                          style={{ margin: '30px 0' }}
                        >
                          Contas
                        </Typography>

                        <Box mt="1em" />

                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Banco</StyledTableCell>
                                <StyledTableCell>Conta</StyledTableCell>
                                <StyledTableCell>Agência</StyledTableCell>
                                <StyledTableCell>Tipo</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {contas.map((row) => (
                                <StyledTableRow key={row.id}>
                                  <StyledTableCell component="th" scope="row">
                                    {row.banco ? row.banco.nome : ''}
                                  </StyledTableCell>
                                  <StyledTableCell>{row.conta}</StyledTableCell>
                                  <StyledTableCell>
                                    {row.agencia}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {getTipo(row.tipo)}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TabPanel>

                      <TabPanel value={value} index={3}>
                        <Typography
                          variant="h5"
                          gutterBottom
                          style={{ margin: '30px 0' }}
                        >
                          Dados Funcionais
                        </Typography>

                        <Box display="flex">
                          <Box flex={1} mr="0.5em" mb="10px">
                            <span
                              style={{
                                //fontWeight: 'bold',
                                marginRight: '10px',
                              }}
                            >
                              Loja:
                            </span>
                            {`${empresa ? empresa.nome_fantasia : ''}`}
                          </Box>
                          <Box flex={1} ml="0.5em" mb="10px" />
                        </Box>

                        <Box display="flex">
                          <Box flex={1} mr="0.5em" mb="10px">
                            <span
                              style={{
                                //fontWeight: 'bold',
                                marginRight: '10px',
                              }}
                            >
                              Locais:
                            </span>
                            {`${joinArrayNames(locais)}`}
                          </Box>
                          <Box flex={1} ml="0.5em" mb="10px">
                            <span
                              style={{
                                //fontWeight: 'bold',
                                marginRight: '10px',
                              }}
                            >
                              Fardamentos:
                            </span>
                            {`${joinArrayNames(fardamentos)}`}
                          </Box>
                        </Box>

                        <Box mt="1em" />

                        <Box display="flex">
                          <Box flex={1} mr="0.5em" mb="10px">
                            <span
                              style={{
                                //fontWeight: 'bold',
                                marginRight: '10px',
                              }}
                            >
                              Função:
                            </span>
                            {`${funcao ? funcao.nome : ''}`}
                          </Box>
                          <Box flex={1} ml="0.5em" mb="10px">
                            <span
                              style={{
                                //fontWeight: 'bold',
                                marginRight: '10px',
                              }}
                            >
                              Data de Entrada na empresa:
                            </span>
                            {`${getDataFormatada(dataEntrada)}`}
                          </Box>
                        </Box>

                        <Box mt="1em" />

                        <Box display="flex">
                          <Box flex={1} mr="0.5em" mb="10px">
                            <span
                              style={{
                                //fontWeight: 'bold',
                                marginRight: '10px',
                              }}
                            >
                              Qualidades:
                            </span>
                            {`${joinArrayNames(qualidades)}`}
                          </Box>
                          <Box flex={1} ml="0.5em" mb="10px">
                            <span
                              style={{
                                //fontWeight: 'bold',
                                marginRight: '10px',
                              }}
                            >
                              Defeitos:
                            </span>
                            {`${joinArrayNames(defeitos)}`}
                          </Box>
                        </Box>

                        <Box mt="1em" />

                        <Typography
                          variant="h5"
                          gutterBottom
                          style={{ margin: '30px 0' }}
                        >
                          Usuário
                        </Typography>

                        <Box display="flex">
                          <Box flex={1} mr="0.5em" mb="10px">
                            <span
                              style={{
                                //fontWeight: 'bold',
                                marginRight: '10px',
                              }}
                            >
                              Username:
                            </span>
                            {user ? user.username : '-'}
                          </Box>
                          <Box flex={1} ml="0.5em" mb="10px" />
                        </Box>
                      </TabPanel>

                      <TabPanel value={value} index={4}>
                        <Box display="flex">
                          <Box flex={1} mr="0.5em" mb="10px">
                            <span
                              style={{
                                //fontWeight: 'bold',
                                marginRight: '10px',
                              }}
                            >
                              Desconto Palestra:
                            </span>
                            {`${formatMoeda(descontoPalestra)}`}
                          </Box>
                          <Box flex={1} ml="0.5em" mb="10px">
                            <span
                              style={{
                                //fontWeight: 'bold',
                                marginRight: '10px',
                              }}
                            >
                              Desconto Plano de Saúde:
                            </span>
                            {`${formatMoeda(descontoPlanoSaude)}`}
                          </Box>
                        </Box>

                        <Box mt="1em" />

                        <Box display="flex">
                          <Box flex={1} mr="0.5em" mb="10px">
                            <span
                              style={{
                                //fontWeight: 'bold',
                                marginRight: '10px',
                              }}
                            >
                              Desconto Plano Odontológico:
                            </span>
                            {`${formatMoeda(descontoPlanoOdontologico)}`}
                          </Box>
                          <Box flex={1} ml="0.5em" mb="10px">
                            <span
                              style={{
                                //fontWeight: 'bold',
                                marginRight: '10px',
                              }}
                            >
                              Valor Hora Extra:
                            </span>
                            {`${formatMoeda(horasExtras)}`}
                          </Box>
                        </Box>

                        <Box mt="1em" />

                        <Box display="flex">
                          <Box flex={1} mr="0.5em" mb="10px">
                            <span
                              style={{
                                //fontWeight: 'bold',
                                marginRight: '10px',
                              }}
                            >
                              Desconto Vale Transporte:
                            </span>
                            {`${formatMoeda(descontoValeTransporte)}`}
                          </Box>
                          <Box flex={1} ml="0.5em" mb="10px" />
                        </Box>
                      </TabPanel>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
