import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const DescricaoField = (props) => {
  const classes = useStyles();
  const { source } = props;
  const record = useRecordContext(props);
  const value = record ? record.record[source] : '';
  return (
    <Tooltip title={value} placement="bottom" aria-label="add2">
      <Typography className={classes.text}>{value}</Typography>
    </Tooltip>
  );
};



export default DescricaoField;
