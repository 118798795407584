import React from 'react';
import { TextInput, DateTimeInput } from 'react-admin';

import { Typography, Box } from '@material-ui/core';

import {
  IntegerInput,
  PesoInput,
  PrecoInput,
} from '../../Components/InputsReactAdmin';

const Form = (props) => (
  <Box p="1em">
    <Box display="flex">
      <Box flex={2} mr="1em">
        <Typography variant="h6" gutterBottom>
          Geral
        </Typography>

        <Box display="flex">
          <Box flex={1} ml="0.5em">
            <PrecoInput source="valor" label="Valor" fullWidth />
          </Box>
        </Box>

        <Box mt="1em" />
      </Box>
    </Box>
  </Box>
);

export default Form;
