import React from 'react';
import { Button } from 'react-admin';

import EditIcon from '@material-ui/icons/Edit';

const EditButton = ({
  label = 'ra.action.edit',
  record,
  icon = defaultIcon,
  resource,
  disabled = () => false,
  editHundle = () => {},
  ...rest
}) => (
  <Button
    label={label}
    onClick={(e) => {
      e.stopPropagation();
      editHundle({ resource, ...record });
    }}
    {...rest}
    disabled={disabled(record)}
  >
    {icon}
  </Button>
);
const defaultIcon = <EditIcon />;

export default EditButton;
