import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Rating from '@material-ui/lab/Rating';
import { useSnackbar } from 'notistack';

import {
  TextInput,
  ColorInput,
  SwitchInput,
  SelectInput,
  DialogoCompra,
  DialogoConfirmacao,
} from '../../../Components';
import { createAPI, updateAPI, getListAllAPI, api } from '../../../services';
import {
  EVENTO_MANIFESTACAO_NFE_CIENCIA_DA_OPERACAO,
  EVENTO_MANIFESTACAO_NFE_CONFIRMACAO_DA_OPERACAO,
  EVENTO_MANIFESTACAO_NFE_DESCONHECIMENTO_DA_OPERACAO,
  EVENTO_MANIFESTACAO_NFE_REGISTRO_DA_OPERACAO_NÃO_FINALIZADA,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  formatCNPJ,
  formatDateDDMMYYYYHHmm,
  formatMoeda,
} from '../../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    //borderRadius: '25px',
  },
  btnAtions: {
    //borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    //borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const optionsTipoManifestacao = [
  {
    label: 'Confirmação da Operação',
    value: EVENTO_MANIFESTACAO_NFE_CONFIRMACAO_DA_OPERACAO,
  },
  {
    label: 'Desconhecimento da Operação',
    value: EVENTO_MANIFESTACAO_NFE_DESCONHECIMENTO_DA_OPERACAO,
  },
  {
    label: 'Operação não finalizada',
    value: EVENTO_MANIFESTACAO_NFE_REGISTRO_DA_OPERACAO_NÃO_FINALIZADA,
  },
];

const optionsTipoJustificativa = [
  {
    label: 'Pedido totalmente avariado.',
    value: 'Pedido totalmente avariado.',
  },
  {
    label: 'Mercadoria não recebida dentro do prazo limite (30 dias).',
    value: 'Mercadoria não recebida dentro do prazo limite (30 dias).',
  },
  {
    label:
      'Houve sinistro na carga durante o transporte (roubo, furto, acidente)',
    value:
      'Houve sinistro na carga durante o transporte (roubo, furto, acidente)',
  },
  {
    label: 'O produto errado foi entregue.',
    value: 'O produto errado foi entregue.',
  },
];

const colorHeader = SECONDARY_COLOR;

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose = () => {} }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [carregando, setCarregando] = useState(false);
    const [tipoManifestacao, setTipoManifestacao] = useState(
      EVENTO_MANIFESTACAO_NFE_CONFIRMACAO_DA_OPERACAO,
    );
    const [justificativa, setJustificativa] = useState(null);

    const [chave, setChave] = useState('');

    const [dadosNfe, setDadosNfe] = useState(null);

    const refDialogoCompra = useRef(null);

    const classes = useStyles({ cor: '#009FD4' });

    const refDialogoConfirmacao = useRef(null);

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    const handleCloseDialog2 = () => {
      setOpen(false);
      handleClose();
    };

    async function marcarManifestacao() {
      if (
        tipoManifestacao ===
          EVENTO_MANIFESTACAO_NFE_DESCONHECIMENTO_DA_OPERACAO ||
        tipoManifestacao ===
          EVENTO_MANIFESTACAO_NFE_REGISTRO_DA_OPERACAO_NÃO_FINALIZADA
      ) {
        const response = await api.put(`/define_manifestacao/${chave}`, {
          tipoManifestacao,
          justificativa,
        });

        const { status, data, message } = response.data;

        if (status) {
          handleCloseDialog();
          enqueueSnackbar('Manifestação feita com sucesso junto a SEFAZ', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      }
    }

    function getMessage() {
      switch (tipoManifestacao) {
        case EVENTO_MANIFESTACAO_NFE_DESCONHECIMENTO_DA_OPERACAO:
          return `Tem certeza que deseja confirmar essa manifestação como sendo "DESCONHECIMENTO DA OPERAÇÃO"`;
        case EVENTO_MANIFESTACAO_NFE_REGISTRO_DA_OPERACAO_NÃO_FINALIZADA:
          return `Tem certeza que deseja confirmar essa manifestação como sendo "OPERAÇÃO NÃO FINALIZADA" com a justificativa de "${justificativa}"`;
        default:
          return '';
      }
    }

    async function defineManifestacao() {
      if (
        tipoManifestacao !== EVENTO_MANIFESTACAO_NFE_CONFIRMACAO_DA_OPERACAO
      ) {
        if (refDialogoConfirmacao.current) {
          refDialogoConfirmacao.current.handleOpen(
            'Confirmação da manifestação da Nfe junto a SEFAZ',
            getMessage(),
            0,
          );
        }
      } else {
        setOpen(false);
        if (refDialogoCompra.current) {
          refDialogoCompra.current.handleOpenApartirDaNfeCompra(chave);
        }
      }
    }

    function handleConfirma(codigo) {
      switch (codigo) {
        case 0:
          marcarManifestacao();
          break;
        default:
          break;
      }
    }

    async function getNfe(chave) {
      const response = await api.get(`/get_nfe_compra_by_chave/${chave}`);
      const { status, data, message } = response.data;

      if (status) {
        if (
          data.tipo_manifestacao === EVENTO_MANIFESTACAO_NFE_CIENCIA_DA_OPERACAO
        ) {
          setDadosNfe(data);
        } else {
          setDadosNfe(null);
          enqueueSnackbar('Esta Nfe já foi manifestada!', {
            variant: 'error',
          });
          handleCloseDialog();
        }
      } else {
        setDadosNfe(null);
        enqueueSnackbar(message, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    useImperativeHandle(ref, () => ({
      handleOpen(chave) {
        setOpen(true);
        setTipoManifestacao(EVENTO_MANIFESTACAO_NFE_CONFIRMACAO_DA_OPERACAO);
        setJustificativa(null);
        setChave(chave);
        getNfe(chave);
      },
    }));

    function getError() {
      let error = '';
      if (
        tipoManifestacao ===
          EVENTO_MANIFESTACAO_NFE_REGISTRO_DA_OPERACAO_NÃO_FINALIZADA &&
        !justificativa
      ) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    const error = getError();

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          TransitionComponent={Transition}
          //fullScreen
          fullWidth
          maxWidth="md"
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            Manisfestação conclusiva da Nfe de Compra
          </DialogTitle>
          <DialogContent
            style={{
              padding: '10px',
              margin: '0',
            }}
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div>
                {dadosNfe ? (
                  <div>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      flex={1}
                      style={{
                        border: `1px solid ${PRIMARY_COLOR}`,
                        marginLeft: '0px',
                        padding: '5px',
                        borderRadius: '5px',
                        marginBottom: '10px',
                        marginTop: '10px',
                      }}
                    >
                      <Box
                        marginLeft="10px"
                        display="flex"
                        flexDirection="column"
                        flex={1}
                        style={{
                          gap: '5px',
                        }}
                      >
                        <Box
                          marginLeft="0px"
                          fontSize="15px"
                          fontWeight="bold"
                          display="flex"
                          flex={1}
                        >
                          <p
                            className={classes.textOverflow}
                            style={{
                              marginBottom: '0px',
                              color: colorHeader,
                              marginRight: '10px',
                            }}
                          >
                            NSU:
                          </p>
                          {dadosNfe.nsu}
                        </Box>
                        <Box
                          marginLeft="0px"
                          fontSize="15px"
                          fontWeight="bold"
                          display="flex"
                          flex={1}
                        >
                          <p
                            className={classes.textOverflow}
                            style={{
                              marginBottom: '0px',
                              color: colorHeader,
                              marginRight: '10px',
                            }}
                          >
                            Chave:
                          </p>
                          {dadosNfe.chave}
                        </Box>
                        <Box
                          marginLeft="0px"
                          fontSize="15px"
                          fontWeight="bold"
                          display="flex"
                          flex={1}
                          justifyContent="flex-start"
                        >
                          <p
                            className={classes.textOverflow}
                            style={{
                              marginBottom: '0px',
                              color: colorHeader,
                              marginRight: '10px',
                            }}
                          >
                            Data da Emissão:
                          </p>
                          {formatDateDDMMYYYYHHmm(dadosNfe.dhEmis)}
                        </Box>
                      </Box>
                      <Box
                        marginLeft="10px"
                        display="flex"
                        flexDirection="column"
                        flex={1}
                        style={{
                          gap: '5px',
                        }}
                      >
                        <Box
                          marginLeft="0px"
                          fontSize="15px"
                          fontWeight="bold"
                          display="flex"
                          flex={1}
                          justifyContent="flex-start"
                        >
                          <p
                            className={classes.textOverflow}
                            style={{
                              marginBottom: '0px',
                              color: colorHeader,
                              marginRight: '10px',
                            }}
                          >
                            Valor da Nfe:
                          </p>
                          {formatMoeda(dadosNfe.vNF)}
                        </Box>
                        <Box
                          marginLeft="0px"
                          fontSize="15px"
                          fontWeight="bold"
                          display="flex"
                          flex={1}
                        >
                          <p
                            className={classes.textOverflow}
                            style={{
                              marginBottom: '0px',
                              color: colorHeader,
                              marginRight: '10px',
                            }}
                          >
                            CNPJ do Emitente:
                          </p>
                          {formatCNPJ(dadosNfe.emitCnpj)}
                        </Box>
                        <Box
                          marginLeft="0px"
                          fontSize="15px"
                          fontWeight="bold"
                          display="flex"
                          flex={1}
                        >
                          <p
                            className={classes.textOverflow}
                            style={{
                              marginBottom: '0px',
                              color: colorHeader,
                              marginRight: '10px',
                            }}
                          >
                            Razão Social do Emitente:
                          </p>
                          {dadosNfe.emitRazao}
                        </Box>
                      </Box>
                    </Box>
                  </div>
                ) : null}
                <FormControl
                  variant="outlined"
                  style={{
                    marginTop: '16px',
                  }}
                  className={classes.formControl}
                  fullWidth
                >
                  <InputLabel shrink id="label-manifestacao">
                    Tipo da Manifestação
                  </InputLabel>
                  <SelectInput
                    labelId="label-manifestacao"
                    name="label-manifestacao"
                    handleEnter={() => {}}
                    label="Tipo da Manifestação"
                    handleKey={() => {}}
                    value={tipoManifestacao}
                    onChange={(value) =>
                      setTipoManifestacao(value.target.value)
                    }
                    options={optionsTipoManifestacao}
                  />
                </FormControl>

                {tipoManifestacao ===
                EVENTO_MANIFESTACAO_NFE_REGISTRO_DA_OPERACAO_NÃO_FINALIZADA ? (
                  <FormControl
                    variant="outlined"
                    style={{
                      marginTop: '16px',
                    }}
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel shrink id="label-justificativa">
                      Justificativa
                    </InputLabel>
                    <SelectInput
                      labelId="label-justificativa"
                      name="label-justificativa"
                      handleEnter={() => {}}
                      label="Justificativa"
                      handleKey={() => {}}
                      value={justificativa}
                      onChange={(value) => setJustificativa(value.target.value)}
                      options={optionsTipoJustificativa}
                      error={error !== ''}
                      helperText={error}
                    />
                  </FormControl>
                ) : null}

                <Paper
                  elevation={10}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    padding: '10px',
                    marginTop: '20px',
                  }}
                >
                  <h2>Tipos de manifestação de destinatário</h2>
                  <p>
                    A manifestação de destinatário eletrônica é considerada um
                    evento da NF-e. De acordo com a cláusula décima quinta-A, do
                    Ajuste Sinief nº 07/2005, os tipos de eventos que podem ser
                    relacionados à manifestação de destinatário de uma nota
                    fiscal eletrônica são os seguintes:
                  </p>

                  <h3>Ciência da emissão</h3>

                  <p>
                    É o registro de que o destinatário tem conhecimento da
                    emissão da NF-e contra o seu CNPJ. Isso não quer dizer que
                    ele manifesta ciência sobre a operação comercial realizada,
                    apenas sobre a existência da nota. A partir do evento da
                    ciência da emissão, ele pode baixar o arquivo XML referente
                    à NF-e. Então há um prazo para a manifestação conclusiva (de
                    acordo com uma das três categorias abaixo), que pode ser
                    diferente de estado para estado.
                  </p>

                  <h3>Confirmação da operação</h3>

                  <p>
                    O evento é registrado nessa categoria caso seja reconhecido
                    que a mercadoria à qual a nota fiscal se refere foi, de
                    fato, recebida. Também é possível confirmar mesmo se o
                    produto não chegou, mas é importante levar em conta que,
                    após a confirmação, não é mais possível cancelar a NF-e.
                  </p>

                  <h3>Operação não realizada</h3>

                  <p>
                    Se você reconhece a operação descrita na NF-e, mas não
                    recebeu a mercadoria acordada, a opção é registrar o evento
                    “operação não realizada”. Também vale para situações em que
                    houve um sinistro da carga durante o transporte ou se o
                    produto errado é entregue. Em termos práticos, Chute de
                    vendedor ou mercadoria que nunca chegou.
                  </p>

                  <h3>Desconhecimento da operação</h3>

                  <p>
                    É quando o destinatário declara que não solicitou a operação
                    descrita na NF-e. Registra-se esse evento quando a inscrição
                    estadual e CNPJ do destinatário são usados indevidamente por
                    parte do emitente da nota fiscal. Manifestando
                    desconhecimento, o destinatário se protege de possíveis
                    passivos tributários advindos de operações fraudulentas. Em
                    termos práticos, NFe de GOLPES que estelionatários compraram
                    usando o seu CNPJ.
                  </p>
                </Paper>
              </div>
            )}
          </DialogContent>
          <DialogActions
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Button
              onClick={handleCloseDialog2}
              color="primary"
              variant="contained"
              tabindex="-1"
            >
              Cancelar
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={defineManifestacao}
              tabindex="-1"
              disabled={error !== ''}
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
        <DialogoCompra ref={refDialogoCompra} handleClose={handleClose} />
        <DialogoConfirmacao
          ref={refDialogoConfirmacao}
          handleConfirma={handleConfirma}
        />
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
