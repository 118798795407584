import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  DateField,
  ListContextProvider,
  useListContext,
  ListBase,
  ListToolbar,
  Pagination,
  Datagrid,
  useRefresh,
  Title,
  TextField,
} from 'react-admin';

import { Divider, Tabs, Tab, Card, makeStyles } from '@material-ui/core';
import moment from 'moment';

import { stylesMalote } from '../../utils';
import DialogoLixeira from '../MaloteSangrias/DialogoLixeira';
import ValorTransferenciaField from '../Transacoes/Fields/ValorTransferenciaField';
import Actions from './ActionsList';
import DialogoChangeStatus from './DialogoChangeStatus';
import DialogoChangeTransportadorEntradaCofre from './DialogoChangeTransportadorEntradaCofre';
import DialogoChangeTransportadorSaidaCofre from './DialogoChangeTransportadorSaidaCofre';
import DialogoConfirmaEntradaCofre from './DialogoConfirmaEntradaCofre';
import DialogoConfirmaSaidaCofre from './DialogoConfirmaSaidaCofre';
import DialogoCreate from './DialogoCreate';
import DialogoDeletar from './DialogoDeletar';
import DialogoEdit from './DialogoEdit';
import DialogoShowTroco from './DialogoShowTroco';
import ActionsField from './Fields/ActionsField';
import CodigoField from './Fields/CodigoField';
import Conta2Field from './Fields/Conta2Field';
import ContaField from './Fields/ContaField';
import Frentista2Field from './Fields/Frentista2Field';
import FrentistaField from './Fields/FrentistaField';
import Responsavel2Field from './Fields/Responsavel2Field';
import ResponsavelField from './Fields/ResponsavelField';
import StatusField from './Fields/StatusField';
import Transportador2Field from './Fields/Transportador2Field';
import TransportadorField from './Fields/TransportadorField';
import Filter from './Filter';
////////////

const useStylesDatagrid = makeStyles((theme) => stylesMalote);

const tabs = [
  { id: 'nao_conferido', name: 'Não Utilizadas' },
  { id: 'conferido', name: 'Utilizadas' },
];

const MyList = ({ ...props }) => {
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [confirmados, setConfirmados] = useState([]);
  const [cancelados, setCancelados] = useState([]);
  const refresh = useRefresh();
  const classes = useStylesDatagrid();
  const refDialogoShowTroco = useRef(null);
  const refDialogoChangeStatus = useRef(null);
  const refDialogoCreate = useRef(null);
  const refDialogoDeletar = useRef(null);
  const refDialogoLixeira = useRef(null);
  //
  const refDialogoConfirmaEntradaCofre = useRef(null);
  const refDialogoConfirmaSaidaCofre = useRef(null);
  const refDialogoChangeTransportadorEntradaCofre = useRef(null);
  const refDialogoChangeTransportadorSaidaCofre = useRef(null);

  function changeStatus(record) {
    if (refDialogoChangeStatus.current)
      refDialogoChangeStatus.current.handleOpen(record.record.id);
  }

  const show = (data) => {
    if (refDialogoShowTroco.current) {
      refDialogoShowTroco.current.handleOpen(data.record.id);
    }
  };

  const create = (data) => {
    if (refDialogoCreate.current) {
      refDialogoCreate.current.handleOpen();
    }
  };

  const handleDeletar = (data) => {
    if (refDialogoDeletar.current) {
      refDialogoDeletar.current.handleOpen(data.record);
    }
  };

  //////////

  const handleConfirmarEntradaCofre = (data) => {
    if (refDialogoConfirmaEntradaCofre.current) {
      refDialogoConfirmaEntradaCofre.current.handleOpen(data.record.id);
    }
  };

  const handleConfirmarSaidaCofre = (data) => {
    if (refDialogoConfirmaSaidaCofre.current) {
      refDialogoConfirmaSaidaCofre.current.handleOpen(data.record.id);
    }
  };

  const handleChangeTransportadorEntradaCofre = (data) => {
    if (refDialogoChangeTransportadorEntradaCofre.current) {
      refDialogoChangeTransportadorEntradaCofre.current.handleOpen(
        data.record.id,
      );
    }
  };

  const handleChangeTransportadorSaidaCofre = (data) => {
    if (refDialogoChangeTransportadorSaidaCofre.current) {
      refDialogoChangeTransportadorSaidaCofre.current.handleOpen(
        data.record.id,
      );
    }
  };

  ///////////

  useEffect(() => {
    if (ids && ids !== filterValues.visible) {
      switch (filterValues.visible) {
        case true:
          setCancelados(ids);
          break;
        case false:
          setConfirmados(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.visible]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters(
          { ...filterValues, visible: value === 'nao_conferido' },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  function handleClose() {
    refresh();
  }

  function handleLixeira(data) {
    if (refDialogoLixeira.current) {
      refDialogoLixeira.current.handleOpen(data.record.id);
    }
  }

  return (
    <>
      <Title title="Trocos" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} handleCriar={create} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Divider />
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.visible ? tabs[0].id : tabs[1].id}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.visible === true && (
            <ListContextProvider value={{ ...listContext, ids: cancelados }}>
              <Datagrid {...props}>
                {/* <StatusField
                  source="codigo"
                  label=""
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                /> */}
                <CodigoField
                  source="codigo"
                  label="Código"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  //sortable={false}
                />
                <DateField
                  source="data_pagamento"
                  label="Horário (Ida ao Cofre)"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  options={{ hour: 'numeric', minute: 'numeric' }}
                  sortable={false}
                />
                <TextField
                  source="sessao_id"
                  label="Sessão (Ida ao Cofre)"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <ContaField
                  label="Caixa (Ida ao Cofre)"
                  source="conta_id"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <FrentistaField
                  label="Frentista (Ida ao Cofre)"
                  source="responsavel"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <ResponsavelField
                  label="Responsável (Ida ao Cofre)"
                  source="responsavel"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <TransportadorField
                  label="Transportador (Ida ao Cofre)"
                  source="transportador1_id"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                  enableChangeTransportador
                  enableCheckEntrada
                  handleChange={handleChangeTransportadorEntradaCofre}
                  handleEntradaCofre={handleConfirmarEntradaCofre}
                />
                <Transportador2Field
                  label="Transportador (Volta ao Checkout)"
                  source="transportador1_id"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                  enableChangeTransportador
                  enableCheckEntrada
                  handleChange={handleChangeTransportadorSaidaCofre}
                  handleEntradaCofre={handleConfirmarSaidaCofre}
                />
                <ValorTransferenciaField
                  source="valor"
                  textAlign="right"
                  label="Valor"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.valorCell}
                  sortable={false}
                />
                <ActionsField
                  source="lalala"
                  handleEfetivar={changeStatus}
                  handleShow={show}
                  textAlign="right"
                  label="Ações"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.actionsCell}
                  sortable={false}
                  handleDeletar={handleDeletar}
                  handleLixeira={handleLixeira}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.visible === false && (
            <ListContextProvider value={{ ...listContext, ids: confirmados }}>
              <Datagrid {...props}>
                {/* <StatusField
                  source="codigo"
                  label=""
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                /> */}
                <CodigoField
                  source="codigo"
                  label="Código"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  //sortable={false}
                />
                <DateField
                  source="data_pagamento"
                  label="Horário (Ida ao Cofre)"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  options={{ hour: 'numeric', minute: 'numeric' }}
                  sortable={false}
                />
                <TextField
                  source="sessao_id"
                  label="Sessão (Ida ao Cofre)"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <ContaField
                  label="Caixa (Ida ao Cofre)"
                  source="conta_id"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <FrentistaField
                  label="Frentista (Ida ao Cofre)"
                  source="responsavel"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <ResponsavelField
                  label="Responsável (Ida ao Cofre)"
                  source="responsavel"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <TransportadorField
                  label="Transportador (Ida ao Cofre)"
                  source="transportador1_id"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <DateField
                  source="data_pagamento2"
                  label="Horário (Volta ao Checkout)"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  options={{ hour: 'numeric', minute: 'numeric' }}
                  sortable={false}
                />
                <TextField
                  source="sessao2_id"
                  label="Sessão (Volta ao Checkout)"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <Conta2Field
                  label="Caixa (Volta ao Checkout)"
                  source="conta_id"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <Frentista2Field
                  label="Frentista (Volta ao Checkout)"
                  source="responsavel"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                {/*<Responsavel2Field
                  label="Responsável (Volta ao Checkout)"
                  source="responsavel"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
              />*/}
                <Transportador2Field
                  label="Transportador (Volta ao Checkout)"
                  source="transportador1_id"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <ValorTransferenciaField
                  source="valor"
                  textAlign="right"
                  label="Valor"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.valorCell}
                  sortable={false}
                />
                <ActionsField
                  source="lalala"
                  handleEfetivar={changeStatus}
                  handleShow={show}
                  textAlign="right"
                  label="Ações"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.actionsCell}
                  sortable={false}
                  handleDeletar={handleDeletar}
                />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
        <TransacoesPagination />
      </Card>
      <DialogoEdit ref={refDialogoChangeStatus} handleClose={handleClose} />
      <DialogoShowTroco ref={refDialogoShowTroco} />
      <DialogoCreate ref={refDialogoCreate} handleClose={handleClose} />
      <DialogoDeletar ref={refDialogoDeletar} handleClose={handleClose} />
      <DialogoLixeira ref={refDialogoLixeira} handleClose={handleClose} />
      {/** */}
      <DialogoChangeTransportadorEntradaCofre
        ref={refDialogoChangeTransportadorEntradaCofre}
        handleClose={handleClose}
      />
      <DialogoChangeTransportadorSaidaCofre
        ref={refDialogoChangeTransportadorSaidaCofre}
        handleClose={handleClose}
      />
      <DialogoConfirmaEntradaCofre
        ref={refDialogoConfirmaEntradaCofre}
        handleClose={handleClose}
      />
      <DialogoConfirmaSaidaCofre
        ref={refDialogoConfirmaSaidaCofre}
        handleClose={handleClose}
      />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50]} {...props} />
);

const PostPanel = ({ record }) => (
  <div>
    <br />
    {record.data_confirmacao_transporte_1 ? (
      <p>
        <span style={{ fontWeight: 'bold' }}>Horário Entrada no cofre: </span>
        {record.data_confirmacao_transporte_1
          ? moment(new Date(record.data_confirmacao_transporte_1)).format(
              'DD/MM/YYYY - HH:mm',
            )
          : '-'}
      </p>
    ) : null}
    <br />
    {record.data_confirmacao_transporte_2 ? (
      <p>
        <span style={{ fontWeight: 'bold' }}>Horário Saída do cofre: </span>
        {record.data_confirmacao_transporte_2
          ? moment(new Date(record.data_confirmacao_transporte_2)).format(
              'DD/MM/YYYY - HH:mm',
            )
          : '-'}
      </p>
    ) : null}
    <br />
  </div>
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Trocos"
      expand={<PostPanel />}
      filterDefaultValues={{
        visible: true,
      }}
    />
  </>
);

export default List2;
