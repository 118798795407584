import React from 'react';
import {
  Filter as FilterAdmin,
  TextInput,
  SelectInput,
  BooleanInput,
} from 'react-admin';

import choices from './choices';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <TextInput label="Username" source="username" />
    <BooleanInput label="É Colaborador" source="is_colaborador" />
    <SelectInput
      source="funcao"
      choices={choices}
      label="Cargo"
      allowEmpty={false}
    />
  </FilterAdmin>
);

export default Filter;
