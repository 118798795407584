import React from 'react';
import {
  Filter as FilterAdmin,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

import { STATUS_ATIVO } from '../../utils';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <ReferenceInput
      fullWidth
      label="Colaborador"
      source="colaborador_id"
      reference="colaboradores"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      filter={{ status: STATUS_ATIVO }}
      perPage={30}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput>
    <ReferenceInput
      fullWidth
      label="Fardamento"
      source="fardamento_id"
      reference="fardamentos"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      perPage={30}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput>
  </FilterAdmin>
);

export default Filter;
