import React, { useContext, useEffect } from 'react';
import { AppBar, useLogout } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Context from '../../context';
import { APP_VERSION } from '../../utils';
import UserMenu from '../UserMenu';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
});

const AppBarCustom = (props) => {
  const classes = useStyles();
  const logout = useLogout();

  const [total, setTotal] = useContext(Context);

  useEffect(() => {
    if (total) {
      setTotal(0);
      logout2();
    }
  }, [total]);

  function logout2() {
    logout();
  }

  return (
    <>
      <AppBar {...props} userMenu={<UserMenu />} elevation={1}>
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        >
          {`Módulo Gerencial (${APP_VERSION}) - B - `}{' '}
        </Typography>
      </AppBar>
    </>
  );
};

export default AppBarCustom;
