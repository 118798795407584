import React, { useState, useEffect } from 'react';
import { Title } from 'react-admin';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { api, getListAPI } from '../../../services';
import Filtros from './Filtros';
import PeriodoInput from './PeriodoFilter';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const DashbboardContabilidade = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState({
    linhas: [],
  });
  const { enqueueSnackbar } = useSnackbar();
  const [periodo, setPeriodo] = useState({
    start: new Date(),
    end: new Date(),
  });
  const [tipoPeriodo, setTipoPeriodo] = useState(0);

  async function handleData() {
    setLoading(true);
    try {
      const data = await getListAPI(
        'indicadores/kpi',
        ['nome', 'asc'],
        [1, 10],
        { periodo },
        [''],
      );
      setDados({ linhas: data.data });
      setLoading(false);
    } catch (e) {
      setDados({ linhas: [] });
      setLoading(false);
      enqueueSnackbar(`Erro ao carregar os dados do relatório!`, {
        variant: 'error',
      });
    }
  }

  useEffect(() => {
    handleData();
  }, []);

  function getPeriodo(tipo) {
    switch (tipo) {
      case 0: {
        // hj
        const dataInicio = new Date(moment().format());
        const dataFinal = new Date(moment().format());
        return {
          inicio: dataInicio,
          fim: dataFinal,
        };
      }
      case 1: {
        // 3 dias
        const dataInicio = new Date(moment().subtract(2, 'days').format());
        const dataFinal = new Date(moment().format());
        return {
          inicio: dataInicio,
          fim: dataFinal,
        };
      }
      case 2: {
        // 7 dias
        const dataInicio = new Date(moment().subtract(6, 'days').format());
        const dataFinal = new Date(moment().format());
        return {
          inicio: dataInicio,
          fim: dataFinal,
        };
      }
      case 3: {
        // 14 dias
        const dataInicio = new Date(moment().subtract(2, 'weeks').format());
        const dataFinal = new Date(moment().format());
        return {
          inicio: dataInicio,
          fim: dataFinal,
        };
      }
      case 4: {
        // 1 mês
        const dataInicio = new Date(moment().subtract(1, 'month').format());
        const dataFinal = new Date(moment().format());
        return {
          inicio: dataInicio,
          fim: dataFinal,
        };
      }
      default: {
        // hj
        const dataInicio = new Date(moment().format());
        const dataFinal = new Date(moment().format());
        return {
          inicio: dataInicio,
          fim: dataFinal,
        };
      }
    }
  }

  useEffect(() => {
    const { inicio, fim } = getPeriodo(tipoPeriodo);
    setPeriodo({
      start: inicio,
      end: fim,
    });
    // setTipoPeriodo(tipoPeriodo);
  }, [tipoPeriodo]);

  return (
    <div>
      <div
        style={{
          fontSize: '25px',
          fontWeight: 'bold',
          marginTop: '35px',
          marginBottom: '30px',
          textAlign: 'center',
        }}
      >
        Indicadores KPI
      </div>
      <Title title="Relatório Produtos" />
      <div
        style={{
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <PeriodoInput
            onChange={(e) => setPeriodo(e.target.value)}
            value={periodo}
          />
        </div>
        <Tooltip
          title="Imprimir relatório"
          placement="top"
          aria-label="Imprimir"
        >
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={handleData}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <Filtros
            periodo={tipoPeriodo}
            handlePeriodo={(value) => setTipoPeriodo(value)}
          />
        </div>
        <Paper
          style={{
            flex: 3,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Indicador</StyledTableCell>
                  <StyledTableCell align="right">Valor</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dados.linhas.map((row) => (
                  <StyledTableRow key={row.indicador}>
                    <StyledTableCell>{row.indicador}</StyledTableCell>
                    <StyledTableCell align="right">{row.valor}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default DashbboardContabilidade;
