import React from 'react';
import { Datagrid as DatagridAdmin } from 'react-admin';

import useStyles from './headerStyles';

const Datagrid = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <DatagridAdmin classes={{ headerCell: classes.row }} {...rest}>
      {children}
    </DatagridAdmin>
  );
};

export default Datagrid;
