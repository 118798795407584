import { renameItensUIDD, getSubTotal } from '../../../../../utils';

const reducer = (state, action) => {
  let arrayNew = state.itens.slice();
  switch (action.type) {
    case 'ADD_ITEM':
      return {
        ...state,
        itens: [...arrayNew, action.item],
        subTotal: getSubTotal([...arrayNew, action.item]),
      };
    case 'REMOVE_ITEM':
      for (let i = 0; i < action.indices.length; i += 1) {
        arrayNew = arrayNew.filter((obj) => obj.uidd !== action.indices[i]);
      }
      return {
        ...state,
        itens: [...renameItensUIDD(arrayNew)],
        subTotal: getSubTotal([...arrayNew]),
      };
    case 'UPDATE_ITEMS':
      return {
        ...state,
        itens: [...renameItensUIDD(action.itens)],
        subTotal: getSubTotal([...action.itens]),
      };

    case 'UPDATE_CLIENTE':
      return {
        ...state,
        cliente: action.cliente,
      };

    case 'UPDATE_ID_EDIT':
      return {
        ...state,
        idEdit: action.idEdit,
      };

    case 'UPDATE_IS_EDIT_PRICE':
      return {
        ...state,
        isEditPrice: action.isEditPrice,
      };

    case 'RESETAR_TUDO':
      return {
        ...state,
        isEditPrice: false,
        idEdit: -1,
        itens: [],
        subTotal: 0,
        cliente: null,
      };

    case 'PREENCHER_TUDO':
      return {
        ...state,
        isEditPrice: false,
        idEdit: action.idEdit,
        itens: [...renameItensUIDD(action.itens)],
        subTotal: getSubTotal([...action.itens]),
        cliente: action.cliente,
      };

    case 'PREENCHER_TUDO_PEDIDO':
      return {
        ...state,
        isEditPrice: false,
        idEdit: -1,
        itens: [...renameItensUIDD(action.itens)],
        subTotal: getSubTotal([...action.itens]),
        cliente: action.cliente,
      };
    default:
      return state;
  }
};

export default reducer;
