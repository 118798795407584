import React, { useEffect, useState } from 'react';

import { Paper, Box, makeStyles } from '@material-ui/core';

import {
  getOneAPI,
  getCaixaId,
  getSessionId,
  getUsername,
} from '../../../../../services';
import Temporizador from './Temporizador';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '10px',
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    boxSizing: 'border-box',
    opacity: '0.75',
  },
  containerInterno: {
    marginTop: '10px',
    fontSize: '15px',
    padding: '10px 10px',
  },
}));

const LabelAtendente = ({
  descontoUsadoCliente,
  descontoDisponivelCliente,
  cliente,
  isEmPartes,
}) => {
  const classes = useStyles();
  const [nomeCaixa, setNomeCaixa] = useState('');

  async function getNomeCaixa() {
    const data = await getOneAPI('contas', getCaixaId(), []);
    setNomeCaixa(data.data.nome);
  }

  useEffect(() => {
    async function getDatas() {
      await getNomeCaixa();
    }
    getDatas();
  }, []);

  function valorDisponivel() {
    if (descontoDisponivelCliente === 0) {
      return 0;
    }
    return descontoDisponivelCliente - descontoUsadoCliente;
  }

  const isFidelidade = cliente ? cliente.is_fidelidade : false;

  return (
    <Paper className={classes.container} elevation={3}>
      <Box display="flex" flexDirection="column" width="100%" color="white">
        <div style={{ textAlign: 'center' }}>{nomeCaixa}</div>

        <Paper className={classes.containerInterno} elevation={3}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
            flexDirection="column"
            width="100%"
            color="black"
          >
            {/*  <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <span style={{ fontWeight: 'bold' }}>Atendente: </span>
              <span>{getUsername()}</span>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                margin: '10px 0px',
              }}
            >
              <span style={{ fontWeight: 'bold' }}>Id da sessão: </span>
              <span>{getSessionId()}</span>
            </div> */}
            <Temporizador />
            {/* {isFidelidade && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  margin: '10px 0px',
                }}
              >
                <span style={{ fontWeight: 'bold' }}>
                  Desconto Disponível:{' '}
                </span>
                <span>{formatMoeda(valorDisponivel())}</span>
              </div>
            )} */}
            {isEmPartes ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  margin: '10px 0px',
                }}
              >
                <span style={{ fontSize: '30px', color: 'green' }}>
                  Entrega Parcial
                </span>
              </div>
            ) : (
              <div />
            )}
          </Box>
        </Paper>
      </Box>
    </Paper>
  );
};

export default LabelAtendente;
