import React from 'react';
import { Filter as FilterAdmin, TextInput, SelectInput } from 'react-admin';

const choices = [
  { id: '0', name: 'NCM' },
  { id: '1', name: 'NBS' },
  { id: '2', name: 'LC 116/2003' },
];

const Filter = (props) => (
  <FilterAdmin {...props}>
    <TextInput label="Descrição" source="descricao" />
    <TextInput label="Código" source="codigo" />
    <TextInput label="Exceção" source="excecao" />
    <SelectInput
      sortable={false}
      source="tipo"
      label="Tipo"
      choices={choices}
    />
  </FilterAdmin>
);

export default Filter;
