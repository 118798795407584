import React, { forwardRef, useState, useImperativeHandle } from 'react';
import { Doughnut } from 'react-chartjs-2';

import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

import { ceil10, formatMoeda } from '../../../../utils';

//ChartJS.plugins.register(ArcElement, Tooltip, Legend);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const Grafico = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [dados, setDados] = useState(null);

  const classes = useStyles({ cor: '#009FD4' });

  useImperativeHandle(ref, () => ({
    handleOpen(dados) {
      setOpen(true);
      setDados(dados);
    },
  }));

  const handleCloseDialog = () => {
    setOpen(false);
  };

  function getDados() {
    if (!dados) {
      return [0, 0, 0, 0, 0, 0, 0];
    }
    /*return [
      { id: 'Sales', nested: { value: 1500 } },
      { id: 'Purchases', nested: { value: 500 } },
    ];*/
    return [
      ceil10(dados.valorNegociadoComAIndustria, -2),
      ceil10(dados.margemDeCustoOperacional, -2),
      ceil10(dados.diferencaDeIcms, -2),
      ceil10(dados.lucroBruto, -2),
      ceil10(dados.diferencaDeCofins, -2),
      ceil10(dados.margemDeCustoDeCartao, -2),
      ceil10(dados.diferencaDePis, -2),
    ];
  }

  const data = {
    labels: [
      'Valor Negociado com a indústria',
      'Margem de Custo Operacional',
      'Diferença de ICMS',
      'Lucro Bruto',
      'Diferença de COFINS',
      'Margem de Custo de Cartão',
      'Diferença de PIS',
    ],
    datasets: [
      {
        label: '# of Votes',
        data: getDados(),
        backgroundColor: [
          'rgba(68, 114, 196, 1)',
          'rgba(237, 125, 49, 1)',
          'rgba(165, 165, 165, 1)',
          'rgba(91, 155, 213, 1)',
          'rgba(112, 173, 71, 1)',
          'rgba(68, 114, 196, 1)',
          'rgba(237, 125, 49, 1)',
        ],
        borderColor: [
          'rgba(68, 114, 196, 1)',
          'rgba(237, 125, 49, 1)',
          'rgba(165, 165, 165, 1)',
          'rgba(91, 155, 213, 1)',
          'rgba(112, 173, 71, 1)',
          'rgba(68, 114, 196, 1)',
          'rgba(237, 125, 49, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      classes={{ paper: classes.rootDialogo }}
      className={classes.rootDialogo}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        id="form-dialog-title"
        onClose={handleCloseDialog}
        style={{ backgroundColor: '#009FD4', color: 'white' }}
      >
        {`Composição do Preço de Venda ${
          dados ? formatMoeda(dados.precoVenda) : ''
        }`}
      </DialogTitle>
      <DialogContent>
        {dados ? (
          <div>
            <Doughnut
              data={data}
              /*options={{
                parsing: {
                  key: 'nested.value',
                },
              }}*/
            />
          </div>
        ) : (
          'Nenhum gráfico disponível'
        )}
      </DialogContent>
    </Dialog>
  );
});

export default Grafico;
