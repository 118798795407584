import React from 'react';
import {
  Filter as FilterAdmin,
  TextInput,
  SelectInput,
  ReferenceInput,
  BooleanInput,
} from 'react-admin';

import { PrecoInput, IntegerInput } from '../../Components/InputsReactAdmin';
import { STATUS_ATIVO } from '../../utils/constants';

export const choicesTipo = [
  { id: 'Receita', name: 'Receita' },
  { id: 'Despesa', name: 'Despesa' },
];

const Filter = (props) => (
  <FilterAdmin {...props}>
    <TextInput label="Descrição" source="nome" />
    <ReferenceInput
      source="conta_id"
      reference="contas_normal"
      label="Caixa"
      filter={{ status: STATUS_ATIVO, is_caixa: true }}
      allowEmpty={false}
    >
      <SelectInput optionText="nome" allowEmpty={false} />
    </ReferenceInput>
    <SelectInput
      source="tipo"
      choices={choicesTipo}
      label="Tipo"
      allowEmpty={false}
    />
    <PrecoInput source="valor" label="Valor" fixedDecimalScale={false} />
    <IntegerInput source="sessao_id" label="Id Sessão" prefixo="#" />
    <IntegerInput source="id_sessao2" label="periodo Id Sessão" prefixo="#" />
    <BooleanInput label="Status" source="status" />
    <BooleanInput label="Visíveis" source="visible" />
  </FilterAdmin>
);

export default Filter;
