import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
  useEffect,
} from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { PRIMARY_COLOR } from '../../../../../../utils';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogoAprovacaoVolta = forwardRef(
  ({ handleClose = () => {}, handleSalvar = () => {} }, ref) => {
    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
      handleOpen() {
        setOpen(true);
      },
    }));

    function handleCloseDialog() {
      setOpen(false);
      handleClose();
    }

    const TAMANHO_PEQUENO = 'sm';
    const TAMANHO_GRANDE = 'lg';

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth={TAMANHO_PEQUENO}
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={() => handleCloseDialog()}
            style={{ backgroundColor: PRIMARY_COLOR, color: 'white' }}
          >
            Aprovação
          </DialogTitle>
          <DialogContent dividers>
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '40px',
                fontWeight: 'bold',
              }}
            >
              lllllll
            </div>
          </DialogContent>
          <DialogActions
            style={{
              padding: '20px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={() => handleCloseDialog()}
              variant="contained"
              color="primary"
            >
              Cancelar
            </Button>
            <Button
              onClick={() => handleSalvar()}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoAprovacaoVolta;
