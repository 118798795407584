import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import moment from 'moment';

import {
  STATUS_ANDAMENTO_ATIVIDADE_EM_ANDAMENTO,
  STATUS_ANDAMENTO_ATIVIDADE_FINALIZADA,
} from '../../../utils';

const useStyles = makeStyles((theme) => ({
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
    cursor: 'pointer',
    //border: '1px solid black',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
    cursor: 'pointer',
  },
  cartao: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#00796b',
    marginLeft: '10px',
  },
}));

function verificaDataFutura(data) {
  const dataAtual = new Date(moment().startOf('day').format());
  const dataData = new Date(moment(data).startOf('day').format());

  if (dataAtual.getTime() < dataData.getTime()) {
    return true;
  }

  return false;
}

const StatusField = ({ handleEfetivar = () => {}, ...props }) => {
  const classes = useStyles();
  const record = useRecordContext(props);

  const value = record
    ? record.record.andamento === STATUS_ANDAMENTO_ATIVIDADE_FINALIZADA
    : false;

  const verificaDataFuturaValue = record
    ? verificaDataFutura(record.record.data_entrega)
    : true;

  return (
    <div>
      {!verificaDataFuturaValue ? (
        value ? (
          <Tooltip title="" placement="bottom" aria-label="add2">
            <IconButton
              className={classes.confirmado}
              onClick={() => handleEfetivar(record.record)}
              disabled={verificaDataFuturaValue}
            >
              <CheckIcon style={{ fontSize: 15 }} />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="" placement="bottom" aria-label="add2">
            <IconButton
              className={classes.pendente}
              onClick={() => handleEfetivar(record.record)}
              disabled={verificaDataFuturaValue}
            >
              <PriorityHighIcon style={{ fontSize: 15 }} />
            </IconButton>
          </Tooltip>
        )
      ) : null}
    </div>
  );
};



export default StatusField;
