import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useListContext } from 'react-admin';

import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ExcelJS from 'exceljs';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { SelectInput } from '../../../Components';
import { getListAllAPI, api, getListAPI } from '../../../services';
import { formatMoeda } from '../../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const optionsTipoPlanilha = [
  {
    label: 'Rede',
    value: 0,
  },
  {
    label: 'PagBank',
    value: 1,
  },
];

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [carregando, setCarregando] = useState(false);
  const [file, setFile] = useState(null);
  const listContext = useListContext();
  const { filterValues } = listContext;
  const [naoEncontrado, setNaoEncontrado] = useState([]);
  const [cont, setCont] = useState(0);
  const [tipoPlanilha, setTipoPlanilha] = useState(
    optionsTipoPlanilha[1].value,
  );

  const periodo = filterValues.periodo;

  const classes = useStyles();

  const handleCloseDialog = () => {
    setOpen(false);
    setCarregando(false);
    if (cont > 0) {
      handleClose();
    }
  };

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setCarregando(false);
      setOpen(true);
      setNaoEncontrado([]);
      setCont(0);
      setTipoPlanilha(optionsTipoPlanilha[1].value);
    },
  }));

  function getPeriodoConfereciaGetnet(periodo) {
    if (periodo) {
      const dia = periodo.dia;
      const mes = periodo.mes;
      const ano = periodo.ano;
      const newData = new Date();
      newData.setFullYear(ano);
      newData.setMonth(mes);
      newData.setDate(dia);
      newData.setMonth(mes);
      newData.setFullYear(ano);
      const dataStart = new Date(moment(newData).startOf('day').format());
      const dataEnd = new Date(moment(newData).endOf('day').format());
      return {
        start: dataStart,
        end: dataEnd,
      };
    }
    return null;
  }

  async function getTransacoes() {
    const response = await getListAPI(
      'transacoes_conferencia_get_net',
      ['data_pagamento', 'desc'],
      [1, 1000],
      { periodo },
      [],
    );

    return response.data;
  }

  const nMinutos = 4;

  const toleranciaSegundos = nMinutos * 60 * 1000;

  function encontraCorrespondencias(dadosSistema, dadosPlanilha) {
    const encontrados = [];
    const naoEncontrados = [];
    for (let i = 0; i < dadosPlanilha.length; i++) {
      const { valor, modalidade } = dadosPlanilha[i];
      const dadosSistemaFiltrados = dadosSistema.filter(
        (item) => item.valor === valor && item.modalidade === modalidade,
      );

      if (dadosSistemaFiltrados.length === 0) {
        naoEncontrados.push(dadosPlanilha[i]);
      } else if (dadosSistemaFiltrados.length === 1) {
        /*const diferencaTempo = Math.abs(
          dadosSistemaFiltrados[0].data.getTime() -
            dadosPlanilha[i].data.getTime(),
        );

        if (diferencaTempo <= toleranciaSegundos) {
          encontrados.push(dadosSistemaFiltrados[0]);
        } else {
          naoEncontrados.push(dadosPlanilha[i]);
        }*/

        encontrados.push(dadosSistemaFiltrados[0]);
      } else {
        let diferencaTempo = Math.abs(
          dadosSistemaFiltrados[0].data.getTime() -
            dadosPlanilha[i].data.getTime(),
        );

        let selecionado = dadosSistemaFiltrados[0];

        for (let j = 0; j < dadosSistemaFiltrados.length; j++) {
          const diferencaTempo2 = Math.abs(
            dadosSistemaFiltrados[j].data.getTime() -
              dadosPlanilha[i].data.getTime(),
          );
          if (diferencaTempo2 < diferencaTempo) {
            diferencaTempo = diferencaTempo2;
            selecionado = dadosSistemaFiltrados[j];
          }
        }

        if (diferencaTempo <= toleranciaSegundos) {
          encontrados.push(selecionado);
        } else {
          naoEncontrados.push(dadosPlanilha[i]);
        }
      }
    }
    return {
      encontrados,
      naoEncontrados,
    };
  }

  function getMeioPagamento(nome) {
    if (nome.includes('Cartão de Crédito')) return 'crédito';
    if (nome.includes('Cartão de Débito')) return 'débito';
    if (nome.includes('Voucher')) return 'voucher';
    if (nome.includes('Pix')) return 'pix';
    return '';
  }

  function formatTransacoes(transacoes) {
    return transacoes.map((item) => ({
      id: item.id,
      data: new Date(item.created_at),
      valor: item.valor,
      modalidade: getMeioPagamento(item.nome),
    }));
  }

  async function salvarEncontrados(encontrados) {
    const ids = encontrados.map((item) => item.id);
    const response = await api.put('/confirma_verificacao_planilha_cartoes', {
      ids,
    });
  }

  function verificaValidadePlanilhaRede(worksheetVendas) {
    const row = worksheetVendas.getRow(2);
    if (row.getCell(4).value.trim() !== 'valor da venda original')
      throw `Não exite na planilha a coluna "valor da venda original" como 4ª coluna`;
    if (row.getCell(1).value.trim() !== 'data da venda')
      throw `Não exite na planilha a coluna "data da venda" como 1ª coluna`;
    if (row.getCell(2).value.trim() !== 'hora da venda')
      throw `Não exite na planilha a coluna "hora da venda" como 2ª coluna`;
    if (row.getCell(6).value.trim() !== 'modalidade')
      throw `Não exite na planilha a coluna "modalidade" como 6ª coluna`;
  }

  function verificaValidadePlanilhaPagBank(worksheetVendas) {
    const row = worksheetVendas.getRow(1);
    if (row.getCell(8).value.trim() !== 'Valor_Bruto')
      throw `Não exite na planilha a coluna "Valor_Bruto" como 8ª coluna`;
    if (row.getCell(14).value.trim() !== 'Data_Transacao')
      throw `Não exite na planilha a coluna "Data_Transacao" como 14ª coluna`;
    if (row.getCell(7).value.trim() !== 'Tipo_Pagamento')
      throw `Não exite na planilha a coluna "Tipo_Pagamento" como 7ª coluna`;
  }

  async function getDados() {
    setCarregando(true);
    try {
      if (file) {
        const buff = await file.arrayBuffer();
        const data = new Uint8Array(buff);
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(data);

        const worksheetVendas = workbook.getWorksheet('vendas');

        verificaValidadePlanilhaRede(worksheetVendas);

        const nVendas = worksheetVendas.rowCount;

        const dadosFomatados = [];

        const periodoFormatado = getPeriodoConfereciaGetnet(periodo);

        for (let i = 3; i <= nVendas; i++) {
          const row = worksheetVendas.getRow(i);
          const valor = row.getCell(4).value;
          if (Number.isNaN(Number.parseFloat(valor))) break;
          const data = row.getCell(1).value;
          data.setTime(data.getTime() + 3 * 60 * 60 * 1000);
          const horario = new Date(row.getCell(2).value);
          horario.setTime(horario.getTime() + 3 * 60 * 60 * 1000);
          const modalidade = row.getCell(6).value;

          const dataTotal = new Date(data);
          dataTotal.setTime(
            dataTotal.getTime() +
              horario.getHours() * 60 * 60 * 1000 +
              horario.getMinutes() * 60 * 1000 +
              horario.getSeconds() * 1000 +
              horario.getMilliseconds(),
          );

          if (
            periodoFormatado.start.getTime() > dataTotal.getTime() ||
            periodoFormatado.end.getTime() < dataTotal.getTime()
          ) {
            throw `Esta planilha possui dados fora do intervalo de tempo solicitado (${moment(
              periodoFormatado.start,
            ).format('DD/MM/YYYY')})!`;
          }

          dadosFomatados.push({
            data: dataTotal,
            valor,
            modalidade,
            linha: i,
          });
        }

        let transacoes = await getTransacoes();
        transacoes = formatTransacoes(transacoes);

        const { encontrados, naoEncontrados } = encontraCorrespondencias(
          transacoes,
          dadosFomatados,
        );

        await salvarEncontrados(encontrados);
        setNaoEncontrado(naoEncontrados);
        setCont(1);
        //handleCloseDialog();
        //handleClose();
      } else {
        enqueueSnackbar('É necessário carregar a planilha!', {
          variant: 'error',
        });
      }
    } catch (e) {
      enqueueSnackbar(e, {
        variant: 'error',
      });
    } finally {
      setCarregando(false);
    }
  }

  async function getDadosPagBank() {
    setCarregando(true);
    try {
      if (file) {
        const buff = await file.arrayBuffer();
        const data = new Uint8Array(buff);
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(data);

        const worksheetVendas = workbook.getWorksheet('vendas');

        verificaValidadePlanilhaPagBank(worksheetVendas);

        const nVendas = worksheetVendas.rowCount;

        let dadosFomatados = [];

        const periodoFormatado = getPeriodoConfereciaGetnet(periodo);

        for (let i = 2; i <= nVendas; i++) {
          const row = worksheetVendas.getRow(i);
          const valor = row.getCell(8).value;
          if (Number.isNaN(Number.parseFloat(valor))) break;
          const data = row.getCell(14).value;
          data.setTime(data.getTime() + 3 * 60 * 60 * 1000);
          const modalidade = row.getCell(7).value;

          const dataTotal = new Date(data);

          if (
            periodoFormatado.start.getTime() > dataTotal.getTime() ||
            periodoFormatado.end.getTime() < dataTotal.getTime()
          ) {
            throw `Esta planilha possui dados fora do intervalo de tempo solicitado (${moment(
              periodoFormatado.start,
            ).format('DD/MM/YYYY')})!`;
          }

          dadosFomatados.push({
            data: dataTotal,
            //dataAtual: new Date(),
            valor,
            modalidade: getMeioPagamento(modalidade),
            linha: i,
            //dataFormatada: moment(dataTotal).format('DD/MM/YYYY - HH:mm'),
          });
        }

        dadosFomatados = dadosFomatados.filter(
          (item) => item.modalidade !== 'pix' && item.modalidade !== '',
        );

        let transacoes = await getTransacoes();
        transacoes = formatTransacoes(transacoes);

        const { encontrados, naoEncontrados } = encontraCorrespondencias(
          transacoes,
          dadosFomatados,
        );

        await salvarEncontrados(encontrados);
        setNaoEncontrado(naoEncontrados);
        setCont(1);
      } else {
        enqueueSnackbar('É necessário carregar a planilha!', {
          variant: 'error',
        });
      }
    } catch (e) {
      enqueueSnackbar(e, {
        variant: 'error',
      });
    } finally {
      setCarregando(false);
    }
  }

  function onFileChange(event) {
    setFile(event.target.files.length > 0 ? event.target.files[0] : null);
  }

  function formataCorretamente(data) {
    const data2 = new Date();
    data2.setTime(data.getTime());
    return data2;
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth={naoEncontrado.length > 0 ? 'md' : 'sm'}
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{
            backgroundColor: '#009FD4',
            color: 'white',
          }}
        >
          Carregar Planilha de Conferências
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
            display: 'flex',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              marginTop: '20px',
            }}
          >
            <div>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel shrink id="demo-simple-select-label">
                  Tipo Planilha
                </InputLabel>
                <SelectInput
                  variant="standard"
                  labelId="demo-simple-select-label-2"
                  name="tipo"
                  handleEnter={() => {}}
                  label="Tipo Planilha"
                  handleKey={() => {}}
                  value={tipoPlanilha}
                  onChange={(value) => {
                    setTipoPlanilha(value.target.value);
                  }}
                  options={optionsTipoPlanilha}
                  disabled={carregando}
                />
              </FormControl>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '30px',
                padding: '10px',
                flex: 1,
              }}
            >
              <input type="file" onChange={onFileChange} />
              <Button
                variant="contained"
                color="primary"
                //onClick={() => getDados()}
                onClick={() => {
                  switch (tipoPlanilha) {
                    case 0:
                      getDados();
                      break;
                    case 1:
                      getDadosPagBank();
                      break;
                    default:
                      break;
                  }
                }}
                disabled={!file}
              >
                Analisar
              </Button>
            </div>
          </div>

          {naoEncontrado.length > 0 ? (
            <div
              style={{
                marginTop: '10px',
                flex: 1,
              }}
            >
              <h2
                style={{
                  textAlign: 'center',
                  marginBottom: '20px',
                }}
              >
                Não Encontrados
              </h2>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Linha na Planilha</StyledTableCell>
                      <StyledTableCell>Data</StyledTableCell>
                      <StyledTableCell>Valor</StyledTableCell>
                      <StyledTableCell>Modalidade</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {naoEncontrado.map((row) => (
                      <StyledTableRow key={row.linha}>
                        <StyledTableCell>{row.linha}</StyledTableCell>
                        <StyledTableCell>
                          {moment(formataCorretamente(row.data)).format(
                            'DD/MM/YYYY - HH:mm',
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          {formatMoeda(row.valor)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.modalidade}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ) : null}
        </DialogContent>
      </Dialog>
      <Backdrop className={classes.backdrop} open={carregando}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
});

export default DialogoCategoriaTransacao;
