import React, { useState, useRef } from 'react';
import { Title } from 'react-admin';
import { useHistory } from 'react-router-dom';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useSnackbar } from 'notistack';
import { stringify } from 'query-string';

import { api } from '../../../services';
import { formatMoeda } from '../../../utils';
import MobillsInput from './DateInputMobills';
import Dialogo from './Dialogo';

function formatPorcentagem(valor, casasDecimais = 2) {
  return valor !== null && valor !== undefined
    ? `${valor.toLocaleString('de-DE', {
        minimumFractionDigits: casasDecimais,
        maximumFractionDigits: casasDecimais,
      })}%`
    : '';
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableCell2 = withStyles((theme) => ({
  head: {
    backgroundColor: 'rgba(136, 14, 79, 0.5)',
    color: 'white',
  },
  body: {
    fontSize: 14,
    color: 'white',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableRowPorcentagem = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(136, 14, 79, 1)',
    color: 'white',
  },
}))(TableRow);

const StyledTableRowTotais = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(136, 14, 79, 1)',
    color: 'white',
  },
}))(TableRow);

function createData(
  dia,
  diaDaSemanaLabel,
  dinheiro,
  pix,
  credito,
  debito,
  vonchuer,
  descontoDevolucao,
  boleto,
  total,
  totalAcumulado,
) {
  return {
    dia,
    diaDaSemanaLabel,
    dinheiro,
    pix,
    credito,
    debito,
    vonchuer,
    descontoDevolucao,
    boleto,
    total,
    totalAcumulado,
  };
}

const rows = [
  createData(1, 'Domingo', 12.345, 12, 34, 45, 12, 12, 12, 23, 120, 123),
  createData(2, 'Domingo', 12.345, 12, 34, 45, 12, 12, 12, 23, 120, 123),
  createData(3, 'Domingo', 12.345, 12, 34, 45, 12, 12, 12, 23, 120, 123),
];

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const DashbboardContabilidade = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState({
    linhas: [],
    mes: new Date().getMonth(),
    ano: new Date().getFullYear(),
  });
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const refDialogo = useRef(null);

  function handleOpenDialogo() {
    if (refDialogo.current) {
      refDialogo.current.handleOpen();
    }
  }

  async function handleData({ ano, mes }) {
    setLoading(true);
    try {
      const dados = await api.get(`/relatorio/mensal/${ano}/${mes}`);
      setDados({ linhas: dados.data, mes, ano });
      setLoading(false);
    } catch (e) {
      setDados({ linhas: [], mes, ano });
      setLoading(false);
      enqueueSnackbar(`Erro ao carregar os dados do relatório!`, {
        variant: 'error',
      });
    }
  }

  function calcularEstatisticas() {
    const totais = {
      dinheiro: 0,
      pix: 0,
      credito: 0,
      debito: 0,
      vonchuer: 0,
      descontoDevolucao: 0,
      boleto: 0,
      total: 0,
      lucro: 0,
      despesas: 0,
      lucroLiquido: 0,
    };
    const porcentagens = {
      dinheiro: '0%',
      pix: '0%',
      credito: '0%',
      debito: '0%',
      vonchuer: 0,
      descontoDevolucao: 0,
      boleto: '0%',
      total: '0%',
      lucro: 0,
      despesas: 0,
      lucroLiquido: 0,
    };
    const medias = {
      dinheiro: 0,
      pix: 0,
      credito: 0,
      debito: 0,
      vonchuer: 0,
      descontoDevolucao: 0,
      boleto: 0,
      total: 0,
      lucro: 0,
      despesas: 0,
      lucroLiquido: 0,
    };

    function calculaTotalDias() {
      const mesAtual = new Date().getMonth();
      const anoAtual = new Date().getFullYear();
      if (dados.mes === mesAtual && dados.ano === anoAtual) {
        return new Date().getDate();
      }
      return dados.linhas.length;
    }

    const diasTotais = calculaTotalDias();

    for (let i = 0; i < dados.linhas.length; i += 1) {
      totais.dinheiro += dados.linhas[i].dinheiro;
      totais.pix += dados.linhas[i].pix;
      totais.credito += dados.linhas[i].credito;
      totais.debito += dados.linhas[i].debito;
      totais.vonchuer += dados.linhas[i].vonchuer;
      totais.descontoDevolucao += dados.linhas[i].descontoDevolucao;
      totais.boleto += dados.linhas[i].boleto;
      totais.total += dados.linhas[i].total;
      totais.lucro += dados.linhas[i].lucroMensal;
      totais.despesas += dados.linhas[i].totalDespesas;
      totais.lucroLiquido +=
        dados.linhas[i].lucroMensal - dados.linhas[i].totalDespesas;
    }

    if (dados.linhas.length > 0) {
      porcentagens.dinheiro = formatPorcentagem(
        (totais.dinheiro / totais.total) * 100,
      );
      porcentagens.pix = formatPorcentagem((totais.pix / totais.total) * 100);
      porcentagens.credito = formatPorcentagem(
        (totais.credito / totais.total) * 100,
      );
      porcentagens.debito = formatPorcentagem(
        (totais.debito / totais.total) * 100,
      );
      porcentagens.vonchuer = formatPorcentagem(
        (totais.vonchuer / totais.total) * 100,
      );
      porcentagens.descontoDevolucao = formatPorcentagem(
        (totais.descontoDevolucao / totais.total) * 100,
      );
      porcentagens.boleto = formatPorcentagem(
        (totais.boleto / totais.total) * 100,
      );
      porcentagens.total = '100%';
      porcentagens.lucro = '100%';
      porcentagens.despesas = '100%';
      porcentagens.lucroLiquido = '100%';

      medias.dinheiro = totais.dinheiro / diasTotais;
      medias.pix = totais.pix / diasTotais;
      medias.credito = totais.credito / diasTotais;
      medias.debito = totais.debito / diasTotais;
      medias.vonchuer = totais.vonchuer / diasTotais;
      medias.descontoDevolucao = totais.descontoDevolucao / diasTotais;
      medias.boleto = totais.boleto / diasTotais;
      medias.total = totais.total / diasTotais;
      medias.lucro = totais.lucro / diasTotais;
      medias.despesas = totais.despesas / diasTotais;
      medias.lucroLiquido = totais.lucroLiquido / diasTotais;
    }

    return {
      totais,
      porcentagens,
      medias,
    };
  }

  const { totais, porcentagens, medias } = calcularEstatisticas();

  function navegarRelatorioCartoes(dia) {
    const mes = dados.mes;
    const ano = dados.ano;

    const data = new Date();
    data.setFullYear(ano);
    data.setDate(dia);
    data.setMonth(mes);
    data.setDate(dia);

    history.push(
      `/relatorio_cartoes?${stringify({
        periodo: JSON.stringify({
          start: data.getTime(),
          end: data.getTime(),
        }),
      })}`,
    );
  }

  return (
    <div>
      <div
        style={{
          fontSize: '25px',
          fontWeight: 'bold',
          marginTop: '35px',
          marginBottom: '30px',
        }}
      >
        Relatório Mensal
      </div>
      <Title title="Relatório Mensal" />
      <div
        style={{
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div />
        <MobillsInput handleData={handleData} />
        <Tooltip
          title="Imprimir relatório"
          placement="top"
          aria-label="Imprimir"
        >
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={handleOpenDialogo}
          >
            <AssignmentIcon />
          </IconButton>
        </Tooltip>
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Dia</StyledTableCell>
              <StyledTableCell>Dia da semana</StyledTableCell>
              <StyledTableCell align="right">Dinheiro</StyledTableCell>
              <StyledTableCell align="right">Pix</StyledTableCell>
              <StyledTableCell align="right">Crédito</StyledTableCell>
              <StyledTableCell align="right">Débito</StyledTableCell>
              <StyledTableCell align="right">Voucher</StyledTableCell>
              <StyledTableCell align="right">Devolução</StyledTableCell>
              {/* <StyledTableCell align="right">Boleto</StyledTableCell> */}
              <StyledTableCell align="right">Total</StyledTableCell>
              <StyledTableCell align="right">Total Acum.</StyledTableCell>
              <StyledTableCell align="right">Lucro</StyledTableCell>
              <StyledTableCell align="right">Lucro Acum.</StyledTableCell>
              <StyledTableCell align="right">Despesas</StyledTableCell>
              <StyledTableCell align="right">Despesas Acum.</StyledTableCell>
              <StyledTableCell align="right">Lucro Líq.</StyledTableCell>
              <StyledTableCell align="right">Lucro Líq. Acum.</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dados.linhas.map((row) => (
              <StyledTableRow
                key={row.dia}
                onClick={() => navegarRelatorioCartoes(row.dia)}
              >
                <StyledTableCell>{row.dia}</StyledTableCell>
                <StyledTableCell>{row.diaDaSemanaLabel}</StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.dinheiro)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.pix)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.credito)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.debito)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.vonchuer)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.descontoDevolucao)}
                </StyledTableCell>
                {/* <StyledTableCell align="right">
                  {formatMoeda(row.boleto)}
                </StyledTableCell> */}
                <StyledTableCell align="right">
                  {formatMoeda(row.total)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.totalAcumulado)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.lucroMensal)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.totalLucroAcumulado)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.totalDespesas)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.totalDespesasAcumulado)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.lucroLiquidoMensal)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.totalLucroLiquidoAcumulado)}
                </StyledTableCell>
              </StyledTableRow>
            ))}
            <StyledTableRowTotais>
              <StyledTableCell2>Totais</StyledTableCell2>
              <StyledTableCell2 align="right" />
              <StyledTableCell2 align="right">
                {formatMoeda(totais.dinheiro)}
              </StyledTableCell2>
              <StyledTableCell2 align="right">
                {formatMoeda(totais.pix)}
              </StyledTableCell2>
              <StyledTableCell2 align="right">
                {formatMoeda(totais.credito)}
              </StyledTableCell2>
              <StyledTableCell2 align="right">
                {formatMoeda(totais.debito)}
              </StyledTableCell2>
              <StyledTableCell2 align="right">
                {formatMoeda(totais.vonchuer)}
              </StyledTableCell2>
              <StyledTableCell2 align="right">
                {formatMoeda(totais.descontoDevolucao)}
              </StyledTableCell2>
              {/* <StyledTableCell2 align="right">
                {formatMoeda(totais.boleto)}
              </StyledTableCell2> */}
              <StyledTableCell2 align="right">
                {formatMoeda(totais.total)}
              </StyledTableCell2>
              <StyledTableCell2 align="right">-</StyledTableCell2>
              <StyledTableCell2 align="right">
                {formatMoeda(totais.lucro)}
              </StyledTableCell2>
              <StyledTableCell2 align="right">-</StyledTableCell2>
              <StyledTableCell2 align="right">
                {formatMoeda(totais.despesas)}
              </StyledTableCell2>
              <StyledTableCell2 align="right">-</StyledTableCell2>
              <StyledTableCell2 align="right">
                {formatMoeda(totais.lucroLiquido)}
              </StyledTableCell2>
              <StyledTableCell2 align="right">-</StyledTableCell2>
            </StyledTableRowTotais>
            <StyledTableRowPorcentagem>
              <StyledTableCell2>%</StyledTableCell2>
              <StyledTableCell2 align="right" />
              <StyledTableCell2 align="right">
                {porcentagens.dinheiro}
              </StyledTableCell2>
              <StyledTableCell2 align="right">
                {porcentagens.pix}
              </StyledTableCell2>
              <StyledTableCell2 align="right">
                {porcentagens.credito}
              </StyledTableCell2>
              <StyledTableCell2 align="right">
                {porcentagens.debito}
              </StyledTableCell2>
              <StyledTableCell2 align="right">
                {porcentagens.vonchuer}
              </StyledTableCell2>
              <StyledTableCell2 align="right">
                {porcentagens.descontoDevolucao}
              </StyledTableCell2>
              {/* <StyledTableCell2 align="right">
                {porcentagens.boleto}
              </StyledTableCell2> */}
              <StyledTableCell2 align="right">
                {porcentagens.total}
              </StyledTableCell2>
              <StyledTableCell2 align="right">-</StyledTableCell2>
              <StyledTableCell2 align="right">
                {porcentagens.lucro}
              </StyledTableCell2>
              <StyledTableCell2 align="right">-</StyledTableCell2>
              <StyledTableCell2 align="right">
                {porcentagens.despesas}
              </StyledTableCell2>
              <StyledTableCell2 align="right">-</StyledTableCell2>
              <StyledTableCell2 align="right">
                {porcentagens.lucroLiquido}
              </StyledTableCell2>
              <StyledTableCell2 align="right">-</StyledTableCell2>
            </StyledTableRowPorcentagem>
            <StyledTableRowPorcentagem>
              <StyledTableCell2>Média</StyledTableCell2>
              <StyledTableCell2 align="right" />
              <StyledTableCell2 align="right">
                {formatMoeda(medias.dinheiro)}
              </StyledTableCell2>
              <StyledTableCell2 align="right">
                {formatMoeda(medias.pix)}
              </StyledTableCell2>
              <StyledTableCell2 align="right">
                {formatMoeda(medias.credito)}
              </StyledTableCell2>
              <StyledTableCell2 align="right">
                {formatMoeda(medias.debito)}
              </StyledTableCell2>
              <StyledTableCell2 align="right">
                {formatMoeda(medias.vonchuer)}
              </StyledTableCell2>
              <StyledTableCell2 align="right">
                {formatMoeda(medias.descontoDevolucao)}
              </StyledTableCell2>
              {/*  <StyledTableCell2 align="right">
                {formatMoeda(medias.boleto)}
              </StyledTableCell2> */}
              <StyledTableCell2 align="right">
                {formatMoeda(medias.total)}
              </StyledTableCell2>
              <StyledTableCell2 align="right">-</StyledTableCell2>
              <StyledTableCell2 align="right">
                {formatMoeda(medias.lucro)}
              </StyledTableCell2>
              <StyledTableCell2 align="right">-</StyledTableCell2>
              <StyledTableCell2 align="right">
                {formatMoeda(medias.despesas)}
              </StyledTableCell2>
              <StyledTableCell2 align="right">-</StyledTableCell2>
              <StyledTableCell2 align="right">
                {formatMoeda(medias.lucroLiquido)}
              </StyledTableCell2>
              <StyledTableCell2 align="right">-</StyledTableCell2>
            </StyledTableRowPorcentagem>
          </TableBody>
        </Table>
      </TableContainer>
      <Dialogo
        dados={{
          linhas: dados.linhas,
          totais,
          porcentagens,
          medias,
          mes: dados.mes,
          ano: dados.ano,
        }}
        ref={refDialogo}
      />
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default DashbboardContabilidade;
