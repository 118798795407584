import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { TimeInputBonito } from '../../Components';
import { getListAllAPI, getUserId, api } from '../../services';
import { writeCodigoBarras } from '../../utils/codigoBarras';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_ID = -1;

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState(DEFAULT_ID);

  const [carregando, setCarregando] = useState(false);

  const [horarioEntrada1, setHorarioEntrada1] = useState(null);
  const [horarioSaida1, setHorarioSaida1] = useState(null);
  const [horarioEntrada2, setHorarioEntrada2] = useState(null);
  const [horarioSaida2, setHorarioSaida2] = useState(null);

  const [colaborador, setColaborador] = useState('');
  const [data, setData] = useState('');

  const [pontos, setPontos] = useState([]);

  const classes = useStyles({ cor: '#009FD4' });

  function getTime(data) {
    if (data) {
      const h = data.getHours();
      const m = data.getMinutes();
      const t = h * 60 + m;
      return t;
    }
    return -10;
  }

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'pontos_colaborador',
        ['id', 'asc'],
        { id: [id] },
        ['colaborador', 'dia', 'pontosUnitarios'],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);

        setHorarioEntrada1(
          data.data[0].horario_entrada_1
            ? new Date(data.data[0].horario_entrada_1)
            : null,
        );

        setHorarioSaida1(
          data.data[0].horario_saida_1
            ? new Date(data.data[0].horario_saida_1)
            : null,
        );

        setHorarioEntrada2(
          data.data[0].horario_entrada_2
            ? new Date(data.data[0].horario_entrada_2)
            : null,
        );

        setHorarioSaida2(
          data.data[0].horario_saida_2
            ? new Date(data.data[0].horario_saida_2)
            : null,
        );

        setColaborador(data.data[0].colaborador.nome);

        setData(moment(new Date(data.data[0].dia.data)).format('DD/MM/YYYY'));

        setPontos(data.data[0].pontosUnitarios);

        setCarregando(false);
      } else {
        throw 'Esta Ponto não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    try {
      await api.put(`/pontos_colaborador/update_horarios/${id}`, {
        horario_entrada_1: horarioEntrada1,
        horario_saida_1: horarioSaida1,
        horario_entrada_2: horarioEntrada2,
        horario_saida_2: horarioSaida2,
        atualizador_id: getUserId(),
      });
      setCarregando(false);
      enqueueSnackbar('Este Ponto foi atualizado com sucesso!', {
        variant: 'success',
      });

      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
    },
  }));

  function validateHorarioEntrada1() {
    const error = '';
    //if (!horarioEntrada1) error = 'Este campo é obrigatório';
    return error;
  }

  function validateHorarioSaida1() {
    let error = '';
    //if (!horarioSaida1) error = 'Este campo é obrigatório';
    const timeEntrada1 = getTime(horarioEntrada1);
    const timeSaida1 = getTime(horarioSaida1);
    if (timeEntrada1 >= 0) {
      if (timeSaida1 >= 0) {
        if (timeEntrada1 > timeSaida1) {
          error = 'O horário de saída deve ser maior que o de entrada!';
        }
      } else {
        //nenhum problema
      }
    } else if (timeSaida1 >= 0) {
      error = 'é necessário preencher o horário de entrada!';
    } else {
      //nenhum problema
    }
    return error;
  }

  function validateHorarioEntrada2() {
    let error = '';
    //if (!horarioSaida1) error = 'Este campo é obrigatório';
    const timeSaida1 = getTime(horarioSaida1);
    const timeEntrada2 = getTime(horarioEntrada2);
    if (timeSaida1 >= 0) {
      if (timeEntrada2 >= 0) {
        if (timeSaida1 > timeEntrada2) {
          error =
            'O horário de entrada do expediente 2 deve ser maior que o horário de saída no expediente 1!';
        }
      } else {
        //nenhum problema
      }
    } else if (timeEntrada2 >= 0) {
      error = 'É necessário preencher o horário de saída do expediente 1!';
    } else {
      //nenhum problema
    }
    return error;
  }

  function validateHorarioSaida2() {
    let error = '';
    //if (!horarioSaida1) error = 'Este campo é obrigatório';
    const timeEntrada2 = getTime(horarioEntrada2);
    const timeSaida2 = getTime(horarioSaida2);
    if (timeEntrada2 >= 0) {
      if (timeSaida2 >= 0) {
        if (timeEntrada2 > timeSaida2) {
          error =
            'O horário de saída do expediente 2 deve ser maior que o horário de entrada no expediente 2!';
        }
      } else {
        //nenhum problema
      }
    } else if (timeSaida2 >= 0) {
      error = 'É necessário preencher o horário de entrada do expediente 2!';
    } else {
      //nenhum problema
    }
    return error;
  }

  function getErros() {
    const errosOld = ['', ''];
    errosOld[0] = validateHorarioEntrada1();
    errosOld[1] = validateHorarioSaida1();
    errosOld[2] = validateHorarioEntrada2();
    errosOld[3] = validateHorarioSaida2();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  function formatData2(data) {
    if (data) {
      //return moment(data).format('DD/MM/YYYY - HH:mm');
      return moment(data).format('HH:mm');
    }
    return '-';
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
        keepMounted
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          {`Expedientes ${colaborador} - ${data}`}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 10px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Box p="1em">
              <Box>
                <Box>
                  <Typography
                    variant="h5"
                    style={{
                      marginBottom: '20px',
                    }}
                  >
                    1º Expediente
                  </Typography>
                  <Box display="flex">
                    <Box flex={1} mr="0.5em">
                      <TimeInputBonito
                        name="horario_entrada_1"
                        handleEnter={() => {}}
                        label="Entrada"
                        handleKey={() => {}}
                        value={horarioEntrada1}
                        onChange={(value) => setHorarioEntrada1(value)}
                        format="HH:mm"
                        fullWidth
                        inputVariant="outlined"
                        ampm={false}
                        views={['hours', 'minutes']}
                        error={erros[0] !== ''}
                        helperText={erros[0]}
                        clearable
                        clearLabel="Limpar"
                      />
                    </Box>
                    <Box flex={1} mr="0.5em">
                      <TimeInputBonito
                        name="horario_saida_1"
                        handleEnter={() => {}}
                        label="Saída"
                        handleKey={() => {}}
                        value={horarioSaida1}
                        onChange={(value) => setHorarioSaida1(value)}
                        format="HH:mm"
                        fullWidth
                        inputVariant="outlined"
                        ampm={false}
                        views={['hours', 'minutes']}
                        error={erros[1] !== ''}
                        helperText={erros[1]}
                        clearable
                        clearLabel="Limpar"
                      />
                    </Box>
                  </Box>
                </Box>
                <Box style={{ marginTop: '15px' }}>
                  <Typography
                    variant="h5"
                    style={{
                      marginBottom: '20px',
                    }}
                  >
                    2º Expediente
                  </Typography>
                  <Box display="flex">
                    <Box flex={1} mr="0.5em">
                      <TimeInputBonito
                        name="horario_entrada_2"
                        handleEnter={() => {}}
                        label="Entrada"
                        handleKey={() => {}}
                        value={horarioEntrada2}
                        onChange={(value) => setHorarioEntrada2(value)}
                        format="HH:mm"
                        fullWidth
                        inputVariant="outlined"
                        ampm={false}
                        views={['hours', 'minutes']}
                        error={erros[2] !== ''}
                        helperText={erros[2]}
                        clearable
                        clearLabel="Limpar"
                      />
                    </Box>
                    <Box flex={1} mr="0.5em">
                      <TimeInputBonito
                        name="horario_saida_2"
                        handleEnter={() => {}}
                        label="Saída"
                        handleKey={() => {}}
                        value={horarioSaida2}
                        onChange={(value) => setHorarioSaida2(value)}
                        format="HH:mm"
                        fullWidth
                        inputVariant="outlined"
                        ampm={false}
                        views={['hours', 'minutes']}
                        error={erros[3] !== ''}
                        helperText={erros[3]}
                        clearable
                        clearLabel="Limpar"
                      />
                    </Box>
                  </Box>
                </Box>

                <Box style={{ marginTop: '15px' }}>
                  <Typography
                    variant="h5"
                    style={{
                      marginBottom: '20px',
                    }}
                  >
                    Batidas de Ponto
                  </Typography>

                  <Box>
                    <TableContainer component={Paper} className={classes.root}>
                      <Table aria-label="collapsible table">
                        <TableHead
                          style={{
                            color: 'white',
                            fontWeight: 'bold',
                            backgroundColor: '#009FD4',
                          }}
                        >
                          <TableRow className={classes.rowHead}>
                            <TableCell className={classes.rowHead}>
                              Código
                            </TableCell>
                            <TableCell className={classes.rowHead}>
                              Horário
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {pontos.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell component="th" scope="row">
                                {writeCodigoBarras(row.id)}
                              </TableCell>
                              <TableCell>{formatData2(row.data)}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          <div className={classes.wrapper}>
            <Button
              onClick={handleSalvar}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              disabled={erroExistente || carregando}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
            {carregando && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
