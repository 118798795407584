import React from 'react';
import {
  Filter as FilterAdmin,
  TextInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <TextInput label="Descrição" source="nome" />
  </FilterAdmin>
);

export default Filter;
