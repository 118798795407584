import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { PDFViewer } from '@react-pdf/renderer';
import moment from 'moment';

import { getListAllAPI, api } from '../../../services';
import Document from './Document';

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex" style={{}}>
      <CircularProgress variant="determinate" {...props} color="secondary" />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const TIPO_RECEITA = 'Receita';
const TIPO_DESPESA = 'Despesa';

const RelatorioFc = ({
  sessao_id,
  showSaldoCorrigido = false,
  showDescontos = false,
  showAcrescimos = false,
}) => {
  const [dados, setDados] = useState(null);
  const [progress, setProgress] = useState(0);

  async function getEntradas(sessao_id) {
    const dadosEntradas = await getListAllAPI(
      'entradascaixa',
      ['horario', 'desc'],
      { sessao_id },
      [],
    );
    const entradas = [];
    for (let i = 0; i < dadosEntradas.data.length; i += 1) {
      entradas.push({
        nome: dadosEntradas.data[i].nome,
        valor: dadosEntradas.data[i].valor,
        horario: moment(dadosEntradas.data[i].horario).format('HH:mm'),
      });
    }
    return entradas;
  }

  async function getSaidas(sessao_id) {
    const dadosSaidas = await getListAllAPI(
      'saidascaixa',
      ['horario', 'desc'],
      { sessao_id },
      [],
    );
    const saidas = [];
    for (let i = 0; i < dadosSaidas.data.length; i += 1) {
      saidas.push({
        nome: dadosSaidas.data[i].nome,
        valor: dadosSaidas.data[i].valor,
        horario: moment(dadosSaidas.data[i].horario).format('HH:mm'),
      });
    }
    return saidas;
  }

  async function getTransferencias(sessao_id) {
    const dadosTransferencias = await getListAllAPI(
      'retiradascaixa',
      ['horario', 'desc'],
      { sessao_id },
      [],
    );
    const transferencias = [];
    for (let i = 0; i < dadosTransferencias.data.length; i += 1) {
      transferencias.push({
        valor: dadosTransferencias.data[i].valor,
        horario: moment(dadosTransferencias.data[i].horario).format('HH:mm'),
      });
    }
    return transferencias;
  }

  function formatarParcelas(parcelas) {
    const parcelasFomat = [
      {
        valor: 0,
        tipoPagamento: 'Dinheiro',
        data: '',
      },
      {
        valor: 0,
        tipoPagamento: 'Cartão de Crédito',
        data: '',
      },
      {
        valor: 0,
        tipoPagamento: 'Cartão de Débito',
        data: '',
      },
      {
        valor: 0,
        tipoPagamento: 'TED/PIX',
        data: '',
      },
      {
        valor: 0,
        tipoPagamento: 'Outros',
        data: '',
      },
      {
        valor: 0,
        tipoPagamento: 'Vonchuer',
        data: '',
      },
      {
        valor: 0,
        tipoPagamento: 'Desc. Devo.',
        data: '',
      },
    ];
    for (let i = 0; i < parcelas.length; i += 1) {
      const parcela = parcelas[i];
      switch (parcela.tipoPagamento.id) {
        case 1:
          parcelasFomat[0].valor = parcela.valor_real;
          break;
        case 3:
          parcelasFomat[1].valor += parcela.valor;
          break;
        case 4:
          parcelasFomat[2].valor += parcela.valor;
          break;
        case 5:
          parcelasFomat[3].valor += parcela.valor;
          break;
        case 6:
          parcelasFomat[4].valor += parcela.valor;
          parcelasFomat[4].data = moment(parcela.data_pagamento).format(
            'DD/MM/YYYY',
          );
          break;
        case 7:
          parcelasFomat[3].valor += parcela.valor;
          break;
        case 8:
          parcelasFomat[5].valor += parcela.valor;
          break;
        case 9:
          parcelasFomat[6].valor += parcela.valor;
          break;
        case 10:
          parcelasFomat[3].valor += parcela.valor;
          break;
        default:
          break;
      }
      /* switch (parcela.tipoPagamento.nome) {
        case 'Dinheiro':
          parcelasFomat[0].valor = parcela.valor_real;
          break;
        case 'Cartão de Crédito':
          parcelasFomat[1].valor += parcela.valor;
          break;
        case 'Cartão de Débito':
          parcelasFomat[2].valor += parcela.valor;
          break;
        case 'TED/PIX':
          parcelasFomat[3].valor += parcela.valor;
          break;
        default:
          parcelasFomat[4].valor = parcela.valor;
          parcelasFomat[4].data = moment(parcela.data_pagamento).format(
            'DD/MM/YYYY',
          );
          break;
      } */
    }

    return parcelasFomat;
  }

  async function getVendas(sessao_id) {
    const dadosVendas = await getListAllAPI(
      'vendas',
      ['numero', 'asc'],
      { sessao_id },
      ['cliente', 'parcelas.tipoPagamento'],
    );
    const vendas = [];
    const devolucoes = [];
    const vendasCanceladas = [];
    const devolucoesCanceladas = [];
    let descontos = 0;
    let acrescimos = 0;
    for (let i = 0; i < dadosVendas.data.length; i += 1) {
      if (dadosVendas.data[i].devolucao === false) {
        const parcelasTotalizadas = formatarParcelas(
          dadosVendas.data[i].parcelas,
        );
        if (dadosVendas.data[i].cancelado === false) {
          vendas.push({
            numero: `${dadosVendas.data[i].numero}`,
            cliente: dadosVendas.data[i].cliente.nome,
            nomeFantasia: dadosVendas.data[i].cliente.nome_fantasia,
            valor: parcelasTotalizadas[0].valor,
            horario: moment(dadosVendas.data[i].created_at).format('HH:mm'),
            valorTotal: dadosVendas.data[i].valor,
            valorCartaoCredito: parcelasTotalizadas[1].valor,
            valorCartaoDebito: parcelasTotalizadas[2].valor,
            valorTedPix: parcelasTotalizadas[3].valor,
            valorOutros: parcelasTotalizadas[4].valor,
            valorVonchuer: parcelasTotalizadas[5].valor,
            valorDescontoDevolucao: parcelasTotalizadas[6].valor,
            dataOutros:
              parcelasTotalizadas[4].valor > 0
                ? parcelasTotalizadas[4].data
                : '',
          });
        } else {
          vendasCanceladas.push({
            numero: `${dadosVendas.data[i].numero}`,
            cliente: dadosVendas.data[i].cliente.nome,
            nomeFantasia: dadosVendas.data[i].cliente.nome_fantasia,
            valor: parcelasTotalizadas[0].valor,
            horario: moment(dadosVendas.data[i].created_at).format('HH:mm'),
            valorTotal: dadosVendas.data[i].valor,
            valorCartaoCredito: parcelasTotalizadas[1].valor,
            valorCartaoDebito: parcelasTotalizadas[2].valor,
            valorTedPix: parcelasTotalizadas[3].valor,
            valorOutros: parcelasTotalizadas[4].valor,
            valorVonchuer: parcelasTotalizadas[5].valor,
            valorDescontoDevolucao: parcelasTotalizadas[6].valor,
            dataOutros:
              parcelasTotalizadas[4].valor > 0
                ? parcelasTotalizadas[4].data
                : '',
          });
        }
      } else if (dadosVendas.data[i].cancelado === false) {
        devolucoes.push({
          numero: `${dadosVendas.data[i].numero}`,
          cliente: dadosVendas.data[i].cliente.nome,
          nomeFantasia: dadosVendas.data[i].cliente.nome_fantasia,
          valor: dadosVendas.data[i].valorEmDinheiro,
          horario: moment(dadosVendas.data[i].created_at).format('HH:mm'),
          valorTotal: dadosVendas.data[i].valor,
        });
      } else {
        devolucoesCanceladas.push({
          numero: `${dadosVendas.data[i].numero}`,
          cliente: dadosVendas.data[i].cliente.nome,
          nomeFantasia: dadosVendas.data[i].cliente.nome_fantasia,
          valor: dadosVendas.data[i].valorEmDinheiro,
          horario: moment(dadosVendas.data[i].created_at).format('HH:mm'),
          valorTotal: dadosVendas.data[i].valor,
        });
      }
      for (let j = 0; j < dadosVendas.data[i].parcelas.length; j += 1) {
        if (dadosVendas.data[i].parcelas[j].tipoPagamento.nome === 'Dinheiro') {
          const diferencaValores =
            dadosVendas.data[i].parcelas[j].valor_real -
            dadosVendas.data[i].parcelas[j].valor;
          if (diferencaValores < 0) {
            descontos += -1 * diferencaValores;
          } else {
            acrescimos += diferencaValores;
          }
        }
      }
    }

    return {
      vendas,
      devolucoes,
      vendasCanceladas,
      devolucoesCanceladas,
      descontos,
      acrescimos,
    };
  }

  async function getNomeCaixa(caixa_id) {
    const data = await api.get(`/contas/${caixa_id}`);
    return data.data.nome;
  }

  async function getSession(sessao_id) {
    const dadosVendas = await getListAllAPI(
      'sessoes',
      ['id', 'asc'],
      { id: sessao_id },
      ['user'],
    );
    return {
      abertura: moment(dadosVendas.data[0].abertura).format('HH:mm'),
      fechamento: dadosVendas.data[0].fechamento
        ? moment(dadosVendas.data[0].fechamento).format('HH:mm')
        : '-',
      troco_inicial: dadosVendas.data[0].troco_inicial,
      username: dadosVendas.data[0].user.username,
      caixa_id: dadosVendas.data[0].caixa_id,
      saldo: dadosVendas.data[0].saldo,
      saldo_corrigido: dadosVendas.data[0].saldo_corrigido,
      data: moment(dadosVendas.data[0].abertura).format('DD/MM/YYYY'),
    };
  }

  async function getTransacoes(sessao_id) {
    const dados = await api.get(`/todos_dados_sessao/${sessao_id}`);

    // return dados.data;
    const {
      entradas,
      saidas,
      saldoAbertura,
      saldoFechamento,
      transferenciasEntrada,
      transferenciasSaida,
      ajuste,
      numeroTotalItensDiferentes,
      numeroTotalItens,
      numeroVendasNaoFinalizadas,
      numeroItensVendaRemovidos,
    } = dados.data;
    const transferenciasEntradaFormatadas = [];
    for (let i = 0; i < transferenciasEntrada.length; i += 1) {
      transferenciasEntradaFormatadas.push({
        valor: transferenciasEntrada[i].valor,
        horario: moment(transferenciasEntrada[i].data_pagamento).format(
          'HH:mm',
        ),
        conta: transferenciasEntrada[i].conta.nome,
      });
    }

    const transferenciasSaidaFormatadas = [];
    for (let i = 0; i < transferenciasSaida.length; i += 1) {
      transferenciasSaidaFormatadas.push({
        valor: transferenciasSaida[i].valor,
        horario: moment(transferenciasSaida[i].data_pagamento).format('HH:mm'),
        conta: transferenciasSaida[i].conta.nome,
      });
    }

    const entradasFormatadas = [];
    for (let i = 0; i < entradas.length; i += 1) {
      entradasFormatadas.push({
        nome: entradas[i].nome,
        valor: entradas[i].valor,
        horario: moment(entradas[i].data_pagamento).format('HH:mm'),
      });
    }

    const saidasFormatadas = [];
    for (let i = 0; i < saidas.length; i += 1) {
      saidasFormatadas.push({
        nome: saidas[i].nome,
        valor: saidas[i].valor,
        horario: moment(saidas[i].data_pagamento).format('HH:mm'),
      });
    }

    const saldoVirtual = saldoFechamento;

    // if (ajuste.length > 0) {
    //   const ajusteFinal = ajuste[0];
    //   if (ajusteFinal.tipo === TIPO_RECEITA) {
    //     saldoVirtual -= ajusteFinal.valor;
    //   } else {
    //     saldoVirtual += ajusteFinal.valor;
    //   }
    // } else {
    //   saldoVirtual = saldoFechamento;
    // }

    return {
      entradas: entradasFormatadas,
      saidas: saidasFormatadas,
      saldoAbertura,
      saldoCorrigidoFechamento: 0,
      saldoVirtual,
      transferenciasEntrada: transferenciasEntradaFormatadas,
      transferenciasSaida: transferenciasSaidaFormatadas,
      numeroTotalItensDiferentes,
      numeroTotalItens,
      numeroVendasNaoFinalizadas,
      numeroItensVendaRemovidos,
    };
  }

  async function getData() {
    /* const entradas = await getEntradas(sessao_id);
    setProgress(10);
    const saidas = await getSaidas(sessao_id);
    setProgress(20);
    const transferencias = await getTransferencias(sessao_id);
    setProgress(50); */
    const {
      vendas,
      devolucoes,
      descontos,
      acrescimos,
      vendasCanceladas,
      devolucoesCanceladas,
    } = await getVendas(sessao_id);

    setProgress(80);
    const session = await getSession(sessao_id);
    const atendente = session.username;
    const caixa = await getNomeCaixa(session.caixa_id);
    const {
      entradas,
      saidas,
      saldoAbertura,
      saldoCorrigidoFechamento,
      saldoVirtual,
      transferenciasEntrada,
      transferenciasSaida,
      numeroTotalItensDiferentes,
      numeroTotalItens,
      numeroVendasNaoFinalizadas,
      numeroItensVendaRemovidos,
    } = await getTransacoes(sessao_id);
    getTransacoes(sessao_id);
    setProgress(90);

    const totalVendas = vendas.reduce(
      (accumulator, currentValue) => accumulator + currentValue.valorTotal,
      0,
    );

    const ticketMedio = vendas.length > 0 ? totalVendas / vendas.length : 0;

    const numeroVendas = vendas.length > 0 ? vendas.length : 0;

    const numeroVendasCanceladas = 0;

    const numeroMedioItensPorVenda =
      vendas.length > 0 ? numeroTotalItens / vendas.length : 0;

    const valorMedioDosItens =
      numeroTotalItensDiferentes > 0
        ? totalVendas / numeroTotalItensDiferentes
        : 0;

    setDados({
      entradas,
      saidas,
      // transferencias,
      // transferencias: transferenciasSaida,
      transferenciasEntrada,
      transferenciasSaida,
      vendas,
      devolucoes,
      vendasCanceladas,
      devolucoesCanceladas,
      atendente,
      caixa,
      abertura: session.abertura,
      fechamento: session.fechamento,
      // trocoInicial: session.troco_inicial,
      // saldo: session.saldo,
      // saldoCorrigido: session.saldo_corrigido,
      trocoInicial: saldoAbertura,
      saldo: saldoVirtual,
      saldoCorrigido: saldoCorrigidoFechamento,
      showSaldoCorrigido,
      descontos,
      acrescimos,
      showDescontos,
      showAcrescimos,
      data: session.data,
      numeroMedioItensPorVenda,
      numeroVendas,
      numeroVendasCanceladas,
      ticketMedio,
      valorMedioDosItens,
      numeroTotalItensDiferentes,
      numeroVendasNaoFinalizadas,
      numeroItensVendaRemovidos,
    });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {dados !== null ? (
        <PDFViewer
          style={{ margin: 0, padding: 0, border: 0 }}
          width="100%"
          height="100%"
        >
          <Document {...dados} />
        </PDFViewer>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: '0.75',
          }}
        >
          <CircularProgressWithLabel value={progress} />
        </div>
      )}
    </>
  );
};

export default RelatorioFc;
