import React, {
  useImperativeHandle,
  forwardRef,
  useState,
} from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const DialogoConfirmacao = forwardRef(({ handleConfirma }, ref) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [codigo, setCodigo] = useState(0);

  useImperativeHandle(ref, () => ({
    handleOpen(titleNew, contentNew, codigoNew) {
      setTitle(titleNew);
      setContent(contentNew);
      setOpen(true);
      setCodigo(codigoNew);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleSalvar = () => {
    setOpen(false);
    handleConfirma(codigo);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleSalvar} color="secondary" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoConfirmacao;
