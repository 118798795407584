import React from 'react';
import {
  Filter as FilterAdmin,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  TextInput,
} from 'react-admin';

import {
  PeriodoInput,
  PrecoInput,
  IntegerInput,
} from '../../Components/InputsReactAdmin';
import { STATUS_ATIVO } from '../../utils/constants';

const Filter = (props) => (
  <FilterAdmin {...props}>
    {/*<PeriodoInput
      source="periodo"
      label="Período"
      defaultValue={{
        start: new Date(),
        end: new Date(),
      }}
    />*/}
    <TextInput source="codigo" label="Código" />
    <PeriodoInput
      source="data_criacao"
      label="Criação"
      defaultValue={{
        start: new Date(),
        end: new Date(),
      }}
    />
    <PeriodoInput
      source="data_uso"
      label="Uso no Checkout"
      defaultValue={{
        start: new Date(),
        end: new Date(),
      }}
    />
    <PrecoInput source="search" label="Valor" fixedDecimalScale={false} />
    <ReferenceInput
      source="conta_id"
      reference="contas_normal"
      label="Caixa (Entrada no Cofre)"
      allowEmpty={false}
      filter={{ is_caixa: true, status: STATUS_ATIVO }}
    >
      <SelectInput optionText="nome" allowEmpty={false} />
    </ReferenceInput>
    <ReferenceInput
      source="conta2_id"
      reference="contas_normal"
      label="Caixa (Saída do Cofre)"
      allowEmpty={false}
      filter={{ is_caixa: true, status: STATUS_ATIVO }}
    >
      <SelectInput optionText="nome" allowEmpty={false} />
    </ReferenceInput>
    <ReferenceInput
      fullWidth
      label="Frentista (Entrada no Cofre)"
      source="frentista_id"
      reference="users"
      sort={{ field: 'username', order: 'ASC' }}
      filterToQuery={(searchText) => ({ username: `${searchText}` })}
      perPage={10}
      allowEmpty={false}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <ReferenceInput
      fullWidth
      label="Frentista (Saida do Cofre)"
      source="frentista2_id"
      reference="users"
      sort={{ field: 'username', order: 'ASC' }}
      filterToQuery={(searchText) => ({ username: `${searchText}` })}
      perPage={10}
      allowEmpty={false}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <ReferenceInput
      fullWidth
      label="Responsável (Entrada no Cofre)"
      source="responsavel_id"
      reference="users"
      sort={{ field: 'username', order: 'ASC' }}
      filterToQuery={(searchText) => ({ username: `${searchText}` })}
      perPage={10}
      allowEmpty={false}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <ReferenceInput
      fullWidth
      label="Transportador (Entrada no Cofre)"
      source="transportador1_id"
      reference="users"
      sort={{ field: 'username', order: 'ASC' }}
      filterToQuery={(searchText) => ({ username: `${searchText}` })}
      perPage={10}
      allowEmpty={false}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <ReferenceInput
      fullWidth
      label="Transportador (Saída do Cofre)"
      source="transportador2_id"
      reference="users"
      sort={{ field: 'username', order: 'ASC' }}
      filterToQuery={(searchText) => ({ username: `${searchText}` })}
      perPage={10}
      allowEmpty={false}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <IntegerInput
      source="sessao_id"
      label="Id Sessão (Entrada no Cofre)"
      prefixo="#"
    />
    <IntegerInput
      source="sessao2_id"
      label="Id Sessão (Saída Cofre)"
      prefixo="#"
    />
  </FilterAdmin>
);

export default Filter;
