import React, {
  useState,
  useRef,
  useContext,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';

import {
  Box,
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import { AutoCompleteRemoto } from '../../../Components/Inputs';
import { getListAllAPI } from '../../../services';
import {
  formatCPF,
  formatCNPJ,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  ATALHO_AVANCAR,
  ATALHO_VOLTAR,
  STATUS_ATIVO,
} from '../../../utils';

const useStyles = makeStyles((theme) => ({
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  textField: {},
  btnImprimir: {
    backgroundColor: theme.palette.secondary.main,
    opacity: 0.4,
  },
  header: {
    height: '17%',
  },
  body: {
    height: '83%',
  },
  footer: {
    height: '15vh',
    padding: '10px',
    paddingBottom: '20px',
  },
  textOverflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const colorHeader = SECONDARY_COLOR;
const NOVO_CLIENTE_ACTION = 'F9';
const EDITAR_CLIENTE_ACTION = 'F7';

const DialogoConfirmacao = forwardRef(
  ({ handleSelecionado = () => {}, ids_excluso = [] }, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [fornecedor, setFornecedor] = useState(null);

    const handleSalvar = () => {
      if (fornecedor) {
        handleSelecionado(fornecedor);
        setFornecedor(null);
      } else {
        enqueueSnackbar(`${'É necessário especificar o fornecedor!'}`, {
          variant: 'error',
        });
      }
    };

    return (
      <Box padding="10px" className={classes.header}>
        <Box
          display="flex"
          css={{
            backgroundColor: 'white',
            opacity: '0.75',
            height: '100%',
            padding: '0px 10px',
            flexDirection: 'column',
          }}
        >
          <Box display="flex" alignItems="center" flex={1}>
            <AutoCompleteRemoto
              id="cliente"
              name="cliente"
              label="Responsável"
              value={fornecedor}
              onChange={(value) => setFornecedor(value)}
              handleKey={(keyCode, keyName) => {}}
              handleEnter={handleSalvar}
              resource="fornecedores"
              nested={['cidade.estado']}
              toString={false}
              fullwidth
              autoFocus
              filters={{ status: STATUS_ATIVO, ids_excluso }}
            />
            <AutoCompleteRemoto
              id="cliente"
              name="cliente"
              label="Nome Fantasia"
              value={fornecedor}
              onChange={(value) => setFornecedor(value)}
              handleKey={(keyCode, keyName) => {}}
              handleEnter={handleSalvar}
              resource="fornecedores"
              campo="nome_fantasia"
              nested={['cidade.estado']}
              toString={false}
              style={{ marginLeft: '10px' }}
              fullwidth
              filters={{ status: STATUS_ATIVO, ids_excluso }}
            />
          </Box>
          <Box display="flex" alignItems="center" flex={1}>
            {/*<AutoCompleteRemoto
              id="cliente"
              name="cliente"
              label="CPF"
              value={fornecedor}
              onChange={(value) => setFornecedor(value)}
              handleKey={(keyCode, keyName) => {}}
              handleEnter={handleSalvar}
              resource="fornecedores"
              campo="cpf"
              campoPesquisa="cpf_search"
              nested={['cidade.estado']}
              toString={false}
              fullwidth
              filters={{ status: STATUS_ATIVO }}
        />*/}
            {/*<AutoCompleteRemoto
              id="cliente"
              name="cliente"
              label="CNPJ"
              value={fornecedor}
              onChange={(value) => setFornecedor(value)}
              handleKey={(keyCode, keyName) => {}}
              handleEnter={handleSalvar}
              resource="fornecedores"
              campo="cnpj"
              campoPesquisa="cnpj_search"
              nested={['cidade.estado']}
              toString={false}
              style={{ marginLeft: '10px' }}
              fullwidth
              filters={{ status: STATUS_ATIVO }}
        />*/}
          </Box>
          {fornecedor && (
            <Box
              display="flex"
              justifyContent="space-between"
              flex={1}
              style={{
                border: `1px solid ${PRIMARY_COLOR}`,
                marginLeft: '0px',
                padding: '5px',
                borderRadius: '5px',
                marginBottom: '10px',
              }}
            >
              <Box
                marginLeft="10px"
                display="flex"
                flexDirection="column"
                flex={1}
              >
                <Box
                  marginLeft="0px"
                  fontSize="15px"
                  fontWeight="bold"
                  display="flex"
                  flex={1}
                >
                  <p
                    className={classes.textOverflow}
                    style={{
                      marginBottom: '0px',
                      color: colorHeader,
                      marginRight: '10px',
                    }}
                  >
                    Responsável:
                  </p>
                  {fornecedor.nome}
                </Box>
                <Box
                  marginLeft="0px"
                  fontSize="15px"
                  fontWeight="bold"
                  display="flex"
                  flex={1}
                >
                  <p
                    className={classes.textOverflow}
                    style={{
                      marginBottom: '0px',
                      color: colorHeader,
                      marginRight: '10px',
                    }}
                  >
                    Razão Social:
                  </p>
                  {fornecedor.razao_social}
                </Box>
                <Box
                  marginLeft="0px"
                  fontSize="15px"
                  fontWeight="bold"
                  display="flex"
                  flex={1}
                >
                  <p
                    className={classes.textOverflow}
                    style={{
                      marginBottom: '0px',
                      color: colorHeader,
                      marginRight: '10px',
                    }}
                  >
                    CPF:
                  </p>
                  {formatCPF(fornecedor.cpf)}
                </Box>
                <Box
                  marginLeft="0px"
                  fontSize="15px"
                  fontWeight="bold"
                  display="flex"
                  flex={1}
                >
                  <p
                    className={classes.textOverflow}
                    style={{
                      marginBottom: '0px',
                      color: colorHeader,
                      marginRight: '10px',
                    }}
                  >
                    CNPJ:
                  </p>
                  {formatCNPJ(fornecedor.cnpj)}
                </Box>
              </Box>
              <Box
                marginLeft="10px"
                display="flex"
                flexDirection="column"
                flex={1}
              >
                <Box
                  marginLeft="0px"
                  fontSize="15px"
                  fontWeight="bold"
                  display="flex"
                  flex={1}
                  justifyContent="flex-start"
                >
                  <p
                    className={classes.textOverflow}
                    style={{
                      marginBottom: '0px',
                      color: colorHeader,
                      marginRight: '10px',
                    }}
                  >
                    Nome Fantasia:
                  </p>
                  {fornecedor.nome_fantasia}
                </Box>
                <Box
                  marginLeft="0px"
                  fontSize="15px"
                  fontWeight="bold"
                  display="flex"
                  flex={1}
                  justifyContent="flex-start"
                >
                  <p
                    className={classes.textOverflow}
                    style={{
                      marginBottom: '0px',
                      color: colorHeader,
                      marginRight: '10px',
                    }}
                  >
                    Bairro:
                  </p>
                  {fornecedor.bairro}
                </Box>
                <Box
                  marginLeft="0px"
                  fontSize="15px"
                  fontWeight="bold"
                  display="flex"
                  flex={1}
                  justifyContent="flex-start"
                >
                  <p
                    className={classes.textOverflow}
                    style={{
                      marginBottom: '0px',
                      color: colorHeader,
                      marginRight: '10px',
                    }}
                  >
                    Cidade:
                  </p>
                  {fornecedor.cidade
                    ? `${fornecedor.cidade.nome} - ${fornecedor.cidade.estado.sigla}`
                    : ''}
                </Box>
                <Box
                  marginLeft="0px"
                  fontSize="15px"
                  fontWeight="bold"
                  display="flex"
                  flex={1}
                  justifyContent="flex-start"
                >
                  <p
                    className={classes.textOverflow}
                    style={{
                      marginBottom: '0px',
                      color: colorHeader,
                      marginRight: '10px',
                    }}
                  >
                    Logradouro:
                  </p>
                  {fornecedor.logradouro}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    );
  },
);

export default DialogoConfirmacao;
