import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';

import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import { TextInput, TelefoneInput } from '../../Components';
import { createAPI, updateAPI, getListAllAPI } from '../../services';

const FECHAR_VENDA_ACTION = 'F8';
const CANCELAR_VENDA_ACTION = 'F4';

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  root: {
    flexGrow: 1,
  },
}));

const DEFAULT_ID = -1;

const inputs = [
  {
    nome: 'nome',
    defaultValue: '',
    label: 'Nome',
  },
  {
    nome: 'telefone',
    defaultValue: '',
    label: 'Telefone',
  },
];

const SidebarInputs = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [id, setId] = useState(DEFAULT_ID);

  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');

  const [carregando, setCarregando] = useState(false);

  const refs = useRef([]);

  refs.current = inputs.map(
    (ref, index) => (refs.current[index] = React.createRef()),
  );

  function validateNome() {
    let error = '';
    if (nome) {
      if (nome.length > 80)
        error = 'Este campo deve ter no máximo 80 caracteres!';
    } else {
      error = 'Este campo é obrigatório!';
    }
    return error;
  }

  function validateTelefone() {
    let error = '';
    if (telefone.length < 10) {
      error = 'O Telefone deve ter 10 ou 11 dígitos';
    }
    return error;
  }

  function getErros() {
    const errosOld = ['', ''];

    errosOld[0] = validateNome();
    errosOld[1] = validateTelefone();

    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  const handleCloseDialog = (idUpdate) => {
    setOpen(false);
    handleClose(idUpdate);
  };

  async function reiniciar() {
    try {
      setId(DEFAULT_ID);
      setNome('Anônimo');
      setTelefone('81');

      setCarregando(false);
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(-1);
    }
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'clientes_televendas',
        ['id', 'asc'],
        { id: [id] },
        [],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        setNome(data.data[0].nome);
        setTelefone(data.data[0].telefone);
        setCarregando(false);
      } else {
        throw 'Este cliente de televendas não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(-1);
    }
  }

  async function handleSalvar() {
    if (!erroExistente) {
      setCarregando(true);
      try {
        let idLocal = -1;
        if (id <= 0) {
          const produtoCriado = await createAPI('clientes_televendas', {
            nome,
            telefone,
          });
          setCarregando(false);
          if (produtoCriado.data.id) {
            enqueueSnackbar('Cliente de televendas criado com sucesso!', {
              variant: 'success',
            });
            idLocal = produtoCriado.data.id;
          } else {
            enqueueSnackbar(produtoCriado.data, {
              variant: 'error',
            });
            idLocal = -1;
          }
        } else {
          const produtoAtualizado = await updateAPI('clientes_televendas', id, {
            nome,
            telefone,
          });
          setCarregando(false);
          if (produtoAtualizado.data.id) {
            enqueueSnackbar('Cliente de televendas atualizado com sucesso!', {
              variant: 'success',
            });
            idLocal = id;
          } else {
            enqueueSnackbar(produtoAtualizado.data, {
              variant: 'error',
            });
            idLocal = -1;
          }
        }
        handleCloseDialog(idLocal);
      } catch (erros) {
        setCarregando(false);
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog(-1);
      }
    } else {
      enqueueSnackbar('Existem erros no formulário!', {
        variant: 'error',
      });
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      setOpen(true);
      setCarregando(true);
      reiniciar();
    },
    handleEdit(id) {
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
    },
  }));

  function handleActions(action) {
    switch (action) {
      case FECHAR_VENDA_ACTION:
        handleSalvar();
        break;
      case CANCELAR_VENDA_ACTION:
        handleCloseDialog(-1);
        break;
      default:
        break;
    }
  }

  function handleKey(keyCode, keyName) {
    handleActions(keyName);
  }

  function getRefNextInput(index) {
    let position = -1;
    switch (index) {
      case 0:
        position = 1;
        break;
      case 1:
        position = 0;
        break;
      default:
        break;
    }
    return position;
  }

  function handleNextInput(index) {
    const position = getRefNextInput(index);
    if (position === -1) {
      refs.current[0].current.focus();
      refs.current[0].current.select();
    } else if (refs.current[position].current) {
      refs.current[position].current.focus();
      refs.current[position].current.select();
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen
    >
      <DialogTitle id="form-dialog-title">
        {id > 0 ? 'Editar Cliente Televendas' : 'Novo Cliente Televendas'}
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ height: '100%' }}>
          <Box
            display={{ xs: 'block', sm: 'flex' }}
            flexDirection="column"
            marginBottom="30px"
            height="100%"
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '300px',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Box p="1em">
                <Box display="flex">
                  <Box flex={2} mr="1em">
                    <Typography variant="h6" gutterBottom>
                      Dados Pessoais
                    </Typography>

                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <TextInput
                          name={inputs[0].nome}
                          ref={refs.current[0]}
                          handleEnter={() => handleNextInput(0)}
                          label={inputs[0].label}
                          handleKey={handleKey}
                          value={nome}
                          onChange={(value) => setNome(value.target.value)}
                          error={erros[0] !== ''}
                          helperText={erros[0]}
                          fullWidth
                          autoFocus
                        />
                      </Box>
                      <Box flex={1} mr="0.5em">
                        <TelefoneInput
                          name={inputs[1].nome}
                          ref={refs.current[1]}
                          handleEnter={() => handleNextInput(1)}
                          label={inputs[1].label}
                          handleKey={handleKey}
                          value={telefone}
                          onChange={(value) => setTelefone(value.target.value)}
                          error={erros[1] !== ''}
                          helperText={erros[1]}
                          fullWidth
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCloseDialog(-1)} color="primary">
          Cancelar (F4)
        </Button>
        <div className={classes.wrapper}>
          <Button
            color="primary"
            onClick={() => {
              handleSalvar();
            }}
            disabled={erroExistente || carregando}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
          >
            Cadastrar (F8)
          </Button>
          {carregando && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
});

export default SidebarInputs;
