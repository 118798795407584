import React, { useRef } from 'react';
import {
  Datagrid,
  Pagination,
  ListBase,
  ListToolbar,
  useRefresh,
  useListContext,
} from 'react-admin';

import {
  Card,
  Typography,
  Box,
  makeStyles,
  useMediaQuery,
  IconButton,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';

import ActionsList from './ActionsList';
import DialogoChangeStatus from './DialogoChangeStatus';
import DialogoCreate from './DialogoCreate';
import DialogoCreate2 from './DialogoCreate3';
import DialogoDeletar from './DialogoDeletar';
import DialogoUserFila from './DialogoUserFila';
import ActionsField from './Fields/ActionsField';
import Horario from './Fields/Horario';
import Produto from './Fields/Produto';
import StatusField from './Fields/StatusField';
import TipoImpressaoField from './Fields/TipoImpressaoField';
import UnidadesField from './Fields/Unidades';
import User from './Fields/User';
import Filter from './Filter';
import DateInputMobills from './Inputs/DateInputMobills';

const useStylesDatagrid = makeStyles((theme) => ({
  header: {
    backgroundColor: '#f8f8f8',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
  },
  cell: {
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
    maxWidth: '670px',
  },
  primaryText: {
    fontWeight: 'bold',
    marginBottom: '10px',
  },
}));

function getLabelTipo(tipo_impressao) {
  switch (tipo_impressao) {
    case 0:
      return 'Varejo';
    case 1:
      return 'Atacado';
    case 2:
      return 'Varejo|Atacado';
    default:
      return 'Nenhum tipo!';
  }
}

const CategoryLista = ({
  handleEdit = () => {},
  handleDelete = () => {},
  handleEfetivar = () => {},
  ...props
}) => {
  const classes = useStyles(props);
  const { data, ids } = useListContext();
  return ids ? (
    <List className={classes.root}>
      {ids.map((id) => {
        const dadosItem = data[id];
        return (
          <>
            <ListItem alignItems="flex-start">
              <ListItemText
                classes={{ primary: classes.primaryText }}
                primary={dadosItem.produto.nome}
                secondary={
                  <>
                    <div>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                        style={{ fontWeight: 'bold', color: 'red' }}
                      >
                        {`Cópias: `}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {`${dadosItem.copias}`}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                        style={{ fontWeight: 'bold', color: 'red' }}
                      >
                        {`Tipo impressão: `}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {getLabelTipo(dadosItem.tipo_impressao)}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                        style={{ fontWeight: 'bold', color: 'red' }}
                      >
                        {`Horário: `}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {`${moment(dadosItem.horario).format('HH:mm')}`}
                      </Typography>
                    </div>
                  </>
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    handleEdit({ record: dadosItem });
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    handleDelete({ record: dadosItem });
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="inset" component="li" />
          </>
        );
      })}
    </List>
  ) : null;
};

const DataGridLocal = ({
  handleEdit = () => {},
  handleDelete = () => {},
  handleEfetivar = () => {},
  handleStatus = () => {},
  ...props
}) => {
  const classes = useStylesDatagrid();
  return (
    <>
      <Datagrid {...props} bulkActionButtons={false}>
        <StatusField
          source="status"
          label="Status"
          sortable={false}
          headerClassName={classes.header}
          cellClassName={classes.cell}
          handleStatus={handleStatus}
        />
        <Produto
          source="nome"
          label="Produto"
          sortable={false}
          headerClassName={classes.header}
          cellClassName={classes.cell}
        />
        <User
          source="user"
          label="User"
          sortable={false}
          headerClassName={classes.header}
          cellClassName={classes.cell}
        />
        <Horario
          source="horario"
          label="Horário"
          headerClassName={classes.header}
          cellClassName={classes.cell}
        />
        <UnidadesField
          source="copias"
          label="Cópias"
          sortable={false}
          headerClassName={classes.header}
          cellClassName={classes.cell}
        />

        <TipoImpressaoField
          source="tipo_impressao"
          label="Tipo impressão"
          sortable={false}
          headerClassName={classes.header}
          cellClassName={classes.cell}
        />

        <ActionsField
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleEfetivar={handleEfetivar}
          textAlign="right"
          label="Ações"
          source="status"
          sortable={false}
          headerClassName={classes.header}
          cellClassName={classes.cell}
        />
      </Datagrid>
    </>
  );
};

const MyList = ({ handleOpenFiltros, ...props }) => {
  const refresh = useRefresh();
  const refDialogo = useRef(null);
  const refDialogo2 = useRef(null);
  const refDialogoDeletar = useRef(null);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const refDialogoStatus = useRef(null);
  const refDialogoUserFila = useRef(null);

  const create = () => {
    if (refDialogo2.current) {
      refDialogo2.current.handleCreate();
    }
  };

  const edit = (data) => {
    if (refDialogo.current) {
      refDialogo.current.handleEdit(data.record.id);
    }
  };

  const deletar = (data) => {
    if (refDialogoDeletar.current) {
      refDialogoDeletar.current.handleOpen(data.record.id);
    }
  };

  const changeStatus = (data) => {
    if (data.record.status) {
      if (refDialogoStatus.current) {
        refDialogoStatus.current.handleOpen(data.record.id);
      }
    }
  };

  const defineFila = () => {
    if (refDialogoUserFila.current) {
      refDialogoUserFila.current.handleOpen();
    }
  };

  const handleClose = () => {
    refresh();
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '15px',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          marginBottom: '5px',
        }}
      >
        <Typography>Solicitações de impressão</Typography>
      </div>
      <ListToolbar
        filters={props.filters}
        actions={
          <ActionsList
            handleCriar={create}
            handleChangeFila={defineFila}
            {...props}
          />
        }
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Box flex={1} margin="0px 0 20px">
          <DateInputMobills />
        </Box>

        {isSmall ? (
          <CategoryLista handleEdit={edit} handleDelete={deletar} />
        ) : (
          <DataGridLocal
            handleEdit={edit}
            handleDelete={deletar}
            handleStatus={changeStatus}
          />
        )}
        <TransacoesPagination />
      </Card>
      <DialogoCreate handleClose={handleClose} ref={refDialogo} />
      <DialogoCreate2 handleClose={handleClose} ref={refDialogo2} />
      <DialogoDeletar handleClose={handleClose} ref={refDialogoDeletar} />
      <DialogoChangeStatus handleClose={handleClose} ref={refDialogoStatus} />
      <DialogoUserFila handleClose={handleClose} ref={refDialogoUserFila} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]} {...props} />
);

const DIA_ATUAL = new Date().getDate();
const MES_ATUAL = new Date().getMonth();
const ANO_ATUAL = new Date().getFullYear();

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      component="div"
      bulkActionButtons={false}
      sort={{ field: 'horario', order: 'DESC' }}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      filter={{ isSearchUser: true }}
      filterDefaultValues={{
        periodo: { dia: DIA_ATUAL, mes: MES_ATUAL, ano: ANO_ATUAL },
        status: false,
      }}
    />
  </>
);

export default List2;
