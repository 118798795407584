import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

import { formatMoeda } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
  },
}));

const ValorField = (props) => {
  const record = useRecordContext(props);
  const totalJuros = record
    ? record.record
      ? record.record.total_juros
      : 0
    : 0;

  return (
    <div>
      {totalJuros > 0 ? (
        <Typography
          variant="subtitle1"
          style={{
            color: '#f44336',
            fontWeight: 'bold',
          }}
        >
          {formatMoeda(totalJuros)}
        </Typography>
      ) : (
        <Typography
          variant="subtitle1"
          style={{
            //color: '#f44336',
            fontWeight: 'bold',
          }}
        >
          -
        </Typography>
      )}
    </div>
  );
};



export default ValorField;
