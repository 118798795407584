import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '400px',
    // color: 'red',
  },
}));

const DataPagamentoField = (props) => {
  const classes = useStyles();

  const record = useRecordContext(props);
  const value = record
    ? record.record.categoria_problema_despesa_id
      ? record.record.categoria_problema_despesa_id === 1
        ? null
        : new Date(record.record.data_pagamento_original)
      : new Date(record.record.data_pagamento_original)
    : null;

  return (
    <Typography className={classes.text}>
      {value ? moment(value).format('DD/MM/YYYY') : '-'}
    </Typography>
  );
};


export default DataPagamentoField;
