import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // color: 'red',
    marginLeft: '15px',
    // maxWidth: '150px',
  },
  avatar: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    color: 'white',
    backgroundColor: '#f44336',
  },
}));

const DescricaoField = (props) => {
  const classes = useStyles();
  const record = useRecordContext();
  const value = record.record
    ? record.record.despesa.problema
      ? record.record.despesa.problema.nome
      : ''
    : '';

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
      <div
        style={{ fontSize: '17px', fontWeight: 'bold', marginRight: '10px' }}
      >
        Categoria Problema:{' '}
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title={value} placement="bottom" aria-label="add2">
          <Typography className={classes.text}>{value}</Typography>
        </Tooltip>
      </div>
    </div>
  );
};



export default DescricaoField;
