import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { useHistory } from 'react-router-dom';

import { Box, makeStyles, Typography, withStyles } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AlarmOffIcon from '@material-ui/icons/AlarmOff';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import LineWeightIcon from '@material-ui/icons/LineWeight';
import LinkIcon from '@material-ui/icons/Link';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { useSnackbar } from 'notistack';

import { getOneAPI } from '../../../services';
import { StyledBadgeCompra, StyledBadgeVenda } from '../Badges';

const useStyles = makeStyles((theme) => ({
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
    fontSize: 15,
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
  },
  cartao: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#00796b',
    marginLeft: '10px',
  },
}));

const ActionsField = ({
  tipo,
  handleEdit,
  handleDelete,
  handleEfetivar,
  handleAgendar,
  handleDesagendar,
  openDialogoCompra,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();
  // const { source } = rest;
  const record = useRecordContext(rest);
  // const value = record ? record.record[source] : '';
  const statusValue = record ? record.record.status : false;

  const fatura_id = record ? record.record.fatura_id : -1;
  const disaledDelete = record
    ? record.record.parcela_venda_id ||
      record.record.parcela_compra_id ||
      record.record.parcela_compra_insumo_id ||
      (fatura_id && statusValue)
    : false;

  const disabledEdit = record
    ? (record.record.conta.is_caixa && !!record.record.parcela_venda_id) ||
      (record.record.conta.is_caixa && !record.record.is_transferencia) ||
      (fatura_id && statusValue)
    : false;

  const visibleRemove = record ? record.record.categoria.id !== 7 : true;

  const statusAgendamento = record ? record.record.agendamento_user_id : null;

  const isBoleto = record ? !!record.record.codigo_boleto : false;

  // async function copiarParaAreaDeTranferencia() {
  //   const response = await getOneAPI(
  //     'parcelascompra',
  //     record.record.parcela_compra_id,
  //     [],
  //   );

  //   const parcela = await response.data;

  //   if (parcela.codigo_boleto) {
  //     if (navigator.clipboard) {
  //       await navigator.clipboard.writeText(parcela.codigo_boleto);

  //       enqueueSnackbar('Código copiado para a área de transferência!', {
  //         variant: 'success',
  //       });
  //     } else {
  //       enqueueSnackbar('Não tem acesso a api de copia e cola!', {
  //         variant: 'error',
  //       });
  //     }
  //   } else {
  //     enqueueSnackbar('Essa despesa não possui boleto cadastrado!', {
  //       variant: 'error',
  //     });
  //   }
  // }

  async function copiarParaAreaDeTranferencia() {
    if (record.record.codigo_boleto) {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(record.record.codigo_boleto);

        enqueueSnackbar('Código copiado para a área de transferência!', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Não tem acesso a api de copia e cola!', {
          variant: 'error',
        });
      }
    } else {
      enqueueSnackbar('Essa despesa não possui boleto cadastrado!', {
        variant: 'error',
      });
    }
  }

  const isCompra = record ? !!record.record.parcela_compra_id : false;

  const isVenda = record ? !!record.record.parcela_venda_id : false;

  async function irParaCompraVenda() {
    if (isVenda) {
      const parcela = await getOneAPI(
        'parcelasvenda',
        record.record.parcela_venda_id,
        [],
      );
      history.push(`/vendas/${parcela.data.venda_id}/show/2`);
    } else if (isCompra) {
      const parcela = await getOneAPI(
        'parcelascompra',
        record.record.parcela_compra_id,
        [],
      );
      openDialogoCompra(parcela.data.compra_id);
      //history.push(`/compras/${parcela.data.compra_id}/show/2`);
    }
  }

  return (
    <div
      style={{
        marginRight: '0px',
        width: '140px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {isCompra || isVenda ? (
        isCompra ? (
          <StyledBadgeCompra
            overlap="circle"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            variant="dot"
          >
            <IconButton
              aria-label="check"
              size="small"
              onClick={() => irParaCompraVenda()}
            >
              <LinkIcon />
            </IconButton>
          </StyledBadgeCompra>
        ) : (
          <StyledBadgeVenda
            overlap="circle"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            variant="dot"
          >
            <IconButton
              aria-label="check"
              size="small"
              onClick={() => irParaCompraVenda()}
            >
              <LinkIcon />
            </IconButton>
          </StyledBadgeVenda>
        )
      ) : (
        <IconButton
          aria-label="check"
          size="small"
          style={{ backgroundColor: 'transparent' }}
        >
          <LinkIcon style={{ color: 'transparent' }} />
        </IconButton>
      )}
      {isBoleto ? (
        <Tooltip title="Copiar" placement="bottom" aria-label="add2">
          <IconButton
            aria-label="check"
            size="small"
            onClick={() => copiarParaAreaDeTranferencia()}
          >
            <LineWeightIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton
          aria-label="check"
          size="small"
          style={{ backgroundColor: 'transparent' }}
        >
          <LineWeightIcon style={{ color: 'transparent' }} />
        </IconButton>
      )}
      {tipo === 'Despesa' && (
        <>
          {statusValue ? (
            <IconButton
              aria-label="check"
              size="small"
              className={classes.confirmado}
              style={{ backgroundColor: 'transparent' }}
            >
              <AlarmOffIcon style={{ color: 'transparent', fontSize: 15 }} />
            </IconButton>
          ) : statusAgendamento ? (
            <Tooltip title="Desagendar" placement="bottom" aria-label="add2">
              <IconButton
                aria-label="check"
                size="small"
                onClick={() => handleDesagendar(record)}
                className={classes.confirmado}
              >
                <AlarmOffIcon style={{ fontSize: 15 }} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Agendar" placement="bottom" aria-label="add2">
              <IconButton
                aria-label="check"
                size="small"
                onClick={() => handleAgendar(record)}
                className={classes.pendente}
              >
                <AlarmOnIcon style={{ fontSize: 15 }} />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
      {/*tipo !== 'Transferência' && !statusValue && !fatura_id ? (
        <Tooltip title="Efetivar" placement="bottom" aria-label="add2">
          <IconButton
            aria-label="check"
            classes={{ root: classes.root }}
            size="small"
            onClick={() => handleEfetivar(record)}
          >
            <CheckCircleOutlineIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton
          aria-label="check"
          classes={{ root: classes.root }}
          size="small"
        >
          <CheckCircleOutlineIcon style={{ color: 'transparent' }} />
        </IconButton>
      )*/}
      {visibleRemove && (
        <Tooltip title="Editar" placement="bottom" aria-label="add2">
          <IconButton
            aria-label="edit"
            classes={{ root: classes.root }}
            size="small"
            onClick={() => handleEdit(record)}
            disabled={disabledEdit}
          >
            <EditOutlinedIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Deletar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="delete"
          classes={{ root: classes.root }}
          size="small"
          onClick={() => handleDelete(record)}
          disabled={disaledDelete}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};



export default ActionsField;
