import React, { useRef } from 'react';
import {
  DateField,
  Datagrid,
  ListBase,
  ListToolbar,
  Pagination,
  useRefresh,
  Title,
} from 'react-admin';

import { Card, makeStyles } from '@material-ui/core';

import DescricaoField from '../Transacoes/Fields/DescricaoField';
import Actions from './ActionsList';
import DialogoCreateEdit from './DialogoCreateEdit';
import DialogoDelete from './DialogoDelete';
import ActionsField from './Fields/ActionsField';
import ValorField from './Fields/ValorField';
import Filter from './Filter';

const useStylesDatagrid = makeStyles((theme) => ({
  statusHeader: {
    paddingLeft: '30px',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  statusCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingLeft: '30px',
  },
  statusContent: {
    flex: 5,
    marginLeft: '30px',
    maxWidth: '100px',
  },
  dataHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoHeader: {
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoCell: {
    flex: 2,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaHeader: {
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaCell: {
    flex: 2,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaHeader: {
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaCell: {
    flex: 2,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  actionsHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingRight: '100px',
  },
  actionsCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingRight: '100px',
  },
}));

const MyList = ({ ...props }) => {
  const refresh = useRefresh();
  const refDialogoCreateEdit = useRef(null);
  const refDialogoDelete = useRef(null);

  const classes = useStylesDatagrid();

  const create = (data) => {
    if (refDialogoCreateEdit.current) {
      refDialogoCreateEdit.current.handleCreate();
    }
  };

  const edit = (data) => {
    if (refDialogoCreateEdit.current) {
      refDialogoCreateEdit.current.handleEdit(data.record.id);
    }
  };

  const deletar = (data) => {
    if (refDialogoDelete.current) {
      refDialogoDelete.current.handleOpen(data.record.id);
    }
  };

  const handleClose = () => {
    refresh();
  };

  return (
    <>
      <Title title="Transações Simuladas" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} handleCriar={create} />}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid {...props} bulkActionButtons={false}>
          <DateField
            source="data_pagamento"
            label="Data"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <DescricaoField
            source="nome"
            label="nome"
            sortable={false}
            headerClassName={classes.descricaoHeader}
            cellClassName={classes.descricaoCell}
          />
          <ValorField
            source="valor"
            textAlign="right"
            label="Valor"
            headerClassName={classes.valorHeader}
            cellClassName={classes.valorCell}
          />
          <ActionsField
            source="lalala"
            handleEdit={edit}
            handleDelete={deletar}
            textAlign="right"
            label="Ações"
            headerClassName={classes.actionsHeader}
            cellClassName={classes.actionsCell}
            sortable={false}
          />
        </Datagrid>
        <TransacoesPagination />
      </Card>
      <DialogoCreateEdit ref={refDialogoCreateEdit} handleClose={handleClose} />
      <DialogoDelete ref={refDialogoDelete} handleClose={handleClose} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      filters={<Filter />}
      title="Transações Simuladas"
      component="div"
      bulkActionButtons={false}
      sort={{ field: 'data_pagamento', order: 'DESC' }}
      pagination={<TransacoesPagination />}
      perPage={50}
      filterDefaultValues={{
        periodo: {
          start: new Date(),
          end: new Date(),
        },
      }}
    />
  </>
);

export default List2;
