import React, { useState, useCallback, useEffect } from 'react';

import { Button, Box, Typography, makeStyles } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import useDebounce from '../../../hooks/useDebounce';

const useStyles = makeStyles({
  btn: {
    borderRadius: '50px',
    color: (props) => props.color,
    borderColor: (props) => props.color,
    '&:hover': {
      backgroundColor: (props) => props.color,
      color: 'white',
    },
  },
});

const meses = [
  {
    label: 'Janeiro',
    sigla: 'Jan',
  },
  {
    label: 'Fevereiro',
    sigla: 'Fev',
  },
  {
    label: 'Março',
    sigla: 'Mar',
  },
  {
    label: 'Abril',
    sigla: 'Abr',
  },
  {
    label: 'Maio',
    sigla: 'Mai',
  },
  {
    label: 'Junho',
    sigla: 'Jun',
  },
  {
    label: 'Julho',
    sigla: 'Jul',
  },
  {
    label: 'Agosto',
    sigla: 'Ago',
  },
  {
    label: 'Setembro',
    sigla: 'Set',
  },
  {
    label: 'Outubro',
    sigla: 'Out',
  },
  {
    label: 'Novembro',
    sigla: 'Nov',
  },
  {
    label: 'Dezembro',
    sigla: 'Dez',
  },
];

const MODO_CONTRAIDO = 0;
const MODO_EXPANDIDO = 1;

const DateMobillsInput = ({ color = '#59c3fa', handleData = () => {} }) => {
  const [data, setData] = useState(
    buildData(new Date().getMonth(), new Date().getFullYear()),
  );
  const [anoNav, setAnoNav] = useState(new Date().getMonth());
  const [modo, setModo] = useState(MODO_CONTRAIDO);
  const classes = useStyles({ color });
  const [dataDebounce] = useDebounce(data, 1000);

  function getData(data) {
    return {
      mes: parseInt(data.substring(0, 2), 10),
      ano: parseInt(data.substring(2), 10),
    };
  }

  function buildData(mes, ano) {
    return `${mes < 10 ? `0${mes}` : mes}${ano}`;
  }

  useEffect(() => {
    if (modo === MODO_EXPANDIDO) {
      setAnoNav(ano);
    }
  }, [modo]);

  useEffect(() => {
    handleData(getData(dataDebounce));
  }, [dataDebounce]);

  const { mes, ano } = getData(data);

  const handleBefore = () => {
    if (mes === 0) {
      setData(buildData(11, ano - 1));
    } else {
      setData(buildData(mes - 1, ano));
    }
  };

  const handleNext = () => {
    if (mes === 11) {
      setData(buildData(0, ano + 1));
    } else {
      setData(buildData(mes + 1, ano));
    }
  };

  const handleBeforeAno = () => {
    setAnoNav(anoNav - 1);
  };

  const handleNextAno = () => {
    setAnoNav(anoNav + 1);
  };

  const handleSelectMes = (newMes) => {
    setModo(MODO_CONTRAIDO);
    setData(buildData(newMes, anoNav));
  };

  function disableNext(mes, ano) {
    const dataAtual = new Date();
    return dataAtual.getMonth() === mes && dataAtual.getFullYear() === ano;
  }

  function dataMaior(mes, ano) {
    const dataAtual = new Date();
    const mesAtual = dataAtual.getMonth();
    const anoAtual = dataAtual.getFullYear();

    if (ano > anoAtual) return true;
    if (ano < anoAtual) return false;
    if (mes > mesAtual) return true;
    if (mes < mesAtual) return false;
    return false;
  }

  const isDisableNext = disableNext(mes, ano);

  return (
    <>
      {modo === MODO_CONTRAIDO ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <IconButton
            aria-label="delete"
            onClick={handleBefore}
            style={{ color }}
          >
            <NavigateBeforeIcon />
          </IconButton>
          <Box marginLeft="10px" marginRight="10px">
            <Button
              variant="outlined"
              className={classes.btn}
              style={{
                width: '160px',
              }}
              onClick={() => setModo(MODO_EXPANDIDO)}
            >
              <span
                style={{ marginRight: '5px', fontWeight: 'bold' }}
              >{`${meses[mes].label}`}</span>
              <span>{`${ano}`}</span>
            </Button>
          </Box>
          <IconButton
            aria-label="delete"
            onClick={handleNext}
            style={{ color, opacity: isDisableNext ? 0.5 : 1 }}
            disabled={isDisableNext}
          >
            <NavigateNextIcon />
          </IconButton>
        </Box>
      ) : (
        <Grow in={modo === MODO_EXPANDIDO} timeout={1000}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" alignItems="center">
              <IconButton
                aria-label="delete"
                onClick={handleBeforeAno}
                style={{ color }}
              >
                <NavigateBeforeIcon />
              </IconButton>
              <Box marginLeft="10px" marginRight="10px">
                <Typography style={{ color }}>{`${anoNav}`}</Typography>
              </Box>
              <IconButton
                aria-label="delete"
                onClick={handleNextAno}
                style={{
                  color,
                  opacity: new Date().getFullYear() <= anoNav ? 0.5 : 1,
                }}
                disabled={new Date().getFullYear() <= anoNav}
              >
                <NavigateNextIcon />
              </IconButton>
            </Box>
            <Box display="flex" alignItems="center">
              {meses.map((item, index) => (
                <Box marginRight="5px">
                  <Button
                    variant="outlined"
                    className={classes.btn}
                    onClick={() => handleSelectMes(index)}
                    disabled={dataMaior(index, anoNav)}
                  >
                    {`${item.sigla}`}
                  </Button>
                </Box>
              ))}
            </Box>
          </Box>
        </Grow>
      )}
    </>
  );
};

export default DateMobillsInput;
