import React from 'react';

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import moment from 'moment';

import logo from '../../../assets/logo.png';
import { formatMoeda } from '../../../utils';

const styles = StyleSheet.create({
  page: {},
  body: {
    width: '100%',
    fontSize: '7',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  header2: {
    display: 'flex',
    backgroundColor: '#E4E4E4',
    color: 'black',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10',
    fontWeight: 'bold',
    borderBottom: '1 solid black',
    padding: '2px 0',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid black',
  },
  contentTotais: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid black',
    backgroundColor: '#ffff00',
  },
  contentTotaisTotais: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid black',
    backgroundColor: '#f96c25',
  },
  item: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  item3: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  negativo: {
    backgroundColor: '#ffc7cf',
  },
  positivo: {
    backgroundColor: '#cbf5d0',
  },
});

const ListaProdutos = ({
  rows,
  valorCompraTotal,
  valorVendaTotal,
  pesoTotal,
  unidadesTotal,
  data = moment().format('DD/MM/YYYY'),
}) => (
  <Document author="anderson" title={`CARUARU FRIOS - INVENTÁRIO - ${data}`}>
    <Page size="A4" style={styles.page} wrap>
      <View style={styles.body}>
        <View
          style={[
            styles.title,
            { margin: '10 0', fontWeight: 'bold', fontSize: '15' },
          ]}
        >
          <Image src={logo} style={{ width: '30%' }} />
          <Text style={{ marginTop: '15px' }}>{`Inventário - ${data}`}</Text>
        </View>

        {rows.map((cat) => (
          <>
            <View style={styles.header2}>
              <Text>{cat.nome}</Text>
            </View>
            {cat.produtos.length > 0 && (
              <View style={styles.title}>
                <View style={styles.container}>
                  <View style={styles.header}>
                    <View style={[styles.item, { flex: 5 }]}>
                      <Text>Produto</Text>
                    </View>
                    <View style={[styles.item, { flex: 2 }]}>
                      <Text>Unidades</Text>
                    </View>
                    <View style={[styles.item, { flex: 2 }]}>
                      <Text>Peso (Kg)</Text>
                    </View>
                    <View style={[styles.item, { flex: 2 }]}>
                      <Text>Total em Compra</Text>
                    </View>
                    <View style={[styles.item, { flex: 2 }]}>
                      <Text>Total em Venda</Text>
                    </View>
                    <View style={[styles.item, { flex: 2 }]}>
                      <Text>Lucro</Text>
                    </View>
                    <View style={[styles.item, { flex: 2 }]}>
                      <Text>Estoque Mínimo</Text>
                    </View>
                  </View>
                  {cat.produtos.map((item) => (
                    <View style={styles.content}>
                      <View style={[styles.item3, { flex: 5 }]}>
                        <Text>{item.nome}</Text>
                      </View>
                      <View style={[styles.item, { flex: 2 }]}>
                        <Text>{item.unidadesDisponivel}</Text>
                      </View>
                      <View style={[styles.item, { flex: 2 }]}>
                        <Text>{item.pesoDisponivel}</Text>
                      </View>
                      <View style={[styles.item, { flex: 2 }]}>
                        <Text>{formatMoeda(item.precoTotalCompra)}</Text>
                      </View>
                      <View style={[styles.item, { flex: 2 }]}>
                        <Text>{formatMoeda(item.precoTotalVenda)}</Text>
                      </View>
                      <View style={[styles.item, { flex: 2 }]}>
                        <Text>{formatMoeda(item.lucro)}</Text>
                      </View>
                      <View
                        style={[
                          styles.item,
                          { flex: 2 },
                          item.statusEstoque
                            ? styles.positivo
                            : styles.negativo,
                        ]}
                      >
                        <Text>{item.statusEstoque ? 'Acima' : 'Abaixo'}</Text>
                      </View>
                    </View>
                  ))}
                  <View style={styles.contentTotais}>
                    <View style={[styles.item3, { flex: 5 }]}>
                      <Text>Totais</Text>
                    </View>
                    <View style={[styles.item, { flex: 2 }]}>
                      <Text>{cat.unidadesTotal}</Text>
                    </View>
                    <View style={[styles.item, { flex: 2 }]}>
                      <Text>{cat.pesoTotal}</Text>
                    </View>
                    <View style={[styles.item, { flex: 2 }]}>
                      <Text>{formatMoeda(cat.precoTotalCompra)}</Text>
                    </View>
                    <View style={[styles.item, { flex: 2 }]}>
                      <Text>{formatMoeda(cat.precoTotalVenda)}</Text>
                    </View>
                    <View style={[styles.item, { flex: 2 }]}>
                      <Text>{formatMoeda(cat.lucro)}</Text>
                    </View>
                    <View style={[styles.item, { flex: 2 }]}>
                      <Text />
                    </View>
                  </View>
                </View>
              </View>
            )}
          </>
        ))}
        <View style={styles.contentTotaisTotais}>
          <View style={[styles.item3, { flex: 5 }]}>
            <Text>Totais dos Totais</Text>
          </View>
          <View style={[styles.item, { flex: 2 }]}>
            <Text>{unidadesTotal}</Text>
          </View>
          <View style={[styles.item, { flex: 2 }]}>
            <Text>{pesoTotal}</Text>
          </View>
          <View style={[styles.item, { flex: 2 }]}>
            <Text>{formatMoeda(valorCompraTotal)}</Text>
          </View>
          <View style={[styles.item, { flex: 2 }]}>
            <Text>{formatMoeda(valorVendaTotal)}</Text>
          </View>
          <View style={[styles.item, { flex: 2 }]}>
            <Text>{formatMoeda(valorVendaTotal - valorCompraTotal)}</Text>
          </View>
          <View style={[styles.item, { flex: 2 }]}>
            <Text />
          </View>
        </View>
      </View>
    </Page>
  </Document>
);
export default ListaProdutos;
