import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useState,
} from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { api } from '../../../../../services';
import {
  SECONDARY_COLOR,
  ATALHO_AVANCAR,
  ATALHO_VOLTAR,
} from '../../../../../utils';
import { IntegerInput, TextInput } from '../../Inputs';

const inputs = [
  {
    nome: 'senha',
    defaultValue: '',
    label: 'Senha Gerencial',
  },
];

const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;

const DialogoNota = forwardRef(
  ({ handleCancelar, handleSalvar, enqueueSnackbar }, ref) => {
    const [open, setOpen] = useState(false);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [dados, setDados] = useState(null);

    const refs = useRef([]);

    refs.current = inputs.map(
      (ref, index) => (refs.current[index] = React.createRef()),
    );

    const handleChange = (event) => {
      setPassword(event.target.value);
    };

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const handleCloseDialog = () => {
      setOpen(false);
      setPassword('');
      handleCancelar(password);
    };

    async function handleSalvarDialog() {
      if (!erroExistente) {
        const dataConfig = await api.get('/config/1');
        if (password === dataConfig.data.senha_alterar_preco_frente_caixa) {
          handleSalvar(dados);
          setOpen(false);
          setPassword('');
          enqueueSnackbar('Desconto liberado com sucesso!!!', {
            variant: 'success',
          });
        } else {
          setOpen(false);
          setPassword('');
          enqueueSnackbar('Senha incorreta!', {
            variant: 'error',
          });
        }
      }
    }

    useImperativeHandle(ref, () => ({
      handleOpen(dados) {
        setOpen(true);
        setPassword('');
        setDados(dados);
      },
    }));

    function handleKey(action, name) {
      switch (name) {
        case FECHAR_VENDA_ACTION:
          handleSalvarDialog();
          break;
        case CANCELAR_VENDA_ACTION:
          handleCloseDialog();
          break;
        default:
          break;
      }
    }

    function getRefNextInput(index) {
      let position = -1;
      switch (index) {
        case 0:
          position = 0;
          break;
        default:
          break;
      }
      return position;
    }

    function handleNextInput(index) {
      const position = getRefNextInput(index);
      if (position !== -1) {
        if (refs.current[position].current) {
          refs.current[position].current.focus();
          refs.current[position].current.select();
        }
      } else {
        handleSalvarDialog();
      }
    }

    function validateSenha() {
      let error = '';
      if (!password) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function getErros() {
      const errosOld = [''];
      errosOld[1] = validateSenha();

      return errosOld;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    return (
      <div>
        <Dialog
          open={open}
          onClose={handleCloseDialog}
          aria-labelledby="form-dialog-title"
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle
            id="form-dialog-title"
            style={{ color: 'white', backgroundColor: SECONDARY_COLOR }}
          >
            Liberar Desconto
          </DialogTitle>
          <DialogContent>
            <TextInput
              name={inputs[0].nome}
              ref={refs.current[0]}
              type={showPassword ? 'text' : 'password'}
              label={inputs[0].label}
              value={password}
              onChange={handleChange}
              fullWidth
              autoFocus
              handleKey={handleKey}
              handleEnter={() => handleNextInput(0)}
              error={erros[0] !== ''}
              helperText={erros[0]}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </DialogContent>
          <DialogActions
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Button
              onClick={handleCloseDialog}
              color="secondary"
              variant="contained"
              tabindex="-1"
            >
              {`Cancelar (${CANCELAR_VENDA_ACTION})`}
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleSalvarDialog}
              disabled={erroExistente}
              tabindex="-1"
            >
              {`Confirmar (${FECHAR_VENDA_ACTION})`}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoNota;
