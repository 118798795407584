import React, { forwardRef } from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const SwitchInput = forwardRef(
  ({ handleEnter, handleKey, label, ...rest }, ref) => (
    <FormControlLabel
      control={
        <Switch
          {...rest}
          inputRef={ref}
          // variant="outlined"
          // margin="normal"
          onKeyDown={(e) => {
            if (e.keyCode === 13) handleEnter();
            else handleKey(e.keyCode, e.key);
          }}
        />
      }
      label={label}
      value="start"
      // labelPlacement="start"
      style={{ margin: 0 }}
    />
  ),
);

export default SwitchInput;
