import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import HttpsIcon from '@material-ui/icons/Https';
import NoEncryptionIcon from '@material-ui/icons/NoEncryption';

import { STATUS_ATIVO, STATUS_INATIVO } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    /* width: theme.spacing(3),
    height: theme.spacing(3), */
  },
}));

const GL1 = 'GL1';
const GL2 = 'GL2';
const GL3 = 'GL3';
const C1 = 'C1';

function getPodeEditar(codinome) {
  switch (codinome) {
    case GL1:
      return false;
    case GL2:
      return false;
    case GL3:
      return false;
    case C1:
      return false;
    default:
      return true;
  }
}

const ActionsField = ({
  handleEdit = () => {},
  handleDeletar = () => {},
  handleAtivar = () => {},
  handleDesativar = () => {},
  handleTravar = () => {},
  handleDestravar = () => {},
  ...rest
}) => {
  const classes = useStyles();
  const record = useRecordContext(rest);
  // const value = record ? record.record[source] : '';

  const status = record ? (record.record ? record.record.status : '') : '';

  const isPodeEditarValue = record
    ? record.record
      ? getPodeEditar(record.record.nome)
      : false
    : false;

  const isTravado = record
    ? record.record
      ? record.record.is_travado
      : true
    : true;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        // width: '120px',
      }}
    >
      <Tooltip title="Editar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="edit"
          classes={{ root: classes.root }}
          size="small"
          onClick={() => handleEdit(record.record)}
          //disabled={!isPodeEditarValue}
        >
          <EditOutlinedIcon />
        </IconButton>
      </Tooltip>
      {/* <Tooltip title="Deletar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="delete"
          classes={{ root: classes.root }}
          size="small"
          // disabled
          onClick={() => handleDeletar(record.record)}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </Tooltip> */}
      {status === STATUS_INATIVO && (
        <Tooltip title="Ativar" placement="bottom" aria-label="ativar2">
          <IconButton
            aria-label="ativar"
            size="small"
            onClick={() => handleAtivar(record.record)}
            disabled={!isPodeEditarValue}
          >
            <CheckCircleOutlineIcon />
          </IconButton>
        </Tooltip>
      )}
      {status === STATUS_ATIVO && (
        <Tooltip title="Desativar" placement="bottom" aria-label="desativar2">
          <IconButton
            aria-label="desativar"
            size="small"
            onClick={() => handleDesativar(record.record)}
            disabled={!isPodeEditarValue}
          >
            <ClearIcon />
          </IconButton>
        </Tooltip>
      )}
      {status === STATUS_INATIVO && (
        <Tooltip title="Excluir" placement="bottom" aria-label="excluir2">
          <IconButton
            aria-label="excluir"
            size="small"
            onClick={() => handleDeletar(record.record)}
            disabled={!isPodeEditarValue}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      )}
      {status === STATUS_ATIVO ? (
        isTravado ? (
          <Tooltip title="Liberar Uso" placement="bottom" aria-label="ativar2">
            <IconButton
              aria-label="ativar"
              size="small"
              onClick={() => handleDestravar(record.record)}
              disabled={!isPodeEditarValue}
            >
              <HttpsIcon
                style={
                  isPodeEditarValue
                    ? {
                        color: 'red',
                      }
                    : {}
                }
              />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Travar Uso" placement="bottom" aria-label="ativar2">
            <IconButton
              aria-label="ativar"
              size="small"
              onClick={() => handleTravar(record.record)}
              disabled={!isPodeEditarValue}
              style={
                isPodeEditarValue
                  ? {
                      color: 'green',
                    }
                  : {}
              }
            >
              <NoEncryptionIcon />
            </IconButton>
          </Tooltip>
        )
      ) : null}
    </div>
  );
};

export default ActionsField;
