import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from 'react';

import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useSnackbar } from 'notistack';

import { PrecoInput } from '../..';
import { getListAllAPI, api } from '../../../services';
import { formatMoeda } from '../../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, textAlign = 'left', ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5" style={{ fontWeight: 'bold', textAlign }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: 'black' }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '200px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '200px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [fatura, setFatura] = useState(null);
  const [valorAjustado, setValorAjustado] = useState(0);
  const classes = useStyles();

  const refValorField = useRef(null);

  function focusValorField() {
    if (refValorField.current) {
      refValorField.current.focus();
      refValorField.current.select();
    }
  }

  const tempo = 1000;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (open) {
        focusValorField();
      }
    }, tempo);
    return () => clearTimeout(timer);
  }, [open]);

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function getDataResource(cartao_credito_id, mes, ano) {
    try {
      const data = await getListAllAPI(
        'faturas',
        ['id', 'asc'],
        { cartao_credito_id, mes, ano },
        [],
      );
      if (data.data.length > 0) {
        const dataPagamentoFatura = new Date();
        dataPagamentoFatura.setDate(data.data[0].cartao.dia_pagamento);
        dataPagamentoFatura.setMonth(mes);
        dataPagamentoFatura.setFullYear(ano);
        const descricaoFatura = `${format(
          dataPagamentoFatura,
          "dd 'de' MMMM 'de' yyyy",
          {
            locale: ptBR,
          },
        )}`;
        data.data[0].descricao = descricaoFatura;
        setFatura(data.data[0]);
        setValorAjustado(0);
      } else {
        throw 'Esta fatura não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function handleSalvar() {
    try {
      await api.put(
        `/ajustar_fatura/${fatura.cartao_credito_id}/${fatura.mes}/${fatura.ano}`,
        {
          valorAjustado,
        },
      );
      enqueueSnackbar('Fatura ajustada com sucesso!', {
        variant: 'success',
      });
      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(cartao_credito_id, mes, ano) {
      getDataResource(cartao_credito_id, mes, ano);
      setOpen(true);
    },
  }));

  function validateValorAjustado() {
    let error = '';
    if (valorAjustado <= 0 || Number.isNaN(valorAjustado)) {
      error = 'Deve ter um valor maior que 0';
    }
    return error;
  }

  function getErros() {
    const errosOld = [''];
    errosOld[1] = validateValorAjustado();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  function getDadosFatura() {
    if (fatura) {
      return {
        nomeCartao: fatura.cartao.nome,
        valorFatura: fatura.valor,
        nomeFatura: fatura.descricao,
      };
    }
    return {
      nomeCartao: '',
      valorFatura: 0,
      nomeFatura: '',
    };
  }

  const { nomeCartao, nomeFatura, valorFatura } = getDadosFatura();

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ color: 'black' }}
        >
          Ajustar fatura
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '5px',
              }}
            >
              {`Cartão: ${nomeCartao}`}
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '5px',
              }}
            >
              <div>Fatura</div>
              <div style={{ fontWeight: 'bold' }}>{nomeFatura}</div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '20px',
              }}
            >
              <div>Valor Atual</div>
              <div style={{ fontWeight: 'bold' }}>
                {formatMoeda(valorFatura)}
              </div>
            </div>
            <p
              style={{
                marginBottom: '8px',
                color: 'rgba(0, 0, 0, 0.54)',
                fontWeight: 400,
              }}
            >
              O valor da sua fatura é de:
            </p>
            <PrecoInput
              name="valor"
              ref={refValorField}
              handleEnter={() => {}}
              label="Valor pago"
              handleKey={() => {}}
              value={Number.isNaN(valorAjustado) ? '' : String(valorAjustado)}
              onChange={(value) => {
                const newValue = parseFloat(value.target.value);
                if (newValue <= 0 || Number.isNaN(newValue)) {
                  setValorAjustado(0);
                } else {
                  setValorAjustado(newValue);
                }
              }}
              error={erros[1] !== ''}
              helperText={erros[1]}
              fullWidth
              variant="standard"
              autoFocus
              style={{ marginBottom: '20px', marginTop: '0px' }}
              styleInput={{ fontSize: '25px', color: '#00796b' }}
            />
          </div>
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={handleSalvar}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
            variant="contained"
            style={{ backgroundColor: 'white', color: '#00796b' }}
            color="primary"
          >
            CANCELAR
          </Button>
          <div className={classes.wrapper}>
            <Button
              onClick={handleSalvar}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="contained"
              style={{ backgroundColor: '#00796b', color: 'white' }}
              color="primary"
              disabled={erroExistente}
            >
              AJUSTAR FATURA
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
