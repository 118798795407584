import React, { useImperativeHandle, forwardRef, useState } from 'react';
import { useRefresh } from 'react-admin';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { api, getListAPI } from '../../../services';

const FormDialog = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(-1);
  const [venda, setVenda] = useState(null);
  const refresh = useRefresh();
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };

  async function handleConfirma() {
    try {
      const response = await api.put(`/mudar_tipo_venda/${id}`);
      const { status, message } = response.data;

      if (status) {
        enqueueSnackbar('Sucesso ao alterar o tipo da venda!', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(`${message}`, {
          variant: 'error',
        });
      }

      refresh();
      setOpen(false);
    } catch (erros) {
      setOpen(false);
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
    }
  }

  async function getVenda(id) {
    try {
      const data = await getListAPI(
        'vendas',
        ['id', 'asc'],
        [1, 1],
        { id: [id] },
        ['cliente'],
      );

      if (data.data.length <= 0) {
        throw 'Esta venda não existe!';
      }

      setVenda(data.data[0]);
    } catch (erros) {
      setOpen(false);
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setOpen(true);
      setId(id);
      getVenda(id);
    },
  }));

  function dadosFormatadosVenda() {
    if (venda)
      return {
        is_em_partes: venda.is_em_partes,
        numero: venda.numero,
        cliente: venda.cliente.nome,
        data_venda: moment(new Date(venda.data)).format('DD/MM/YYYY'),
      };

    return {
      is_em_partes: false,
      numero: '',
      cliente: '',
      data_venda: '',
    };
  }

  const { is_em_partes, cliente, data_venda, numero } = dadosFormatadosVenda();

  function getLabelVenda(is_em_partes) {
    if (is_em_partes) return 'Venda Normal';
    return 'Venda em partes';
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{`Mudar tipo da venda ${numero}`}</DialogTitle>
        <DialogContent>
          <div>
            <div>{`Tem certeza que deseja mudar o tipo da venda para ${getLabelVenda(
              is_em_partes,
            )}!`}</div>

            <div>{`Data: ${data_venda}`}</div>
            <div>{`Cliente: ${cliente}`}</div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
          <Button onClick={handleConfirma} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default FormDialog;
