import React, { useImperativeHandle, forwardRef, useRef } from 'react';

import { DialogoConfirmacao } from '../../../Components';
import { api } from '../../../services';

const FormDialog = forwardRef(({ handleClose }, ref) => {
  const refDialogoConfirmacao = useRef(null);

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      if (refDialogoConfirmacao.current) {
        refDialogoConfirmacao.current.handleOpen(
          'Enviar para a Lixeira',
          'Deseja realmente enviar este pacote para a lixeira?',
          id,
        );
      }
    },
  }));

  async function handleConfirma(id) {
    await api.put(`/mandar_pacote_lixeira/${id}`);
    handleClose();
  }

  return (
    <DialogoConfirmacao
      ref={refDialogoConfirmacao}
      handleConfirma={handleConfirma}
    />
  );
});

export default FormDialog;
