import React, { useState, useCallback, useEffect } from 'react';
import { useListContext } from 'react-admin';

import { Button, Box, Typography, makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import moment from 'moment';

import useDebounce from '../../hooks/useDebounce';

import { DateInputBonito } from '..';

const useStyles = makeStyles({
  btn: {
    borderRadius: '50px',
    color: (props) => props.color,
    borderColor: (props) => props.color,
  },
});

const DIA_ATUAL = new Date().getDate();
const MES_ATUAL = new Date().getMonth();
const ANO_ATUAL = new Date().getFullYear();

const DateMobillsInput = ({ color = '#040844', onChange }) => {
  const [data, setData] = useState(buildData(DIA_ATUAL, MES_ATUAL, ANO_ATUAL));
  const classes = useStyles({ color });
  const [dataDebounce] = useDebounce(data, 250);

  function handleData({ mes, ano }) {
    onChange({ dia, mes, ano });
  }

  function getData(data) {
    return {
      dia: parseInt(data.substring(0, 2), 10),
      mes: parseInt(data.substring(2, 4), 10),
      ano: parseInt(data.substring(4), 10),
    };
  }

  function buildData(dia, mes, ano) {
    return `${dia < 10 ? `0${dia}` : dia}${mes < 10 ? `0${mes}` : mes}${ano}`;
  }

  useEffect(() => {
    handleData(getData(dataDebounce));
  }, [dataDebounce]);

  const { dia, mes, ano } = getData(data);

  const handleBefore = () => {
    const dataBase = new Date();
    dataBase.setFullYear(ano);
    dataBase.setMonth(mes);
    dataBase.setDate(dia);

    const provisorio = moment(dataBase).subtract(1, 'days').format('DDMMYYYY');
    const objProvisorio = getData(provisorio);
    setData(
      buildData(objProvisorio.dia, objProvisorio.mes - 1, objProvisorio.ano),
    );
  };

  const handleNext = () => {
    const dataBase = new Date();
    dataBase.setFullYear(ano);
    dataBase.setMonth(mes);
    dataBase.setDate(dia);

    const provisorio = moment(dataBase).add(1, 'days').format('DDMMYYYY');
    const objProvisorio = getData(provisorio);
    setData(
      buildData(objProvisorio.dia, objProvisorio.mes - 1, objProvisorio.ano),
    );
  };

  function getObjData() {
    const dataBase = new Date();
    dataBase.setFullYear(ano);
    dataBase.setMonth(mes);
    dataBase.setDate(dia);
    return dataBase;
  }

  const dataObj = getObjData();

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <IconButton aria-label="delete" onClick={handleBefore} style={{ color }}>
        <NavigateBeforeIcon />
      </IconButton>
      <Box marginLeft="10px" marginRight="10px">
        <Button variant="outlined" className={classes.btn} onClick={() => {}}>
          <DateInputBonito
            showTodayButton
            todayLabel="Hoje"
            name="data_pagamento"
            handleEnter={() => {}}
            style={{ marginBottom: '0px' }}
            label=""
            handleKey={() => {}}
            value={dataObj}
            onChange={(value) => {
              const provisorio = moment(value).format('DDMMYYYY');
              const objProvisorio = getData(provisorio);
              setData(
                buildData(
                  objProvisorio.dia,
                  objProvisorio.mes - 1,
                  objProvisorio.ano,
                ),
              );
            }}
            format="dd MMMM yyyy"
            fullWidth
            disabled={false}
            variant="standard"
            inputProps={{
              style: {
                textAlign: 'center',
                color: '#040844',
                fontWeight: 'bold',
                border: 'none',
              },
            }}
          />
        </Button>
      </Box>
      <IconButton aria-label="delete" onClick={handleNext} style={{ color }}>
        <NavigateNextIcon />
      </IconButton>
    </Box>
  );
};

export default DateMobillsInput;
