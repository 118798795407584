import * as React from 'react';
import { useRecordContext } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import CropFreeIcon from '@material-ui/icons/CropFree';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TimelineIcon from '@material-ui/icons/Timeline';

import {
  STATUS_ATIVO,
  STATUS_INATIVO,
  EVENTO_MANIFESTACAO_NFE_CIENCIA_DA_OPERACAO,
} from '../../utils/constants';

const ActionsField = ({
  handleImportar = () => {},
  handlePdf = () => {},
  handleManifestacao = () => {},
  ...rest
}) => {
  const record = useRecordContext(rest);

  const status = record
    ? record.record
      ? record.record.tipo_manifestacao ===
        EVENTO_MANIFESTACAO_NFE_CIENCIA_DA_OPERACAO
      : false
    : false;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {status ? (
        <Tooltip
          title="Importar na Compra"
          placement="bottom"
          aria-label="add2"
        >
          <IconButton
            aria-label="edit"
            size="small"
            onClick={() => handleImportar(record.record)}
          >
            <ShoppingCartIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      {/*status ? (
        <Tooltip
          title="Manifestar de forma Conclusiva"
          placement="bottom"
          aria-label="add2"
        >
          <IconButton
            aria-label="edit"
            size="small"
            onClick={() => handleManifestacao(record.record)}
          >
            <DoneOutlineIcon />
          </IconButton>
        </Tooltip>
      ) : null*/}
      <Tooltip title="exibir PDF" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="edit"
          size="small"
          onClick={() => handlePdf(record.record)}
        >
          <AssignmentIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ActionsField;
