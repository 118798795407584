import React, { useRef } from 'react';
import {
  ListBase,
  ListToolbar,
  Pagination,
  useRefresh,
  Title,
  Datagrid,
  FunctionField,
  TextField,
  DateField,
  SelectField,
} from 'react-admin';

import { Card, makeStyles } from '@material-ui/core';

import { formatPorcentagem } from '../../utils';
import ActionsField from './ActionsField';
import ActionsList from './ActionsList';
import DialogoImportarJSONNcm from './DialogoImportarJSONNcm';
import DialogoImportarXCSVIBTP from './DialogoImportarXCSVIBTP';
import DialogoShow from './DialogoShow';
import DialogoUpdate from './DialogoUpdate';
import Filter from './Filter';

const useStyles = makeStyles((theme) => ({
  dataHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
}));

function ParagradoImposto({ label, valor }) {
  return (
    <p>
      <span
        style={{
          fontWeight: 'bold',
          marginRight: '5px',
        }}
      >
        {label}:
      </span>
      <span>{formatPorcentagem(valor)} </span>
    </p>
  );
}

const Panel = (props) => (
  <div>
    <ParagradoImposto
      label="Imposto Nacional"
      valor={props.record.aliquota_nacional}
    />
    <ParagradoImposto
      label="Imposto Importação"
      valor={props.record.aliquota_importacao}
    />
    <ParagradoImposto
      label="Imposto Estadual"
      valor={props.record.aliquota_estadual}
    />
    <ParagradoImposto
      label="Imposto Municipal"
      valor={props.record.aliquota_municipal}
    />
  </div>
);

const choices = [
  { id: '0', name: 'NCM' },
  { id: '1', name: 'NBS' },
  { id: '2', name: 'LC 116/2003' },
];

const MyList = ({ ...props }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const refDialogoImportarXCSVIBTP = useRef(null);
  const refDialogoUpdate = useRef(null);
  const refDialogoShow = useRef(null);
  const refDialogoImportarJSONNcm = useRef(null);

  function handleTabelaIbtp() {
    if (refDialogoImportarXCSVIBTP.current) {
      refDialogoImportarXCSVIBTP.current.handleOpen();
    }
  }

  function handleTabelaNcm() {
    if (refDialogoImportarJSONNcm.current) {
      refDialogoImportarJSONNcm.current.handleOpen();
    }
  }

  function handleUpdate(dados) {
    if (refDialogoUpdate.current) {
      refDialogoUpdate.current.handleUpdate(dados.id);
    }
  }

  function handleShow({ id }) {
    if (refDialogoShow.current) {
      refDialogoShow.current.handleOpen(id);
    }
  }

  return (
    <>
      <Title title="Tabela IBTP" />
      <ListToolbar
        filters={props.filters}
        actions={
          <ActionsList
            handleImportarCsv={handleTabelaIbtp}
            handleTabelaNcm={handleTabelaNcm}
            {...props}
          />
        }
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid {...props}>
          <TextField source="codigo" label="Código" />
          <TextField source="excecao" label="Exceção" />
          <SelectField
            sortable={false}
            source="tipo"
            label="Tipo"
            choices={choices}
          />
          <TextField source="descricao" label="Descrição" />
          <DateField source="vigencia_inicio" label="Vigência Início" />
          <DateField source="vigencia_fim" label="Vigência Fim" />
          <TextField source="chave" label="Chave" />
          <TextField source="versao" label="Versão" />
          <TextField source="fonte" label="Fonte" />
          <ActionsField
            source="lalala"
            textAlign="right"
            label="Ações"
            sortable={false}
            handleEdit={handleUpdate}
            handleShow={handleShow}
          />
        </Datagrid>
        <TransacoesPagination />
      </Card>
      <DialogoImportarXCSVIBTP
        ref={refDialogoImportarXCSVIBTP}
        handleClose={() => {}}
      />
      <DialogoImportarJSONNcm
        ref={refDialogoImportarJSONNcm}
        handleClose={() => {}}
      />
      <DialogoUpdate ref={refDialogoUpdate} handleClose={() => {}} />
      <DialogoShow
        ref={refDialogoShow}
        handleClose={() => {
          refresh();
        }}
      />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      expand={<Panel />}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Tabela IBTP"
      component="div"
    />
  </>
);

export default List2;
