import React, { forwardRef, useState, useImperativeHandle } from 'react';

import { Box } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { SwitchInput } from '../../../../..';
import { ceil10, formatMoeda, PRIMARY_COLOR } from '../../../../../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const Grafico = forwardRef(
  (
    { isProdutosSomenteFornecedor, isIncluiInativos, handleClose = () => {} },
    ref,
  ) => {
    const [open, setOpen] = useState(false);
    const [
      isProdutosSomenteFornecedorLocal,
      setIsProdutosSomenteFornecedorLocal,
    ] = useState(false);
    const [isIncluiInativosLocal, setIsIncluiInativosLocal] = useState(false);

    const classes = useStyles({ cor: '#009FD4' });

    useImperativeHandle(ref, () => ({
      handleOpen() {
        setIsProdutosSomenteFornecedorLocal(isProdutosSomenteFornecedor);
        setIsIncluiInativosLocal(isIncluiInativos);
        setOpen(true);
      },
    }));

    const handleCloseDialog = () => {
      handleClose(isProdutosSomenteFornecedorLocal, isIncluiInativosLocal);
      setOpen(false);
    };

    return (
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: PRIMARY_COLOR, color: 'white' }}
        >
          Filtros Pesquisa Produto
        </DialogTitle>
        <DialogContent>
          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <SwitchInput
                handleEnter={() => {}}
                handleKey={() => {}}
                label="Somente Produtos do Fornecedor"
                checked={isProdutosSomenteFornecedorLocal}
                onChange={(e) =>
                  setIsProdutosSomenteFornecedorLocal(e.target.checked)
                }
              />
            </Box>
            <Box flex={1} ml="0.5em">
              <SwitchInput
                handleEnter={() => {}}
                handleKey={() => {}}
                label="Incluir Inativos"
                checked={isIncluiInativosLocal}
                onChange={(e) => setIsIncluiInativosLocal(e.target.checked)}
              />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    );
  },
);

export default Grafico;
