import React, { useImperativeHandle, forwardRef, useRef } from 'react';
import { useRefresh, useNotify } from 'react-admin';

import { useSnackbar } from 'notistack';

import { DialogoConfirmacao } from '../../../Components';
import { api, getListAllAPI } from '../../../services';
import { formatDateDDMMYYYYHHmm } from '../../../utils';

const FormDialog = forwardRef(({ campoAtivo }, ref) => {
  const refresh = useRefresh();
  const { enqueueSnackbar } = useSnackbar();
  const refDialogoConfirmacao = useRef(null);

  async function getDataResource(record) {
    try {
      const data = await getListAllAPI(
        'devolucoes_vendas',
        ['id', 'asc'],
        { id: [record.id] },
        [],
      );
      if (data.data.length > 0) {
        const devolucao = data.data[0];
        refDialogoConfirmacao.current.handleOpen(
          'Exclusão de Devolução de Venda',
          `Tem certeza que excluir a  Devolução de Venda nº ${
            devolucao.id
          } realidada em ${formatDateDDMMYYYYHHmm(devolucao.data)} ?`,
          record,
        );
      } else {
        throw 'Esta Devolução de Venda não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(record) {
      getDataResource(record);
    },
  }));

  async function handleConfirma(record) {
    const response = await api.delete(`/devolucoes_vendas/${record.id}`);
    const data = response.data;
    const { status, message } = data;
    if (!status) {
      enqueueSnackbar(`${message}`, {
        variant: 'error',
      });
    }
    refresh();
  }

  return (
    <DialogoConfirmacao
      ref={refDialogoConfirmacao}
      handleConfirma={handleConfirma}
    />
  );
});

export default FormDialog;

/*import React, { useImperativeHandle, forwardRef } from 'react';
import { useRefresh, useNotify } from 'react-admin';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { api } from '../../../services';

const FormDialog = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState({});
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClose = () => {
    setOpen(false);
  };

  async function handleConfirma(e) {
    try {
      await api.delete(`/devolucoes_vendas/${value.id}`);
      refresh();
      setOpen(false);
    } catch (erros) {
      setOpen(false);
      notify(`Erro ao deletar a Devolução de Venda!`);
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(record) {
      setOpen(true);
      setValue(record);
    },
  }));

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Exluir Registro de Devolução de Venda
        </DialogTitle>
        <DialogContent>
          <div>
            <div>Tem certeza que deseja excluir esta Devolução de Venda!</div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
          <Button onClick={handleConfirma} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default FormDialog;*/
