import {
  formatCPF,
  formatCNPJ,
  PIX_ALEATORIA_OPTION,
  PIX_CNPJ_OPTION,
  PIX_CPF_OPTION,
  PIX_EMAIL_OPTION,
  PIX_TELEFONE_OPTION,
  formatTelefone,
} from '../../../utils';

function getValorFormatado(valor, type) {
  switch (type) {
    case PIX_CNPJ_OPTION:
      return formatCNPJ(valor);
    case PIX_CPF_OPTION:
      return formatCPF(valor);
    case PIX_TELEFONE_OPTION:
      return formatTelefone(valor);
    default:
      return valor;
  }
}

function getLabelTipo(type) {
  switch (type) {
    case PIX_CNPJ_OPTION:
      return 'CNPJ';
    case PIX_CPF_OPTION:
      return 'CPF';
    case PIX_TELEFONE_OPTION:
      return 'Telefone';
    case PIX_EMAIL_OPTION:
      return 'Email';
    case PIX_ALEATORIA_OPTION:
      return 'Aleatória';
    default:
      return '';
  }
}

export { getValorFormatado, getLabelTipo };
