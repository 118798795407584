import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
} from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import { TextInput } from '../../../Components';
import { ATALHO_VOLTAR, ATALHO_AVANCAR } from '../../../utils';
import DialogoSenha from '../DialogoSenha';

const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;

const DialogoConfirmacao = forwardRef(({ handleSalvar }, ref) => {
  const [open, setOpen] = useState(false);
  const [codigo, setCodigo] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const refDialogoSenha = useRef(null);
  const theme = useTheme();

  function handleCloseLocal() {
    setOpen(false);
  }

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
      setCodigo('');
    },
  }));

  function salvarLocal() {
    handleSalvar(codigo);
    setCodigo('');
    setOpen(false);
  }

  function autorizacao() {
    if (refDialogoSenha.current) {
      refDialogoSenha.current.handleOpen();
    }
  }

  function handleActions(action) {
    switch (action) {
      case FECHAR_VENDA_ACTION:
        autorizacao();
        break;
      case CANCELAR_VENDA_ACTION:
        setCodigo('');
        setOpen(false);
        break;
      default:
        break;
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseLocal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
        PaperProps={{
          style: { backgroundColor: theme.palette.secondary.main },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ color: 'white', textAlign: 'center', fontWeight: 'bold' }}
        >
          CAREGAR TROCO
        </DialogTitle>
        <DialogContent>
          <TextInput
            name="codigo"
            handleEnter={autorizacao}
            label=""
            handleKey={(keyCode, keyName) => {
              handleActions(keyName);
            }}
            value={codigo}
            onChange={(value) => setCodigo(value.target.value)}
            fullWidth
            autoFocus
            style={{
              marginTop: '10px',
              backgroundColor: 'white',
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseLocal}
            color="secondary"
            style={{ color: 'white' }}
          >
            {`Fechar (${CANCELAR_VENDA_ACTION})`}
          </Button>
        </DialogActions>
        <DialogoSenha
          ref={refDialogoSenha}
          handleClose={() => {}}
          enqueueSnackbar={enqueueSnackbar}
          handleCancelar={() => {}}
          handleSalvar={(codigo) => {
            salvarLocal();
          }}
          mensagemSucesso="Depósito autorizado!"
          title="Autorização depósito!"
        />
      </Dialog>
    </div>
  );
});

export default DialogoConfirmacao;
