import React, { useState, useCallback, cloneElement, useEffect } from 'react';
import {
  TopToolbar,
  Toolbar,
  sanitizeListRestProps,
  Button,
  useListContext,
  useVersion,
} from 'react-admin';
import { useHistory, useLocation } from 'react-router-dom';

import { Box, makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { green, pink } from '@material-ui/core/colors';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import BarChartIcon from '@material-ui/icons/BarChart';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import compareDesc from 'date-fns/compareDesc';

import { getListAllAPI } from '../../services';
import SearchInputMobills from './Inputs/SearchInputMobills';

const MES_ATUAL = new Date().getMonth();
const ANO_ATUAL = new Date().getFullYear();

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    alignItems: 'center',
  },
  small: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: pink[500],
    backgroundColor: pink[500],
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
  transacao: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: '#0039cb',
    backgroundColor: '#0039cb',
  },
  despesa: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: '#f44336',
    backgroundColor: '#f44336',
  },
  receita: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: '#4caf50',
    backgroundColor: '#4caf50',
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: '20px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {},
}))(MenuItem);

const TipoTransacaoSelect = ({ cartaoSelecionado }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [cartoesCreditoOptions, setCartoesCreditoOptions] = useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickItem = (code) => {
    handleClose();
    handleChange(code);
  };

  const handleChange = useCallback(
    (code) => {
      if (setFilters) {
        setFilters(
          { ...filterValues, cartao_credito_id: code },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  useEffect(() => {
    async function getCartoes() {
      const dataCartoes = await getListAllAPI(
        'cartoes_credito',
        ['id', 'asc'],
        {},
        [],
      );

      setCartoesCreditoOptions(dataCartoes.data);
    }
    getCartoes();
  }, []);

  return (
    <>
      <Fab
        variant="extended"
        style={{ backgroundColor: '#00796b', color: 'white' }}
        onClick={handleClick}
      >
        <ExpandMoreIcon style={{ color: 'white' }} />
        {`Cartão: ${cartaoSelecionado ? cartaoSelecionado.cartao.nome : ''}`}
      </Fab>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Zoom}
        transitionDuration={500}
      >
        {cartoesCreditoOptions.map((cartao) => (
          <StyledMenuItem onClick={() => handleClickItem(cartao.id)}>
            <ListItemText primary={cartao.nome} />
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  // displayedFilters,
  exporter,
  // filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  enabledCreate,
  handleCriar = () => {},
  handleOpenFiltros = () => {},
  handlePagarParcial = () => {},
  handlePagarAdiantado = () => {},
  handleAjustarFatura = () => {},
  handleHistoricoFatura = () => {},
  handleGraficoDespesas = () => {},
  handleVisaoGeral = () => {},
  handlePagarTudo = () => {},
  handleReabrirFatura = () => {},
  cartaoSelecionado,
  ...rest
}) => {
  const classes = useStyles();
  const version = useVersion();
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  /*  const [cartaoSelecionado, setCartaoSelecionado] = useState(null); */

  const aplicarFiltros = useCallback(
    (search) => {
      if (setFilters) {
        setFilters({ ...filterValues, search }, displayedFilters);
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickItem = (code) => {
    handleClose();
    handleCriar(code);
  };

  function getDados() {
    switch (filterValues.tipo) {
      case 'Transacao':
        return {
          color: '#0039cb',
          label: 'Transação',
        };
      case 'Despesa':
        return {
          color: '#f44336',
          label: 'Despesa',
        };
      case 'Receita':
        return {
          color: '#4caf50',
          label: 'Receita',
        };
      case 'Transferência':
        return {
          color: '#0039cb',
          label: 'Transferência',
        };
      default:
        return {
          color: '',
          label: '',
        };
    }
  }

  const dados = getDados();

  /* async function getDados2() {
    const dataCartoes = await getListAllAPI(
      'cartoes_credito',
      ['id', 'asc'],
      { id: filterValues.cartao_credito_id },
      [],
    );
    if (dataCartoes.data.length > 0) {
      setCartaoSelecionado(dataCartoes.data[0]);
    } else {
      setCartaoSelecionado(null);
    }
  }

  useEffect(() => {
    getDados2();
  }, [filterValues.periodo2, filterValues.cartao_credito_id, version]); */

  let hasItensMenu = {
    pagarPacial: true,
    pagarTotal: true,
    reabrirFatura: false,
    pagarAdiantado: true,
    ajustarFatura: true,
  };

  if (cartaoSelecionado) {
    if (cartaoSelecionado.status) {
      hasItensMenu = {
        pagarPacial: false,
        pagarTotal: false,
        reabrirFatura: true,
        pagarAdiantado: false,
        ajustarFatura: false,
      };
    }
  }

  return (
    <TopToolbar {...sanitizeListRestProps(rest)} className={classes.root}>
      <Box
        display="flex"
        justifyContent="space-between"
        flex={1}
        alignItems="center"
      >
        <div style={{ display: 'flex' }}>
          <Box marginRight="15px">
            <Tooltip title="Mais Opções" placement="top" aria-label="add2">
              <Box
                boxShadow={2}
                borderRadius="50px"
                display="flex"
                alignItems="center"
                width="44px"
                style={{
                  backgroundColor: 'white',
                  height: '48px',
                  width: '48px',
                }}
              >
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  style={{ height: '48px', width: '48px' }}
                  onClick={() => history.push(`/cartoes_credito`)}
                  disableElevation
                >
                  <ArrowBackIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>
          <TipoTransacaoSelect cartaoSelecionado={cartaoSelecionado} />
        </div>
        <Box display="flex" alignItems="center">
          <Box
            boxShadow={2}
            // onClick={handleOpenFiltros}
            borderRadius="50px"
            display="flex"
            alignItems="center"
            width="44px"
            style={{
              backgroundColor: 'white',
              height: '48px',
              width: '48px',
            }}
          >
            <IconButton
              className={classes.iconButton}
              aria-label="search"
              onClick={() => handleCriar()}
              style={{ height: '48px', width: '48px' }}
            >
              <AddIcon style={{ color: '#00796b' }} />
            </IconButton>
          </Box>
          {hasItensMenu.pagarTotal && (
            <Box
              boxShadow={2}
              // onClick={handleOpenFiltros}
              borderRadius="50px"
              display="flex"
              alignItems="center"
              marginLeft="15px"
              width="44px"
              style={{
                backgroundColor: 'white',
                height: '48px',
                width: '48px',
              }}
            >
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                onClick={() => handlePagarTudo()}
                style={{ height: '48px', width: '48px' }}
              >
                <DoneAllIcon />
              </IconButton>
            </Box>
          )}
          <Box marginLeft="15px">
            {/* <Tooltip title="Pesquisar" placement="top" aria-label="add">
              <Fab
                aria-label="add"
                onClick={handleCriar}
                style={{ backgroundColor: 'white', color: '#737477' }}
              >
                <SearchIcon />
              </Fab>
            </Tooltip> */}
            <SearchInputMobills
              color={dados.color}
              onChange={(search) => aplicarFiltros(search)}
              placeholder="Pesquise por descrição ou valor"
            />
          </Box>
          <Box marginLeft="15px">
            <Tooltip title="Mais opções" placement="top" aria-label="add2">
              {/* <Fab
                aria-label="add"
                onClick={handleCriar}
                style={{ backgroundColor: 'white', color: '#737477' }}
              >
                <MoreVertIcon />
              </Fab> */}
              <Box
                boxShadow={2}
                borderRadius="50px"
                display="flex"
                alignItems="center"
                width="44px"
                style={{
                  backgroundColor: 'white',
                  height: '48px',
                  width: '48px',
                }}
              >
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  onClick={handleClick}
                  style={{ height: '48px', width: '48px' }}
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Zoom}
        transitionDuration={500}
      >
        {/* {hasItensMenu.pagarPacial && (
          <StyledMenuItem
            onClick={() => {
              handleClose();
              handlePagarParcial();
            }}
          >
            <ListItemText primary="Pagar Parcial" />
          </StyledMenuItem>
        )} */}
        {hasItensMenu.reabrirFatura && (
          <StyledMenuItem
            onClick={() => {
              handleClose();
              handleReabrirFatura();
            }}
          >
            <ListItemText primary="Reabrir fatura" />
          </StyledMenuItem>
        )}
        {hasItensMenu.pagarAdiantado && (
          <StyledMenuItem
            onClick={() => {
              handleClose();
              handlePagarAdiantado();
            }}
          >
            <ListItemText primary="Pagar adiantado" />
          </StyledMenuItem>
        )}
        {hasItensMenu.ajustarFatura && (
          <StyledMenuItem
            onClick={() => {
              handleClose();
              handleAjustarFatura();
            }}
          >
            <ListItemText primary="Ajustar fatura" />
          </StyledMenuItem>
        )}
        <StyledMenuItem
          onClick={() => {
            handleClose();
            handleHistoricoFatura();
          }}
        >
          <ListItemText primary="Histórico de faturas" />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            handleClose();
            handleGraficoDespesas();
          }}
        >
          <ListItemText primary="Gráfico despesas" />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            handleClose();
            handleVisaoGeral();
          }}
        >
          <ListItemText primary="Visão geral" />
        </StyledMenuItem>
      </StyledMenu>
    </TopToolbar>
  );
};

export default ListActions;
