import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  DateField,
  ListContextProvider,
  useListContext,
  ListBase,
  ListToolbar,
  Pagination,
  Datagrid,
  useRefresh,
  Title,
} from 'react-admin';

import { Divider, Tabs, Tab, Card, makeStyles } from '@material-ui/core';

import { stylesMalote } from '../../utils';
import DialogoLixeira from '../MaloteSangrias/DialogoLixeira';
import ValorTransferenciaField from '../Transacoes/Fields/ValorTransferenciaField';
import Actions from './ActionsList';
import DialogoCriarMaloteCompatibilidade from './DialogoCreate';
import DialogoCriarMalote from './DialogoCriarMalote';
import DialogoDesmembrarMalote from './DialogoDesmembrarMalote';
import DialogoEditarMalote from './DialogoEditarMalote';
import DialogoFecharMalote from './DialogoFecharMalote';
import DailogoShowMalote from './DialogoShowMalote';
import ActionsField from './Fields/ActionsField';
import AtualizadorField from './Fields/AtualizadorField';
import CodigoField from './Fields/CodigoField';
import ContaField from './Fields/ContaField';
import CriadorField from './Fields/CriadorField';
import StatusField from './Fields/StatusField';
import Filter from './Filter';

const useStylesDatagrid = makeStyles((theme) => stylesMalote);
const tabs = [
  { id: 'nao_conferido', name: 'Não Enviados' },
  { id: 'conferido', name: 'Enviados' },
];

const MyList = ({ ...props }) => {
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [confirmados, setConfirmados] = useState([]);
  const [cancelados, setCancelados] = useState([]);
  const refDialogoFecharMalote = useRef(null);
  const refresh = useRefresh();
  const classes = useStylesDatagrid();
  const refDialogoShowMalote = useRef(null);
  const refDialogoEditarMalote = useRef(null);
  const refDialogoCriarMalote = useRef(null);
  const refDialogoCriarMaloteCompatibilidade = useRef(null);
  const refDialogoDesmembrarMalote = useRef(null);
  const refDialogoLixeira = useRef(null);

  function fecharMalote(record) {
    if (refDialogoFecharMalote.current)
      refDialogoFecharMalote.current.handleOpen(record.record.id);
  }

  const edit = (data) => {
    if (refDialogoEditarMalote.current) {
      refDialogoEditarMalote.current.handleOpen(data.record.id);
    }
  };

  const show = (data) => {
    if (refDialogoShowMalote.current) {
      refDialogoShowMalote.current.handleOpen(data.record.id);
    }
  };

  const create = (data) => {
    if (refDialogoCriarMalote.current) {
      refDialogoCriarMalote.current.handleOpen();
    }
  };

  const createCompatibilidade = (data) => {
    if (refDialogoCriarMaloteCompatibilidade.current) {
      refDialogoCriarMaloteCompatibilidade.current.handleOpen();
    }
  };

  const desmembrarMalote = (data) => {
    if (refDialogoDesmembrarMalote.current) {
      refDialogoDesmembrarMalote.current.handleOpen(data.record.id);
    }
  };

  useEffect(() => {
    if (ids && ids !== filterValues.visible) {
      switch (filterValues.visible) {
        case false:
          setConfirmados(ids);
          break;
        case true:
          setCancelados(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.visible]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters(
          { ...filterValues, visible: value !== 'conferido' },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  function handleClose() {
    refresh();
  }

  function handleLixeira(data) {
    if (refDialogoLixeira.current) {
      refDialogoLixeira.current.handleOpen(data.record.id);
    }
  }

  return (
    <>
      <Title title="Malotes" />
      <ListToolbar
        filters={props.filters}
        actions={
          <Actions
            {...props}
            handleCriar={create}
            handleCriarCompatibilizacao={createCompatibilidade}
          />
        }
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Divider />
        <Tabs
          variant="fullWidth"
          centered
          value={!filterValues.visible ? tabs[1].id : tabs[0].id}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.visible === true && (
            <ListContextProvider value={{ ...listContext, ids: cancelados }}>
              <Datagrid>
                <StatusField
                  source="codigo"
                  label=""
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <CodigoField
                  source="codigo"
                  label="Código"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <DateField
                  source="data_pagamento"
                  label="Criação"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  options={{ hour: 'numeric', minute: 'numeric' }}
                  sortable={false}
                />
                <CriadorField
                  label="Criador"
                  source="responsavel"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <AtualizadorField
                  label="Quem Atualizou"
                  source="responsavel"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <ContaField
                  label="Conta"
                  source="conta_id"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <ValorTransferenciaField
                  source="valor"
                  textAlign="right"
                  label="Valor"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.valorCell}
                  sortable={false}
                />
                <ActionsField
                  source="lalala"
                  handleEdit={edit}
                  handleEfetivar={fecharMalote}
                  handleShow={show}
                  handleDesmembrar={desmembrarMalote}
                  textAlign="right"
                  label="Ações"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.actionsCell}
                  sortable={false}
                  handleLixeira={handleLixeira}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.visible === false && (
            <ListContextProvider value={{ ...listContext, ids: confirmados }}>
              <Datagrid>
                <StatusField
                  source="codigo"
                  label=""
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <CodigoField
                  source="codigo"
                  label="Código"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <DateField
                  source="data_pagamento"
                  label="Criação"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  options={{ hour: 'numeric', minute: 'numeric' }}
                  sortable={false}
                />
                <CriadorField
                  label="Criador"
                  source="responsavel"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <AtualizadorField
                  label="Quem Fechou"
                  source="responsavel"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <ContaField
                  label="Conta"
                  source="conta_id"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <ValorTransferenciaField
                  source="valor"
                  textAlign="right"
                  label="Valor"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.valorCell}
                  sortable={false}
                />
                <ActionsField
                  source="lalala"
                  handleEdit={edit}
                  handleEfetivar={fecharMalote}
                  handleShow={show}
                  handleDesmembrar={desmembrarMalote}
                  textAlign="right"
                  label="Ações"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.actionsCell}
                  sortable={false}
                />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
        <TransacoesPagination />
      </Card>
      <DialogoFecharMalote
        ref={refDialogoFecharMalote}
        handleClose={handleClose}
      />
      <DailogoShowMalote ref={refDialogoShowMalote} />
      <DialogoCriarMalote
        ref={refDialogoCriarMalote}
        handleClose={handleClose}
      />
      <DialogoEditarMalote
        ref={refDialogoEditarMalote}
        handleClose={handleClose}
      />
      <DialogoCriarMaloteCompatibilidade
        ref={refDialogoCriarMaloteCompatibilidade}
        handleClose={handleClose}
      />
      <DialogoDesmembrarMalote
        ref={refDialogoDesmembrarMalote}
        handleClose={handleClose}
      />
      <DialogoLixeira ref={refDialogoLixeira} handleClose={handleClose} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Malotes"
      filterDefaultValues={{
        visible: true,
      }}
    />
  </>
);

export default List2;
