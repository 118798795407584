import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  ListBase,
  ListToolbar,
  Pagination,
  useRefresh,
  Title,
  useListContext,
  Datagrid,
  ListContextProvider,
} from 'react-admin';

import { Card, Tabs, Tab, Divider, makeStyles } from '@material-ui/core';

import { STATUS_ATIVO, STATUS_INATIVO } from '../../utils/constants';
import Actions from './ActionsList';
import DialogoCreateEdit from './DialogoCreateEdit';
import DialogoDeletar from './DialogoDeletar';
import DialogoShow from './DialogoShow';
import ActionsField from './Fields/ActionsField';
import CodigoField from './Fields/CodigoField';
import DataField from './Fields/DataField';
import PesoLiquidoField from './Fields/PesoLiquidoField';
import ProdutoField from './Fields/ProdutoField';
import ResponsavelField from './Fields/ResponsavelField';
import Filter from './Filter';

const useStyles = makeStyles((theme) => ({
  dataHeader: {
    flex: 1,
    backgroundColor: theme.palette.primary.main,
    padding: '10px',
    color: 'white',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
}));

const PostPanel = (props) => (
  <div>
    <span
      style={{
        marginRight: '10px',
        fontWeight: 'bold',
      }}
    >
      Descrição:
    </span>
    <span>{props.record.descricao}</span>
  </div>
);

const tabs = [
  { id: 'ativos', name: 'Ativos' },
  { id: 'inativos', name: 'Inativos' },
];

const MyList = ({ ...props }) => {
  const refresh = useRefresh();
  const listContext = useListContext();
  const refDialogoCreateEdit = useRef(null);
  const refDialogoDelete = useRef(null);
  const refDialogoShow = useRef(null);
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [ativos, setAtivos] = useState([]);
  const [inativos, setInativos] = useState([]);
  const classes = useStyles();

  const handleClose = () => {
    refresh();
  };

  function handleCriar() {
    if (refDialogoCreateEdit.current) {
      refDialogoCreateEdit.current.handleCreate();
    }
  }

  function handleEdit({ id }) {
    if (refDialogoCreateEdit.current) {
      refDialogoCreateEdit.current.handleEdit(id);
    }
  }

  function handleDelete({ id }) {
    if (refDialogoDelete.current) refDialogoDelete.current.handleOpen(id);
  }

  function handleShow({ id }) {
    if (refDialogoShow.current) refDialogoShow.current.handleOpen(id);
  }

  useEffect(() => {
    if (ids && ids !== filterValues.status) {
      switch (filterValues.status) {
        case STATUS_ATIVO:
          setAtivos(ids);
          break;
        case STATUS_INATIVO:
          setInativos(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.status]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters(
          {
            ...filterValues,
            status: value === 'ativos' ? STATUS_ATIVO : STATUS_INATIVO,
          },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  return (
    <>
      <Title title="Conferências Grandes Pesagens" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions handleCriar={handleCriar} {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.status === STATUS_ATIVO ? tabs[0].id : tabs[1].id}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.status === STATUS_ATIVO && (
            <ListContextProvider value={{ ...listContext, ids: ativos }}>
              <Datagrid {...props}>
                <CodigoField
                  source="id"
                  //textAlign="right"
                  label="Código"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                />
                <ProdutoField
                  source="produto_id"
                  //textAlign="right"
                  label="Produto"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                />
                <ResponsavelField
                  source="responsavel_id"
                  //textAlign="right"
                  label="Responsável"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                />
                <DataField
                  source="data"
                  //textAlign="right"
                  label="Data"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                />
                <PesoLiquidoField
                  source="peso_liquido_total"
                  //textAlign="right"
                  label="Peso Líquido Total"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                />
                <ActionsField
                  source="lalala"
                  handleEdit={handleEdit}
                  handleShow={handleShow}
                  handleDelete={handleDelete}
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.status === STATUS_INATIVO && (
            <ListContextProvider value={{ ...listContext, ids: inativos }}>
              <Datagrid {...props}>
                <CodigoField
                  source="id"
                  //textAlign="right"
                  label="Código"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                />
                <ProdutoField
                  source="produto_id"
                  //textAlign="right"
                  label="Produto"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                />
                <ResponsavelField
                  source="responsavel_id"
                  //textAlign="right"
                  label="Responsável"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                />
                <DataField
                  source="data"
                  //textAlign="right"
                  label="Data"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                />
                <PesoLiquidoField
                  source="peso_liquido_total"
                  textAlign="right"
                  label="Peso Líquido Total"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                />
                <ActionsField
                  source="lalala"
                  handleEdit={handleEdit}
                  handleShow={handleShow}
                  handleDelete={handleDelete}
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
        <TransacoesPagination />
      </Card>
      <DialogoDeletar ref={refDialogoDelete} handleClose={handleClose} />
      <DialogoCreateEdit ref={refDialogoCreateEdit} handleClose={handleClose} />
      <DialogoShow ref={refDialogoShow} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      expand={<PostPanel />}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Conferências Grandes Pesagens"
      component="div"
      filterDefaultValues={{
        status: STATUS_ATIVO,
      }}
      filter={{}}
    />
  </>
);

export default List2;
