import * as React from 'react';
import { useRecordContext } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailsIcon from '@material-ui/icons/Details';
import EditIcon from '@material-ui/icons/Edit';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import PrintIcon from '@material-ui/icons/Print';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useSnackbar } from 'notistack';

const ActionsField = ({
  handleAprovacao = () => {},
  handleDetalhes = () => {},
  ...rest
}) => {
  const record = useRecordContext(rest);

  const statusValue = record
    ? record.record
      ? record.record.is_analisado
      : false
    : false;

  return (
    <div
      style={{
        marginRight: '0px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {!statusValue ? (
        <Tooltip title="Aprovação" placement="bottom" aria-label="add2">
          <IconButton
            aria-label="check"
            size="small"
            onClick={() => handleAprovacao(record.record)}
          >
            <CheckCircleOutlineIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      {statusValue ? (
        <Tooltip title="Detalhes" placement="bottom" aria-label="add2">
          <IconButton
            aria-label="check"
            size="small"
            onClick={() => handleDetalhes(record.record)}
          >
            <DetailsIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </div>
  );
};

export default ActionsField;
