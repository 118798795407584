import React, { useRef, useState, useEffect } from 'react';
import {
  ListBase,
  ListToolbar,
  Pagination,
  useRefresh,
  Title,
  useListContext,
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  ReferenceField,
  TextField,
  useVersion,
} from 'react-admin';

import { Card, makeStyles, CardContent } from '@material-ui/core';
import { stringify } from 'query-string';

import {
  DialogoTransacao,
  DialogoChangeStatusTransacao,
  DialogoDespesaCartaoCredito,
} from '../../Components';
import { api } from '../../services';
import { formatMoeda, SECONDARY_COLOR } from '../../utils';
import DialogoPagarParcelaCompraComMalote from '../MaloteSubmalotes/DialogoPagarParcelaCompra';
import DateMobillsInput from '../Transacoes/Inputs/DateInputMobills';
import Actions from './ActionsList';
import ActionsField from './Fields/ActionsField';
import CompraField from './Fields/CompraField';
import ContaField from './Fields/ContaField';
import DataPagamentoFieldOriginal from './Fields/DataPagamentoFieldOriginal';
import DataPagamentoFields from './Fields/DataPagamentoFields';
import DiaSemanaField from './Fields/DiaSemanaField';
import DiasRealOriginal from './Fields/DiasRealOriginal';
import EncargosField from './Fields/EncargosField';
import FaturaField from './Fields/FaturaField';
import FornecedorField from './Fields/FornecedorField';
import StatusField from './Fields/StatusField';
import ValorField from './Fields/ValorField';
import Filter from './Filter';

const useStyles = makeStyles((theme) => ({
  statusHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 1,
    backgroundColor: SECONDARY_COLOR,
    padding: '10px',
    color: 'white',
    fontWeight: 'bold',
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  statusCell: {
    // marginLeft: '30px',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    // paddingLeft: '30px',
  },
  statusContent: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 5,
    marginLeft: '30px',
    maxWidth: '100px',
  },
  dataHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoCell: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaCell: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
}));

const CardTotal = ({ label, value, onClick }) => (
  <Card
    style={{ borderRadius: '20px', cursor: 'pointer', width: '50%' }}
    onClick={onClick}
  >
    <CardContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%',
          alignItems: 'center',
        }}
      >
        <div>
          <div
            style={{
              fontSize: '17px',
              color: '#a1c3d1',
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {label}
          </div>
          <div style={{ fontSize: '20px', color: 'black', fontWeight: 'bold' }}>
            {formatMoeda(value)}
          </div>
        </div>
      </div>
    </CardContent>
  </Card>
);

const SessaoTotais = () => {
  const listContext = useListContext();
  const version = useVersion();
  const { ids, filterValues, setFilters, displayedFilters, data } = listContext;

  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  async function getDados() {
    setLoading(true);
    const dados = await api.get(
      `/total_parcelas_nao_pagas_fornecedor?${stringify({
        filter: JSON.stringify({
          fornecedor_id: filterValues.fornecedor_id,
        }),
      })}`,
    );

    setTotal(dados.data.total);
    setLoading(false);
  }

  useEffect(() => {
    getDados();
  }, [filterValues.fornecedor_id, version]);

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        margin: '30px 0 15px',
        maxHeight: '100px',
      }}
    >
      <CardTotal label="Total" value={total} onClick={() => {}} />
    </div>
  );
};

const MyList = ({ ...props }) => {
  const refDialogo = useRef(null);
  const refDialogoStatus = useRef(null);
  const refDialogoDespesaCartao = useRef(null);
  const refresh = useRefresh();
  const listContext = useListContext();
  const classes = useStyles();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const refDialogoPagarParcelaCompraComMalote = useRef(null);

  function handleEdit(id, is_cartao, conta_id, statusValue) {
    if (conta_id !== 1) {
      if (refDialogo.current) {
        if (is_cartao) {
          if (refDialogoDespesaCartao.current) {
            refDialogoDespesaCartao.current.handleEdit(id);
          }
        } else if (refDialogo.current) {
          refDialogo.current.handleEdit(id);
        }
      }
    }
  }

  const confirmarHundle = (id, is_cartao, conta_id, statusValue) => {
    if (conta_id === 1) {
      if (!statusValue) {
        if (refDialogoPagarParcelaCompraComMalote.current) {
          refDialogoPagarParcelaCompraComMalote.current.handleOpen(id);
        }
      }
    } else if (refDialogoStatus.current) {
      refDialogoStatus.current.handleOpen(id);
    }
  };

  const handleClose = () => {
    refresh();
  };

  return (
    <>
      <Title title="Parcelas Não Pagas" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} />}
        {...props}
      />
      <SessaoTotais {...props} />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid {...props}>
          <CompraField
            source="compra"
            label="Compra"
            sortable={false}
            headerClassName={classes.statusHeader}
            cellClassName={classes.statusCell}
          />
          <StatusField
            source="status"
            label="Status"
            sortable={false}
            headerClassName={classes.statusHeader}
            cellClassName={classes.statusCell}
          />
          <DiaSemanaField
            source="data_pagamento"
            label="Dia"
            sortable={false}
            headerClassName={classes.statusHeader}
            cellClassName={classes.dataCell}
          />
          <DataPagamentoFields
            source="data_pagamento"
            label="Data Pagamento"
            headerClassName={classes.statusHeader}
            cellClassName={classes.dataCell}
            sortable={false}
          />
          {/*<DataPagamentoFieldOriginal
            source="data_pagamento_original"
            label="Data Original"
            headerClassName={classes.statusHeader}
            cellClassName={classes.dataCell}
            sortable={false}
          />
          <DataPagamentoFields
            source="data_pagamento"
            label="Data Real"
            headerClassName={classes.statusHeader}
            cellClassName={classes.dataCell}
            sortable={false}
          />
          <DiasRealOriginal
            source="data_pagamento"
            label="Dias atrazo"
            headerClassName={classes.statusHeader}
            cellClassName={classes.dataCell}
            sortable={false}
          />*/}
          <ContaField
            label="Conta / Cartão"
            source="conta_id"
            sortable={false}
            headerClassName={classes.statusHeader}
            cellClassName={classes.contaCell}
            link={false}
          />
          <ValorField
            source="valor"
            textAlign="right"
            label="Valor Atual"
            headerClassName={classes.statusHeader}
            cellClassName={classes.valorCell}
          />
          {/*<EncargosField
            source="total_juros"
            textAlign="right"
            label="Encargos"
            headerClassName={classes.statusHeader}
            cellClassName={classes.valorCell}
        />*/}
          <FaturaField
            source="fatura"
            sortable={false}
            textAlign="right"
            label="Fatura"
            headerClassName={classes.statusHeader}
            cellClassName={classes.valorCell}
          />
          {!filterValues.fornecedor_id && (
            <FornecedorField
              source="compra"
              sortable={false}
              textAlign="right"
              label="Fornecedor"
              headerClassName={classes.statusHeader}
              cellClassName={classes.valorCell}
            />
          )}
          <ActionsField
            source="valor"
            textAlign="right"
            label="Ações"
            sortable={false}
            headerClassName={classes.statusHeader}
            cellClassName={classes.dataCell}
            handleEfetivar={confirmarHundle}
            handleEdit={handleEdit}
          />
        </Datagrid>
        <TransacoesPagination />
      </Card>
      <DialogoTransacao ref={refDialogo} handleClose={handleClose} />
      <DialogoDespesaCartaoCredito
        ref={refDialogoDespesaCartao}
        handleClose={handleClose}
      />
      <DialogoChangeStatusTransacao
        ref={refDialogoStatus}
        handleClose={handleClose}
      />
      <DialogoPagarParcelaCompraComMalote
        ref={refDialogoPagarParcelaCompraComMalote}
        handleClose={handleClose}
      />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Parcelas Não Pagas"
      component="div"
    />
  </>
);

export default List2;
