import React from 'react';
import { TextInput, Filter as FilterAdmin } from 'react-admin';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <TextInput label="nome" source="Nome" />
  </FilterAdmin>
);

export default Filter;
