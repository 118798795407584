import React, { useRef, useState } from 'react';
import {
  List as ListTemplate,
  Datagrid,
  Pagination,
  ListBase,
  ListToolbar,
  useRefresh,
  useListContext,
  TextField,
  DateField,
} from 'react-admin';
import { useHistory } from 'react-router-dom';

import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Button,
  Box,
  makeStyles,
  useMediaQuery,
  CardHeader,
  Avatar,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import moment from 'moment';

import {
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  POR_UNIDADE,
  getEstoqueLabel,
  formatPeso,
} from '../../utils';
import ActionsList from './ActionsList';
import DialogoCreate from './DialogoCreate';
import DialogoDeletar from './DialogoDeletar';
import ActionsField from './Fields/ActionsField';
import Horario from './Fields/Horario';
import Peso from './Fields/Peso';
import Produto from './Fields/Produto';
import StatusField from './Fields/StatusField';
import UnidadesField from './Fields/Unidades';
import User from './Fields/User';
import DateInputMobills from './Inputs/DateInputMobills';

const useStylesDatagrid = makeStyles((theme) => ({
  header: {
    backgroundColor: '#f8f8f8',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
  },
  cell: {
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
    maxWidth: '670px',
  },
  primaryText: {
    fontWeight: 'bold',
    marginBottom: '10px',
  },
}));

function listShow(produto, unidades) {
  switch (produto.unidade.tipo) {
    case POR_UNIDADE:
      return {
        isUnidade: true,
        isPeso: false,
        //unidades: getUnidades(produto, unidades),
        unidades: 0,
      };
    case POR_PESO_FIXO:
      return {
        isUnidade: true,
        isPeso: true,
        unidades: 0,
        //unidades: getUnidades(produto, unidades),
      };
    case POR_PESO_VARIAVEL:
      return {
        isUnidade: true,
        isPeso: true,
        unidades: `${unidades} un`,
      };
    case POR_PESO_VARIAVEL_SEM_UNIDADE:
      return {
        isUnidade: false,
        isPeso: true,
        unidades: '0 un',
      };
    default:
      return {
        isUnidade: false,
        isPeso: false,
        unidades: '0 un',
      };
  }
}

const CategoryLista = ({
  handleEdit = () => {},
  handleDelete = () => {},
  handleEfetivar = () => {},
  ...props
}) => {
  const classes = useStyles(props);
  const { data, ids, filterValues } = useListContext();
  return ids ? (
    <List className={classes.root}>
      {ids.map((id) => {
        const dadosItem = data[id];
        const { isUnidade, isPeso, unidades } = listShow(
          dadosItem.produto,
          dadosItem.unidades,
        );
        return (
          <>
            <ListItem alignItems="flex-start">
              <ListItemText
                classes={{ primary: classes.primaryText }}
                primary={dadosItem.produto.nome}
                secondary={
                  <>
                    {isUnidade && (
                      <div>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textPrimary"
                          style={{ fontWeight: 'bold', color: 'red' }}
                        >
                          {`Unidades: `}
                        </Typography>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textPrimary"
                        >
                          {`${unidades}`}
                        </Typography>
                      </div>
                    )}
                    {isPeso && (
                      <div>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textPrimary"
                          style={{ fontWeight: 'bold', color: 'red' }}
                        >
                          {`Peso: `}
                        </Typography>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textPrimary"
                        >
                          {`${formatPeso(dadosItem.peso)} Kg`}
                        </Typography>
                      </div>
                    )}
                    <div>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                        style={{ fontWeight: 'bold', color: 'red' }}
                      >
                        {`Horário: `}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {`${moment(dadosItem.horario).format('HH:mm')}`}
                      </Typography>
                    </div>
                  </>
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    handleEdit({ record: dadosItem });
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    handleDelete({ record: dadosItem });
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="inset" component="li" />
          </>
        );
      })}
    </List>
  ) : null;
};

const DataGridLocal = ({
  handleEdit = () => {},
  handleDelete = () => {},
  handleEfetivar = () => {},
  ...props
}) => {
  const classes = useStylesDatagrid();
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  return (
    <>
      <Datagrid {...props} bulkActionButtons={false}>
        <StatusField
          source="status"
          label="Status"
          sortable={false}
          headerClassName={classes.header}
          cellClassName={classes.cell}
        />
        <Produto
          source="nome"
          label="Produto"
          sortable={false}
          headerClassName={classes.header}
          cellClassName={classes.cell}
        />
        <Horario
          source="horario"
          label="Horário"
          headerClassName={classes.header}
          cellClassName={classes.cell}
          // showTime
        />
        <User
          source="user"
          label="User"
          sortable={false}
          headerClassName={classes.header}
          cellClassName={classes.cell}
        />
        <UnidadesField
          source="unidades"
          label="Unidades"
          sortable={false}
          headerClassName={classes.header}
          cellClassName={classes.cell}
        />
        <Peso
          source="peso"
          label="Peso"
          sortable={false}
          headerClassName={classes.header}
          cellClassName={classes.cell}
        />

        <ActionsField
          source="lalala"
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleEfetivar={handleEfetivar}
          textAlign="right"
          label="Ações"
          sortable={false}
          headerClassName={classes.header}
          cellClassName={classes.cell}
        />
      </Datagrid>
    </>
  );
};

const MyList = ({ handleOpenFiltros, ...props }) => {
  const refresh = useRefresh();
  const refDialogo = useRef(null);
  const refDialogoDeletar = useRef(null);
  const refDialogoDespesaCartao = useRef(null);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const history = useHistory();

  const create = () => {
    /* if (refDialogo.current) {
      refDialogo.current.handleCreate();
    } */

    history.push('/create-conferencia-item-inventario');
  };

  const edit = (data) => {
    if (refDialogo.current) {
      refDialogo.current.handleEdit(data.record.id);
    }
  };

  const deletar = (data) => {
    if (refDialogoDeletar.current) {
      refDialogoDeletar.current.handleOpen(data.record.id);
    }
  };

  const handleClose = () => {
    refresh();
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '15px',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          marginBottom: '5px',
        }}
      >
        <Typography>Conferências do inventário</Typography>
      </div>
      <ListToolbar
        filters={props.filters}
        actions={<ActionsList handleCriar={create} {...props} />}
        // {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Box flex={1} margin="0px 0 20px">
          <DateInputMobills />
        </Box>

        {isSmall ? (
          <CategoryLista handleEdit={edit} handleDelete={deletar} />
        ) : (
          <DataGridLocal handleEdit={edit} handleDelete={deletar} />
        )}
        <TransacoesPagination />
      </Card>
      <DialogoCreate handleClose={handleClose} ref={refDialogo} />
      <DialogoDeletar handleClose={handleClose} ref={refDialogoDeletar} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]} {...props} />
);

const DIA_ATUAL = new Date().getDate();
const MES_ATUAL = new Date().getMonth();
const ANO_ATUAL = new Date().getFullYear();

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      component="div"
      bulkActionButtons={false}
      sort={{ field: 'horario', order: 'DESC' }}
      pagination={<TransacoesPagination />}
      perPage={50}
      filter={{ isSearchUser: true }}
      filterDefaultValues={{
        status: false,
        periodo: { dia: DIA_ATUAL, mes: MES_ATUAL, ano: ANO_ATUAL },
      }}
    />
  </>
);

export default List2;
