import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { useHistory, useLocation } from 'react-router-dom';

import { Box, makeStyles, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import MaterialIcon, { colorPalette } from 'material-icons-react';

import { getOneAPI } from '../../../services';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // color: 'red',
    marginLeft: '15px',
    // maxWidth: '150px',
  },
  avatar: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    color: 'white',
    backgroundColor: '#f44336',
    cursor: 'pointer',
  },
}));

const DescricaoField = ({ ...props }) => {
  const classes = useStyles();

  const value = props ? props.record.categoria.nome : '';
  const icon = props ? props.record.categoria.icon : '';
  const color = props ? props.record.categoria.color : '';

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Avatar className={classes.avatar} style={{ backgroundColor: color }}>
        <MaterialIcon icon={icon} size={20} color="#ffffff" />
      </Avatar>
      <Tooltip title={value} placement="bottom" aria-label="add2">
        <Typography className={classes.text}>{value}</Typography>
      </Tooltip>
    </div>
  );
};



export default DescricaoField;
