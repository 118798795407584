import * as React from 'react';
import { useRecordContext } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AlarmAddIcon from '@material-ui/icons/AlarmAdd';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';

import { STATUS_ATIVO } from '../../../utils';

const ActionsField = ({ handleEstenderValidade = () => {}, ...rest }) => {
  const record = useRecordContext(rest);

  const status = record
    ? record.record
      ? record.record.status === STATUS_ATIVO
      : false
    : false;

  return (
    <div
      style={{
        marginRight: '0px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {status ? (
        <Tooltip
          title="Estender Validade"
          placement="bottom"
          aria-label="estender-oferta"
        >
          <IconButton
            aria-label="estender-oferta"
            size="small"
            onClick={() => handleEstenderValidade(record.record)}
          >
            <AlarmAddIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </div>
  );
};

export default ActionsField;
