import React from 'react';
import { Filter as FilterAdmin, TextInput, BooleanInput } from 'react-admin';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <TextInput label="Descrição" source="nome" />
    <BooleanInput label="Ativos" source="ativo" />
  </FilterAdmin>
);

export default Filter;
