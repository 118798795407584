import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Typography } from '@material-ui/core';

import {
  formatMoeda,
  POR_UNIDADE,
  round10,
  formatPeso,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
} from '../../../utils';

const QuantidadeField = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  const quantidade = record ? (record.record ? record.record.qtde : 0) : 0;
  const isTipoUnidade = record
    ? record.record
      ? record.record.produto.unidade
        ? record.record.produto.unidade.tipo !== POR_PESO_VARIAVEL_SEM_UNIDADE
        : true
      : true
    : true;
  const isTipoUnidadeValue = record
    ? record.record
      ? record.record.produto.unidade
        ? record.record.produto.unidade.tipo
        : ''
      : ''
    : '';
  const value = isTipoUnidade
    ? `${round10(quantidade)} Un`
    : `${formatPeso(round10(quantidade, -3))}`;
  return (
    <Typography
      variant="subtitle1"
      style={{
        fontWeight: 'bold',
      }}
    >
      {value}
    </Typography>
  );
};

export default QuantidadeField;
