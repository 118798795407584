import React, { useEffect, useState } from 'react';
import { TextInput, DateTimeInput, FormDataConsumer } from 'react-admin';
import { useForm } from 'react-final-form';

import { Typography, Box } from '@material-ui/core';

import { IntegerInput, PesoInput } from '../../Components/InputsReactAdmin';

const Form = () => (
  <Box p="1em">
    <Box display="flex">
      <Box flex={2} mr="1em">
        <Typography variant="h6" gutterBottom>
          Geral
        </Typography>

        {/* <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => {
            const form = useForm();
            useEffect(() => {
              async function getProduto() {
                const data = await getListAPI(
                  'produtos',
                  ['id', 'asc'],
                  [1, 1],
                  // { id: [parseInt(param.id, 10)] },
                  { id: [parseInt(formData.produto_id, 10)] },
                  ['unidade'],
                );
                form.change(
                  'showPeso',
                  data.data[0].unidade.tipo !== POR_UNIDADE,
                );

                form.change(
                  'showUnidades',
                  data.data[0].unidade.tipo !== POR_PESO_VARIAVEL_SEM_UNIDADE,
                );
              }
              getProduto();
            }, [formData.produto_id]);
          }}
        </FormDataConsumer> */}

        <Box display="flex">
          {/* <Box flex={1} mr="0.5em">
            <IntegerInput source="unidades" label="Unidades" fullWidth />
          </Box> */}

          <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => (
              <>
                {formData.showUnidades && (
                  <Box flex={1} ml="0.5em">
                    <IntegerInput
                      source="unidades"
                      label="Unidades"
                      fullWidth
                      {...rest}
                    />
                  </Box>
                )}
              </>
            )}
          </FormDataConsumer>

          {/* <Box flex={1} ml="0.5em">
            <PesoInput source="peso" label="Peso" fullWidth />
          </Box> */}
          <Box flex={1} ml="0.5em">
            <FormDataConsumer>
              {({ formData, dispatch, ...rest }) => (
                <>
                  {formData.showPeso && (
                    <PesoInput
                      source="peso"
                      label="Peso"
                      fullWidth
                      {...rest}
                      disabled={formData.pm}
                    />
                  )}
                </>
              )}
            </FormDataConsumer>
          </Box>

          <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => (
              <>{!formData.showUnidades && <Box flex={1} ml="0.5em" />}</>
            )}
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => {
              const form = useForm();
              useEffect(() => {
                if (formData.pm) {
                  form.change('peso', formData.unidades * formData.pm);
                }
              }, [formData.unidades]);
            }}
          </FormDataConsumer>
        </Box>

        <Box mt="1em" />
      </Box>
    </Box>
  </Box>
);
export default Form;
