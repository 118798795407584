import React from 'react';
import { useInput, InputHelperText, FieldTitle } from 'react-admin';
import DatePicker, { registerLocale } from 'react-datepicker';

import TextField from '@material-ui/core/TextField';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/locale';

registerLocale('ptBR', pt);

function range(inicio, termino) {
  const anos = [];
  for (let i = inicio; i <= termino; i++) {
    anos.push(i);
  }
  return anos;
}

const years = range(
  new Date().getFullYear() - 10,
  new Date().getFullYear() + 10,
);
const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

const PeriodoInput = (props) => {
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props);

  const {
    format = (v) => String(v),
    helperText,
    label,
    margin = 'dense',
    onBlur,
    onFocus,
    onChange: onChange2,
    options,
    parse = (v) => parseFloat(v),
    resource,
    source,
    step,
    min,
    max,
    validate,
    variant = 'filled',
    inputProps: overrideInputProps,
    ...rest2
  } = props;

  function getDateStart() {
    return rest.value.start ? new Date(rest.value.start) : new Date();
  }

  function getDateEnd() {
    return rest.value.end ? new Date(rest.value.end) : new Date();
  }

  function getYear(date) {
    return date.getFullYear();
  }

  function getMonth(date) {
    return date.getMonth();
  }

  const CustomInput = ({ value, onClick, label }) => (
    <TextField
      name={name}
      variant={variant}
      margin={margin}
      value={value}
      onClick={onClick}
      helperText={
        <InputHelperText
          touched={touched}
          error={error}
          helperText={helperText}
        />
      }
      label={
        <FieldTitle
          label={label}
          source={source}
          resource={resource}
          isRequired={isRequired}
        />
      }
      style={{ cursor: 'pointer', width: '120px' }}
      {...rest2}
    />
  );

  return (
    <div style={{ zIndex: '3000', display: 'flex' }}>
      <div style={{ marginRight: '15px' }}>
        <DatePicker
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div
              style={{
                margin: 10,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                type="button"
              >
                {'<'}
              </button>
              <select
                value={getYear(date)}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                type="button"
              >
                {'>'}
              </button>
            </div>
          )}
          locale="ptBR"
          selected={getDateStart()}
          onChange={(date) =>
            onChange({
              target: {
                name,
                value: {
                  start: date,
                  end: getDateEnd(),
                },
              },
            })
          }
          maxDate={getDateEnd()}
          dateFormat="dd/MM/yyyy"
          selectsRange={false}
          customInput={<CustomInput label="Início" />}
        />
      </div>
      <DatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              type="button"
            >
              {'<'}
            </button>
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              type="button"
            >
              {'>'}
            </button>
          </div>
        )}
        locale="ptBR"
        selected={getDateEnd()}
        onChange={(date) =>
          onChange({
            target: {
              name,
              value: {
                start: getDateStart(),
                end: date,
              },
            },
          })
        }
        minDate={getDateStart()}
        dateFormat="dd/MM/yyyy"
        selectsRange={false}
        customInput={<CustomInput label="Término" />}
      />
    </div>
  );
};

export default PeriodoInput;
