import { Category } from '@material-ui/icons';

import Create from './Create';
import Edit from './Edit';
import List from './List';

export default {
  list: List,
  /*  create: Create,
  edit: Edit, */
  icon: Category,
};
