import React from 'react';

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';

import logo from '../../../assets/logo.png';

const styles = StyleSheet.create({
  page: {},
  body: {
    width: '100%',
    fontSize: '7',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  header2: {
    display: 'flex',
    backgroundColor: '#E4E4E4',
    color: 'black',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10',
    fontWeight: 'bold',
    borderBottom: '1 solid black',
    padding: '2px 0',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid black',
  },
  item: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  item3: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const ListaProdutos = ({ categorias, data }) => (
  <Document
    author="anderson"
    title={`CARUARU FRIOS - LISTA PRODUTOS - ${data}`}
  >
    <Page size="A4" style={styles.page} wrap>
      <View style={styles.body}>
        <View
          style={[
            styles.title,
            { margin: '10 0', fontWeight: 'bold', fontSize: '15' },
          ]}
        >
          <Image src={logo} style={{ width: '30%' }} />
          <Text
            style={{ marginTop: '15px' }}
          >{`LISTA PRODUTOS - ${data}`}</Text>
        </View>

        {categorias.map((cat) => (
          <>
            <View style={styles.header2}>
              <Text>{cat.nome}</Text>
            </View>
            {cat.produtos.length > 0 && (
              <View style={styles.title}>
                <View style={styles.container}>
                  <View style={styles.header}>
                    <View style={[styles.item, { flex: 8 }]}>
                      <Text>Descrição</Text>
                    </View>
                    <View style={[styles.item, { flex: 2 }]}>
                      <Text>Unidades</Text>
                    </View>
                    <View style={[styles.item, { flex: 2 }]}>
                      <Text>Peso</Text>
                    </View>
                    <View style={[styles.item, { flex: 2 }]}>
                      <Text>Tipo Unidade</Text>
                    </View>
                    <View style={[styles.item, { flex: 3 }]}>
                      <Text>Código de barras</Text>
                    </View>
                    <View style={[styles.item, { flex: 2 }]}>
                      <Text>Código Interno</Text>
                    </View>
                    <View style={[styles.item, { flex: 1 }]}>
                      <Text>Tecla</Text>
                    </View>
                  </View>
                  {cat.produtos.map((item) => (
                    <View style={styles.content}>
                      <View style={[styles.item3, { flex: 8 }]}>
                        <Text>{item.descricao}</Text>
                      </View>
                      <View style={[styles.item, { flex: 2 }]}>
                        <Text>{item.unidades}</Text>
                      </View>
                      <View style={[styles.item, { flex: 2 }]}>
                        <Text>{item.peso}</Text>
                      </View>
                      <View style={[styles.item, { flex: 2 }]}>
                        <Text>{item.tipoUnidade}</Text>
                      </View>
                      <View style={[styles.item, { flex: 3 }]}>
                        <Text>{item.codigo}</Text>
                      </View>
                      <View style={[styles.item, { flex: 2 }]}>
                        <Text>{item.codigo_interno}</Text>
                      </View>
                      <View style={[styles.item, { flex: 1 }]}>
                        <Text>{item.tecla}</Text>
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            )}
          </>
        ))}
      </View>
    </Page>
  </Document>
);

export default ListaProdutos;
