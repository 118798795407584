import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import { formatMoeda, getTotal2 } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '400px',
  },
}));

function getSubTotal(data) {
  const unidades = data.unidades_caixa_aparente
    ? data.unidades_caixa_aparente
    : data.unidades_caixa
    ? data.unidades / data.unidades_caixa
    : data.unidades;

  const unitario = data.unidades_caixa_aparente
    ? data.preco_venda_caixa
    : data.unidades_caixa
    ? data.preco_venda_caixa
    : data.preco_venda;

  const isNivel = data.unidades_caixa_aparente ? true : !!data.unidades_caixa;

  const total = formatMoeda(
    getTotal2(data.peso, unidades, unitario, {
      ...data.produto,
      nivelCaixa: isNivel,
    }),
  );

  return total;
}

const DescricaoField = (props) => {
  const classes = useStyles();

  const record = useRecordContext(props);
  const value = record ? getSubTotal(record.record) : '';

  return <Typography className={classes.text}>{value}</Typography>;
};

export default DescricaoField;
