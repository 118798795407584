import React, { useImperativeHandle, forwardRef } from 'react';
import { useRefresh, useNotify } from 'react-admin';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

import { api } from '../../services';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const FormDialog = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState({});
  const [modoEdit, setModoEdit] = React.useState(0);
  const classes = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClose = () => {
    setOpen(false);
  };

  async function handleConfirma(e) {
    try {
      await api.delete(`/${value.resource}/${value.id}?modoEdicao=${modoEdit}`);
      refresh();
      setOpen(false);
    } catch (erros) {
      setOpen(false);
      notify(
        `Não é possível deletar esse registro, por haver registros relacionados a este!`,
      );
    }
  }

  const handleChange = (event) => {
    setModoEdit(event.target.value);
  };

  useImperativeHandle(ref, () => ({
    handleOpen(record) {
      setOpen(true);
      setModoEdit(0);
      setValue(record);
    },
  }));

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Exluir Item</DialogTitle>
        <DialogContent>
          <div>
            {value.grupo_id ? (
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Você deseja
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={modoEdit}
                  onChange={handleChange}
                  label="Você deseja"
                >
                  <MenuItem value={0}>Excluir somente esta</MenuItem>
                  <MenuItem value={1}>Excluir esta, e as futuras</MenuItem>
                  <MenuItem value={2}>
                    Excluir todas (incluindo recebidas)
                  </MenuItem>
                </Select>
              </FormControl>
            ) : (
              <div> Tem certeza que deseja excluir essee registro!!! </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
          <Button onClick={handleConfirma} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default FormDialog;
