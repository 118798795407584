import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useState,
} from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSnackbar } from 'notistack';

import { NumeroNotaFiscalInput } from '../../../Components';
import { api } from '../../../services';

const DEFAULT_ID = -1;

const DialogoNota = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const [numeroNota, setNumeroNota] = useState('');
  const [id, setId] = useState(DEFAULT_ID);
  const { enqueueSnackbar } = useSnackbar();
  const refBtn = useRef(null);

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function reiniciar() {
    setNumeroNota('');
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      reiniciar();
      setId(id);
      setOpen(true);
    },
  }));

  async function handleConfirma() {
    try {
      await api.put(`/parcelas_vendas_nfe_verificado/confirmar/${id}`, {
        numero_nota_fiscal: numeroNota,
      });
      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  function validateNumeroNota() {
    let error = '';
    if (!numeroNota) error = 'Este campo é obrigatório';
    else if (numeroNota.length < 9) {
      error = 'Número de caracteres menor que o permitido (mín 9)';
    }
    return error;
  }

  function getErros() {
    const errosOld = [''];
    errosOld[0] = validateNumeroNota();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="form-dialog-title">Marcar como verificado</DialogTitle>
        <DialogContent>
          <NumeroNotaFiscalInput
            label="Número nota fiscal"
            onChange={(value) => setNumeroNota(value.target.value)}
            value={numeroNota}
            error={false}
            helperText=""
            disabled={false}
            handleEnter={() => {}}
            handleKey={() => {}}
            fullwidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" ref={refBtn}>
            Cancelar
          </Button>
          <Button
            onClick={handleConfirma}
            color="primary"
            ref={refBtn}
            disabled={erroExistente}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoNota;
