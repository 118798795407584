import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { Box } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: '2px 4px',
    padding: '0px 0px 0px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    borderRadius: '50px',
  },
  input: {
    marginLeft: theme.spacing(1),
    // marginBottom: '2px',
    flex: 1,
    borderBottom: '2px solid blue',
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  containerIcon: {

  },
}));

const MODO_FECHADO = 0;
const MODO_ABERTO = 1;

export default function SearchMobillInput() {
  const [modo, setModo] = useState(MODO_FECHADO);
  const classes = useStyles();

  const handleModoAberto = () => {
    setModo(MODO_ABERTO);
  };

  const handleModoFechado = () => {
    setModo(MODO_FECHADO);
  };

  return (
    <>
      { modo === MODO_ABERTO
        ? (
          <Slide in={modo === MODO_ABERTO} timeout={1000} mountOnEnter unmountOnExit>
            <Box
              component="div"
              boxShadow={2}
              className={classes.root}
            >
              <InputBase
                className={classes.input}
                placeholder="Pesquise por descrição, categoria ou valor"
                inputProps={{ 'aria-label': 'search google maps' }}
                onBlur={handleModoFechado}
                autoFocus
              />
              <IconButton className={classes.iconButton} aria-label="search" onClick={handleModoFechado}>
                <SearchIcon />
              </IconButton>
            </Box>
          </Slide>
        )
        : (
          <Box
            boxShadow={2}
            borderRadius="50px"
            display="flex"
            alignItems="center"
            width="44px"
          >
            <IconButton className={classes.iconButton} aria-label="search" onClick={handleModoAberto}>
              <SearchIcon />
            </IconButton>
          </Box>
        )}
    </>
  );
}
