import React, { useState } from 'react';
import {
  Button,
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Grow from '@material-ui/core/Grow';

const useStyles = makeStyles({
  btn: {
    borderRadius: '50px',
    color: (props) => props.color,
    borderColor: (props) => props.color,
    '&:hover': {
      backgroundColor: (props) => props.color,
      color: 'white',
    },
  },
});

const meses = [
  {
    label: 'Janeiro',
    sigla: 'Jan',
  },
  {
    label: 'Fevereiro',
    sigla: 'Fev',
  },
  {
    label: 'Março',
    sigla: 'Mar',
  },
  {
    label: 'Abril',
    sigla: 'Abr',
  },
  {
    label: 'Maio',
    sigla: 'Mai',
  },
  {
    label: 'Junho',
    sigla: 'Jun',
  },
  {
    label: 'Julho',
    sigla: 'Jul',
  },
  {
    label: 'Agosto',
    sigla: 'Ago',
  },
  {
    label: 'Setembro',
    sigla: 'Set',
  },
  {
    label: 'Outubro',
    sigla: 'Out',
  },
  {
    label: 'Novembro',
    sigla: 'Nov',
  },
  {
    label: 'Dezembro',
    sigla: 'Dez',
  },
];

const MODO_CONTRAIDO = 0;
const MODO_EXPANDIDO = 1;

const DateMobillsInput = ({ color = '#59c3fa' }) => {
  const [ano, setAno] = useState(new Date().getFullYear());
  const [mes, setMes] = useState(new Date().getMonth());
  const [modo, setModo] = useState(MODO_CONTRAIDO);
  const classes = useStyles({ color });

  const handleBefore = () => {
    if (mes === 0) {
      setMes(11);
      setAno(ano - 1);
    } else {
      setMes(mes - 1);
    }
  };

  const handleNext = () => {
    if (mes === 11) {
      setMes(0);
      setAno(ano + 1);
    } else {
      setMes(mes + 1);
    }
  };

  const handleBeforeAno = () => {
    setAno(ano - 1);
  };

  const handleNextAno = () => {
    setAno(ano + 1);
  };

  const handleSelectMes = (newMes) => {
    setMes(newMes);
    setModo(MODO_CONTRAIDO);
  };

  return (
    <>
      {modo === MODO_CONTRAIDO
        ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <IconButton aria-label="delete" onClick={handleBefore} style={{ color }}>
              <NavigateBeforeIcon />
            </IconButton>
            <Box marginLeft="10px" marginRight="10px">
              <Button
                variant="outlined"
                className={classes.btn}
                style={{
                  width: '160px',
                }}
                onClick={() => setModo(MODO_EXPANDIDO)}
              >
                <span style={{ marginRight: '5px', fontWeight: 'bold' }}>{`${meses[mes].label}`}</span>
                <span>{`${ano}`}</span>
              </Button>
            </Box>
            <IconButton aria-label="delete" onClick={handleNext} style={{ color }}>
              <NavigateNextIcon />
            </IconButton>
          </Box>
        )
        : (
          <Grow in={modo === MODO_EXPANDIDO} timeout={1000}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box display="flex" alignItems="center">
                <IconButton aria-label="delete" onClick={handleBeforeAno} style={{ color }}>
                  <NavigateBeforeIcon />
                </IconButton>
                <Box marginLeft="10px" marginRight="10px">
                  <Typography style={{ color }}>{`${ano}`}</Typography>
                </Box>
                <IconButton aria-label="delete" onClick={handleNextAno} style={{ color }}>
                  <NavigateNextIcon />
                </IconButton>
              </Box>
              <Box display="flex" alignItems="center">
                {meses.map((item, index) => (
                  <Box marginRight="5px">
                    <Button
                      variant="outlined"
                      className={classes.btn}
                      onClick={() => handleSelectMes(index)}
                    >
                      {`${item.sigla}`}
                    </Button>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grow>
        )}
    </>
  );
};

export default DateMobillsInput;
