import React from 'react';
import {
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
} from 'react-admin';

import { Box } from '@material-ui/core';

import { PesoGramasInput } from '../../Components/InputsReactAdmin';

const Form = (props) => (
  <Box p="1em">
    <Box display="flex">
      <Box flex={2} mr="0.5em">
        <TextInput
          source="nome"
          autoFocus
          label="Descrição"
          fullWidth
          {...props}
        />
      </Box>
      <Box flex={2} mr="0.5em">
        <PesoGramasInput
          fullWidth
          source="valor"
          label="Valor Tara"
          {...props}
        />
      </Box>
    </Box>
  </Box>
);

export default Form;
