import React, { useState, forwardRef, useImperativeHandle } from 'react';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import { getListAllAPI, api, getUserId } from '../../../services';
import {
  TIPO_OFERTA_PRODUTO_JORNAL,
  TIPO_OFERTA_PRODUTO_LIBERACAO,
  TIPO_OFERTA_PRODUTO_ZERAR_ESTOQUE,
} from '../../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState(-1);
  const [nome, setNome] = useState('');
  const [tipoOferta, setTipoOferta] = useState('');
  const [carregando, setCarregando] = useState(false);

  const classes = useStyles();

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  function getLabelTipoOferta() {
    switch (tipoOferta) {
      case TIPO_OFERTA_PRODUTO_JORNAL:
        return 'Oferta de Jornal';
      case TIPO_OFERTA_PRODUTO_LIBERACAO:
        return 'Liberação de Preço';
      case TIPO_OFERTA_PRODUTO_ZERAR_ESTOQUE:
        return 'Oferta de Zerar Estoque';
      default:
        return '';
    }
  }

  const labelTipoOferta = getLabelTipoOferta();

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'ofertas_jornal_produtos',
        ['id', 'asc'],
        { id: [id] },
        ['produto'],
      );
      if (data.data.length > 0) {
        setNome(data.data[0].produto.nome);
        setTipoOferta(data.data[0].tipo);
        setCarregando(false);
      } else {
        throw `Esta ${labelTipoOferta} não existe!`;
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function handleSalvar() {
    try {
      if (id <= 0) {
        enqueueSnackbar(`Nenhuma ${labelTipoOferta} aqui!!!`, {
          variant: 'error',
        });
      } else {
        await api.delete(
          `/ofertas_jornal_produtos/${id}?user_id=${getUserId()}`,
        );
        setCarregando(false);
        enqueueSnackbar(`${labelTipoOferta} apagada!`, {
          variant: 'success',
        });
      }
      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`Erro ao excluir esta ${labelTipoOferta}!`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setCarregando(true);
      getDataResource(id);
      setOpen(true);
      setId(id);
    },
  }));

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{
            backgroundColor: '#f44336',
            color: 'white',
          }}
        >
          {`Deletar ${labelTipoOferta}`}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '20px',
                  height: '100px',
                }}
              >
                {nome}
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '32px 36px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={handleCloseDialog}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
            variant="outlined"
            color="primary"
          >
            Cancelar
          </Button>
          <Box>
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                variant="contained"
                color="primary"
                disabled={carregando}
              >
                Deletar
              </Button>
              {carregando && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
