import React, { cloneElement } from 'react';
import { TopToolbar, sanitizeListRestProps, Button } from 'react-admin';

import PublishIcon from '@material-ui/icons/Publish';

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  enabledCreate,
  handleImportarCsv = () => {},
  handleTabelaNcm = () => {},
  ...rest
}) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {filters &&
      cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <Button onClick={handleImportarCsv} label="Importar CSV">
      <PublishIcon />
    </Button>
    <Button onClick={handleTabelaNcm} label="Importar NCM JSON">
      <PublishIcon />
    </Button>
  </TopToolbar>
);

export default ListActions;
