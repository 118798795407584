import React, { forwardRef, useEffect, useRef, useState } from 'react';

import { TextInput } from '../TextInput';

export const NumberInput = forwardRef(
  (
    {
      value,
      onChange,
      decimalPlaces,
      handleEnter = () => {},
      handleKey = () => {},
      prefix = '',
      //suffix = '',
      name,
      ...rest
    },
    ref,
  ) => {
    //const [count, setCount] = useState(0);
    //const ref2 = useRef(null);

    //const refFinal = ref ? ref : ref2;

    /*useEffect(() => {
      if (refFinal) {
        if (refFinal.current) {
          const lastIndex = valueFormated.length - suffix.length;
          refFinal.current.focus();
          refFinal.current.setSelectionRange(lastIndex, lastIndex);
        }
      }
    }, [count]);*/

    const handleChange = (event) => {
      const newValue = event.target.value;
      onChange({ target: { name, value: sanitize(newValue) } });
      //setCount(count + 1);
    };

    function getZeros(count) {
      let zeros = '';
      for (let i = 0; i < count; i++) {
        zeros += '0';
      }
      return zeros;
    }

    function sanitize(value) {
      let replaceQuantity = formatNumberMask(value);
      replaceQuantity = replaceQuantity.replaceAll(/[^0-9.,]/g, '');
      replaceQuantity = replaceQuantity.replaceAll(/[.]/g, '');
      replaceQuantity = replaceQuantity.replaceAll(/,/g, '.');
      return Number(replaceQuantity);
    }

    function formatNumberMask(value) {
      let replaceQuantity = value;
      if (value === '') {
        if (decimalPlaces <= 0) {
          return '0';
        }
        return `0,${getZeros(decimalPlaces)}`;
      }
      replaceQuantity = String(Number(value.replaceAll(/[^0-9]/g, '')));
      if (decimalPlaces > 0) {
        if (Number.isNaN(Number(replaceQuantity))) {
          return `0,${getZeros(decimalPlaces)}`;
        }
        if (replaceQuantity.length <= decimalPlaces) {
          return `0,${getZeros(
            decimalPlaces - replaceQuantity.length,
          )}${replaceQuantity}`;
        }
        if (
          replaceQuantity.length > decimalPlaces &&
          replaceQuantity.length < 3 + decimalPlaces
        ) {
          replaceQuantity = `${replaceQuantity.slice(
            0,
            -1 * decimalPlaces,
          )},${replaceQuantity.slice(-1 * decimalPlaces)}`;
        } else {
          const before = replaceQuantity.slice(0, -1 * decimalPlaces);
          const after = replaceQuantity.slice(-1 * decimalPlaces);
          let formated = '';
          for (let i = 0; i < before.length; i += 1) {
            let dot = '';
            if (i % 3 === 0 && i !== 0) dot = '.';
            formated = before[before.length - 1 - i] + dot + formated;
          }
          replaceQuantity = `${formated},${after}`;
        }
      } else if (Number.isNaN(Number(replaceQuantity))) {
        replaceQuantity = '0';
      } else {
        let formated = '';
        for (let i = 0; i < replaceQuantity.length; i += 1) {
          let dot = '';
          if (i % 3 === 0 && i !== 0) dot = '.';
          formated =
            replaceQuantity[replaceQuantity.length - 1 - i] + dot + formated;
        }
        replaceQuantity = formated;
      }
      return replaceQuantity;
    }

    const valueFormated = `${prefix}${formatNumberMask(
      decimalPlaces <= 0
        ? String(value)
        : value.toLocaleString('de-DE', {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
          }),
    )}`;

    return (
      <TextInput
        {...rest}
        value={valueFormated}
        onChange={handleChange}
        handleEnter={handleEnter}
        handleKey={handleKey}
        //ref={refFinal}
        ref={ref}
      />
    );
  },
);
