import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

import {
  STATUS_ATIVO,
  STATUS_INATIVO,
  STATUS_DELETADO,
} from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    /* width: theme.spacing(3),
    height: theme.spacing(3), */
  },
}));

const ActionsField = ({
  tipo,
  handleEdit = () => {},
  handleAtivar = () => {},
  handleDesativar = () => {},
  handleDeletar = () => {},
  permissaoEditarConta,
  ativo,
  ...rest
}) => {
  const classes = useStyles();
  const record = useRecordContext(rest);

  const gerenciado = record ? record.record.id === 1 : false;

  const status = record ? record.record.status : '';
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {/* {status === STATUS_ATIVO && (
        <Tooltip title="Editar" placement="bottom" aria-label="add2">
          <IconButton
            aria-label="edit"
            classes={{ root: classes.root }}
            size="small"
            onClick={() => handleEdit(record.record)}
            disabled={gerenciado}
          >
            <EditOutlinedIcon />
          </IconButton>
        </Tooltip>
      )} */}
      {status === STATUS_INATIVO && (
        <Tooltip title="Ativar" placement="bottom" aria-label="ativar2">
          <IconButton
            aria-label="ativar"
            classes={{ root: classes.root }}
            size="small"
            onClick={() => handleAtivar(record.record)}
            disabled={gerenciado || !permissaoEditarConta}
          >
            <CheckCircleOutlineIcon />
          </IconButton>
        </Tooltip>
      )}
      {status === STATUS_ATIVO && (
        <Tooltip title="Desativar" placement="bottom" aria-label="desativar2">
          <IconButton
            aria-label="desativar"
            classes={{ root: classes.root }}
            size="small"
            onClick={() => handleDesativar(record.record)}
            disabled={gerenciado || !permissaoEditarConta}
          >
            <ClearIcon />
          </IconButton>
        </Tooltip>
      )}
      {status === STATUS_INATIVO && (
        <Tooltip title="Excluir" placement="bottom" aria-label="excluir2">
          <IconButton
            aria-label="excluir"
            classes={{ root: classes.root }}
            size="small"
            onClick={() => handleDeletar(record.record)}
            disabled={gerenciado || !permissaoEditarConta}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};



export default ActionsField;
