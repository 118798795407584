import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DetailsIcon from '@material-ui/icons/Details';
import GroupIcon from '@material-ui/icons/Group';

import { formatMoeda } from '../../../../../utils';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'block',
  },
  sectionDesktop: {
    display: 'flex',
    gap: '10px',
  },
}));

export default function PrimarySearchAppBar({
  disabledBack = false,
  disabledForward = false,
  onClick,
  CANCELAR_COMPRA_ACTION,
  FECHAR_COMPRA_ACTION,
}) {
  const classes = useStyles();

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar>
          <Tooltip title="Cancelar">
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              size="small"
              disabled={disabledBack}
              onClick={() => onClick(CANCELAR_COMPRA_ACTION)}
            >
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Prosseguir">
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              size="small"
              disabled={disabledForward}
              onClick={() => onClick(FECHAR_COMPRA_ACTION)}
            >
              <ArrowForwardIcon />
            </IconButton>
          </Tooltip>
          <Typography className={classes.title} variant="h6" noWrap />
          <div className={classes.grow}>IMPORTAÇÃO DO XML DA COMPRA</div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
