import React, { useState, useEffect } from 'react';
import {
  Box, makeStyles, Button, Paper,
} from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import MaterialIcon, { colorPalette } from 'material-icons-react';

const useStyles = makeStyles(() => ({
  avatar: {
    width: '28',
    height: '28',
  },
  btn: {
    borderRadius: '50px',
  },
  iconButton: {
    backgroundColor: 'rgb(218, 218, 218)',
    width: '36px',
    height: '36px',
    '&:hover': {
      backgroundColor: 'rgb(218, 218, 218)',
    },
  },
  iconButtonSelect: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    width: '36px',
    height: '36px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3);',
    },
  },
  iconButtonPopover: {
    width: '36px',
    height: '36px',
  },
}));

const icons = [
  'search',
  'home',
  'settings',
  'done',
  'account_circle',
  'info',
  'check_circle',
  'delete',
  'favorite',
  'face',
  'logout',
  'lock',
];

const CIRCLES_VISIBLE = 3;

const IconPicker = ({ onChange }) => {
  const classes = useStyles();
  return (
    <Box display="flex" flexWrap="wrap" width="250px" maxHeight="300px" style={{ overflowY: 'auto' }}>
      {
        icons.map((icon) => (
          <Box width="calc(100%/7)" display="flex" justifyContent="center" alignItems="center">
            <IconButton aria-label="delete" onClick={() => onChange(icon)} className={classes.iconButtonPopover}>
              <MaterialIcon icon={icon} size={20} />
            </IconButton>
          </Box>
        ))
    }
    </Box>
  );
};

const IconInput = ({ value, onChange }) => {
  const classes = useStyles({ color: 'red' });
  const [anchorEl, setAnchorEl] = useState(null);
  // const [value, onChange] = useState('logout');
  const [cont, setCont] = useState(0);

  useEffect(() => {
    setCont(cont + 1);
  }, [value]);

  function createVetor() {
    const vetor = [];
    for (let i = 0; i < CIRCLES_VISIBLE; i += 1) {
      vetor.push(i);
    }
    return vetor;
  }

  const vetorAux = createVetor();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover-icon' : undefined;

  const changeIconPopover = (newIcon) => {
    onChange(newIcon);
    handleClose();
  };

  const checkFirst = () => {
    for (let i = 0; i < CIRCLES_VISIBLE; i += 1) {
      if (value === icons[i]) {
        return false;
      }
    }
    return true;
  };

  const isCheckFirst = checkFirst();

  return (
    <>
      <Box display="flex" alignItems="center">
        <Box marginRight="10px">
          { cont % 2 === 0 ? (
            <IconButton
              aria-label="delete1"
              className={isCheckFirst ? classes.iconButtonSelect : classes.iconButton}
              onClick={() => onChange(icons[icons.length - 1])}
            >
              <MaterialIcon icon={isCheckFirst ? value : icons[icons.length - 1]} size={20} />
            </IconButton>
          ) : (
            <div>
              <IconButton
                aria-label="delete1"
                className={isCheckFirst ? classes.iconButtonSelect : classes.iconButton}
                onClick={() => onChange(icons[icons.length - 1])}
              >
                <MaterialIcon icon={isCheckFirst ? value : icons[icons.length - 1]} size={20} />
              </IconButton>
            </div>
          )}
        </Box>
        {
            vetorAux.map((item) => (
              <Box marginRight="10px">
                <IconButton
                  aria-label="delete"
                  onClick={() => onChange(icons[item])}
                  className={icons[item] === value ? classes.iconButtonSelect : classes.iconButton}
                >
                  <MaterialIcon icon={icons[item]} size={20} />
                </IconButton>
              </Box>
            ))
        }
        <Box>
          <Button
            variant="contained"
            className={classes.btn}
            onClick={handleClick}
          >
            Outros
          </Button>
        </Box>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classes.paper }}
      >
        <Paper style={{ padding: '10px' }}>
          <IconPicker value={value} onChange={(newIcon) => changeIconPopover(newIcon)} />
        </Paper>
      </Popover>
    </>
  );
};

export default IconInput;
