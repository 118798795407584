import React, {
  useState,
  useRef,
  useContext,
  forwardRef,
  useImperativeHandle,
} from 'react';

import {
  Box,
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import { DialogoCliente2 as DialogoCliente } from '../../../Components';
import { AutoCompleteRemoto } from '../../../Components/Inputs';
import { getListAllAPI } from '../../../services';
import {
  formatCPF,
  formatCNPJ,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  ATALHO_AVANCAR,
  ATALHO_VOLTAR,
  STATUS_ATIVO,
} from '../../../utils';

const useStyles = makeStyles((theme) => ({
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  textField: {},
  btnImprimir: {
    backgroundColor: theme.palette.secondary.main,
    opacity: 0.4,
  },
  header: {
    height: '17%',
  },
  body: {
    height: '83%',
  },
  footer: {
    height: '15vh',
    padding: '10px',
    paddingBottom: '20px',
  },
  textOverflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const colorHeader = SECONDARY_COLOR;
const NOVO_CLIENTE_ACTION = 'F9';
const EDITAR_CLIENTE_ACTION = 'F7';
const ESC_ACTION = 'Escape';

const DialogoConfirmacao = forwardRef(({ handleSalvar = () => {} }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const refDialogoCliente = useRef(null);
  const classes = useStyles();
  const [cliente, setCliente] = useState(null);

  const refNome = useRef(null);
  const refNomeFantasia = useRef(null);
  const refCpf = useRef(null);
  const refCnpj = useRef(null);

  useImperativeHandle(ref, () => ({
    handleOpen(cliente) {
      setOpen(true);
      setCliente(cliente);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleSalvarLocal = () => {
    if (cliente) {
      setOpen(false);
      handleSalvar(cliente);
      setCliente(null);
    } else {
      enqueueSnackbar(`${'É necessário especificar o cliente!'}`, {
        variant: 'error',
      });
    }
  };

  function createCliente() {
    if (refDialogoCliente.current) refDialogoCliente.current.handleCreate();
  }

  function editarCliente() {
    if (refDialogoCliente.current) {
      const idCliente = cliente ? cliente.id : 0;
      if (idCliente > 0) {
        refDialogoCliente.current.handleEdit(idCliente);
      }
    }
  }

  function handleActions(name) {
    switch (name) {
      case CANCELAR_VENDA_ACTION:
        handleClose();
        break;
      case FECHAR_VENDA_ACTION:
        handleSalvar();
        break;
      case NOVO_CLIENTE_ACTION:
        createCliente();
        break;
      case EDITAR_CLIENTE_ACTION:
        editarCliente();
        break;
      case ESC_ACTION:
        handleClose();
        break;
      default:
        break;
    }
  }

  async function updateCliente(idUpdate) {
    try {
      const data = await getListAllAPI(
        'clientes',
        ['id', 'asc'],
        { id: [idUpdate] },
        ['cidade.estado', 'categoria'],
      );
      if (data.data.length > 0) {
        setCliente(data.data[0]);
      } else {
        throw 'Este cliente não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            color: 'white',
            backgroundColor: SECONDARY_COLOR,
            textAlign: 'center',
          }}
        >
          CLIENTE
        </DialogTitle>
        <DialogContent>
          <Box padding="10px" className={classes.header}>
            <Box
              display="flex"
              css={{
                backgroundColor: 'white',
                opacity: '0.75',
                height: '100%',
                padding: '0px 10px',
                flexDirection: 'column',
              }}
            >
              <Box display="flex" alignItems="center" flex={1}>
                <AutoCompleteRemoto
                  ref={refNome}
                  id="cliente"
                  name="cliente"
                  label="Responsável"
                  value={cliente}
                  onChange={(value) => {
                    setCliente(value);
                  }}
                  handleKey={(keyCode, keyName) => {
                    handleActions(keyName);
                  }}
                  handleEnter={() => {
                    if (refNomeFantasia.current) {
                      refNomeFantasia.current.focus();
                      refNomeFantasia.current.select();
                    }
                  }}
                  resource="clientes"
                  nested={['cidade.estado', 'categoria']}
                  toString={false}
                  fullwidth
                  autoFocus
                  filters={{ status: STATUS_ATIVO, ids_excluso: [1] }}
                />
                <AutoCompleteRemoto
                  ref={refNomeFantasia}
                  id="cliente"
                  name="cliente"
                  label="Nome Fantasia"
                  value={cliente}
                  onChange={(value) => {
                    setCliente(value);
                  }}
                  handleKey={(keyCode, keyName) => {
                    handleActions(keyName);
                  }}
                  handleEnter={() => {
                    if (refCpf.current) {
                      refCpf.current.focus();
                      refCpf.current.select();
                    }
                  }}
                  resource="clientes"
                  campo="nome_fantasia"
                  nested={['cidade.estado', 'categoria']}
                  toString={false}
                  style={{ marginLeft: '10px' }}
                  fullwidth
                  filters={{ status: STATUS_ATIVO, ids_excluso: [1] }}
                />
              </Box>
              <Box display="flex" alignItems="center" flex={1}>
                <AutoCompleteRemoto
                  ref={refCpf}
                  id="cliente"
                  name="cliente"
                  label="CPF"
                  value={cliente}
                  onChange={(value) => {
                    setCliente(value);
                  }}
                  handleKey={(keyCode, keyName) => {
                    handleActions(keyName);
                  }}
                  handleEnter={() => {
                    if (refCnpj.current) {
                      refCnpj.current.focus();
                      refCnpj.current.select();
                    }
                  }}
                  resource="clientes"
                  campo="cpf"
                  campoPesquisa="cpf_search"
                  nested={['cidade.estado', 'categoria']}
                  toString={false}
                  fullwidth
                  filters={{ status: STATUS_ATIVO, ids_excluso: [1] }}
                />
                <AutoCompleteRemoto
                  ref={refCnpj}
                  id="cliente"
                  name="cliente"
                  label="CNPJ"
                  value={cliente}
                  onChange={(value) => {
                    setCliente(value);
                  }}
                  handleKey={(keyCode, keyName) => {
                    handleActions(keyName);
                  }}
                  handleEnter={() => {
                    if (refNome.current) {
                      refNome.current.focus();
                      refNome.current.select();
                    }
                  }}
                  resource="clientes"
                  campo="cnpj"
                  campoPesquisa="cnpj_search"
                  nested={['cidade.estado', 'categoria']}
                  toString={false}
                  style={{ marginLeft: '10px' }}
                  fullwidth
                  filters={{ status: STATUS_ATIVO, ids_excluso: [1] }}
                />
              </Box>
              {cliente && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flex={1}
                  style={{
                    border: `1px solid ${PRIMARY_COLOR}`,
                    marginLeft: '0px',
                    padding: '5px',
                    borderRadius: '5px',
                    marginBottom: '10px',
                  }}
                >
                  <Box
                    marginLeft="10px"
                    display="flex"
                    flexDirection="column"
                    flex={1}
                  >
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: colorHeader,
                          marginRight: '10px',
                        }}
                      >
                        Responsável:
                      </p>
                      {cliente.nome}
                    </Box>
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: colorHeader,
                          marginRight: '10px',
                        }}
                      >
                        Razão Social:
                      </p>
                      {cliente.razao_social}
                    </Box>
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: colorHeader,
                          marginRight: '10px',
                        }}
                      >
                        CPF:
                      </p>
                      {formatCPF(cliente.cpf)}
                    </Box>
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: colorHeader,
                          marginRight: '10px',
                        }}
                      >
                        CNPJ:
                      </p>
                      {formatCNPJ(cliente.cnpj)}
                    </Box>
                  </Box>
                  <Box
                    marginLeft="10px"
                    display="flex"
                    flexDirection="column"
                    flex={1}
                  >
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                      justifyContent="flex-start"
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: colorHeader,
                          marginRight: '10px',
                        }}
                      >
                        Nome Fantasia:
                      </p>
                      {cliente.nome_fantasia}
                    </Box>
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                      justifyContent="flex-start"
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: colorHeader,
                          marginRight: '10px',
                        }}
                      >
                        Bairro:
                      </p>
                      {cliente.bairro}
                    </Box>
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                      justifyContent="flex-start"
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: colorHeader,
                          marginRight: '10px',
                        }}
                      >
                        Cidade:
                      </p>
                      {cliente.cidade
                        ? `${cliente.cidade.nome} - ${cliente.cidade.estado.sigla}`
                        : ''}
                    </Box>
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                      justifyContent="flex-start"
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: colorHeader,
                          marginRight: '10px',
                        }}
                      >
                        Logradouro:
                      </p>
                      {cliente.logradouro}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Button
            onClick={handleClose}
            color="secondary"
            variant="contained"
            tabindex="-1"
          >
            {`Cancelar (${CANCELAR_VENDA_ACTION})`}
          </Button>
          {/*<Button
            color="secondary"
            variant="contained"
            onClick={createCliente}
            tabindex="-1"
          >
            {`Novo cliente (${NOVO_CLIENTE_ACTION})`}
                      </Button>*/}
          {/*<Button
            color="secondary"
            variant="contained"
            onClick={editarCliente}
            tabindex="-1"
          >
            {`Editar cliente (${EDITAR_CLIENTE_ACTION})`}
                    </Button>*/}
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSalvarLocal}
            tabindex="-1"
          >
            {`Concluir (${FECHAR_VENDA_ACTION})`}
          </Button>
        </DialogActions>
      </Dialog>
      <DialogoCliente
        ref={refDialogoCliente}
        handleClose={(idUpdate) => {
          if (idUpdate > 0) {
            updateCliente(idUpdate);
          }
        }}
      />
    </div>
  );
});

export default DialogoConfirmacao;
