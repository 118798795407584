import React, { useRef, useState, useCallback, useEffect } from 'react';
import {
  TextField,
  ReferenceField,
  ShowButton,
  NumberField,
  ListBase,
  ListToolbar,
  Pagination,
  FunctionField,
  useRefresh,
  Title,
  useListContext,
  Datagrid,
  DateField,
  ListContextProvider,
  BooleanField,
} from 'react-admin';
import { useHistory } from 'react-router-dom';

import { Card, Divider, Tabs, Tab } from '@material-ui/core';

import { DialogoFornecedor, DialogoConfirmacao } from '../../Components';
import { api, getUserId, getListAllAPI } from '../../services';
import { STATUS_ATIVO, STATUS_INATIVO } from '../../utils/constants';
import ActionsField from './ActionsField';
import Actions from './ActionsList';
import Filter from './Filter';

const tabs = [
  { id: 'ativos', name: 'Ativos' },
  { id: 'inativos', name: 'Inativos' },
];

const MyList = ({ ...props }) => {
  const refDialogoCompra = useRef(null);
  const refresh = useRefresh();
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const refDialogoConfirmacao = useRef(null);
  const [ativos, setAtivos] = useState([]);
  const [inativos, setInativos] = useState([]);
  const history = useHistory();
  const [permissaoEditarConta, setPermissaoEditarConta] = useState(false);

  function handleCriar() {
    history.push(`/contas/create`);
  }

  function handleEdit({ id }) {
    history.push(`/contas/${id}`);
  }

  function handleAtivar({ id }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Reativação de Conta',
        'Deseja realmente reativar este conta?',
        { id, codigo: 1 },
      );
    }
  }

  function handleDesativar({ id }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Desativação de Conta',
        'Deseja realmente desativar este conta?',
        { id, codigo: 2 },
      );
    }
  }

  function handleDeletar({ id }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Exclusão de Conta',
        'Deseja deletar permanentemente este conta?',
        { id, codigo: 3 },
      );
    }
  }

  async function ativar(id) {
    await api.put(`/contas/status/ativar/${id}`);
    handleClose();
  }

  async function desativar(id) {
    await api.put(`/contas/status/inativar/${id}`);
    handleClose();
  }

  async function deletar(id) {
    await api.put(`/contas/status/deletar/${id}`);
    handleClose();
  }

  async function handleConfirma({ id, codigo }) {
    switch (codigo) {
      case 1:
        ativar(id);
        break;
      case 2:
        desativar(id);
        break;
      case 3:
        deletar(id);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (ids && ids !== filterValues.status) {
      switch (filterValues.status) {
        case STATUS_ATIVO:
          setAtivos(ids);
          break;
        case STATUS_INATIVO:
          setInativos(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.status]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters(
          {
            ...filterValues,
            status: value === 'ativos' ? STATUS_ATIVO : STATUS_INATIVO,
          },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  const handleClose = () => {
    refresh();
  };

  async function getData() {
    const userId = getUserId();
    const data = await getListAllAPI('users', ['id', 'asc'], { id: [userId] }, [
      'cargo',
    ]);
    if (data.data.length > 0) {
      setPermissaoEditarConta(
        data.data[0].cargo.sistema_edit_conta ? true : false,
      );
    } else {
      setPermissaoEditarConta(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Title title="Contas" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions handleCriar={handleCriar} {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Divider />
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.status === STATUS_ATIVO ? tabs[0].id : tabs[1].id}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.status === STATUS_ATIVO && (
            <ListContextProvider value={{ ...listContext, ids: ativos }}>
              <Datagrid {...props}>
                <TextField source="nome" label="Descrição" />
                <NumberField
                  sortable={false}
                  source="saldoAtual"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                  label="Saldo"
                />
                <ActionsField
                  source="valor"
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  handleEdit={handleEdit}
                  handleAtivar={handleAtivar}
                  handleDesativar={handleDesativar}
                  handleDeletar={handleDeletar}
                  permissaoEditarConta={permissaoEditarConta}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.status === STATUS_INATIVO && (
            <ListContextProvider value={{ ...listContext, ids: inativos }}>
              <Datagrid {...props}>
                <TextField source="nome" label="Descrição" />
                {/*<NumberField
                  sortable={false}
                  source="valor"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                  label="Valor Total"
                />*/}
                <ActionsField
                  source="valor"
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  handleEdit={handleEdit}
                  handleAtivar={handleAtivar}
                  handleDesativar={handleDesativar}
                  handleDeletar={handleDeletar}
                  permissaoEditarConta={permissaoEditarConta}
                />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
        <TransacoesPagination />
      </Card>
      <DialogoFornecedor ref={refDialogoCompra} handleClose={handleClose} />
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={handleConfirma}
      />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Contas"
      filterDefaultValues={{
        status: STATUS_ATIVO,
        tipo: 'Despesa',
        periodo: { ano: new Date().getFullYear(), mes: new Date().getMonth() },
      }}
      component="div"
    />
  </>
);

export default List;
