import React, { useState, useEffect, useRef } from 'react';

import { Box, Card, CardContent } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { DialogoConfirmacao } from '../../../Components';
import { api, getListAPI, getUserId, createAPI } from '../../../services';
import { formatMoeda, SECONDARY_COLOR } from '../../../utils';

function formatHorario(data) {
  return moment(new Date(data)).format('DD/MM/YYYY - HH:mm');
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  rowHead: {
    color: 'white',
    fontWeight: 'bold',
  },
}));

function getColor(valor) {
  if (valor < 0)
    return {
      background: '#ffcccc',
      color: '#e60000',
    };

  return {
    background: '#ccffcd',
    color: '#00e604',
  };
}

const CardTotal = ({
  label,
  valor,
  style = {},
  isPreco = true,
  isColor = false,
}) => {
  const { background, color } = getColor(valor);
  return (
    <Card
      style={{
        borderRadius: '20px',
        cursor: 'pointer',
        flex: 1,
        backgroundColor: isColor ? background : 'white',
        color: isColor ? color : 'black',
        ...style,
      }}
    >
      <CardContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <div>
            <div
              style={{
                fontSize: '20px',
                marginBottom: '20px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {label}
            </div>
            <div
              style={{
                fontSize: '30px',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {isPreco ? `${formatMoeda(valor)}` : valor}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const ContaGerencial = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState(null);
  const [historico, setHistorico] = useState([]);
  const refDialogoConfirmacao = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);

  async function handleData() {
    setLoading(true);
    try {
      const dados = await api.get(`/malote_pacotes/conta_gerencial`);
      setDados(dados.data);
      setLoading(false);
    } catch (e) {
      setDados(null);
      setLoading(false);
      enqueueSnackbar(`Erro ao carregar os dados!`, {
        variant: 'error',
      });
    }
  }

  async function getHistorico() {
    const data = await getListAPI(
      'historico_conferencias_conta_gerencial',
      ['data_pagamento', 'desc'],
      [page + 1, rowsPerPage],
      {},
      ['user'],
    );
    setTotal(data.total);
    setHistorico(data.data);
  }

  async function createItemHistorico() {
    await createAPI('historico_conferencias_conta_gerencial', {
      user_id: getUserId(),
    });
    await getHistorico();
  }

  function confirmacaoConferencia() {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Registrar conferência da conta gerencial',
        'Deseja confirmar a criação dessa conferência da conta gerencial?',
        1,
      );
    }
  }

  async function getAllData() {
    await handleData();
    await getHistorico();
  }

  useEffect(() => {
    handleData();
    getHistorico();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Box
        style={{
          padding: '10px',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <IconButton
          aria-label="recarregar"
          size="small"
          onClick={() => getAllData()}
        >
          <ReplayIcon />
        </IconButton>
        <IconButton
          aria-label="registrar conferência"
          size="small"
          onClick={() => confirmacaoConferencia()}
        >
          <CheckIcon />
        </IconButton>
      </Box>
      <Box
        display="flex"
        style={{
          gap: '10px',
          marginBottom: '20px',
          marginTop: '30px',
          flexWrap: 'wrap',
        }}
      >
        {dados &&
          dados.contaGerencial.map((item) => (
            <CardTotal
              label={item.label}
              valor={item.valor}
              isColor={item.isColor}
            />
          ))}
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        style={{
          gap: '10px',
          marginBottom: '20px',
          marginTop: '30px',
          flexWrap: 'wrap',
        }}
      >
        <Box
          display="flex"
          style={{
            gap: '10px',
            marginBottom: '20px',
            flexWrap: 'wrap',
          }}
          flexDirection="column"
        >
          {dados &&
            dados.qtdePacotes.map((item) => (
              <CardTotal
                label={item.label}
                valor={item.valor}
                isPreco={false}
              />
            ))}
        </Box>
        <Box
          display="flex"
          style={{
            gap: '10px',
            marginBottom: '20px',
            flexWrap: 'wrap',
          }}
          flexDirection="column"
        >
          {dados &&
            dados.totalPacotes.map((item) => (
              <CardTotal
                label={item.label}
                valor={item.valor}
                style={{
                  width: '300px',
                }}
              />
            ))}
        </Box>
        <Box>
          <TableContainer component={Paper} className={classes.root}>
            <Table aria-label="collapsible table">
              <TableHead
                style={{
                  backgroundColor: SECONDARY_COLOR,
                  color: 'white',
                  fontWeight: 'bold',
                }}
              >
                <TableRow className={classes.rowHead}>
                  <TableCell className={classes.rowHead}>Horário</TableCell>
                  <TableCell className={classes.rowHead}>Responsável</TableCell>
                  <TableCell className={classes.rowHead}>Diferença</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {historico.map((historyRow) => (
                  <TableRow key={historyRow.nome}>
                    <TableCell component="th" scope="row">
                      {formatHorario(historyRow.data_pagamento)}
                    </TableCell>
                    <TableCell>{historyRow.user.username}</TableCell>
                    <TableCell>{formatMoeda(historyRow.valor)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage="Por página"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count !== -1 ? count : `more than ${to}`}`
              }
            />
          </TableContainer>
        </Box>
      </Box>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={createItemHistorico}
      />
    </>
  );
};

export default ContaGerencial;
