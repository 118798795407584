import React, { forwardRef } from 'react';

import { makeStyles } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

import AutoCompleteLocalCustomizado from '../AutoComplete/AutoCompleteLocalCustomizado';

const useStyles3 = makeStyles({
  chip: {
    borderColor: (props) => props.color,
    backgroundColor: 'white',
    cursor: 'default',
    color: '#8e8e8e',
    '&:hover': {
      color: (props) => props.color,
      backgroundColor: 'white',
    },
  },
  deleteIcon: {
    color: (props) => props.color,
    cursor: 'pointer',
    '&:hover': {
      color: (props) => props.color,
    },
  },
});

const ChipLocal = ({ option, injetado, campo }) => {
  const classes2 = useStyles3({ color: 'red' });
  const { onDelete, ...rest } = injetado;
  return (
    <>
      {option.id < 0 ? (
        <Chip
          style={{
            color: '#8e8e8e',
            borderColor: '#8e8e8e',
          }}
          variant="outlined"
          label={option[campo]}
          {...rest}
        />
      ) : (
        <Chip
          classes={{ outlined: classes2.chip, deleteIcon: classes2.deleteIcon }}
          variant="outlined"
          label={option[campo]}
          {...rest}
          onDelete={onDelete}
        />
      )}
    </>
  );
};

const LocaisInputs = forwardRef(
  (
    {
      options,
      campo = 'nome',
      label,
      handleEnter = () => {},
      handleKey = () => {},
      value,
      onChange,
      ...rest
    },
    ref,
  ) => {
    const handleOnChange = (value) => {
      if (value.length > 0) {
        if (value[0].id < 0) {
          onChange(value.slice(1));
        } else {
          onChange(value);
        }
      } else {
        onChange(value);
      }
    };

    const renderTag = (option, injetado) => (
      <ChipLocal option={option} injetado={injetado} campo={campo} />
    );

    const renderOption = (option) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {option[campo]}
      </div>
    );

    return (
      <AutoCompleteLocalCustomizado
        {...rest}
        ref={ref}
        name={label}
        handleEnter={handleEnter}
        label={label}
        handleKey={handleKey}
        value={value}
        disableClearable={value.length <= 0}
        onChangeLocal={handleOnChange}
        campo={campo}
        options={options}
        variant="outlined"
        renderTag={renderTag}
        renderOption={renderOption}
        style={{ marginTop: 0, marginBottom: 0 }}
      />
    );
  },
);

export default LocaisInputs;
