import React, { forwardRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';

const fontNormal = '10px';
// const fontTitulosGrandes = '12px';
const fontTitulosMedio = '12px';
const fontTitulosPequeno = '10px';

const useStyles = makeStyles({
  container: {
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  logoImg: {
    display: 'block',
    width: '250px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    fontSize: fontTitulosMedio,
  },
  row2: {
    display: 'flex',
    padding: '1px 1px 1px 1px',
  },
  font12: {
    fontSize: '12px',
  },
  rowTitle: {
    fontSize: fontNormal,
    textAlign: 'left',
    fontWeight: 'bold',
  },
  rowValue: {
    fontSize: fontNormal,
    textAlign: 'left',
  },
  cellHeaderProdutos: {
    flex: 1,
    textAlign: 'center',
    fontSize: fontNormal,
  },
  containerParcelas: {
    '& div': {
      border: '1px solid black',
      borderCollapse: 'collapse',
    },
  },
});

const tamanhoLinha = 10;

const getTamanhoEmLinhas = (nLinhas) => `${nLinhas * tamanhoLinha}px`;

const Nota = forwardRef((props, ref) => {
  const {
    venda: {
      numero: numeroVenda,
      data: dataVenda,
      totalValor,
      totalUnidades,
      totalPeso,
      // totalValorParcelas,
      parcelas,
      produtos,
      cliente: {
        razaoSocial: razaoSocialCliente,
        nomeFantasia: nomeFantasiaCliente,
        email,
        responsavel,
        logradouro,
        n,
        bairro,
        cep,
        fone,
        cnpj: cnpjCliente,
        cidade,
        // uf,
        cpf: cpfCliente,
      },
    },
    empresa: {
      cnpj: cnpjEmpresa,
      nomeFantasia: nomeFantasiaEmpresa,
      razaoSocial: razaoSocialEmpresa,
      logo,
      // email: emailEmpresa,
      // responsavel: responsavelEmpresa,
      logradouro: logradouroEmpresa,
      n: nEmpresa,
      bairro: bairroEmpresa,
      cep: cepEmpresa,
      fone: foneEmpresa,
      cidade: cidadeEmpresa,
      inscricao_estadual: IEEmpresa,
    },
    showItens,
    showParcelas,
    showDadosCliente,
    showHeader,
    showFooter,
    tipoTransacao,
  } = props;
  const classes = useStyles();
  return (
    <div className={classes.container} ref={ref}>
      { showHeader && (
      <div className={classes.header}>
        <div className={classes.row}>
          {logo && (
          <img
            src={logo}
            style={{ margin: '15px 0' }}
            className={classes.logoImg}
            alt="Logo da Empresa"
          />
          )}
        </div>
        <div className={classes.row} style={{ marginBottom: getTamanhoEmLinhas(1) }}>
          <div>
            {`${dataVenda} - Nº ${numeroVenda}`}
          </div>
        </div>
        <div className={classes.row}>
          <div style={{ textAlign: 'center', fontSize: fontTitulosPequeno }}>
            {`${logradouroEmpresa}, ${nEmpresa}, ${bairroEmpresa}, ${cidadeEmpresa} - PE, CEP ${cepEmpresa} `}
          </div>
        </div>
        <div className={classes.row} style={{ marginBottom: getTamanhoEmLinhas(1) }}>
          <div style={{ textAlign: 'center', fontSize: fontTitulosPequeno }}>
            {`FONE: ${foneEmpresa} `}
          </div>
        </div>
        <div className={classes.row} style={{ marginBottom: getTamanhoEmLinhas(1) }}>
          <div style={{ textAlign: 'center', fontSize: fontTitulosPequeno }}>
            Documento Auxiliar da Nota Fiscal do Consumidor Eletrônica
          </div>
        </div>
        <div className={classes.row} style={{ marginBottom: getTamanhoEmLinhas(2) }}>
          <div style={{ textAlign: 'center', fontSize: fontTitulosPequeno }}>
            Assinatura do Recebedor:
          </div>
        </div>
        <div className={classes.row} style={{ marginBottom: getTamanhoEmLinhas(1) }}>
          <div style={{ textAlign: 'center', fontSize: fontTitulosPequeno }}>
            X_________________________________________________
          </div>
        </div>
      </div>
      )}
      <div className={classes.body}>
        { showDadosCliente && (
        <div style={{ width: '100%', padding: '5px 0px' }}>
          <div style={{ textAlign: 'center', fontSize: fontTitulosMedio, marginBottom: '5px' }}>{`Dados do ${tipoTransacao === 'venda' ? 'cliente' : 'fornecedor'}`}</div>
          <div className={classes.row2}>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <div className={classes.rowTitle} style={{ flex: 1 }}>
                NOME/RAZÃO SOCIAL
              </div>
              <div className={classes.rowValue} style={{ flex: 1 }}>
                {razaoSocialCliente}
              </div>
              <div className={classes.rowTitle} style={{ flex: 1 }}>
                NOME FANTASIA
              </div>
              <div className={classes.rowValue} style={{ flex: 1 }}>
                {nomeFantasiaCliente}
              </div>
              <div className={classes.rowTitle} style={{ flex: 1 }}>
                NOME DO RESPONSÁVEL
              </div>
              <div className={classes.rowValue} style={{ flex: 1 }}>
                {responsavel}
              </div>
              <div className={classes.rowTitle} style={{ flex: 1 }}>
                LOGRADOURO
              </div>
              <div className={classes.rowValue} style={{ flex: 1 }}>
                {logradouro}
              </div>
              <div className={classes.rowTitle} style={{ flex: 1 }}>
                BAIRRO
              </div>
              <div className={classes.rowValue} style={{ flex: 1 }}>
                {bairro}
              </div>
              <div className={classes.rowTitle} style={{ flex: 1 }}>
                MUNICÍPIO
              </div>
              <div className={classes.rowValue} style={{ flex: 1 }}>
                {cidade}
              </div>
            </div>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <div className={classes.rowTitle} style={{ flex: 1 }}>
                CPF
              </div>
              <div className={classes.rowValue} style={{ flex: 1 }}>
                {cpfCliente}
              </div>
              <div className={classes.rowTitle} style={{ flex: 1 }}>
                EMAIL
              </div>
              <div className={classes.rowValue} style={{ flex: 1 }}>
                {email}
              </div>
              <div className={classes.rowTitle} style={{ flex: 1 }}>
                FONE
              </div>
              <div className={classes.rowValue} style={{ flex: 1 }}>
                {fone}
              </div>
              <div className={classes.rowTitle} style={{ flex: 1 }}>
                Nº
              </div>
              <div className={classes.rowValue} style={{ flex: 1 }}>
                {n}
              </div>
              <div className={classes.rowTitle} style={{ flex: 1 }}>
                CEP
              </div>
              <div className={classes.rowValue} style={{ flex: 1 }}>
                {cep}
              </div>
              <div className={classes.rowTitle} style={{ flex: 1 }}>
                CNPJ
              </div>
              <div className={classes.rowValue} style={{ flex: 1 }}>
                {cnpjCliente}
              </div>
            </div>
          </div>
        </div>
        )}
        { showItens && (
        <>
          {' '}

          <div style={{
            width: '100%', borderBottom: '1px solid black', marginBottom: '3px',
          }}
          >
            <div
              style={
              {
                textAlign: 'center',
                fontSize: fontTitulosMedio,
                borderTop: '1px solid black',
                borderBottom: '1px solid black',
                padding: '3px',
                fontWeight: 'bold',
              }
            }
            >
              DESCRIÇÃO DOS PRODUTOS
            </div>
            <div style={{ display: 'flex', padding: '5px', borderBottom: '1px solid black' }}>
              <div className={classes.cellHeaderProdutos}>Volumes</div>
              <div className={classes.cellHeaderProdutos}>Tipo</div>
              <div className={classes.cellHeaderProdutos}>Peso(Kg)</div>
              <div className={classes.cellHeaderProdutos}>R$ UN.</div>
              <div className={classes.cellHeaderProdutos}>R$ Subtotal</div>
            </div>
            <div style={{ paddingBottom: '5px' }}>
              {produtos.map((produto) => (
                <div>
                  <div
                    style={
                  {
                    fontSize: fontNormal,
                    marginBottom: '1px',
                    marginLeft: '2px',
                    marginTop: '0px',
                  }
                }
                  >
                    {produto.descricao}
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div className={classes.cellHeaderProdutos}>{produto.qtde}</div>
                    <div className={classes.cellHeaderProdutos}>{produto.tipo}</div>
                    <div className={classes.cellHeaderProdutos}>{produto.peso}</div>
                    <div className={classes.cellHeaderProdutos}>{`${produto.valorUnitario}`}</div>
                    <div className={classes.cellHeaderProdutos}>{`${produto.total}`}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div style={{
            display: 'flex', width: '100%', borderTop: '1px solid black', borderBottom: '1px solid black', marginBottom: '3px',
          }}
          >
            <div style={{
              flex: 2, textAlign: 'center', borderRight: '1px solid black', display: 'flex', flexDirection: 'column',
            }}
            >
              <div
                className={classes.rowValue}
                style={{
                  textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '2px 0px',
                }}
              >
                VOLUMES TOTAIS
              </div>
              <div
                className={classes.rowValue}
                style={{
                  flex: 1, textAlign: 'center', borderTop: '1px solid black', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '1px 0px',
                }}
              >
                {totalUnidades}
              </div>
            </div>
            <div style={{
              flex: 1, textAlign: 'center', borderRight: '1px solid black', display: 'flex', flexDirection: 'column',
            }}
            >
              <div
                className={classes.rowValue}
                style={{
                  textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '2px 0px',
                }}
              >
                Kg TOTAL
              </div>
              <div
                className={classes.rowValue}
                style={{
                  flex: 1, textAlign: 'center', borderTop: '1px solid black', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '1px 0px',
                }}
              >
                {totalPeso}
              </div>
            </div>
            <div style={{ flex: 2, textAlign: 'center' }}>
              <div
                className={classes.rowValue}
                style={{
                  textAlign: 'center', fontSize: fontTitulosMedio, fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '2px 0px',
                }}
              >
                R$ TOTAL
              </div>
              <div
                className={classes.rowValue}
                style={{
                  textAlign: 'center', fontSize: fontTitulosMedio, fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '1px 0px',
                }}
              >
                {`${totalValor}`}
              </div>
            </div>
          </div>
        </>
        )}
        { showParcelas
        && (
        <div style={{ width: '100%' }}>
          <div
            style={
              {
                textAlign: 'center',
                fontSize: fontTitulosMedio,
                borderTop: '1px solid black',
                borderBottom: '1px solid black',
                fontWeight: 'bold',
                padding: '3px 0px',
              }
            }
          >
            Formas de Pagamento
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
            <div
              style={{
                flex: 1.5, textAlign: 'center', padding: '1px 0px', borderRight: '1px solid black', display: 'flex', alignItems: 'center', justifyContent: 'center',
              }}
              className={classes.rowValue}
            >
              Tipo
            </div>
            <div
              style={{
                flex: 1.5, textAlign: 'center', padding: '1px 0px', borderRight: '1px solid black', display: 'flex', alignItems: 'center', justifyContent: 'center',
              }}
              className={classes.rowValue}
            >
              R$ Parcelas
            </div>
            <div
              style={{
                flex: 1.5, textAlign: 'center', padding: '1px 0px', display: 'flex', alignItems: 'center', justifyContent: 'center',
              }}
              className={classes.rowValue}
            >
              R$ Troco / Data
            </div>
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{
              flex: 1.5, textAlign: 'center', display: 'flex', flexDirection: 'column', borderRight: '1px solid black',
            }}
            >
              {parcelas.map((itemParcela) => <div style={{ flex: 1, borderTop: '1px solid black', padding: '0px 0px' }} className={classes.rowValue}>{itemParcela.tipoPagamento}</div>)}
            </div>
            <div style={{
              flex: 1.5, textAlign: 'center', display: 'flex', flexDirection: 'column', borderRight: '1px solid black',
            }}
            >
              {parcelas.map((itemParcela) => (
                <div
                  style={{
                    flex: 1, borderTop: '1px solid black', textAlign: 'center', padding: '0px 0px',
                  }}
                  className={classes.rowValue}
                >
                  {itemParcela.valor}
                </div>
              ))}

            </div>

            <div style={{
              flex: 1.5, textAlign: 'center', display: 'flex', flexDirection: 'column',
            }}
            >
              {parcelas.map((itemParcela) => (
                <div
                  style={{
                    flex: 1, borderTop: '1px solid black', padding: '0px 0px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                  }}
                  className={classes.rowValue}
                >
                  {itemParcela.troco !== '' ? itemParcela.troco : itemParcela.data}
                </div>
              ))}

            </div>
          </div>

          {/* <div style={{ display: 'flex', width: '100%' }}>
            <div style={{
              flex: 2, textAlign: 'center', display: 'flex', flexDirection: 'column', borderRight: '1px solid black',
            }}
            >
              <div style={{ flex: 1, borderTop: '1px solid black', padding: '0px 0px' }} className={classes.rowValue}>{parcelas[0].tipoPagamento}</div>
              <div style={{ flex: 1, borderTop: '1px solid black', padding: '0px 0px' }} className={classes.rowValue}>{parcelas[1].tipoPagamento}</div>
              <div style={{ flex: 1, borderTop: '1px solid black', padding: '0px 0px' }} className={classes.rowValue}>{parcelas[2].tipoPagamento}</div>
              <div style={{ flex: 1, borderTop: '1px solid black', padding: '0px 0px' }} className={classes.rowValue}>{parcelas[3].tipoPagamento}</div>
              <div style={{ flex: 1, borderTop: '1px solid black', padding: '0px 0px' }} className={classes.rowValue}>{parcelas[4].tipoPagamento}</div>
            </div>
            <div style={{
              flex: 1, textAlign: 'center', display: 'flex', flexDirection: 'column', borderRight: '1px solid black',
            }}
            >
              <div
                style={{
                  flex: 1, borderTop: '1px solid black', textAlign: 'center', padding: '0px 0px',
                }}
                className={classes.rowValue}
              >
                {parcelas[0].valor}
              </div>
              <div
                style={{
                  flex: 1, borderTop: '1px solid black', textAlign: 'center', padding: '0px 0px',
                }}
                className={classes.rowValue}
              >
                {parcelas[1].valor}
              </div>
              <div
                style={{
                  flex: 1, borderTop: '1px solid black', textAlign: 'center', padding: '0px 0px',
                }}
                className={classes.rowValue}
              >
                {parcelas[2].valor}
              </div>
              <div
                style={{
                  flex: 1, borderTop: '1px solid black', textAlign: 'center', padding: '0px 0px',
                }}
                className={classes.rowValue}
              >
                {parcelas[3].valor}
              </div>
              <div
                style={{
                  flex: 1, borderTop: '1px solid black', textAlign: 'center', padding: '0px 0px',
                }}
                className={classes.rowValue}
              >
                {parcelas[4].valor}
              </div>
            </div>
            <div style={{
              flex: 2, textAlign: 'center', display: 'flex', flexDirection: 'column',
            }}
            >
              <div
                style={{
                  flex: 1, borderTop: '1px solid black', textAlign: 'center', padding: '0px 0px',
                }}
                className={classes.rowValue}
              >
                {parcelas[0].troco}
              </div>
              <div
                style={{
                  flex: 1, borderTop: '1px solid black', padding: '0px 0px',
                }}
                className={classes.rowValue}
              />
              <div
                style={{
                  flex: 2, borderTop: '1px solid black', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: fontTitulosMedio, fontWeight: 'bold', padding: '0px 0px',
                }}
                className={classes.rowValue}
              >
                Data
              </div>
              <div
                style={{
                  flex: 1, borderTop: '1px solid black', textAlign: 'center', padding: '0px 0px',
                }}
                className={classes.rowValue}
              >
                {parcelas[4].data}
                {' '}
              </div>
            </div>
          </div> */}
        </div>
        )}
      </div>
      { showFooter
      && (
      <div className={classes.footer} style={{ marginTop: getTamanhoEmLinhas(1) }}>
        <div className={classes.row}>
          <div className={classes.rowValue}>
            {`Razão Social: ${razaoSocialEmpresa}`}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.rowValue}>
            {`Nome Fantasia: ${nomeFantasiaEmpresa}`}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.rowValue}>
            {`CNPJ: ${cnpjEmpresa}`}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.rowValue}>
            {`IE: ${IEEmpresa}`}
          </div>
        </div>
      </div>
      ) }
    </div>
  );
});

export default Nota;
