import React, { useState, useCallback, useEffect } from 'react';

import { Button, Box, Typography, makeStyles } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import useDebounce from '../../../hooks/useDebounce';

const useStyles = makeStyles({
  btn: {
    borderRadius: '50px',
    color: (props) => props.color,
    borderColor: (props) => props.color,
    '&:hover': {
      backgroundColor: (props) => props.color,
      color: 'white',
    },
  },
});

const meses = [
  {
    label: 'Janeiro',
    sigla: 'Jan',
  },
  {
    label: 'Fevereiro',
    sigla: 'Fev',
  },
  {
    label: 'Março',
    sigla: 'Mar',
  },
  {
    label: 'Abril',
    sigla: 'Abr',
  },
  {
    label: 'Maio',
    sigla: 'Mai',
  },
  {
    label: 'Junho',
    sigla: 'Jun',
  },
  {
    label: 'Julho',
    sigla: 'Jul',
  },
  {
    label: 'Agosto',
    sigla: 'Ago',
  },
  {
    label: 'Setembro',
    sigla: 'Set',
  },
  {
    label: 'Outubro',
    sigla: 'Out',
  },
  {
    label: 'Novembro',
    sigla: 'Nov',
  },
  {
    label: 'Dezembro',
    sigla: 'Dez',
  },
];

const DateMobillsInput = ({ color = '#59c3fa', handleAno = () => {} }) => {
  const [ano, setAno] = useState(new Date().getFullYear());
  const classes = useStyles({ color });
  const [anoDebounce] = useDebounce(ano, 1000);

  useEffect(() => {
    handleAno(anoDebounce);
  }, [anoDebounce]);

  const handleBefore = () => {
    setAno(ano - 1);
  };

  const handleNext = () => {
    setAno(ano + 1);
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="center">
        <IconButton
          aria-label="delete"
          onClick={handleBefore}
          style={{ color }}
        >
          <NavigateBeforeIcon />
        </IconButton>
        <Box marginLeft="10px" marginRight="10px">
          <Button
            variant="outlined"
            className={classes.btn}
            style={{
              width: '160px',
            }}
          >
            <span>{`${ano}`}</span>
          </Button>
        </Box>
        <IconButton aria-label="delete" onClick={handleNext} style={{ color }}>
          <NavigateNextIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default DateMobillsInput;
