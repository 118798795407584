import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Typography } from '@material-ui/core';

import {
  formatMoeda,
  POR_UNIDADE,
  TIPO_UNIDADE_OFERTA_KG,
  TIPO_UNIDADE_OFERTA_UN,
  TIPO_UNIDADE_OFERTA_CAIXA,
  TIPO_UNIDADE_OFERTA_CAIXETA,
  TIPO_UNIDADE_OFERTA_CAIXA_MAE,
  getLabelTipoUnidade,
} from '../../../utils';

function getQuantidade(oferta) {
  if (oferta.escalonadas) {
    if (oferta.escalonadas.length > 0) {
      return oferta.escalonadas[0].quantidade;
    }
    return 0;
  }
  return 0;
}

const QtdeMinimaField = (props) => {
  const record = useRecordContext(props);
  const valor = record ? (record.record ? getQuantidade(record.record) : 0) : 0;
  const labelUnidade = record
    ? record.record
      ? getLabelTipoUnidade(record.record.tipo_unidade)
      : ''
    : '';

  return (
    <Typography
      variant="subtitle1"
      style={
        {
          //fontWeight: 'bold',
        }
      }
    >
      {`${valor} (${labelUnidade})`}
    </Typography>
  );
};

export default QtdeMinimaField;
