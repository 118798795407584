import React, { useImperativeHandle, forwardRef, useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { api } from '../../../services';
import { formatPeso } from '../../../utils';
import { getEstoqueLabel } from '../../../utils/precoVenda';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const FormDialog = forwardRef((props, ref) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [linhas, setLinhas] = useState([]);
  const [venda, setVenda] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  async function getData(venda_id) {
    const data2 = await api.get(
      `/resumo_mercadorias_venda_cliente/${venda_id}`,
    );
    setLinhas(data2.data);
  }

  useImperativeHandle(ref, () => ({
    handleOpen(venda_id, venda) {
      setOpen(true);
      getData(venda_id);
      setVenda(venda);
    },
  }));

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullScreen
      >
        <DialogTitle id="form-dialog-title">{`Mercadorias Restantes Venda nº ${
          venda ? venda.numero : ''
        }`}</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Produto</StyledTableCell>
                  <StyledTableCell align="right">Unidades</StyledTableCell>
                  <StyledTableCell align="right">Peso (Kg)</StyledTableCell>
                  <StyledTableCell align="right">Estoque</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {linhas.map((row) => (
                  <StyledTableRow key={row.produto}>
                    <StyledTableCell>{row.produto.nome}</StyledTableCell>
                    <StyledTableCell align="right">
                      {row.unidades ? row.unidades : '-'}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.peso ? formatPeso(row.peso) : '-'}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {getEstoqueLabel(row.produto, row.unidades, row.peso)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default FormDialog;
