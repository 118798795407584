import React, { forwardRef, useState, useImperativeHandle } from 'react';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';

import {
  ceil10,
  formatMoeda,
  PRIMARY_COLOR,
  getTotalCompra,
  TIPO_PRECIFICACAO_NORMAL,
  TIPO_PRECIFICACAO_COMPRA,
  TIPO_PRECIFICACAO_LIBERACAO_PRECO,
  TIPO_PRECIFICACAO_OFERTA,
  TIPO_PRECIFICACAO_ZERAR_ESTOQUE,
} from '../../../utils';

function formatPorcentagem(valor) {
  return valor !== null && valor !== undefined
    ? `${valor.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} %`
    : '';
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 12,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  table: {
    //minWidth: 650,
  },
  paper: {
    height: 300,
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflow: 'auto',
  },
}));

function getTipoPrecificacao(value) {
  switch (value) {
    case TIPO_PRECIFICACAO_NORMAL:
      return 'Edição Normal';
    case TIPO_PRECIFICACAO_COMPRA:
      return 'Compra';
    case TIPO_PRECIFICACAO_LIBERACAO_PRECO:
      return 'Liberação de Preço';
    case TIPO_PRECIFICACAO_OFERTA:
      return 'Oferta Normal';
    case TIPO_PRECIFICACAO_ZERAR_ESTOQUE:
      return 'Oferta de Zerar Estoque';
    default:
      return '-';
  }
}

const Grafico = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [dados, setDados] = useState(null);

  const classes = useStyles({ cor: '#009FD4' });

  useImperativeHandle(ref, () => ({
    handleOpen(dados) {
      setOpen(true);
      setDados(dados);
    },
  }));

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      classes={{ paper: classes.rootDialogo }}
      className={classes.rootDialogo}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        id="form-dialog-title"
        onClose={handleCloseDialog}
        style={{ backgroundColor: PRIMARY_COLOR, color: 'white' }}
      >
        {`Detalhes Precificação - ${
          dados ? (dados.produto ? dados.produto.nome : '') : ''
        } (${getTipoPrecificacao(dados ? dados.tipo : '')}) - ${
          dados
            ? dados.updated_at
              ? moment(new Date(dados.updated_at)).format('DD/MM/YYYY - HH:mm')
              : ''
            : ''
        }`}
      </DialogTitle>
      <DialogContent>
        {dados ? (
          <Box>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                margin: '5px 0 15px',
                textAlign: 'center',
                color: 'red',
              }}
            >
              Geral
            </Typography>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Imposto</StyledTableCell>
                    <StyledTableCell align="left">Unitário</StyledTableCell>
                    <StyledTableCell align="left">Percentual</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow key="1">
                    <StyledTableCell align="left">
                      Valor Negociado
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(dados.valor_negociado_com_a_industria)}
                    </StyledTableCell>
                    <StyledTableCell align="left">-</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="2">
                    <StyledTableCell align="left">Custo Final</StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(dados.custo_final_do_produto)}
                    </StyledTableCell>
                    <StyledTableCell align="left">-</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="3">
                    <StyledTableCell align="left">
                      Preço de Venda
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(dados.preco_de_venda)}
                    </StyledTableCell>
                    <StyledTableCell align="left">-</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="4">
                    <StyledTableCell align="left">Lucro Bruto</StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(dados.lucro_bruto)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatPorcentagem(dados.taxa_lucro_bruto)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="7">
                    <StyledTableCell align="left">
                      Lucro Líquido
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(dados.lucro_liquido)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatPorcentagem(dados.taxa_lucro_liquido)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="5">
                    <StyledTableCell align="left">
                      Custo Operacional
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(dados.custo_operacional)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatPorcentagem(
                        (dados.custo_operacional * 100) / dados.preco_de_venda,
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="6">
                    <StyledTableCell align="left">
                      Custo com Cartão
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(dados.custo_de_cartao)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatPorcentagem(
                        (dados.custo_de_cartao * 100) / dados.preco_de_venda,
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                margin: '25px 0 15px',
                textAlign: 'center',
                color: 'red',
              }}
            >
              Repasses
            </Typography>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Imposto</StyledTableCell>
                    <StyledTableCell align="left">Unitário</StyledTableCell>
                    <StyledTableCell align="left">Percentual</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow key="1">
                    <StyledTableCell align="left">IPI</StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(dados.repasse_do_ipi)}
                    </StyledTableCell>
                    <StyledTableCell align="left">-</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="2">
                    <StyledTableCell align="left">Frete</StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(dados.repasse_do_frete)}
                    </StyledTableCell>
                    <StyledTableCell align="left">-</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="3">
                    <StyledTableCell align="left">ST</StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(dados.repasse_de_st)}
                    </StyledTableCell>
                    <StyledTableCell align="left">-</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="4">
                    <StyledTableCell align="left">Seguro</StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(dados.repasse_do_seguro)}
                    </StyledTableCell>
                    <StyledTableCell align="left">-</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="5">
                    <StyledTableCell align="left">
                      Outras Despesas
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(dados.repasse_de_outras_despesas)}
                    </StyledTableCell>
                    <StyledTableCell align="left">-</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                margin: '25px 0 15px',
                textAlign: 'center',
                color: 'red',
              }}
            >
              Impostos
            </Typography>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Imposto</StyledTableCell>
                    <StyledTableCell align="left">Unitário</StyledTableCell>
                    <StyledTableCell align="left">Percentual</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow key="1">
                    <StyledTableCell align="left">PIS Compra</StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(dados.pis_compra)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatPorcentagem(dados.taxa_pis_compra)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="2">
                    <StyledTableCell align="left">
                      COFINS Compra
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(dados.cofins_compra)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatPorcentagem(dados.taxa_cofins_compra)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="3">
                    <StyledTableCell align="left">ICMS Compra</StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(dados.icms_compra)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatPorcentagem(dados.taxa_icms_compra)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="4">
                    <StyledTableCell align="left">
                      ICMS Antecipação
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(dados.icms_antecipacao)}
                    </StyledTableCell>
                    <StyledTableCell align="left">-</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="5">
                    <StyledTableCell align="left">CSLL</StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(dados.csll)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatPorcentagem(dados.taxa_csll)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="6">
                    <StyledTableCell align="left">PIS Venda</StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(dados.pis_venda)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatPorcentagem(dados.taxa_pis_venda)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="7">
                    <StyledTableCell align="left">COFINS Venda</StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(dados.cofins_venda)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatPorcentagem(dados.taxa_cofins_venda)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="8">
                    <StyledTableCell align="left">ICMS Venda</StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(dados.icms_venda)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatPorcentagem(dados.taxa_icms_venda)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="9">
                    <StyledTableCell align="left">
                      ICMS de Fronteira
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(dados.icms_fronteira)}
                    </StyledTableCell>
                    <StyledTableCell align="left">-</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key="10">
                    <StyledTableCell align="left">IRPJ</StyledTableCell>
                    <StyledTableCell align="left">
                      {formatMoeda(dados.irpj)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatPorcentagem(dados.taxa_irpj)}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <div>Nada...</div>
        )}
      </DialogContent>
    </Dialog>
  );
});

export default Grafico;
