import React, { forwardRef } from 'react';
import InputMask from 'react-input-mask';

import TextField from '@material-ui/core/TextField';

export const TeclaInput = forwardRef((
  {
    value,
    onChange,
    handleEnter = () => {},
    handleKey = () => {},
    fullWidth,
    disabled = false,
    error = false,
    helperText = '',
    ...rest
  }, forwardedRef,
) => (
  <InputMask
    mask="**-**"
    value={value}
    onChange={onChange}
    disabled={disabled}
  >
    {() => (
      <TextField
        margin="normal"
        {...rest}
        error={error}
        helperText={helperText}
        inputRef={forwardedRef}
        onKeyDown={(e) => {
          if (e.keyCode === 13) handleEnter();
          else handleKey(e.keyCode, e.key);
        }}
        type="text"
        variant="outlined"
        fullWidth={fullWidth}
      />
    )}
  </InputMask>
));

export default TeclaInput;
