import React from 'react';

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import moment from 'moment';

import logo from '../../../../../assets/logo.png';
import { formatMoeda } from '../../../../../utils';

const styles = StyleSheet.create({
  page: {},
  body: {
    width: '100%',
    fontSize: '7',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    borderBottom: '1 solid black',
  },
  headerFinais: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    borderBottom: '1 solid black',
  },
  header2: {
    display: 'flex',
    backgroundColor: '#E4E4E4',
    color: 'black',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10',
    fontWeight: 'bold',
    borderBottom: '1 solid black',
    padding: '2px 0',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid black',
  },
  contentTotais: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid black',
    backgroundColor: '#ffff00',
  },
  contentTotaisTotais: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid black',
    backgroundColor: '#f96c25',
  },
  item: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  item3: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  negativo: {
    backgroundColor: '#ffc7cf',
  },
  positivo: {
    backgroundColor: '#cbf5d0',
  },
});

function TabelaMeioPagamento({ list, title, total, totalInput }) {
  return (
    <>
      <View
        style={[
          styles.title,
          { margin: '10 0', fontWeight: 'bold', fontSize: '12' },
        ]}
      >
        <Text style={{ marginTop: '15px' }}>{title}</Text>
      </View>

      <View style={styles.header}>
        <View style={[styles.item, { flex: 1 }]}>
          <Text>Horário</Text>
        </View>
        <View style={[styles.item, { flex: 1 }]}>
          <Text>Caixa</Text>
        </View>
        <View style={[styles.item, { flex: 1 }]}>
          <Text>Frentista</Text>
        </View>
        <View style={[styles.item, { flex: 1 }]}>
          <Text>nº Venda</Text>
        </View>
        <View style={[styles.item, { flex: 1 }]}>
          <Text>Valor</Text>
        </View>
      </View>

      {list.map((item) => (
        <View style={styles.content}>
          <View style={[styles.item3, { flex: 1 }]}>
            <Text>
              {moment(new Date(item.venda.data)).format('DD/MM/YYYY HH:mm')}
            </Text>
          </View>
          <View style={[styles.item3, { flex: 1 }]}>
            <Text>{item.venda.sessao.caixa.nome}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{item.venda.sessao.user.username}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{item.venda.numero}</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>{formatMoeda(item.valor)}</Text>
          </View>
        </View>
      ))}

      <View style={styles.headerFinais}>
        <View style={[styles.item, { flex: 1 }]}>
          <Text />
        </View>
        <View style={[styles.item, { flex: 1 }]}>
          <Text />
        </View>
        <View style={[styles.item, { flex: 1 }]}>
          <Text />
        </View>
        <View style={[styles.item, { flex: 1 }]}>
          <Text>Total Calculado:</Text>
        </View>
        <View style={[styles.item, { flex: 1 }]}>
          <Text>{formatMoeda(total)}</Text>
        </View>
      </View>

      <View style={styles.headerFinais}>
        <View style={[styles.item, { flex: 1 }]}>
          <Text />
        </View>
        <View style={[styles.item, { flex: 1 }]}>
          <Text />
        </View>
        <View style={[styles.item, { flex: 1 }]}>
          <Text />
        </View>
        <View style={[styles.item, { flex: 1 }]}>
          <Text>Total Entrada:</Text>
        </View>
        <View style={[styles.item, { flex: 1 }]}>
          <Text>{formatMoeda(totalInput)}</Text>
        </View>
      </View>

      <View style={styles.headerFinais}>
        <View style={[styles.item, { flex: 1 }]}>
          <Text />
        </View>
        <View style={[styles.item, { flex: 1 }]}>
          <Text />
        </View>
        <View style={[styles.item, { flex: 1 }]}>
          <Text />
        </View>
        <View style={[styles.item, { flex: 1 }]}>
          <Text>Diferença:</Text>
        </View>
        <View style={[styles.item, { flex: 1 }]}>
          <Text>{formatMoeda(total - totalInput)}</Text>
        </View>
      </View>
    </>
  );
}

function formatDate(data) {
  return moment(new Date(data)).format('DD/MM/YYYY');
}

function getTitle(periodo) {
  return `RELATÓRIO CARTÕES - (${formatDate(periodo.start)} - ${formatDate(
    periodo.end,
  )})`;
}

const ListaProdutos = ({ periodo, dados }) => {
  const {
    pixQrCodeTotal,
    pixQrCodeList,
    pixQrCodeInputValue,
    pixADistanciaTotal,
    pixADistanciaList,
    creditoTotal,
    creditoList,
    creditoInputValue,
    debitoTotal,
    debitoList,
    debitoInputValue,
    vonchuerTotal,
    vonchuerList,
    vonchuerInputValue,
  } = dados;
  return (
    <Document author="anderson" title={getTitle(periodo)}>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.body}>
          <View
            style={[
              styles.title,
              { margin: '10 0', fontWeight: 'bold', fontSize: '15' },
            ]}
          >
            <Image src={logo} style={{ width: '30%' }} />
            <Text style={{ marginTop: '15px' }}>{getTitle(periodo)}</Text>
          </View>
          <TabelaMeioPagamento
            title="Pix QrCode"
            list={pixQrCodeList}
            total={pixQrCodeTotal}
            totalInput={pixQrCodeInputValue}
          />
          <TabelaMeioPagamento
            title="Pix à Distância"
            list={pixADistanciaList}
            total={pixADistanciaTotal}
            totalInput={pixADistanciaTotal}
          />
          <TabelaMeioPagamento
            title="Débito"
            list={debitoList}
            total={debitoTotal}
            totalInput={debitoInputValue}
          />
          <TabelaMeioPagamento
            title="Crédito"
            list={creditoList}
            total={creditoTotal}
            totalInput={creditoInputValue}
          />
          <TabelaMeioPagamento
            title="Vonchuer"
            list={vonchuerList}
            total={vonchuerTotal}
            totalInput={vonchuerInputValue}
          />
        </View>
      </Page>
    </Document>
  );
};
export default ListaProdutos;
