import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useState,
} from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import {
  IntegerInput,
  DialogoConfirmacao,
  TextInput,
} from '../../../Components';
import { api, getListAllAPI, getUserId } from '../../../services';
import {
  formatMoeda,
  SECONDARY_COLOR,
  ATALHO_VOLTAR,
  ATALHO_AVANCAR,
} from '../../../utils';
import { formatarPacote } from '../../../utils/malote';

const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const PROXIMO_ACTION = ATALHO_AVANCAR;

const notasTrocoInit = {
  moedas_5_centavos: 0,
  moedas_10_centavos: 0,
  moedas_25_centavos: 0,
  moedas_50_centavos: 0,
  moedas_1_real: 0,
  cedulas_2_reais: 0,
  cedulas_5_reais: 0,
  cedulas_10_reais: 0,
  cedulas_20_reais: 0,
  cedulas_50_reais: 0,
  cedulas_100_reais: 0,
  cedulas_200_reais: 0,
};

const DialogoContadorCedulas = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = React.useState(false);
  const [notasParcelaCompra, setParcelaCompra] = useState(notasTrocoInit);
  const [notasMalote, setNotasMalote] = useState(notasTrocoInit);
  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState(-1);
  const [valorParcela, setValorParcela] = useState(0);
  const [idMalote, setMalote] = useState(-1);
  const refDialogoConfirmacao = useRef(null);
  const [codigo, setCodigo] = useState('');

  async function getParcela(id) {
    try {
      const data = await getListAllAPI(
        'transacoes',
        ['id', 'asc'],
        { id: [id] },
        [],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        setValorParcela(data.data[0].valor);
        setParcelaCompra(notasTrocoInit);
        // setNotasMalote(notasTrocoInit)
      } else {
        throw 'Esta parcela não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseLocal();
    }
  }

  async function getMalote() {
    try {
      const data = await getListAllAPI(
        'malote_malotes',
        ['id', 'asc'],
        { codigo },
        [],
      );
      if (data.data.length > 0) {
        setMalote(data.data[0].id);
        if (!data.data[0].visible) throw 'Este malote já está fechado!';
        const dataFormated = formatarPacote(data.data[0]);
        setNotasMalote(dataFormated.notas);
      } else {
        throw 'Este malote não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      setCodigo('');
      // handleCloseLocal();
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setId(id);
      getParcela(id);
      setOpen(true);
    },
  }));

  function handleCloseLocal() {
    setOpen(false);
  }

  const handleChangeNotasTroco = (field, value) => {
    setParcelaCompra({
      ...notasParcelaCompra,
      [field]: value,
    });
  };

  function proximaEtapa() {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Finalização pagamento da parcela de compra',
        'Deseja realmente finalizar o pagamento da parcela de compra?',
        {
          code: 1,
          data: null,
        },
      );
    }
  }

  function etapaAnterior() {
    handleCloseLocal();
  }

  async function salvarDados() {
    try {
      await api.put(`/malote_compras/parcela/${id}`, {
        notas: notasParcelaCompra,
        malote_id: idMalote,
        user_id: getUserId(),
      });
      handleCloseLocal();
      handleClose();
    } catch (e) {
      handleCloseLocal();
    }
  }

  async function handleConfirma({ code, data }) {
    switch (code) {
      case 1:
        salvarDados();
        break;
      default:
        break;
    }
  }

  function getTotalNotas(notas) {
    if (notas) {
      const total =
        formataValor(notas.moedas_5_centavos) * 0.05 +
        formataValor(notas.moedas_10_centavos) * 0.1 +
        formataValor(notas.moedas_25_centavos) * 0.25 +
        formataValor(notas.moedas_50_centavos) * 0.5 +
        formataValor(notas.moedas_1_real) * 1 +
        formataValor(notas.cedulas_2_reais) * 2 +
        formataValor(notas.cedulas_5_reais) * 5 +
        formataValor(notas.cedulas_10_reais) * 10 +
        formataValor(notas.cedulas_20_reais) * 20 +
        formataValor(notas.cedulas_50_reais) * 50 +
        formataValor(notas.cedulas_100_reais) * 100 +
        formataValor(notas.cedulas_200_reais) * 200;

      return total;
    }

    return 0;
  }

  const totalMalote = getTotalNotas(notasMalote);
  const totalParcela = getTotalNotas(notasParcelaCompra);

  const totalRestante = totalMalote - totalParcela;

  function getValueNota(field, objNotas) {
    if (objNotas) {
      return objNotas[field];
    }
    return 0;
  }

  function formataValor(valor) {
    return Number.isNaN(valor) ? 0 : valor;
  }

  const isValorErrado = totalParcela !== valorParcela;

  function getError(field) {
    let error = '';

    const valueTroco = formataValor(getValueNota(field, notasParcelaCompra));

    const valueSubmalote = formataValor(getValueNota(field, notasMalote));

    if (valueTroco > valueSubmalote) {
      error = '* Valor maior que o disponível.';
    }

    return error;
  }

  const erro_moedas_5_centavos = getError('moedas_5_centavos');
  const erro_moedas_10_centavos = getError('moedas_10_centavos');
  const erro_moedas_25_centavos = getError('moedas_25_centavos');
  const erro_moedas_50_centavos = getError('moedas_50_centavos');
  const erro_moedas_1_real = getError('moedas_1_real');
  const erro_cedulas_2_reais = getError('cedulas_2_reais');
  const erro_cedulas_5_reais = getError('cedulas_5_reais');
  const erro_cedulas_10_reais = getError('cedulas_10_reais');
  const erro_cedulas_20_reais = getError('cedulas_20_reais');
  const erro_cedulas_50_reais = getError('cedulas_50_reais');
  const erro_cedulas_100_reais = getError('cedulas_100_reais');
  const erro_cedulas_200_reais = getError('cedulas_200_reais');

  const isError =
    isValorErrado ||
    erro_moedas_5_centavos !== '' ||
    erro_moedas_10_centavos !== '' ||
    erro_moedas_25_centavos !== '' ||
    erro_moedas_50_centavos !== '' ||
    erro_moedas_1_real !== '' ||
    erro_cedulas_2_reais !== '' ||
    erro_cedulas_5_reais !== '' ||
    erro_cedulas_10_reais !== '' ||
    erro_cedulas_20_reais !== '' ||
    erro_cedulas_50_reais !== '' ||
    erro_cedulas_100_reais !== '' ||
    erro_cedulas_200_reais !== '';

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseLocal}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        scroll="paper"
        fullWidth
        maxWidth="md"
        fullScreen
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ color: 'white', backgroundColor: SECONDARY_COLOR }}
        >
          <div>{`Pagar parcela compra - ${formatMoeda(valorParcela)}`}</div>
        </DialogTitle>
        <DialogContent dividers>
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                    gap: '20px',
                  }}
                >
                  <div>
                    <span
                      style={{
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Valor Pago:
                    </span>
                    <span>{`${formatMoeda(totalParcela)}`}</span>
                  </div>
                  <div />
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '20px',
                    padding: '10px',
                    border: '1px solid black',
                    borderRadius: '10px',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '5px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 200,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <IntegerInput
                        handleEnter={() => {}}
                        label=""
                        handleKey={() => {}}
                        value={
                          Number.isNaN(
                            getValueNota(
                              'cedulas_200_reais',
                              notasParcelaCompra,
                            ),
                          )
                            ? ''
                            : String(
                                getValueNota(
                                  'cedulas_200_reais',
                                  notasParcelaCompra,
                                ),
                              )
                        }
                        onChange={(value) =>
                          handleChangeNotasTroco(
                            'cedulas_200_reais',
                            parseInt(value.target.value, 10),
                          )
                        }
                        fullWidth
                        style={{
                          margin: '0',
                          padding: '0',
                          width: '50px',
                        }}
                        styleInput={{
                          padding: '0',
                          textAlign: 'center',
                        }}
                      />
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          200 *
                            formataValor(
                              getValueNota(
                                'cedulas_200_reais',
                                notasParcelaCompra,
                              ),
                            ),
                        )}
                      </span>
                    </div>
                    {erro_cedulas_200_reais !== '' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}
                      >
                        {erro_cedulas_200_reais}
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 100,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <IntegerInput
                        handleEnter={() => {}}
                        label=""
                        handleKey={() => {}}
                        value={
                          Number.isNaN(
                            getValueNota(
                              'cedulas_100_reais',
                              notasParcelaCompra,
                            ),
                          )
                            ? ''
                            : String(
                                getValueNota(
                                  'cedulas_100_reais',
                                  notasParcelaCompra,
                                ),
                              )
                        }
                        onChange={(value) =>
                          handleChangeNotasTroco(
                            'cedulas_100_reais',
                            parseInt(value.target.value, 10),
                          )
                        }
                        fullWidth
                        style={{
                          margin: '0',
                          padding: '0',
                          width: '50px',
                        }}
                        styleInput={{
                          padding: '0',
                          textAlign: 'center',
                        }}
                      />
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          100 *
                            formataValor(
                              getValueNota(
                                'cedulas_100_reais',
                                notasParcelaCompra,
                              ),
                            ),
                        )}
                      </span>
                    </div>
                    {erro_cedulas_100_reais !== '' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}
                      >
                        {erro_cedulas_100_reais}
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 50,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <IntegerInput
                        handleEnter={() => {}}
                        label=""
                        handleKey={() => {}}
                        value={
                          Number.isNaN(
                            getValueNota(
                              'cedulas_50_reais',
                              notasParcelaCompra,
                            ),
                          )
                            ? ''
                            : String(
                                getValueNota(
                                  'cedulas_50_reais',
                                  notasParcelaCompra,
                                ),
                              )
                        }
                        onChange={(value) =>
                          handleChangeNotasTroco(
                            'cedulas_50_reais',
                            parseInt(value.target.value, 10),
                          )
                        }
                        fullWidth
                        style={{
                          margin: '0',
                          padding: '0',
                          width: '50px',
                        }}
                        styleInput={{
                          padding: '0',
                          textAlign: 'center',
                        }}
                      />
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          50 *
                            formataValor(
                              getValueNota(
                                'cedulas_50_reais',
                                notasParcelaCompra,
                              ),
                            ),
                        )}
                      </span>
                    </div>
                    {erro_cedulas_50_reais !== '' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}
                      >
                        {erro_cedulas_50_reais}
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 20,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <IntegerInput
                        handleEnter={() => {}}
                        label=""
                        handleKey={() => {}}
                        value={
                          Number.isNaN(
                            getValueNota(
                              'cedulas_20_reais',
                              notasParcelaCompra,
                            ),
                          )
                            ? ''
                            : String(
                                getValueNota(
                                  'cedulas_20_reais',
                                  notasParcelaCompra,
                                ),
                              )
                        }
                        onChange={(value) =>
                          handleChangeNotasTroco(
                            'cedulas_20_reais',
                            parseInt(value.target.value, 10),
                          )
                        }
                        fullWidth
                        style={{
                          margin: '0',
                          padding: '0',
                          width: '50px',
                        }}
                        styleInput={{
                          padding: '0',
                          textAlign: 'center',
                        }}
                      />
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          20 *
                            formataValor(
                              getValueNota(
                                'cedulas_20_reais',
                                notasParcelaCompra,
                              ),
                            ),
                        )}
                      </span>
                    </div>
                    {erro_cedulas_20_reais !== '' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}
                      >
                        {erro_cedulas_20_reais}
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 10,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <IntegerInput
                        handleEnter={() => {}}
                        label=""
                        handleKey={() => {}}
                        value={
                          Number.isNaN(
                            getValueNota(
                              'cedulas_10_reais',
                              notasParcelaCompra,
                            ),
                          )
                            ? ''
                            : String(
                                getValueNota(
                                  'cedulas_10_reais',
                                  notasParcelaCompra,
                                ),
                              )
                        }
                        onChange={(value) =>
                          handleChangeNotasTroco(
                            'cedulas_10_reais',
                            parseInt(value.target.value, 10),
                          )
                        }
                        fullWidth
                        style={{
                          margin: '0',
                          padding: '0',
                          width: '50px',
                        }}
                        styleInput={{
                          padding: '0',
                          textAlign: 'center',
                        }}
                      />
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          10 *
                            formataValor(
                              getValueNota(
                                'cedulas_10_reais',
                                notasParcelaCompra,
                              ),
                            ),
                        )}
                      </span>
                    </div>
                    {erro_cedulas_10_reais !== '' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}
                      >
                        {erro_cedulas_10_reais}
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 5,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <IntegerInput
                        handleEnter={() => {}}
                        label=""
                        handleKey={() => {}}
                        value={
                          Number.isNaN(
                            getValueNota('cedulas_5_reais', notasParcelaCompra),
                          )
                            ? ''
                            : String(
                                getValueNota(
                                  'cedulas_5_reais',
                                  notasParcelaCompra,
                                ),
                              )
                        }
                        onChange={(value) =>
                          handleChangeNotasTroco(
                            'cedulas_5_reais',
                            parseInt(value.target.value, 10),
                          )
                        }
                        fullWidth
                        style={{
                          margin: '0',
                          padding: '0',
                          width: '50px',
                        }}
                        styleInput={{
                          padding: '0',
                          textAlign: 'center',
                        }}
                      />
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          5 *
                            formataValor(
                              getValueNota(
                                'cedulas_5_reais',
                                notasParcelaCompra,
                              ),
                            ),
                        )}
                      </span>
                    </div>
                    {erro_cedulas_5_reais !== '' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}
                      >
                        {erro_cedulas_5_reais}
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 2,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <IntegerInput
                        handleEnter={() => {}}
                        label=""
                        handleKey={() => {}}
                        value={
                          Number.isNaN(
                            getValueNota('cedulas_2_reais', notasParcelaCompra),
                          )
                            ? ''
                            : String(
                                getValueNota(
                                  'cedulas_2_reais',
                                  notasParcelaCompra,
                                ),
                              )
                        }
                        onChange={(value) =>
                          handleChangeNotasTroco(
                            'cedulas_2_reais',
                            parseInt(value.target.value, 10),
                          )
                        }
                        fullWidth
                        style={{
                          margin: '0',
                          padding: '0',
                          width: '50px',
                        }}
                        styleInput={{
                          padding: '0',
                          textAlign: 'center',
                        }}
                      />
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          2 *
                            formataValor(
                              getValueNota(
                                'cedulas_2_reais',
                                notasParcelaCompra,
                              ),
                            ),
                        )}
                      </span>
                    </div>
                    {erro_cedulas_2_reais !== '' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}
                      >
                        {erro_cedulas_2_reais}
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '5px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 1,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <IntegerInput
                        handleEnter={() => {}}
                        label=""
                        handleKey={() => {}}
                        value={
                          Number.isNaN(
                            getValueNota('moedas_1_real', notasParcelaCompra),
                          )
                            ? ''
                            : String(
                                getValueNota(
                                  'moedas_1_real',
                                  notasParcelaCompra,
                                ),
                              )
                        }
                        onChange={(value) =>
                          handleChangeNotasTroco(
                            'moedas_1_real',
                            parseInt(value.target.value, 10),
                          )
                        }
                        fullWidth
                        style={{
                          margin: '0',
                          padding: '0',
                          width: '50px',
                        }}
                        styleInput={{
                          padding: '0',
                          textAlign: 'center',
                        }}
                      />
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          1 *
                            formataValor(
                              getValueNota('moedas_1_real', notasParcelaCompra),
                            ),
                        )}
                      </span>
                    </div>
                    {erro_moedas_1_real !== '' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}
                      >
                        {erro_moedas_1_real}
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 0,50
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <IntegerInput
                        handleEnter={() => {}}
                        label=""
                        handleKey={() => {}}
                        value={
                          Number.isNaN(
                            getValueNota(
                              'moedas_50_centavos',
                              notasParcelaCompra,
                            ),
                          )
                            ? ''
                            : String(
                                getValueNota(
                                  'moedas_50_centavos',
                                  notasParcelaCompra,
                                ),
                              )
                        }
                        onChange={(value) =>
                          handleChangeNotasTroco(
                            'moedas_50_centavos',
                            parseInt(value.target.value, 10),
                          )
                        }
                        fullWidth
                        style={{
                          margin: '0',
                          padding: '0',
                          width: '50px',
                        }}
                        styleInput={{
                          padding: '0',
                          textAlign: 'center',
                        }}
                      />
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          0.5 *
                            formataValor(
                              getValueNota(
                                'moedas_50_centavos',
                                notasParcelaCompra,
                              ),
                            ),
                        )}
                      </span>
                    </div>
                    {erro_moedas_50_centavos !== '' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}
                      >
                        {erro_moedas_50_centavos}
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 0,25
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <IntegerInput
                        handleEnter={() => {}}
                        label=""
                        handleKey={() => {}}
                        value={
                          Number.isNaN(
                            getValueNota(
                              'moedas_25_centavos',
                              notasParcelaCompra,
                            ),
                          )
                            ? ''
                            : String(
                                getValueNota(
                                  'moedas_25_centavos',
                                  notasParcelaCompra,
                                ),
                              )
                        }
                        onChange={(value) =>
                          handleChangeNotasTroco(
                            'moedas_25_centavos',
                            parseInt(value.target.value, 10),
                          )
                        }
                        fullWidth
                        style={{
                          margin: '0',
                          padding: '0',
                          width: '50px',
                        }}
                        styleInput={{
                          padding: '0',
                          textAlign: 'center',
                        }}
                      />
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          0.25 *
                            formataValor(
                              getValueNota(
                                'moedas_25_centavos',
                                notasParcelaCompra,
                              ),
                            ),
                        )}
                      </span>
                    </div>
                    {erro_moedas_25_centavos !== '' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}
                      >
                        {erro_moedas_25_centavos}
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 0,10
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <IntegerInput
                        handleEnter={() => {}}
                        label=""
                        handleKey={() => {}}
                        value={
                          Number.isNaN(
                            getValueNota(
                              'moedas_10_centavos',
                              notasParcelaCompra,
                            ),
                          )
                            ? ''
                            : String(
                                getValueNota(
                                  'moedas_10_centavos',
                                  notasParcelaCompra,
                                ),
                              )
                        }
                        onChange={(value) =>
                          handleChangeNotasTroco(
                            'moedas_10_centavos',
                            parseInt(value.target.value, 10),
                          )
                        }
                        fullWidth
                        style={{
                          margin: '0',
                          padding: '0',
                          width: '50px',
                        }}
                        styleInput={{
                          padding: '0',
                          textAlign: 'center',
                        }}
                      />
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          0.1 *
                            formataValor(
                              getValueNota(
                                'moedas_10_centavos',
                                notasParcelaCompra,
                              ),
                            ),
                        )}
                      </span>
                    </div>
                    {erro_moedas_10_centavos !== '' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}
                      >
                        {erro_moedas_10_centavos}
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 0,05
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <IntegerInput
                        handleEnter={() => {}}
                        label=""
                        handleKey={() => {}}
                        value={
                          Number.isNaN(
                            getValueNota(
                              'moedas_5_centavos',
                              notasParcelaCompra,
                            ),
                          )
                            ? ''
                            : String(
                                getValueNota(
                                  'moedas_5_centavos',
                                  notasParcelaCompra,
                                ),
                              )
                        }
                        onChange={(value) =>
                          handleChangeNotasTroco(
                            'moedas_5_centavos',
                            parseInt(value.target.value, 10),
                          )
                        }
                        fullWidth
                        style={{
                          margin: '0',
                          padding: '0',
                          width: '50px',
                        }}
                        styleInput={{
                          padding: '0',
                          textAlign: 'center',
                        }}
                      />
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          0.05 *
                            formataValor(
                              getValueNota(
                                'moedas_5_centavos',
                                notasParcelaCompra,
                              ),
                            ),
                        )}
                      </span>
                    </div>
                    {erro_moedas_5_centavos !== '' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}
                      >
                        {erro_moedas_5_centavos}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                  }}
                >
                  <div>
                    <span
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      Malote:{' '}
                    </span>
                    <span>{`${formatMoeda(totalMalote)}`}</span>
                  </div>
                  <div>
                    <span
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      Restante:{' '}
                    </span>
                    <span>{`${formatMoeda(totalRestante)}`}</span>
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    gap: '20px',
                    padding: '10px',
                    border: '1px solid black',
                    borderRadius: '10px',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '5px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 200,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {getValueNota('cedulas_200_reais', notasMalote)}
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          200 * getValueNota('cedulas_200_reais', notasMalote),
                        )}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 100,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {getValueNota('cedulas_100_reais', notasMalote)}
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          100 * getValueNota('cedulas_100_reais', notasMalote),
                        )}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 50,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {getValueNota('cedulas_50_reais', notasMalote)}
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          50 * getValueNota('cedulas_50_reais', notasMalote),
                        )}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 20,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {getValueNota('cedulas_20_reais', notasMalote)}
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          20 * getValueNota('cedulas_20_reais', notasMalote),
                        )}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 10,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {getValueNota('cedulas_10_reais', notasMalote)}
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          10 * getValueNota('cedulas_10_reais', notasMalote),
                        )}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 5,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {getValueNota('cedulas_5_reais', notasMalote)}
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          5 * getValueNota('cedulas_5_reais', notasMalote),
                        )}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 2,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {getValueNota('cedulas_2_reais', notasMalote)}
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          2 * getValueNota('cedulas_2_reais', notasMalote),
                        )}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '5px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 1,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {getValueNota('moedas_1_real', notasMalote)}
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          1 * getValueNota('moedas_1_real', notasMalote),
                        )}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 0,50
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {getValueNota('moedas_50_centavos', notasMalote)}
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          0.5 * getValueNota('moedas_50_centavos', notasMalote),
                        )}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 0,25
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {getValueNota('moedas_25_centavos', notasMalote)}
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          0.25 *
                            getValueNota('moedas_25_centavos', notasMalote),
                        )}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 0,10
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {getValueNota('moedas_10_centavos', notasMalote)}
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          0.1 * getValueNota('moedas_10_centavos', notasMalote),
                        )}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 0,05
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {getValueNota('moedas_5_centavos', notasMalote)}
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          0.05 *
                            getValueNota(
                              'moedas_5_centavos',
                              notasParcelaCompra,
                            ),
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <TextInput
            name="codigo"
            handleEnter={() => getMalote()}
            label="Código"
            handleKey={() => {}}
            value={codigo}
            onChange={(value) => setCodigo(value.target.value)}
            fullWidth
            autoFocus
            style={{
              marginTop: '40px',
            }}
          />
          {isValorErrado && (
            <div
              style={{
                color: 'red',
                marginTop: '50px',
                fontSize: '20px',
              }}
            >
              * O total das cédulas deve ser igual ao valor da parcela.
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px',
          }}
        >
          <Button
            onClick={etapaAnterior}
            color="secondary"
            variant="contained"
            tabindex="-1"
          >
            {`Fechar (${CANCELAR_VENDA_ACTION})`}
          </Button>
          <Button
            onClick={proximaEtapa}
            color="secondary"
            variant="contained"
            tabindex="-1"
            disabled={isError}
          >
            {`Finalizar (${PROXIMO_ACTION})`}
          </Button>
        </DialogActions>
        <DialogoConfirmacao
          ref={refDialogoConfirmacao}
          handleConfirma={handleConfirma}
        />
      </Dialog>
    </div>
  );
});

export default DialogoContadorCedulas;
