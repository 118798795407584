import React, { useRef } from 'react';
import {
  DateField,
  ReferenceField,
  TextField,
  ListBase,
  ListToolbar,
  Pagination,
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  Datagrid,
  useListContext,
} from 'react-admin';

import { Card } from '@material-ui/core';

import ActionsField from './ActionsField';
import Actions from './ActionsList';
import Dialogo from './ContainerDialogoVenda';
import DialogoDelete from './DialogoDelete';
import DialogoShow from './DialogoShow';
import Filter from './Filter';

const PostPanel = (props) => (
  <Show {...props} title="." actions={false}>
    <SimpleShowLayout>
      <ReferenceManyField
        addLabel={false}
        reference="vendas_parciais_itens"
        target="venda_parcial_id"
        pagination={<Pagination />}
      >
        <Datagrid>
          <ReferenceField
            label="Produto"
            source="produto_id"
            reference="produtos"
            sortable={false}
          >
            <TextField source="nome" />
          </ReferenceField>
          <TextField source="unidades" label="Unidades" />
          <TextField source="peso" label="Peso (Kg)" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

const MyList = ({ ...props }) => {
  const refDialogoCreate = useRef(null);
  const refDialogoDelete = useRef(null);
  const listContext = useListContext();
  const { filterValues } = listContext;
  const refDialogoShow = useRef(null);

  function handleDelete(record) {
    if (refDialogoDelete.current) refDialogoDelete.current.handleOpen(record);
  }

  function handleCriar() {
    if (refDialogoCreate.current) {
      const venda_id = filterValues.venda_id;
      refDialogoCreate.current.handleCreate(venda_id);
    }
  }

  function handleShow({ id }) {
    if (refDialogoShow.current) refDialogoShow.current.handleOpen(id);
  }

  return (
    <>
      <ListToolbar
        filters={props.filters}
        actions={<Actions handleCriar={handleCriar} {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid {...props}>
          <TextField source="numero" label="Nº" />
          <DateField source="data" label="Data da Retirada" showTime />
          <ReferenceField
            label="Cliente"
            source="cliente_id"
            reference="clientes"
          >
            <TextField source="nome" />
          </ReferenceField>
          <ReferenceField
            source="responsavel_id"
            reference="users"
            label="Responsável"
            allowEmpty={false}
            sortable={false}
          >
            <TextField source="username" />
          </ReferenceField>
          <ReferenceField
            source="conferente_id"
            reference="users"
            label="Conferente"
            allowEmpty={false}
            sortable={false}
          >
            <TextField source="username" />
          </ReferenceField>
          <ActionsField
            source="lalala"
            handleDelete={handleDelete}
            handleShow={handleShow}
            textAlign="right"
            label="Ações"
            sortable={false}
          />
        </Datagrid>
        <TransacoesPagination />
      </Card>
      <Dialogo ref={refDialogoCreate} />
      <DialogoDelete ref={refDialogoDelete} />
      <DialogoShow ref={refDialogoShow} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      expand={<PostPanel />}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Vendas Parciais"
      filterDefaultValues={{
        periodo: {
          start: new Date(),
          end: new Date(),
        },
      }}
    />
  </>
);

export default List2;
