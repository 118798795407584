import React from 'react';
import { useInput, InputHelperText, FieldTitle } from 'react-admin';
import DatePicker, { registerLocale } from 'react-datepicker';

import TextField from '@material-ui/core/TextField';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/locale';

registerLocale('ptBR', pt);

function range(inicio, termino) {
  const anos = [];
  for (let i = inicio; i <= termino; i++) {
    anos.push(i);
  }
  return anos;
}

const years = range(
  new Date().getFullYear() - 10,
  new Date().getFullYear() + 10,
);
const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

const PeriodoInput = ({
  value,
  onChange,
  variant = 'filled',
  margin = 'dense',
  name,
}) => {
  function getDateStart() {
    return value.start ? new Date(value.start) : new Date();
  }

  function getDateEnd() {
    return value.end ? new Date(value.end) : new Date();
  }

  function getYear(date) {
    return date.getFullYear();
  }

  function getMonth(date) {
    return date.getMonth();
  }

  const CustomInput = ({ value, onClick, label }) => (
    <TextField
      name={name}
      variant={variant}
      margin={margin}
      value={value}
      onClick={onClick}
      /* helperText={
        <InputHelperText
          touched={touched}
          error={error}
          helperText={helperText}
        />
      } */
      label={
        <FieldTitle
          label={label}
          /* source={source}
          resource={resource}
          isRequired={isRequired} */
        />
      }
      style={{ cursor: 'pointer', flex: 1 }}
    />
  );

  return (
    <div style={{ zIndex: '3000', display: 'flex' }}>
      <div style={{ marginRight: '15px' }}>
        <DatePicker
          withPortal
          // showWeekNumbers
          // fixedHeight
          // peekNextMonth
          // disabledKeyboardNavigation
          showMonthDropdown
          showYearDropdown
          todayButton="Hoje"
          dropdownMode="select"
          locale="ptBR"
          selected={getDateStart()}
          onChange={(date) =>
            onChange({
              target: {
                name,
                value: {
                  start: date,
                  end: getDateEnd(),
                },
              },
            })
          }
          maxDate={getDateEnd()}
          dateFormat="dd/MM/yyyy"
          selectsRange={false}
          customInput={<CustomInput label="De" />}
        />
      </div>
      <DatePicker
        withPortal
        // showWeekNumbers
        fixedHeight
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        todayButton="Hoje"
        dropdownMode="select"
        locale="ptBR"
        selected={getDateEnd()}
        onChange={(date) =>
          onChange({
            target: {
              name,
              value: {
                start: getDateStart(),
                end: date,
              },
            },
          })
        }
        minDate={getDateStart()}
        dateFormat="dd/MM/yyyy"
        selectsRange={false}
        customInput={<CustomInput label="Para" />}
      />
    </div>
  );
};

export default PeriodoInput;
