export const CONTA_DEFAULT = 'Conta Gerencial';

export const CLIENTE_DEFAULT = 'Anônimo';
export const FORNECEDOR_DEFAULT = 'Anônimo';
export const FORNECEDOR_IMPORTACAO = 'Importação';
export const TIPO_PAGAMENTO_DEFAULT = 'Dinheiro';

export const ID_CLIENTE_DEFAULT = 1;
export const ID_FORNECEDOR_DEFAULT = 1;
export const ID_FORNECEDOR_IMPORTACAO = 2;

export const A_VISTA = 'Á Vista';
export const A_PRAZO = 'Á Prazo';

export const PAGO = 'Pago';
export const PARCIALMENTE_PAGO = 'Parcialmente Pago';
export const NAO_PAGO = 'Não Pago';

export const POR_PESO_VARIAVEL = 'Por Peso Variável';
export const POR_PESO_FIXO = 'Por Peso Fixo';
export const POR_UNIDADE = 'Por Unidade';
export const POR_PESO_VARIAVEL_SEM_UNIDADE = 'Por Peso Variável Sem Unidade';

export const POR_ANO = 'Anual';
export const POR_MES = 'Mensal';
export const POR_SEMANA = 'Semanal';
export const POR_DIA = 'Diario';

export const TIPO_PAGAMENTO_DINHEIRO = 'Dinheiro';
export const TIPO_PAGAMENTO_FIADO = 'Fiado';
export const TIPO_PAGAMENTO_CARTAO_CREDITO = 'Cartão de Crédito';
export const TIPO_PAGAMENTO_CARTAO_DEBITO = 'Cartão de Débito';
export const TIPO_PAGAMENTO_TED_PIX = 'TED/PIX';
export const TIPO_PAGAMENTO_BOLETO_GERENCIANET = 'Boleto';

export const ID_TIPO_PAGAMENTO_DINHEIRO = 1;
export const ID_TIPO_PAGAMENTO_FIADO = 2;
export const ID_TIPO_PAGAMENTO_CARTAO_CREDITO = 3;
export const ID_TIPO_PAGAMENTO_CARTAO_DEBITO = 4;
export const ID_TIPO_PAGAMENTO_TED_PIX = 5;
export const ID_TIPO_PAGAMENTO_BOLETO_GERENCIANET = 6;

export const CONTA_GERENCIAL = 'Gerencial';
export const CONTA_BANCO_DO_BRASIL = 'Banco do Brasil';
export const CONTA_GERENCIANET = 'Gerencianet';
export const CONTA_SANTANDER = 'Santander';

export const ID_CONTA_GERENCIAL = 1;
export const ID_CONTA_GERENCIANET = 2;
export const ID_CONTA_SANTANDER = 3;
export const ID_CONTA_BANCO_DO_BRASIL = 4;

export const USER_GERENTE = 'Gerente';
export const USER_FRENTISTA = 'Frentista';

export const CATEGORIA_TRANSACAO_FORNECEDOR = 'Fornecedores';
export const CATEGORIA_TRANSACAO_AJUSTES = 'Ajustes';
export const CATEGORIA_TRANSACAO_PAGAMENTOS = 'Pagamentos';
export const CATEGORIA_TRANSACAO_VENDAS = 'Vendas';

export const TIPO_RECEITA = 'Receita';
export const TIPO_DESPESA = 'Despesa';

export const ATALHO_VOLTAR = 'F4';
export const ATALHO_AVANCAR = 'F8';
export const ATALHO_OPEN_MENU = 'insert';

export const PRIMARY_COLOR = process.env.REACT_APP_PRIMARY_COLOR;
export const SECONDARY_COLOR = process.env.REACT_APP_SECONDARY_COLOR;

export const URL_SERVIDOR_IMPRESSORA = process.env.REACT_APP_HOST_IMPRESSORA;

export const URL_SERVER_RESTART = process.env.REACT_APP_URL_SERVER_RESTART;

export const STATUS_ATIVO = 'ativo';
export const STATUS_INATIVO = 'inativo';
export const STATUS_DELETADO = 'deletado';

export const PIX_EMAIL_OPTION = 'pix_email_option';
export const PIX_TELEFONE_OPTION = 'pix_telefone_option';
export const PIX_ALEATORIA_OPTION = 'pix_aleatoria_option';
export const PIX_CPF_OPTION = 'pix_cpf_option';
export const PIX_CNPJ_OPTION = 'pix_cnpj_option';

export const MALOTE_MALOTE_OPTION = 'malote_malote';
export const MALOTE_SUBMALOTE_OPTION = 'malote_submalote';
export const MALOTE_SANGRIA_OPTION = 'malote_sangria';
export const MALOTE_TROCO_OPTION = 'malote_troco';

export const TIPO_FERIADO_FERIADO = 'feriado';
export const TIPO_FERIADO_DATA_COMEMORATIVA = 'data_comemorativa';
export const TIPO_FERIADO_DIA_COMUM = 'dia_comum';

export const UNIVERSO_FERIADO_NACIONAL = 'nacional';
export const UNIVERSO_FERIADO_ESTADUAL = 'estadual';
export const UNIVERSO_FERIADO_MUNICIPAL = 'municipal';
export const UNIVERSO_FERIADO_SINDICATO = 'sindicato';

export const TIPO_OFERTA_PRODUTO_JORNAL = 'tipo_oferta_produto_jornal';
export const TIPO_OFERTA_PRODUTO_ZERAR_ESTOQUE =
  'tipo_oferta_produto_zerar_estoque';
export const TIPO_OFERTA_PRODUTO_LIBERACAO = 'tipo_oferta_produto_liberacao';

export const TIPO_UNIDADE_OFERTA_KG = 'tipo_unidade_oferta_kg';
export const TIPO_UNIDADE_OFERTA_UN = 'tipo_unidade_oferta_un';
export const TIPO_UNIDADE_OFERTA_CAIXA = 'tipo_unidade_oferta_caixa';
export const TIPO_UNIDADE_OFERTA_CAIXETA = 'tipo_unidade_oferta_caixeta';
export const TIPO_UNIDADE_OFERTA_CAIXA_MAE = 'tipo_unidade_oferta_caixa_mae';

export const APP_VERSION = '183.0.0';
export const APP_VERSION_FUNCIONALIDADES = '';

export const ZERO_APROXIMADO = 0.0099;

export const TURNO_MANHA = 'turno_manha';
export const TURNO_NOITE = 'turno_noite';
export const TURNO_12_HORAS = 'turno_12_horas';

export const STATUS_LOJA_ABERTA = 'status_loja_aberta';
export const STATUS_LOJA_FECHADA = 'status_loja_fechada';

export const TIPO_HORARIO_NORMAL = 'tipo_horario_normal';
export const TIPO_HORARIO_DOMINGO = 'tipo_horario_domingo';

export const STATUS_ANDAMENTO_ATIVIDADE_NAO_VISUALIZADA =
  'status_andamento_atividade_nao_visualizada';
export const STATUS_ANDAMENTO_ATIVIDADE_VISUALIZADA =
  'status_andamento_atividade_visualizada';
export const STATUS_ANDAMENTO_ATIVIDADE_EM_ANDAMENTO =
  'status_andamento_atividade_em_andamento';
export const STATUS_ANDAMENTO_ATIVIDADE_FINALIZADA =
  'status_andamento_atividade_finalizada';
export const STATUS_ANDAMENTO_ATIVIDADE_PROBLEMATICA =
  'status_andamento_atividade_problematica';

export const TIPO_RECORRENCIA_NAO_RECORRENCIA =
  'tipo_recorrencia_nao_recorrencia';
export const TIPO_RECORRENCIA_DIARIA_NORMAL = 'tipo_recorrencia_diaria_normal';
export const TIPO_RECORRENCIA_SEMANAL_NORMAL =
  'tipo_recorrencia_semanal_normal';
export const TIPO_RECORRENCIA_MENSAL_NORMAL = 'tipo_recorrencia_mensal_normal';
export const TIPO_RECORRENCIA_ANUAL_NORMAL = 'tipo_recorrencia_anual_normal';

export const AVALIACAO_FEITO_COM_EXCELENCIA = 'avaliacao_feito_com_excelencia';
export const AVALIACAO_MALFEITO = 'avaliacao_malfeito';
export const AVALIACAO_NAO_REALIZADO = 'avaliacao_nao_realizado';

export const REGIME_TRIBUTARIO_MEI = 'regime_tributario_mei';
export const REGIME_TRIBUTARIO_SIMPLES_NACIONAL =
  'regime_tributario_simples_nacional';
export const REGIME_TRIBUTARIO_LUCRO_PRESUMIDO =
  'regime_tributario_lucro_presumido';
export const REGIME_TRIBUTARIO_LUCRO_REAL = 'regime_tributario_lucro_real';
export const REGIME_TRIBUTARIO_PRODUTOR_RURAL =
  'regime_tributario_produtor_rural';
export const REGIME_TRIBUTARIO_SEM_NFE = 'regime_tributario_sem_nfe';

export const TIPO_PRECIFICACAO_NORMAL = 'tipo_precificacao_normal';
export const TIPO_PRECIFICACAO_COMPRA = 'tipo_precificacao_compra';
export const TIPO_PRECIFICACAO_LIBERACAO_PRECO =
  'tipo_precificacao_liberacao_preco';
export const TIPO_PRECIFICACAO_OFERTA = 'tipo_precificacao_oferta';
export const TIPO_PRECIFICACAO_ZERAR_ESTOQUE =
  'tipo_precificacao_zerar_estoque';

export const TIPO_TRANSACAO_ESPECIAL_VERBA = 'tipo_transacao_especial_verba';

export const EVENTO_MANIFESTACAO_NFE_CONFIRMACAO_DA_OPERACAO =
  'evento_manifestacao_nfe_confirmacao_da_operacao';
export const EVENTO_MANIFESTACAO_NFE_CIENCIA_DA_OPERACAO =
  'evento_manifestacao_nfe_ciencia_da_operacao';
export const EVENTO_MANIFESTACAO_NFE_DESCONHECIMENTO_DA_OPERACAO =
  'evento_manifestacao_nfe_desconhecimento_da_operacao';
export const EVENTO_MANIFESTACAO_NFE_REGISTRO_DA_OPERACAO_NÃO_FINALIZADA =
  'evento_manifestacao_nfe_registro_da_operacao_nao_finalizada';

export const LOG_TASK_CALCULA_RELATORIO_CLIENTE =
  'log_task_calcula_relatorio_cliente';
export const LOG_TASK_CALCULA_RELATORIO_PRODUTO =
  'log_task_calcula_relatorio_produto';
export const LOG_TASK_RELATORIO_ANUAL = 'log_task_relatorio_anual';
export const LOG_TASK_RELATORIO_MENSAL = 'log_task_relatorio_mensal';

export const TIPO_IMPRESSAO_KG = 'tipo_impressao_kg';
export const TIPO_IMPRESSAO_UN = 'tipo_impressao_un';

export const stylesMalote = {
  statusHeader: {
    paddingLeft: '30px',
    flex: 1,
    backgroundColor: SECONDARY_COLOR,
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  statusCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingLeft: '30px',
  },
  statusContent: {
    flex: 5,
    marginLeft: '30px',
    maxWidth: '100px',
  },
  dataHeader: {
    flex: 1,
    backgroundColor: SECONDARY_COLOR,
    padding: '10px',
    color: 'white',
    fontWeight: 'bold',
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoHeader: {
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoCell: {
    flex: 2,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaHeader: {
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaCell: {
    flex: 2,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaHeader: {
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaCell: {
    flex: 2,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  actionsHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingRight: '100px',
  },
  actionsCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingRight: '100px',
  },
};
