import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import { formatMoeda } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '400px',
  },
}));

function getPreco(data) {
  const unitario = data.unidades_caixa_aparente
    ? data.preco_venda_caixa
    : data.unidades_caixa
    ? data.preco_venda_caixa
    : data.preco_venda;

  return formatMoeda(unitario);
}

const PrecoField = (props) => {
  const classes = useStyles();

  const record = useRecordContext(props);
  const value = record ? getPreco(record.record) : '';

  return (
    <Tooltip title={value} placement="bottom" aria-label="add2">
      <Typography className={classes.text}>{value}</Typography>
    </Tooltip>
  );
};

export default PrecoField;
