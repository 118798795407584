import React, { useRef, useState, useEffect, useContext } from 'react';

import { makeStyles, Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'notistack';

import {
  DialogoConfirmacao,
  Actions,
  AutoCompleteRemoto,
  AutoCompleteLocal,
  PrecoInput,
  NumeroNotaFiscalInput,
  DateInput,
  DialogoFornecedor,
  SerieNotaFiscalInput,
  ChaveAcessoNotaFiscalInput,
} from '../../../..';
import { getListAllAPI } from '../../../../../services';
import {
  formatCPF,
  formatCNPJ,
  formatMoeda,
  STATUS_ATIVO,
  toStringParcelaCompra,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  REGIME_TRIBUTARIO_MEI,
  REGIME_TRIBUTARIO_SIMPLES_NACIONAL,
  REGIME_TRIBUTARIO_LUCRO_PRESUMIDO,
  REGIME_TRIBUTARIO_LUCRO_REAL,
  REGIME_TRIBUTARIO_PRODUTOR_RURAL,
  REGIME_TRIBUTARIO_SEM_NFE,
} from '../../../../../utils';
import { CompraContext } from '../contextCompra';
import AppBar from './AppBar';
import DialogoDetalhes from './DialogoDetalhes';
//import ContainerNumeroNotaFiscalInput from '../FinalizarFrenteCaixaCompra/ContainerNumeroNotaFiscalInput';
const optionsLoja = [
  {
    nome: 'CARUARU FRIOS',
    value: 'CF',
  },
  {
    nome: 'CASA DA PIZZA',
    value: 'CP',
  },
];

const FECHAR_COMPRA_ACTION = 'F8';
const CANCELAR_COMPRA_ACTION = 'F4';
const EDITAR_FORNECEDOR_ACTION = 'F7';
const NOVO_FORNECEDOR_ACTION = 'F9';
const DETALHES_ACTION = 'DETALHES';

const useStyles = makeStyles(() => ({
  btn: {
    marginTop: '10px',
    opacity: '0.75',
  },
  footer: {
    height: '15vh',
    padding: '10px',
    paddingBottom: '20px',
  },
}));

function getTotalParcelas(parcelas) {
  let soma = 0;
  for (let i = 0; i < parcelas.length; i += 1) {
    soma += parcelas[i].valor;
  }
  return soma;
}

const optionsRegimeTributario = [
  {
    value: REGIME_TRIBUTARIO_PRODUTOR_RURAL,
    label: 'Produtor Rural',
  },
  {
    value: REGIME_TRIBUTARIO_MEI,
    label: 'MEI (Micro Empreendedor Individual)',
  },
  {
    value: REGIME_TRIBUTARIO_SIMPLES_NACIONAL,
    label: 'SIMPLES Nacional',
  },
  {
    value: REGIME_TRIBUTARIO_LUCRO_PRESUMIDO,
    label: 'Lucro Presumido',
  },
  {
    value: REGIME_TRIBUTARIO_LUCRO_REAL,
    label: 'Lucro Real',
  },
  {
    value: REGIME_TRIBUTARIO_SEM_NFE,
    label: 'Sem NFE',
  },
];

function getRegimeTributario(value) {
  switch (value) {
    case REGIME_TRIBUTARIO_PRODUTOR_RURAL:
      return optionsRegimeTributario[0].label;
    case REGIME_TRIBUTARIO_MEI:
      return optionsRegimeTributario[1].label;
    case REGIME_TRIBUTARIO_SIMPLES_NACIONAL:
      return optionsRegimeTributario[2].label;
    case REGIME_TRIBUTARIO_LUCRO_PRESUMIDO:
      return optionsRegimeTributario[3].label;
    case REGIME_TRIBUTARIO_LUCRO_REAL:
      return optionsRegimeTributario[4].label;
    case REGIME_TRIBUTARIO_SEM_NFE:
      return optionsRegimeTributario[5].label;
    default:
      return null;
  }
}

function quebrarChaveAcesso(chaveAcesso) {
  return {
    cuf: chaveAcesso.substring(0, 2),
    aamm: chaveAcesso.substring(2, 6),
    cnpj: chaveAcesso.substring(6, 20),
    mod: chaveAcesso.substring(20, 22),
    serie: chaveAcesso.substring(22, 25),
    nfe: chaveAcesso.substring(25, 34),
    tpemis: chaveAcesso.substring(34, 35),
    cnf: chaveAcesso.substring(35, 43),
    cdv: chaveAcesso.substring(43, 33),
  };
}

function PreInicio({ irParaTelaInit, irParaTelaFrentedeCaixa }) {
  const classes = useStyles();
  const refDialogoDetalhes = useRef(null);
  const refDialogoConfirmacao = useRef(null);
  const refDialogoFornecedor = useRef(null);
  const { compra, dispatch } = useContext(CompraContext);
  const {
    fornecedor,
    parcelas,
    lotes,
    idEdit,
    numeroNotaFiscal,
    loja,
    data_faturamento,
    data_criacao,
    //
    repasse_frete,
    repasse_ipi,
    repasse_outras_despesas,
    repasse_seguros,
    repasse_st,
    subTotal,
    //
    subTotalProdutos,
    total_repasse_frete,
    total_repasse_ipi,
    total_repasse_outras_despesas,
    total_repasse_seguros,
    total_repasse_st,
    serieNotaFiscal,
    chaveAcessoNotaFiscal,
    //isImportacaoXml,
    numeroCompra,
  } = compra;
  ///////////////////////////
  const [fornecedorLocal, setFornecedorLocal] = useState(fornecedor);
  const [numeroNotaFiscalLocal, setNumeroNotaFiscalLOcal] = useState(
    numeroNotaFiscal,
  );
  const [dataFaturamentoLocal, setDataFaturamentoLocal] = useState(
    data_faturamento,
  );
  const [lojaLocal, setLojaLocal] = useState(loja);
  const [repasseFreteLocal, setRepasseFreteLocal] = useState(repasse_frete);
  const [repasseIpiLocal, setRepasseIpiLocal] = useState(repasse_ipi);
  const [repasseOutrasDespesasLocal, setRepasseOutrasDespesasLocal] = useState(
    repasse_outras_despesas,
  );
  const [repasseSegurosLocal, setRepasseSegurosLocal] = useState(
    repasse_seguros,
  );
  const [repasseStLocal, setRepasseStLocal] = useState(repasse_st);

  const [serieNotaFiscallLocal, setSerieNotaFiscallLocal] = useState(
    serieNotaFiscal,
  );

  const [chaveAcessoNotaFiscalLocal, setChaveAcessoNotaFiscalLocal] = useState(
    chaveAcessoNotaFiscal,
  );

  const { enqueueSnackbar } = useSnackbar();
  ///////////////////////////
  function handleActions(action) {
    switch (action) {
      case FECHAR_COMPRA_ACTION: {
        if (fornecedorLocal.estado) {
          const {
            hasItemFrete,
            hasItemIPI,
            hasItemOutrasDespesas,
            hasItemST,
            hasItemSeguros,
          } = verificaExistenciaRepasses(lotes);

          dispatch({
            type: 'UPDATE_DADOS_TELA_INICIAL',
            fornecedor: fornecedorLocal,
            loja: lojaLocal,
            numeroNotaFiscal: numeroNotaFiscalLocal,
            data_faturamento: dataFaturamentoLocal,
            repasse_frete: hasItemFrete ? 0 : repasseFreteLocal,
            repasse_ipi: hasItemIPI ? 0 : repasseIpiLocal,
            repasse_outras_despesas: hasItemOutrasDespesas
              ? 0
              : repasseOutrasDespesasLocal,
            repasse_seguros: hasItemSeguros ? 0 : repasseSegurosLocal,
            repasse_st: hasItemST ? 0 : repasseStLocal,
            serieNotaFiscal: serieNotaFiscallLocal,
            chaveAcessoNotaFiscal: chaveAcessoNotaFiscalLocal,
          });
          irParaTelaFrentedeCaixa();
        } else {
          enqueueSnackbar(
            'Este fornecedor não tem o (Estado de Compra) registrado',
            {
              variant: 'error',
            },
          );
        }
        break;
      }
      case CANCELAR_COMPRA_ACTION:
        if (refDialogoConfirmacao.current) {
          refDialogoConfirmacao.current.handleOpen(
            'Cancelamento da compra',
            'Tem certeza que deseja cancelar essa compra!',
            CANCELAR_COMPRA_ACTION,
          );
        }
        break;
      case EDITAR_FORNECEDOR_ACTION:
        if (refDialogoFornecedor.current) {
          const idFornecedor = fornecedorLocal ? fornecedorLocal.id : 0;
          if (idFornecedor > 0) {
            refDialogoFornecedor.current.handleEdit(idFornecedor, 0);
          }
        }
        break;
      case NOVO_FORNECEDOR_ACTION:
        // if (refDialogoFornecedor.current) refDialogoFornecedor.current.handleOpen(0);
        if (refDialogoFornecedor.current)
          refDialogoFornecedor.current.handleCreate();
        break;
      case DETALHES_ACTION:
        if (refDialogoDetalhes.current) refDialogoDetalhes.current.handleOpen();
        break;
      default:
        break;
    }
  }

  function handleConfirma(codigo) {
    switch (codigo) {
      case CANCELAR_COMPRA_ACTION:
        irParaTelaInit();
        break;
      default:
        break;
    }
  }

  function gerLabelPrazosFornecedor(fornecedor) {
    let prazoLabel = 'Nenhum prazo cadastrado!';
    if (fornecedor) {
      if (fornecedor.prazosFornecedores) {
        if (fornecedor.prazosFornecedores.length > 0) {
          prazoLabel = fornecedor.prazosFornecedores
            .sort((a, b) => a.dias - b.dias)
            .map((item) => item.dias)
            .join(', ');
        }
      }
    }
    return prazoLabel;
  }

  function verificaExistenciaRepasses(lotes) {
    return {
      hasItemFrete: lotes.some((item) => {
        if (item.dadosPrecificacao) {
          if (item.dadosPrecificacao.repasse_do_frete > 0) {
            return true;
          }
          return false;
        }
        return false;
      }),
      hasItemIPI: lotes.some((item) => {
        if (item.dadosPrecificacao) {
          if (item.dadosPrecificacao.repasse_do_ipi > 0) {
            return true;
          }
          return false;
        }
        return false;
      }),
      hasItemST: lotes.some((item) => {
        if (item.dadosPrecificacao) {
          if (item.dadosPrecificacao.repasse_de_st > 0) {
            return true;
          }
          return false;
        }
        return false;
      }),
      hasItemSeguros: lotes.some((item) => {
        if (item.dadosPrecificacao) {
          if (item.dadosPrecificacao.repasse_do_seguro > 0) {
            return true;
          }
          return false;
        }
        return false;
      }),
      hasItemOutrasDespesas: lotes.some((item) => {
        if (item.dadosPrecificacao) {
          if (item.dadosPrecificacao.repasse_de_outras_despesas > 0) {
            return true;
          }
          return false;
        }
        return false;
      }),
    };
  }

  const {
    hasItemFrete,
    hasItemIPI,
    hasItemOutrasDespesas,
    hasItemST,
    hasItemSeguros,
  } = verificaExistenciaRepasses(lotes);

  const prazoLabel = gerLabelPrazosFornecedor(fornecedor);

  //////////

  function validateFornecedor() {
    let error = '';
    if (!fornecedorLocal) error = 'Este campo é obrigatório';
    return error;
  }

  function validateLoja() {
    let error = '';
    if (!lojaLocal) error = 'Este campo é obrigatório';
    return error;
  }

  function validateNumeroNotaFiscal() {
    let error = '';
    if (numeroNotaFiscalLocal !== '') {
      if (numeroNotaFiscalLocal.length < 9) {
        error = 'Número de nota fiscal em formato inválido!';
      }
    }
    return error;
  }

  function validateDataFaturamento() {
    let error = '';
    if (numeroNotaFiscalLocal !== '') {
      if (dataFaturamentoLocal === '') {
        error = 'Data faturamento inválida!';
      }
    }
    return error;
  }

  function validateRepasseFrete() {
    let error = '';
    if (!hasItemFrete) {
      if (repasseFreteLocal < 0 || Number.isNaN(repasseFreteLocal)) {
        error = 'Este campo é obrigatório';
      }
    }
    return error;
  }

  function validateRepasseIpi() {
    let error = '';
    if (!hasItemIPI) {
      if (repasseIpiLocal < 0 || Number.isNaN(repasseIpiLocal)) {
        error = 'Este campo é obrigatório';
      }
    }
    return error;
  }

  function validateRepasseOutrasDespesas() {
    let error = '';
    if (!hasItemOutrasDespesas) {
      if (
        repasseOutrasDespesasLocal < 0 ||
        Number.isNaN(repasseOutrasDespesasLocal)
      ) {
        error = 'Este campo é obrigatório';
      }
    }
    return error;
  }

  function validateRepasseSeguros() {
    let error = '';
    if (!hasItemSeguros) {
      if (repasseSegurosLocal < 0 || Number.isNaN(repasseSegurosLocal)) {
        error = 'Este campo é obrigatório';
      }
    }
    return error;
  }

  function validateRepasseSt() {
    let error = '';
    if (repasseStLocal < 0 || Number.isNaN(repasseStLocal)) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateSerieNotaFiscal() {
    let error = '';
    if (numeroNotaFiscalLocal.length === 9) {
      if (serieNotaFiscallLocal.length < 3) {
        error = 'Série de nota fiscal em formato inválido!';
      }
    }
    return error;
  }

  function validateChaveAcessoNotaFiscal() {
    let error = '';
    if (numeroNotaFiscalLocal.length === 9) {
      if (chaveAcessoNotaFiscalLocal.length < 44) {
        error = 'Chave de Acesso da nota fiscal em formato inválido!';
      } else {
        const { cnpj, serie, nfe } = quebrarChaveAcesso(
          chaveAcessoNotaFiscalLocal,
        );

        const cnpjFornecedor = fornecedorLocal ? fornecedorLocal.cnpj : '';

        /*if (cnpj.trim() !== cnpjFornecedor) {
          error =
            'O CNPJ desse fornecedor não é compatível com essa chave de acesso!';
        } else */ if (
          serie.trim() !== serieNotaFiscallLocal
        ) {
          error = 'A Série inserida não é compatível com a chave de acesso!';
        } else if (nfe.trim() !== numeroNotaFiscalLocal) {
          error =
            'O número da nota inserido não é compatível com a chave de acesso!';
        }
      }
    }
    return error;
  }

  function getErros() {
    const errosOld = ['', ''];
    errosOld[0] = validateFornecedor();
    errosOld[1] = validateLoja();
    errosOld[2] = validateNumeroNotaFiscal();
    errosOld[3] = validateDataFaturamento();
    errosOld[4] = validateRepasseFrete();
    errosOld[5] = validateRepasseIpi();
    errosOld[6] = validateRepasseOutrasDespesas();
    errosOld[7] = validateRepasseSeguros();
    errosOld[8] = validateRepasseSt();
    errosOld[9] = validateSerieNotaFiscal();
    errosOld[10] = validateChaveAcessoNotaFiscal();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  async function getFornecedor(id) {
    const data = await getListAllAPI('fornecedores', ['id', 'asc'], { id }, [
      'estado',
    ]);
    if (data.data.length <= 0) {
      throw 'Fornecedor não encontrado!';
    }
    setFornecedorLocal(data.data[0]);
  }

  const totalParcelas = getTotalParcelas(parcelas);

  const subTotal2 =
    subTotal -
    (repasse_frete +
      repasse_ipi +
      repasse_outras_despesas +
      repasse_seguros +
      repasse_st) +
    (repasseFreteLocal +
      repasseIpiLocal +
      repasseOutrasDespesasLocal +
      repasseSegurosLocal +
      repasseStLocal);

  function buildData(data) {
    const ano = parseInt(data.substring(0, 4), 10);
    const mes = parseInt(data.substring(5, 7), 10) - 1;
    const dia = parseInt(data.substring(8, 10), 10);

    const dataFormatada = new Date();
    dataFormatada.setFullYear(ano);
    dataFormatada.setDate(dia);
    dataFormatada.setMonth(mes);
    dataFormatada.setDate(dia);

    return dataFormatada;
  }

  return (
    <>
      <AppBar
        disabledBack={false}
        disabledForward={erroExistente}
        onClick={handleActions}
        FECHAR_COMPRA_ACTION={FECHAR_COMPRA_ACTION}
        CANCELAR_COMPRA_ACTION={CANCELAR_COMPRA_ACTION}
        EDITAR_FORNECEDOR_ACTION={EDITAR_FORNECEDOR_ACTION}
        NOVO_FORNECEDOR_ACTION={NOVO_FORNECEDOR_ACTION}
        idEdit={idEdit}
        subtotal={subTotal2}
        resto={subTotal2 - totalParcelas}
        totalParcelas={totalParcelas}
        DETALHES_ACTION={DETALHES_ACTION}
        //disabledFornecedor={isImportacaoXml}
        numeroCompra={numeroCompra}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        //padding="10px"
        className={classes.body}
        //style={{ padding: '10px 25%' }}
      >
        <Box
          //padding="0 10px"
          flex={4}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            //flexDirection: 'column',
            //gap: '10px',
          }}
        >
          <Box
            //elevation={3}
            display="flex"
            css={{
              backgroundColor: 'transparent',
              padding: '10px 10px',
              width: '50%',
              //backgroundClip: 'content-box, padding-box'
            }}
            //component="div"
          >
            <Box
              display="flex"
              css={{
                backgroundColor: 'white',
                opacity: '0.75',
                //height: '100%',
                padding: '0px 10px',
                flexDirection: 'column',
                width: '100%',
                //backgroundClip: 'content-box, padding-box'
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <h2
                  style={{
                    textAlign: 'center',
                    margin: '10px',
                  }}
                >
                  Fornecedor
                </h2>
                <Tooltip title="Editar Fornecedor">
                  <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="open drawer"
                    size="small"
                    disabled={
                      //isImportacaoXml
                      false
                        ? true
                        : fornecedorLocal === null
                        ? true
                        : fornecedorLocal.id === 1 || fornecedorLocal.id === 2
                    }
                    onClick={() => handleActions(EDITAR_FORNECEDOR_ACTION)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <Box
                display="flex"
                alignItems="center"
                flex={1}
                style={{
                  gap: '10px',
                }}
              >
                <AutoCompleteRemoto
                  id="fornecedor"
                  name="fornecedor"
                  label="Responsável"
                  value={fornecedorLocal}
                  onChange={(value) => {
                    //dispatch({ type: 'UPDATE_FORNECEDOR', fornecedor: value })
                    if (lotes.length <= 0) {
                      setFornecedorLocal(value);
                    } else {
                      enqueueSnackbar(
                        'Pra alterar o fornecedor, exclua todos os itens da compra!!!',
                        {
                          variant: 'error',
                        },
                      );
                    }
                  }}
                  handleKey={(keyCode, keyName) => {
                    handleActions(keyName);
                  }}
                  handleEnter={() => {}}
                  resource="fornecedores"
                  nested={['cidade.estado', 'prazosFornecedores', 'estado']}
                  toString={false}
                  style={{}}
                  fullwidth={false}
                  autoFocus
                  filters={{ status: STATUS_ATIVO, ids_excluso: [1, 2] }}
                  getOptionSelected={(option, value) =>
                    option.nome === value.nome
                  }
                  getOptionLabel={(option) =>
                    `${option.nome}${
                      option.nome_fantasia ? ` - (${option.nome_fantasia})` : ''
                    }`
                  }
                  disabled={lotes.length > 0 /*|| isImportacaoXml*/}
                />
                <AutoCompleteRemoto
                  id="fornecedor"
                  name="fornecedor"
                  label="Nome Fantasia"
                  value={fornecedorLocal}
                  onChange={(value) => {
                    //dispatch({ type: 'UPDATE_FORNECEDOR', fornecedor: value })
                    if (lotes.length <= 0) {
                      setFornecedorLocal(value);
                    } else {
                      enqueueSnackbar(
                        'Pra alterar o fornecedor, exclua todos os itens da compra!!!',
                        {
                          variant: 'error',
                        },
                      );
                    }
                  }}
                  handleKey={(keyCode, keyName) => {
                    handleActions(keyName);
                  }}
                  handleEnter={() => {}}
                  resource="fornecedores"
                  campo="nome_fantasia"
                  nested={['cidade.estado', 'prazosFornecedores', 'estado']}
                  toString={false}
                  style={{}}
                  fullwidth={false}
                  filters={{ status: STATUS_ATIVO, ids_excluso: [1, 2] }}
                  getOptionSelected={(option, value) =>
                    option.nome_fantasia === value.nome_fantasia
                  }
                  getOptionLabel={(option) =>
                    `${option.nome_fantasia ? `(${option.nome_fantasia})` : ''}`
                  }
                  disabled={lotes.length > 0 /*|| isImportacaoXml*/}
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                flex={1}
                style={{
                  gap: '10px',
                }}
              >
                <AutoCompleteRemoto
                  id="fornecedor"
                  name="fornecedor"
                  label="Razão Social"
                  value={fornecedorLocal}
                  onChange={(value) => {
                    //dispatch({ type: 'UPDATE_FORNECEDOR', fornecedor: value })
                    if (lotes.length <= 0) {
                      setFornecedorLocal(value);
                    } else {
                      enqueueSnackbar(
                        'Pra alterar o fornecedor, exclua todos os itens da compra!!!',
                        {
                          variant: 'error',
                        },
                      );
                    }
                  }}
                  handleKey={(keyCode, keyName) => {
                    handleActions(keyName);
                  }}
                  handleEnter={() => {}}
                  resource="fornecedores"
                  campo="razao_social"
                  nested={['cidade.estado', 'prazosFornecedores', 'estado']}
                  toString={false}
                  style={{}}
                  fullwidth={false}
                  filters={{ status: STATUS_ATIVO, ids_excluso: [1, 2] }}
                  getOptionSelected={(option, value) =>
                    option.razao_social === value.razao_social
                  }
                  getOptionLabel={(option) =>
                    `${option.razao_social ? `(${option.razao_social})` : ''}`
                  }
                  disabled={lotes.length > 0 /*|| isImportacaoXml*/}
                />
                <AutoCompleteRemoto
                  id="fornecedor"
                  name="fornecedor"
                  label="CNPJ"
                  value={fornecedorLocal}
                  onChange={(value) => {
                    //dispatch({ type: 'UPDATE_FORNECEDOR', fornecedor: value })
                    if (lotes.length <= 0) {
                      setFornecedorLocal(value);
                    } else {
                      enqueueSnackbar(
                        'Pra alterar o fornecedor, exclua todos os itens da compra!!!',
                        {
                          variant: 'error',
                        },
                      );
                    }
                  }}
                  handleKey={(keyCode, keyName) => {
                    handleActions(keyName);
                  }}
                  handleEnter={() => {}}
                  resource="fornecedores"
                  campo="cnpj"
                  nested={['cidade.estado', 'prazosFornecedores', 'estado']}
                  campoPesquisa="cnpj"
                  toString={false}
                  style={{}}
                  fullwidth={false}
                  filters={{ status: STATUS_ATIVO, ids_excluso: [1, 2] }}
                  getOptionSelected={(option, value) =>
                    option.cnpj === value.cnpj
                  }
                  getOptionLabel={(option) =>
                    `${option.cnpj ? `(${option.cnpj})` : ''}`
                  }
                  disabled={lotes.length > 0 /*|| isImportacaoXml*/}
                />
              </Box>
              {fornecedorLocal && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flex={1}
                  style={{
                    //border: `1px solid ${PRIMARY_COLOR}`,
                    marginLeft: '0px',
                    padding: '5px',
                    borderRadius: '5px',
                    marginBottom: '10px',
                  }}
                >
                  <Box
                    marginLeft="10px"
                    display="flex"
                    flexDirection="column"
                    flex={1}
                  >
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: PRIMARY_COLOR,
                          marginRight: '10px',
                        }}
                      >
                        Responsável:
                      </p>
                      {fornecedorLocal.nome}
                    </Box>
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: PRIMARY_COLOR,
                          marginRight: '10px',
                        }}
                      >
                        Razão Social:
                      </p>
                      {fornecedorLocal.razao_social}
                    </Box>
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: PRIMARY_COLOR,
                          marginRight: '10px',
                        }}
                      >
                        CPF:
                      </p>
                      {formatCPF(fornecedorLocal.cpf)}
                    </Box>
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: PRIMARY_COLOR,
                          marginRight: '10px',
                        }}
                      >
                        CNPJ:
                      </p>
                      {formatCNPJ(fornecedorLocal.cnpj)}
                    </Box>
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: PRIMARY_COLOR,
                          marginRight: '10px',
                        }}
                      >
                        Regime Tributário:
                      </p>
                      {getRegimeTributario(fornecedorLocal.regime_tributario)}
                    </Box>
                  </Box>
                  <Box
                    marginLeft="10px"
                    display="flex"
                    flexDirection="column"
                    flex={1}
                  >
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                      justifyContent="flex-start"
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: PRIMARY_COLOR,
                          marginRight: '10px',
                        }}
                      >
                        Nome Fantasia:
                      </p>
                      {fornecedorLocal.nome_fantasia}
                    </Box>

                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                      justifyContent="flex-start"
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: PRIMARY_COLOR,
                          marginRight: '10px',
                        }}
                      >
                        Estado da Compra:
                      </p>
                      {fornecedorLocal.estado
                        ? `${fornecedorLocal.estado.nome} (${fornecedorLocal.estado.sigla})`
                        : ''}
                    </Box>
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                      justifyContent="flex-start"
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: PRIMARY_COLOR,
                          marginRight: '10px',
                        }}
                      >
                        Prazos:
                      </p>
                      {prazoLabel}
                    </Box>
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                      justifyContent="flex-start"
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: PRIMARY_COLOR,
                          marginRight: '10px',
                        }}
                      >
                        ICMS de Compra:
                      </p>
                      {fornecedorLocal.estado
                        ? `${fornecedorLocal.estado.icms_ai_compra}%`
                        : ''}
                    </Box>
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                      justifyContent="flex-start"
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>

          <Box
            display="flex"
            css={{
              backgroundColor: 'transparent',
              padding: '10px 10px',
              width: '50%',
              //backgroundClip: 'content-box, padding-box'
            }}
          >
            <Box
              display="flex"
              css={{
                backgroundColor: 'white',
                opacity: '0.75',
                //height: '100%',
                padding: '0px 10px',
                flexDirection: 'column',
                width: '100%',
                //backgroundClip: 'content-box, padding-box'
              }}
            >
              <h2
                style={{
                  textAlign: 'center',
                  margin: '10px',
                }}
              >
                Repasses Não Unitários
              </h2>
              <Box
                display="flex"
                alignItems="center"
                flex={1}
                style={{
                  gap: '10px',
                }}
              >
                <PrecoInput
                  name="repasse_frete"
                  handleEnter={() => {}}
                  //label="Repasse Não Unitário de Frete"
                  label="Frete FOB"
                  handleKey={() => {}}
                  value={
                    Number.isNaN(repasseFreteLocal)
                      ? ''
                      : String(repasseFreteLocal)
                  }
                  onChange={(value) => {
                    const newValue = parseFloat(value.target.value);
                    setRepasseFreteLocal(newValue);
                  }}
                  error={erros[4] !== ''}
                  helperText={erros[4]}
                  fullWidth
                  disabled={hasItemFrete /*|| isImportacaoXml*/}
                />
                <PrecoInput
                  name="repasse_ipi"
                  handleEnter={() => {}}
                  //label="Repasse Não Unitário de IPI"
                  label="IPI"
                  handleKey={() => {}}
                  value={
                    Number.isNaN(repasseIpiLocal) ? '' : String(repasseIpiLocal)
                  }
                  onChange={(value) => {
                    const newValue = parseFloat(value.target.value);
                    setRepasseIpiLocal(newValue);
                  }}
                  error={erros[5] !== ''}
                  helperText={erros[5]}
                  fullWidth
                  disabled={hasItemIPI /*|| isImportacaoXml*/}
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                flex={1}
                style={{
                  gap: '10px',
                }}
              >
                <PrecoInput
                  name="repasse_st"
                  handleEnter={() => {}}
                  //label="Repasse Não Unitário de Substituição Tributária"
                  label="Substituição Tributária"
                  handleKey={() => {}}
                  value={
                    Number.isNaN(repasseStLocal) ? '' : String(repasseStLocal)
                  }
                  onChange={(value) => {
                    const newValue = parseFloat(value.target.value);
                    setRepasseStLocal(newValue);
                  }}
                  error={erros[8] !== ''}
                  helperText={erros[8]}
                  fullWidth
                  disabled={hasItemST /*|| isImportacaoXml*/}
                />
                <PrecoInput
                  name="repasse_seguros"
                  handleEnter={() => {}}
                  //label="Repasse Não Unitário de Seguros"
                  label="Seguros"
                  handleKey={() => {}}
                  value={
                    Number.isNaN(repasseSegurosLocal)
                      ? ''
                      : String(repasseSegurosLocal)
                  }
                  onChange={(value) => {
                    const newValue = parseFloat(value.target.value);
                    setRepasseSegurosLocal(newValue);
                  }}
                  error={erros[7] !== ''}
                  helperText={erros[7]}
                  fullWidth
                  disabled={hasItemSeguros /*|| isImportacaoXml*/}
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                flex={1}
                style={{
                  gap: '10px',
                }}
              >
                <div
                  style={{
                    flex: 1,
                  }}
                >
                  <PrecoInput
                    name="repasse_outras_despesas"
                    handleEnter={() => {}}
                    //label="Repasse Não Unitário de Outras Despesas"
                    label="Outras Despesas"
                    handleKey={() => {}}
                    value={
                      Number.isNaN(repasseOutrasDespesasLocal)
                        ? ''
                        : String(repasseOutrasDespesasLocal)
                    }
                    onChange={(value) => {
                      const newValue = parseFloat(value.target.value);
                      setRepasseOutrasDespesasLocal(newValue);
                    }}
                    error={erros[6] !== ''}
                    helperText={erros[6]}
                    fullWidth
                    disabled={hasItemOutrasDespesas /*|| isImportacaoXml*/}
                  />
                </div>
                <div
                  style={{
                    flex: 1,
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            css={{
              backgroundColor: 'transparent',
              padding: '10px 10px',
              width: '50%',
              //backgroundClip: 'content-box, padding-box'
            }}
          >
            <Box
              display="flex"
              css={{
                backgroundColor: 'white',
                opacity: '0.75',
                //height: '100%',
                padding: '0px 10px',
                flexDirection: 'column',
                width: '100%',
                //backgroundClip: 'content-box, padding-box'
              }}
            >
              <h2
                style={{
                  textAlign: 'center',
                  margin: '10px',
                }}
              >
                Fiscal
              </h2>
              <Box
                display="flex"
                alignItems="center"
                flex={1}
                style={{
                  gap: '10px',
                }}
              >
                <Box flex={1}>
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{
                      gap: '10px',
                    }}
                  >
                    <NumeroNotaFiscalInput
                      label="Número nota fiscal"
                      onChange={(value) =>
                        setNumeroNotaFiscalLOcal(value.target.value)
                      }
                      value={numeroNotaFiscalLocal}
                      handleEnter={() => {}}
                      handleKey={(keyCode, keyName) => {
                        handleActions(keyName);
                      }}
                      style={{
                        flex: 1,
                      }}
                      error={erros[2] !== ''}
                      helperText={erros[2]}
                      //disabled={isImportacaoXml}
                      //fullwidth
                      //style={{ width: '150px' }}
                    />
                  </Box>
                </Box>
                <Box flex={1}>
                  <AutoCompleteLocal
                    id="loja"
                    name="loja"
                    label="Loja"
                    options={optionsLoja}
                    value={lojaLocal}
                    onChangeLocal={(value) =>
                      //dispatch({ type: 'UPDATE_LOJA', loja: value })
                      setLojaLocal(value)
                    }
                    handleKey={(keyCode, keyName) => {
                      handleActions(keyName);
                    }}
                    handleEnter={() => {
                      //
                    }}
                    style={{}}
                    fullwidth
                    //disabled={isImportacaoXml}
                  />
                </Box>
              </Box>
              {numeroNotaFiscalLocal.length === 9 && (
                <Box
                  display="flex"
                  alignItems="center"
                  flex={1}
                  style={{
                    gap: '10px',
                  }}
                >
                  <Box flex={1}>
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{
                        gap: '10px',
                      }}
                    >
                      <SerieNotaFiscalInput
                        label="Série nota fiscal"
                        onChange={(value) =>
                          setSerieNotaFiscallLocal(value.target.value)
                        }
                        value={serieNotaFiscallLocal}
                        error={erros[9] !== ''}
                        helperText={erros[9]}
                        handleEnter={() => {}}
                        handleKey={(keyCode, keyName) => {
                          handleActions(keyName);
                        }}
                        style={{
                          flex: 1,
                        }}
                        //disabled={isImportacaoXml}
                        //fullwidth
                        //style={{ width: '150px' }}
                      />
                      <DateInput
                        name="data_faturamento"
                        handleEnter={() => {}}
                        label="Data Faturamento"
                        handleKey={() => {}}
                        value={dataFaturamentoLocal}
                        //fullwidth
                        // disabled={hasCaixa}
                        style={{
                          flex: 1,
                        }}
                        onChange={(e) => {
                          setDataFaturamentoLocal(e.target.value);
                        }}
                        error={erros[3] !== ''}
                        helperText={erros[3]}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
              {numeroNotaFiscalLocal.length === 9 && (
                <Box
                  display="flex"
                  alignItems="center"
                  flex={1}
                  style={{
                    gap: '10px',
                  }}
                >
                  <Box flex={1}>
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{
                        gap: '10px',
                      }}
                    >
                      <ChaveAcessoNotaFiscalInput
                        label="Chave de Acesso da nota fiscal"
                        onChange={(value) =>
                          setChaveAcessoNotaFiscalLocal(value.target.value)
                        }
                        value={chaveAcessoNotaFiscalLocal}
                        error={erros[10] !== ''}
                        helperText={erros[10]}
                        handleEnter={() => {}}
                        handleKey={(keyCode, keyName) => {
                          handleActions(keyName);
                        }}
                        style={{
                          flex: 1,
                        }}
                        //disabled={isImportacaoXml}
                        //fullwidth
                        //style={{ width: '150px' }}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={handleConfirma}
      />
      <DialogoDetalhes
        ref={refDialogoDetalhes}
        subTotalProdutos={subTotalProdutos}
        //total_repasse_frete={total_repasse_frete}
        //total_repasse_ipi={total_repasse_ipi}
        //total_repasse_outras_despesas={total_repasse_outras_despesas}
        //total_repasse_seguros={total_repasse_seguros}
        //total_repasse_st={total_repasse_st}
        repasse_frete={repasse_frete}
        repasse_ipi={repasse_ipi}
        repasse_outras_despesas={repasse_outras_despesas}
        repasse_seguros={repasse_seguros}
        repasse_st={repasse_st}
        subTotal={subTotal}
        lotes={lotes}
      />
      <DialogoFornecedor
        ref={refDialogoFornecedor}
        handleClose={(idUpdate) => {
          if (idUpdate > 0) {
            getFornecedor(idUpdate);
          }
        }}
      />
    </>
  );
}

export default PreInicio;
