import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from 'react';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import { SearchProduto, AutoCompleteRemoto } from '../../Components';
import DialogoCreateImpressao from '../../Components/Dialogos/DialogoProduto2/DialogoCreateImpressao';
import { getListAllAPI, api } from '../../services';
import {
  TIPO_OFERTA_PRODUTO_JORNAL,
  TIPO_OFERTA_PRODUTO_LIBERACAO,
  TIPO_OFERTA_PRODUTO_ZERAR_ESTOQUE,
  STATUS_ATIVO,
} from '../../utils';
import DialogoOfertaJornal from './DialogoOfertaJornal';
//import DialogoOfertaLiberacao from './DialogoOfertaLiberacao';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DialogoCategoriaTransacao = forwardRef(
  ({ handleSlavar, ofertas = [] }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [tipo, setTipo] = useState(TIPO_OFERTA_PRODUTO_JORNAL);
    const [produto, setProduto] = useState(null);

    const refDialogoOfertaJornal = useRef(null);

    const classes = useStyles();

    const handleCloseDialog = () => {
      setOpen(false);
    };

    useImperativeHandle(ref, () => ({
      handleCreate() {
        setOpen(true);
        setProduto(null);
        setTipo(TIPO_OFERTA_PRODUTO_JORNAL);
      },
      handleEdit(id, valor, produto_id) {
        setOpen(false);
        if (refDialogoOfertaJornal.current) {
          refDialogoOfertaJornal.current.handleEdit(id, valor, produto_id);
        }
      },
    }));

    async function verificaOfertaExistente(produto_id) {
      const status = ofertas.some((item) => item.produto.id === produto_id);
      if (status) {
        return {
          status: false,
          message: 'Já existe oferta deste item no jornal!!!',
        };
      }
      return { status: true };
    }

    function handleSalvar(produto) {
      setOpen(false);
      if (refDialogoOfertaJornal.current) {
        refDialogoOfertaJornal.current.handleCreate(produto);
      }
    }

    const nomeProduto = produto ? produto.nome : '';

    useEffect(() => {
      if (produto) {
        handleSalvar(produto);
      }
    }, [produto]);

    async function changeProduto(produto) {
      if (produto) {
        const { status, message } = await verificaOfertaExistente(produto.id);
        if (status) {
          if (!produto.permitir_criacao_ofertas) {
            enqueueSnackbar(
              'ESte produto foi travado pelo comprador para não ser utilizado em ofertas de jornal!',
              {
                variant: 'error',
              },
            );
          } else {
            setProduto(produto);
          }
        } else {
          setProduto(null);
          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      }
    }

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullWidth
          maxWidth="sm"
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{
              backgroundColor: '#00796b',
              color: 'white',
            }}
          >
            Definir Oferta
          </DialogTitle>
          <DialogContent
            style={{
              padding: '20px 36px 20px',
              margin: '0',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              <AutoCompleteRemoto
                name="produto"
                handleEnter={() => {}}
                label="Produto"
                handleKey={() => {}}
                value={produto}
                onChange={(value) => changeProduto(value)}
                fullWidth
                toString={false}
                getOptionSelected={(option, value) =>
                  option.nome === value.nome
                }
                getOptionLabel={(option) => `${option.nome}`}
                filters={{
                  status: STATUS_ATIVO,
                  //permitir_criacao_ofertas: true,
                }}
                resource="produtos/search/autocomplete"
                nested={['unidade']}
              />
              {produto && (
                <div>
                  <h2
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {nomeProduto}
                  </h2>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions
            style={{
              padding: '32px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={handleCloseDialog}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="contained"
              color="primary"
            >
              Cancelar
            </Button>
            <Box>
              <div className={classes.wrapper}>
                <Button
                  classes={{
                    root: classes.btnAtions,
                    disabled: classes.btnAtionsDisabled,
                  }}
                  variant="contained"
                  color="primary"
                  disabled={!produto}
                >
                  Prosseguir
                </Button>
              </div>
            </Box>
          </DialogActions>
          <DialogoOfertaJornal
            ref={refDialogoOfertaJornal}
            handleSlavar={handleSlavar}
          />
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
