import React from 'react';

import {
  withStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import {
  TIPO_UNIDADE_OFERTA_UN,
  TIPO_UNIDADE_OFERTA_KG,
  TIPO_UNIDADE_OFERTA_CAIXA,
  TIPO_UNIDADE_OFERTA_CAIXA_MAE,
  TIPO_UNIDADE_OFERTA_CAIXETA,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  POR_UNIDADE,
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL,
  formatPeso,
  formatMoeda,
  TIPO_OFERTA_PRODUTO_JORNAL,
  TIPO_OFERTA_PRODUTO_ZERAR_ESTOQUE,
  formatPreco,
} from '../../../../../../utils';
import {
  tipoStatusAndamentoAprovar,
  tipoStatusAndamentoAprovarVolta,
  tipoStatusAndamentoCriarPedido,
  tipoStatusAndamentoDetalhes,
} from '../../../utils/constants';

function formatPreco2(valor, casasDecimais = 2) {
  return valor !== null && valor !== undefined
    ? valor % 1 === 0
      ? valor
      : `${valor.toLocaleString('de-DE', {
          minimumFractionDigits: casasDecimais,
          maximumFractionDigits: casasDecimais,
        })}`
    : '';
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    //backgroundColor: 'green',
    color: 'black',
    //padding: '8px',
    //whiteSpace: 'nowrap',
    //writingMode: 'sideways-lr',
    //textOrientation: 'sideways',
    //width: 'min-content',
    width: '1px',
    whiteSpace: 'nowrap',
    //textAlign: 'center',
    //display: 'flex',
    //justifyContent: 'center',
    padding: '2px',
    border: '1px solid black',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    padding: '2px',
    border: '1px solid black',
    textAlign: 'center',
    verticalAlign: 'middle',
    //textAlign: 'center',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {},
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  paper: {
    height: 300,
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflow: 'auto',
  },
}));

export default function Tabela({
  isMargemCartao,
  tipoUnidadeOferta,
  produto,
  changeTipoCaixaria = () => {},
  precoVendaNormalSemVerba,
  precoVendaJornalOferta,
  precoVendaZerarEstoque,
  precoVendaComMargemDeLbZero,
  verbaAplicadaPorUnidadeDeProduto,
  precoVendaLiberadoMaisVerbaIncluida,
  tipoCaixarias,
  quantidadeLiberada,
  precoVendaComMargemDeLbZero2,
  precoCompra,
  precoMinimo,
  tipoStatusAndamento,
}) {
  let unidades_caixa_nivel_1 = '';
  let unidades_caixa_nivel_2 = '';
  let unidades_caixa_nivel_3 = '';
  let peso_medio = '';

  if (produto) {
    if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
      if (produto.peso_caixa_nivel_1 > 0)
        unidades_caixa_nivel_1 = `(${formatPeso(produto.peso_caixa_nivel_1)})`;
      if (produto.unidades_caixa_nivel_2 > 0)
        unidades_caixa_nivel_2 = `(${
          produto.unidades_caixa_nivel_2
        } Cx1 = ${formatPeso(
          produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2,
        )})`;
      if (produto.unidades_caixa_nivel_3 > 0)
        unidades_caixa_nivel_3 = `(${
          produto.unidades_caixa_nivel_3
        } Cx2 = ${formatPeso(
          produto.peso_caixa_nivel_1 *
            produto.unidades_caixa_nivel_2 *
            produto.unidades_caixa_nivel_3,
        )})`;
    } else if (produto.unidade.tipo === POR_UNIDADE) {
      if (produto.unidades_caixa_nivel_1 > 0)
        unidades_caixa_nivel_1 = `(${produto.unidades_caixa_nivel_1} Un)`;
      if (produto.unidades_caixa_nivel_2 > 0)
        unidades_caixa_nivel_2 = `(${produto.unidades_caixa_nivel_2} Cx1 = ${
          produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2
        } Un)`;
      if (produto.unidades_caixa_nivel_3 > 0)
        unidades_caixa_nivel_3 = `(${produto.unidades_caixa_nivel_3} Cx2 = ${
          produto.unidades_caixa_nivel_1 *
          produto.unidades_caixa_nivel_2 *
          produto.unidades_caixa_nivel_3
        } Un)`;
    } else if (produto.unidade.tipo === POR_PESO_FIXO) {
      peso_medio = `(${formatPeso(produto.peso_medio)})`;
      if (produto.unidades_caixa_nivel_1 > 0)
        unidades_caixa_nivel_1 = `(${
          produto.unidades_caixa_nivel_1
        } Un = ${formatPeso(
          produto.unidades_caixa_nivel_1 * produto.peso_medio,
        )})`;
      if (produto.unidades_caixa_nivel_2 > 0)
        unidades_caixa_nivel_2 = `(${produto.unidades_caixa_nivel_2} Cx1 = ${
          produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2
        } Un = ${formatPeso(
          produto.unidades_caixa_nivel_1 *
            produto.unidades_caixa_nivel_2 *
            produto.peso_medio,
        )})`;
      if (produto.unidades_caixa_nivel_3 > 0)
        unidades_caixa_nivel_3 = `(${produto.unidades_caixa_nivel_3} Cx2 = ${
          produto.unidades_caixa_nivel_1 *
          produto.unidades_caixa_nivel_2 *
          produto.unidades_caixa_nivel_3
        } Un = ${formatPeso(
          produto.unidades_caixa_nivel_1 *
            produto.unidades_caixa_nivel_2 *
            produto.unidades_caixa_nivel_3 *
            produto.peso_medio,
        )})`;
    }
  }

  return (
    <TableContainer
      component={Paper}
      /* style={{
        opacity: '0.75',
        height: '100%',
        width: '100%',
        overflow: 'auto',
      }} */
    >
      <Table aria-label="a dense table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">Forma de Pagamento</StyledTableCell>
            <StyledTableCell
              align="left"
              style={!isMargemCartao ? { backgroundColor: 'yellow' } : {}}
            >
              Cartão
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={isMargemCartao ? { backgroundColor: 'yellow' } : {}}
            >
              À Vista
            </StyledTableCell>
            <StyledTableCell align="left" />
            <StyledTableCell align="left" />
            <StyledTableCell align="left" />
          </TableRow>
          <TableRow>
            <StyledTableCell align="left">Descrição do KPI</StyledTableCell>
            <StyledTableCell
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_UN ||
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_KG
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {`Unidade ${peso_medio}`}
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXA
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {`Caixaria Nível 1 ${unidades_caixa_nivel_1}`}
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXETA
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {`Caixaria Nível 2 ${unidades_caixa_nivel_2}`}
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXA_MAE
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {`Caixaria Nível 3 ${unidades_caixa_nivel_3}`}
            </StyledTableCell>
            <StyledTableCell align="left">Totais</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow key="tipo_caixaria">
            <StyledTableCell component="th" scope="row">
              {tipoCaixarias.descicao_do_kpi}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_UN ||
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_KG
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
              onClick={() => {
                if (tipoStatusAndamento === tipoStatusAndamentoCriarPedido) {
                  if (produto) {
                    if (
                      produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
                    ) {
                      changeTipoCaixaria(TIPO_UNIDADE_OFERTA_KG);
                    } else {
                      changeTipoCaixaria(TIPO_UNIDADE_OFERTA_UN);
                    }
                  }
                }
              }}
            >
              {tipoCaixarias.unidade}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXA
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
              onClick={() => {
                if (tipoStatusAndamento === tipoStatusAndamentoCriarPedido) {
                  if (produto) {
                    if (
                      produto.unidades_caixa_nivel_1 > 0 ||
                      produto.peso_caixa_nivel_1 > 0
                    ) {
                      changeTipoCaixaria(TIPO_UNIDADE_OFERTA_CAIXA);
                    }
                  }
                }
              }}
            >
              {tipoCaixarias.caixaria_nivel_1}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXETA
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
              onClick={() => {
                if (tipoStatusAndamento === tipoStatusAndamentoCriarPedido) {
                  if (produto) {
                    if (produto.unidades_caixa_nivel_2 > 0) {
                      changeTipoCaixaria(TIPO_UNIDADE_OFERTA_CAIXETA);
                    }
                  }
                }
              }}
            >
              {tipoCaixarias.caixaria_nivel_2}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXA_MAE
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
              onClick={() => {
                if (tipoStatusAndamento === tipoStatusAndamentoCriarPedido) {
                  if (produto) {
                    if (produto.unidades_caixa_nivel_3 > 0) {
                      changeTipoCaixaria(TIPO_UNIDADE_OFERTA_CAIXA_MAE);
                    }
                  }
                }
              }}
            >
              {tipoCaixarias.caixaria_nivel_3}
            </StyledTableCell>
            <StyledTableCell component="th" scope="row" align="left">
              {tipoCaixarias.totais}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow key="quantidade_liberada">
            <StyledTableCell component="th" scope="row">
              {quantidadeLiberada.descicao_do_kpi}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_UN ||
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_KG
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {quantidadeLiberada.unidade}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXA
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {quantidadeLiberada.caixaria_nivel_1}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXETA
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {quantidadeLiberada.caixaria_nivel_2}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXA_MAE
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {quantidadeLiberada.caixaria_nivel_3}
            </StyledTableCell>
            <StyledTableCell component="th" scope="row" align="left">
              {quantidadeLiberada.totais}
            </StyledTableCell>
          </StyledTableRow>
          {true ? (
            <StyledTableRow key="preco_compra">
              <StyledTableCell component="th" scope="row">
                {precoCompra.descicao_do_kpi}
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                align="left"
                style={
                  tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_UN ||
                  tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_KG
                    ? {
                        backgroundColor: 'yellow',
                      }
                    : {}
                }
              >
                {precoCompra.unidade}
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                align="left"
                style={
                  tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXA
                    ? {
                        backgroundColor: 'yellow',
                      }
                    : {}
                }
              >
                {precoCompra.caixaria_nivel_1}
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                align="left"
                style={
                  tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXETA
                    ? {
                        backgroundColor: 'yellow',
                      }
                    : {}
                }
              >
                {precoCompra.caixaria_nivel_2}
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                align="left"
                style={
                  tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXA_MAE
                    ? {
                        backgroundColor: 'yellow',
                      }
                    : {}
                }
              >
                {precoCompra.caixaria_nivel_3}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" align="left">
                {precoCompra.totais}
              </StyledTableCell>
            </StyledTableRow>
          ) : null}

          {true ? (
            <StyledTableRow key="preco_minimo">
              <StyledTableCell component="th" scope="row">
                {precoMinimo.descicao_do_kpi}
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                align="left"
                style={
                  tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_UN ||
                  tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_KG
                    ? {
                        backgroundColor: 'yellow',
                      }
                    : {}
                }
              >
                {precoMinimo.unidade}
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                align="left"
                style={
                  tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXA
                    ? {
                        backgroundColor: 'yellow',
                      }
                    : {}
                }
              >
                {precoMinimo.caixaria_nivel_1}
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                align="left"
                style={
                  tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXETA
                    ? {
                        backgroundColor: 'yellow',
                      }
                    : {}
                }
              >
                {precoMinimo.caixaria_nivel_2}
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                align="left"
                style={
                  tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXA_MAE
                    ? {
                        backgroundColor: 'yellow',
                      }
                    : {}
                }
              >
                {precoMinimo.caixaria_nivel_3}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" align="left">
                {precoMinimo.totais}
              </StyledTableCell>
            </StyledTableRow>
          ) : null}
          <StyledTableRow key="preco_venda_normal_sem_verba">
            <StyledTableCell component="th" scope="row">
              {precoVendaNormalSemVerba.descicao_do_kpi}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_UN ||
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_KG
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {precoVendaNormalSemVerba.unidade}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXA
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {precoVendaNormalSemVerba.caixaria_nivel_1}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXETA
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {precoVendaNormalSemVerba.caixaria_nivel_2}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXA_MAE
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {precoVendaNormalSemVerba.caixaria_nivel_3}
            </StyledTableCell>
            <StyledTableCell component="th" scope="row" align="left">
              {precoVendaNormalSemVerba.totais}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow key="preco_venda_jornal_oferta">
            <StyledTableCell component="th" scope="row">
              {precoVendaJornalOferta.descicao_do_kpi}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_UN ||
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_KG
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {precoVendaJornalOferta.unidade}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXA
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {precoVendaJornalOferta.caixaria_nivel_1}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXETA
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {precoVendaJornalOferta.caixaria_nivel_2}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXA_MAE
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {precoVendaJornalOferta.caixaria_nivel_3}
            </StyledTableCell>
            <StyledTableCell component="th" scope="row" align="left">
              {precoVendaJornalOferta.totais}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow key="preco_venda_zerar_estoque">
            <StyledTableCell component="th" scope="row">
              {precoVendaZerarEstoque.descicao_do_kpi}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_UN ||
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_KG
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {precoVendaZerarEstoque.unidade}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXA
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {precoVendaZerarEstoque.caixaria_nivel_1}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXETA
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {precoVendaZerarEstoque.caixaria_nivel_2}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXA_MAE
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {precoVendaZerarEstoque.caixaria_nivel_3}
            </StyledTableCell>
            <StyledTableCell component="th" scope="row" align="left">
              {precoVendaZerarEstoque.totais}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow key="preco_venda_com_margem_de_lb_zero">
            <StyledTableCell component="th" scope="row">
              {precoVendaComMargemDeLbZero.descicao_do_kpi}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_UN ||
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_KG
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {precoVendaComMargemDeLbZero.unidade}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXA
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {precoVendaComMargemDeLbZero.caixaria_nivel_1}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXETA
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {precoVendaComMargemDeLbZero.caixaria_nivel_2}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXA_MAE
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {precoVendaComMargemDeLbZero.caixaria_nivel_3}
            </StyledTableCell>
            <StyledTableCell component="th" scope="row" align="left">
              {precoVendaComMargemDeLbZero.totais}
            </StyledTableCell>
          </StyledTableRow>

          {tipoStatusAndamento === tipoStatusAndamentoAprovar && false ? (
            <StyledTableRow key="preco_venda_com_margem_de_lb_zero_2">
              <StyledTableCell component="th" scope="row">
                {precoVendaComMargemDeLbZero2.descicao_do_kpi}
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                align="left"
                style={
                  tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_UN ||
                  tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_KG
                    ? { backgroundColor: 'yellow' }
                    : {}
                }
              >
                {precoVendaComMargemDeLbZero2.unidade}
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                align="left"
                style={
                  tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXA
                    ? { backgroundColor: 'yellow' }
                    : {}
                }
              >
                {precoVendaComMargemDeLbZero2.caixaria_nivel_1}
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                align="left"
                style={
                  tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXETA
                    ? { backgroundColor: 'yellow' }
                    : {}
                }
              >
                {precoVendaComMargemDeLbZero2.caixaria_nivel_2}
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                align="left"
                style={
                  tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXA_MAE
                    ? { backgroundColor: 'yellow' }
                    : {}
                }
              >
                {precoVendaComMargemDeLbZero2.caixaria_nivel_3}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" align="left">
                {precoVendaComMargemDeLbZero2.totais}
              </StyledTableCell>
            </StyledTableRow>
          ) : null}

          <StyledTableRow key="verba_aplicada_por_unidade_de_produto">
            <StyledTableCell component="th" scope="row">
              {verbaAplicadaPorUnidadeDeProduto.descicao_do_kpi}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_UN ||
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_KG
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {verbaAplicadaPorUnidadeDeProduto.unidade}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXA
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {verbaAplicadaPorUnidadeDeProduto.caixaria_nivel_1}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXETA
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {verbaAplicadaPorUnidadeDeProduto.caixaria_nivel_2}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXA_MAE
                  ? { backgroundColor: 'yellow' }
                  : {}
              }
            >
              {verbaAplicadaPorUnidadeDeProduto.caixaria_nivel_3}
            </StyledTableCell>
            <StyledTableCell component="th" scope="row" align="left">
              {verbaAplicadaPorUnidadeDeProduto.totais}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow key="preco_venda_liberado_mais_verba_incluida">
            <StyledTableCell component="th" scope="row">
              {precoVendaLiberadoMaisVerbaIncluida.descicao_do_kpi}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_UN ||
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_KG
                  ? {
                      backgroundColor: 'yellow',
                      fontWeight: 'bold',
                      color: 'red',
                      fontSize: '30px',
                    }
                  : {}
              }
            >
              {precoVendaLiberadoMaisVerbaIncluida.unidade}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXA
                  ? {
                      backgroundColor: 'yellow',
                      fontWeight: 'bold',
                      color: 'red',
                      fontSize: '30px',
                    }
                  : {}
              }
            >
              {precoVendaLiberadoMaisVerbaIncluida.caixaria_nivel_1}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXETA
                  ? {
                      backgroundColor: 'yellow',
                      fontWeight: 'bold',
                      color: 'red',
                      fontSize: '30px',
                    }
                  : {}
              }
            >
              {precoVendaLiberadoMaisVerbaIncluida.caixaria_nivel_2}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={
                tipoUnidadeOferta === TIPO_UNIDADE_OFERTA_CAIXA_MAE
                  ? {
                      backgroundColor: 'yellow',
                      fontWeight: 'bold',
                      color: 'red',
                      fontSize: '30px',
                    }
                  : {}
              }
            >
              {precoVendaLiberadoMaisVerbaIncluida.caixaria_nivel_3}
            </StyledTableCell>
            <StyledTableCell component="th" scope="row" align="left">
              {precoVendaLiberadoMaisVerbaIncluida.totais}
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
