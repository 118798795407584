import * as React from 'react';
import { useRecordContext } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import { STATUS_ATIVO } from '../../../utils';

const ActionsField = ({
  handleEdit = () => {},
  handleDeletar = () => {},
  ...rest
}) => {
  const record = useRecordContext(rest);
  const value = record
    ? record.record
      ? record.record.status === STATUS_ATIVO
      : false
    : false;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Tooltip title="Editar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="edit"
          size="small"
          onClick={() => handleEdit(record.record)}
          disabled={!value}
        >
          <EditOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Deletar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => handleDeletar(record.record)}
          disabled={!value}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ActionsField;
