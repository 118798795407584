import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';

import {
  TIPO_RECORRENCIA_NAO_RECORRENCIA,
  TIPO_RECORRENCIA_DIARIA_NORMAL,
  TIPO_RECORRENCIA_SEMANAL_NORMAL,
  TIPO_RECORRENCIA_MENSAL_NORMAL,
  TIPO_RECORRENCIA_ANUAL_NORMAL,
} from '../../utils';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '400px',
  },
}));

const labelSemana = {
  0: 'Domingo',
  1: 'Segunda-Feira',
  2: 'Terça-Feira',
  3: 'Quarta-Feira',
  4: 'Quinta-Feira',
  5: 'Sexta-Feira',
  6: 'Sábado',
};

function getValor(dados) {
  switch (dados.tipo_recorrencia) {
    case TIPO_RECORRENCIA_NAO_RECORRENCIA:
      return moment(new Date(dados.data_entrega)).format('DD/MM/YYYY');
    case TIPO_RECORRENCIA_DIARIA_NORMAL:
      return moment(new Date(dados.data_entrega)).format('DD/MM/YYYY');
    case TIPO_RECORRENCIA_SEMANAL_NORMAL:
      return labelSemana[dados.dia_semana];
    case TIPO_RECORRENCIA_MENSAL_NORMAL:
      return `${dados.dia < 10 ? `0${dados.dia}` : dados.dia}`;
    case TIPO_RECORRENCIA_ANUAL_NORMAL:
      return `${dados.dia < 10 ? `0${dados.dia}` : dados.dia}/${
        dados.mes + 1 < 10 ? `0${dados.mes + 1}` : dados.mes + 1
      }`;
    default:
      return '-';
  }
}

const DescricaoField = (props) => {
  const classes = useStyles();

  const record = useRecordContext(props);
  const value = record ? getValor(record.record) : '-';

  return (
    <Tooltip title={value} placement="bottom" aria-label="add2">
      <Typography className={classes.text}>{value}</Typography>
    </Tooltip>
  );
};

export default DescricaoField;
