import React from 'react';
import { Button } from 'react-admin';
import { useHistory } from 'react-router-dom';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const BackButton = ({ routeBack = '' }) => {
  const history = useHistory();

  const handleClick = () => {
    if (routeBack !== '') history.push(routeBack);
    else history.go(-1);
  };

  return (
    <Button onClick={handleClick} label="Voltar">
      <ArrowBackIcon />
    </Button>
  );
};

export default BackButton;
