import React, { forwardRef } from 'react';

import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const AutoCompleteNormal = forwardRef(({
  label,
  campo = 'nome',
  options,
  value,
  onChangeLocal,
  handleKey,
  fullWidth = true,
  autoFocus = false,
  variant = 'outlined',
  handleEnter = () => {},
  disabled = false,
  ...rest
}, forwardedRef) => {
  const [inputValue, setInputValue] = React.useState('');

  return (
    <Autocomplete
      options={options}
      getOptionSelected={(option, value) => option[campo] === value[campo]}
      getOptionLabel={(option) => option[campo]}
      value={value}
      onChange={(event, newValue) => {
        onChangeLocal(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      disabled={disabled}
      fullWidth={fullWidth}
      autoFocus={autoFocus}
      renderInput={(params) => (
        <TextField
          margin="normal"
          {...rest}
          {...params}
          label={label}
          variant={variant}
          fullWidth
          autoComplete="off"
          inputRef={forwardedRef}
          onKeyDown={(e) => {
            if (e.keyCode === 13) handleEnter();
            else handleKey(e.keyCode, e.key);
          }}
          autoFocus={autoFocus}
        />
      )}
    />
  );
});

export default AutoCompleteNormal;
