import React from 'react';

import Paper from '@material-ui/core/Paper';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '50px',
      fontSize: '32px',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      textAlign: 'center',
      opacity: '0.75',
    },
  }),
);

const Label = ({ label }) => {
  const classes = useStyles();

  return <Paper className={classes.root}>{label}</Paper>;
};

export default Label;
