import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import {
  formatCPF,
  formatCNPJ,
  PIX_ALEATORIA_OPTION,
  PIX_CNPJ_OPTION,
  PIX_CPF_OPTION,
  PIX_EMAIL_OPTION,
  PIX_TELEFONE_OPTION,
  formatTelefone,
} from '../../../utils';
import {
  TextInput,
  SelectInput,
  CpfInput,
  CnpjInput,
  AutoCompleteRemoto,
  TelefoneInput,
} from '../../Inputs';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));
/*
const GenericoInput = forwardRef(({ formato, ...props }, ref) => (
  <TemplateNumberFormatInput {...props} formato={formato} ref={ref} />
)); */

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const optionsTipoChaves = [
  {
    label: 'Chave CPF',
    value: PIX_CPF_OPTION,
  },
  {
    label: 'Chave CNPJ',
    value: PIX_CNPJ_OPTION,
  },
  {
    label: 'Chave Telefone',
    value: PIX_TELEFONE_OPTION,
  },
  {
    label: 'Chave Email',
    value: PIX_EMAIL_OPTION,
  },
  {
    label: 'Chave Aleatória',
    value: PIX_ALEATORIA_OPTION,
  },
];

const DEFAULT_ID = -1;

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose, handleSave }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [valor, setValor] = useState('');
    const [tipo, setTipo] = useState('conta_corrente');

    const [id, setId] = useState(DEFAULT_ID);

    const classes = useStyles({ cor: '#009FD4' });

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    async function reiniciar() {
      setId(DEFAULT_ID);
      setValor('');
      setTipo(PIX_CPF_OPTION);
      setOpen(true);
    }

    async function handleSalvar() {
      try {
        if (id <= 0) {
          handleSave(id, valor, tipo);
          enqueueSnackbar('Chave pix registrada com sucesso!', {
            variant: 'success',
          });
        } else {
          handleSave(id, valor, tipo);
          enqueueSnackbar('Chave pix registrada com sucesso!', {
            variant: 'success',
          });
        }
        handleCloseDialog();
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    useImperativeHandle(ref, () => ({
      handleCreate() {
        reiniciar();
        setOpen(true);
      },
      handleEdit(id, valor, tipo) {
        setValor(valor);
        setTipo(tipo);
        setId(id);
        setOpen(true);
      },
    }));

    function validateValor() {
      let error = '';
      const regexEmail = /\S+@\S+\.\S+/;
      if (!valor) error = 'Este campo é obrigatório';
      else {
        switch (tipo) {
          case PIX_CPF_OPTION:
            if (valor.length !== 11) {
              error = 'Número de caracteres diferente do formato do CPF (11)';
            }
            break;
          case PIX_CNPJ_OPTION:
            if (valor.length !== 14) {
              error = 'Número de caracteres diferente do formato do CNPJ (14)';
            }
            break;
          case PIX_TELEFONE_OPTION:
            if (valor.length !== 11) {
              error =
                'Número de caracteres diferente do formato do telefone (11)';
            }
            break;
          case PIX_EMAIL_OPTION:
            if (valor.length > 80) {
              error = 'Número de caracteres maior que o permitido (máximo 80)';
            } else if (!regexEmail.test(valor)) {
              error = 'Email em formato inválido!';
            }
            break;
          case PIX_ALEATORIA_OPTION:
            if (valor.length > 80) {
              error = 'Número de caracteres maior que o permitido (máximo 80)';
            }
            break;
          default:
            error = 'O tipo de pix deve ser escolhido!';
            break;
        }
      }

      return error;
    }

    function getErros() {
      const errosOld = [''];
      errosOld[0] = validateValor();
      return errosOld;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullWidth
          maxWidth="sm"
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            {id < 0
              ? 'Novo Registro de Chave Pix'
              : 'Editar Registro de Chave Pix'}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '10px 36px 20px',
              margin: '0',
            }}
          >
            <div>
              <Box flex={1} mr="0.5em" mt="20px">
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel shrink id="demo-simple-select-label">
                    Tipo Chave Pix
                  </InputLabel>
                  <SelectInput
                    labelId="demo-simple-select-label"
                    name="tipo"
                    handleEnter={() => {}}
                    label="Tipo de Chave"
                    handleKey={() => {}}
                    value={tipo}
                    onChange={(value) => setTipo(value.target.value)}
                    /* error={erros[4] !== ''}
                    helperText={erros[4]} */
                    options={optionsTipoChaves}
                    //
                  />
                </FormControl>
              </Box>
              {tipo === PIX_CPF_OPTION && (
                <Box flex={1} mr="0.5em">
                  <CpfInput
                    name="cpf"
                    handleEnter={() => {}}
                    label="Chave"
                    handleKey={() => {}}
                    value={valor}
                    onChange={(value) => setValor(value.target.value)}
                    error={erros[0] !== ''}
                    helperText={erros[0]}
                    fullWidth
                  />
                </Box>
              )}
              {tipo === PIX_CNPJ_OPTION && (
                <Box flex={1} mr="0.5em">
                  <CnpjInput
                    name="cnpj"
                    handleEnter={() => {}}
                    label="Chave"
                    handleKey={() => {}}
                    value={valor}
                    onChange={(value) => setValor(value.target.value)}
                    error={erros[0] !== ''}
                    helperText={erros[0]}
                    fullWidth
                  />
                </Box>
              )}
              {tipo === PIX_TELEFONE_OPTION && (
                <Box flex={1} mr="0.5em">
                  <TelefoneInput
                    name="telefone"
                    handleEnter={() => {}}
                    label="Chave"
                    handleKey={() => {}}
                    value={valor}
                    onChange={(value) => setValor(value.target.value)}
                    error={erros[0] !== ''}
                    helperText={erros[0]}
                    fullWidth
                  />
                </Box>
              )}
              {tipo === PIX_EMAIL_OPTION && (
                <Box flex={1} mr="0.5em">
                  <TextInput
                    name="email"
                    handleEnter={() => {}}
                    label="Chave"
                    handleKey={() => {}}
                    value={valor}
                    onChange={(value) => setValor(value.target.value)}
                    error={erros[0] !== ''}
                    helperText={erros[0]}
                    fullWidth
                  />
                </Box>
              )}
              {tipo === PIX_ALEATORIA_OPTION && (
                <Box flex={1} mr="0.5em">
                  <TextInput
                    name="aleatória"
                    handleEnter={() => {}}
                    label="Chave"
                    handleKey={() => {}}
                    value={valor}
                    onChange={(value) => setValor(value.target.value)}
                    error={erros[0] !== ''}
                    helperText={erros[0]}
                    fullWidth
                  />
                </Box>
              )}
            </div>
          </DialogContent>
          <DialogActions
            style={{
              padding: '0px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={handleCloseDialog}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="contained"
              color="primary"
            >
              Fechar
            </Button>
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                disabled={erroExistente}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
