import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AlarmAddIcon from '@material-ui/icons/AlarmAdd';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DoneIcon from '@material-ui/icons/Done';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from 'moment';

import { STATUS_ATIVO, STATUS_INATIVO } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    /* width: theme.spacing(3),
    height: theme.spacing(3), */
  },
}));

function verificaDataFutura(data) {
  const dataAtual = new Date(moment().startOf('day').format());
  const dataData = new Date(moment(data).startOf('day').format());

  if (dataAtual.getTime() < dataData.getTime()) {
    return true;
  }

  return false;
}

const ActionsField = ({
  tipo,
  handleEdit = () => {},
  handleDeletar = () => {},
  handleAvaliar = () => {},
  handleDesavaliar = () => {},
  ativo,
  ...rest
}) => {
  const classes = useStyles();
  const record = useRecordContext(rest);
  //const status = record ? record.record.status : '';
  const statusAvaliacao = record ? !!record.record.avaliacao : false;

  const verificaDataFuturaValue = record
    ? verificaDataFutura(record.record.data_entrega)
    : true;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        // width: '120px',
      }}
    >
      {!verificaDataFuturaValue ? (
        statusAvaliacao ? (
          <Tooltip title="Desavaliar" placement="bottom" aria-label="add2">
            <IconButton
              aria-label="edit"
              classes={{ root: classes.root }}
              size="small"
              onClick={() => handleDesavaliar(record.record)}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Avaliar" placement="bottom" aria-label="add2">
            <IconButton
              aria-label="edit"
              classes={{ root: classes.root }}
              size="small"
              onClick={() => handleAvaliar(record.record)}
            >
              <DoneIcon />
            </IconButton>
          </Tooltip>
        )
      ) : null}
      {/*<Tooltip title="Editar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="edit"
          classes={{ root: classes.root }}
          size="small"
          onClick={() => handleEdit(record.record)}
        >
          <EditOutlinedIcon />
        </IconButton>
        </Tooltip>*/}
      <Tooltip title="Excluir" placement="bottom" aria-label="excluir2">
        <IconButton
          aria-label="excluir"
          size="small"
          onClick={() => handleDeletar(record.record)}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};



export default ActionsField;
