import React, { forwardRef, useState, useImperativeHandle } from 'react';
import { Line } from 'react-chartjs-2';

import { useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { getInfos, legend } from './createInfosGrafico';
import { createObjEstoque } from './createObjGraficos';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const Grafico = forwardRef(({ linhas, days }, ref) => {
  const [open, setOpen] = useState(false);
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const classes = useStyles({ cor: '#009FD4' });

  const tamGrafico = isXSmall ? '100%' : '100%';

  const { data, options } = getInfos(
    'Teste',
    createObjEstoque(linhas, days),
    days,
  );

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
    },
  }));

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      classes={{ paper: classes.rootDialogo }}
      className={classes.rootDialogo}
      /*  fullWidth
      maxWidth="sm" */
      TransitionComponent={Transition}
      keepMounted
      fullScreen
    >
      <DialogTitle
        id="form-dialog-title"
        onClose={handleCloseDialog}
        style={{ backgroundColor: '#009FD4', color: 'white' }}
      >
        Gráfico
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '50px 30px 0px',
          }}
        >
          <div
            style={{
              width: tamGrafico,
              /*  marginBottom: '50px',
              padding: '0px 20px', */
            }}
          >
            <Line data={data} legend={legend} options={options} />
          </div>
        </div>
      </DialogContent>
      {/* <DialogActions
        style={{
          padding: '0px 36px 20px',
          margin: '0',
        }}
      >
        <div className={classes.wrapper}>
          <Button
            onClick={handleCloseDialog}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
            variant="contained"
            color="primary"
          >
            Salvar
          </Button>
        </div>
      </DialogActions> */}
    </Dialog>
  );
});

export default Grafico;
