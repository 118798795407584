import React, { useState, useEffect, useRef, useContext } from 'react';

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockIcon from '@material-ui/icons/Lock';
import { useSnackbar } from 'notistack';

import imagem from './assets/background.jpg';
import { DialogoConfirmacao } from './Components';
import InputPassaword from './Components/DialogoEditarVenda/components/InputPassaword';
import InputUsername from './Components/DialogoEditarVenda/components/InputUsername';
import Context from './context';
import {
  api,
  getUsername,
  setIsTimeTelaBloqueio,
  getIsTimeTelaBloqueio,
  isAuthenticated,
  logout,
} from './services';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    minWidth: 300,
    marginTop: '6em',
    opacity: '0.90',
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500],
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  actions: {
    padding: '0 1em 1em 1em',
  },
  margin: {
    margin: theme.spacing(1),
  },
  settings: {
    color: 'red',
  },
}));

const TEMPO_TELA_BLOQUEIO_DEFAULT = 2;

const DialogoTelaBloqueio = ({ children }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [senha, setSenha] = useState('');
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const refDados = useRef({
    time: new Date(),
    tempo: TEMPO_TELA_BLOQUEIO_DEFAULT,
  });
  const refDialogoConfirmacao = useRef(null);
  const [total, setTotal] = useContext(Context);

  async function getTempoTelaBloqueio() {
    try {
      const response = await api.get('/config/1');
      const { minutos_para_tela_bloqueio } = response.data;
      refDados.current.tempo = minutos_para_tela_bloqueio;
    } catch (e) {
      refDados.current.tempo = TEMPO_TELA_BLOQUEIO_DEFAULT;
    }
  }

  useEffect(() => {
    getTempoTelaBloqueio();
  }, []);

  function verificaLimite() {
    const delta = new Date().getTime() - refDados.current.time.getTime();
    const tempoLimite = refDados.current.tempo * 60 * 1000;

    return delta > tempoLimite;
  }

  function verificaAbrirTela() {
    if (getIsTimeTelaBloqueio()) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }

  function verificaAbrirTela2() {
    if (getIsTimeTelaBloqueio()) {
      setOpen(true);
      const data1 = new Date();
      data1.setTime(data1.getTime() - refDados.current.tempo * 60 * 1000);
      refDados.current.time = data1;
    } else {
      setOpen(false);
      refDados.current.time = new Date();
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (isAuthenticated()) {
        const verificaLimiteValue = verificaLimite();
        if (verificaLimiteValue) {
          setIsTimeTelaBloqueio(true);
        } else {
          setIsTimeTelaBloqueio(false);
        }
      } else {
        setIsTimeTelaBloqueio(false);
      }

      verificaAbrirTela();
    }, 5 * 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isAuthenticated()) {
      verificaAbrirTela2();
    } else {
      setOpen(false);
      refDados.current.time = new Date();
    }
  }, []);

  async function handleSubmit() {
    setLoading(true);
    try {
      const dataUsuario = await api.post('/confere_senha', {
        username: getUsername(),
        password: senha,
      });
      const { status } = dataUsuario.data;
      if (status) {
        setOpen(false);
        setIsTimeTelaBloqueio(false);
        refDados.current.time = new Date();
        setSenha('');
      } else {
        throw 'Senha incorreta';
      }
    } catch (e) {
      enqueueSnackbar(e, {
        variant: 'error',
      });
    }
    setLoading(false);
  }

  function validateSenha() {
    let error = '';
    if (!senha) {
      error = 'É necessário fornecer a senha do usuário!';
    }
    return error;
  }

  function getErros() {
    const errosOld = [''];
    errosOld[0] = validateSenha();

    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  function mouseMove() {
    refDados.current.time = new Date();
  }

  function handleConfirma() {
    setTotal(1);
  }

  function openLogout() {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Logout',
        'Tem certeza de que deseja realizar o logout?',
        1,
      );
    }
  }

  return (
    <div>
      <div onMouseMove={mouseMove}>{children}</div>
      <Dialog
        open={open}
        fullScreen
        style={{
          padding: '0',
          margin: '0',
        }}
      >
        <DialogContent
          style={{
            padding: '0',
            margin: '0',
            backgroundImage: `url(${imagem})`,
            backgroundSize: 'cover',
          }}
        >
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <Avatar className={classes.icon}>
                  <LockIcon />
                </Avatar>
              </div>
              <div className={classes.hint}>TELA DE BLOQUEIO</div>
              <div className={classes.form}>
                <div className={classes.input}>
                  <InputUsername
                    label="Usuário"
                    value={getUsername()}
                    onChange={(value) => {}}
                    error={erros[1] !== ''}
                    helperText={erros[1]}
                    fullWidth
                    disabled={loading}
                    autoFocus
                  />
                </div>
                <div className={classes.input}>
                  <InputPassaword
                    label="Senha"
                    value={senha}
                    onChange={(value) => setSenha(value.target.value)}
                    error={erros[0] !== ''}
                    helperText={erros[0]}
                    fullWidth
                    disabled={loading}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        handleSubmit();
                      }
                    }}
                    autoFocus
                  />
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  disabled={erroExistente}
                  tabindex="-1"
                  onClick={handleSubmit}
                >
                  {loading && (
                    <CircularProgress
                      size={25}
                      thickness={2}
                      color="secondary"
                      style={{ marginRight: '10px' }}
                      tabindex="-1"
                    />
                  )}
                  {`Entrar`}
                </Button>
              </CardActions>
            </Card>
            <Tooltip title="Logout" placement="bottom" aria-label="add2">
              <IconButton
                aria-label="check"
                size="small"
                onClick={() => openLogout()}
                style={{ color: 'white', marginTop: '20px' }}
              >
                <ExitToAppIcon />
              </IconButton>
            </Tooltip>
          </div>
        </DialogContent>
        <DialogoConfirmacao
          ref={refDialogoConfirmacao}
          handleConfirma={handleConfirma}
        />
      </Dialog>
    </div>
  );
};

export default DialogoTelaBloqueio;
