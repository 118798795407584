import React, { useState } from 'react';

import { Box } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { useSnackbar } from 'notistack';

import { api } from '../../../services';
import CardItemBaixar from './CardItemBaixar';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const BaixarArquivos = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  async function arquivosBalançaZip() {
    const response = await api.get('/arquivos_balanca');

    const {
      itensContentArquivo,
      teclasContentArquivo,
      tarasContentArquivo,
    } = response.data;

    const zip = new JSZip();
    zip.file('Itensmgv.txt', itensContentArquivo);
    zip.file('Txteclas.txt', teclasContentArquivo);
    zip.file('Tara.txt', tarasContentArquivo);
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, 'arquivos_balanca.zip');
    });
  }

  async function baixarArquivoInterceptador() {
    try {
      setLoading(true);
      await arquivosBalançaZip();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      enqueueSnackbar(e, {
        variant: 'error',
      });
    }
  }

  return (
    <Box>
      <Box
        style={{
          padding: '20px',
        }}
      >
        <CardItemBaixar
          handleBaixar={baixarArquivoInterceptador}
          title="Arquivos Balança"
          desciption="Arquivos da balança para importar no mgv6"
          link="https://cdn.awsli.com.br/600x700/127/127091/produto/59020174/7ec24f9278.jpg"
        />
      </Box>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="primary" />
      </Backdrop>
    </Box>
  );
};

export default BaixarArquivos;
