import React from 'react';

import { Paper, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  formatPeso,
  getEstoqueLabel,
} from '../../../utils';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '10px',
    backgroundColor: theme.palette.secondary.main,
    marginTop: '15px',
    color: 'white',
    boxSizing: 'border-box',
    opacity: '0.75',
  },
  containerOpaco: {
    padding: '10px',
    backgroundColor: theme.palette.secondary.main,
    marginTop: '15px',
    color: 'white',
    boxSizing: 'border-box',
  },
  containerInterno: {
    marginTop: '10px',
    fontSize: '15px',
  },
}));

const LabelEstoque = ({ produto, isOpaco = false }) => {
  const classes = useStyles();
  const isVisiblePeso = produto ? produto.unidade.tipo !== POR_UNIDADE : false;
  const isVisibleUnidades = produto
    ? produto.unidade.tipo !== POR_PESO_VARIAVEL_SEM_UNIDADE
    : false;
  return (
    <Paper
      className={isOpaco ? classes.containerOpaco : classes.container}
      elevation={3}
    >
      <Box display="flex" flexDirection="column" width="100%" color="white">
        <div style={{ textAlign: 'center' }}>ESTOQUE</div>

        <Paper className={classes.containerInterno} elevation={3}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width="100%"
            color="black"
            padding="10px"
          >
            {isVisibleUnidades && (
              <div
                style={{
                  marginTop: '5px',
                  fontSize: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <span style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                  Unidades Disponiveis:{' '}
                </span>
                <span>{getEstoqueLabel(produto)}</span>
              </div>
            )}
            {isVisiblePeso && (
              <div
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <span style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                  Peso Disponível:{' '}
                </span>
                <span>{`${formatPeso(produto.pesoDisponivel)}Kg`}</span>
              </div>
            )}
          </Box>
        </Paper>
      </Box>
    </Paper>
  );
};

export default LabelEstoque;
