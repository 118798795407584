import React from 'react';
import {
  TextField,
  ListBase,
  ListToolbar,
  Pagination,
  Title,
  Datagrid,
  DateField,
} from 'react-admin';

import { Card, makeStyles } from '@material-ui/core';

import Actions from './ActionsList';
import Filter from './Filter';

const useStyles = makeStyles((theme) => ({
  dataHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
}));

const MyList = ({ ...props }) => {
  const classes = useStyles();

  return (
    <>
      <Title title="Compras" />
      <ListToolbar
        filters={props.filters}
        {...props}
        actions={<Actions {...props} />}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid {...props}>
          <TextField
            source="compra.id"
            label="Compra"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <DateField
            source="compra.data"
            label="Data"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <TextField
            source="unidades"
            label="Unidades"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <TextField
            source="peso"
            label="Peso (Kg)"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
        </Datagrid>
        <TransacoesPagination />
      </Card>
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      title="Compras"
      component="div"
      filters={<Filter />}
    />
  </>
);

export default List2;
