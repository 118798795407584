import React from 'react';

import EditTemplate from '../../Components/Edit';
import Form from './Form';
import validate from './Validation';

const Title = ({ record }) => (
  <span>Editar Despesa: {record ? `"${record.nome}"` : ''}</span>
);

function transform({
  valor,
  nome,
  horario,
  conta_id,
  categoria_transacao_id,
  status,
  tipo_edicao,
  fixo,
  repeticoes,
  tipo_periodo,
  ...data
}) {
  return {
    valor: parseFloat(valor),
    nome,
    horario,
    conta_id,
    categoria_transacao_id,
    status,
    tipo_edicao,
    // fixo,
    // repeticoes: fixo ? parseInt(repeticoes, 10) : 0,
    // tipo_periodo,
  };
}

const Edit = (props) => (
  <EditTemplate
    {...props}
    title={<Title />}
    validate={validate}
    transform={transform}
    defaultValue={{
      tipo_edicao: 0,
    }}
    isDelete={false}
    isBack
  >
    <Form isCreate={false} />
  </EditTemplate>
);

export default Edit;
