import React from 'react';

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import moment from 'moment';

const styles = StyleSheet.create({
  page: {},
  body: {
    width: '100%',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  header2: {
    display: 'flex',
    backgroundColor: '#E4E4E4',
    color: 'black',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10',
    fontWeight: 'bold',
    borderBottom: '1 solid black',
    padding: '2px 0',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid black',
  },
  contentTotais: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid black',
    backgroundColor: '#ffff00',
  },
  contentTotaisTotais: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid black',
    backgroundColor: '#f96c25',
  },
  item: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  item3: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  item4: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderLeft: '1 solid black',
  },
  negativo: {
    backgroundColor: '#ffc7cf',
  },
  positivo: {
    backgroundColor: '#cbf5d0',
  },
});

const maximoLinhasColuna = 35;

function getTamanhoFont(nColunas) {
  switch (nColunas) {
    case 1:
      return 11;
    case 2:
      return 11;
    case 3:
      return 11;
    default:
      return 7;
  }
}

function gerarHeader(nColunas, tamanhoFont) {
  const rows = [];
  for (let i = 0; i < nColunas; i++) {
    rows.push(
      <View
        style={[
          styles.item,
          { flex: 3, fontSize: `${tamanhoFont}`, marginLeft: '10' },
        ]}
      >
        <Text>Produto</Text>
      </View>,
    );
    rows.push(
      <View style={[styles.item, { flex: 1, fontSize: `${tamanhoFont}` }]}>
        <Text>Código</Text>
      </View>,
    );
    rows.push(
      <View style={[styles.item, { flex: 1, fontSize: `${tamanhoFont}` }]}>
        <Text>Preço</Text>
      </View>,
    );
    rows.push(
      <View style={[styles.item, { flex: 1, fontSize: `${tamanhoFont}` }]}>
        <Text />
      </View>,
    );
  }
  return rows;
}

function getItem(i, j, itens) {
  const index = j * maximoLinhasColuna + i;
  if (index > itens.length - 1) {
    return {
      linha: '',
      produto: '',
      codigo: '',
      preco: '',
    };
  }
  const item = itens[index];
  return {
    linha: index + 1,
    produto: item.produto,
    codigo: item.codigo,
    preco: item.preco,
  };
}

function gerarBody(itens, nColunas, tamanhoFont) {
  const rows = [];
  let lala = 0;
  for (let i = 0; i < maximoLinhasColuna; i++) {
    const rows2 = [];
    for (let j = 0; j < nColunas; j++) {
      lala++;
      const item = getItem(i, j, itens);
      rows2.push(
        <View
          style={[
            styles.item,
            {
              flex: 3,
              fontSize: `${tamanhoFont}`,
              marginLeft: '10',
              whiteSpace: 'nowrap',
            },
          ]}
        >
          <Text>{item.produto.trim()}</Text>
        </View>,
      );
      rows2.push(
        <View style={[styles.item, { flex: 1, fontSize: `${tamanhoFont}` }]}>
          <Text>{item.codigo}</Text>
        </View>,
      );
      rows2.push(
        <View style={[styles.item, { flex: 1, fontSize: `${tamanhoFont}` }]}>
          <Text>{item.preco}</Text>
        </View>,
      );
      rows2.push(
        <View style={[styles.item, { flex: 1, fontSize: `${tamanhoFont}` }]}>
          <Text />
        </View>,
      );
    }
    rows.push(<View style={styles.content}>{rows2}</View>);
  }
  return rows;
}

const ListaProdutos = ({ itens }) => {
  const nColunas =
    parseInt(itens.length / maximoLinhasColuna, 10) +
    (itens.length % maximoLinhasColuna === 0 ? 0 : 1);
  const tamanhoFont = getTamanhoFont(nColunas);
  return (
    <Document
      author="anderson"
      title={`CARUARU FRIOS - INVENTÁRIO DE PRODUTOS VENDIDOS NO KG - ${moment().format(
        'DD/MM/YYYY',
      )}`}
      pageLayout="singlePage"
    >
      <Page size="A4" style={styles.page} wrap={false} orientation="landscape">
        <View style={styles.body}>
          <View
            style={[
              styles.title,
              { margin: '2 0', fontWeight: 'bold', fontSize: '12' },
            ]}
          >
            <Text
              style={{ marginTop: '2px' }}
            >{`INVENTÁRIO DE PRODUTOS VENDIDOS NO KG - ${moment().format(
              'DD/MM/YYYY - HH:mm',
            )}`}</Text>
            <Text style={{ marginTop: '2px' }}>{`${itens.length} Itens`}</Text>
          </View>

          <View style={styles.header}>
            {gerarHeader(nColunas, tamanhoFont)}
          </View>
          {gerarBody(itens, nColunas, tamanhoFont)}
        </View>
      </Page>
    </Document>
  );
};
export default ListaProdutos;
