import React from 'react';

import {
  withStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 12,
    minWidth: '140px',
  },
  body: {
    fontSize: 15,
    minWidth: '140px',
    fontWeight: 'bold',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    minWidth: '140px',
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  paper: {
    height: 300,
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflow: 'auto',
  },
}));

export default function Tabela({ headCells, rows }) {
  const classes = useStyles();
  return (
    <TableContainer
      component={Paper}
      style={{
        // opacity: '0.75',
        height: '100%',
        width: '100%',
        overflow: 'auto',
      }}
    >
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <StyledTableCell
                align={headCell.right ? 'right' : 'inherit'}
                width="150px"
              >
                {headCell.label}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.uidd}>
              {headCells.map((column) => (
                <>
                  {column.right ? (
                    <StyledTableCell align="right" width="150px">
                      {row[column.field]}
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell component="th" scope="row" width="150px">
                      {row[column.field]}
                    </StyledTableCell>
                  )}
                </>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
