import React from 'react';
import { UserMenu } from 'react-admin';

import Divider from '@material-ui/core/Divider';

import Avatar from './Avatar';
import UsernameMenuItem from './UsernameMenuItem';
import VersionamentoMenuItem from './VersionamentoMenuItem';

const UserMenuCustom = (props) => (
  <UserMenu {...props} icon={<Avatar />}>
    <UsernameMenuItem />
    <Divider />
    <VersionamentoMenuItem />
    <Divider />
  </UserMenu>
);
export default UserMenuCustom;
