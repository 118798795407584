import React from 'react';

import { Paper, Box, makeStyles } from '@material-ui/core';

import { formatCPF, formatCNPJ } from '../../../../../utils';

const useStyles = makeStyles((theme) => ({
  containerOpaco: {
    padding: '10px',
    backgroundColor: theme.palette.secondary.main,
    /* height: '120px', */
    marginTop: '0px',
    color: 'white',
    boxSizing: 'border-box',
    opacity: '0.75',
  },
  containerInterno: {
    height: '100%',
    marginTop: '10px',
    /* fontSize: '35px', */
  },
}));

const LabelCliente = ({ cliente }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.containerOpaco} elevation={3}>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        color="white"
      >
        <div style={{ textAlign: 'center' }}>Cliente</div>

        <Paper
          className={classes.containerInterno}
          elevation={3}
          style={{
            fontSize: '14px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            padding: '5px',
          }}
        >
          <div
            style={{
              display: 'flex',
            }}
          >
            <span style={{ fontWeight: 'bold', marginRight: '5px' }}>
              Responsável:{' '}
            </span>
            <span>{cliente.nome}</span>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <span style={{ fontWeight: 'bold', marginRight: '5px' }}>
              Razão Social:{' '}
            </span>
            <span>{cliente.razao_social}</span>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <span style={{ fontWeight: 'bold', marginRight: '5px' }}>
              CPF:{' '}
            </span>
            <span>{formatCPF(cliente.cpf)}</span>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <span style={{ fontWeight: 'bold', marginRight: '5px' }}>
              CNPJ:{' '}
            </span>
            <span>{formatCNPJ(cliente.cnpj)}</span>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <span style={{ fontWeight: 'bold', marginRight: '5px' }}>
              Cidade:{' '}
            </span>
            <span>
              {cliente.cidade
                ? `${cliente.cidade.nome} - ${cliente.cidade.estado.sigla}`
                : ''}
            </span>
          </div>
        </Paper>
      </Box>
    </Paper>
  );
};

export default LabelCliente;
