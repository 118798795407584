import React from 'react';
import {
  Filter as FilterAdmin,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  TextInput,
} from 'react-admin';

import {
  PeriodoInput,
  PrecoInput,
  IntegerInput,
} from '../../Components/InputsReactAdmin';
import { STATUS_ATIVO } from '../../utils/constants';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <ReferenceInput
      fullWidth
      label="Produto"
      source="produto_id"
      reference="produtos/search/autocomplete"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      filter={{ status: STATUS_ATIVO, is_kit: false, is_caixa: false }}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput>
    <ReferenceInput
      fullWidth
      label="Cliente"
      source="cliente_id"
      reference="clientes"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput>
    <PeriodoInput
      source="periodo"
      label="Período"
      defaultValue={{
        start: new Date(),
        end: new Date(),
      }}
    />
    <ReferenceInput
      source="conta_id"
      reference="contas_normal"
      label="Caixa"
      allowEmpty={false}
      filter={{ is_caixa: true, status: STATUS_ATIVO }}
    >
      <SelectInput optionText="nome" allowEmpty={false} />
    </ReferenceInput>
    <ReferenceInput
      fullWidth
      label="Frentista"
      source="user_id"
      reference="users"
      sort={{ field: 'username', order: 'ASC' }}
      filterToQuery={(searchText) => ({ username: `${searchText}` })}
      perPage={30}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <IntegerInput source="sessao_id" label="Id Sessão" prefixo="#" />
    <TextInput source="numero" label="Número da venda" />
  </FilterAdmin>
);

export default Filter;

// mudar aqui
