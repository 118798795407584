import React from 'react';
import {
  Filter as FilterAdmin,
  TextInput,
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
} from 'react-admin';

import { STATUS_ATIVO } from '../../utils/constants';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <TextInput label="Descrição" source="search" />
    <ReferenceInput
      label="Categoria"
      source="categoria_desconto_funcionario_id"
      reference="categorias_descontos_funcionarios"
      allowEmpty={false}
    >
      <SelectInput optionText="nome" allowEmpty={false} />
    </ReferenceInput>
    <ReferenceInput
      fullWidth
      label="Funcionário"
      source="user_id"
      reference="users"
      sort={{ field: 'username', order: 'ASC' }}
      filterToQuery={(searchText) => ({ username: `${searchText}` })}
      perPage={30}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <BooleanInput
      label="Descontos Acima do aceitavel"
      source="desconto_aceitavel_funcionario"
    />
  </FilterAdmin>
);

export default Filter;
