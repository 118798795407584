import React, { useImperativeHandle, forwardRef, useRef } from 'react';

import { useSnackbar } from 'notistack';
import { DialogoConfirmacao } from '../../../Components';
import { api } from '../../../services';

const FormDialog = forwardRef(({ handleClose }, ref) => {
  const refDialogoConfirmacao = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  useImperativeHandle(ref, () => ({
    handleOpen(record) {
      if (refDialogoConfirmacao.current) {
        refDialogoConfirmacao.current.handleOpen(
          'Deletar Sangria',
          'Tem certeza de que deseja deletar esta sangria?',
          record,
        );
      }
    },
  }));

  async function handleConfirma({ id }) {
    const response = await api.delete(`/malote_sangrias/${id}`);
    const { 
      isError,
      message
    } = response.data
    if(isError) {
      enqueueSnackbar(message, {
        variant: 'error',
      });
    }
    handleClose();
  }

  return (
    <DialogoConfirmacao
      ref={refDialogoConfirmacao}
      handleConfirma={handleConfirma}
    />
  );
});

export default FormDialog;
