import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from 'react';
import { Title } from 'react-admin';
import { useLocation, useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { TextInput, SearchProduto, PrecoInput } from '../../../Components';
import { createAPI, updateAPI, getListAllAPI } from '../../../services';
import { STATUS_ATIVO, formatMoeda } from '../../../utils';
import DateInputMobills from './DateInputMobills';
import DialogoCreate from './DialogoCreate';
import DialogoDeletar from './DialogoDeletar';
import DialogoUpdate from './DialogoUpdate';

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Cotacoes = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [nome, setNome] = useState('');
  const [fornecedores, setFornecedores] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [id, setId] = useState(-1);
  const [itens, setItens] = useState([]);
  const [data2, setData2] = useState(new Date());

  const [fieldOrder, setFieldOrder] = useState(-1);
  const [typeOrder, setTypeOrder] = useState(true);

  const [carregando, setCarregando] = useState(false);

  const refDialogoCreate = useRef(null);
  const refDialogoEdit = useRef(null);
  const refDialogoDelete = useRef(null);

  const classes = useStyles({ cor: '#009FD4' });

  const query = useQuery();

  const cotacaoTemplateIdQuery = query.get('cotacao_template_id');

  function handleCriar() {
    if (refDialogoCreate.current) {
      const cotacaoTemplateId = parseInt(cotacaoTemplateIdQuery, 10);
      refDialogoCreate.current.handleOpen(cotacaoTemplateId, data2);
    }
  }

  function handleEdit() {
    if (refDialogoEdit.current) {
      refDialogoEdit.current.handleOpen(id);
    }
  }

  function handleDelete() {
    if (refDialogoDelete.current) refDialogoDelete.current.handleOpen(id);
  }

  function formataFornecedores(itens) {
    const newArray = [];
    for (let i = 0; i < itens.length; i++) {
      const isExistis = newArray.some(
        (item) => item.id === itens[i].fornecedor.id,
      );
      if (!isExistis) {
        newArray.push({
          nome: itens[i].fornecedor.nome,
          id: itens[i].fornecedor.id,
          prazos: itens[i].prazos,
        });
      }
    }
    return newArray.sort((a, b) => a.id - b.id);
  }

  function formataProdutos(itens) {
    const newArray = [];
    for (let i = 0; i < itens.length; i++) {
      const isExistis = newArray.some(
        (item) => item.id === itens[i].produto.id,
      );
      if (!isExistis) {
        newArray.push({
          nome: itens[i].produto.nome,
          id: itens[i].produto.id,
        });
      }
    }
    return newArray.sort((a, b) => a.id - b.id);
  }

  function searchItem(itens, fornecedor_id, produto_id) {
    return itens.find(
      (item) =>
        item.fornecedor.id === fornecedor_id && item.produto.id === produto_id,
    );
  }

  function initItens(itens, fornecedores, produtos) {
    const rowsArray = [];
    for (let i = 0; i < fornecedores.length; i++) {
      const columnsArray = [];
      for (let j = 0; j < produtos.length; j++) {
        columnsArray.push(
          searchItem(itens, fornecedores[i].id, produtos[j].id).preco,
        );
      }
      rowsArray.push(columnsArray);
    }
    return rowsArray;
  }

  async function getDataResource() {
    try {
      setCarregando(true);
      const data = await getListAllAPI(
        'cotacoes',
        ['id', 'asc'],
        {
          periodo: {
            start: data2,
            end: data2,
          },
        },
        ['itens.produto', 'itens.fornecedor', 'template'],
      );
      if (data.data.length > 0) {
        //setData2(moment(new Date(data.data[0].data)).format('DD/MM/YYYY'));
        //setNome(data.data[0].template.nome);
        setId(data.data[0].id);
        const fornecedores = formataFornecedores(data.data[0].itens);
        setFornecedores(fornecedores);
        const produtos = formataProdutos(data.data[0].itens);
        setProdutos(produtos);
        setItens(initItens(data.data[0].itens, fornecedores, produtos));
        setCarregando(false);
      } else {
        throw 'Esta cotação não existe!';
      }
    } catch (erros) {
      // enqueueSnackbar(`${erros}`, {
      //   variant: 'error',
      // });
      setId(-1);
      setCarregando(false);
    }
  }

  async function init(cotacaoTemplateId) {
    const data = await getListAllAPI(
      'cotacoes_templates',
      ['id', 'asc'],
      {
        id: [cotacaoTemplateId],
      },
      [],
    );
    setNome(data.data[0].nome);
    await getDataResource();
  }

  useEffect(() => {
    getDataResource();
  }, [data2]);

  useEffect(() => {
    if (cotacaoTemplateIdQuery) {
      const cotacaoTemplateId = parseInt(cotacaoTemplateIdQuery, 10);
      init(cotacaoTemplateId);
    }
  }, [cotacaoTemplateIdQuery]);

  function getItem(i, j) {
    if (itens.length >= i + 1) {
      if (itens[i].length >= j + 1) {
        return itens[i][j];
      }
      return 0;
    }
    return 0;
  }

  function getHeaderTable() {
    const columns = [];
    columns.push({
      value: 'Fornecedores\\Produtos',
      produto_id: -1,
      is_input: false,
    });
    for (let i = 0; i < produtos.length; i++) {
      columns.push({
        value: produtos[i].nome,
        produto_id: produtos[i].id,
        is_input: true,
      });
    }
    columns.push({
      value: 'Prazos',
      produto_id: -1,
      is_input: false,
    });
    return columns;
  }

  function getLabelFornecedor(data) {}

  const columns = getHeaderTable();

  function getLinesTable() {
    const lines = [];
    let index = 0;
    for (let i = 0; i < fornecedores.length; i++) {
      const column = [];
      column.push({
        value: fornecedores[i].nome,
        is_input: false,
        id: `${fornecedores[i].id}-label`,
        index: -1,
        i: -1,
        j: -1,
        produto_id: -1,
        fornecedor_id: -1,
      });
      for (let j = 0; j < produtos.length; j++) {
        column.push({
          value: getItem(i, j),
          is_input: true,
          id: `${fornecedores[i].id}-${produtos[j].id}`,
          index,
          i,
          j,
          produto_id: produtos[j].id,
          fornecedor_id: fornecedores[i].id,
        });
        index += 1;
      }
      column.push({
        value: fornecedores[i].prazos,
        is_input: false,
        id: `${fornecedores[i].id}-prazos`,
        index: -1,
        i: -1,
        j: -1,
        produto_id: -1,
        fornecedor_id: -1,
      });
      lines.push({
        columns: column,
        id: `${fornecedores[i].id}`,
      });
    }
    return lines;
  }

  const rows = getLinesTable();

  function sortItens(rows) {
    const index = produtos.findIndex((item) => item.id === fieldOrder);
    if (index >= 0) {
      if (typeOrder) {
        return rows.sort(
          (a, b) => a.columns[index + 1].value - b.columns[index + 1].value,
        );
      }
      return rows.sort(
        (a, b) => b.columns[index + 1].value - a.columns[index + 1].value,
      );
    }
    return rows;
  }

  const rowsSorted = sortItens(rows);

  const title = `Cotações: ${nome}`;

  function menoresValores() {
    const valores = [];

    if (itens.length > 0) {
      const nProdutos = itens[0].length;

      if (nProdutos > 0) {
        for (let i = 0; i < nProdutos; i++) {
          valores.push({});
        }

        for (let i = 0; i < nProdutos; i++) {
          valores[i] = itens[0][i];
          for (let j = 0; j < itens.length; j++) {
            if (valores[i] > itens[j][i]) valores[i] = itens[j][i];
          }
        }
      } else {
        ///////////
      }
    } else {
      /////////
    }
    return valores;
  }

  const arrayMenoresValores = menoresValores();

  function getValorMenor(produto_id) {
    const achado = arrayMenoresValores.find(
      (item) => item.produto_id === produto_id,
    );
    if (achado) {
      return achado.preco;
    }

    return 0;
  }

  return (
    <div>
      <Title title={title} />
      <div>
        <Box p="1em">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '30px',
              marginTop: '30px',
            }}
          >
            <div />
            <DateInputMobills handleData={(value) => setData2(value)} />
            <div>
              {id > 0 ? (
                <div>
                  <IconButton
                    aria-label="edit"
                    classes={{ root: classes.root }}
                    size="small"
                    onClick={() => handleEdit()}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                  <IconButton
                    aria-label="edit"
                    classes={{ root: classes.root }}
                    size="small"
                    onClick={() => handleDelete()}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </div>
              ) : (
                <IconButton
                  aria-label="edit"
                  classes={{ root: classes.root }}
                  size="small"
                  onClick={() => handleCriar()}
                >
                  <AddIcon />
                </IconButton>
              )}
            </div>
          </div>
          {id > 0 ? (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {columns.map(({ value, produto_id, is_input }) => (
                      <StyledTableCell
                        key={produto_id}
                        onClick={() => {
                          if (fieldOrder === produto_id) {
                            setTypeOrder(!typeOrder);
                          } else {
                            setFieldOrder(produto_id);
                            setTypeOrder(true);
                          }
                        }}
                      >
                        {is_input ? (
                          <div
                            style={{
                              display: 'flex',
                              cursor: 'pointer',
                            }}
                          >
                            {fieldOrder === produto_id && (
                              <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                                size="small"
                                style={{ color: 'white' }}
                              >
                                {typeOrder ? (
                                  <ArrowDownwardIcon />
                                ) : (
                                  <ArrowUpwardIcon />
                                )}
                              </IconButton>
                            )}
                            <div>{value}</div>
                          </div>
                        ) : (
                          <div>{value}</div>
                        )}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsSorted.map(({ id, columns }) => (
                    <StyledTableRow key={id}>
                      {columns.map(
                        (
                          { value, is_input, index, i, j, produto_id },
                          index2,
                        ) => (
                          //const valueMenor = getValorMenor(produto_id);

                          <StyledTableCell component="th" scope="row">
                            {is_input ? (
                              <div
                                style={
                                  value ===
                                    (arrayMenoresValores.length >= index2
                                      ? arrayMenoresValores[index2 - 1]
                                      : 0) && value !== 0
                                    ? {
                                        backgroundColor: 'yellow',
                                      }
                                    : {}
                                }
                              >
                                {value === 0 ? '-' : formatMoeda(getItem(i, j))}
                              </div>
                            ) : (
                              <div>{value}</div>
                            )}
                          </StyledTableCell>
                        ),
                      )}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <h2
              style={{
                margin: '50px 0',
                textAlign: 'center',
              }}
            >
              A cotação deste dia não foi registrada!!
            </h2>
          )}
        </Box>
      </div>
      <DialogoCreate
        handleClose={() => {
          getDataResource();
        }}
        ref={refDialogoCreate}
      />
      <DialogoUpdate
        handleClose={() => {
          getDataResource();
        }}
        ref={refDialogoEdit}
      />
      <DialogoDeletar
        handleClose={() => {
          getDataResource();
        }}
        ref={refDialogoDelete}
      />
    </div>
  );
};

export default Cotacoes;
