const Validation = (values) => {
  const errors = {};
  if (!values.nome) errors.nome = 'Este campo é obrigatório';
  else if (values.nome.length > 20)
    errors.nome = 'Número de caracteres maior que o permitido (máximo 20)';
  if (Number.isNaN(values.valor)) {
    errors.csll = 'Campo Obrigatório';
  } else if (values.valor < 0) {
    errors.csll = 'Valor inválido';
  }
  return errors;
};

export default Validation;
