import React, { useRef } from 'react';
import {
  TextField,
  ReferenceField,
  Button,
  Link,
  ListButton,
  RefreshButton,
  TabbedShowLayout,
  Tab,
  DateField,
  ReferenceManyField,
  NumberField,
  TabbedShowLayoutTabs,
  Pagination,
  SimpleShowLayout,
  Show as ShowAdmin,
  useRefresh,
  Datagrid,
  FunctionField,
} from 'react-admin';

import { makeStyles } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import { withStyles } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AssignmentIcon from '@material-ui/icons/Assignment';

import logo from '../../assets/logo.png';
import {
  DialogoNota,
  DialogoTransacao,
  DialogoChangeStatusTransacao,
  DialogoDeletarTransacao,
  AutoCompleteLocalCustomizado,
  DialogoDespesaCartaoCredito,
} from '../../Components';
import Datagrid2 from '../../Components/Datagrid';
import EditButton from '../../Components/EditButton';
import rowStyles from '../../Components/List/rowStyles';
import ShowTemplate from '../../Components/Show';
import { getListAllAPI } from '../../services';
import DialogoPagarParcelaCompraComMalote from '../MaloteSubmalotes/DialogoPagarParcelaCompra';
import ActionsFieldParcelaCompra from './ActionsFieldParcelaCompra';
import ActionsField from './Fields/ActionsField';
import CategoriaField from './Fields/CategoriaField';
import ContaField from './Fields/ContaField';
import DataPagamentoFields from './Fields/DataPagamentoFields';
import DescricaoField from './Fields/DescricaoField';
import DescricaoProblemaField from './Fields/DescricaoProblemaField';
import FaturaField from './Fields/FaturaField';
import ProblemaField from './Fields/ProblemaField';
import ReferenceFieldCategoria from './Fields/ReferenceFieldCategoria';
import StatusField from './Fields/StatusField';
import ValorField from './Fields/ValorField';
import Filter from './Filter';

const styles = {
  button: {
    marginTop: '1em',
  },
};

const Title = ({ record }) => <span>Compra </span>;

const AddNewLoteButton = withStyles(styles)(({ classes, record }) => (
  <Button
    component={Link}
    className={classes.button}
    to={{
      pathname: '/lotes/create',
      search: `?compra_id=${record.id}`,
    }}
    label="Novo Item"
  >
    <AddBoxIcon />
  </Button>
));

const AddNewParcelaButton = withStyles(styles)(({ classes, record }) => (
  <Button
    component={Link}
    className={classes.button}
    to={{
      pathname: '/parcelascompra/create',
      search: `?compra_id=${record.id}`,
    }}
    label="Nova Parcela"
  >
    <AddBoxIcon />
  </Button>
));

const Actions = ({ basePath, data }) => {
  const refDialogoNota = useRef();
  function openNota() {
    if (refDialogoNota.current)
      refDialogoNota.current.handleOpen(data.id, 'compra');
  }
  return (
    <CardActions>
      <ListButton basePath={basePath} />
      <RefreshButton />
      <Button onClick={openNota} label="Nota">
        <AssignmentIcon />
      </Button>
      <DialogoNota ref={refDialogoNota} logo={logo} />
    </CardActions>
  );
};

const useStylesDatagrid = makeStyles((theme) => ({
  statusHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    paddingLeft: '30px',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  statusCell: {
    // marginLeft: '30px',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingLeft: '30px',
  },
  statusContent: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 5,
    marginLeft: '30px',
    maxWidth: '100px',
  },
  dataHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoCell: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaCell: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  actionsHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingRight: '100px',
  },
  actionsCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingRight: '100px',
  },
}));

const TransacaoPanel1 = ({
  handleEdit = () => {},
  handleDelete = () => {},
  handleEfetivar = () => {},
  ...props
}) => {
  const classes = useStylesDatagrid();

  return (
    <ShowAdmin
      {...props}
      /* disable the app title change when shown */
      title=" "
    >
      <SimpleShowLayout>
        <ReferenceManyField
          reference="transacoes"
          target="parcela_compra_id"
          addLabel={false}
        >
          <Datagrid>
            <StatusField
              source="status"
              label="Status"
              sortable={false}
              headerClassName={classes.statusHeader}
              cellClassName={classes.statusCell}
              // className={classes.statusContent}
            />
            <DataPagamentoFields
              source="data_pagamento"
              label="Data"
              headerClassName={classes.dataHeader}
              cellClassName={classes.dataCell}
              sortable={false}
            />
            <DescricaoField
              source="descricao"
              label="Descrição"
              sortable={false}
              headerClassName={classes.descricaoHeader}
              cellClassName={classes.descricaoCell}
            />
            <ReferenceFieldCategoria
              label="Categoria"
              source="categoria_transacao_2_id"
              reference="categorias_transacao"
              sortable={false}
              headerClassName={classes.categoriaHeader}
              cellClassName={classes.categoriaCell}
              link={false}
            />
            <ReferenceField
              label="Conta"
              source="conta_id"
              reference="contas"
              sortable={false}
              headerClassName={classes.contaHeader}
              cellClassName={classes.contaCell}
              link={false}
            >
              <ContaField source="nome" />
            </ReferenceField>

            <ValorField
              source="valor"
              /* locales="pt-BR"
          options={{
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'BRL',
          }} */
              textAlign="right"
              label="Valor"
              headerClassName={classes.valorHeader}
              cellClassName={classes.valorCell}
            />
            <FaturaField
              source="fatura"
              sortable={false}
              textAlign="right"
              label="Fatura"
              headerClassName={classes.valorHeader}
              cellClassName={classes.valorCell}
            />
            <ActionsField
              source="lalala"
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              handleEfetivar={handleEfetivar}
              textAlign="right"
              label="Ações"
              headerClassName={classes.actionsHeader}
              cellClassName={classes.actionsCell}
              tipo="Receita"
              sortable={false}
            />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </ShowAdmin>
  );
};

const TransacaoPanel = ({
  handleEdit = () => {},
  handleDelete = () => {},
  handleEfetivar = () => {},
  ...props
}) => {
  const classes = useStylesDatagrid();

  return (
    <ShowAdmin
      {...props}
      /* disable the app title change when shown */
      title=" "
    >
      <SimpleShowLayout style={{ display: 'flex' }}>
        <StatusField
          source="despesa.status"
          label="Status"
          sortable={false}
          headerClassName={classes.statusHeader}
          cellClassName={classes.statusCell}
        />
        <DataPagamentoFields
          source="despesa.data_pagamento"
          label="Data"
          headerClassName={classes.dataHeader}
          cellClassName={classes.dataCell}
        />
        <DescricaoField
          source="despesa.descricao"
          label="Descrição"
          sortable={false}
          headerClassName={classes.descricaoHeader}
          cellClassName={classes.descricaoCell}
        />
        <CategoriaField
          label="Categoria"
          source="despesa.categoria_transacao_2_id"
          sortable={false}
          headerClassName={classes.categoriaHeader}
          cellClassName={classes.categoriaCell}
          link={false}
        />
        {/* <ReferenceFieldCategoria
            label="Categoria"
            source="despesa.categoria_transacao_2_id"
            reference="categorias_transacao"
            sortable={false}
            headerClassName={classes.categoriaHeader}
            cellClassName={classes.categoriaCell}
            link={false}
          /> */}
        {/* <ReferenceField
            label="Conta"
            source="despesa.conta_id"
            reference="contas"
            sortable={false}
            headerClassName={classes.contaHeader}
            cellClassName={classes.contaCell}
            link={false}
          >
            <ContaField source="nome" />
          </ReferenceField> */}

        <ContaField
          label="Conta / Cartão"
          source="despesa.conta_id"
          sortable={false}
          headerClassName={classes.contaHeader}
          cellClassName={classes.contaCell}
          link={false}
        />

        <ValorField
          source="despesa.valor"
          textAlign="right"
          label="Valor"
          headerClassName={classes.valorHeader}
          cellClassName={classes.valorCell}
        />
        <FaturaField
          source="fatura"
          sortable={false}
          textAlign="right"
          label="Fatura"
          headerClassName={classes.valorHeader}
          cellClassName={classes.valorCell}
        />
        <ProblemaField
          label="Problema"
          source="despesa.categoria_problema_despesa_id"
          sortable={false}
          headerClassName={classes.contaHeader}
          cellClassName={classes.contaCell}
          link={false}
        />
        <DescricaoProblemaField
          source="despesa.descricao_problema"
          label="Descrição Problema"
          sortable={false}
          headerClassName={classes.descricaoHeader}
          cellClassName={classes.descricaoCell}
        />
        <ActionsField
          source="despesa"
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleEfetivar={handleEfetivar}
          textAlign="right"
          label="Ações"
          headerClassName={classes.actionsHeader}
          cellClassName={classes.actionsCell}
          tipo="Receita"
          sortable={false}
        />
      </SimpleShowLayout>
    </ShowAdmin>
  );
};

const Show = (props) => {
  const refresh = useRefresh();
  const refDialogo = useRef(null);
  const refDialogoStatus = useRef(null);
  const refDialogoDespesaCartao = useRef(null);
  const refDialogoPagarParcelaCompraComMalote = useRef(null);
  const edit = (id, is_cartao, conta_id, statusValue) => {
    if (is_cartao) {
      if (refDialogoDespesaCartao.current) {
        refDialogoDespesaCartao.current.handleEdit(id);
      }
    } else if (refDialogo.current) {
      refDialogo.current.handleEdit(id);
    }
  };

  const efetivar = (id, is_cartao, conta_id, statusValue) => {
    if (conta_id === 1) {
      if (!statusValue) {
        if (refDialogoPagarParcelaCompraComMalote.current) {
          refDialogoPagarParcelaCompraComMalote.current.handleOpen(id);
        }
      }
    } else if (refDialogoStatus.current) {
      refDialogoStatus.current.handleOpen(id);
    }
  };

  const handleClose = () => {
    refresh();
  };
  return (
    <>
      <ShowTemplate
        {...props}
        actions={<Actions />}
        title={<Title />}
        isBack
        routeBack="/compras"
      >
        <TabbedShowLayout
          tabs={
            <TabbedShowLayoutTabs variant="fullWidth" centered {...props} />
          }
        >
          <Tab label="Geral">
            <TextField source="id" label="Nº compra" />
            <ReferenceField
              label="Fornecedor"
              source="fornecedor_id"
              reference="fornecedores"
            >
              <TextField source="nome" />
            </ReferenceField>
            <NumberField
              source="valor"
              locales="pt-BR"
              options={{
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: 'currency',
                currency: 'BRL',
              }}
              label="Valor Total"
            />
            <DateField source="data" label="Data da Compra" />
            <FunctionField
              label="Faturamento"
              render={(record) => {
                if (record.data_faturamento) {
                  const data = new Date(record.data_faturamento);
                  return `${
                    data.getDate() < 10 ? `0${data.getDate()}` : data.getDate()
                  }/${
                    data.getMonth() + 1 < 10
                      ? `0${data.getMonth() + 1}`
                      : data.getMonth() + 1
                  }/${data.getFullYear()}`;
                }
                return '-';
              }}
            />
          </Tab>
          <Tab label="Itens">
            <ReferenceManyField
              addLabel={false}
              reference="lotes"
              target="compra_id"
              pagination={<Pagination />}
            >
              <Datagrid2>
                <ReferenceField
                  label="Produto"
                  source="produto_id"
                  reference="produtos"
                >
                  <TextField source="nome" />
                </ReferenceField>
                <TextField source="numero" label="Nº lote" />
                <DateField source="validade" label="Validade" />
                <TextField source="unidades" label="QTDE" />
                <TextField source="peso" label="Peso (Kg)" />
                <NumberField
                  source="preco_compra"
                  label="Preço de Compra/Kg"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                />
                <NumberField
                  source="cg"
                  label="CG"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                />
                {/* <EditButton query={`?compra_id=${props.id}`} /> */}
              </Datagrid2>
            </ReferenceManyField>
            {/* <AddNewLoteButton /> */}
          </Tab>
          <Tab label="Parcelas">
            <ReferenceManyField
              addLabel={false}
              reference="parcelascompra"
              target="compra_id"
              pagination={<Pagination />}
            >
              <Datagrid2
                expand={
                  <TransacaoPanel handleEdit={edit} handleEfetivar={efetivar} />
                }
              >
                <DateField source="data_pagamento" label="Data de pagamento" />
                <ReferenceField
                  label="Conta"
                  source="despesa.conta_id"
                  reference="contas"
                  sortable={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <NumberField
                  source="valor"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                  label="Valor"
                />
                <TextField source="codigo_boleto" label="Código Boleto" />
                {/*  <ChangeStatusTransacoesButton /> */}
                <ActionsFieldParcelaCompra
                  source="despesa"
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                />
              </Datagrid2>
            </ReferenceManyField>
          </Tab>
        </TabbedShowLayout>
      </ShowTemplate>
      <DialogoTransacao ref={refDialogo} handleClose={handleClose} />
      <DialogoPagarParcelaCompraComMalote
        ref={refDialogoPagarParcelaCompraComMalote}
        handleClose={handleClose}
      />
      <DialogoDespesaCartaoCredito
        ref={refDialogoDespesaCartao}
        handleClose={handleClose}
      />
      <DialogoChangeStatusTransacao
        ref={refDialogoStatus}
        handleClose={handleClose}
      />
    </>
  );
};

export default Show;
