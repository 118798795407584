import React from 'react';
import { TextField, EditButton } from 'react-admin';

import ListTemplate from '../../Components/List';
import Filter from './Filter';

const List = (props) => (
  <ListTemplate
    {...props}
    filters={<Filter />}
    title="Categorias Clientes"
    filterDefaultValues={{ ativo: true }}
    isDelete
    isButtonAtivo
  >
    <TextField source="nome" label="Descrição" />
    <EditButton />
  </ListTemplate>
);

export default List;
