import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useState,
  useEffect,
} from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { IntegerInput, DialogoConfirmacao } from '../../../Components';
import { api, getListAllAPI, getUserId } from '../../../services';
import { imprimirPacoteMalote } from '../../../services/impressora';
import {
  formatMoeda,
  SECONDARY_COLOR,
  ATALHO_VOLTAR,
  ATALHO_AVANCAR,
} from '../../../utils';
import { formatarPacote } from '../../../utils/malote';

const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const PROXIMO_ACTION = ATALHO_AVANCAR;

const notasTrocoInit = {
  moedas_5_centavos: 0,
  moedas_10_centavos: 0,
  moedas_25_centavos: 0,
  moedas_50_centavos: 0,
  moedas_1_real: 0,
  cedulas_2_reais: 0,
  cedulas_5_reais: 0,
  cedulas_10_reais: 0,
  cedulas_20_reais: 0,
  cedulas_50_reais: 0,
  cedulas_100_reais: 0,
  cedulas_200_reais: 0,
};

const DialogoContadorCedulas = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = React.useState(false);
  const [notasTroco, setNotasTroco] = useState(notasTrocoInit);
  const [notasSubmalote, setNotasSubmalote] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState(-1);
  const [valorPadraoRetirada, setValorPadraoRetirada] = useState(0);
  const [copias, setCopias] = useState(1);

  useEffect(() => {
    async function getConfig() {
      const dataConfig = await api.get('/config/1');
      setValorPadraoRetirada(
        dataConfig.data.valor_padrao_retiradas_frete_caixa,
      );
    }
    getConfig();
  }, []);

  const refDialogoConfirmacao = useRef(null);

  async function getSubmalote(id) {
    try {
      const data = await getListAllAPI(
        'malote_submalotes',
        ['id', 'asc'],
        { id },
        [],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        const dataFormated = formatarPacote(data.data[0]);
        setNotasSubmalote(dataFormated.notas);
        setNotasTroco(notasTrocoInit);
        setCopias(1);
      } else {
        throw 'Este submalote não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseLocal();
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setId(id);
      getSubmalote(id);
      setOpen(true);
    },
  }));

  function handleCloseLocal() {
    setOpen(false);
  }

  const handleChangeNotasTroco = (field, value) => {
    setNotasTroco({
      ...notasTroco,
      [field]: value,
    });
  };

  function proximaEtapa() {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Finalização da criação do troco',
        'Deseja realmente finalizar a criação do troco?',
        {
          code: 1,
          data: null,
        },
      );
    }
  }

  function etapaAnterior() {
    handleCloseLocal();
  }

  function pause(tempo) {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, tempo);
    });
  }

  async function salvarDados() {
    try {
      const result = await api.post(`/malote_trocos/create_apartir_submalote`, {
        notas: notasTroco,
        submalote_id: id,
        user_id: getUserId(),
        copias,
      });
      const idsTrocos = result.data.idsTrocos;
      for (let i = 0; i < idsTrocos.length; i++) {
        const codigo = await imprimirPacoteMalote(idsTrocos[i]);
        if (codigo < 0) {
          enqueueSnackbar('Erro ao imprimir a nota!', {
            variant: 'error',
          });
        }
        await pause(1000);
      }
      handleCloseLocal();
      handleClose();
    } catch (e) {
      handleCloseLocal();
    }
  }

  async function handleConfirma({ code, data }) {
    switch (code) {
      case 1:
        salvarDados();
        break;
      default:
        break;
    }
  }

  function getTotalNotas(notas) {
    if (notas) {
      const total =
        formataValor(notas.moedas_5_centavos) * 0.05 +
        formataValor(notas.moedas_10_centavos) * 0.1 +
        formataValor(notas.moedas_25_centavos) * 0.25 +
        formataValor(notas.moedas_50_centavos) * 0.5 +
        formataValor(notas.moedas_1_real) * 1 +
        formataValor(notas.cedulas_2_reais) * 2 +
        formataValor(notas.cedulas_5_reais) * 5 +
        formataValor(notas.cedulas_10_reais) * 10 +
        formataValor(notas.cedulas_20_reais) * 20 +
        formataValor(notas.cedulas_50_reais) * 50 +
        formataValor(notas.cedulas_100_reais) * 100 +
        formataValor(notas.cedulas_200_reais) * 200;

      return total;
    }

    return 0;
  }

  const totalSubmalote = getTotalNotas(notasSubmalote);
  const totalTroco = getTotalNotas(notasTroco);

  const totalTodosTrocos = getCopias() * totalTroco;

  const totalRestante = totalSubmalote - totalTodosTrocos;

  function getValueNota(field, objNotas) {
    if (objNotas) {
      return objNotas[field];
    }
    return 0;
  }

  function getCopias() {
    if (Number.isNaN(copias)) {
      return 0;
    }
    return copias;
  }

  function formataValor(valor) {
    return Number.isNaN(valor) ? 0 : valor;
  }

  const isValorErrado =
    /* totalTroco !== valorPadraoRetirada ||  */ totalTroco <= 0;

  function getError(field) {
    let error = '';

    const valueTroco =
      getCopias() * formataValor(getValueNota(field, notasTroco));

    const valueSubmalote = formataValor(getValueNota(field, notasSubmalote));

    if (valueTroco > valueSubmalote) {
      error = '* Valor maior que o disponível.';
    }

    return error;
  }

  const erro_moedas_5_centavos = getError('moedas_5_centavos');
  const erro_moedas_10_centavos = getError('moedas_10_centavos');
  const erro_moedas_25_centavos = getError('moedas_25_centavos');
  const erro_moedas_50_centavos = getError('moedas_50_centavos');
  const erro_moedas_1_real = getError('moedas_1_real');
  const erro_cedulas_2_reais = getError('cedulas_2_reais');
  const erro_cedulas_5_reais = getError('cedulas_5_reais');
  const erro_cedulas_10_reais = getError('cedulas_10_reais');
  const erro_cedulas_20_reais = getError('cedulas_20_reais');
  const erro_cedulas_50_reais = getError('cedulas_50_reais');
  const erro_cedulas_100_reais = getError('cedulas_100_reais');
  const erro_cedulas_200_reais = getError('cedulas_200_reais');

  const copiasFormatada = getCopias();

  const erroCopias = copiasFormatada <= 0 || copiasFormatada > 10;

  const isError =
    isValorErrado ||
    erroCopias ||
    erro_moedas_5_centavos !== '' ||
    erro_moedas_10_centavos !== '' ||
    erro_moedas_25_centavos !== '' ||
    erro_moedas_50_centavos !== '' ||
    erro_moedas_1_real !== '' ||
    erro_cedulas_2_reais !== '' ||
    erro_cedulas_5_reais !== '' ||
    erro_cedulas_10_reais !== '' ||
    erro_cedulas_20_reais !== '' ||
    erro_cedulas_50_reais !== '' ||
    erro_cedulas_100_reais !== '' ||
    erro_cedulas_200_reais !== '';

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseLocal}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        scroll="paper"
        fullWidth
        maxWidth="md"
        fullScreen
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ color: 'white', backgroundColor: SECONDARY_COLOR }}
        >
          <div>Criar Troco</div>
        </DialogTitle>
        <DialogContent dividers>
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                    gap: '20px',
                  }}
                >
                  <div>
                    <span
                      style={{
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Troco:
                    </span>
                    <span>{`${formatMoeda(totalTroco)}`}</span>
                  </div>
                  <div>
                    <span
                      style={{
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Trocos:
                    </span>
                    <span>{`${formatMoeda(totalTodosTrocos)}`}</span>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '20px',
                    padding: '10px',
                    border: '1px solid black',
                    borderRadius: '10px',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '5px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 200,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <IntegerInput
                        handleEnter={() => {}}
                        label=""
                        handleKey={() => {}}
                        value={
                          Number.isNaN(
                            getValueNota('cedulas_200_reais', notasTroco),
                          )
                            ? ''
                            : String(
                                getValueNota('cedulas_200_reais', notasTroco),
                              )
                        }
                        onChange={(value) =>
                          handleChangeNotasTroco(
                            'cedulas_200_reais',
                            parseInt(value.target.value, 10),
                          )
                        }
                        fullWidth
                        style={{
                          margin: '0',
                          padding: '0',
                          width: '50px',
                        }}
                        styleInput={{
                          padding: '0',
                          textAlign: 'center',
                        }}
                      />
                      {copiasFormatada !== 1 && (
                        <>
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            X
                          </span>
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {copiasFormatada}
                          </span>
                        </>
                      )}
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          200 *
                            copiasFormatada *
                            formataValor(
                              getValueNota('cedulas_200_reais', notasTroco),
                            ),
                        )}
                      </span>
                    </div>
                    {erro_cedulas_200_reais !== '' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}
                      >
                        {erro_cedulas_200_reais}
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 100,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <IntegerInput
                        handleEnter={() => {}}
                        label=""
                        handleKey={() => {}}
                        value={
                          Number.isNaN(
                            getValueNota('cedulas_100_reais', notasTroco),
                          )
                            ? ''
                            : String(
                                getValueNota('cedulas_100_reais', notasTroco),
                              )
                        }
                        onChange={(value) =>
                          handleChangeNotasTroco(
                            'cedulas_100_reais',
                            parseInt(value.target.value, 10),
                          )
                        }
                        fullWidth
                        style={{
                          margin: '0',
                          padding: '0',
                          width: '50px',
                        }}
                        styleInput={{
                          padding: '0',
                          textAlign: 'center',
                        }}
                      />
                      {copiasFormatada !== 1 && (
                        <>
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            X
                          </span>
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {copiasFormatada}
                          </span>
                        </>
                      )}
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          100 *
                            copiasFormatada *
                            formataValor(
                              getValueNota('cedulas_100_reais', notasTroco),
                            ),
                        )}
                      </span>
                    </div>
                    {erro_cedulas_100_reais !== '' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}
                      >
                        {erro_cedulas_100_reais}
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 50,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <IntegerInput
                        handleEnter={() => {}}
                        label=""
                        handleKey={() => {}}
                        value={
                          Number.isNaN(
                            getValueNota('cedulas_50_reais', notasTroco),
                          )
                            ? ''
                            : String(
                                getValueNota('cedulas_50_reais', notasTroco),
                              )
                        }
                        onChange={(value) =>
                          handleChangeNotasTroco(
                            'cedulas_50_reais',
                            parseInt(value.target.value, 10),
                          )
                        }
                        fullWidth
                        style={{
                          margin: '0',
                          padding: '0',
                          width: '50px',
                        }}
                        styleInput={{
                          padding: '0',
                          textAlign: 'center',
                        }}
                      />
                      {copiasFormatada !== 1 && (
                        <>
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            X
                          </span>
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {copiasFormatada}
                          </span>
                        </>
                      )}
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          50 *
                            copiasFormatada *
                            formataValor(
                              getValueNota('cedulas_50_reais', notasTroco),
                            ),
                        )}
                      </span>
                    </div>
                    {erro_cedulas_50_reais !== '' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}
                      >
                        {erro_cedulas_50_reais}
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 20,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <IntegerInput
                        handleEnter={() => {}}
                        label=""
                        handleKey={() => {}}
                        value={
                          Number.isNaN(
                            getValueNota('cedulas_20_reais', notasTroco),
                          )
                            ? ''
                            : String(
                                getValueNota('cedulas_20_reais', notasTroco),
                              )
                        }
                        onChange={(value) =>
                          handleChangeNotasTroco(
                            'cedulas_20_reais',
                            parseInt(value.target.value, 10),
                          )
                        }
                        fullWidth
                        style={{
                          margin: '0',
                          padding: '0',
                          width: '50px',
                        }}
                        styleInput={{
                          padding: '0',
                          textAlign: 'center',
                        }}
                      />
                      {copiasFormatada !== 1 && (
                        <>
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            X
                          </span>
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {copiasFormatada}
                          </span>
                        </>
                      )}
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          20 *
                            copiasFormatada *
                            formataValor(
                              getValueNota('cedulas_20_reais', notasTroco),
                            ),
                        )}
                      </span>
                    </div>
                    {erro_cedulas_20_reais !== '' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}
                      >
                        {erro_cedulas_20_reais}
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 10,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <IntegerInput
                        handleEnter={() => {}}
                        label=""
                        handleKey={() => {}}
                        value={
                          Number.isNaN(
                            getValueNota('cedulas_10_reais', notasTroco),
                          )
                            ? ''
                            : String(
                                getValueNota('cedulas_10_reais', notasTroco),
                              )
                        }
                        onChange={(value) =>
                          handleChangeNotasTroco(
                            'cedulas_10_reais',
                            parseInt(value.target.value, 10),
                          )
                        }
                        fullWidth
                        style={{
                          margin: '0',
                          padding: '0',
                          width: '50px',
                        }}
                        styleInput={{
                          padding: '0',
                          textAlign: 'center',
                        }}
                      />
                      {copiasFormatada !== 1 && (
                        <>
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            X
                          </span>
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {copiasFormatada}
                          </span>
                        </>
                      )}
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          10 *
                            copiasFormatada *
                            formataValor(
                              getValueNota('cedulas_10_reais', notasTroco),
                            ),
                        )}
                      </span>
                    </div>
                    {erro_cedulas_10_reais !== '' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}
                      >
                        {erro_cedulas_10_reais}
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 5,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <IntegerInput
                        handleEnter={() => {}}
                        label=""
                        handleKey={() => {}}
                        value={
                          Number.isNaN(
                            getValueNota('cedulas_5_reais', notasTroco),
                          )
                            ? ''
                            : String(
                                getValueNota('cedulas_5_reais', notasTroco),
                              )
                        }
                        onChange={(value) =>
                          handleChangeNotasTroco(
                            'cedulas_5_reais',
                            parseInt(value.target.value, 10),
                          )
                        }
                        fullWidth
                        style={{
                          margin: '0',
                          padding: '0',
                          width: '50px',
                        }}
                        styleInput={{
                          padding: '0',
                          textAlign: 'center',
                        }}
                      />
                      {copiasFormatada !== 1 && (
                        <>
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            X
                          </span>
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {copiasFormatada}
                          </span>
                        </>
                      )}
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          5 *
                            copiasFormatada *
                            formataValor(
                              getValueNota('cedulas_5_reais', notasTroco),
                            ),
                        )}
                      </span>
                    </div>
                    {erro_cedulas_5_reais !== '' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}
                      >
                        {erro_cedulas_5_reais}
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 2,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <IntegerInput
                        handleEnter={() => {}}
                        label=""
                        handleKey={() => {}}
                        value={
                          Number.isNaN(
                            getValueNota('cedulas_2_reais', notasTroco),
                          )
                            ? ''
                            : String(
                                getValueNota('cedulas_2_reais', notasTroco),
                              )
                        }
                        onChange={(value) =>
                          handleChangeNotasTroco(
                            'cedulas_2_reais',
                            parseInt(value.target.value, 10),
                          )
                        }
                        fullWidth
                        style={{
                          margin: '0',
                          padding: '0',
                          width: '50px',
                        }}
                        styleInput={{
                          padding: '0',
                          textAlign: 'center',
                        }}
                      />
                      {copiasFormatada !== 1 && (
                        <>
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            X
                          </span>
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {copiasFormatada}
                          </span>
                        </>
                      )}
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          2 *
                            copiasFormatada *
                            formataValor(
                              getValueNota('cedulas_2_reais', notasTroco),
                            ),
                        )}
                      </span>
                    </div>
                    {erro_cedulas_2_reais !== '' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}
                      >
                        {erro_cedulas_2_reais}
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '5px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 1,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <IntegerInput
                        handleEnter={() => {}}
                        label=""
                        handleKey={() => {}}
                        value={
                          Number.isNaN(
                            getValueNota('moedas_1_real', notasTroco),
                          )
                            ? ''
                            : String(getValueNota('moedas_1_real', notasTroco))
                        }
                        onChange={(value) =>
                          handleChangeNotasTroco(
                            'moedas_1_real',
                            parseInt(value.target.value, 10),
                          )
                        }
                        fullWidth
                        style={{
                          margin: '0',
                          padding: '0',
                          width: '50px',
                        }}
                        styleInput={{
                          padding: '0',
                          textAlign: 'center',
                        }}
                      />
                      {copiasFormatada !== 1 && (
                        <>
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            X
                          </span>
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {copiasFormatada}
                          </span>
                        </>
                      )}
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          1 *
                            copiasFormatada *
                            formataValor(
                              getValueNota('moedas_1_real', notasTroco),
                            ),
                        )}
                      </span>
                    </div>
                    {erro_moedas_1_real !== '' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}
                      >
                        {erro_moedas_1_real}
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 0,50
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <IntegerInput
                        handleEnter={() => {}}
                        label=""
                        handleKey={() => {}}
                        value={
                          Number.isNaN(
                            getValueNota('moedas_50_centavos', notasTroco),
                          )
                            ? ''
                            : String(
                                getValueNota('moedas_50_centavos', notasTroco),
                              )
                        }
                        onChange={(value) =>
                          handleChangeNotasTroco(
                            'moedas_50_centavos',
                            parseInt(value.target.value, 10),
                          )
                        }
                        fullWidth
                        style={{
                          margin: '0',
                          padding: '0',
                          width: '50px',
                        }}
                        styleInput={{
                          padding: '0',
                          textAlign: 'center',
                        }}
                      />
                      {copiasFormatada !== 1 && (
                        <>
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            X
                          </span>
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {copiasFormatada}
                          </span>
                        </>
                      )}
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          0.5 *
                            copiasFormatada *
                            formataValor(
                              getValueNota('moedas_50_centavos', notasTroco),
                            ),
                        )}
                      </span>
                    </div>
                    {erro_moedas_50_centavos !== '' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}
                      >
                        {erro_moedas_50_centavos}
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 0,25
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <IntegerInput
                        handleEnter={() => {}}
                        label=""
                        handleKey={() => {}}
                        value={
                          Number.isNaN(
                            getValueNota('moedas_25_centavos', notasTroco),
                          )
                            ? ''
                            : String(
                                getValueNota('moedas_25_centavos', notasTroco),
                              )
                        }
                        onChange={(value) =>
                          handleChangeNotasTroco(
                            'moedas_25_centavos',
                            parseInt(value.target.value, 10),
                          )
                        }
                        fullWidth
                        style={{
                          margin: '0',
                          padding: '0',
                          width: '50px',
                        }}
                        styleInput={{
                          padding: '0',
                          textAlign: 'center',
                        }}
                      />
                      {copiasFormatada !== 1 && (
                        <>
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            X
                          </span>
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {copiasFormatada}
                          </span>
                        </>
                      )}
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          0.25 *
                            copiasFormatada *
                            formataValor(
                              getValueNota('moedas_25_centavos', notasTroco),
                            ),
                        )}
                      </span>
                    </div>
                    {erro_moedas_25_centavos !== '' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}
                      >
                        {erro_moedas_25_centavos}
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 0,10
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <IntegerInput
                        handleEnter={() => {}}
                        label=""
                        handleKey={() => {}}
                        value={
                          Number.isNaN(
                            getValueNota('moedas_10_centavos', notasTroco),
                          )
                            ? ''
                            : String(
                                getValueNota('moedas_10_centavos', notasTroco),
                              )
                        }
                        onChange={(value) =>
                          handleChangeNotasTroco(
                            'moedas_10_centavos',
                            parseInt(value.target.value, 10),
                          )
                        }
                        fullWidth
                        style={{
                          margin: '0',
                          padding: '0',
                          width: '50px',
                        }}
                        styleInput={{
                          padding: '0',
                          textAlign: 'center',
                        }}
                      />
                      {copiasFormatada !== 1 && (
                        <>
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            X
                          </span>
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {copiasFormatada}
                          </span>
                        </>
                      )}
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          0.1 *
                            copiasFormatada *
                            formataValor(
                              getValueNota('moedas_10_centavos', notasTroco),
                            ),
                        )}
                      </span>
                    </div>
                    {erro_moedas_10_centavos !== '' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}
                      >
                        {erro_moedas_10_centavos}
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 0,05
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <IntegerInput
                        handleEnter={() => {}}
                        label=""
                        handleKey={() => {}}
                        value={
                          Number.isNaN(
                            getValueNota('moedas_5_centavos', notasTroco),
                          )
                            ? ''
                            : String(
                                getValueNota('moedas_5_centavos', notasTroco),
                              )
                        }
                        onChange={(value) =>
                          handleChangeNotasTroco(
                            'moedas_5_centavos',
                            parseInt(value.target.value, 10),
                          )
                        }
                        fullWidth
                        style={{
                          margin: '0',
                          padding: '0',
                          width: '50px',
                        }}
                        styleInput={{
                          padding: '0',
                          textAlign: 'center',
                        }}
                      />
                      {copiasFormatada !== 1 && (
                        <>
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            X
                          </span>
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {copiasFormatada}
                          </span>
                        </>
                      )}
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          0.05 *
                            copiasFormatada *
                            formataValor(
                              getValueNota('moedas_5_centavos', notasTroco),
                            ),
                        )}
                      </span>
                    </div>
                    {erro_moedas_5_centavos !== '' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}
                      >
                        {erro_moedas_5_centavos}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                  }}
                >
                  <div>
                    <span
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      Submalote:{' '}
                    </span>
                    <span>{`${formatMoeda(totalSubmalote)}`}</span>
                  </div>
                  <div>
                    <span
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      Restante:{' '}
                    </span>
                    <span>{`${formatMoeda(totalRestante)}`}</span>
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    gap: '20px',
                    padding: '10px',
                    border: '1px solid black',
                    borderRadius: '10px',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '5px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 200,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {getValueNota('cedulas_200_reais', notasSubmalote)}
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          200 *
                            getValueNota('cedulas_200_reais', notasSubmalote),
                        )}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 100,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {getValueNota('cedulas_100_reais', notasSubmalote)}
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          100 *
                            getValueNota('cedulas_100_reais', notasSubmalote),
                        )}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 50,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {getValueNota('cedulas_50_reais', notasSubmalote)}
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          50 * getValueNota('cedulas_50_reais', notasSubmalote),
                        )}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 20,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {getValueNota('cedulas_20_reais', notasSubmalote)}
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          20 * getValueNota('cedulas_20_reais', notasSubmalote),
                        )}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 10,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {getValueNota('cedulas_10_reais', notasSubmalote)}
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          10 * getValueNota('cedulas_10_reais', notasSubmalote),
                        )}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 5,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {getValueNota('cedulas_5_reais', notasSubmalote)}
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          5 * getValueNota('cedulas_5_reais', notasSubmalote),
                        )}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 2,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {getValueNota('cedulas_2_reais', notasSubmalote)}
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          2 * getValueNota('cedulas_2_reais', notasSubmalote),
                        )}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '5px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 1,00
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {getValueNota('moedas_1_real', notasSubmalote)}
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          1 * getValueNota('moedas_1_real', notasSubmalote),
                        )}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 0,50
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {getValueNota('moedas_50_centavos', notasSubmalote)}
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          0.5 *
                            getValueNota('moedas_50_centavos', notasSubmalote),
                        )}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 0,25
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {getValueNota('moedas_25_centavos', notasSubmalote)}
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          0.25 *
                            getValueNota('moedas_25_centavos', notasSubmalote),
                        )}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 0,10
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {getValueNota('moedas_10_centavos', notasSubmalote)}
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          0.1 *
                            getValueNota('moedas_10_centavos', notasSubmalote),
                        )}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        R$: 0,05
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        X
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {getValueNota('moedas_5_centavos', notasSubmalote)}
                      </span>
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        =
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatMoeda(
                          0.05 *
                            getValueNota('moedas_5_centavos', notasSubmalote),
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              /* justifyContent: 'space-between',
              alignItems: 'center', */
              flexDirection: 'column',
              gap: ' 30px',
              marginTop: '20px',
            }}
          >
            <div
              style={{
                width: '200px',
              }}
            >
              <IntegerInput
                handleEnter={() => {}}
                label="Cópias"
                handleKey={() => {}}
                value={Number.isNaN(copias) ? '' : String(copias)}
                onChange={(value) =>
                  setCopias(parseInt(value.target.value, 10))
                }
                fullWidth
                error={erroCopias}
                helperText={
                  erroCopias
                    ? 'O número de cópias dever ser maior que 0 e menos que 11!'
                    : ''
                }
                /* style={{
                margin: '0',
                padding: '0',
                width: '50px',
              }}
              styleInput={{
                padding: '0',
                textAlign: 'center',
              }} */
              />
            </div>
            {isValorErrado && (
              <div
                style={{
                  color: 'red',
                  /* marginTop: '50px', */
                  fontSize: '20px',
                  /*  width: '50%', */
                }}
              >
                {/*  {`* O valor do troco deve ser de ${formatMoeda(
                  valorPadraoRetirada,
                )}`} */}
                {`* O valor do troco ou sangria deve ser maior que ${formatMoeda(
                  0,
                )}`}
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px',
          }}
        >
          <Button
            onClick={etapaAnterior}
            color="secondary"
            variant="contained"
            tabindex="-1"
          >
            {`Fechar (${CANCELAR_VENDA_ACTION})`}
          </Button>
          <Button
            onClick={proximaEtapa}
            color="secondary"
            variant="contained"
            tabindex="-1"
            disabled={isError}
          >
            {`Finalizar (${PROXIMO_ACTION})`}
          </Button>
        </DialogActions>
        <DialogoConfirmacao
          ref={refDialogoConfirmacao}
          handleConfirma={handleConfirma}
        />
      </Dialog>
    </div>
  );
});

export default DialogoContadorCedulas;
