import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    /* width: theme.spacing(3),
    height: theme.spacing(3), */
  },
}));

const ActionsField = ({
  handleEdit = () => {},
  handleEfetivar = () => {},
  ...rest
}) => {
  const classes = useStyles();
  const record = useRecordContext(rest);

  const statusValue = record.record ? record.record.status : false;
  const disabledEdit = record.record
    ? record.record.conta.is_caixa && !!record.record.parcela_venda_id
    : false;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {!statusValue && !record.record.parcelaCompra.is_cartao && (
        <Tooltip title="Efetivar" placement="bottom" aria-label="add2">
          <IconButton
            aria-label="efetivar"
            classes={{ root: classes.root }}
            size="small"
            onClick={() =>
              handleEfetivar(
                record.record ? record.record.id : -1,
                record.record ? record.record.parcelaCompra.is_cartao : false,
                record.record ? record.record.conta_id : false,
                statusValue,
              )
            }
          >
            <CheckCircleOutlineIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Editar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="edit"
          classes={{ root: classes.root }}
          size="small"
          onClick={() =>
            handleEdit(
              record.record ? record.record.id : -1,
              record.record ? record.record.parcelaCompra.is_cartao : false,
              record.record ? record.record.conta_id : false,
              statusValue,
            )
          }
          disabled={disabledEdit}
        >
          <EditOutlinedIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};



export default ActionsField;
