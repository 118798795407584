import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { PDFViewer } from '@react-pdf/renderer';
import moment from 'moment';

import { getListAllAPI } from '../../../services';
import {
  formatPeso,
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
} from '../../../utils';
import Document from './Document';

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex" style={{}}>
      <CircularProgress variant="determinate" {...props} color="secondary" />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const RelatorioFc = ({
  filters = { ativo: true },
  order = ['nome', 'asc'],
}) => {
  const [dados, setDados] = useState(null);
  const [progress, setProgress] = useState(0);

  async function getProdutos(categoria_id) {
    const dadosProdutos = await getListAllAPI(
      'produtos_relatorio',
      order,
      { categoria_id, ...filters },
      ['unidade'],
    );
    const produtos = [];
    for (let i = 0; i < dadosProdutos.data.length; i += 1) {
      const enableUnidades =
        dadosProdutos.data[i].unidade.tipo !== POR_PESO_VARIAVEL_SEM_UNIDADE;
      const enablePeso = dadosProdutos.data[i].unidade.tipo !== POR_UNIDADE;
      produtos.push({
        descricao: dadosProdutos.data[i].nome,
        codigo: dadosProdutos.data[i].codigo
          ? dadosProdutos.data[i].codigo
          : '',
        codigo_interno: dadosProdutos.data[i].codigo_interno
          ? dadosProdutos.data[i].codigo_interno
          : '',
        tecla: dadosProdutos.data[i].tecla ? dadosProdutos.data[i].tecla : '',
        unidades: enableUnidades
          ? dadosProdutos.data[i].unidadesDisponivel
          : '-',
        peso: enablePeso
          ? formatPeso(dadosProdutos.data[i].pesoDisponivel)
          : '-',
        tipoUnidade: dadosProdutos.data[i].unidade.nome,
      });
    }
    return produtos;
  }

  async function getCategorias() {
    const dadosCategorias = await getListAllAPI(
      'categorias',
      ['nome', 'asc'],
      {},
      [],
    );
    const categorias = [];
    for (let i = 0; i < dadosCategorias.data.length; i += 1) {
      const produtos = await getProdutos(dadosCategorias.data[i].id);
      categorias.push({
        nome: dadosCategorias.data[i].nome,
        produtos,
      });
    }
    return categorias;
  }

  async function getData() {
    setProgress(10);
    const categorias = await getCategorias();
    setProgress(80);
    setDados({
      categorias,
      data: moment().format('DD/MM/YYYY'),
    });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {dados !== null ? (
        <PDFViewer
          style={{ margin: 0, padding: 0, border: 0 }}
          width="100%"
          height="100%"
        >
          <Document {...dados} />
        </PDFViewer>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: '0.75',
          }}
        >
          <CircularProgressWithLabel value={progress} />
        </div>
      )}
    </>
  );
};

export default RelatorioFc;
