import React, {
  forwardRef,
  useEffect,
  useRef,
  useImperativeHandle,
  useState,
} from 'react';

import InputBase from '@material-ui/core/InputBase';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { getListAPI } from '../../../services';
import { STATUS_ATIVO } from '../../../utils/constants';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      opacity: '0.75',
    },
    rootInput: {
      marginLeft: theme.spacing(1),
      flex: 1,
      padding: '10px',
    },
    input: {
      padding: '0px 10px',
    },
    iconButton: {
      padding: 10,
      color: theme.palette.secondary.main,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  }),
);

const AutoCompleteProdutos = forwardRef(
  (
    {
      value,
      onChange,
      inputValue,
      updateValue,
      valueDebounce,
      label,
      handleKey,
      resource,
      nested,
      contemNumber,
      filters,
      mantemFiltroPadrao = true,
      disabled = false,
      ...rest
    },
    forwardedRef,
  ) => {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;
    const classes = useStyles();
    const [total, setTotal] = useState(0);

    const refAutoComplete = useRef(null);

    useImperativeHandle(forwardedRef, () => ({
      focus() {
        if (refAutoComplete.current) {
          refAutoComplete.current.focus();
        }
      },
    }));

    useEffect(() => {
      (async () => {
        if (open) {
          const produtos = await getListAPI(
            resource,
            ['id', 'asc'],
            [1, 10],
            mantemFiltroPadrao
              ? { nome: valueDebounce, status: STATUS_ATIVO, ...filters }
              : { nome: valueDebounce, ...filters },
            nested,
          );
          setTotal(produtos.total);
          setOptions(produtos.data);
        } else {
          setOptions([]);
        }
      })();
    }, [open]);

    useEffect(() => {
      (async () => {
        const produtos = await getListAPI(
          resource,
          ['id', 'asc'],
          [1, 10],
          mantemFiltroPadrao
            ? { nome: valueDebounce, status: STATUS_ATIVO, ...filters }
            : { nome: valueDebounce, ...filters },
          nested,
        );
        setTotal(produtos.total);
        setOptions(produtos.data);
      })();
    }, [valueDebounce]);

    return (
      <Autocomplete
        open={open && !contemNumber}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        value={value}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          updateValue(newInputValue);
        }}
        getOptionSelected={(option, value) => option.nome === value.nome}
        getOptionLabel={(option) => option.nome}
        options={options}
        loading={loading}
        fullWidth
        autoFocus
        disabled={disabled}
        className={classes.input}
        renderInput={(params) => (
          <InputBase
            {...rest}
            {...params}
            endAdornment={
              <div
                style={{
                  whiteSpace: 'nowrap',
                }}
              >{`Nº: ${total}`}</div>
            }
            color="secondary"
            className={{
              root: classes.rootInput,
              input: classes.input,
            }}
            placeholder={label}
            {...params.inputProps}
            ref={params.InputProps.ref}
            inputRef={refAutoComplete}
            autoFocus
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                if (!open || contemNumber) handleKey(e.keyCode, e.key);
                setOpen(false);
              } else {
                handleKey(e.keyCode, e.key);
              }
            }}
          />
        )}
      />
    );
  },
);

export default AutoCompleteProdutos;
