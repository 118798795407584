/*import React, { forwardRef } from 'react';
import { useRefresh } from 'react-admin';

import DialogoCreateEdit from './DialogoCreateEdit';

const Container = forwardRef((props, ref) => {
  const refresh = useRefresh();
  function handleClose() {
    refresh();
  }
  return <DialogoCreateEdit ref={ref} handleClose={handleClose} />;
});

export default Container;*/

import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
} from 'react';
import { useRefresh } from 'react-admin';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSnackbar } from 'notistack';

import {
  TextInput,
  //DialogoConfirmacao,
  DialogoCliente2,
} from '../../Components';
import { api } from '../../services';
import DialogoConfirmacao from './DialogoConfirmacao';
import DialogoCreateEdit from './DialogoCreateEdit';

const DialogoCreateVenda = forwardRef(({ handleClose = () => {} }, ref) => {
  const [open, setOpen] = useState(false);
  const refDialogoCreateEdit = useRef(null);
  const [numeroNota, setNumeroNota] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const refresh = useRefresh();
  const [venda, setVenda] = useState(null);

  const refDialogoConfirmacao = useRef(null);
  const refDialogoCliente = useRef(null);

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
      setNumeroNota('');
      setVenda(null);
    },
  }));

  function handleCloseLocal() {
    setOpen(false);
    handleClose();
    refresh();
    setVenda(null);
  }

  function openDialogoConformacao(venda) {
    refDialogoConfirmacao.current.handleOpen(venda);
  }

  async function handleSubmit() {
    try {
      const response = await api.get(
        `/get_venda_mais_recente_pelo_numero/${numeroNota}`,
      );

      const data = response.data;

      if (!data.data) {
        throw 'Venda Inexistente!';
      }

      const venda = data.data;

      if (venda.is_em_partes) {
        if (venda.porcentagem_parcial !== 100) {
          throw 'Uma venda em partes só pode ter devolução se ela já foi entregue 100%!';
        }
      }

      if (venda.cliente_id === 1) {
        //throw 'O cliente anônimo não pode ter devoluções!';
        openDialogoConformacao(venda);
      } else {
        setOpen(false);
        setNumeroNota('');
        if (refDialogoCreateEdit.current) {
          refDialogoCreateEdit.current.handleCreate(data.data.id);
        }
      }
    } catch (e) {
      enqueueSnackbar(`${e}`, {
        variant: 'error',
      });
      setOpen(false);
      setNumeroNota('');
    }
  }

  /*function validateNumeroNota() {
    let error = '';
    if (Number.isNaN(numeroNota)) {
      error = 'Este campo é obrigatório';
    } else if (numeroNota <= 0) {
      error = 'O número da nota dese ser um valor positivo maior que zero!';
    }
    return error;
  }*/

  function validateNumeroNota() {
    let error = '';
    if (!numeroNota) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  const messageNumeroNota = validateNumeroNota();

  function handleConfirma(record, id) {
    setVenda(record);
    if (id > 0) {
      alterarClienteNaVenda(id, record.id);
    } else if (refDialogoCliente.current) {
      refDialogoCliente.current.handleCreate();
    }
  }

  async function alterarClienteNaVenda(clienteId, vendaId) {
    const response = await api.put(
      `/update_cliente_in_venda/${vendaId}/${clienteId}`,
    );
    const data = response.data;
    if (data.status) {
      enqueueSnackbar(`Cliente da venda alterado com sucesso!`, {
        variant: 'success',
      });
      await handleSubmit();
    } else {
      enqueueSnackbar(`Erro ao alterar o cliente da venda!`, {
        variant: 'error',
      });
      setOpen(false);
      setNumeroNota('');
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Nova Devolução</DialogTitle>
        <DialogContent
          style={{
            padding: '20px',
            margin: '0',
          }}
          dividers
        >
          <TextInput
            name="numero_venda"
            handleEnter={() => {}}
            label="Número da Venda"
            handleKey={() => {}}
            value={numeroNota}
            onChange={(value) => setNumeroNota(value.target.value)}
            error={messageNumeroNota !== ''}
            helperText={messageNumeroNota}
            fullWidth
            autoFocus
          />
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button onClick={handleCloseLocal} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={messageNumeroNota !== ''}
          >
            Prosseguir
          </Button>
        </DialogActions>
      </Dialog>
      <DialogoCreateEdit
        ref={refDialogoCreateEdit}
        handleClose={handleCloseLocal}
      />
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={handleConfirma}
      />
      <DialogoCliente2
        ref={refDialogoCliente}
        handleClose={(clienteId) => {
          if (clienteId > 0) {
            enqueueSnackbar(`Cliente criado com sucesso!`, {
              variant: 'success',
            });
            alterarClienteNaVenda(clienteId, venda.id);
          } else {
            enqueueSnackbar(`Erro na criação do cliente!`, {
              variant: 'error',
            });
            setOpen(false);
            setNumeroNota(0);
          }
        }}
      />
    </div>
  );
});

export default DialogoCreateVenda;

// mudar aqui
