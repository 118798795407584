import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import { DateInputBonito, AutoCompleteRemoto } from '../../../Components';
import { createAPI, updateAPI, getListAllAPI } from '../../../services';
import { STATUS_ATIVO } from '../../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_ID = -1;

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [colaborador, setColaborador] = useState(null);
  const [fardamento, setFardamento] = useState(null);
  const [data, setData] = useState(new Date());

  const [colaboradorOriginalId, setColaboradorOriginalId] = useState(null);

  const [id, setId] = useState(DEFAULT_ID);

  const [carregando, setCarregando] = useState(false);

  const classes = useStyles({ cor: '#009FD4' });

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function reiniciar() {
    setId(DEFAULT_ID);
    setColaborador(null);
    setFardamento(null);
    setData(new Date());
    setColaboradorOriginalId(null);
    setCarregando(false);
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'camisas_checagem',
        ['id', 'asc'],
        { id: [id] },
        ['colaborador', 'fardamento'],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        setColaborador(data.data[0].colaborador);
        setColaboradorOriginalId(
          data.data[0].colaborador ? data.data[0].colaborador.id : null,
        );
        setFardamento(data.data[0].fardamento);
        setData(data.data[0].data);
        setCarregando(false);
      } else {
        throw 'Esta checagem de camisa não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    try {
      if (id <= 0) {
        await createAPI('camisas_checagem', {
          data,
          colaborador_id: colaborador.id,
          fardamento_id: fardamento.id,
        });
        setCarregando(false);
        enqueueSnackbar('Checagem de camisa criada com sucesso!', {
          variant: 'success',
        });
      } else {
        await updateAPI('camisas_checagem', id, {
          data,
          colaborador_id: colaborador.id,
          fardamento_id: fardamento.id,
        });
        setCarregando(false);
        enqueueSnackbar('Checagem de camisa atualizada com sucesso!', {
          variant: 'success',
        });
      }

      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      reiniciar();
      setOpen(true);
    },
    handleEdit(id) {
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
    },
  }));

  function validateColaborador() {
    let error = '';
    if (!colaborador) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateFardamento() {
    let error = '';
    if (!fardamento) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function getErros() {
    const errosOld = [''];
    errosOld[0] = validateColaborador();
    errosOld[1] = validateFardamento();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          {id < 0
            ? `Nova Checagem de fardamento`
            : `Editar Checagem de fardamento`}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <Box flex={1} mr="0.5em">
                <AutoCompleteRemoto
                  name="colaborador"
                  handleEnter={() => {}}
                  label="Colaborador"
                  handleKey={() => {}}
                  value={colaborador}
                  onChange={(value) => setColaborador(value)}
                  error={erros[0] !== ''}
                  helperText={erros[0]}
                  fullWidth
                  toString={false}
                  getOptionSelected={(option, value) =>
                    option.nome === value.nome
                  }
                  getOptionLabel={(option) => `${option.nome}`}
                  nested={[]}
                  resource="colaboradores"
                  filters={
                    colaboradorOriginalId
                      ? {
                          status: STATUS_ATIVO,
                          ids_incluso: [colaboradorOriginalId],
                        }
                      : { status: STATUS_ATIVO }
                  }
                />
              </Box>

              <Box flex={1} mr="0.5em">
                <AutoCompleteRemoto
                  name="fardamento"
                  handleEnter={() => {}}
                  label="Fardamento"
                  handleKey={() => {}}
                  value={fardamento}
                  onChange={(value) => setFardamento(value)}
                  error={erros[0] !== ''}
                  helperText={erros[0]}
                  fullWidth
                  toString={false}
                  getOptionSelected={(option, value) =>
                    option.nome === value.nome
                  }
                  getOptionLabel={(option) => `${option.nome}`}
                  nested={[]}
                  resource="fardamentos"
                />
              </Box>

              <DateInputBonito
                showTodayButton
                disableFuture
                todayLabel="Hoje"
                name="data_pagamento"
                handleEnter={() => {}}
                label=""
                handleKey={() => {}}
                value={data}
                onChange={(value) => {
                  setData(value);
                }}
                format="dd MMMM yyyy"
                fullWidth
                variant="standard"
              />
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          <div className={classes.wrapper}>
            <Button
              onClick={handleSalvar}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              disabled={erroExistente || carregando}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
            {carregando && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
