import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useState,
} from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import {
  formatMoeda,
  SECONDARY_COLOR,
  ATALHO_VOLTAR,
  ATALHO_AVANCAR,
} from '../../../../../utils';
import { IntegerInput } from '../../Inputs';

const inputs = [
  {
    nome: 'valor20000',
    valorNota: 200,
    label: 'Cédulas R$ 200,00',
  },
  {
    nome: 'valor10000',
    valorNota: 100,
    label: 'Cédulas R$ 100,00',
  },
  {
    nome: 'valor5000',
    valorNota: 50,
    label: 'Cédulas R$ 50,00',
  },
  {
    nome: 'valor2000',
    valorNota: 20,
    label: 'Cédulas R$ 20,00',
  },
  {
    nome: 'valor1000',
    valorNota: 10,
    label: 'Cédulas R$ 10,00',
  },
  {
    nome: 'valor500',
    valorNota: 5,
    label: 'Cédulas R$ 5,00',
  },
  {
    nome: 'valor200',
    valorNota: 2,
    label: 'Cédulas R$ 2,00',
  },
  {
    nome: 'valor100',
    valorNota: 1,
    label: 'Moedas R$ 1,00',
  },
  {
    nome: 'valor050',
    valorNota: 0.5,
    label: 'Moedas R$ 0,50',
  },
  {
    nome: 'valor025',
    valorNota: 0.25,
    label: 'Moedas R$ 0,25',
  },
  {
    nome: 'valor010',
    valorNota: 0.1,
    label: 'Moedas R$ 0,10',
  },
  {
    nome: 'valor005',
    valorNota: 0.05,
    label: 'Moedas R$ 0,05',
  },
];

const valoresReset = [];

for (let i = 0; i < inputs.length; i += 1) {
  valoresReset.push(0);
}

const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;

const DialogoContadorCedulas = forwardRef(
  ({ handleClose, handleSalvar, getTitle = () => 'Título' }, ref) => {
    const [open, setOpen] = React.useState(false);
    const refs = useRef([]);
    const [qtdeNotas, setQtdeNotas] = useState(valoresReset);

    refs.current = inputs.map(
      (ref, index) => (refs.current[index] = React.createRef()),
    );

    function calculaTotal() {
      let totalSaldo = 0;
      for (let i = 0; i < inputs.length; i += 1) {
        totalSaldo +=
          (Number.isNaN(qtdeNotas[i]) ? 0 : qtdeNotas[i]) * inputs[i].valorNota;
      }
      return totalSaldo;
    }

    const valorTotal = calculaTotal();

    function getRefNextInput(index) {
      if (index < inputs.length - 1) {
        return index + 1;
      }
      return 0;
    }

    function handleNextInput(index) {
      const position = getRefNextInput(index);
      if (position !== -1) {
        if (refs.current[position].current) {
          refs.current[position].current.focus();
          refs.current[position].current.select();
        }
      }
    }

    const handleCloseLocal = () => {
      setOpen(false);
      handleClose();
    };

    async function handleSalvarLocal() {
      const objNotas = {
        moedas_1_centavo: 0,
        moedas_5_centavos: Number.isNaN(qtdeNotas[11]) ? 0 : qtdeNotas[11],
        moedas_10_centavos: Number.isNaN(qtdeNotas[10]) ? 0 : qtdeNotas[10],
        moedas_25_centavos: Number.isNaN(qtdeNotas[9]) ? 0 : qtdeNotas[9],
        moedas_50_centavos: Number.isNaN(qtdeNotas[8]) ? 0 : qtdeNotas[8],
        moedas_1_real: Number.isNaN(qtdeNotas[7]) ? 0 : qtdeNotas[7],
        cedulas_2_reais: Number.isNaN(qtdeNotas[6]) ? 0 : qtdeNotas[6],
        cedulas_5_reais: Number.isNaN(qtdeNotas[5]) ? 0 : qtdeNotas[5],
        cedulas_10_reais: Number.isNaN(qtdeNotas[4]) ? 0 : qtdeNotas[4],
        cedulas_20_reais: Number.isNaN(qtdeNotas[3]) ? 0 : qtdeNotas[3],
        cedulas_50_reais: Number.isNaN(qtdeNotas[2]) ? 0 : qtdeNotas[2],
        cedulas_100_reais: Number.isNaN(qtdeNotas[1]) ? 0 : qtdeNotas[1],
        cedulas_200_reais: Number.isNaN(qtdeNotas[0]) ? 0 : qtdeNotas[0],
      };
      handleSalvar(calculaTotal(), objNotas);
      setOpen(false);
    }

    function resetarValores() {
      setQtdeNotas(valoresReset);
    }

    useImperativeHandle(ref, () => ({
      handleOpen() {
        resetarValores();
        setOpen(true);
      },
    }));

    function handleKey(action, name) {
      switch (name) {
        case FECHAR_VENDA_ACTION:
          handleSalvarLocal();
          break;
        case CANCELAR_VENDA_ACTION:
          handleCloseLocal();
          break;
        default:
          break;
      }
    }

    function updateNota(index, value) {
      const gridOld = qtdeNotas.slice();
      gridOld[index] = value;
      setQtdeNotas(gridOld);
    }

    return (
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          disableBackdropClick
          disableEscapeKeyDown
          scroll="paper"
        >
          <DialogTitle
            id="form-dialog-title"
            style={{ color: 'white', backgroundColor: SECONDARY_COLOR }}
          >
            {getTitle(valorTotal)}
          </DialogTitle>
          <DialogContent dividers>
            <div style={{ height: '100%' }}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                padding="0px"
                overflow="auto"
              >
                {inputs.map((input, indexInput) => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{ color: SECONDARY_COLOR, width: '75px' }}
                    >{`${formatMoeda(input.valorNota)}`}</div>
                    <div style={{ marginLeft: '10px' }}>X</div>
                    <div style={{ marginLeft: '10px' }}>
                      <IntegerInput
                        name={inputs[indexInput].nome}
                        ref={refs.current[indexInput]}
                        handleEnter={() => handleNextInput(indexInput)}
                        handleKey={handleKey}
                        value={
                          Number.isNaN(qtdeNotas[indexInput])
                            ? ''
                            : String(qtdeNotas[indexInput])
                        }
                        onChange={(value) =>
                          updateNota(
                            indexInput,
                            parseInt(value.target.value, 10),
                          )
                        }
                        autoFocus={indexInput === 0}
                        size="small"
                        variant="standard"
                        style={{
                          padding: 0,
                          margin: 0,
                          width: '50px',
                          border: 'none',
                          outline: 'none',
                        }}
                        margin="none"
                        styleInput={{ textAlign: 'center' }}
                        autoComplete="off"
                      />
                    </div>
                    <div style={{ marginLeft: '10px' }}>{' = '}</div>
                    <div
                      style={{ width: '150px', marginLeft: '10px' }}
                    >{`${formatMoeda(
                      Number.isNaN(qtdeNotas[indexInput])
                        ? 0
                        : qtdeNotas[indexInput] * input.valorNota,
                    )}`}</div>
                  </div>
                ))}
              </Box>
            </div>
          </DialogContent>
          <DialogActions
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Button
              onClick={handleCloseLocal}
              color="secondary"
              variant="contained"
              tabindex="-1"
            >
              {`Cancelar (${CANCELAR_VENDA_ACTION})`}
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleSalvarLocal}
              tabindex="-1"
            >
              {`Obter (${FECHAR_VENDA_ACTION})`}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoContadorCedulas;
