import React, { useState, forwardRef, useImperativeHandle } from 'react';

import { Box } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CloseIcon from '@material-ui/icons/Close';
import StayCurrentPortraitIcon from '@material-ui/icons/StayCurrentPortrait';
import TodayIcon from '@material-ui/icons/Today';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  PrecoInput,
  DateInputBonito,
  AutoCompleteLocalCustomizado,
} from '../../Components';
import { getListAllAPI, updateAPI } from '../../services';
import { STATUS_ATIVO } from '../../utils/constants';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const RECEITA = 'Receita';
const DESPESA = 'Despesa';

const useStyles3 = makeStyles((theme) => ({
  chip: {
    borderColor: (props) => props.color,
    backgroundColor: 'white',
    cursor: 'default',
    color: (props) => props.color,
    '&:hover': {
      color: (props) => props.color,
      backgroundColor: 'white',
    },
  },
  deleteIcon: {
    color: (props) => props.color,
    cursor: 'pointer',
    '&:hover': {
      color: (props) => props.color,
    },
  },
}));

const ChipConta = ({ option, injetado, campo }) => {
  const classes2 = useStyles3({ color: option.color });
  const { onDelete, ...rest } = injetado;
  return (
    <Chip
      classes={{ outlined: classes2.chip, deleteIcon: classes2.deleteIcon }}
      variant="outlined"
      label={option[campo]}
      {...rest}
      avatar={
        <Avatar
          style={{
            backgroundColor: option.color,
            marginRight: '3px',
            color: 'white',
          }}
        >
          {option.nome.charAt(0)}
        </Avatar>
      }
    />
  );
};

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose, cor = '#2196f3', campo = 'nome' }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [conta, setConta] = useState([]);
    const [valor, setValor] = useState(0);
    const [dataPagamento, setDataPagamento] = useState(
      moment().format('YYYY-MM-DD'),
    );
    const [contasOptions, setContasOptions] = useState([]);
    const [id, setId] = useState(-1);
    const [tipoTransacao, setTipoTransacao] = useState(RECEITA);
    const [carregando, setCarregando] = useState(false);
    const [status, setStatus] = useState(false);
    const classes = useStyles();

    async function getContas(id = 0) {
      const dataContas = await getListAllAPI(
        'contas_normal',
        ['id', 'asc'],
        id === 0
          ? { status: STATUS_ATIVO }
          : { status: STATUS_ATIVO, ids_incluso: [id] },
        [],
      );
      if (dataContas.data.length > 0) setConta([dataContas.data[0]]);
      setContasOptions(dataContas.data);
    }

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    async function getDataResource(id) {
      try {
        const data = await getListAllAPI(
          'transacoes',
          ['id', 'asc'],
          { id: [id] },
          ['conta'],
        );
        if (data.data.length > 0) {
          if (data.data[0].is_transferencia) {
            setCarregando(false);
            throw 'Não pode mudar o status de transferências!';
          } else {
            await getContas(data.data[0].conta.id);
            setConta([data.data[0].conta]);
            setValor(data.data[0].valor);
            setStatus(data.data[0].visible);
            setDataPagamento(new Date());
            setTipoTransacao(data.data[0].tipo === RECEITA ? RECEITA : DESPESA);
            setCarregando(false);
          }
        } else {
          setCarregando(false);
          throw 'Esta transação não existe!';
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    async function handleSalvar() {
      setCarregando(true);
      try {
        if (id <= 0) {
          setCarregando(false);
          enqueueSnackbar('Nenhuma transação aqui!!!', {
            variant: 'error',
          });
        } else {
          await updateAPI('transacoes/pix/efetivar', id, {
            valor,
            conta_id: conta[0].id,
            data_pagamento: dataPagamento,
          });
          enqueueSnackbar(`${tipoTransacao} confirmada com sucesso!`, {
            variant: 'success',
          });
          setCarregando(false);
        }
        handleCloseDialog();
      } catch (erros) {
        setCarregando(false);
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    useImperativeHandle(ref, () => ({
      handleOpen(id) {
        setCarregando(true);
        getDataResource(id);
        setOpen(true);
        setId(id);
      },
    }));

    const errorValor =
      valor <= 0 || Number.isNaN(valor) ? 'Este campo é obrigatório' : '';
    const errorDataPagamento =
      dataPagamento === null || dataPagamento === '' ? 'Data Inválida!' : '';
    const errorConta = conta.length <= 0 ? 'Este campo é obrigatório' : '';

    function isDisabledSubmit() {
      return (
        errorValor !== '' || errorDataPagamento !== '' || errorConta !== ''
      );
    }

    const renderTag = (option, injetado) => (
      <ChipConta option={option} injetado={injetado} campo={campo} />
    );

    const renderOption = (option) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          style={{
            backgroundColor: option.color,
            marginRight: '10px',
            width: '30px',
            height: '30px',
          }}
        >
          {option.nome.charAt(0)}
        </Avatar>
        {option[campo]}
      </div>
    );

    const handleOnChangeConta = (value) => {
      if (value.length > 0) {
        if (value.length === 1) {
          setConta([value[0]]);
        } else if (value.length === 2) {
          setConta([value[1]]);
        }
      } else {
        setConta([]);
      }
    };

    function getColor() {
      switch (tipoTransacao) {
        case RECEITA:
          return '#4caf50';
        case DESPESA:
          return '#f44336';
        default:
          return '#009FD4';
      }
    }

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullWidth
          maxWidth="sm"
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: getColor(), color: 'white' }}
          >
            {`Deseja ${
              status ? 'Desefetivar' : 'Efetivar'
            } esta ${tipoTransacao} ?`}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div>
                <div
                  style={{
                    padding: '10px',
                    marginBottom: '12px',
                    color: 'rgba(0, 0, 0, 0.54)',
                    fontSize: '1rem',
                    fontWeight: 400,
                    lineHeight: 1.5,
                  }}
                >
                  {tipoTransacao === DESPESA
                    ? 'Ao efetivar essa despesa será descontado o valor na Conta'
                    : 'Ao efetivar essa receita será acrescido o valor na Conta'}
                </div>
                <Box marginBottom="24px">
                  <PrecoInput
                    name="valor"
                    handleEnter={() => {}}
                    label=""
                    handleKey={() => {}}
                    value={Number.isNaN(valor) ? '' : String(valor)}
                    onChange={(value) => {
                      const newValue = parseFloat(value.target.value);
                      if (newValue <= 0 || Number.isNaN(newValue)) {
                        setValor(0);
                      } else {
                        setValor(newValue);
                      }
                    }}
                    error={errorValor !== ''}
                    helperText={errorValor}
                    fullWidth
                    variant="standard"
                    disabled
                    InputProps={{
                      startAdornment: (
                        <>
                          <StayCurrentPortraitIcon
                            style={{ marginRight: '24px' }}
                          />
                        </>
                      ),
                    }}
                  />
                </Box>
                <Box marginBottom="24px">
                  <DateInputBonito
                    showTodayButton
                    todayLabel="Hoje"
                    name="data_pagamento"
                    handleEnter={() => {}}
                    label=""
                    handleKey={() => {}}
                    value={dataPagamento}
                    onChange={(value) => setDataPagamento(value)}
                    format="dd MMMM yyyy"
                    fullWidth
                    variant="standard"
                    disabled
                    InputProps={{
                      startAdornment: (
                        <>
                          <TodayIcon style={{ marginRight: '24px' }} />
                        </>
                      ),
                    }}
                  />
                </Box>
                <AutoCompleteLocalCustomizado
                  name="conta"
                  handleEnter={() => {}}
                  label=""
                  handleKey={() => {}}
                  value={conta}
                  disableClearable
                  onChangeLocal={(value) => handleOnChangeConta(value)}
                  campo="nome"
                  options={contasOptions}
                  variant="standard"
                  renderTag={renderTag}
                  renderOption={renderOption}
                  error={errorConta !== ''}
                  helperText={errorConta}
                  startAdornment={
                    <InputAdornment position="start">
                      <AccountBalanceIcon />
                    </InputAdornment>
                  }
                />
              </div>
            )}
          </DialogContent>
          <DialogActions
            style={{
              padding: '32px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={handleCloseDialog}
              variant="outlined"
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              color="primary"
            >
              Cancelar
            </Button>
            <Box>
              <div className={classes.wrapper}>
                <Button
                  onClick={handleSalvar}
                  classes={{
                    root: classes.btnAtions,
                    disabled: classes.btnAtionsDisabled,
                  }}
                  disabled={isDisabledSubmit() || carregando}
                  variant="contained"
                  color="primary"
                >
                  {tipoTransacao === RECEITA ? 'Receber' : 'Pagar'}
                </Button>
                {carregando && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Box>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
