import * as React from 'react';
import { useRecordContext } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useSnackbar } from 'notistack';

import { imprimirRetiradaMercadoria } from '../../services';

const ActionsField = ({ handleDelete, handleShow, ...rest }) => {
  const record = useRecordContext(rest);
  const { enqueueSnackbar } = useSnackbar();

  async function imprimir(id) {
    if (id > 0) {
      const codigo = await imprimirRetiradaMercadoria(id);
      if (codigo < 0) {
        enqueueSnackbar('Erro ao imprimir a nota!', {
          variant: 'error',
        });
      }
    }
  }

  return (
    <div
      style={{
        marginRight: '0px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Tooltip title="Status" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleDelete(record.record)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Detalhes" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleShow(record.record)}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Imprimir" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="imprimir"
          size="small"
          onClick={() => imprimir(record ? record.record.id : -1)}
        >
          <PrintIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ActionsField;
