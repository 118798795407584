import { makeStyles } from '@material-ui/core';

const useStylesHeader = makeStyles((theme) => ({
  row: {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
  },
}));

export default useStylesHeader;
