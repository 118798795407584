import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Rating from '@material-ui/lab/Rating';
import { useSnackbar } from 'notistack';

import { TextInput, ColorInput, SwitchInput } from '../../../Components';
import { createAPI, updateAPI, getListAllAPI } from '../../../services';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_NOME = '';
const DEFAULT_DESCRICAO = '';
const DEFAULT_COLOR = '#f44336';
const DEFAULT_URGENCIA = 1;
const DEFAULT_DIFICULDADE = 1;
const DEFAULT_ID = -1;

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [nome, setNome] = useState(DEFAULT_NOME);
  const [descricao, setDescricao] = useState(DEFAULT_DESCRICAO);

  const [color, setColor] = useState(DEFAULT_COLOR);
  const [id, setId] = useState(DEFAULT_ID);

  const [urgencia, setUrgencia] = useState(DEFAULT_URGENCIA);
  const [dificuldade, setDificuldade] = useState(DEFAULT_DIFICULDADE);
  const [status, setStatus] = useState(false);

  const [carregando, setCarregando] = useState(false);

  const classes = useStyles({ cor: '#009FD4' });

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function reiniciar() {
    setId(DEFAULT_ID);
    setNome(DEFAULT_NOME);
    setDescricao(DEFAULT_DESCRICAO);
    setColor(DEFAULT_COLOR);
    setUrgencia(DEFAULT_URGENCIA);
    setDificuldade(DEFAULT_DIFICULDADE);
    setStatus(false);
    setCarregando(false);
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'features',
        ['id', 'asc'],
        { id: [id] },
        [],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        setNome(data.data[0].nome);
        setDescricao(data.data[0].descricao);
        setColor(data.data[0].color);
        setUrgencia(data.data[0].urgencia);
        setDificuldade(data.data[0].dificuldade);
        setStatus(data.data[0].status);
        setCarregando(false);
      } else {
        throw 'Esta feature não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    try {
      if (id <= 0) {
        await createAPI('features', {
          nome,
          color,
          urgencia,
          dificuldade,
          descricao,
          status,
        });
        setCarregando(false);
        enqueueSnackbar('Feature criada com sucesso!', {
          variant: 'success',
        });
      } else {
        await updateAPI('features', id, {
          nome,
          color,
          urgencia,
          dificuldade,
          descricao,
          status,
        });
        setCarregando(false);
        enqueueSnackbar('Feature atualizada com sucesso!', {
          variant: 'success',
        });
      }

      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      reiniciar();
      setOpen(true);
    },
    handleEdit(id) {
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
    },
  }));

  function validateNome() {
    let error = '';
    if (!nome) error = 'Este campo é obrigatório';
    else if (nome.length > 80) {
      error = 'Número de caracteres maior que o permitido (máximo 80)';
    }
    return error;
  }

  function validateDescricao() {
    let error = '';
    if (!descricao) {
      // error = 'Este campo é obrigatório';
    } else if (descricao.length > 200) {
      error = 'Número de caracteres maior que o permitido (máximo 200)';
    }
    return error;
  }

  function getErros() {
    const errosOld = ['', ''];
    errosOld[0] = validateNome();
    errosOld[1] = validateDescricao();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  const marginLine = 2;

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          {id < 0 ? `Nova Feature` : `Editar Feature`}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <Box
                component="fieldset"
                mb={marginLine}
                borderColor="transparent"
              >
                <TextInput
                  name="nome"
                  handleEnter={() => {}}
                  label="Descrição"
                  handleKey={() => {}}
                  value={nome}
                  onChange={(value) => setNome(value.target.value)}
                  error={erros[0] !== ''}
                  helperText={erros[0]}
                  fullWidth
                  autoFocus
                  variant="standard"
                />
              </Box>
              <Box
                component="fieldset"
                mb={marginLine}
                borderColor="transparent"
              >
                <Typography component="legend">Urgência</Typography>
                <Rating
                  name="urgencia"
                  value={urgencia}
                  max={10}
                  onChange={(event, newValue) => {
                    setUrgencia(newValue);
                  }}
                  precision={0.25}
                />
              </Box>
              <Box
                component="fieldset"
                mb={marginLine}
                borderColor="transparent"
              >
                <Typography component="legend">Relevância</Typography>
                <Rating
                  name="dificuldade"
                  value={dificuldade}
                  max={10}
                  onChange={(event, newValue) => {
                    setDificuldade(newValue);
                  }}
                  precision={0.25}
                />
              </Box>
              <Box
                component="fieldset"
                mb={marginLine}
                borderColor="transparent"
              >
                <div>
                  <div>Cor</div>
                </div>
                <div style={{ margin: '16px 0px 0px' }}>
                  <ColorInput
                    value={color}
                    onChange={(newColor) => setColor(newColor)}
                  />
                </div>
              </Box>
              <SwitchInput
                name="status"
                handleEnter={() => {}}
                label="Status"
                handleKey={() => {}}
                checked={status}
                onChange={(value) => setStatus(value.target.checked)}
              />
              <TextInput
                name="detalhes"
                handleEnter={() => {}}
                label="Detalhes"
                handleKey={() => {}}
                value={descricao}
                onChange={(value) => setDescricao(value.target.value)}
                error={erros[1] !== ''}
                helperText={erros[1]}
                fullWidth
                autoFocus
                variant="standard"
                multiline
                rows={4}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          <div className={classes.wrapper}>
            <Button
              onClick={handleSalvar}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              disabled={erroExistente || carregando}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
            {carregando && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
