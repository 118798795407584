import React from 'react';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
});

export default function CardItemBaixar({
  handleBaixar = () => {},
  title = '',
  desciption = '',
  link = '',
}) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          component="img"
          alt={title}
          height="140"
          image={link}
          title={title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {desciption}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button size="small" color="primary" onClick={() => handleBaixar()}>
          Baixar
        </Button>
      </CardActions>
    </Card>
  );
}
