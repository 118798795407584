import React, { useImperativeHandle, forwardRef, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  makeStyles,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { api } from '../../../../services';
import {
  SECONDARY_COLOR,
  ATALHO_AVANCAR,
  ATALHO_VOLTAR,
} from '../../../../utils';
import { TextInput } from '../../../Inputs';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {},
  withoutLabel: {},
  textField: {},
}));

const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;

const DialogoNota = forwardRef(
  (
    {
      handleCancelar,
      handleSalvar,
      title,
      enqueueSnackbar,
      mensagemSucesso = 'Item removido com sucesso!',
    },
    ref,
  ) => {
    const [open, setOpen] = useState(false);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [codigo, setCodigo] = useState(0);

    const handleChange = (event) => {
      setPassword(event.target.value);
    };

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const handleCloseDialog = () => {
      setOpen(false);
      setPassword('');
      handleCancelar();
    };

    useImperativeHandle(ref, () => ({
      handleOpen(codigoNew = 0) {
        setOpen(true);
        setCodigo(codigoNew);
      },
    }));

    async function handleSalvarDialog() {
      if (!erroExistente) {
        const dataConfig = await api.get('/config/1');
        if (password === dataConfig.data.senha_alterar_preco_frente_caixa) {
          handleSalvar(codigo);
          setOpen(false);
          setPassword('');
          enqueueSnackbar(mensagemSucesso, {
            variant: 'success',
          });
        } else {
          setOpen(false);
          setPassword('');
          enqueueSnackbar('Senha incorreta!', {
            variant: 'error',
          });
        }
      }
    }

    function handleKey(action, name) {
      switch (name) {
        case FECHAR_VENDA_ACTION:
          handleSalvarDialog();
          break;
        case CANCELAR_VENDA_ACTION:
          handleCloseDialog();
          break;
        default:
          break;
      }
    }

    function validateSenha() {
      let error = '';
      if (!password) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function getErros() {
      const errosOld = ['', ''];
      errosOld[0] = validateSenha();

      return errosOld;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    return (
      <div>
        <Dialog
          open={open}
          onClose={handleCloseDialog}
          aria-labelledby="form-dialog-title"
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle
            id="form-dialog-title"
            style={{ color: 'white', backgroundColor: SECONDARY_COLOR }}
          >
            {title}
          </DialogTitle>
          <DialogContent>
            <TextInput
              type={showPassword ? 'text' : 'password'}
              label="Senha Gerencial"
              value={password}
              onChange={handleChange}
              fullWidth
              handleKey={handleKey}
              handleEnter={() => {}}
              error={erros[0] !== ''}
              helperText={erros[0]}
              autoFocus
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </DialogContent>
          <DialogActions
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Button
              onClick={handleCloseDialog}
              color="secondary"
              variant="contained"
              tabindex="-1"
            >
              {`Cancelar (${CANCELAR_VENDA_ACTION})`}
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleSalvarDialog}
              disabled={erroExistente}
              tabindex="-1"
            >
              {`Confirmar (${FECHAR_VENDA_ACTION})`}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoNota;
