import React from 'react';

import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

import {
  STATUS_ATIVO,
  TIPO_FERIADO_DATA_COMEMORATIVA,
  TIPO_FERIADO_FERIADO,
  TURNO_MANHA,
  TURNO_NOITE,
  TURNO_12_HORAS,
  formatDateDDMMYYYY,
} from '../../../../../utils';

const styles = StyleSheet.create({
  page: {},
  body: {
    width: '100%',
    fontSize: '5',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    borderBottom: '1 solid black',
  },
  headerFinais: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    borderBottom: '1 solid black',
  },
  header2: {
    display: 'flex',
    backgroundColor: '#E4E4E4',
    color: 'black',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '5',
    fontWeight: 'bold',
    borderBottom: '1 solid black',
    padding: '2px 0',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid black',
  },
  contentTotais: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid black',
    backgroundColor: '#ffff00',
  },
  contentTotaisTotais: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid black',
    backgroundColor: '#f96c25',
  },
  item: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    //marginTop: '3px',
    //marginBottom: '3px',
    padding: '2px 0',
  },
  item3: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '2px',
    marginBottom: '2px',
  },
  item4: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffff00',
    marginTop: '2px',
    marginBottom: '2px',
  },
  item5: {
    margin: 0,
    flex: 1,
  },
  negativo: {
    backgroundColor: '#ffc7cf',
  },
  positivo: {
    backgroundColor: '#cbf5d0',
  },
});

const dadosSelecionado = {
  tipoJornada: 'SS',
  dias: [
    {
      descricao: 'sexta-feira, dezembro 01, 2023',
      descricao2: '01/12/2023 (terça)',
      tipo: TIPO_FERIADO_FERIADO,
      dia_semana: 1,
      ponto: {
        horarioEntrada1: '11:48',
        horarioSaida1: '14:59',
        horarioEntrada2: '16:56',
        horarioSaida2: '22:03',
        horasTrabalhadasTurno1: '3h 10min',
        horasTrabalhadasTurno2: '5h 6min',
        horasTrabalhadasTotais: '8h 17min',
        horasExtras: '0h 13min',
        horasExtrasComTolerancia: '0h 13min',
        tipoJornada: 'SS',
      },
    },
    {
      descricao: 'sexta-feira, dezembro 01, 2023',
      tipo: TIPO_FERIADO_FERIADO,
      dia_semana: 1,
      ponto: {
        horarioEntrada1: '11:48',
        horarioSaida1: '14:59',
        horarioEntrada2: '16:56',
        horarioSaida2: '22:03',
        horasTrabalhadasTurno1: '3h 10min',
        horasTrabalhadasTurno2: '5h 6min',
        horasTrabalhadasTotais: '8h 17min',
        horasExtras: '0h 13min',
        horasExtrasComTolerancia: '0h 13min',
        tipoJornada: 'SS',
      },
    },
    {
      descricao: 'sexta-feira, dezembro 01, 2023',
      tipo: TIPO_FERIADO_FERIADO,
      dia_semana: 1,
      ponto: {
        horarioEntrada1: '11:48',
        horarioSaida1: '14:59',
        horarioEntrada2: '16:56',
        horarioSaida2: '22:03',
        horasTrabalhadasTurno1: '3h 10min',
        horasTrabalhadasTurno2: '5h 6min',
        horasTrabalhadasTotais: '8h 17min',
        horasExtras: '0h 13min',
        horasExtrasComTolerancia: '0h 13min',
        tipoJornada: 'SS',
      },
    },
    {
      descricao: 'sexta-feira, dezembro 01, 2023',
      tipo: TIPO_FERIADO_FERIADO,
      dia_semana: 1,
      ponto: {
        horarioEntrada1: '11:48',
        horarioSaida1: '14:59',
        horarioEntrada2: '16:56',
        horarioSaida2: '22:03',
        horasTrabalhadasTurno1: '3h 10min',
        horasTrabalhadasTurno2: '5h 6min',
        horasTrabalhadasTotais: '8h 17min',
        horasExtras: '0h 13min',
        horasExtrasComTolerancia: '0h 13min',
        tipoJornada: 'SS',
      },
    },
  ],
};

const dadosHeader = {
  cargo: 'Repositor',
  cnpj: '-',
  dataAdmissao: '11/02/2020',
  nomeColaborador: 'Anderson Batista de Oliveira',
  nomeFantasia: '-',
  periodoApuracao: 'set.-23',
  razaoSocial: '-',
  totalHorasExtras: 'R$ 7,36',
  atestados: '0',
  domingosTrabalhados: '0',
  feriadosTrabalhados: '0',
  folgas: '0',
  horasExtras: '0h 42min	',
  horasExtrasTolerancia: '0h 42min',
  somaHorasTrabalhadas: '24h 54min',
};

function getGradeHorarios(turno, isSS) {
  if (turno === TURNO_MANHA) {
    //manha
    //

    if (isSS) {
      return (
        <View>
          <View style={[{ display: 'flex', flexDirection: 'row' }]}>
            <Text
              style={{
                flex: 1,
                backgroundColor: '#E4E4E4',
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              Entrada 1
            </Text>
            <Text
              style={{
                flex: 1,
                backgroundColor: '#E4E4E4',
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              Entrada 2
            </Text>
          </View>
          <View style={[{ display: 'flex', flexDirection: 'row' }]}>
            <Text
              style={{
                flex: 1,
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              07:00:00
            </Text>
            <Text
              style={{
                flex: 1,
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              14:00:00
            </Text>
          </View>
          <View style={[{ display: 'flex', flexDirection: 'row' }]}>
            <Text
              style={{
                flex: 1,
                backgroundColor: '#E4E4E4',
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              Saída 1
            </Text>
            <Text
              style={{
                flex: 1,
                backgroundColor: '#E4E4E4',
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              Saída 2
            </Text>
          </View>
          <View style={[{ display: 'flex', flexDirection: 'row' }]}>
            <Text
              style={{
                flex: 1,
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              12:00:00
            </Text>
            <Text
              style={{
                flex: 1,
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              16:20:00
            </Text>
          </View>
          <View style={[{ display: 'flex', flexDirection: 'row' }]}>
            <Text
              style={{
                flex: 1,
                backgroundColor: '#E4E4E4',
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              Soma das Horas
            </Text>
            <Text
              style={{
                flex: 1,
                backgroundColor: '#E4E4E4',
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              Soma das Horas
            </Text>
          </View>
          <View style={[{ display: 'flex', flexDirection: 'row' }]}>
            <Text
              style={{
                flex: 1,
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              05:00:00
            </Text>
            <Text
              style={{
                flex: 1,
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              02:20:00
            </Text>
          </View>
          <View style={[{ display: 'flex', flexDirection: 'row' }]}>
            <Text
              style={{
                flex: 1,
                fontWeight: 'bold',
                backgroundColor: '#E4E4E4',
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              Carga Horária
            </Text>
            <Text
              style={{
                flex: 1,
                backgroundColor: '#E4E4E4',
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              07:20:00
            </Text>
          </View>
          <View style={[{ display: 'flex', flexDirection: 'row' }]}>
            <Text
              style={{
                flex: 1,
                fontWeight: 'bold',
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              Almoço
            </Text>
            <Text
              style={{
                flex: 1,
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              02:00:00
            </Text>
          </View>
        </View>
      );
    }
    return (
      <View>
        <View style={[{ display: 'flex', flexDirection: 'row' }]}>
          <Text
            style={{
              flex: 1,
              fontWeight: 'bold',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            DIA NORMAL
          </Text>
          <Text
            style={{
              flex: 1,
              fontWeight: 'bold',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            DOMINGO
          </Text>
        </View>
        <View style={[{ display: 'flex', flexDirection: 'row' }]}>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Entrada 1
          </Text>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Entrada 2
          </Text>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Entrada 1
          </Text>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Entrada 2
          </Text>
        </View>
        <View style={[{ display: 'flex', flexDirection: 'row' }]}>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            07:00:00
          </Text>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            14:00:00
          </Text>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            07:00:00
          </Text>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            -
          </Text>
        </View>
        <View style={[{ display: 'flex', flexDirection: 'row' }]}>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Saída 1
          </Text>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Saída 2
          </Text>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Saída 1
          </Text>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Saída 2
          </Text>
        </View>
        <View style={[{ display: 'flex', flexDirection: 'row' }]}>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            12:00:00
          </Text>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            17:04:00
          </Text>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            13:00:00
          </Text>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            -
          </Text>
        </View>
        <View style={[{ display: 'flex', flexDirection: 'row' }]}>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Soma das Horas
          </Text>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Soma das Horas
          </Text>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Soma das Horas
          </Text>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Soma das Horas
          </Text>
        </View>
        <View style={[{ display: 'flex', flexDirection: 'row' }]}>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            05:00:00
          </Text>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            03:04:00
          </Text>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            06:00:00
          </Text>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            00:00:00
          </Text>
        </View>
        <View style={[{ display: 'flex', flexDirection: 'row' }]}>
          <Text
            style={{
              flex: 1,
              fontWeight: 'bold',
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Carga Horária
          </Text>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            08:04:00
          </Text>
          <Text
            style={{
              flex: 1,
              fontWeight: 'bold',
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Carga Horária
          </Text>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            06:00:00
          </Text>
        </View>
        <View style={[{ display: 'flex', flexDirection: 'row' }]}>
          <Text
            style={{
              flex: 1,
              fontWeight: 'bold',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Almoço
          </Text>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            02:00:00
          </Text>
          <Text
            style={{
              flex: 1,
              fontWeight: 'bold',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Almoço
          </Text>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            -
          </Text>
        </View>
      </View>
    );
  }

  if (turno === TURNO_NOITE) {
    //NOITE

    if (isSS) {
      return (
        <View>
          <View style={[{ display: 'flex', flexDirection: 'row' }]}>
            <Text
              style={{
                flex: 1,
                backgroundColor: '#E4E4E4',
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              Entrada 1
            </Text>
            <Text
              style={{
                flex: 1,
                backgroundColor: '#E4E4E4',
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              Entrada 2
            </Text>
          </View>
          <View style={[{ display: 'flex', flexDirection: 'row' }]}>
            <Text
              style={{
                flex: 1,
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              12:00:00
            </Text>
            <Text
              style={{
                flex: 1,
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              16:20:20
            </Text>
            <Text />
          </View>
          <View style={[{ display: 'flex', flexDirection: 'row' }]}>
            <Text
              style={{
                flex: 1,
                backgroundColor: '#E4E4E4',
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              Saída 1
            </Text>
            <Text
              style={{
                flex: 1,
                backgroundColor: '#E4E4E4',
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              Saída 2
            </Text>
          </View>
          <View style={[{ display: 'flex', flexDirection: 'row' }]}>
            <Text
              style={{
                flex: 1,
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              14:20:00
            </Text>
            <Text
              style={{
                flex: 1,
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              21:20:00
            </Text>
          </View>
          <View style={[{ display: 'flex', flexDirection: 'row' }]}>
            <Text
              style={{
                flex: 1,
                backgroundColor: '#E4E4E4',
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              Soma das Horas
            </Text>
            <Text
              style={{
                flex: 1,
                backgroundColor: '#E4E4E4',
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              Soma das Horas
            </Text>
          </View>
          <View style={[{ display: 'flex', flexDirection: 'row' }]}>
            <Text
              style={{
                flex: 1,
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              02:20:00
            </Text>
            <Text
              style={{
                flex: 1,
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              05:00:00
            </Text>
          </View>
          <View style={[{ display: 'flex', flexDirection: 'row' }]}>
            <Text
              style={{
                flex: 1,
                fontWeight: 'bold',
                backgroundColor: '#E4E4E4',
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              Carga Horária
            </Text>
            <Text
              style={{
                flex: 1,
                backgroundColor: '#E4E4E4',
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              07:20:00
            </Text>
          </View>
          <View style={[{ display: 'flex', flexDirection: 'row' }]}>
            <Text
              style={{
                flex: 1,
                fontWeight: 'bold',
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              Almoço
            </Text>
            <Text
              style={{
                flex: 1,
                textAlign: 'center',
                border: '1 solid black',
                padding: '2px 0',
              }}
            >
              02:00:00
            </Text>
          </View>
        </View>
      );
    }
    return (
      <View>
        <View style={[{ display: 'flex', flexDirection: 'row' }]}>
          <Text
            style={{
              flex: 1,
              fontWeight: 'bold',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            DIA NORMAL
          </Text>
          <Text
            style={{
              flex: 1,
              fontWeight: 'bold',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            DOMINGO
          </Text>
        </View>
        <View style={[{ display: 'flex', flexDirection: 'row' }]}>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Entrada 1
          </Text>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Entrada 2
          </Text>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Entrada 1
          </Text>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Entrada 2
          </Text>
        </View>
        <View style={[{ display: 'flex', flexDirection: 'row' }]}>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            11:46:00
          </Text>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            16:50:00
          </Text>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            12:00:00
          </Text>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          />
        </View>
        <View style={[{ display: 'flex', flexDirection: 'row' }]}>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Saída 1
          </Text>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Saída 2
          </Text>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Saída 1
          </Text>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Saída 2
          </Text>
        </View>
        <View style={[{ display: 'flex', flexDirection: 'row' }]}>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            14:50:00
          </Text>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            21:50:00
          </Text>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            18:00:00
          </Text>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          />
        </View>
        <View style={[{ display: 'flex', flexDirection: 'row' }]}>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Soma das Horas
          </Text>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Soma das Horas
          </Text>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Soma das Horas
          </Text>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Soma das Horas
          </Text>
        </View>
        <View style={[{ display: 'flex', flexDirection: 'row' }]}>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            03:04:00
          </Text>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            05:00:00
          </Text>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            06:00:00
          </Text>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            00:00:00
          </Text>
        </View>
        <View style={[{ display: 'flex', flexDirection: 'row' }]}>
          <Text
            style={{
              flex: 1,
              fontWeight: 'bold',
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Carga Horária
          </Text>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            08:04:00
          </Text>
          <Text
            style={{
              flex: 1,
              fontWeight: 'bold',
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Carga Horária
          </Text>
          <Text
            style={{
              flex: 1,
              backgroundColor: '#E4E4E4',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            06:00:00
          </Text>
        </View>
        <View style={[{ display: 'flex', flexDirection: 'row' }]}>
          <Text
            style={{
              flex: 1,
              fontWeight: 'bold',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Almoço
          </Text>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            02:00:00
          </Text>
          <Text
            style={{
              flex: 1,
              fontWeight: 'bold',
              textAlign: 'center',
              border: '1 solid black',
              padding: '2px 0',
            }}
          >
            Almoço
          </Text>
          <Text />
        </View>
      </View>
    );

    //
  }

  if (turno === TURNO_12_HORAS) {
    return null;
  }

  return null;
}

function isDomingoTrabalhado(dia) {
  if (!dia.ponto) return false;
  if (dia.ponto.is_folga) return false;
  if (dia.ponto.is_faltou) return false;
  return dia.dia_semana === 0;
}

function isFeriadoTrabalhado(dia) {
  if (!dia.ponto) return false;
  if (dia.ponto.is_folga) return false;
  if (dia.ponto.is_faltou) return false;
  return dia.tipo === TIPO_FERIADO_FERIADO;
}

function isAtestado(dia) {
  if (!dia.ponto) return false;
  if (dia.ponto.is_folga) return false;
  if (dia.ponto.is_faltou) {
    if (dia.ponto.is_atestado) {
      return true;
    }
    return false;
  }
}

function isFolga(dia) {
  if (!dia.ponto) return false;
  if (dia.ponto.is_folga) return true;
  return false;
}

function getLabelStatus(dia) {
  if (!dia.ponto) return '-';
  if (dia.ponto.is_folga) return 'FOLGA';
  if (dia.ponto.is_faltou) return 'FALTA';
  if (dia.ponto.is_ferias) return 'FÉRIAS';
  return 'TRABALHADO';
}

const ListaProdutos = ({ dadosSelecionado, dadosHeader }) => (
  <Document author="anderson" title="PDF">
    <Page size="A4" style={styles.page} wrap /* orientation="landscape" */>
      <View style={styles.body}>
        <View
          style={[
            styles.title,
            { margin: '10 0', fontWeight: 'bold', fontSize: '10' },
          ]}
        >
          <Text style={{ marginTop: '5px' }}>Legendas</Text>
        </View>
        <View style={{ marginBottom: '5px' }} />
        <View style={[styles.header, { borderTop: '1 solid black' }]}>
          <View
            style={[
              styles.item,
              {
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                //justifyContent: 'flex-start',
                justifyContent: 'space-between',
              },
            ]}
          >
            <View
              style={[
                styles.item5,
                {
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                },
              ]}
            >
              <Text style={{ fontWeight: 'bold', marginRight: '5px' }}>
                E1:
              </Text>
              <Text>Entrada 1º expediente</Text>
            </View>
            <View
              style={[
                styles.item5,
                {
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                },
              ]}
            >
              <Text
                style={{
                  fontWeight: 'bold',
                  marginRight: '5px',
                  marginLeft: '20px',
                }}
              >
                S1:
              </Text>
              <Text>Saída 1º expediente</Text>
            </View>
            <View
              style={[
                styles.item5,
                {
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                },
              ]}
            >
              <Text
                style={{
                  fontWeight: 'bold',
                  marginRight: '5px',
                  marginLeft: '20px',
                }}
              >
                E2:
              </Text>
              <Text>Entrada 2º expediente</Text>
            </View>
            <View
              style={[
                styles.item5,
                {
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                },
              ]}
            >
              <Text
                style={{
                  fontWeight: 'bold',
                  marginRight: '5px',
                  marginLeft: '20px',
                }}
              >
                S2:
              </Text>
              <Text>Saída 2º expediente</Text>
            </View>
            <View
              style={[
                styles.item5,
                {
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                },
              ]}
            >
              <Text
                style={{
                  fontWeight: 'bold',
                  marginRight: '5px',
                  marginLeft: '20px',
                }}
              >
                T1:
              </Text>
              <Text>1º Turno</Text>
            </View>
            <View
              style={[
                styles.item5,
                {
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                },
              ]}
            >
              <Text
                style={{
                  fontWeight: 'bold',
                  marginRight: '5px',
                  marginLeft: '20px',
                }}
              >
                T2:
              </Text>
              <Text>2º Turno</Text>
            </View>
          </View>
        </View>

        <View style={styles.header}>
          <View
            style={[
              styles.item,
              {
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                //justifyContent: 'flex-start',
                justifyContent: 'space-between',
              },
            ]}
          >
            <View
              style={[
                styles.item5,
                {
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                },
              ]}
            >
              <Text style={{ fontWeight: 'black', marginRight: '5px' }}>
                S. H.:
              </Text>
              <Text>Soma das horas trabalhadas</Text>
            </View>
            <View
              style={[
                styles.item5,
                {
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                },
              ]}
            >
              <Text
                style={{
                  fontWeight: 'bold',
                  marginRight: '5px',
                  marginLeft: '20px',
                }}
              >
                H. E.:
              </Text>
              <Text>Horas Extras</Text>
            </View>
            <View
              style={[
                styles.item5,
                {
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                },
              ]}
            >
              <Text
                style={{
                  fontWeight: 'bold',
                  marginRight: '5px',
                  marginLeft: '20px',
                }}
              >
                H. E. T.:
              </Text>
              <Text>Horas Extras com Tolerância</Text>
            </View>
            <View
              style={[
                styles.item5,
                {
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                },
              ]}
            >
              <Text
                style={{
                  fontWeight: 'bold',
                  marginRight: '5px',
                  marginLeft: '20px',
                }}
              >
                D. T.:
              </Text>
              <Text>Domingos Trabalhados</Text>
            </View>
            <View
              style={[
                styles.item5,
                {
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                },
              ]}
            >
              <Text
                style={{
                  fontWeight: 'bold',
                  marginRight: '5px',
                  marginLeft: '20px',
                }}
              >
                F. T.:
              </Text>
              <Text>Feriados Trabalhados</Text>
            </View>
            <View
              style={[
                styles.item5,
                {
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                },
              ]}
            >
              <Text
                style={{
                  fontWeight: 'bold',
                  marginRight: '5px',
                  marginLeft: '20px',
                }}
              >
                T. E.:
              </Text>
              <Text>Tipo de escala</Text>
            </View>
          </View>
        </View>

        <View style={{ marginTop: '5px' }} />

        <View
          style={[
            styles.title,
            { margin: '10 0', fontWeight: 'bold', fontSize: '10' },
          ]}
        >
          <Text style={{ marginTop: '5px' }}>CARGA HORÁRIA</Text>
        </View>

        <View style={{ marginTop: '5px' }} />

        {getGradeHorarios(
          dadosSelecionado ? dadosSelecionado.turno : '',
          dadosSelecionado ? dadosSelecionado.tipoJornada === 'SS' : false,
        )}

        <View style={{ marginTop: '5px' }} />

        <View
          style={[
            styles.title,
            { margin: '10 0', fontWeight: 'bold', fontSize: '10' },
          ]}
        >
          <Text
            style={{ marginTop: '5px' }}
          >{`HORAS EXTRAS - ${dadosHeader.nomeColaborador} - ${dadosHeader.periodoApuracao}`}</Text>
        </View>

        <View style={{ marginTop: '5px' }} />

        <View style={[styles.header, { borderTop: '1 solid black' }]}>
          <View
            style={[
              styles.item,
              {
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
              },
            ]}
          >
            <Text style={{ fontWeight: 'bold', marginRight: '5px' }}>
              RAZÃO SOCIAL:
            </Text>
            <Text>{dadosHeader.razaoSocial}</Text>
          </View>
          <View
            style={[
              styles.item,
              {
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
              },
            ]}
          >
            <Text style={{ fontWeight: 'bold', marginRight: '5px' }}>
              CNPJ:
            </Text>
            <Text>{dadosHeader.cnpj}</Text>
          </View>
          <View
            style={[
              styles.item,
              {
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
              },
            ]}
          >
            <Text style={{ fontWeight: 'bold', marginRight: '5px' }}>
              NOME FANTASIA:
            </Text>
            <Text>{dadosHeader.nomeFantasia}</Text>
          </View>
        </View>
        <View style={styles.header}>
          <View
            style={[
              styles.item,
              {
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
              },
            ]}
          >
            <Text style={{ fontWeight: 'bold', marginRight: '5px' }}>
              NOME DO COLABORADOR:
            </Text>
            <Text>{`${dadosHeader.nomeColaborador}`}</Text>
          </View>
          <View
            style={[
              styles.item,
              {
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
              },
            ]}
          >
            <Text style={{ fontWeight: 'bold', marginRight: '5px' }}>
              DATA DE ADMISSÃO:
            </Text>
            <Text>{`${dadosHeader.dataAdmissao}`}</Text>
          </View>
          <View
            style={[
              styles.item,
              {
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
              },
            ]}
          >
            <Text style={{ fontWeight: 'bold', marginRight: '5px' }}>
              CARGO:
            </Text>
            <Text>{`${dadosHeader.cargo}`}</Text>
          </View>
        </View>
        <View style={styles.header}>
          <View
            style={[
              styles.item,
              {
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              },
            ]}
          >
            <Text style={{ fontWeight: 'bold', marginRight: '5px' }}>
              PERÍODO DE APURAÇÃO:
            </Text>
            <Text>{`${dadosHeader.periodoApuracao}`}</Text>
          </View>
          <View
            style={[
              styles.item,
              {
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
              },
            ]}
          >
            <Text style={{ fontWeight: 'bold', marginRight: '5px' }}>
              TOTAL DE HORAS EXTRAS:
            </Text>
            <Text>{`${dadosHeader.totalHorasExtras}`}</Text>
          </View>
          <View
            style={[
              styles.item,
              {
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
              },
            ]}
          >
            <Text style={{ fontWeight: 'bold', marginRight: '5px' }} />
            <Text />
          </View>
        </View>
        <View style={styles.header}>
          <View
            style={[
              styles.item,
              {
                flex: 3,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
              },
            ]}
          >
            <Text>ASSINATURA DO COLABORADOR</Text>
          </View>
          <View style={[styles.item, { flex: 6 }]}>
            <Text />
          </View>
          <View style={[styles.item4, { flex: 1 }]}>
            <Text> {dadosHeader.somaHorasTrabalhadas}</Text>
          </View>
          <View style={[styles.item4, { flex: 1 }]}>
            <Text> {dadosHeader.horasExtras}</Text>
          </View>
          <View style={[styles.item4, { flex: 1 }]}>
            <Text> {dadosHeader.horasExtrasTolerancia}</Text>
          </View>
          <View style={[styles.item4, { flex: 1 }]}>
            <Text> {dadosHeader.domingosTrabalhados}</Text>
          </View>
          <View style={[styles.item4, { flex: 1 }]}>
            <Text> {dadosHeader.feriadosTrabalhados}</Text>
          </View>
          <View style={[styles.item4, { flex: 1 }]}>
            <Text> {dadosHeader.atestados}</Text>
          </View>
          <View style={[styles.item4, { flex: 1 }]}>
            <Text> {dadosHeader.folgas}</Text>
          </View>
          <View style={[styles.item4, { flex: 1 }]}>
            <Text />
          </View>
        </View>
        <View style={styles.header}>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>DATA</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>DIA</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>STATUS</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>E1</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>S1</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>E2</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>S2</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>T1</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>T2</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>S. H.</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>H. E.</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>H. E. T.</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>D. T.</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>F. T.</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>ATESTADO</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>FOLGA</Text>
          </View>
          <View style={[styles.item, { flex: 1 }]}>
            <Text>T. E.</Text>
          </View>
        </View>
        {dadosSelecionado.dias.map((dia) => (
          <>
            {dia.ponto ? (
              <View style={styles.content}>
                <View style={[styles.item3, { flex: 1 }]}>
                  <Text>{dia.descricao2}</Text>
                </View>
                <View style={[styles.item3, { flex: 1 }]}>
                  <Text>{dia.descricao3}</Text>
                </View>
                <View style={[styles.item3, { flex: 1 }]}>
                  <Text>{getLabelStatus(dia)}</Text>
                </View>
                <View style={[styles.item, { flex: 1 }]}>
                  <Text> {dia.ponto ? dia.ponto.horarioEntrada1 : ''}</Text>
                </View>
                <View style={[styles.item, { flex: 1 }]}>
                  <Text> {dia.ponto ? dia.ponto.horarioSaida1 : ''}</Text>
                </View>
                <View style={[styles.item, { flex: 1 }]}>
                  <Text> {dia.ponto ? dia.ponto.horarioEntrada2 : ''}</Text>
                </View>
                <View style={[styles.item, { flex: 1 }]}>
                  <Text>{dia.ponto ? dia.ponto.horarioSaida2 : ''}</Text>
                </View>
                <View style={[styles.item, { flex: 1 }]}>
                  <Text>
                    {' '}
                    {dia.ponto ? dia.ponto.horasTrabalhadasTurno1 : ''}
                  </Text>
                </View>
                <View style={[styles.item, { flex: 1 }]}>
                  <Text>
                    {' '}
                    {dia.ponto ? dia.ponto.horasTrabalhadasTurno2 : ''}
                  </Text>
                </View>
                <View style={[styles.item, { flex: 1 }]}>
                  <Text>
                    {' '}
                    {dia.ponto ? dia.ponto.horasTrabalhadasTotais : ''}
                  </Text>
                </View>
                <View style={[styles.item, { flex: 1 }]}>
                  <Text> {dia.ponto ? dia.ponto.horasExtras : ''}</Text>
                </View>
                <View style={[styles.item, { flex: 1 }]}>
                  <Text>
                    {dia.ponto ? dia.ponto.horasExtrasComTolerancia : ''}
                  </Text>
                </View>
                <View style={[styles.item, { flex: 1 }]}>
                  <Text> {isDomingoTrabalhado(dia) ? 'V' : ''}</Text>
                </View>
                <View style={[styles.item, { flex: 1 }]}>
                  <Text>{isFeriadoTrabalhado(dia) ? 'V' : ''}</Text>
                </View>
                <View style={[styles.item, { flex: 1 }]}>
                  <Text> {isAtestado(dia) ? 'V' : ''}</Text>
                </View>
                <View style={[styles.item, { flex: 1 }]}>
                  <Text> {isFolga(dia) ? 'V' : ''}</Text>
                </View>
                <View style={[styles.item, { flex: 1 }]}>
                  <Text>
                    {dia.ponto
                      ? dia.ponto.tipoJornada
                      : dadosSelecionado.tipoJornada}
                  </Text>
                </View>
              </View>
            ) : null}
          </>
        ))}
      </View>
    </Page>
  </Document>
);
export default ListaProdutos;
