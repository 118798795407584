import React from 'react';

import { Paper, Box, makeStyles } from '@material-ui/core';

import { SECONDARY_COLOR } from '../../../../../utils';

const useStyles = makeStyles((theme) => ({
  containerOpaco: {
    padding: '10px',
    backgroundColor: theme.palette.secondary.main,
    marginTop: '15px',
    color: 'white',
    boxSizing: 'border-box',
  },
  containerInterno: {
    marginTop: '10px',
    fontSize: '15px',
  },
}));

const disableColor = '#ccc';

const LabelEstoque = ({ grid, fontSize = '12px' }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.containerOpaco} elevation={3}>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        color="white"
      >
        <div style={{ textAlign: 'center' }}>TECLAS DE ATALHO</div>
        <Paper className={classes.containerInterno} elevation={3}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width="100%"
            color="black"
            padding="10px"
            fontSize={fontSize}
            fontWeight="bold"
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                color: SECONDARY_COLOR,
              }}
            >
              {grid.map((coluna) => (
                <div style={{ flex: 1 }}>
                  {coluna.map((item) => (
                    <div
                      className={item.class ? item.class : ''}
                      style={
                        item.status === undefined || item.status === true
                          ? { textAlign: 'start', marginBottom: '0px' }
                          : {
                              textAlign: 'start',
                              marginBottom: '0px',
                              color: disableColor,
                            }
                      }
                    >
                      {`[${item.atalho}] - ${item.label}`}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </Box>
        </Paper>
      </Box>
    </Paper>
  );
};

export default LabelEstoque;
