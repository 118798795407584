import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { useHistory, useLocation } from 'react-router-dom';

import { Box, makeStyles, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { getOneAPI } from '../../../services';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // color: 'red',
    marginLeft: '15px',
    maxWidth: '150px',
  },
  avatar: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    color: 'white',
    backgroundColor: '#f44336',
    cursor: 'pointer',
  },
}));

const FaturaField = ({ ...props }) => {
  const record = useRecordContext(props);

  const nomeFatura = record
    ? record.record
      ? record.record.parcelaCompra.compra.fornecedor.nome
      : '-'
    : '-';

  return (
    <div>
      <Typography variant="subtitle1">{nomeFatura}</Typography>
    </div>
  );
};


export default FaturaField;
