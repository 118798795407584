import React, { useEffect, useState } from 'react';
import {
  ListBase,
  ListToolbar,
  Pagination,
  Title,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  useListContext,
  useVersion,
} from 'react-admin';

import { Card, makeStyles, CardContent, Box } from '@material-ui/core';
import Avatar2 from '@material-ui/core/Avatar';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Skeleton from '@material-ui/lab/Skeleton';
import { stringify } from 'query-string';

import { api } from '../../services';
import { formatMoeda } from '../../utils';
import ValorField from '../Transacoes/Fields/ValorField';
import Actions from './ActionsList';
import Filter from './Filter';

const useStyles = makeStyles((theme) => ({
  statusHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  statusCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  statusContent: {
    flex: 5,
    marginLeft: '30px',
    maxWidth: '100px',
  },
  dataHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoHeader: {
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoCell: {
    flex: 2,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaHeader: {
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaCell: {
    flex: 2,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaHeader: {
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaCell: {
    flex: 2,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
}));

const CardTotal = ({ label, valor, icon, cor, loading = false }) => (
  <Card style={{ borderRadius: '20px', cursor: 'pointer', flex: 1 }}>
    <CardContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <div
            style={{
              fontSize: '17px',
              color: '#737477',
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {label}
            <ArrowForwardIosIcon
              style={{ fontSize: '10px', marginLeft: '5px' }}
            />
          </div>
          {!loading ? (
            <div
              style={{ fontSize: '20px', color: 'black', fontWeight: 'bold' }}
            >
              {formatMoeda(valor)}
            </div>
          ) : (
            <div
              style={{ fontSize: '20px', color: 'black', fontWeight: 'bold' }}
            >
              <Skeleton variant="text" />
            </div>
          )}
        </div>
        <div>
          <Avatar2
            aria-label="recipe"
            style={{ backgroundColor: cor, color: 'white' }}
          >
            {icon}
          </Avatar2>
        </div>
      </div>
    </CardContent>
  </Card>
);

const SessaoTotais = () => {
  const listContext = useListContext();
  const version = useVersion();
  const { filterValues } = listContext;

  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  async function getDados() {
    setLoading(true);
    const dados = await api.get(
      `/totais-transacoes-limpas?${stringify({
        filter: JSON.stringify({
          nome: filterValues.nome,
          conta_id: filterValues.conta_id,
          tipo: filterValues.tipo,
          valor: filterValues.valor,
          sessao_id: filterValues.sessao_id,
          id_sessao2: filterValues.id_sessao2,
          status: filterValues.status,
          visible: filterValues.visible,
        }),
      })}`,
    );

    setTotal(dados.data.total);
    setLoading(false);
  }

  useEffect(() => {
    getDados();
  }, [
    filterValues.nome,
    filterValues.conta_id,
    filterValues.tipo,
    filterValues.valor,
    filterValues.sessao_id,
    filterValues.id_sessao2,
    filterValues.status,
    filterValues.visible,
    version,
  ]);

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        margin: '30px 0 15px',
        maxHeight: '100px',
      }}
    >
      <Box flex={1}>
        <CardTotal
          label="Total"
          valor={total}
          icon={<AccountBalanceIcon />}
          cor="#009FD4"
          loading={loading}
        />
      </Box>
    </div>
  );
};

const MyList = ({ ...props }) => {
  const classes = useStyles();

  return (
    <>
      <Title title="Transações Conferências" />
      <SessaoTotais />
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid {...props}>
          <TextField
            source="id"
            label="ID"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            sortable={false}
          />
          <TextField
            source="sessao_id"
            label="ID SESSÃO"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            sortable={false}
          />
          <TextField
            source="nome"
            label="Nome"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            sortable={false}
          />
          <DateField
            source="data_pagamento"
            label="Data"
            showTime
            sortable={false}
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <ReferenceField
            label="Conta"
            source="conta_id"
            reference="contas_normal"
            sortable={false}
          >
            <TextField source="nome" />
          </ReferenceField>
          <ValorField
            source="valor"
            label="Valor"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            sortable={false}
          />
        </Datagrid>
        <TransacoesPagination />
      </Card>
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Transações Conferências"
      component="div"
    />
  </>
);

export default List2;
