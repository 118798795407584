import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import { getListAllAPI, createAPI, updateAPI } from '../../../services';
import { TextInput, ColorInput, SwitchInput } from '../../Inputs';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    // backgroundColor: (props) => props.cor,
    // color: 'white',
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    // backgroundColor: 'rgba(0, 0, 0, 0.12)',
    // color: 'red',
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_NOME = '';
const DEFAULT_COLOR = '#f44336';
const DEFAULT_IS_CAIXA = false;
const DEFAULT_ID = -1;

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [nome, setNome] = useState(DEFAULT_NOME);
  const [color, setColor] = useState(DEFAULT_COLOR);
  const [isCaixa, setIsCaixa] = useState(DEFAULT_IS_CAIXA);
  const [id, setId] = useState(DEFAULT_ID);
  const [carregando, setCarregando] = useState(false);
  const classes = useStyles();

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function reiniciar() {
    setNome(DEFAULT_NOME);
    setId(DEFAULT_ID);
    setColor(DEFAULT_COLOR);
    setIsCaixa(DEFAULT_IS_CAIXA);
    setCarregando(false);
  }

  async function reiniciarCaixa() {
    setNome(DEFAULT_NOME);
    setId(DEFAULT_ID);
    setColor(DEFAULT_COLOR);
    setIsCaixa(true);
    setCarregando(false);
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'contas',
        ['id', 'asc'],
        { id: [id] },
        [],
      );
      if (data.data.length > 0) {
        setNome(data.data[0].nome);
        setId(data.data[0].id);
        setColor(data.data[0].color);
        setIsCaixa(data.data[0].is_caixa);
        setCarregando(false);
      } else {
        throw 'Esta conta não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    try {
      if (id <= 0) {
        await createAPI('contas', {
          nome,
          color,
          is_caixa: isCaixa,
        });
        setCarregando(false);
        enqueueSnackbar('Conta criada com sucesso!', {
          variant: 'success',
        });
      } else {
        await updateAPI('contas', id, {
          nome,
          color,
        });
        setCarregando(false);
        enqueueSnackbar('Conta atualizada com sucesso!', {
          variant: 'success',
        });
      }
      handleCloseDialog();
    } catch (erros) {
      setCarregando(false);
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      reiniciar();
      setOpen(true);
    },
    handleEdit(id) {
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
    },
    handleCreateCaixa() {
      reiniciarCaixa();
      setOpen(true);
    },
  }));

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{
            backgroundColor: isCaixa ? '#4caf67' : '#009FD4',
            color: 'white',
          }}
        >
          {id < 0
            ? isCaixa
              ? 'Novo Caixa'
              : 'Nova Conta'
            : isCaixa
            ? 'Editar Caixa'
            : 'Editar Conta'}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <TextInput
                name="nome"
                handleEnter={() => {}}
                label="Descrição"
                handleKey={() => {}}
                value={nome}
                onChange={(value) => setNome(value.target.value)}
                error={nome === '' || nome.length > 80}
                helperText={
                  nome === ''
                    ? 'Este campo é obrigatório'
                    : nome.length > 80
                    ? 'nÚMERO DE CARACTERES TEM QUE SER MENOS QUE 80!'
                    : ''
                }
                fullWidth
                autoFocus
                variant="standard"
              />
              <div style={{ marginTop: '24px' }}>
                <div>
                  <div>Cor da conta</div>
                </div>
                <div style={{ margin: '16px 6.4px' }}>
                  <ColorInput
                    value={color}
                    onChange={(newColor) => setColor(newColor)}
                  />
                </div>
              </div>
              {/*id < 0 && (
                <div
                  style={{
                    marginTop: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ fontWeight: 'bold' }}>Caixa</div>
                  <SwitchInput
                    name="caixa"
                    handleEnter={() => {}}
                    label=""
                    handleKey={() => {}}
                    checked={isCaixa}
                    onChange={(value) => setIsCaixa(value.target.checked)}
                    disabled={id > 0}
                  />
                </div>
                )*/}
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          <div className={classes.wrapper}>
            <Button
              onClick={handleSalvar}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              disabled={nome === '' || nome.length > 80 || carregando}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
            {carregando && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
