import React from 'react';

import { parse } from 'query-string';

import EditTemplate from '../../Components/Edit';
import Erros from './Erros';
import Form from './Form';
import validate from './Validation';

const Title = ({ record }) => <span>Editar Correção de saldo de conta</span>;

const Edit = (props) => {
  const { conta_id: conta_id_string } = parse(props.location.search);
  const conta_id = conta_id_string ? parseInt(conta_id_string, 10) : '';
  // const redirect = produto_id ? `/sessoes/${produto_id}/show/2` : 'show';
  return (
    <EditTemplate
      {...props}
      title={<Title />}
      validate={validate}
      onFailure={Erros}
      isBack
    >
      <Form />
    </EditTemplate>
  );
};

export default Edit;
