import React, { useState, useEffect } from 'react';
import { Title } from 'react-admin';

import { TextField } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SearchIcon from '@material-ui/icons/Search';
import { useSnackbar } from 'notistack';

import { AutoCompleteRemoto } from '../../../Components';
import useDebounce from '../../../hooks/useDebounce';
import { api, getListAPI } from '../../../services';
import { formatMoeda, STATUS_ATIVO } from '../../../utils';
import Filtros from './Filtros';

function formatPorcentagem(valor, casasDecimais = 2) {
  return valor !== null && valor !== undefined
    ? `${valor.toLocaleString('de-DE', {
        minimumFractionDigits: casasDecimais,
        maximumFractionDigits: casasDecimais,
      })}%`
    : '';
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const DashbboardContabilidade = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState({
    linhas: [],
  });
  const { enqueueSnackbar } = useSnackbar();
  const [periodo, setPeriodo] = useState(0);
  const [sort, setSort] = useState('vendas');
  const [nome, setNome] = useState('');
  const [page, setPage] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [totalVendas, setTotalVendas] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [vendedor, setVendedor] = useState(null);

  const [nomeDebounce] = useDebounce(nome, 500);

  const [vendedorDebounce] = useDebounce(vendedor, 500);

  async function handleData() {
    setLoading(true);
    try {
      const data = await getListAPI(
        'relatorio/clientes',
        [sort, 'desc'],
        [page + 1, rowsPerPage],
        vendedor
          ? { nome, periodo, vendedor_id: vendedor.id }
          : { nome, periodo },
        [''],
      );
      setDados({ linhas: data.data.clientes });
      setTotalVendas(data.data.total_venda);
      setTotal(data.total);
      setLoading(false);
    } catch (e) {
      setDados({ linhas: [] });
      setTotalVendas(0);
      setTotal(0);
      setLoading(false);
      enqueueSnackbar(`Erro ao carregar os dados do relatório!`, {
        variant: 'error',
      });
    }
  }

  useEffect(() => {
    handleData();
  }, [periodo, sort, nomeDebounce, page, rowsPerPage, vendedorDebounce]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function getColorCurvaAbc(value) {
    if (value > 0 && value <= 50) {
      return '#82f291';
    }

    if (value > 50 && value <= 80) {
      return '#ecf76f';
    }

    if (value > 80 && value <= 100) {
      return '#f26a6a';
    }

    return 'white';
  }

  return (
    <div>
      <div
        style={{
          fontSize: '25px',
          fontWeight: 'bold',
          marginTop: '35px',
          marginBottom: '30px',
          textAlign: 'center',
        }}
      >
        Relatório Clientes
      </div>
      <Title title="Relatório Clientes" />
      <div
        style={{
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ padding: '10px' }}>
          <TextField
            id="outlined-basic"
            label="Descrição"
            variant="outlined"
            fullWidth
            value={nome}
            onChange={(e) => {
              setNome(e.target.value);
            }}
            /*  InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={(value) => setNome(value)}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }} */
          />
        </div>
        <div style={{ fontWeight: 'bold' }}>{`Vendas: ${formatMoeda(
          totalVendas,
        )}`}</div>
        <div style={{ width: '300px' }}>
          <AutoCompleteRemoto
            name="vendedor"
            handleEnter={() => {}}
            label="Vendedor"
            handleKey={() => {}}
            value={vendedor}
            onChange={(value) => setVendedor(value)}
            fullWidth
            toString={false}
            getOptionSelected={(option, value) => option.nome === value.nome}
            getOptionLabel={(option) => `${option.nome}`}
            nested={[]}
            filters={{ status: STATUS_ATIVO }}
            resource="colaboradores"
          />
        </div>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">
            Período
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={periodo}
            onChange={(event) => setPeriodo(event.target.value)}
            label="Período"
          >
            <MenuItem value={0}>Ontem</MenuItem>
            <MenuItem value={1}>3 últimos dias</MenuItem>
            <MenuItem value={2}>7 últimos dias</MenuItem>
            <MenuItem value={3}>14 últimos dias</MenuItem>
            <MenuItem value={4}>30 últimos dias</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div style={{ display: 'flex' }}>
        {/* <div style={{ flex: 1 }}>
          <Filtros
            periodo={periodo}
            sort={sort}
            handlePeriodo={(value) => setPeriodo(value)}
            handleSort={(value) => setSort(value)}
            handleNome={(value) => setNome(value)}
          />
        </div> */}
        <Paper
          style={{
            flex: 3,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ cursor: 'pointer' }}>
                    Ranking
                  </StyledTableCell>
                  <StyledTableCell style={{ cursor: 'pointer' }}>
                    Responsável
                    {sort === 'responsavel' && (
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        size="small"
                        style={{ color: 'white' }}
                      >
                        <ArrowDownwardIcon />
                      </IconButton>
                    )}
                  </StyledTableCell>
                  <StyledTableCell style={{ cursor: 'pointer' }}>
                    Nome Fantasia
                    {sort === 'nome_fantasia' && (
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        size="small"
                        style={{ color: 'white' }}
                      >
                        <ArrowDownwardIcon />
                      </IconButton>
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    onClick={() => setSort('vendas')}
                    style={{ cursor: 'pointer' }}
                  >
                    <div style={{ display: 'flex' }}>
                      Vendas (R$)
                      {sort === 'vendas' && (
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          size="small"
                          style={{ color: 'white' }}
                        >
                          <ArrowDownwardIcon />
                        </IconButton>
                      )}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    onClick={() => setSort('vendas')}
                    style={{ cursor: 'pointer' }}
                  >
                    Vendas (%)
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    onClick={() => setSort('vendas')}
                    style={{ cursor: 'pointer' }}
                  >
                    Vendas Acumulada(%)
                  </StyledTableCell>

                  <StyledTableCell
                    align="right"
                    onClick={() => setSort('lucro')}
                    style={{ cursor: 'pointer' }}
                  >
                    Lucro (R$)
                    {sort === 'lucro' && (
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        size="small"
                        style={{ color: 'white' }}
                      >
                        <ArrowDownwardIcon />
                      </IconButton>
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    onClick={() => setSort('lucro')}
                    style={{ cursor: 'pointer' }}
                  >
                    Lucro (%)
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    onClick={() => setSort('lucro')}
                    style={{ cursor: 'pointer' }}
                  >
                    Lucro Acumulado(%)
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    //onClick={() => setSort('lucro')}
                    style={{ cursor: 'pointer' }}
                  >
                    Vendedor
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dados.linhas.map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell>
                      {row.ranking ? row.ranking : '-'}
                    </StyledTableCell>
                    <StyledTableCell>{formatMoeda(row.nome)}</StyledTableCell>
                    <StyledTableCell>{row.nomeFantasia}</StyledTableCell>
                    <StyledTableCell align="right">
                      {formatMoeda(row.vendas)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.vendasPorcentagem > 0
                        ? formatPorcentagem(row.vendasPorcentagem, 6)
                        : '-'}
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      style={{
                        color: 'black',
                        fontWeight: 'bold',
                        backgroundColor:
                          sort === 'vendas'
                            ? getColorCurvaAbc(
                                row.vendasPorcentagemAcumulada,
                                6,
                              )
                            : 'white',
                      }}
                    >
                      {row.vendasPorcentagemAcumulada > 0 && sort === 'vendas'
                        ? formatPorcentagem(row.vendasPorcentagemAcumulada, 6)
                        : '-'}
                    </StyledTableCell>

                    <StyledTableCell align="right">
                      {row.lucro > 0 ? formatMoeda(row.lucro) : '-'}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.lucroPorcentagem > 0
                        ? formatPorcentagem(row.lucroPorcentagem, 6)
                        : '-'}
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      style={{
                        color: 'black',
                        fontWeight: 'bold',
                        backgroundColor:
                          sort === 'lucro'
                            ? getColorCurvaAbc(row.lucroPorcentagemAcumulada, 6)
                            : 'white',
                      }}
                    >
                      {row.lucroPorcentagemAcumulada > 0 && sort === 'lucro'
                        ? formatPorcentagem(row.lucroPorcentagemAcumulada, 6)
                        : '-'}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.vendedor ? row.vendedor.nome : '-'}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Por página"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : `more than ${to}`}`
            }
          />
        </Paper>
      </div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default DashbboardContabilidade;
