import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  TextInput,
  SearchProduto,
  PrecoInput,
  DateInput,
} from '../../../../Components';
import { createAPI, updateAPI, getListAllAPI } from '../../../../services';
import { STATUS_ATIVO } from '../../../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [nome, setNome] = useState('');
  const [fornecedores, setFornecedores] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [id, setId] = useState(-1);
  const [itens, setItens] = useState([]);
  const [cursor, setCursor] = useState(0);
  const refs = useRef([]);
  const [data2, setData2] = useState(moment().format('DD/MM/YYYY'));

  const [carregando, setCarregando] = useState(false);

  const classes = useStyles({ cor: '#009FD4' });

  refs.current = getArrayTeste().map(
    (ref, index) => (refs.current[index] = React.createRef()),
  );

  function getRef(position) {
    if (refs.current) {
      if (refs.current.length >= position + 1) {
        return refs.current[position];
      }
      return null;
    }
    return null;
  }

  function getArrayTeste() {
    const array = [];
    for (let i = 0; i < fornecedores.length; i++) {
      for (let j = 0; j < produtos.length; j++) {
        array.push(0);
      }
    }
    return array;
  }

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  function formataPrazos(prazos) {
    const newPrazos = prazos.map(({ dias }) => dias);
    return newPrazos.sort((a, b) => a.id - b.id).join(', ');
  }

  function formataFornecedores(itens) {
    const newArray = [];
    for (let i = 0; i < itens.length; i++) {
      const isExistis = newArray.some(
        (item) => item.id === itens[i].fornecedor.id,
      );
      if (!isExistis) {
        newArray.push({
          nome: itens[i].fornecedor.nome,
          id: itens[i].fornecedor.id,
          prazos: itens[i].prazos,
        });
      }
    }
    return newArray.sort((a, b) => a.id - b.id);
  }

  function formataProdutos(itens) {
    const newArray = [];
    for (let i = 0; i < itens.length; i++) {
      const isExistis = newArray.some(
        (item) => item.id === itens[i].produto.id,
      );
      if (!isExistis) {
        newArray.push({
          nome: itens[i].produto.nome,
          id: itens[i].produto.id,
        });
      }
    }
    return newArray.sort((a, b) => a.id - b.id);
  }

  function searchItem(itens, fornecedor_id, produto_id) {
    const value = itens.find(
      (item) =>
        item.fornecedor.id === fornecedor_id && item.produto.id === produto_id,
    );

    return value ? value.preco : 0;
  }

  function initItens(itens, fornecedores, produtos) {
    const rowsArray = [];
    for (let i = 0; i < fornecedores.length; i++) {
      const columnsArray = [];
      for (let j = 0; j < produtos.length; j++) {
        columnsArray.push(
          searchItem(itens, fornecedores[i].id, produtos[j].id),
        );
      }
      rowsArray.push(columnsArray);
    }
    return rowsArray;
  }

  async function getDataResource(id) {
    try {
      setCarregando(true);
      const data = await getListAllAPI(
        'cotacoes',
        ['id', 'asc'],
        { id: [id] },
        ['itens.produto', 'itens.fornecedor', 'template'],
      );
      if (data.data.length > 0) {
        setData2(moment(data.data[0].data).format('DD/MM/YYYY'));
        setCursor(0);
        setId(data.data[0].id);
        setNome(data.data[0].template.nome);
        const fornecedores = formataFornecedores(data.data[0].itens);
        setFornecedores(fornecedores);
        const produtos = formataProdutos(data.data[0].itens);
        setProdutos(produtos);
        setItens(initItens(data.data[0].itens, fornecedores, produtos));
        setCarregando(false);
      } else {
        throw 'Este template não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function handleSalvar() {
    if (!erroExistente) {
      setCarregando(true);
      try {
        await updateAPI(`cotacoes`, id, {
          itens: getItensEnvio(),
          data: buildData(data2),
        });
        setCarregando(false);
        enqueueSnackbar('Cotação atualizada com sucesso!', {
          variant: 'success',
        });

        handleCloseDialog();
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    } else {
      enqueueSnackbar(`${erros.join('; ')}`, {
        variant: 'error',
      });
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      getDataResource(id);
      setOpen(true);
    },
  }));

  function validatePreco(value) {
    let error = '';
    if (value < 0 || Number.isNaN(value)) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function getErros() {
    const errosOld = [];
    for (let i = 0; i < fornecedores.length; i++) {
      for (let j = 0; j < produtos.length; j++) {
        errosOld.push(validatePreco(getItem(i, j)));
      }
    }
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  function getItem(i, j) {
    if (itens.length >= i + 1) {
      if (itens[i].length >= j + 1) {
        return itens[i][j];
      }
      return 0;
    }
    return 0;
  }

  function getHeaderTable() {
    const columns = [];
    columns.push('Fornecedores\\Produtos');
    for (let i = 0; i < produtos.length; i++) {
      columns.push(produtos[i].nome);
    }
    columns.push('Prazos');
    return columns;
  }

  function getLabelFornecedor(data) {}

  const columns = getHeaderTable();

  function getLinesTable() {
    const lines = [];
    let index = 0;
    for (let i = 0; i < fornecedores.length; i++) {
      const column = [];
      column.push({
        value: fornecedores[i].nome,
        is_input: false,
        id: `${fornecedores[i].id}-label`,
        index: -1,
        i: -1,
        j: -1,
      });
      for (let j = 0; j < produtos.length; j++) {
        column.push({
          value: getItem(i, j),
          is_input: true,
          id: `${fornecedores[i].id}-${produtos[j].id}`,
          index,
          i,
          j,
        });
        index += 1;
      }
      column.push({
        value: fornecedores[i].prazos,
        is_input: false,
        id: `${fornecedores[i].id}-prazos`,
        index: -1,
        i: -1,
        j: -1,
      });
      lines.push({
        columns: column,
        id: `${fornecedores[i].id}`,
      });
    }
    return lines;
  }

  const rows = getLinesTable();

  function getRefNextInput(index, total) {
    if (index === total - 1) {
      return -1;
    }
    return index + 1;
  }

  function handleNextInput(index) {
    if (refs.current) {
      const rows = fornecedores.length;
      const columns = produtos.length;
      const position = getRefNextInput(index, rows * columns);

      if (position === -1) {
        refs.current[0].current.focus();
        refs.current[0].current.select();
      } else if (refs.current[position].current) {
        refs.current[position].current.focus();
        refs.current[position].current.select();
      }
    }
  }

  function getItensEnvio() {
    const newItens = [];
    for (let i = 0; i < fornecedores.length; i++) {
      for (let j = 0; j < produtos.length; j++) {
        newItens.push({
          fornecedor_id: fornecedores[i].id,
          produto_id: produtos[j].id,
          preco: itens[i][j],
          prazos: fornecedores[i].prazos,
        });
      }
    }
    return newItens;
  }

  function buildData(data) {
    const dia = parseInt(data.substring(0, 2), 10);
    const mes = parseInt(data.substring(3, 5), 10) - 1;
    const ano = parseInt(data.substring(6, 10), 10);

    const dataFormatada = new Date();
    dataFormatada.setFullYear(ano);
    dataFormatada.setDate(dia);
    dataFormatada.setMonth(mes);
    dataFormatada.setDate(dia);

    return dataFormatada;
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        disableBackdropClick
        disableEscapeKeyDown
        fullScreen
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          {`Editar cotação (Template: ${nome})`}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <Box p="1em">
                <h2
                  style={{
                    margin: '10px 0',
                  }}
                >
                  {data2}
                </h2>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map((value) => (
                          <StyledTableCell>{value}</StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map(({ id, columns }) => (
                        <StyledTableRow key={id}>
                          {columns.map(({ value, is_input, index, i, j }) => (
                            <StyledTableCell component="th" scope="row">
                              {is_input ? (
                                <PrecoInput
                                  ref={getRef(index)}
                                  handleEnter={() => handleNextInput(index)}
                                  label=""
                                  handleKey={() => {}}
                                  value={
                                    Number.isNaN(getItem(i, j))
                                      ? ''
                                      : String(getItem(i, j))
                                  }
                                  onChange={(value) => {
                                    const newValue = parseFloat(
                                      value.target.value,
                                    );
                                    const clone = [...itens];
                                    clone[i][j] = newValue;
                                    setItens(clone);
                                  }}
                                  error={erros[index] !== ''}
                                  helperText={erros[index]}
                                  fullWidth
                                />
                              ) : (
                                <div>{value}</div>
                              )}
                            </StyledTableCell>
                          ))}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={handleCloseDialog}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
            variant="contained"
            color="primary"
          >
            Cancelar
          </Button>
          <div className={classes.wrapper}>
            <Button
              onClick={handleSalvar}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              disabled={carregando || erroExistente}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
            {carregando && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
