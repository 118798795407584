import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import { Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import xml2js from 'xml2js';

import { api } from '../../../services';
import {
  formatDateDDMMYYYYHHmm,
  formatCEP,
  formatCNPJ,
  formatCPF,
  formatPreco,
  formatPeso,
  formatIE,
  formatMoeda,
  formatDateDDMMYYYY,
} from '../../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    //borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: 'black',
    width: '1px',
    whiteSpace: 'nowrap',
    padding: '5px',
    border: '1px solid black',
    textAlign: 'left',
    verticalAlign: 'middle',
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    padding: '5px',
    border: '1px solid black',
    textAlign: 'left',
    verticalAlign: 'middle',
  },
}))(TableCell);

const codigosEstados = {
  12: {
    sigla: 'AC',
    nome: 'Acre',
  },
  27: {
    sigla: 'AL',
    nome: 'Alagoas',
  },
  13: {
    sigla: 'AM',
    nome: 'Amazonas',
  },
  16: {
    sigla: 'AP',
    nome: 'Amapá',
  },
  29: {
    sigla: 'BA',
    nome: 'Bahia',
  },
  23: {
    sigla: 'CE',
    nome: 'Ceará',
  },
  53: {
    sigla: 'DF',
    nome: 'Distrito Federal',
  },
  32: {
    sigla: 'ES',
    nome: 'Espírito Santo',
  },
  52: {
    sigla: 'GO',
    nome: 'Goiás',
  },
  21: {
    sigla: 'MA',
    nome: 'Maranhão',
  },
  31: {
    sigla: 'MG',
    nome: 'Minas Gerais',
  },
  50: {
    sigla: 'MS',
    nome: 'Mato Grosso do Sul',
  },
  51: {
    sigla: 'MT',
    nome: 'Mato Grosso',
  },
  15: {
    sigla: 'PA',
    nome: 'Pará',
  },
  25: {
    sigla: 'PB',
    nome: 'Paraíba',
  },
  26: {
    sigla: 'PE',
    nome: 'Pernambuco',
  },
  22: {
    sigla: 'PI',
    nome: 'Piauí',
  },
  41: {
    sigla: 'PR',
    nome: 'Paraná',
  },
  33: {
    sigla: 'RJ',
    nome: 'Rio de Janeiro',
  },
  24: {
    sigla: 'RN',
    nome: 'Rio Grande do Norte',
  },
  11: {
    sigla: 'RO',
    nome: 'Rondônia',
  },
  14: {
    sigla: 'RR',
    nome: 'Roraima',
  },
  43: {
    sigla: 'RS',
    nome: 'Rio Grande do Sul',
  },
  42: {
    sigla: 'SC',
    nome: 'Santa Catarina',
  },
  28: {
    sigla: 'SE',
    nome: 'Sergipe',
  },
  35: {
    sigla: 'SP',
    nome: 'São Paulo',
  },
  17: {
    sigla: 'TO',
    nome: 'Tocantins',
  },
};

const codigoModeloDocumentoFiscal = {
  55: 'NF-e emitida em substituição ao modelo 1 ou 1A',
  65: 'NFC-e, utilizada nas operações de venda no varejo (a critério da UF aceitar este modelo de documento)',
};

const codigosTipoOperacao = {
  0: 'Entrada',
  1: 'Saída',
};

const codigosIdentificadorDeLocalDoDestinoDaOperacao = {
  1: 'Operação interna',
  2: 'Operação interestadual',
  3: 'Operação com exterior',
};

const codigosFormatoImpressaoDanfe = {
  0: 'Sem geração de DANFE; ',
  1: 'DANFE normal, Retrato;',
  2: 'DANFE normal, Paisagem;',
  3: 'DANFE Simplificado',
  4: 'DANFE NFC-e',
  5: `DANFE NFC-e em mensagem eletrônica (o envio de
    mensagem eletrônica pode ser feita de forma simultânea
    com a impressão do DANFE; usar o tpImp=5 quando esta
    for a única forma de disponibilização do DANFE)`,
};

const codigosTipoEmissaoDaNfe = {
  1: 'Emissão normal (não em contingência)',
  2: 'Contingência FS-IA, com impressão do DANFE em Formulário de Segurança - Impressor Autônomo',
  3: 'Contingência SCAN (Sistema de Contingência do Ambiente Nacional); *Desativado * NT 2015/002',
  4: 'Contingência EPEC (Evento Prévio da Emissão em Contingência)',
  5: 'Contingência FS-DA, com impressão do DANFE em Formulário de Segurança - Documento Auxiliar;',
  6: 'Contingência SVC-AN (SEFAZ Virtual de Contingência do AN);',
  7: 'Contingência SVC-RS (SEFAZ Virtual de Contingência do RS);',
  9: 'Contingência off-line da NFC-e;',
};

const codigosIdentificacaoDoAmbiente = {
  1: 'Produção',
  2: 'Homologação',
};

const codigosFinalidadeDaEmissaoDaNfe = {
  1: 'NF-e normal;',
  2: 'NF-e complementar;',
  3: 'NF-e de ajuste;',
  4: 'Devolução de mercadoria.',
};

const codigosOperacaoConsumidorFinal = {
  0: 'Normal',
  1: 'Consumidor final',
};

const codigosIndicadorPresencaColaboradorNoEstabelecimentoDaCompra = {
  0: 'Não se aplica (por exemplo, Nota Fiscal complementar ou de ajuste',
  1: 'Operação presencial;',
  2: 'Operação não presencial, pela Internet;',
  3: 'Operação não presencial, Teleatendimento;',
  4: 'NFC-e em operação com entrega a domicílio;',
  5: 'Operação presencial, fora do estabelecimento; (incluído NT2016.002)',
  9: 'Operação não presencial, outros',
};

const codigosIndicadorDeIntermediario = {
  0: 'Operação sem intermediador (em site ou plataforma própria)',
  1: 'Operação em site ou plataforma de terceiros (intermediadores/marketplace)',
};

const codigosProecessoEmissaoNfe = {
  0: 'Emissão de NF-e com aplicativo do contribuinte;',
  1: 'Emissão de NF-e avulsa pelo Fisco;',
  2: 'Emissão de NF-e avulsa, pelo contribuinte com seu certificado digital, através do site do Fisco',
  3: 'Emissão NF-e pelo contribuinte com aplicativo fornecido pelo Fisco',
};

function getObjData(value) {
  const ano = parseInt(value.substring(0, 4), 10);
  const mes = parseInt(value.substring(0, 4), 10);
  const dia = parseInt(value.substring(0, 4), 10);
  const hora = parseInt(value.substring(0, 4), 10);
  const minuto = parseInt(value.substring(0, 4), 10);
  const segundo = parseInt(value.substring(0, 4), 10);

  const data = new Date();
  data.setFullYear(ano);
  data.setDate(dia);
  data.setMonth(mes - 1);
  data.setDate(dia);
  data.setFullYear(ano);
  data.setHours(hora);
  data.setMinutes(minuto);
  data.setSeconds(segundo);

  return data;
}

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose = () => {} }, ref) => {
    const [open, setOpen] = useState(false);
    const refInputFile = useRef(null);
    const [nota, setNota] = useState({});
    const [pesquisando, setPesquisando] = useState(false);

    const [dadosNota, setDadosNota] = useState(null);
    const [fornecedor, setFornecedor] = useState(null);
    const [itens, setItens] = useState([]);
    const [fatura, setFatura] = useState(null);
    const [parcelas, setParcelas] = useState([]);
    const [totaisNota, setTotaisNota] = useState(null);

    const [produtosEncontrados, setProdutosEncontrados] = useState([]);
    const [fornecedorEncontrado, seFornecedorEncontrados] = useState(null);

    const classes = useStyles({ cor: '#009FD4' });

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    const handleSalvar = () => {
      /* setOpen(false);
    handleClose(); */
      handleSubmit();
    };

    useImperativeHandle(ref, () => ({
      handleOpen() {
        setOpen(true);
        handleLimpar();
      },
    }));

    function getDadosIde(infNFe) {
      const dadosFormatados = {};
      const dadosNota = {};
      if (infNFe.ide) {
        if (infNFe.ide.length > 0) {
          const ide = infNFe.ide[0];

          // cUF
          if (ide.cUF) {
            if (ide.cUF.length > 0) {
              dadosFormatados.cUF = {
                descricao: 'Código da UF do emitente do Documento Fiscal',
                value: ide.cUF[0],
                valorFormatado: codigosEstados[ide.cUF[0]].nome,
              };
            }
          }

          // cNF
          if (ide.cNF) {
            if (ide.cNF.length > 0) {
              dadosFormatados.cNF = {
                descricao: 'Código Numérico que compõe a Chave de Acesso',
                value: ide.cNF[0],
                valorFormatado: ide.cNF[0],
              };
            }
          }

          // natOp
          if (ide.natOp) {
            if (ide.natOp.length > 0) {
              dadosFormatados.natOp = {
                descricao: 'Descrição da Natureza da Operação',
                value: ide.natOp[0],
                valorFormatado: ide.natOp[0],
              };
            }
          }

          // mod
          if (ide.mod) {
            if (ide.mod.length > 0) {
              dadosFormatados.mod = {
                descricao: 'Código do Modelo do Documento Fiscal',
                value: ide.mod[0],
                valorFormatado: codigoModeloDocumentoFiscal[ide.mod[0]],
              };
            }
          }

          // serie
          if (ide.serie) {
            if (ide.serie.length > 0) {
              dadosNota.serie = {
                descricao: 'Série do Documento Fiscal',
                value: ide.serie[0],
                valorFormatado: ide.serie[0],
              };
              dadosFormatados.serie = {
                descricao: 'Série do Documento Fiscal',
                value: ide.serie[0],
                valorFormatado: ide.serie[0],
              };
            }
          }

          // nNF
          if (ide.nNF) {
            if (ide.nNF.length > 0) {
              dadosNota.nNF = {
                descricao: 'Número do Documento Fiscal',
                value: ide.nNF[0],
                valorFormatado: ide.nNF[0],
              };
              dadosFormatados.nNF = {
                descricao: 'Número do Documento Fiscal',
                value: ide.nNF[0],
                valorFormatado: ide.nNF[0],
              };
            }
          }

          // dhEmi
          if (ide.dhEmi) {
            if (ide.dhEmi.length > 0) {
              dadosNota.dhEmi = {
                descricao: 'Data e hora de emissão do Documento Fiscal',
                value: new Date(ide.dhEmi[0]),
                valorFormatado: formatDateDDMMYYYYHHmm(new Date(ide.dhEmi[0])),
              };
              dadosFormatados.dhEmi = {
                descricao: 'Data e hora de emissão do Documento Fiscal',
                value: new Date(ide.dhEmi[0]),
                valorFormatado: formatDateDDMMYYYYHHmm(new Date(ide.dhEmi[0])),
              };
            }
          }

          // dhSaiEnt
          if (ide.dhSaiEnt) {
            if (ide.dhSaiEnt.length > 0) {
              dadosFormatados.dhSaiEnt = {
                descricao:
                  'Data e hora de Saída ou da Entrada da Mercadoria/Produto',
                value: new Date(ide.dhSaiEnt[0]),
                valorFormatado: formatDateDDMMYYYYHHmm(
                  new Date(ide.dhSaiEnt[0]),
                ),
              };
            }
          }

          // tpNF
          if (ide.tpNF) {
            if (ide.tpNF.length > 0) {
              dadosFormatados.tpNF = {
                descricao: 'Tipo de Operação',
                value: ide.tpNF[0],
                valorFormatado: codigosTipoOperacao[ide.tpNF[0]],
              };
            }
          }

          // idDest
          if (ide.idDest) {
            if (ide.idDest.length > 0) {
              dadosFormatados.idDest = {
                descricao: 'Identificador de local de destino da operação',
                value: ide.idDest[0],
                valorFormatado:
                  codigosIdentificadorDeLocalDoDestinoDaOperacao[ide.idDest[0]],
              };
            }
          }

          // cMunFG
          if (ide.cMunFG) {
            if (ide.cMunFG.length > 0) {
              dadosFormatados.cMunFG = {
                descricao: 'Código do Município de Ocorrência do Fato Gerador',
                value: ide.cMunFG[0],
                valorFormatado: ide.cMunFG[0],
              };
            }
          }

          // tpImp
          if (ide.tpImp) {
            if (ide.tpImp.length > 0) {
              dadosFormatados.tpImp = {
                descricao: 'Formato de Impressão do DANFE',
                value: ide.tpImp[0],
                valorFormatado: codigosFormatoImpressaoDanfe[ide.tpImp[0]],
              };
            }
          }

          // tpEmis
          if (ide.tpEmis) {
            if (ide.tpEmis.length > 0) {
              dadosFormatados.tpEmis = {
                descricao: 'Tipo de Emissão da NF-e',
                value: ide.tpEmis[0],
                valorFormatado: codigosTipoEmissaoDaNfe[ide.tpEmis[0]],
              };
            }
          }

          // cDV
          if (ide.cDV) {
            if (ide.cDV.length > 0) {
              dadosFormatados.cDV = {
                descricao: 'Dígito Verificador da Chave de Acesso da NF-e',
                value: ide.cDV[0],
                valorFormatado: ide.cDV[0],
              };
            }
          }

          // tpAmb
          if (ide.tpAmb) {
            if (ide.tpAmb.length > 0) {
              dadosFormatados.tpAmb = {
                descricao: 'Identificação do Ambiente',
                value: ide.tpAmb[0],
                valorFormatado: codigosIdentificacaoDoAmbiente[ide.tpAmb[0]],
              };
            }
          }

          // finNFe
          if (ide.finNFe) {
            if (ide.finNFe.length > 0) {
              dadosFormatados.finNFe = {
                descricao: 'Finalidade de emissão da NF-e',
                value: ide.finNFe[0],
                valorFormatado: codigosFinalidadeDaEmissaoDaNfe[ide.finNFe[0]],
              };
            }
          }

          // indFinal
          if (ide.indFinal) {
            if (ide.indFinal.length > 0) {
              dadosFormatados.indFinal = {
                descricao: 'Indica operação com Consumidor final',
                value: ide.indFinal[0],
                valorFormatado: codigosOperacaoConsumidorFinal[ide.indFinal[0]],
              };
            }
          }

          // indPres
          if (ide.indPres) {
            if (ide.indPres.length > 0) {
              dadosFormatados.indPres = {
                descricao:
                  'Indicador de presença do comprador no estabelecimento comercial no momento da operação',
                value: ide.indPres[0],
                valorFormatado:
                  codigosIndicadorPresencaColaboradorNoEstabelecimentoDaCompra[
                    ide.indPres[0]
                  ],
              };
            }
          }

          // indIntermed
          if (ide.indIntermed) {
            if (ide.indIntermed.length > 0) {
              dadosFormatados.indIntermed = {
                descricao: 'Indicador de intermediador/marketplace',
                value: ide.indIntermed[0],
                valorFormatado:
                  codigosIndicadorDeIntermediario[ide.indIntermed[0]],
              };
            }
          }

          // procEmi
          if (ide.procEmi) {
            if (ide.procEmi.length > 0) {
              dadosFormatados.procEmi = {
                descricao: 'Processo de emissão da NF-e',
                value: ide.procEmi[0],
                valorFormatado: codigosProecessoEmissaoNfe[ide.procEmi[0]],
              };
            }
          }

          // verProc
          if (ide.verProc) {
            if (ide.verProc.length > 0) {
              dadosFormatados.verProc = {
                descricao: 'Versão do Processo de emissão da NF-e',
                value: ide.verProc[0],
                valorFormatado: ide.verProc[0],
              };
            }
          }

          // dhCont
          if (ide.dhCont) {
            if (ide.dhCont.length > 0) {
              dadosFormatados.dhCont = {
                descricao: 'Data e Hora da entrada em contingênci',
                value: ide.dhCont[0],
                valorFormatado: ide.dhCont[0],
              };
            }
          }

          // xJust
          if (ide.xJust) {
            if (ide.xJust.length > 0) {
              dadosFormatados.xJust = {
                descricao: 'Justificativa da entrada em contingência',
                value: ide.xJust[0],
                valorFormatado: ide.xJust[0],
              };
            }
          }

          // NFref
          if (ide.NFref) {
            if (ide.NFref.length > 0) {
              const NFref = ide.NFref[0];
              const NFrefFormatado = {};

              // refNFe
              if (NFref.refNFe) {
                if (NFref.refNFe.length > 0) {
                  NFrefFormatado.refNFe = {
                    descricao: 'Chave de acesso da NF-e referenciada',
                    value: NFref.refNFe[0],
                    valorFormatado: NFref.refNFe[0],
                  };
                }
              }

              // refCTe
              if (NFref.refCTe) {
                if (NFref.refCTe.length > 0) {
                  NFrefFormatado.refCTe = {
                    descricao: 'Chave de acesso do CT-e referenciada',
                    value: NFref.refCTe[0],
                    valorFormatado: NFref.refCTe[0],
                  };
                }
              }

              // refNF
              if (NFref.refNF) {
                if (NFref.refNF.length > 0) {
                  const refNF = NFref.refNF[0];
                  const refNFFormatado = {};

                  // cUF
                  if (refNF.cUF) {
                    if (refNF.cUF.length > 0) {
                      refNFFormatado.cUF = {
                        descricao: 'Código da UF do emitente',
                        value: refNF.cUF[0],
                        valorFormatado: refNF.cUF[0],
                      };
                    }
                  }

                  // AAMM
                  if (refNF.AAMM) {
                    if (refNF.AAMM.length > 0) {
                      refNFFormatado.AAMM = {
                        descricao: 'Ano e Mês de emissão da NF-e',
                        value: refNF.AAMM[0],
                        valorFormatado: refNF.AAMM[0],
                      };
                    }
                  }

                  // CNPJ
                  if (refNF.CNPJ) {
                    if (refNF.CNPJ.length > 0) {
                      refNFFormatado.CNPJ = {
                        descricao: 'CNPJ do emitente',
                        value: refNF.CNPJ[0],
                        valorFormatado: refNF.CNPJ[0],
                      };
                    }
                  }

                  // mod
                  if (refNF.mod) {
                    if (refNF.mod.length > 0) {
                      refNFFormatado.mod = {
                        descricao: 'Modelo do Documento Fiscal',
                        value: refNF.mod[0],
                        valorFormatado: refNF.mod[0],
                      };
                    }
                  }

                  // serie
                  if (refNF.serie) {
                    if (refNF.serie.length > 0) {
                      refNFFormatado.serie = {
                        descricao: 'Série do Documento Fiscal',
                        value: refNF.serie[0],
                        valorFormatado: refNF.serie[0],
                      };
                    }
                  }

                  // nNF
                  if (refNF.nNF) {
                    if (refNF.nNF.length > 0) {
                      refNFFormatado.nNF = {
                        descricao: 'Número do Documento Fiscal',
                        value: refNF.nNF[0],
                        valorFormatado: refNF.nNF[0],
                      };
                    }
                  }

                  NFrefFormatado.refNF = {
                    descricao:
                      'Informação da NF modelo 1/1A ou NF modelo 2 referenciada (alterado pela NT2016.002)',
                    value: refNFFormatado,
                    valorFormatado: refNFFormatado,
                  };
                }
              }

              // refNFP
              if (NFref.refNFP) {
                if (NFref.refNFP.length > 0) {
                  const refNFP = NFref.refNFP[0];
                  const refNFPFormatado = {};

                  // cUF
                  if (refNFP.cUF) {
                    if (refNFP.cUF.length > 0) {
                      refNFPFormatado.cUF = {
                        descricao: 'Código da UF do emitente',
                        value: refNFP.cUF[0],
                        valorFormatado: refNFP.cUF[0],
                      };
                    }
                  }

                  // AAMM
                  if (refNFP.AAMM) {
                    if (refNFP.AAMM.length > 0) {
                      refNFPFormatado.AAMM = {
                        descricao: 'Ano e Mês de emissão da NF-e',
                        value: refNFP.AAMM[0],
                        valorFormatado: refNFP.AAMM[0],
                      };
                    }
                  }

                  // CNPJ
                  if (refNFP.CNPJ) {
                    if (refNFP.CNPJ.length > 0) {
                      refNFPFormatado.CNPJ = {
                        descricao: 'CNPJ do emitente',
                        value: refNFP.CNPJ[0],
                        valorFormatado: refNFP.CNPJ[0],
                      };
                    }
                  }

                  // CPF
                  if (refNFP.CPF) {
                    if (refNFP.CPF.length > 0) {
                      refNFPFormatado.CPF = {
                        descricao: 'CPF do emitente',
                        value: refNFP.CPF[0],
                        valorFormatado: refNFP.CPF[0],
                      };
                    }
                  }

                  // IE
                  if (refNFP.IE) {
                    if (refNFP.IE.length > 0) {
                      refNFPFormatado.IE = {
                        descricao: 'IE do emitente',
                        value: refNFP.IE[0],
                        valorFormatado: refNFP.IE[0],
                      };
                    }
                  }

                  // mod
                  if (refNFP.mod) {
                    if (refNFP.mod.length > 0) {
                      refNFPFormatado.mod = {
                        descricao: 'Modelo do Documento Fiscal',
                        value: refNFP.mod[0],
                        valorFormatado: refNFP.mod[0],
                      };
                    }
                  }

                  // serie
                  if (refNFP.serie) {
                    if (refNFP.serie.length > 0) {
                      refNFPFormatado.serie = {
                        descricao: 'Série do Documento Fiscal',
                        value: refNFP.serie[0],
                        valorFormatado: refNFP.serie[0],
                      };
                    }
                  }

                  // nNF
                  if (refNFP.nNF) {
                    if (refNFP.nNF.length > 0) {
                      refNFPFormatado.nNF = {
                        descricao: 'Número do Documento Fiscal',
                        value: refNFP.nNF[0],
                        valorFormatado: refNFP.nNF[0],
                      };
                    }
                  }

                  NFrefFormatado.refNFP = {
                    descricao:
                      'Informações da NF de produtor rural referenciada',
                    value: refNFPFormatado,
                    valorFormatado: refNFPFormatado,
                  };
                }
              }

              // refECF
              if (NFref.refECF) {
                if (NFref.refECF.length > 0) {
                  const refECF = NFref.refECF[0];
                  const refECFFormatado = {};

                  // mod
                  if (refECF.mod) {
                    if (refECF.mod.length > 0) {
                      refECFFormatado.mod = {
                        descricao: 'Modelo do Documento Fiscal',
                        value: refECF.mod[0],
                        valorFormatado: refECF.mod[0],
                      };
                    }
                  }

                  // nECF
                  if (refECF.nECF) {
                    if (refECF.nECF.length > 0) {
                      refECFFormatado.nECF = {
                        descricao: 'Número de ordem sequencial do ECF',
                        value: refECF.nECF[0],
                        valorFormatado: refECF.nECF[0],
                      };
                    }
                  }

                  // nCOO
                  if (refECF.nCOO) {
                    if (refECF.nCOO.length > 0) {
                      refECFFormatado.nCOO = {
                        descricao:
                          'Número do Contador de Ordem de Operação - COO',
                        value: refECF.nCOO[0],
                        valorFormatado: refECF.nCOO[0],
                      };
                    }
                  }

                  NFrefFormatado.refECF = {
                    descricao: 'Informações do Cupom Fiscal referenciado',
                    value: refECFFormatado,
                    valorFormatado: refECFFormatado,
                  };
                }
              }

              dadosFormatados.NFref = {
                descricao: 'Informação de Documentos Fiscais referenciados',
                value: NFrefFormatado,
                valorFormatado: NFrefFormatado,
              };
            }
          }
        }
      }

      setDadosNota(dadosNota);
      return dadosFormatados;
    }

    function getDadosEmit(infNFe) {
      const dadosFormatados = {};
      const dadosEmitente = {};
      if (infNFe.emit) {
        if (infNFe.emit.length > 0) {
          const emit = infNFe.emit[0];

          // CNPJ
          if (emit.CNPJ) {
            if (emit.CNPJ.length > 0) {
              dadosEmitente.CNPJ = {
                descricao: 'CNPJ do emitente',
                value: emit.CNPJ[0],
                valorFormatado: formatCNPJ(emit.CNPJ[0]),
              };
              dadosFormatados.CNPJ = {
                descricao: 'CNPJ do emitente',
                value: emit.CNPJ[0],
                valorFormatado: formatCNPJ(emit.CNPJ[0]),
              };
            }
          }

          // CPF
          if (emit.CPF) {
            if (emit.CPF.length > 0) {
              dadosEmitente.CPF = {
                descricao: 'CPF do remetente',
                value: emit.CPF[0],
                valorFormatado: formatCPF(emit.CPF[0]),
              };
              dadosFormatados.CPF = {
                descricao: 'CPF do remetente',
                value: emit.CPF[0],
                valorFormatado: formatCPF(emit.CPF[0]),
              };
            }
          }

          // xNome
          if (emit.xNome) {
            if (emit.xNome.length > 0) {
              dadosEmitente.xNome = {
                descricao: 'Razão Social ou Nome do emitente',
                value: emit.xNome[0],
                valorFormatado: emit.xNome[0],
              };
              dadosFormatados.xNome = {
                descricao: 'Razão Social ou Nome do emitente',
                value: emit.xNome[0],
                valorFormatado: emit.xNome[0],
              };
            }
          }

          // xFant
          if (emit.xFant) {
            if (emit.xFant.length > 0) {
              dadosEmitente.xFant = {
                descricao: 'Nome fantasia',
                value: emit.xFant[0],
                valorFormatado: emit.xFant[0],
              };
              dadosFormatados.xFant = {
                descricao: 'Nome fantasia',
                value: emit.xFant[0],
                valorFormatado: emit.xFant[0],
              };
            }
          }

          // IE
          if (emit.IE) {
            if (emit.IE.length > 0) {
              dadosEmitente.IE = {
                descricao: 'Inscrição Estadual do Emitente',
                value: emit.IE[0],
                valorFormatado: formatIE(emit.IE[0]),
              };
              dadosFormatados.IE = {
                descricao: 'Inscrição Estadual do Emitente',
                value: emit.IE[0],
                valorFormatado: formatIE(emit.IE[0]),
              };
            }
          }

          // IEST
          if (emit.IEST) {
            if (emit.IEST.length > 0) {
              dadosFormatados.IEST = {
                descricao: 'IE do Substituto Tributário',
                value: emit.IEST[0],
                valorFormatado: emit.IEST[0],
              };
            }
          }

          // IM
          if (emit.IM) {
            if (emit.IM.length > 0) {
              dadosFormatados.IM = {
                descricao: 'Inscrição Municipal do Prestador de Serviço',
                value: emit.IM[0],
                valorFormatado: emit.IM[0],
              };
            }
          }

          // CNAE
          if (emit.CNAE) {
            if (emit.CNAE.length > 0) {
              dadosFormatados.CNAE = {
                descricao: 'CNAE fisca',
                value: emit.CNAE[0],
                valorFormatado: emit.CNAE[0],
              };
            }
          }

          // CRT
          if (emit.CRT) {
            if (emit.CRT.length > 0) {
              dadosFormatados.CRT = {
                descricao: 'Código de Regime Tributário',
                value: emit.CRT[0],
                valorFormatado: emit.CRT[0],
              };
            }
          }

          // enderEmit
          if (emit.enderEmit) {
            if (emit.enderEmit.length > 0) {
              const enderEmit = emit.enderEmit[0];
              const enderEmitFormatado = {};

              // xLgr
              if (enderEmit.xLgr) {
                if (enderEmit.xLgr.length > 0) {
                  enderEmitFormatado.xLgr = {
                    descricao: 'Logradouro',
                    value: enderEmit.xLgr[0],
                    valorFormatado: enderEmit.xLgr[0],
                  };
                }
              }

              // nro
              if (enderEmit.nro) {
                if (enderEmit.nro.length > 0) {
                  enderEmitFormatado.nro = {
                    descricao: 'Número',
                    value: enderEmit.nro[0],
                    valorFormatado: enderEmit.nro[0],
                  };
                }
              }

              // xCpl
              if (enderEmit.xCpl) {
                if (enderEmit.xCpl.length > 0) {
                  enderEmitFormatado.xCpl = {
                    descricao: 'Complemento',
                    value: enderEmit.xCpl[0],
                    valorFormatado: enderEmit.xCpl[0],
                  };
                }
              }

              // xBairro
              if (enderEmit.xBairro) {
                if (enderEmit.xBairro.length > 0) {
                  enderEmitFormatado.xBairro = {
                    descricao: 'Bairro',
                    value: enderEmit.xBairro[0],
                    valorFormatado: enderEmit.xBairro[0],
                  };
                }
              }

              // cMun
              if (enderEmit.cMun) {
                if (enderEmit.cMun.length > 0) {
                  enderEmitFormatado.cMun = {
                    descricao: 'Código do município',
                    value: enderEmit.cMun[0],
                    valorFormatado: enderEmit.cMun[0],
                  };
                }
              }

              // xMun
              if (enderEmit.xMun) {
                if (enderEmit.xMun.length > 0) {
                  enderEmitFormatado.xMun = {
                    descricao: 'Nome do município',
                    value: enderEmit.xMun[0],
                    valorFormatado: enderEmit.xMun[0],
                  };
                }
              }

              // UF
              if (enderEmit.UF) {
                if (enderEmit.UF.length > 0) {
                  enderEmitFormatado.UF = {
                    descricao: 'Sigla da UF',
                    value: enderEmit.UF[0],
                    valorFormatado: enderEmit.UF[0],
                  };
                }
              }

              // CEP
              if (enderEmit.CEP) {
                if (enderEmit.CEP.length > 0) {
                  enderEmitFormatado.CEP = {
                    descricao: 'Código do CEP',
                    value: enderEmit.CEP[0],
                    valorFormatado: enderEmit.CEP[0],
                  };
                }
              }

              // cPais
              if (enderEmit.cPais) {
                if (enderEmit.cPais.length > 0) {
                  enderEmitFormatado.cPais = {
                    descricao: 'Código do Paí',
                    value: enderEmit.cPais[0],
                    valorFormatado: enderEmit.cPais[0],
                  };
                }
              }

              // xPais
              if (enderEmit.xPais) {
                if (enderEmit.xPais.length > 0) {
                  enderEmitFormatado.xPais = {
                    descricao: 'Nome do País',
                    value: enderEmit.xPais[0],
                    valorFormatado: enderEmit.xPais[0],
                  };
                }
              }

              // fone
              if (enderEmit.fone) {
                if (enderEmit.fone.length > 0) {
                  enderEmitFormatado.fone = {
                    descricao: 'Telefone',
                    value: enderEmit.fone[0],
                    valorFormatado: enderEmit.fone[0],
                  };
                }
              }

              dadosFormatados.enderEmit = {
                descricao: 'Endereço do emitente',
                value: enderEmitFormatado,
                valorFormatado: enderEmitFormatado,
              };
            }
          }
        }
      }

      setFornecedor(dadosEmitente);
      return dadosFormatados;
    }

    function getDadosAvulsa(infNFe) {
      const dadosFormatados = {};
      if (infNFe.avulsa) {
        if (infNFe.avulsa.length > 0) {
          const avulsa = infNFe.dest[0];

          // CNPJ
          if (avulsa.CNPJ) {
            if (avulsa.CNPJ.length > 0) {
              dadosFormatados.CNPJ = {
                descricao: 'CNPJ do órgão emitente',
                value: avulsa.CNPJ[0],
                valorFormatado: avulsa.CNPJ[0],
              };
            }
          }

          // xOrgao
          if (avulsa.xOrgao) {
            if (avulsa.xOrgao.length > 0) {
              dadosFormatados.xOrgao = {
                descricao: 'Órgão emitente',
                value: avulsa.xOrgao[0],
                valorFormatado: avulsa.xOrgao[0],
              };
            }
          }

          // matr
          if (avulsa.matr) {
            if (avulsa.matr.length > 0) {
              dadosFormatados.matr = {
                descricao: 'Matrícula do agente do Fisco',
                value: avulsa.matr[0],
                valorFormatado: avulsa.matr[0],
              };
            }
          }

          // xAgente
          if (avulsa.xAgente) {
            if (avulsa.xAgente.length > 0) {
              dadosFormatados.xAgente = {
                descricao: 'Nome do agente do Fisco',
                value: avulsa.xAgente[0],
                valorFormatado: avulsa.xAgente[0],
              };
            }
          }

          // fone
          if (avulsa.fone) {
            if (avulsa.fone.length > 0) {
              dadosFormatados.fone = {
                descricao: 'Telefone',
                value: avulsa.fone[0],
                valorFormatado: avulsa.fone[0],
              };
            }
          }

          // UF
          if (avulsa.UF) {
            if (avulsa.UF.length > 0) {
              dadosFormatados.UF = {
                descricao: 'Sigla da UF',
                value: avulsa.UF[0],
                valorFormatado: avulsa.UF[0],
              };
            }
          }

          // nDAR
          if (avulsa.nDAR) {
            if (avulsa.nDAR.length > 0) {
              dadosFormatados.nDAR = {
                descricao: 'Número do Documento de Arrecadação de Receita',
                value: avulsa.nDAR[0],
                valorFormatado: avulsa.nDAR[0],
              };
            }
          }

          // dEmi
          if (avulsa.dEmi) {
            if (avulsa.dEmi.length > 0) {
              dadosFormatados.dEmi = {
                descricao: 'Número do Documento de Arrecadação de Receita',
                value: avulsa.dEmi[0],
                valorFormatado: avulsa.dEmi[0],
              };
            }
          }

          // vDAR
          if (avulsa.vDAR) {
            if (avulsa.vDAR.length > 0) {
              dadosFormatados.vDAR = {
                descricao:
                  'Valor Total constante no Documento de arrecadação de Receita',
                value: avulsa.vDAR[0],
                valorFormatado: avulsa.vDAR[0],
              };
            }
          }

          // repEmi
          if (avulsa.repEmi) {
            if (avulsa.repEmi.length > 0) {
              dadosFormatados.repEmi = {
                descricao: 'Repartição Fiscal emitente',
                value: avulsa.repEmi[0],
                valorFormatado: avulsa.repEmi[0],
              };
            }
          }

          // dPag
          if (avulsa.dPag) {
            if (avulsa.dPag.length > 0) {
              dadosFormatados.dPag = {
                descricao: 'Data de pagamento do Documento de Arrecadação',
                value: avulsa.dPag[0],
                valorFormatado: new Date(avulsa.dPag[0]),
              };
            }
          }
        }
      }
    }

    function getDadosDest(infNFe) {
      const dadosFormatados = {};
      if (infNFe.dest) {
        if (infNFe.dest.length > 0) {
          const dest = infNFe.dest[0];

          // CNPJ
          if (dest.CNPJ) {
            if (dest.CNPJ.length > 0) {
              dadosFormatados.CNPJ = {
                descricao: 'CNPJ do destinatário',
                value: dest.CNPJ[0],
                valorFormatado: dest.CNPJ[0],
              };
            }
          }

          // CPF
          if (dest.CPF) {
            if (dest.CPF.length > 0) {
              dadosFormatados.CPF = {
                descricao: 'CNPJ do destinatário',
                value: dest.CPF[0],
                valorFormatado: dest.CPF[0],
              };
            }
          }

          // idEstrangeiro
          if (dest.idEstrangeiro) {
            if (dest.idEstrangeiro.length > 0) {
              dadosFormatados.idEstrangeiro = {
                descricao:
                  'Identificação do destinatário no caso de comprador estrangeiro',
                value: dest.idEstrangeiro[0],
                valorFormatado: dest.idEstrangeiro[0],
              };
            }
          }

          // xNome
          if (dest.xNome) {
            if (dest.xNome.length > 0) {
              dadosFormatados.xNome = {
                descricao: 'Razão Social ou nome do destinatário',
                value: dest.xNome[0],
                valorFormatado: dest.xNome[0],
              };
            }
          }

          // indIEDest
          if (dest.indIEDest) {
            if (dest.indIEDest.length > 0) {
              dadosFormatados.indIEDest = {
                descricao: 'Indicador da IE do Destinatário',
                value: dest.indIEDest[0],
                valorFormatado: dest.indIEDest[0],
              };
            }
          }

          // IE
          if (dest.IE) {
            if (dest.IE.length > 0) {
              dadosFormatados.IE = {
                descricao: 'Inscrição Estadual do Destinatário',
                value: dest.IE[0],
                valorFormatado: dest.IE[0],
              };
            }
          }

          // ISUF
          if (dest.ISUF) {
            if (dest.ISUF.length > 0) {
              dadosFormatados.ISUF = {
                descricao: 'Inscrição na SUFRAMA',
                value: dest.ISUF[0],
                valorFormatado: dest.ISUF[0],
              };
            }
          }

          // IM
          if (dest.IM) {
            if (dest.IM.length > 0) {
              dadosFormatados.IM = {
                descricao: 'Inscrição Municipal do Tomador do Serviço',
                value: dest.IM[0],
                valorFormatado: dest.IM[0],
              };
            }
          }

          // email
          if (dest.email) {
            if (dest.email.length > 0) {
              dadosFormatados.email = {
                descricao: 'email',
                value: dest.email[0],
                valorFormatado: dest.email[0],
              };
            }
          }

          // enderEmit
          if (dest.enderDest) {
            if (dest.enderDest.length > 0) {
              const enderDest = dest.enderDest[0];
              const enderDestFormatado = {};

              // xLgr
              if (enderDest.xLgr) {
                if (enderDest.xLgr.length > 0) {
                  enderDestFormatado.xLgr = {
                    descricao: 'Logradouro',
                    value: enderDest.xLgr[0],
                    valorFormatado: enderDest.xLgr[0],
                  };
                }
              }

              // nro
              if (enderDest.nro) {
                if (enderDest.nro.length > 0) {
                  enderDestFormatado.nro = {
                    descricao: 'Número',
                    value: enderDest.nro[0],
                    valorFormatado: enderDest.nro[0],
                  };
                }
              }

              // xCpl
              if (enderDest.xCpl) {
                if (enderDest.xCpl.length > 0) {
                  enderDestFormatado.xCpl = {
                    descricao: 'Complemento',
                    value: enderDest.xCpl[0],
                    valorFormatado: enderDest.xCpl[0],
                  };
                }
              }

              // xBairro
              if (enderDest.xBairro) {
                if (enderDest.xBairro.length > 0) {
                  enderDestFormatado.xBairro = {
                    descricao: 'Bairro',
                    value: enderDest.xBairro[0],
                    valorFormatado: enderDest.xBairro[0],
                  };
                }
              }

              // cMun
              if (enderDest.cMun) {
                if (enderDest.cMun.length > 0) {
                  enderDestFormatado.cMun = {
                    descricao: 'Código do município',
                    value: enderDest.cMun[0],
                    valorFormatado: enderDest.cMun[0],
                  };
                }
              }

              // xMun
              if (enderDest.xMun) {
                if (enderDest.xMun.length > 0) {
                  enderDestFormatado.xMun = {
                    descricao: 'Nome do município',
                    value: enderDest.xMun[0],
                    valorFormatado: enderDest.xMun[0],
                  };
                }
              }

              // UF
              if (enderDest.UF) {
                if (enderDest.UF.length > 0) {
                  enderDestFormatado.UF = {
                    descricao: 'Sigla da UF',
                    value: enderDest.UF[0],
                    valorFormatado: enderDest.UF[0],
                  };
                }
              }

              // CEP
              if (enderDest.CEP) {
                if (enderDest.CEP.length > 0) {
                  enderDestFormatado.CEP = {
                    descricao: 'Código do CEP',
                    value: enderDest.CEP[0],
                    valorFormatado: enderDest.CEP[0],
                  };
                }
              }

              // cPais
              if (enderDest.cPais) {
                if (enderDest.cPais.length > 0) {
                  enderDestFormatado.cPais = {
                    descricao: 'Código do País',
                    value: enderDest.cPais[0],
                    valorFormatado: enderDest.cPais[0],
                  };
                }
              }

              // xPais
              if (enderDest.xPais) {
                if (enderDest.xPais.length > 0) {
                  enderDestFormatado.xPais = {
                    descricao: 'Nome do País',
                    value: enderDest.xPais[0],
                    valorFormatado: enderDest.xPais[0],
                  };
                }
              }

              // fone
              if (enderDest.fone) {
                if (enderDest.fone.length > 0) {
                  enderDestFormatado.fone = {
                    descricao: 'Telefone',
                    value: enderDest.fone[0],
                    valorFormatado: enderDest.fone[0],
                  };
                }
              }

              dadosFormatados.enderEmit = {
                descricao: 'Endereço do Destinatário da NF-e',
                value: enderDestFormatado,
                valorFormatado: enderDestFormatado,
              };
            }
          }
        }
      }

      return dadosFormatados;
    }

    function getDadosRetirada(infNFe) {
      const dadosFormatados = {};
      if (infNFe.retirada) {
        if (infNFe.retirada.length > 0) {
          const retirada = infNFe.retirada[0];

          // CNPJ
          if (retirada.CNPJ) {
            if (retirada.CNPJ.length > 0) {
              dadosFormatados.CNPJ = {
                descricao: 'CNPJ',
                value: retirada.CNPJ[0],
                valorFormatado: retirada.CNPJ[0],
              };
            }
          }

          // CPF
          if (retirada.CPF) {
            if (retirada.CPF.length > 0) {
              dadosFormatados.CPF = {
                descricao: 'CPF',
                value: retirada.CPF[0],
                valorFormatado: retirada.CPF[0],
              };
            }
          }

          // xNome
          if (retirada.xNome) {
            if (retirada.xNome.length > 0) {
              dadosFormatados.xNome = {
                descricao: 'Razão Social ou Nome do Expedidor',
                value: retirada.xNome[0],
                valorFormatado: retirada.xNome[0],
              };
            }
          }

          // xLgr
          if (retirada.xLgr) {
            if (retirada.xLgr.length > 0) {
              dadosFormatados.xLgr = {
                descricao: 'Logradouro',
                value: retirada.xLgr[0],
                valorFormatado: retirada.xLgr[0],
              };
            }
          }

          // nro
          if (retirada.nro) {
            if (retirada.nro.length > 0) {
              dadosFormatados.nro = {
                descricao: 'Número',
                value: retirada.nro[0],
                valorFormatado: retirada.nro[0],
              };
            }
          }

          // xCpl
          if (retirada.xCpl) {
            if (retirada.xCpl.length > 0) {
              dadosFormatados.xCpl = {
                descricao: 'Complemento',
                value: retirada.xCpl[0],
                valorFormatado: retirada.xCpl[0],
              };
            }
          }

          // xBairro
          if (retirada.xBairro) {
            if (retirada.xBairro.length > 0) {
              dadosFormatados.xBairro = {
                descricao: 'Bairro',
                value: retirada.xBairro[0],
                valorFormatado: retirada.xBairro[0],
              };
            }
          }

          // cMun
          if (retirada.cMun) {
            if (retirada.cMun.length > 0) {
              dadosFormatados.cMun = {
                descricao: 'Código do município',
                value: retirada.cMun[0],
                valorFormatado: retirada.cMun[0],
              };
            }
          }

          // xMun
          if (retirada.xMun) {
            if (retirada.xMun.length > 0) {
              dadosFormatados.xMun = {
                descricao: 'Nome do município',
                value: retirada.xMun[0],
                valorFormatado: retirada.xMun[0],
              };
            }
          }

          // UF
          if (retirada.UF) {
            if (retirada.UF.length > 0) {
              dadosFormatados.UF = {
                descricao: 'Sigla da UF',
                value: retirada.UF[0],
                valorFormatado: retirada.UF[0],
              };
            }
          }

          // CEP
          if (retirada.CEP) {
            if (retirada.CEP.length > 0) {
              dadosFormatados.CEP = {
                descricao: 'Código do CEP',
                value: retirada.CEP[0],
                valorFormatado: retirada.CEP[0],
              };
            }
          }

          // cPais
          if (retirada.cPais) {
            if (retirada.cPais.length > 0) {
              dadosFormatados.cPais = {
                descricao: 'Código do País',
                value: retirada.cPais[0],
                valorFormatado: retirada.cPais[0],
              };
            }
          }

          // xPais
          if (retirada.xPais) {
            if (retirada.xPais.length > 0) {
              dadosFormatados.xPais = {
                descricao: 'Nome do País',
                value: retirada.xPais[0],
                valorFormatado: retirada.xPais[0],
              };
            }
          }

          // fone
          if (retirada.fone) {
            if (retirada.fone.length > 0) {
              dadosFormatados.fone = {
                descricao: 'Telefone',
                value: retirada.fone[0],
                valorFormatado: retirada.fone[0],
              };
            }
          }

          // email
          if (retirada.email) {
            if (retirada.email.length > 0) {
              dadosFormatados.email = {
                descricao: 'Endereço de e-mail do Expedidor',
                value: retirada.email[0],
                valorFormatado: retirada.email[0],
              };
            }
          }

          // IE
          if (retirada.IE) {
            if (retirada.IE.length > 0) {
              dadosFormatados.IE = {
                descricao: 'Inscrição Estadual do Estabelecimento Expedidor',
                value: retirada.IE[0],
                valorFormatado: retirada.IE[0],
              };
            }
          }
        }
      }

      return dadosFormatados;
    }

    function getDadosEntrega(infNFe) {
      const dadosFormatados = {};
      if (infNFe.entrega) {
        if (infNFe.entrega.length > 0) {
          const entrega = infNFe.entrega[0];

          // CNPJ
          if (entrega.CNPJ) {
            if (entrega.CNPJ.length > 0) {
              dadosFormatados.CNPJ = {
                descricao: 'CNPJ',
                value: entrega.CNPJ[0],
                valorFormatado: entrega.CNPJ[0],
              };
            }
          }

          // CPF
          if (entrega.CPF) {
            if (entrega.CPF.length > 0) {
              dadosFormatados.CNPJ = {
                descricao: 'CPF',
                value: entrega.CPF[0],
                valorFormatado: entrega.CPF[0],
              };
            }
          }

          // xNome
          if (entrega.xNome) {
            if (entrega.xNome.length > 0) {
              dadosFormatados.xNome = {
                descricao: 'Razão Social ou Nome do Recebedor',
                value: entrega.xNome[0],
                valorFormatado: entrega.xNome[0],
              };
            }
          }

          // xLgr
          if (entrega.xLgr) {
            if (entrega.xLgr.length > 0) {
              dadosFormatados.xLgr = {
                descricao: 'Logradouro',
                value: entrega.xLgr[0],
                valorFormatado: entrega.xLgr[0],
              };
            }
          }

          // nro
          if (entrega.nro) {
            if (entrega.nro.length > 0) {
              dadosFormatados.nro = {
                descricao: 'Número',
                value: entrega.nro[0],
                valorFormatado: entrega.nro[0],
              };
            }
          }

          // xCpl
          if (entrega.xCpl) {
            if (entrega.xCpl.length > 0) {
              dadosFormatados.xCpl = {
                descricao: 'Complemento',
                value: entrega.xCpl[0],
                valorFormatado: entrega.xCpl[0],
              };
            }
          }

          // xCpl
          if (entrega.xBairro) {
            if (entrega.xBairro.length > 0) {
              dadosFormatados.xBairro = {
                descricao: 'Bairro',
                value: entrega.xBairro[0],
                valorFormatado: entrega.xBairro[0],
              };
            }
          }

          // cMun
          if (entrega.cMun) {
            if (entrega.cMun.length > 0) {
              dadosFormatados.cMun = {
                descricao: 'Código do município',
                value: entrega.cMun[0],
                valorFormatado: entrega.cMun[0],
              };
            }
          }

          // xMun
          if (entrega.xMun) {
            if (entrega.xMun.length > 0) {
              dadosFormatados.xMun = {
                descricao: 'Nome do município',
                value: entrega.xMun[0],
                valorFormatado: entrega.xMun[0],
              };
            }
          }

          // UF
          if (entrega.UF) {
            if (entrega.UF.length > 0) {
              dadosFormatados.UF = {
                descricao: 'Sigla da UF',
                value: entrega.UF[0],
                valorFormatado: entrega.UF[0],
              };
            }
          }

          // CEP
          if (entrega.CEP) {
            if (entrega.CEP.length > 0) {
              dadosFormatados.CEP = {
                descricao: 'Código do CEP',
                value: entrega.CEP[0],
                valorFormatado: entrega.CEP[0],
              };
            }
          }

          // cPais
          if (entrega.cPais) {
            if (entrega.cPais.length > 0) {
              dadosFormatados.cPais = {
                descricao: 'Código do País',
                value: entrega.cPais[0],
                valorFormatado: entrega.cPais[0],
              };
            }
          }

          // xPais
          if (entrega.xPais) {
            if (entrega.xPais.length > 0) {
              dadosFormatados.xPais = {
                descricao: 'Nome do País',
                value: entrega.xPais[0],
                valorFormatado: entrega.xPais[0],
              };
            }
          }

          // fone
          if (entrega.fone) {
            if (entrega.fone.length > 0) {
              dadosFormatados.fone = {
                descricao: 'Telefone',
                value: entrega.fone[0],
                valorFormatado: entrega.fone[0],
              };
            }
          }

          // email
          if (entrega.email) {
            if (entrega.email.length > 0) {
              dadosFormatados.email = {
                descricao: 'Endereço de e-mail do Recebedo',
                value: entrega.email[0],
                valorFormatado: entrega.email[0],
              };
            }
          }

          // IE
          if (entrega.IE) {
            if (entrega.IE.length > 0) {
              dadosFormatados.IE = {
                descricao: 'Inscrição Estadual do Estabelecimento Recebedor',
                value: entrega.IE[0],
                valorFormatado: entrega.IE[0],
              };
            }
          }
        }
      }
    }

    function getDadosAutXml(infNFe) {
      const dadosFormatados = {};
      if (infNFe.autXML) {
        if (infNFe.autXML.length > 0) {
          const autXML = infNFe.autXML[0];

          // CNPJ
          if (autXML.CNPJ) {
            if (autXML.CNPJ.length > 0) {
              dadosFormatados.CNPJ = {
                descricao: 'CNPJ Autorizado',
                value: autXML.CNPJ[0],
                valorFormatado: autXML.CNPJ[0],
              };
            }
          }

          // CPF
          if (autXML.CPF) {
            if (autXML.CPF.length > 0) {
              dadosFormatados.CPF = {
                descricao: 'CPF Autorizado',
                value: autXML.CPF[0],
                valorFormatado: autXML.CPF[0],
              };
            }
          }
        }
      }

      return dadosFormatados;
    }

    function getDadosDet(infNFe) {
      const dadosFormatados = [];
      const itens = [];
      if (infNFe.det) {
        const dets = infNFe.det;

        for (let i = 0; i < dets.length; i++) {
          const det = dets[i];
          const detFormatados = {};

          const detItem = {};

          // nItem
          if (det.nItem) {
            if (det.nItem.length > 0) {
              detFormatados.nItem = {
                descricao: 'Número do item',
                value: det.nItem[0],
                valorFormatado: det.nItem[0],
              };
            }
          }

          // prod
          if (det.prod) {
            if (det.prod.length > 0) {
              const prod = det.prod[0];
              const prodFormatado = {};

              // cProd
              if (prod.cProd) {
                if (prod.cProd.length > 0) {
                  detItem.cProd = {
                    descricao: 'Código do produto ou serviço',
                    value: prod.cProd[0],
                    valorFormatado: prod.cProd[0],
                  };
                  prodFormatado.cProd = {
                    descricao: 'Código do produto ou serviço',
                    value: prod.cProd[0],
                    valorFormatado: prod.cProd[0],
                  };
                }
              }

              // cEAN
              if (prod.cEAN) {
                if (prod.cEAN.length > 0) {
                  detItem.cEAN = {
                    descricao:
                      'GTIN (Global Trade Item Number) do produto, antigo código EAN ou código de barras',
                    value: prod.cEAN[0],
                    valorFormatado: prod.cEAN[0],
                  };
                  prodFormatado.cEAN = {
                    descricao:
                      'GTIN (Global Trade Item Number) do produto, antigo código EAN ou código de barras',
                    value: prod.cEAN[0],
                    valorFormatado: prod.cEAN[0],
                  };
                }
              }

              // xProd
              if (prod.xProd) {
                if (prod.xProd.length > 0) {
                  detItem.xProd = {
                    descricao: 'Descrição do produto ou serviço',
                    value: prod.xProd[0],
                    valorFormatado: prod.xProd[0],
                  };
                  prodFormatado.xProd = {
                    descricao: 'Descrição do produto ou serviço',
                    value: prod.xProd[0],
                    valorFormatado: prod.xProd[0],
                  };
                }
              }

              // NCM
              if (prod.NCM) {
                if (prod.NCM.length > 0) {
                  detItem.NCM = {
                    descricao: 'Código NCM com 8 dígitos ',
                    value: prod.NCM[0],
                    valorFormatado: prod.NCM[0],
                  };
                  prodFormatado.NCM = {
                    descricao: 'Código NCM com 8 dígitos ',
                    value: prod.NCM[0],
                    valorFormatado: prod.NCM[0],
                  };
                }
              }

              // NVE
              if (prod.NVE) {
                if (prod.NVE.length > 0) {
                  prodFormatado.NVE = {
                    descricao:
                      'Codificação NVE - Nomenclatura de Valor Aduaneiro e Estatística.',
                    value: prod.NVE[0],
                    valorFormatado: prod.NVE[0],
                  };
                }
              }

              // CEST
              if (prod.CEST) {
                if (prod.CEST.length > 0) {
                  prodFormatado.CEST = {
                    descricao: 'Código CEST',
                    value: prod.CEST[0],
                    valorFormatado: prod.CEST[0],
                  };
                }
              }

              // indEscala
              if (prod.indEscala) {
                if (prod.indEscala.length > 0) {
                  prodFormatado.indEscala = {
                    descricao: 'Indicador de Escala Relevante',
                    value: prod.indEscala[0],
                    valorFormatado: prod.indEscala[0],
                  };
                }
              }

              // CNPJFab
              if (prod.CNPJFab) {
                if (prod.CNPJFab.length > 0) {
                  prodFormatado.CNPJFab = {
                    descricao: 'CNPJ do Fabricante da Mercadoria',
                    value: prod.CNPJFab[0],
                    valorFormatado: prod.CNPJFab[0],
                  };
                }
              }

              // cBenef
              if (prod.cBenef) {
                if (prod.cBenef.length > 0) {
                  prodFormatado.cBenef = {
                    descricao:
                      'Código de Benefício Fiscal na UF aplicado ao item',
                    value: prod.cBenef[0],
                    valorFormatado: prod.cBenef[0],
                  };
                }
              }

              // EXTIPI
              if (prod.EXTIPI) {
                if (prod.EXTIPI.length > 0) {
                  prodFormatado.EXTIPI = {
                    descricao: 'EX_TIPI',
                    value: prod.EXTIPI[0],
                    valorFormatado: prod.EXTIPI[0],
                  };
                }
              }

              // CFOP
              if (prod.CFOP) {
                if (prod.CFOP.length > 0) {
                  prodFormatado.CFOP = {
                    descricao: 'Código Fiscal de Operações e Prestações',
                    value: prod.CFOP[0],
                    valorFormatado: prod.CFOP[0],
                  };
                }
              }

              // uCom
              if (prod.uCom) {
                if (prod.uCom.length > 0) {
                  detItem.uCom = {
                    descricao: 'Unidade Comercial ',
                    value: prod.uCom[0],
                    valorFormatado: prod.uCom[0],
                  };
                  prodFormatado.uCom = {
                    descricao: 'Unidade Comercial ',
                    value: prod.uCom[0],
                    valorFormatado: prod.uCom[0],
                  };
                }
              }

              // qCom
              if (prod.qCom) {
                if (prod.qCom.length > 0) {
                  detItem.qCom = {
                    descricao: 'Quantidade Comercial',
                    value: prod.qCom[0],
                    valorFormatado: prod.qCom[0],
                  };
                  prodFormatado.qCom = {
                    descricao: 'Quantidade Comercial',
                    value: prod.qCom[0],
                    valorFormatado: prod.qCom[0],
                  };
                }
              }

              // vUnCom
              if (prod.vUnCom) {
                if (prod.vUnCom.length > 0) {
                  detItem.vUnCom = {
                    descricao: 'Valor Unitário de Comercialização',
                    value: prod.vUnCom[0],
                    valorFormatado: formatMoeda(Number(prod.vUnCom[0])),
                  };
                  prodFormatado.vUnCom = {
                    descricao: 'Valor Unitário de Comercialização',
                    value: prod.vUnCom[0],
                    valorFormatado: formatMoeda(Number(prod.vUnCom[0])),
                  };
                }
              }

              // vProd
              if (prod.vProd) {
                if (prod.vProd.length > 0) {
                  detItem.vProd = {
                    descricao: 'Valor Total Bruto dos Produtos ou Serviços.',
                    value: prod.vProd[0],
                    valorFormatado: formatMoeda(Number(prod.vProd[0])),
                  };
                  prodFormatado.vProd = {
                    descricao: 'Valor Total Bruto dos Produtos ou Serviços.',
                    value: prod.vProd[0],
                    valorFormatado: formatMoeda(Number(prod.vProd[0])),
                  };
                }
              }

              // cEANTrib
              if (prod.cEANTrib) {
                if (prod.cEANTrib.length > 0) {
                  detItem.cEANTrib = {
                    descricao:
                      'GTIN (Global Trade Item Number) da unidade tributável, antigo código EAN ou código de barras',
                    value: prod.cEANTrib[0],
                    valorFormatado: prod.cEANTrib[0],
                  };
                  prodFormatado.cEANTrib = {
                    descricao:
                      'GTIN (Global Trade Item Number) da unidade tributável, antigo código EAN ou código de barras',
                    value: prod.cEANTrib[0],
                    valorFormatado: prod.cEANTrib[0],
                  };
                }
              }

              // uTrib
              if (prod.uTrib) {
                if (prod.uTrib.length > 0) {
                  detItem.uTrib = {
                    descricao: 'Unidade Tributável',
                    value: prod.uTrib[0],
                    valorFormatado: prod.uTrib[0],
                  };
                  prodFormatado.uTrib = {
                    descricao: 'Unidade Tributável',
                    value: prod.uTrib[0],
                    valorFormatado: prod.uTrib[0],
                  };
                }
              }

              // qTrib
              if (prod.qTrib) {
                if (prod.qTrib.length > 0) {
                  detItem.qTrib = {
                    descricao: 'Quantidade Tributável',
                    value: prod.qTrib[0],
                    valorFormatado: prod.qTrib[0],
                  };
                  prodFormatado.qTrib = {
                    descricao: 'Quantidade Tributável',
                    value: prod.qTrib[0],
                    valorFormatado: prod.qTrib[0],
                  };
                }
              }

              // vUnTrib
              if (prod.vUnTrib) {
                if (prod.vUnTrib.length > 0) {
                  detItem.vUnTrib = {
                    descricao: 'Valor Unitário de tributação',
                    value: prod.vUnTrib[0],
                    valorFormatado: formatMoeda(Number(prod.vUnTrib[0])),
                  };
                  prodFormatado.vUnTrib = {
                    descricao: 'Valor Unitário de tributação',
                    value: prod.vUnTrib[0],
                    valorFormatado: formatMoeda(Number(prod.vUnTrib[0])),
                  };
                }
              }

              // vFrete
              if (prod.vFrete) {
                if (prod.vFrete.length > 0) {
                  detItem.vFrete = {
                    descricao: 'Valor Total do Frete',
                    value: prod.vFrete[0],
                    valorFormatado: formatMoeda(Number(prod.vFrete[0])),
                  };
                  prodFormatado.vFrete = {
                    descricao: 'Valor Total do Frete',
                    value: prod.vFrete[0],
                    valorFormatado: formatMoeda(Number(prod.vFrete[0])),
                  };
                }
              }

              // vSeg
              if (prod.vSeg) {
                if (prod.vSeg.length > 0) {
                  detItem.vSeg = {
                    descricao: 'Valor Total do Seguro',
                    value: prod.vSeg[0],
                    valorFormatado: formatMoeda(Number(prod.vSeg[0])),
                  };
                  prodFormatado.vSeg = {
                    descricao: 'Valor Total do Seguro',
                    value: prod.vSeg[0],
                    valorFormatado: formatMoeda(Number(prod.vSeg[0])),
                  };
                }
              }

              // vDesc
              if (prod.vDesc) {
                if (prod.vDesc.length > 0) {
                  detItem.vDesc = {
                    descricao: 'Valor do Desconto',
                    value: prod.vDesc[0],
                    valorFormatado: formatMoeda(Number(prod.vDesc[0])),
                  };
                  prodFormatado.vDesc = {
                    descricao: 'Valor do Desconto',
                    value: prod.vDesc[0],
                    valorFormatado: formatMoeda(Number(prod.vDesc[0])),
                  };
                }
              }

              // vOutro
              if (prod.vOutro) {
                if (prod.vOutro.length > 0) {
                  detItem.vOutro = {
                    descricao: 'Outras despesas acessórias',
                    value: prod.vOutro[0],
                    valorFormatado: formatMoeda(Number(prod.vOutro[0])),
                  };
                  prodFormatado.vOutro = {
                    descricao: 'Outras despesas acessórias',
                    value: prod.vOutro[0],
                    valorFormatado: formatMoeda(Number(prod.vOutro[0])),
                  };
                }
              }

              // indTot
              if (prod.indTot) {
                if (prod.indTot.length > 0) {
                  prodFormatado.indTot = {
                    descricao:
                      'Indica se valor do Item (vProd) entra no valor total da NF-e (vProd)',
                    value: prod.indTot[0],
                    valorFormatado: prod.indTot[0],
                  };
                }
              }

              // rastro
              if (prod.rastro) {
                if (prod.rastro.length > 0) {
                  const rastro = prod.rastro[0];
                  const rastroFormatado = {};

                  // nLote
                  if (rastro.nLote) {
                    if (rastro.nLote.length > 0) {
                      rastroFormatado.nLote = {
                        descricao: 'Número do Lote do produto',
                        value: rastro.nLote[0],
                        valorFormatado: rastro.nLote[0],
                      };
                    }
                  }

                  // qLote
                  if (rastro.qLote) {
                    if (rastro.qLote.length > 0) {
                      rastroFormatado.qLote = {
                        descricao: 'Quantidade de produto no Lote ',
                        value: rastro.qLote[0],
                        valorFormatado: rastro.qLote[0],
                      };
                    }
                  }

                  // dFab
                  if (rastro.dFab) {
                    if (rastro.dFab.length > 0) {
                      rastroFormatado.dFab = {
                        descricao: 'Data de fabricação/ Produção',
                        value: new Date(rastro.dFab[0]),
                        valorFormatado: formatDateDDMMYYYY(
                          new Date(rastro.dFab[0]),
                        ),
                      };
                    }
                  }

                  // dVal
                  if (rastro.dVal) {
                    if (rastro.dVal.length > 0) {
                      detItem.dVal = {
                        descricao: 'Data de validade',
                        value: new Date(rastro.dVal[0]),
                        valorFormatado: formatDateDDMMYYYY(
                          new Date(rastro.dVal[0]),
                        ),
                      };
                      rastroFormatado.dVal = {
                        descricao: 'Data de validade',
                        value: new Date(rastro.dVal[0]),
                        valorFormatado: formatDateDDMMYYYY(
                          new Date(rastro.dVal[0]),
                        ),
                      };
                    }
                  }

                  // cAgreg
                  if (rastro.cAgreg) {
                    if (rastro.cAgreg.length > 0) {
                      rastroFormatado.cAgreg = {
                        descricao: 'Código de Agregação',
                        value: rastro.cAgreg[0],
                        valorFormatado: rastro.cAgreg[0],
                      };
                    }
                  }

                  prodFormatado.rastro = {
                    descricao:
                      'Detalhamento de produto sujeito a rastreabilidade',
                    value: rastroFormatado,
                    valorFormatado: rastroFormatado,
                  };
                }
              }

              //itens.push(prodFormatado);

              detFormatados.prod = {
                descricao: 'Detalhamento de Produtos e Serviços',
                value: prodFormatado,
                valorFormatado: prodFormatado,
              };
            }
          }

          if (det.imposto) {
            if (det.imposto.length > 0) {
              const imposto = det.imposto[0];
              if (imposto.IPI) {
                if (imposto.IPI.length > 0) {
                  const IPI = imposto.IPI[0];
                  const IPIFormatado = {};

                  // IPITrib
                  if (IPI.IPITrib) {
                    if (IPI.IPITrib.length > 0) {
                      const IPITrib = IPI.IPITrib[0];

                      // vIPI
                      if (IPITrib.vIPI) {
                        if (IPITrib.vIPI.length > 0) {
                          detItem.vIPI = {
                            descricao: 'Valor do IPI',
                            value: IPITrib.vIPI[0],
                            valorFormatado: formatMoeda(
                              Number(IPITrib.vIPI[0]),
                            ),
                          };
                          IPIFormatado.vIPI = {
                            descricao: 'Valor do IPI',
                            value: IPITrib.vIPI[0],
                            valorFormatado: formatMoeda(
                              Number(IPITrib.vIPI[0]),
                            ),
                          };
                        }
                      }
                    }
                  }

                  detFormatados.IPI = {
                    descricao: 'Grupo IPI',
                    value: IPIFormatado,
                    valorFormatado: IPIFormatado,
                  };
                }
              }

              //////////////////////

              if (imposto.ICMS) {
                if (imposto.ICMS.length > 0) {
                  const ICMS = imposto.ICMS[0];
                  const ICMSFormatado = {};

                  if (ICMS.ICMS10) {
                    if (ICMS.ICMS10.length > 0) {
                      const ICMS10 = ICMS.ICMS10[0];

                      // vICMSST
                      if (ICMS10.vICMSST) {
                        if (ICMS10.vICMSST.length > 0) {
                          detItem.vICMSST = {
                            descricao: 'Valor do ICMS ST',
                            value: ICMS10.vICMSST[0],
                            valorFormatado: formatMoeda(
                              Number(ICMS10.vICMSST[0]),
                            ),
                          };
                          ICMSFormatado.vICMSST = {
                            descricao: 'Valor do ICMS ST',
                            value: ICMS10.vICMSST[0],
                            valorFormatado: formatMoeda(
                              Number(ICMS10.vICMSST[0]),
                            ),
                          };
                        }
                      }
                    }
                  }

                  detFormatados.ICMS = {
                    descricao: 'Informações do ICMS da Operação própria e ST',
                    value: ICMSFormatado,
                    valorFormatado: ICMSFormatado,
                  };
                }
              }
            }
          }

          itens.push(detItem);

          dadosFormatados.push(detFormatados);
        }
      }

      setItens(itens);
      return dadosFormatados;
    }

    function getDadosTotal(infNFe) {
      const dadosFormatados = {};
      const totaisFormatados = [];
      if (infNFe.total) {
        if (infNFe.total.length > 0) {
          const total = infNFe.total[0];

          // ICMSTot
          if (total.ICMSTot) {
            if (total.ICMSTot.length > 0) {
              const ICMSTot = total.ICMSTot[0];
              const ICMSTotFormatado = {};

              // vBC
              if (ICMSTot.vBC) {
                if (ICMSTot.vBC.length > 0) {
                  ICMSTotFormatado.vBC = {
                    descricao: 'Base de Cálculo do ICMS',
                    value: ICMSTot.vBC[0],
                    valorFormatado: ICMSTot.vBC[0],
                  };
                }
              }

              // vICMS
              if (ICMSTot.vICMS) {
                if (ICMSTot.vICMS.length > 0) {
                  ICMSTotFormatado.vICMS = {
                    descricao: 'Valor Total do ICMS',
                    value: ICMSTot.vICMS[0],
                    valorFormatado: ICMSTot.vICMS[0],
                  };
                }
              }

              // vICMSDeson
              if (ICMSTot.vICMSDeson) {
                if (ICMSTot.vICMSDeson.length > 0) {
                  ICMSTotFormatado.vICMSDeson = {
                    descricao: 'Valor Total do ICMS desonerado',
                    value: ICMSTot.vICMSDeson[0],
                    valorFormatado: ICMSTot.vICMSDeson[0],
                  };
                }
              }

              // vFCPUFDest
              if (ICMSTot.vFCPUFDest) {
                if (ICMSTot.vFCPUFDest.length > 0) {
                  ICMSTotFormatado.vFCPUFDest = {
                    descricao:
                      'Valor total do ICMS relativo Fundo de Combate à Pobreza (FCP) da UF de destino',
                    value: ICMSTot.vFCPUFDest[0],
                    valorFormatado: ICMSTot.vFCPUFDest[0],
                  };
                }
              }

              // vICMSUFDest
              if (ICMSTot.vICMSUFDest) {
                if (ICMSTot.vICMSUFDest.length > 0) {
                  ICMSTotFormatado.vICMSUFDest = {
                    descricao:
                      'Valor total do ICMS Interestadual para a UF de destino',
                    value: ICMSTot.vICMSUFDest[0],
                    valorFormatado: ICMSTot.vICMSUFDest[0],
                  };
                }
              }

              // vICMSUFRemet
              if (ICMSTot.vICMSUFRemet) {
                if (ICMSTot.vICMSUFRemet.length > 0) {
                  ICMSTotFormatado.vICMSUFRemet = {
                    descricao:
                      'Valor total do ICMS Interestadual para a UF do remetente',
                    value: ICMSTot.vICMSUFRemet[0],
                    valorFormatado: ICMSTot.vICMSUFRemet[0],
                  };
                }
              }

              // vFCP
              if (ICMSTot.vFCP) {
                if (ICMSTot.vFCP.length > 0) {
                  ICMSTotFormatado.vFCP = {
                    descricao:
                      'Valor Total do FCP (Fundo de Combate à Pobreza)',
                    value: ICMSTot.vFCP[0],
                    valorFormatado: ICMSTot.vFCP[0],
                  };
                }
              }

              // vBCST
              if (ICMSTot.vBCST) {
                if (ICMSTot.vBCST.length > 0) {
                  ICMSTotFormatado.vBCST = {
                    descricao: 'Base de Cálculo do ICMS ST ',
                    value: ICMSTot.vBCST[0],
                    valorFormatado: ICMSTot.vBCST[0],
                  };
                }
              }

              // vST
              if (ICMSTot.vST) {
                if (ICMSTot.vST.length > 0) {
                  totaisFormatados.vST = {
                    descricao: 'Valor Total do ICMS ST  ',
                    value: ICMSTot.vST[0],
                    valorFormatado: formatMoeda(Number(ICMSTot.vST[0])),
                  };
                  ICMSTotFormatado.vST = {
                    descricao: 'Valor Total do ICMS ST  ',
                    value: ICMSTot.vST[0],
                    valorFormatado: formatMoeda(Number(ICMSTot.vST[0])),
                  };
                }
              }

              // vFCPST
              if (ICMSTot.vFCPST) {
                if (ICMSTot.vFCPST.length > 0) {
                  ICMSTotFormatado.vFCPST = {
                    descricao:
                      'Valor Total do FCP (Fundo de Combate à Pobreza) retido por substituição tributária',
                    value: ICMSTot.vFCPST[0],
                    valorFormatado: ICMSTot.vFCPST[0],
                  };
                }
              }

              // vFCPSTRet
              if (ICMSTot.vFCPSTRet) {
                if (ICMSTot.vFCPSTRet.length > 0) {
                  ICMSTotFormatado.vFCPSTRet = {
                    descricao:
                      'Valor Total do FCP retido anteriormente por Substituição Tributária',
                    value: ICMSTot.vFCPSTRet[0],
                    valorFormatado: ICMSTot.vFCPSTRet[0],
                  };
                }
              }

              // vProd
              if (ICMSTot.vProd) {
                if (ICMSTot.vProd.length > 0) {
                  totaisFormatados.vProd = {
                    descricao: 'Valor Total dos produtos e serviços',
                    value: ICMSTot.vProd[0],
                    valorFormatado: formatMoeda(Number(ICMSTot.vProd[0])),
                  };
                  ICMSTotFormatado.vProd = {
                    descricao: 'Valor Total dos produtos e serviços',
                    value: ICMSTot.vProd[0],
                    valorFormatado: formatMoeda(Number(ICMSTot.vProd[0])),
                  };
                }
              }

              // vFrete
              if (ICMSTot.vFrete) {
                if (ICMSTot.vFrete.length > 0) {
                  totaisFormatados.vFrete = {
                    descricao: 'Valor Total do Frete',
                    value: ICMSTot.vFrete[0],
                    valorFormatado: formatMoeda(Number(ICMSTot.vFrete[0])),
                  };
                  ICMSTotFormatado.vFrete = {
                    descricao: 'Valor Total do Frete',
                    value: ICMSTot.vFrete[0],
                    valorFormatado: formatMoeda(Number(ICMSTot.vFrete[0])),
                  };
                }
              }

              // vSeg
              if (ICMSTot.vSeg) {
                if (ICMSTot.vSeg.length > 0) {
                  totaisFormatados.vSeg = {
                    descricao: 'Valor Total do Seguro',
                    value: ICMSTot.vSeg[0],
                    valorFormatado: formatMoeda(Number(ICMSTot.vSeg[0])),
                  };
                  ICMSTotFormatado.vSeg = {
                    descricao: 'Valor Total do Seguro',
                    value: ICMSTot.vSeg[0],
                    valorFormatado: formatMoeda(Number(ICMSTot.vSeg[0])),
                  };
                }
              }

              // vDesc
              if (ICMSTot.vDesc) {
                if (ICMSTot.vDesc.length > 0) {
                  totaisFormatados.vDesc = {
                    descricao: 'Valor Total do Desconto',
                    value: ICMSTot.vDesc[0],
                    valorFormatado: formatMoeda(Number(ICMSTot.vDesc[0])),
                  };
                  ICMSTotFormatado.vDesc = {
                    descricao: 'Valor Total do Desconto',
                    value: ICMSTot.vDesc[0],
                    valorFormatado: formatMoeda(Number(ICMSTot.vDesc[0])),
                  };
                }
              }

              // vII
              if (ICMSTot.vII) {
                if (ICMSTot.vII.length > 0) {
                  ICMSTotFormatado.vII = {
                    descricao: 'Valor Total do II',
                    value: ICMSTot.vII[0],
                    valorFormatado: ICMSTot.vII[0],
                  };
                }
              }

              // vIPI
              if (ICMSTot.vIPI) {
                if (ICMSTot.vIPI.length > 0) {
                  totaisFormatados.vIPI = {
                    descricao: 'Valor Total do IPI',
                    value: ICMSTot.vIPI[0],
                    valorFormatado: formatMoeda(Number(ICMSTot.vIPI[0])),
                  };
                  ICMSTotFormatado.vIPI = {
                    descricao: 'Valor Total do IPI',
                    value: ICMSTot.vIPI[0],
                    valorFormatado: formatMoeda(Number(ICMSTot.vIPI[0])),
                  };
                }
              }

              // vIPIDevol
              if (ICMSTot.vIPIDevol) {
                if (ICMSTot.vIPIDevol.length > 0) {
                  totaisFormatados.vIPIDevol = {
                    descricao: 'Valor Total do IPI devolvido',
                    value: ICMSTot.vIPIDevol[0],
                    valorFormatado: formatMoeda(Number(ICMSTot.vIPIDevol[0])),
                  };
                  ICMSTotFormatado.vIPIDevol = {
                    descricao: 'Valor Total do IPI devolvido',
                    value: ICMSTot.vIPIDevol[0],
                    valorFormatado: formatMoeda(Number(ICMSTot.vIPIDevol[0])),
                  };
                }
              }

              // vPIS
              if (ICMSTot.vPIS) {
                if (ICMSTot.vPIS.length > 0) {
                  ICMSTotFormatado.vPIS = {
                    descricao: 'Valor do PIS',
                    value: ICMSTot.vPIS[0],
                    valorFormatado: ICMSTot.vPIS[0],
                  };
                }
              }

              // vCOFINS
              if (ICMSTot.vCOFINS) {
                if (ICMSTot.vCOFINS.length > 0) {
                  ICMSTotFormatado.vCOFINS = {
                    descricao: 'Valor da COFINS',
                    value: ICMSTot.vCOFINS[0],
                    valorFormatado: ICMSTot.vCOFINS[0],
                  };
                }
              }

              // vOutro
              if (ICMSTot.vOutro) {
                if (ICMSTot.vOutro.length > 0) {
                  totaisFormatados.vOutro = {
                    descricao: 'Outras Despesas acessórias',
                    value: ICMSTot.vOutro[0],
                    valorFormatado: formatMoeda(Number(ICMSTot.vOutro[0])),
                  };
                  ICMSTotFormatado.vOutro = {
                    descricao: 'Outras Despesas acessórias',
                    value: ICMSTot.vOutro[0],
                    valorFormatado: formatMoeda(Number(ICMSTot.vOutro[0])),
                  };
                }
              }

              // vNF
              if (ICMSTot.vNF) {
                if (ICMSTot.vNF.length > 0) {
                  totaisFormatados.vNF = {
                    descricao: 'Valor Total da NF-e',
                    value: ICMSTot.vNF[0],
                    valorFormatado: formatMoeda(Number(ICMSTot.vNF[0])),
                  };
                  ICMSTotFormatado.vNF = {
                    descricao: 'Valor Total da NF-e',
                    value: ICMSTot.vNF[0],
                    valorFormatado: formatMoeda(Number(ICMSTot.vNF[0])),
                  };
                }
              }

              // vTotTrib
              if (ICMSTot.vTotTrib) {
                if (ICMSTot.vTotTrib.length > 0) {
                  ICMSTotFormatado.vTotTrib = {
                    descricao:
                      'Valor aproximado total de tributos federais, estaduais e municipais.',
                    value: ICMSTot.vTotTrib[0],
                    valorFormatado: ICMSTot.vTotTrib[0],
                  };
                }
              }

              dadosFormatados.ICMSTot = {
                descricao: 'Grupo Totais referentes ao ICMS ',
                value: ICMSTotFormatado,
                valorFormatado: ICMSTotFormatado,
              };
            }
          }

          // ISSQNtot
          if (total.ISSQNtot) {
            if (total.ISSQNtot.length > 0) {
              const ISSQNtot = total.ISSQNtot[0];
              const ISSQNtotFormatado = {};

              // vServ
              if (ISSQNtot.vServ) {
                if (ISSQNtot.vServ.length > 0) {
                  ISSQNtotFormatado.vServ = {
                    descricao:
                      'Valor total dos Serviços sob não- incidência ou não tributados pelo ICMS',
                    value: ISSQNtot.vServ[0],
                    valorFormatado: ISSQNtot.vServ[0],
                  };
                }
              }

              // vServ
              if (ISSQNtot.vBC) {
                if (ISSQNtot.vBC.length > 0) {
                  ISSQNtotFormatado.vBC = {
                    descricao: 'Valor total Base de Cálculo do ISS',
                    value: ISSQNtot.vBC[0],
                    valorFormatado: ISSQNtot.vBC[0],
                  };
                }
              }

              // vISS
              if (ISSQNtot.vISS) {
                if (ISSQNtot.vISS.length > 0) {
                  ISSQNtotFormatado.vISS = {
                    descricao: 'Valor total do ISS',
                    value: ISSQNtot.vISS[0],
                    valorFormatado: ISSQNtot.vISS[0],
                  };
                }
              }

              // vPIS
              if (ISSQNtot.vPIS) {
                if (ISSQNtot.vPIS.length > 0) {
                  ISSQNtotFormatado.vPIS = {
                    descricao: 'Valor total do PIS sobre serviços',
                    value: ISSQNtot.vPIS[0],
                    valorFormatado: ISSQNtot.vPIS[0],
                  };
                }
              }

              // vCOFINS
              if (ISSQNtot.vCOFINS) {
                if (ISSQNtot.vCOFINS.length > 0) {
                  ISSQNtotFormatado.vCOFINS = {
                    descricao: 'Valor total da COFINS sobre serviços',
                    value: ISSQNtot.vCOFINS[0],
                    valorFormatado: ISSQNtot.vCOFINS[0],
                  };
                }
              }

              // dCompet
              if (ISSQNtot.dCompet) {
                if (ISSQNtot.dCompet.length > 0) {
                  ISSQNtotFormatado.dCompet = {
                    descricao: 'Data da prestação do serviço',
                    value: ISSQNtot.dCompet[0],
                    valorFormatado: ISSQNtot.dCompet[0],
                  };
                }
              }

              // vDeducao
              if (ISSQNtot.vDeducao) {
                if (ISSQNtot.vDeducao.length > 0) {
                  ISSQNtotFormatado.vDeducao = {
                    descricao:
                      'Valor total dedução para redução da Base de Cálculo',
                    value: ISSQNtot.vDeducao[0],
                    valorFormatado: ISSQNtot.vDeducao[0],
                  };
                }
              }

              // vOutro
              if (ISSQNtot.vOutro) {
                if (ISSQNtot.vOutro.length > 0) {
                  ISSQNtotFormatado.vOutro = {
                    descricao: 'Valor total outras retenções',
                    value: ISSQNtot.vOutro[0],
                    valorFormatado: ISSQNtot.vOutro[0],
                  };
                }
              }

              // vDescIncond
              if (ISSQNtot.vDescIncond) {
                if (ISSQNtot.vDescIncond.length > 0) {
                  ISSQNtotFormatado.vDescIncond = {
                    descricao: 'Valor total desconto incondicionado',
                    value: ISSQNtot.vDescIncond[0],
                    valorFormatado: ISSQNtot.vDescIncond[0],
                  };
                }
              }

              // vDescCond
              if (ISSQNtot.vDescCond) {
                if (ISSQNtot.vDescCond.length > 0) {
                  ISSQNtotFormatado.vDescCond = {
                    descricao: 'Valor total desconto condicionado',
                    value: ISSQNtot.vDescCond[0],
                    valorFormatado: ISSQNtot.vDescCond[0],
                  };
                }
              }

              // vISSRet
              if (ISSQNtot.vISSRet) {
                if (ISSQNtot.vISSRet.length > 0) {
                  ISSQNtotFormatado.vISSRet = {
                    descricao: 'Valor total retenção ISS',
                    value: ISSQNtot.vISSRet[0],
                    valorFormatado: ISSQNtot.vISSRet[0],
                  };
                }
              }

              // cRegTrib
              if (ISSQNtot.cRegTrib) {
                if (ISSQNtot.cRegTrib.length > 0) {
                  ISSQNtotFormatado.cRegTrib = {
                    descricao: 'Código do Regime Especial de Tributação',
                    value: ISSQNtot.cRegTrib[0],
                    valorFormatado: ISSQNtot.cRegTrib[0],
                  };
                }
              }

              dadosFormatados.ISSQNtot = {
                descricao: 'Grupo Totais referentes ao ISSQN',
                value: ISSQNtotFormatado,
                valorFormatado: ISSQNtotFormatado,
              };
            }
          }

          // retTrib
          if (total.retTrib) {
            if (total.retTrib.length > 0) {
              const retTrib = total.retTrib[0];
              const retTribFormatado = {};

              // vRetPIS
              if (retTrib.vRetPIS) {
                if (retTrib.vRetPIS.length > 0) {
                  retTribFormatado.vRetPIS = {
                    descricao: 'Valor Retido de PIS ',
                    value: retTrib.vRetPIS[0],
                    valorFormatado: retTrib.vRetPIS[0],
                  };
                }
              }

              // vRetCOFINS
              if (retTrib.vRetCOFINS) {
                if (retTrib.vRetCOFINS.length > 0) {
                  retTribFormatado.vRetCOFINS = {
                    descricao: 'Valor Retido de COFINS',
                    value: retTrib.vRetCOFINS[0],
                    valorFormatado: retTrib.vRetCOFINS[0],
                  };
                }
              }

              // vRetCSLL
              if (retTrib.vRetCSLL) {
                if (retTrib.vRetCSLL.length > 0) {
                  retTribFormatado.vRetCSLL = {
                    descricao: 'Valor Retido de CSLL',
                    value: retTrib.vRetCSLL[0],
                    valorFormatado: retTrib.vRetCSLL[0],
                  };
                }
              }

              // vBCIRRF
              if (retTrib.vBCIRRF) {
                if (retTrib.vBCIRRF.length > 0) {
                  retTribFormatado.vBCIRRF = {
                    descricao: 'Base de Cálculo do IRRF',
                    value: retTrib.vBCIRRF[0],
                    valorFormatado: retTrib.vBCIRRF[0],
                  };
                }
              }

              // vBCIRRF
              if (retTrib.vBCIRRF) {
                if (retTrib.vBCIRRF.length > 0) {
                  retTribFormatado.vBCIRRF = {
                    descricao: 'Base de Cálculo do IRRF',
                    value: retTrib.vBCIRRF[0],
                    valorFormatado: retTrib.vBCIRRF[0],
                  };
                }
              }

              // vIRRF
              if (retTrib.vIRRF) {
                if (retTrib.vIRRF.length > 0) {
                  retTribFormatado.vIRRF = {
                    descricao: 'Valor Retido do IRRF',
                    value: retTrib.vIRRF[0],
                    valorFormatado: retTrib.vIRRF[0],
                  };
                }
              }

              // vBCRetPrev
              if (retTrib.vBCRetPrev) {
                if (retTrib.vBCRetPrev.length > 0) {
                  retTribFormatado.vBCRetPrev = {
                    descricao:
                      'Base de Cálculo da Retenção da Previdência Social',
                    value: retTrib.vBCRetPrev[0],
                    valorFormatado: retTrib.vBCRetPrev[0],
                  };
                }
              }

              // vRetPrev
              if (retTrib.vRetPrev) {
                if (retTrib.vRetPrev.length > 0) {
                  retTribFormatado.vRetPrev = {
                    descricao: 'Valor da Retenção da Previdência Social',
                    value: retTrib.vRetPrev[0],
                    valorFormatado: retTrib.vRetPrev[0],
                  };
                }
              }

              dadosFormatados.retTrib = {
                descricao: 'Grupo Retenções de Tributos',
                value: retTribFormatado,
                valorFormatado: retTribFormatado,
              };
            }
          }
        }
      }
      setTotaisNota(totaisFormatados);
      return dadosFormatados;
    }

    function getDadosTransp(infNFe) {
      const dadosFormatados = {};
      if (infNFe.transp) {
        if (infNFe.transp.length > 0) {
          const transp = infNFe.transp[0];

          // modFrete
          if (transp.modFrete) {
            if (transp.modFrete.length > 0) {
              dadosFormatados.modFrete = {
                descricao: 'Modalidade do frete ',
                value: transp.modFrete[0],
                valorFormatado: transp.modFrete[0],
              };
            }
          }

          // transporta
          if (transp.transporta) {
            if (transp.transporta.length > 0) {
              const transporta = transp.transporta[0];
              const transportaFormatado = {};

              // CNPJ
              if (transporta.CNPJ) {
                if (transporta.CNPJ.length > 0) {
                  transportaFormatado.CNPJ = {
                    descricao: 'CNPJ do Transportador',
                    value: transporta.CNPJ[0],
                    valorFormatado: transporta.CNPJ[0],
                  };
                }
              }

              // CPF
              if (transporta.CPF) {
                if (transporta.CPF.length > 0) {
                  transportaFormatado.CPF = {
                    descricao: 'CPF do Transportador',
                    value: transporta.CPF[0],
                    valorFormatado: transporta.CPF[0],
                  };
                }
              }

              // xNome
              if (transporta.xNome) {
                if (transporta.xNome.length > 0) {
                  transportaFormatado.xNome = {
                    descricao: 'Razão Social ou nome',
                    value: transporta.xNome[0],
                    valorFormatado: transporta.xNome[0],
                  };
                }
              }

              // IE
              if (transporta.IE) {
                if (transporta.IE.length > 0) {
                  transportaFormatado.IE = {
                    descricao: 'Inscrição Estadual do Transportador',
                    value: transporta.IE[0],
                    valorFormatado: transporta.IE[0],
                  };
                }
              }

              // xEnder
              if (transporta.xEnder) {
                if (transporta.xEnder.length > 0) {
                  transportaFormatado.xEnder = {
                    descricao: 'Endereço Completo',
                    value: transporta.xEnder[0],
                    valorFormatado: transporta.xEnder[0],
                  };
                }
              }

              // xMun
              if (transporta.xMun) {
                if (transporta.xMun.length > 0) {
                  transportaFormatado.xMun = {
                    descricao: 'Nome do município',
                    value: transporta.xMun[0],
                    valorFormatado: transporta.xMun[0],
                  };
                }
              }

              // UF
              if (transporta.UF) {
                if (transporta.UF.length > 0) {
                  transportaFormatado.UF = {
                    descricao: 'Sigla da UF',
                    value: transporta.UF[0],
                    valorFormatado: transporta.UF[0],
                  };
                }
              }

              dadosFormatados.transporta = {
                descricao: 'Grupo Transportador',
                value: transportaFormatado,
                valorFormatado: transportaFormatado,
              };
            }
          }

          // retTransp
          if (transp.retTransp) {
            if (transp.retTransp.length > 0) {
              const retTransp = transp.retTransp[0];
              const retTranspFormatado = {};

              // vServ
              if (retTransp.vServ) {
                if (retTransp.vServ.length > 0) {
                  retTranspFormatado.vServ = {
                    descricao: 'Valor do Serviço',
                    value: retTransp.vServ[0],
                    valorFormatado: retTransp.vServ[0],
                  };
                }
              }

              // vBCRet
              if (retTransp.vBCRet) {
                if (retTransp.vBCRet.length > 0) {
                  retTranspFormatado.vBCRet = {
                    descricao: 'BC da Retenção do ICMS',
                    value: retTransp.vBCRet[0],
                    valorFormatado: retTransp.vBCRet[0],
                  };
                }
              }

              // pICMSRet
              if (retTransp.pICMSRet) {
                if (retTransp.pICMSRet.length > 0) {
                  retTranspFormatado.pICMSRet = {
                    descricao: 'Alíquota da Retenção',
                    value: retTransp.pICMSRet[0],
                    valorFormatado: retTransp.pICMSRet[0],
                  };
                }
              }

              // vICMSRet
              if (retTransp.vICMSRet) {
                if (retTransp.vICMSRet.length > 0) {
                  retTranspFormatado.vICMSRet = {
                    descricao: 'Valor do ICMS Retido',
                    value: retTransp.vICMSRet[0],
                    valorFormatado: retTransp.vICMSRet[0],
                  };
                }
              }

              // CFOP
              if (retTransp.CFOP) {
                if (retTransp.CFOP.length > 0) {
                  retTranspFormatado.CFOP = {
                    descricao: 'CFOP',
                    value: retTransp.CFOP[0],
                    valorFormatado: retTransp.CFOP[0],
                  };
                }
              }

              // cMunFG
              if (retTransp.cMunFG) {
                if (retTransp.cMunFG.length > 0) {
                  retTranspFormatado.cMunFG = {
                    descricao:
                      'Código do município de ocorrência do fato gerador do ICMS do transporte',
                    value: retTransp.cMunFG[0],
                    valorFormatado: retTransp.cMunFG[0],
                  };
                }
              }

              dadosFormatados.retTransp = {
                descricao: 'Grupo Retenção ICMS transporte',
                value: retTranspFormatado,
                valorFormatado: retTranspFormatado,
              };
            }
          }
        }
      }

      return dadosFormatados;
    }

    function getDadosCobr(infNFe) {
      const dadosFormatados = {};
      const dadosFatura = {};
      const dadosParcelas = [];
      if (infNFe.cobr) {
        if (infNFe.cobr.length > 0) {
          const cobr = infNFe.cobr[0];

          // fat
          if (cobr.fat) {
            if (cobr.fat.length > 0) {
              const fat = cobr.fat[0];
              const fatFormatado = {};

              // nFat
              if (fat.nFat) {
                if (fat.nFat.length > 0) {
                  dadosFatura.nFat = {
                    descricao: 'Número da Fatura',
                    value: fat.nFat[0],
                    valorFormatado: fat.nFat[0],
                  };
                  fatFormatado.nFat = {
                    descricao: 'Número da Fatura',
                    value: fat.nFat[0],
                    valorFormatado: fat.nFat[0],
                  };
                }
              }

              // vOrig
              if (fat.vOrig) {
                if (fat.vOrig.length > 0) {
                  dadosFatura.vOrig = {
                    descricao: 'Valor Original da Fatura',
                    value: fat.vOrig[0],
                    valorFormatado: formatMoeda(Number(fat.vOrig[0])),
                  };
                  fatFormatado.vOrig = {
                    descricao: 'Valor Original da Fatura',
                    value: fat.vOrig[0],
                    valorFormatado: formatMoeda(Number(fat.vOrig[0])),
                  };
                }
              }

              // vDesc
              if (fat.vDesc) {
                if (fat.vDesc.length > 0) {
                  dadosFatura.vDesc = {
                    descricao: 'Valor do desconto',
                    value: fat.vDesc[0],
                    valorFormatado: formatMoeda(Number(fat.vDesc[0])),
                  };
                  fatFormatado.vDesc = {
                    descricao: 'Valor do desconto',
                    value: fat.vDesc[0],
                    valorFormatado: formatMoeda(Number(fat.vDesc[0])),
                  };
                }
              }

              // vLiq
              if (fat.vLiq) {
                if (fat.vLiq.length > 0) {
                  dadosFatura.vLiq = {
                    descricao: 'Valor Líquido da Fatura',
                    value: fat.vLiq[0],
                    valorFormatado: formatMoeda(Number(fat.vLiq[0])),
                  };
                  fatFormatado.vLiq = {
                    descricao: 'Valor Líquido da Fatura',
                    value: fat.vLiq[0],
                    valorFormatado: formatMoeda(Number(fat.vLiq[0])),
                  };
                }
              }

              dadosFormatados.fat = {
                descricao: 'Grupo Fatura',
                value: fatFormatado,
                valorFormatado: fatFormatado,
              };
            }
          }

          // dup
          if (cobr.dup) {
            const dups = cobr.dup;
            const dupsFormatado = [];

            for (let i = 0; i < dups.length; i++) {
              const dup = dups[i];
              const dupFormatado = [];
              // nDup
              if (dup.nDup) {
                if (dup.nDup.length > 0) {
                  dupFormatado.nDup = {
                    descricao: 'Número da Parcela',
                    value: dup.nDup[0],
                    valorFormatado: dup.nDup[0],
                  };
                }
              }

              // dVenc
              if (dup.dVenc) {
                if (dup.dVenc.length > 0) {
                  dupFormatado.dVenc = {
                    descricao: 'Data de vencimento',
                    value: new Date(dup.dVenc[0]),
                    valorFormatado: formatDateDDMMYYYY(new Date(dup.dVenc[0])),
                  };
                }
              }

              // vDup
              if (dup.vDup) {
                if (dup.vDup.length > 0) {
                  dupFormatado.vDup = {
                    descricao: 'Valor da Parcela',
                    value: dup.vDup[0],
                    valorFormatado: formatMoeda(Number(dup.vDup[0])),
                  };
                }
              }

              dadosParcelas.push(dupFormatado);
              dupsFormatado.push(dupFormatado);
            }

            dadosFormatados.dup = {
              descricao: 'Grupo Parcelas',
              value: dupsFormatado,
              valorFormatado: dupsFormatado,
            };
          }
        }
      }
      setFatura(dadosFatura);
      setParcelas(dadosParcelas);
      return dadosFormatados;
    }

    function getDadosPag(infNFe) {
      const dadosFormatados = {};
      if (infNFe.pag) {
        if (infNFe.pag.length > 0) {
          const pag = infNFe.pag[0];

          // detPag
          if (pag.detPag) {
            if (pag.detPag.length > 0) {
              const detPag = pag.detPag[0];
              const detPagFormatado = {};

              // indPag
              if (detPag.indPag) {
                if (detPag.indPag.length > 0) {
                  detPagFormatado.indPag = {
                    descricao: 'Indicador da Forma de Pagamento',
                    value: detPag.indPag[0],
                    valorFormatado: detPag.indPag[0],
                  };
                }
              }

              // tPag
              if (detPag.tPag) {
                if (detPag.tPag.length > 0) {
                  detPagFormatado.tPag = {
                    descricao: 'Meio de pagamento',
                    value: detPag.tPag[0],
                    valorFormatado: detPag.tPag[0],
                  };
                }
              }

              // vPag
              if (detPag.vPag) {
                if (detPag.vPag.length > 0) {
                  detPagFormatado.vPag = {
                    descricao: 'Valor do Pagamento',
                    value: detPag.vPag[0],
                    valorFormatado: detPag.vPag[0],
                  };
                }
              }

              dadosFormatados.detPag = {
                descricao: 'Grupo Detalhamento do Pagamento',
                value: detPagFormatado,
                valorFormatado: detPagFormatado,
              };
            }
          }

          // card
          if (pag.card) {
            if (pag.card.length > 0) {
              const card = pag.card[0];
              const cardFormatado = {};

              // tpIntegra
              if (card.tpIntegra) {
                if (card.tpIntegra.length > 0) {
                  cardFormatado.tpIntegra = {
                    descricao: 'Tipo de Integração para pagamento',
                    value: card.tpIntegra[0],
                    valorFormatado: card.tpIntegra[0],
                  };
                }
              }

              // CNPJ
              if (card.CNPJ) {
                if (card.CNPJ.length > 0) {
                  cardFormatado.CNPJ = {
                    descricao: 'CNPJ da instituição de pagamento',
                    value: card.CNPJ[0],
                    valorFormatado: card.CNPJ[0],
                  };
                }
              }

              // tBand
              if (card.tBand) {
                if (card.tBand.length > 0) {
                  cardFormatado.tBand = {
                    descricao:
                      'Bandeira da operadora de cartão de crédito e/ou débito',
                    value: card.tBand[0],
                    valorFormatado: card.tBand[0],
                  };
                }
              }

              // cAut
              if (card.cAut) {
                if (card.cAut.length > 0) {
                  cardFormatado.cAut = {
                    descricao:
                      'Número de autorização da operação cartão de crédito e/ou débito',
                    value: card.cAut[0],
                    valorFormatado: card.cAut[0],
                  };
                }
              }

              // vTroco
              if (card.vTroco) {
                if (card.vTroco.length > 0) {
                  cardFormatado.vTroco = {
                    descricao: 'Valor do troco',
                    value: card.vTroco[0],
                    valorFormatado: card.vTroco[0],
                  };
                }
              }

              dadosFormatados.card = {
                descricao: 'Grupo de Cartões',
                value: cardFormatado,
                valorFormatado: cardFormatado,
              };
            }
          }
        }
      }

      return dadosFormatados;
    }

    function getDadosInfIntermed(infNFe) {
      const dadosFormatados = {};
      if (infNFe.infIntermed) {
        if (infNFe.infIntermed.length > 0) {
          const infIntermed = infNFe.infIntermed[0];

          // CNPJ
          if (infIntermed.CNPJ) {
            if (infIntermed.CNPJ.length > 0) {
              dadosFormatados.CNPJ = {
                descricao:
                  'CNPJ do Intermediador da Transação (agenciador, plataforma de delivery, marketplace e similar) de serviços e de negócios.',
                value: infIntermed.CNPJ[0],
                valorFormatado: infIntermed.CNPJ[0],
              };
            }
          }

          // idCadIntTran
          if (infIntermed.idCadIntTran) {
            if (infIntermed.idCadIntTran.length > 0) {
              dadosFormatados.idCadIntTran = {
                descricao: 'Identificador cadastrado no intermediador',
                value: infIntermed.idCadIntTran[0],
                valorFormatado: infIntermed.idCadIntTran[0],
              };
            }
          }
        }
      }
      return dadosFormatados;
    }

    function getDadosInfAdc(infNFe) {
      const dadosFormatados = {};
      if (infNFe.infAdic) {
        if (infNFe.infAdic.length > 0) {
          const infAdic = infNFe.infAdic[0];

          // infAdic
          if (infAdic.infAdFisco) {
            if (infAdic.infAdFisco.length > 0) {
              dadosFormatados.infAdFisco = {
                descricao: 'Informações Adicionais de Interesse do Fisco',
                value: infAdic.infAdFisco[0],
                valorFormatado: infAdic.infAdFisco[0],
              };
            }
          }

          // infCpl
          if (infAdic.infCpl) {
            if (infAdic.infCpl.length > 0) {
              dadosFormatados.infCpl = {
                descricao:
                  'Informações Complementares de interesse do Contribuinte',
                value: infAdic.infCpl[0],
                valorFormatado: infAdic.infCpl[0],
              };
            }
          }

          // obsCont
          if (infAdic.obsCont) {
            if (infAdic.obsCont.length > 0) {
              const obsCont = infAdic.obsCont[0];
              const obsContFormatado = {};

              // xCampo
              if (obsCont.xCampo) {
                if (obsCont.xCampo.length > 0) {
                  obsContFormatado.xCampo = {
                    descricao: 'Identificação do campo',
                    value: obsCont.xCampo[0],
                    valorFormatado: obsCont.xCampo[0],
                  };
                }
              }

              // xTexto
              if (obsCont.xTexto) {
                if (obsCont.xTexto.length > 0) {
                  obsContFormatado.xTexto = {
                    descricao: 'Conteúdo do campo',
                    value: obsCont.xTexto[0],
                    valorFormatado: obsCont.xTexto[0],
                  };
                }
              }

              dadosFormatados.obsCont = {
                descricao: 'Grupo Campo de uso livre do contribuinte',
                value: obsContFormatado,
                valorFormatado: obsContFormatado,
              };
            }
          }

          // obsFisco
          if (infAdic.obsFisco) {
            if (infAdic.obsFisco.length > 0) {
              const obsFisco = infAdic.obsFisco[0];
              const obsFiscoFormatado = {};

              // xCampo
              if (obsFisco.xCampo) {
                if (obsFisco.xCampo.length > 0) {
                  obsFiscoFormatado.xCampo = {
                    descricao: 'Grupo Campo de uso livre do Fisco',
                    value: obsFisco.xCampo[0],
                    valorFormatado: obsFisco.xCampo[0],
                  };
                }
              }

              // xTexto
              if (obsFisco.xTexto) {
                if (obsFisco.xTexto.length > 0) {
                  obsFiscoFormatado.xTexto = {
                    descricao: 'Conteúdo do campo',
                    value: obsFisco.xTexto[0],
                    valorFormatado: obsFisco.xTexto[0],
                  };
                }
              }

              dadosFormatados.obsFisco = {
                descricao: 'Grupo Campo de uso livre do Fisco',
                value: obsFiscoFormatado,
                valorFormatado: obsFiscoFormatado,
              };
            }
          }

          // procRef
          if (infAdic.procRef) {
            if (infAdic.procRef.length > 0) {
              const procRef = infAdic.procRef[0];
              const procRefFormatado = {};

              // nProc
              if (procRef.nProc) {
                if (procRef.nProc.length > 0) {
                  procRefFormatado.nProc = {
                    descricao: 'Identificador do processo ou ato concessório',
                    value: procRef.nProc[0],
                    valorFormatado: procRef.nProc[0],
                  };
                }
              }

              // indProc
              if (procRef.indProc) {
                if (procRef.indProc.length > 0) {
                  procRefFormatado.indProc = {
                    descricao: 'Indicador da origem do processo',
                    value: procRef.indProc[0],
                    valorFormatado: procRef.indProc[0],
                  };
                }
              }

              dadosFormatados.procRef = {
                descricao: 'Grupo Processo referenciado',
                value: procRefFormatado,
                valorFormatado: procRefFormatado,
              };
            }
          }
        }
      }

      return dadosFormatados;
    }

    function getDadosExporta(infNFe) {
      const dadosFormatados = {};
      if (infNFe.exporta) {
        if (infNFe.exporta.length > 0) {
          const exporta = infNFe.exporta[0];

          // UFSaidaPais
          if (exporta.UFSaidaPais) {
            if (exporta.UFSaidaPais.length > 0) {
              dadosFormatados.UFSaidaPais = {
                descricao:
                  'Sigla da UF de Embarque ou de transposição de fronteira',
                value: exporta.UFSaidaPais[0],
                valorFormatado: exporta.UFSaidaPais[0],
              };
            }
          }

          // xLocExporta
          if (exporta.xLocExporta) {
            if (exporta.xLocExporta.length > 0) {
              dadosFormatados.xLocExporta = {
                descricao:
                  'Descrição do Local de Embarque ou de transposição de fronteira',
                value: exporta.xLocExporta[0],
                valorFormatado: exporta.xLocExporta[0],
              };
            }
          }

          // xLocDespacho
          if (exporta.xLocDespacho) {
            if (exporta.xLocDespacho.length > 0) {
              dadosFormatados.xLocDespacho = {
                descricao: 'Descrição do local de despacho',
                value: exporta.xLocDespacho[0],
                valorFormatado: exporta.xLocDespacho[0],
              };
            }
          }
        }
      }
      return dadosFormatados;
    }

    function getDadosCompra(infNFe) {
      const dadosFormatados = {};
      if (infNFe.compra) {
        if (infNFe.compra.length > 0) {
          const compra = infNFe.compra[0];

          // xNEmp
          if (compra.xNEmp) {
            if (compra.xNEmp.length > 0) {
              dadosFormatados.xNEmp = {
                descricao: 'Nota de Empenho',
                value: compra.xNEmp[0],
                valorFormatado: compra.xNEmp[0],
              };
            }
          }

          // xPed
          if (compra.xPed) {
            if (compra.xPed.length > 0) {
              dadosFormatados.xPed = {
                descricao: 'Pedido',
                value: compra.xPed[0],
                valorFormatado: compra.xPed[0],
              };
            }
          }

          // xCont
          if (compra.xCont) {
            if (compra.xCont.length > 0) {
              dadosFormatados.xCont = {
                descricao: 'Contrato',
                value: compra.xCont[0],
                valorFormatado: compra.xCont[0],
              };
            }
          }
        }
      }
      return dadosFormatados;
    }

    function getDadosCana(infNFe) {
      const dadosFormatados = {};
      if (infNFe.cana) {
        if (infNFe.cana.length > 0) {
          const cana = infNFe.cana[0];

          // safra
          if (cana.safra) {
            if (cana.safra.length > 0) {
              dadosFormatados.safra = {
                descricao: 'Identificação da safra',
                value: cana.safra[0],
                valorFormatado: cana.safra[0],
              };
            }
          }

          // ref
          if (cana.ref) {
            if (cana.ref.length > 0) {
              dadosFormatados.ref = {
                descricao: 'Mês e ano de referência',
                value: cana.ref[0],
                valorFormatado: cana.ref[0],
              };
            }
          }

          // forDia
          if (cana.forDia) {
            if (cana.forDia.length > 0) {
              const forDia = cana.forDia[0];
              const forDiaFormatado = {};

              // dia
              if (forDia.dia) {
                if (forDia.dia.length > 0) {
                  forDiaFormatado.dia = {
                    descricao: 'Dia',
                    value: forDia.dia[0],
                    valorFormatado: forDia.dia[0],
                  };
                }
              }

              // qtde
              if (forDia.qtde) {
                if (forDia.qtde.length > 0) {
                  forDiaFormatado.qtde = {
                    descricao: 'Quantidade',
                    value: forDia.qtde[0],
                    valorFormatado: forDia.qtde[0],
                  };
                }
              }

              // qTotMes
              if (forDia.qTotMes) {
                if (forDia.qTotMes.length > 0) {
                  forDiaFormatado.qTotMes = {
                    descricao: 'Quantidade Total do Mês',
                    value: forDia.qTotMes[0],
                    valorFormatado: forDia.qTotMes[0],
                  };
                }
              }

              // qTotAnt
              if (forDia.qTotAnt) {
                if (forDia.qTotAnt.length > 0) {
                  forDiaFormatado.qTotAnt = {
                    descricao: 'Quantidade Total Anterior',
                    value: forDia.qTotAnt[0],
                    valorFormatado: forDia.qTotAnt[0],
                  };
                }
              }

              // qTotGer
              if (forDia.qTotGer) {
                if (forDia.qTotGer.length > 0) {
                  forDiaFormatado.qTotGer = {
                    descricao: 'Quantidade Total Geral',
                    value: forDia.qTotGer[0],
                    valorFormatado: forDia.qTotGer[0],
                  };
                }
              }

              dadosFormatados.forDia = {
                descricao: 'Grupo Fornecimento diário de cana ',
                value: forDiaFormatado,
                valorFormatado: forDiaFormatado,
              };
            }
          }

          // deduc
          if (cana.deduc) {
            if (cana.deduc.length > 0) {
              const deduc = deduc.deduc[0];
              const deducFormatado = {};

              // xDed
              if (deduc.xDed) {
                if (deduc.xDed.length > 0) {
                  deducFormatado.xDed = {
                    descricao: 'Descrição da Dedução',
                    value: deduc.xDed[0],
                    valorFormatado: deduc.xDed[0],
                  };
                }
              }

              // vDed
              if (deduc.vDed) {
                if (deduc.vDed.length > 0) {
                  deducFormatado.vDed = {
                    descricao: 'Valor da Dedução',
                    value: deduc.vDed[0],
                    valorFormatado: deduc.vDed[0],
                  };
                }
              }

              // vFor
              if (deduc.vFor) {
                if (deduc.vFor.length > 0) {
                  deducFormatado.vFor = {
                    descricao: 'Valor dos Fornecimentos',
                    value: deduc.vFor[0],
                    valorFormatado: deduc.vFor[0],
                  };
                }
              }

              // vTotDed
              if (deduc.vTotDed) {
                if (deduc.vTotDed.length > 0) {
                  deducFormatado.vTotDed = {
                    descricao: 'Valor Total da Dedução',
                    value: deduc.vTotDed[0],
                    valorFormatado: deduc.vTotDed[0],
                  };
                }
              }

              // vLiqFor
              if (deduc.vLiqFor) {
                if (deduc.vLiqFor.length > 0) {
                  deducFormatado.vLiqFor = {
                    descricao: 'Valor Líquido dos Fornecimentos',
                    value: deduc.vLiqFor[0],
                    valorFormatado: deduc.vLiqFor[0],
                  };
                }
              }

              dadosFormatados.deduc = {
                descricao: 'Grupo Deduções – Taxas e Contribuições',
                value: deducFormatado,
                valorFormatado: deducFormatado,
              };
            }
          }
        }
      }

      return dadosFormatados;
    }

    function getDadosInfRespTec(infNFe) {
      const dadosFormatados = {};
      if (infNFe.infRespTec) {
        if (infNFe.infRespTec.length > 0) {
          const infRespTec = infNFe.infRespTec[0];

          // CNPJ
          if (infRespTec.CNPJ) {
            if (infRespTec.CNPJ.length > 0) {
              dadosFormatados.CNPJ = {
                descricao:
                  'CNPJ da pessoa jurídica responsável pelo sistema utilizado na emissão do documento fiscal eletrônico',
                value: infRespTec.CNPJ[0],
                valorFormatado: infRespTec.CNPJ[0],
              };
            }
          }

          // xContato
          if (infRespTec.xContato) {
            if (infRespTec.xContato.length > 0) {
              dadosFormatados.xContato = {
                descricao: 'Nome da pessoa a ser contatada',
                value: infRespTec.xContato[0],
                valorFormatado: infRespTec.xContato[0],
              };
            }
          }

          // email
          if (infRespTec.email) {
            if (infRespTec.email.length > 0) {
              dadosFormatados.email = {
                descricao: 'E-mail da pessoa jurídica a ser contatada',
                value: infRespTec.email[0],
                valorFormatado: infRespTec.email[0],
              };
            }
          }

          // fone
          if (infRespTec.fone) {
            if (infRespTec.fone.length > 0) {
              dadosFormatados.fone = {
                descricao:
                  'CNPJ da pessoa jurídica responsável pelo sistema utilizado na emissão do documento fiscal eletrônico',
                value: infRespTec.fone[0],
                valorFormatado: infRespTec.fone[0],
              };
            }
          }

          // idCSRT
          if (infRespTec.idCSRT) {
            if (infRespTec.idCSRT.length > 0) {
              dadosFormatados.idCSRT = {
                descricao: 'Identificador do CSRT',
                value: infRespTec.idCSRT[0],
                valorFormatado: infRespTec.idCSRT[0],
              };
            }
          }

          // hashCSRT
          if (infRespTec.hashCSRT) {
            if (infRespTec.hashCSRT.length > 0) {
              dadosFormatados.hashCSRT = {
                descricao: 'Hash do CSRT',
                value: infRespTec.hashCSRT[0],
                valorFormatado: infRespTec.hashCSRT[0],
              };
            }
          }
        }
      }
      return dadosFormatados;
    }

    function getDadosInfNFeSupl(infNFe) {
      const dadosFormatados = {};
      if (infNFe.infNFeSupl) {
        if (infNFe.infNFeSupl.length > 0) {
          const infNFeSupl = infNFe.infNFeSupl[0];

          // qrCode
          if (infNFeSupl.qrCode) {
            if (infNFeSupl.qrCode.length > 0) {
              dadosFormatados.qrCode = {
                descricao: 'Texto com o QR-Code impresso no DANFE NFC-e',
                value: infNFeSupl.qrCode[0],
                valorFormatado: infNFeSupl.qrCode[0],
              };
            }
          }

          // urlChave
          if (infNFeSupl.urlChave) {
            if (infNFeSupl.urlChave.length > 0) {
              dadosFormatados.urlChave = {
                descricao:
                  'Texto com a URL de consulta por chave de acesso a ser impressa no DANFE NFC-e. ',
                value: infNFeSupl.urlChave[0],
                valorFormatado: infNFeSupl.urlChave[0],
              };
            }
          }
        }
      }

      return dadosFormatados;
    }

    function getDadosNota(dados) {
      const dadosFormatados = {};
      if (dados) {
        const nfeProc = dados.nfeProc;
        if (nfeProc) {
          const NFe = nfeProc.NFe;
          if (NFe) {
            if (NFe.length > 0) {
              const infNFe = NFe[0].infNFe;
              if (infNFe) {
                if (infNFe.length > 0) {
                  if (infNFe[0].versao) {
                    if (infNFe[0].versao.length > 0) {
                      dadosFormatados.versao = {
                        descricao: 'Versão do leiaute',
                        value: infNFe[0].versao[0],
                        valorFormatado: infNFe[0].versao[0],
                      };
                    }
                  }

                  if (infNFe[0].Id) {
                    if (infNFe[0].Id.length > 0) {
                      dadosFormatados.Id = {
                        descricao: 'Identificador da TAG a ser assinada ',
                        value: infNFe[0].Id[0],
                        valorFormatado: infNFe[0].Id[0],
                      };
                    }
                  }

                  if (infNFe[0].pk_nItem) {
                    if (infNFe[0].pk_nItem.length > 0) {
                      dadosFormatados.pk_nItem = {
                        descricao:
                          'Regra para que a numeração do item de detalhe da NF-e seja única.',
                        value: infNFe[0].pk_nItem[0],
                        valorFormatado: infNFe[0].pk_nItem[0],
                      };
                    }
                  }

                  dadosFormatados.ide = getDadosIde(infNFe[0]);
                  dadosFormatados.emit = getDadosEmit(infNFe[0]);
                  dadosFormatados.avulsa = getDadosAvulsa(infNFe[0]);
                  dadosFormatados.dest = getDadosDest(infNFe[0]);
                  dadosFormatados.retirada = getDadosRetirada(infNFe[0]);
                  dadosFormatados.entrega = getDadosEntrega(infNFe[0]);
                  dadosFormatados.autXML = getDadosAutXml(infNFe[0]);
                  dadosFormatados.det = getDadosDet(infNFe[0]);
                  dadosFormatados.total = getDadosTotal(infNFe[0]);
                  dadosFormatados.transp = getDadosTransp(infNFe[0]);
                  dadosFormatados.cobr = getDadosCobr(infNFe[0]);
                  dadosFormatados.pag = getDadosPag(infNFe[0]);
                  dadosFormatados.infIntermed = getDadosInfIntermed(infNFe[0]);
                  dadosFormatados.infAdic = getDadosInfAdc(infNFe[0]);
                  dadosFormatados.exporta = getDadosExporta(infNFe[0]);
                  dadosFormatados.compra = getDadosCompra(infNFe[0]);
                  dadosFormatados.cana = getDadosCana(infNFe[0]);
                  dadosFormatados.infRespTec = getDadosInfRespTec(infNFe[0]);
                  dadosFormatados.infNFeSupl = getDadosInfNFeSupl(infNFe[0]);
                }
              }
            }
          }
        }
      }
      setNota(dadosFormatados);
    }

    function handleSubmit() {
      setPesquisando(true);
      try {
        const file = refInputFile.current.files[0];

        const reader = new FileReader();

        reader.readAsText(file);

        reader.onloadend = (evt) => {
          const readerData = evt.target.result;

          const parser = new DOMParser();
          const xml = parser.parseFromString(readerData, 'text/xml');

          xml2js
            .parseStringPromise(
              new XMLSerializer().serializeToString(xml.documentElement),
              { mergeAttrs: true },
            )
            .then((result) => {
              try {
                getDadosNota(result);
                setPesquisando(false);
              } catch (e) {
                setPesquisando(false);
              }
            })
            .catch((err) => {
              setPesquisando(false);
            });
        };
      } catch (e) {
        setPesquisando(false);
      }
    }

    function handleLimpar() {
      setNota({});
      setDadosNota(null);
      setFornecedor(null);
      setItens([]);
      setFatura(null);
      setParcelas([]);
      setTotaisNota(null);

      setProdutosEncontrados([]);
      seFornecedorEncontrados(null);
    }

    async function handleBuscarAssociacoes() {
      if (itens.length > 0 && fornecedor) {
        const fornecedorBody = fornecedor.CNPJ ? fornecedor.CNPJ.value : '';
        const produtosBody = itens.map((item) => ({
          codigo: item.cEANTrib ? item.cEANTrib.value : '',
        }));

        const response = await api.post('/identifica-dados-xml-compra', {
          dadosProdutos: produtosBody,
          dadosFornecedor: {
            cnpj: fornecedorBody,
          },
        });

        const data = response.data;

        const { fornecedor: fornecedor2, produtos } = data;

        seFornecedorEncontrados(fornecedor2);
        setProdutosEncontrados(produtos);
      }
    }

    function getMapProdutos() {
      const prods = {};
      for (let i = 0; i < produtosEncontrados.length; i++) {
        const produto = produtosEncontrados[i];
        prods[produto.codigo] = produto;
      }
      return prods;
    }

    const mapaProdutos = getMapProdutos();

    const itensFormatados = itens.map((item) => ({
      ...item,
      correspondencia: mapaProdutos[item.cEANTrib ? item.cEANTrib.value : ''],
    }));

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          //fullWidth
          //maxWidth="md"
          fullScreen
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            Carregar Nota
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            <div
              style={{
                //height: '100px',
                display: 'flex',
                flexDirection: 'column',
                // justifyContent: 'center',
                //alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  margin: '10px 0',
                }}
              >
                <input
                  type="file"
                  ref={refInputFile}
                  disabled={pesquisando}
                  style={{ margin: '20px 0 10px' }}
                />
                <Button
                  onClick={handleSalvar}
                  variant="contained"
                  color="primary"
                >
                  Analisar
                </Button>
                <Button
                  onClick={handleLimpar}
                  variant="contained"
                  color="primary"
                >
                  Limpar
                </Button>
                <Button
                  onClick={handleBuscarAssociacoes}
                  variant="contained"
                  color="primary"
                >
                  Buscar Associações
                </Button>
              </div>
              {dadosNota ? (
                <div>
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{ margin: '20px 0 10px', textAlign: 'center' }}
                  >
                    Dados Gerais
                  </Typography>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Campo
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Valor
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Descrição
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dadosNota.nNF ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            Série
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosNota.serie.valorFormatado}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosNota.serie.descricao}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {dadosNota.nNF ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            Número NFE
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosNota.nNF.valorFormatado}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosNota.nNF.descricao}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {dadosNota.dhEmi ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            DATA
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosNota.dhEmi.valorFormatado}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosNota.dhEmi.descricao}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                    </TableBody>
                  </Table>
                </div>
              ) : null}
              {totaisNota ? (
                <div>
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{ margin: '20px 0 10px', textAlign: 'center' }}
                  >
                    Totais da Nota
                  </Typography>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Campo
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Valor
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Descrição
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {totaisNota.vST ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            ICMS ST
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totaisNota.vST.valorFormatado}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totaisNota.vST.descricao}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {totaisNota.vIPI ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            Valor Total do IPI
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totaisNota.vIPI.valorFormatado}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totaisNota.vIPI.descricao}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {totaisNota.vIPIDevol ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            Valor Total do IPI devolvido
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totaisNota.vIPIDevol.valorFormatado}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totaisNota.vIPIDevol.descricao}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {totaisNota.vProd ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            Valor Total
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totaisNota.vProd.valorFormatado}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totaisNota.vProd.descricao}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {totaisNota.vFrete ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            Frete
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totaisNota.vFrete.valorFormatado}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totaisNota.vFrete.descricao}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {totaisNota.vSeg ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            Seguro
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totaisNota.vSeg.valorFormatado}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totaisNota.vSeg.descricao}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {totaisNota.vDesc ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            Desconto
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totaisNota.vDesc.valorFormatado}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totaisNota.vDesc.descricao}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {totaisNota.vOutro ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            Outras Despesas acessórias
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totaisNota.vOutro.valorFormatado}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totaisNota.vOutro.descricao}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {totaisNota.vNF ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            Total da NF-e
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totaisNota.vNF.valorFormatado}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totaisNota.vNF.descricao}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                    </TableBody>
                  </Table>
                </div>
              ) : null}
              {fornecedor ? (
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      gap: '10px',
                      margin: '20px 0 10px',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="h4" style={{ textAlign: 'center' }}>
                      Fornecedor
                    </Typography>
                    <div>
                      {fornecedorEncontrado ? (
                        <Avatar className={classes.confirmado}>
                          <CheckIcon style={{ fontSize: 15 }} />
                        </Avatar>
                      ) : (
                        <Avatar className={classes.pendente}>
                          <PriorityHighIcon style={{ fontSize: 15 }} />
                        </Avatar>
                      )}
                    </div>
                  </div>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Campo
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Valor
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Descrição
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fornecedor.CNPJ ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            CNPJ
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {fornecedor.CNPJ.valorFormatado}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {fornecedor.CNPJ.descricao}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {fornecedor.CPF ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            CPF
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {fornecedor.CPF.valorFormatado}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {fornecedor.CPF.descricao}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {fornecedor.xNome ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            Razão Social
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {fornecedor.xNome.valorFormatado}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {fornecedor.xNome.descricao}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {fornecedor.xFant ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            Nome Fantasia
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {fornecedor.xFant.valorFormatado}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {fornecedor.xFant.descricao}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {fornecedor.IE ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            IE
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {fornecedor.IE.valorFormatado}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {fornecedor.IE.descricao}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                    </TableBody>
                  </Table>
                </div>
              ) : null}
              {fatura ? (
                <div>
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{ margin: '20px 0 10px', textAlign: 'center' }}
                  >
                    Cobrança
                  </Typography>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Campo
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Valor
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Descrição
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fatura.nFat ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            Número da Fatura
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {fatura.nFat.valorFormatado}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {fatura.nFat.descricao}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {fatura.vOrig ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            Valor Original
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {fatura.vOrig.valorFormatado}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {fatura.vOrig.descricao}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {fatura.vDesc ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            Valor do Desconto
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {fatura.vDesc.valorFormatado}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {fatura.vDesc.descricao}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {fatura.vLiq ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            Valor Líquido
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {fatura.vLiq.valorFormatado}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {fatura.vLiq.descricao}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                    </TableBody>
                  </Table>
                </div>
              ) : null}
              {parcelas.length > 0 ? (
                <div>
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{ margin: '20px 0 10px', textAlign: 'center' }}
                  >
                    Parcelas
                  </Typography>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Número da Parcela
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Data de vencimento
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Valor da Parcela
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {parcelas.map((item) => (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.nDup ? item.nDup.valorFormatado : ''}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.dVenc ? item.dVenc.valorFormatado : ''}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.vDup ? item.vDup.valorFormatado : ''}
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              ) : null}
              {itensFormatados.length > 0 ? (
                <div>
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{ margin: '20px 0 10px', textAlign: 'center' }}
                  >
                    Produtos
                  </Typography>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Ações
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Código do produto ou serviço
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Código GTIN
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Descrição do produto ou serviço
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Código NCM com 8 dígitos
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Unidade Comercial
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Quantidade Comercial
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Valor Unitário de Comercialização
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Valor Total Bruto dos Produtos ou Serviços
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          GTIN da unidade tributável
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Unidade Tributável
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Quantidade Tributável
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Valor Unitário de tributação
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Valor Total do Frete
                        </StyledTableCell>

                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Valor Total do Seguro
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Valor do Desconto
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Outras despesas acessórias
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          IPI
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          ICMS ST
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Validade
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {itensFormatados.map((item) => (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.correspondencia ? (
                              <Avatar className={classes.confirmado}>
                                <CheckIcon style={{ fontSize: 15 }} />
                              </Avatar>
                            ) : (
                              <Avatar className={classes.pendente}>
                                <PriorityHighIcon style={{ fontSize: 15 }} />
                              </Avatar>
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.cProd ? item.cProd.valorFormatado : ''}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.cEAN ? item.cEAN.valorFormatado : ''}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.xProd ? item.xProd.valorFormatado : ''}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.NCM ? item.NCM.valorFormatado : ''}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.uCom ? item.uCom.valorFormatado : ''}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.qCom ? item.qCom.valorFormatado : ''}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.vUnCom ? item.vUnCom.valorFormatado : ''}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.vProd ? item.vProd.valorFormatado : ''}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.cEANTrib ? item.cEANTrib.valorFormatado : ''}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.uTrib ? item.uTrib.valorFormatado : ''}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.qTrib ? item.qTrib.valorFormatado : ''}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.vUnTrib ? item.vUnTrib.valorFormatado : ''}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.vFrete ? item.vFrete.valorFormatado : ''}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.vSeg ? item.vSeg.valorFormatado : ''}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.vDesc ? item.vDesc.valorFormatado : ''}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.vOutro ? item.vOutro.valorFormatado : ''}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.vIPI ? item.vIPI.valorFormatado : ''}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.vICMSST ? item.vICMSST.valorFormatado : ''}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.dVal ? item.dVal.valorFormatado : ''}
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              ) : null}
              {produtosEncontrados.length > 0 ? (
                <div>
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{ margin: '20px 0 10px', textAlign: 'center' }}
                  >
                    Produtos Encontrados
                  </Typography>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Código GTIN
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Descrição do produto
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Tipo Unidade
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {produtosEncontrados.map((item) => (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.codigo}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.nome}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.unidade.nome}
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              ) : null}
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
