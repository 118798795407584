import { useRef, useState, useEffect } from 'react';

import { useSnackbar } from 'notistack';

import {
  getListAllAPI,
  api,
  imprimirNotaDevolucaoVenda,
} from '../../../../services';
import {
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  getPesoCaixa,
  changeCaixariaLocal,
  getUnidadesItemCaixa,
  getUnidadesCaixa,
  POR_PESO_FIXO,
  ZERO_APROXIMADO,
  trunc10,
} from '../../../../utils';

function useItens(
  itens,
  updateItem,
  vendaId,
  cliente,
  irParaTelaInit,
  subTotal,
) {
  const { enqueueSnackbar } = useSnackbar();
  const refSidebarInputs = useRef(null);
  const [produto, setProduto] = useState(null);
  const [search, setSearch] = useState('');
  const refSearch = useRef(null);
  const [podeFecharVenda, setPodeFecharVenda] = useState(true);

  function getTotal2(
    peso,
    unidades,
    unidadesCaixaAparente,
    unitario,
    unitarioCaixa,
    produto,
  ) {
    if (produto) {
      if (produto.unidade.tipo === POR_UNIDADE) {
        if (produto.nivelCaixa) {
          return trunc10(unidadesCaixaAparente * unitarioCaixa, -2);
        }
        return trunc10(unidades * unitario, -2);
      }
      if (produto.unidade.tipo === POR_PESO_FIXO) {
        if (produto.nivelCaixa) {
          return trunc10(unidadesCaixaAparente * unitarioCaixa, -2);
        }
        return trunc10(peso * unitario, -2);
      }
      if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
        if (produto.nivelCaixa) {
          return trunc10(unidadesCaixaAparente * unitarioCaixa, -2);
        }
        return trunc10(peso * unitario, -2);
      }
      return trunc10(peso * unitario, -2);
    }
    return 0;
  }

  function addNewItemInterno(dados, produto) {
    const { unidades, peso, isIntegro } = dados;

    const unidades2 = getUnidadesItemCaixa(produto, unidades);
    const peso2 =
      produto.unidade.tipo === POR_UNIDADE
        ? 0
        : produto.itemVenda.pesoCaixa === 0
        ? peso
        : produto.itemVenda.pesoCaixa * unidades;
    const unidadesCaixaAparente2 = produto.nivelCaixa ? unidades : 0;
    const unitario2 = produto.itemVenda.unitario;
    const unitarioCaixa2 = produto.itemVenda.unitarioCaixa;

    updateItem({
      produto,
      peso: peso2,
      total: getTotal2(
        peso2,
        unidades2,
        unidadesCaixaAparente2,
        unitario2,
        unitarioCaixa2,
        produto,
      ),
      unidades: unidades2,
      unitario: unitario2,
      uidd: `${produto.nome}${itens.length}`,
      item_venda_id: produto.itemVenda.id,
      idIndicadorChange: -1,
      unidadesCaixa: produto.itemVenda.unidadesCaixa,
      unitarioCaixa: unitarioCaixa2,
      pesoCaixa: produto.itemVenda.pesoCaixa,
      unidadesCaixaAparente: unidadesCaixaAparente2,
      nivelCaixaria: produto.nivelCaixa ? produto.nivelCaixa : 0,
      cgp: null,
      grupo_oferta_preco_produto_id: null,
      tipo_oferta: null,
      is_margem_cartao: null,
      isIntegro,
    });

    setProduto(null);
    if (refSearch.current) refSearch.current.focus();
  }

  /*function selectProduto(produto) {
    const hideUnidades = produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE;
    if (refSidebarInputs.current)
      refSidebarInputs.current.focus(hideUnidades ? 1 : 0);
    setProduto(produto);
  }*/

  function selectProduto(produto) {
    if (produto) {
      const hideUnidades =
        produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE &&
        !produto.nivelCaixa;
      if (refSidebarInputs.current)
        refSidebarInputs.current.focus(hideUnidades ? 1 : 0);
      setProduto(produto);
    } else if (refSearch.current) refSearch.current.focus();
  }

  function addNewItem(dados) {
    addNewItemInterno(dados, produto);
  }

  function getMessagesError() {
    const errors = [];

    if (cliente === null) {
      errors.push({
        message: 'Campo cliente não foi preenchido!',
        type: 'warning',
      });
    }

    if (itens.length <= 0) {
      errors.push({
        message: 'É necessário ao menos um registro de itens!',
        type: 'warning',
      });
    }

    return errors;
  }

  function montarObjVenda(responsavel_id, tipo_parcela, conta_id) {
    const listaItens = [];

    for (let i = 0; i < itens.length; i += 1) {
      listaItens.push({
        peso: itens[i].peso,
        unidades: itens[i].unidades,
        produto_id: itens[i].produto.id,
        idIndicadorChange: itens[i].idIndicadorChange,
        unidades_caixa: itens[i].unidadesCaixa,
        peso_caixa: itens[i].pesoCaixa,
        unidades_caixa_aparente: itens[i].unidadesCaixaAparente,
        nivel_caixaria: itens[i].nivelCaixaria,
        item_venda_id: itens[i].item_venda_id,
        preco_venda: itens[i].unitario,
        preco_venda_caixa: itens[i].unitarioCaixa,
        is_integro: itens[i].isIntegro,
      });
    }

    return {
      listaItens,
      data: new Date(),
      cliente_id: cliente.id,
      venda_id: vendaId,
      responsavel_id,
      tipo_parcela,
      conta_id,
      subTotal,
    };
  }

  async function submitVenda(responsavel_id, tipo_parcela, conta_id) {
    try {
      const objVenda = montarObjVenda(responsavel_id, tipo_parcela, conta_id);
      const data = await api.post(`/devolucoes_vendas`, {
        ...objVenda,
      });
      return data.data.id;
    } catch (e) {
      return -1;
    }
  }

  async function handleFinalizarVenda(responsavel_id, tipo_parcela, conta_id) {
    const errors = getMessagesError();
    if (errors.length <= 0 && podeFecharVenda) {
      setPodeFecharVenda(false);
      const idVenda = await submitVenda(responsavel_id, tipo_parcela, conta_id);
      if (idVenda > 0) {
        const codigo = await imprimirNotaDevolucaoVenda(idVenda);
        if (codigo < 0) {
          enqueueSnackbar('Erro ao imprimir a nota!', {
            variant: 'error',
          });
        }
        irParaTelaInit();
      } else {
        setPodeFecharVenda(true);
        enqueueSnackbar('Erro ao registrar a devolução de venda!', {
          variant: 'error',
        });
      }
    } else {
      for (let i = 0; i < errors.length; i += 1) {
        enqueueSnackbar(errors[i].message, {
          variant: errors[i].type,
        });
      }
    }
  }

  function changeCaixaria() {
    changeCaixariaLocal(produto, setProduto, enqueueSnackbar);
  }

  return {
    addNewItem,
    setSearch,
    produto,
    refSearch,
    search,
    refSidebarInputs,
    enqueueSnackbar,
    setProduto,
    selectProduto,
    handleFinalizarVenda,
    changeCaixaria,
  };
}

export default useItens;
