import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import TimeInput from '../../../Components/Inputs/TimeInput';
import { getListAllAPI, getUserId, api } from '../../../services';
import { writeCodigoBarras } from '../../../utils/codigoBarras';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const FECHAR_VENDA_ACTION = 'F8';
const CANCELAR_VENDA_ACTION = 'F4';

const DEFAULT_ID = -1;

const DialogoCategoriaTransacao = forwardRef(
  ({ updatePonto = () => {} }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [id, setId] = useState(DEFAULT_ID);

    const [carregando, setCarregando] = useState(false);

    const [horarioEntrada1, setHorarioEntrada1] = useState(null);

    const [colaborador, setColaborador] = useState('');

    const [data, setData] = useState('');

    const [dadosDia, setDadosDia] = useState(null);

    const [pontos, setPontos] = useState([]);

    const classes = useStyles({ cor: '#009FD4' });

    const handleCloseDialog = () => {
      setOpen(false);
    };

    async function getDataResource(id) {
      try {
        const data = await getListAllAPI(
          'pontos_colaborador',
          ['id', 'asc'],
          { id: [id] },
          ['colaborador', 'dia', 'pontosUnitarios'],
        );
        if (data.data.length > 0) {
          setId(data.data[0].id);

          setColaborador(data.data[0].colaborador);

          setData(moment(new Date(data.data[0].dia.data)).format('DD/MM/YYYY'));

          setPontos(data.data[0].pontosUnitarios);

          setDadosDia(data.data[0].dia);

          setHorarioEntrada1(null);

          setCarregando(false);
        } else {
          throw 'Esta Ponto não existe!';
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    function converteData(dataBase, dataHorario) {
      if (dataHorario) {
        const dataNova = new Date(dataBase);
        dataNova.setHours(dataHorario.getHours());
        dataNova.setMinutes(dataHorario.getMinutes());
        dataNova.setSeconds(0);
        dataNova.setMilliseconds(0);
        return dataNova;
      }
    }

    async function handleSalvar() {
      setCarregando(true);
      try {
        const horario_entrada_1 = converteData(dadosDia.data, horarioEntrada1);

        const response = await api.put(`/marcar_pontos_atrazados`, {
          dados_dia_id: dadosDia.id,
          colaborador_id: colaborador.id,
          horario: horario_entrada_1,
        });

        const data = response.data;

        if (data.status) {
          setCarregando(false);
          enqueueSnackbar('Este Ponto foi criado com sucesso!', {
            variant: 'success',
          });

          handleCloseDialog();
        } else {
          setCarregando(false);
          enqueueSnackbar(data.message, {
            variant: 'success',
          });

          handleCloseDialog();
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    useImperativeHandle(ref, () => ({
      handleOpen(id) {
        setCarregando(true);
        getDataResource(id);
        setId(id);
        setOpen(true);
      },
    }));

    function validateHorario() {
      const error = '';
      //if (!horarioEntrada1) error = 'Este campo é obrigatório';
      return error;
    }

    function getErros() {
      const errosOld = ['', ''];
      errosOld[0] = validateHorario();
      return errosOld;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    function formatData2(data) {
      if (data) {
        return moment(data).format('HH:mm');
      }
      return '-';
    }

    function handleActions(action, inputCode) {
      switch (action) {
        case FECHAR_VENDA_ACTION:
          handleSalvar();
          break;
        case CANCELAR_VENDA_ACTION:
          handleCloseDialog(-1);
          break;
        default:
          break;
      }
    }

    function handleKey(keyCode, keyName, inputCode) {
      handleActions(keyName, inputCode);
    }

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          TransitionComponent={Transition}
          keepMounted
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            {`Criar Batida de ponto - ${
              colaborador ? colaborador.nome : ''
            } - ${data}`}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 10px 20px',
              margin: '0',
            }}
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div>
                <Box
                  p="1em"
                  style={{
                    display: 'flex',
                  }}
                >
                  <Box flex={1}>
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <TimeInput
                          label="Ponto"
                          value={horarioEntrada1}
                          onChange={(value) => setHorarioEntrada1(value)}
                          error={erros[0] !== ''}
                          helperText={erros[0]}
                          handleEnter={() => {}}
                          autoFocus
                          handleKey={(keyCode, keyName) => {
                            handleKey(keyCode, keyName, 0);
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box style={{ marginTop: '0px' }} flex={1}>
                    <Typography
                      variant="h5"
                      style={{
                        marginBottom: '20px',
                      }}
                    >
                      Batidas de Ponto
                    </Typography>

                    <Box>
                      <TableContainer
                        component={Paper}
                        className={classes.root}
                      >
                        <Table aria-label="collapsible table">
                          <TableHead
                            style={{
                              color: 'white',
                              fontWeight: 'bold',
                              backgroundColor: '#009FD4',
                            }}
                          >
                            <TableRow className={classes.rowHead}>
                              <TableCell className={classes.rowHead}>
                                Código
                              </TableCell>
                              <TableCell className={classes.rowHead}>
                                Horário
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {pontos.map((row) => (
                              <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                  {writeCodigoBarras(row.id)}
                                </TableCell>
                                <TableCell>{formatData2(row.data)}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Box>
                </Box>
              </div>
            )}
          </DialogContent>
          <DialogActions
            style={{
              padding: '0px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={handleCloseDialog}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="contained"
              color="primary"
            >
              Calcelar
            </Button>
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                disabled={erroExistente || carregando}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
              {carregando && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
