import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import { stringify } from 'query-string';

const Config = () => {
  const history = useHistory();

  const despesasAgendadas = (data) => {
    const dataStart = new Date(
      moment(new Date().setFullYear(2000)).startOf('year').format(),
    );

    const dataEnd = new Date(
      moment(new Date()).add(1, 'months').endOf('day').format(),
    );

    const queryOj = {};

    queryOj.displayedFilters = JSON.stringify({});
    queryOj.filter = JSON.stringify({
      tipo: 'Despesa',
      periodo: {
        start: dataStart,
        end: dataEnd,
      },
      situacoes: [2],
      agendamento: [1],
    });
    history.push(
      `/transacoes?${stringify(
        queryOj,
      )}&order=DESC&page=1&perPage=100&sort=data_pagamento`,
    );
  };

  useEffect(() => {
    despesasAgendadas();
  }, []);

  return (
    <>
      <h1>Agendamentos</h1>
    </>
  );
};

export default Config;
