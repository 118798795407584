import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
} from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DataUsageIcon from '@material-ui/icons/DataUsage';

import { formatMoeda } from '../../../utils';
import DialogoGrafico from './DialogoGrafico';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: 'black',
    width: '1px',
    whiteSpace: 'nowrap',
    padding: '5px',
    border: '1px solid black',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    padding: '5px',
    border: '1px solid black',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
}))(TableCell);

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function formatPorcentagem(valor) {
  return valor !== null && valor !== undefined
    ? `${valor.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} %`
    : '';
}

const COLOR_RED = '#ff0000';
const BACKGROUND_PREVISAO_LUCRO = '#92d050';
const BACKGROUND_PRECO_VENDA = '#ffff00';
const BACKGROUND_CREDITO_COMPRA = '#e2efd9';
const BACKGROUND_DEBITO_VENDA = '#fef2cb';
const BACKGROUND_ANALISE_FLUXO_CAIXA = '#fbe4d5';
const BACKGROUND_CUSTOS_ADICIONAIS = '#d6dce4';

const DialogoBoletos = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [carregando, setCarregando] = useState(false);
  const classes = useStyles();
  const refDialogoGrafico = useRef(null);

  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
    },
  }));

  function handleSalvar() {}

  const erroExistente = false;

  function calculaDados({
    estadoOrigem,
    estadoDestino,
    valorNegociadoComAIndustria,
    irpjPorcentagemLb,
    csllPorcentagemLb,
    //lucroBrutoPorcentagem,
    precoVenda,
    aliquotaInternaCompraPorcentagem,
    pisCompraPorcentagem,
    cofinsCompraPorcentagem,
    aliquotaInternaVendaPorcentagem,
    agregadoDoCnaePorcentagem,
    mvaDaPauta,
    margemDeCustoOperacionalPorcentagem,
    margemDeCustoDeCartaoPorcentagem,
    repasseDoIpi,
    repasseDoFreteUnitario,
    repasseDeSt,
    repasseDeSeguros,
    repasseDeOutrasDespesas,
  }) {
    const icmsAntecipacaoPorcentagem = estadoOrigem === estadoDestino ? 2.5 : 0; //entrada

    const custoFinalDoProduto =
      valorNegociadoComAIndustria +
      repasseDoIpi +
      repasseDoFreteUnitario +
      repasseDeSt +
      repasseDeSeguros +
      repasseDeOutrasDespesas;

    const aliquotaInternaCompra =
      valorNegociadoComAIndustria * (aliquotaInternaCompraPorcentagem / 100);

    const pisCompra =
      valorNegociadoComAIndustria * (pisCompraPorcentagem / 100);

    const cofinsCompra =
      valorNegociadoComAIndustria * (cofinsCompraPorcentagem / 100);

    const custoLivreDeImpostoPorcentagem =
      custoFinalDoProduto - aliquotaInternaCompra - pisCompra - cofinsCompra;

    const pisVendaPorcentagem = pisCompraPorcentagem;

    const cofinsVendaPorcentagem = cofinsCompraPorcentagem;

    const lucroBrutoPorcentagem =
      1 -
      ((pisCompraPorcentagem +
        cofinsCompraPorcentagem +
        aliquotaInternaVendaPorcentagem +
        margemDeCustoOperacionalPorcentagem +
        margemDeCustoDeCartaoPorcentagem) /
        100 +
        custoLivreDeImpostoPorcentagem / precoVenda);

    /*const precoVenda =
      custoLivreDeImpostoPorcentagem /
      (1 -
        (aliquotaInternaVendaPorcentagem +
          pisVendaPorcentagem +
          cofinsVendaPorcentagem +
          lucroBrutoPorcentagem +
          margemDeCustoOperacionalPorcentagem +
          margemDeCustoDeCartaoPorcentagem) /
          100);*/

    const valorNegociadoComAIndustriaPorcentagem =
      (valorNegociadoComAIndustria / precoVenda) * 100;

    const lucroLiquidoPorcentagem =
      lucroBrutoPorcentagem *
      (1 - (irpjPorcentagemLb + csllPorcentagemLb) / 100);
    const lucroLiquido = precoVenda * (lucroLiquidoPorcentagem / 100);

    const lucroBruto = (lucroBrutoPorcentagem / 100) * precoVenda;

    const irpj = (irpjPorcentagemLb / 100) * lucroBruto;
    const irpjPorcentagemPv = (irpj / precoVenda) * 100;

    const csll = (csllPorcentagemLb / 100) * lucroBruto;
    const csllPorcentagemPv = (csll / precoVenda) * 100;

    const totalDeCreditosCompra =
      aliquotaInternaCompra + pisCompra + cofinsCompra;
    const totalDeCreditosCompraPorcentagem =
      (totalDeCreditosCompra / precoVenda) * 100;

    const aliquotaInternaVenda =
      (aliquotaInternaVendaPorcentagem / 100) * precoVenda;

    const pisVenda = (pisVendaPorcentagem / 100) * precoVenda;

    const cofinsVenda = (cofinsVendaPorcentagem / 100) * precoVenda;

    const totalDeDebitosVenda = aliquotaInternaVenda + pisVenda + cofinsVenda;
    const totalDeDebitosVendaPorcentagem =
      (totalDeDebitosVenda / precoVenda) * 100;

    const icmsAntecipacao =
      icmsAntecipacaoPorcentagem * valorNegociadoComAIndustria;

    const calculoDaPautaDeIcmsDeFronteira =
      mvaDaPauta > 0
        ? mvaDaPauta * (aliquotaInternaVendaPorcentagem / 100) -
          aliquotaInternaCompra
        : 0;

    const fronteira =
      estadoOrigem === estadoDestino
        ? 0
        : ((custoFinalDoProduto - aliquotaInternaCompra) /
            (1 - aliquotaInternaVendaPorcentagem / 100)) *
            (1 + agregadoDoCnaePorcentagem / 100) *
            (aliquotaInternaVendaPorcentagem / 100) -
          aliquotaInternaCompra;
    const fronteiraPorcentagem =
      estadoOrigem === estadoDestino
        ? 0
        : (fronteira / valorNegociadoComAIndustria) * 100;

    const icmsDeFronteiraAdotado =
      fronteira > calculoDaPautaDeIcmsDeFronteira
        ? fronteira
        : calculoDaPautaDeIcmsDeFronteira;
    const icmsDeFronteiraAdotadoPorcentagem =
      (icmsDeFronteiraAdotado / valorNegociadoComAIndustria) * 100;

    const totalDeIcmsAntecipado = icmsAntecipacao + icmsDeFronteiraAdotado;
    const totalDeIcmsAntecipadoPorcentagem =
      (totalDeIcmsAntecipado / precoVenda) * 100;

    const diferencaDeIcms = aliquotaInternaVenda - aliquotaInternaCompra;
    const diferencaDeIcmsPorcentagem = (diferencaDeIcms / precoVenda) * 100;

    const diferencaDePis = pisVenda - pisCompra;
    const diferencaDePisPorcentagem = (diferencaDePis / precoVenda) * 100;

    const diferencaDeCofins = cofinsVenda - cofinsCompra;
    const diferencaDeCofinsPorcentagem = (diferencaDeCofins / precoVenda) * 100;

    const tributacaoEfetiva = totalDeDebitosVenda - totalDeCreditosCompra;
    const tributacaoEfetivaPorcentagem = (tributacaoEfetiva / precoVenda) * 100;

    const resultadoDoFluxoDeCaixa = tributacaoEfetiva + totalDeIcmsAntecipado;
    const resultadoDoFluxoDeCaixaPorcentagem =
      (resultadoDoFluxoDeCaixa / precoVenda) * 100;

    const margemDeCustoOperacional =
      (margemDeCustoOperacionalPorcentagem / 100) * precoVenda;

    const margemDeCustoDeCartao =
      (margemDeCustoDeCartaoPorcentagem / 100) * precoVenda;

    const repasseDoIpiPorcentagem =
      (repasseDoIpi / valorNegociadoComAIndustria) * 100;

    const repasseDoFreteUnitarioPorcentagem =
      (repasseDoFreteUnitario / valorNegociadoComAIndustria) * 100;

    const repasseDeStPorcentagem =
      (repasseDeSt / valorNegociadoComAIndustria) * 100;

    const repasseDeSegurosPorcentagem =
      (repasseDeSeguros / valorNegociadoComAIndustria) * 100;

    const repasseDeOutrasDespesasPorcentagem =
      (repasseDeOutrasDespesas / valorNegociadoComAIndustria) * 100;

    const totalDeCustosDeRepasses =
      repasseDoIpi +
      repasseDoFreteUnitario +
      repasseDeSt +
      repasseDeSeguros +
      repasseDeOutrasDespesas;
    const totalDeCustosDeRepassesPorcentagem =
      (totalDeCustosDeRepasses / precoVenda) * 100;

    const custoFinalDoProdutoPorcentagem =
      (custoFinalDoProduto / precoVenda) * 100;

    const conferenciaDoGraficoDeSecaoCircular =
      totalDeCustosDeRepassesPorcentagem +
      valorNegociadoComAIndustriaPorcentagem +
      lucroBrutoPorcentagem +
      diferencaDeIcmsPorcentagem +
      diferencaDePisPorcentagem +
      diferencaDeCofinsPorcentagem +
      margemDeCustoOperacionalPorcentagem +
      margemDeCustoDeCartaoPorcentagem;

    const margemTotal = precoVenda - valorNegociadoComAIndustria;
    const margemTotalPorcentagem =
      (margemTotal / valorNegociadoComAIndustria) * 100;

    return {
      estadoOrigem,
      estadoDestino,
      valorNegociadoComAIndustriaPorcentagem,
      valorNegociadoComAIndustria,
      custoLivreDeImpostoPorcentagem,
      lucroLiquidoPorcentagem,
      lucroLiquido,
      irpjPorcentagemPv,
      irpjPorcentagemLb,
      irpj,
      csllPorcentagemPv,
      csllPorcentagemLb,
      csll,
      lucroBrutoPorcentagem,
      lucroBruto,
      precoVenda,
      aliquotaInternaCompraPorcentagem,
      aliquotaInternaCompra,
      pisCompraPorcentagem,
      pisCompra,
      cofinsCompraPorcentagem,
      cofinsCompra,
      totalDeCreditosCompraPorcentagem,
      totalDeCreditosCompra,
      aliquotaInternaVendaPorcentagem,
      aliquotaInternaVenda,
      pisVendaPorcentagem,
      pisVenda,
      cofinsVendaPorcentagem,
      cofinsVenda,
      totalDeDebitosVendaPorcentagem,
      totalDeDebitosVenda,
      icmsAntecipacaoPorcentagem,
      icmsAntecipacao,
      agregadoDoCnaePorcentagem,
      mvaDaPauta,
      calculoDaPautaDeIcmsDeFronteira,
      fronteiraPorcentagem,
      fronteira,
      icmsDeFronteiraAdotadoPorcentagem,
      icmsDeFronteiraAdotado,
      totalDeIcmsAntecipadoPorcentagem,
      totalDeIcmsAntecipado,
      diferencaDeIcmsPorcentagem,
      diferencaDeIcms,
      diferencaDePisPorcentagem,
      diferencaDePis,
      diferencaDeCofinsPorcentagem,
      diferencaDeCofins,
      tributacaoEfetivaPorcentagem,
      tributacaoEfetiva,
      resultadoDoFluxoDeCaixaPorcentagem,
      resultadoDoFluxoDeCaixa,
      margemDeCustoOperacionalPorcentagem,
      margemDeCustoOperacional,
      margemDeCustoDeCartaoPorcentagem,
      margemDeCustoDeCartao,
      repasseDoIpiPorcentagem,
      repasseDoIpi,
      repasseDoFreteUnitarioPorcentagem,
      repasseDoFreteUnitario,
      repasseDeStPorcentagem,
      repasseDeSt,
      repasseDeSegurosPorcentagem,
      repasseDeSeguros,
      repasseDeOutrasDespesasPorcentagem,
      repasseDeOutrasDespesas,
      totalDeCustosDeRepassesPorcentagem,
      totalDeCustosDeRepasses,
      custoFinalDoProdutoPorcentagem,
      custoFinalDoProduto,
      conferenciaDoGraficoDeSecaoCircular,
      margemTotalPorcentagem,
      margemTotal,
    };
  }

  function formataDados(entradas) {
    const dados = calculaDados(entradas);
    return {
      estadoOrigem: dados.estadoOrigem,
      estadoDestino: dados.estadoDestino,
      valorNegociadoComAIndustriaPorcentagem: formatPorcentagem(
        dados.valorNegociadoComAIndustriaPorcentagem,
      ),
      valorNegociadoComAIndustria: formatMoeda(
        dados.valorNegociadoComAIndustria,
      ),
      custoLivreDeImpostoPorcentagem: formatMoeda(
        dados.custoLivreDeImpostoPorcentagem,
      ),
      lucroLiquidoPorcentagem: formatPorcentagem(dados.lucroLiquidoPorcentagem),
      lucroLiquido: formatMoeda(dados.lucroLiquido),
      irpjPorcentagemPv: formatPorcentagem(dados.irpjPorcentagemPv),
      irpjPorcentagemLb: formatPorcentagem(dados.irpjPorcentagemLb),
      irpj: formatMoeda(dados.irpj),
      csllPorcentagemPv: formatPorcentagem(dados.csllPorcentagemPv),
      csllPorcentagemLb: formatPorcentagem(dados.csllPorcentagemLb),
      csll: formatMoeda(dados.csll),
      lucroBrutoPorcentagem: formatPorcentagem(dados.lucroBrutoPorcentagem),
      lucroBruto: formatMoeda(dados.lucroBruto),
      precoVenda: formatMoeda(dados.precoVenda),
      aliquotaInternaCompraPorcentagem: formatPorcentagem(
        dados.aliquotaInternaCompraPorcentagem,
      ),
      aliquotaInternaCompra: formatMoeda(dados.aliquotaInternaCompra),
      pisCompraPorcentagem: formatPorcentagem(dados.pisCompraPorcentagem),
      pisCompra: formatMoeda(dados.pisCompra),
      cofinsCompraPorcentagem: formatPorcentagem(dados.cofinsCompraPorcentagem),
      cofinsCompra: formatMoeda(dados.cofinsCompra),
      totalDeCreditosCompraPorcentagem: formatPorcentagem(
        dados.totalDeCreditosCompraPorcentagem,
      ),
      totalDeCreditosCompra: formatMoeda(dados.totalDeCreditosCompra),
      aliquotaInternaVendaPorcentagem: formatPorcentagem(
        dados.aliquotaInternaVendaPorcentagem,
      ),
      aliquotaInternaVenda: formatMoeda(dados.aliquotaInternaVenda),
      pisVendaPorcentagem: formatPorcentagem(dados.pisVendaPorcentagem),
      pisVenda: formatMoeda(dados.pisVenda),
      cofinsVendaPorcentagem: formatPorcentagem(dados.cofinsVendaPorcentagem),
      cofinsVenda: formatMoeda(dados.cofinsVenda),
      totalDeDebitosVendaPorcentagem: formatPorcentagem(
        dados.totalDeDebitosVendaPorcentagem,
      ),
      totalDeDebitosVenda: formatMoeda(dados.totalDeDebitosVenda),
      icmsAntecipacaoPorcentagem: formatPorcentagem(
        dados.icmsAntecipacaoPorcentagem,
      ),
      icmsAntecipacao: formatMoeda(dados.icmsAntecipacao),
      agregadoDoCnaePorcentagem: formatPorcentagem(
        dados.agregadoDoCnaePorcentagem,
      ),
      mvaDaPauta: formatMoeda(dados.mvaDaPauta),
      calculoDaPautaDeIcmsDeFronteira: formatMoeda(
        dados.calculoDaPautaDeIcmsDeFronteira,
      ),
      fronteiraPorcentagem: formatPorcentagem(dados.fronteiraPorcentagem),
      fronteira: formatMoeda(dados.fronteira),
      icmsDeFronteiraAdotadoPorcentagem: formatPorcentagem(
        dados.icmsDeFronteiraAdotadoPorcentagem,
      ),
      icmsDeFronteiraAdotado: formatMoeda(dados.icmsDeFronteiraAdotado),
      totalDeIcmsAntecipadoPorcentagem: formatPorcentagem(
        dados.totalDeIcmsAntecipadoPorcentagem,
      ),
      totalDeIcmsAntecipado: formatMoeda(dados.totalDeIcmsAntecipado),
      diferencaDeIcmsPorcentagem: formatPorcentagem(
        dados.diferencaDeIcmsPorcentagem,
      ),
      diferencaDeIcms: formatMoeda(dados.diferencaDeIcms),
      diferencaDePisPorcentagem: formatPorcentagem(
        dados.diferencaDePisPorcentagem,
      ),
      diferencaDePis: formatMoeda(dados.diferencaDePis),
      diferencaDeCofinsPorcentagem: formatPorcentagem(
        dados.diferencaDeCofinsPorcentagem,
      ),
      diferencaDeCofins: formatMoeda(dados.diferencaDeCofins),
      tributacaoEfetivaPorcentagem: formatPorcentagem(
        dados.tributacaoEfetivaPorcentagem,
      ),
      tributacaoEfetiva: formatMoeda(dados.tributacaoEfetiva),
      resultadoDoFluxoDeCaixaPorcentagem: formatPorcentagem(
        dados.resultadoDoFluxoDeCaixaPorcentagem,
      ),
      resultadoDoFluxoDeCaixa: formatMoeda(dados.resultadoDoFluxoDeCaixa),
      margemDeCustoOperacionalPorcentagem: formatPorcentagem(
        dados.margemDeCustoOperacionalPorcentagem,
      ),
      margemDeCustoOperacional: formatMoeda(dados.margemDeCustoOperacional),
      margemDeCustoDeCartaoPorcentagem: formatPorcentagem(
        dados.margemDeCustoDeCartaoPorcentagem,
      ),
      margemDeCustoDeCartao: formatMoeda(dados.margemDeCustoDeCartao),
      repasseDoIpiPorcentagem: formatPorcentagem(dados.repasseDoIpiPorcentagem),
      repasseDoIpi: formatMoeda(dados.repasseDoIpi),
      repasseDoFreteUnitarioPorcentagem: formatPorcentagem(
        dados.repasseDoFreteUnitarioPorcentagem,
      ),
      repasseDoFreteUnitario: formatMoeda(dados.repasseDoFreteUnitario),
      repasseDeStPorcentagem: formatPorcentagem(dados.repasseDeStPorcentagem),
      repasseDeSt: formatMoeda(dados.repasseDeSt),
      repasseDeSegurosPorcentagem: formatPorcentagem(
        dados.repasseDeSegurosPorcentagem,
      ),
      repasseDeSeguros: formatMoeda(dados.repasseDeSeguros),
      repasseDeOutrasDespesasPorcentagem: formatPorcentagem(
        dados.repasseDeOutrasDespesasPorcentagem,
      ),
      repasseDeOutrasDespesas: formatMoeda(dados.repasseDeOutrasDespesas),
      totalDeCustosDeRepassesPorcentagem: formatPorcentagem(
        dados.totalDeCustosDeRepassesPorcentagem,
      ),
      totalDeCustosDeRepasses: formatMoeda(dados.totalDeCustosDeRepasses),
      custoFinalDoProdutoPorcentagem: formatPorcentagem(
        dados.custoFinalDoProdutoPorcentagem,
      ),
      custoFinalDoProduto: formatMoeda(dados.custoFinalDoProduto),
      conferenciaDoGraficoDeSecaoCircular: formatPorcentagem(
        dados.conferenciaDoGraficoDeSecaoCircular,
      ),
      margemTotalPorcentagem: formatPorcentagem(dados.margemTotalPorcentagem),
      margemTotal: formatMoeda(dados.margemTotal),
      originalValues: dados,
    };
  }

  const linhas = [];
  linhas.push(
    formataDados({
      estadoOrigem: 'Alagoas',
      estadoDestino: 'Pernambuco',
      valorNegociadoComAIndustria: 100,
      irpjPorcentagemLb: 25,
      csllPorcentagemLb: 9,
      precoVenda: 127,
      aliquotaInternaCompraPorcentagem: 12,
      pisCompraPorcentagem: 1.65,
      cofinsCompraPorcentagem: 7.6,
      aliquotaInternaVendaPorcentagem: 18,
      agregadoDoCnaePorcentagem: 30,
      mvaDaPauta: 0,
      margemDeCustoOperacionalPorcentagem: 5,
      margemDeCustoDeCartaoPorcentagem: 3,
      repasseDoIpi: 0,
      repasseDoFreteUnitario: 0,
      repasseDeSt: 0,
      repasseDeSeguros: 0,
      repasseDeOutrasDespesas: 0,
    }),
  );
  linhas.push(
    formataDados({
      estadoOrigem: 'Rio de Janeiro',
      estadoDestino: 'Pernambuco',
      valorNegociadoComAIndustria: 100,
      irpjPorcentagemLb: 25,
      csllPorcentagemLb: 9,
      precoVenda: 127,
      aliquotaInternaCompraPorcentagem: 7,
      pisCompraPorcentagem: 1.65,
      cofinsCompraPorcentagem: 7.6,
      aliquotaInternaVendaPorcentagem: 18,
      agregadoDoCnaePorcentagem: 30,
      mvaDaPauta: 0,
      margemDeCustoOperacionalPorcentagem: 5,
      margemDeCustoDeCartaoPorcentagem: 3,
      repasseDoIpi: 0,
      repasseDoFreteUnitario: 0,
      repasseDeSt: 0,
      repasseDeSeguros: 0,
      repasseDeOutrasDespesas: 0,
    }),
  );

  function abrirGrafico(data) {
    if (refDialogoGrafico.current) {
      refDialogoGrafico.current.handleOpen(data);
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullScreen
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleClose}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          Margeamento
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.root}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    colSpan={2}
                    rowSpan={3}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    Valor Negociado com a indústria
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={11}
                    rowSpan={2}
                    style={{
                      backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                    }}
                  >
                    PREVISÃO DE LUCRO
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={3}
                    style={{
                      backgroundColor: BACKGROUND_PRECO_VENDA,
                    }}
                  >
                    Preço de venda
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={9}
                    rowSpan={1}
                    style={{
                      backgroundColor: BACKGROUND_CREDITO_COMPRA,
                    }}
                  >
                    Crédito de Compra
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={6}
                    rowSpan={1}
                    style={{
                      backgroundColor: BACKGROUND_DEBITO_VENDA,
                    }}
                  >
                    Débito de Venda
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={21}
                    rowSpan={1}
                    style={{
                      backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                    }}
                  >
                    Análise de Fluxo de Caixa
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={18}
                    rowSpan={2}
                    style={{
                      backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                    }}
                  >
                    Custos Adicionais
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={3}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    Conferência do Gráfico de Seção Circular
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    rowSpan={3}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    Margem Total
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={4}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    Ações
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell
                    colSpan={3}
                    rowSpan={2}
                    style={{
                      backgroundColor: BACKGROUND_CREDITO_COMPRA,
                    }}
                  >
                    ALÍQUOTA INTERNA / ICMS NORMAL
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    rowSpan={2}
                    style={{
                      backgroundColor: BACKGROUND_CREDITO_COMPRA,
                    }}
                  >
                    PIS
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    rowSpan={2}
                    style={{
                      backgroundColor: BACKGROUND_CREDITO_COMPRA,
                    }}
                  >
                    COFINS
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    rowSpan={2}
                    style={{
                      backgroundColor: BACKGROUND_CREDITO_COMPRA,
                    }}
                  >
                    TOTAL DE CRÉDITOS
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    rowSpan={2}
                    style={{
                      backgroundColor: BACKGROUND_DEBITO_VENDA,
                    }}
                  >
                    ALÍQUOTA INTERNA / ICMS NORMAL
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={2}
                    style={{
                      backgroundColor: BACKGROUND_DEBITO_VENDA,
                    }}
                  >
                    PIS
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={2}
                    style={{
                      backgroundColor: BACKGROUND_DEBITO_VENDA,
                    }}
                  >
                    CONFINS
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    rowSpan={2}
                    style={{
                      backgroundColor: BACKGROUND_DEBITO_VENDA,
                    }}
                  >
                    TOTAL DE DÉBITOS
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    rowSpan={2}
                    style={{
                      backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                    }}
                  >
                    ICMS ANTECIPAÇÃO (Usado em caso de compras de dentro do
                    estado. Havendo Antecipação não pode existir Fronteira.)
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={7}
                    rowSpan={1}
                    style={{
                      backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                    }}
                  >
                    ICMS de Fronteira
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    rowSpan={2}
                    style={{
                      backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                    }}
                  >
                    TOTAL DE ICMS ANTECIPADO (A SER COLOCADO COMO CRÉDITO NO
                    ICMS NORMAL DO PRÓXIMO MÊS)
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    rowSpan={2}
                    style={{
                      backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                    }}
                  >
                    Diferença de ICMS
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    rowSpan={2}
                    style={{
                      backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                    }}
                  >
                    Diferença de PIS
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    rowSpan={2}
                    style={{
                      backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                    }}
                  >
                    Diferença de COFINS
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    rowSpan={2}
                    style={{
                      backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                    }}
                  >
                    TRIBUTAÇÃO EFETIVA
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    rowSpan={2}
                    style={{
                      backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                    }}
                  >
                    RESULTADO DO FLUXO DE CAIXA
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                    }}
                  >
                    CLI (Custo Livre de Impostos)
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    rowSpan={1}
                    style={{
                      backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                    }}
                  >
                    Lucro Líquido
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={3}
                    rowSpan={1}
                    style={{
                      backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                    }}
                  >
                    IRPJ
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={3}
                    rowSpan={1}
                    style={{
                      backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                    }}
                  >
                    CSLL
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    rowSpan={1}
                    style={{
                      backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                    }}
                  >
                    Lucro Bruto
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                    }}
                  >
                    Agregado do CNAE (30% para produtos normais e 35% para
                    derivados do leite)
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                    }}
                  >
                    MVA da Pauta
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                    }}
                  >
                    Cálculo da Pauta de ICMS de Fronteira
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    rowSpan={1}
                    style={{
                      backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                    }}
                  >
                    FRONTEIRA (Usado em caso de compras de fora do estado.
                    Havendo Fronteira não pode haver Antecipação.)
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    rowSpan={1}
                    style={{
                      backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                    }}
                  >
                    ICMS de Fronteira Adotado
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    rowSpan={1}
                    style={{
                      backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                    }}
                  >
                    Margem de Custo operacional
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    rowSpan={1}
                    style={{
                      backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                    }}
                  >
                    Margem de Custo de cartão
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    rowSpan={1}
                    style={{
                      backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                    }}
                  >
                    Repasse do IPI
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    rowSpan={1}
                    style={{
                      backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                    }}
                  >
                    Repasse de Frete Unitário
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    rowSpan={1}
                    style={{
                      backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                    }}
                  >
                    Repasse de ST (Substituição Tributária)
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    rowSpan={1}
                    style={{
                      backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                    }}
                  >
                    Repasse de Seguros
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    rowSpan={1}
                    style={{
                      backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                    }}
                  >
                    Repasse de Outras Despesas
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    rowSpan={1}
                    style={{
                      backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                    }}
                  >
                    Total de Custos de Repasses
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    rowSpan={1}
                    style={{
                      backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                    }}
                  >
                    Custo final do produto
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    %
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    %
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    % PV
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    % do LB
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    % PV
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    % do LB
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    % do LB
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    Estado
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    %
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    % {/*pis */}
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    % {/*cofins */}
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    % {/*tola credito */}
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    % {/*aliquota interna */}
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$ {/*pis*/}
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$ {/*cofins*/}
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    %
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    %
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    %
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    %
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    %
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    %
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    %
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    %
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    %
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    %
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    %
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    %
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    %
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    %
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    %
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    %
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    %
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    %
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    %
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    %
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    %
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    %
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={1}
                    rowSpan={1}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    R$
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {linhas.map(
                  ({
                    valorNegociadoComAIndustriaPorcentagem,
                    valorNegociadoComAIndustria,
                    custoLivreDeImpostoPorcentagem,
                    lucroLiquidoPorcentagem,
                    lucroLiquido,
                    irpjPorcentagemPv,
                    irpjPorcentagemLb,
                    irpj,
                    csllPorcentagemPv,
                    csllPorcentagemLb,
                    csll,
                    lucroBrutoPorcentagem,
                    lucroBruto,
                    precoVenda,
                    estadoOrigem,
                    aliquotaInternaCompraPorcentagem,
                    aliquotaInternaCompra,
                    pisCompraPorcentagem,
                    pisCompra,
                    cofinsCompraPorcentagem,
                    cofinsCompra,
                    totalDeCreditosCompraPorcentagem,
                    totalDeCreditosCompra,
                    aliquotaInternaVendaPorcentagem,
                    aliquotaInternaVenda,
                    pisVenda,
                    cofinsVenda,
                    totalDeDebitosVendaPorcentagem,
                    totalDeDebitosVenda,
                    icmsAntecipacaoPorcentagem,
                    icmsAntecipacao,
                    agregadoDoCnaePorcentagem,
                    mvaDaPauta,
                    calculoDaPautaDeIcmsDeFronteira,
                    fronteiraPorcentagem,
                    fronteira,
                    icmsDeFronteiraAdotadoPorcentagem,
                    icmsDeFronteiraAdotado,
                    totalDeIcmsAntecipadoPorcentagem,
                    totalDeIcmsAntecipado,
                    diferencaDeIcmsPorcentagem,
                    diferencaDeIcms,
                    diferencaDePisPorcentagem,
                    diferencaDePis,
                    diferencaDeCofinsPorcentagem,
                    diferencaDeCofins,
                    tributacaoEfetivaPorcentagem,
                    tributacaoEfetiva,
                    resultadoDoFluxoDeCaixaPorcentagem,
                    resultadoDoFluxoDeCaixa,
                    margemDeCustoOperacionalPorcentagem,
                    margemDeCustoOperacional,
                    margemDeCustoDeCartaoPorcentagem,
                    margemDeCustoDeCartao,
                    repasseDoIpiPorcentagem,
                    repasseDoIpi,
                    repasseDoFreteUnitarioPorcentagem,
                    repasseDoFreteUnitario,
                    repasseDeStPorcentagem,
                    repasseDeSt,
                    repasseDeSegurosPorcentagem,
                    repasseDeSeguros,
                    repasseDeOutrasDespesasPorcentagem,
                    repasseDeOutrasDespesas,
                    totalDeCustosDeRepassesPorcentagem,
                    totalDeCustosDeRepasses,
                    custoFinalDoProdutoPorcentagem,
                    custoFinalDoProduto,
                    conferenciaDoGraficoDeSecaoCircular,
                    margemTotalPorcentagem,
                    margemTotal,
                    originalValues,
                  }) => (
                    <TableRow>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {valorNegociadoComAIndustriaPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {valorNegociadoComAIndustria}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {custoLivreDeImpostoPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {lucroLiquidoPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {lucroLiquido}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {irpjPorcentagemPv}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {irpjPorcentagemLb}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {irpj}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {csllPorcentagemPv}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {csllPorcentagemLb}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {csll}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {lucroBrutoPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {lucroBruto}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {precoVenda}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {estadoOrigem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {aliquotaInternaCompraPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {aliquotaInternaCompra}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {pisCompraPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {pisCompra}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {cofinsCompraPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {cofinsCompra}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {totalDeCreditosCompraPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {totalDeCreditosCompra}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {aliquotaInternaVendaPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {aliquotaInternaVenda}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {pisVenda}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {cofinsVenda}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {totalDeDebitosVendaPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {totalDeDebitosVenda}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {icmsAntecipacaoPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {icmsAntecipacao}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {agregadoDoCnaePorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {mvaDaPauta}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {calculoDaPautaDeIcmsDeFronteira}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {fronteiraPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {fronteira}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {icmsDeFronteiraAdotadoPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {icmsDeFronteiraAdotado}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {totalDeIcmsAntecipadoPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {totalDeIcmsAntecipado}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {diferencaDeIcmsPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {diferencaDeIcms}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {diferencaDePisPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {diferencaDePis}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {diferencaDeCofinsPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {diferencaDeCofins}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {tributacaoEfetivaPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {tributacaoEfetiva}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {resultadoDoFluxoDeCaixaPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {resultadoDoFluxoDeCaixa}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {margemDeCustoOperacionalPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {margemDeCustoOperacional}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {margemDeCustoDeCartaoPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {margemDeCustoDeCartao}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {repasseDoIpiPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {repasseDoIpi}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {repasseDoFreteUnitarioPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {repasseDoFreteUnitario}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {repasseDeStPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {repasseDeSt}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {repasseDeSegurosPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {repasseDeSeguros}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {repasseDeOutrasDespesasPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {repasseDeOutrasDespesas}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {totalDeCustosDeRepassesPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {totalDeCustosDeRepasses}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {custoFinalDoProdutoPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {custoFinalDoProduto}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {conferenciaDoGraficoDeSecaoCircular}
                      </StyledTableCell>

                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {margemTotalPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {margemTotal}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        <Tooltip title="Gráfico de pizza">
                          <IconButton
                            aria-label="delete"
                            onClick={() => abrirGrafico(originalValues)}
                          >
                            <DataUsageIcon />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={handleClose}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
            variant="contained"
            color="primary"
          >
            Cancelar
          </Button>
          <div className={classes.wrapper}>
            <Button
              onClick={handleSalvar}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              disabled={erroExistente || carregando}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
            {carregando && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
        <DialogoGrafico ref={refDialogoGrafico} />
      </Dialog>
    </div>
  );
});

export default DialogoBoletos;
