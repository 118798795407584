import React, { useState } from 'react';

import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import FilledInput from '@material-ui/core/FilledInput';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DraftsIcon from '@material-ui/icons/Drafts';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import StarBorder from '@material-ui/icons/StarBorder';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  selected: {
    '&$selected': {
      color: 'green',
      /* '&:hover': {
        backgroundColor: 'green',
      }, */
    },
  },
  selected2: {},
}));

function Filtros({ periodo = 0, handlePeriodo = () => {} }) {
  const classes = useStyles();

  return (
    <Card style={{ marginRight: '10px' }}>
      <Typography
        style={{ textAlign: 'center', marginTop: '10px', fontWeight: 'bold' }}
      >
        Filtros
      </Typography>
      <CardContent>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.root}
        >
          <ListItem button>
            <ListItemIcon>
              <AccessTimeIcon />
            </ListItemIcon>
            <ListItemText primary="Período" style={{ fontWeight: 'bold' }} />
          </ListItem>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              classes={periodo === 0 ? { root: classes.selected } : {}}
              selected={periodo === 0}
              onClick={() => handlePeriodo(0)}
            >
              <ListItemText primary="Hoje" />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              classes={periodo === 1 ? { root: classes.selected } : {}}
              selected={periodo === 1}
              onClick={() => handlePeriodo(1)}
            >
              <ListItemText primary="3 últimos dias" />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              classes={periodo === 2 ? { root: classes.selected } : {}}
              selected={periodo === 2}
              onClick={() => handlePeriodo(2)}
            >
              <ListItemText primary="7 últimos dias" />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              classes={periodo === 3 ? { root: classes.selected } : {}}
              selected={periodo === 3}
              onClick={() => handlePeriodo(3)}
            >
              <ListItemText primary="14 últimos dias" />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              classes={periodo === 4 ? { root: classes.selected } : {}}
              selected={periodo === 4}
              onClick={() => handlePeriodo(4)}
            >
              <ListItemText primary="30 últimos dias" />
            </ListItem>
          </List>
        </List>
      </CardContent>
    </Card>
  );
}

export default Filtros;
