import React, { useRef } from 'react';
import {
  useRefresh,
  TextField,
  NumberField,
  ListBase,
  ListToolbar,
  Pagination,
} from 'react-admin';

import { Card } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { DialogoProduto2 } from '../../Components';
import Datagrid from '../../Components/Datagrid';
import DeleteButton from '../../Components/DeleteButton';
import EditButton from '../../Components/EditButton2';
import Actions from './ActionsList2';
import DialogoCriarEditar from './DialogoCreateEdit';
import DialogoDeletar from './DialogoDeletar';
import Filter from './Filter';

const MyList = ({ ...props }) => {
  const refresh = useRefresh();
  const refDialogo = useRef(null);
  const refDialogoProduto = useRef(null);
  const refDialogoDeletar = useRef(null);

  const create = (tipo) => {
    if (refDialogo.current) {
      refDialogo.current.handleCreate(tipo);
    }
  };

  const edit = (data) => {
    if (refDialogo.current) {
      refDialogo.current.handleEdit(data.id);
    }
  };

  const createProduto = ({ id }) => {
    if (refDialogoProduto.current) {
      refDialogoProduto.current.handleCreateApartirFamilia(id);
    }
  };

  const deletar = (data) => {
    if (refDialogoDeletar.current) {
      refDialogoDeletar.current.handleOpen(data.id);
    }
  };

  const handleClose = () => {
    refresh();
  };

  return (
    <>
      <ListToolbar
        filters={<Filter />}
        actions={<Actions handleCriar={create} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid {...props}>
          <TextField source="nome" label="Descrição" />
          <NumberField
            source="preco_venda"
            locales="pt-BR"
            options={{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            }}
            label="Preço de Venda"
          />
          <EditButton editHundle={edit} />
          <DeleteButton
            label="Excluir"
            deleteHundle={deletar}
            disabled={() => false}
          />
          <DeleteButton
            label="Novo Produto"
            deleteHundle={createProduto}
            disabled={() => false}
            icon={<AddIcon />}
          />
        </Datagrid>
        <TransacoesPagination />
      </Card>
      <DialogoCriarEditar ref={refDialogo} handleClose={handleClose} />
      <DialogoProduto2 ref={refDialogoProduto} handleClose={handleClose} />
      <DialogoDeletar ref={refDialogoDeletar} handleClose={handleClose} />
    </>
  );
};

const ContainerMyList = ({ ...props }) => (
  <ListBase {...props}>
    <MyList />
  </ListBase>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const CategoryList = (props) => (
  <>
    <ContainerMyList
      {...props}
      title=""
      component="div"
      bulkActionButtons={false}
      sort={{ field: 'nome', order: 'asc' }}
      perPage={20}
      pagination={false}
    />
  </>
);

export default CategoryList;
