import React, { useState } from 'react';
import { CirclePicker } from 'react-color';

import { Box, makeStyles, Button, Paper } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Popover from '@material-ui/core/Popover';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(() => ({
  avatar: {
    width: '28',
    height: '28',
    cursor: 'pointer',
  },
  btn: {
    borderRadius: '50px',
  },
}));

const colors = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#607d8b',
];

const CIRCLES_VISIBLE = 3;

const ColorInput = ({ value, onChange }) => {
  const classes = useStyles({ color: 'red' });
  const [anchorEl, setAnchorEl] = useState(null);

  function createVetor() {
    const vetor = [];
    for (let i = 0; i < CIRCLES_VISIBLE; i += 1) {
      vetor.push(i);
    }
    return vetor;
  }

  const vetorAux = createVetor();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover-color' : undefined;

  const changeColorPopover = (newColor) => {
    onChange(newColor);
    handleClose();
  };

  const checkFirst = () => {
    for (let i = 0; i < CIRCLES_VISIBLE; i += 1) {
      if (value === colors[i]) {
        return false;
      }
    }
    return true;
  };

  const isCheckFirst = checkFirst();

  return (
    <>
      <Box display="flex" alignItems="center">
        <Box marginRight="10px">
          <Avatar
            className={classes.avatar}
            style={{
              color: isCheckFirst ? 'white' : colors[colors.length - 1],
              backgroundColor: isCheckFirst ? value : colors[colors.length - 1],
            }}
            onClick={() => onChange(colors[colors.length - 1])}
          >
            <CheckIcon />
          </Avatar>
        </Box>
        {vetorAux.map((item) => (
          <Box marginRight="10px">
            <Avatar
              className={classes.avatar}
              style={{
                color: colors[item] === value ? 'white' : colors[item],
                backgroundColor: colors[item],
              }}
              onClick={() => onChange(colors[item])}
            >
              <CheckIcon />
            </Avatar>
          </Box>
        ))}
        <Box>
          <Button
            variant="contained"
            className={classes.btn}
            onClick={handleClick}
          >
            Outros
          </Button>
        </Box>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classes.paper }}
      >
        <Paper style={{ padding: '20px' }}>
          <CirclePicker
            color={value}
            onChange={(color) => changeColorPopover(color.hex)}
          />
        </Paper>
      </Popover>
    </>
  );
};

export default ColorInput;
