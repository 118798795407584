import React from 'react';
import { Filter as FilterAdmin } from 'react-admin';

import { PeriodoInput } from '../../Components/InputsReactAdmin';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <PeriodoInput
      source="periodo"
      label="Peŕiodo"
      defaultValue={{
        start: new Date(),
        end: new Date(),
      }}
    />
  </FilterAdmin>
);

export default Filter;
