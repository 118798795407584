import React, { useRef } from 'react';
import {
  ListBase,
  ListToolbar,
  Pagination,
  useRefresh,
  Title,
  useListContext,
  Datagrid,
} from 'react-admin';

import { Card, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { getUserId } from '../../services';
import { STATUS_ATIVO } from '../../utils';
import Actions from './ActionsList';
import DescricaoField from './Fields/DescricaoField';
import Filter from './Filter';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell
          style={{
            width: 'min-content',
          }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.nome}
        </TableCell>
        <TableCell component="th" scope="row" />
        <TableCell component="th" scope="row" />
        <TableCell component="th" scope="row" />
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>{`Detalhes: ${row.detalhes}`}</Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const PostPanel = (props) => (
  <div>
    {`Detalhes: ${props.record.detalhes}`}
    <Box mt="1em" />
    {props.record.subAtividades.length > 0 ? (
      <div
        style={{
          flex: 1,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" gutterBottom>
            Sub Tarefas
          </Typography>
        </div>
        <Box mt="1em" />
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    width: 'min-content',
                  }}
                />
                <TableCell align="left">Título</TableCell>
                <TableCell align="left" />
                <TableCell align="left" />
                <TableCell align="left" />
              </TableRow>
            </TableHead>
            <TableBody>
              {props.record.subAtividades.map((row) => (
                <Row key={row.id} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    ) : null}
  </div>
);

const MyList = ({ ...props }) => {
  const listContext = useListContext();

  return (
    <>
      <Title title="Atividades" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid {...props}>
          <DescricaoField source="id" label="ID" />
          <DescricaoField source="nome" label="Título" />
        </Datagrid>
        <TransacoesPagination />
      </Card>
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      expand={<PostPanel />}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Atividades"
      component="div"
      filter={{ user_id: getUserId(), status: STATUS_ATIVO }}
    />
  </>
);

export default List2;
