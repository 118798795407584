import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useState,
} from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { DateInputBonito } from '../../../Components';
import { api, getUserId, getListAllAPI, createAPI } from '../../../services';
import { SECONDARY_COLOR } from '../../../utils';

const DialogoContadorCedulas = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState(-1);
  const [carregando, setCarregando] = useState(false);
  const [dataMinima, setDataMinima] = useState(new Date());
  const [dataMaxima, setDataMaxima] = useState(new Date());

  const handleCloseDialog = () => {
    setOpen(false);
  };

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'jornais_de_ofertas',
        ['id', 'asc'],
        { id: [id] },
        [],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        //setDataMinima(new Date(data.data[0].data_minima));
        //setDataMaxima(new Date(data.data[0].data_maxima));
        setDataMinima(new Date());
        setDataMaxima(new Date());
        setCarregando(false);
      } else {
        throw 'Esta Oferta de Jornal não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    try {
      await createAPI('criar-jornal-ofertas-com-base-em-jornal-anterior', {
        data_minima: dataMinima,
        data_maxima: dataMaxima,
        user_id: getUserId(),
        jornal_oferta_anterior_id: id,
      });
      setCarregando(false);
      enqueueSnackbar(`Oferta de Jornal criada com sucesso!`, {
        variant: 'success',
      });
      handleCloseDialog();
      handleClose();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate(id) {
      setCarregando(true);
      getDataResource(id);
      setOpen(true);
      setId(id);
    },
  }));

  async function getJornaisConflito() {
    const response = await api.post('/get_jornais_complito', {
      data_minima: dataMinima,
      data_maxima: dataMaxima,
    });
    return response.data;
  }

  async function middleware() {
    const jornaisConflitos = await getJornaisConflito();
    let isLiberado = false;
    if (jornaisConflitos.length === 0) {
      isLiberado = true;
    } else if (jornaisConflitos.length === 1) {
      if (jornaisConflitos[0].id === id) {
        isLiberado = true;
      }
    }
    if (isLiberado) {
      await handleSalvar();
    } else {
      enqueueSnackbar(
        'Existe um jornal que interpõe esse intervalo que você esta definindo!!!',
        {
          variant: 'error',
        },
      );
    }
  }

  function dataMaximaMaxima() {
    return new Date(moment(dataMinima).add(3, 'weeks').format());
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        scroll="paper"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ color: 'white', backgroundColor: SECONDARY_COLOR }}
        >
          Novo Jornal de Oferta com Base no Anterior
        </DialogTitle>
        <DialogContent dividers>
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <div style={{ marginTop: '15px' }}>
                <DateInputBonito
                  showTodayButton
                  todayLabel="Hoje"
                  name="dataMinima"
                  handleEnter={() => {}}
                  label="Data de Início"
                  handleKey={() => {}}
                  value={dataMinima}
                  onChange={(value) => setDataMinima(value)}
                  format="dd MMMM yyyy"
                  fullWidth
                  variant="standard"
                  maxDate={dataMaxima}
                  disablePast
                />
              </div>
              <div style={{ marginTop: '15px', display: 'flex' }}>
                <DateInputBonito
                  showTodayButton
                  todayLabel="Hoje"
                  name="dataLimite"
                  handleEnter={() => {}}
                  label="Data Término"
                  handleKey={() => {}}
                  value={dataMaxima}
                  onChange={(value) => setDataMaxima(value)}
                  format="dd MMMM yyyy"
                  fullWidth
                  variant="standard"
                  minDate={
                    new Date().getTime() > dataMinima.getTime()
                      ? new Date()
                      : dataMinima
                  }
                  maxDate={dataMaximaMaxima()}
                  disablePast
                />
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px',
          }}
        >
          <Button
            onClick={handleCloseDialog}
            color="secondary"
            variant="contained"
            tabindex="-1"
          >
            Cancelar
          </Button>
          <Button
            onClick={middleware}
            color="secondary"
            variant="contained"
            tabindex="-1"
            disabled={carregando}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoContadorCedulas;
