import React, { useState } from 'react';

import ExcelJS from 'exceljs';

function LerPlanilhaCartoes() {
  const [file, setFile] = useState(null);
  async function getDados() {
    if (file) {
      const buff = await file.arrayBuffer();
      const data = new Uint8Array(buff);
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(data);

      const worksheetVendas = workbook.getWorksheet('vendas');

      const nVendas = worksheetVendas.rowCount;

      const dadosFomatados = [];

      for (let i = 3; i <= nVendas; i++) {
        const row = worksheetVendas.getRow(i);
        const data = row.getCell(1).value;
        const horario = new Date(row.getCell(2).value);
        const valor = row.getCell(4).value;
        const modalidade = row.getCell(6).value;

        const dataTotal = new Date(data);
        dataTotal.setTime(
          dataTotal.getTime() +
            6 * 60 * 60 * 1000 +
            horario.getUTCHours() * 60 * 60 * 1000 +
            horario.getMinutes() * 60 * 1000 +
            horario.getSeconds() * 1000 +
            horario.getMilliseconds(),
        );
        //dataTotal.setHours(horario.getHours());
        //dataTotal.setMinutes(horario.getMinutes());

        //dataTotal.setSeconds(horario.getSeconds());
        //dataTotal.setMilliseconds(horario.getMilliseconds());

        dadosFomatados.push({
          data: new Date(data),
          horario,
          valor,
          modalidade,
          dataTotal,
        });
      }
    } else {
      ///
    }
  }

  function onFileChange(event) {
    setFile(event.target.files.length > 0 ? event.target.files[0] : null);
  }

  return (
    <div>
      <div>teste planilha kkkkkkkk</div>
      <input type="file" onChange={onFileChange} />
      <button type="button" onClick={() => getDados()}>
        apertar
      </button>
    </div>
  );
}

export { LerPlanilhaCartoes };
