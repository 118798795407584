import {
  TIPO_FERIADO_DATA_COMEMORATIVA,
  TIPO_FERIADO_FERIADO,
  UNIVERSO_FERIADO_ESTADUAL,
  UNIVERSO_FERIADO_MUNICIPAL,
  UNIVERSO_FERIADO_NACIONAL,
  UNIVERSO_FERIADO_SINDICATO,
  TIPO_HORARIO_DOMINGO,
  TIPO_HORARIO_NORMAL,
  STATUS_LOJA_ABERTA,
  STATUS_LOJA_FECHADA,
} from '../../utils/constants';

const choicesTipo = [
  { id: TIPO_FERIADO_FERIADO, name: 'Feriado' },
  { id: TIPO_FERIADO_DATA_COMEMORATIVA, name: 'Data Comemorativa' },
];

const choicesUniverso = [
  { id: UNIVERSO_FERIADO_MUNICIPAL, name: 'Municipal' },
  { id: UNIVERSO_FERIADO_ESTADUAL, name: 'Estadual' },
  { id: UNIVERSO_FERIADO_NACIONAL, name: 'Nacional' },
  { id: UNIVERSO_FERIADO_SINDICATO, name: 'Sindicato' },
];

const choicesStatusLoja = [
  { id: STATUS_LOJA_ABERTA, name: 'Aberta' },
  { id: STATUS_LOJA_FECHADA, name: 'Fechada' },
];

const choicesTipoHorario = [
  { id: TIPO_HORARIO_NORMAL, name: 'Normal' },
  { id: TIPO_HORARIO_DOMINGO, name: 'Domingo' },
];

export { choicesTipo, choicesUniverso, choicesStatusLoja, choicesTipoHorario };
