import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CloseIcon from '@material-ui/icons/Close';
import Rating from '@material-ui/lab/Rating';
import { useSnackbar } from 'notistack';

import { TextInput, DateInputBonito } from '../../Components';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_ID = -1;

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose, handleSave }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [nome, setNome] = useState(null);
    const [data, setData] = useState(new Date());

    const [id, setId] = useState(DEFAULT_ID);

    const classes = useStyles({ cor: '#009FD4' });

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    async function reiniciar() {
      setId(DEFAULT_ID);
      setNome('');
      setData(new Date());
    }

    async function handleSalvar() {
      try {
        if (id <= 0) {
          handleSave(id, nome, data);
          enqueueSnackbar('Filho criado com sucesso!', {
            variant: 'success',
          });
        } else {
          handleSave(id, nome, data);
          enqueueSnackbar('Filho atualizado com sucesso!', {
            variant: 'success',
          });
        }
        handleCloseDialog();
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    useImperativeHandle(ref, () => ({
      handleCreate() {
        reiniciar();
        setOpen(true);
      },
      handleEdit(id, nome, data) {
        setNome(nome);
        setData(data);
        setId(id);
        setOpen(true);
      },
    }));

    function validateNome() {
      let error = '';
      if (!nome) error = 'Este campo é obrigatório';
      else if (nome.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
      return error;
    }

    function getErros() {
      const errosOld = [''];
      errosOld[0] = validateNome();
      return errosOld;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullWidth
          maxWidth="sm"
          TransitionComponent={Transition}
          keepMounted
          // style={{}}
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            {id < 0 ? `Novo Filho` : `Editar Filho`}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            <div>
              <Box flex={1} mr="0.5em">
                <TextInput
                  name="nome"
                  handleEnter={() => {}}
                  label="Nome"
                  handleKey={() => {}}
                  value={nome}
                  onChange={(value) => setNome(value.target.value)}
                  error={erros[0] !== ''}
                  helperText={erros[0]}
                  fullWidth
                  autoFocus
                />
              </Box>

              <DateInputBonito
                showTodayButton
                disableFuture
                todayLabel="Hoje"
                name="data_pagamento"
                handleEnter={() => {}}
                label="Data de nascimento"
                handleKey={() => {}}
                value={data}
                onChange={(value) => {
                  setData(value);
                }}
                format="dd MMMM yyyy"
                fullWidth
              />
            </div>
          </DialogContent>
          <DialogActions
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                disabled={erroExistente}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
