import React from 'react';

import { Paper, Box, makeStyles } from '@material-ui/core';

import { formatMoeda } from '../../../../../utils';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '10px',
    backgroundColor: theme.palette.secondary.main,
    marginTop: '15px',
    color: 'white',
    boxSizing: 'border-box',
    opacity: '0.75',
    fontSize: '18px',
  },
  containerInterno: {
    marginTop: '10px',
    fontSize: '18px',
  },
}));

const LabelTotalParcelas = ({
  totalAVistaCalculado,
  totalAVistaObrigatorio,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.container} elevation={3}>
      <Box display="flex" flexDirection="column" width="100%" color="white">
        <Box display="flex" justifyContent="space-between">
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            Obrig. à Vista{' '}
          </div>
          <div>|</div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            Total à Vista
          </div>
        </Box>

        <Paper className={classes.containerInterno} elevation={3}>
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            height="40px"
            color="black"
          >
            <Box display="flex" justifyContent="space-between" width="100%">
              <div
                style={{ flex: 1, display: 'flex', justifyContent: 'center' }}
              >
                {formatMoeda(totalAVistaObrigatorio)}
              </div>
              <div>|</div>
              <div
                style={{ flex: 1, display: 'flex', justifyContent: 'center' }}
              >
                {formatMoeda(totalAVistaCalculado)}
              </div>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Paper>
  );
};

export default LabelTotalParcelas;
