import React, { useImperativeHandle, forwardRef, useRef } from 'react';
import { useRefresh, useNotify } from 'react-admin';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { validQueryParams } from 'ra-core/esm/controller/useListParams';

import { DialogoConfirmacao } from '../../../../Components';
import { api } from '../../../../services';

const FormDialog = forwardRef(({ handleClose }, ref) => {
  const refDialogoConfirmacao = useRef(null);

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      if (refDialogoConfirmacao.current) {
        refDialogoConfirmacao.current.handleOpen(
          'Deletar Cotação',
          'Tem certeza de que deseja deletar esta cotação',
          id,
        );
      }
    },
  }));

  async function handleConfirma(id) {
    await api.delete(`/cotacoes/${id}`);
    handleClose();
  }

  return (
    <DialogoConfirmacao
      ref={refDialogoConfirmacao}
      handleConfirma={handleConfirma}
    />
  );
});

export default FormDialog;
