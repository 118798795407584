import React, { useState, useCallback, useRef } from 'react';
import {
  TopToolbar,
  Toolbar,
  sanitizeListRestProps,
  Button,
  useListContext,
} from 'react-admin';

import { Box, makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { green, pink } from '@material-ui/core/colors';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import AddIcon from '@material-ui/icons/Add';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import BarChartIcon from '@material-ui/icons/BarChart';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import GridOnIcon from '@material-ui/icons/GridOn';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import compareDesc from 'date-fns/compareDesc';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { stringify } from 'query-string';

import DialogoFluxoCaixa from './DialogoFluxoCaixa';
import SearchInputMobills from './Inputs/SearchInputMobills2';

const MES_ATUAL = new Date().getMonth();
const ANO_ATUAL = new Date().getFullYear();

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    alignItems: 'center',
  },
  small: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: pink[500],
    backgroundColor: pink[500],
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
  transacao: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: '#0039cb',
    backgroundColor: '#0039cb',
  },
  despesa: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: '#f44336',
    backgroundColor: '#f44336',
  },
  receita: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: '#4caf50',
    backgroundColor: '#4caf50',
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: '20px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {},
}))(MenuItem);

const TipoTransacaoSelect = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickItem = (code) => {
    handleClose();
    handleChange(code);
  };

  const handleChange = useCallback(
    (code) => {
      if (setFilters) {
        setFilters(
          { tipo: code, periodo2: { mes: MES_ATUAL, ano: ANO_ATUAL } },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  function getDados() {
    switch (filterValues.tipo) {
      case 'Transacao':
        return {
          color: '#0039cb',
          label: 'Transações',
        };
      case 'Despesa':
        return {
          color: '#f44336',
          label: 'Despesas',
        };
      case 'Receita':
        return {
          color: '#4caf50',
          label: 'Receitas',
        };
      case 'Transferência':
        return {
          color: '#0039cb',
          label: 'Transferências',
        };
      default:
        return {
          color: '',
          label: '',
        };
    }
  }

  const dados = getDados();

  return (
    <>
      <Fab
        variant="extended"
        style={{ backgroundColor: dados.color, color: 'white' }}
        onClick={handleClick}
      >
        <ExpandMoreIcon style={{ color: 'white' }} />
        {dados.label}
      </Fab>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Zoom}
        transitionDuration={500}
      >
        <StyledMenuItem onClick={() => handleClickItem('Transacao')}>
          <ListItemIcon>
            <Avatar className={classes.transacao} />
          </ListItemIcon>
          <ListItemText primary="Transações" />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleClickItem('Despesa')}>
          <ListItemIcon>
            <Avatar className={classes.despesa} />
          </ListItemIcon>
          <ListItemText primary="Despesas" />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleClickItem('Receita')}>
          <ListItemIcon>
            <Avatar className={classes.receita} />
          </ListItemIcon>
          <ListItemText primary="Receitas" />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleClickItem('Transferência')}>
          <ListItemIcon>
            <Avatar className={classes.transacao} />
          </ListItemIcon>
          <ListItemText primary="Transferências" />
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  // displayedFilters,
  exporter,
  // filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  enabledCreate,
  handleCriar = () => {},
  handleOpenFiltros = () => {},
  ...rest
}) => {
  const classes = useStyles();
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [anchorEl, setAnchorEl] = useState(null);
  const refDialogoFluxoCaixa = useRef(null);
  const [anchorEl2, setAnchorEl2] = useState(null);

  const aplicarFiltros = useCallback(
    (search, inverso) => {
      if (setFilters) {
        setFilters({ ...filterValues, search, inverso }, displayedFilters);
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleClickItem = (code) => {
    handleClose();
    handleCriar(code);
  };

  function getDados() {
    switch (filterValues.tipo) {
      case 'Transacao':
        return {
          color: '#0039cb',
          label: 'Transação',
        };
      case 'Despesa':
        return {
          color: '#f44336',
          label: 'Despesa',
        };
      case 'Receita':
        return {
          color: '#4caf50',
          label: 'Receita',
        };
      case 'Transferência':
        return {
          color: '#0039cb',
          label: 'Transferência',
        };
      default:
        return {
          color: '',
          label: '',
        };
    }
  }

  const dados = getDados();

  function formatDataAtual() {
    return `Relatorio_Transacoes_${moment(new Date()).format(
      'DD/MM/YYYY-HH:mm',
    )}.xlsx`;
  }

  const saveFile = () => {
    saveAs(
      `${process.env.REACT_APP_HOST_API}/transacoes_excel?${stringify({
        filter: JSON.stringify({
          tipo: filterValues.tipo,
          categorias: filterValues.categorias,
          contas: filterValues.contas,
          tipos: filterValues.tipos,
          situacoes: filterValues.situacoes,
          periodo: filterValues.periodo,
          periodo2: filterValues.periodo2,
          search: filterValues.search,
        }),
      })}`,
      formatDataAtual(),
    );
  };

  function editFluxoCaixa() {
    if (filterValues.showPrazos) {
      if (refDialogoFluxoCaixa.current) {
        refDialogoFluxoCaixa.current.handleOpen();
      }
    }
  }

  return (
    <TopToolbar {...sanitizeListRestProps(rest)} className={classes.root}>
      <Box
        display="flex"
        justifyContent="space-between"
        flex={1}
        alignItems="center"
      >
        <TipoTransacaoSelect />
        <Box display="flex" alignItems="center">
          <Fab
            variant="extended"
            // style={{ backgroundColor: dados.color, color: 'white' }}
            // onClick={handleClick}
            style={{
              backgroundColor: 'white',
              color: dados.color,
              fontWeight: 'bold',
            }}
            onClick={(e) => {
              if (filterValues.tipo === 'Transacao') {
                handleClick(e);
              } else {
                handleCriar(filterValues.tipo);
              }
            }}
          >
            <AddIcon
              style={{
                color: dados.color,
                marginRight: '5px',
                // backgroundColor: '#009FD4',
              }}
            />
            {`Nova ${dados.label}`}
          </Fab>
          <Box marginLeft="15px">
            {/* <Tooltip title="Pesquisar" placement="top" aria-label="add">
              <Fab
                aria-label="add"
                onClick={handleCriar}
                style={{ backgroundColor: 'white', color: '#737477' }}
              >
                <SearchIcon />
              </Fab>
            </Tooltip> */}
            <SearchInputMobills
              color={dados.color}
              onChange={(search, inverso) => aplicarFiltros(search, inverso)}
              placeholder="Pesquise por descrição ou valor"
            />
          </Box>
          {filterValues.tipo !== 'Transferência' && (
            <Box marginLeft="15px">
              <Tooltip title="Filtrar" placement="top" aria-label="add2">
                {/* <Fab
                  aria-label="add"
                  onClick={handleOpenFiltros}
                  style={{ backgroundColor: 'white', color: '#737477' }}
                >
                  <MaterialIcon icon="filter_alt" color="#737477" />
                </Fab> */}
                <Box
                  boxShadow={2}
                  onClick={handleOpenFiltros}
                  borderRadius="50px"
                  display="flex"
                  alignItems="center"
                  width="44px"
                  style={{
                    backgroundColor: 'white',
                    height: '48px',
                    width: '48px',
                  }}
                >
                  <IconButton
                    className={classes.iconButton}
                    aria-label="search"
                    // onClick={handleModoAberto}
                    style={{ height: '48px', width: '48px' }}
                  >
                    <FilterListIcon />
                  </IconButton>
                </Box>
              </Tooltip>
            </Box>
          )}
          <Box marginLeft="15px">
            <Tooltip title="Baixar Excel" placement="top" aria-label="add2">
              <Box
                boxShadow={2}
                borderRadius="50px"
                display="flex"
                alignItems="center"
                width="44px"
                onClick={saveFile}
                style={{
                  backgroundColor: 'white',
                  height: '48px',
                  width: '48px',
                }}
              >
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  style={{ height: '48px', width: '48px' }}
                >
                  <GridOnIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>
          {/* <div>
            <a
              href={`${
                process.env.REACT_APP_HOST_API
              }/transacoes_excel?${stringify({
                filter: JSON.stringify({
                  tipo: filterValues.tipo,
                  categorias: filterValues.categorias,
                  contas: filterValues.contas,
                  tipos: filterValues.tipos,
                  situacoes: filterValues.situacoes,
                  periodo: filterValues.periodo,
                  periodo2: filterValues.periodo2,
                  search: filterValues.search,
                }),
              })}`}
              download
            >
              Click to download
            </a>
          </div> */}
          <Box marginLeft="15px">
            <Tooltip title="Mais opções" placement="top" aria-label="add2">
              {/* <Fab
                aria-label="add"
                onClick={handleCriar}
                style={{ backgroundColor: 'white', color: '#737477' }}
              >
                <MoreVertIcon />
              </Fab> */}
              <Box
                boxShadow={2}
                borderRadius="50px"
                display="flex"
                alignItems="center"
                width="44px"
                style={{
                  backgroundColor: 'white',
                  height: '48px',
                  width: '48px',
                }}
              >
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  onClick={handleClick2}
                  //onClick={() => editFluxoCaixa()}
                  style={{ height: '48px', width: '48px' }}
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <DialogoFluxoCaixa ref={refDialogoFluxoCaixa} handleClose={() => {}} />
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Zoom}
        transitionDuration={500}
      >
        <StyledMenuItem onClick={() => handleClickItem('Despesa')}>
          <ListItemIcon>
            <TrendingDownIcon style={{ color: '#f44336' }} />
          </ListItemIcon>
          <ListItemText primary="Despesas" />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleClickItem('Receita')}>
          <ListItemIcon>
            <TrendingUpIcon style={{ color: '#4caf50' }} />
          </ListItemIcon>
          <ListItemText primary="Receitas" />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleClickItem('Transferência')}>
          <ListItemIcon>
            <AutorenewIcon style={{ color: '#0039cb' }} />
          </ListItemIcon>
          <ListItemText primary="Transferências" />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleClickItem('Despesa_cartao')}>
          <ListItemIcon>
            <CreditCardIcon style={{ color: '#00796b' }} />
          </ListItemIcon>
          <ListItemText primary="Despesa cartão" />
        </StyledMenuItem>
      </StyledMenu>

      <StyledMenu
        id="customized-menu2"
        anchorEl={anchorEl2}
        keepMounted
        open={Boolean(anchorEl2)}
        onClose={handleClose2}
        TransitionComponent={Zoom}
        transitionDuration={500}
      >
        <StyledMenuItem onClick={() => editFluxoCaixa()}>
          <ListItemIcon>
            <DataUsageIcon style={{ color: '#f44336' }} />
          </ListItemIcon>
          <ListItemText primary="Dados Fluxo de Caixa" />
        </StyledMenuItem>
      </StyledMenu>
    </TopToolbar>
  );
};

export default ListActions;
