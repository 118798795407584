import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Typography } from '@material-ui/core';
import moment from 'moment';

const DataHorarioField = (props) => {
  const record = useRecordContext(props);
  const horario = record
    ? record.record
      ? record.record.created_at
        ? `${moment(new Date(record.record.created_at)).format(
            'DD/MM/YYYY - HH:mm',
          )}`
        : '-'
      : ''
    : '';

  return <Typography variant="subtitle1">{horario}</Typography>;
};

export default DataHorarioField;
