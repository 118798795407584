import React from 'react';
import {
  TextInput,
  DateInput,
  SelectInput,
  ReferenceInput,
  BooleanInput,
} from 'react-admin';

import { Box } from '@material-ui/core';

const Form = () => (
  <Box p="1em">
    <Box display="flex">
      <Box flex={2} mr="0.5em">
        <TextInput source="username" autoFocus label="Username" fullWidth />
      </Box>
      <Box flex={2} mr="0.5em">
        <TextInput source="passwordNew" label="Senha" fullWidth />
      </Box>
    </Box>
    <Box display="flex">
      <Box flex={2} mr="0.5em">
        <ReferenceInput
          source="cargo_id"
          reference="cargos"
          label="Cargo"
          fullWidth
        >
          <SelectInput optionText="nome" />
        </ReferenceInput>
      </Box>
      <Box flex={2} mr="0.5em">
        <DateInput fullWidth source="dataAniversario" label="Aniversário" />
      </Box>
      <Box flex={2} mr="0.5em">
        <BooleanInput
          source="is_responsavel"
          label="Gerente de Caixa"
          fullWidth
        />
      </Box>
    </Box>
  </Box>
);

export default Form;
