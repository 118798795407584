import React from 'react';
import { Logout } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.main,
    height: '50px',
  },
  icon2: {
    color: theme.palette.primary.main,
  },
}));

const LogoutButtonCustom = (props) => {
  const classes = useStyles();
  return (
    <Logout
      {...props}
      className={classes.icon}
      icon={<ExitToAppIcon className={classes.icon2} />}
    />
  );
};

export default LogoutButtonCustom;
