import React from 'react';
import {
  Edit as EditAdmin,
  FormWithRedirect,
  useNotify,
  useRedirect,
} from 'react-admin';

import ContainerActionsBack from '../ContainerActionsBack';
import { EditToolbar } from '../Toolbar';
import Actions from './Actions';

const FormContainer = ({ children, isDelete, ...props }) => (
  <FormWithRedirect
    {...props}
    warnWhenUnsavedChanges
    render={(formProps) => (
      <form onSubmit={formProps.handleSubmit}>
        {children}
        <EditToolbar rota="list" isDelete={isDelete} {...formProps} />
      </form>
    )}
  />
);

const Edit = ({
  children,
  title,
  defaultValue = {},
  transform = (props) => props,
  validate = () => {},
  onFailure = () => ({ message: 'algo deu errado', type: 'warning' }),
  isDelete = true,
  isBack = false,
  routeBack = '',
  ...props
}) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onFailureLocal = (error) => {
    const { message, type } = onFailure(error);
    notify(message, type);
    redirect('list', props.basePath);
  };
  return (
    <EditAdmin
      {...props}
      title={title}
      transform={transform}
      onFailure={onFailureLocal}
      actions={
        <ContainerActionsBack
          actions={<Actions />}
          isBack={isBack}
          routeBack={routeBack}
        />
      }
    >
      <FormContainer
        validate={validate}
        defaultValue={defaultValue}
        isDelete={isDelete}
      >
        {children}
      </FormContainer>
    </EditAdmin>
  );
};

export default Edit;
