import React, { useState, useRef } from 'react';
import { Title } from 'react-admin';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import BarChartIcon from '@material-ui/icons/BarChart';
import { useSnackbar } from 'notistack';

import { api } from '../../../services';
import MobillsInput from './DateInputMobills';
import DialogoGrafico from './DialogoGrafico';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const DashbboardContabilidade = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState({
    linhas: [],
    mes: new Date().getMonth(),
    ano: new Date().getFullYear(),
  });
  const { enqueueSnackbar } = useSnackbar();
  const refDialogo = useRef(null);

  async function handleData({ ano, mes }) {
    setLoading(true);
    try {
      const dados = await api.get(`/relatorio-scores/${ano}/${mes}`);
      setDados({ linhas: dados.data, mes, ano });
      setLoading(false);
    } catch (e) {
      setDados({ linhas: [], mes, ano });
      setLoading(false);
      enqueueSnackbar(`Erro ao carregar os dados do relatório!`, {
        variant: 'error',
      });
    }
  }

  function handleOpenDialogo() {
    if (refDialogo.current) {
      refDialogo.current.handleOpen();
    }
  }

  return (
    <div>
      <div
        style={{
          fontSize: '25px',
          fontWeight: 'bold',
          marginTop: '35px',
          marginBottom: '30px',
        }}
      >
        Relatório Scores
      </div>
      <Title title="Relatório Mensal" />
      <div
        style={{
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div />
        <MobillsInput handleData={handleData} />
        <Tooltip title="Gerar Gráfico" placement="top" aria-label="Imprimir">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={handleOpenDialogo}
          >
            <BarChartIcon />
          </IconButton>
        </Tooltip>
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Dia</StyledTableCell>
              <StyledTableCell>Dia da semana</StyledTableCell>
              <StyledTableCell align="right">SERASA PF</StyledTableCell>
              <StyledTableCell align="right">SERASA PJ</StyledTableCell>
              <StyledTableCell align="right">SPC PF</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dados.linhas.map((row) => (
              <StyledTableRow key={row.dia}>
                <StyledTableCell>{row.dia}</StyledTableCell>
                <StyledTableCell>{row.diaDaSemanaLabel}</StyledTableCell>
                <StyledTableCell align="right">{row.serasa_pf}</StyledTableCell>
                <StyledTableCell align="right">{row.serasa_pj}</StyledTableCell>
                <StyledTableCell align="right">{row.spc_pf}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DialogoGrafico
        ref={refDialogo}
        linhas={dados.linhas}
        days={dados.linhas.length}
        mes={dados.mes}
        ano={dados.ano}
      />
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default DashbboardContabilidade;
