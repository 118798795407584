import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Typography } from '@material-ui/core';

const ProdutoField = (props) => {
  const record = useRecordContext(props);
  const descricao = record
    ? record.record
      ? record.record.responsavel.username
      : '-'
    : '-';

  return <Typography variant="subtitle1">{descricao}</Typography>;
};

export default ProdutoField;
