import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';

import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import { IntegerInput, PesoInput } from '../../../Components/Inputs';
import { createAPI, getListAllAPI, getUserId } from '../../../services';
import {
  getNivelCaixaQtde,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  POR_UNIDADE,
  POR_PESO_FIXO,
} from '../../../utils';

const FECHAR_VENDA_ACTION = 'F8';
const CANCELAR_VENDA_ACTION = 'F4';

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function getQuantidadesTotal(
  produto,
  unidades,
  peso,
  unidadesCaixa1,
  unidadesCaixa2,
  unidadesCaixa3,
) {
  if (produto) {
    if (produto.unidade.tipo === POR_UNIDADE) {
      const unidadesQtde =
        unidades +
        unidadesCaixa1 * produto.unidades_caixa_nivel_1 +
        unidadesCaixa2 *
          produto.unidades_caixa_nivel_1 *
          produto.unidades_caixa_nivel_2 +
        unidadesCaixa3 *
          produto.unidades_caixa_nivel_1 *
          produto.unidades_caixa_nivel_2 *
          produto.unidades_caixa_nivel_3;
      return {
        unidades: unidadesQtde,
        peso: 0,
      };
    }
    if (produto.unidade.tipo === POR_PESO_FIXO) {
      const unidadesQtde =
        unidades +
        unidadesCaixa1 * produto.unidades_caixa_nivel_1 +
        unidadesCaixa2 *
          produto.unidades_caixa_nivel_1 *
          produto.unidades_caixa_nivel_2 +
        unidadesCaixa3 *
          produto.unidades_caixa_nivel_1 *
          produto.unidades_caixa_nivel_2 *
          produto.unidades_caixa_nivel_3;
      return {
        unidades: unidadesQtde,
        peso: unidadesQtde * produto.peso_medio,
      };
    }
    if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
      const pesoQtde =
        peso +
        unidadesCaixa1 * produto.peso_caixa_nivel_1 +
        unidadesCaixa2 *
          produto.peso_caixa_nivel_1 *
          produto.unidades_caixa_nivel_2 +
        unidadesCaixa3 *
          produto.peso_caixa_nivel_1 *
          produto.unidades_caixa_nivel_2 *
          produto.unidades_caixa_nivel_3;
      return {
        unidades: 0,
        peso: pesoQtde,
      };
    }
    return {
      unidades: 0,
      peso: 0,
    };
  }
  return {
    unidades: 0,
    peso: 0,
  };
}

const SidebarInputs = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [id, setId] = useState(-1);
  const [produto, setProduto] = useState(null);
  const [unidades, setUnidades] = useState(0);
  const [peso, setPeso] = useState(0);
  const [unidadesCaixa1, setUnidadesCaixa1] = useState(0);
  const [unidadesCaixa2, setUnidadesCaixa2] = useState(0);
  const [unidadesCaixa3, setUnidadesCaixa3] = useState(0);

  const refs = useRef([]);
  const [carregando, setCarregando] = useState(false);

  const inputs = [
    {
      nome: 'unidades',
      defaultValue: 0,
      label: 'Unidades',
    },
    {
      nome: 'unidades_caixa_1',
      defaultValue: 0,
      label: 'Unidades',
    },
    {
      nome: 'unidades_caixa_2',
      defaultValue: 0,
      label: 'Unidades',
    },
    {
      nome: 'unidades_caixa_3',
      defaultValue: 0,
      label: 'Unidades',
    },
  ];

  refs.current = inputs.map(
    (ref, index) => (refs.current[index] = React.createRef()),
  );

  const isPorPesoVariavel = produto
    ? produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
    : false;

  const nivelCaixa = produto
    ? produto.nivelCaixa
      ? produto.nivelCaixa
      : 0
    : 0;

  function validateUnidadesBasicas() {
    let error = '';
    if (isPorPesoVariavel) {
      if (peso < 0 || Number.isNaN(peso)) {
        error = 'Este campo é obrigatório';
      }
    } else if (unidades < 0 || Number.isNaN(unidades)) {
      error = 'Este campo é obrigatório';
    }

    return error;
  }

  function validateUnidadesCaixa1() {
    let error = '';
    if (nivelCaixa >= 1) {
      if (unidadesCaixa1 < 0 || Number.isNaN(unidadesCaixa1)) {
        error = 'Este campo é obrigatório';
      }
    }
    return error;
  }

  function validateUnidadesCaixa2() {
    let error = '';
    if (nivelCaixa >= 2) {
      if (unidadesCaixa2 < 0 || Number.isNaN(unidadesCaixa2)) {
        error = 'Este campo é obrigatório';
      }
    }
    return error;
  }

  function validateUnidadesCaixa3() {
    let error = '';
    if (nivelCaixa >= 3) {
      if (unidadesCaixa3 < 0 || Number.isNaN(unidadesCaixa3)) {
        error = 'Este campo é obrigatório';
      }
    }
    return error;
  }

  function getErros() {
    const errosOld = ['', '', ''];

    errosOld[0] = validateUnidadesBasicas();
    errosOld[1] = validateUnidadesCaixa1();
    errosOld[2] = validateUnidadesCaixa2();
    errosOld[3] = validateUnidadesCaixa3();

    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  const handleCloseDialog = (status = false) => {
    setOpen(false);
    handleClose(status);
  };

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'produtos',
        ['id', 'asc'],
        { id: [id] },
        ['unidade'],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);

        setPeso(0);
        setUnidades(0);
        setUnidadesCaixa1(0);
        setUnidadesCaixa2(0);
        setUnidadesCaixa3(0);

        setProduto({
          ...data.data[0],
          nivelCaixa: getNivelCaixaQtde(data.data[0]),
        });
        setCarregando(false);
      } else {
        throw 'Este produto não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function handleSalvar() {
    const { unidades: unidades2, peso: peso2 } = getQuantidadesTotal(
      produto,
      unidades,
      peso,
      unidadesCaixa1,
      unidadesCaixa2,
      unidadesCaixa3,
    );

    if (!(unidades === 0 && peso === 0)) {
      if (!erroExistente) {
        setCarregando(true);
        try {
          await createAPI('avarias', {
            unidades: unidades2,
            peso: peso2,
            produto_id: id,
            data: new Date(),
            user_id: getUserId(),
          });
          setCarregando(false);
          enqueueSnackbar('Avaria criada com sucesso!', {
            variant: 'success',
          });
          handleCloseDialog(true);
        } catch (erros) {
          setCarregando(false);
          enqueueSnackbar(`${erros}`, {
            variant: 'error',
          });
          handleCloseDialog();
        }
      } else {
        enqueueSnackbar('Existem erros no formulário!', {
          variant: 'error',
        });
      }
    } else {
      enqueueSnackbar('A avaria não pode ser nula!', {
        variant: 'error',
      });
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setOpen(true);
      setCarregando(true);
      setId(id);
      getDataResource(id);
    },
  }));

  function handleActions(action) {
    switch (action) {
      case FECHAR_VENDA_ACTION:
        handleSalvar();
        break;
      case CANCELAR_VENDA_ACTION:
        handleCloseDialog();
        break;
      default:
        break;
    }
  }

  function getRefNextInput(index) {
    let position = -1;
    switch (index) {
      case 0:
        position = 1;
        break;
      default:
        break;
    }
    return position;
  }

  function handleNextInput(index) {
    const position = getRefNextInput(index);
    if (position === -1) {
      refs.current[0].current.focus();
      refs.current[0].current.select();
    } else if (refs.current[position].current) {
      refs.current[position].current.focus();
      refs.current[position].current.select();
    }
  }

  function handleKey(keyCode, keyName) {
    handleActions(keyName);
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleCloseDialog()}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen
    >
      <DialogTitle id="form-dialog-title">Nova Avaria</DialogTitle>
      <DialogContent dividers>
        <div style={{ height: '100%' }}>
          <Box
            display={{ xs: 'block', sm: 'flex' }}
            flexDirection="column"
            marginBottom="30px"
            height="100%"
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '300px',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Box p="1em">
                <Typography variant="h6" gutterBottom>
                  {isPorPesoVariavel ? 'Peso' : 'Unidades'}
                </Typography>
                <Box display="flex" mb="30px">
                  <Box flex={1} display="flex">
                    {!isPorPesoVariavel ? (
                      <IntegerInput
                        name={inputs[0].nome}
                        ref={refs.current[0]}
                        handleEnter={() => handleNextInput(0)}
                        label="Unidades"
                        handleKey={handleKey}
                        value={Number.isNaN(unidades) ? '' : String(unidades)}
                        onChange={(value) =>
                          setUnidades(parseInt(value.target.value, 10))
                        }
                        error={erros[0] !== ''}
                        helperText={erros[0]}
                        fullWidth
                      />
                    ) : (
                      <PesoInput
                        name={inputs[0].nome}
                        ref={refs.current[0]}
                        handleEnter={() => handleNextInput(0)}
                        label="Peso"
                        handleKey={handleKey}
                        value={Number.isNaN(peso) ? '' : String(peso)}
                        onChange={(value) =>
                          setPeso(parseFloat(value.target.value))
                        }
                        error={erros[0] !== ''}
                        helperText={erros[0]}
                        fullWidth
                      />
                    )}
                  </Box>
                </Box>
                {nivelCaixa >= 1 && (
                  <>
                    <Typography variant="h6" gutterBottom>
                      Caixa (Nível 1)
                    </Typography>
                    <Box display="flex" mb="30px">
                      <Box flex={1} display="flex">
                        <IntegerInput
                          name={inputs[1].nome}
                          ref={refs.current[1]}
                          handleEnter={() => handleNextInput(1)}
                          label={inputs[1].label}
                          handleKey={handleKey}
                          value={
                            Number.isNaN(unidadesCaixa1)
                              ? ''
                              : String(unidadesCaixa1)
                          }
                          onChange={(value) =>
                            setUnidadesCaixa1(parseInt(value.target.value, 10))
                          }
                          error={erros[1] !== ''}
                          helperText={erros[1]}
                          fullWidth
                        />
                      </Box>
                    </Box>
                  </>
                )}
                {nivelCaixa >= 2 && (
                  <>
                    <Typography variant="h6" gutterBottom>
                      Caixa (Nível 2)
                    </Typography>
                    <Box display="flex" mb="30px">
                      <Box flex={1} display="flex">
                        <IntegerInput
                          name={inputs[2].nome}
                          ref={refs.current[2]}
                          handleEnter={() => handleNextInput(2)}
                          label={inputs[2].label}
                          handleKey={handleKey}
                          value={
                            Number.isNaN(unidadesCaixa2)
                              ? ''
                              : String(unidadesCaixa2)
                          }
                          onChange={(value) =>
                            setUnidadesCaixa2(parseInt(value.target.value, 10))
                          }
                          error={erros[2] !== ''}
                          helperText={erros[2]}
                          fullWidth
                        />
                      </Box>
                    </Box>
                  </>
                )}
                {nivelCaixa >= 3 && (
                  <>
                    <Typography variant="h6" gutterBottom>
                      Caixa (Nível 3)
                    </Typography>
                    <Box display="flex" mb="30px">
                      <Box flex={1} display="flex">
                        <IntegerInput
                          name={inputs[3].nome}
                          ref={refs.current[3]}
                          handleEnter={() => handleNextInput(3)}
                          label={inputs[3].label}
                          handleKey={handleKey}
                          value={
                            Number.isNaN(unidadesCaixa3)
                              ? ''
                              : String(unidadesCaixa3)
                          }
                          onChange={(value) =>
                            setUnidadesCaixa3(parseInt(value.target.value, 10))
                          }
                          error={erros[3] !== ''}
                          helperText={erros[3]}
                          fullWidth
                        />
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            )}
          </Box>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCloseDialog()} color="primary">
          Cancelar (F4)
        </Button>
        <div className={classes.wrapper}>
          <Button
            color="primary"
            onClick={() => {
              handleSalvar();
            }}
            disabled={erroExistente || carregando}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
          >
            Cadastrar (F8)
          </Button>
          {carregando && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
});

export default SidebarInputs;
