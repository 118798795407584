const POR_ANO = 'Anual';
const POR_MES = 'Mensal';
const POR_SEMANA = 'Semanal';
const POR_DIA = 'Diario';

export const choicesList = [
  { id: null, name: 'Nenhuma' },
  { id: POR_ANO, name: 'Anual' },
  { id: POR_MES, name: 'Mensal' },
  { id: POR_SEMANA, name: 'Semanal' },
  { id: POR_DIA, name: 'Diario' },
];

export const choicesEdit = [
  { id: POR_ANO, name: 'Anual' },
  { id: POR_MES, name: 'Mensal' },
  { id: POR_SEMANA, name: 'Semanal' },
  { id: POR_DIA, name: 'Diario' },
];

export const choicesSituacao = [
  { id: true, name: 'Pago' },
  { id: false, name: 'Pendente' },
];

export const choicesTiposEdicao = [
  { id: 0, name: 'Editar somente esta' },
  { id: 1, name: 'Editar esta, e as futuras' },
  { id: 2, name: 'Editar todas (incluindo recebidas)' },
];
