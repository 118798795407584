import React, { useState, forwardRef, useImperativeHandle } from 'react';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import NoteIcon from '@material-ui/icons/Note';
import StayCurrentPortraitIcon from '@material-ui/icons/StayCurrentPortrait';
import TodayIcon from '@material-ui/icons/Today';
import { useSnackbar } from 'notistack';

import {
  PrecoInput,
  DateInputBonito,
  TextInput,
  SelectInput,
} from '../../../Components';
import { getListAllAPI, createAPI, updateAPI } from '../../../services';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ESPACO_INPUTS = '24px';
const TAMANHO_PEQUENO = 'sm';

const DEFAULT_DESCRICAO = '';
const DEFAULT_VALOR = 0;
const DEFAULT_DATA_PAGAMENTO = new Date();
const DEFAULT_ID = -1;
const DEFAULT_TAMANHO = TAMANHO_PEQUENO;

const options = [
  {
    label: 'Despesa',
    value: -1,
  },
  {
    label: 'Receita',
    value: 1,
  },
];

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [descricao, setDescricao] = useState(DEFAULT_DESCRICAO);
  const [valor, setValor] = useState(DEFAULT_VALOR);
  const [dataPagamento, setDataPagamento] = useState(DEFAULT_DATA_PAGAMENTO);
  const [id, setId] = useState(DEFAULT_ID);
  const [tamanho, setTamanho] = useState(DEFAULT_TAMANHO);
  const classes = useStyles();
  const [carregando, setCarregando] = useState(false);
  const [tipo, setTipo] = useState(-1);

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  function reiniciar() {
    try {
      setDescricao(DEFAULT_DESCRICAO);
      setValor(DEFAULT_VALOR);
      setDataPagamento(DEFAULT_DATA_PAGAMENTO);
      setId(DEFAULT_ID);
      setCarregando(false);
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'transacoes_simuladas',
        ['id', 'asc'],
        { id: [id] },
        [],
      );

      if (data.data.length > 0) {
        setDescricao(data.data[0].nome);
        setValor(Math.abs(data.data[0].valor));
        setTipo(data.data[0].valor > 0 ? 1 : -1);
        setDataPagamento(data.data[0].data_pagamento);
        setCarregando(false);
      } else {
        throw 'Esta transação simulada não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    try {
      if (id <= 0) {
        await createAPI('transacoes_simuladas', {
          nome: descricao,
          valor: tipo === 1 ? valor : valor * -1,
          data_pagamento: dataPagamento,
        });
        setCarregando(false);
        enqueueSnackbar(`Transação simulada criada com sucesso!`, {
          variant: 'success',
        });
      } else {
        await updateAPI('transacoes_simuladas', id, {
          nome: descricao,
          valor: tipo === 1 ? valor : valor * -1,
          data_pagamento: dataPagamento,
        });
        setCarregando(false);
        enqueueSnackbar(`Transação simulada atualizada com sucesso!`, {
          variant: 'success',
        });
      }
      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      setCarregando(true);
      reiniciar();
      setOpen(true);
    },
    handleEdit(id) {
      setCarregando(true);
      getDataResource(id);
      setOpen(true);
      setId(id);
    },
  }));

  const errorValor =
    valor <= 0 || Number.isNaN(valor) ? 'Deve ter um valor maior que 0' : '';
  const errorDescricao =
    descricao === '' || descricao === null ? 'Este campo é obrigatório' : '';

  function isDisabledSubmit() {
    return errorValor !== '' || errorDescricao !== '';
  }

  function getColor(tipo) {
    switch (tipo) {
      case 1:
        return '#4caf50';
      case -1:
        return '#f44336';
      default:
        return '#009FD4';
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth={tamanho}
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: getColor(tipo), color: 'white' }}
        >
          {id <= 0 ? `Nova Transação simulada` : `Editar Transação simulada`}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <PrecoInput
                  name="valor"
                  handleEnter={() => {}}
                  label=""
                  handleKey={() => {}}
                  value={Number.isNaN(valor) ? '' : String(valor)}
                  onChange={(value) => {
                    const newValue = parseFloat(value.target.value);
                    if (newValue <= 0 || Number.isNaN(newValue)) {
                      setValor(0);
                    } else {
                      setValor(newValue);
                    }
                  }}
                  error={errorValor !== ''}
                  helperText={errorValor}
                  style={{ marginBottom: ESPACO_INPUTS }}
                  fullWidth
                  variant="standard"
                  autoFocus
                  InputProps={{
                    startAdornment: (
                      <>
                        <StayCurrentPortraitIcon
                          style={{ marginRight: '24px' }}
                        />
                      </>
                    ),
                  }}
                />

                <DateInputBonito
                  showTodayButton
                  disablePast
                  todayLabel="Hoje"
                  name="data_pagamento"
                  handleEnter={() => {}}
                  style={{ marginBottom: ESPACO_INPUTS, marginTop: '0px' }}
                  label=""
                  handleKey={() => {}}
                  value={dataPagamento}
                  onChange={(value) => {
                    setDataPagamento(value);
                  }}
                  format="dd MMMM yyyy"
                  fullWidth
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <>
                        <TodayIcon style={{ marginRight: '24px' }} />
                      </>
                    ),
                  }}
                />

                <TextInput
                  name="descricao"
                  handleEnter={() => {}}
                  label=""
                  handleKey={() => {}}
                  value={descricao}
                  onChange={(value) => setDescricao(value.target.value)}
                  error={errorDescricao !== ''}
                  helperText={errorDescricao}
                  style={{ marginBottom: ESPACO_INPUTS, marginTop: '0px' }}
                  fullWidth
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <>
                        <NoteIcon style={{ marginRight: '24px' }} />
                      </>
                    ),
                  }}
                />

                <SelectInput
                  name="tipo_periodo"
                  handleEnter={() => {}}
                  label="lalalala"
                  handleKey={() => {}}
                  value={tipo}
                  onChange={(value) => setTipo(value.target.value)}
                  options={options}
                  fullWidth
                  variant="standard"
                />
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          <Box>
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                disabled={isDisabledSubmit() || carregando}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
              {carregando && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
