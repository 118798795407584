import React from 'react';

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import moment from 'moment';

import logo from '../../../../../assets/logo.png';

const styles = StyleSheet.create({
  page: {},
  body: {
    width: '100%',
    fontSize: '7',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  header2: {
    display: 'flex',
    backgroundColor: '#E4E4E4',
    color: 'black',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10',
    fontWeight: 'bold',
    borderBottom: '1 solid black',
    padding: '2px 0',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid black',
  },
  contentTotais: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid black',
    backgroundColor: '#ffff00',
  },
  contentTotaisTotais: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid black',
    backgroundColor: '#f96c25',
  },
  item: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  item3: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  negativo: {
    backgroundColor: '#ffc7cf',
  },
  positivo: {
    backgroundColor: '#cbf5d0',
  },
});

const ListaProdutos = ({ dados, periodo }) => (
  <Document
    author="anderson"
    title={`CARUARU FRIOS - RELATÓRIO CADASTRO CLIENTES - ${periodo.mes + 1}/${
      periodo.ano
    }`}
  >
    <Page size="A4" style={styles.page} wrap>
      <View style={styles.body}>
        <View
          style={[
            styles.title,
            { margin: '10 0', fontWeight: 'bold', fontSize: '15' },
          ]}
        >
          <Image src={logo} style={{ width: '30%' }} />
          <Text style={{ marginTop: '15px' }}>{`RELATÓRIO CADASTRO CLIENTES - ${
            periodo.mes + 1 < 10 ? `0${periodo.mes + 1}` : periodo.mes + 1
          }/${periodo.ano}`}</Text>
        </View>

        {dados.map((cat) => (
          <>
            <View style={styles.header2}>
              <Text>{cat.username}</Text>
            </View>
            {cat.clientes.length > 0 && (
              <View style={styles.title}>
                <View style={styles.container}>
                  <View style={styles.header}>
                    <View style={[styles.item, { flex: 2 }]}>
                      <Text>ID</Text>
                    </View>
                    <View style={[styles.item, { flex: 2 }]}>
                      <Text>Cliente</Text>
                    </View>
                    <View style={[styles.item, { flex: 2 }]}>
                      <Text>Nome Fantasia</Text>
                    </View>
                    <View style={[styles.item, { flex: 2 }]}>
                      <Text>Razão Social</Text>
                    </View>
                    <View style={[styles.item, { flex: 2 }]}>
                      <Text>Cidade</Text>
                    </View>
                    <View style={[styles.item, { flex: 2 }]}>
                      <Text>Bairro</Text>
                    </View>
                    <View style={[styles.item, { flex: 2 }]}>
                      <Text>Hora Cadas.</Text>
                    </View>
                  </View>
                  {cat.clientes.map((item) => (
                    <View style={styles.content}>
                      <View style={[styles.item3, { flex: 2 }]}>
                        <Text>{item.id}</Text>
                      </View>
                      <View style={[styles.item, { flex: 2 }]}>
                        <Text>{item.nome}</Text>
                      </View>
                      <View style={[styles.item, { flex: 2 }]}>
                        <Text>{item.nome_fantasia}</Text>
                      </View>
                      <View style={[styles.item, { flex: 2 }]}>
                        <Text>{item.cidade ? item.cidade.nome : ''}</Text>
                      </View>
                      <View style={[styles.item, { flex: 2 }]}>
                        <Text>{item.bairro}</Text>
                      </View>
                      <View style={[styles.item, { flex: 2 }]}>
                        <Text>{item.categoria ? item.categoria.nome : ''}</Text>
                      </View>
                      <View style={[styles.item, { flex: 2 }]}>
                        <Text>
                          {moment(item.created_at).format('DD/MM/YYYY HH:mm')}
                        </Text>
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            )}
          </>
        ))}
      </View>
    </Page>
  </Document>
);
export default ListaProdutos;
