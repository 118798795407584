import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

const useStyles = makeStyles((theme) => ({
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
    cursor: 'pointer',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
    cursor: 'pointer',
  },
  cartao: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#00796b',
    marginLeft: '10px',
  },
}));

const StatusField = ({ handleEfetivar, ...props }) => {
  const classes = useStyles();
  const { source } = props;
  const record = useRecordContext(props);
  const value = record ? record.record[source] : false;
  const isCartao = record ? record.record.fatura_id : false;

  const statusValue = record ? record.record.status : false;

  const fatura_id = record ? record.record.fatura_id : -1;

  const tipo = record ? record.record.tipo : '';

  const statusPagar = tipo !== 'Transferência' && !statusValue && !fatura_id;

  function getTipoLabel(tipo) {
    switch (tipo) {
      case 'Receita':
        return 'Receita';

      case 'Despesa':
        return 'Despesa';

      default:
        return '';
    }
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      {value ? (
        statusPagar ? (
          <Tooltip
            title={`${getTipoLabel(tipo)} Paga, deseja desfazer o pagamento?`}
            placement="bottom"
            aria-label="add2"
          >
            <Avatar className={classes.confirmado}>
              <CheckIcon style={{ fontSize: 15 }} />
            </Avatar>
          </Tooltip>
        ) : (
          <Avatar className={classes.confirmado}>
            <CheckIcon style={{ fontSize: 15 }} />
          </Avatar>
        )
      ) : statusPagar ? (
        <Tooltip
          title={`${getTipoLabel(tipo)} não Paga, deseja Pagar?`}
          placement="bottom"
          aria-label="add2"
        >
          <Avatar
            className={classes.pendente}
            onClick={() => handleEfetivar(record)}
          >
            <PriorityHighIcon style={{ fontSize: 15 }} />
          </Avatar>
        </Tooltip>
      ) : (
        <Avatar className={classes.pendente}>
          <PriorityHighIcon style={{ fontSize: 15 }} />
        </Avatar>
      )}
      {isCartao && (
        <Tooltip title="Despesa de cartão" placement="bottom" aria-label="add2">
          <Avatar className={classes.cartao}>
            <CreditCardIcon style={{ fontSize: 15 }} />
          </Avatar>
        </Tooltip>
      )}
    </div>
  );
};



export default StatusField;
