import React, { useState, useCallback, useEffect } from 'react';

import { Button, Box, Typography, makeStyles } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import moment from 'moment';

import useDebounce from '../../../hooks/useDebounce';

const useStyles = makeStyles({
  btn: {
    borderRadius: '50px',
    color: (props) => props.color,
    borderColor: (props) => props.color,
    '&:hover': {
      backgroundColor: (props) => props.color,
      color: 'white',
    },
  },
});

const DateMobillsInput = ({ color = '#59c3fa', handleData = () => {} }) => {
  const [data, setData] = useState(new Date());
  const classes = useStyles({ color });
  const [dataDebounce] = useDebounce(data, 1000);

  useEffect(() => {
    handleData(dataDebounce);
  }, [dataDebounce]);

  const handleBefore = () => {
    setData(new Date(moment(data).subtract(1, 'day').format()));
  };

  const handleNext = () => {
    setData(new Date(moment(data).add(1, 'day').format()));
  };

  function getLabel() {
    return moment(data).format('DD/MM/YYYY');
  }

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="center">
        <IconButton
          aria-label="delete"
          onClick={handleBefore}
          style={{ color }}
        >
          <NavigateBeforeIcon />
        </IconButton>
        <Box marginLeft="10px" marginRight="10px">
          <Button
            variant="outlined"
            className={classes.btn}
            style={{
              width: '160px',
            }}
          >
            <span>{getLabel()}</span>
          </Button>
        </Box>
        <IconButton aria-label="delete" onClick={handleNext} style={{ color }}>
          <NavigateNextIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default DateMobillsInput;
