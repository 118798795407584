import React from 'react';

import { Paper, Box, makeStyles } from '@material-ui/core';

import {
  formatPeso,
  getEstoqueLabel,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
} from '../../../../../utils';

const useStyles = makeStyles((theme) => ({
  containerOpaco: {
    padding: '10px',
    backgroundColor: theme.palette.secondary.main,
    /* height: '120px', */
    marginTop: '0px',
    color: 'white',
    boxSizing: 'border-box',
    opacity: '0.75',
  },
  containerInterno: {
    height: '100%',
    marginTop: '10px',
    /* fontSize: '35px', */
  },
}));

function getLabelEstoque(produto) {
  if (produto) {
    if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
      if (produto.nivelCaixa) {
        return `${produto.itemVenda.unidadesCaixaFinal}`;
      }
      return `${formatPeso(produto.itemVenda.pesoFinal)}`;
    }

    if (produto.nivelCaixa) {
      return `${produto.itemVenda.unidadesCaixaFinal}`;
    }
    return `${produto.itemVenda.unidadesFinal}`;
  }
  return '-';
}

function getUnidadesCaixa(produto) {
  if (produto) {
    if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
      if (produto.nivelCaixa) {
        return `Cx(${formatPeso(produto.itemVenda.pesoCaixa)})`;
      }
      return 'Kg';
    }

    if (produto.nivelCaixa) {
      return `Cx(${produto.itemVenda.unidadesCaixa} Un)`;
    }
    return 'Un';
  }
  return '-';
}

const LabelEstoque = ({ produto }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.containerOpaco} elevation={3}>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        color="white"
      >
        <div
          style={{ textAlign: 'center' }}
        >{`Qtde Disponível: ${getUnidadesCaixa(produto)}`}</div>

        <Paper
          className={classes.containerInterno}
          elevation={3}
          style={{
            fontSize: '30px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            padding: '5px',
          }}
        >
          {getLabelEstoque(produto)}
        </Paper>
      </Box>
    </Paper>
  );
};

export default LabelEstoque;
