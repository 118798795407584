import React, { useRef } from 'react';
import {
  TextField,
  ListBase,
  ListToolbar,
  Pagination,
  useRefresh,
  Title,
  Datagrid,
} from 'react-admin';
import { useHistory } from 'react-router-dom';

import { Card, makeStyles } from '@material-ui/core';
import { stringify } from 'query-string';

import DialogoCreateCotacao from '../Cotacoes/DialogoCreate';
import Actions from './ActionsList';
import Dialogo from './DialogoCreateEdit';
//import DialogoDeleteResource from './DialogoDeletar';
import ActionsField from './Fields/ActionsField';

const useStyles = makeStyles((theme) => ({
  statusHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  statusCell: {
    // marginLeft: '30px',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    // paddingLeft: '30px',
  },
  statusContent: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 5,
    marginLeft: '30px',
    maxWidth: '100px',
  },
  dataHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoCell: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaCell: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
}));

const MyList = ({ ...props }) => {
  const refDialogo = useRef(null);
  const refDialogoCreateCotacao = useRef(null);
  //const refDialogoDeleteResource = useRef(null);
  const refresh = useRefresh();
  const classes = useStyles();
  const history = useHistory();

  function handleCriar() {
    if (refDialogo.current) {
      refDialogo.current.handleCreate();
    }
  }

  function handleEdit({ id }) {
    if (refDialogo.current) {
      refDialogo.current.handleEdit(id);
    }
  }

  function handleCreateCotacao({ id }) {
    if (refDialogoCreateCotacao.current) {
      refDialogoCreateCotacao.current.handleOpen(id);
    }
  }

  // function handleCotacoes({ id }) {
  //   const queryOj = {};

  //   queryOj.displayedFilters = JSON.stringify({});
  //   queryOj.filter = JSON.stringify({
  //     cotacao_template_id: id,
  //     periodo: {
  //       start: new Date(),
  //       end: new Date(),
  //     },
  //   });
  //   history.push(
  //     `/cotacoes?${stringify(queryOj)}&order=DESC&page=1&perPage=50&sort=data`,
  //   );
  // }

  function handleCotacoes({ id }) {
    history.push(`/cotacoes?cotacao_template_id=${id}`);
  }

  // function deleteHundle({ id }) {
  //   if (refDialogoDeleteResource.current)
  //     refDialogoDeleteResource.current.handleOpen(id);
  // }

  const handleClose = () => {
    refresh();
  };

  return (
    <>
      <Title title="Cotações Templates" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions handleCriar={handleCriar} {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid {...props}>
          <TextField
            source="nome"
            label="Nome"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <ActionsField
            source="valor"
            textAlign="right"
            label="Ações"
            sortable={false}
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            //handleDelete={deleteHundle}
            handleListCotacoes={handleCotacoes}
            handleEdit={handleEdit}
            handleCotacao={handleCreateCotacao}
            ativo
          />
        </Datagrid>
        <TransacoesPagination />
      </Card>
      <Dialogo ref={refDialogo} handleClose={handleClose} />
      <DialogoCreateCotacao
        ref={refDialogoCreateCotacao}
        handleClose={handleClose}
      />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      title="Cotações"
      component="div"
    />
  </>
);

export default List2;
