import React, { useState, forwardRef, useImperativeHandle } from 'react';

import { Box } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import { getListAllAPI, api } from '../../../services';
import { formatMoeda } from '../../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState(-1);
  const [value, setValue] = React.useState(0);
  const [repetida, setRepetida] = React.useState(false);
  const [descricao, setDescricao] = useState('');
  const [sufixo, setSufixo] = useState('');
  const [valor, setValor] = useState(0);
  const [cartao, setCartao] = useState(null);
  const [carregando, setCarregando] = useState(false);

  const classes = useStyles();

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  const handleChange = (event) => {
    setValue(parseInt(event.target.value, 10));
  };

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'despesas_cartao_credito',
        ['id', 'asc'],
        { id: [id] },
        ['fatura.cartao'],
      );
      if (data.data.length > 0) {
        setDescricao(data.data[0].nome);
        setCartao(data.data[0].fatura.cartao);
        setValor(data.data[0].valor);
        setRepetida(!!data.data[0].grupo_id);
        setValue(0);
        setSufixo(
          data.data[0].grupo_id
            ? `(${data.data[0].id_repeticao}/${data.data[0].total_repeticoes})`
            : '',
        );
        setCarregando(false);
      } else {
        throw 'Esta transação não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function handleSalvar() {
    try {
      if (id <= 0) {
        enqueueSnackbar('Nenhuma transação aqui!!!', {
          variant: 'error',
        });
      } else {
        await api.delete(
          `/despesas_cartao_credito/${id}?modoEdicao=${repetida ? value : 0}`,
        );
        setCarregando(false);
        enqueueSnackbar('Despesa de cartão excluída com sucesso!', {
          variant: 'success',
        });
      }
      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setCarregando(true);
      getDataResource(id);
      setOpen(true);
      setId(id);
    },
  }));

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth="md"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: '', color: 'black' }}
        >
          Deletar despesa de cartão
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <div style={{ display: 'flex', padding: '20px 0px' }}>
                <div style={{ flex: 1 }}>
                  <div
                    style={{
                      marginBottom: '5px',
                      color: 'rgba(0, 0, 0, 0.54)',
                      fontWeight: '500',
                    }}
                  >
                    Cartão
                  </div>
                  {cartao && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        style={{
                          backgroundColor: cartao.color,
                          marginRight: '10px',
                          width: '30px',
                          height: '30px',
                        }}
                      >
                        {cartao.nome.charAt(0)}
                      </Avatar>
                      {cartao.nome}
                    </div>
                  )}
                </div>
                <div style={{ flex: 1 }}>
                  <div
                    style={{
                      marginBottom: '5px',
                      color: 'rgba(0, 0, 0, 0.54)',
                      fontWeight: '500',
                    }}
                  >
                    Descrição
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {descricao}
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <div
                    style={{
                      marginBottom: '5px',
                      color: 'rgba(0, 0, 0, 0.54)',
                      fontWeight: '500',
                    }}
                  >
                    Valor
                  </div>
                  <div style={{}}>{formatMoeda(valor)}</div>
                </div>
              </div>
              {repetida && (
                <>
                  <div
                    style={{
                      margin: '8px 0px',
                      color: 'rgba(0, 0, 0, 0.87)',
                      fontSize: '20px',
                      fontWeight: 'bold',
                    }}
                  >
                    <div
                      style={{ marginBottom: '5px' }}
                    >{`Atenção esta é uma despesa do cartão parcelada ${sufixo}.`}</div>
                    <div>Você deseja deletar...</div>
                  </div>
                  <FormControl
                    component="fieldset"
                    style={{ marginBottom: '9px' }}
                  >
                    <RadioGroup
                      aria-label="gender"
                      name="gender1"
                      value={value}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label="Somente esta"
                      />
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="Esta, e as futuras"
                      />
                      <FormControlLabel
                        value={2}
                        control={<Radio />}
                        label="Todas as despesas, incluindo as passadas"
                      />
                    </RadioGroup>
                  </FormControl>
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '32px 36px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={handleCloseDialog}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
            variant="outlined"
            color="primary"
          >
            Cancelar
          </Button>
          <Box>
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                variant="contained"
                color="primary"
                disabled={carregando}
              >
                Deletar
              </Button>
              {carregando && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
