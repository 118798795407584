import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import {
  formatCNPJ,
  EVENTO_MANIFESTACAO_NFE_CIENCIA_DA_OPERACAO,
  EVENTO_MANIFESTACAO_NFE_CONFIRMACAO_DA_OPERACAO,
  EVENTO_MANIFESTACAO_NFE_DESCONHECIMENTO_DA_OPERACAO,
  EVENTO_MANIFESTACAO_NFE_REGISTRO_DA_OPERACAO_NÃO_FINALIZADA,
} from '../../../utils';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    color: 'white',
    backgroundColor: '#f44336',
  },
}));

const labels = {
  [EVENTO_MANIFESTACAO_NFE_CIENCIA_DA_OPERACAO]: 'Ciência da operação',
  [EVENTO_MANIFESTACAO_NFE_CONFIRMACAO_DA_OPERACAO]: 'Confirmação da Operação',
  [EVENTO_MANIFESTACAO_NFE_DESCONHECIMENTO_DA_OPERACAO]:
    'Desconhecimento da Operação',
  [EVENTO_MANIFESTACAO_NFE_REGISTRO_DA_OPERACAO_NÃO_FINALIZADA]:
    'Operação não Realizada',
};

const CnpjField = (props) => {
  const classes = useStyles();
  const record = useRecordContext(props);
  const value = record
    ? record.record
      ? labels[record.record.tipo_manifestacao]
      : ''
    : '';

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title={value} placement="bottom" aria-label="add2">
        <Typography className={classes.text}>{value}</Typography>
      </Tooltip>
    </div>
  );
};

export default CnpjField;
