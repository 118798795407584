import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
} from 'react';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { api } from '../../../services';
import Relatorio from './relatorio';

const itens = [
  {
    produto: 'Linquiça asasasasasasasas',
    codigo: '000001',
    preco: 'R$: 123,00',
  },
];

for (let i = 0; i < 150; i++) {
  itens.push(itens[0]);
}

const DialogoTrocoInicial = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [dados, setDados] = useState(null);

  const handleClose = () => {
    setOpen(false);
    setDados(null);
  };

  async function getDados() {
    const response = await api.get('/inventario_produtos_vendidos_no_kg');
    const itens = response.data;
    itens.sort((a, b) => {
      if (a.produto.trim().toLowerCase() > b.produto.trim().toLowerCase()) {
        return 1;
      }
      if (a.produto.trim().toLowerCase() < b.produto.trim().toLowerCase()) {
        return -1;
      }
      return 0;
    });
    setDados({ itens });
  }

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
      getDados();
    },
  }));

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        scroll="paper"
        fullScreen
      >
        <DialogTitle id="form-dialog-title">
          INVENTÁRIO DE PRODUTOS VENDIDOS NO KG
        </DialogTitle>
        <DialogContent dividers style={{ padding: 0, height: '100%' }}>
          <div style={{ height: '100%' }}>
            <Box
              display={{ xs: 'block', sm: 'flex' }}
              flexDirection="column"
              marginBottom="0px"
              height="100%"
            >
              <Relatorio open={open} dados={dados} />
            </Box>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoTrocoInicial;
