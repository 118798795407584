import React from 'react';
import { ListButton } from 'react-admin';

import CardActions from '@material-ui/core/CardActions';

const Actions = ({ basePath, data }) => (
  <CardActions>
    <ListButton basePath={basePath} />
  </CardActions>
);

export default Actions;
