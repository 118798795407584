import React, { useState, useEffect, useRef } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { TrainRounded } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import PrintIcon from '@material-ui/icons/Print';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

import { AutoCompleteLocal, SelectInput } from '../../../Components';
import useDebounce from '../../../hooks/useDebounce';
import { api, getListAllAPI } from '../../../services';
import {
  POR_UNIDADE,
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  formatPeso,
  STATUS_ATIVO,
} from '../../../utils';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {},
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  pendente: {
    //width: theme.spacing(3),
    //height: theme.spacing(3),
    //color: 'white',
    //backgroundColor: '#f44336',
    color: '#f44336',
    cursor: 'pointer',
    border: '2px solid #f44336',
    borderRadius: '50%',
  },
  confirmado: {
    //width: theme.spacing(3),
    //height: theme.spacing(3),
    //color: 'white',
    //backgroundColor: '#4caf50',
    color: '#4caf50',
    cursor: 'pointer',
    border: '2px solid #4caf50',
    borderRadius: '50%',
  },
  cartao: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#00796b',
    marginLeft: '10px',
  },
}));

function getOptionsDias(nDias) {
  const options = [];
  for (let i = 1; i <= nDias; i++) {
    options.push({
      value: i,
      label: `${i}`,
    });
  }
  return options;
}

const Filters = ({
  handleFilters = () => {},
  handlePrint = () => {},
  handleEfetivar = () => {},
  nDias,
  folha,
}) => {
  const [dia, setDia] = useState(1);
  const [showDia, setShowDia] = useState(false);
  const [diaDebounce] = useDebounce(dia, 1000);
  const [showDiaDebounce] = useDebounce(showDia, 500);

  const [status, setStatus] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [statusDebounce] = useDebounce(status, 1000);
  const [showStatusDebounce] = useDebounce(showStatus, 500);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const iconButtonRef = useRef(null);

  const classes = useStyles();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    function getData() {
      const filters = {};
      if (showDiaDebounce) filters.dia = diaDebounce;

      if (showStatusDebounce) filters.status = statusDebounce;

      handleFilters(filters);
    }
    getData();
  }, [diaDebounce, showDiaDebounce, statusDebounce, showStatusDebounce]);

  const optionsDias = getOptionsDias(nDias);

  const statusCheck = folha ? folha.status : false;

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          {showStatus && (
            <div style={{ marginRight: '20px' }}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => setShowStatus(false)}
              >
                <CloseIcon />
              </IconButton>
              <FormControlLabel
                control={
                  <Switch
                    checked={status}
                    onChange={(e) => setStatus(e.target.checked)}
                    name="checkedA"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    margin="normal"
                  />
                }
                label="Status"
                value="start"
                labelPlacement="top"
                style={{ margin: 0 }}
              />
            </div>
          )}
          {showDia && (
            <div style={{ marginRight: '20px' }}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => setShowDia(false)}
              >
                <CloseIcon />
              </IconButton>
              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">
                  Dia
                </InputLabel>
                <SelectInput
                  variant="standard"
                  labelId="demo-simple-select-label-2"
                  name="tipo"
                  handleEnter={() => {}}
                  label="Dia"
                  handleKey={() => {}}
                  value={dia}
                  onChange={(value) => {
                    setDia(value.target.value);
                  }}
                  options={optionsDias}
                />
              </FormControl>
            </div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            gap: '10px',
          }}
        >
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            ref={iconButtonRef}
            onClick={handleClick}
          >
            <FilterListIcon />
          </IconButton>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={handlePrint}
            disabled={!statusCheck}
          >
            <PrintIcon />
          </IconButton>
          {statusCheck ? (
            <Tooltip
              title="Reabrir Dados desse colaborador para este mês"
              placement="bottom"
              aria-label="add2"
            >
              <IconButton component="span" onClick={() => handleEfetivar()}>
                <CheckIcon className={classes.confirmado} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              title="Fechar Dados desse colaborador para este mês"
              placement="bottom"
              aria-label="add2"
            >
              <IconButton component="span" onClick={() => handleEfetivar()}>
                <PriorityHighIcon className={classes.pendente} />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!showDia && (
          <StyledMenuItem onClick={() => setShowDia(true)}>
            <ListItemText primary="Dia" />
          </StyledMenuItem>
        )}
        {!showStatus && (
          <StyledMenuItem onClick={() => setShowStatus(true)}>
            <ListItemText primary="Status" />
          </StyledMenuItem>
        )}
      </StyledMenu>
    </>
  );
};

export default Filters;
