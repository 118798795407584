import React from 'react';
import { TextField, EditButton, SelectField } from 'react-admin';

import ListTemplate from '../../Components/List';
import choices from './choices';
import Filter from './Filter';

const List = (props) => (
  <ListTemplate
    {...props}
    filters={<Filter />}
    title="Unidades de Produtos"
    filterDefaultValues={{ ativo: true }}
    isDelete
    isButtonAtivo
  >
    <TextField source="nome" label="Descrição" />
    <SelectField
      sortable={false}
      source="tipo"
      label="Gerenciamento"
      choices={choices}
    />
    <EditButton />
  </ListTemplate>
);

export default List;
