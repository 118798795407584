import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  DateField,
  ListContextProvider,
  useListContext,
  ListBase,
  ListToolbar,
  Pagination,
  Datagrid,
  useRefresh,
  Title,
  BulkActionsToolbar,
  TextField,
  useUnselectAll,
  Button as ButtonAdmin,
} from 'react-admin';

import {
  Divider,
  Tabs,
  Tab,
  Card,
  makeStyles,
  Button,
} from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import moment from 'moment';

import { getUserId } from '../../services';
import { stylesMalote } from '../../utils';
import DialogoConfirmaEntradaCofre from '../MaloteTrocos/DialogoConfirmaEntradaCofre';
import DialogoConfirmaSaidaCofre from '../MaloteTrocos/DialogoConfirmaSaidaCofre';
import DialogoShowTroco from '../MaloteTrocos/DialogoShowTroco';
import CodigoField from '../MaloteTrocos/Fields/CodigoField';
import Conta2Field from '../MaloteTrocos/Fields/Conta2Field';
import ContaField from '../MaloteTrocos/Fields/ContaField';
import Frentista2Field from '../MaloteTrocos/Fields/Frentista2Field';
import FrentistaField from '../MaloteTrocos/Fields/FrentistaField';
import ResponsavelField from '../MaloteTrocos/Fields/ResponsavelField';
import Transportador2Field from '../MaloteTrocos/Fields/Transportador2Field';
import TransportadorField from '../MaloteTrocos/Fields/TransportadorField';
import ValorTransferenciaField from '../Transacoes/Fields/ValorTransferenciaField';
import ActionsField from './ActionsField';
import Actions from './ActionsList';
import CheckboxField from './Checkbox';
import DialogoPegarResponsabilidadeTrocos from './DialogoPegarResponsabilidadeTrocos';
import DialogoTransferirResponsabilidadeTrocos from './DialogoTransferirResponsabilidadeTrocos';

const useStylesDatagrid = makeStyles((theme) => stylesMalote);

const tabs = [
  { id: 'ida_ao_cofre', name: 'Ida ao Cofre' },
  { id: 'vinda_ao_checkout', name: 'Vinda ao Checkout' },
];

const CustomResetViewsButton = ({ handleCheck }) => (
  <ButtonAdmin onClick={handleCheck} label="Transferir Responsabilidade">
    <AutorenewIcon />
  </ButtonAdmin>
);
const PostBulkActionButtons = (props) => (
  <>
    <CustomResetViewsButton {...props} />
  </>
);

const MyList = ({ ...props }) => {
  const listContext = useListContext();
  const {
    ids,
    filterValues,
    setFilters,
    displayedFilters,
    onToggleItem,
    selectedIds,
  } = listContext;
  const [confirmados, setConfirmados] = useState([]);
  const [cancelados, setCancelados] = useState([]);
  const refresh = useRefresh();
  const classes = useStylesDatagrid();
  const refDialogoShowTroco = useRef(null);
  const refDialogoTransferirResponsabilidadeTrocos = useRef(null);
  const refDialogoPegarResponsabilidadeTrocos = useRef(null);
  const unselectAll = useUnselectAll();

  const refDialogoConfirmaEntradaCofre = useRef(null);
  const refDialogoConfirmaSaidaCofre = useRef(null);

  const show = (data) => {
    if (refDialogoShowTroco.current) {
      refDialogoShowTroco.current.handleOpen(data.record.id);
    }
  };

  useEffect(() => {
    if (ids && ids !== filterValues.caminho) {
      switch (filterValues.caminho) {
        case true:
          setCancelados(ids);
          break;
        case false:
          setConfirmados(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.caminho]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters(
          { ...filterValues, caminho: value === 'ida_ao_cofre' },
          displayedFilters,
        );
      }
      unselectAll('trocos_transportadores');
    },
    [displayedFilters, filterValues, setFilters],
  );

  function handleClose() {
    unselectAll('trocos_transportadores');
    refresh();
  }

  const changeStatusSelect = (selectedIds) => {
    if (refDialogoTransferirResponsabilidadeTrocos.current) {
      refDialogoTransferirResponsabilidadeTrocos.current.handleOpen(
        selectedIds,
      );
    }
  };

  const changeStatusSelect2 = (selectedIds) => {
    if (refDialogoPegarResponsabilidadeTrocos.current) {
      refDialogoPegarResponsabilidadeTrocos.current.handleOpen();
    }
  };

  const handleConfirmarEntradaCofre = (data) => {
    if (refDialogoConfirmaEntradaCofre.current) {
      refDialogoConfirmaEntradaCofre.current.handleOpen(data.record.id);
    }
  };

  const handleConfirmarSaidaCofre = (data) => {
    if (refDialogoConfirmaSaidaCofre.current) {
      refDialogoConfirmaSaidaCofre.current.handleOpen(data.record.id);
    }
  };

  return (
    <>
      <Title title="Trocos Transportador" />
      <BulkActionsToolbar {...props}>
        <PostBulkActionButtons
          handleCheck={() => changeStatusSelect(selectedIds)}
        />
      </BulkActionsToolbar>
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} />}
        handleCheck={() => changeStatusSelect()}
        handleCheck2={() => changeStatusSelect2()}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Divider />
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.caminho ? tabs[0].id : tabs[1].id}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.caminho === true && (
            <ListContextProvider
              value={{
                ...listContext,
                ids: cancelados,
              }}
            >
              <Datagrid {...props}>
                <CheckboxField
                  source="status"
                  label=""
                  headerClassName={classes.header}
                  cellClassName={classes.cell}
                  selectedIds={selectedIds}
                  handleToggleSelection={(data) => onToggleItem(data.id)}
                  sortable={false}
                />
                <CodigoField
                  source="codigo"
                  label="Código"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <DateField
                  source="data_pagamento"
                  label="Horário (Ida ao Cofre)"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  options={{ hour: 'numeric', minute: 'numeric' }}
                  sortable={false}
                />
                <TextField
                  source="sessao_id"
                  label="Sessão (Ida ao Cofre)"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <ContaField
                  label="Caixa (Ida ao Cofre)"
                  source="conta_id"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <FrentistaField
                  label="Frentista (Ida ao Cofre)"
                  source="responsavel"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <ResponsavelField
                  label="Responsável (Ida ao Cofre)"
                  source="responsavel"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <TransportadorField
                  label="Transportador (Ida ao Cofre)"
                  source="transportador1_id"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                  enableCheckEntrada
                  handleEntradaCofre={handleConfirmarEntradaCofre}
                />
                <ValorTransferenciaField
                  source="valor"
                  textAlign="right"
                  label="Valor"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.valorCell}
                  sortable={false}
                />
                <ActionsField
                  source="lalala"
                  handleShow={show}
                  textAlign="right"
                  label="Ações"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.actionsCell}
                  sortable={false}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.caminho === false && (
            <ListContextProvider value={{ ...listContext, ids: confirmados }}>
              <Datagrid {...props}>
                <CheckboxField
                  source="status"
                  label=""
                  headerClassName={classes.header}
                  cellClassName={classes.cell}
                  selectedIds={selectedIds}
                  handleToggleSelection={(data) => onToggleItem(data.id)}
                  sortable={false}
                />
                <CodigoField
                  source="codigo"
                  label="Código"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <DateField
                  source="data_pagamento"
                  label="Horário (Ida ao Cofre)"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  options={{ hour: 'numeric', minute: 'numeric' }}
                  sortable={false}
                />
                <ContaField
                  label="Caixa (Ida ao Cofre)"
                  source="conta_id"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <FrentistaField
                  label="Frentista (Ida ao Cofre)"
                  source="responsavel"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <ResponsavelField
                  label="Responsável (Ida ao Cofre)"
                  source="responsavel"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <TransportadorField
                  label="Transportador (Ida ao Cofre)"
                  source="transportador1_id"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                  //enableCheckEntrada
                />
                <DateField
                  source="data_pagamento2"
                  label="Horário (Volta ao Checkout)"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  options={{ hour: 'numeric', minute: 'numeric' }}
                  sortable={false}
                />
                <Conta2Field
                  label="Caixa (Volta ao Checkout)"
                  source="conta_id"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <Frentista2Field
                  label="Frentista (Volta ao Checkout)"
                  source="responsavel"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <Transportador2Field
                  label="Transportador (Volta ao Checkout)"
                  source="transportador1_id"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                  enableCheckEntrada
                  handleEntradaCofre={handleConfirmarSaidaCofre}
                />
                <ValorTransferenciaField
                  source="valor"
                  textAlign="right"
                  label="Valor"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.valorCell}
                  sortable={false}
                />
                <ActionsField
                  source="lalala"
                  handleShow={show}
                  textAlign="right"
                  label="Ações"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.actionsCell}
                  sortable={false}
                />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
        <TransacoesPagination />
      </Card>
      <DialogoShowTroco ref={refDialogoShowTroco} />
      <DialogoTransferirResponsabilidadeTrocos
        ref={refDialogoTransferirResponsabilidadeTrocos}
        handleClose={handleClose}
        caminho={filterValues.caminho}
      />
      <DialogoPegarResponsabilidadeTrocos
        ref={refDialogoPegarResponsabilidadeTrocos}
        handleClose={handleClose}
      />
      <DialogoConfirmaEntradaCofre
        ref={refDialogoConfirmaEntradaCofre}
        handleClose={handleClose}
      />
      <DialogoConfirmaSaidaCofre
        ref={refDialogoConfirmaSaidaCofre}
        handleClose={handleClose}
      />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50]} {...props} />
);

const PostPanel = ({ record }) => (
  <div>
    <br />
    {record.data_confirmacao_transporte_1 ? (
      <p>
        <span style={{ fontWeight: 'bold' }}>Horário Entrada no cofre: </span>
        {record.data_confirmacao_transporte_1
          ? moment(new Date(record.data_confirmacao_transporte_1)).format(
              'DD/MM/YYYY - HH:mm',
            )
          : '-'}
      </p>
    ) : null}
    <br />
    {record.data_confirmacao_transporte_2 ? (
      <p>
        <span style={{ fontWeight: 'bold' }}>Horário Saída do cofre: </span>
        {record.data_confirmacao_transporte_2
          ? moment(new Date(record.data_confirmacao_transporte_2)).format(
              'DD/MM/YYYY - HH:mm',
            )
          : '-'}
      </p>
    ) : null}
    <br />
  </div>
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      pagination={<TransacoesPagination />}
      perPage={50}
      title="Trocos Transportador"
      expand={<PostPanel />}
      filterDefaultValues={{
        caminho: true,
      }}
      filter={{ transportador_id: getUserId() }}
    />
  </>
);

export default List2;
