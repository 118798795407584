import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import { useListContext } from 'react-admin';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CloseIcon from '@material-ui/icons/Close';
import Rating from '@material-ui/lab/Rating';
import { useSnackbar } from 'notistack';
import { stringify } from 'query-string';

import { PrecoInput, IntegerInput } from '../../../Components';
import { createAPI, updateAPI, getListAllAPI, api } from '../../../services';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const listContext = useListContext();
  const { filterValues } = listContext;

  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [saldoConta, setSaldoCaixa] = useState(0);
  const [despesasPendentes, setDespesasPendentes] = useState(0);
  const [serasaPf, setSerasaPf] = useState(0);
  const [serasaPj, setSerasaPj] = useState(0);
  const [spcPf, setSpcPf] = useState(0);

  const [carregando, setCarregando] = useState(false);

  const classes = useStyles({ cor: '#009FD4' });

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function getDataResource() {
    try {
      const periodo = new Date(filterValues.periodo.start);

      const { dia, mes, ano } = getDadosData(periodo);

      const response = await api.get(
        `/dados-fluxos-caixas-periodo/${dia}/${mes}/${ano}`,
      );

      const data = response.data;

      setSaldoCaixa(data.saldo_conta);
      setSerasaPf(data.serasa_pf);
      setSerasaPj(data.serasa_pj);
      setSpcPf(data.spc_pf);
      getDespesasPendentes();
      setCarregando(false);
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  function getDadosData(data) {
    return {
      dia: data.getDate(),
      mes: data.getMonth(),
      ano: data.getFullYear(),
    };
  }

  async function getDespesasPendentes() {
    const dados = await api.get(
      `/totais_transacoes?${stringify({
        filter: JSON.stringify({
          tipo: filterValues.tipo,
          categorias: filterValues.categorias,
          contas: filterValues.contas,
          tipos: filterValues.tipos,
          situacoes: filterValues.situacoes,
          periodo: filterValues.periodo,
          periodo2: filterValues.periodo2,
          search: filterValues.search,
          inverso: filterValues.inverso,
        }),
      })}`,
    );

    setDespesasPendentes(dados.data.totalDespesasPendentesPeriodo);
  }

  async function handleSalvar() {
    setCarregando(true);
    try {
      const periodo = new Date(filterValues.periodo.start);

      const { dia, mes, ano } = getDadosData(periodo);

      const periodoNovo = new Date();

      periodoNovo.setFullYear(ano);
      periodoNovo.setDate(10);
      periodoNovo.setMonth(mes);
      periodoNovo.setDate(dia);

      await api.put(`/dados-fluxos-caixas`, {
        data: periodoNovo,
        saldo_conta: saldoConta,
        despesas_pendentes: despesasPendentes,
        fluxo_caixa: saldoConta - despesasPendentes,
        serasa_pf: serasaPf,
        serasa_pj: serasaPj,
        spc_pf: spcPf,
      });
      setCarregando(false);
      enqueueSnackbar('Fluxo de Caixa registrada com sucesso!', {
        variant: 'success',
      });

      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen() {
      getDataResource();
      setOpen(true);
    },
  }));

  function validateSaldo(value) {
    let error = '';
    if (value < 0 || Number.isNaN(value)) {
      error = 'Este campo é obrigatório!';
    }
    return error;
  }

  function validateScore(value) {
    let error = '';
    if (value < 0 || Number.isNaN(value)) {
      error = 'Este campo é obrigatório!';
    } else if (value > 1000) {
      error = 'O score é valor de 0 a 1000!';
    }
    return error;
  }

  function getErros() {
    const errosOld = [''];
    errosOld[0] = validateSaldo(saldoConta);
    errosOld[1] = validateScore(serasaPf);
    errosOld[2] = validateScore(serasaPj);
    errosOld[3] = validateScore(spcPf);
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  const fluxoCaixa = saldoConta - despesasPendentes;

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          Dados Fluxo de Caixa
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  margin: '20px',
                }}
              >
                Especificação do produto
              </Typography>
              <Box display="flex">
                <Box flex={1} mr="0.5em">
                  <PrecoInput
                    name="saldo_contas"
                    handleEnter={() => {}}
                    label="Saldo Contas"
                    handleKey={() => {}}
                    value={Number.isNaN(saldoConta) ? '' : String(saldoConta)}
                    onChange={(value) =>
                      setSaldoCaixa(parseFloat(value.target.value))
                    }
                    error={erros[0] !== ''}
                    helperText={erros[0]}
                    fullWidth
                  />
                </Box>
                <Box flex={1} ml="0.5em" mr="0.5em">
                  <PrecoInput
                    name="despesas_pendentes"
                    handleEnter={() => {}}
                    label="Despesas Pendentes"
                    handleKey={() => {}}
                    value={
                      Number.isNaN(despesasPendentes)
                        ? ''
                        : String(despesasPendentes)
                    }
                    onChange={(value) => {}}
                    disabled
                    fullWidth
                  />
                </Box>
                <Box flex={1} ml="0.5em">
                  <PrecoInput
                    name="fluxo_caixa"
                    handleEnter={() => {}}
                    label="Fluxo Caixa"
                    handleKey={() => {}}
                    value={Number.isNaN(fluxoCaixa) ? '' : String(fluxoCaixa)}
                    onChange={(value) => {}}
                    disabled
                    fullWidth
                  />
                </Box>
              </Box>
              <Box display="flex">
                <Box flex={1} mr="0.5em">
                  <IntegerInput
                    name="serasa_pf"
                    handleEnter={() => {}}
                    label="Serasa PF"
                    handleKey={() => {}}
                    value={Number.isNaN(serasaPf) ? '' : String(serasaPf)}
                    onChange={(value) =>
                      setSerasaPf(parseInt(value.target.value, 10))
                    }
                    error={erros[1] !== ''}
                    helperText={erros[1]}
                    fullWidth
                  />
                </Box>
                <Box flex={1} ml="0.5em" mr="0.5em">
                  <IntegerInput
                    name="serasa_pj"
                    handleEnter={() => {}}
                    label="Serasa PJ"
                    handleKey={() => {}}
                    value={Number.isNaN(serasaPj) ? '' : String(serasaPj)}
                    onChange={(value) =>
                      setSerasaPj(parseInt(value.target.value, 10))
                    }
                    error={erros[2] !== ''}
                    helperText={erros[2]}
                    fullWidth
                  />
                </Box>
                <Box flex={1} ml="0.5em">
                  <IntegerInput
                    name="spc_pf"
                    handleEnter={() => {}}
                    label="SPC PF"
                    handleKey={() => {}}
                    value={Number.isNaN(spcPf) ? '' : String(spcPf)}
                    onChange={(value) =>
                      setSpcPf(parseInt(value.target.value, 10))
                    }
                    error={erros[3] !== ''}
                    helperText={erros[3]}
                    fullWidth
                  />
                </Box>
              </Box>
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={handleCloseDialog}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
            variant="contained"
            color="primary"
          >
            Cancelar
          </Button>
          <div className={classes.wrapper}>
            <Button
              onClick={handleSalvar}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              disabled={erroExistente || carregando}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
            {carregando && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
