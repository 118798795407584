import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';

const StatusField = (props) => {
  const record = useRecordContext(props);
  const horario = record ? new Date(record.record[props.source]) : new Date();
  return (
    <Typography
      variant="subtitle1"
      style={{
        fontWeight: 'bold',
      }}
    >
      {moment(horario).format('HH:mm')}
    </Typography>
  );
};

export default StatusField;
