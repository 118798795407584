import React from 'react';

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import moment from 'moment';

import logo from '../../../../../assets/logo.png';
import {
  formatMoeda,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  POR_UNIDADE,
  getEstoqueLabel,
} from '../../../../../utils';

const styles = StyleSheet.create({
  page: {},
  body: {
    width: '100%',
    fontSize: '7',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  header2: {
    display: 'flex',
    backgroundColor: '#E4E4E4',
    color: 'black',
    /* alignItems: 'center', */
    /* justifyContent: 'center', */
    fontSize: '10',
    fontWeight: 'bold',
    borderBottom: '1 solid black',
    padding: '2px 0',
    paddingLeft: '10px',
  },
  headerGrandao: {
    display: 'flex',
    backgroundColor: '#E4E4E4',
    color: 'black',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '15',
    fontWeight: 'bold',
    borderBottom: '2 solid black',
    borderTop: '2 solid black',
    padding: '2px 0',
    marginTop: '20px',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    // borderBottom: '1 solid black',
  },
  content2: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid black',
    marginTop: '2px',
  },
  contentTotais: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid black',
    backgroundColor: '#ffff00',
  },
  contentTotaisTotais: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid black',
    backgroundColor: '#f96c25',
  },
  item: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  item3: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  item4: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  negativo: {
    backgroundColor: '#ffc7cf',
  },
  positivo: {
    backgroundColor: '#cbf5d0',
  },
  caixinha: {
    border: '1 solid black',
    width: '7px',
    height: '7px',
  },
  precoVendaOferta: {
    color: 'red',
    textDecoration: 'line-through',
    fontSize: '7',
  },
  precoOferta: {
    backgroundColor: 'yellow',
    fontWeight: 'bold',
    fontSize: '8',
  },
});

const ListaProdutos = ({
  dados,
  colaborador,
  data = moment().format('DD/MM/YYYY'),
}) => (
  <Document
    author="anderson"
    title={`CARUARU FRIOS - PRODUTOS DO COLABORADOR ${
      colaborador ? colaborador.nome : ''
    } - ${data}`}
  >
    <Page size="A4" style={styles.page} wrap>
      <View style={styles.body}>
        <View
          style={[
            styles.title,
            { margin: '10 0', fontWeight: 'bold', fontSize: '15' },
          ]}
        >
          <Image src={logo} style={{ width: '30%' }} />
          <Text style={{ marginTop: '15px' }}>{`PRODUTOS DO COLABORADOR ${
            colaborador ? colaborador.nome : ''
          } - ${data}`}</Text>
        </View>

        {dados.map(({ fornecedores, nome }) => (
          <>
            <View style={styles.headerGrandao}>
              <Text>{nome.toUpperCase()}</Text>
            </View>
            {fornecedores.map((cat) => (
              <>
                <View style={styles.header2}>
                  <Text>{cat.nome}</Text>
                </View>
                {cat.produtos.length > 0 && (
                  <View style={styles.title}>
                    <View style={styles.container}>
                      <View style={styles.header}>
                        <View style={[styles.item, { flex: 5 }]}>
                          <Text>Produto</Text>
                        </View>
                        <View style={[styles.item, { flex: 2 }]}>
                          <Text>Unidades</Text>
                        </View>
                        <View style={[styles.item, { flex: 2 }]}>
                          <Text>Peso (Kg)</Text>
                        </View>
                        <View style={[styles.item, { flex: 2 }]}>
                          <Text>Preço Venda</Text>
                        </View>
                      </View>
                      {cat.produtos.map((item) => (
                        <>
                          <View style={styles.content}>
                            <View style={[styles.item3, { flex: 5 }]}>
                              <Text>{item.nome}</Text>
                            </View>
                            <View style={[styles.item, { flex: 2 }]}>
                              <Text>
                                {item.unidade.tipo ===
                                POR_PESO_VARIAVEL_SEM_UNIDADE
                                  ? '-'
                                  : getEstoqueLabel(item)}
                              </Text>
                            </View>
                            <View style={[styles.item, { flex: 2 }]}>
                              <Text>
                                {item.unidade.tipo === POR_UNIDADE
                                  ? '-'
                                  : getEstoqueLabel(item)}
                              </Text>
                            </View>
                            <View style={[styles.item, { flex: 2 }]}>
                              {item.preco_venda > item.preco_oferta ? (
                                <>
                                  <Text style={[styles.precoVendaOferta]}>
                                    {formatMoeda(item.preco_venda)}
                                  </Text>
                                  <Text style={[styles.precoOferta]}>
                                    {formatMoeda(item.preco_oferta)}
                                  </Text>
                                </>
                              ) : (
                                <Text>{formatMoeda(item.preco_venda)}</Text>
                              )}
                            </View>
                          </View>
                          <View style={styles.content2}>
                            <View
                              style={[
                                styles.item4,
                                { flex: 7, fontWeight: 'bold' },
                              ]}
                            >
                              <Text>Rotatividade</Text>
                            </View>
                            <View style={[styles.item4, { flex: 1 }]}>
                              <Text>Baixa </Text>
                            </View>
                            <View style={[styles.item4, { flex: 1 }]}>
                              <Text style={styles.caixinha} />
                            </View>
                            <View style={[styles.item4, { flex: 1 }]}>
                              <Text>Média </Text>
                            </View>
                            <View style={[styles.item4, { flex: 1 }]}>
                              <Text style={styles.caixinha} />
                            </View>
                            <View style={[styles.item4, { flex: 1 }]}>
                              <Text>Alta</Text>
                            </View>
                            <View style={[styles.item4, { flex: 1 }]}>
                              <Text style={styles.caixinha} />
                            </View>
                            <View
                              style={[
                                styles.item4,
                                { flex: 7, fontWeight: 'bold' },
                              ]}
                            >
                              <Text style={{ fontWeight: 'bold' }}>
                                Comprar
                              </Text>
                            </View>
                            <View style={[styles.item4, { flex: 1 }]}>
                              <Text>Não</Text>
                            </View>
                            <View style={[styles.item4, { flex: 1 }]}>
                              <Text style={styles.caixinha} />
                            </View>
                            <View style={[styles.item4, { flex: 1 }]}>
                              <Text>Pouco</Text>
                            </View>
                            <View style={[styles.item4, { flex: 1 }]}>
                              <Text style={styles.caixinha} />
                            </View>
                            <View style={[styles.item4, { flex: 1 }]}>
                              <Text>Muito</Text>
                            </View>
                            <View style={[styles.item4, { flex: 1 }]}>
                              <Text style={styles.caixinha} />
                            </View>
                          </View>
                        </>
                      ))}
                    </View>
                  </View>
                )}
              </>
            ))}
          </>
        ))}
      </View>
    </Page>
  </Document>
);
export default ListaProdutos;
