import React from 'react';
import {
  Filter as FilterAdmin,
  TextInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <TextInput label="Descrição" source="nome" />
    <BooleanInput label="Ativos" source="ativo" alwaysOn />
    <ReferenceInput
      source="setor_id"
      reference="setores"
      label="Setor"
      allowEmpty={false}
    >
      <SelectInput optionText="nome" allowEmpty={false} />
    </ReferenceInput>
  </FilterAdmin>
);

export default Filter;
