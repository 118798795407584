const Validation = (values) => {
  const errors = {};
  if (!values.username) errors.username = 'Este campo é obrigatório';
  else if (values.username.length > 80)
    errors.username = 'Número de caracteres maior que o permitido (máximo 80)';
  if (values.isCreate) {
    if (!values.passwordNew) errors.passwordNew = 'Este campo é obrigatório';
    else if (values.passwordNew.length > 80)
      errors.passwordNew =
        'Número de caracteres maior que o permitido (máximo 80)';
  }
  if (!values.dataAniversario)
    errors.dataAniversario = 'Este campo é obrigatório';
  if (!values.cargo_id) errors.cargo_id = 'Este campo é obrigatório';
  return errors;
};

export default Validation;
