import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useSnackbar } from 'notistack';

import { TextInput, IntegerInput, SearchProduto } from '../../../Components';
import { createAPI, updateAPI, getListAllAPI } from '../../../services';
import {
  POR_PESO_FIXO,
  POR_UNIDADE,
  formatMoeda,
  STATUS_ATIVO,
} from '../../../utils';
import DialogoDeletarFornecedor from '../DialogoDeletarFornecedor';
import DialogoDeletarProduto from '../DialogoDeletarProduto';
import InputFornecedor from '../InputFornecedor';
import SearchFornecedor from '../SearchFornecedor';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_ID = -1;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState(0);

  const [nome, setNome] = useState('');
  const [fornecedores, setFornecedores] = useState([]);
  const [produtos, setProdutos] = useState([]);

  const [id, setId] = useState(DEFAULT_ID);

  const [carregando, setCarregando] = useState(false);

  const refDialogoDeletarProduto = useRef(null);

  const refDialogoDeletarFornecedor = useRef(null);

  const [searchProduto, setSearchProduto] = useState('');
  const [searchFornecedor, setSearchFornecedor] = useState('');

  const classes = useStyles({ cor: '#009FD4' });

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function reiniciar() {
    setId(DEFAULT_ID);
    setNome('');
    setFornecedores([]);
    setProdutos([]);
    setCarregando(false);
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'cotacoes_templates',
        ['id', 'asc'],
        { id: [id] },
        ['fornecedores', 'produtos'],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        setNome(data.data[0].nome);
        setFornecedores(data.data[0].fornecedores);
        setProdutos(data.data[0].produtos);
        setCarregando(false);
      } else {
        throw 'Este setor não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  function getIdsOptions(array) {
    const ids = [];
    for (let i = 0; i < array.length; i += 1) {
      ids.push(array[i].id);
    }
    return ids;
  }

  async function handleSalvar() {
    if (!erroExistente) {
      setCarregando(true);
      try {
        if (id <= 0) {
          await createAPI('cotacoes_templates', {
            nome,
            fornecedores: getIdsOptions(fornecedores),
            produtos: getIdsOptions(produtos),
          });
          setCarregando(false);
          enqueueSnackbar('Cotação Template criada com sucesso!', {
            variant: 'success',
          });
        } else {
          await updateAPI('cotacoes_templates', id, {
            nome,
            fornecedores: getIdsOptions(fornecedores),
            produtos: getIdsOptions(produtos),
          });
          setCarregando(false);
          enqueueSnackbar('Cotação Template atualizada com sucesso!', {
            variant: 'success',
          });
        }

        handleCloseDialog();
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    } else {
      enqueueSnackbar(`${erros.join('; ')}`, {
        variant: 'error',
      });
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      reiniciar();
      setValue(0);
      setOpen(true);
    },
    handleEdit(id) {
      setValue(0);
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
    },
  }));

  function validateNome() {
    let error = '';
    if (!nome) error = 'Este campo é obrigatório';
    else if (nome.length > 80) {
      error = 'Número de caracteres maior que o permitido (máximo 80)';
    }
    return error;
  }

  function validateProdutos() {
    let error = '';
    if (produtos.length === 0) {
      error = 'É necessário ao menos um produto!';
    }
    return error;
  }

  function validateFornecedores() {
    let error = '';
    if (fornecedores.length === 0) {
      error = 'É necessário ao menos um fornecedor!';
    }
    return error;
  }

  function getErros() {
    const errosOld = ['', '', ''];
    errosOld[0] = validateNome();
    errosOld[1] = validateProdutos();
    errosOld[2] = validateFornecedores();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function deletarProduto(produto) {
    if (refDialogoDeletarProduto.current) {
      refDialogoDeletarProduto.current.handleOpen(produto);
    }
  }

  function handleDeletarProduto({ id }) {
    const newArray = produtos.slice().filter((item) => item.id !== id);
    setProdutos(newArray);
  }

  function deletarFornecedor(fornecedor) {
    if (refDialogoDeletarFornecedor.current) {
      refDialogoDeletarFornecedor.current.handleOpen(fornecedor);
    }
  }

  function handleDeletarFornecedor({ id }) {
    const newArray = fornecedores.slice().filter((item) => item.id !== id);
    setFornecedores(newArray);
  }

  async function handleSearchProduto(codigo, id) {
    const produtoData = await getListAllAPI(
      'produtos',
      ['id', 'asc'],
      id
        ? {
            id,
          }
        : {
            codigo,
            is_kit: false,
            is_caixa: false,
            status: STATUS_ATIVO,
          },
      ['unidade'],
    );

    if (produtoData.data.length > 0) {
      const existis = produtos.some(
        (item) => item.id === produtoData.data[0].id,
      );

      if (existis) {
        enqueueSnackbar('Este produto já está adicionado!', {
          variant: 'error',
        });
      } else {
        const newArray = produtos.slice();
        newArray.push(produtoData.data[0]);
        setProdutos(newArray);
      }
    } else {
      enqueueSnackbar('Este produto não está disponível!', {
        variant: 'error',
      });
    }
  }

  async function handleSearchFornecedor(fornecedor) {
    if (fornecedor) {
      const existis = fornecedores.some((item) => item.id === fornecedor.id);

      if (existis) {
        enqueueSnackbar('Este fornecedor já está adicionado!', {
          variant: 'error',
        });
      } else {
        const newArray = fornecedores.slice();
        newArray.push(fornecedor);
        setFornecedores(newArray);
      }
    } else {
      enqueueSnackbar('Este fornecedor não está disponível!', {
        variant: 'error',
      });
    }
  }

  const produtosReverse = [...produtos];
  produtosReverse.reverse();

  const fornecedoresReverse = [...fornecedores];
  fornecedoresReverse.reverse();

  const idsProdutos = produtos.map((item) => item.id);
  const idsFornecedores = [1, 2, ...fornecedores.map((item) => item.id)];

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        disableBackdropClick
        disableEscapeKeyDown
        fullScreen
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          {id < 0 ? `Nova Cotação` : `Editar Cotação`}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <Box p="1em">
                <Box style={{ margin: '10px 0px' }}>
                  <TextInput
                    name="nome"
                    handleEnter={() => {}}
                    label="Descrição"
                    handleKey={() => {}}
                    value={nome}
                    onChange={(value) => setNome(value.target.value)}
                    error={erros[0] !== ''}
                    helperText={erros[0]}
                    fullWidth
                    autoFocus
                  />
                </Box>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab label="Produtos" {...a11yProps(0)} />
                  <Tab label="Fornecedores" {...a11yProps(1)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <Box style={{ marginBottom: '20px' }}>
                    <SearchProduto
                      label="Pesquisar produto"
                      value={searchProduto}
                      onChange={(e) => setSearchProduto(e)}
                      fullwidth
                      searchHandle={handleSearchProduto}
                      searchHandle2={() => {}}
                      //ref={refSearch}
                      handleKey={(keyCode, keyName) => {
                        //handleActions(keyName);
                      }}
                      filters={{ ativo: true, ids_excluso: idsProdutos }}
                    />
                  </Box>
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Id</StyledTableCell>
                          <StyledTableCell>Código</StyledTableCell>
                          <StyledTableCell>Descrição</StyledTableCell>
                          <StyledTableCell align="right">Ações</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {produtosReverse.map((row) => {
                          const { id, nome, codigo } = row;
                          return (
                            <StyledTableRow key={row.id}>
                              <StyledTableCell component="th" scope="row">
                                {id}
                              </StyledTableCell>
                              <StyledTableCell>{codigo}</StyledTableCell>
                              <StyledTableCell>{nome}</StyledTableCell>
                              <StyledTableCell align="right">
                                <Tooltip
                                  title="Deletar"
                                  placement="bottom"
                                  aria-label="add2"
                                >
                                  <IconButton
                                    aria-label="delete"
                                    classes={{ root: classes.root }}
                                    size="small"
                                    onClick={() => deletarProduto(row)}
                                  >
                                    <DeleteOutlineIcon />
                                  </IconButton>
                                </Tooltip>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Box style={{ marginBottom: '20px' }}>
                    {/*<SearchFornecedor
                      label="Pesquisar fornecedor"
                      value={searchFornecedor}
                      onChange={(e) => setSearchFornecedor(e)}
                      fullwidth
                      searchHandle={handleSearchFornecedor}
                      searchHandle2={() => {}}
                      handleKey={(keyCode, keyName) => {
                      }}
                    />*/}
                    <InputFornecedor
                      handleSelecionado={handleSearchFornecedor}
                      ids_excluso={idsFornecedores}
                    />
                  </Box>
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Id</StyledTableCell>
                          <StyledTableCell>Descrição</StyledTableCell>
                          <StyledTableCell align="right">Ações</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {fornecedoresReverse.map((row) => {
                          const { id, nome } = row;
                          return (
                            <StyledTableRow key={row.id}>
                              <StyledTableCell component="th" scope="row">
                                {id}
                              </StyledTableCell>
                              <StyledTableCell>{nome}</StyledTableCell>
                              <StyledTableCell align="right">
                                <Tooltip
                                  title="Deletar"
                                  placement="bottom"
                                  aria-label="add2"
                                >
                                  <IconButton
                                    aria-label="delete"
                                    classes={{ root: classes.root }}
                                    size="small"
                                    onClick={() => deletarFornecedor(row)}
                                  >
                                    <DeleteOutlineIcon />
                                  </IconButton>
                                </Tooltip>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TabPanel>
              </Box>
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={handleCloseDialog}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
            variant="contained"
            color="primary"
          >
            Cancelar
          </Button>
          <div className={classes.wrapper}>
            <Button
              onClick={handleSalvar}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              disabled={carregando}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
            {carregando && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
        <DialogoDeletarFornecedor
          ref={refDialogoDeletarFornecedor}
          handleClose={() => {}}
          handleDelete={handleDeletarFornecedor}
        />
        <DialogoDeletarProduto
          ref={refDialogoDeletarProduto}
          handleClose={() => {}}
          handleDelete={handleDeletarProduto}
        />
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
