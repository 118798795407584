import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
} from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Box,
  Typography,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import { useSnackbar } from 'notistack';

import { TextInput, AutoCompleteRemoto } from '../../Components/Inputs';
import { api, getListAllAPI, getUsername } from '../../services';
import { SECONDARY_COLOR, STATUS_ATIVO } from '../../utils';

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DialogoNota = forwardRef(
  ({ handleClose, handleAutoriza, soUm = false }, ref) => {
    const [open, setOpen] = useState(false);
    const [usernameAtual, setUsernameAtual] = useState('');
    const [passwordAtual, setPasswordAtual] = useState('');

    const [usuarioNovo, setUsuarioNovo] = useState(null);
    const [passwordNovo, setPasswordNovo] = useState('');

    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [carregando, setCarregando] = useState(false);

    const handleCloseDialog = () => {
      setOpen(false);
    };

    useImperativeHandle(ref, () => ({
      handleOpen(usernameAtual = '') {
        setOpen(true);
        setUsernameAtual(getUsername());
        setPasswordAtual('');
        setUsuarioNovo(null);
        setPasswordNovo('');
      },
    }));

    async function handleSalvarDialog() {
      if (!erroExistente) {
        try {
          setCarregando(true);
          let dataUsuarioAtual = {
            data: {
              status: true,
              message: '',
            },
          };

          if (usernameAtual) {
            dataUsuarioAtual = await api.post('/login_retirada_caixa', {
              username: usernameAtual,
              password: passwordAtual,
            });
          }

          if (dataUsuarioAtual.data.status) {
            if (!soUm) {
              const dataUsuarioNovo = await api.post('/login_retirada_caixa', {
                username: usuarioNovo.username,
                password: passwordNovo,
              });

              if (dataUsuarioNovo.data.status) {
                handleAutoriza(usuarioNovo.id);
                handleCloseDialog();
              } else {
                enqueueSnackbar(dataUsuarioNovo.data.message, {
                  variant: 'error',
                });
              }
            } else {
              handleAutoriza();
              handleCloseDialog();
            }
          } else {
            enqueueSnackbar(dataUsuarioAtual.data.message, {
              variant: 'error',
            });
          }
        } catch (e) {
          enqueueSnackbar('Erro inesperado!', {
            variant: 'error',
          });
        } finally {
          setCarregando(false);
        }
      }
    }

    function validatePasswordUsuarioAtual() {
      let error = '';
      if (!passwordAtual) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function validateUsuarioNovo() {
      let error = '';
      if (!usuarioNovo) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function validatePasswordUsuarioNovo() {
      let error = '';
      if (!passwordNovo) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function getErros() {
      const errosOld = ['', '', ''];
      errosOld[0] = validatePasswordUsuarioAtual();
      errosOld[1] = soUm ? '' : validateUsuarioNovo();
      errosOld[2] = soUm ? '' : validatePasswordUsuarioNovo();
      return errosOld;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    return (
      <div>
        <Dialog
          open={open}
          onClose={handleCloseDialog}
          aria-labelledby="form-dialog-title"
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle
            id="form-dialog-title"
            style={{
              color: 'white',
              backgroundColor: SECONDARY_COLOR,
              textAlign: 'center',
            }}
          >
            {soUm
              ? 'Autorização pegar responsabilidade'
              : 'Autorização mudança de responsabilidade'}
          </DialogTitle>
          <DialogContent>
            <Box>
              <Typography
                variant="h6"
                gutterBottom
                style={{
                  textAlign: 'center',
                }}
              >
                Transportador Atual
              </Typography>

              <Box display="flex">
                <Box flex={1} mr="0.5em">
                  <TextInput
                    name="username"
                    label="Usuário"
                    value={usernameAtual}
                    onChange={() => {}}
                    fullWidth
                    handleKey={() => {}}
                    handleEnter={() => {}}
                    disabled
                  />
                </Box>
                <Box flex={1} ml="0.5em">
                  <TextInput
                    handleEnter={() => {
                      handleSalvarDialog();
                    }}
                    name="password"
                    type="password"
                    label="Senha"
                    value={passwordAtual}
                    onChange={(event) => {
                      setPasswordAtual(event.target.value);
                    }}
                    fullWidth
                    handleKey={(action, name) => {}}
                    error={erros[0] !== ''}
                    helperText={erros[0]}
                    autoFocus
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          <Visibility />
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </Box>
              </Box>
            </Box>
            {!soUm ? (
              <Box>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    textAlign: 'center',
                  }}
                >
                  Novo Transportador
                </Typography>

                <Box display="flex">
                  <Box flex={1} mr="0.5em">
                    <AutoCompleteRemoto
                      handleEnter={() => {}}
                      id="transportador"
                      name="transportador"
                      label="Usuário"
                      value={usuarioNovo}
                      onChange={(value) => setUsuarioNovo(value)}
                      handleKey={(action, name) => {}}
                      resource="users"
                      nested={[]}
                      toString={false}
                      fullwidth
                      campo="username"
                      filters={{ status: STATUS_ATIVO }}
                      getOptionSelected={(option, value) =>
                        option.username === value.username
                      }
                      getOptionLabel={(option) => `${option.username}`}
                      error={erros[1] !== ''}
                      helperText={erros[1]}
                    />
                  </Box>
                  <Box flex={1} ml="0.5em">
                    <TextInput
                      handleEnter={() => {
                        handleSalvarDialog();
                      }}
                      name="password"
                      type="password"
                      label="Senha"
                      value={passwordNovo}
                      onChange={(event) => {
                        setPasswordNovo(event.target.value);
                      }}
                      fullWidth
                      handleKey={(action, name) => {}}
                      error={erros[2] !== ''}
                      helperText={erros[2]}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <Visibility />
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                  </Box>
                </Box>
              </Box>
            ) : null}
          </DialogContent>
          <DialogActions
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Button
              onClick={handleCloseDialog}
              color="secondary"
              variant="contained"
              tabindex="-1"
            >
              Cancelar
            </Button>
            <div className={classes.wrapper}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  handleSalvarDialog();
                }}
                disabled={erroExistente || carregando}
              >
                Confirmar
              </Button>
              {carregando && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoNota;
