import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  DateField,
  ListContextProvider,
  NumberField,
  TextField,
  useListContext,
  ListBase,
  ListToolbar,
  Pagination,
  Datagrid,
  useRefresh,
  useNotify,
  FunctionField,
} from 'react-admin';

import { Divider, Tabs, Tab, Card } from '@material-ui/core';

import logo from '../../assets/logo.png';
import { DialogoNota } from '../../Components';
import Actions from './ActionsList';
import DialogoAtivarResource from './DialogoAtivarResource';
import DialogoDesativarResource from './DialogoDesativarResource';
import ActionsField from './Fields/ActionsField';
import StatusField from './Fields/StatusField';
import Filter from './Filter';

function formatNfe(nfe) {
  if (nfe) {
    if (nfe.length === 9) {
      return `${nfe.substring(0, 3)}.${nfe.substring(3, 6)}.${nfe.substring(
        6,
        9,
      )}`;
    }
    return nfe;
  }
  return '';
}

const tabs = [
  { id: 'nao_conferido', name: 'Não Conferido' },
  { id: 'conferido', name: 'Conferido' },
];

const MyList = ({ ...props }) => {
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [confirmados, setConfirmados] = useState([]);
  const [cancelados, setCancelados] = useState([]);
  const refDialogoAtivaResource = useRef(null);
  const refDialogoDesativarResource = useRef(null);
  const refresh = useRefresh();
  const refDialogoNota = useRef();

  function openNota(data) {
    if (refDialogoNota.current)
      refDialogoNota.current.handleOpen(data.record.id, 'venda');
  }

  function ativarHandle(record) {
    if (refDialogoAtivaResource.current)
      refDialogoAtivaResource.current.handleOpen(record.record.id);
  }

  function desativarHandle(record) {
    if (refDialogoDesativarResource.current)
      refDialogoDesativarResource.current.handleOpen(record.record.id);
  }

  useEffect(() => {
    if (ids && ids !== filterValues.nfe_verificado) {
      switch (filterValues.nfe_verificado) {
        case false:
          setCancelados(ids);
          break;
        case true:
          setConfirmados(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.nfe_verificado]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters(
          { ...filterValues, nfe_verificado: value === 'conferido' },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  function handleClose() {
    refresh();
  }

  return (
    <>
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.nfe_verificado ? tabs[1].id : tabs[0].id}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.nfe_verificado === false && (
            <ListContextProvider value={{ ...listContext, ids: cancelados }}>
              <Datagrid {...props}>
                <StatusField source="numero_nota_fiscal" label="Status" />
                {/* <TextField
                  source="tipoPagamento.nome"
                  label="Meio de Pagamento"
                /> */}
                <NumberField
                  source="valor_total_nfe"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                  label="Valor Nota"
                />
                <TextField source="numero" label="Nº Venda" />
                <DateField
                  source="data"
                  label="Data"
                  options={{ hour: 'numeric', minute: 'numeric' }}
                />
                <NumberField
                  source="valor"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                  label="Valor da venda"
                />
                <TextField source="sessao.caixa.nome" label="Checkout" />
                <TextField source="cliente.nome" label="Cliente" />
                <ActionsField
                  handleEfetivar={ativarHandle}
                  handleNota={openNota}
                  label="Ações"
                  textAlign="right"
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.nfe_verificado === true && (
            <ListContextProvider value={{ ...listContext, ids: confirmados }}>
              <Datagrid {...props}>
                <StatusField source="numero_nota_fiscal" label="Status" />
                <TextField source="id" label="ID da parcela" />
                {/* <TextField
                  source="numero_nota_fiscal"
                  label="Número nota fiscal"
                /> */}
                <FunctionField
                  label="Número nota fiscal"
                  render={(record) => formatNfe(record.numero_nota_fiscal)}
                />
                {/* <TextField
                  source="tipoPagamento.nome"
                  label="Meio de Pagamento"
                /> */}
                <NumberField
                  source="valor_total_nfe"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                  label="Valor Nota"
                />
                <TextField source="numero" label="Nº Venda" />
                <DateField
                  source="data"
                  label="Data"
                  options={{ hour: 'numeric', minute: 'numeric' }}
                />
                <NumberField
                  source="valor"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                  label="Valor da venda"
                />
                <TextField source="sessao.caixa.nome" label="Checkout" />
                <TextField source="cliente.nome" label="Cliente" />
                <ActionsField
                  handleEfetivar={desativarHandle}
                  handleNota={openNota}
                  label="Ações"
                  textAlign="right"
                />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
        <TransacoesPagination />
      </Card>
      <DialogoNota ref={refDialogoNota} logo={logo} />
      <DialogoAtivarResource
        ref={refDialogoAtivaResource}
        handleClose={handleClose}
      />
      <DialogoDesativarResource ref={refDialogoDesativarResource} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Verificação Notas Vendas"
      filterDefaultValues={{
        nfe_verificado: false,
      }}
    />
  </>
);

export default List2;
