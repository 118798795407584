import React from 'react';

import CreateTemplate from '../../Components/Create';
import Erros from './Erros';
import Form from './Form';
import validate from './Validation';

function transform({
  passwordNew,
  dataAniversario,
  username,
  funcao,
  cargo_id,
  is_responsavel,
  ...data
}) {
  let dia_aniversario = null;
  let mes_aniversario = null;
  let ano_aniversario = null;
  if (dataAniversario !== null) {
    const dataAniversarioObj = new Date(dataAniversario);
    dia_aniversario = dataAniversarioObj.getDate();
    mes_aniversario = dataAniversarioObj.getMonth();
    ano_aniversario = dataAniversarioObj.getFullYear();
  }
  return {
    dia_aniversario,
    mes_aniversario,
    ano_aniversario,
    username,
    password: passwordNew,
    funcao: 0,
    cargo_id,
    is_responsavel,
  };
}

const Create = (props) => (
  <CreateTemplate
    {...props}
    title="Novo Adm"
    transform={transform}
    validate={validate}
    onFailure={Erros}
    defaultValue={{ dataAniversario: new Date(), isCreate: true }}
    isBack
  >
    <Form />
  </CreateTemplate>
);

export default Create;
